import {createSlice, createSelector} from '@reduxjs/toolkit';
import { callAPIProps } from 'services/hooks/useAPI';
import _ from 'lodash';
import { currentProjectIdSelector } from 'services/store/scopeSlice';

export type RoomsObjectState = any;

const initialState: RoomsObjectState = {

}


export const roomsSlice = createSlice({
    name: "rooms",
    initialState, 
    reducers: {
        get: (state, action) => {

            if (!state[action.payload.projectId]) state[action.payload.projectId] = {};
            if (action.payload.data && action.payload.data.forEach) {
                const sorted = _.sortBy(action.payload.data, "order")
                sorted.forEach((r:any) => {
                    state[action.payload.projectId][r.id] = r;
                })
            }
        },
        updateRoom: (state, action) => {
            state[action.payload.projectId][action.payload.data.id] = action.payload.data;
        },
        addRoom: (state, action) => {
            state[action.payload.projectId][action.payload.data.id] = action.payload.data;
        },
        removeRoom: (state, action) => {
         //   console.log("Removing room", action)
            delete state[action.payload.projectId][action.payload.additional.roomId]
            //state[action.payload.projectId][action.payload.data.id] = action.payload.data;
        },
    }
})


export const GetRooms = ():callAPIProps => ({
    url: ({ getApiUrl, language, projectId}: any) => getApiUrl(`projects/${projectId}/websites/object/room`,1),
    method: "GET",
    successDispatch: roomsSlice.actions.get,
})

export const AddRoom = ():callAPIProps => ({
    url: ({getApiUrl, projectId}) => getApiUrl(`projects/${projectId}/websites/object/room`),
    method: "POST",
    successDispatch: roomsSlice.actions.addRoom,
})

export const RemoveRoom = (roomId: any):callAPIProps => ({
    url: ({getApiUrl, projectId}) => getApiUrl(`projects/${projectId}/websites/object/room/${roomId}`),
    method: "DELETE",
    successDispatch: roomsSlice.actions.removeRoom,
    passToDispatcher: {roomId: roomId},
})

export const ProjectRoomsSelector = createSelector([
    (state:any) => state?.siteGenerator.rooms,
    currentProjectIdSelector
],
    (state:any, projectId: any) => state && state[projectId]
)

export const RoomSelector = createSelector([
    ProjectRoomsSelector,
    (state:any, roomId: any) => roomId
], (rooms: any, roomId: any) => rooms && rooms[roomId])

export const RoomSelectorWithCategories = createSelector([
    (state:any) => state,
    (RoomSelector as any)
], (state: any, room:any) => {
    if (!room) return null;
    if (!state) return null;
})

export const UserHasFilledRooms = createSelector(
    [ProjectRoomsSelector as any],
    (state:any) => {
        return true;
    }
)

export default roomsSlice.reducer;