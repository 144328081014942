import { ProductConfig } from "products";
import logo from "products/localMax/FastTonyLocalMax-Logo.png";
import requirements from "../local3/requirements";
import { Requirement } from "modules";

const config: ProductConfig = {
  name: "LocalMax",
  logo,
  requirements: requirements as Requirement[],
};

export default config;
