import { Requirement } from "modules";
import {
  getUrl as getWebsitesUrl,
  ONBOARDING_COMPANY_DATA,
  ONBOARDING_WEBSITE_THEME,
  ONBOARDING_INDUSTRY,
} from "modules/site-generator/config/routes";
import {
  UserHasFilledCompanyDataSelector,
  UserHasFilledWebsiteTheme,
  UserHasWebsitesWithIndustrySelector,
} from "modules/site-generator/reducers/websiteSlice";

const siteGeneratorRequirementsBase: Requirement[] = [
  {
    // check sideGenerator have website.industry
    key: "userHasProjectWithWebsitesFeature",
    path: () => getWebsitesUrl(ONBOARDING_INDUSTRY),
    selector: UserHasWebsitesWithIndustrySelector,
    omitWhenFulfilled: true,
    // component: <OnboardingCreateWebsiteAndIndustrySelect/>
    // website.industry
  },
  // {
  //     key: "userHasVerifiedIdentity",
  //     path: () => getWebsitesUrl(ONBOARDING_VERIFY_IDENTITY),
  //     selector: () => false,
  //     //selector: userHasFeatureWebsitesSelector,
  //     omitWhenFulfilled: true,
  // },
  {
    // check sideGenerator have survey parameters
    key: "userHasFilledCompanyDataSelector",
    path: () => getWebsitesUrl(ONBOARDING_COMPANY_DATA),
    selector: UserHasFilledCompanyDataSelector,
    // component: <OnboardingCompanyData/>
    // company_name !== null &&
    // address !== null &&
    // zip_code !== null &&
    // city !== null &&
    // latitude !== null &&
    // longitude !== null &&
    // voivodeship !== null &&
    // country !== null &&
    // nip !== null &&
    // phone !== null &&
    // email !== null
  },
  // {
  //     key: "userHasFilledLocationData",
  //     path: () => getWebsitesUrl(ONBOARDING_LOCATION_DATA),
  //     selector: (state: any) => true,
  //     omitWhenFulfilled: false,
  // },
  {
    // check sideGenerator have website.template_id or website.template_number
    key: "userHasFilledWebsiteTheme",
    path: () => getWebsitesUrl(ONBOARDING_WEBSITE_THEME),
    selector: UserHasFilledWebsiteTheme,
    // component: <OnboardingWebsiteTheme/>
    // website.template_id || website.template_number
  },
];

export default siteGeneratorRequirementsBase;
