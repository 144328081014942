import { useMemo, useState } from "react"
import SiderMenuItem from "./SiderMenuItem";
import "./sider-menu.less";
import { Layout } from "../templates";
import TestPage from "modules/panel/testing/test";
import { AwesomeLarge } from "components/common/AwesomeIcon";
import { faBadgePercent, faGraduationCap, faRobot, faVial } from "@fortawesome/pro-light-svg-icons";
import AILexiconTestPanel from "modules/panel/testing/AILexicon/AILexiconTestPanel";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { WebsiteIndustrySelector } from "modules/site-generator/reducers/websiteSlice";
import { GetIndustryByName } from "modules/site-generator/config/industries/IndustriesConfig";
import TestIndex from "modules/panel/testing/TestIndex";
// import { SUPER_PROMO } from "modules/panel/config/routes";


// const superPromo = {
//     key: SUPER_PROMO,
//     path: "/panel/super-promo",
//     icon: <AwesomeLarge icon={faBadgePercent} color="white"/>,
//     highlightStyle: {
//         background: `linear-gradient(135deg, rgba(33, 33, 33,1) 0%, rgba(60, 60, 60, 1) 70%, rgba(90,90,90,1) 100%)`
//     }
// }

export default function SiderMenu(props: any) {

    //experimental routes can be accessed by setting apropriate local storage item
    //this can be done manually or by visiting
    //{URL}/panel/enableFeature?AILexicon=true

    const industry = useSelectorWithParams(WebsiteIndustrySelector);

    const experimentalRoutes = useMemo(() => {

        const exRoutes: any[] = [];
        const AILexicon = localStorage.getItem("AILexicon");
        if (AILexicon === "true") {
            exRoutes.push({
                key: "AI_LEXICON",
                path: "/panel/ai-lexicon",
                displayInMenu: true,
                layout: Layout.Panel,
                content: AILexiconTestPanel,
                icon: <AwesomeLarge icon={faRobot} />,
                name: "AI Lexicon",
            })
        }

        if (import.meta.env.VITE_NODE_ENV === "development") {
            exRoutes.push({
                key: "testPage",
                path: "/panel/test",
                displayInMenu: true,
                layout: Layout.Panel,
                content: TestPage,
                icon: <AwesomeLarge icon={faVial} />,
                name: "Test Page",
            })


            exRoutes.push({
                key: "tests",
                path: "/panel/tests",
                displayInMenu: true,
                layout: Layout.Panel,
                content: TestIndex,
                icon: <AwesomeLarge icon={faRobot} />,
                name: "Tests",
            })
        }

        return exRoutes;

    }, [props.apps])

    const routes = useMemo(() => {
        let routes: any[] = [];

        for (const app of props.apps) {
            for (const route of app.routes) {
                if (route.displayInMenu !== false) {
                    routes.push({
                        ...route,
                        path: "/" + app.path + route.path
                    })
                }
            }
        }

        if (industry) {
            const config = GetIndustryByName(industry);
            if (config.disallowedRoutes && config.disallowedRoutes.length > 0) {
                routes = routes.filter((route) => config.disallowedRoutes?.includes(route.key) !== true)
            }
        }

        routes = [...routes, ...experimentalRoutes]
        
        return routes;

    }, [props.apps])

    const mappedRoutes = useMemo(() => {
        return routes.map((route: any) => <SiderMenuItem key={route.key} route={route} isMobile={props.isMobile} />)
    }, [routes])

    return (
        <ul className={`sider-menu ${props.isMobile ? "mobile" : ""}`}>
            {mappedRoutes}
        </ul>
    )
}