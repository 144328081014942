import { Card } from "antd";
import { StandardDrawer, useStandardDrawer } from "services/hooks/useDrawer";
import FormattedMessage from "components/common/FormattedMessage";
import useAPI from "services/hooks/useAPI";
import {
  RemovePage,
  WebsitePageSelector,
} from "modules/site-generator/reducers/websitePagesSlice";
import { StandardModal, useStandardModal } from "services/hooks/useModal";
import { HorizontalCenter } from "components/common/Center";
import WebsitePageEditor from "./WebsitePageEditor";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import AwesomeIcon from "components/common/AwesomeIcon";
import { faPencil, faTrash } from "@fortawesome/pro-light-svg-icons";

export type WebsitePageProps = {
  pageId?: string | number;
  website?: any;
  onPageDelete?: (pageId?: string) => void;
  displayDeleteConfirmation?: () => void
};

export const WebsitePageListEntry = (props: WebsitePageProps) => {

  const page = useSelectorWithParams(WebsitePageSelector(props.pageId))
  const [delConfRef, delConfOpen, delConfClose] = useStandardModal();
  const [ref, open, close] = useStandardDrawer();
  const { call } = useAPI({});

  const displayDeleteConfirmation = () => {
    delConfOpen();
  };

  const handleRemove = () => {
    delConfClose();
    call(RemovePage(props.website.id, page.id));
    props.onPageDelete && props.onPageDelete(page.id);
  };

  if (!page) return null;

  return (
    <>
      <Card
        actions={[
          <AwesomeIcon icon={faPencil} key="edit" onClick={open} />,
          <AwesomeIcon icon={faTrash} key="delete" onClick={displayDeleteConfirmation} />,
        ]}
        style={{ width: "200px", maxWidth: "100vw" }}
        title={page?.title}
      >
        <div>
          <FormattedMessage
            id="apps.websites.customPages.page.status"
            defaultMessage="Status"
          />
          : {page.status}
        </div>
        <div>
          <FormattedMessage
            id="apps.websites.customPages.page.place"
            defaultMessage="Location"
          />
          : {page.place}
        </div>
        <div>
          <FormattedMessage
            id="apps.websites.customPages.page.type"
            defaultMessage="Type"
          />
          : {page.type}
        </div>
      </Card>
      <StandardDrawer
        ref={ref}
        title={
          <FormattedMessage
            id="apps.websites.customPages.page.edit"
            defaultMessage="Edit Page"
          />
        }
        width="1500px"
        layout="standard"
      >
        <WebsitePageEditor
          pageId={props.pageId}
          onPageDelete={close}
          displayDeleteConfirmation={displayDeleteConfirmation}
        />
      </StandardDrawer>
      <StandardModal ref={delConfRef} onOk={handleRemove}>
        <FormattedMessage
          id="apps.websites.customPages.page.delete.confirm"
          defaultMessage="Are you sure you want to delete this page?"
        />
        <HorizontalCenter>
          <h1>{page.title}</h1>
        </HorizontalCenter>
      </StandardModal>
    </>
  );
};


