import EstimateDisplay from "components/keywords/estimateDisplay";
import KeywordsFacebook from "components/keywords/KeywordsFacebook";
import _ from "lodash";
import ConnectedOnboardingControls from "providers/onboarding/controls";
import { useOnboarding } from "providers/onboarding/onboarding-context";
import { useEffect} from "react";
import FormattedMessage from "components/common/FormattedMessage";
import useAPI from "services/hooks/useAPI";
import { StandardModal, useStandardModal } from "services/hooks/useModal";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { HorizontalCenter } from "components/common/Center";
import { Spin } from "antd";
import RelaxField from "tools/relaxForm/RelaxField";
import { FacebookKeywordsSelector, UpdateFacebookAdsSettings } from "modules/smart/smartSlice";

export default function OnboardingKeywordsFacebook () {

    const keywords = useSelectorWithParams([FacebookKeywordsSelector]);
    const {call: checkIfRequired, data: isRequired, loading: checkingIfRequired} = useAPI({
        url: ({getApiUrl, smart}) => getApiUrl(`smart/${smart}/require-interests`, 2)
    },true)
    const [ref, open, close] = useStandardModal();

    const {nextStep} = useOnboarding();

    useEffect(() => {

        if (isRequired && isRequired.require === "no") {
            //console.log("Interests not required, skipping");
            nextStep();
        }

    }, [isRequired, checkingIfRequired])

    const checkNext = () => {
        if (!keywords || keywords.length === 0) {
            open();
            return false;
        }
        return true;
    }

    const modalOK = () => {
        nextStep();
    }

    if (checkingIfRequired) return <HorizontalCenter><Spin /></HorizontalCenter>;
      
    return (
        <>
            {keywords && keywords.length > 0 ? <EstimateDisplay platform="facebook" /> : null}
            <RelaxField
            name="audience_interest"
            initialValues={{"audience_interest": keywords}}
            callAPI={UpdateFacebookAdsSettings}
            submitOnChange
            style={{paddingBottom: "10px", minHeight: "300px"}}
            >            
                <KeywordsFacebook platform="facebook" simpleModeOnly/>
            </RelaxField>
            <StandardModal
                ref={ref}
                title={<FormattedMessage
                            id="smart.onboarding.facebookKeywords.skipStep.title"
                            defaultMessage="Are you sure?"
                        />}
                onOk={modalOK}
                >
                <FormattedMessage
                            id="smart.onboarding.facebookKeywords.skipStep.message"
                            defaultMessage="Are you sure you want to skip this step?"
                        />
                </StandardModal>
            <ConnectedOnboardingControls relaxDontSubmit = {true} clickOverride={checkNext}/>
        </>
    )

}

