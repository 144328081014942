import { Dropdown, Menu, Popconfirm } from "antd"
import FormattedMessage from "components/common/FormattedMessage";
import StandardButton from "components/common/StandardButton"
import { MoreIcon } from "modules/panel/components/ft-icons"
import { ReactNode, useMemo } from "react";

type Props = {
    postId?: string;
    previewUrl?: string;
    hidePostControls: boolean;
    children?: ReactNode;
}

export const MoreOptionsDropdown = (props:Props) =>{

    const dropdownMenu = useMemo(() => {
        const options = [];
    
        if (!props.hidePostControls) {
          options.push(
            <Menu.Item key="update">
              <a target="_blank" href={props.previewUrl}>
                <FormattedMessage
                  id="modules.smart.containers.ads.updateAd"
                  defaultMessage="Preview"
                />
              </a>
            </Menu.Item>
          )
          options.push(
            <Menu.Item key="delete">
                      <Popconfirm
                        title={
                          <FormattedMessage
                            id="modules.smart.containers.ads.doYouReallyWantToDelete"
                            defaultMessage="Delete post"
                          />
                        }
                        placement="left"
                        //onConfirm={remove}
                      >
                        <a>
                          <FormattedMessage
                            id="modules.smart.containers.ads.delete"
                            defaultMessage="Delete"
                          />
                        </a>
                      </Popconfirm>
            </Menu.Item>
          )
        }
    
        if (props.children) {
          options.push(props.children)
        } 
    
        return (
          <Menu>
            {options}
          </Menu>
        )
      },[props.hidePostControls, props.children])
return(
    <>
    <Dropdown
    overlay={dropdownMenu}
    placement="bottomRight"
    >
    <StandardButton className="more-options-dropdown" type="link" size="small">
        <MoreIcon />
      </StandardButton>
    </Dropdown>
    </>
)
}