import { PricingColumnTemplate, UpdateSection } from "modules/site-generator/reducers/websitePricesSlice";
import useAPI from "services/hooks/useAPI";
import { PriceTableProps } from "./PricingTable";
import AwesomeIcon from "components/common/AwesomeIcon";
import { faGripDots, faTrash } from "@fortawesome/pro-light-svg-icons";
import StandardButton from "components/common/StandardButton";
import { useEffect, useRef } from "react";
import dragula from "dragula";
import "./price-config.less";

const getIndexInParent = (el: any) =>
  Array.from(el.parentNode.children).indexOf(el);

const ColumnHeading = ({
  column,
  table,
}: {
  column: PricingColumnTemplate;
  table: PriceTableProps;
}) => {
  const udpateColumns = useAPI(UpdateSection(table.id));

  const handleDelete = () => {
    const newColumns = {
      columns: table.columns.filter((c: number) => c != column.id),
    };

    udpateColumns.call({
      body: newColumns,
    });
  };

  return (
    <div
      className="price-column-heading"
      data-price-table-column-id={column.id}
    >
      <AwesomeIcon icon={faGripDots} size="xl" />
      {column.name}
      <StandardButton icon={faTrash} onClick={handleDelete} type="text" />
    </div>
  );
};

export const Columns = ({ table }: { table: PriceTableProps }) => {
  const { template } = table;
  const newColumnsOrder = useAPI(UpdateSection(table.id));
  const columns = template
    ? template.columns
        .map((column: PricingColumnTemplate) => {
          if (table.columns.some((col) => col === column.id)) return column;
        })
        .filter(Boolean)
    : [];

  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let start;

    const d = dragula([], {
      revertOnSpill: true,
      moves: (el) => {
        if (!el) return false;
        start = getIndexInParent(el);
        return true;
      },
    });
    listRef.current && d.containers.push(listRef.current);

    d.on("drop", (el, target, source, sibling) => {
      const to = getIndexInParent(el);
      const columnId = el.getAttribute("data-price-table-column-id");
      const newColumns = table.columns.slice();
      if (columnId !== null) {
        const from = newColumns.indexOf(parseInt(columnId));
        const f = newColumns.splice(from, 1)[0];
        newColumns.splice(to, 0, f);
      }
      newColumnsOrder.call({
        body: {
          columns: newColumns,
        },
      });
    });

    return () => {
      d.destroy();
    };
  }, [table]);

  return (
    <div
      className="price-column-headings"
      ref={listRef}
      style={{
        gridTemplateColumns: `repeat(${columns.length}, 1fr)`,
      }}
    >
      {columns &&
        columns.map &&
        columns.map((column) => {
          if (column !== undefined)
            return (
              <ColumnHeading column={column} table={table} key={column.id} />
            );
          else {
            return null;
          }
        })}
    </div>
  );
};