import { Space } from "antd";
import StandardActorCard, { StandardProjectCard, StandardSubscriptionCard } from "components/common/StandardActorCard";
import { Text } from "components/common/StandardText";
import { userSelector } from "modules/panel/config/selectors/user";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { projectsSelector } from "state/projects/projectsSelectors";
import { subscriptionsArraySelector } from "state/subscriptions/subscriptionsSelectors";

const UserAccountSummary = () => {

    const user = useSelectorWithParams(userSelector)
    const projects = useSelectorWithParams(projectsSelector)


    const projectCards = projects ? Object.keys(projects).map((project: string) => {
        return (
            <StandardProjectCard projectId={project} />
        )
    }) : []

    return (
        <div>
        <Space direction="vertical">
            <Text>User</Text>
            <StandardActorCard actor={{
                name: user?.first_name + " " + user?.last_name,
            }} />
            <Text>Projects</Text>
            <Space direction="horizontal">
            {projectCards}
            </Space>
        </Space>
        </div>
    )

}

export default UserAccountSummary;