import { Radio, Space } from "antd";
import Checkbox from "components/inputs/Checkbox";
import RelaxField from "tools/relaxForm/RelaxField";

const ModerationSettings = () => {

    return (
        <div>
            <RelaxField
                name="trigger_words"
            >
                <Checkbox>Używaj listy zabronionych słów.</Checkbox>
            </RelaxField>
            <RelaxField
                name="sentiment_detection"
            >
                <Checkbox>Używaj AI aby wykrywać sentyment komentarza</Checkbox>
            </RelaxField>


            <RelaxField
                name="negative_comments"
                label="Negatywne Komentarze"
            >
                <Radio.Group>
                    <Space direction="vertical">
                        <Radio value={1}>Ukrywaj negatywne komentarze</Radio>
                        <Radio value={2}>Usuwaj negatywne komentarze</Radio>
                        <Radio value={3}>Nie usuwaj negatywnych komentarzy</Radio>
                    </Space>
                </Radio.Group>
            </RelaxField>

            <RelaxField
                name="offensive_comments"
                label="Obraźliwe Komentarze"
            >
                <Radio.Group>
                    <Space direction="vertical">
                        <Radio value={1}>Ukrywaj obraźliwe komentarze</Radio>
                        <Radio value={2}>Usuwaj obraźliwe komentarze</Radio>
                        <Radio value={3}>Nie usuwaj obraźliwych komentarzy</Radio>
                    </Space>
                </Radio.Group>
            </RelaxField>

            <RelaxField 
                name="comments_with_links"
                label="Komentarze z linkami"
            >
                <Radio.Group>
                    <Space direction="vertical">
                        <Radio value={1}>Ukrywaj komentarze z linkami</Radio>
                        <Radio value={2}>Usuwaj komentarze z linkami</Radio>
                        <Radio value={3}>Nie usuwaj komentarzy z linkami</Radio>
                    </Space>
                </Radio.Group>
            </RelaxField>

            <RelaxField
                name="ban_rule"
                label="Ban rule"
            >
                <Radio.Group>
                    <Space direction="vertical">
                        <Radio value={1}>Banuj użytkownika przy pierwszym wykroczeniu</Radio>
                        <Radio value={2}>Banuj użytkownika przy drugim wykroczeniu</Radio>
                        <Radio value={3}>Nie banuj użytowników</Radio>
                    </Space>
                </Radio.Group>
            </RelaxField>
            <RelaxField
                name="ban_reason"
            >
                <Radio.Group>
                    <Space direction="vertical">
                        <Radio value={1}>Banuj tylko za obraźliwe komentarze</Radio>
                        <Radio value={2}>Banuj za obraźliwe i negatywne komentarze</Radio>
                        <Radio value={3}>Banuj za reakcje 😡</Radio>
                    </Space>
                </Radio.Group>
            </RelaxField>


        </div>
    )


    return (
        <div>
            <RelaxField
                name="trigger_words"
            >
                <Checkbox>Use list of trigger words</Checkbox>
            </RelaxField>
            <RelaxField
                name="sentiment_detection"
            >
                <Checkbox>Use AI sentiment detection</Checkbox>
            </RelaxField>
            <RelaxField
                name="detection_rule"
                label="Detection rule"
            >
                <Radio.Group>
                    <Space direction="vertical">
                        <Radio value={1}>Remove offensive comments</Radio>
                        <Radio value={2}>Remove offensive and negative comments</Radio>
                    </Space>
                </Radio.Group>
            </RelaxField>
            <RelaxField
                name="ban_rule"
                label="Ban rule"
            >
                <Radio.Group>
                    <Space direction="vertical">
                        <Radio value={1}>Ban on first offense</Radio>
                        <Radio value={2}>Ban on second offense</Radio>
                    </Space>
                </Radio.Group>
            </RelaxField>
            <RelaxField
                name="ban_reason"
            >
                <Radio.Group>
                    <Space direction="vertical">
                        <Radio value={1}>Ban only for offensive comments</Radio>
                        <Radio value={2}>Ban for offensive and negative comments</Radio>
                    </Space>
                </Radio.Group>
            </RelaxField>
        </div>
    )

}


export default ModerationSettings;