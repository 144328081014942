import { isSubscriptionActive } from "state/subscriptions/subscriptionsHelpers";
import { Project, ProjectWithSubscriptions } from "./projectTypes";

export const mapSubscriptionsToProject = (project: Project, subscriptions: any):ProjectWithSubscriptions => {
    const projectSubscriptions = (project.subscriptions || []).map((subscriptionId: string) => subscriptions[subscriptionId]);
    return {
        ...project,
        subscriptions: projectSubscriptions
    };
}

export const projectHasProductSubscription = (project: ProjectWithSubscriptions, product: string):boolean => {
    return project.subscriptions.some(subscription => subscription.product === product);
}

export const isProjectActive = (project: ProjectWithSubscriptions):boolean => {
    return project.subscriptions.some(isSubscriptionActive);
}

//parsing projects to extract integrations and help with recursion
export const parseProjects = (projects:any[]) => {
    let parsedProjects = {};
    let parsedIntegrations = {
      facebook: {
        fanpages: {},
        adAccounts: {},
        pixels: {},
      },
      google: {
        analyticsAccounts: {},
        myBusinessPages: {},
        adAccounts: {},
      },
    };
  
    projects.forEach((project) => {
      if (project.service_facebook_page)
        parsedIntegrations.facebook.fanpages[project.service_facebook_page.id] =
          project.service_facebook_page;
      if (project.service_facebook_ads_account)
        parsedIntegrations.facebook.adAccounts[
          project.service_facebook_ads_account.id
        ] = project.service_facebook_ads_account;
      if (project.service_facebook_ads_account_pixels)
        parsedIntegrations.facebook.pixels[
          project.service_facebook_ads_account_pixels.id
        ] = project.service_facebook_ads_account_pixels;
      if (project.service_google_analytics)
        parsedIntegrations.google.analyticsAccounts[
          project.service_google_analytics.id
        ] = project.service_google_analytics;
      if (project.service_google_my_business)
        parsedIntegrations.google.myBusinessPages[
          project.service_google_my_business.id
        ] = project.service_google_my_business;
      if (project.service_google_ads)
        parsedIntegrations.google.adAccounts[project.service_google_ads.id] =
          project.service_google_ads;
  
      parsedProjects[project.id] = {
        ...project,
        employees: {},
        subscriptions: [1],
        company: 1,
      };
    });
  
    return {
      projects: parsedProjects,
      integrations: parsedIntegrations,
    };
  };
  
