import { useMemo } from "react";
import { useGallery } from "./Gallery";
import ImageGalleryElement from "./GalleryElement";

const TrackItem = (props:any) => {
    if (props?.item?.type === "image") {
        if (props?.item?.url) {
            return (
            <div className="track-item-wrapper" 
                onClick={() => props.onClick && props.onClick()}
            >
                <img
                    className={`track-item`}
                    src={props.item.url}
                />
            </div>
            )
        }
    }
    return null;
}

export default function ImageCropSideTrack() {

    const {galleryItems, selectedImages, requestAction} = useGallery();


    const mappedItems = useMemo(() => galleryItems?.filter((item) => {
        //console.log("filtering", item)
        return item.type === "image" && !item.crop; 
    }).map((item) => {
        return (
            <TrackItem 
                item={item}
                onClick={() => requestAction!(item, "crop")}
            />
        );
    }),[galleryItems, selectedImages])

    return (
        <div className="image-crop-side-track">
            <div className="items">
                {mappedItems}
            </div>
        </div>
    )
}