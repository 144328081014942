import { createSelector, createSlice } from "@reduxjs/toolkit";
import _ from 'lodash';
import { timeout } from "services/helpers/fake-asset-generators";
import { callAPIFunction, callAPIProps } from "services/hooks/useAPI";

export type InvoiceObjectState = {
  list: any
};

const initialState: InvoiceObjectState = {
  list: {}
}

export const InvoiceSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    get: (state, action) => {
      if (action.payload.data && action.payload.data.forEach) {
        action.payload.data.forEach((r: any, index: number) => {
          if (!r.id) {
            r.id = `draft${index + 1}`;
          }

          state.list[r.id] = r;
        })
      }
    },
    clearInvoices:(state, action) =>{
      return{
        list:{}
      }
    }
  }
})

export const SortPaymentHistoryByDate = (posts: any[], newestFirst: boolean) => posts && posts.sort((a: any, b: any) => {
  const aDate = new Date(a.invoice_created);
  const bDate = new Date(b.invoice_created);
  if (newestFirst) return bDate.getTime() - aDate.getTime();
  return aDate.getTime() - bDate.getTime();
})

export const GetPaymentHistory:callAPIFunction = (query: any): callAPIProps => {
  const call = {
    url: ({ getApiUrl, serializeQuery}: any) => getApiUrl(`user/history${query ? "?" + serializeQuery(query) : ""}`, 3),
    method: "GET",
    successDispatch: InvoiceSlice.actions.get,
    passToDispatcher: query,
  };

  if (import.meta.env.VITE_DEBUG_FAKE_POSTS === "true") {
    return {
        ...call,
        fakeResponse: async (request) => await timeout(500).then(() => getFakeInvoices(request))
    }
  }

  return call;

};


const getFakeInvoices = (request: any) => {
  let sq: any = {};

  if (request.url) {
    const q = request.url && request.url.split && request.url.split("?").length > 1 && request.url.split("?")[1].split("&");
    sq = q && q.reduce && q.reduce((acc: any, item: any) => {
      const [key, value] = item.split("=");
      acc[key] = value;
      return acc;
    }, {})
  }

  const allInvoicesInRange = generateFakeInvoices();

  return {
    status: 200,
    json: () => ({
      data: allInvoicesInRange
    })
  }
}

const generateFakeInvoice = (index: number) => {
  const statusOptions = ["draft", "paid", "pending", "canceled"];
  const currencyOptions = ["pln", "usd", "eur"];

  const randomStatus = statusOptions[Math.floor(Math.random() * statusOptions.length)];
  const randomCurrency = currencyOptions[Math.floor(Math.random() * currencyOptions.length)];

  const getRandomDate = (): string => {
    const startDate = new Date("2023-01-01").getTime();
    const endDate = new Date("2023-08-01").getTime();
    const randomTimestamp = Math.random() * (endDate - startDate) + startDate;
    const randomDate = new Date(randomTimestamp);

    const year = randomDate.getFullYear();
    const month = String(randomDate.getMonth() + 1).padStart(2, "0");
    const day = String(randomDate.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  return {
    attributes: {
      currency: randomCurrency,
      status: randomStatus,
      status_transitions: {
        finalized_at: null,
        marked_uncollectible_at: null,
        paid_at: null,
        voided_at: null
      },
      tax: 0,
      tax_percent: null,
      total: Math.floor(Math.random() * 951) + 50,
      subscription_name: "Fake Subscription",
      invoice_id: `${index + 1}`,
      invoice_number: `${index + 1}`,
      invoice_created: getRandomDate()
    },
    id: `fake_invoice_${index + 1}`,
    type: "invoice",

  };
};

const generateFakeInvoices = () => {
  const fakeInvoices = [];
  for (let i = 0; i < 200; i++) {
    fakeInvoices.push(generateFakeInvoice(i));
  }
  return fakeInvoices;
};

const invoiceData = {
  data: [
    {
      type: "invoice",
      attributes: {
        currency: "pln",
        status: "draft",
        status_transitions: {
          finalized_at: null,
          marked_uncollectible_at: null,
          paid_at: null,
          voided_at: null,
        },
        tax: 26.91,
        tax_percent: 23,
        total: 143.91,
        subscription_name: "smart monthly",
        invoice_id: null,
        invoice_number: null,
        invoice_created: null,
      },
      id: null,
    },
  ],
};


export const PaymentHistorySelector = (state: any) => Object.values(state.invoices.list);