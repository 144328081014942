import { faCalendar, faClock } from "@fortawesome/pro-light-svg-icons";
import { Tooltip } from "antd";
import AwesomeIcon from "components/common/AwesomeIcon";
import dayjs, { Dayjs } from "dayjs";
import { ReactNode, useMemo } from "react";

export type DateTimeDisplayProps = {
    dateTime: string | Date | undefined | null;
    showDate?: boolean;
    fullDate?: boolean;
    showTime?: boolean;
    showIcons?: boolean;
    style?: any;
    noWrapper?: boolean;
    fallback?: JSX.Element;
    dateformat?: string;
    timeformat?: string;
}


export default function DateTimeDisplay (props:DateTimeDisplayProps):JSX.Element | null {
    

    const dt = useMemo(() => {
        if (!props.dateTime) return null;
        return dayjs(props.dateTime);
    }, [props.dateTime])


    const displayTime = useMemo(() => {
        if (!dt) return null;

        let formattedDate:any = null;
        let formattedTime:any = null;

        const dateIcon = props.showIcons ? <AwesomeIcon icon={faCalendar} style={{paddingRight: 3}} /> : null;
        const timeIcon = props.showIcons ? <AwesomeIcon icon={faClock} style={{paddingRight: 3}}/> : null;


        try {
            formattedDate = props.fullDate ? dt.format(props.dateformat || "EEEE, dd MMMM yyyy") : dt.format(props.dateformat || "DD.MM.YY");
            formattedTime = dt.format(props.timeformat || "hh:mm")
        } catch (e) {
            console.log("Can't render dateTime", props.dateTime);
            return null;
        }

        if (props.showTime && props.showDate) 
            return (
            <>
            <div>
                <div>{dateIcon}{formattedDate}</div>
                <div>{timeIcon}{formattedTime}</div>
            </div>
            </>)
        if (props.showTime)
            return <div>{timeIcon}{formattedTime}</div>
        if (props.showDate)
            return <div>{dateIcon}{formattedDate}</div>
        return null;

    }, [dt, props.showDate, props.showIcons, props.showTime])

    const displayFallback = useMemo(() => {
        if (props.fallback) return props.fallback;
        return <div>{"-"}</div>
    }, [props.fallback])

    
    if (!displayTime && !displayFallback) return null;

    if (props.noWrapper) {
        if (displayTime) return displayTime;
        return displayFallback
    }

    if (displayTime) return (
        <div className="date-time-display" style={props.style}>
            {displayTime}
        </div>
    )
    
    return (
        <div className="date-time-display" style={props.style}>
            {displayFallback}
        </div>
    )
}


export const DateTimeDisplayWithTooltip = (props:DateTimeDisplayProps):JSX.Element => {
    return (
        <Tooltip
        title={<DateTimeDisplay showTime showDate dateTime={props.dateTime}/>}
        >
            <div>
            <DateTimeDisplay {...props} />
            </div>
        </Tooltip>
    )
}