import { Input, Typography } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import StandardButton from "components/common/StandardButton";
import {
  StandardFullColumn,
  StandardRow,
} from "components/layout/StandardGrid";
import { useState } from "react";
import useAPI, { APIErrorDisplay } from "services/hooks/useAPI";
import { EditProject, EditProjectRecursionFix } from "state/projects/projectEndpoints";
import { currentProjectSelector } from "state/projects/projectSelectors";
import RelaxField from "tools/relaxForm/RelaxField";

export const ProjectEdit = () => {
  const [newProjectData, setNewProjectData] = useState<Object>();
  const editProject = useAPI(EditProjectRecursionFix);

  return (
    <>
      <StandardRow>
        <StandardFullColumn>
          <Typography.Title level={3}>
            <FormattedMessage
              id="panel.components.project.form.settings"
              defaultMessage="Project settings"
            />
          </Typography.Title>
        </StandardFullColumn>
      </StandardRow>
      <StandardRow>
        <StandardFullColumn>
          <RelaxField
            name={"name"}
            initialValuesSelector={currentProjectSelector}
            submitOverride={(value: any) => {
              setNewProjectData(value);
            }}
            hideStatus={true}
            label={
              <FormattedMessage
                id="panel.components.project.form.name"
                defaultMessage="Project name"
              />
            }
            tooltip={
              <FormattedMessage
                id="panel.components.project.form.name.tooltip"
                defaultMessage="Project name"
              />
            }
          >
            <Input />
          </RelaxField>
          <APIErrorDisplay error={editProject.error} />
          <StandardButton
            onClick={() =>
              editProject.call({
                body: { ...newProjectData },
              })
            }
          >
            <FormattedMessage id="common.edit" defaultMessage="Edit" />
          </StandardButton>
        </StandardFullColumn>
      </StandardRow>
    </>
  );
};
