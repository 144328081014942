import _ from "lodash";
import useAPI, { callAPIProps, callAPISettings, getCallAPIProps } from "services/hooks/useAPI";
import useCallAfterUpdate from "services/hooks/useCallAfterUpdate";
import { useRef } from "react";

export default function useRelaxAPIHandler (rd: any) {

    const {
        settings, 
        currentValue,
    } = rd;

    const { loading, data, error, call: apiCall, clear } = useAPI(getCallAPIProps(rd.callAPI));
    const resendRequest = useRef<any>(null);

    const requestCall = useCallAfterUpdate(async (v) => {
        await submit();
        if (resendRequest.current) {
            requestCall(resendRequest.current);
            resendRequest.current = null;
        }

    })

    const submit = async () => {

        //console.log("trying to submit", settings.name, currentValue)

        clear();

        const dataToSend =  currentValue;
        let dataObject = (() => {
            if (settings.name) return _.set({}, settings.name, dataToSend);
            return dataToSend;
        })()


        if (settings.finalizeSubmit) {
            dataObject = settings.finalizeSubmit(dataObject, rd.initialValueObject);
            if (!dataObject) return;
        }

        let callProps: callAPIProps = settings.callAPI || {};

        if (settings.callAPI && typeof settings.callAPI === "function") {
            callProps = { ...callProps, ...settings.callAPI(false, { body: dataObject }) };
        } else {
            callProps = { ...callProps, body: dataObject }
        }

        if (settings.submitOverride) {
            settings.submitOverride(dataObject);
            return;
        }

        rd.onSend && rd.onSend(dataObject);

        let res;
        try {
            res = await apiCall(callProps).then((res) => {
                rd?.settings?.onSuccess && rd?.settings?.onSuccess(res, dataObject)
            });
        } catch (e) {
            console.log("Error while submitting form", e);
        } finally {
            console.log("res",res)
            if (settings.onFinish) settings.onFinish(res);

        }
        return res;
    }


    return {
        data, 
        loading, 
        error, 
        requestCall,
        clear,
        resendRequest
    }

}