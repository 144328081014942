export type provincesProps = {
    [country: string]: {
        label: string,
        value: string
    }[]
};

export const provinces:provincesProps = {
    
    "PL": [
        {
            label: "Województwo dolnośląskie",
            value: "dolnośląskie"
        },
        {
            label: "Województwo kujawsko-pomorskie",
            value: "kujawsko-pomorskie"
        },
        {
            label: "Województwo lubelskie",
            value: "lubelskie"
        },
        {
            label: "Województwo lubuskie",
            value: "lubuskie"
        },
        {
            label: "Województwo łódzkie",
            value: "łódzkie"
        },
        {
            label: "Województwo małopolskie",
            value: "małopolskie"
        },
        {
            label: "Województwo mazowieckie",
            value: "mazowieckie"
        },
        {
            label: "Województwo podkarpackie",
            value: "podkarpackie"
        },
        {
            label: "Województwo podlaskie",
            value: "podlaskie"
        },
        {
            label: "Województwo pomorskie",
            value: "pomorskie"
        },
        {
            label: "Województwo śląskie",
            value: "śląskie"
        },
        {
            label: "Województwo świętokrzyskie",
            value: "świętokrzyskie"
        },
        {
            label: "Województwo warmińsko-mazurskie",
            value: "warmińsko-mazurskie"
        },
        {
            label: "Województwo wielkopolskie",
            value: "wielkopolskie"
        },
        {
            label: "Województwo zachodniopomorskie",
            value: "zachodniopomorskie"
        }
    ],
    "DE": [
        {
            label: "Baden-Württemberg",
            value: "baden-württemberg"
        },
        {
            label: "Bayern",
            value: "bayern"
        },
        {
            label: "Berlin",
            value: "berlin"
        },
        {
            label: "Brandenburg",
            value: "brandenburg"
        },
        {
            label: "Bremen",
            value: "bremen"
        },
        {
            label: "Niedersachsen",
            value: "niedersachsen"
        },
        {
            label: "Hamburg",
            value: "hamburg"
        },
        {
            label: "Hessen",
            value: "hessen"
        },
        {
            label: "Mecklenburg-Vorpommern",
            value: "mecklenburg-vorpommern"
        },
        {
            label: "Nordrhein-Westfalen",
            value: "nordrhein-westfalen"
        },
        {
            label: "Rheinland-Pfalz",
            value: "rheinland-pfalz"
        },
        {
            label: "Saarland",
            value: "saarland"
        },
        {
            label: "achsen",
            value: "sachsen"
        },
        {
            label: "Sachsen-Anhalt",
            value: "sachsen-anhalt"
        },
        {
            label: "Schleswig-Holstein",
            value: "schleswig-holstein"
        },
        {
            label: "Thüringen",
            value: "thüringen"
        }
    ]
};