import { Input } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import StandardButton from "components/common/StandardButton";
import { useState } from "react";
import useAPI, { APIErrorDisplay, callAPIProps } from "services/hooks/useAPI";
import RelaxSingleField from "tools/relaxForm/RelaxField";

const changePassword:callAPIProps = {
    url: ({ getApiUrl, projectId }) => getApiUrl(`user/password-change`),
    method: "POST",
}

type Passwords = {
    oldPassword: string,
    newPassword: string,
    passwordConfirmation: string
}

export default function ChangePassword () {

    const changeCall = useAPI(changePassword);

    const [passwords, setPasswords] = useState<Passwords>({
        newPassword: "",
        passwordConfirmation: "",
        oldPassword: ""
    });

    const passwordsMatch = passwords.newPassword === passwords.passwordConfirmation;
    const err = passwordsMatch ? null : ["Passwords don't match"];

    const handleSubmit = () => {
        changeCall.call({
            body: {
                password: passwords.newPassword,
                password_confirmation: passwords.passwordConfirmation,
                old_password: passwords.oldPassword
            }
        })
    }

    return (
        <>
            <RelaxSingleField
                name="oldPassword"
                label={<FormattedMessage
                    id="panel.containers.user.changePassword.oldPassword"
                    defaultMessage="Old password"
                />}
                onChange={(e:any) => setPasswords((np:Passwords) => ({...np, oldPassword: e}))}
                >
                    <Input.Password />
            </RelaxSingleField>
            <RelaxSingleField
                name="newPassword"
                label={<FormattedMessage
                    id="panel.containers.user.changePassword.newPassword"
                    defaultMessage="New password"
                />}
                rules={[{type: "password"}]}
                onChange={(e:any) => setPasswords((np:Passwords) => ({...np, newPassword: e}))}
                extraErrors={err}
                >
                    <Input.Password />
            </RelaxSingleField>
            <RelaxSingleField
                name="passwordConfirmation"
                label={<FormattedMessage
                    id="panel.containers.user.changePassword.passwordConfirmation"
                    defaultMessage="New password"
                />}
                rules={[{type: "password"}]}
                onChange={(e:any) => setPasswords((np:Passwords) => ({...np, passwordConfirmation: e}))}
                extraErrors={err}
                >
                    <Input.Password />
            </RelaxSingleField>
            <APIErrorDisplay error={changeCall?.error?.error} />
            <StandardButton type="primary" onClick={handleSubmit}>
                <FormattedMessage
                        id="panel.containers.user.changePassword.requestBtn"
                        defaultMessage="Change password"
                    />
            </StandardButton>
        </>
    )
}