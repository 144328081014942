import { createSelector, createSlice } from "@reduxjs/toolkit";
import { callAPIProps } from "services/hooks/useAPI";
import { currentProjectIdSelector } from "services/store/scopeSlice";
import { userSettingsInterfaceTasksListSelector } from "state/user/userSettingsSlice";
import { creationsSlice } from "tools/creationEditor/creationsSlice";

export type TasksSliceState = {
    tasks: any,
}

export type TaskListFilter = {
    showPostRequest?: boolean,
    showDataUpdate?: boolean,
    statusFilter?: {
        [key: string]: boolean
    }
}

const initialState: TasksSliceState = {
    tasks: {},
}


const addOrUpdateTask = (state: any, task: any) => {
    const id = task?.id;
    if (!id) return;
    state.tasks[id] = task;
}

const addOrUpdateTasks = (state: any, tasks: any[]) => {
    tasks.forEach((task: any) => {
        addOrUpdateTask(state, task);
    })
}

export const tasksSlice = createSlice({
    name: "tasks",
    initialState,
    reducers: {
        getUserTasks: (state, action) => {
            addOrUpdateTasks(state, action.payload.data)
        },
        getProjectTasks: (state, action) => {
            addOrUpdateTasks(state, action.payload.data)
        },
        getTask: (state, action) => {

        },
        createTask: (state, action) => {
            addOrUpdateTask(state, action.payload.data)
        },
        updateTask: (state, action) => {
            addOrUpdateTask(state, action.payload.data)
        },
        acceptTask: (state, action) => {
            addOrUpdateTask(state, action.payload.data)
        },
        rejectTask: (state, action) => {
            addOrUpdateTask(state, action.payload.data)
        },
        unpublishTask: (state, action) => {
            addOrUpdateTask(state, action.payload.data)
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(creationsSlice.actions.requestPublication, (state, action) => {
                if (action?.payload?.included) {
                    action?.payload?.included.forEach((i: any) => {
                        if (i && i._type === "tasks") addOrUpdateTask(state, i)
                    })
                }
            })
    }
})

export const getUserTasks: callAPIProps = {
    url: ({ getApiUrl, userId }) => getApiUrl(`user/tasks`),
    method: "GET",
    successDispatch: tasksSlice.actions.getUserTasks,
}

export const getProjectTasks: callAPIProps = {
    url: ({ getApiUrl, projectId }: any) => getApiUrl(`projects/${projectId}/tasks`),
    method: "GET",
    successDispatch: tasksSlice.actions.getProjectTasks,
}

export const CreateTask: callAPIProps = {
    url: ({ getApiUrl }: any) => getApiUrl(`tasks`),
    method: "POST",
    successDispatch: tasksSlice.actions.createTask,
}

export const PatchTask = (taskId: string): callAPIProps => ({
    url: ({ getApiUrl }: any) => getApiUrl(`tasks/${taskId}`),
    method: "PATCH",
    successDispatch: tasksSlice.actions.updateTask,
})

export const AcceptTask = (taskId: string): callAPIProps => ({
    url: ({ getApiUrl }: any) => getApiUrl(`tasks/${taskId}/accept`),
    method: "POST",
    successDispatch: tasksSlice.actions.acceptTask,
})

export const RejectTask = (taskId: string): callAPIProps => ({
    url: ({ getApiUrl }: any) => getApiUrl(`tasks/${taskId}/reject`),
    method: "POST",
    successDispatch: tasksSlice.actions.rejectTask,
})

export const UnpublishTask = (taskId: string): callAPIProps => ({
    url: ({ getApiUrl }: any) => getApiUrl(`tasks/${taskId}/unpublish`),
    method: "POST",
    successDispatch: tasksSlice.actions.unpublishTask,
})

export const FilterTasks = (task: any, filter: any) => {
    if (!filter) return true;

    if (task) {
        const status = task.status;

        if (status === "pending_acceptation") {
            if (filter.statusFilter.pendingAcceptation !== true) return false;
        }

        if (status === "accepted") {
            if (filter.statusFilter.accepted !== true) return false;
        }

        if (status === "rejected") {
            if (filter.statusFilter.rejected !== true) return false;
        }

        if (status === "returned_for_correction") {
            if (filter.statusFilter.returnedForCorrection !== true) return false;
        }

        if (status === "completed") {
            if (filter.statusFilter.completed !== true) return false;
        }

        if (status === "scheduled") {
            if (filter.statusFilter.scheduled !== true) return false;
        }
    }

    if (filter.showPostRequest === false && task.type === "post_request") return false;
    if (filter.showDataUpdate === false && task.type === "data_update") return false;

    return true;
}


export const TasksSelector = createSelector([
    (state: any) => state.tasks,
], (tasks) => tasks.tasks)

export const UserTasksSelector = createSelector([
    TasksSelector,
], (tasks) => tasks.userTasks)

export const ProjectTasksSelector = createSelector([
    TasksSelector,
    currentProjectIdSelector
], (tasks, projectId) => {
    if (!projectId) return []
    if (!tasks || !tasks.length) return [];
    return tasks.filter((task: any) => {
        if (task.source_type === "project" && task.source_id === projectId) return true;
        if (task.target_type === "project" && task.target_id === projectId) return true;
        return false;
    })
})

export const TaskSelector = createSelector([
    TasksSelector,
    (state: any, taskId: string) => taskId
], (tasks, taskId) => {
    return tasks[taskId] || null;
})

export const TasksArraySelector = createSelector([
    TasksSelector,
], (tasks) => {
    return Object.values(tasks) || [];
})

export const filteredTasksCountSelector = createSelector([
    TasksArraySelector,
    userSettingsInterfaceTasksListSelector,
],
(tasks, filters) => {
    const filteredTasks = tasks.filter((task: any) => {
        return FilterTasks(task, filters)
    });
    return tasks.length - filteredTasks.length;
})