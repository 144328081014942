import { AppConfig } from "modules";
import routes, { APP_PATH } from "modules/call/config/routes";
import requirements from "modules/call/config/requirements";

export default {
    key: "SmartCall",
    path: APP_PATH,
    routes,
    requirements,
} as AppConfig;
