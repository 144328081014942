import { Badge } from "antd"

export default function DetailsSection(props: any) {

    if (props.ribbon) {
        return (
            <Badge.Ribbon 
            className="details-box-ribbon" 
            text={props.ribbon} 
            color={props.ribbonColor || "blue"}>
                <div className="post-card" style={props.style} onClick={props.onClick}>
                    {props.children}
                </div>
            </Badge.Ribbon>
        )
    } else {
        return (
            <div className="post-card" style={props.style} onClick={props.onClick}>
                {props.children}
            </div>
        )
    }

}
