import { applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { apiMiddleware } from "redux-api-middleware";

import addUserTokenToRequest from "services/store/middlewares/api/authorization-middleware";
import addProjectIdToRequest from "services/store/middlewares/api/project-middleware";
import keepRequestStatusUnderKey from "services/store/middlewares/api/request-status-under-key-middleware";
import attachNotificationsToResponse from "services/store/middlewares/api/attach-notifications-middleware";
import normalizeJsonApiResponse from "services/store/middlewares/api/json-api-normalizer/json-api-normalizer-middleware";

import handleUnauthorizedApiCallMiddleware from "services/store/middlewares/errors/handle-unauthorized-middleware";
import handleGoogleTokenErrorMiddleware from "services/store/middlewares/errors/handle-google-token-error-middleware";
import handleFacebookTokenErrorMiddleware from "services/store/middlewares/errors/handle-facebook-token-error-middleware";


export default applyMiddleware(
  thunk,
  addUserTokenToRequest,
  addProjectIdToRequest,
  keepRequestStatusUnderKey,
  attachNotificationsToResponse,
  normalizeJsonApiResponse,
  apiMiddleware,
  handleUnauthorizedApiCallMiddleware,
  handleGoogleTokenErrorMiddleware,
  handleFacebookTokenErrorMiddleware,
  ({getState, dispatch}) => {
    //Run once, on load
    return (next) => (action) => {
      //Run when store updates
      next(action);
    };
  }
);

export const middlewareArray = [
  thunk,
  addUserTokenToRequest,
  addProjectIdToRequest,
  keepRequestStatusUnderKey,
  attachNotificationsToResponse,
  normalizeJsonApiResponse,
  apiMiddleware,
  handleUnauthorizedApiCallMiddleware,
  handleGoogleTokenErrorMiddleware,
  handleFacebookTokenErrorMiddleware,
  // ({getState, dispatch}:any) => {
  //   //Run once, on load
  //   return (next:any) => (action:any) => {
  //     //Run when store updates
  //     next(action);
  //   };
  // }
]
