import useAPI from "services/hooks/useAPI";
import { IntegrationSelect } from "./IntegrationSelect"
import { props } from "_components/onboarding/IndustryCategoryCheckboxSelection";

export const FreshMailCampaignSelect = ({...rest}) =>{
    const { call, data, loading } = useAPI(
        {
          url: ({ getApiUrl, projectId }) =>
            getApiUrl(`projects/${projectId}/lead-bot/config/fresh-mail`),
          method: "POST",
          customNormalizer: (data: any) => {
            return data?.data?.attributes?.subsciber_lists || [];
          }
        },
        true
      );
    return(
        <IntegrationSelect data={data} call={call} {...rest}/>
    )
}