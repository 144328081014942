import { Alert, Modal, Space } from "antd";
import StandardButton from "components/common/StandardButton";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import useAPI, { APIErrorDisplay, callAPIProps } from "services/hooks/useAPI";
import useScope from "services/hooks/useScope";

const validateInvitation = (code: string): callAPIProps => ({
    url: ({ getApiUrl }) => getApiUrl(`organization/invitations/${code}`, 1),
})

const acceptInvitation = (code: string): callAPIProps => ({
    url: ({ getApiUrl }) => getApiUrl(`organization/invitations/${code}/accept`, 1),
    method: "POST",
    dispatchName: "INVITATION_ACCEPT",
})


export const OrganizationInvitationModal = () => {
    const validateInvitationCall = useAPI(validateInvitation(""));
    const acceptInvitationCall = useAPI(validateInvitation(""));
    const scope = useScope();
    const [isOpen, setIsOpen] = useState(true);

    const [invitationCode, setInvitationCode] = useState<string | null>(localStorage.getItem("invitation_code"))

    const hasCode = invitationCode && invitationCode.length > 0;

    useEffect(() => {

        hasCode && validateInvitationCall.call(validateInvitation(invitationCode))

    }, []);

    const handleRejectInvitation = () => {
        setIsOpen(false);
        localStorage.removeItem("invitation_code");
    }

    const handleAcceptInvitation = () => {
        acceptInvitationCall.call(acceptInvitation(invitationCode || "")).then((res: any) => {
            if (res && res.status === 200 && res.data) {
                scope.openProject(String(res.data.id))
                location.reload();
            }

        })
    }

    const modalContent = useMemo(() => {

        if (validateInvitationCall.error) {
            return (
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                    Invitation code found but could not be verified.
                    <div>
                    <APIErrorDisplay error={validateInvitationCall.error} />
                    </div>
                </div>
            )
        }

        if (validateInvitationCall.data) {

            const { organization } = validateInvitationCall.data;
            const validTime = dayjs(validateInvitationCall.data.valid_time);
            const isValid = validTime.isAfter(dayjs());
    

            return (
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                    You have been invited to join:

                    <h3 style={{
                        padding: "20px"
                    }}>{organization.name}</h3>
                    <div>Valid until: {validTime.format("DD MMMM YYYY")}</div>

                    {!isValid &&
                        <Alert
                            type="error"
                            message="This invitation is no longer valid"
                        />
                    }
                </div>
            )
        }

        return null;

    }, [validateInvitationCall])

    const buttons = useMemo(() => {

        if (validateInvitationCall.data) {

        const { organization } = validateInvitationCall.data;
        const validTime = dayjs(validateInvitationCall.data.valid_time);
        const isValid = validTime.isAfter(dayjs());

        return (
            <Space wrap >
                <StandardButton onClick={handleRejectInvitation}>Reject invitation</StandardButton>
                <StandardButton type="primary" onClick={handleAcceptInvitation} disabled={!isValid}>Accept invitation</StandardButton>
            </Space>
        )

        }

        return (
            <Space wrap >
                <StandardButton onClick={handleRejectInvitation}>Cancel</StandardButton>
            </Space>
        )

    }, [validateInvitationCall.data])


    return (<Modal
        title="Invitation Code"
        open={isOpen}
        footer={buttons}
        onCancel={() => setIsOpen(false)}
    >
        {modalContent}
    </Modal>)
}