import { Spin } from "antd";
import React, { Suspense } from "react";
const PostTextEditor = React.lazy(() => import("tools/textEditor/PostTextEditorNew"));

export default function PostTextEditorWithSuspense (props:any) {

    return (
        <Suspense fallback={<Spin />}>
            <PostTextEditor {...props} />
        </Suspense>
    )

}