import NipInput from "_components/forms/masked-inputs/nip-input";
import { Alert, Radio } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { timeout } from "services/helpers/fake-asset-generators";
import useAPI from "services/hooks/useAPI"
import RelaxField from "tools/relaxForm/RelaxField";

type Props = {
    data:{},
    onChange: (data:any) => void
    onSuccess: (data:any) => void
    onError: () => void
}

export const CompanyDataWithExternalDownload = (props:Props) => {
    const [loading,setLoading] = useState(false);
    const vatComapnyDataSuggest = useAPI({ 

        // fakeResponse: async (request) => await timeout(500).then(() => ({
        //     status: 200,
        //     json: () => ({
        //         data: {
        //             "id": "5881950329",
        //             "type": "ceidg-data",
        //             "attributes": {
        //                 "company_name": "Agencja Ma & Ma DANIEL KĘDZIERSKI",
        //                 "company_begin_date": "2003-10-01",
        //                 "country": "Polska",
        //                 "nip": "5881950329",
        //                 "regon": "192950184",
        //                 "address": "ul. Toruńska 5",
        //                 "street": "ul. Toruńska",
        //                 "street_number": "5",
        //                 "street_apartment": "",
        //                 "zip_code": "84-230",
        //                 "city": "Rumia",
        //                 "voivodeship": "POMORSKIE"
        //             }
        //         }
        //     })
        // }))
    })

    const [showError, setShowError] = useState(false)

    const handleVatSuggest = (vat:any) => {
        setShowError(false)
        setLoading(true)
        vatComapnyDataSuggest.call({
            url: ({ getApiUrl }) => getApiUrl(`info/ceidg/company/${vat}`, 1),
            method: 'GET',
            dispatchName: 'getDetailsFromCEIDG',
            customNormalizer:(data:any) => data
            
        }).then((res) => {
            if (res.status === 200) {
                props.onSuccess && props.onSuccess(res.data)
                setLoading(false)
            } else {
                setShowError(true)
                props.onError && props.onError()
                setLoading(false)
            }
        }).catch(() => {
            setShowError(true);
            props.onError && props.onError();
            setLoading(false)
        });
    }


    return (
        <>

            <RelaxField
                name="vat_number"
                label={
                    <FormattedMessage
                        id="panel.containers.companySettings.vatNumber"
                        defaultMessage="VAT number"
                    />
                }
                onChange={(value:any) => props.onChange && props.onChange(value)}

                submitOverride={(value:any) =>{
                    handleVatSuggest(value.vat_number)
                }}
                initialValues={props.data}>
                <NipInput />
            </RelaxField>

            {loading ? vatComapnyDataSuggest.StatusDisplay : null}

            {showError && (
                    <Alert
                        message={
                            <FormattedMessage
                                id="apps.panel.containers.onboarding.companyData.couldNotGetYourData"
                                defaultMessage="We're sorry but we could not get your data from CEIDG."
                            />
                        }
                        description={
                            <FormattedMessage
                                id="apps.panel.containers.onboarding.companyData.couldNotGetYourData"
                                defaultMessage="You will have to input your company data by yourself."
                            />
                        }
                        type="warning"
                        showIcon
                        style={{ marginBottom: "30px" }}
                    />
                )}

        </>
    )
}