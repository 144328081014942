import { StandardHalfFullColumn, StandardRow } from "components/layout/StandardGrid";
import PurchaseSubscription, { SubscriptionPurchaseInitialOptions, usePurchaseSubscription } from "components/purchases/subscriptionPurchase/PurchaseSubscription";
import { SubscriptionOfferDisplayInPortal } from "components/purchases/subscriptionPurchase/SubscriptionOfferDisplay";
import { DASHBOARD, getUrl } from "modules/panel/config/routes";
import { useOnboarding } from "providers/onboarding/onboarding-context";
import ProductLogo from "providers/routing/layouts/components/product-logo/product-logo";
import { getProductLogo } from "providers/routing/layouts/components/product-logo/smallProductLogo";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useLocation, useParams } from "react-router";
import { Redirect, useHistory } from "react-router-dom";
import useScope from "services/hooks/useScope";
import { loadSubscriptionPurchaseInitialOptions } from "state/subscriptions/subscriptionsHelpers";

//PURCHASE SUBSCRIPTION for existing project

const EditLayoutLogoOverride = () => {

    const [target, setTarget] = useState<HTMLElement | null>(null);
    const {product } = usePurchaseSubscription();
    
    useEffect(() => {
        const t = document.querySelector('.edit-page-layout > .ant-layout-header > .container > .left-side');
        if (t) setTarget(t);
    }, []);


    if (!target) return null;
    if (!product) return null;
    return (
        <>
            {createPortal(<ProductLogo logo={getProductLogo(product)} id="purchase-logo"/>, target)}
        </>
    )

    // const target = document.getElementById('layout-edit-logo');
    // console.log("target", target)

    // if (!target) return null;
    // return createPortal(<div>Hello</div>, target)

}

function BuyNewSubscription() {

    const { product, projectId } = useParams<{
        product: string;
        projectId: string;
    }>();

    const history = useHistory();
    const scope = useScope();
    const [initialPurchaseOptions, setInitialPurchaseOptions] = useState<SubscriptionPurchaseInitialOptions>(loadSubscriptionPurchaseInitialOptions({ projectId, product }));

    const handlePaymentSuccess = (data: any) => {
        const subscriptionId = data?.subscription?.id;
        scope.openSubscription(subscriptionId)
        history.push(getUrl(DASHBOARD))
    }

    return (
        <StandardRow>
            <StandardHalfFullColumn>
                <PurchaseSubscription
                    onComplete={handlePaymentSuccess}
                    initialPurchaseOptions={initialPurchaseOptions}
                >
                    <SubscriptionOfferDisplayInPortal 
                        summaryClassName="black-text"
                    />
                    <EditLayoutLogoOverride />
                </PurchaseSubscription>
            </StandardHalfFullColumn>
            <StandardHalfFullColumn>
                <div id="payment-sidebar" className="standard-border with-background payment-sidebar"> </div>
            </StandardHalfFullColumn>
        </StandardRow>
    );
}


const GreedyOnboardingWorkaround = () => {
    
    const onboarding = useOnboarding();

    if (!onboarding.fulfilled) return (<Redirect to="panel/onboarding/payment" />)
    return <BuyNewSubscription />

}

export default GreedyOnboardingWorkaround;