
import { createSelector } from "@reduxjs/toolkit"
import dayjs from "dayjs"
import { getThreads, sendMessage, Thread, threadByIdSelector, ThreadMessage, threadsSelector } from "modules/bot/state/threadsSlice"
import { useEffect } from "react"
import useAPI, { callAPIFunction, callAPIProps } from "services/hooks/useAPI"
import useSelectorWithParams from "services/hooks/useSelectorWithParams"
import Chat, { Conversation, Message } from "tools/chat/Chat"

const adjustMessage = (messages: ThreadMessage):Message => {

    return {
        message: messages.message,
        author: messages.from.name,
        date: dayjs(messages.created_time)
    }

}

const threadToConversation = (thread: Thread):Conversation => {

    const messages = thread.messages ? thread.messages.map(adjustMessage).sort((a, b) => a.date.isBefore(b.date) ? -1 : 1) : []
    const lastMessage = messages[messages.length - 1]

    return {
        id: thread.id,
        messages: messages,
        conversation_with: thread.participants[0].name,
        lastMessage: lastMessage.message,
        lastMessage_at: lastMessage.date.format("L LT"),
        speaker: thread.participants[1].name,
    }

}

const threadsAsListOfConversationsSelector = createSelector(
    threadsSelector,
    (threads) => Object.values(threads).map(threadToConversation).sort((a, b) => a.lastMessage_at < b.lastMessage_at ? 1 : -1)
)
const threadByIdAsConversationSelector = createSelector(
    threadByIdSelector,
    (thread) => {
    if (!thread) return null;
    if (!thread.messages) return null;
    let conv = threadToConversation(thread);
    return conv;

})



const PageChat = () => {

    const {} = useAPI(getThreads, true)

    return (
        <Chat 
            conversationsSelector={threadsAsListOfConversationsSelector}
            conversationSelector={threadByIdAsConversationSelector}
            sendMessage={sendMessage}
        />
    )

}

export default PageChat;