import { Alert, Col, Input, Row } from "antd";
import StandardButton from "components/common/StandardButton";
import { useState } from "react";
import useAPI from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { ProjectSettingsSelector, UpdateProjectSettings } from "state/project/projectSettingsSlice";
import RelaxField from "tools/relaxForm/RelaxField";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";

const GoogleConfigAddFound = () => {

    const [sent, setSent] = useState<boolean>(false)

    const fundsCall = useAPI({})
    const settings = useSelectorWithParams(ProjectSettingsSelector)
    const funds = settings?.funds;

    const handleAddFound = () => {
        setSent(true)
        fundsCall.call({...UpdateProjectSettings, 
                body: {
                    settings: {
                        funds: [...funds || [], Math.floor(Math.random() * 100000)]
                    }
                }
            })
    }

    return (
        <div>
            <Row gutter={30}>
                <Col sm={24} md={12}>
                    <RelaxMultiform
                        disableSubmit
                        hideStatus={true}
                    >
                        <Row>
                            <RelaxField
                                label="Credit Card Number"
                                name="cardNumber"
                                style={{width: "100%"}}
                            >
                                <Input 
                                    placeholder="0000 0000 0000 0000"
                                />
                            </RelaxField>
                        </Row>
                        <Row gutter={30}>
                            <Col sm={12}>
                                <RelaxField
                                    label="ExpirationData"
                                    name="expirationData"
                                >
                                    <Input 
                                        placeholder="MM/YY"
                                    />
                                </RelaxField>
                            </Col>
                            <Col sm={12}>
                                <RelaxField
                                    label="CVC"
                                    name="CVC"
                                >
                                    <Input 
                                        placeholder="CVC"
                                    />
                                </RelaxField>
                            </Col>
                        </Row>
                    </RelaxMultiform>
                    <Row gutter={30}>
                        <Col sm={12}>
                        <RelaxField
                            label="Amount"
                            name="amount"
                        >
                            <Input 
                                placeholder="Amount"
                            />
                        </RelaxField>
                        </Col>
                       <Col sm={12}>
                            <StandardButton style={{
                                marginTop: "25px"
                            }}
                            type="primary"
                            onClick={handleAddFound}
                            >
                                Add funds
                            </StandardButton>
                        </Col>
                    </Row>
                {sent && (
                    <Alert message="Request to add funds was sent." type="success" showIcon />
                )}
                </Col>
            </Row>
        </div>
    )
}

export default GoogleConfigAddFound;