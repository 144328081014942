
export function generateFakeResponse(code: number, data: any) {
    return {
        status: code,
        json: () => ({
            data: data
        })
    }
}

export function timeout(ms:number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const randomFromArray = (arr: any[]) => arr[Math.floor(Math.random() * arr.length)];

//pick n number of items from an array, no duplicates
export const pickNFromArray = (arr: any[], n: number) => {
    const a = [...arr]
    const picked = [];
    for (let i = 0; i < n; i++) {
        const randomIndex = Math.floor(Math.random() * a.length);
        picked.push(a[randomIndex]);
        a.splice(randomIndex, 1);
    }
    return picked;
}
