import { RSAA } from "redux-api-middleware";

function attachNotificationsToResponse() {
  return (next) => (action) => {
    const rsaaAction = action?.[RSAA];
    if (
      rsaaAction &&
      rsaaAction.types[1] &&
      !rsaaAction.types[1].meta &&
      typeof rsaaAction.types[1] === "object"
    ) {
      action[RSAA].types[1]["payload"] = payloadsWithIncludes(
        action[RSAA].types[1]["payload"]
      );
    }

    next(action);
  };
}

const payloadsWithIncludes = (fn) => {
  return (action, state, response) => {
    const contentType = response.headers.get("content-type");
    if (contentType === "application/json") {
      const original = response.clone().json();

      return fn(action, state, response).then((res) => {
        res.included = original.then((ref) => ref.included);
        return res;
      });
    } else {
      return fn(action, state, response).then((res) => res);
    }
  };
};

export default attachNotificationsToResponse;
