import { Tag } from "antd"
import { ReactNode } from "react";

type TagButtonProps = {
    className?: string;
    children?: ReactNode;
    color?:string;
    onClick?:() => void
}

export const TagButton = (props?: TagButtonProps) => {

    const {className, ...rest} = props || {};

    return (
        <button className={`clean-button ${className ? className : ""}`}>
            <Tag {...rest}>
                {props?.children}
            </Tag>
        </button>
    )

}
