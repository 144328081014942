import {Map} from "immutable";
import {setRequestStatusReducer} from "services/helpers/api-helpers";
import {CLEAR_ACTIONS_STATUSES} from "modules/panel/actions/request-status";

const successReducer = (state = Map({}), action) => {
    const { type } = action;

    if (type === CLEAR_ACTIONS_STATUSES) return state.deleteAll(action.actions);

    const matches = /(.*)_(REQUEST|SUCCESS)/.exec(type);
    if (!matches) return state;

    const [, requestName, requestState] = matches;

    return setRequestStatusReducer(state, requestName, requestState === 'SUCCESS', action.meta)
};

export default successReducer;