import { Space } from "antd"
import FormattedMessage from "components/common/FormattedMessage"
import _ from "lodash"
import { StandardModalAction } from "services/hooks/useModal"
import useSelectorWithParams from "services/hooks/useSelectorWithParams"
import OrganizationTableProjects from "./components/OrganizationTableProjects"
import OrganizationTools from "./components/OrgannizationTools"
import { NewGroup, OrganizationProjectsSelector, PatchGroup } from "./data/organizationSlice"
import { toolsGroup, useOrganizationTool } from "./OrganizationManager"
import { OrganizationToolEditGoogleMyBusiness, OrganizationToolAddProjectsToGroup, OrganizationToolNewGroupWithProjects } from "./tools/OrganizationToolForms"

const projectGroupTools: toolsGroup = {
    key: "projectGroupTools",
    label: "Group",
    tools: [
        {
            key: "addToGroup",
            label: <FormattedMessage
                id="organization.tool.addProjectsToGroup.label"
                defaultMessage="Add to group" />,
            title: <FormattedMessage
                id="organization.tool.addProjectsToGroup.title"
                defaultMessage="Add selected projects to a group" />,
            component: <OrganizationToolAddProjectsToGroup />,
            disabled: (inputData: any) => !inputData || !inputData.projects || inputData.projects.length === 0,
            toolAPICall: (inputData: any, outputData: any) => {

                const group = outputData.group;
                const projects = _.union(inputData.projects, group.projects.map((p: any) => p.id));

                return {
                    ...PatchGroup(group.id),
                    body: {
                        projects: projects
                    }
                }
            }
        },
        {
            key: "newGroupFrom",
            label: <FormattedMessage
                id="organization.tool.newGroupFrom.label"
                defaultMessage="New group from" />,
            title: <FormattedMessage
                id="organization.tool.newGroupFrom.title"
                defaultMessage="Create a new group from selected projects" />,
            component: <OrganizationToolNewGroupWithProjects />,
            disabled: (inputData: any) => !inputData || !inputData.projects || inputData.projects.length === 0,
            toolAPICall: (inputData: any, outputData: any) => {
                return {
                    ...NewGroup(),
                    body: {
                        projects: inputData.projects,
                        name: outputData.name
                    }
                }
            }
        },
        {
            key: "editGoogleMyBusiness",
            label: <FormattedMessage
                id="organization.tool.editGoogleMyBusiness.label"
                defaultMessage="Edit Google My Business" />,
            title: <FormattedMessage
                id="organization.tool.editGoogleMyBusiness.title"
                defaultMessage="Edit Google My Business" />,
            component: <OrganizationToolEditGoogleMyBusiness />,
            disabled: (inputData: any) => !inputData || !inputData.projects || inputData.projects.length === 0,
        }
    ]
}


export default function OrganizationProjectsTab(props: any) {
    return (
        <div style={{
            height: "100%",
            overflow: "hidden",
        }}>
            <OrganizationTools toolset={projectGroupTools} />
            <OrganizationTableProjects />
        </div>
    )
}