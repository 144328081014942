import { Alert, Col, Input, Row, Select } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import RelaxField from "tools/relaxForm/RelaxField";
import ColorPicker from "components/inputs/ColorPicker";
import FontSelect from "_components/forms/FontSelect";

const TemplateCustomizationTools = () => {

    return (
        <>
            <Row gutter={30}>
                <Col xs={24} sm={12}>
                    <RelaxField
                        label={<FormattedMessage
                            id="apps.websites.forms.website.mainColor"
                            defaultMessage="Main color"
                        />}
                        name="color_main"
                    >
                        <ColorPicker
                            placeholder="#D01E36"
                        />
                    </RelaxField>
                </Col>
                <Col xs={24} sm={12}>
                    <RelaxField
                        name="color_alternative"
                        label={<FormattedMessage
                            id="apps.websites.forms.website.alternativeColor"
                            defaultMessage="Alternative color"
                        />}
                    >
                        <ColorPicker
                            placeholder="#D01E36"
                        />
                    </RelaxField>
                </Col>
            </Row>
            <Row gutter={30}>
                <Col xs={24} sm={12}>
                    <RelaxField
                        name="color_font"
                        label={<FormattedMessage
                            id="apps.websites.forms.website.textColor"
                            defaultMessage="Text color"
                        />}
                    >
                        <ColorPicker
                            placeholder="#D01E36"
                        />
                    </RelaxField>
                </Col>
                <Col xs={24} sm={12}>
                    <RelaxField
                        name="color_background"
                        label={<FormattedMessage
                            id="apps.websites.forms.website.backgroundColor"
                            defaultMessage="Background color"
                        />}
                    >
                        <ColorPicker
                            placeholder="#D01E36"
                        />
                    </RelaxField>
                </Col>
            </Row>
            <Row gutter={30}>
                <Col xs={24} sm={12}>
                    <RelaxField
                        name="font_header"
                        label={<FormattedMessage
                            id="apps.websites.forms.website.fontHeader"
                            defaultMessage="Heading font"
                        />}
                    >
                        <FontSelect type={""} templateId={0} />
                    </RelaxField>
                </Col>
                <Col xs={24} sm={12}>
                    <RelaxField
                        name="font_text"
                        label={<FormattedMessage
                            id="apps.websites.forms.website.fontText"
                            defaultMessage="Paragraphs font"
                        />}
                    >
                        <FontSelect type={""} templateId={0} />
                    </RelaxField>
                </Col>
            </Row>
        </>
    )
}

export default TemplateCustomizationTools;