import { RouteConfig } from "providers/routing/routes/routes";
import PanelLayoutConfig, {
  PanelPageLayoutRoute,
} from "providers/routing/layouts/templates/panel/panel";
import TwoColumnsLayoutConfig, {
  TwoColumnsLayoutRouteConfig,
} from "providers/routing/layouts/templates/two-columns/two-columns";
import EditLayoutConfig, {
  EditLayoutRouteConfig,
} from "providers/routing/layouts/templates/edit/edit";
import BlankLayoutConfig, {
  BlankLayoutRouteConfig,
} from "providers/routing/layouts/templates/blank";

export type LayoutRouteConfig =
  | RouteConfig
  | PanelPageLayoutRoute
  | TwoColumnsLayoutRouteConfig
  | EditLayoutRouteConfig
  | BlankLayoutRouteConfig;

export default [
  PanelLayoutConfig,
  TwoColumnsLayoutConfig,
  EditLayoutConfig,
  BlankLayoutConfig,
];
