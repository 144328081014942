import { Col, Collapse, Input, Row, Space } from "antd";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import { OrganizationProjectSelector } from "../data/organizationSlice";
import CompanyCategorySelect from "modules/forsant/components/business-details/company-search-select";
import { ReactNode, useEffect, useMemo } from "react";
import PhoneNumberInputWithCountrySelect from "_components/forms/PhoneNumberInputWithCountrySelect";
import OpeningHours from "_components/forms/opening-hours";
import useTaskMiddleware from "tools/tasks/useTaskMiddleware";
import { useAssetDrawer } from "providers/assetDrawer/StandardAssetDrawer";
import useOrganization from "../tools/useOrganization";
import { ListOfProjects, ListOfProjectsAsTags } from "../tools/OrganizationToolHelpers";
import FormattedMessage from "components/common/FormattedMessage";

export const GoogleMyBusinessFieldsMap = new Map<string, ReactNode>([
    ["name", (
        <RelaxSingleField
                name="name"
                label="Name"
            >
                <Input />
            </RelaxSingleField>
    )],
    ["locationName", (
        <RelaxSingleField
                name="locationName"
                label="Location Name"
            >
                <Input />
            </RelaxSingleField>
    )],
    ["categories", (
        <RelaxSingleField
                name={["categories", "primary"]}
                label="Category"
            >
                <CompanyCategorySelect />
            </RelaxSingleField>
    )],
    ["websiteUri", (
        <RelaxSingleField
                name="websiteUri"
                label="Website"
            >
                <Input />
            </RelaxSingleField>
    )],
    ["phoneNumbers", (
        <RelaxSingleField
                name={["phoneNumbers", "primary"]}
                label="Phone Number"
            >
                <PhoneNumberInputWithCountrySelect />
            </RelaxSingleField>
    )],
    ["regularHours", (
        <RelaxSingleField
                name={"regularHours"}
                label="Hours"
            >
                <OpeningHours type="week" />
            </RelaxSingleField>
    )],
    ["address", (
        <>
            <RelaxSingleField
                name={["address", "addressLines", "0"]}
                label="Line 1"
            >
                <Input />
            </RelaxSingleField>
            <RelaxSingleField
                name={["address", "addressLines", "1"]}
                label="Line 2"
            >
                <Input />
            </RelaxSingleField>
            <Row gutter={32}>
                <Col span={12}>
                    <RelaxSingleField
                        name={["address", "locality"]}
                        label="City"
                    >
                        <Input />
                    </RelaxSingleField>
                </Col>
                <Col span={12}>
                    <RelaxSingleField
                        name={["address", "postalCode"]}
                        label="Postal Code"
                    >
                        <Input />
                    </RelaxSingleField>
                </Col>
            </Row>
        </>
    )],
    ["description", (
        <RelaxSingleField
                name="description"
                label="Description"
            >
                <Input />
            </RelaxSingleField>
    )],
])


export const GoogleMyBusinessCompanyInfoForm_Fields = (props: any) => {

    return (
        <>
            <RelaxSingleField
                name="name"
                label="Name"
            >
                <Input />
            </RelaxSingleField>
            <RelaxSingleField
                name="locationName"
                label="Location Name"
            >
                <Input />
            </RelaxSingleField>
            <RelaxSingleField
                name={["categories", "primary"]}
                label="Category"
            >
                <CompanyCategorySelect />
            </RelaxSingleField>
            <RelaxSingleField
                name="websiteUri"
                label="Website"
            >
                <Input />
            </RelaxSingleField>
            <RelaxSingleField
                name={["phoneNumbers", "primary"]}
                label="Phone Number"
            >
                <PhoneNumberInputWithCountrySelect />
            </RelaxSingleField>
            <RelaxSingleField
                name={"regularHours"}
                label="Hours"
            >
                <OpeningHours type="week" />
            </RelaxSingleField>
            <Collapse>
                <Collapse.Panel header="Address" key="specialHours">
                    <>
                        <RelaxSingleField
                            name={["address", "addressLines", "0"]}
                            label="Line 1"
                        >
                            <Input />
                        </RelaxSingleField>
                        <RelaxSingleField
                            name={["address", "addressLines", "1"]}
                            label="Line 2"
                        >
                            <Input />
                        </RelaxSingleField>
                        <Row gutter={32}>
                            <Col span={12}>
                                <RelaxSingleField
                                    name={["address", "locality"]}
                                    label="City"
                                >
                                    <Input />
                                </RelaxSingleField>
                            </Col>
                            <Col span={12}>
                                <RelaxSingleField
                                    name={["address", "postalCode"]}
                                    label="Postal Code"
                                >
                                    <Input />
                                </RelaxSingleField>
                            </Col>
                        </Row>


                    </>
                </Collapse.Panel>
            </Collapse>

            <RelaxSingleField
                name="description"
                label="Description"
            >
                <Input />
            </RelaxSingleField>
        </>
    )

}

export default function GoogleMyBusinessCompanyInfoForm(props: any) {

    const project = useSelectorWithParams([OrganizationProjectSelector, props.projectId]);
    const { organizationId } = useOrganization();
    const { asset, assetId } = useAssetDrawer();

    const { callAPI, combinedData, statusAlert } = useTaskMiddleware({
        dataSelector: () => null,
        adjustData: (data) => {
            return asset?.serviceGoogleMyBusiness?.normalized_object
        },
        selectTask: (tasks) => {

            const tasksForThisProject = (tasks || []).filter(t => t.payload?.projects?.includes(props.projectId))

            if (!tasksForThisProject || tasksForThisProject.length === 0) return null;

            const pendingTasks = tasksForThisProject.filter(t => t.status === "pending_acceptation");

            if (pendingTasks.length === 0) return null;

            const newestTask = pendingTasks.sort((a, b) => {
                return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
            })[0];

            if (newestTask) return newestTask;

            return null;

        },
        taskTemplate: {
            source_id: assetId,
            source_type: "project",
            type: "data_update",
            target_type: "organization",
            target_id: organizationId || -1,
            payload: {
                type: "google_my_business",
                projects: [props.projectId],
            }
        },
        combineData: (task, data) => {
            return {
                ...data,
                ...task.payload,
            };
        }
    })


    const normalizedBusinessInfo = useMemo(() => {
        return project?.serviceGoogleMyBusiness?.normalized_object || {}
    }, [project])

    return (
        <RelaxMultiform
            name="GoogleMyBusinessCompanyInfoForm"
            initialValues={combinedData}
            callAPI={callAPI}
        >
            {statusAlert}
            <GoogleMyBusinessCompanyInfoForm_Fields />
        </RelaxMultiform>
    )

}

function getCombinedDataFromAllSelectedProjects() {
    throw new Error("Function not implemented.");
}


export const DataUpdateTask_GoogleMyBusiness = (props: any) => {

    const task = props.task;

    const modifiedFields = useMemo(() => {

        if (!task?.payload) return null;
        const p = task.payload;
        const fields:any[] = [];

        for (const property in p) {
            const f = GoogleMyBusinessFieldsMap.get(property);
            if (f) fields.push(f);
        }

        return fields;

    }, [task])

    return (
        <Space direction="vertical">
            <FormattedMessage
                id="tools.tasks.data_update.google_my_business.projects"
                defaultMessage="Projects"
                />
            <ListOfProjectsAsTags projects={task.payload.projects} />
            <Collapse
            style={{width: "100%"}}
            >
                <Collapse.Panel header={<FormattedMessage
                id="tools.tasks.data_update.google_my_business.changes"
                defaultMessage="Changes"
                />} key="changes">
            <RelaxMultiform
                disabled={true}
                initialValues={task.payload}
                >
            {modifiedFields}
            </RelaxMultiform>
            </Collapse.Panel>
            </Collapse>
        </Space>
    )

}