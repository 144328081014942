import React, { useEffect, useRef } from "react";
import useAPI from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import CreationEditor, { CreationEditorRef } from "./CreationEditor";
import { creationsSlice, getCreations } from "./creationsSlice";
import { useDispatch } from "react-redux";
import { currentProjectIdSelector } from "services/store/scopeSlice";

const CreationGlobalContext = React.createContext({} as any);
CreationGlobalContext.displayName = "Creation Global Context";
export const useCreationGlobal = () => React.useContext(CreationGlobalContext);

export default function CreationGlobal (props: any) {

    const assetEditorRef = useRef<CreationEditorRef>(null);
    const projectId = useSelectorWithParams(currentProjectIdSelector);
    const getCreationsCall = useAPI(getCreations);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!projectId) return;
        dispatch(creationsSlice.actions.clear({}))
        //getCreationsCall.call();
    }, [projectId]);

    const openCreation = (id: number, queue: string[]) => {
        assetEditorRef.current?.openCreation(id, queue);
    }

    const closeCreation = () => {
        assetEditorRef.current?.close();
    }

    return (
        <CreationGlobalContext.Provider value={{
            openCreation,
            closeCreation
        }}>
            {props.children}
            
            <CreationEditor ref = {assetEditorRef}/>
            {/* <FeatureErrorBoundry feature="CreationEditor">
            <CreationEditor ref = {assetEditorRef}/>
            </FeatureErrorBoundry> */}
        </CreationGlobalContext.Provider>
    )
}