import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { faAndroid, faApple, faFacebook, faGoogle, faInstagram, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faArrowsRotate, faArrowUpArrowDown, faBed, faBriefcase, faBuildings, faChartLineUp, faCheck, faCircle, faCircleCheck, faCircleExclamation, faCircleInfo, faCirclePause, faCirclePlay, faCircleQuestion, faCircleStop, faCircleXmark, faCity, faDoorClosed, faDoorOpen, faDownload, faEclipse, faEllipsis, faFilePlus, faFlag, faGamepad, faGear, faGlobe, faGraduationCap, faHashtag, faHeart, faHome, faHourglass, faIdCard, faImage, faKey, faLockKeyhole, faMinus, faMoneyBill, faPenToSquare, faPhone, faPhonePlus, faPhoneXmark, faPlus, faRotateRight, faSchool, faTag, faTimer, faTrash, faUser, faVial, faWallet, IconDefinition } from "@fortawesome/pro-light-svg-icons";

type AwesomeIconProps = FontAwesomeIconProps & {
    onClick?: () => void

}

export type AwesomeStandardIconProps = Omit<AwesomeIconProps, "icon">

export const AwesomeIconOK = faCheck
export const AwesomeIconEdit = faPenToSquare
export const AwesomeIconFacebook = faFacebook
export const AwesomeIconInfo = faCircleInfo
export const AwesomeTooltipIcon = faCircleQuestion
export const AwesomeIconDelete = faTrash


export const AwesomeOk = ({...props}:AwesomeStandardIconProps) => <FontAwesomeIcon icon={faCircleCheck} {...props} className="awesome" fixedWidth/>
export const AwesomeNotOk = ({...props}:AwesomeStandardIconProps) => <FontAwesomeIcon icon={faCircleXmark} {...props} className="awesome" fixedWidth/>
export const AwesomeTooltip = ({...props}:AwesomeStandardIconProps) => <FontAwesomeIcon icon={faCircleQuestion} {...props} className="awesome" fixedWidth/>
export const AwesomeLoading = ({...props}:AwesomeStandardIconProps) => <FontAwesomeIcon icon={faHourglass} {...props} className="awesome" fixedWidth size="xl" color="grey"/>
export const AwesomeInfo = ({...props}:AwesomeStandardIconProps) => <FontAwesomeIcon icon={faCircleInfo} {...props} className="awesome" fixedWidth/>
export const AwesomeEdit = ({...props}:AwesomeStandardIconProps) => <FontAwesomeIcon icon={faPenToSquare} {...props} className="awesome" fixedWidth/>
export const AwesomeClose = ({...props}:AwesomeStandardIconProps) => <FontAwesomeIcon icon={faCircleXmark} {...props} className="awesome" fixedWidth/>
export const AwesomeMore = ({...props}:AwesomeStandardIconProps) => <FontAwesomeIcon icon={faEllipsis} {...props} className="awesome" fixedWidth/>

export const AwesomeFacebookBlue = ({...props}:AwesomeStandardIconProps) => <FontAwesomeIcon icon={faFacebook} {...props} className="awesome" fixedWidth color="#1877f2"/>
export const AwesomeGoogle = ({...props}:AwesomeStandardIconProps) => <FontAwesomeIcon icon={faGoogle} {...props} className="awesome" fixedWidth color="green"/>

export const AwesomeLarge = ({size, ...props}:AwesomeIconProps) => <FontAwesomeIcon {...props} size="2xl" className="awesome"/>

export default function AwesomeIcon(props: AwesomeIconProps): JSX.Element {

    return <FontAwesomeIcon {...props} className={`awesome ${props.className}`} fixedWidth/>

}

