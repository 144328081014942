import FormattedMessage from "components/common/FormattedMessage";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { useMemo, useRef, useState } from "react";
import { Card, Radio, Space } from "antd";
import Meta from "antd/es/card/Meta";
import StandardButton from "components/common/StandardButton";
import { faList, faPlusCircle, faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import CreationDrawer from "./CreationDrawer";
import { CreationEditorRef } from "./CreationEditor";
import { useCreationGlobal } from "./CreationGlobalContext";
import { CreationTypes } from "./CreationConfig";
import { useHelpPanel } from "tools/helpPanel/HelpPanelDrawer";
import useAPI from "services/hooks/useAPI";
import { getCreations } from "./creationsSlice";
import { currentFlatSubscriptionPermissionsSelector } from "state/subscriptions/subscriptionsSelectors";
import { currentProductSelector } from "services/store/scopeSlice";
import { StandardHalfFullColumn, StandardRow } from "components/layout/StandardGrid";
import SalesFunnelCreationTypes from "./salesFunnelDisplay/SalesFunnelCreationTypes";


export const CreationTypeCard = (props: {
  type: any,
  onCreateNew?: () => void,
  onOpenList?: () => void,
  showDescription?: boolean,
}) => {

  const { openHelpPanel } = useHelpPanel();

  const disabled = props.type.disabled;

  return (
    <Card
      hoverable={disabled ? false : true}
      style={{
        width: props.showDescription ? "330px" : "200px",
      }}
      cover={
        <div style={{ display: "grid", placeItems: "center", padding: "10px" }}>
          <img
            alt="example"
            src={props.type.image}
            style={{
              height: props.showDescription ? "200px" : "150px",
              width: props.showDescription ? "200px" : "150px",
            }}
          />
        </div>
      }
      onClick={() => { }}
      className={disabled ? "standard-disabled" : ""}
    >
      <Meta
        title={props.type.title}
        description=
          {props.showDescription ? (<div style={{ position: "relative" }}>

            <p className="description">
              {props.type.description}
            </p>

          </div>) : null
        }
        style={{
          overflow: "hidden",
          minHeight: props.showDescription ? "200px" : "0px",
          maxHeight: props.showDescription ? "200px" : "0px",
          paddingBottom: "10px",
        }}
      />
      <div
        style={{
          display: "grid",
          placeItems: "center",
        }}
      >
        {props.showDescription ? (
        <StandardButton
          type="primary"
          style={{ width: "100%" }}
          size={props.showDescription ? undefined : "small"}
          icon={faPlusCircle}
          onClick={() => props.onCreateNew && props.onCreateNew()}
          disabled={disabled}
        >
          <FormattedMessage
            id="tools.creationEditor.post.actions.new"
            defaultMessage="New Post"
          />
        </StandardButton>
        ) : null}
        <StandardButton
          style={{ width: "100%" }}
          size={props.showDescription ? undefined : "small"}
          icon={faList}
          onClick={() => props.onOpenList && props.onOpenList()}
          disabled={disabled}
        >
          <FormattedMessage
            id="tools.creationEditor.draft.actions.myDrafts"
            defaultMessage="My Drafts"
          />
        </StandardButton>
        <StandardButton
          style={{ width: "100%" }}
          size={props.showDescription ? undefined : "small"}
          icon={faQuestionCircle}
          disabled={disabled}
          onClick={() => openHelpPanel((props.type.tooltip as any)?.props?.id)}
        >
          <FormattedMessage
            id="common.readMore"
            defaultMessage="Read More"
          />
        </StandardButton>
      </div>
    </Card>
  );
};


export default function CreationMainPage(props: any) {

  const product = useSelectorWithParams([currentProductSelector]);
  const flatPermissions = useSelectorWithParams([currentFlatSubscriptionPermissionsSelector]);
  const creationListRef = useRef<any>(null);
  const assetEditorRef = useRef<CreationEditorRef>(null);
  const { openCreation } = useCreationGlobal();
  const [filter, setFilter] = useState("funnel");
  const getCreationsCall = useAPI(getCreations, true);

  const allowedTypes = useMemo(() => CreationTypes.filter((type) => {
    if (!type.permission) return true;
    if (typeof type.permission === "function") return type.permission(flatPermissions);
    return flatPermissions.includes(type.permission);
  }), [flatPermissions]);

  const filteredTypes = useMemo(() => {
    if (filter === "all" || filter === "funnel") return allowedTypes;
    if (filter === "posts") return allowedTypes.filter((type) => !type.isAd);
    if (filter === "ads") return allowedTypes.filter((type) => type.isAd);
    return []
  }, [allowedTypes, filter]);

  if (filter === "funnel") {

    return (
      <>
        <StandardRow style={{width: "100%"}}>
          <StandardRow style={{width: "100%"}}>
              <Radio.Group
                defaultValue="funnel"
                buttonStyle="solid"
                style={{ width: "100%", marginBottom: "20px" }}
                onChange={(e) => setFilter(e.target.value)}
              >
                <Radio.Button value="funnel">
                  <FormattedMessage
                    id="tools.creationEditor.typeFilter.funnel"
                    defaultMessage="Funnel" />
                </Radio.Button>
                <Radio.Button value="all">
                  <FormattedMessage
                    id="tools.creationEditor.typeFilter.all"
                    defaultMessage="All" />
                </Radio.Button>
                <Radio.Button value="posts">
                  <FormattedMessage
                    id="ttools.creationEditor.typeFilter.posts"
                    defaultMessage="Posts" />
                </Radio.Button>
                <Radio.Button value="ads">
                  <FormattedMessage
                    id="tools.creationEditor.typeFilter.ads"
                    defaultMessage="Ads" />
                </Radio.Button>
              </Radio.Group>
              <SalesFunnelCreationTypes allowedTypes={allowedTypes}
                onOpen={(type) => {
                  if (creationListRef.current) {
                    creationListRef.current.openWithCreationType(type);
                  }
                }}
                onNew={(type) => {
                  if (creationListRef.current) {
                    creationListRef.current.newDraft(type);
                  }
                }}
              />
          </StandardRow>
        </StandardRow>
        <CreationDrawer
          ref={creationListRef}
          onOpen={(id: any) => {
            openCreation(id);
          }}
        />
      </>
    )

  }


  return (
    <>
      <StandardRow style={{width: "100%"}}>
        <Radio.Group
          defaultValue="funnel"
          buttonStyle="solid"
          style={{ width: "100%", marginBottom: "20px" }}
          onChange={(e) => setFilter(e.target.value)}
        >
          <Radio.Button value="funnel">
            <FormattedMessage
              id="tools.creationEditor.typeFilter.funnel"
              defaultMessage="Funnel" />
          </Radio.Button>
          <Radio.Button value="all">
            <FormattedMessage
              id="tools.creationEditor.typeFilter.all"
              defaultMessage="All" />
          </Radio.Button>
          <Radio.Button value="posts">
            <FormattedMessage
              id="ttools.creationEditor.typeFilter.posts"
              defaultMessage="Posts" />
          </Radio.Button>
          <Radio.Button value="ads">
            <FormattedMessage
              id="tools.creationEditor.typeFilter.ads"
              defaultMessage="Ads" />
          </Radio.Button>
        </Radio.Group>
        <Space direction="horizontal" wrap={true} align="start" style={{ height: "100%", overflow: "auto"}}>
          {filteredTypes.map((type, index) => (
            <CreationTypeCard
              key={index}
              type={type}
              showDescription={true}
              onCreateNew={() => {
                if (creationListRef.current) {
                  creationListRef.current.newDraft(type);
                }
              }}
              onOpenList={() => {
                if (creationListRef.current) {
                  creationListRef.current.openWithCreationType(type);
                }
              }}
            />
          ))}
        </Space>
      </StandardRow>
      <CreationDrawer
        ref={creationListRef}
        onOpen={(id: any) => {
          openCreation(id);
        }}
      />
    </>
  )


}