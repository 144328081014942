import { Input, Spin } from "antd";
import React, { useMemo } from "react";
import { Suspense, useEffect } from "react";
import { useCreationEditor } from "tools/creationEditor/CreationEditor";
import CroppedImage from "tools/gallery/CroppedImage";
import GalleryItemDisplay from "tools/gallery/GalleryItemDisplay";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import { EditorSectionCTA, EditorSectionDescription, EditorSectionMedia, EditorSectionMessage, EditorSectionPublication, EditorSectionTitle } from "../editorSections/EditorSections";
import EditorSectionsCollapse from "../editorSections/EditorSectionsCollapse";
import CreationTargetSelector from "../editorTools/CreationTargetSelector";
import FieldMessage, { FieldCTA, FieldDescription, FieldMediaSingle, FieldPostType, FieldTitle } from "../Fields";
import EditorWithStages from "./EditorWithStages";
import useDataPrefill from "services/hooks/useDataPrefill";
import { Creation, creationContentSelector, patchCreation } from "../creationsSlice";
import { patchCreationWithSmartAction } from "../creationsSlice"

const EditorVideoReel = (props: any) => {

    const { creation, subtype, creationId } = useCreationEditor();
    const messangerDataPrefill = useDataPrefill({
        dataSelector: (state: any) => creationContentSelector(state, creationId),
        callAPI: patchCreationWithSmartAction(creationId, "ce_ctas"),
        checkFunction: (data: any) => {
            if (!data) return false;

            const prefillData:any = {};
            if (!data.provider || !data.provider.includes("facebook")) prefillData.provider=["facebook"];
            if (Object.keys(prefillData).length > 0) return {content: prefillData};
            return false;
        }
    })

    return (
        <div>
            <EditorSectionsCollapse>
                <EditorSectionMedia />
                <EditorSectionMessage />
                <EditorSectionPublication />
            </EditorSectionsCollapse>
        </div>
    )

}

export default EditorVideoReel;