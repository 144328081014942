import React, { Component } from "react";
import { Button } from "antd";
import * as Sentry from "@sentry/browser";
import { SubscriptionConsumer } from "providers/subscription/subscription";
import ErrorPage from "modules/panel/components/error-page";
import FormattedMessage from "components/common/FormattedMessage";
import { withRouter, RouteComponentProps } from "react-router-dom";
import StandardButton from "components/common/StandardButton";
import { SentryCaptureException, sentryMonitor } from "services/sentry";

type ErrorBoundaryState = {
  eventId?: string;
  hasError?: boolean;
};

class ErrorBoundary extends Component<RouteComponentProps, ErrorBoundaryState> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = { eventId: undefined };
  }

  static getDerivedStateFromError(error: React.ErrorInfo) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    //SentryCaptureException(error, errorInfo);
    Sentry.withScope((scope) => {
      scope.setTag("type", "feature-error");
      scope.setExtras(error as any);
      scope.setTag("buildDate", import.meta.env.BUILD_DATE);
      Sentry.captureEvent({
          message: "Feature Error",
          extra: {
              error: error,
          },
      });
  });
  }


  render() {

    if (this.state.hasError) {
      return (
        <ErrorPage
          title={
            <FormattedMessage
              id="panel.providers.routing.error.title"
              defaultMessage="Error occured"
            />
          }
          subtitle={
            <>
            <FormattedMessage
              id="panel.providers.routing.error.subtitle"
              defaultMessage="We have just sent report to our development team."
            />
            <div style={{fontSize: "14px", padding: "5px"}}>
              {sentryMonitor.lastEventId}
            </div>
            </>
          }
          backgroundText={"ERROR"}
          buttons={
            <>
              <SubscriptionConsumer>
                {({ index }) => (
                  //TODO: rewrite with ACL
                  <StandardButton
                    type="primary"
                    onClick={() => {
                      this.setState({ hasError: false });
                      if (index) this.props.history.push(index);
                    }}
                  >
                    <FormattedMessage
                      id="panel.providers.routing.error.takeMeBack"
                      defaultMessage="Take me back"
                    />
                  </StandardButton>
                )}
              </SubscriptionConsumer>
              <StandardButton
                onClick={() =>
                  Sentry.showReportDialog({ eventId: this.state.eventId })
                }
              >
                <FormattedMessage
                  id="panel.providers.routing.error.reportFeedback"
                  defaultMessage="Report feedback"
                />
              </StandardButton>
            </>
          }
        />
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
