import { Input } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import StandardButton from "components/common/StandardButton";
import useAPI from "services/hooks/useAPI";
import { StandardModal, useStandardModal } from "services/hooks/useModal";
import RelaxField from "tools/relaxForm/RelaxField";
import { ruleMin } from "tools/relaxForm/relaxPresets";
import useRelaxData from "tools/relaxForm/useRelaxData";
import { PlusIcon } from "../../ft-icons";
import ButtonWithIcon from "modules/smart/components/list-item/base-list-item/ButtonWithIcon";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { CreateNewProject } from "state/projects/projectEndpoints";

type CreateNewProjectPopupProps = {
  onCreate?: (newProjectId: string) => void;
  hideLabel?: boolean;
};

export const CreateNewProjectPopup = (props:CreateNewProjectPopupProps) => {
  const [ref, open, close] = useStandardModal();
  const rd = useRelaxData();
  const { currentValue, setCurrentValue, errors, setErrors } = rd;
  const createProjectCall = useAPI(CreateNewProject);

  return (
    <>
      <StandardButton className="choose" onClick={open} icon={faPlus}>
        {(props.hideLabel !== true) && <FormattedMessage
          id="apps.panel.components.subscriptions.projectSelectConfirm.select.createNew"
          defaultMessage="Create project"
        />}
      </StandardButton>
      <StandardModal
        ref={ref}
        title={
          <FormattedMessage
            id="apps.panel.components.subscriptions.projectCreationPopup.title"
            defaultMessage="Enter a name for the new project"
          />
        }
        onOk={() => {
          if(errors === null && currentValue != null){
            createProjectCall.call({
              body:{
                name:currentValue
              }
            }).then((res)=>{
              if(res.status == 200){
                close();
                setCurrentValue("")
                setErrors([])
                if (props.onCreate) props.onCreate(res.data.id)
              }
            })
            
          }else{
            setErrors([
              <FormattedMessage
            id="apps.panel.components.subscriptions.projectCreationPopup.error"
            defaultMessage="New project name is required"
          />]
            )
          }
        }}
      >
        <RelaxField
          relaxData={rd}
          hideStatus={true}
          label={
            <FormattedMessage
              id="apps.panel.components.subscriptions.projectCreatePopup"
              defaultMessage="Project name"
            />
          }
          rules={[ruleMin(3)]}
        >
          <Input placeholder="Company XYZ" />
        </RelaxField>
      </StandardModal>
    </>
  );
};
