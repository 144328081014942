import { Tooltip } from "antd";
import { CSSProperties, ReactNode } from "react";
import { useHelpPanel } from "tools/helpPanel/HelpPanelDrawer";
import AwesomeIcon from "../AwesomeIcon";
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";

export const TooltipIcon = (props: { title: ReactNode, link?: string, style?:CSSProperties }) => {

    const { openHelpPanel } = useHelpPanel();
  
    const link = () => {
        if (props.link) return props.link;
        if (typeof props.title === "object") return (props?.title as any)?.props?.id;
        return props.title;
    }
  
    const handleOpenHelper = () => {
      openHelpPanel(link(), props.title);
    };
  
    return (
      <Tooltip title={props.title}>
        <AwesomeIcon 
        style={props.style}
          onClick={handleOpenHelper}
        icon={faQuestionCircle} size="xl" color={"grey"} />
      </Tooltip>
    );
  };