import { Select } from "antd";
import { useMemo } from "react";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { CreationTypes } from "./CreationConfig";
import { currentFlatSubscriptionPermissionsSelector } from "state/subscriptions/subscriptionsSelectors";

const LabelRender = (props:any) => {

    return (
        <div style={{
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "stretch",
        }}>
            <div style={{width: "65px"}}>
                <img 
                src={props.image} 
                height="40px"
                />
            </div>
            {props.label}
        </div>

    )

}

export default function CreationTypeSelect(props:any) {

    const flatPermissions = useSelectorWithParams([currentFlatSubscriptionPermissionsSelector]);

    const allowedTypes = useMemo(() => CreationTypes.filter((type) => {
        if (!type.permission) return true;
        return flatPermissions.includes(type.permission);
    }), [flatPermissions]);

    return (
        <Select
                value={props.value}
                onChange={props.onChange}
                style={{ width: "100%", paddingBottom: "10px" }}
                options={allowedTypes.map((ct) => ({ label: <LabelRender label={ct.title} image={ct.image}/>, value: ct.type.join(".") }))}
            />
    )

}