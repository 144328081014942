import ConnectedOnboardingControls from "providers/onboarding/controls";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import WebsiteObjectLocation from "../WebsiteObjectLocation";

export default function WebsiteObjectLocationOnboarding() {

    const fulfilled = useSelectorWithParams([() => true]);

    return (
        <>
            <WebsiteObjectLocation />
            <ConnectedOnboardingControls relaxDontSubmit = {true} nextDisabled={(!fulfilled)}/>
        </>
    )

}