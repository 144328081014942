import dayjs from "dayjs";
import { CalendarEntriesManager } from "./Calendar";
import { events } from "modules/panel/components/promo/ListOfPromoEvents";
import AwesomeIcon from "components/common/AwesomeIcon";
import { faClock } from "@fortawesome/pro-light-svg-icons";
import { Link } from "react-router-dom";
import { StandardModal, useSimpleModal, useStandardModal } from "services/hooks/useModal";
import StandardButton from "components/common/StandardButton";
import FormattedMessage from "components/common/FormattedMessage";
import "./calendar-promo.less";

const WrappedWithLink = (props: { link: string, children: any }) => {
    if (!props.link || props.link.length === 0) return props.children;
    return (
        <a href={props.link} target="_blank" className="event-link-wrapper">
            {props.children}
        </a>
    )
}

const EventComponent = (props: any) => {

    const { entry } = props;

    const [ref, open, close] = useStandardModal();


    if (!entry) return null;

    const hasLink = entry.link && entry.link.length > 0;

    const hours = (() => {
        if (entry.timeStart && entry.timeEnd) {
            return <div><AwesomeIcon icon={faClock} />{`${entry.timeStart} - ${entry.timeEnd}`}</div>
        }
        if (entry.timeStart) {
            return `${entry.timeStart}`
        }
        return null;
    })()

    const image = (() => {
        if (entry.image) {
            return <img src={entry.image} />
        }
    })()

    return (
        <>
            <div 
                data-calendar-entry-can-be-picked-up={false}
                className={`calendar-promo-event ${entry.image ? "with-image" : "no-image"}`}
                onClick={() => open()}
            >

                <div className="promo-image">
                    {image}
                </div>
                <div className="description">
                    {hours}
                    {props?.entry?.name}
                </div>

            </div>
            <StandardModal
                ref={ref}
                onCancel={close}
                width="800px"
                modalProps={{
                    footer: hasLink ? 
                        <a href={entry.link} target="_blank">
                            <StandardButton type={"primary"}>
                            <FormattedMessage
                                id="tools.calendar.promo.event.link"
                                defaultMessage="Subscribe" />
                            </StandardButton>
                        </a> 
                    : null
                }}
            >
                <div className={`calendar-promo-event ${entry.image ? "with-image" : "no-image"} modal`}>
                    <div className="promo-image">
                        {image}
                    </div>
                    <div className="description">
                        {dayjs(entry.date).format("dddd, DD MMMM YYYY")}{hours}
                        {props?.entry?.name}
                    </div>
                </div>
            </StandardModal>
        </>
    )

}

const useCalendarPromoEntries: CalendarEntriesManager = (props) => {

    const adjustedEntries = events.map(e => {
        return {
            ...e,
            date: dayjs(e.date, "DD.MM.YYYY"),
        }
    });

    return {
        entries: adjustedEntries,
        dayFilter: (e, d) => {
            return e.date.isSame(d, 'day');
        },
        EntryComponent: EventComponent
    }

}

export default useCalendarPromoEntries;