import React from 'react';
import { SubscriptionConsumer } from 'providers/subscription/subscription';

export function withSubscriptionConfig(Component: any) {
    return function WrapperComponent(props: any) {

        // return (
        //     <Component {...props} />
        // )


        return (
            <SubscriptionConsumer>
                {state => <Component {...props} subscription={state}/>}
            </SubscriptionConsumer>
        );
    };
}

export default withSubscriptionConfig;