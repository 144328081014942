import { useEffect, useState } from "react";

export default function useEffectNotFirst(callback: () => void, dependencies: any[] = []) {

    const [wasFirst, setWasFirst] = useState(false);

    useEffect(() => {
        if (wasFirst) {
            callback && callback();
        } else {
            setWasFirst(true);
        }
    }, dependencies);

}