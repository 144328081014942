import { InputNumber, Spin } from "antd";
import { HorizontalCenter } from "components/common/Center";
import { useEffect, useMemo } from "react";

type BudgetInputProps = {
    value?: number,
    onChange?: (v:number) => void,
    onBlur?: () => void,
    minimumBudget?: number,
    defaultBudget?: number,
    currency?: string,
    className?: string,
}

export default function BudgetInput(props:BudgetInputProps) {

    const minBudget = props.minimumBudget || 0;
    const defaultBudget = props.defaultBudget || 0;

      
    return (
        <InputNumber
        value={props.value ? props.value : defaultBudget}
        onChange={(v:any) => props.onChange && props.onChange(v)}
        onBlur={props.onBlur}
        min={minBudget}
        addonAfter={props.currency}
        className={props.className}
        style={{
            width: "100%"
        }}
        />
    )

}