import { Input, Radio } from "antd";
import ConnectedOnboardingControls from "providers/onboarding/controls";
import { useEffect, useMemo, useState } from "react";
import FormattedMessage from "components/common/FormattedMessage";
import useAPI, { callAPIFunction } from "services/hooks/useAPI";
import Gallery from "tools/gallery/Gallery";
import { GalleryExternalUpload } from "tools/gallery/GalleryExternalUpload";
import {
  ruleArrayMax,
  ruleArrayMin,
  ruleArrayNoDuplicates,
} from "tools/relaxForm/relaxPresets";
import RelaxList from "tools/relaxForm/RelaxList";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import {
  HasFilledVideoAdsMediaSelector,
  VideoSelector,
  smartSlice,
} from "modules/smart/smartSlice";

type Props = {
  noOnboarding?: boolean;
};

type VideoTypeSelectProps = {
  value: string
  onChange: (value:string) => void
}

const VideoTypeSelect = (props: VideoTypeSelectProps) => {
  return (
    <Radio.Group
      value={props.value}
      onChange={(tab: any) =>
        props.onChange && props.onChange(tab.target.value)
      }
    >
      <Radio.Button value="video">
        <FormattedMessage
          id="apps.forsant.onboaridng.video.uploadVideo"
          defaultMessage="Upload video"
        />
      </Radio.Button>
      <Radio.Button value="youtube">
        <FormattedMessage
          id="apps.forsant.onboaridng.video.youtube"
          defaultMessage="YouTube URL"
        />
      </Radio.Button>
      <Radio.Button value="slideshow">
        <FormattedMessage
          id="apps.forsant.onboaridng.video.slideshow"
          defaultMessage="Slideshow"
        />
      </Radio.Button>
    </Radio.Group>
  );
};

export const OnboardingVideoAds = (props: Props) => {
  const updateSettings: callAPIFunction = (body) => ({
    url: ({ getApiUrl, projectId, smart }) =>
      getApiUrl(
        `projects/${projectId}/smarts/${smart}/google-smart-settings`,
        2
      ),
    method: "PATCH",
    successDispatch: smartSlice.actions.updateGoogleSmartSettings,
    body,
  });

  const fulfilled = useSelectorWithParams(HasFilledVideoAdsMediaSelector);
  const videoSelector = useSelectorWithParams(VideoSelector);
  const videoTypeSelector = () =>{
    if(videoSelector === null || videoSelector === undefined) return "video"
    switch(videoSelector[0].type){
      case "youtube":
        return "youtube"

      case "generated":
          return "slideshow"
      default:
      return "video";
    }
  }
  const [type, setType] = useState<string>(videoTypeSelector());
  const [value, setValue] = useState<any>(null);

  const saveCall = useAPI(updateSettings());

  const isEnabled = useMemo(() => {
    if (fulfilled) return true;
    if (!type) return false;
    if (type === "slideshow") return true;
    if (!value) return false;

    switch (type) {
      case "video":
        return value && value.length > 0;

      case "youtube":
        return value && value.length > 0;

      default:
        return true;
    }
  }, [type, value]);

  useEffect(() => {
    setValue(null);
  }, [type]);

  const handleSend = () => {
    return saveCall.call(updateSettings(finalizeBody()));
  };

  const finalizeBody = () => {
    if (!value)
      return {
        videos: [
          {
            type: "generated",
            selected: true,
          },
        ],
      };
    if (type === "video") {
      return { videos: value.map((v: any) => ({ ...v, type: "local" })) };
    }
    if (type === "youtube") {
      return {
        videos: value.map((v: string) => ({ url: v, type: "youtube" })),
      };
    }
    if (type === "slideshow")
      return {
        videos: [
          {
            type: "generated",
            selected: true,
          },
        ],
      };
    return value;
  };

  const videoInput = useMemo(() => {
    switch (type) {
      case "video":
        return (
          <RelaxSingleField
            name="videos"
            rules={[ruleArrayNoDuplicates, ruleArrayMin(1), ruleArrayMax(5)]}
            submitOverride={(v: any) => {
              setValue(v.videos);
            }}
          >
            <Gallery allowedTypes={["video"]} allowedTools={["delete"]} />
          </RelaxSingleField>
        );
      case "youtube":
        return (
          <RelaxList
            name="youtubeVideos"
            rules={[ruleArrayNoDuplicates, ruleArrayMin(1), ruleArrayMax(5)]}
            submitOverride={(v: any) => {
              setValue(v.youtubeVideos);
            }}
          >
            <GalleryExternalUpload />
          </RelaxList>
        );
      case "slideshow":
        return (
          <div>
            <FormattedMessage
              id="apps.forsant.onboaridng.video.slideshow.description"
              defaultMessage="Slideshow will be generated from images provided"
            />
            <RelaxSingleField name="generatedVideos" disableSubmit>
              <Input type="hidden" />
            </RelaxSingleField>
          </div>
        );
      default:
        return <div>default</div>;
    }
  }, [type]);

  return (
    <>
      <RelaxMultiform
        name="video"
        initialValues={videoSelector}
        //callAPI={UpdateSettings(smartId)}
        // finalizeSubmit={(values: any) => {
        //   if (!values) return;
        //   if (type === "video") {
        //     return { videos: values.videos.map((v: any) => ({ ...v, type: "local" })) }
        //   }
        //   if (type === "youtube") {
        //     return { videos: values.youtubeVideos.map((v: string) => ({ url: v, type: "youtube" })) }
        //   }
        //   if (type === "slideshow")
        //     return {
        //       videos: [{
        //         type: "generated",
        //         selected: true,
        //       }]
        //     }
        //   return values;
        // }}
      >
        <VideoTypeSelect value={type} onChange={setType} />
        {videoInput}
        {props.noOnboarding != true && (
          <ConnectedOnboardingControls
            nextDisabled={!isEnabled}
            clickOverride={handleSend}
          />
        )}
      </RelaxMultiform>
    </>
  );
};
