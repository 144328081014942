import FormattedMessage from "components/common/FormattedMessage";
import {
  StandardProjectCard,
  StandardSubscriptionCard,
} from "components/common/StandardActorCard";
import {
  Duration,
  SubscriptionContractTypeDisplay,
  SubscriptionPaymentDueAmountDisplay,
  SubscriptionPriceWithBillingCycleDisplay,
} from "./SubscriptionListDisplays";
import { SubscriptionCancellationButton } from "./cancellation/SubscriptionCancellationButton";

const sizes = {
  standard: ["200px", "200px", "200px", "200px", "200px", "200px"],
  mid: ["150px", "150px", "150px", "150px", "150px", "150px"],
  project: [null, "150px", "150px", "120px", "120px", "200px"],
  amount: [null, null, "120px", "120px", "120px", "200px"],
  duration: [null, null, "120px", "120px", "120px", "200px"],
  price: [null, null, "120px", "120px", "120px", "150px"],
  type: [null, null, null, null, null, "120px", "150px"],
  action: ["150px", "150px", "150px", "120px", "120px", "150px"],
};

export const subscriptionWithDueListHeading = [
  {
    key: "product",
    size: sizes.mid,
    render: () => (
      <FormattedMessage
        id="panel.subscriptions.list.headings.subscription"
        defaultMessage="Subscription"
      />
    ),
  },
  {
    key: "project",
    size: sizes.project,
    render: () => (
      <FormattedMessage
        id="panel.subscriptions.list.headings.project"
        defaultMessage="Project"
      />
    ),
  },
  {
    key: "paymentDueAmount",
    size: sizes.amount,
    render: () => (
      <FormattedMessage
        id="panel.subscriptions.list.headings.paymentDueAmount"
        defaultMessage="Amount Due"
      />
    ),
  },
  {
    key: "duration",
    size: sizes.duration,
    render: () => (
      <FormattedMessage
        id="panel.subscriptions.list.headings.duration"
        defaultMessage="Duration"
      />
    ),
  },
  {
    key: "price",
    size: sizes.price,
    render: () => (
      <FormattedMessage
        id="panel.subscriptions.list.headings.price"
        defaultMessage="Price"
      />
    ),
  },
  {
    key: "type",
    size: sizes.type,
    render: () => (
      <FormattedMessage
        id="panel.subscriptions.list.headings.type"
        defaultMessage="Type"
      />
    ),
  },
  {
    key: "action",
    size: sizes.action,
    // render: () => (
    //   <FormattedMessage
    //     id="panel.subscriptions.list.headings.action"
    //     defaultMessage="Action"
    //   />
    // ),
    render: (item: any) => null
  },
];

export const subscriptionWithDueListColumns = [
  {
    key: "product",
    size: sizes.mid,
    render: (item: any) => (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "5px",
          width: "100%",
          justifyContent: "flex-start",
          paddingLeft: "10px",
        }}
      >
        <StandardSubscriptionCard subscription={item} />
      </div>
    ),
  },
  {
    key: "project",
    size: sizes.project,
    render: (item: any) => (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "5px",
          width: "100%",
          justifyContent: "flex-start",
          paddingLeft: "10px",
        }}
      >
        <StandardProjectCard projectId={item?.project} />
      </div>
    ),
  },
  {
    key: "paymentDueAmount",
    size: sizes.amount,
    render: (item: any) => <SubscriptionPaymentDueAmountDisplay />,
  },
  {
    key: "duration",
    size: sizes.duration,
    render: (item: any) => <Duration />,
  },
  {
    key: "price",
    size: sizes.price,
    render: (item: any) => <SubscriptionPriceWithBillingCycleDisplay />,
  },
  {
    key: "type",
    size: sizes.type,
    render: (item: any) => <SubscriptionContractTypeDisplay />,
  },
  {
    key: "action",
    size: sizes.action,
    // render: (item: any) => <SubscriptionCancellationButton />,
    render: (item: any) => null
  },
];
