import { faEnvelope } from "@fortawesome/pro-light-svg-icons"
import { Badge, Dropdown, Menu } from "antd";
import AwesomeIcon from "components/common/AwesomeIcon"
import { useNotifications } from "./NotificationsDrawer";

const NotificationsNavbarWidget = () => {

    const {openNotifications, notifications} = useNotifications();

    return null;

    return (
        <Badge count={notifications.length || 0} offset={[-22, 20]} style={{ backgroundColor: "#ff4d4f",}}>
            <AwesomeIcon icon={faEnvelope} size="2xl" onClick={openNotifications} style={{
                padding: "20px"
            }}/>
        </Badge>
    )

}

export default NotificationsNavbarWidget;