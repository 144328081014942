import React, { FunctionComponent, ReactNode } from "react";

type ConditionalWrapperProps = {
  condition: boolean;
  wrapper: (children: ReactNode) => ReactNode;
};

const ConditionalWrapper: FunctionComponent<ConditionalWrapperProps> = ({
  condition,
  wrapper,
  children,
}) => <>{condition ? wrapper(children) : children}</>;

export default ConditionalWrapper;
