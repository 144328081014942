import { Button } from "antd";
import StandardButton from "components/common/StandardButton";
import { PlayIcon, UpdateIcon } from "modules/panel/components/ft-icons";
import React, { ReactNode, useState} from "react";
import FormattedMessage from "components/common/FormattedMessage";
import './switchable-input.less'

type SwitchableProps = {
    value?: ReactNode;
    edit: JSX.Element;
    onSave?: () => void;
}

export default function SwitchableInput(props:SwitchableProps) {

    const [isOpen, setIsOpen] = useState(false);


    function handleSave() {
        if (props.onSave) props.onSave();
        setIsOpen(false);
    }

    if (isOpen) {
        return (
            <div className="switchable-input edit">
                {props.edit}
                <StandardButton type="primary" onClick={() => handleSave()}>
                    <FormattedMessage
                        id="components.forms.controls.save"
                        defaultMessage="Save"
                      />
                </StandardButton>
                <StandardButton onClick={() => setIsOpen(false)} >
                    <FormattedMessage
                        id="components.forms.controls.cancel"
                        defaultMessage="Cancel"
                      />
                </StandardButton>
            </div>
        )    
    }

    return (
        <div className="switchable-input value">
            {props.value}
            <UpdateIcon onClick={() => setIsOpen(true)} className = "icon edit"/>
        </div>
    )
    

}
