import FormattedMessage from "components/common/FormattedMessage";
import { SubscriptionCancellationPlanName } from "state/subscriptions/subscriptionTypes";
import SubscriptionCancellationSummary from "./SubscriptionCancellationSummary";
import SubscriptionCancellationPlanDisplay from "./SubscriptionCancellationPlanDisplay";

type CancellationPlanConfig = {
    title: JSX.Element,
    description: JSX.Element,
    outcome: JSX.Element,
    summaryComponent?: JSX.Element,
}

export const CancellationPlansConfig:Record<SubscriptionCancellationPlanName,CancellationPlanConfig> = {
    "close": {
        title: <FormattedMessage
            id="panel.subscriptions.cancellation.selectPlan.close.title"
            defaultMessage="Close"
        />,
        description: <FormattedMessage
            id="panel.subscriptions.cancellation.selectPlan.close.description"
            defaultMessage="This option allows you to immediately terminate your contract by paying all outstanding debts. Additionally, you will incur a fine for breaking the contract, which includes paying the difference between the full price of the product and the promotional price you were previously paying."
        />,
        outcome: <FormattedMessage
            id="panel.subscriptions.cancellation.selectPlan.close.outcome"
            defaultMessage="Your subscription is closed immediately after settling the required payments."
        />,
        summaryComponent: <SubscriptionCancellationPlanDisplay/>
    },
    "cancelNow": {
        title: <FormattedMessage
            id="panel.subscriptions.cancellation.selectPlan.cancelNow.title"
            defaultMessage="Cancel now"
        />,
        description: <FormattedMessage
            id="panel.subscriptions.cancellation.selectPlan.cancelNow.description"
            defaultMessage="With this option, you agree to pay the normal subscription price for all remaining months in your contract immediately. Your subscription will remain active until the end of your contract duration."
        />,
        outcome: <FormattedMessage
            id="panel.subscriptions.cancellation.selectPlan.cancelNow.outcome"
            defaultMessage="Your subscription will be closed at the end of your current contract period after all payments are settled."
        />
    },
    "cancel": {
        title: <FormattedMessage
            id="panel.subscriptions.cancellation.selectPlan.cancel.title"
            defaultMessage="Cancel"
        />,
        description: <FormattedMessage
            id="panel.subscriptions.cancellation.selectPlan.cancel.description"
            defaultMessage="By choosing this option, your subscription is flagged to not renew once the contract ends. You will continue to pay the normal monthly subscription rate until the contract's end date. If all dues are cleared by then, the account is closed. However, if there are any outstanding debts at the end of the contract, it will convert into an indefinite period contract until the dues are fully paid."
        />,
        outcome: <FormattedMessage
            id="panel.subscriptions.cancellation.selectPlan.cancel.outcome"
            defaultMessage="Your subscription will close at the end of the contract if all payments are up to date. If not, it will continue on a month-to-month basis until settled."
        />
    },
}