import useAPI from "services/hooks/useAPI";
import { validateCreation } from "../creationsSlice";
import { useCreationEditor } from "../CreationEditor";

type useCreationActionsReturn = {
    validate: () => void;
}

export default function useCreationActions (props?:any):useCreationActionsReturn {
    
    const { creationId, setValidationResults } = useCreationEditor();
    const validateAPI = useAPI(validateCreation(null))

    const handleValidate = () => {
        validateAPI.call(validateCreation(creationId)).then((res) => {
            if (res?.status === 200) {
                setValidationResults([]);
            } else if (res?.error) {
                let errors = res?.error?.errors;
                if (errors) {
                    setValidationResults(errors);
                }
            }
        });
    }

    return {
        validate:handleValidate
    }
}