import React, { Component } from "react";
import dayjs, { Dayjs } from 'dayjs';
import DatePicker from "components/inputs/DatePicker";

class DateInput extends Component {
  render() {
    let { value, onChange, ...props } = this.props;

    return (
      <DatePicker
        value={value ? dayjs(value) : value}
        onChange={(date) =>
          onChange(date ? date.format(this.props.format) : undefined)
        }
        {...props}
      />
    );
  }
}

DateInput.defaultProps = {
  format: "YYYY-MM-DD",
};

export default DateInput;
