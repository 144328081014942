import React, { Component } from "react";
import { Map } from "immutable";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  createErrorSelector,
  createLoadingSelector,
  createSuccessSelector,
} from "modules/panel/config/selectors/request-status";
import { clearActionsStatuses } from "modules/panel/actions/request-status";
import forIn from "lodash/forIn";
import { getDisplayName, injectProps } from "services/helpers/hoc-helpers";

function WithWatchedActions(WrappedComponent) {
  class WatchActions extends Component {
    componentWillUnmount() {
      this.props.clearActionsStatuses(this.props.watchedActions);
    }

    getFormattedActions = () => {
      let actions = {};

      for (let action in this.props.actions) {
        let actionDetails = {
          exec: this.props.actions[action],
          status: {
            get: (key) => this.getStatusByKey(action, key),
            ...this.props.requestStatus[action],
          },
        };

        if (
          this.props.observedActionsSuccess &&
          this.state.requestSuccess.get(action)
        )
          actionDetails.status.success = this.state.requestSuccess.get(action);

        let actionData = this.props.actionsData
          ? this.props.actionsData[action]
          : false;
        if (actionData) actionDetails.data = actionData;

        actions[action] = actionDetails;
      }

      return actions;
    };

    getStatusByKey = (action, key) => {
      let { pending, error, success } = this.props.requestStatus[action];
      return {
        pending: Map.isMap(pending) ? pending.get(key) : false,
        error: Map.isMap(error) ? error.get(key) : false,
        success: Map.isMap(success) ? success.get(key) : false,
      };
    };

    render() {
      let {
        observedActionsSuccess,
        requestStatus,
        actionsData,
        actions,
        clearActionsStatuses,
        watchedActions,
        ...props
      } = this.props;

      let formattedActions = this.getFormattedActions();

      return <WrappedComponent {...formattedActions} {...props} />;
    }
  }
  WatchActions.displayName = `WithWatchedActions(${getDisplayName(
    WrappedComponent
  )})`;
  return WatchActions;
}

export const WatchActions = (options) => (WrappedComponent) => {
  if (!options.redux) options.redux = {};
  // eslint-disable-next-line
  if (!options.redux.mapStateToProps) options.redux.mapStateToProps = () => {};

  if (!options.redux.mapDispatchToProps)
    // eslint-disable-next-line
    options.redux.mapDispatchToProps = () => {};

  return compose(
    connect(
      (state, ownProps) => {
        return {
          ...registerActionsStatusesAndSelectors(options.watch, state),
          ...options.redux.mapStateToProps(state, ownProps),
        };
      },
      (dispatch, ownProps) => {
        return {
          ...registerActionsDispatches(options.watch, dispatch, ownProps),
          ...options.redux.mapDispatchToProps(dispatch, ownProps),
        };
      }
    ),
    injectProps({
      watchedActions: getWatchedActionsConsts(options),
    }),
    WithWatchedActions
  )(WrappedComponent);
};

const getWatchedActionsConsts = (options) => {
  let actions = [];
  forIn(options.watch, (watchedAction) => {
    actions.push(watchedAction.action);
  });
  return actions;
};

const registerActionsDispatches = (actions, dispatch, ownProps) => {
  if (!actions) return {};

  let actionsDispatches = {};
  forIn(actions, (action, name) => {
    actionsDispatches[name] = action.dispatch(dispatch, ownProps);
  });
  return {
    actions: actionsDispatches,
    clearActionsStatuses: (actions) => dispatch(clearActionsStatuses(actions)),
  };
};

const registerActionsStatusesAndSelectors = (actions, state) => {
  if (!actions) return {};

  let requestStatuses = {};
  let actionsSelectors = {};

  forIn(actions, (action, actionName) => {
    let actionStatus = {};

    if (action.pending !== false) {
      actionStatus.pending = createLoadingSelector(action.action)(state);
    }

    if (action.error !== false) {
      actionStatus.error = createErrorSelector(action.action)(state);
    }

    if (action.success) {
      actionStatus.success = createSuccessSelector(action.action)(state);
    }

    if (action.selector) {
      actionsSelectors[actionName] = action.selector(state);
    }

    requestStatuses[actionName] = actionStatus;
  });

  let statuses = {};
  statuses.requestStatus = requestStatuses;
  if (Object.keys(actionsSelectors).length)
    statuses.actionsData = actionsSelectors;

  return statuses;
};

export default WatchActions;
