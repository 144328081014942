import { useEffect, useState } from "react";
import useAPI, { callAPIProps } from "./useAPI";

export default function useAPIMultiRequest(props: any) {

    const call = useAPI({})
    const [requests, setRequests] = useState<callAPIProps[]>([])
    
    const newRequest = (request:callAPIProps) => {
        setRequests(reqs => [...reqs, request])
    }

    useEffect(() => {
        if (requests.length > 0 && !call.loading) {
            const request = requests[0]
            call.call(request).then((res) => {
                //remove request from queue
                setRequests(reqs => reqs.filter(r => r !== request))
            })
        }
    }, [requests, call.loading]);

    return {
        newRequest
    }

}