import { Alert } from "antd";
import { useCancellationWizard } from "./SubscriptionCancellationWizard";
import FormattedMessage from "components/common/FormattedMessage";
import { StandardSubscriptionCard } from "components/common/StandardActorCard";

function SubscriptionCancellationSummaryAlert() {

    const { subscriptionId, goToStep, plan, setPlan, subscription, closeWizard } = useCancellationWizard();

    return (
        <Alert
            type="warning"
            message={
                <div>
                    <FormattedMessage
                        id="panel.subscriptions.cancellation.selectPlan.selectedSubscriptionExplanation"
                        defaultMessage="You are about to cancel:"
                    />
                    <StandardSubscriptionCard subscription={subscription} />
                </div>
            }

        />

    );
}

export default SubscriptionCancellationSummaryAlert;