import { useEffect, useState } from "react"
import useAPI, { callAPIFunction, callAPIProps, getCallAPIProps } from "./useAPI"
import useAPIWithPages from "./useAPIWithPages"
import _ from 'lodash';

export type QueryIncrementFunction = (query: any) => any;

export type useAPIWithIncrementsProps = {
    callAPI: callAPIProps | callAPIFunction,
    query?: any,
    increment?: QueryIncrementFunction, 
}

export type useAPIWithIncrementsHook = {
    startWithNewQuery: (query: any) => void,
    getNextIncrement: (increment: QueryIncrementFunction) => void,
    query: any,
    loading: boolean,
    emptyResponses: number,
}

export default function useAPIWithIncrementsNoPages(props: useAPIWithIncrementsProps): useAPIWithIncrementsHook {

    const {call, loading, error} = useAPI(typeof props.callAPI === "function" ? props.callAPI() : props.callAPI); 
    const [query, setQuery] = useState<any>(props.query);
    const [emptyResponses, setEmptyResponses] = useState<number>(0);

    const getNextIncrement = (increment: QueryIncrementFunction) => {
        const newQuery = increment ? increment(query) : (props.increment && props.increment(query));
        if (!newQuery) return;
        setQuery(newQuery);
    }

    useEffect(() => {
        callAPI();
    }, [query]);

    const callAPI = async () => {
        if (!query) return;

        const c = await call(getCallAPIProps(props.callAPI, query));
        if (c && !c.data || c.data.length === 0) {
            setEmptyResponses(emptyResponses + 1);
        }
    }

    const startWithNewQuery = (query: any) => {
        setQuery(_.cloneDeep(query));
        setEmptyResponses(0);
    }

    return {
        startWithNewQuery,
        getNextIncrement,
        query,
        loading,
        emptyResponses
    }
}