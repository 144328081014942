import FormattedMessage from "components/common/FormattedMessage";
import { ReactNode } from "react";
import { InfinityListSettingsCheckbox } from "tools/infinityList/infinityListSettingsCheckbox";
import { InfinityColumnProps } from "tools/infinityList/infinityTemplates";
import { taskTypes } from "./data/taskTypeConfig";
import TaskParticipantCard from "./TaskParticipantCard";
import AwesomeIcon from "components/common/AwesomeIcon";
import { faCheck, faCheckCircle, faHourglass, faMagnifyingGlass, faXmarkCircle } from "@fortawesome/pro-light-svg-icons";
import DateTimeDisplay from "components/displays/DateTimeDisplay";
import StandardButton from "components/common/StandardButton";
import CreationCard from "tools/creationEditor/CreationCard";
import { OgranizationGroupsDisplay } from "tools/organization/components/OrganizationGroupSelect";

export const TasksListAdvancedSettings = [
    {
        key: "filterType",
        label: (
            <FormattedMessage
                id="tasks.list.filtering.advancedSettings.filtering.type.label"
                defaultMessage="Filter by type" />
        ),
        popupClassName: "post-list-advanced-settings-dropdown",
        children: [
            {
                key: "showPostRequest",
                label: (
                    <InfinityListSettingsCheckbox
                        message={
                            <FormattedMessage
                                id="tasks.list.filtering.advancedSettings.showPostRequest.label"
                                defaultMessage="Show Post Request" />
                        }
                        path={["tasksList", "showPostRequest"]}
                    />
                )

            },
            {
                key: "showDataUpdate",
                label: (
                    <InfinityListSettingsCheckbox
                        message={
                            <FormattedMessage
                                id="tasks.list.filtering.advancedSettings.showDataUpdate.label"
                                defaultMessage="Show Data Update" />
                        }
                        path={["tasksList", "showDataUpdate"]}
                    />
                )
            },
        ]
    },
    {
        key: "filterStatus",
        label: (
            <FormattedMessage
                id="tasks.list.filtering.advancedSettings.filtering.status.label"
                defaultMessage="Filter by status" />
        ),
        popupClassName: "post-list-advanced-settings-dropdown",
        children: [
            {
                key: "pendingAcceptation",
                label: (
                    <InfinityListSettingsCheckbox
                        message={
                            <FormattedMessage
                                id="tasks.list.filtering.advancedSettings.pendingAcceptation.label"
                                defaultMessage="Pending Acceptation" />
                        }
                        path={["tasksList", "pendingAcceptation"]}
                    />
                )
            },
            {
                key: "accepted",
                label: (
                    <InfinityListSettingsCheckbox
                        message={
                            <FormattedMessage
                                id="tasks.list.filtering.advancedSettings.accepted.label"
                                defaultMessage="Accepted" />
                        }
                        path={["tasksList", "accepted"]}
                    />
                )
            },
            {
                key: "rejected",
                label: (
                    <InfinityListSettingsCheckbox
                        message={
                            <FormattedMessage
                                id="tasks.list.filtering.advancedSettings.rejected.label"
                                defaultMessage="Rejected" />
                        }
                        path={["tasksList", "Rejected"]}
                    />
                )
            },
            {
                key: "returnedForCorrection",
                label: (
                    <InfinityListSettingsCheckbox
                        message={
                            <FormattedMessage
                                id="tasks.list.filtering.advancedSettings.returnedForCorrection.label"
                                defaultMessage="Returned For Correction" />
                        }
                        path={["tasksList", "returnedForCorrection"]}
                    />
                )
            },
            {
                key: "completed",
                label: (
                    <InfinityListSettingsCheckbox
                        message={
                            <FormattedMessage
                                id="tasks.list.filtering.advancedSettings.completed.label"
                                defaultMessage="Completed" />
                        }
                        path={["tasksList", "completed"]}
                    />
                )
            },
            {
                key: "scheduled",
                label: (
                    <InfinityListSettingsCheckbox
                        message={
                            <FormattedMessage
                                id="tasks.list.filtering.advancedSettings.scheduled.label"
                                defaultMessage="Scheduled" />
                        }
                        path={["tasksList", "scheduled"]}
                    />
                )
            },
        ]
    },
]



const taskColumnHeadings: { [key: string]: ReactNode } = {
    "subject": <FormattedMessage
    id="tasks.list.header.subject"
    defaultMessage="Subject"
    />,
    "type": <FormattedMessage
        id="tasks.list.header.type"
        defaultMessage="Status"
    />,
    "status": <FormattedMessage
        id="tasks.list.header.status"
        defaultMessage="Status"
    />,
    "sender": <FormattedMessage
        id="tasks.list.header.sender"
        defaultMessage="Sender"
    />,
    "receiver": <FormattedMessage
        id="tasks.list.header.receiver"
        defaultMessage="Receiver"
    />,
    "message": <FormattedMessage
        id="tasks.list.header.message"
        defaultMessage="Message"
    />,
    "received": <FormattedMessage
        id="tasks.list.header.received"
        defaultMessage="Received"
    />,
    "resolve": <FormattedMessage
        id="tasks.list.header.resolve"
        defaultMessage="Resolve"
    />,
}

const columnSize = {
    small: ["75px", "75px", "75px", "75px", "75px", "75px"],
    standard: [false, false, "75px", "100px", "150px", "250px"],
    message: ["150px", "200px", "250px", "250px", "250px", "250px"],
    resolve: ["100px", "200px", "250px", "250px", "250px", "250px"],
    wide: ["1fr", "1fr", "1fr", "1fr", "1fr", "1fr"],
}

export const TasksListHeaders = (mode: string | undefined): InfinityColumnProps[] => {
    return [
        {
            key: 'subject',
            size: columnSize.wide,
            render: () => taskColumnHeadings['subject'],
        },
        {
            key: 'type',
            size: columnSize.standard,
            render: () => taskColumnHeadings['type'],
        },
        {
            key: 'status',
            size: columnSize.small,
            render: () => taskColumnHeadings['status'],
        },
        {
            key: 'sender',
            size: columnSize.standard,
            render: () => taskColumnHeadings['sender'],
        },
        ...(mode === 'organization'
            ? []
            : [
                {
                    key: 'receiver',
                    size: columnSize.standard,
                    render: () => taskColumnHeadings['receiver'],
                },
            ]),
        
        {
            key: 'received',
            size: columnSize.standard,
            render: () => taskColumnHeadings['received'],
        },
        {
            key: 'resolve',
            size: columnSize.resolve,
            render: () => taskColumnHeadings['resolve'],
        },
    ];
};

const TaskStatuses: any = {
    pending_acceptation: {
        title: <FormattedMessage
            id="tasks.status.pending"
            defaultMessage="Pending"
        />,
        icon: <AwesomeIcon icon={faHourglass} size="xl" />
    },
    accepted: {
        title: <FormattedMessage
            id="tasks.status.accepted"
            defaultMessage="Accepted"
        />,
        icon: <AwesomeIcon icon={faCheckCircle} size="xl" color="green" />
    },
    rejected: {
        title: <FormattedMessage
            id="tasks.status.rejected"
            defaultMessage="Rejected"
        />,
        icon: <AwesomeIcon icon={faXmarkCircle} size="xl" color="red" />
    }
}


export const TaskColumns = (mode: string | undefined, taskModal: any) => [
    {
        title: 'Subject',
        size: columnSize.wide,
        render: (record: any) => {
            let msg: string = '';
            let title: string = '';
            if (record.reject_message) {
                title = 'Rejected:';
                msg = record.reject_message;
            }
            if (record.accept_message) {
                title = 'Accepted:';
                msg = record.accept_message;
            }
            if (record.request_message) {
                title = 'Request:';
                msg = record.request_message;
            }
            return (
                <div style={{ display: 'flex', flexDirection: 'row', width: "100%" }}>
                <CreationCard 
                    mode="queue" 
                    creationId={record?.subject_id} 
                    extraInfo={c => (<div>Groups: <OgranizationGroupsDisplay groups={c?.content?.publication_target} /></div>)}
                />
                </div>
            );
        },
    },
    {
        title: 'Type',
        size: columnSize.standard,
        render: (record: any) => taskTypes.get(record?.type)?.title || 'N/A',
    },
    {
        title: 'Status',
        size: columnSize.small,
        render: (record: any) =>
            TaskStatuses[record.status] ? TaskStatuses[record.status].icon : record.status,
    },
    {
        title: 'Sender',
        size: columnSize.standard,
        render: (record: any) => <TaskParticipantCard task={record} participant="source" />,
    },
    ...(mode === 'organization' ? [] : [
        {
            title: 'Receiver',
            size: columnSize.standard,
            render: (record: any) => <TaskParticipantCard task={record} participant="target" />,
        },
    ]),
   
    {
        title: 'Received',
        size: columnSize.standard,
        render: (record: any) => (
            <DateTimeDisplay showDate showTime dateTime={record.created_at} />
        ),
    },
    {
        title: 'Resolve',
        size: columnSize.resolve,
        render: (record: any) => {
            if (record.status === 'pending_acceptation') {
                return (
                    <StandardButton
                        onClick={() => taskModal.current.open(record.id)}
                        icon={faCheck}
                    >
                        <FormattedMessage id="task.button.resolve" defaultMessage="Resolve" />
                    </StandardButton>
                );
            }
            return (
                <StandardButton
                    fullWidth
                    onClick={() => taskModal.current.open(record.id)}
                    icon={faMagnifyingGlass}
                >
                    <FormattedMessage id="common.view" defaultMessage="View" />
                </StandardButton>
            );
        },
    },
];
