import { Image, Popover, Select, Spin, Tooltip } from 'antd';
import FormattedMessage from 'components/common/FormattedMessage';
import _, { includes } from 'lodash';
import React, { useImperativeHandle } from 'react';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import useAPI, { callAPIFunction, callAPIProps } from 'services/hooks/useAPI';
import { useDebounceTrigger } from 'services/hooks/useDebounce';
import { GetGroupConfig } from './KeywordsFacebook';
import { Text } from 'components/common/StandardText';
import AwesomeIcon, { AwesomeNotOk, AwesomeOk } from 'components/common/AwesomeIcon';
import useSelectorWithParams from 'services/hooks/useSelectorWithParams';
import { BehaviorsSelector } from 'modules/smart/smartSlice';
import { Keyword } from './KeywordsConfig';
import "./keywords.less";
import { createPortal } from 'react-dom';
import { KeywordTooltip } from './KeywordFacebook';



//Ant Design Select doesnt support object values. 
//This wrapper adds that support. 

export type Props = {
    onSelect?: any,
    onOptionsLoaded?: any,
    valueIdName?: string,
    valueDisplayName?: string,
    disabled?: boolean,
    suggestionToValue?: (suggestion: any) => any,
    renderOption?: (option: any) => { value: string, label: ReactNode, disabled: boolean },
    clearOnSelect?: boolean,
    extraSuggestions?: any,
    filterOptions?: any,
    includes: Keyword[],
    excludes: Keyword[],
}
//'groups.*' => 'in:interests,behaviors,eduschool,edumajor,workemployer,workposition',

const getQuery = (q: any): callAPIProps => {

    const query = {
        q: q,
        type: "query",
        //group: "interests",
        groups: ["interests", "eduschool", "edumajor", "workemployer", "workposition"],
        ["page[size]"]: 150
    }

    return {
        url: ({ getApiUrl, serializeQuery }) => getApiUrl(`services/facebook/interests?${serializeQuery(query)}`),
        method: "GET",
        auth: true,
    }
}

const AlreadyIncluded = <FormattedMessage
    id="components.keywords.search.errors.alreadyIncluded"
    defaultMessage="Already included"
/>

const AlreadyExcluded = <FormattedMessage
    id="components.keywords.search.errors.alreadyExcluded"
    defaultMessage="Already excluded"
/>

export const KeywordLabel = (props: { keyword: Keyword, inclusion: "included" | "excluded" | null }) => {

    const { group, name, description, label } = props.keyword;
    const config = GetGroupConfig(group);

    const tooltipDisplay = () => {
        const portalDestination = document.querySelector('.tooltip-information-display');
        if (!portalDestination) return null;

        return createPortal(
            <>
                <KeywordTooltip keyword={props.keyword} />
            </>, portalDestination
        );
    };

    return (
        <div className='interests-keyword-search-label'>
            <Popover
                content={tooltipDisplay()}
                destroyTooltipOnHide={true}
                showArrow={false}
                overlayClassName="custom-popover"
            >
                <div className='interests-keyword-search-name'>
                    <Tooltip
                        title={config.displayName}
                    >
                        <AwesomeIcon icon={config.icon} className="interests-keyword-search-icon" />
                    </Tooltip>
                    <Text disabled={!!props.inclusion}>
                        {name || label}
                    </Text>
                </div>
            </Popover>
            <div className='interests-keyword-search-description'>
                <Text type={"secondary"} disabled={!!props.inclusion} className='dont-show-on-mobile' style={{
                    paddingLeft: "5px",
                    fontSize: "0.8rem",
                }}>
                    {description}
                </Text>
            </div>
            {props.inclusion === "included" && (
                <div className='inclusion-status'>
                    <Text type="secondary">
                        {AlreadyIncluded}
                    </Text>
                    <AwesomeOk />
                </div>
            )}
            {props.inclusion === "excluded" && (
                <div className='inclusion-status'>
                    <Text type="secondary">
                        {AlreadyExcluded}
                    </Text>
                    <AwesomeNotOk />
                </div>
            )}
        </div >
    )
}

const KeywordSearchWithSuggestions = React.forwardRef((props: Props, ref: any) => {

    const askForSuggestions = (q: any) => {
        setAllValues([]);
        if (q && q !== "") call(getQuery(q))
    }

    const [debounceAsk] = useDebounceTrigger(askForSuggestions, 500)
    const { call, data: suggestions, loading, clear } = useAPI(getQuery(""))
    const [allValues, setAllValues] = useState<any[]>([]);

    const behaviors = useSelectorWithParams(BehaviorsSelector)

    useEffect(() => {
        let combined = [...(suggestions || []), ...(behaviors || [])];
        setAllValues(combined)
    }, [suggestions])

    const handleSelect = (s: any) => {
        if (s && allValues) {
            const selected = allValues.find((e: any) => e.id === s);
            props.onSelect(selected);
            if (props.onSelect) props.onSelect(selected);
        } else {
            props.onSelect(s);
            if (props.onSelect) props.onSelect(s);
        }
        setAllValues([]);
    }

    useImperativeHandle(ref, () => ({
        clearSuggestions: clear,
    }))

    const options = useMemo(() => allValues && allValues.map && allValues.map((e: any) => {

        const isIncluded = props.includes.find((i: any) => i.id === e.id);
        const isExcluded = props.excludes.find((i: any) => i.id === e.id);

        return {
            ...e,
            label: <KeywordLabel keyword={e} inclusion={isIncluded ? "included" : isExcluded ? "excluded" : null} />,
            value: e.id,
            disabled: isIncluded || isExcluded,
        }
    }), [allValues])

    const filterOption = (input: string, option: any) => {
        if (option && option.group === "behaviors" && option.name) {
            if (option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0) {
                return true;
            }
            return false;
        }
        return true;
    }

    return (
        <Select
            {...props}
            popupClassName='interests-keyword-search'
            filterOption={filterOption}
            optionFilterProp='label'
            onSearch={debounceAsk}
            notFoundContent={loading ? <Spin size="small" /> : null}
            onSelect={handleSelect}
            showSearch
            value={null}
            options={loading ? [] : options}
            placement='topLeft'
            placeholder={<FormattedMessage
                id="components.keywords.groups.tootlip"
                defaultMessage="Interests and Behaviors"
            />}
            style={{ width: "100%" }}
        />
    );
});

export default KeywordSearchWithSuggestions;