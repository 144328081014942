import { Space } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import StandardButton from "components/common/StandardButton";
import WizardButtons from "components/misc/WizardButtons";
import { useCancellationWizard } from "./SubscriptionCancellationWizard";
import { SubscriptionCancellationPlanName } from "state/subscriptions/subscriptionTypes";
import SubscriptionCancellationSummaryAlert from "./SubscriptionCancellationSummaryAlert";

function SubscriptionCancellationOutstandingPaymentWarning() {
    
    const { subscriptionId, goToStep, plan, setPlan, subscription, closeWizard } = useCancellationWizard();


    const handleSelect = (selectedPlan: SubscriptionCancellationPlanName) => {
        setPlan(selectedPlan);
        goToStep("survey");
    }

    return (

        <>
                    <SubscriptionCancellationSummaryAlert />
        <Space
            direction="vertical"
            size="middle"
            style={{
                width: "100%",
                paddingTop: "20px",
            }}
        >
            <FormattedMessage
                id="panel.subscriptions.cancellation.outstandingPaymentWarning.message"
                defaultMessage="Subscription will not be cancelled if there are outstanding payments."
            />
            <StandardButton 
                fullWidth   
                onClick={() => handleSelect("closeNow")}
                >
                <FormattedMessage
                    id="panel.subscriptions.cancellation.outstandingPaymentWarning.payNow"
                    defaultMessage="Pay Now"
                />
            </StandardButton>
            <StandardButton
                fullWidth  
                onClick={() => handleSelect("cancel")}
            >
                <FormattedMessage
                    id="panel.subscriptions.cancellation.outstandingPaymentWarning.cancelAnyway"
                    defaultMessage="Cancel Anyway"
                />
            </StandardButton>
            <WizardButtons
                canGoBack={true}
                canGoForward={"hidden"}
                onBack={() => goToStep("selectPlan")}
            />
        </Space>
        </>
    );
}

export default SubscriptionCancellationOutstandingPaymentWarning;