import { ProductConfig } from "products";
import logo from "./Forsant-Role-Client.png";

import logoOwner from "./Forsant-Role-Owner.png";
import logoClient from "./Forsant-Role-Client.png";
import logoExternalPartner from "./Forsant-Role-ExternalPartner.png";
import logoInternalPartner from "./Forsant-Role-InternalPartner.png";
import logoPostManager from "./Forsant-Role-PostManager.png";
import logoDirector from "./Forsant-Role-Director.png";

import requirements from "modules/forsant/config/requirements";

const config: ProductConfig = {
  name: "Forsant.io",
  logo,
  requirements: requirements as any,
};

export const orgClient: ProductConfig = {
  name: "Organization Client",
  logo: logoClient,
  requirements: [],
}

export const orgExternalPartner: ProductConfig = {
  name: "Organization External Partner",
  logo: logoExternalPartner,
  requirements: [],
}

export const orgInternalPartner: ProductConfig = {
  name: "Organization Internal Partner",
  logo: logoInternalPartner,
  requirements: [],
}

export const orgPostManager: ProductConfig = {
  name: "Organization Manager",
  logo: logoPostManager,
  requirements: [],
}

export const orgDirector: ProductConfig = {
  name: "Organization Director",
  logo: logoDirector,
  requirements: [],
}

export const orgOwner: ProductConfig = {
  name: "Organization Owner",
  logo: logoOwner,
  requirements: [],
}

export default config;
