import {Map} from "immutable";
import {setRequestStatusReducer} from "services/helpers/api-helpers";

const loadingReducer = (state = Map({}), action) => {
    const { type } = action;
    const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);
    if (!matches) return state;

    const [, requestName, requestState] = matches;

    return setRequestStatusReducer(state, requestName, requestState === 'REQUEST', action.meta)
};

export default loadingReducer;