import { fromJS } from "immutable";

function notificationsReducer(
  state = fromJS({
      reauth: undefined
  }),
  action: any
) {
  switch (action.type) {

    case "NEED_REAUTH":
        return state.set("reauth", action?.payload.platform);

    default:
      return state;
  }
}

export default notificationsReducer;
