import { NamePath } from "antd/es/form/interface";
import _ from "lodash";
import React, { ReactNode, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useMemo } from "react";
import { APIErrorDisplay } from "services/hooks/useAPI";
import useEffectNotFirst from "services/hooks/useEffectNotFirst";
import "./relax.less";
import RelaxErrors from "./RelaxError";
import { RelaxFieldProps } from "./relaxTypes";
import { RelaxApiStatus, RelaxLabel } from "./relaxExtras";
import useCallAfterUpdate from "services/hooks/useCallAfterUpdate";
import { StandardTooltip } from "modules/panel/testing/StandardTooltip";
import useRelaxWrapper from "./useRelaxWrapper";
import RelaxDebugView from "./RelaxDebugView";



export type RelaxSingleFieldInstance = {
    submit: () => void,
    setTouched: (touched: boolean) => void,
    setErrors: (errors: any[] | null) => void,
    currentValue: any,
    validate: (v: any) => void,
}


const RelaxSingleField = React.forwardRef(({children, relaxData, ...props}: RelaxFieldProps, ref: React.Ref<RelaxSingleFieldInstance>) => {


    const rd = relaxData

    const {
        currentValue, 
        setCurrentValue,
        hasErrors,
        errors,
        settings,
        validate,
        changed,
        touched, 
        setTouched,
        parentContext, 
        hasParentContext,
        initialValue,
        setInitialValue,
        name,
        isFocused,
        setIsFocused,
        submit,
    } = rd; 
    
    useEffect(() => {
        rd.setBackFilledProps(props);
    }, []);

    
    const [extraErrors, setExtraErrors] = useState<any[] | null>(props.extraErrors ? props.extraErrors : null);
    const inputRef = useRef<any>(null);

    const { loading: apiLoading, data: apiData, error: apiError, clear: apiClear, } = rd.apiHandler;

    const _handleCall = () => {
        submit();
    }

    const handleCall = useCallAfterUpdate(_handleCall);

    useEffectNotFirst(() => {
        if (props.extraErrors === null || props.extraErrors === undefined) setExtraErrors(null);
        if (Array.isArray(props.extraErrors)) {
            setExtraErrors(props.extraErrors)
        } else {
            setExtraErrors([props.extraErrors])
        }
    }, [props.extraErrors]);

    useEffect(() => {
        //console.log("initial rendering of ", settings.name, " with value ", initialValue)
        if (settings.register && hasParentContext) {
            parentContext.handleFieldChange(name, initialValue);
        }
    }, []);
    
    useEffectNotFirst(() => {
        settings.onFocus && settings.onFocus(isFocused);
    }, [isFocused]);


    useEffectNotFirst(() => {
        if (!changed) {
            if (apiError) apiClear();
        }
    }, [changed]);

    const handleChange = (e: any) => {
        let v = (e?.target ? e.target?.value : e)
        setCurrentValue(v);
        setTouched(true);
        settings.onChange && settings.onChange(v);
        if (hasParentContext && parentContext.handleFieldChange) parentContext.handleFieldChange(name, v);
        if (settings.submitOnChange && settings.mode !== "manual") handleCall();
    }

    const handleBlur = () => {
        if (hasParentContext && parentContext.handleFieldBlur) parentContext.handleFieldBlur(name);
        if (settings.mode !== "manual" && !settings.submitOnChange) handleCall();
    }

    const setAdditionalErrors = (e: any[] | null) => {
        setExtraErrors(e);
    }

    const setValueAndSend = (v: any) => {
        setCurrentValue(v);
        setTouched(true);
        handleBlur();
    }

    const handleValidate = (v:any) => {
        return validate();
    }

    const handleForceUpdate = () => {
        handleChange(currentValue)
    }

    const handle = () => ({
        name: name,
        submit: submit,
        setErrors: setAdditionalErrors,
        currentValue: currentValue,
        setInitialValue: setInitialValue,
        validate: handleValidate,
        handleChange: handleChange,
        forceUpdate: handleForceUpdate,
        setValueAndSend: setValueAndSend,
        inputRef: inputRef,
        focus: () => (inputRef.current && inputRef.current.focus) && inputRef.current.focus(),
    })

    useImperativeHandle(ref, handle)

    const combinedErrors = useMemo(() => {
        let c:any = [];
        if (errors && errors.length > 0) c = [...c, ...errors];
        if (extraErrors && extraErrors.length > 0) c = [...c, ...extraErrors];
        return c;
    }, [errors, extraErrors, apiError])

    const tooltip = useMemo(() => {
        if (!props.tooltip) return null;
        return <StandardTooltip title={props.tooltip} link={props.tooltipLink}/>
    }, [props.tooltip, errors])


    const cloneChild = () => {

        if (React.Children.count(children) !== 1) {
            //throw new Error("RelaxField must have exactly one child");
            console.log("RelaxField must have exactly one child", name)
            return null;
        }

        const child = React.Children.only(children);
        const childProps = child.props;
        const childRef = child.ref;
        const passedRef = childRef !== null && childRef !== undefined;

        const newProps = {
            ...childProps,
            name: name,
            onChange: handleChange,
            onBlur: () => handleBlur(),
            status: hasErrors ? "error" : "",
            disabled: settings.disabled,
            value: typeof currentValue === "object" ? _.cloneDeep(currentValue) : currentValue,
            //ref: childProps?.ref
            ref: childRef ? childRef: inputRef,
        }

        const clone = React.cloneElement(child, newProps)
        return clone;

    }

    const fieldId = (settings.fieldIdPrefix ? settings.fieldIdPrefix + "-" : "") + "rf-" + (Array.isArray(name) ? name.join("-") : name);

    return (
        <>
        <div 
            className={`relax-new ${props.wrapperClass}`} 
            id={fieldId}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            style={{
                paddingBottom: "20px",
                ...settings.style,
            }}
        >
            {(settings.label || !settings.hideStatus) ? 
            (<div className="label-bar">
                <RelaxLabel label={settings.label}/>{settings.hideStatus === true ? null : <RelaxApiStatus loading={apiLoading} data={apiData} error={apiError} touched={touched} changed={changed}/>}
            </div>) : null}
            <div className={`content ${tooltip ? "with-badge" : ""}`}>
                {cloneChild()}
                <div className="badge">
                    {tooltip}
                </div>
            </div>
            <div className="error-bar">
                <RelaxErrors errors={combinedErrors} />
                <div className="error">
                <APIErrorDisplay error={apiError} />
                </div>
                <div className="extra-error">
                </div>
                {settings.debug && <RelaxDebugView relaxData={rd} />}
                {/* {typeof initialValue === "string" || typeof initialValue === "number" ? <div className="debug">{initialValue}</div> : null} */}
            </div>
        </div>
        
        </>
    )

})

const RelaxField = React.forwardRef(({children, ...props}: RelaxFieldProps, ref:any) => {

    const {relaxData: _relaxData, ref: _ref} = useRelaxWrapper({...props, ref});

    if (!_relaxData) return null;

    return (
        <RelaxSingleField ref={_ref} relaxData={_relaxData} {...props}> 
            {children}
        </RelaxSingleField>
    )

})

export default RelaxField;