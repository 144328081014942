import FormattedMessage from "components/common/FormattedMessage"
import { ReactNode } from "react"

const FormStatusLoading = <FormattedMessage
    id = "tools.relax.fieldStatus.loading"
    defaultMessage = "Loading..."
    />

const FormStatusError = <FormattedMessage
    id = "tools.relax.fieldStatus.error"
    defaultMessage = "Error"
    />

const FormStatusSaved = <FormattedMessage
    id = "tools.relax.fieldStatus.saved"
    defaultMessage = "Saved"
    />

const FormStatusUnsavedChanges = <FormattedMessage
    id = "tools.relax.fieldStatus.unsavedChanges"
    defaultMessage = "Unsaved changes"
    />

export const RelaxApiStatus = (props: { loading: boolean, data: any, error: any, touched: boolean | null | undefined, changed: boolean | null | undefined }) => {

    if (props.loading) return (
        <div className="relax-sending-status">
            {props.loading && <div className="loading">{FormStatusLoading}</div>}
            {props.error && <div className="error">{FormStatusError}</div>}
        </div>
    )

    if (props.changed) {
        
        if (props.loading || props.error)  return (
            <div className="relax-sending-status">
                {props.loading && <div className="loading">{FormStatusLoading}</div>}
                {props.error && <div className="error">{FormStatusError}</div>}
            </div>
        )

        return (
            <div className="relax-sending-status">
                {props.changed && <div className="changed">{FormStatusUnsavedChanges}</div>}
            </div>
        )
    }

    if (props.data) return (
        <div className="relax-sending-status">
            {props.data && <div className="success">{FormStatusSaved}</div>}
        </div>
    )

    return (
        <div className="relax-sending-status">
            {props.loading && <div className="loading">{FormStatusLoading}</div>}
            {props.error && <div className="error">{FormStatusError}</div>}
            {props.data && <div className="success">{FormStatusSaved}</div>}
            {props.changed && <div className="changed">{FormStatusUnsavedChanges}</div>}
        </div>
    )
}


export const RelaxLabel = (props: {label: ReactNode}) => {
    return <label> {props.label} {" "} </label>
}