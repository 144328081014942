import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { getApiUrl } from "services/helpers/api-helpers";
import { TokenLocalStorage } from "services/helpers/local-storage";
import { GetFileType } from "tools/gallery/imageHelpers";
import useAPISmartActions from "./useAPISmartActions";
import useCallAfterUpdate from "./useCallAfterUpdate";


export type ChunkUpload = {
    file: File;
    fileName: string;
    id: string;
    progress: number;
    error: boolean;
    numberOfChunks: number;
    currentChunk: number;
    url?:string;
    uuid?: string;
    type: "image" | "video" | "file",
    controller: AbortController,
    errorMsg?: string,
    state: "initial" | "chunkStart" | "uploading" | "chunkDone" | "complete" | "error" | "canceled"
}

export type UseUploadProps = {
    onUpload?: (file: Partial<ChunkUpload>) => void;
    smartActionName?: string;
}

const MB = 1024 * 1024;
const chunkSize = 10 * MB;
const url = getApiUrl("storage/chunk-upload")

const controller = new AbortController();

export default function useUploadFile(props?: UseUploadProps) {

    const token = useMemo(() => {
        const ts: any = TokenLocalStorage.get();
        if (!ts) return {}
        return {
          token_type: ts.token_type,
          token: ts.token,
        }
      }, [])
    

    const [uploads, setUploads] = useState<Map<string, ChunkUpload>>(new Map());
    const [controllers, setControllers] = useState<Map<string, AbortController>>(new Map());
    const {registerAction, resolveAction, getAction} = useAPISmartActions();
    const resolveActionAfterUpdate = useCallAfterUpdate((val: any) => {
        //console.log("Call after update with val", val);
        //console.log("Uplaods", uploads);
        
        if (!props?.smartActionName) return;

        const uploadsArray = Array.from(uploads.values());
        if (uploadsArray.length === 0) {
            //console.log("no more uploads")

            const action = getAction(props.smartActionName);
            if (action) {
                //console.log("Action found", action)
            }

            resolveAction(props?.smartActionName, "success", null);
        }
    
    })


    const registerUpload = () => {
        if (props?.smartActionName) {
            registerAction(props.smartActionName)
        }
    }


    useEffect(() => {
        const uploadsArray = Array.from(uploads.values());

        const uploadsGroupedByState = uploadsArray.reduce((acc, upload) => {
            if (!acc[upload.state]) acc[upload.state] = [];
            acc[upload.state].push(upload);
            return acc;
        }, {} as any)

       // console.log("Uploads grouped by state", uploadsGroupedByState)

        const isWorking = uploadsGroupedByState["chunkStart"]?.length > 0 || uploadsGroupedByState["uploading"]?.length > 0 || uploadsGroupedByState["chunkDone"]?.length > 0;
        const hasUploadsInQueue = uploadsGroupedByState["initial"] && uploadsGroupedByState["initial"].length > 0;

        //console.log("Is working", isWorking, "Has uploads in queue", hasUploadsInQueue);

        if (uploadsGroupedByState["chunkDone"] && uploadsGroupedByState["chunkDone"].length > 0) {
            const upload = uploadsGroupedByState["chunkDone"][0];
            //console.log("Next chunk", upload.id);
            UploadChunk(upload.id);
            return;
        }

        if (uploadsGroupedByState["complete"] && uploadsGroupedByState["complete"].length > 0) {
            const upload = uploadsGroupedByState["complete"][0];
            //console.log("Upload complete", upload.id);
            resolveActionAfterUpdate(upload.id);
            if (props && props.onUpload) props.onUpload(upload);
            setUploads(prev => {
                prev.delete(upload.id)
                return new Map(prev);
            });
            return;
        }

        //if not working and has uploads in queue
        if (!isWorking && hasUploadsInQueue) {
            const upload = uploadsGroupedByState["initial"][0];
            //console.log("Staring upload", upload.id);
            registerUpload();
            UploadChunk(upload.id);
            return;
        } 


    }, [uploads]);


    const UpdateUpload = (id: string, update: Partial<ChunkUpload>, reason?:string) => {
      //  console.log("UpdateUpload", id, reason);
        const upload = uploads.get(id);
        const updated = {...upload, ...update}
        setUploads((prev) => {
            prev.set(id, updated as any)
            return new Map(prev);
        });
        return updated;
    }

    const UploadChunk = async (fileId:string) => {

        const file = uploads.get(fileId);
        if (!file) return;

        const start = file.currentChunk * chunkSize;
        const chunkBlob = file.file.slice(start, start + chunkSize + 0)

        const fd = new FormData();
        fd.append('file', chunkBlob)
        fd.append("chunk", file.currentChunk + "");
        fd.append("chunks", file.numberOfChunks + "");
        fd.append("file_hash", fileId);
        //console.log("Uploading Chunk", fileId, `${file.currentChunk}/${file.numberOfChunks}`)
        
        const controller = new AbortController();
        setControllers(prev => {
            prev.set(fileId, controller);
            return new Map(prev);
        })

        UpdateUpload(fileId, {currentChunk: file.currentChunk, state: "chunkStart"}, "chunkStart");

        await axios.request({
            signal: controller.signal,
            method: "post", 
            url: url, 
            data: fd, 
            headers: { Authorization: `${token.token_type} ${token.token}` },
            onUploadProgress: (p) => {
                const progress = ((file.currentChunk + (p.loaded / p.total)) / file.numberOfChunks);
                
                UpdateUpload(fileId, {
                    progress: file.currentChunk / file.numberOfChunks,
                    currentChunk: file.currentChunk,
                    state: "uploading"
                }, "uploading");
            }
        }).then (data => {
           // console.log("Chunk uploaded", data)
            if (data?.data?.data?.attributes?.url) {
                UpdateUpload(fileId, {url: data.data.data.attributes.url, state: "complete", uuid: data.data.data.attributes.uuid}, "complete");
            } else {
                UpdateUpload(fileId, {
                    currentChunk: file.currentChunk+1, 
                    state: "chunkDone", 
                    progress: file.currentChunk / file.numberOfChunks
                }, "chunkDone");
            }
        }).catch(err => {
            console.log("error while uploading chunk", err);
            CancelUpload(fileId);
            UpdateUpload(fileId, {error: true, progress: 0, state: "error", errorMsg: err?.message}, "error");
        })
    }


    const UploadFile = async (file: any) => {
        if (!file) return;

        const controller = new AbortController();
        setControllers(prev => {
            prev.set(file.id, controller);
            return prev;
        })

        const numberOfChunks = Math.ceil(file.size / chunkSize);

        UpdateUpload(file.uid, {
            file: file,
            fileName: file.name,
            id: file.uid,
            type: GetFileType(file),
            progress: 0,
            error: false,
            numberOfChunks: numberOfChunks,
            currentChunk: 0,
            controller: controller,
            state: "initial"
        }, "start");

    }

    const CancelUpload = (id: string) => {
        const controller = controllers.get(id);
        if (!controller) return;
        controller.abort();
    }

    const CancelAllUploads = () => {
        Array.from(controllers.values()).forEach((controller) => {
            const res = controller.abort()
        });
    }

    const RemoveFile = (fileUrl: string) => {
        setUploads((prev) => {
            prev.forEach((upload, key) => {
                if (upload.url === fileUrl) {
                    prev.delete(key);
                }
            })
            return new Map(prev);
        })
    }

    return {
        uploads,
        UploadFile,
        UpdateUpload,
        CancelUpload,
        CancelAllUploads,
        RemoveFile,
    }

}