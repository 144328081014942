import { faGrid2 } from "@fortawesome/pro-light-svg-icons";
import AwesomeIcon from "components/common/AwesomeIcon";
import { StandardDrawer, useStandardDrawer } from "services/hooks/useDrawer";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { projectsSelector } from "state/projects/projectsSelectors";
import { ProjectListItemBasic, ProjectListItemFull } from "./ProjectListItem";
import { currentProjectIdSelector } from "services/store/scopeSlice";
import useScope from "services/hooks/useScope";

const ProjectsDrawer = () => {

    const [drawerRef, open, close, isOpen] = useStandardDrawer();
    const projects = useSelectorWithParams(projectsSelector);
    const scope = useScope();

    const handleSelect = () => {
        close();
    }

    return (
        <>
            <StandardDrawer
                ref={drawerRef}
                title="Projects"
                width={600}
                layout="standard"
            >
                    {Object.values(projects).map((project: any) => {
                        return (
                            <ProjectListItemBasic
                                key={project.id}
                                projectId={project.id}
                                onClick={handleSelect}
                            />
                        );
                    })}
            </StandardDrawer>
            <a onClick={open}>
                <AwesomeIcon icon={faGrid2} size="3x" color="black" />
            </a>
        </>
    )

}

export default ProjectsDrawer;