import { Spin, Table } from "antd";
import { HorizontalCenter } from "components/common/Center";
import { useEffect } from "react";
import useAPI, { callAPIProps } from "services/hooks/useAPI";

const getKeywordsStats:callAPIProps = {
    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/google/keywords/stats`),
    // fakeResponse: async (request) => ({
    //     status: 200,
    //     json: () => ({
    //         data: [
    //             {
    //                 "type": "google-keyword-stats",
    //                 "attributes": {
    //                   "campaign_id": 20426018788,
    //                   "campaign_name": "[Forsant API] - search campaign 1691066757",
    //                   "ad_group_id": 152826805100,
    //                   "ad_group_name": "[Forsant API] - search campaign  AdGroup1691066758",
    //                   "criterion_id": 297494130956,
    //                   "keyword_text": "atrakcje dla dzieci",
    //                   "keyword_match_type": "BROAD_MATCH",
    //                   "impressions": 2263,
    //                   "clicks": 376,
    //                   "cost_micros": 258.63
    //                 }
    //               }
    //         ]
    //     })
    //})
}

type GoogleKeywordStats = {
    type: string,
    campaign_id: number,
    campaign_name: string,
    ad_group_id: number,
    ad_group_name: string,
    criterion_id: number,
    keyword_text: string,
    keyword_match_type: string,
    impressions: number,
    clicks: number,
    cost_micros: number
}


const keywordStatsColumns = [
    {
        key: "campaignName",
        title: "Campaign",
        dataIndex: "campaign_name"
    },
    // {
    //     key: "adGroupName",
    //     title: "Ad Group",
    //     dataIndex: "ad_group_name"
    // },
    {
        key: "keywordText",
        title: "Keyword",
        dataIndex: "keyword_text"
    },
    {
        key: "keywordMatchType",
        title: "Match Type",
        dataIndex: "keyword_match_type"
    },
    {
        key: "impressions",
        title: "Impressions",
        dataIndex: "impressions"
    },
    {
        key: "clicks",
        title: "Clicks",
        dataIndex: "clicks"
    },
    {
        key: "costMicros",
        title: "Cost",
        dataIndex: "cost_micros"
    }
]

const GoogleKeywordStats = () => {

    const statsCall = useAPI(getKeywordsStats, true);

    if (statsCall.loading || statsCall.error) return (
        statsCall.StatusDisplay
    )
    return (
        <Table
            pagination={false}
            columns={keywordStatsColumns}
            dataSource={statsCall.data}
            />
    )

}

export default GoogleKeywordStats;