import { Table } from "antd";
import AwesomeIcon, { AwesomeNotOk, AwesomeOk } from "components/common/AwesomeIcon";
import StandardActorCard from "components/common/StandardActorCard";
import { useEffect, useMemo } from "react";
import useSelectorWithParams from "services/hooks/useSelectorWithParams"
import { OrganizationGroupSelector, OrganizationGroupsSelector, OrganizationProjectSelector, OrganizationProjectsSelector } from "../data/organizationSlice"
import { IsProjectInGroup } from "../tools/OrganizationToolHelpers";
import useOrganizationGroupHelper from "../tools/useOrganizationGroupHelper";


const baseColumns: any = [
    {
        title: "Project",
        render: (text: any, record: any) => <StandardActorCard actor={record} />,
        width: "250px"
    },
]

const ProjectInGroupDisplay = (props: { projectId: string, groupId: string, onRemove: () => void, onAdd: () => void }) => {

    const group = useSelectorWithParams([OrganizationGroupSelector, props.groupId])
    const inGroup = IsProjectInGroup(props.projectId, group)

    if (inGroup) return <AwesomeOk color="green" size="2xl" onClick={() => props.onRemove()} />
    return <AwesomeNotOk color="grey" size="2xl" onClick={() => props.onAdd()} />

}

export default function OrganizationTableFull(props: any) {

    const projects = useSelectorWithParams(OrganizationProjectsSelector)
    const groups = useSelectorWithParams(OrganizationGroupsSelector)
    const { addProjectToGroup, removeProjectFromGroup } = useOrganizationGroupHelper();

    const adjustedData = useMemo(() => {
        if (!projects || !groups) return null;
        if (projects.length === 0 || groups.length === 0) return null;
        return projects;
    }, [projects, groups]);

    const adjustedColumns = useMemo(() => {

        if (!groups) return baseColumns;
        if (groups.length === 0) return baseColumns;

        const cols = baseColumns;

        groups.forEach((group: any) => {
            cols.push({
                title: group.name,
                render: (text: any, record: any) => {
                    return (
                        <ProjectInGroupDisplay
                            projectId={record.id}
                            groupId={group.id}
                            onAdd={() => addProjectToGroup(record.id, group.id)}
                            onRemove={() => removeProjectFromGroup(record.id, group.id)}
                        />
                    )
                },
                width: "100px"
            })
        })

        return cols;
    }, [groups, projects])

    // useEffect(() => {
    //     groups && groups.forEach((group: any) => {
    //         console.log(group.name, ": ", group.projects && group.projects.map((project: any) => project.name))
    //     })
    // }, [groups]);


    if (!projects || !groups) return null;


    return (
        <Table dataSource={adjustedData} columns={adjustedColumns} rowKey="id" pagination={false} scroll={{ x: "max-content" }} />
    )
}