import TemplateRow from "tools/infinityList/TemplateRow";
import { DefaultColumnsBots } from "./BotsHeadigs";

const BotsListEntry =({ item }: { item: any }) => {

    const columns = DefaultColumnsBots;

    return (
        <div className={`infinity-item-wrapper`}>
        <div className="ant-collapse post-entry-content" >
        <TemplateRow size = "medium" item={item} columns={columns}  />
        </div>
        </div>

    );

}

export default BotsListEntry