import { SmsApiIcon, FreshMailIcon } from "modules/panel/components/ft-icons";
import FormattedMessage from "components/common/FormattedMessage";
import { useSelector } from "react-redux";
import { Avatar } from "antd";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { currentProjectSelector } from "state/projects/projectSelectors";
import AwesomeIcon from "components/common/AwesomeIcon";
import { faEnvelope } from "@fortawesome/pro-light-svg-icons";

export type IntegrationTypes = "sms-api" | "fresh-mail" | "mailer-lite" | undefined;

type Props = {
  type: IntegrationTypes;
};

export const IntegrationIndicator = ({ type }: Props) => {
  const project = useSelectorWithParams(currentProjectSelector);

  const renderIntegrationContent = () => {
    switch (type) {
      case "sms-api":
        return (
          <>
            <SmsApiIcon /> <span className="integration-type">SMS API</span>
          </>
        );
      case "fresh-mail":
        return (
          <>
            <FreshMailIcon /> <span className="integration-type">Freshmail</span>
          </>
        );
      case "mailer-lite":
        return (
          <>
            <AwesomeIcon size="2x" icon={faEnvelope} />
            <span className="integration-type">MailerLite</span>
          </>
        );
      default:
        return (
          <>
            <Avatar shape="circle" src={project.image} />
            <span className="integration-type">
              <FormattedMessage
                id="apps.bot.components.integrationType.simpleReply"
                defaultMessage="Simple reply"
              />
            </span>
          </>
        );
    }
  };

  return <>{renderIntegrationContent()}</>;
};
