import { useMemo } from "react"
import { useGallery } from "./Gallery";
import { StandardResult } from "components/common/StandardResults";
import FormattedMessage from "components/common/FormattedMessage";
import { roundToDecimalPlaces } from "services/helpers/js-helpers";
import { ImageCropHelpText } from "./imageCropHelp";




export default function ImageUploadErrorDisplay(props: any) {

    const { errors } = useGallery();

    const getErrorMessageByErrorCode = (error: string) => {

        switch (error) {
            case "WRONG_RESOLUTION":
                return <FormattedMessage
                    id="apps.tools.imageGallery.errors.wrongResolution"
                    defaultMessage="Wrong resolution"
                    />;
            case "WRONG_FILE_TYPE":
                return <FormattedMessage
                    id="apps.tools.imageGallery.errors.wrongFileType"
                    defaultMessage="Wrong file type"
                    />;
            case "FILE_TOO_BIG":
                return <FormattedMessage
                    id="apps.tools.imageGallery.errors.fileTooBig"
                    defaultMessage="File too big"
                    />;
            case "FILE_TOO_SMALL":
                return <FormattedMessage
                    id="apps.tools.imageGallery.errors.fileTooSmall"
                    defaultMessage="File too small"
                    />;
        }

        return "";

    }

    const getErrorExtraInfo = (error: string, extra: any) => {

        console.log("error", error, extra)

        switch (error) {
            case "WRONG_RESOLUTION":
                return (
                    <><FormattedMessage
                        id="apps.tools.imageGallery.errors.wrongResolution.extra"
                        defaultMessage="File resolution is {width}px by {height}px."
                        values={{
                            width: extra?.width,
                            height: extra?.height
                        }}
                    />
                        <ImageCropHelpText />
                    </>)
            case "FILE_TOO_BIG":
                return <FormattedMessage
                    id="apps.tools.imageGallery.errors.fileTooBig.extra"
                    defaultMessage="File size is {size} MB, maximum allowed size is {maxSize} MB"
                    values={{
                        size: roundToDecimalPlaces(extra?.fileSize / 1000 / 1000, 2),
                        maxSize: roundToDecimalPlaces(extra?.maxSize / 1000 / 1000, 2)
                    }}
                />
        }

        return null;

    }

    const errorList = useMemo(() => {
        if (!errors) return;
        if (errors.length === 0) return;
        return errors.map((e: any) => (
            <StandardResult
                result="error"
                message={<div>{e?.file?.name}{" - "}{getErrorMessageByErrorCode(e?.error?.error)}</div>}
                extra={getErrorExtraInfo(e?.error?.error, e?.error?.extra)}
            />
        ))
    }, [errors])

    return (
        <div
            className="error-list"
        >
            {errorList}
        </div>
    )


}