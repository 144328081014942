import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import TextEditor from "tools/textEditor/textEditor";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import RelaxControls from "tools/relaxForm/RelaxControls";
import useRelaxData from "tools/relaxForm/useRelaxData";
import { UpdateWebsiteTemaplate, WebsiteSelector } from "../reducers/websiteSlice";

export default function TermsOfServiceEditor() {
  const website = useSelectorWithParams([WebsiteSelector]);
  const rd = useRelaxData({
    initialValues: { tos: website.tos || "" },
  });

  return (
    <>
      <RelaxSingleField
        name="tos"
        relaxData={rd}
        callAPI={UpdateWebsiteTemaplate}
      >
        <TextEditor />
      </RelaxSingleField>
      <RelaxControls relaxData={rd} />
    </>
  );
}
