import { Button, Input, InputNumber, Tooltip } from "antd";
import Map, { CountryPolygon } from "components/map/Map";
import "./geo-points.less";
import useAPI, { callAPIProps } from "services/hooks/useAPI";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import SelectWithSuggestions from "components/forms/SelectWithSuggestions";
import { useGoogleGeocoder } from "services/hooks/useGoogleGeocoder";
import GeoPointsEntryFacebook from "./GeoPointsEntryFacebook";
import { TooltipIcon } from "components/common/tooltip-icon/TooltipIcon";
import FormattedMessage from "components/common/FormattedMessage";
import AwesomeIcon from "components/common/AwesomeIcon";
import { faArrowsToCircle, faExpand, faLocationDot, faLocationPin } from "@fortawesome/pro-light-svg-icons";
import React from "react";
import _ from "lodash";
import { MapPointImportToolModal } from "./MapPointImportTool";
import { StandardModal, useStandardModal } from "services/hooks/useModal";



//0 or min 17 max 80 
// pins min 1 max 80

type GeoPoint = {
  id: string,
  type: "city" | "region" | "country",
  name: string,
  region?: string,
  country_name: string,
}


const isPointInPoint = (point: any, pointToCheck: any) => {
  if (!point || !pointToCheck) return false;
  const pointType = point.data.attributes.type;
  const ptcType = pointToCheck.data.attributes.type;

  if (pointType === "country" && ptcType === "country") return false;
  if (ptcType === "country") {
    return point.data.attributes.country_code === pointToCheck.data?.attributes.country_code;
  }
  if (ptcType === "region") {

    const rid = parseInt(point.data.attributes.region_id);
    const ptcRid = parseInt(pointToCheck.data.attributes.id);
    if (isNaN(rid) || isNaN(ptcRid)) return false;
    return rid === ptcRid && point.data.attributes.country_code === pointToCheck.data.attributes.country_code;
  }
  if (ptcType === "city") {
    return false;
  }
}

const isPointInPointFromFBQuery = (point: any, pointToCheck: any) => {
  if (!point || !pointToCheck) return false;

  const pointType = point.type;
  const ptcType = pointToCheck?.data?.attributes.type;

  if (pointType === "country" && ptcType === "country") return false;
  if (ptcType === "country") {
    //return point.country_name === pointToCheck.name;
    return point.country_code === pointToCheck.data?.attributes?.country_code;
  }
  if (ptcType === "region") {

    const rid = parseInt(point.region_id);
    const ptcRid = parseInt(pointToCheck.data.attributes.id);
    if (isNaN(rid) || isNaN(ptcRid)) return false;
    return rid === ptcRid && point.country_code === pointToCheck.data?.attributes?.country_code;
  }
  if (ptcType === "city") {
    return false;
  }
}

const isPointInAnyRegion = (point: GeoPoint, regions: GeoPoint[]) => {
  return regions.some((region) => isPointInPointFromFBQuery(point, region));
}


export type GeoPointsInputProps = {
  onChange?: (geoPoints: any) => void;
  value?: any[];
  onBlur?: (e: any) => void;
}

type RunFunctionAfterOKProps = {
  fun: any,
  runFun: boolean,
  params: any[] | {}
}

const getLabel = (point: any) => {
  if (point.type === "country") return point.name;
  if (point.type === "region") return `${point.name}, ${point.country_name}`;
  if (point.type === "city") return `${point.name}, ${point.region}, ${point.country_name}`;
  return point.name;
}

const filterPointsAfterAdding = (point: any, values: any) => {
  const type = point?.data?.attributes?.type;
  let filtered: any[] = []

  if (type === "country" || type === "region") {
    filtered = values.filter((value: any) => {
      if (value?.data?.attributes?.type === "custom_location") return true;
      return !isPointInPoint(value, point)
    });
  } else {
    filtered = values;
  }

  return [...filtered, point];
}

const renderSuggestion = (suggestion: any, values: any) => {
  const isInRegion = isPointInAnyRegion(suggestion, values);
  const isAlreadyAdded = values.some((value: any) => suggestion.key === value.data.attributes.key);
  const isMoreThanOneContry = values.some((value: any) => value.data.attributes.country_code !== suggestion.country_code);
  return {
    label: getLabel(suggestion),
    value: suggestion.id,
    disabled: isInRegion || isAlreadyAdded || isMoreThanOneContry,
  }
}

export default function GeoPointsInputFacebook(props: GeoPointsInputProps) {

  const { search, results } = useGoogleGeocoder({});
  const mapRef = React.useRef(null);
  const [points, setPoints] = useState<any[]>([]);
  const [isCustomPoints, setIsCustomPoints] = useState<boolean>(points.every((point) => point?.data?.attributes?.type === 'custom_location'))
  const [ref, open, close] = useStandardModal();
  const [runFunctionAfterOK, setRunFunctionAfterOK] = useState<RunFunctionAfterOKProps>({
    fun: null,
    runFun: false,
    params: []
  })

  const pointsRef: any = useRef()

  useEffect(() => {
    if (points.length !== 0) {
      setIsCustomPoints(points.every((point) => point?.data?.attributes?.type === 'custom_location'))
    } else {
      setIsCustomPoints(false)
    }
    pointsRef.current = _.cloneDeep(points);
  }, [points])

  const adjustPoint = (point: any, geoData: any) => {

    const pointType = isCustomPoints ? 'custom_location' : point.type;
    return {
      address: `${point.name}, ${point.region}, ${point.country_name}`,
      area: 0,
      city: point.name,
      country: point.country_code,
      radius: point.radius || 17,
      geometry: point.geometry,
      longitude: geoData?.geometry?.location?.lng(),
      latitude: geoData?.geometry?.location?.lat(),
      data: {
        attributes: { ...point, type: pointType }
      }
    }
  }

  const handleChange = (newPoints: any[]) => {
    pointsRef.current = _.cloneDeep(newPoints)

    setPoints((ps: any) => {
      const np = _.cloneDeep(newPoints);
      if (_.isEqual(ps, np)) return ps;
      props.onChange && props.onChange(np);
      return np;
    })
  }

  useEffect(() => {
    if (!props.value) return;
    if (_.isEqual(points, props.value)) return;
    setPoints(props.value);
  }, [props.value]);

  const handleBlur = () => {
    props.onBlur && props.onBlur(null);
  }

  const handleNewPointSelected = async (point: any) => {

    if (!point) return;
    if (typeof point === "string") return;

    let res = null;

    try {
      res = await search(`${point.name}, ${point.region}, ${point.country_name}`)
    } catch (e) {
      res = point;
    }
    const newPoint = adjustPoint(point, res?.results?.[0]);

    const filtered = filterPointsAfterAdding(newPoint, pointsRef.current || [])

    mapRef?.current?.frameView();

    handleChange(filtered);
    handleBlur();

    try {
      res = await search(`${point.name}, ${point.region}, ${point.country_name}`)
    } catch (e) {
      res = point;
    }

  }

  const removePoint = (point: any) => {
    if (!points) return;
    const newPoints = points.filter((p: any) => p.data.attributes.key !== point.data.attributes.key);
    handleChange(newPoints);
    handleBlur();
  }

  const handlePointChange = (point: any) => {
    if (!points) return;
    const newPoints = points && points.map((p: any) => p.data.attributes.key === point.data.attributes.key ? point : p);
    handleChange(newPoints);
    handleBlur();
  }

  const handlePointDrag = (point: any, longitude: any, latitude: any) => {
    const currentPoints = pointsRef.current !== undefined ? pointsRef.current : points

    if (currentPoints.some((point: any) => ['city', 'region', 'country'].includes(point?.data?.attributes?.type))) {
      setRunFunctionAfterOK({ ...runFunctionAfterOK, fun: handlePointDrag, params: {point, longitude, latitude} });
      return open()
    }
    let newPoint = currentPoints && currentPoints.find((p: any) => p.data.attributes.key === point?.meta?.data?.attributes?.key);

    if (!newPoint) newPoint = {}

    const isPoint = newPoint.data?.attributes?.type === "custom_location";

    newPoint = {
      id: newPoint.id || Math.floor(Math.random() * 100000000000000000),
      name: isPoint ? newPoint.name : newPoint.data?.attributes?.name,
      address: isPoint ? newPoint.address : newPoint.data?.attributes?.name,
      radius: newPoint.radius,
      smart_id: newPoint.smart_id,
      type: "include",
      latitude: latitude,
      longitude: longitude,
      data: {
        attributes: {
          key: point?.meta?.data?.attributes?.key || Math.floor(Math.random() * 100000000000000000),
          longitude,
          latitude,
          type: "custom_location",
          country_code: "PL"
        }
      }
    }

    const newPoints = currentPoints && currentPoints.map((p: any) => p.data.attributes.key === point?.meta?.data?.attributes?.key ? newPoint : p);

    handleChange(newPoints);
    handleBlur();

  }

  const mappedPoints = useMemo(() => {

    if (!points) return [];
    return (points || []).map((point: any) => {

      const id = point?.data?.attributes?.key || point?.id || "point";

      return (
        <GeoPointsEntryFacebook
          key={id}
          point={_.cloneDeep(point)}
          onRemove={removePoint}
          onChange={handlePointChange}
        />
      )
    })
  }, [points])

  const mappedCircles = useMemo(() => {
    if (!points) return [];

    return (points)
      .filter((point: any) => point?.data?.attributes?.type !== "country")
      .map((point: any) => {
        if (!point.longitude || !point.latitude) return null;
        return {
          position: {
            lat: point.latitude,
            lng: point.longitude
          },
          shape: "MarkerShapeCircle",
          radius: point.radius * 1000,
        }
      }).filter((p: any) => p !== null);

  }, [points])


  const mappedMarkers = useMemo(() => {
    if (!points) return [];

    return (points)
      .map((point: any) => {
        if (!point.longitude || !point.latitude) return null;

        let label: any;
        if (point?.data?.attributes?.type === "custom_location") {
          label = {
            text: point.address,
            fontSize: "16px",
          }
        }
        // if (point?.data?.attributes?.type === "country") {
        //   label = {
        //       text: point?.data?.attributes?.name,
        //       fontSize: "16px",
        //     }
        // }

        return {
          position: {
            lat: point.latitude,
            lng: point.longitude
          },
          draggable: true,
          meta: point,
          label
        }
      }).filter((p: any) => p !== null);

  }, [points])

  const mappedRegions = useMemo(() => {
    if (!points) return [];
    return points.filter((point: any) => point?.data?.attributes?.type === "country").map(r => {
      return r.data.attributes.country_code
    })
  }, [points])


  const handleOk = () => {
    if (runFunctionAfterOK.fun !== null) {
      setRunFunctionAfterOK({ ...runFunctionAfterOK, runFun: true })
    }

    let regionPoints = points && points.filter((p: any) => p.data.attributes.type !== 'custom_location');

    regionPoints = regionPoints.map((p: any) => ({
      id: p.data?.attributes?.id,
      name: p.name,
      address: p.data?.attributes?.name,
      radius: p.radius,
      smart_id: p.smart_id,
      type: "include",
      latitude: p.latitude,
      longitude: p.longitude,
      data: {
        attributes: {
          key: p.data.attributes.key,
          latitude: p.latitude,
          longitude: p.longitude,
          type: "custom_location",
          country_code: "PL"
        }
      }
    }));

    const newPoints = points && points.map((p: any) => {
      const updatedPoint = regionPoints.find((rp: any) => rp.id === p.data?.attributes?.id);
      return updatedPoint ? updatedPoint : p;
    });

    pointsRef.current = _.cloneDeep(newPoints)

    close();
  }

  useEffect(() => {
    if (runFunctionAfterOK.runFun === true && runFunctionAfterOK.fun !== null) {
      if (Array.isArray(runFunctionAfterOK.params)) {
        runFunctionAfterOK.fun(runFunctionAfterOK.params)
      } else if (typeof runFunctionAfterOK.params === 'object') {
        const paramsArray = Object.values(runFunctionAfterOK.params);
        runFunctionAfterOK.fun(...paramsArray)
      } else {
        runFunctionAfterOK.fun()
      }

      handleChange(pointsRef.current);
      handleBlur();
      setRunFunctionAfterOK({ fun: null, runFun: false, params: [] })
    }
  }, [runFunctionAfterOK])

  const warningPointsModal = (pointType: string[], fun: any, e?: any) => {
    if (!points) return null
    if (points.some((point) => pointType.includes(point?.data?.attributes?.type))) {
      if (e){
        setRunFunctionAfterOK({ ...runFunctionAfterOK, fun: fun, params: e })
      }else{
        setRunFunctionAfterOK({ ...runFunctionAfterOK, fun: fun })
      }
      open()
    } else {
      if (e) return fun(e)
      return fun()
    }
  }

  const addPinInTheMiddle = () => {
    if (!mapRef.current) return;
    const center = mapRef.current.map.getCenter();
    const lat = center.lat();
    const lng = center.lng();
    const newPoint: any = {
      id: Math.floor(Math.random() * 100000000000000000),
      name: "point",
      address: "point",
      radius: 17,
      type: "include",
      latitude: lat,
      longitude: lng,
      data: {
        attributes: {
          key: Math.floor(Math.random() * 100000000000000000),
          longitude: lng,
          latitude: lat,
          type: "custom_location",
          country_code: "PL"
        }
      }
    }

    let newPoints = _.cloneDeep(pointsRef.current) || [];
    newPoints.push(newPoint);
    handleChange(newPoints);
    handleBlur();

    mapRef?.current?.frameView();

  }

  const handleBulkAddPoints = (addedPoints: any) => {
    if (!addedPoints || addedPoints.length === 0) return;

    let newPoints = _.cloneDeep(pointsRef.current) || [];

    addedPoints.forEach((point: any, index: number) => {

      try {
        const latitude = parseFloat(point.latitude);
        const longitude = parseFloat(point.longitude);
        const radius = parseInt(point.radius);

        if (isNaN(latitude) || isNaN(longitude) || isNaN(radius)) return;

        newPoints.push({
          id: Math.floor(Math.random() * 100000000000000000),
          name: point.name,
          address: point.name,
          radius: radius,
          type: "include",
          latitude: latitude,
          longitude: longitude,
          data: {
            attributes: {
              key: Math.floor(Math.random() * 100000000000000000),
              longitude: longitude,
              latitude: latitude,
              type: "custom_location",
              country_code: "PL"
            }
          }
        })
      } catch (e) {
        console.error("error while parsing geopoints", e);
      }

    })

    handleChange(newPoints);
    handleBlur();

    mapRef?.current?.frameView();

  }

  return (
    <>
      <StandardModal
        ref={ref}
        width={"600px"}
        onCancel={close}
        title={<FormattedMessage
          id="apps.smart.components.adsSettings.GeoPointsFacebook.warringNoCityPoints.modal.title"
          defaultMessage="Warrnig"
        />}
        onOk={handleOk}
      >
        <FormattedMessage
          id="apps.smart.components.adsSettings.GeoPointsFacebook.warringNoCityPoints.modal.description"
          defaultMessage="You can only have points of the same type. Either region points or custom points. Do you want to change all points to custom type?" />
      </StandardModal>

      <div className="new-geo-points">
        <div className="input-container">
          <MapPointImportToolModal onOk={(e: any) => warningPointsModal(['city', 'region', 'country'], handleBulkAddPoints, e)} />
          <SelectWithSuggestions
            placeholder="Search the cities"
            callAPI={(query: string): callAPIProps => {
              return {
                url: ({ getApiUrl, serializeQuery }) => getApiUrl(`services/facebook/fblocations?${serializeQuery({ q: query })}`),
              }
            }
            }
            renderOption={(suggestion: any) => renderSuggestion(suggestion, points)}
            onChange={handleNewPointSelected}
            clearOnSelect={true}
            style={{ width: "100%" }}
          />
        </div>
        <div className="geo-points-list">
          {mappedPoints}
        </div>
        <div className="standard-border map-container">
          <Map
            ref={mapRef}
            height={300}
            circles={mappedCircles}
            markers={mappedMarkers}
            regions={mappedRegions}
            scrollwheel={true}
            onDragEnd={handlePointDrag}
          >
            <Tooltip title="Add pin in the middle">
              <AwesomeIcon icon={faLocationDot} style={{
                height: "50px",
                width: "50px",
                margin: "0px",
                borderRadius: "10px 10px 0px 0px"
              }} onClick={() => warningPointsModal(['city', 'region', 'country'], addPinInTheMiddle)} />
            </Tooltip>
          </Map>
        </div>
      </div>
    </>
  )
}
