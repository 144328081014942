import React, { useEffect, useMemo } from "react";
import { Select } from "antd";
import FormattedMessage from "components/common/FormattedMessage";

const DefaultCTAActions = [
  'SUBSCRIBE', 'ADD_TO_CART', 'BUY_NOW', 'LEARN_MORE', 'SIGN_UP', 'DOWNLOAD', 'SHOP_NOW', 'BOOK_TRAVEL'
]

const LangComponents: Record<string, JSX.Element> = {
  
  "APPLY_NOW": <FormattedMessage
    id="apps.smart.containers.createPostAndAd.form.callToAction.applyNow"
    defaultMessage="Apply Now"
  />,
  "ADD_TO_CART": <FormattedMessage
    id="apps.smart.containers.createPostAndAd.form.callToAction.addToCart"
    defaultMessage="Add to cart"
  />,
  "BUY_NOW": <FormattedMessage
    id="apps.smart.containers.createPostAndAd.form.callToAction.buy"
    defaultMessage="Buy"
  />,
  "GET_QUOTE": <FormattedMessage
  id="apps.smart.containers.createPostAndAd.form.callToAction.getQuote"
  defaultMessage="Get Quote"
  />,
  // "learn_more": <FormattedMessage
  //   id="apps.smart.containers.createPostAndAd.form.callToAction.learnMore"
  //   defaultMessage="Learn More"
  // />,
  "none": <FormattedMessage
    id="apps.smart.containers.createPostAndAd.form.callToAction.none"
    defaultMessage="None"
  />,
  // "selectOne": <FormattedMessage
  //   id="apps.smart.containers.createPostAndAd.form.callToAction.selectOne"
  //   defaultMessage="Select One"
  // />,
  // "send_message": <FormattedMessage
  //   id="apps.smart.containers.createPostAndAd.form.callToAction.sendMessage"
  //   defaultMessage="Send Message"
  // />,
  // "sign_up": <FormattedMessage
  //   id="apps.smart.containers.createPostAndAd.form.callToAction.signup"
  //   defaultMessage="Sign Up"
  // />,
  // "subscribe": <FormattedMessage
  //   id="apps.smart.containers.createPostAndAd.form.callToAction.subscribe"
  //   defaultMessage="Subscribe"
  // />,
  "SUBSCRIBE": <FormattedMessage
    id="apps.smart.containers.createPostAndAd.form.callToAction.subscribe"
    defaultMessage="Subscribe"
  />,
  "SHOP_NOW": <FormattedMessage
    id="apps.smart.containers.createPostAndAd.form.callToAction.shopNow"
    defaultMessage="Shop Now"
  />,
  "LEARN_MORE": <FormattedMessage
    id="apps.smart.containers.createPostAndAd.form.callToAction.learnMore"
    defaultMessage="Learn More"
  />,
  "SIGN_UP": <FormattedMessage
    id="apps.smart.containers.createPostAndAd.form.callToAction.signUp"
    defaultMessage="Sign Up"
  />,
  "BOOK_TRAVEL": <FormattedMessage
    id="apps.smart.containers.createPostAndAd.form.callToAction.bookTravel"
    defaultMessage="Book Travel"
  />,
  "BOOK_NOW": <FormattedMessage
    id="apps.smart.containers.createPostAndAd.form.callToAction.bookNow"
    defaultMessage="Book Now"
  />,
  "DOWNLOAD": <FormattedMessage
    id="apps.smart.containers.createPostAndAd.form.callToAction.download"
    defaultMessage="Download"
  />,
  // "SEND_MESSAGE": <FormattedMessage
  //   id="apps.smart.containers.createPostAndAd.form.callToAction.sendMessage"
  //   defaultMessage="Send Message"
  // />,
  "MESSAGE_PAGE": <FormattedMessage
    id="apps.smart.containers.createPostAndAd.form.callToAction.sendMessage"
    defaultMessage="Send Message"
  />,
  "NO_BUTTON": <FormattedMessage
    id="apps.smart.containers.createPostAndAd.form.callToAction.noBUtton"
    defaultMessage="No Button"
  />,
}

export type CTASelectProps = {
  mode?: "single" | "multiple";
  actions?: string[];
  style?: any;
  onChange?: any;
  value?: any;
  onBlur?: any;
}

const CTASelect = (props: CTASelectProps) => {


  const options = useMemo((): string[] => {
    let newOptions: string[] = [];
    if (props.actions && (props.actions as string[]).length > 0) {
      newOptions = (props.actions as string[]);
    } else {
      newOptions = DefaultCTAActions;
    }
    return newOptions;
  }, [props.actions])


  const mappedOptions = useMemo((): JSX.Element[] => {
    return options.map((option) => (
      <Select.Option value={(option === "none" || option === "selectOne") ? undefined : option}>
        {LangComponents[option] || option}
      </Select.Option>
    ))
  }, [options])

  const mappedValue = useMemo((): string[] => {

    if (!props.value) return [];

    if (!Array.isArray(props.value)) return props.value;
    return props.value.map((value:any) => {
      if (typeof value === "object") return value.type;
      return value;
    })
  }, [props.value])

  if (props.actions && (props.actions as any).length === 1) {

    return (
      <Select
        onChange={props.onChange}
        value={options[0]}
        style={{width: "100%"}}
        onBlur={props.onBlur}
        mode={props.mode === "multiple" ? "multiple" : undefined}
        defaultValue={[options[0]]}
        placeholder={
          LangComponents["selectOne"]
        }
        //value={options[0]}
        disabled={true}
      >
        {mappedOptions}
      </Select>
    )

  }

  return (
    <Select
      onChange={props.onChange}
      value={mappedValue}
      style={{width: "100%"}}
      onBlur={props.onBlur}
      mode={props.mode === "multiple" ? "multiple" : undefined}
      placeholder={
        LangComponents["selectOne"]
      }
    >
      {mappedOptions}
    </Select>
  )

};

export default CTASelect;