import { Card } from "antd"
import { Notification } from "./NotificationsSlice";
import AwesomeIcon, { AwesomeIconDelete } from "components/common/AwesomeIcon";
import { faQuestionCircle, faTrash } from "@fortawesome/pro-light-svg-icons";
import { StandardTooltip } from "modules/panel/testing/StandardTooltip";


const NotificationCard = ({ notification }: { notification: Notification }) => {

    const actions = () => {

        if (!notification.type) return [];
        if (notification.type === "ai_campaign_ready") return (

            <><span> </span><a>Click here to view</a></>

        )

    }

    return (
        <Card
            title={
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}>
                    {notification.title}
                    <div style={{
                        display: "flex",
                    }}>
                        <StandardTooltip 
                        title="Mark as read" 
                        link="config.keywords.interests.autopromoteKeywords.tooltip"
                        color="black"
                        >
                        <AwesomeIcon icon={faQuestionCircle} size="xl"/>
                        </StandardTooltip>
                        <AwesomeIcon icon={faTrash} size="xl"/>
                        
                    </div>
                </div>
            }
        >
            {notification.message}
            {actions()}
        </Card>
    )


}

export default NotificationCard;