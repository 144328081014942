import PlatformIcon from "components/common/icons/PlatformIcon";
import { usePost } from "modules/posts/postContext";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { currentFlatSubscriptionPermissionsSelector } from "state/subscriptions/subscriptionsSelectors";


export type ProviderIconListProps = {
    providers: string[];
    size?: string;
}

export const ProviderIconList = (props: ProviderIconListProps) => {
    
        const {post} = usePost();
        const permissions = useSelectorWithParams(currentFlatSubscriptionPermissionsSelector);

        const allowedProviders:string[] = [];
        if (permissions.includes("use creations platform facebook")) allowedProviders.push("facebook");
        if (permissions.includes("use creations platform instagram")) allowedProviders.push("instagram");
        if (permissions.includes("use creations platform google")) allowedProviders.push("google");

        return (
            <div className="provider-icon-list">
                {props.providers.filter((provider) => {

                    if (!allowedProviders.includes(provider)) return false;
    
                    const p = post.getProviderPost(provider);
                    return !!p;
                }).map((provider) => <PlatformIcon key={provider} platform = {provider as any} size="small"/>)}
            </div>
        )
    
}