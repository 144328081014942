import { Col, Row } from "antd";

export const StandardRow = ({children, style, ...props}:any) => (
    <Row gutter={[{ xs: 0, sm: 0, md: 15, lg: 30, xl: 30, xxl: 30 }, 30]} style={{ marginLeft: "0px", marginRight: "0px", ...style}} {...props}>
        {children}
    </Row>
)

export const StandardFullColumn = ({children, ...props}:any) => (
    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} {...props}>
        {children}
    </Col>
)

export const StandardHalfFullColumn = (props:any) => (
    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} className={props.className} style={{...props.style}}>
        {props.children}
    </Col>
)

export const StandardQuaterColumn = ({children, ...props}:any) => (
    <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6} {...props}>
        {children}
    </Col>
)

export const StandardThirdColumn = ({children, ...props}:any) => (
    <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8} {...props}>
        {children}
    </Col>
)
