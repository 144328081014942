import { randomFromArray } from "services/helpers/fake-asset-generators";
import dayjs, { Dayjs } from 'dayjs';

const exampleSubs = [
]

export const fakeSubscription = () => {

    const id = Math.floor(Math.random() * 1000000);
    //random number between -20 and 20
    const untilPaid = Math.floor(Math.random() * 40) - 20;

    const expiry = dayjs().add(untilPaid, "days").format("YYYY-MM-DD");

    const toBePaidAmmount = Math.random() > 0.5 ? Math.floor(Math.random() * 1000) : 0;

    return {
        "id": id,
        "type": "subscription",
        "attributes": {
            "name": "Test Subscription",
            id: id,
            "created_at": "2021-03-01T00:00:00.000Z",
            "to_be_paid": toBePaidAmmount,
            "status": randomFromArray(["active", "ended", "cancelled"]),
            "expiry": expiry,
            "billing_cycle": randomFromArray(["monthly", "yearly", "quarterly"]),
            "price": Math.floor(Math.random() * 1000),
        }
    }
}

export const generateFakeSubscriptions = () => {

    return {
        "data":[
           {
              "id":100,
              "type":"subscriptions",
              "attributes":{
                 "name":"smart monthly",
                 "stripe_status":"active",
                 "created_at":"2021-04-12T16:44:08.000000Z",
                 "expiry":"2026-05-12 16:44:08",
                 "billing_cycle":"monthly",
                 "charge_price":"79",
                 "product":"FastTonyPl",
                 "tax_info":[
                    {
                       "id":245,
                       "subscriptions_offer_id":16,
                       "stripe_price_id":"txr_1HkSj2Lj5MrJkuJecrHj2q0q",
                       "type":"tax_rate",
                       "value":"23",
                       "stripe_value":23,
                       "name":"Podatek",
                       "tax_rate":null
                    }
                 ],
                 "payments":null,
                 "win_back_time":null,
                 "compromise":null,
                 "subscription_permissions":[
                    "use module smart posts",
                    "use module smart ads",
                    "use module smart createPost",
                    "use module smart createAd",
                    "use module smart adsReport",
                    "use module smart editAdsSettings",
                    "use module smart adsSettings",
                    "use module smart pixel",
                    "use module smart adsSettings interestsKeywords",
                    "use module smart createPostInfo",
                    "use module smart createAoa",
                    "use module smart aoaList",
                    "use module smart advancedSettings",
                    "use integration facebook pixel",
                    "use integration facebook fbAdAccount",
                    "use integration facebook fanpage",
                    "use project integrations",
                    "use view lead forms",
                    "use module panel tracking",
                    "use module panel tracking pixel",
                    "use module panel integrations",
                    "use module smart facebook settings",
                    "use module smart business settings",
                    "use module smart creations",
                    "use creations platform facebook",
                    "use creations type post",
                    "use creations type aoa",
                    "use creations publish",
                    "use integration facebook instagram",
                    "use module smart createW2LAoa",
                    "use creations type w2laoa",
                    "use module smart calendar",
                    "use module panel promotions",
                    "use module panel assistant",
                    "use module smart facebook settings budget"
                 ]
              },
              "relationships":{
                 "stripeInvoices":[
                    {
                       "id":1001,
                       "type":"stripe_invoices"
                    },
                    {
                       "id":1002,
                       "type":"stripe_invoices"
                    },
                    {
                       "id":1003,
                       "type":"stripe_invoices"
                    },
                    {
                       "id":1004,
                       "type":"stripe_invoices"
                    }
                 ],
                 "projects":[
                    {
                       "id":21,
                       "type":"projects"
                    }
                 ]
              }
           },
           {
            "id":123,
            "type":"subscriptions",
            "attributes":{
               "name":"smart yearly",
               "stripe_status":"active",
               "created_at":"2021-04-12T16:44:08.000000Z",
               "expiry":"2025-05-12 16:44:08",
               "billing_cycle":"yearly",
               "charge_price":"79",
               "product":"FastTonyPl",
               "tax_info":[
                  {
                     "id":245,
                     "subscriptions_offer_id":16,
                     "stripe_price_id":"txr_1HkSj2Lj5MrJkuJecrHj2q0q",
                     "type":"tax_rate",
                     "value":"23",
                     "stripe_value":23,
                     "name":"Podatek",
                     "tax_rate":null
                  }
               ],
               "payments":null,
               "win_back_time":null,
               "compromise":null,
               "subscription_permissions":[
                  "use module smart posts",
                  "use module smart ads",
                  "use module smart createPost",
                  "use module smart createAd",
                  "use module smart adsReport",
                  "use module smart editAdsSettings",
                  "use module smart adsSettings",
                  "use module smart pixel",
                  "use module smart adsSettings interestsKeywords",
                  "use module smart createPostInfo",
                  "use module smart createAoa",
                  "use module smart aoaList",
                  "use module smart advancedSettings",
                  "use integration facebook pixel",
                  "use integration facebook fbAdAccount",
                  "use integration facebook fanpage",
                  "use project integrations",
                  "use view lead forms",
                  "use module panel tracking",
                  "use module panel tracking pixel",
                  "use module panel integrations",
                  "use module smart facebook settings",
                  "use module smart business settings",
                  "use module smart creations",
                  "use creations platform facebook",
                  "use creations type post",
                  "use creations type aoa",
                  "use creations publish",
                  "use integration facebook instagram",
                  "use module smart createW2LAoa",
                  "use creations type w2laoa",
                  "use module smart calendar",
                  "use module panel promotions",
                  "use module panel assistant",
                  "use module smart facebook settings budget"
               ]
            },
            "relationships":{
               "stripeInvoices":[
                  {
                     "id":1001,
                     "type":"stripe_invoices"
                  },
               ],
               "projects":[
                  {
                     "id":21,
                     "type":"projects"
                  }
               ]
            }
         },
         {
            "id":124,
            "type":"subscriptions",
            "attributes":{
               "name":"smart monthly",
               "stripe_status":"cancelled",
               "created_at":"2021-04-12T16:44:08.000000Z",
               "expiry":"2021-05-12 16:44:08",
               "billing_cycle":"monthly",
               "charge_price":"79",
               "product":"FastTonyPl",
               "tax_info":[
                  {
                     "id":245,
                     "subscriptions_offer_id":16,
                     "stripe_price_id":"txr_1HkSj2Lj5MrJkuJecrHj2q0q",
                     "type":"tax_rate",
                     "value":"23",
                     "stripe_value":23,
                     "name":"Podatek",
                     "tax_rate":null
                  }
               ],
               "payments":null,
               "win_back_time":null,
               "compromise":null,
               "subscription_permissions":[
               ]
            },
            "relationships":{
               "stripeInvoices":[
                  {
                     "id":1001,
                     "type":"stripe_invoices"
                  },
               ],
               "projects":[
                  {
                     "id":21,
                     "type":"projects"
                  }
               ]
            }
         },
         {
            "id":125,
            "type":"subscriptions",
            "attributes":{
               "name":"smart monthly",
               "stripe_status":"cancelled",
               "created_at":"2021-04-12T16:44:08.000000Z",
               "expiry":"2021-05-12 16:44:08",
               "billing_cycle":"monthly",
               "charge_price":"79",
               "product":"FastTonyPl",
               "tax_info":[
                  {
                     "id":245,
                     "subscriptions_offer_id":16,
                     "stripe_price_id":"txr_1HkSj2Lj5MrJkuJecrHj2q0q",
                     "type":"tax_rate",
                     "value":"23",
                     "stripe_value":23,
                     "name":"Podatek",
                     "tax_rate":null
                  }
               ],
               "payments":null,
               "win_back_time":null,
               "compromise":null,
               "subscription_permissions":[
               ]
            },
            "relationships":{
               "stripe_invoices":[
                  {
                     "id":1005,
                     "type":"stripe_invoices"
                  },
               ],
               "projects":[
                  {
                     "id":21,
                     "type":"projects"
                  }
               ]
            }
         },
         {
            "type": "subscriptions",
            "id": 126,
            "attributes": {
              "id": 126,
              "name": "od daniela",
              "stripe_status": "canceled",
              "quantity": 1,
              "trial_ends_at": null,
              "ends_at": "2024-01-31T11:00:00.000000Z",
              "created_at": "2024-01-10T13:14:09.000000Z",
              "baseSubInfo": [],
              "compromise": null,
              "win_back_time": null,
              "seller_id": null,
              "status": "canceled",
              "subscription_permissions": [],
              "subscription_permissions_plain": [],
              "expiry": null,
              "billing_cycle": null,
              "charge_price": null,
              "tax_info": null,
              "stripe_plan": "internal",
              "product":"FastTonyPl",
            },
            "relationships": {
              "projects": {
                "data": []
              },
              "stripeInvoices": {
                "data": []
              }
            }
          }
           ],
          "included":[
           {
              "id":1001,
              "type":"stripe_invoices",
              "attributes":{
                 "id":1001,
                 "stripe_number":"FOO_BAR_BAZ_1234",
                 "paid":true,
                 "paid_out_of_band":0,
                 "paid_at":"2023-11-12T17:45:41.000000Z",
                 "amount_paid":97.17,
                 "amount_due":97.17,
                 "currency":"pln",
                 "metadata":[
                    
                 ],
                 "created_at":"2023-11-12T17:45:48.000000Z",
                 "updated_at":"2024-01-19T00:21:16.000000Z",
                 "subjects":[
                    "Subscription: smart monthly"
                 ]
              }
           },
           {
              "id":1002,
              "type":"stripe_invoices",
              "attributes":{
                 "id":1002,
                 "stripe_number":"FOO_BAR_BAZ_1235",
                 "paid":true,
                 "paid_out_of_band":0,
                 "paid_at":"2023-12-12T17:45:41.000000Z",
                 "amount_paid":97.17,
                 "amount_due":97.17,
                 "currency":"pln",
                 "metadata":[
                    
                 ],
                 "created_at":"2023-12-12T17:45:45.000000Z",
                 "updated_at":"2024-01-19T00:27:28.000000Z",
                 "subjects":[
                    "Subscription: smart monthly"
                 ]
              }
           },
           {
              "id":1003,
              "type":"stripe_invoices",
              "attributes":{
                 "id":1003,
                 "stripe_number":"FOO_BAR_BAZ_1236",
                 "paid":true,
                 "paid_out_of_band":0,
                 "paid_at":"2024-01-12T17:44:27.000000Z",
                 "amount_paid":97.17,
                 "amount_due":97.17,
                 "currency":"pln",
                 "metadata":[
                    
                 ],
                 "created_at":"2024-01-12T17:44:34.000000Z",
                 "updated_at":"2024-01-19T02:26:51.000000Z",
                 "subjects":[
                    "Subscription: smart monthly"
                 ]
              }
           },
           {
              "id":1004,
              "type":"stripe_invoices",
              "attributes":{
                 "id":1004,
                 "stripe_number":"FOO_BAR_BAZ_1237",
                 "paid":false,
                 "paid_out_of_band":0,
                 "paid_at":"2024-02-12T17:44:38.000000Z",
                 "amount_paid":40,
                 "amount_due":100.00,
                 "currency":"pln",
                 "metadata":[
                    
                 ],
                 "created_at":"2024-02-12T16:44:55.000000Z",
                 "updated_at":"2024-02-12T17:44:46.000000Z",
                 "subjects":[
                    "Subscription: smart monthly"
                 ]
              }
           },
           {
            "id":1005,
            "type":"stripe_invoices",
            "attributes":{
               "id":1005,
               "stripe_number":"FOO_BAR_BAZ_1238",
               "paid":false,
               "paid_out_of_band":0,
               "paid_at":"2024-02-12T17:44:38.000000Z",
               "amount_paid":0,
               "amount_due":200.00,
               "currency":"pln",
               "metadata":[
                  
               ],
               "created_at":"2024-02-12T16:44:55.000000Z",
               "updated_at":"2024-02-12T17:44:46.000000Z",
               "subjects":[
                  "Subscription: smart monthly"
               ]
            }
         },
          ]
     }
       
}