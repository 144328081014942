import { Progress, Spin, Statistic } from "antd";
import { HorizontalCenter } from "components/common/Center";
import FormattedMessage from "components/common/FormattedMessage";
import { Title } from "components/common/StandardText";
import { ProjectAIInfoDemographicsSelector, GenerateAIDemographics, GetAiProjectInfo, ProjectHasDemographicSelector, AiProjectInfoSelector } from "modules/panel/state/AISlice";
import ConnectedOnboardingControls from "providers/onboarding/controls"
import { useOnboarding } from "providers/onboarding/onboarding-context";
import { useEffect, useRef, useState } from "react";
import useAPI from "services/hooks/useAPI";
import useAPIWithRetry from "services/hooks/useAPIWithRetry";
import useCallAfterUpdate from "services/hooks/useCallAfterUpdate";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";

const gatheringTitle = 
    <FormattedMessage
        id="apps.smart.components.aiSettings.aiGeneration.gathering.title"
        defaultMessage="Gathering data"
    />

const generatingTitle =
            <FormattedMessage
                id="apps.smart.components.aiSettings.aiGeneration.generating.title"
                defaultMessage="Generating demographics"
            />

const OnboardingAIGeneration = () => {


    const projectAIInfoCall = useAPI(GetAiProjectInfo);
    const hasValidDemographics = useSelectorWithParams(ProjectHasDemographicSelector);
    const generate = useAPI(GenerateAIDemographics, false);
    const { nextStep, direction, previousStep } = useOnboarding();
    const [hasError, setHasError] = useState(false);

    const handleGenerate = () => {
        generate.call().then((res) => {
            if (res.status===200 || res.status===201 || res.status===204) {
                nextStep();
            } else {
                setHasError(true);
            }
        });
    }

    useEffect(() => {
        if (!projectAIInfoCall.data) return;
        
        const { demographics, demographics_status} = projectAIInfoCall.data;
        const vaidDemographics = demographics && Object.keys(demographics).length > 0;


        if (vaidDemographics) nextStep();
        if (demographics_status === "in_process" || demographics_status ==="in_queue") nextStep();
        if (demographics_status === "none") {
            handleGenerate();
            return;
        }
        if (demographics_status === "ready" || !vaidDemographics) {
            handleGenerate();
            return;
        }
        if (demographics_status === "error") {
            setHasError(true);
            return;
        }

    }, [projectAIInfoCall.data]);
    
    useEffect(() => {
        if (direction === "backward") {
            previousStep();
            return;
        }
        if (hasValidDemographics) {
            nextStep();
        } else {     
            projectAIInfoCall.call()
        }
    }, []);

    if (hasError) {

        return (
            <div>
                <FormattedMessage
                    id="apps.smart.components.aiSettings.aiGeneration.error"
                    defaultMessage="Something went wrong. Please try again later."
                />
            </div>
        )

    }

    return (
        <div>
            <ConnectedOnboardingControls hideButtons={true}/> 
        </div>
    )
}

export default OnboardingAIGeneration;

export function OnboardingAIGenerationCompleted() {

    const hasValidDemographics = useSelectorWithParams(ProjectHasDemographicSelector);
    const projectAIInfo = useSelectorWithParams(AiProjectInfoSelector);
    const { nextStep, previousStep, direction } = useOnboarding();
    const [status, setStatus] = useState<"idle" | "loading" | "error">("idle");

    const hasGenError = projectAIInfo?.demographics_status === "error";

    const { enable, data, loading, error, checkData } = useAPIWithRetry({
        ...GetAiProjectInfo,
        enabled: !hasValidDemographics && !hasGenError,
        delay: 5000,
        isDataReady: (data) => {
            return !!data.demographics || data.demographics_status === "error";
        }
    })

    useEffect(() => {
        if (direction === "backward") {
            previousStep();
            return;
        }
        if (hasValidDemographics) {
            nextStep();
            return;
        }
    }, [hasValidDemographics]);


    useEffect(() => {
        if (!projectAIInfo) return;
        if (!projectAIInfo.demographics) return;
        if (hasGenError) setStatus("error")
    }, [projectAIInfo]);

    
    if (status === "error") {

        return (
            <div>
                <FormattedMessage
                    id="apps.smart.components.aiSettings.aiGeneration.error"
                    defaultMessage="Something went wrong. Please try again later."
                />
            </div>
        )

    }

    return (
        <div>
            <HorizontalCenter><Spin /></HorizontalCenter>
            <ConnectedOnboardingControls hideButtons={true}/> 
        </div>
    )

}


