import { TimePicker } from "antd";
import { useEffect, useMemo, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import FormattedMessage from "components/common/FormattedMessage";

const UIformat = "HH:mm";
const APIformat = "HH:mm:ss";

interface WorkHours {
  day: string;
  start: Dayjs | undefined;
  stop: Dayjs | undefined;
}

const getinitialDays = (type: string): WorkHours[] => {
  const days = [
    { day: "monday", start: undefined, stop: undefined },
    { day: "tuesday", start: undefined, stop: undefined },
    { day: "wednesday", start: undefined, stop: undefined },
    { day: "thursday", start: undefined, stop: undefined },
    { day: "friday", start: undefined, stop: undefined },
    { day: "saturday", start: undefined, stop: undefined },
    { day: "sunday", start: undefined, stop: undefined },
  ];
  if (type === "workweek") {
    return days.slice(0, 5);
  } else if (type === "weekend") {
    return days.slice(5, 7);
  } else {
    return days;
  }
};

const allDays = {
  monday: (
    <FormattedMessage
      id="components.inputs.openingHours.days.monday"
      defaultMessage="monday"
    />
  ),
  tuesday: (
    <FormattedMessage
      id="components.inputs.openingHours.days.tuesday"
      defaultMessage="tuesday"
    />
  ),
  wednesday: (
    <FormattedMessage
      id="components.inputs.openingHours.days.wednesday"
      defaultMessage="wednesday"
    />
  ),
  thursday: (
    <FormattedMessage
      id="components.inputs.openingHours.days.thursday"
      defaultMessage="thursday"
    />
  ),
  friday: (
    <FormattedMessage
      id="components.inputs.openingHours.days.friday"
      defaultMessage="friday"
    />
  ),
  saturday: (
    <FormattedMessage
      id="components.inputs.openingHours.days.saturday"
      defaultMessage="saturday"
    />
  ),
  sunday: (
    <FormattedMessage
      id="components.inputs.openingHours.days.sunday"
      defaultMessage="sunday"
    />
  ),
  holidays: (
    <FormattedMessage
      id="components.inputs.openingHours.days.holidays"
      defaultMessage="holidays"
    />
  ),
};

const OpeningHours = (props: {
  type: string;
  onChange: any;
  onBlur: any;
  value: any;
}) => {
  const [workHours, setWorkHours] = useState<WorkHours[]>(
    getinitialDays(props.type)
  );
  const days = useMemo(() => {
    return getinitialDays(props.type);
  }, [props.type]);

  useEffect(() => {
    if (props.value) {
      const newWorkHours = [...workHours];
      workHours.forEach((day: any, index: number) => {
        const match = props.value.find((d: any) => d.day === day.day);
        if (match) {
          newWorkHours[index].start = dayjs(match.time_start, APIformat);
          newWorkHours[index].stop = dayjs(match.time_stop, APIformat);
        }
      });
      setWorkHours(newWorkHours);
    }
  }, [props.value]);

  const handleTimeChange =
    (day: string, field: string) => (value: Dayjs | null) => {
      let index = workHours.findIndex((d) => d.day === day);
      const newWorkHours = [...workHours];

      if (newWorkHours.every((d) => !d.start || !d.stop) && day === "monday") {
        for (let i = 0; i < newWorkHours.length; i++) {
          newWorkHours[i][field] = value;
        }
      }

      if (index === -1) {
        const newDay = { day: day, start: undefined, stop: undefined };
        newDay[field] = value;
        newWorkHours.push(newDay);
        index = newWorkHours.length - 1;
      } else {
        newWorkHours[index][field] = value;
      }

      if (
        (field === "start" || field === "stop") &&
        (!value || value === null)
      ) {
        newWorkHours[index][field] = undefined;

        if (newWorkHours.every((d) => !d.start && !d.stop)) {
          setWorkHours([]);
          return;
        }
      }

      if (newWorkHours[index].start && newWorkHours[index].stop) {
        if (newWorkHours[index].start.isAfter(newWorkHours[index].stop)) {
          const temp = newWorkHours[index].start;
          newWorkHours[index].start = newWorkHours[index].stop;
          newWorkHours[index].stop = temp;
        }
      }

      setWorkHours(newWorkHours);
      const formattedWorkHours = newWorkHours
        .filter((d) => d.start !== undefined && d.stop !== undefined)
        .map(({ day, start, stop }) => ({
          day,
          time_start: start?.format(APIformat),
          time_stop: stop?.format(APIformat),
        }));

      props.onChange && props.onChange(formattedWorkHours);
      props.onBlur && props.onBlur();
    };

  return (
    <div className={`opening-hours-container type-${props.type}`}>
      {days.map(({ day, start, stop }, index) => (
        <div className="opening-hours-picker" key={day}>
          <div className="day-name-container">
            <span className="day-name">{(allDays as any)[day]}</span>
          </div>
          <div className="pickers-container">
            <TimePicker
              className="from"
              value={workHours.find((d) => d.day === day)?.start}
              onChange={handleTimeChange(day, "start")}
              placeholder="From"
              defaultOpenValue={dayjs("08:00", "HH:mm")}
              format={UIformat}
              minuteStep={15}
              showNow={false}
            />
            <TimePicker
              className="to"
              value={workHours.find((d) => d.day === day)?.stop}
              onChange={handleTimeChange(day, "stop")}
              placeholder="To"
              defaultOpenValue={dayjs("16:00", "HH:mm")}
              format={UIformat}
              minuteStep={15}
              showNow={false}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default OpeningHours;
