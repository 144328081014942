import React, { Component } from "react";
import { TimePicker } from "antd";
import { injectIntl, defineMessages } from "react-intl";
import FormattedMessage from "components/common/FormattedMessage";
import dayjs, { Dayjs } from 'dayjs';

const UIformat = "HH:mm";
const APIformat = "HH:mm:ss";

class OpeningHoursInput extends Component {
  constructor(props) {
    super(props);

    this.setHour = this.setHour.bind(this);
  }

  renderTimePickers = () => {
    let openingHoursPickersArray = [];
    let index = 0;
    for (let dayKey in days[this.props.type]) {
      let value = this.props.value
        ? this.props.value.find((day) => day.day === dayKey)
        : undefined;
      let props = {
        ...this.props,
        key: dayKey,
        dayName: days[this.props.type][dayKey],
        fromValue:
          value && value.time_start
            ? dayjs(value.time_start, UIformat)
            : undefined,
        toValue:
          value && value.time_stop
            ? dayjs(value.time_stop, UIformat)
            : undefined,
        formatMessage: this.props.intl.formatMessage,
      };

      let setEveryHour = index === 0 && (this.props.type === "workweek" || this.props.type === "week");
      props["onFromChange"] = (time) =>
        this.setHour(time, dayKey, "time_start", setEveryHour);
      props["onToChange"] = (time) =>
        this.setHour(time, dayKey, "time_stop", setEveryHour);

      openingHoursPickersArray.push(<OpeningHoursPicker {...props} />);
      index++;
    }
    return openingHoursPickersArray;
  };

  setHour(time, dayKey, fromOrTo, setEveryHour = false) {
    let value = this.getValue();
    if (!value.find((day) => day.day === dayKey)) {
      let day = {
        day: dayKey,
        time_start: undefined,
        time_stop: undefined,
      };

      day[fromOrTo] = time ? dayjs(time).format(APIformat) : undefined;
      value.push(day);
    } else
      value = value.map((day) => {
        if (
          setEveryHour &&
          time &&
          day[fromOrTo] === undefined &&
          day.day !== dayKey
        ) {
          day[fromOrTo] = dayjs(time).format(APIformat);
        }
        if (day.day === dayKey)
          day[fromOrTo] = time ? dayjs(time).format(APIformat) : undefined;
        return day;
      });

      if (this.props.injectPropertyType) {
        value = value.map(v => ({...v, type: this.props.injectPropertyType}))
      }

    this.props.onChange(value);
    this.props.onBlur();
  }

  getValue = () => {
    return !this.props.value?.length
      ? this.getInitialValue()
      : [...this.props.value];
  };

  getInitialValue = () => {
    switch (this.props.type) {
      case "workweek":
        return [
          {
            day: "monday",
            time_start: undefined,
            time_stop: undefined,
          },
          {
            day: "tuesday",
            time_start: undefined,
            time_stop: undefined,
          },
          {
            day: "wednesday",
            time_start: undefined,
            time_stop: undefined,
          },
          {
            day: "thursday",
            time_start: undefined,
            time_stop: undefined,
          },
          {
            day: "friday",
            time_start: undefined,
            time_stop: undefined,
          }
        ]
      case "weekend":
        return [
          {
            day: "saturday",
            time_start: undefined,
            time_stop: undefined,
          },
          {
            day: "sunday",
            time_start: undefined,
            time_stop: undefined,
          },
        ]
      case "day": 
        return [{
          day: "day",
          time_start: undefined,
          time_stop: undefined,
        }]
      case "week": 
      return [
        {
          day: "monday",
          time_start: undefined,
          time_stop: undefined,
        },
        {
          day: "tuesday",
          time_start: undefined,
          time_stop: undefined,
        },
        {
          day: "wednesday",
          time_start: undefined,
          time_stop: undefined,
        },
        {
          day: "thursday",
          time_start: undefined,
          time_stop: undefined,
        },
        {
          day: "friday",
          time_start: undefined,
          time_stop: undefined,
        },
        {
          day: "saturday",
          time_start: undefined,
          time_stop: undefined,
        },
        {
          day: "sunday",
          time_start: undefined,
          time_stop: undefined,
        },
      ]
    }

  };

  render() {
    return (
      <div className={`opening-hours-container type-${this.props.type}`}>
        {this.renderTimePickers()}
      </div>
    );
  }
}

export default injectIntl(OpeningHoursInput);

const OpeningHoursPicker = ({
  dayName,
  fromValue,
  onFromChange,
  toValue,
  onToChange,
  formatMessage,
  onBlur
}) => {
  return (
    <div className="opening-hours-picker">
      <div className="day-name-container">
        <span className="day-name">{dayName}</span>
      </div>
      <div className="pickers-container">
        <TimePicker
          className="from"
          value={fromValue}
          onChange={onFromChange}
          onBlur={onBlur}
          placeholder={formatMessage(placeholderMessages.from)}
          defaultOpenValue={dayjs("08:00", UIformat)}
          format={UIformat}
          minuteStep={15}
        />
        <TimePicker
          className="to"
          value={toValue}
          onChange={onToChange}
          onBlur={onBlur}
          placeholder={formatMessage(placeholderMessages.to)}
          defaultOpenValue={dayjs("16:00", UIformat)}
          format={UIformat}
          minuteStep={15}
        />
      </div>
    </div>
  );
};

const allDays = {
  monday: (
    <FormattedMessage
      id="components.inputs.openingHours.days.monday"
      defaultMessage="monday"
    />
  ),
  tuesday: (
    <FormattedMessage
      id="components.inputs.openingHours.days.tuesday"
      defaultMessage="tuesday"
    />
  ),
  wednesday: (
    <FormattedMessage
      id="components.inputs.openingHours.days.wednesday"
      defaultMessage="wednesday"
    />
  ),
  thursday: (
    <FormattedMessage
      id="components.inputs.openingHours.days.thursday"
      defaultMessage="thursday"
    />
  ),
  friday: (
    <FormattedMessage
      id="components.inputs.openingHours.days.friday"
      defaultMessage="friday"
    />
  ),
  saturday: (
    <FormattedMessage
      id="components.inputs.openingHours.days.saturday"
      defaultMessage="saturday"
    />
  ),
  sunday: (
    <FormattedMessage
      id="components.inputs.openingHours.days.sunday"
      defaultMessage="sunday"
    />
  ),
  holidays: (
    <FormattedMessage
    id="components.inputs.openingHours.days.holidays"
    defaultMessage="holidays"
  />
  ),
}

const days = {
  workweek: {
    monday: allDays.monday,
    tuesday: allDays.tuesday,
    wednesday: allDays.wednesday,
    thursday: allDays.thursday,
    friday: allDays.friday,
  },
  weekend: {
    saturday: allDays.saturday,
    sunday: allDays.sunday,
  },
  week: {
    monday: allDays.monday,
    tuesday: allDays.tuesday,
    wednesday: allDays.wednesday,
    thursday: allDays.thursday,
    friday: allDays.friday,
    saturday: allDays.saturday,
    sunday: allDays.sunday,
  }
};

const placeholderMessages = defineMessages({
  from: {
    id: "components.inputs.openingHours.fromPlaceholder",
    defaultMessage: "From",
  },
  to: {
    id: "components.inputs.openingHours.toPlaceholder",
    defaultMessage: "To",
  },
});
