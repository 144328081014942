import DisplayIfHasPermission from "components/common/DisplayIfHasPermission";
import CodeDisplay, { CodeBox } from "components/displays/CodeDisplay";
import { StandardBox } from "components/layout/StandardBoxes";
import FormattedMessage from "components/common/FormattedMessage";
import useAPI from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { currentProjectIdSelector } from "services/store/scopeSlice";

export default function Pixel() {

  const { call, data, loading, error, StatusDisplay } = useAPI({
    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/pixel`),
  }, true)

  const project = useSelectorWithParams(currentProjectIdSelector);

  if (error || loading) return StatusDisplay;

  return (<CodeDisplay
    code={data?.html}
    sendEmail={true}
    sendCallApi={{
      url: ({ getApiUrl, projectId }) => getApiUrl(`projects/pixel-send`),
      addToBody: {
        project_id: project,
      }
    }}
  />)


}

const PixelExtraInfo = () => {

  return (
    <div style={{paddingTop: "20px"}}>
      <p>
        <FormattedMessage
        id="panel.tracking.pixel.extraInfo"
        defaultMessage="If the user is redirected to a thank you page after sending the form, paste the code there."
        />
      </p>
      <CodeBox code={"<script> window.dataLayer = window.dataLayer || []; window.dataLayer.push({ 'event': 'lead', }); </script> "} style={{ minHeight: "75px" }} />
      <p>
        <FormattedMessage
        id="panel.tracking.pixel.extraInfo2"
        defaultMessage="If the form is sent dynamically (does not redirect to another page) then we must run the following method after a successful send:"
        />
      </p>
      <CodeBox code={"<script>window.dataLayer.push({'event': 'lead'});</script>"} style={{ minHeight: "75px" }} />
    </div>
  )

}

export const PixelBox = () => {

  return (
    <>
    <StandardBox
      title={"Pixel"}
    >
      <Pixel />
    </StandardBox>
    <DisplayIfHasPermission permission={["use module panel tracking pixel extraInfo"]}>
      <StandardBox
        title={"Tag Manager"}
      >
        <PixelExtraInfo />
      </StandardBox>
    </DisplayIfHasPermission>
    </>
  )

}