import { useEffect, useMemo, useState } from "react";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import useWindowSize from "services/hooks/useWindowSize";
import { creationsAsArraySelector } from "../creationsSlice";
import CreationCard from "../CreationCard";
import { useCreationEditor } from "../CreationEditor";
import { Title } from "components/common/StandardText";
import FormattedMessage from "components/common/FormattedMessage";

const CreationDraftQueue = (props: any) => {

    //const creations = useSelectorWithParams(creationsAsArraySelector);
    const size = useWindowSize();
    const { queue } = useCreationEditor();

    const right = size.width > 1700 ? 1300 : 1250
    const left = size.width > 1700 ? 100 : 50

    const filteredQueue = useMemo(() => {
        if (!queue) return [];
        return queue.filter((creationInQueue: any) => {
            if (creationInQueue.status !== "draft") return false;
            return true;
        })
    }, [queue])

    if (!props.animEnded) return null;

    if (size.width < 1500) return null;

    return (
        <div style={{
            position: "fixed",
            left: left,
            top: 100,
            bottom: 100,
            right: right,
            padding: "10px",
            pointerEvents: "none",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
        }}>
            <div className="standard-border" style={{
                backgroundColor: "white",
                display: "grid",
                placeItems: "center"
            }}>
                <Title noMargins level={3}>
                    <FormattedMessage
                    id="tools.creationsList.confirmationQueue.upcomingDrafts"
                    defaultMessage="Upcoming drafts"
                />
                </Title>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                overflow: "scroll",
            }}>
                {filteredQueue.map((c: any) => <CreationCard creationId={c.id} mode="queue" />)}
            </div>
        </div>
    );

}

export default CreationDraftQueue;