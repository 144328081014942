import { Divider, Select, Space, Typography } from "antd";
import { ReactNode, useEffect, useMemo } from "react";
import useAPI, { callAPIProps } from "services/hooks/useAPI"
import { useCreationEditor } from "../CreationEditor";
import FormattedMessage from "components/common/FormattedMessage";
import "./aoa-events-select.less";

/*
'Rate', 'TutorialCompletion', 'Contact', 'CustomizeProduct', 'Donate', 'FindLocation', 'Schedule', 'StartTrial', 'SubmitApplication', 'Subscribe',
'AddToCart', 'AddToWishlist', 'InitiatedCheckout', 'AddPaymentInfo', 'Purchase', 'Lead', 'CompleteRegistration', 'ContentView', 'Search',
'ServiceBookingRequest', 'MessagingConversationStarted7D', 'LevelAchieved', 'AchievementUnlocked', 'SpentCredits', 'ListingInteraction',
'D2Retention', 'D7Retention', 'Other',
'ViewContent'
*/

const EventStrings:Record<string, ReactNode> = {
    "Rate": <FormattedMessage id="components.aoa.events.rate" defaultMessage="Rate"/>,
    "TutorialCompletion": <FormattedMessage id="components.aoa.events.tutorialCompletion" defaultMessage="Tutorial Completion"/>,
    "Contact": <FormattedMessage id="components.aoa.events.contact" defaultMessage="Contact"/>,
    "CustomizeProduct": <FormattedMessage id="components.aoa.events.customizeProduct" defaultMessage="Customize Product"/>,
    "Donate": <FormattedMessage id="components.aoa.events.donate" defaultMessage="Donate"/>,
    "FindLocation": <FormattedMessage id="components.aoa.events.findLocation" defaultMessage="Find Location"/>,
    "Schedule": <FormattedMessage id="components.aoa.events.schedule" defaultMessage="Schedule"/>,
    "StartTrial": <FormattedMessage id="components.aoa.events.startTrial" defaultMessage="Start Trial"/>,
    "SubmitApplication": <FormattedMessage id="components.aoa.events.submitApplication" defaultMessage="Submit Application"/>,
    "Subscribe": <FormattedMessage id="components.aoa.events.subscribe" defaultMessage="Subscribe"/>,
    "AddToCart": <FormattedMessage id="components.aoa.events.addToCart" defaultMessage="Add To Cart"/>,
    "AddToWishlist": <FormattedMessage id="components.aoa.events.addToWishlist" defaultMessage="Add To Wishlist"/>,
    "InitiatedCheckout": <FormattedMessage id="components.aoa.events.initiatedCheckout" defaultMessage="Initiated Checkout"/>,
    "AddPaymentInfo": <FormattedMessage id="components.aoa.events.addPaymentInfo" defaultMessage="Add Payment Info"/>,
    "Purchase": <FormattedMessage id="components.aoa.events.purchase" defaultMessage="Purchase"/>,
    "Lead": <FormattedMessage id="components.aoa.events.lead" defaultMessage="Lead"/>,
    "CompleteRegistration": <FormattedMessage id="components.aoa.events.completeRegistration" defaultMessage="Complete Registration"/>,
    "ContentView": <FormattedMessage id="components.aoa.events.contentView" defaultMessage="Content View"/>,
    "Search": <FormattedMessage id="components.aoa.events.search" defaultMessage="Search"/>,
    "ServiceBookingRequest": <FormattedMessage id="components.aoa.events.serviceBookingRequest" defaultMessage="Service Booking Request"/>,
    "MessagingConversationStarted7D": <FormattedMessage id="components.aoa.events.messagingConversationStarted7D" defaultMessage="Messaging Conversation Started 7D"/>,
    "LevelAchieved": <FormattedMessage id="components.aoa.events.levelAchieved" defaultMessage="Level Achieved"/>,
    "AchievementUnlocked": <FormattedMessage id="components.aoa.events.achievementUnlocked" defaultMessage="Achievement Unlocked"/>,
    "SpentCredits": <FormattedMessage id="components.aoa.events.spentCredits" defaultMessage="Spent Credits"/>,
    "ListingInteraction": <FormattedMessage id="components.aoa.events.listingInteraction" defaultMessage="Listing Interaction"/>,
    "D2Retention": <FormattedMessage id="components.aoa.events.d2Retention" defaultMessage="D2 Retention"/>,
    "D7Retention": <FormattedMessage id="components.aoa.events.d7Retention" defaultMessage="D7 Retention"/>,
    "Other": <FormattedMessage id="components.aoa.events.other" defaultMessage="Other"/>,
    "ViewContent": <FormattedMessage id="components.aoa.events.viewContent" defaultMessage="View Content"/>,
}

const inTheLast30Days = <FormattedMessage id="components.aoa.events.inTheLast30Days" defaultMessage="In the last 30 days"/>;

const EventLabel = (props:{qunatity: number, name: string}) => {

    const translatedName = EventStrings[props.name] || props.name;

    return (
        <div style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
            <Typography.Text>{translatedName}</Typography.Text>
            <div style={{display: "flex", flexDirection: "column" }}>
                <Typography.Text style={{fontSize: "16px", lineHeight: "16px"}}>{props.qunatity}</Typography.Text>
                <Typography.Text type="secondary" style={{fontSize: "10px", lineHeight: "10px"}}>{inTheLast30Days}</Typography.Text>
            </div>
        </div>
    )
}

export default function AOAEvents (props:any) {


    const {call, data, loading, error, StatusDisplay} = useAPI({});
    const {type, subtype} = useCreationEditor();

    useEffect(() => {
        if (type && subtype && !loading) {
            call({
                    url: ({getApiUrl, projectId}) => getApiUrl("aoa/events?project_id=" + projectId + "&type=" + subtype, 2),
                });
        }
    }, [type, subtype]);


    const handleChange = (value:any) => {
        if (typeof value === "string") {
            props.onChange && props.onChange([value])
            props.onBlur && props.onBlur();
        } else {
            props.onChange && props.onChange(value);
            props.onBlur && props.onBlur();
        }
    }

    const mappedOptions = useMemo(() => {
        if (!data) return [];
        const withQuantity = data.filter((item:any) => item.quantity > 0);
        const withoutQuantity = data.filter((item:any) => item.quantity === 0);

        if (withQuantity.length === 0) return withoutQuantity.map((item:any) => ({value: item.id, label: <EventLabel qunatity={item.quantity} name={item.id} />}));

        return [
            {
                options: withQuantity.map((item:any) => ({value: item.id, label: <EventLabel qunatity={item.quantity} name={item.id} />}))
            },
            {
                label: <Divider></Divider>,
                options: withoutQuantity.map((item:any) => ({value: item.id, label: <EventLabel qunatity={item.quantity} name={item.id} />}))
            }
        ]

    }, [data])

    useEffect(() => {
        if (!data) return;
        if (data.length !== 1) return;
        handleChange([data[0].id])
    }, [data]);

    const adjustedValue = useMemo(() => {
        if (!props.value) return [];
        if (typeof props.value === "string") return [props.value];
        return props.value;
    }, [props.value])

    if (loading || error) return StatusDisplay 

    return (
        <Select
            popupClassName="aoa-events-select"
            onChange={handleChange}
            onBlur={props.onBlur}
            options={mappedOptions}
            value={adjustedValue}
            />
    )

}