import React from "react";
import { FormattedMessage } from "react-intl";

export type AiPostListProps = {
    titles: Array<{ id: number; content: string }>;
    texts: Array<{ id: number; content: string }>;
  }

export const AiPostList = ({ titles, texts }: AiPostListProps) => {
    
    const reversedTitles = [...titles].reverse();
    return (
        <>
            {reversedTitles.map((titleData: any) => {
                const text = texts.find(
                    (textData: any) => textData.id === titleData.id + 1
                );
                return (
                    <div key={titleData.id}>
                        <p>
                            <strong>{titleData.content}</strong>
                        </p>
                        {text && <p>{text.content}</p>}
                    </div>
                );
            })}
        </>
    );
};
