import {
  getUrl as getSmartUrl,
  ONBOARDING_FACEBOOK_AUDIENCE,
  ONBOARDING_FACEBOOK_BUDGET,
  ONBOARDING_FACEBOOK_KEYWORD,
  ONBOARDING_FACEBOOK_SUCCESS,
  ONBOARDING_PIXEL,
  ONBOARDING_PREPARE_PROJECT,
} from "modules/smart/config/routes";
import {
  getUrl as getForsantUrl,
  ONBOARDING_GOOGLE_GEOLOCATION,
  ONBOARDING_BUSINESS_DETAILS,
  ONBOARDING_OPENING_HOURS,
  ONBOARDING_ADS_AUDIENCE,
  ONBOARDING_KEYWORD_IDEAS,
  ONBOARDING_HEADLINES,
  ONBOARDING_SHORT_DESCRIPTIONS,
  ONBOARDING_LONG_DESCRIPTIONS,
  ONBOARDING_DISPLAY_ADS_HORIZONTAl,
  ONBOARDING_DISPLAY_ADS_SQUARE,
  ONBOARDING_VIDEO_ADS,
  ONBOARDING_ADS_BUDGET,
  ONBOARDING_ADS_INVITATION,
  ONBOARDING_PAYMENT_METHOD,
  ONBOARDING_SUMMARY,
} from "modules/forsant/config/routes";
import {
  HasBusinessSurveyAdsSettingsFilledSelector,
  HasFacebookAudienceFilledSelector,
  HasFacebookBudgetFilledSelector,
  HasFilledAdsAudienceSelector,
  HasFilledBudgetSelector,
  HasFilledBusinessDetailsSelector,
  HasFilledDisplayAdsMediaHorizontalSelector,
  HasFilledDisplayAdsMediaSquareSelector,
  HasFilledHeadlinesSelector,
  HasFilledKeywordIdeasSelector,
  HasFilledLongDescriptionsSelector,
  HasFilledOpeningHoursSelector,
  HasFilledShortDescriptionsSelector,
  HasFilledVideoAdsMediaSelector,
  HasGoogleAudienceFilledSelector,
  HasUserSmartProject,
  HasVerifiedAdsInvitationSelector,
  HasVerifiedPaymentMethodSelector,
  HasVerifiedTrackingCodeSelector,
} from "modules/smart/smartSlice";

export default [
  {
    // check current project have smart
    key: "prepareSmartObject",
    path: () => getSmartUrl(ONBOARDING_PREPARE_PROJECT),
    selector: HasUserSmartProject,
    omitWhenFulfilled: true,
    // component: <PrepareSmartProject/>
    // smart[currentProject]
  },
  {
    // check smart have website, initial_keyword
    key: "hasBusinessSurveyAdsSettingsFilled",
    path: () => getSmartUrl(ONBOARDING_BUSINESS_DETAILS),
    selector: HasBusinessSurveyAdsSettingsFilledSelector,
    // component: <ForsantOnboardingBusinessDetails/>
    // website !== null && initial_keyword !== null
  },
  {
    // check smart have facebook_ads_settings parameters
    key: "hasFacebookAudienceFilled",
    path: () => getSmartUrl(ONBOARDING_FACEBOOK_AUDIENCE),
    selector: HasFacebookAudienceFilledSelector,
    integration: "facebook",
    // component: <OnboardingAudienceFacebook/>
    // component: <OnboardingAudienceFacebook/>
    // gender !== null &&
    // age_from !== null &&
    // age_to !== null &&
    // location_targeting.geo_points.length > 0 &&
  },
  {
    // no requirement
    key: "hasFacebookKeywordsFilled",
    path: () => getSmartUrl(ONBOARDING_FACEBOOK_KEYWORD),
    //selector: hasFacebookKeywordsFilledSelector,
    selector: () => true,
    integration: "facebook",
    permission: "use module smart google_smart_settings interestsKeywords",
    omitWhenFulfilled: false,
    // component: <OnboardingKeywordsFacebook/>
  },
  {
    // check smart have facebook_ads_settings parameters
    key: "hasFacebookBudgetFilled",
    path: [
      () => getSmartUrl(ONBOARDING_FACEBOOK_BUDGET),
      () => getSmartUrl(ONBOARDING_PIXEL),
      () => getSmartUrl(ONBOARDING_FACEBOOK_SUCCESS),
    ],
    selector: HasFacebookBudgetFilledSelector,
    integration: "facebook",
    // components: <OnboardingBudgetFacebook/>, <OnboardingPixel/>, <OnboardingSuccessFacebook/>
    // standard_daily_advertising_budget !== null, standard_period !== null &&
    // link_daily_advertising_budget !== null &&, link_period !== null &&
    // video_daily_advertising_budget !== null &&, video_period !== null &&
    // message_daily_advertising_budget !== null &&, message_period !== null
  },
  {
    // check smart have facebook_ads_settings parameters
    key: "hasFilledGeolocationSelector",
    path: () => getForsantUrl(ONBOARDING_GOOGLE_GEOLOCATION),
    selector: HasGoogleAudienceFilledSelector,
    // component: <OnboardingAudienceGoogle/>
    // gender !== null &&
    // age_from !== null &&
    // age_to !== null &&
    // location_targeting.geo_points.length > 0 &&
    // locales !== null
  },
  {
    // check smart have google_smart_settings parameters
    key: "hasFilledBusinessDetailsSelector",
    path: () => getForsantUrl(ONBOARDING_BUSINESS_DETAILS),
    selector: HasFilledBusinessDetailsSelector,
    // component: <ForsantOnboardingBusinessDetails/>
    // company_category, website_url, phones, start_date
  },
  {
    // check smart have google_smart_settings working_hours
    key: "hasFilledOpeningHoursSelector",
    path: () => getForsantUrl(ONBOARDING_OPENING_HOURS),
    selector: HasFilledOpeningHoursSelector,
    // component: <OnboardingOpeningHours/>
    // working_hours >= 1
  },
  {
    // check smart have google_smart_settings parameters
    key: "hasFilledAdsAudienceSelector",
    path: () => getForsantUrl(ONBOARDING_ADS_AUDIENCE),
    selector: HasFilledAdsAudienceSelector,
    // component: <OnboardingAdsAudience/>
    // keywords_seed, competitors_websites
  },
  {
    // check smart have google_smart_settings keywords
    key: "hasFilledKeywordIdeasSelector",
    path: () => getForsantUrl(ONBOARDING_KEYWORD_IDEAS),
    selector: HasFilledKeywordIdeasSelector,
    // component: <OnboardingKeywordIdeas/>
    // keywords >= 1
  },
  {
    // check smart have google_smart_settings headlines
    key: "hasFilledHeadlinesSelector",
    path: () => getForsantUrl(ONBOARDING_HEADLINES),
    selector: HasFilledHeadlinesSelector,
    // component: <Headlines/>
    // headlines >= 6
  },
  {
    // check smart have google_smart_settings short_descriptions
    key: "hasFilledShortDescriptionsSelector",
    path: () => getForsantUrl(ONBOARDING_SHORT_DESCRIPTIONS),
    selector: HasFilledShortDescriptionsSelector,
    // component: <ShortDescriptions/>
    // short_descriptions >= 3
  },
  {
    // check smart have google_smart_settings descriptions
    key: "hasFilledLongDescriptionsSelector",
    path: () => getForsantUrl(ONBOARDING_LONG_DESCRIPTIONS),
    selector: HasFilledLongDescriptionsSelector,
    // component: <LongDescriptions/>
    // descriptions >= 3
  },
  {
    // check smart have google_smart_settings horizontal_images
    key: "hasFilledDisplayAdsMediaHorizontalSelector",
    path: () => getForsantUrl(ONBOARDING_DISPLAY_ADS_HORIZONTAl),
    selector: HasFilledDisplayAdsMediaHorizontalSelector,
    // component: <OnboardingHorizontalAds/>
    // horizontal_images
  },
  {
    // check smart have google_smart_settings square_images
    key: "hasFilledDisplayAdsMediaSquareSelector",
    path: () => getForsantUrl(ONBOARDING_DISPLAY_ADS_SQUARE),
    selector: HasFilledDisplayAdsMediaSquareSelector,
    // component: <OnboardingSquareAds/>
    // square_images
  },
  {
    // check smart have google_smart_settings videos
    key: "hasFilledVideoAdsMediaSelector",
    path: () => getForsantUrl(ONBOARDING_VIDEO_ADS),
    selector: HasFilledVideoAdsMediaSelector,
    // component: <OnboardingVideoAds/>
    // videos
  },
  {
    // check smart have google_smart_settings parameters
    key: "hasFilledBudgetSelector",
    path: () => getForsantUrl(ONBOARDING_ADS_BUDGET),
    selector: HasFilledBudgetSelector,
    // component: <OnboardingBudget/>
    // general_budget, currency
  },
  {
    // check smart have google_smart_settings accepted_invitation
    key: "hasVerifiedAdsInvitationSelector",
    path: () => getForsantUrl(ONBOARDING_ADS_INVITATION),
    selector: HasVerifiedAdsInvitationSelector,
    omitWhenFulfilled: true,
    // component: <OnboardingAdsInvitation/>
    // accepted_invitation
  },
  {
    // check smart have google_smart_settings configured_payment
    key: "hasVerifiedPaymentMethodSelector",
    path: () => getForsantUrl(ONBOARDING_PAYMENT_METHOD),
    selector: HasVerifiedPaymentMethodSelector,
    omitWhenFulfilled: true,
    // component: <OnboardingPaymentMethod/>
    // configured_payment
  },
  {
    // check smart have google_smart_settings valid_console
    key: "hasVerifiedTrackingCodeSelector",
    path: () => getForsantUrl(ONBOARDING_SUMMARY),
    selector: HasVerifiedTrackingCodeSelector,
    omitWhenFulfilled: true,
    // component: <OnboardingTrackingCode/>
    // valid_console === true && valid_console === "valid" && valid_console === "skipped"
  },
];
