import Tabs from "_components/tabs/tabs";
import { ProviderIntegrationFacebook, ProviderIntegrationGoogle } from "./ProviderIntegration";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { useMemo } from "react";
import { FACEBOOK_PAGES, GOOGLE_AD_ACCOUNTS, getProviderFieldConfigByName } from "modules/panel/config/ProviderConfig";
import { ProviderFieldIcon } from "modules/panel/config/ProviderHelpers";
import { currentFlatSubscriptionPermissionsSelector } from "state/subscriptions/subscriptionsSelectors";


export default function ManageProviderIntegrations() {

    const permissions = useSelectorWithParams([currentFlatSubscriptionPermissionsSelector]);

    const panes:any = useMemo(() => {
        if (!permissions) return [];
        const p = [];
        const hasFacebookPermission = permissions.some((p: string) => p.startsWith("use integration facebook"));
        hasFacebookPermission && p.push({
            title: "Facebook",
            key: "facebook",
            icon: <ProviderFieldIcon field={FACEBOOK_PAGES} />,
            content: <ProviderIntegrationFacebook />,
        },)

        const hasGooglePermission = permissions.some((p: string) => p.startsWith("use integration google"));
        hasGooglePermission && p.push({
            title: "Google",
            key: "google",
            icon: <ProviderFieldIcon field={GOOGLE_AD_ACCOUNTS} />,
            //disabled: true,
            content: <ProviderIntegrationGoogle />,
        },)

        return p;

    }, [permissions])

    return (
        <Tabs
            panes={panes} />
    )


}