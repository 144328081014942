import IFrameContainer from "_components/website-preview/iframe";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { useDebounceTrigger } from "services/hooks/useDebounce";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { UserWebsiteUrl } from "../reducers/websiteSlice";

const WebsitePreviewDisplay = React.forwardRef((props:any, ref:any) => {

    const [content, setContent] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const websiteUrl = useSelectorWithParams([UserWebsiteUrl]);

    const handleGetPreview = async (body: any) => {

        
        await fetch(`${websiteUrl}/template_preview`, {
        //await fetch(`https://98d9638525.fasttony.site/template_preview`, {
        method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })
        .then(response => response.text())
        .then((res:any) => {
            if (!res) return;
            setContent(res)
        })
    }

    const [debounce] = useDebounceTrigger(handleGetPreview, 1000)

    const handleAsk = (data: any) => {
        setIsLoading(true);
        debounce(data);
    }

    React.useImperativeHandle(ref, () => ({
        getPreview: handleAsk
    }));

    const adjustedContent = useMemo(() => {
        return content
    }, [content])

    return (
        <>
                <IFrameContainer
                loading={false}
                content={adjustedContent}
                style={{
                    width: "100%",
                    height: "100%",
                    border: "none"
                }}
                />
        </>
    )


})

export default WebsitePreviewDisplay;