import { faArrowsRotate, faFilePlus } from "@fortawesome/pro-light-svg-icons"
import { Select } from "antd"
import StandardButton from "components/common/StandardButton"
import useAPI from "services/hooks/useAPI"
import useSelectorWithParams from "services/hooks/useSelectorWithParams"
import { currentProjectIntegration } from "state/projects/projectSelectors"

export default function LeadFormSelectForPosts(props: any) {

    const { call, data, loading, error, StatusDisplay } = useAPI({
        url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/leads-manager/forms`),
    }, true)
    const page = useSelectorWithParams(currentProjectIntegration("service_facebook_page"))
    const adAccount = useSelectorWithParams(currentProjectIntegration("service_facebook_ads_account"))

    const handleResponse = (resp: any) => {
        console.log("response", resp);
        call();
    }

    const handleOpenEditor = () => {
        try {
            (window as any).FB.ui({
                method: "lead_gen",
                display: "popup",
                page_id: page.page_id,
                ad_account_id: adAccount.account_id,
              }, handleResponse)
        } catch (error) {
            console.log("error with fb", error);
        }
    }

    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
        }}>
        <Select
        onChange={props.onChange}
        onBlur={props.onBlur}
        value={props.value}
        style={{width: "100%"}}
        >
        {data?.map((form: any) => (
            <Select.Option value={form.id}>{form?.name}</Select.Option>
        ))}
        </Select>
        <StandardButton onClick={handleOpenEditor}
        icon = {faFilePlus}
        type="text"
        />
      <StandardButton onClick={() => call()}
      icon = {faArrowsRotate}
      type="text"
      />
       </div>
    )
}