import { Tooltip } from "antd";
import DateTimeDisplay from "components/displays/DateTimeDisplay";
import { CenterdCell } from "./CommonCells";

export default function DateDisplayCell (props: any) {

    return (
        <div onClick={(e) => props.onClick && props.onClick(e)}>
            <Tooltip
                title={<DateTimeDisplay dateTime={props.date} showDate showTime fullDate/>}
            >
                <div>
                <CenterdCell>
                    <DateTimeDisplay dateTime={props.date} showDate/>
                </CenterdCell>
                
                </div>
            </Tooltip>
        </div>
    )

}