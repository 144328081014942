import { Input, Select, Tooltip } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import { StandardProjectCard } from "components/common/StandardActorCard";
import ActorCardSelect from "components/inputs/actorCardSelect/ActorCardSelect";
import { ReactNode, forwardRef, useEffect, useMemo, useState } from "react";
import useAPI from "services/hooks/useAPI";
import { StandardModal, useStandardModal } from "services/hooks/useModal";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { projectHasProductSubscription } from "state/projects/projectHelpers";
import { projectsSelector, projectsWithSubscriptionsSelector } from "state/projects/projectsSelectors";
import RelaxField from "tools/relaxForm/RelaxField";
import { ruleMin } from "tools/relaxForm/relaxPresets";
import useRelaxData from "tools/relaxForm/useRelaxData";

type Props = {
  onSelect: (data: any) => void;
  product: string;
  children?: ReactNode;
  onClose?: () => void;
};


const SelectOrCreateProjectModal = forwardRef((props: Props, ref: any) => {
  const [projectId, setProjectId] = useState<string>("");
  const [newProjectName, setNewProjectName] = useState<Object>();
  const [error, setError] = useState<any>();
  const projects = useSelectorWithParams(projectsWithSubscriptionsSelector);

  const { call, loading } = useAPI({
    url: ({ getApiUrl }) => getApiUrl(`projects`, 2),
    method: "POST",
    dispatchName: "PROJECT_CREATE",
    customNormalizer: (data) => data?.project,
  });

  const mappedProjects = useMemo(() => {

    const p: {
      value: string;
      disabled?: boolean;
      renderLabel: any;
    }[] = [];

    Object.values(projects).forEach((project: any) => {
      p.push({
        value: project.id,
        disabled: projectHasProductSubscription(project, props.product),
        renderLabel: <StandardProjectCard projectId={project.id} />,
      });
    })

    p.push({
      value: "Create New",
      renderLabel: (
        <Tooltip
          key="create-project"
          placement="left"
          title={
            <FormattedMessage
              id="apps.panel.components.subscriptions.projectSelectConfirm.select.createNew.tooltip"
              defaultMessage="Use this option if you want use different Facebook, Google settings than your existing projects."
            />
          }
        >
          <FormattedMessage
            id="apps.panel.components.subscriptions.projectSelectConfirm.select.createNew"
            defaultMessage="Create new project"
          />
        </Tooltip>
      ),
    });

    return p;
  }, [projects]);


  const newProjectInput = useMemo(() => {
    if (projectId !== "Create New") return null;
    return (
      <RelaxField
        name={"name"}
        hideStatus={true}
        submitOverride={(data: Object) => {
          setNewProjectName(data);
        }}
        rules={[ruleMin(3)]}
        label={
          <FormattedMessage
            id="apps.panel.components.subscriptions.projectSelectConfirm.projectName"
            defaultMessage="Project name"
          />
        }
      >
        <Input placeholder="Company XYZ" disabled={loading} />
      </RelaxField>
    );
  }, [projectId]);

  return (
    <>
      <StandardModal
        ref={ref}
        title={
          <FormattedMessage
            id="apps.panel.components.subscriptions.projectSelectConfirm.title"
            defaultMessage="Select project to which you want to attach"
          />
        }
        onOk={() => {
          if (projectId === "") {
            setError(
              <FormattedMessage
                id="apps.panel.components.subscriptions.projectSelectConfirm.error"
                defaultMessage="Select project name"
              />
            );
            return null;
          }
          if (projectId != "" && newProjectName !== undefined) {
            call({
              body: { ...newProjectName },
            }).then((res) => {
              if (res.status === 200) {
                props.onSelect(res.data.id);
              }
            });
          } else {
            props.onSelect(projectId);
          }
          setProjectId("");
          setNewProjectName({});
          setError(null);
          props.onClose && props.onClose();
        }}
      >
        <FormattedMessage
          id="apps.panel.components.subscriptions.projectSelectConfirm.description"
          defaultMessage="If you want to share existing configuration in Facebook, Google choose existing project, or create new if you want to config it in a new way."
        />
        <RelaxField
          name={"projectId"}
          hideStatus={true}
          submitOverride={(data: string) => {
            setProjectId(data);
            setError(null);
          }}
          required={true}
          label={
            <FormattedMessage
              id="apps.panel.components.subscriptions.projectSelectConfirm.project"
              defaultMessage="Project"
            />
          }
        >
          <>
            <ActorCardSelect
              key={props.product}
              style={{ width: "100%" }}
              actors={mappedProjects !== undefined ? mappedProjects : null}
              onChange={(value: any) => setProjectId(value)}
            />
          </>
        </RelaxField>
        <div style={{ color: "red" }}>{error}</div>
        {newProjectInput}
      </StandardModal>
    </>
  );
});

export const SelectOrCreateProject = (props: Props) => {
  const [ref, open, close] = useStandardModal();
  

  return (
    <>
      <div onClick={open}>{props.children}</div>
      <SelectOrCreateProjectModal
        onSelect={props.onSelect}
        onClose={close}
        product={props.product}
        ref={ref}
      />
    </>
  );
};
