import { Radio } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import useAPI from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import {
  GetIntegrations,
  ProjectToolIntegrationsSelector,
} from "state/project/toolIntegrationsSlice";
import RelaxField from "tools/relaxForm/RelaxField";

type Props = {
  integration?: string;
  setIntegration: (data: string) => void;
  actionType?: string;
};

export const BotIntegrationRadio = (props: Props) => {
  const {} = useAPI(GetIntegrations, true);
  const integrationSelector = useSelectorWithParams(ProjectToolIntegrationsSelector);

  const integration = props.integration === 'private_replies' ? null : props.integration

  return (
    <RelaxField name="type" initialValue={integration} hideStatus={true}>
      <Radio.Group defaultValue={integration}  className="bot-integration-select">
        <Radio.Button
          value={null}
          onClick={() => props.setIntegration("private_replies")}
          disabled={
            props.actionType === "create" ||
            (props.actionType === "update" &&
              props.integration === "private_replies")
              ? false
              : true
          }
        >
          <FormattedMessage
            id="components.forms.botIntegrations.simplyReply"
            defaultMessage="Simple Reply"
          />
        </Radio.Button>
        <Radio.Button
          value={"fresh-mail"}
          onClick={() => props.setIntegration("fresh-mail")}
          disabled={
            (props.actionType === "create" && integrationSelector["fresh-mail"]!== null) ||
            (props.actionType === "update" &&
              props.integration === "fresh-mail")
              ? false
              : true
          }
        >
          <FormattedMessage
            id="components.forms.postVisibility.freshMail"
            defaultMessage="Fresh Mail"
          />
        </Radio.Button>
        <Radio.Button
          value={"sms-api"}
          onClick={() => props.setIntegration("sms-api")}
          disabled={
            (props.actionType === "create" && integrationSelector["sms-api"]!== null) ||
            (props.actionType === "update" && props.integration === "sms-api")
              ? false
              : true
          }
        >
          <FormattedMessage
            id="components.forms.postVisibility.smsApi"
            defaultMessage="SMS API"
          />
        </Radio.Button>
        <Radio.Button
          value={"mailer-lite"}
          onClick={() => props.setIntegration("mailer-lite")}
          disabled={
            (props.actionType === "create" && integrationSelector["mailer-lite"]!== null) ||
            (props.actionType === "update" &&
              props.integration === "mailer-lite")
              ? false
              : true
          }
        >
          <FormattedMessage
            id="components.forms.postVisibility.mailerLite"
            defaultMessage="Mailer Lite"
          />
        </Radio.Button>
      </Radio.Group>
    </RelaxField>
  );
};
