import { faClose } from "@fortawesome/pro-light-svg-icons"
import { Space, Table, Tag, Tooltip } from "antd"
import StandardActorCard from "components/common/StandardActorCard"
import StandardButton from "components/common/StandardButton"
import { useEffect, useMemo } from "react"
import { useSimpleModalWithAction } from "services/hooks/useModal"
import useSelectorWithParams from "services/hooks/useSelectorWithParams"
import { libraryProjectsSelector } from "state/librarySlice"
import { OrganizationGroupSelector, OrganizationGroupsSelector, OrganizationProjectSelector, OrganizationProjectsSelector, PatchGroup } from "../data/organizationSlice"
import { useOrganizationTool } from "../OrganizationManager"

export const ListOfProjectsAsTags = (props: { projects: any[], onClose?: (projectId: number) => void }) => {

    const projects = useSelectorWithParams(libraryProjectsSelector)

    if (!props.projects) return null;
    if (!props.projects.length) return null;

    return (
        <Space wrap>
            {props.projects.map((p: any) => {

                let project:any;
                if (typeof p === "string" || typeof p === "number") {
                    let projectsArray = Array.isArray(projects) ? projects : Object.values(projects);
                    project = projectsArray && projectsArray.find((pr: any) => pr.id === p) 
                } else {
                    project = p;
                }

                if (!project) return null;

                return (
                    <StandardActorCard actor={project} extra={
                        <Tooltip title="Remove">
                            <StandardButton type="text" icon={faClose} onClick={() => props.onClose && props.onClose(project.id)}/>
                        </Tooltip>
                    }/>
                )

            })}
        </Space>
    )
}

export const InputProjectsDisplay = () => {

    const {inputData, setOutputData, outputData, setInputData} = useOrganizationTool();

    return (
        <div>
            <h3>Selected projects</h3>
            <ListOfProjectsAsTags projects={inputData?.projects} onClose={(p) => {
                setInputData((i:any) => { 
                    return {...i, projects: i.projects.filter((project:any) => project !== p)};
                })
            }}/>
        </div>
    )

}

export const SimpleListOfProjects = (props: { projects: any[] }) => {

    if (!props.projects) return (
        <Space wrap>
            <Tag>No projects</Tag>
        </Space>
    )

    return (
        <Space wrap>
            {props.projects.map((project: any) => {
                return <StandardActorCard actor={project} />
            })}
        </Space>
    )
}

export const ListOfProjects = (props: any) => {

    const projects = useSelectorWithParams(libraryProjectsSelector)
    const group = useSelectorWithParams([OrganizationGroupSelector, props.groupId])


    return (
        <Table
            pagination={false}
            columns={[
                {
                    title: "Project",
                    render: (text: any, record: any) => {
                        let projectsArray = Array.isArray(projects) ? projects : Object.values(projects);
                        
                        const pro = projectsArray && projectsArray.find((pr: any) => pr.id === record)
                        return <StandardActorCard actor={pro} />
                    }
                },
            ]}
            dataSource={props.projects}
            style={{
                maxHeight: "50vh"
            }}
        />
    )

}

export const IsProjectInGroup = (projectId: string, group: { name: string, projects: { id: string }[] }) => {
    return !!(group.projects.find((project) => project.id === projectId))
}

export const GroupsWithProject = (projectId: string, groups: { name: string, projects: { id: string }[] }[]) => {
    if (!projectId) return [];
    if (!groups) return [];

    const groupsWithProject = groups.filter((group) => {
        return IsProjectInGroup(projectId, group)
    })

    return groupsWithProject;

}

export const GroupsOfProject = (props: { projectId: string, closable: boolean, onClose: (group: any) => void }) => {

    const groups = useSelectorWithParams(OrganizationGroupsSelector)
    const project = useSelectorWithParams([OrganizationProjectSelector, props.projectId])

    const projectGroups = useMemo(() => {
        const g = GroupsWithProject(props.projectId, groups)
        if (!g || !g.length) return "";
        return g.map((group) => <Tag
            closable={props.closable}
            onClose={(e: any) => {
                e.preventDefault();
                props.onClose(group)
            }}
        >{group.name}</Tag>)

    }, [groups, project])

    return (
        <div>{projectGroups}</div>
    )

}

export const GroupsOfManager = (props: {managerId: string}) => {

    const groups:any[] = useSelectorWithParams(OrganizationGroupsSelector);
    const myGroups = groups && groups.filter((group:any) => group.managers.map((m:any) => m.id).includes(props.managerId))

    const [open, contextHolder] = useSimpleModalWithAction({});


    const handleClose = (groupId: string) => {

        const newManagers = groups.find((group:any) => group.id === groupId).managers.filter((m:any) => m.id !== props.managerId)
        open({
            title: "Remove manager from group",
            content: <p>Are you sure you want to remove this manager from this group?</p>,
            onOk: () => {
            },
            callAPI: {...PatchGroup(groupId), body: {managers: newManagers}}
        })
    }

    const managedGroups = useMemo(() => {
        return myGroups.map((group) => <Tag
            closable={true}
            onClose={(e: any) => {
                e.preventDefault();
                handleClose(group.id)
            }}
        >{group.name}</Tag>)

    }, [groups])

    return (
        <div>{contextHolder}{managedGroups}</div>
    )

}


export const OrganizationRoleNames:{label:string, value: string}[] = [
    {label: "Owner", value: "org-owner"},
    {label: "Director", value: "org-director"},
    {label: "Post Manager", value: "org-post-manager"},
    {label: "Internal Partner", value: "org-internal-partner"},
    {label: "External Partner", value: "org-external-partner"},
    {label: "Client", value: "org-client"},
]
