import FormattedMessage from "components/common/FormattedMessage";

export const TourismCategories = {
    "object-type": {
        title: <FormattedMessage
            id="apps.websites.categories.objectType"
            defaultMessage="Object Type"
        />,
        min: 1,
        max: 1,
        sidebar: {
            heading: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.objectType.sidebar.heading"
                defaultMessage="Object Type"
              />
            ),
            description: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.objectType.sidebar.descripition"
                defaultMessage="The type of the object."
              />
            ),
          },
    },
    "localization": {
        title: <FormattedMessage
            id="apps.websites.categories.localization"
            defaultMessage="Localization"
        />,
        min: 1,
        max: 1,
        sidebar: {
            heading: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.localization.sidebar.heading"
                defaultMessage="Localization"
              />
            ),
            description: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.localization.sidebar.descripition"
                defaultMessage="Whats the location?"
              />
            ),
          },
    },
    "attraction-type": {
      title: <FormattedMessage
          id="apps.websites.categories.attractionType"
          defaultMessage="Attraction Type"
      />,
      min: 1,
      max: 1,
      sidebar: {
          heading: (
            <FormattedMessage
              id="apps.websites.containers.onboarding.categories.attractionType.sidebar.heading"
              defaultMessage="Attraction Type"
            />
          ),
          description: (
            <FormattedMessage
              id="apps.websites.containers.onboarding.categories.attractionType.sidebar.descripition"
              defaultMessage="Whats the location?"
            />
          ),
        },
  },
    "regional-attraction-type": {
        title: <FormattedMessage
            id="apps.websites.categories.regionalAttraction"
            defaultMessage="Regional Attraction"
        />,
        min: 1,
        max: 1,
        sidebar: {
            heading: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.regionalAttraction.sidebar.heading"
                defaultMessage="Regional Attraction"
              />
            ),
            description: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.regionalAttraction.sidebar.descripition"
                defaultMessage="The type of the Regional Attraction."
              />
            ),
          },
    },
    "parking": {
        title: <FormattedMessage
            id="apps.websites.categories.parking"
            defaultMessage="Parking"
        />,
        min: 0,
        max: 0,
        sidebar: {
            heading: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.parking.sidebar.heading"
                defaultMessage="Parking"
              />
            ),
            description: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.parking.sidebar.descripition"
                defaultMessage="The type of parking."
              />
            ),
          },
    },
    "internet": {
        title: <FormattedMessage
            id="apps.websites.categories.internet"
            defaultMessage="Internet"
        />,
        min: 0,
        max: 0,
        sidebar: {
            heading: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.internet.sidebar.heading"
                defaultMessage="Internet"
              />
            ),
            description: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.internet.sidebar.descripition"
                defaultMessage="Internet"
              />
            ),
          },
    },
    "security": {
        title: <FormattedMessage
            id="apps.websites.categories.security"
            defaultMessage="Security"
        />,
        min: 0,
        max: 0,
        sidebar: {
            heading: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.security.sidebar.heading"
                defaultMessage="Security"
              />
            ),
            description: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.security.sidebar.descripition"
                defaultMessage="Security"
              />
            ),
          },
    },
    "supported-languages": {
        title: <FormattedMessage
            id="apps.websites.categories.languages"
            defaultMessage="Languages"
        />,
        min: 0,
        max: 0,
        sidebar: {
            heading: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.languages.sidebar.heading"
                defaultMessage="Languages"
              />
            ),
            description: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.languages.sidebar.descripition"
                defaultMessage="Languages"
              />
            ),
          },
    },
    "technology": {
        title: <FormattedMessage
            id="apps.websites.categories.technology"
            defaultMessage="Technology"
        />,
        min: 0,
        max: 0,
        sidebar: {
            heading: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.technology.sidebar.heading"
                defaultMessage="Technology"
              />
            ),
            description: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.technology.sidebar.descripition"
                defaultMessage="Technology"
                />
            ),
          },
    },
    "climate": {
        title: <FormattedMessage
            id="apps.websites.categories.climate"
            defaultMessage="Climate"
        />,
        min: 0,
        max: 0,
        sidebar: {
            heading: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.climate.sidebar.heading"
                defaultMessage="Climate"
              />
            ),
            description: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.climate.sidebar.descripition"
                defaultMessage="Climate"
                />
            ),
          },
    },
    "atmosphere": {
        title: <FormattedMessage
            id="apps.websites.categories.atmosphere"
            defaultMessage="Atmosphere"
        />,
        min: 0,
        max: 0,
        sidebar: {
            heading: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.atmosphere.sidebar.heading"
                defaultMessage="Atmosphere"
              />
            ),
            description: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.atmosphere.sidebar.descripition"
                defaultMessage="Atmosphere"
              />
            ),
          },
    },
    "relax": {
        title: <FormattedMessage
            id="apps.websites.categories.relax"
            defaultMessage="Relax"
        />,
        min: 0,
        max: 0,
        sidebar: {
            heading: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.relax.sidebar.heading"
                defaultMessage="Relax"
              />
            ),
            description: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.relax.sidebar.descripition"
                defaultMessage="Relax"
                />
            ),
          },
    },
    "spa": {
        title: <FormattedMessage
            id="apps.websites.categories.spa"
            defaultMessage="Spa"
        />,
        min: 0,
        max: 0,
        sidebar: {
            heading: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.spa.sidebar.heading"
                defaultMessage="Spa"
              />
            ),
            description: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.spa.sidebar.descripition"
                defaultMessage="Spa"
              />
            ),
          },
    },
    "kids": {
        title: <FormattedMessage
            id="apps.websites.categories.kids"
            defaultMessage="Kids"
        />,
        min: 0,
        max: 0,
        sidebar: {
            heading: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.kids.sidebar.heading"
                defaultMessage="Kids"
              />
            ),
            description: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.kids.sidebar.descripition"
                defaultMessage="Kids"
              />
            ),
          },
    },
    "disabled-facilities": {
        title: <FormattedMessage
            id="apps.websites.categories.disabledFacilities"
            defaultMessage="Disabled Facilities"
        />,
        min: 0,
        max: 0,
        sidebar: {
            heading: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.disabledFacilities.sidebar.heading"
                defaultMessage="Disabled Facilities"
              />
            ),
            description: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.disabledFacilities.sidebar.descripition"
                defaultMessage="Disabled Facilities"
                />
            ),
          },
    },
    "meals": {
        title: <FormattedMessage
            id="apps.websites.categories.meals"
            defaultMessage="Meals"
        />,
        min: 0,
        max: 0,
        sidebar: {
            heading: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.meals.sidebar.heading"
                defaultMessage="Meals"
              />
            ),
            description: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.meals.sidebar.descripition"
                defaultMessage="Meals"
                />
            ),
          },
    },
    "special-transport": {
        title: <FormattedMessage
            id="apps.websites.categories.specialTransport"
            defaultMessage="Special Transport"
        />,
        min: 0,
        max: 0,
        sidebar: {
            heading: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.specialTransport.sidebar.heading"
                defaultMessage="Special Transport"
              />
            ),
            description: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.specialTransport.sidebar.descripition"
                defaultMessage="Special Transport"
                />
            ),
          },
    },
    "view": {
        title: <FormattedMessage
            id="apps.websites.categories.view"
            defaultMessage="View"
        />,
        min: 0,
        max: 0,
        sidebar: {
            heading: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.view.sidebar.heading"
                defaultMessage="View"
              />
            ),
            description: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.view.sidebar.descripition"
                defaultMessage="View"
                />
            ),
          },
    },
    "feeding": {
        title: <FormattedMessage
            id="apps.websites.categories.feeding"
            defaultMessage="Feeding"
        />,
        min: 0,
        max: 0,
        sidebar: {
            heading: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.feeding.sidebar.heading"
                defaultMessage="Feeding"
              />
            ),
            description: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.feeding.sidebar.descripition"
                defaultMessage="Feeding"
                />
            ),
          },
    },
    "special-offers": {
        title: <FormattedMessage
            id="apps.websites.categories.specialOffers"
            defaultMessage="Special Offers"
        />,
        min: 0,
        max: 0,
        sidebar: {
            heading: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.specialOffers.sidebar.heading"
                defaultMessage="Special Offers"
              />
            ),
            description: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.specialOffers.sidebar.descripition"
                defaultMessage="Special Offers"
                />
            ),
          },
    }, 
    "nearby-attractions": {
        title: <FormattedMessage
            id="apps.websites.categories.nearbyAttractions"
            defaultMessage="Nearby Attractions"
        />,
        min: 0,
        max: 0,
        sidebar: {
            heading: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.nearbyAttractions.sidebar.heading"
                defaultMessage="Nearby Attractions"
              />
            ),
            description: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.nearbyAttractions.sidebar.descripition"
                defaultMessage="Nearby Attractions"
                />
            ),
          },
    }, 
    "sport": {
        title: <FormattedMessage
            id="apps.websites.categories.sport"
            defaultMessage="Sport"
        />,
        min: 0,
        max: 0,
        sidebar: {
            heading: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.sport.sidebar.heading"
                defaultMessage="Sport"
              />
            ),
            description: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.sport.sidebar.descripition"
                defaultMessage="Sport"
                />
            ),
          },
    }, 
    "recreation": {
        title: <FormattedMessage
            id="apps.websites.categories.recreation"
            defaultMessage="Recreation"
        />,
        min: 0,
        max: 0,
        sidebar: {
            heading: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.recreation.sidebar.heading"
                defaultMessage="Recreation"
              />
            ),
            description: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.recreation.sidebar.descripition"
                defaultMessage="Recreation"
                />
            ),
          },
    }, 
    "events": {
        title: <FormattedMessage
            id="apps.websites.categories.events"
            defaultMessage="Events"
        />,
        min: 0,
        max: 0,
        sidebar: {
            heading: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.events.sidebar.heading"
                defaultMessage="Events"
              />
            ),
            description: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.events.sidebar.descripition"
                defaultMessage="Events"
                />
            ),
          },
    }, 
    "beauty": {
        title: <FormattedMessage
            id="apps.websites.categories.beauty"
            defaultMessage="Beauty"
        />,
        min: 0,
        max: 0,
        sidebar: {
            heading: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.beauty.sidebar.heading"
                defaultMessage="Beauty"
              />
            ),
            description: (
              <FormattedMessage
                id="apps.websites.containers.onboarding.categories.beauty.sidebar.descripition"
                defaultMessage="Beauty"
                />
            ),
          },
    }
}