
import { Button, Col, Collapse, Grid } from "antd";
import useSimpleBreakpoint from "services/hooks/useSimpleBreakpoint";
import { CalculateGridStyle } from "./infinityTemplates";
import { isFirefox } from 'react-device-detect';
import FeatureErrorBoundry from "providers/routing/FeatureErrorBoundry";
import SimpleErrorBoundry from "providers/routing/SimpleErrorBoundry";
import { useEffect } from "react";

function Row (props:any) {

    const [size, sizeIndex] = useSimpleBreakpoint();

    const mapToGridCell = (c: any, q: any) => {
        const style: any = { ...c.style};
        if (c.render) {
            if (!c.size || !c.size[sizeIndex] || c.size[sizeIndex] === false || c.size[sizeIndex] === "0") return;

            return (
                <div
                    key={c.key}
                    className={`infinity-element-wrapper ${c.key}`}
                    style={{ ...c.wrapperStyle, gridArea: c.key}}>
                    <div className="infinity-element" style={style}>
                            {c.render(props.item, { ...props.options, ...q, ...(props.details || {}), size: c.size, sizeIndex: sizeIndex, })}
                    </div>
                </div>
            )
        }
        return null;
    } 

    const mapToGridRow = () => {
        return props.columns.map((c:any) => mapToGridCell(c, props.query));
    }

    const calculateGridStyle = () => {
        
        const style = CalculateGridStyle(props.columns, sizeIndex);

        return {
            ...style, 
            width: "100%",
            paddingRight: isFirefox ? "5px" : "0px",
            ...props.style
        }
    }

    return (
        <div
        className={`column-grid ${props.size} ${props.className ? props.className : ""}`}
        style={{ ...calculateGridStyle()}}>
            {mapToGridRow()}
    </div>
    )

}

export default function TemplateRow (props:any) {
    return (
        <SimpleErrorBoundry>
            <Row {...props} />
        </SimpleErrorBoundry>
    )
}