import { UploadFile } from "antd/es/upload/interface"
import { Children, useMemo } from "react"
// import { DndProvider } from "react-dnd"
// import { TouchBackend } from "react-dnd-touch-backend"
import { AllowedTools, useGallery } from "./Gallery"
import ImageGalleryElement from "./GalleryElement"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
    DndContext, 
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
  } from '@dnd-kit/core';
  import {
    arrayMove,
    rectSortingStrategy,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
  } from '@dnd-kit/sortable';
  

export type ImageGalleryElementListProps = {
    
    allowedTools?:AllowedTools,
    children?: any,
    listPrefix?: any,
    listSuffix?: any,
}



const ImageGalleryElementList = (props:ImageGalleryElementListProps) => {

    const {galleryItems, selectedImages, reorderImage} = useGallery();

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
          coordinateGetter: sortableKeyboardCoordinates,
        })
      );


    const mappedItems = useMemo(() => galleryItems && galleryItems.map((item) => (
        <ImageGalleryElement
            item={item}
            isSelected={selectedImages?.includes((item as any).url)}
            id={(item as any).index}
        />
    )),[galleryItems, selectedImages])

    const handleDragEnd = (event:any) => {

        const {active, over} = event;

        if (active.id === over.id) return;

        reorderImage(active.id, over.id)
          
    }

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd} 
        >
            <SortableContext
                items={(galleryItems || []).map((i:any) => i.index)}
                strategy={rectSortingStrategy}
                >
                    <div
                        className="element-list"
                    >
                        {props.listPrefix}
                        {mappedItems}
                        {props.children}
                        {props.listSuffix}
                    </div>
                </SortableContext>

        </DndContext>
    )

}


export default ImageGalleryElementList;