import { SubscriptionOfferDisplayInPortal } from "components/purchases/subscriptionPurchase/SubscriptionOfferDisplay";
import OnboardingControls from "providers/onboarding/controls";
import { useOnboarding } from "providers/onboarding/onboarding-context";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import useScope from "services/hooks/useScope";
import { currentProductSelector, currentProjectIdSelector } from "services/store/scopeSlice";
import PurchaseSubscription, { SubscriptionPurchaseInitialOptions } from "components/purchases/subscriptionPurchase/PurchaseSubscription";
import { useEffect, useState } from "react";
import { loadSubscriptionPurchaseInitialOptions } from "state/subscriptions/subscriptionsHelpers";
import { useDispatch } from "react-redux";

//PURCHASE SUBSCRIPTION during onboarding (for projects without subscription)

function OnboardingPayment() {

    const projectId = useSelectorWithParams(currentProjectIdSelector);
    const productFromState = useSelectorWithParams(currentProductSelector);
    const {nextStep} = useOnboarding();

    const lsSettings = localStorage.getItem("selectedOffer") ? JSON.parse(localStorage.getItem("selectedOffer") as string) : null;
    const product = lsSettings?.product || productFromState || import.meta.env.VITE_INSTANCE_DEFAULT_PRODUCT;

    const [initialPurchaseOptions, setInitialPurchaseOptions] = useState<SubscriptionPurchaseInitialOptions>(loadSubscriptionPurchaseInitialOptions({
        projectId,
        product,
    }));

    const scope = useScope();
    
    useEffect(() => {
        if (initialPurchaseOptions && initialPurchaseOptions.product) {
            scope.changeScope(initialPurchaseOptions.product,
                scope.projectId,
                scope.subscriptionId
            )
        }
    }, [initialPurchaseOptions]);

    const handlePaymentSuccess = (data:any) => {

        const subscriptionId = data?.subscription?.id;
        scope.openNewSubscription(subscriptionId)

        nextStep();
    }

    return (
        <div>
        <PurchaseSubscription
            onComplete={handlePaymentSuccess}
            initialPurchaseOptions={initialPurchaseOptions}
        >
            <SubscriptionOfferDisplayInPortal 
            summaryClassName="white-text"
            />
        </PurchaseSubscription>
        <OnboardingControls hideButtons={true}/>
        </div>
    );
}

export default OnboardingPayment

