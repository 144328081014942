import { Select, Space, Spin, Typography } from "antd";
import ConnectedOnboardingControls from "providers/onboarding/controls";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import industriesConifg, { GetIndustryByName } from "../config/industries/IndustriesConfig";
import FormattedMessage from "components/common/FormattedMessage";
import useAPI, { callAPIProps } from "services/hooks/useAPI";
import { StandardStatusMessage } from "components/common/StandardText";
import websiteSlice, { WebsiteSelector } from "../reducers/websiteSlice";
import { useEffect, useMemo, useState } from "react";
import { HorizontalCenter } from "components/common/Center";
import { useOnboarding } from "providers/onboarding/onboarding-context";
import { currentProductSelector, currentProjectIdSelector } from "services/store/scopeSlice";
import { currentProjectSelector } from "state/projects/projectSelectors";

const patchProject = (projectId:any):callAPIProps => ({
    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}`),
    method: "PATCH",
})


export default function OnboardingCreateWebsiteAndIndustrySelect() {

    const projectId = useSelectorWithParams(currentProjectIdSelector);
    const project = useSelectorWithParams(currentProjectSelector);
    const product = useSelectorWithParams(currentProductSelector);
    const fulfilled = useSelectorWithParams(() => false);
    const website = useSelectorWithParams(WebsiteSelector);
    const [industry, setIndustry] = useState<string | null>(null);
    const {nextStep} = useOnboarding();

    const callMakeWebsite = useAPI({
        url: ({ getApiUrl }) => getApiUrl(`projects/${projectId}/website`,2),
        method: "POST",
        successDispatch: websiteSlice.actions.create,
    });

    const lockedIndustry = useMemo(() => {
        if (product === "FastTonyCarservice") return "carservice";
        if (product === "tourism") return "touristic";
        return null;
    }, [product])


    const makeWebsiteWithIndustry = async (i:string) => {
        let c = await callMakeWebsite.call({
            body: {
                industry: i,
            }
        });
        if (c && c.status===200) return true;
        return false;
    }

    useEffect(() => {

        if (!project) {
            return;
        }

        if (project && !website && lockedIndustry && !callMakeWebsite.loading && !callMakeWebsite.error) {
            makeWebsiteWithIndustry(lockedIndustry).then((r) => {
                if (r) {
                    nextStep();
                } else {
                }
            });
        }


    }, [project, website, product, industry, lockedIndustry])

    const handleNext = async () => {
        if (!industry) return false;
        let c = await makeWebsiteWithIndustry(industry)
        return c;
    }

    if (lockedIndustry) return <>
        <HorizontalCenter>
            <Spin/>
        </HorizontalCenter>
        <ConnectedOnboardingControls nextDisabled={true}/>
    </>;

    return (
        <Space style={{ width: "100%" }} direction="vertical">
            <Typography.Title level={3}>
                <FormattedMessage
                    id="apps.websites.onboarding.industrySelect.title"
                    defaultMessage="Select your industry"
                />
            </Typography.Title>
            <Typography.Paragraph
                style={{
                    whiteSpace: "pre-line",
                }}
            >
                <FormattedMessage
                    id="apps.websites.onboarding.industrySelect.description"
                    defaultMessage="Select the industry of your business." />
            </Typography.Paragraph>
            <StandardStatusMessage
                type="warning"
                text={
                    <FormattedMessage
                        id="apps.websites.onboarding.industrySelect.noChangeWarning"
                        defaultMessage="This cannot be changed later."
                    />
                }
            />
            <Select
                options={industriesConifg} 
                style={{width: "100%"}}
                onChange={(v) => setIndustry(v)}
                />

            <ConnectedOnboardingControls nextDisabled={!industry} clickOverride={handleNext}/>
        </Space>
    )

}