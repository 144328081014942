import { Input, Select } from "antd";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import OrganizationTableInvitations from "./components/OrganizationTableInvitations";
import OrganizationTools from "./components/OrgannizationTools";
import { NewInvitation } from "./data/organizationSlice";
import { toolsGroup, useOrganizationTool } from "./OrganizationManager";
import { OrganizationRoleNames } from "./tools/OrganizationToolHelpers";



const NewInvitationForm = () => {

    const {inputData, setOutputData, outputData} = useOrganizationTool();


    return (
        <>
        New invitation will be created and sent to the following email.
        <RelaxSingleField
            onChange={c => setOutputData((o:any) => ({...o, email: c}))}
            label="Email"
            name="email">
            <Input type="email"/>
        </RelaxSingleField>
        <RelaxSingleField
            onChange={r => setOutputData((o:any) => ({...o, role: r}))}
            label="Role"
            name="role">
            <Select 
                options={OrganizationRoleNames}
            />
        </RelaxSingleField>
        </>
    )

}

const intivationsTools:toolsGroup = {
    key: "invitationTools",
    label: "Invitations",
    tools: [
        {
            key: "newInvitation",
            label: "New Invitation",
            title: "Create new invitation",
            component: <NewInvitationForm />,
            toolAPICall: (inputData: any, outputData: any) => {
                return {...NewInvitation(), body: {
                    email: outputData.email,
                    role: outputData.role
                }}
            }
        },  
    ]
}


export default function OrganizationInvitationsTab (props:any) {

    const {setInputData} = useOrganizationTool();

    return (
        <>
            <OrganizationTools toolset = {intivationsTools}/>
            <OrganizationTableInvitations onSelectedRows={(rows: string[]) => setInputData((i:any) => ({...i, projects: rows}))}/>
        </>
    )
}