import React, { FunctionComponent } from "react";
import { Form } from '@ant-design/compatible';
import { Tooltip } from "antd";
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import {GetFieldDecoratorOptions} from "@ant-design/compatible/lib/form/Form";
import getDecoratorOptions, {
  formItemLayout,
  requiredFieldRule,
} from "services/helpers/forms-helpers";
import {ValidationRule} from "@ant-design/compatible/lib/form/Form";
import PassFormItemPropsToChildren from "_components/forms/form-item-wrapper/pass-form-item-props-to-children";
import { FormItemProps } from "antd/es/form";
import mergeWith from "lodash/mergeWith";
import ErrorsInputHelper from "_components/forms/errors-input-helper";
import { TooltipIcon } from "modules/panel/components/ft-icons";
import FormattedMessage from "components/common/FormattedMessage";

type RequestStatus = {
  pending: boolean;
  error: {
    fields: {
      code: string;
    };
  };
  success: boolean;
};
export type FormHocProps = {
  getRequestResultFieldProps: {
    (field: string): {
      validateStatus: "success" | "error";
      hasFeedback: boolean;
      help?: string;
    };
  };
  instance: WrappedFormUtils;
  handleSubmit: (values?: any) => void;
  submitForm: (values: any) => void;
  fieldsSetted: boolean;
  status: {
    get: (key: any) => RequestStatus;
  } & RequestStatus;
};

export type FormValues = { [key: string]: any };

export type withFormHoc = {
  form: FormHocProps;
};

type ActionConfig = {
  action: string;
  // eslint-disable-next-line
  dispatch: () => void;
};

type WatchActionConfig = {
  action: string;
  selector?: (state: never) => any;
  dispatch: (dispatch: any) => () => any;
};

type FormConfig = {
  mapPropsToFields?: (props: any) => FormValues; //TODO: contextual state from props
  parseValuesBeforeSend?: (values: any) => FormValues; //TODO: contextual state from props
  redirectOnSuccess?: () => string;
} & ActionConfig;

export type CreateFormConfig = {
  form: FormConfig;
  redux?: {
    mapStateToProps?: (state: never) => {
      [prop: string]: any;
    };
    mapDispatchToProps?: (dispatch: void) => {
      [prop: string]: void;
    };
  };
  watch?: {
    [actionName: string]: WatchActionConfig;
  }; //TODO:
  integrations?: string[]; //TODO: rewrite config, pass integrations keys enum instead string
  withRouter?: boolean;
};

type ArrayOfFunctions = {
  (parameter: any): {
    [key: string]: any;
  };
}[];

const pushSpecifiedFunctionsToArray =
  (keys: string[]) => (objValue: any, srcValue: any, key: string) => {
    if (keys.includes(key)) {
      if (!objValue) objValue = [];

      return [...objValue, srcValue];
    }

    return srcValue;
  };

const invokeEveryFunction = (
  arrayOfFunctions?: ArrayOfFunctions,
  parameter: any = undefined
) => {
  if (!Array.isArray(arrayOfFunctions)) return {};

  let result = {};

  arrayOfFunctions.forEach(
    (func) =>
      (result = {
        ...result,
        ...func(parameter),
      })
  );

  return result;
};

export const mergeCreateFormConfigs = (
  configs: CreateFormConfig[]
): CreateFormConfig => {
  let mergedConfigsWithFunctionsAsArrays: {
    form: {
      mapPropsToFields?: ArrayOfFunctions;
      parseValuesBeforeSend?: ArrayOfFunctions;
    } & Omit<
      CreateFormConfig["form"],
      "mapPropsToFields" | "parseValuesBeforeSend"
    >;
    redux: {
      mapStateToProps?: ArrayOfFunctions;
      mapDispatchToProps?: ArrayOfFunctions;
    };
  } & Omit<CreateFormConfig, "form" | "redux"> = {
    form: {
      action: "",
      // eslint-disable-next-line
      dispatch: () => {},
    },
    redux: {},
  };

  configs.forEach((config) => {
    mergedConfigsWithFunctionsAsArrays = {
      ...mergedConfigsWithFunctionsAsArrays,
      ...config,
      form: mergeWith(
        mergedConfigsWithFunctionsAsArrays.form,
        config.form,
        pushSpecifiedFunctionsToArray([
          "mapPropsToFields",
          "parseValuesBeforeSend",
        ])
      ),
      redux: mergeWith(
        mergedConfigsWithFunctionsAsArrays.redux,
        config.redux,
        pushSpecifiedFunctionsToArray(["mapStateToProps", "mapDispatchToProps"])
      ),
      watch: {
        ...mergedConfigsWithFunctionsAsArrays.watch,
        ...config.watch,
      },
    };
  });

  return {
    ...mergedConfigsWithFunctionsAsArrays,
    form: {
      ...mergedConfigsWithFunctionsAsArrays.form,
      mapPropsToFields: (props: any) =>
        invokeEveryFunction(
          mergedConfigsWithFunctionsAsArrays.form.mapPropsToFields,
          props
        ),
      parseValuesBeforeSend: (values: FormValues) =>
        invokeEveryFunction(
          mergedConfigsWithFunctionsAsArrays.form.parseValuesBeforeSend,
          values
        ),
    },
    redux: {
      mapStateToProps: (state: any) =>
        invokeEveryFunction(
          mergedConfigsWithFunctionsAsArrays.redux.mapStateToProps,
          state
        ),
      mapDispatchToProps: (dispatch: void) =>
        invokeEveryFunction(
          mergedConfigsWithFunctionsAsArrays.redux.mapDispatchToProps,
          dispatch
        ),
    },
  };
};

export interface FormItemInterface extends withFormHoc {
  children: React.ReactNode;
  field: string;
  testId?: string;
  label?: string | React.ReactNode;
  validator?: string;
  required?: boolean;
  initialValue?: any;
  showApiResponseErrors?: boolean;
  rules?: ValidationRule[];
  options?: GetFieldDecoratorOptions;
  showValidatorResults?: boolean;
  showHelp?: boolean;
  tooltip?: React.ReactNode;
  listMaxCount?: number;
  hideOptionalLabel?: boolean;
}

const FormItem: FunctionComponent<FormItemInterface> = ({
  children,
  field,
  form,
  label = false,
  validator = false,
  required = false,
  initialValue = false,
  showApiResponseErrors = true,
  rules = [],
  options = {},
  showValidatorResults = false,
  showHelp = true,
  tooltip = false,
  hideOptionalLabel = false,
}) => {
  let fieldDecorator: GetFieldDecoratorOptions = {};

  if (validator)
    fieldDecorator = { ...fieldDecorator, ...getDecoratorOptions(validator) };

  if (options) fieldDecorator = { ...fieldDecorator, ...options };

  if (initialValue) fieldDecorator.initialValue = initialValue;

  if (rules)
    fieldDecorator.rules = [
      ...(fieldDecorator.rules ? fieldDecorator.rules : []),
      ...rules,
    ];

  if (required)
    fieldDecorator.rules = [
      ...(fieldDecorator.rules ? fieldDecorator.rules : []),
      requiredFieldRule,
    ];

  let formItemProps: FormItemProps = {
    ...formItemLayout,
  };

  if (showApiResponseErrors)
    formItemProps = {
      ...formItemProps,
      ...form.getRequestResultFieldProps(field),
    };

  if (label)
    formItemProps.label = (
      <>
        {label}{" "}
        {(!required && !hideOptionalLabel) && (
          <>
            (
            <FormattedMessage
              id="components.forms.field.optional"
              defaultMessage="optional"
            />
            )
          </>
        )}
      </>
    );

  if (!showHelp) formItemProps.help = false;

  return (
    <Form.Item
      {...formItemProps}
      required={false}
      className={tooltip ? "has-tooltip" : ""}
    >
      {form.instance.getFieldDecorator(
        field,
        fieldDecorator
      )(<PassFormItemPropsToChildren input={children} />)}
      {tooltip && (
        <Tooltip title={tooltip} className="tooltip-icon">
          <TooltipIcon />
        </Tooltip>
      )}
      {showValidatorResults && (
        <ErrorsInputHelper
          fieldValue={form.instance.getFieldValue(field)}
          rules={fieldDecorator}
          currentErrors={form.instance.getFieldError(field)}
        />
      )}
    </Form.Item>
  );
};

export default FormItem;
