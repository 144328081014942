import React from "react";
import Helper from "providers/routing/routes/helper";
import FormattedMessage from "components/common/FormattedMessage";
import { LayoutRouteConfig } from "providers/routing/layouts/layouts";
import { Layout } from "providers/routing/layouts/templates/templates";

import LoginForm from "modules/panel/containers/public/login";
import ResetPasswordForm from "modules/panel/containers/public/reset-password/request";
import SetNewPasswordForm from "modules/panel/containers/public/reset-password/set-new";
import DynamicIntegratePlatforms from "modules/panel/containers/onboarding/integrations/dynamic-integrate-platforms";

import WeWillContactUOnboarding from "modules/panel/containers/onboarding/we-will-contact-u";
import AuthorizationSuccessOnboarding from "modules/panel/containers/onboarding/authorization-success";
import CompanySettingsPage from "../containers/company/CompanySettingsPage";
import subscriptionsPage from "../containers/user/subscriptionsPage";
import TestPage from "../testing/test";
import LocalStorageOverride from "../containers/public/local-storage-override";
import InfinityPostList from "modules/posts/InfinityPostList";
import { OnboardingProviderIntegrationFacebook, OnboardingProviderIntegrationGoogle } from "../components/integrations/ProviderIntegration";
import ToolIntegrations from "../components/toolIntegrations/ToolIntegrations";
import PixelAndDomain from "../components/tracking/Tracking";
import { AwesomeLarge } from "components/common/AwesomeIcon";
import { faArrowUpArrowDown, faBadgePercent, faBriefcase, faCog, faComments, faFileSignature, faFolder, faGlobe, faGraduationCap, faList, faRobot, faScreenUsers, faTag, faUser, faVial, faWallet } from "@fortawesome/pro-light-svg-icons";
import { OnboardingMarketingConsentsStatement, OnboardingTermsOfServiceStatement } from "../components/statements/Statement";
import TasksList from "tools/tasks/TasksList";
import Invitation from "../containers/public/Invitation";
import PrepareProject from "../containers/onboarding/projects/PrepareProject";
import AILexiconTestPanel from "../testing/AILexicon/AILexiconTestPanel";
import ManageIntegrations from "../components/integrations/ManageProviderIntegrations";
import ProjectSettings from "../containers/projects/ProjectSettings";
import PromoList from "../components/promo/PromoList";
import UserAccountPage from "../containers/user/UserAccountPage";
import AiAssistant from "../containers/assistant/AiAssistant";
import { OpenAiIcon } from "components/common/AdditionalIcons";
import OnboardingVerifyPhone from "../containers/onboarding/verifyphone/OnboardingVerifyPhone";

import Dashboard from "../components/Dashboard/Dashboard";
import TestIndex from "../testing/TestIndex";
import { OnboardingCompanyInvoiceData } from "../containers/company/OnboardingCompanyInvoiceData";
import SubscriptionCancellationWizard from "../components/user/subscriptions/cancellation/SubscriptionCancellationWizard";
import OnboardingPayment from "../containers/onboarding/OnboardingPayment";
import BuyNewSubscription from "../components/projects/BuyNewSubscription";
import SubscriptionDetailsPage from "../components/user/subscriptions/SubscriptionDetailsPage";
import PromoCalendar from "../components/promo/PromoCalendar";
import ProjectList from "../containers/projects/ProjectList";
import { StatementPageBusinessPurposses, StatementPageMarketingConsents, StatementPageTermsOfService } from "../components/statements/Statements";
import LogoutPage from "../containers/public/LogoutPage";
import { DeleteUser } from "../components/user/UserDeletion/DeleteUser";
import UserWantsToDeleteAccount from "../containers/public/UserWantsToDeleteAccount";
import SetOfferPurchaseOptions from "../containers/public/SetOfferPurchaseOptions";
import ProductsPage from "../containers/products/ProductsPage";
import OnboardingUpdateProjectStatus from "../containers/onboarding/projects/OnboardingUpdateProjectStatus";
import OnboardingContactEmail from "../containers/user/OnboardingContactEmail";
import ProductCatalogs from "modules/smart/components/productCatalogs/ProductCatalogs";


export const LOGIN = "login",
  DASHBOARD = "dashboard",
  ACCOUNT = "account",
  LOGOUT = "logout",
  SET_PRODUCT_AND_OFFER_ID = "setProductAndOfferId",
  LOCAL_STORAGE_OVERRIDE = "superSecret",
  INVITATION = "invitation",
  CHANGE_PASSWORD = "changePassword",
  RESET_PASSWORD = "resetPassword",
  SET_NEW_PASSWORD = "setNewPassword",
  USER_DETAILS = "userDetails",
  DETAILS = "details",
  INVOICES = "invoices",
  PAYMENTS = "payments",
  SUBSCRIPTIONS = "subscriptions",
  SUBSCRIPTION_CANCELLATION = "subscriptionCancellation",
  USER_DELETION = "userDeletion",
  USER_DELETION_PAGE = "userDeletionPage",

  USER_SETTINGS = "userSettings",
  PROJECT_SETTINGS = "projectSettings",
  COMPANY_SETTINGS = "companySettings",

  SUBSCRIPTION_DETAILS = "subscriptionDetails",

  PRODUCTS = "products",


  COMPANY_SUBSCRIPTIONS = "companySubscriptions",
  COMPANY_BUY_PRODUCT = "companyBuyProduct",
  CHANGE_CREDIT_CARD = "changeCreditCard",
  COMPANY_PAYMENTS = "companyPayments",
  COMPANY_PROJECTS = "companyProjects",
  PROJECT_APP_SWITCHER = "projectsAppSwitcher",
  TEMP_STORAGE = "tempStorage",
  INTEGRATIONS = "integrations",
  PLATFORM_INTEGRATIONS = "platformIntegrations",
  TASKS = "tasks",
  TRACKING = "tracking",
  ONBOARDING_PAYMENT = "onboardingPayment",
  ONBOARDING_PREPARE_PROJECT = "onboardingPrepareProject",
  ONBOARDING_CHOOSE_PROJECT = "onboardingChooseProject",
  ONBOARDING_UPDATE_PROJECT_STATUS = "onboardingUpdateProjectStatus",
  ONBOARDING_DYNAMIC_INTEGRATE_PLATFORMS = "dynamicIntegratePlatforms",
  ONBOARDING_SET_FACEBOOK_INTEGRATION = "onboardingSetFacebookIntegration",
  ONBOARDING_SET_GOOGLE_INTEGRATION = "onboardingSetGoogleIntegration",
  ONBOARDING_COMPANY_DATA = "onboardingCompanyData",
  ONBOARDING_MARKETING_STATEMENT = "onboardingMarketingStatement",
  ONBOARDING_TERMS_OF_SERVICES_STATEMENT = "onboardingTermsOfServicesStatement",
  TOKEN_PARAMETER = "token",
  COMPANY = "company",
  PROJECTS = "projects",
  PROJECT_ID_PARAMETER = "projectId",
  RESET_PASSWORD_BASE_URL = "/reset-password",
  TEMP_STORAGE_URL = "storage/tmp",
  NOT_FOUND_URL = "/not-found",
  ONBOARDING_BASE_URL = "/onboarding",
  ONBOARDING_WE_WILL_CONTACT_U = "onboardingWeWillContactU",
  ONBOARDING_VERIFY_NUMBER = "onboardingVerifyNumber",
  ONBOARDING_AUTHORIZATION_SUCCESS = "onboardingAuthSuccess",
  ONBOARDING_CONTACT_EMAIL = "onboardingContactEmail",
  TEST_PAGE = "testPage",
  EXPERIMENTAL = "experimental",
  PROMOTIONS = "promotions",
  ASSISTANT = "assistant",
  TESTS = "tests",
  SUPER_PROMO = "superPromo",
  EVENTS = "events",
  STATEMENT_TERMS_OF_SERVCIE = "termsOfService",
  STATEMENT_MARKETING_CONSENTS = "marketingConsents";

export const APP_PATH = "panel";

const routes: LayoutRouteConfig[] = [
  {
    key: DASHBOARD,
    path: "/dashboard",
    name: (
      <FormattedMessage
        id="apps.smart.routing.dashboard"
        defaultMessage="Dashboard"
      />
    ),
    icon: <AwesomeLarge icon={faUser} />,
    layout: Layout.Panel,
    title: (
      <FormattedMessage
        id="apps.smart.routing.dashbaord"
        defaultMessage="Dashboard"
      />
    ),
    content: Dashboard,
    fullHeight: true,
    mainHasBackground: false,
    displayInMenu: false,
  },
  {
    key: LOGIN,
    path: "/login",
    public: true,
    layout: Layout.TwoColumns,
    content: LoginForm,
    displayInMenu: false,
    title: (
      <FormattedMessage
        id="panel.containers.login.heading"
        defaultMessage="Hi There!"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="panel.containers.login.backAgain"
        defaultMessage="We’re happy to have you here again!"
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="panel.providers.routing.templates.panel.sidebars.registerNow.heading"
          defaultMessage="No account?"
        />
      ),
      description: (
        <FormattedMessage
          id="panel.providers.routing.templates.panel.sidebars.registerNow.slogan"
          defaultMessage="Are you here first time? Awesome! <div>Click Facebook button</div>"
          values={{
            div: (chunks: any) => (
              <>
                <br /> {chunks}
              </>
            ),
          }}
        />
      ),
    },
  },
  {
    key: LOGOUT,
    path: "/logout",
    layout: Layout.Panel,
    content: LogoutPage,
    mainHasBackground: false,
    fullHeight: true,
    displayInMenu: false,
  },
  {
    key: SET_PRODUCT_AND_OFFER_ID,
    path: "/product/:product/:offerId?",
    public: true,
    content: SetOfferPurchaseOptions,
    layout: Layout.Blank,
    displayInMenu: false,
  },
  {
    key: LOCAL_STORAGE_OVERRIDE,
    path: "/enableFeature/",
    public: true,
    content: LocalStorageOverride,
    layout: Layout.Blank,
    displayInMenu: false,
  },
  {
    key: INVITATION,
    path: "/invitation/",
    public: true,
    content: Invitation,
    layout: Layout.Blank,
    displayInMenu: false,
  },
  {
    key: RESET_PASSWORD,
    path: `/resetPassword`,
    public: true,
    layout: Layout.TwoColumns,
    content: ResetPasswordForm,
    sidebar: {
      heading: (
        <FormattedMessage
          id="panel.providers.routing.templates.panel.sidebars.resetPassword.heading"
          defaultMessage="Sometimes **** happens"
        />
      ),
      description: (
        <FormattedMessage
          id="panel.providers.routing.templates.panel.sidebars.resetPassword.slogan"
          defaultMessage="Your account miss you.<c1>Use this form to get your brand</c1><c2>new password</c2>"
          values={{
            c1: (chunks: any) => (
              <>
                <br /> {chunks}
              </>
            ),
            c2: (chunks: any) => (
              <>
                <br /> {chunks}
              </>
            ),
          }}
        />
      ),
    },
    displayInMenu: false,
  },
  {
    key: SET_NEW_PASSWORD,
    path: `${RESET_PASSWORD_BASE_URL}/:${TOKEN_PARAMETER}`,
    public: true,
    layout: Layout.TwoColumns,
    content: SetNewPasswordForm,
    displayInMenu: false,
    title: (
      <FormattedMessage
        id="panel.containers.resetPassword.set.title"
        defaultMessage="Set new password"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="panel.containers.resetPassword.set.subtitle"
        defaultMessage="Set your account password"
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="panel.providers.routing.templates.panel.sidebars.setPassword.heading"
          defaultMessage="Sometimes **** happens"
        />
      ),
      descripiton: (
        <FormattedMessage
          id="panel.providers.routing.templates.panel.sidebars.setPassword.slogan"
          defaultMessage="Your account miss you. Use this form to get your brand new password"
        />
      ),
    },
    sidebarPosition: "left",
  },
  {
    key: USER_DELETION,
    path: `/deleteAccount`,
    public: true,
    content: UserWantsToDeleteAccount,
    layout: Layout.Blank,
    displayInMenu: false,
  },

  {
    key: USER_DELETION_PAGE,
    path: `/user/deleteAccount`,
    layout: Layout.TwoColumns,
    content: DeleteUser,
    displayInMenu: false,
  },
  // {
  //   key: DETAILS,
  //   path: `/${COMPANY}/${DETAILS}`,
  //   layout: Layout.Panel,
  //   title: (
  //     <FormattedMessage
  //       id="panel.routing.companyDetails"
  //       defaultMessage="Company Details"
  //     />
  //   ),
  //   content: CompanyDetails,
  //   mainHasBackground: false,
  //   displayInMenu: false,
  // },
  // {
  //   key: INVOICES,
  //   path: "/invoices",
  //   layout: Layout.Panel,
  //   icon: <AwesomeLarge icon={faWallet} />,
  //   name: (
  //     <FormattedMessage id="panel.routing.invoices" defaultMessage="Invoices" />
  //   ),
  //   title: (
  //     <FormattedMessage id="panel.routing.invoices" defaultMessage="Invoices" />
  //   ),
  //   content: Invoices,
  //   mainHasBackground: false,
  //   fullHeight: true,
  // },
  {
    key: ASSISTANT,
    path: "/assistant",
    layout: Layout.Panel,
    icon: <OpenAiIcon />,
    name: (
      <FormattedMessage id="panel.routing.assistant" defaultMessage="Assistant" />
    ),
    title: (
      <FormattedMessage id="panel.routing.assistant" defaultMessage="Assistant" />
    ),
    content: AiAssistant,
    requiredPermission: true,
    mainHasBackground: false,
    fullHeight: true,
    displayInMenu: true,
    highlightStyle: {
      background: `linear-gradient(135deg, rgba(56,202,166,0.6) 0%, rgba(44,164,136,1) 70%)`
    }
  },
  {
    key: SUBSCRIPTION_DETAILS,
    path: `/subscriptions/:subscriptionId`,
    layout: Layout.Panel,
    content: SubscriptionDetailsPage,
    displayInMenu: false,
    mainHasBackground: false,
  },
  {
    key: SUBSCRIPTIONS,
    path: "/subscriptions/",
    layout: Layout.Panel,
    icon: <AwesomeLarge icon={faWallet} />,
    name: (
      <FormattedMessage id="panel.routing.subscriptions" defaultMessage="Subscriptions" />
    ),
    title: (
      <FormattedMessage id="panel.routing.Subscriptions" defaultMessage="Subscriptions" />
    ),
    content: subscriptionsPage,
    displayInMenu: false,
    mainHasBackground: true,
    fullHeight: true,
  },
  
  {
    key: SUBSCRIPTION_CANCELLATION,
    path: "/subscription-cancellation",
    layout: Layout.Edit,
    name: (
      <FormattedMessage id="panel.routing.subscriptions" defaultMessage="Subscriptions" />
    ),
    title: (
      <FormattedMessage id="panel.routing.Subscriptions" defaultMessage="Subscriptions" />
    ),
    content: SubscriptionCancellationWizard,
    displayInMenu: false,
  },
  {
    key: COMPANY_PROJECTS,
    path: `/${COMPANY}/${PROJECTS}`,
    layout: Layout.Panel,
    title: (
      <FormattedMessage id="panel.routing.projects" defaultMessage="Projects" />
    ),
    subtitle: (
      <FormattedMessage
        id="panel.routing.projectsSubtitle"
        defaultMessage="All your company projects in one place."
      />
    ),
    content: ProjectList,
    mainHasBackground: true,
    displayInMenu: false,
  },

  {
    key: INTEGRATIONS,
    path: "/integrations",
    icon: <AwesomeLarge icon={faArrowUpArrowDown} />,
    requiredPermission: true,
    name: (
      <FormattedMessage
        id="panel.routing.integrations.name"
        defaultMessage="Integrations"
      />
    ),
    layout: Layout.Panel,
    title: (
      <FormattedMessage
        id="panel.routing.integrations.title"
        defaultMessage="Integrations"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="panel.routing.integrations.subtitle"
        defaultMessage="Integrate your bot with external applications"
      />
    ),
    content: ToolIntegrations,
    fullHeight: true,
    mainHasBackground: false,
  },


  {
    key: TRACKING,
    path: "/tracking",
    icon: <AwesomeLarge icon={faGlobe} />,
    name: (
      <FormattedMessage
        id="panel.routing.tracking.name"
        defaultMessage="Tracking"
      />
    ),
    layout: Layout.Panel,
    title: (
      <FormattedMessage
        id="panel.routing.tracking.title"
        defaultMessage="Tracking"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="panel.routing.tracking.subtitle"
        defaultMessage="Manage your pixel and domain settings"
      />
    ),
    content: PixelAndDomain,
    requiredPermission: true,
    displayInMenu: true,
    fullHeight: true,
    mainHasBackground: false,
  },
  {
    key: TASKS,
    path: "/tasks",
    icon: <AwesomeLarge icon={faVial} />,
    name: (
      <FormattedMessage
        id="panel.routing.tasks.name"
        defaultMessage="Tasks"
      />
    ),
    layout: Layout.Panel,
    title: (
      <FormattedMessage
        id="panel.routing.tasks.title"
        defaultMessage="Tasks"
      />
    ),
    content: TasksList,
    displayInMenu: false,
  },
  {
    key: PROJECT_SETTINGS,
    path: `/projectSettings`,
    name: (
      <FormattedMessage
        id="panel.routing.projectSettings"
        defaultMessage="Project Settings"
      />
    ),
    title: (
      <FormattedMessage
        id="panel.routing.projectSettings"
        defaultMessage="Project settings"
      />
    ),
    icon: <AwesomeLarge icon={faCog} />,
    layout: Layout.Panel,
    content: ProjectSettings,
    displayInMenu: true,
    mainHasBackground: false,
  },
  {
    key: USER_SETTINGS,
    path: "/userSettings",
    name: (
      <FormattedMessage
        id="apps.smart.routing.account"
        defaultMessage="Account"
      />
    ),
    icon: <AwesomeLarge icon={faUser} />,
    layout: Layout.Panel,
    title: (
      <FormattedMessage
        id="apps.smart.routing.account"
        defaultMessage="Account"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.smart.routing.profileSubtitle"
        defaultMessage="This is where it all begins. Everything starts here, today."
      />
    ),
    content: UserAccountPage,
    fullHeight: true,
    mainHasBackground: false,
  },
  {
    key: COMPANY_SETTINGS,
    path: `/companySettings`,
    icon: <AwesomeLarge icon={faBriefcase} />,
    layout: Layout.Panel,
    name: (
      <FormattedMessage
        id="panel.routing.companySettings"
        defaultMessage="Company settings"
      />
    ),
    title: (
      <FormattedMessage
        id="panel.routing.companySettings"
        defaultMessage="Company settings"
      />
    ),
    content: CompanySettingsPage,
    subtitle: (
      <FormattedMessage
        id="panel.routing.settingsSubtitle"
        defaultMessage="Settings for your company."
      />
    ),
    displayInMenu: true,
    mainHasBackground: false,
  },

  {
    key: STATEMENT_TERMS_OF_SERVCIE,
    path: "/terms-of-service",
    layout: Layout.Panel,
    icon: <AwesomeLarge icon={faFileSignature} />,
    name: (
      <FormattedMessage
        id="panel.routing.termsOfService"
        defaultMessage="Terms of service"
      />
    ),
    title: (
      <FormattedMessage
        id="panel.routing.termsOfService"
        defaultMessage="Terms of service"
      />
    ),
    content: StatementPageTermsOfService, 
    displayInMenu: false,
  },

  {
    key: STATEMENT_MARKETING_CONSENTS,
    path: "/marketing-consents",
    layout: Layout.Panel,
    icon: <AwesomeLarge icon={faFileSignature} />,
    name: (
      <FormattedMessage
        id="panel.routing.marketingConsents"
        defaultMessage="Marketing Consents"
      />
    ),
    title: (
      <FormattedMessage
        id="panel.routing.marketingConsents"
        defaultMessage="Marketing Consents"
      />
    ),
    content: StatementPageMarketingConsents, 
    displayInMenu: false,
  },

  {
    key: PRODUCTS,
    path: "/products",
    layout: Layout.Edit,
    content: ProductsPage,
    displayInMenu: false,
  },

  // {
  //   key: COMPANY_SUBSCRIPTIONS,
  //   path: `/${COMPANY}/subscriptions`,
  //   layout: Layout.Edit,
  //   content: CompanySubscriptions,
  //   displayInMenu: false,
  // },
  {
    key: COMPANY_BUY_PRODUCT,
    path: `/company/products/buy/:product/:projectId?`,
    content: BuyNewSubscription,
    layout: Layout.Edit,
    displayInMenu: false,
  },
  // {
  //   ...CompanyBuyProductConfig,
  //   key: COMPANY_BUY_PRODUCT,
  //   layout: Layout.Edit,
  //   displayInMenu: false,
  // },
  {
    key: PLATFORM_INTEGRATIONS,
    path: "/platform-integrations",
    icon: <AwesomeLarge icon={faRobot} />,
    mainHasBackground: false,
    content: ManageIntegrations,
    displayInMenu: false,
    layout: Layout.Panel,
  },
  {
    key: ONBOARDING_PAYMENT,
    path: "/onboarding/payment",
    layout: Layout.TwoColumns,
    title: (
      <FormattedMessage
        id="panel.routing.companyPayments"
        defaultMessage="Company payments"
      />
    ),
    //content: PaymentOnboarding,
    content: OnboardingPayment,
    sidebar: <div id="payment-sidebar" className="payment-sidebar"></div>,
    //sidebar: <PaymentSidebar />,
    displayInMenu: false,
  },
  {
    key: ONBOARDING_PREPARE_PROJECT,
    path: "/onboarding/projects/prepare",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: PrepareProject
    //content: PrepareProject,
  },
  {
    key: ONBOARDING_UPDATE_PROJECT_STATUS,
    path: "/onboarding/projects/update-status",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingUpdateProjectStatus
    //content: UpdateProjectStatus,
  },
  {
    key: ONBOARDING_DYNAMIC_INTEGRATE_PLATFORMS,
    path: "/integrate/authorize-platforms",
    layout: Layout.TwoColumns,
    content: DynamicIntegratePlatforms,
    displayInMenu: false,
    title: (
      <FormattedMessage
        id="panel.containers.onboarding.integrations.platforms.title"
        defaultMessage="First step"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="panel.containers.onboarding.integrations.platforms.subtitle"
        defaultMessage="Please set up your first project."
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="panel.routing.integrate.platforms.sidebar.title"
          defaultMessage="Integrations setup"
        />
      ),
      description: (
        <FormattedMessage
          id="panel.routing.integrate.platforms.sidebar.description"
          defaultMessage="Choose witch integrations would you like to add to your first project"
        />
      ),
    },
  },
  {
    key: ONBOARDING_SET_FACEBOOK_INTEGRATION,
    path: "/integrate/facebook",
    layout: Layout.TwoColumns,
    content: OnboardingProviderIntegrationFacebook,
    displayInMenu: false,
    title: (
      <FormattedMessage
        id="panel.containers.onboarding.integrations.platforms.facebook.title"
        defaultMessage="One step closer"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="panel.containers.onboarding.integrations.platforms.facebook.subtitle"
        defaultMessage="Please set up your first project."
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="panel.routing.integrate.facebook.sidebar.title"
          defaultMessage="Facebook setup"
        />
      ),
      description: (
        <FormattedMessage
          id="panel.routing.integrate.facebook.sidebar.description"
          defaultMessage="Add your social pages and ads account to provide necessary access for the app purpose."
        />
      ),
    },
  },
  {
    key: ONBOARDING_SET_GOOGLE_INTEGRATION,
    path: "/integrate/google",
    layout: Layout.TwoColumns,
    content: OnboardingProviderIntegrationGoogle,
    //content: SetGoogleIntegration,
    displayInMenu: false,
    title: (
      <FormattedMessage
        id="panel.containers.onboarding.integrations.platforms.google.title"
        defaultMessage="One step closer"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="panel.containers.onboarding.integrations.platforms.google.subtitle"
        defaultMessage="Please set up your first project."
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="panel.routing.integrate.google.sidebar.title"
          defaultMessage="Google setup"
        />
      ),
      description: (
        <FormattedMessage
          id="panel.routing.integrate.google.sidebar.description"
          defaultMessage="Let us Google it for you! Connect or create your business accounts to create posts and advertisements."
        />
      ),
    },
  },
  {
    key: ONBOARDING_WE_WILL_CONTACT_U,
    path: "/onboarding/success",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: WeWillContactUOnboarding,
  },
  {
    key: ONBOARDING_AUTHORIZATION_SUCCESS,
    path: "/onboarding/auth-success",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: AuthorizationSuccessOnboarding,
  },
  {
    key: ONBOARDING_COMPANY_DATA,
    path: "/onboarding/company-data",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingCompanyInvoiceData
  },
  {
    key: ONBOARDING_VERIFY_NUMBER,
    path: "/onboarding/verify-number",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingVerifyPhone,
    //content: VerifyNumberOnboarding,
    title: (
      <FormattedMessage
        id="panel.containers.onboarding.verifyNumber.title"
        defaultMessage="What is your phone number?"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="panel.containers.onboarding.verifyNumber.subtitle"
        defaultMessage="We need to verify your phone number before we begin."
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.verifyNumber.sidebar.title"
          defaultMessage="Phone verifying"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.verifyNumber.sidebar.description"
          defaultMessage="I just realized there’s something wrong with my phone, it doesn’t have your number! "
        />
      ),
    },
  },
  {
    key: ONBOARDING_CONTACT_EMAIL,
    path: "/onboarding/contact-email",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingContactEmail,
    title: (
      <FormattedMessage
        id="panel.containers.onboarding.contactEmail.title"
        defaultMessage="What is your email address?"
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.contactEmail.sidebar.title"
          defaultMessage="Contact email"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.contactEmail.sidebar.description"
          defaultMessage="Please provide your email address."
        />
      ),
    }
  },
  {
    key: ONBOARDING_MARKETING_STATEMENT,
    path: "/onboarding/statements/marketing",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingMarketingConsentsStatement,
    //content: MarketingConsentsOnboardingStatement,
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.marketingStatement.sidebar.title"
          defaultMessage="Marketing Statement"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.marketingStatement.sidebar.description"
          defaultMessage="Please read carefully."
        />
      ),
    },
  },
  {
    key: ONBOARDING_TERMS_OF_SERVICES_STATEMENT,
    path: "/onboarding/statements/terms-of-services",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingTermsOfServiceStatement,
    //content: TermsOfServiceStatementStatement,
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.termsOfService.sidebar.title"
          defaultMessage="Terms of service"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.termsOfService.sidebar.description"
          defaultMessage="Please read carefully."
        />
      ),
    },
  },
  {
    key: TEST_PAGE,
    path: "/test",
    displayInMenu: false,
    layout: Layout.Panel,
    mainHasBackground: false,
    content: TestPage,
    icon: <AwesomeLarge icon={faVial} />,
  },
  {
    key: TESTS,
    path: "/tests",
    displayInMenu: false,
    layout: Layout.Panel,
    mainHasBackground: false,
    content: TestIndex,
    icon: <AwesomeLarge icon={faRobot} />,
  },
  {
    key: EXPERIMENTAL,
    path: "/experimental",
    displayInMenu: false,
    layout: Layout.Panel,
    mainHasBackground: false,
    content: InfinityPostList,
    icon: <AwesomeLarge icon={faVial} />
  },
  {
    key: PROMOTIONS,
    path: "/promotions",
    title: (
      <FormattedMessage
        id="panel.containers.promotions.title"
        defaultMessage="Promotions"
      />
    ),
    displayInMenu: true,
    layout: Layout.Panel,
    mainHasBackground: false,
    requiredPermission: true,
    content: PromoList,
    icon: <AwesomeLarge icon={faGraduationCap} />,
    // highlightStyle: {
    //   background: `linear-gradient(135deg, rgba(251,33,147,0.6) 0%, rgba(251,33,147,1) 70%)`
    // }
  },
  {
    key: EVENTS,
    path: "/events",
    title: (
      <FormattedMessage
        id="panel.containers.events.title"
        defaultMessage="Events"
      />
    ),
    displayInMenu: true,
    content: PromoCalendar,
    icon: <AwesomeLarge icon={faScreenUsers} />,
    mainHasBackground: false,
    layout: Layout.Panel,
    highlightStyle: {
      background: `linear-gradient(135deg, rgba(255,165,0,0.6) 0%, rgba(255,140,0,1) 70%)`
    }
  },
  {
    key: "AI_LEXICON",
    path: "/ai-lexicon",
    displayInMenu: false,
    layout: Layout.Panel,
    content: AILexiconTestPanel,
    icon: <AwesomeLarge icon={faRobot} />,
  },
  // {
  //   key: SUPER_PROMO,
  //   path: "/super-promo",
  //   displayInMenu: false,
  //   layout: Layout.Panel,
  //   mainHasBackground: false,
  //   content: SuperPromoPage,
  //   icon: <AwesomeLarge icon={faBadgePercent} />,
  //   highlightStyle: {
  //     background: `linear-gradient(135deg, rgba(251,33,147,0.6) 0%, rgba(251,33,147,1) 70%)`
  //   }
  // }
];

export default routes;

export const getUrl = (routeKey: string, options?: any) =>
  Helper.getPath(APP_PATH, routes, routeKey, "", options);
