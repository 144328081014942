import React, { useMemo, ReactNode } from "react";
import { getProviderFieldConfigByName, mappedProvidersFields } from "modules/panel/config/ProviderConfig";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { ProviderFieldIcon } from "modules/panel/config/ProviderHelpers";
import StandardActorCard from "components/common/StandardActorCard";
import { projectsSelector } from "state/projects/projectsSelectors";

type Props = {
  projectId: string;
};

const ProjectIntegrationDisplay = ({ projectId }: Props) => {

  const projects = useSelectorWithParams(projectsSelector)
  const project = projects?.[projectId]

  const content = useMemo(() => {

    const ele: ReactNode[] = [];
    for (const [provider, details] of mappedProvidersFields.entries()) {

      if(!project) return null
      const config = getProviderFieldConfigByName(provider)
      const storedObject = config.stateObjectFieldName
      
      if (!project || !project[storedObject]) continue;

      const actorData = config.getActorCardData(project[storedObject])

      if (details.showInProjectIndicators === true) {
        ele.push(
          <StandardActorCard
          size="small"
          actor={{ name: actorData.name, subtitle: actorData.subtitle, image: <ProviderFieldIcon field={provider} />}}
        />
        );
      }
  }
    return ele;
  }, [projectId]);

  return content;
};

export default ProjectIntegrationDisplay;
