import { Requirement } from "modules";
import {
  getUrl as getWebsitesUrl,
  ONBOARDING_BRANDS,
  ONBOARDING_CATEGORIES,
  ONBOARDING_MANUFACTURERS,
  ONBOARDING_TIRES_MANUFACTURERS,
} from "modules/site-generator/config/routes";
import { userHasFilledBrandsSelector, userHasFilledCarserviceCategoriesSelector, userHasFilledManufacturersSelector } from "modules/site-generator/reducers/websiteSlice";

const requirementsCarservice: Requirement[] = [
  {
    // siteGenerator have categories
    key: "userHasFilledCategoriesSelector",
    path: () => getWebsitesUrl(ONBOARDING_CATEGORIES),
    selector: userHasFilledCarserviceCategoriesSelector,
    // component: <CategoriesOnboarding/>
    // !!categories.size
  },
  {
    // siteGenerator have brands
    key: "userHasFilledBrandsSelector",
    path: () => getWebsitesUrl(ONBOARDING_BRANDS),
    selector: userHasFilledBrandsSelector,
    // component: <BrandsOnboarding/>
    // !!brands.size
  },
  {
    // siteGenerator have manufacturers
    key: "userHasFilledManufacturersSelector",
    path: [
      () => getWebsitesUrl(ONBOARDING_MANUFACTURERS),
      () => getWebsitesUrl(ONBOARDING_TIRES_MANUFACTURERS),
    ],
    selector: userHasFilledManufacturersSelector,
    // components: <ManufacturersOnboarding/>, <TiresManufacturersOnboarding/>
    // !!manufacturers.size
  },
];

export const disallowedRoutesCarservice: string[] = [
  "rooms",
  "objectRules",
  "objectCategories",
  "objects",
];

export default requirementsCarservice;
