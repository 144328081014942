import { Tooltip } from "antd";
import { ReactNode, useMemo } from "react";
import {CenterdCell} from "../CommonCells";

type CellWithMultipleValuesProps = {
    onClick?: (e?: any) => void;
    rows: [ReactNode, ReactNode][],
    layout?: "vertical" | "horizontal"
}

const firstColumnStyle = {
    fontSize: "10px",
    display: "grid",
    placeItems: "center"
}


const secondColumnStyle = {
    display: "grid",
    placeItems: "center"
}

export default function CellWithMultipleValues(props: CellWithMultipleValuesProps) {

    const rows = useMemo(() => {
        return props.rows.map((row, index) => {

            if (props.layout === "vertical") {
                return (
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }} key={index}>
                        <div style={firstColumnStyle}>{row[0]}</div>
                        <div style={secondColumnStyle}>{row[1]}</div>
                    </div>
                )
            }

            return (
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }} key={index}>
                    <div style={firstColumnStyle}>{row[0]}</div>
                    <div style={secondColumnStyle}>{row[1]}</div>
                </div>
            )
        })
        
    }, [props.rows])

    return (
            <CenterdCell 
            onClick={(e: any) => props.onClick && props.onClick()}
            style={{padding: "5px"}}
            >
                {props.layout === "vertical" ? 
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%"}}>{rows}</div> :
                rows
                }
            </CenterdCell>
    )

}