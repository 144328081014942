import FormattedMessage from "components/common/FormattedMessage"
import StandardAvatar from "components/common/Avatar/StandardAvatar"
import { InfinityColumnProps, columnSize } from "tools/infinityList/infinityTemplates"
import CreationTypeDisplay from "./displays/CreationTypeDisplay"
import CreationStatusDisplay from "./displays/CreationStatusDisplay"
import { useCreationGlobal } from "./CreationGlobalContext"
import { Space } from "antd"
import StandardButton from "components/common/StandardButton"
import { trimString } from "services/helpers/js-helpers"
import DateTimeDisplay from "components/displays/DateTimeDisplay"
import dayjs from "dayjs"
import { CreationStatusString } from "./CreationConfig"
import { AskToDeleteCreation } from "./CreationActionModals"
import { CreationActionDelete } from "./editorTools/CreationMainActions"
import { Creation } from "./creationsSlice"

const PublishAtDisplay = (props: any) => {

    if (!props.creation) return null;
    if (!props.creation.publish_at) return null;

    const date = dayjs.utc(props.creation.publish_at)

    if (!date) return null;

    return (
        <div>
            <div>
                {CreationStatusString(props.creation.status)}
            </div>
            <div>
                {date.local().format("DD.MM.YY HH:mm")}
            </div>
        </div>
    )


}


export const CreationHeaders: InfinityColumnProps[] = [
    {
        key: "image",
        size: columnSize.size50,
        render: () => <FormattedMessage
            id="tools.creationsList.headers.image"
            defaultMessage="Image" />,
    },
    {
        key: "type",
        size: columnSize.size90,
        render: () => <FormattedMessage
            id="tools.creationsList.headers.type"
            defaultMessage="Type" />
    },
    {
        key: "status",
        size: columnSize.size90,
        render: () => <FormattedMessage
            id="tools.creationsList.headers.status"
            defaultMessage="Status" />
    },
    {
        key: "title",
        size: columnSize.interactions,
        render: () => <FormattedMessage
            id="tools.creationsList.headers.title"
            defaultMessage="Title" />
    },
    {
        key: "message",
        size: columnSize.description,
        render: () => <FormattedMessage
            id="tools.creationsList.headers.message"
            defaultMessage="Message" />
    },
    {
        key: "createdAt",
        size: columnSize.size60,
        render: () => <FormattedMessage
            id="tools.creationsList.headers.createdAt"
            defaultMessage="Created" />
    },
    {
        key: "updatedAt",
        size: columnSize.size60,
        render: () => <FormattedMessage
            id="tools.creationsList.headers.updatedAt"
            defaultMessage="Updated" />
    },
    {
        key: "publishAt",
        size: columnSize.size70,
        render: () => <FormattedMessage
            id="tools.creationsList.headers.publishAt"
            defaultMessage="Publish at" />
    },
    {
        key: "tools",
        size: columnSize.tools,
        render: () => null
    }

]


const EditCreationButton = ({ creation }: { creation: Creation }) => {

    const { openCreation } = useCreationGlobal();

    if (creation.status === "published") return null;

    return (
        <Space direction="horizontal">
            <StandardButton onClick={() => openCreation(creation.id)}>
                <FormattedMessage
                    id="common.edit"
                    defaultMessage="Edit" />
            </StandardButton>
        </Space>
    )

}

export const CreationColumns = [
    {
        key: "image",
        size: columnSize.image,
        render: (creation: Creation) => <StandardAvatar image={creation?.content?.media?.[0]} size={"medium"} />
    },
    {
        key: "type",
        size: columnSize.size90,
        render: (creation: Creation) => <CreationTypeDisplay type={creation.type} subtype={creation?.content?.type} />
    },
    {
        key: "status",
        size: columnSize.size90,
        render: (creation: Creation) => <CreationStatusDisplay status={creation.status} />
    },
    {
        key: "title",
        size: columnSize.interactions,
        render: (creation: Creation) => {
            if (creation?.content?.name) return creation?.content?.name;
            if (creation?.content?.title) return creation?.content?.title;
            if (creation?.content?.titles) {
                if (Array.isArray(creation?.content?.titles) && creation?.content?.titles[0]) return creation?.content?.titles[0];
            }
            return ""
        }
    },
    {
        key: "message",
        size: columnSize.description,
        render: (creation: Creation) => {
            if (creation.content.bodies && creation.content.bodies.length) return trimString(creation.content.bodies[0], 20, 30)
            if (creation?.content?.message) return trimString(creation?.content?.message, 20, 30)
        }
    },
    {
        key: "createdAt",
        size: columnSize.size60,
        render: (creation: Creation) => <DateTimeDisplay showDate showTime showIcons dateTime={creation.created_at} />
    },
    {
        key: "updatedAt",
        size: columnSize.size60,
        render: (creation: Creation) => <DateTimeDisplay showDate showTime showIcons dateTime={creation.updated_at} />
    },
    {
        key: "publishAt",
        size: columnSize.size70,
        render: (creation: Creation) => <PublishAtDisplay creation={creation} />
    },
    {
        key: "tools",
        size: columnSize.tools,
        render: (creation: Creation) => <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", width: "100%"}}>
            <EditCreationButton creation={creation} />
            <CreationActionDelete creation={creation} />
        </div>
    }
]