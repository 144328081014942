import { Tooltip } from "antd";
import AwesomeIcon from "components/common/AwesomeIcon";
import { CreationStatusIcon, CreationStatusString } from "../CreationConfig";
import { SmallText } from "components/common/StandardText";

export default function CreationStatusDisplay(props: any) {

    if (!props.status) return null;
    return (
        <Tooltip
            title={CreationStatusString(props.status)}
        >

            <div style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "5px 0px"
            }}>
                <AwesomeIcon icon={CreationStatusIcon(props.status)} size="2xl" />
                <SmallText>{CreationStatusString(props.status)}</SmallText>
            </div>
        </Tooltip>
    )
}