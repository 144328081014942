import { IconDefinition, faCamera } from "@fortawesome/pro-light-svg-icons";
import { Avatar } from "antd";
import { ReactNode, useEffect, useMemo } from "react";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { GalleryItem } from "tools/gallery/Gallery";
import GalleryItemDisplay from "tools/gallery/GalleryItemDisplay";
import AwesomeIcon from "../AwesomeIcon";
import "./standard-avatar.less";
import { currentProjectSelector } from "state/projects/projectSelectors";

type AvatarProps = {
    image: string | ReactNode | Partial<GalleryItem> | IconDefinition; 
    size?: "small" | "medium" | "large" | undefined | null;
    overlay?: ReactNode;
    onClick?: () => void;
}


export default function StandardAvatar(props:AvatarProps) {

    const img = useMemo(() => {
        if (!props.image) return null;
        if (typeof props.image === "string") return props.image;
        if (typeof props.image === "object" && props.image.url) {
            return props.image.url;
        }
        if(typeof props.image === "object"){
            return props.image
        }
    }, [props])

    if (props.image === null) return null;

    const hasImage = !!img;

    const className = `standard-avatar ${props.size ? props.size : "medium"} ${hasImage ? "has-image" : "no-image"}`;

    const A = img ? (
        <Avatar 
        className={className}
        size={props.size}
        shape="square" 
        src={img} 
        />
    ) : (
        <Avatar 
            className={className}
            size={props.size}
            shape="square" 
            src={<AwesomeIcon icon={props.image ? props.image : faCamera} color="black" size="2xl"/>} />
    )

    return ( 
        <div 
        className={`standard-avatar-wrapper ${props.overlay ? "with-overlay" : "overlay"} ${props.onClick ? "clickable" : ""}`}
        onClick={props.onClick}
        >
            {A}
            <div className="standard-avatar-overlay">
                {props.overlay}
            </div>
        </div>
    )
    

}

export const ProjectAvatar = () => {

    const project = useSelectorWithParams(currentProjectSelector);

    if (!project) return null;

    return (
        <StandardAvatar image={project.image}  />
    )
}