import { Alert, Button, Input, Space, Typography, notification } from "antd";
import StandardButton from "components/common/StandardButton";
import { useEffect, useState } from "react";
import useAPI, { APIErrorDisplay, callAPIProps } from "services/hooks/useAPI"
import FormattedMessage from "components/common/FormattedMessage";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { usePurchaseSubscription } from "./PurchaseSubscription";


export default function PaymentCoupon(props: {
    onClick: (page: string) => void;
    className?: string;
}) {

    const { promoCodeCheck, promoCode } = usePurchaseSubscription();
    const {checkPromoCode, loading, error, clear} = promoCodeCheck;
    const [code, setCode] = useState(promoCode || "");

    const handleUseCoupon = async (c?:string) => {

        if (!code && !c) return;
        const checkResult = await checkPromoCode(code || c || "");

        if (checkResult) props.onClick && props.onClick("summary")

    }

    useEffect(() => {
        setCode(promoCode || "");
    }, [promoCode])

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px"
        }}
            className={props.className}
        >
            <Space
                direction="vertical"
            >
                <Typography.Title
                    level={3}>
                    <FormattedMessage
                        id="panel.components.containers.coupons.title"
                        defaultMessage="Discount coupon"
                    />
                </Typography.Title>
                <Typography.Text>
                    <FormattedMessage
                        id="panel.components.containers.coupons.description"
                        defaultMessage="If you have a coupon, enter it here."
                    />
                </Typography.Text>
                <Input
                    onChange={(e) => {
                        setCode(e.target.value);
                        clear();
                    }}
                    value={code}
                    disabled={loading}
                    status={error ? "error" : undefined}
                />
                <div style={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: "space-between"
                }}>
                <StandardButton
                    type="text"
                    onClick={() => props.onClick && props.onClick("summary")}
                >
                    <FormattedMessage
                        id="common.back"
                        defaultMessage="Back"
                    />
                </StandardButton>
                {/* <StandardButton 
                    onClick={() => {
                        setCode("");
                        clear()
                    }} 
                    icon={faTrash}
                    /> */}
                <StandardButton
                    onClick={() => handleUseCoupon()}
                    loading={loading}
                    disabled={!code}
                >
                    <FormattedMessage
                        id="panel.components.containers.coupons.use"
                        defaultMessage="Use coupon"
                    />
                </StandardButton>
                </div>
                {error && (
                    <Alert
                        type="error"
                        showIcon
                        message={<FormattedMessage
                            id="components.coupons.error.message"
                            defaultMessage="Invalid Code"
                        />}
                        description={<APIErrorDisplay {...error} />}
                    />
                )}
            </Space>
        </div>
    )

}