import { combineReducers, createSlice } from "@reduxjs/toolkit";

export type projectPixelSliceState = any;

const initialState: projectPixelSliceState = {
    "pixel": null,
};

export const projectPixelSlice = createSlice({
    name: "pixel",
    initialState: initialState,
    reducers: {
        get: (state, action) => {
            console.log("ProjectPixelSlice", action);
        }
    },
})