import { Col, InputNumber } from "antd";
import BudgetInputFacebook from "components/inputs/BudgetInputFacebook";
import EstimateDisplay from "components/keywords/estimateDisplay";
import KeywordsFacebook from "components/keywords/KeywordsFacebook";
import { StandardRow } from "components/layout/StandardGrid";
import GeoPointsFacebook from "components/map/GeoPointsFacebook";
import FacebookLocaleLanguageSelect from "modules/smart/components/forms/FacebookLocaleLanguageSelected";
import { useIntl } from "react-intl";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";
import { ruleMax, ruleMin } from "tools/relaxForm/relaxPresets";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import PeriodInput from "_components/forms/period-input";
import FormattedMessage from "components/common/FormattedMessage";
import { GenderInput } from "_components/forms/GenderInputs";
import DisplayIfHasPermission from "components/common/DisplayIfHasPermission";
import { FacebookAdsSettingsSelector, smartSlice } from "modules/smart/smartSlice";

const FacebookInterests = () => {

    return (
        <>
            <EstimateDisplay />

            <RelaxSingleField
                name="audience_interest"
                hideStatus={true}
                initialValuesSelector={FacebookAdsSettingsSelector}
                callAPI={{
                    url: ({ getApiUrl, projectId, smart }) => getApiUrl(`projects/${projectId}/smarts/${smart}/facebook-ads-settings`, 2),
                    method: "PATCH",
                    successDispatch: smartSlice.actions.updateFacebookAdsSettings,
                    adjustResponse: (nd, rd, normalizer) => {
                        //API BUG: Estimates are included as [0] in response, not in "included"
                        return { ...nd, _estimate: rd?.[0]?.[0] };
                    }
                }}
                finalizeSubmit={(values) => {
                    if (values.location_targeting) {
                        values.location_targeting.country_code = "PL"
                    }
                    return values;
                }}
                submitOnChange
            >
                <KeywordsFacebook platform="facebook" />
            </RelaxSingleField>
        </>
    )
}



export default function SettingsFacebookAds() {

    const { formatMessage, formatNumber } = useIntl();

    const setValueIfEmpty =
        (setValue: number) => (value: string | number | undefined) =>
            value ? value.toString() : setValue.toString();

    const maxAgeFormatter = (value: string | undefined) => {
        if (value === "65") {
            return "65+";
        }
        return value || "";
    }

    return (
        <>
            <div style={{ height: "20px" }}></div>
            <RelaxMultiform
                name="facebookAdsSettings"
                initialValuesSelector={FacebookAdsSettingsSelector}
                callAPI={{
                    url: ({ getApiUrl, projectId, smart }) => getApiUrl(`projects/${projectId}/smarts/${smart}/facebook-ads-settings`, 2),
                    method: "PATCH",
                    successDispatch: smartSlice.actions.updateFacebookAdsSettings,
                    adjustResponse: (nd, rd, normalizer) => {
                        //API BUG: Estimates are included as [0] in response, not in "included"
                        return { ...nd, _estimate: rd?.[0]?.[0] };
                    }
                }}
                ignoreChangesToInitialValues={true}
                finalizeSubmit={(values) => {
                    if (values.location_targeting) {
                        values.location_targeting.country_code = "PL"
                    }
                    return values;
                }}
            >
                <StandardRow>
                    <Col span={24}>
                        <GeoPointsFacebook />
                    </Col>
                </StandardRow>
                <div style={{ height: "25px" }}></div>

                <StandardRow>

                    <Col xs={24} sm={24} md={12} lg={6}>
                        <RelaxSingleField
                            name="gender"
                            initialValue={["male", "female"]}
                            label={<FormattedMessage
                                id="apps.smart.components.adsSettings.genderLabel.label"
                                defaultMessage="Gender"
                            />}
                            tooltip={
                                <FormattedMessage
                                    id="apps.smart.components.adsSettings.genderLabel.tooltip"
                                    defaultMessage="Gender"
                                />
                            }
                        >
                            <GenderInput />
                        </RelaxSingleField>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={6}>
                        <RelaxSingleField
                            name="age_from"
                            label={<FormattedMessage
                                id="components.forms.inputs.ageRange.From.label"
                                defaultMessage="Age from"
                            />}
                            tooltip={
                                <FormattedMessage
                                    id="components.forms.inputs.ageRange.From.tooltip"
                                    defaultMessage="Age from"
                                />
                            }
                            rules={[ruleMin(18), ruleMax(65)]}
                        >
                            <InputNumber
                                formatter={setValueIfEmpty(18)}
                                className="fullwidth from"
                                min={18}
                            />
                        </RelaxSingleField>
                    </Col>


                    <Col xs={24} sm={24} md={12} lg={6}>
                        <RelaxSingleField
                            name="age_to"
                            initialValue={65}
                            label={<FormattedMessage
                                id="components.forms.inputs.ageRange.To.label"
                                defaultMessage="Age to"
                            />}
                            tooltip={
                                <FormattedMessage
                                    id="components.forms.inputs.ageRange.To.tooltip"
                                    defaultMessage="Age to"
                                />
                            }
                            rules={[ruleMin(18), ruleMax(65)]}
                        >
                            <InputNumber
                                formatter={maxAgeFormatter}
                                className="fullwidth from"
                                max={65}
                            />
                        </RelaxSingleField>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={6}>
                        <RelaxSingleField
                            name="locales"
                            initialValue={"Polish"}
                            label={<FormattedMessage
                                id="apps.smart.components.adsSettings.locales.label"
                                defaultMessage="Languages"
                            />}
                            tooltip={
                                <FormattedMessage
                                    id="apps.smart.components.adsSettings.locales.tooltip"
                                    defaultMessage="Languages"
                                />
                            }
                        // inputStyle={{width: "300px"}}
                        >
                            <FacebookLocaleLanguageSelect />
                        </RelaxSingleField>
                    </Col>


                </StandardRow>

                <div style={{ height: "25px" }}></div>
                <DisplayIfHasPermission permission="use module smart facebook settings budget">
                    <StandardRow>
                        <Col xs={24} sm={12} md={6}>

                            <h3>
                                <FormattedMessage
                                    id="apps.smart.forms.budget.facebook.standardPost"
                                    defaultMessage="Standard Post"
                                />
                            </h3>
                            <RelaxSingleField
                                name="standard_daily_advertising_budget"
                                initialValue={15}
                                label={<FormattedMessage
                                    id="apps.smart.components.adsSettings.dailyBudgetLabel.label"
                                    defaultMessage="Your daily advertising budget"
                                />}
                                tooltip={
                                    <FormattedMessage
                                        id="apps.smart.components.adsSettings.dailyBudgetLabel.tooltip"
                                        defaultMessage="Your daily advertising budget"
                                    />
                                }
                            >
                                <BudgetInputFacebook
                                    className="fullwidth"
                                    defaultBudget={(minBudget: number) => minBudget * 2}
                                />
                            </RelaxSingleField>
                            <RelaxSingleField
                                name="standard_period"
                                label={<FormattedMessage
                                    id="apps.smart.components.adsSettings.promotionPeriod.label"
                                    defaultMessage="Period of time to promote post"
                                />}
                                tooltip={
                                    <FormattedMessage
                                        id="apps.smart.components.adsSettings.promotionPeriod.tooltip"
                                        defaultMessage="Period of time to promote post"
                                    />
                                }
                                initialValue={7}
                            >
                                <PeriodInput
                                    className="fullwidth"
                                    formatMessage={formatMessage}
                                />
                            </RelaxSingleField>
                        </Col>


                        <Col xs={24} sm={12} md={6}>

                            <h3>
                                <FormattedMessage
                                    id="apps.smart.forms.budget.facebook.linkPost"
                                    defaultMessage="Link Post"
                                />
                            </h3>
                            <RelaxSingleField
                                name="link_daily_advertising_budget"
                                label={<FormattedMessage
                                    id="apps.smart.components.adsSettings.dailyBudgetLabel.label"
                                    defaultMessage="Your daily advertising budget"
                                />}
                                tooltip={
                                    <FormattedMessage
                                        id="apps.smart.components.adsSettings.dailyBudgetLabel.tooltip"
                                        defaultMessage="Your daily advertising budget"
                                    />
                                }
                                initialValue={10}
                            >
                                <BudgetInputFacebook
                                    className="fullwidth"
                                    defaultBudget={(minBudget: number) => minBudget * 2}
                                />
                            </RelaxSingleField>
                            <RelaxSingleField
                                name="link_period"
                                label={<FormattedMessage
                                    id="apps.smart.components.adsSettings.promotionPeriod.label"
                                    defaultMessage="Period of time to promote post"
                                />}
                                tooltip={
                                    <FormattedMessage
                                        id="apps.smart.components.adsSettings.promotionPeriod.tooltip"
                                        defaultMessage="Period of time to promote post"
                                    />
                                }
                                initialValue={1}
                            >
                                <PeriodInput
                                    className="fullwidth"
                                    formatMessage={formatMessage}
                                />
                            </RelaxSingleField>
                        </Col>


                        <Col xs={24} sm={12} md={6}>

                            <h3>
                                <FormattedMessage
                                    id="apps.smart.forms.budget.facebook.videoPost"
                                    defaultMessage="Video Post"
                                />
                            </h3>

                            <RelaxSingleField
                                name="video_daily_advertising_budget"
                                label={<FormattedMessage
                                    id="apps.smart.components.adsSettings.dailyBudgetLabel.label"
                                    defaultMessage="Your daily advertising budget"
                                />}
                                initialValue={10}
                                tooltip={
                                    <FormattedMessage
                                        id="apps.smart.components.adsSettings.dailyBudgetLabel.tooltip"
                                        defaultMessage="Your daily advertising budget"
                                    />
                                }
                            >
                                <BudgetInputFacebook
                                    className="fullwidth"
                                    defaultBudget={(minBudget: number) => minBudget * 2}
                                />
                            </RelaxSingleField>
                            <RelaxSingleField
                                name="video_period"
                                label={<FormattedMessage
                                    id="apps.smart.components.adsSettings.promotionPeriod.label"
                                    defaultMessage="Period of time to promote post"
                                />}
                                tooltip={
                                    <FormattedMessage
                                        id="apps.smart.components.adsSettings.promotionPeriod.tooltip"
                                        defaultMessage="Period of time to promote post"
                                    />
                                }
                                initialValue={10}
                            >
                                <PeriodInput
                                    className="fullwidth"
                                    formatMessage={formatMessage}
                                />
                            </RelaxSingleField>
                        </Col>


                        <Col xs={24} sm={12} md={6}>

                            <h3>
                                <FormattedMessage
                                    id="apps.smart.forms.budget.facebook.messagePost"
                                    defaultMessage="Message Post"
                                />
                            </h3>
                            <RelaxSingleField
                                name="message_daily_advertising_budget"
                                label={<FormattedMessage
                                    id="apps.smart.components.adsSettings.dailyBudgetLabel.label"
                                    defaultMessage="Your daily advertising budget"
                                />}
                                tooltip={
                                    <FormattedMessage
                                        id="apps.smart.components.adsSettings.dailyBudgetLabel.tooltip"
                                        defaultMessage="Your daily advertising budget"
                                    />
                                }
                                initialValue={10}
                            >
                                <BudgetInputFacebook
                                    className="fullwidth"
                                    defaultBudget={(minBudget: number) => minBudget * 2}
                                />
                            </RelaxSingleField>
                            <RelaxSingleField
                                name="message_period"
                                label={<FormattedMessage
                                    id="apps.smart.components.adsSettings.promotionPeriod.label"
                                    defaultMessage="Period of time to promote post"
                                />}
                                tooltip={
                                    <FormattedMessage
                                        id="apps.smart.components.adsSettings.promotionPeriod.tooltip"
                                        defaultMessage="Period of time to promote post"
                                    />
                                }
                                initialValue={1}
                            >
                                <PeriodInput
                                    className="fullwidth"
                                    formatMessage={formatMessage}
                                />
                            </RelaxSingleField>
                        </Col>


                    </StandardRow>
                </DisplayIfHasPermission>

            </RelaxMultiform>
            <div style={{ height: "25px" }}></div>


            <StandardRow>
                <Col span={24}>
                    <FacebookInterests />
                </Col>
            </StandardRow>
            <div style={{ height: "100px" }}></div>
        </>
    )

}