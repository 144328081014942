import { ProductConfig } from "products";
import logo from "./logoICall.svg";


const iCallConfig: ProductConfig = {
  name: "iCall",
  logo: logo,
  requirements: [],
};

export default iCallConfig;
