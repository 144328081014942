import useAPI from "services/hooks/useAPI";
import FormattedMessage from "components/common/FormattedMessage";
import { getApiUrl } from "services/helpers/api-helpers";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import Tabs from "_components/tabs/tabs";
import { useMemo } from "react";
import DateTimeInput from "components/inputs/DateTimeInput";
import dayjs from 'dayjs';
import Switch from "_components/forms/switch";
import { Input, Slider } from "antd";
import DatePicker from "components/inputs/DatePicker";
import { GetCallApp, ProjectCallAppSelector, callAppSlice } from "state/callAppSlice";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";

const labels = {
    "active": <FormattedMessage
        id="modules.smartcall.containers.config.active"
        defaultMessage="Active"
    />,
    "message_content": <FormattedMessage
        id="modules.smartcall.containers.config.messageContent"
        defaultMessage="Message"
    />,
    "discount": <FormattedMessage
        id="modules.smartcall.containers.config.discount"
        defaultMessage="Discount"
    />,
    "discount_percentage": <FormattedMessage
        id="modules.smartcall.containers.config.discountPercentage"
        defaultMessage="Discount Percentage"
    />,
}

export default function CallConfiguration() {


    const panes = [
        {
            title: <FormattedMessage
                id="modules.smartcall.containers.config.massCampaign"
                defaultMessage="Mass Campaign "
            />,
            key: "massCampaign",
            content: (
                <div>
                    Mass Campaign
                    <RelaxSingleField
                        name={["configurations", "mass", "active"]}
                        label={labels["active"]}
                    >
                        <Switch />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "mass", "message_content"]}
                        label={labels["message_content"]}
                    >
                        <Input />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "mass", "start_date"]}
                        label={<FormattedMessage
                            id="modules.smartcall.containers.config.mass.calledWithin"
                            defaultMessage="Mass campaign start date"
                        />}
                    //initialValue={{configuration: {mass: {start_date: dayjs()}}}}
                    >
                        <DateTimeInput
                            disabledDate={true}
                            allowedPeriodStart={dayjs()}
                            allowedPeriodEnd={dayjs().add(100, "day")}
                        />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "mass", "discount"]}
                        label={labels["discount"]}
                    >
                        <Switch />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "mass", "discount_percentage"]}
                        label={labels["discount_percentage"]}
                    >
                        <Slider />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "mass", "sms_per_hour"]}
                        label="SMS per hour"
                    >
                        <Slider />
                    </RelaxSingleField>
                </div>
            )
        },
        {
            title: <FormattedMessage
                id="modules.smartcall.containers.config.messageWithConsent"
                defaultMessage="Message with Consent"
            />,
            key: "messageWithConcent",
            content: (
                <>
                    Message with Consent
                    {/* <RelaxSingleField
                        name={["configurations", "consent", "active"]}
                        label={labels["active"]}
                        preset="switch"
                    /> */}
                    <RelaxSingleField
                        name={["configurations", "consent", "facebook_targeting"]}
                        label="Facebook Targeting"
                        initialValues={{ configurations: { consent: { facebook_targeting: true } } }}
                    >
                        <Switch />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "consent", "marketing_messages"]}
                        label="Marketing Messages"
                        initialValues={{ configurations: { consent: { marketing_messages: true } } }}
                    >
                        <Switch />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "consent", "consent_content"]}
                        label="Content"
                        submitOnChange={false}
                    >
                        <Input />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "consent", "message_content"]}
                        label="Message"
                        submitOnChange={false}
                    >
                        <Input />
                    </RelaxSingleField>
                </>
            )
        },
        {
            title: <FormattedMessage
                id="modules.smartcall.containers.config.welcomeMessage"
                defaultMessage="Welcome Message"
            />,
            key: "welcomeMessage",
            content: (
                <>
                    Welcome Message
                    <RelaxSingleField
                        name={["configurations", "welcome", "active"]}
                        label={labels["active"]}
                    >
                        <Switch />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "welcome", "message_content"]}
                        label={labels["message_content"]}
                        submitOnChange={false}
                    >
                        <Input />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "welcome", "discount"]}
                        label={labels["discount"]}
                    >
                        <Switch />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "welcome", "discount_percentage"]}
                        label={labels["discount_percentage"]}
                    >
                        <Slider />
                    </RelaxSingleField>
                </>
            )
        },
        {
            title: <FormattedMessage
                id="modules.smartcall.containers.config.thanks"
                defaultMessage="Thanks"
            />,
            key: "thanks",
            content: (<>
                <RelaxSingleField
                    name={["configurations", "thanks", "active"]}
                    label={labels["active"]}
                >
                    <Switch />
                </RelaxSingleField>
                <RelaxSingleField
                    name={["configurations", "thanks", "message_content"]}
                    label={labels["message_content"]}
                    submitOnChange={false}
                >
                    <Input />
                </RelaxSingleField>
            </>)
        },
        {
            title: <FormattedMessage
                id="modules.smartcall.containers.config.reminder"
                defaultMessage="Reminder"
            />,
            key: "reminder",
            content: (
                <>
                    <RelaxSingleField
                        name={["configurations", "reminder", "active"]}
                        label={labels["active"]}
                    >
                        <Switch />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "reminder", "message_content"]}
                        label={labels["message_content"]}
                        submitOnChange={false}
                    >
                        <Input />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "reminder", "called_within"]}
                        label={<FormattedMessage
                            id="modules.smartcall.containers.config.reminder.calledWithin"
                            defaultMessage="Not called within"
                        />}
                    >
                        <DatePicker />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "reminder", "discount"]}
                        label={labels["discount"]}
                    >
                        <Switch />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "reminder", "discount_percentage"]}
                        label={labels["discount_percentage"]}
                    >
                        <Slider />
                    </RelaxSingleField>
                </>
            )
        },

        {
            title: <FormattedMessage
                id="modules.smartcall.containers.config.bestClient"
                defaultMessage="Best Client"
            />,
            key: "bestClient",
            content: (<>
                <RelaxSingleField
                    name={["configurations", "best_client", "active"]}
                    label={labels["active"]}
                >
                    <Switch />
                </RelaxSingleField>
                <RelaxSingleField
                    name={["configurations", "best_client", "message_content"]}
                    label={labels["message_content"]}
                    submitOnChange={false}
                >
                    <Input />
                </RelaxSingleField>
                <RelaxSingleField
                    name={["configurations", "best_client", "send_outgoing_calls"]}
                    label={"Send outgoing calls"}
                >
                    <Switch />
                </RelaxSingleField>
                <RelaxSingleField
                    name={["configurations", "best_client", "discount"]}
                    label={labels["discount"]}
                >
                    <Switch />
                </RelaxSingleField>
                <RelaxSingleField
                    name={["configurations", "best_client", "discount_percentage"]}
                    label={labels["discount_percentage"]}
                >
                    <Switch />
                </RelaxSingleField>
                <RelaxSingleField
                    name={["configurations", "best_client", "called_within"]}
                    label={"Called within"}
                    submitOnChange={false}
                >
                    <Input />
                </RelaxSingleField>
                <RelaxSingleField
                    name={["configurations", "best_client", "called_min"]}
                    label={"Called min"}
                    submitOnChange={false}
                >
                    <Input />
                </RelaxSingleField>
            </>)
        },
        {
            title: <FormattedMessage
                id="modules.smartcall.containers.config.automaticPresence"
                defaultMessage="Automatic Presence"
            />,
            key: "automaticPresence",
            content: (
                <>
                    <RelaxSingleField
                        name={["configurations", "auto_presence", "active"]}
                        label={labels["active"]}
                    >
                        <Switch />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "auto_presence", "message_content"]}
                        label={labels["message_content"]}
                        submitOnChange={false}
                    >
                        <Input />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "auto_presence", "working_hours_integration"]}
                        label={"Working hours integrations"}
                    >
                        <Switch />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "auto_presence", "sms_only_confirmed_numbers"]}
                        label={"Sms only confirmed numbers"}
                    >
                        <Switch />
                    </RelaxSingleField>
                </>
            )
        },
        {
            title: <FormattedMessage
                id="modules.smartcall.containers.config.orderHandling"
                defaultMessage="Order Handling"
            />,
            key: "messages",
            content: (
                <>
                    <div>
                        Order Added:
                        <RelaxSingleField
                            name={["configurations", "order_handling", "order_added", "active"]}
                            label={labels["active"]}
                        >
                            <Switch />
                        </RelaxSingleField>
                        <RelaxSingleField
                            name={["configurations", "order_handling", "order_added", "message_content"]}
                            label={labels["message_content"]}
                        />
                    </div>
                    <div>
                        Order Complete:
                        <RelaxSingleField
                            name={["configurations", "order_handling", "order_complete", "active"]}
                            label={labels["active"]}
                        >
                            <Switch />
                        </RelaxSingleField>
                        <RelaxSingleField
                            name={["configurations", "order_handling", "order_complete", "message_content"]}
                            label={labels["message_content"]}
                        />
                    </div>
                    <div>
                        Order middle:
                        <RelaxSingleField
                            name={["configurations", "order_handling", "order_middle", "active"]}
                            label={labels["active"]}
                        >
                            <Switch />
                        </RelaxSingleField>
                        <RelaxSingleField
                            name={["configurations", "order_handling", "order_middle", "message_content"]}
                            label={labels["message_content"]}
                            submitOnChange={false}
                        >
                            <Input />
                        </RelaxSingleField>
                    </div>
                </>
            )
        },
        {
            title: <FormattedMessage
                id="modules.smartcall.containers.config.locker"
                defaultMessage="Locker"
            />,
            key: "locker",
            content: (<>
                <RelaxSingleField
                    name={["configurations", "locker", "active"]}
                    label={labels["active"]}
                >
                    <Switch />
                </RelaxSingleField>
                <RelaxSingleField
                    name={["configurations", "locker", "pin"]}
                    label={"Pin"}
                    submitOnChange={false}
                >
                    <Input />
                </RelaxSingleField>
            </>)
        },
        {
            title: <FormattedMessage
                id="modules.smartcall.containers.config.advancedSettings"
                defaultMessage="Advanced Settings"
            />,
            key: "advancedSettings",
            content: (
                <>
                    <RelaxSingleField
                        name={["configurations", "sent_outgoing_calls_to_fb", "active"]}
                        label={"Send outgoing calls to FB"}
                    >
                        <Switch />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["configurations", "update_settings", "active"]}
                        label={"Update Settings"}
                    >
                        <Switch />
                    </RelaxSingleField>
                </>
            )
        },
    ];

    const {} = useAPI(GetCallApp, true);

    const config = useSelectorWithParams(ProjectCallAppSelector) 

    const url = useMemo(() => {
        if (config) {
            return ({ projectId }: any) => getApiUrl(`projects/${projectId}/call-app/configuration/${config.id}`)
        } else {
            return ({ projectId }: any) => getApiUrl(`projects/${projectId}/call-app/configuration`)
        }
    }, [config])

    return (
        <RelaxMultiform
            name="multiform"
            initialValues={{ configurations: config && config.configurations }}
            callAPI={(a: any, call) => ({
                url: url,
                method: () => (config as any) ? "PATCH" : "POST",
                successDispatch: callAppSlice.actions.update,
                auth: true,
                ...call
            })}
            submitOnChange
        >
            <Tabs
                panes={panes} />
        </RelaxMultiform>
    )
}