import { ProductConfig } from "products";
import logo from "products/local3/logo.svg";
import requirements from "./requirements";


const config: ProductConfig = {
    name: "PakietLocal3",
    logo, 
    requirements: requirements,
}

export default config;