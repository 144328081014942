import { faHeart, faMinusCircle, faPlusCircle, faTrash } from "@fortawesome/pro-light-svg-icons";
import { Popover } from "antd";
import AwesomeIcon from "components/common/AwesomeIcon";
import FormattedMessage from "components/common/FormattedMessage";
import { ReactElement, ReactNode } from "react";
import { createPortal } from "react-dom";
import { StandardModal, useStandardModal } from "services/hooks/useModal";
import useSimpleBreakpoint from "services/hooks/useSimpleBreakpoint";

export type KeywordProps = {
    keyword?: any,
    mode: "suggestion" | "selected",
    inclusion?: "included" | "excluded",
    onInclude?: any,
    onExclude?: any,
    onRemove?: any,
    icon?: any,
    actions?: ReactNode,
    tooltipContent?: ReactElement,
    modalContent?: ReactElement,
    className?: string,
    onMouseEnter?: (keyword: any) => void,
    onMouseLeave?: (keyword: any) => void,
}

const InterestKeyword = ({ keyword, mode, inclusion, icon, ...props }: KeywordProps) => {

    const [s, si, mobile] = useSimpleBreakpoint();
    const isSmall = si < 2;
    const [modalRef, open, close] = useStandardModal();

    return (
        <li
            className={`interest-keyword ${props.className}`}
            onTouchEnd={() => open()}
            onMouseEnter={() => props.onMouseEnter && props.onMouseEnter(keyword)}
            onMouseLeave={() => props.onMouseLeave && props.onMouseLeave(keyword)}
        >
            <div className={`keyword-actions keyword-actions-wrapper`} >
                {props.actions}
            </div>
            <div className={'keyword-label'}>
                <AwesomeIcon icon={icon || faHeart} className="keyword-icon" size="xl" />
                <b style={{ position: "relative" }}>{keyword && keyword.name}</b>
            </div>
            <StandardModal
                ref={modalRef}
                title={keyword.name}
                onCancel={close}
                onOk={close}
                modalProps={{
                    footer: null,
                }}
            >
                {props.modalContent}
            </StandardModal>
        </li>
    )

}

export default InterestKeyword;