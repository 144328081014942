import React, { Component } from "react";
import { Row, Col } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import ConnectButton from "modules/panel/components/integrations/ConnectBtn";
import { connect } from "react-redux";
import OnboardingControls from "providers/onboarding/controls";
import { Platform } from "modules";
import { SubscriptionContext } from "providers/subscription/subscription";
import DeclinedPermissions from "modules/panel/components/integrations/DeclinedPermissions";
import {
  ProviderConfig,
} from "modules/panel/config/ProviderConfig";
import withSubscriptionConfig from "services/hoc/withSubscriptionConfig";

type Props = { subscription: SubscriptionContext } & ConnectedPlatforms;

type ConnectedPlatforms = {
  [key in Platform]: {
    declinedPermissions: string[];
    authorized: boolean;
  };
};

class DynamicIntegratePlatforms extends Component<Props> {
  render() {
    const { providers } = this.props.subscription;

    const platforms = providers.map((platform) => {
      const label = ProviderConfig.get(platform)?.displayName,
        logo = ProviderConfig.get(platform)?.icon,
        getIntegrateUrl = ProviderConfig.get(platform)?.getIntegrateUrl();
      return {
        label,
        logo,
        url: getIntegrateUrl,
        connected: this.props[platform].authorized,
      };
    });

    const allConnected =
      platforms.map((platform) => platform.connected).indexOf(false) === -1;

    return (
      <>
        <p>
          <FormattedMessage
            id="panel.containers.onboarding.integrations.platforms.integrateInstruction"
            defaultMessage="Please set up your first project. Log in on following platforms and connect your account."
          />
        </p>
        {providers.map((platform) => (
          <DeclinedPermissions
            key={platform}
            declinedPermissions={this.props[platform].declinedPermissions}
          />
        ))}

        <Row gutter={[30, 30]}>
          {platforms.map(({ label, logo, connected, url }, i: number) => (
            <div style={{ display: "flex" }}>
              <h2 style={{ alignSelf: "center" }}>
                <FormattedMessage
                  id="panel.containers.onboarding.integrations.platforms.step"
                  defaultMessage="Step {step})"
                  values={{ step: i }}
                />
              </h2>
              <ConnectButton
                name={label}
                logo={logo}
                url={url}
                connected={connected}
              />
            </div>
          ))}
        </Row>
        <OnboardingControls
          hideButtons={!allConnected}
          style={{ marginTop: "30px" }}
        />
      </>
    );
  }
}

const mapStateToProps = (state: never, { subscription }: Props) => {
  const selectors: any = {};

  subscription.providers.forEach((provider) => {
    const declinedPermissionsSelector = ProviderConfig.get(provider)?.declinedPermissionsSelector, 
    authorizedSelector = ProviderConfig.get(provider)?.authorizedSelector

    selectors[provider] = {
      declinedPermissions: declinedPermissionsSelector(state),
      authorized: authorizedSelector(state),
    };
  });


  return selectors;
};

export default withSubscriptionConfig(
  connect(mapStateToProps)(DynamicIntegratePlatforms)
);
