import FormattedMessage from "components/common/FormattedMessage";
import CompanyForm from "modules/site-generator/components/CompanyForm";
import {
  UserHasFilledCompanyDataSelector,
  WebsiteSelector,
} from "modules/site-generator/reducers/websiteSlice";
import ConnectedOnboardingControls from "providers/onboarding/controls";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";

export const OnboardingComapnyData = () => {
  const companyData = useSelectorWithParams(WebsiteSelector);

  const fulfilled = useSelectorWithParams(UserHasFilledCompanyDataSelector);

  return (
    <>
      <h2 className="col-name">
        <FormattedMessage
          id="apps.websites.containers.onboarding.companyData.heading"
          defaultMessage="Fill in your company data"
        />
      </h2>
      <CompanyForm companyData={companyData} />
      <ConnectedOnboardingControls nextDisabled={!fulfilled} />
    </>
  );
};
