import {
  getUrl as getSmartUrl,
  ONBOARDING_BUSINESS_DETAILS,
  ONBOARDING_GOOGLE_AUDIENCE,
  ONBOARDING_FACEBOOK_AUDIENCE,
  ONBOARDING_FACEBOOK_BUDGET,
  ONBOARDING_GOOGLE_BUDGET,
  ONBOARDING_FACEBOOK_KEYWORD,
  ONBOARDING_GOOGLE_KEYWORD,
  ONBOARDING_FACEBOOK_SUCCESS,
  ONBOARDING_GOOGLE_SUCCESS,
  ONBOARDING_PIXEL,
  ONBOARDING_PREPARE_PROJECT,
} from "modules/smart/config/routes";
import {
  HasBusinessSurveyAdsSettingsFilledSelector,
  HasFacebookAudienceFilledSelector,
  HasFacebookBudgetFilledSelector,
  HasGoogleAudienceFilledSelector,
  HasGoogleBudgetFilledSelector,
  HasGoogleKeywordsFilledSelector,
  HasUserSmartProject,
} from "../smartSlice";

export default [
  {
    // check current project have smart
    key: "userHasSmartProject",
    path: () => getSmartUrl(ONBOARDING_PREPARE_PROJECT),
    selector: HasUserSmartProject,
    omitWhenFulfilled: true,
    // component: <PrepareSmartProject/>
    // smart[currentProject]
  },
  {
    // check smart have website, initial_keyword
    key: "hasBusinessSurveyAdsSettingsFilled",
    path: () => getSmartUrl(ONBOARDING_BUSINESS_DETAILS),
    selector: HasBusinessSurveyAdsSettingsFilledSelector,
    // component: <OnboardingDomainAndKeyword/>
    // website !== null && initial_keyword !== null
  },
  {
    // check smart have facebook_ads_settings parameters
    key: "hasFacebookAudienceFilled",
    path: () => getSmartUrl(ONBOARDING_FACEBOOK_AUDIENCE),
    selector: HasFacebookAudienceFilledSelector,
    integration: "facebook",
    // component: <OnboardingAudienceFacebook/>
    // gender !== null &&
    // age_from !== null &&
    // age_to !== null &&
    // location_targeting.geo_points.length > 0 &&
  },
  {
    // no requirement
    key: "hasFacebookKeywordsFilled",
    path: () => getSmartUrl(ONBOARDING_FACEBOOK_KEYWORD),
    //selector: hasFacebookKeywordsFilledSelector,
    selector: () => true,
    integration: "facebook",
    permission: "use module smart adsSettings interestsKeywords",
    omitWhenFulfilled: false,
    // component: <OnboardingKeywordsFacebook/>
  },
  {
    // check smart have facebook_ads_settings parameters
    key: "hasFacebookBudgetFilled",
    path: [
      () => getSmartUrl(ONBOARDING_FACEBOOK_BUDGET),
      () => getSmartUrl(ONBOARDING_PIXEL),
      () => getSmartUrl(ONBOARDING_FACEBOOK_SUCCESS),
    ],
    selector: HasFacebookBudgetFilledSelector,
    integration: "facebook",
    // components: <OnboardingBudgetFacebook/>, <OnboardingPixel/>, <OnboardingSuccessFacebook/>
    // standard_daily_advertising_budget !== null, standard_period !== null &&
    // link_daily_advertising_budget !== null &&, link_period !== null &&
    // video_daily_advertising_budget !== null &&, video_period !== null &&
    // message_daily_advertising_budget !== null &&, message_period !== null
  },
  {
    // check smart have google_ads_settings parameters
    key: "hasGoogleAudienceFilled",
    path: () => getSmartUrl(ONBOARDING_GOOGLE_AUDIENCE),
    selector: HasGoogleAudienceFilledSelector,
    integration: "google",
    // component: <OnboardingAudienceGoogle/>
    // gender !== null &&
    // age_from !== null &&
    // age_to !== null &&
    // location_targeting.geo_points.length > 0
  },
  {
    // check smart have google_ads_settings.search_keywords
    key: "hasGoogleKeywordsFilled",
    path: () => getSmartUrl(ONBOARDING_GOOGLE_KEYWORD),
    selector: HasGoogleKeywordsFilledSelector,
    integration: "google",
    permission: "use module smart adsSettings interestsKeywords",
    // component: <OnboardingKeywordsGoogle/>
    // search_keywords
  },
  {
    // check smart have google_ads_settings parameters
    key: "hasGoogleBudgetFilled",
    path: [
      () => getSmartUrl(ONBOARDING_GOOGLE_BUDGET),
      () => getSmartUrl(ONBOARDING_GOOGLE_SUCCESS),
    ],
    selector: HasGoogleBudgetFilledSelector,
    integration: "google",
    // components: <OnboardingSuccessGoogle/>, <OnboardingBudgetGoogle/>
    // display_ad_daily_budget !== null && 
    // display_ad_period !== null && 
    // search_ad_default_daily_budget !== null
  },
];
