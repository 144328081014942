import Tabs from "_components/tabs/tabs"
import GoogleCampaignStats from "./GoogleCampaignStats"
import GoogleKeywordsStats from "./GoogleKeywordsStats"

const GoogleStats = () => {

    return (
        <Tabs
            panes={[
                {
                    key: "campaigns",
                    title: "Campaigns",
                    content: <GoogleCampaignStats />
                },
                {
                    key: "keywords",
                    title: "Keywords",
                    content: <GoogleKeywordsStats />
                }
            ]}
        />
    )

}

export default GoogleStats;