import { faCog, faFolder } from "@fortawesome/pro-light-svg-icons";
import { Dropdown, Menu, Tooltip } from "antd";
import AwesomeIcon from "components/common/AwesomeIcon";
import FormattedMessage from "components/common/FormattedMessage";
import { StandardProjectCard } from "components/common/StandardActorCard";
import StandardButton from "components/common/StandardButton";
import {
  COMPANY_PROJECTS,
  PROJECT_SETTINGS,
  getUrl,
} from "modules/panel/config/routes";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import useScope from "services/hooks/useScope";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { currentProjectIdSelector } from "services/store/scopeSlice";
import { Project } from "state/projects/projectTypes";
import { activeProjectsSelector, projectsAsArrayWithSubscriptionsSelector } from "state/projects/projectsSelectors";

export type Projects = Map<string, Project>;

export const ProjectSelect = () => {
  const activeProjects = useSelectorWithParams(activeProjectsSelector);
  const allProjects = useSelectorWithParams(projectsAsArrayWithSubscriptionsSelector)
  const currentProjectId = useSelectorWithParams(currentProjectIdSelector);

  const [showAll, setShowAll] = useState(false);
  const scope = useScope();

  const overlayMenu = useMemo(() => {
    let projectsList = showAll === true ? allProjects : activeProjects;

    return (
      <>
        <Menu>
          {(projectsList || []).map((project) => (
              <Menu.Item
                onClick={() => scope.openProject(project.id)}
                className="project-menu-item"
                key={project.id}
                style={{ padding: "10px" }}
              >
                <StandardProjectCard
                  projectId={project.id}
                  extra={
                    <div style={{ marginLeft: "auto" }}>
                      <Tooltip
                        placement="right"
                        title={
                          <FormattedMessage
                            id="panel.projectSelect.editTooltip"
                            defaultMessage="Project settings"
                          />
                        }
                      >
                        <Link to={getUrl(PROJECT_SETTINGS)}>
                          <AwesomeIcon icon={faCog} color="black" size="xl" />
                        </Link>
                      </Tooltip>
                    </div>
                  }
                />
              </Menu.Item>
            ))}

          <StandardButton
            type="link"
            onClick={() => {
              setShowAll((sa) => !sa);
            }}
            style={{
              padding: "10px",
              width: "100%",
              height: "100%",
              color: "black",
            }}
          >
            {showAll ? (
              <FormattedMessage
                id="panel.projectSelect.hideInactive"
                defaultMessage="Hide inactive projects"
              />
            ) : (
              <FormattedMessage
                id="panel.projectSelect.showAll"
                defaultMessage="Show all projects"
              />
            )}
          </StandardButton>

          <Link to={getUrl(COMPANY_PROJECTS)}>
            <StandardButton
              type="link"
              style={{
                padding: "10px",
                width: "100%",
                height: "100%",
                color: "black",
              }}
              icon={faFolder}
            >
              <FormattedMessage
                id="panel.projectSelect.allProjects"
                defaultMessage="All projects"
              />
            </StandardButton>
          </Link>
        </Menu>
      </>
    );
  }, [currentProjectId, showAll]);

  return (
    <>
      <Dropdown
        overlayClassName="top-bar-item-dropdown"
        trigger={["hover", "click"]}
        overlay={overlayMenu}
        disabled={!overlayMenu}
        className="user"
      >
        <div>
        {currentProjectId && (
          <div
            className="top-bar-item-dropdown-wrapper"
            style={{ padding: "10px" }}
          >
            <StandardProjectCard projectId={currentProjectId} />
          </div>
        )}
        </div>
      </Dropdown>
    </>
  );
};
