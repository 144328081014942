import { useMemo, useState } from "react";
import { useDebounceTrigger } from "./useDebounce";
import useAPI, { callAPIProps } from "./useAPI";

type UseSuggestionsProps<Type> = {
    callAPI: (q: string) => callAPIProps,
    adjustSuggestion?: (suggestions: any) => Type,
}

type UseSuggestionsReturn<Type> = {
    askForSuggestions: (q: string) => void,
    suggestions: Type[],
    loading?: boolean,
}

const useSuggestions = <Type extends any>(props: UseSuggestionsProps<Type>): UseSuggestionsReturn<Type> => {

    const {call, data:suggestions, loading, clear} = useAPI({})

    const askForSuggestions = (q: string) => {
        call(props.callAPI(q))
    }

    const [debounceAsk] = useDebounceTrigger(askForSuggestions, 500)

    const adjustedSuggestions = useMemo(() => {
        if (!suggestions) return [];
        if (!props.adjustSuggestion) return suggestions;
        return suggestions.map((s:any) => props.adjustSuggestion!(s));
    }, [suggestions])

    return {
        askForSuggestions: debounceAsk, 
        suggestions: adjustedSuggestions,
        loading,
    }

}


export default useSuggestions;