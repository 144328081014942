import { Rule } from "antd/es/form";
import FormattedMessage from "components/common/FormattedMessage";

export const ruleRequired: Rule = {
  required: true,
  message: (
    <FormattedMessage
      id="forms.validation.required"
      defaultMessage="This field is required."
    />
  ),
};
export const ruleMin = (min: number) => ({
  min: min,
  message: (
    <FormattedMessage
      id="forms.validation.length.min"
      defaultMessage="Min length is {min}."
      values={{ min }}
    />
  ),
});

export const ruleMax = (max: number) => ({
  max: max,
  message: (
    <FormattedMessage
      id="forms.validation.length.max"
      defaultMessage="Max length is {max}."
      values={{ max }}
    />
  ),
});
export const rulesetMax = (max: number) => [
  {
    max: max,
    message: (
      <FormattedMessage
        id="forms.validation.length.max"
        defaultMessage="Max length is {max}."
        values={{ max }}
      />
    ),
  },
];

export const rulesetMinMax = (min: number, max: number) => [
  {
    max: max,
    message: (
      <FormattedMessage
        id="forms.validation.length.max"
        defaultMessage="Max length is {max}."
        values={{ max }}
      />
    ),
  },
  {
    min: min,
    message: (
      <FormattedMessage
        id="forms.validation.length.min"
        defaultMessage="Min length is {min}."
        values={{ min }}
      />
    ),
  },
];

export const ruleArrayMin = (min: number): Rule => ({
  type: "array",
  min: min,
  message: (
    <FormattedMessage
      id="forms.validation.elements.min"
      defaultMessage="Min elements: {min}."
      values={{ min }}
    />
  ),
});

export const ruleArrayMax = (max: number): Rule => ({
  type: "array",
  max: max,
  message: (
    <FormattedMessage
      id="forms.validation.elements.max"
      defaultMessage="Max elements: {max}."
      values={{ max }}
    />
  ),
});

export const rulesetArrayMin = (min: number): Rule[] => [ruleArrayMin(min)];

export const rulesetArrayMax = (max: number): Rule[] => [ruleArrayMax(max)];

export const rulesetArrayMinMax = (min: number, max: number): Rule[] => {
  const rules: Rule[] = [];

  if (max > 0) rules.push(ruleArrayMax(max));

  if (min > 0) rules.push(ruleArrayMin(min));

  return rules;
};

export const rulesetEmail = (): any[] => [
  {
    type: "email",
  },
];

export const customValidatorNoDuplicates = (min: number, max: number) => {
  return (value: any, name: any, form: any) => {
    const statuses: string[] = [];
    const messages: any[] = [];
    let hasErrors = false;

    if (!value) return null;

    if (value.length < min) {
      form.setFields([{ name, errors: [`Minimum ${min} items.`] }]);

      return {
        status: "error",
        message: (
          <FormattedMessage
            id="forms.validation.elements.min"
            defaultMessage="Min elements: {min}."
            values={{ min }}
          />
        ),
      };
    }

    if (value && value.length > max) {
      form.setFields([{ name, errors: [`Maximum ${max} items.`] }]);
      return {
        status: "error",
        message: (
          <FormattedMessage
            id="forms.validation.elements.max"
            defaultMessage="Max elements: {max}."
            values={{ max }}
          />
        ),
      };
    }

    value &&
      value.forEach &&
      value.forEach((v: any, i: any, a: any) => {
        const duplicates = value.filter((fv: any) => fv === v);
        if (duplicates.length > 1) {
          hasErrors = true;
          statuses[i] = "error";
          messages[i] = (
            <FormattedMessage
              id="forms.validation.elements.duplicate"
              defaultMessage="Duplicate"
              values={{ max }}
            />
          );
        }
      });

    const res = {
      status: hasErrors && "error",
      message: hasErrors && (
        <FormattedMessage
          id="forms.validation.elements.noDduplicates"
          defaultMessage="Values have to be unique."
          values={{ max }}
        />
      ),
      statuses: statuses,
      messages: messages,
    };

    if (hasErrors) {
      form.setFields([
        {
          name,
          errors: [
            <FormattedMessage
              id="forms.validation.elements.noDduplicates"
              defaultMessage="Values have to be unique."
              values={{ max }}
            />,
          ],
        },
      ]);
    } else {
      form.setFields([{ name, errors: null }]);
    }

    return res;
  };
};

export const customValidatorDebug = {
  validator: (rule: any, value: any, ...rest: any[]) => {
    console.log("Custom validator debug", rule, value, rest);
    //console.log("CustomValidaror", rule.field, value)
    return Promise.resolve();
    //return Promise.reject();
  },
  message: "DebugError",
};

// export const customValidatorFunction = (form:any) => {
//     console.log("Running custom validator function", form);
//     return ({
//         validator: ((rule:any, value:any) => {
//             console.log("Running custom, generated validator function", params);
//                     return Promise.resolve();
//         })
//     })
// }

export const ruleArrayNoFieldErrors = (form: any) => {
  return {
    validator: (rule: any, value: any) => {
      const namePath = rule.field.split(".");
      const names = value.map((v: any, i: any) => [...namePath, i]);
      const errors = names.map((n: any) => form.getFieldError(n).length > 0);
      const hasErrors = errors.some((e: any) => e);
      if (hasErrors) {
        return Promise.reject();
      } else {
        return Promise.resolve();
      }
    },
    message: (
      <FormattedMessage
        id="forms.validation.noFieldErrors"
        defaultMessage="Some fields have errors"
      />
    ),
  };
};
export const ruleArrayNoDuplicates = () => {
  return {
    validator: (rule: any, value: any) => {
      const duplicates =
        value.filter((v: any, i: any, a: any) => a.indexOf(v) !== i).length > 0;
      if (duplicates) {
        return Promise.reject();
      } else {
        return Promise.resolve();
      }
    },
    message: (
      <FormattedMessage
        id="forms.validation.noDuplicates"
        defaultMessage="Values have to be unique"
      />
    ),
  };
};

export const ruleNoDuplicates = (form: any) => {
  return {
    validator: (rule: any, value: any) => {
      const name: (string | number)[] = rule.field.split(".");
      const isList = isNaN(Number(name[name.length - 1]));
      if (isList) {
        const duplicates =
          value.filter((v: any, i: any, a: any) => a.indexOf(v) !== i).length >
          0;
        if (duplicates) {
          return Promise.reject();
        } else {
          return Promise.resolve();
        }
      } else {
        const fieldValue = form.getFieldValue(name.slice(0, name.length - 1));
        const duplicates =
          fieldValue.filter((v: any) => v === value).length > 1;
        if (duplicates) {
          return Promise.reject();
        } else {
          return Promise.resolve();
        }
      }
    },
    message: (
      <FormattedMessage
        id="forms.validation.noDuplicates"
        defaultMessage="Values have to be unique"
      />
    ),
  };
};

export const imageRequireCrop = {
  validator: (_: any, value: any) => {
    if (!value) return Promise.resolve();
    let missingCrop = false;
    value.forEach((v: any) => {
      if (!v.crop) missingCrop = true;
    });
    if (missingCrop) return Promise.reject(new Error("Crop required"));
    return Promise.resolve();
    //return Promise.reject(new Error("Custom validator error"));
  },
  message: (
    <FormattedMessage
      id="forms.validation.cropRequired"
      defaultMessage="Crop Required"
    />
  ),
};

export const ruleImageRequireCropsForPlatforms = (platforms?: string[]) => {
  return {
    validator: (_: any, value: any) => {
      if (!value) return Promise.resolve();
      let missingCrop = false;
      value.forEach((v: any) => {
        if (v.type !== "image") return;
        if (!v.crop) {
          missingCrop = true;
          return;
        }

        if (platforms) {
          if (platforms.includes("facebook") && !v.crop.facebook) {
            missingCrop = true;
            return;
          }
          if (platforms.includes("google") && !v.crop.google) {
            missingCrop = true;
            return;
          }
        }
      });
      if (missingCrop) {
        return Promise.reject(new Error("Crop required"));
      }
      return Promise.resolve();
      //return Promise.reject(new Error("Custom validator error"));
    },
    message: (
      <FormattedMessage
        id="forms.validation.cropRequired"
        defaultMessage="Crop Required"
      />
    ),
  };
};

export const ruleKeywordRegex = {
  //eslint-disable-next-line
  pattern: new RegExp("(#)?"),
  message: (
    <FormattedMessage
      id="forms.validation.keyword"
      defaultMessage="Wrong keyword"
    />
  ),
};

export const ruleForbiddenDomains = (domains: string[]) => {
  return {
    custom: (value: any) => {

      try {
        const parsedUrl = new URL(value);
        const hostname = parsedUrl.hostname;
        const forbidden = domains.some(domain => hostname.endsWith(domain));
        return !forbidden;
    } catch (error) {
        console.error('Invalid URL:', error);
        return false;
    }

      
    },
    message: (
      <FormattedMessage
        id="forms.validation.forbiddenDomain"
        defaultMessage="Forbidden domain"
      />
    ),
  };
}

export const rulesetPassword = [
  {
    pattern: new RegExp("^(?=.{8,}$).*$"),
    message: (
      <FormattedMessage
        id="services.formsValidators.minCharacters"
        defaultMessage="min. 8 characters,"
      />
    ),
  },
  {
    pattern: new RegExp("^(?=.*[A-Z]).*$"),
    message: (
      <FormattedMessage
        id="services.formsValidators.minUppercase"
        defaultMessage="min. 1 uppercase character"
      />
    ),
  },

  {
    pattern: new RegExp("^(?=.*[a-z]).*$"),
    message: (
      <FormattedMessage
        id="services.formsValidators.minLowercase"
        defaultMessage="min. 1 lowercase character"
      />
    ),
  },
  {
    pattern: new RegExp("^(?=.*[0-9]).*$"),
    message: (
      <FormattedMessage
        id="services.formsValidators.minNumber"
        defaultMessage="min. 1 numerical character"
      />
    ),
  },
  {
    pattern: new RegExp("^(?=.*\\W).*$"),
    message: (
      <FormattedMessage
        id="services.formsValidators.minSpecial"
        defaultMessage="min. 1 special character"
      />
    ),
  },
];

export const checkDuplicates = (checkedValues: any[]) => {
  return {
    custom: (value: string | number | any) => {
      if (checkedValues.includes(value)){
        return false
      } else{
        return true;
      }
    },
    message: (
      <FormattedMessage
        id="forms.validation.noDuplicates"
        defaultMessage="Values have to be unique"
      />
    ),
  };
};
