import { DatePicker, Form, Grid, Input, Tooltip } from "antd";
import React, { useState, ReactNode, useEffect, useMemo } from "react";
import useSimpleBreakpoint from "services/hooks/useSimpleBreakpoint";
import DateInput from "_components/forms/date-input";
import { PlatformSelector } from "_components/forms/platform-selector";
import { CalculateGridStyle, InfinityColumnProps } from "./infinityTemplates";

export type InfinityHeadingProps = {
    columns: any[];
}


export function InfinityListHeader (props:any) {
    return (
        <div className="infinity-header">
            <div className="title">{props.title}</div>
            <div className="subtitle">{props.subtitle}</div>
        </div>
    )
}

export default function InfinityListHeading ({columns}:InfinityHeadingProps) {


    const [size, sizeIndex] = useSimpleBreakpoint();

    const elements = useMemo(()=> {
        const elements:any[] = [];
        columns.forEach(c => {  
            const style:any = {...c.style, ...c.headingStyle};
           // if (c.width) style["width"] = c.width; 
            if (c.render) {
                if (!c.size || !c.size[sizeIndex] || c.size[sizeIndex] === false || c.size[sizeIndex] === "0") return;

                elements.push(
                <div
                    key={c.key}
                    className={`infinity-element-wrapper ${c.key}`}
                    style={{ ...c.wrapperStyle, gridArea: c.key}}>
                    <div className="infinity-element" style={style}>
                            {c.render(c.item, { ...c.options, platform: "all", ...c, ...(c.details || {}), size: c.size, sizeIndex: sizeIndex, })}
                    </div>
                </div>
                )

                // elements.push(
                //     <Tooltip title={c.headerTooltip} key={c.key}>
                //     <div key={c.key} className={`infinity-element-wrapper ${c.key}`}>
                //         <div className="infinity-element" style={style}>
                //             {c.title}
                //         </div>
                //     </div>
                //     </Tooltip>
                // )
            }
        })
        return elements;
    },[columns,sizeIndex])

    const gridStyle = useMemo(() => {
        return CalculateGridStyle(columns, sizeIndex);
    }, [columns,sizeIndex])

    return (
        <div className="infinity-heading" style={{...gridStyle}}>
            {elements}
        </div>
    )
}