import { createSelector, createSlice } from "@reduxjs/toolkit";
import { callAPIProps } from "services/hooks/useAPI";
import { currentProjectIdSelector } from "services/store/scopeSlice";

export type CallAppState = {[id:number]:CallAppProps};

export type CallAppProps = {
  configurations: {};
  created_at: string;
  id: number;
  project_id: number;
  updated_at: string;
};

const initialState: CallAppState = {};

export const callAppSlice = createSlice({
  name: "callApp",
  initialState,
  reducers: {
    get: (state, action) => {
      state[action.payload.projectId] = action.payload.data;
    },
    update: (state, action) => {
      state[action.payload.projectId] = action.payload.data;
    },
  },
});

export const GetCallApp: callAPIProps = {
  url: ({ getApiUrl, projectId }: any) =>
    getApiUrl(`projects/${projectId}/call-app/configuration`, 1),
  method: "GET",
  successDispatch: callAppSlice.actions.get,
};

export const UpdateCallApp = (callAppId: string): callAPIProps => ({
  url: ({ getApiUrl, projectId }: any) =>
    getApiUrl(`projects/${projectId}/call-me/${callAppId}`, 1),
  method: "PATCH",
  successDispatch: callAppSlice.actions.update,
});

export const ProjectCallAppSelector = createSelector(
  [(state: any) => state?.callApp, currentProjectIdSelector],
  (state: any, projectId: any) => state && state[projectId]
);
