import { Table } from "antd";
import { forwardRef, useImperativeHandle, useMemo, useRef } from "react";
import FormattedMessage from "components/common/FormattedMessage";
import { useGallery } from "./Gallery";
import { StandardModal, useStandardModal } from "services/hooks/useModal";
import PlatformIcon from "components/common/icons/PlatformIcon";

const presetColumns: any = [
    {
        title: <FormattedMessage
            id="apps.tools.imageGallery.presets.name"
            defaultMessage="Crop"
        />,
        key: "displayName",
        render: (preset: any) => preset.displayName || preset.ratio,
    },
    {
        title: <FormattedMessage
            id="apps.tools.imageGallery.presets.minWidth"
            defaultMessage="Min Width"
        />,
        dataIndex: "minWidth",
        key: "minWidth"
    },
    {
        title: <FormattedMessage
            id="apps.tools.imageGallery.presets.minHeight"
            defaultMessage="Min Height"
        />,
        dataIndex: "minHeight",
        key: "minHeight"
    },
    // {
    //     title: <FormattedMessage
    //         id="apps.tools.imageGallery.presets.maxWidth"
    //         defaultMessage="Max Width"
    //     />,
    //     dataIndex: "maxWidth",
    //     key: "maxWidth"
    // },
    // {
    //     title: <FormattedMessage
    //         id="apps.tools.imageGallery.presets.maxHeight"
    //         defaultMessage="Max Height"
    //     />,
    //     dataIndex: "maxHeight",
    //     key: "maxHeight"
    // }
]

const ImageCropHelp = forwardRef((props: any, ref: any) => {

    const { croppedImage, cropPresets } = useGallery();
    const [modalRef, open, close] = useStandardModal();

    const presets = useMemo(() => {
        if (!cropPresets) return;
        return Object.keys(cropPresets).map((platform: any) => (
            <div>
                <PlatformIcon platform={platform} />{platform}
                <Table
                    columns={presetColumns}
                    dataSource={cropPresets[platform]}
                    footer={() => null}
                    pagination={false}
                />
            </div>
        )
        )
    }, [cropPresets])


    const currentImage = useMemo(() => {

        if (!croppedImage) return null;

        return (
            <div>

                <div>
                    <FormattedMessage
                        id="apps.tools.imageGallery.help.width"
                        defaultMessage="Width: {width}"
                        values={{ width: croppedImage.width }}
                    />
                    {"  "}
                    <FormattedMessage
                        id="apps.tools.imageGallery.help.height"
                        defaultMessage="Height: {height}"
                        values={{ height: croppedImage.height }}
                    />
                </div>
            </div>
        )
    }, [croppedImage])

    useImperativeHandle(ref, () => ({
        open,
        close
    }))

    return (
        <StandardModal
            ref={modalRef}
            //onCancel={close}
            onOk={close}
            modalProps={{
                footer: null,
            }}
        >
            <div>
                {currentImage}
                {cropPresets && presets}
            </div>
        </StandardModal>
    )

})

export default ImageCropHelp;

export const ImageCropHelpText = () => {

    const helpModalRef = useRef();

    return (
        <>
        <a onClick={() => helpModalRef.current.open()} style={{padding: "0px 5px"}}>
        <FormattedMessage
            id="apps.tools.imageGallery.toolHelpText"
            defaultMessage="Requirements"
        />
        </a>
        <ImageCropHelp ref={helpModalRef}/>
        </>
    )

}