import { useEffect, useState } from "react";

//Use this hook to create a value that can be overridden by another value

export default function useValueWithOverride<T>(initialValue: T | null):{
    value: T | null,   
    override: T | null,
    normalValue: T | null,
    setValue: (value: T | null) => void,
    setOverride: (value: T | null) => void,
} {

    const [normalValue, setNormalValue] = useState<T | null>(initialValue);
    const [override, setOverride] = useState<T | null>(null);

    useEffect(() => {
        setOverride(initialValue);
    }, [initialValue]);

    return {
        value: override !== null ? override : normalValue,
        override: override,
        normalValue: normalValue,
        setValue: setNormalValue,
        setOverride: setOverride,
    }

}