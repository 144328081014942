
import { createSelector, createSlice } from "@reduxjs/toolkit"
import { isEqual } from "lodash";
import { userSelector } from "modules/panel/config/selectors/user";

import { callAPIProps, callAPISettings } from "services/hooks/useAPI";
import { Subscription } from "state/subscriptions/subscriptionTypes";
import { currentSubscriptionSelector } from "state/subscriptions/subscriptionsSelectors";
import { CreationListFilter } from "tools/creationEditor/creationsSlice";
import { TaskListFilter } from "tools/tasks/data/tasksSlice";

type UserSettingsState = {
    general?: any, 
    subscriptions?: any,
    interface?: {
        language?: string,
        postListv2?: {
            showPosts?: boolean,
            showAOA?: boolean,
            showDeleted?: boolean,
            showHidden?: boolean,
        },
        creationList?: CreationListFilter,
        tasksList?: TaskListFilter
    },
    testing?: any,
}


const supportedLanguages = ["pl", "en"]

const getBrowserLanguage = () => {

    let language:string | null | undefined;
    const localLangaue = localStorage.getItem("language");
    if (localLangaue) {
      //  console.log("Getting language from local storage", localLangaue);
        language = localLangaue;
    }
    if (!language) {
       // console.log("Getting language from navigator", navigator.language);
        language = navigator.language;
    }
    if (!language) {
     //   console.log("Defaulting to english");
        language = "en-US";
    }

    let l = language.split(/[-_]/)[0];

    const supportedLanguage = supportedLanguages.includes(l) ? l : "en";
    return supportedLanguage;
}

const setLangauge = (state: any, language: string, reason:string) => {
    state.interface.language = language;
    localStorage.setItem("language", language);
}

const initializeLanguage = () => {
    const language = getBrowserLanguage();
    localStorage.setItem("language", language);
    return language;
}

export const initialState: UserSettingsState = {
    general: {},
    subscriptions: {},
    interface: {
        language: initializeLanguage(),
        postListv2: {
            showPosts: true,
            showAOA: true,
            showDeleted: false,
            showHidden: false,
        },
        creationList: {
            showPosts: true,
            showAOA: true,
            sort: "-created_at",
            statusFilter:{
                removed: false,
                draft: true,
                published: true,
                scheduled: true
            }
        },
        tasksList: {
            showPostRequest: true,
            showDataUpdate: true,
            statusFilter:{
                pendingAcceptation: true,
                accepted: true,
                rejected: true,
                returnedForCorrection: true,
                completed: true,
                scheduled: true,
            }
        }
    },
    testing: {},
}

const userSettingsSlice = createSlice({
    name: "userSettings",
    initialState: initialState,
    reducers: {
        get: (state, action) => {

            if (!action.payload) return;
            state.general = action?.payload?.data?.settings?.general;
            state.subscriptions = action?.payload?.data?.settings?.subscriptions;

            //remove existing language from interface
            if (action?.payload?.data?.settings?.interface?.language) delete action?.payload?.data?.settings?.interface?.language

            state.interface = {
                ...initialState.interface,
                ...action?.payload?.data?.settings?.interface,
            }
            //if (userSettingsLanguage && userSettingsLanguage !== currentLanguage) setLangauge(state, userSettingsLanguage, "GET")
        },
        update: (state, action) => {
            return action?.payload?.data?.settings;
        },
        localUpdate: (state, action) => {
            return {...state, ...action.payload}
        }, 
        create: (state, action) => {
            return action?.payload?.data?.settings;
        },
        changeLanguage: (state, action) => {
            setLangauge(state, action?.payload?.language, "CHANGE_LANGUAGE")
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase("LOAD_USER_SUCCESS", (state, action) => {
            if (action?.payload?.user?.language)  {
                setLangauge(state, action?.payload?.user?.language, "LOAD_USER_SUCCESS");
            }
        })
        .addCase("USER_DATA_SET_SUCCESS", (state, action) => {
            if (action?.payload?.user?.language) setLangauge(state, action?.payload?.user?.language, "USER_DATA_SET_SUCCESS")
        })
    }
})


export const getUserSettings:callAPIProps = {
    url: ({getApiUrl}) => getApiUrl("user/settings"),
    successDispatch: userSettingsSlice.actions.get,
}

export const updateUserSettings = (data:any):callAPIProps => {
    return {
        url: ({getApiUrl}) => getApiUrl("user/settings"),
        method: "PATCH",
        body: {settings: data},
        successDispatch: userSettingsSlice.actions.update,
    }
}

export const userSettingsSelector = createSelector([
    (state:any) => state.userSettings
], (userSettings: UserSettingsState) => userSettings, 
{
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
})

export const userSettingsInterfaceSelector = createSelector([
    userSettingsSelector
], (userSettings: UserSettingsState) => userSettings.interface)

export const userSettingsInterfacePostListSelector = createSelector([
    userSettingsInterfaceSelector
], (userSettings) => userSettings?.postListv2 || {})

export const userSettingsInterfaceCreationListSelector = createSelector([
    userSettingsInterfaceSelector
], (userSettings) => userSettings?.creationList || {}, 
{
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
})

export const userSettingsInterfaceTasksListSelector = createSelector([
    userSettingsInterfaceSelector
], (userSettings) => userSettings?.tasksList || {})


export const userSettingsInterfaceLanguageSelector = createSelector([
    userSelector
], (user) => {
    if (user?.language) return user?.language
    return getBrowserLanguage();
})

export const userSettingsGeneralSelector = createSelector([
    userSettingsSelector
], (userSettings: UserSettingsState) => userSettings.general)

export const userSettingsTestSelector = createSelector([
    userSettingsSelector
], (userSettings: UserSettingsState) => userSettings.testing)

export const userSettingsSubscriptionsSelector = createSelector([
    userSettingsSelector,
    currentSubscriptionSelector,
], (userSettings: UserSettingsState, subscription: Subscription) => userSettings?.subscriptions?.[subscription.id] || {})


export default userSettingsSlice;