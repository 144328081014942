import { ProductConfig } from "products";
import logo from "products/carservice/logo.svg";
import smartRequirements from "modules/smart/config/requirements";
import {
  getUrl as getSmartUrl,
  ONBOARDING_FACEBOOK_BUDGET,
  ONBOARDING_FACEBOOK_SUCCESS,
} from "modules/smart/config/routes";
import { Requirement } from "modules";
import siteGeneratorRequirementsBase from "modules/site-generator/config/industries/requirements/requirementsBase";

const config: ProductConfig = {
  name: "Car Service",
  logo,
  requirements: [
    ...smartRequirements.map((requirement) => {
      if (requirement.key === "hasFacebookBudgetFilled")
        return {
          ...requirement,
          path: [
            () => getSmartUrl(ONBOARDING_FACEBOOK_BUDGET),
            () => getSmartUrl(ONBOARDING_FACEBOOK_SUCCESS),
          ],
        };
      return requirement;
    }),
    ...siteGeneratorRequirementsBase
  ] as Requirement[],
};

export default config;
