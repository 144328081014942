import FormattedMessage from "components/common/FormattedMessage"
import StandardButton from "components/common/StandardButton"

export default function RelaxControls (props:any) {

    const handleSubmit = () => {
        if (props.relaxData) {
            props.relaxData.submit("manual");
        }
    }

    if (!props.relaxData) return (
        <div style={{
            display: "flex",
            justifyContent: "flex-end",
            alignContent: "center",
            width: "100%"
        }}>
            <StandardButton 
                disabled={true}
                type={"primary"}
            >
                <FormattedMessage
                    id="common.save"
                    defaultMessage="Save" />
                </StandardButton>
        </div>
    )


    return (
        <div style={{
            display: "flex",
            justifyContent: "flex-end",
            alignContent: "center",
            width: "100%"
        }}>
            <StandardButton 
            onClick={handleSubmit}
            loading={props.relaxData?.apiHandler.loading}
            disabled={!props.relaxData?.canSend}
            type={props.relaxData?.changed ? "primary" : "default"}
            >
                <FormattedMessage
                    id="common.save"
                    defaultMessage="Save" />
                </StandardButton>
        </div>
    )

}