import { ProductConfig } from "products";
import logo from "products/smart/logo.svg";
import smartRequirements from "modules/smart/config/requirements";
import combinedRequirements from "products/local3/requirements";
import { Requirement } from "modules";

export const FizjoAcademyMini: ProductConfig = {
  name: "FizjoAcademyMini",
  logo,
  requirements: smartRequirements as Requirement[],
  index: "/smart/posts/list",
};

export const FizjoAcademyOptimum: ProductConfig = {
    name: "FizjoAcademyOptimum",
    logo,
    requirements: combinedRequirements as Requirement[],
    index: "/smart/posts/list",
};

export const FizjoAcademySmart: ProductConfig = {
    name: "FizjoAcademySmart",
    logo,
    requirements: combinedRequirements as Requirement[],
    index: "/smart/posts/list",
};