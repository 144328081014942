import React from "react";
import { ReactNode } from "react";
import { Skeleton, Badge } from "antd";
import products, { Product } from "products";
import Overlay from "_components/Overlay";

type Props = {
  logo?: string;
  name?: string | ReactNode;
  hideTitle?: boolean;
  loading?: boolean;
  count?: number;
  overlay?: ReactNode;
  onClick?: () => void;
  className?: string;
  id?: string;
};

const ProductLogo = ({
  hideTitle = false,
  logo,
  name,
  onClick,
  loading,
  overlay,
  count,
  className,
  ...props
}: Props) => (
  <div className={`product-logo ${className || ""}`} onClick={onClick} id={props?.id}>
    {loading ? (
      <Skeleton
        className="logo skeleton"
        active
        loading
        paragraph={{ rows: 0 }}
      />
    ) : (
      <Badge count={count}>
        <Overlay overlay={overlay}>
          <img
            className="logo"
            src={logo ? logo : getProductLogo(name as Product)}
            alt={`${name} product logo`}
          />
        </Overlay>
      </Badge>
    )}

    {!hideTitle && (
      <span className="name">
        {loading ? (
          <Skeleton
            className="skeleton"
            active
            loading
            paragraph={{ rows: 0 }}
          />
        ) : (
          name
        )}
      </span>
    )}
  </div>
);

const getProductLogo = (productName: Product) =>
  products[productName] ? products[productName].logo : "";

export default ProductLogo;
