import {
  faPlus,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import "./price-config.less";
import { Input, Select, Spin } from "antd";
import RelaxField from "tools/relaxForm/RelaxField";
import {
  UpdatePriceColumn,
  NewPriceRow,
  PricingColumn,
  PricingColumnTemplate,
  PricingTemplate,
  Service,
  TemplateProps,
  UpdatePrice,
  DeleteRow,
} from "modules/site-generator/reducers/websitePricesSlice";
import StandardButton from "components/common/StandardButton";
import "dragula/dist/dragula.css";
import useAPI from "services/hooks/useAPI";
import _ from "lodash";
import { RenameRow } from "./RenameRow";
import FormattedMessage from "components/common/FormattedMessage";
import { Columns } from "./Columns";
import { Rows } from "./Rows";
import AwesomeIcon from "components/common/AwesomeIcon";

export type PriceTableProps = {
  category: string;
  columns: number[] | [];
  id: number;
  name: string;
  services: Service[];
  template: PricingTemplate;
};

const PriceCell = ({
  price,
  row,
  column,
  service,
  table,
}: {
  price: PricingColumn;
  row: number;
  column: number;
  service: Service;
  table: PriceTableProps;
}) => {
  const updateCall = useAPI({});

  const handleSubmit = (v: any) => {
    updateCall.call(
      UpdatePrice(table.category, table.id, service.serviceId, v, service.title)
    );
  };

  return (
    <div
      style={{
        gridRowStart: row + 1,
        gridColumnStart: column + 1,
      }}
      className="price"
    >
      <RelaxField
        name="price"
        initialValue={price.value}
        shouldReset={true}
        submitOverride={handleSubmit}
        finalizeSubmit={(value: any) => {
          const preparedColumns = service.columns.map((c: PricingColumn) =>
            c.id === price.id ? { ...c, value: value.price } : c
          );
          const sorted = _.sortBy(preparedColumns, "id");
          const o: any = {};
          sorted.forEach((v: any) => (o[v.id] = v.value));

          return o;
        }}
      >
        <Input />
      </RelaxField>
    </div>
  );
};

const PriceRows = ({ table }: { table: PriceTableProps }) => {
  const { services, template } = table;
  const removeRow = useAPI(DeleteRow());

  const handleDeleteRow = (serviceId: number) => {
    removeRow.call(DeleteRow(table.id, serviceId));
  };

  return (
    <>
      {services &&
        services.map &&
        services.map((service: Service, serviceIndex: number) => {
          return service.columns.map(
            (price: PricingColumn, columnIndex: number) => {
              if (table.columns.some((col) => col === price.id)) {
                return (
                  <>
                    <PriceCell
                      key={`${serviceIndex}-${columnIndex}`}
                      price={price}
                      row={serviceIndex}
                      column={columnIndex}
                      service={service}
                      table={table}
                    />
                    {columnIndex === table.columns.length - 1 && (
                      <div
                        style={{
                          marginLeft: "10px",
                          marginTop: "-60px",
                          width: "40px",
                          height: "50px",
                          gridRowStart: serviceIndex + 2,
                          gridColumnStart: columnIndex + 2,
                        }}
                        className="price"
                      >
                        <AwesomeIcon icon={faTrash}
                          key={`${serviceIndex}`}
                          onClick={() => {
                            handleDeleteRow(service.serviceId);
                          }}
                        />
                      </div>
                    )}
                  </>
                );
              } else {
                return null;
              }
            }
          );
        })}
    </>
  );
};

const PricingTable = ({
  table,
  templates,
}: {
  table: PriceTableProps;
  templates: TemplateProps[];
}) => {
  const newColumn = useAPI(UpdatePriceColumn(table.id));
  const newRow = useAPI(NewPriceRow(table.id));
  const getPriceLists = useAPI({});

  const selectColumns =
    templates[0] !== undefined && table.columns !== undefined
      ? templates[0].columns.filter(
          (col: PricingColumnTemplate) => !table.columns.includes(col.id)
        )
      : null;

  const mappedColumnsOptions =
    selectColumns &&
    selectColumns.length > 0 &&
    selectColumns.map((col) => {
      return {
        label: col.name,
        value: col.id,
      };
    });

  const handleAddColumn = (value: number) => {
    const newColumns = [...table.columns, value];
    newColumn.call({
      body: {
        columns: newColumns,
      },
    });
  };

  if (!table) return null;

  const rows = table?.services?.length;

  return (
    <Spin spinning={getPriceLists.loading}>
      <div className="price-table-wrapper">
        <div className="price-table-tile">
          <RenameRow name={table.name} id={table.id} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {mappedColumnsOptions && (
              <Select
                style={{
                  width: "250px",
                }}
                options={mappedColumnsOptions}
                onChange={(value: number) => handleAddColumn(value)}
                placeholder={
                  <FormattedMessage
                    id="apps.websites.containers.priceList.tabs.addColumn"
                    defaultMessage="Add Column"
                  />
                }
              />
            )}

            <StandardButton
              onClick={() => {
                newRow.call({
                  body: {
                    columns: "{}",
                    order: 0,
                    title: "",
                  },
                });
              }}
              icon={faPlus}
            >
              <FormattedMessage
                id="apps.websites.containers.priceList.tabs.addRow"
                defaultMessage="Add Row"
              />
            </StandardButton>
          </div>
        </div>

        <div className="price-table">
          <Rows table={table} getPriceLists={getPriceLists} />
          <Columns table={table} />
          <div
            className="price-grid"
            style={{
              gridTemplateRows: `repeat(${rows}, 55px)`,
            }}
          >
            <PriceRows table={table} />
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default PricingTable;