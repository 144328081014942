import { useEffect } from "react";
import FormattedMessage from "components/common/FormattedMessage";
import useAPI from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { TooltipIcon } from "components/common/tooltip-icon/TooltipIcon";
import { EstimateSelector, GetEstimate } from "modules/smart/smartSlice";

export type EstimateDisplayProps = {
    platform?: string;
}


export default function EstimateDisplay(props: EstimateDisplayProps) {

    const estimate = useSelectorWithParams(EstimateSelector);
    const estimateCall = useAPI(GetEstimate());

    useEffect(() => {
        if (!estimate) estimateCall.call();
    }, []);

    return (
        <div style={{ display: "flex", alignItems: "center"}}>
            <div className="standard-border" style={{ width: "98.25%" }}>
                <div style={{ fontSize: "20px", textAlign: "center" }}>
                    <FormattedMessage
                        id="components.forms.inputs.keyword.potentialCustomers"
                        defaultMessage="Potential customers"
                    />
                    {":   "}
                    <span className="number">{(estimate && estimate.estimate_mau) || "-"}</span>
                </div>
            </div>
            <div style={{marginBottom:"12px",marginLeft:"5px"}}>
                <TooltipIcon title={
                    <FormattedMessage
                        id="components.forms.inputs.keyword.potentialCustomers.tooltip"
                        defaultMessage="Potential customers"
                    />
                } />
            </div>
        </div>
    )
}