import { createSelector, createSlice } from "@reduxjs/toolkit";
import { callAPIProps } from "services/hooks/useAPI";

const TIRES_CATEGORY_ID = 51;

const initialState = {
  brands: [],
  manufacturers: {
    tires: [] as any[],
    rest: [] as any[],
  },
  categories: {}
}

export const CarsSlice = createSlice({
  name: "cars",
  initialState,
  reducers: {
    getBrands: (state, action) => {
      state.brands = action.payload.data.brands.data
    },
    getManufacturers: (state, action) => {
      const manufacturerList = action.payload.data.manufacturers.data;

      manufacturerList.forEach((manufacturer: any) => {
        const targetArray = manufacturer.categories.includes(TIRES_CATEGORY_ID) ?
          state.manufacturers.tires :
          state.manufacturers.rest;

        const isAlreadyAdded = targetArray.some(
          (existingManufacturer) => existingManufacturer.id === manufacturer.id
        );

        if (!isAlreadyAdded) {
          targetArray.push(manufacturer);
        }
      });
    },
    getCategories: (state, action) => {
      state.categories = action.payload.data.categories.data;
    }
  }
})

export const GetBrands: callAPIProps = {
  url: ({ getApiUrl }) => getApiUrl(`content/brands/${import.meta.env.VITE_WEBSITE_INDUSTRY_ID}`, 1),
  method: "GET",
  successDispatch: CarsSlice.actions.getBrands,
  customNormalizer: (data: any) => data
}

export const GetManufacturers: callAPIProps ={
  url: ({ getApiUrl }) => getApiUrl(`content/manufacturers/${import.meta.env.VITE_WEBSITE_INDUSTRY_ID}`, 1),
  method: "GET",
  successDispatch: CarsSlice.actions.getManufacturers,
  customNormalizer: (data: any) => data
}

export const GetCategories:callAPIProps = {
  url: ({ getApiUrl }) => getApiUrl(`content/categories/pl/${import.meta.env.VITE_WEBSITE_INDUSTRY_ID}`, 1),
  method: "GET",
  successDispatch: CarsSlice.actions.getCategories,
  customNormalizer: (data: any) => data
}

export const CarserviceBrandsDefinitionsSelector = createSelector(
  [(state: any) => state.siteGenerator.cars.brands || []],
  (brands: any) => brands
);

export const CarserviceManufacturersRestDefinitionsSelector = createSelector(
  [(state: any) => state.siteGenerator.cars.manufacturers || {}],
  (manufacturers: any) => manufacturers?.rest || []
);

export const ManufacturersRestSelectorIDs = createSelector(
  [(state: any) => state.siteGenerator.cars.manufacturers || {}],
  (manufacturers: any) => manufacturers?.rest.map((manufacturer: any) => manufacturer.id) || []
);

export const CarserviceManufacturersTiresDefinitionsSelector = createSelector(
  [(state: any) => state.siteGenerator.cars.manufacturers || {}],
  (manufacturers: any) => manufacturers?.tires || []
);

export const ManufacturersTiresSelectorIDs = createSelector(
  [(state: any) => state.siteGenerator.cars.manufacturers || {}],
  (manufacturers: any) => manufacturers?.tires.map((manufacturer: any) => manufacturer.id) || []
);

export const CategoriesSelector = createSelector(
  [(state: any) => state.siteGenerator.cars.categories || {}],
  (categories: any) => categories
);