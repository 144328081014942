
import { Progress, Spin } from 'antd';
import { useEffect, useState } from 'react';
import "./progress-display.less";
import { faCheck, faHourglass } from '@fortawesome/pro-light-svg-icons';
import AwesomeIcon from 'components/common/AwesomeIcon';
import { Title } from 'components/common/StandardText';

const progressSteps = [
    {
        duration: 4000,
        title: "Rozpoczęto analizowanie grupy odbiorców",
        showProgress: true,
    },
    {
        duration: 4000,
        title: "Analizowanie grupy odbiorców pod względem demografii",
        showProgress: true,
    },
    {
        duration: 6000,
        title: "Identyfikacja obecnej grupy odbiorców",
        showProgress: true,
    },
    {
        duration: 4000,
        title: "Analiza potencjalnych celów optymalizacyjnych",
        showProgress: true,
    },
    {
        duration: 1000,
        title: "Wybór modelu optymalizacji",
        showProgress: false,
    },
    {
        duration: 2000,
        title: "Dopasowanie grupy odbiorców do budżetu klienta",
        showProgress: false,
    },
    {
        duration: 6000,
        title: "Analiza obrazu",
        showProgress: true,
    },
    {
        duration: 4000,
        title: "Modyfikacje grupy odbiorców",
        showProgress: true,
    },
    {
        duration: 1000,
        title: "Wybór miejsc wyświetlania reklam",
        showProgress: false,
    },
    {
        duration: 4000,
        title: "Budowa biblioteki wykluczeń w aplikacjach typu “click to play”",
        showProgress: true,
    },
    {
        duration: 1000,
        title: "Analiza danych z pixela i Conversion API",
        showProgress: false,
    },
    {
        duration: 1000,
        title: "Eksport grupy odbiorców do Facebook",
        showProgress: false,
    },
    {
        duration: 1000,
        title: "Importowanie kreacji reklamowej",
        showProgress: false,
    },
    {
        duration: 1000,
        title: "Tagowanie kreacji",
        showProgress: false,
    },
    {
        duration: 1000,
        title: "Eksport kreacji reklamowej do Facebook",
        showProgress: false,
    },
    {
        duration: 1000,
        title: "Ustawienia zdarzeń mikro konwersji",
        showProgress: false,
    },
    {
        duration: 1000,
        title: "Weryfikacja końcowa: Utworzono kampanie",
        showProgress: false,
    },
    {
        duration: 2000,
        title: "Weryfikacja końcowa: Utworzono grupę odbiorców",
        showProgress: false,
    },
    {
        duration: 2000,
        title: "Weryfikacja końcowa: Określono miejsca wyświetlania",
        showProgress: false,
    },
    {
        duration: 1000,
        title: "Weryfikacja końcowa: Utworzono Kreacje",
        showProgress: false,
    },
];


interface ProgressStep {
  duration: number;
  title: string;
  showProgress: boolean;
}

interface UseProgressProps {
  steps: ProgressStep[];
}

interface ProgressState {
  totalProgress: number;
  stepProgress: number;
  currentStep: ProgressStep | null;
  completedSteps: ProgressStep[];
}

const INTERVAL = 100;

const useProgress = ({ steps }: UseProgressProps) => {
  const [state, setState] = useState<ProgressState>({
    totalProgress: 0,
    stepProgress: 0,
    currentStep: null,
    completedSteps: [],
  });

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    let currentStepIndex = 0;
    let currentStepElapsed = 0;
    setState({
        totalProgress: 0,
        stepProgress: 0,
        currentStep: null,
        completedSteps: [],
    })

    const startNextStep = () => {
      if (currentStepIndex < steps.length) {
        const nextStep = steps[currentStepIndex];
        setState((prevState) => ({
          ...prevState,
          currentStep: nextStep,
          stepProgress: 0,
        }));
        currentStepElapsed = 0;
      } else {
        clearInterval(interval!);
        setState((prevState) => ({
            ...prevState,
            currentStep: null,
        }));
      }
    };

    const updateProgress = () => {
      const currentStep = steps[currentStepIndex];
      currentStepElapsed += INTERVAL;
      const stepProgress = Math.min(
        (currentStepElapsed / currentStep.duration) * INTERVAL,
        INTERVAL
      );
      const totalProgress = Math.min(
        ((currentStepIndex + (currentStepElapsed / currentStep.duration)) /
          steps.length) *
          INTERVAL,
          INTERVAL
      );

      if (currentStepElapsed >= currentStep.duration) {
        setState((prevState) => ({
          ...prevState,
          completedSteps: [...prevState.completedSteps, currentStep],
        }));
        currentStepIndex++;
        startNextStep();
      } else {
        setState((prevState) => ({
          ...prevState,
          stepProgress,
          totalProgress,
        }));
      }
    };

    startNextStep();
    interval = setInterval(updateProgress, INTERVAL);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [steps]);

  return state;
};


const ProgressDisplay = () => {

    const { totalProgress, currentStep, completedSteps, stepProgress} = useProgress({ steps: progressSteps });

    return (
      <div className='progress-display'>

            <ul className='steps'>
            {completedSteps.map((step, index) => (<li><AwesomeIcon icon={faCheck} style={{marginRight: "5px"}} color="#1890ff"/> {step.title}</li>))}
            {currentStep ? <li><AwesomeIcon icon={faHourglass} spin style={{marginRight: "5px"}} />{currentStep?.title}</li> : null}
            </ul>

            <div style={{
            display: "grid",
            placeItems: "center",
            width: "300px"
        }}>
        
        {currentStep ? <Progress key={currentStep.title} type="circle" percent={Math.floor(stepProgress)} width={200} /> : (
          <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
          }}>
            <Progress className="sucess-circle" type="circle" percent={100} width={200} strokeColor={"#1890ff"}/>
            <Title level={2} style={{textAlign: "center"}}>100% Sukces!</Title>
            </div>
        )}
        </div>

      </div>
    )
  
  }

  export default ProgressDisplay;