import React from "react";
import { parsePhoneNumberFromString } from "libphonenumber-js/min";
import memoize from "lodash/memoize";
import { TokenLocalStorage } from "services/helpers/local-storage";
import FormattedMessage from "components/common/FormattedMessage";
import { getApiUrl } from "./api-helpers";

const VALID_URL_REGEXP =
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;

const phoneNumberValidator = (rule, value, callback) => {
  if (!value) {
    callback();
  } else {
    let valid = false;
    const phoneNumber = parsePhoneNumberFromString(value);
    if (phoneNumber) valid = phoneNumber.isValid();
    valid ? callback() : callback(valid);
  }
};

const onlyOneHashCharacter = (rule, value, callback) => {
  if (!value.length) {
    callback();
  } else {
    const regexp = new RegExp("#", "g");
    let count = (value.match(regexp) || []).length;
    const hashOnlyAsFirstLetter = new RegExp(
      "^#[A-Za-z0-9].*|^[A-Za-z0-9]((?!#).)*$"
    );
    let hashPosition = value.match(hashOnlyAsFirstLetter);
    count <= 1 && hashPosition !== null ? callback() : callback(false);
  }
};

export const formItemLayout = {
  colon: false,
};

export const rowLayout = {
  gutter: 30,
};

export const requiredFieldRule = {
  required: true,
  message: (
    <FormattedMessage
      id="components.forms.validation.required"
      defaultMessage="This field is required"
    />
  ),
};

export const onlyNumbersRule = {
  pattern: new RegExp("^[0-9]*$"),
  message: (
    <FormattedMessage
      id="services.formsValidators.onlyNumbers"
      defaultMessage="Only numbers are valid"
    />
  ),
};

export const minLengthRule = memoize((min) => {
  return {
    min,
    message: `${(
      <FormattedMessage
        id="components.forms.validation.length.min"
        defaultMessage="min length is"
      />
    )} ${min}`,
  };
});

export const maxLengthRule = memoize((max, message) => {
  return {
    max,
    message: `${
      message || (
        <FormattedMessage
          id="components.forms.validation.length.max"
          defaultMessage="max length is"
        />
      )
    } ${max}`,
  };
});

//TODO: renamte to immutable..
export const parseStateToFormFieldsValues = memoize((state) => {
  let formValues = {};
  state.keySeq().forEach((key) => {
    let value = state.get(key);
    if (value) {
      formValues[key] = {
        value: value,
      };
    }
  });
  return formValues;
});

export const parseObjectToValues = (object) => {
  let formValues = {};

  for (const key in object) {
    let value = object[key];

    if (value) {
      formValues[key] = { value };
    }
  }

  return formValues;
};

//todo: DELETE ME WHEN REFACTOR DONE
export const parseUrlToFileListItem = (url, name) => {
  return {
    uid: Date.now(),
    name: `${name}`,
    status: "done",
    url: url,
  };
};

export const isValidTimestamp = (timestamp) =>
  Number.isInteger(timestamp) && new Date(timestamp).getTime() > 0;

export const compareToFirstPasswordRule = (form, firstPasswordFieldName) => {
  return {
    validator: (rule, value, callback) => {
      if (value && value !== form.getFieldValue(firstPasswordFieldName)) {
        callback(
          <FormattedMessage
            id="services.formsValidators.inconsistentPasswords"
            defaultMessage="'Two passwords that you enter is inconsistent!'"
          />
        );
      } else {
        callback();
      }
    },
  };
};

export const validateToNextPasswordRule = (form, nextPasswordFieldName) => {
  return {
    validator: (rule, value, callback) => {
      if (value) {
        form.validateFields([nextPasswordFieldName], { force: true });
      }
      callback();
    },
  };
};

let checkSSLRequest = true;
export const validateSSLCert = (form, fieldName) => {
  let _value = "";
  return {
    validator: (rule, value, callback) => {
      _value = value;

      if (!value) callback();

      try {
        const checkHttps = value.match(/(https:\/\/)/);
        const checkUrl = value.match(VALID_URL_REGEXP);

        if (checkHttps && checkUrl && checkSSLRequest) {
          checkSSLRequest = false;
          return fetch(getApiUrl(`url/check-ssl?url=${_value}`), {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${TokenLocalStorage.get().token}`,
            },
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.ssl) {
                callback();
                checkSSLRequest = true;
              } else {
                setTimeout(() => {
                  form.setFields({
                    [fieldName]: {
                      value: _value.replace("https", "http"),
                    },
                  });
                  checkSSLRequest = true;
                }, 4000);
                callback("ssl");
              }
            });
        } else {
          callback();
        }
      } catch (err) {
        callback("ssl");
      }
    },
    message: (
      <FormattedMessage
        id="components.forms.validation.website.noAvailable"
        defaultMessage="SSL certificate is missin"
      />
    ),
  };
};

const getDecoratorOptions = (fieldType) => {
  let options = {};
  let rules = [];

  switch (fieldType) {
    case "email":
      rules.push({
        type: "email",
        message: (
          <FormattedMessage
            id="components.forms.validation.email.notValid"
            defaultMessage="The input is not valid E-mail!"
          />
        ),
      });
      break;

    case "website":
      rules.push(
        {
          pattern: new RegExp(VALID_URL_REGEXP),
          message: (
            <FormattedMessage
              id="components.forms.validation.website.notValid"
              defaultMessage="Please enter a valid domain, with http:// or https://"
            />
          ),
        },
        maxLengthRule(512)
      );
      break;

    case "phoneNumber":
      rules.push({
        validator: phoneNumberValidator,
        message: (
          <FormattedMessage
            id="components.forms.validation.phoneNumber.notValid"
            defaultMessage="Input valid phone number i.e. +48 793 019 999 or +48 58 671 91 83. Remember to use prefix."
          />
        ),
      });
      break;

    case "password":
      rules.push(
        {
          pattern: new RegExp("^(?=.{8,}$).*$"),
          message: (
            <FormattedMessage
              id="services.formsValidators.minCharacters"
              defaultMessage="min. 8 characters,"
            />
          ),
        },
        {
          pattern: new RegExp("^(?=.*[A-Z]).*$"),
          message: (
            <FormattedMessage
              id="services.formsValidators.minUppercase"
              defaultMessage="min. 1 uppercase character"
            />
          ),
        },

        {
          pattern: new RegExp("^(?=.*[a-z]).*$"),
          message: (
            <FormattedMessage
              id="services.formsValidators.minLowercase"
              defaultMessage="min. 1 lowercase character"
            />
          ),
        },
        {
          pattern: new RegExp("^(?=.*[0-9]).*$"),
          message: (
            <FormattedMessage
              id="services.formsValidators.minNumber"
              defaultMessage="min. 1 numerical character"
            />
          ),
        },
        {
          pattern: new RegExp("^(?=.*\\W).*$"),
          message: (
            <FormattedMessage
              id="services.formsValidators.minSpecial"
              defaultMessage="min. 1 special character"
            />
          ),
        }
      );
      break;

    case "nip":
      rules.push({
        message: (
          <FormattedMessage
            id="services.formsValidators.nip"
            defaultMessage="Input valid NIP number i.e. 588 195 01 23 or PL 588 195 01 23."
          />
        ),
        pattern: new RegExp("(PL)?[0-9]{10}"),
      });
      break;

    case "regon":
      rules.push({
        message: (
          <FormattedMessage
            id="services.formsValidators.regon"
            defaultMessage="Input valid REGON number i.e. 5881950329."
          />
        ),
        pattern: new RegExp("^\\d{9}$"),
      });
      break;

    case "zipCode":
      rules.push({
        message: (
          <FormattedMessage
            id="services.formsValidators.zip"
            defaultMessage="Input valid zip code i.e. 32-089."
          />
        ),
        pattern: new RegExp("\\d{2}-\\d{3}"),
      });
      break;

    case "streetNumber":
      rules.push(
        {
          message: (
            <FormattedMessage
              id="services.formsValidators.streetNumber"
              defaultMessage='Only numbers, letters and special characters "/", "-" can be used'
            />
          ),
          pattern: new RegExp("^[a-zA-Z0-9/.-]+$"),
        },
        maxLengthRule(7)
      );
      break;

    case "cardNumberCvv":
      rules.push(onlyNumbersRule, minLengthRule(3), maxLengthRule(4));
      break;

    case "cardNumberMonth":
      rules.push(onlyNumbersRule, minLengthRule(1), maxLengthRule(12));
      break;

    case "cardNumberYear":
      rules.push(onlyNumbersRule);
      break;

    case "hashOnlyAsFirstLetter":
      rules.push(
        {
          validator: onlyOneHashCharacter,
          message: (
            <FormattedMessage
              id="components.forms.validation.hash.onlyOneHashCharacter"
              defaultMessage="Only one # character is allowed and only at the beginning of the field."
            />
          ),
        },
        {
          message: (
            <FormattedMessage
              id="services.formsValidators.onlyLettersAndNumbers"
              defaultMessage="Only letters and numbers are allowed"
            />
          ),
          pattern: new RegExp("^#?[a-zA-Z0-9_]*$"),
        }
      );
      break;

    case "onlyLettersAndNumbers":
      rules.push({
        message: (
          <FormattedMessage
            id="services.formsValidators.onlyLettersAndNumbers"
            defaultMessage="Only letters and numbers are allowed"
          />
        ),
        pattern: new RegExp("^[a-zA-Z0-9_]*$"),
      });
      break;

    case "botMessage":
      rules.push(
        maxLengthRule(256)
      );
      break;

    default:
      if (import.meta.env.VITE_NODE_ENV !== "production") {
        console.error(
          `Decorator for "${fieldType}" does not exist. Are you sure you do not have any typos? Using this function to obtain required rule on single field name is gonna lead to bugs - result is cached. Just pass requiredFieldRule`
        );
      }
  }

  return {
    ...options,
    rules: rules,
  };
};

export const modifyDecorator = (fieldDecorator, rules = [], options = {}) => {
  return {
    ...fieldDecorator.options,
    ...options,
    rules: [...fieldDecorator.rules, ...rules],
  };
};

//faq - how to modify decorator (example withh comparing passwords)

export default memoize(getDecoratorOptions);

export const ruleNoDuplicates = (props) => ({ validator: (r, v)=> {
  if (props && props.list) {
    const field = props.field + "s";
    const values = props.form.instance.getFieldValue(field);
    let hasDuplicates = false;
    if (Array.isArray(values)) {
          values && values.forEach && values.forEach((v, i, a) => {
            const duplicates = values.filter((fv) => fv === v);
            if (duplicates.length > 1) hasDuplicates = true;
        })}
    if (hasDuplicates) {
      return Promise.reject(new Error("Duplicates are not allowed"));
    }
    return Promise.resolve();
  }
}})

export const ruleDuplicateDetection = {validator: (r, value) => {
  if (value) {
    return Promise.reject(new Error('Duplicates are not allowed'));
  } 
    return Promise.resolve();
}}