import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons";
import AwesomeIcon from "components/common/AwesomeIcon";
import { useMemo } from "react";
import { useCreationEditor } from "../CreationEditor";


export const ValidationResult = (props: any) => {
    return (
        <div className="validation-result error" style={{color: "red"}}>
            <AwesomeIcon icon={faExclamationCircle} />{props.result.detail ? props.result.detail : props.result}
        </div>
    )
}

type ValidationResultsProps = {
    warningOnly?: boolean
}

export default function ValidationResults (props: ValidationResultsProps) {

    const {creation, validationResults} = useCreationEditor();

    const mappedResults = useMemo(() => {
        if (!validationResults) return null;
        if (!creation) return null;

        if (props.warningOnly && Object.entries(validationResults).length > 0) {

            return (
                <span>There are errors in your creation.</span>
            )

        }

        return Object.entries(validationResults).map(([key, res]:any) => {
            return <ValidationResult key={key} name={key} result={res}/>
        })
    }, [validationResults])


    return (
        <div className="validation-results">
            {mappedResults}
        </div>
    )

}