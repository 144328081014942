import StandardButton from "components/common/StandardButton";
import Markdown from "components/markdown/Markdown";
import { useIntl } from "react-intl";
import { isValidHelpKey, useHelpPanel } from "./HelpPanelDrawer";
import { useMemo } from "react";


const LinkWrapper = (props:any) => {
    
    const { href, children } = props;
    const {openHelpPanel} = useHelpPanel();

    const isHelpLink = isValidHelpKey(href);  

    if (isHelpLink) {
        return (
            <StandardButton
            type="link"
            onClick={() => {
              openHelpPanel(href)
            }}
          >{children}</StandardButton>
        )
    }
    
    const isEmbeddedVideo = href.includes("youtube.com/embed/") || href.includes("youtu.be") || href.includes("youtube.com");

    if (isEmbeddedVideo) {
        
        const videoUrl = href.split("/").pop();
        
        return (
            <iframe
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/${videoUrl}`}
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                allowfullscreen
            />
        )
    }

    return (
        <a href={href} target="_blank" rel="noreferrer noopener">{children}</a>
    )

}


const HelpPanelDocument = (props:any) => {

    const {intl} = useHelpPanel();
    const debug = (window as any).translationDebug;

    const text = useMemo(() => {
        if (!props.pageId) return null;
        if (!intl) return null;
            return intl.formatMessage({id: props.pageId, defaultMessage: debug === true ? props.pageId : " "});
    }, [props.pageId, intl])

    if (props.center) {
        return (
            <div style={{
                width: "100%",
                height: "100%",
                display: "grid",
                placeItems: "center",
            }}
            >
                <div>
                    <Markdown
                    text={text}
                    components={{a: LinkWrapper}}
                    />
                </div>
            </div>
        )
    }

    return (
        <Markdown
        text={text}
        components={{a: LinkWrapper}}
        />
    )

}

export default HelpPanelDocument;