import React, { Component, useContext } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { userIsLoggedSelector } from "modules/panel/config/selectors/user";
import { createLoadingSelector } from "modules/panel/config/selectors/request-status";
import isEqual from "lodash/isEqual";
import { logout, LOGOUT } from "modules/panel/actions/user/login";
import { USER_DELETION_PAGE, getUrl } from "modules/panel/config/routes";
import { currentProjectIdSelector, currentSubscriptionIdSelector } from "services/store/scopeSlice";
import { currentProjectOnboarded, panelProjectsSelector } from "state/projects/projectSelectors";


const OnboardingContext = React.createContext();
const { Provider, Consumer } = OnboardingContext;
export const useOnboarding = () => useContext(OnboardingContext);

export class OnboardingProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: this.getInitialCurrentStep(),
      requirementsFulfilled: true,
      onboardingCompleted: true,
      direction: "forward",
    };
  }


  shouldComponentUpdate(nextProps, nextState) {




    return (
      nextProps.isLogged &&
      (!isEqual(
        nextProps.fulfilledRequirements,
        this.props.fulfilledRequirements
      ) ||
        !isEqual(nextProps.appRequirements, this.props.appRequirements) ||
        nextProps.isLogged !== this.props.isLogged ||
        nextState.currentStep !== this.state.currentStep ||
        nextState.onboardingCompleted !== this.state.onboardingCompleted ||
        nextState.requirementsFulfilled !== this.state.requirementsFulfilled ||
        nextProps.currentProject !== this.props.currentProject ||
        nextProps.currentSubscriptionId !== this.props.currentSubscriptionId ||
        (this.state.currentStep.index !== null &&
          this.props.location.pathname !== this.state.currentStep.path))
    );
  }

  componentDidMount() {
    
    if (localStorage.getItem("userWantsToDeleteAccount") === "true") {
      this.setState({
        requirementsFulfilled: true,
        onboardingCompleted: true,
        direction: "forward",
      })
      this.props.history.push(getUrl(USER_DELETION_PAGE));
      return;
    }
    
    if (this.props.isLogged) this.start();

  }

  componentDidUpdate(prevProps, prevState) {


    if (!prevState.onboardingCompleted && this.state.onboardingCompleted) {
      this.close();
    }

    if (
      (!prevProps.isLogged && this.props.isLogged) ||
      prevProps.currentProject !== this.props.currentProject ||
      prevProps.currentSubscriptionId !== this.props.currentSubscriptionId
    )
      this.start();

    if (
      !isEqual(
        prevProps.fulfilledRequirements,
        this.props.fulfilledRequirements
      )
    ) {
      this.onFulfillChange();
    }

    if (
      this.state.currentStep.index !== null &&
      (prevState.currentStep !== this.state.currentStep ||
        prevProps.location.pathname !== this.props.location.pathname ||
        this.props.history.action === "POP")
    ) {
      this.redirectToCurrentStep();
      document.documentElement.scrollTop = 0;
    }

    if (prevProps.logoutPending && !this.props.logoutPending) this.resetState();
  }

  start = () => {
    let firstUnfulfilledStep = this.getFirstUnfulfilledRequirmentIndex();

    let requirementsFulfilled = firstUnfulfilledStep === false;

    let state = {
      requirementsFulfilled: requirementsFulfilled,
      onboardingCompleted: requirementsFulfilled,
      direction: "forward",
    };

    if (firstUnfulfilledStep !== false)
      state.currentStep = this.getStepObject(firstUnfulfilledStep);

    this.setState(state);
  };

  close = () => {
    this.resetState();
    this.props.history.push(this.props.appIndexRoute);
  };

  onFulfillChange = () =>
    this.setState({
      requirementsFulfilled:
        this.getFirstUnfulfilledRequirmentIndex() === false,
    });

  getFirstUnfulfilledRequirmentIndex = () => {
    for (
      let stepIndex = 0;
      stepIndex < this.props.appRequirements.length;
      stepIndex++
    ) {
      if (!this.isStepFulfilled(stepIndex)) return stepIndex;
    }

    return false;
  };

  nextStep = (reason) => {

    let nextStepObject = this.getNextStepObject();

    nextStepObject
      ? this.setState({
          currentStep: nextStepObject,
          direction: "forward",
        })
      : this.setState({
          onboardingCompleted: true,
          direction: "forward",
        });
  };

  getNextStepObject = () => {


    let { index, subIndex } = this.state.currentStep;
    
    if (subIndex !== false && this.getSubPathsLastIndex(index) > subIndex) {
      return this.getStepObject(index, subIndex + 1);
    }

    if (this.getRequirmentsLastIndex() === index) return false;

    const nextAvailableStep = this.getFirstNextAvailableStep(index);


    if (nextAvailableStep === false) return false;

    return this.getStepObject(nextAvailableStep);
  };

  getFirstNextAvailableStep = (stepIndex) => {


    // eslint-disable-next-line
    for (let i = stepIndex + 1; i <= this.getRequirmentsLastIndex(); i++) {

      if (this.props.appRequirements[i].forceDisplayOnOnboardedProjects) return i;

      if (
        this.props.projectOnboarded
          ? !this.isStepFulfilled(i)
          : !this.props.appRequirements[i].omitWhenFulfilled ||
            !this.isStepFulfilled(i)
      ) {
        return i;
      }
    }

    return false;
  };

  previousStep = () =>
    this.setState({
      currentStep: this.getPreviousStepObject(),
      direction: "backward"
    });

  getPreviousStepObject = () => {
    let { index, subIndex } = this.state.currentStep;

    if (subIndex !== false && subIndex > 0) {
      return this.getStepObject(index, subIndex - 1);
    }

    let firstPreviousAvailableStep = this.getFirstPreviousAvailableStep(index);
    if (this.hasSubs(firstPreviousAvailableStep))
      return this.getStepObject(
        firstPreviousAvailableStep,
        this.getSubPathsLastIndex(firstPreviousAvailableStep)
      );

    if (Number.isInteger(firstPreviousAvailableStep))
      return this.getStepObject(firstPreviousAvailableStep);
  };

  hasSubs = (step) => Array.isArray(this.props.appRequirements[step].path);

  getStepObject = (stepIndex, subStepIndex = false) => {
    if (this.hasSubs(stepIndex) && !subStepIndex) subStepIndex = 0;

    return {
      index: stepIndex,
      subIndex: subStepIndex,
      fulfilled: this.isStepFulfilled(stepIndex),
      path: this.getStepPathObject(stepIndex, subStepIndex),
      controls: {
        isLastStep: this.isLastStep(stepIndex, subStepIndex),
        availablePreviousBtn: this.hasAvailablePreviousStep(
          stepIndex,
          subStepIndex
        ),
      },
    };
  };

  isStepFulfilled = (stepIndex) => {
    let key = this.props.appRequirements[stepIndex].key;
    return this.props.fulfilledRequirements[key];
  };

  getStepPathObject = (stepIndex, subStepIndex = false) => {
    let path = this.getRequirementPath(stepIndex);
    if (subStepIndex !== false) path = path[subStepIndex];
    return path();
  };


  isLastStep = (stepIndex, subStepIndex = false) => {
    let isLastStep = stepIndex === this.getRequirmentsLastIndex();

    if (isLastStep && Number.isInteger(subStepIndex)) {
      isLastStep = subStepIndex === this.getSubPathsLastIndex(stepIndex);
    }

    return isLastStep;
  };

  getRequirmentsLastIndex = () => this.props.appRequirements.length - 1;

  getSubPathsLastIndex = (stepIndex) =>
    this.getRequirementPath(stepIndex).length - 1;

  getRequirementPath = (stepIndex) =>
    this.props.appRequirements[stepIndex].path;

  hasAvailablePreviousStep = (stepIndex, subStepIndex = false) => {
    if (subStepIndex > 0) return true;

    return this.getFirstPreviousAvailableStep(stepIndex) !== false;
  };

  getFirstPreviousAvailableStep = (stepIndex) => {
    for (let i = stepIndex - 1; i >= 0; i--) {
      if (!this.props.appRequirements[i].omitWhenFulfilled)
        return i;
    }

    return false;
  };

  redirectToCurrentStep = () => {
    let redirectToPath = this.state.currentStep.path;
    if (redirectToPath !== this.props.location.pathname) {
      this.props.history.push(redirectToPath);
    }
  };

  resetState = () => {
    this.setState({
      currentStep: this.getInitialCurrentStep(),
      requirementsFulfilled: true,
      onboardingCompleted: true,
      direction: "forward",
    });
  };

  getInitialCurrentStep = () => {
    return {
      index: null,
      subIndex: null,
      path: null,
      controls: {
        isLastStep: true,
        availablePreviousBtn: false,
      },
    };
  };

  changeProject = (projectId) => {
    //this.resetState();
    //this.props.setProject(projectId);
    //this.props.history.push(this.props.appIndexRoute);
  };

  render() {

    const a = {
      currentStep: this.state.currentStep,
      fulfilled: this.state.requirementsFulfilled,
      completed: this.state.onboardingCompleted,
      previousStep: this.previousStep,
      nextStep: this.nextStep,
      indexRoute: this.props.appIndexRoute,
      logout: this.props.logout,
      logoutPending: this.props.logoutPending,
      projects: {
        ...this.props.projects,
        set: this.changeProject,
      },
    }

    return (
      <Provider
        value={{
          currentStep: this.state.currentStep,
          fulfilled: this.state.requirementsFulfilled,
          completed: this.state.onboardingCompleted,
          previousStep: this.previousStep,
          nextStep: this.nextStep,
          indexRoute: this.props.appIndexRoute,
          logout: this.props.logout,
          logoutPending: this.props.logoutPending,
          direction: this.state.direction, // forward | backward
          projects: {
            ...this.props.projects,
            set: this.changeProject,
          },
        }}
      >
        {this.props.children}
      </Provider>
    );
  }
}

const mapStateToProps = (state, ownProps) =>
  registerCurrentAppAndPanelRequirments(ownProps, state);

const registerCurrentAppAndPanelRequirments = (ownProps, state) => {
  let { appRequirements } = ownProps;

  let currentProject = currentProjectIdSelector(state);
  let projects = panelProjectsSelector(state);
  let logoutPending = createLoadingSelector(LOGOUT)(state);
  let isLogged = userIsLoggedSelector(state);
  let currentSubscriptionId = currentSubscriptionIdSelector(state);
  let projectOnboarded = currentProjectOnboarded(state);

  let registeredSelectors = {
    isLogged,
    currentProject,
    currentSubscriptionId,
    projects,
    logoutPending,
    projectOnboarded,
    fulfilledRequirements: {},
  };

  if (isLogged)
    registeredSelectors.fulfilledRequirements = {
      ...registerSelectors(appRequirements, state),
    };

  return registeredSelectors;
};

const registerSelectors = (requirments, state) => {
  let selectors = {};
  requirments.forEach((requirment) => {
    selectors[requirment.key] = requirment.selector(state);
  });
  return selectors;
};

export default withRouter(
  connect(mapStateToProps, (dispatch) => {
    return {
      logout: () => {
        dispatch(logout());
      },
      // setProject: (projectId) => {
      //   dispatch(setCurrentProject(projectId));
      // },
    };
  })(OnboardingProvider)
);

export const OnboardingConsumer = Consumer;
