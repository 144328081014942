import { Col, Row } from "antd"
import FormattedMessage from "components/common/FormattedMessage"
import BudgetInput from "components/inputs/BudgetInput"
import { GoogleAdsSettingsSelector, HasGoogleBudgetFilledSelector, UpdateGoogleAdsSettings } from "modules/smart/smartSlice"
import ConnectedOnboardingControls from "providers/onboarding/controls"
import useDataPrefill from "services/hooks/useDataPrefill"
import useSelectorWithParams from "services/hooks/useSelectorWithParams"
import RelaxField from "tools/relaxForm/RelaxField"
import RelaxMultiform from "tools/relaxForm/RelaxMultiform"

export const OnboardingBudgetGoogle = () => {

    useDataPrefill({
        dataSelector: GoogleAdsSettingsSelector,
        callAPI: UpdateGoogleAdsSettings,
        checkFunction: (data: any) => {
            if (!data) return false;
            let p: any = {};

            if (!data?.display_ad_daily_budget) p.display_ad_daily_budget = 15;
            if (!data?.display_ad_period) p.display_ad_period = 7;
            if (!data?.search_ad_default_daily_budget) p.search_ad_default_daily_budget = 10;

            if (Object.getOwnPropertyNames(p).length === 0) return null;

            return p;
        }
    })

    const fulfilled = useSelectorWithParams(HasGoogleBudgetFilledSelector);
    const settings = useSelectorWithParams(GoogleAdsSettingsSelector);


    return (
        <>
            <RelaxMultiform
                callAPI={UpdateGoogleAdsSettings}
                hideStatus={true}
                initialValues={settings}>
                <Row gutter={30}>
                    <Col xs={24} md={12}>
                        <h2>
                            <FormattedMessage
                                id="apps.smart.forms.budget.google.displayAd"
                                defaultMessage="Display Ad"
                            />
                        </h2>

                        <RelaxField
                            name="display_ad_daily_budget"
                            label={
                                <FormattedMessage
                                    id="apps.smart.components.adsSettings.dailyBudgetLabel"
                                    defaultMessage="Your daily advertising budget"
                                />
                            }>
                            <BudgetInput
                                className="fullwidth"
                            />
                        </RelaxField>
                    </Col>
                    <Col xs={24} md={12}>
                        <h2>
                            <FormattedMessage
                                id="apps.smart.containers.adsSettings.view.searchAds"
                                defaultMessage="Search Ads"
                            />
                        </h2>

                        <RelaxField
                            name="search_ad_default_daily_budget"
                            label={
                                <FormattedMessage
                                    id="apps.smart.containers.adsSettings.view.searchAdsLabel"
                                    defaultMessage="Default daily budget per campaign"
                                />
                            }>
                            <BudgetInput
                                className="fullwidth"
                            />
                        </RelaxField>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} md={12} style={{ paddingRight: "30px" }}>

                        <RelaxField
                            name="display_ad_period"
                            label={
                                <FormattedMessage
                                    id="apps.smart.components.adsSettings.promotionPeriod"
                                    defaultMessage="Period of time to promote post"
                                />
                            }>
                            <BudgetInput
                                className="fullwidth"
                            />
                        </RelaxField>

                    </Col>
                </Row>


            </RelaxMultiform>

            <ConnectedOnboardingControls relaxDontSubmit = {true} nextDisabled={(!fulfilled)}/>
        </>
    )
}