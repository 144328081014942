import { faGear } from "@fortawesome/pro-light-svg-icons";
import { Dropdown } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import StandardButton from "components/common/StandardButton";



export default function InfinityListAdvancedSettings(props: any) {

    return (
        <Dropdown
            menu={{
                items: props.settings,
                className: "post-list-advanced-settings-dropdown"
            }}
            autoFocus={true}
            trigger={['click']}
        >
            <StandardButton icon={faGear} onClick={(e: any) => {
                e.preventDefault();
            }}>
                <FormattedMessage
                    id="posts.list.filtering.advancedSettings"
                    defaultMessage="Settings" />
            </StandardButton>
        </Dropdown>
    )


}