import { notification } from "antd";
import { NotificationInstance } from "antd/es/notification";
import { ReactNode, createContext, useContext } from "react";

type FeedbackContextType = {
    notifications: NotificationInstance
};

const FeedbackContext = createContext<FeedbackContextType>({});
FeedbackContext.displayName = "Feedback Context";
export const useFeedback = () => useContext(FeedbackContext);

const FeedbackContextProvider = (props: {children? : ReactNode}) => {

    const [antNotificationAPI, antNotificationContextHolder] = notification.useNotification();

    return (
        <FeedbackContext.Provider value={{
            notifications: antNotificationAPI,
        }}>
            {props.children}
            {antNotificationContextHolder}
        </FeedbackContext.Provider>
    )
}

export default FeedbackContextProvider;