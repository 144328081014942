import siteGeneratorRequirementsBase from "modules/site-generator/config/industries/requirements/requirementsBase";
import { ProductConfig } from "products";
import logo from "products/autosite/logoAutoSite.svg";


const autositeConfig: ProductConfig = {
  name: "Autosite",
  logo: logo,
  requirements: siteGeneratorRequirementsBase,
};

export default autositeConfig;
