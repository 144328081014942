import { useEffect, useMemo, useState } from "react"
import useSelectorWithParams from "services/hooks/useSelectorWithParams"
import { GetPaymentHistory, InvoiceSlice, PaymentHistorySelector, SortPaymentHistoryByDate } from "./InvoiceSlice"
import InfinityList from "tools/infinityList/infinityList"
import FormattedMessage from "components/common/FormattedMessage"
import useAPIWithIncrementsNoPages from "services/hooks/useAPIWithIncrementsNoPages"
import { Spin } from "antd"
import InfinityListQuery from "tools/infinityList/infinityListQuery"
import { InvoicesHeadings } from "./InvoiceHeadings"
import InvoiceListEntry from "./InvoiceListEntry"
import { HorizontalCenter } from "components/common/Center"
import { useDispatch } from "react-redux"

export const Invoices = () => {

  const [query, setQuery] = useState<any>();
  const paymentHistory = useSelectorWithParams([PaymentHistorySelector])
  const dispatch = useDispatch();

  const { startWithNewQuery, getNextIncrement, loading, emptyResponses } = useAPIWithIncrementsNoPages({
    callAPI: GetPaymentHistory,
    query: query
  })

  useEffect(() => {
    reloadList();
  }, [query]);

  const reloadList = () => {
    dispatch(InvoiceSlice.actions.clearInvoices({}))
    ListGetHistory();
  }

  const onQueryChange = (query: any) => {
    const normalizedQuery: any = {
      "date[start]": query.start.format("YYYY-MM-DD"),
      "date[end]": query.end.format("YYYY-MM-DD"),
      "sort": query.sort || "invoice_created",
    }

    reloadList();
    setQuery(normalizedQuery);
  }


  const GetMore = () => {
    getNextIncrement((q) => {
      if (!q) {
        return;
      }
      const oldQ = q;
      const previousFrom = new Date(oldQ["date[start]"]);
      const previousTo = new Date(oldQ["date[end]"]);

      let newFrom: Date;
      let newTo: Date;

      if (query?.sort === "invoice_created") {
        newFrom = new Date(previousTo.getTime());
        newTo = new Date(newFrom.getTime() + 1000 * 60 * 60 * 24 * 7);
      } else {
        newFrom = new Date(previousFrom.getTime() - 1000 * 60 * 60 * 24 * 7);
        newTo = new Date(previousFrom.getTime());
      }

      return {
        ...oldQ,
        "date[start]": newFrom.toISOString().split("T")[0],
        "date[end]": newTo.toISOString().split("T")[0]
      };
    })
  }

  const handleLoadNextPage = () => {
    if (emptyResponses < 3) GetMore();
  }

  const ListGetHistory = () => {
    if (!query) return;
    startWithNewQuery(query);
  }

  const sortedPaymentHistory = useMemo(() => {

    if (!paymentHistory) return [];

    const filteredPaymentHistory = paymentHistory;

    if (!query || !query.sort) return paymentHistory;
    if (query.sort === "invoice_created") {
      return SortPaymentHistoryByDate(filteredPaymentHistory, false);
    }
    if (query.sort === "-invoice_created") {
      return SortPaymentHistoryByDate(filteredPaymentHistory, true);
    }

    return filteredPaymentHistory;

  }, [paymentHistory])


  const renderItem = (item: any) => {
    return <InvoiceListEntry item={item} />;
  };


  const extra = useMemo(() => {

    if (paymentHistory && paymentHistory.length > 0 && loading) return (
      <HorizontalCenter style={{ padding: "30px" }} key="loading">
        <Spin />
      </HorizontalCenter>
    )
    if (emptyResponses >= 3) return (
      <HorizontalCenter style={{ padding: "30px" }} key="nothing">
        <h3>
          <FormattedMessage
            id="posts.list.error.nothingMore"
            defaultMessage="Nothing more to show. Please change the date range."
          />
        </h3>
      </HorizontalCenter>
    )
    return null
  }, [emptyResponses, loading])

  const queryInput = useMemo(() => {
    return (
      <InfinityListQuery
        onChange={onQueryChange}
        onReload={reloadList}
        sort={true}
        date={true}
        sortedBy={"invoice_created"}
        defaultRange={"year"}
      >
      </InfinityListQuery>
    )
  }, [query])

  return (
    <>
      <div className="infinity-wrapper">
        {queryInput}
        <InfinityList
          headers={InvoicesHeadings}
          items={sortedPaymentHistory}
          loading={loading}
          loadMoreData={handleLoadNextPage}
          itemSkeleton={() => "skeleton"}
          renderItem={renderItem}
          additionalItems={[
            extra
          ]}
        />
      </div >
    </>
  );
};