import FormattedMessage from "components/common/FormattedMessage";
import Tabs from "_components/tabs/tabs";
import ResidenceRules from "./residence-rules";
import TermsOfServiceEditor from "./terms-of-service";

export default function ResidenceRulesWithTabs () {


    return (
        <Tabs
            panes={[
                {
                    title: (
                      <FormattedMessage
                        id="apps.websites.forms.rules.residenceRules"
                        defaultMessage="Residence Rules"
                      />
                    ),
                    key: "residenceRules",
                    content: <ResidenceRules />,
                  },
                {
                title: (
                    <FormattedMessage
                    id="apps.websites.forms.rules.termsOfService"
                    defaultMessage="Terms of Serivce"
                    />
                ),
                key: "termsOfService",
                content: <TermsOfServiceEditor />,
                },
            ]}
        />
    )

}