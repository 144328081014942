import React from "react";
import FormattedMessage from "components/common/FormattedMessage";

const ErrorPage = ({ title, subtitle, buttons, url = "", backgroundText }) => {
    return <div className="error-page">
        <div className="container">
            <div className="header" />
            <div className="centered">
                <span className="oops">
                    <FormattedMessage
                        id="panel.pages.error.ops"
                        defaultMessage="Oops"
                    />
                </span>
                <h1 className="title">{title}</h1>
                <p className="subtitle">{subtitle}</p>
                {buttons}
            </div>
            <div className="footer">
                <div className="links">
                    <a href={import.meta.env.VITE_INSTANCE_SUPPORT_URL}>
                        <FormattedMessage
                            id="panel.pages.error.supportCenter"
                            defaultMessage="Support center"
                        />
                    </a>
                    <a href={`mailto:${import.meta.env.VITE_INSTANCE_SUPPORT_EMAIL}`}>
                        <FormattedMessage
                            id="panel.pages.error.reportError"
                            defaultMessage="Report error"
                        />
                    </a>
                    <a href={import.meta.env.VITE_INSTANCE_LANDING_URL}>
                        <FormattedMessage
                            id="panel.pages.error.homepage"
                            defaultMessage="Homepage"
                        />
                    </a>
                </div>
                <div className="url-container">
                    <span className="url">{url}</span>
                </div>
            </div>
        </div>
        <div className="background-text">
            <span className="text">{backgroundText}</span>
        </div>
    </div>;
};

export default ErrorPage;