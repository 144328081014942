import { usePost } from "../postContext";

export default function PostCard () {

    const { post } = usePost();

    if (!post) return null;

    return (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: "20px" }}>
        <img src={post.image} style={{ objectFit: "cover", height: "70px", width: "70px", borderRadius: "10px" }} />
        {post.getShortenedDescription()}
    </div>
    )
}