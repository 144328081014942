import useDataPrefill from "services/hooks/useDataPrefill";
import { useCreationEditor } from "../CreationEditor";
import { creationContentSelector, patchCreation } from "../creationsSlice";
import EditorSectionsCollapse from "../editorSections/EditorSectionsCollapse";
import { EditorSectionBudget, EditorSectionCTAWithMultipleLinks, EditorSectionEvents, EditorSectionListOfBodies, EditorSectionListOfDescriptions, EditorSectionListOfMedia, EditorSectionListOfTitles, EditorSectionName, EditorSectionPublication } from "../editorSections/EditorSections";


export const EditorInstagramAoA = (props: any) => {

    const { creation, type, subtype, creationId } = useCreationEditor();
    const { call } = useDataPrefill({
        dataSelector: (state: any) => creationContentSelector(state, creationId),
        callAPI: patchCreation(creationId),
        checkFunction: (data: any) => {
            if (!data) return false;
            const md: any = {};
            if (!data.provider) md.provider = ["facebook"];

            if (Object.getOwnPropertyNames(md).length > 0) return { content: md };
            return false;
        }
    })

    return (
        <div>
            <EditorSectionsCollapse>
                <EditorSectionName />
                <EditorSectionListOfMedia />
                <EditorSectionListOfBodies />
                <EditorSectionListOfTitles />
                <EditorSectionListOfDescriptions />
                <EditorSectionCTAWithMultipleLinks actions={['SUBSCRIBE', 'LEARN_MORE', 'SIGN_UP', 'DOWNLOAD', 'SHOP_NOW', 'BOOK_TRAVEL']} />
                <EditorSectionEvents />
                <EditorSectionBudget />
                <EditorSectionPublication />
            </EditorSectionsCollapse>
        </div>
    )

}


export default EditorInstagramAoA;