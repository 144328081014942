import React from "react";
import { useEffect, useState } from "react";

type WindowSize = {
    width: number;
    height: number;
    isMobile: boolean;
}

const DESKTOP_WIDTH = 992;

export default function useWindowSize(): WindowSize {

    const [size, setSize] = useState<WindowSize>({width: window.innerWidth, height: window.innerHeight, isMobile: window.innerWidth < DESKTOP_WIDTH});

    const handleResize = () => {
        if (size) {
            if (size.width === window.innerWidth && size.height === window.innerHeight) {
                return;
            }
        }
        setSize({
            width: window.innerWidth,
            height: window.innerHeight,
            isMobile: window.innerWidth < DESKTOP_WIDTH,
        });
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return size;

}


const WindowSizeContext = React.createContext<WindowSize>({width: 0, height: 0, isMobile: false});
WindowSizeContext.displayName = "WindowSizeContext";
export const WindowSizeProvider = (props:any) => {
    const size = useWindowSize();
    return (
        <WindowSizeContext.Provider value={size}>
            {props.children}
        </WindowSizeContext.Provider>
    );
}

export const WindowSizeConsumer = WindowSizeContext.Consumer;