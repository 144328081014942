import logoFacebook from "/providers/Facebook.svg";
import logoGoogle from "/providers/Google.svg";
import logoInstagram from "/providers/Instagram.svg";
import "./icons.less"

type Platform = "facebook" | "google" | "instagram";


const platformIcons:Record<Platform, string> = {
    "facebook": logoFacebook,
    "google": logoGoogle,
    "instagram": logoInstagram
}

type PlatformIconProps = {
    platform: Platform,
    size?: "small" | "medium" | "large"
}

const PlatformIcon = (props: PlatformIconProps) => {

    const icon = platformIcons[props.platform];

    return <img src={icon} alt={props.platform} className={`platform-icon ${props.size ? props.size : ""}`}/>

}

export default PlatformIcon;