import { SubscriptionContext } from "providers/subscription/subscription";
import ProductLogo from "providers/routing/layouts/components/product-logo/product-logo";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { getProductLogo } from "./smallProductLogo";
import { currentProductSelector } from "services/store/scopeSlice";

type Props = {
  subscription: SubscriptionContext;
  hideTitle: boolean;
};

const CurrentProductLogo = ({ hideTitle, ...props }: Props) => {

  const product = useSelectorWithParams(currentProductSelector)

  return <ProductLogo hideTitle={hideTitle} logo={getProductLogo(product)} {...props}  />
};

export default CurrentProductLogo;
