import React from "react";
import Icon from '@ant-design/icons';

export const Like = () => (
  <svg
    id="Group_1841"
    data-name="Group 1841"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <defs>
      <linearGradient
        id="linear-gradient-like"
        x1="0.5"
        y1="0.079"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#18afff" />
        <stop offset="1" stopColor="#0062df" />
      </linearGradient>
    </defs>
    <path
      id="Path_8876"
      data-name="Path 8876"
      d="M10,0a10,10,0,1,0,7.071,2.929A10,10,0,0,0,10,0Z"
      transform="translate(0 0)"
      fill="url(#linear-gradient-like)"
    />
    <path
      id="Path_8877"
      data-name="Path 8877"
      d="M13.732,8.165a.811.811,0,0,1,.4.8.872.872,0,0,1-.555.863.882.882,0,0,1,.1.65,1.178,1.178,0,0,1-.787.822.641.641,0,0,1,.018.738c-.217.351-.405.485-1.239.485h-3.4c-1.157,0-1.757-.65-1.757-1.191V8.554c0-1.464,1.718-2.709,1.718-3.727L8.108,3.56a.544.544,0,0,1,.068-.321A1.1,1.1,0,0,1,8.92,3a1.3,1.3,0,0,1,.625.146A1.941,1.941,0,0,1,10.4,4.982a16.82,16.82,0,0,1-.551,1.624,11.6,11.6,0,0,1,2.2-.237c1.243-.007,2.049.226,2.049,1a1.6,1.6,0,0,1-.37.793ZM3.7,7.762H4.64a.694.694,0,0,1,.5.21.717.717,0,0,1,.206.5V13a.717.717,0,0,1-.206.5.694.694,0,0,1-.5.21H3.7a.694.694,0,0,1-.5-.21A.717.717,0,0,1,3,13V8.477a.717.717,0,0,1,.206-.5.694.694,0,0,1,.5-.21Z"
      transform="translate(1.186 1.642)"
      fill="#fff"
    />
  </svg>
);

export const LikeIcon = (props) => <Icon component={Like} {...props} />;

export const Love = () => (
  <svg
    id="Group_1842"
    data-name="Group 1842"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <defs>
      <linearGradient
        id="linear-gradient-love"
        x1="0.5"
        y1="0.049"
        x2="0.542"
        y2="1.059"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#ff6680" />
        <stop offset="1" stopColor="#e61739" />
      </linearGradient>
    </defs>
    <path
      id="Path_8866"
      data-name="Path 8866"
      d="M10,0a10,10,0,1,0,7.071,2.929A10,10,0,0,0,10,0Z"
      transform="translate(0 0)"
      fill="url(#linear-gradient-love)"
    />
    <path
      id="Path_8867"
      data-name="Path 8867"
      d="M12.341,4A2.881,2.881,0,0,0,9.25,6.28,2.879,2.879,0,0,0,6.16,4C3.517,4,2.747,6.778,3.07,8.262c.85,3.925,6.18,6.675,6.18,6.675s5.331-2.75,6.181-6.675C15.752,6.778,14.981,4,12.341,4Z"
      transform="translate(0.75 1)"
      fill="#fff"
    />
  </svg>
);

export const LoveIcon = (props) => <Icon component={Love} {...props} />;

export const Haha = () => (
  <svg
    id="Group_1844"
    data-name="Group 1844"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <defs>
      <linearGradient
        id="linear-gradient-haha"
        x1="0.5"
        x2="0.5"
        y2="1.1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#feea70" />
        <stop offset="1" stopColor="#f69b30" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2-haha"
        x1="0.5"
        y1="0.571"
        x2="0.5"
        y2="-0.429"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#472315" />
        <stop offset="1" stopColor="#8b3a0e" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-3-haha"
        x1="0.5"
        y1="-1.333"
        x2="0.5"
        y2="-2.152"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#fc607c" />
        <stop offset="1" stopColor="#d91f3a" />
      </linearGradient>
    </defs>
    <path
      id="Path_8878"
      data-name="Path 8878"
      d="M20,10a10,10,0,1,1-2.929-7.071A10,10,0,0,1,20,10"
      transform="translate(0 0)"
      fill="url(#linear-gradient-haha)"
    />
    <path
      id="Path_8879"
      data-name="Path 8879"
      d="M3,8.26c0,2.519,1.258,7.49,6.25,7.49s6.25-4.971,6.25-7.49c0-.2-2.012-1.26-6.25-1.26S3,8.061,3,8.26Z"
      transform="translate(0.75 1.75)"
      fill="url(#linear-gradient-2-haha)"
    />
    <path
      id="Path_8880"
      data-name="Path 8880"
      d="M4.541,12.875A5.171,5.171,0,0,0,8.877,14.75,5.145,5.145,0,0,0,13.2,12.875,5.646,5.646,0,0,0,8.877,11,5.68,5.68,0,0,0,4.541,12.875Z"
      transform="translate(1.135 2.75)"
      fill="url(#linear-gradient-3-haha)"
    />
    <path
      id="Path_8881"
      data-name="Path 8881"
      d="M7.016,4.43a.95.95,0,0,1,.513.985c-.089.318-.243.461-.528.464a8.426,8.426,0,0,0-3.133.765,1.006,1.006,0,0,1-.415.106.434.434,0,0,1-.409-.3c-.084-.2-.091-.485.345-.747a7.357,7.357,0,0,1,2.386-.755,8.788,8.788,0,0,0-1.644-.96c-.534-.243-.475-.571-.4-.75.159-.4.761-.245,1.347.033A11.183,11.183,0,0,1,7.016,4.43Zm4.471,0a11.115,11.115,0,0,1,1.938-1.156c.588-.278,1.188-.429,1.347-.033.071.179.13.507-.4.75a8.808,8.808,0,0,0-1.641.96,7.322,7.322,0,0,1,2.384.755c.436.262.427.55.345.747a.433.433,0,0,1-.409.3,1.006,1.006,0,0,1-.415-.106,8.438,8.438,0,0,0-3.13-.765c-.285,0-.439-.146-.528-.462a.952.952,0,0,1,.512-.985Z"
      transform="translate(0.75 0.75)"
      fill="#2a3755"
    />
  </svg>
);

export const HahaIcon = (props) => <Icon component={Haha} {...props} />;

export const Wow = () => (
  <svg
    id="Group_1886"
    data-name="Group 1886"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <defs>
      <clipPath id="wow-clip-path">
        <rect
          id="Rectangle_1213"
          data-name="Rectangle 1213"
          width="20"
          height="20"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="wow-linear-gradient"
        x1="0.5"
        y1="0.074"
        x2="0.5"
        y2="1.063"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#feea70" />
        <stop offset="1" stopColor="#f69b30" />
      </linearGradient>
      <linearGradient
        id="wow-linear-gradient-2"
        x1="0.5"
        y1="0.333"
        x2="0.5"
        y2="-0.667"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#472315" />
        <stop offset="1" stopColor="#8b3a0e" />
      </linearGradient>
      <linearGradient
        id="wow-linear-gradient-3"
        x1="0.5"
        y1="3.333"
        x2="0.5"
        y2="2.333"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#191a33" />
        <stop offset="0.872" stopColor="#3b426a" />
      </linearGradient>
      <linearGradient
        id="wow-linear-gradient-4"
        x1="0.5"
        y1="12.195"
        x2="0.5"
        y2="11.195"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#e78e0d" />
        <stop offset="1" stopColor="#cb6000" />
      </linearGradient>
    </defs>
    <g id="Group_1829" data-name="Group 1829" clipPath="url(#wow-clip-path)">
      <g id="Group_1828" data-name="Group 1828" transform="translate(0 0)">
        <path
          id="Path_8912"
          data-name="Path 8912"
          d="M20,10a10,10,0,1,1-2.929-7.071A10,10,0,0,1,20,10"
          transform="translate(0 0)"
          fill="url(#wow-linear-gradient)"
        />
        <path
          id="Path_8913"
          data-name="Path 8913"
          d="M5.648,11.61c-.2,2.306.907,3.89,2.946,3.89s3.144-1.584,2.946-3.89S10.146,8,8.594,8,5.844,9.3,5.648,11.61Z"
          transform="translate(1.406 2)"
          fill="url(#wow-linear-gradient-2)"
        />
        <path
          id="Path_8914"
          data-name="Path 8914"
          d="M3.5,5.875A1.74,1.74,0,0,1,5.063,4,1.74,1.74,0,0,1,6.625,5.875,1.739,1.739,0,0,1,5.063,7.75,1.739,1.739,0,0,1,3.5,5.875Zm8.125,0A1.741,1.741,0,0,1,13.188,4,1.74,1.74,0,0,1,14.75,5.875,1.739,1.739,0,0,1,13.188,7.75,1.74,1.74,0,0,1,11.625,5.875Z"
          transform="translate(0.875 1)"
          fill="url(#wow-linear-gradient-3)"
        />
        <path
          id="Path_8915"
          data-name="Path 8915"
          d="M3.5,5.875A1.74,1.74,0,0,1,5.063,4,1.74,1.74,0,0,1,6.625,5.875,1.739,1.739,0,0,1,5.063,7.75,1.739,1.739,0,0,1,3.5,5.875Zm8.125,0A1.741,1.741,0,0,1,13.188,4,1.74,1.74,0,0,1,14.75,5.875,1.739,1.739,0,0,1,13.188,7.75,1.74,1.74,0,0,1,11.625,5.875Z"
          transform="translate(0.875 1)"
        />
        <path
          id="Path_8916"
          data-name="Path 8916"
          d="M4.6,4.569a.453.453,0,0,1,.29.586.5.5,0,0,1-.55.4.454.454,0,0,1-.29-.588A.5.5,0,0,1,4.6,4.569Zm8.323.079a.5.5,0,0,1,.324.65.552.552,0,0,1-.612.443.5.5,0,0,1-.321-.651.549.549,0,0,1,.612-.441Z"
          transform="translate(1.006 1.14)"
          fill="#4e506a"
        />
        <path
          id="Path_8917"
          data-name="Path 8917"
          d="M13.013,1.7c.065-.006.13-.009.2-.009a2.554,2.554,0,0,1,1.715.7.456.456,0,0,1,.142.313.464.464,0,0,1-.115.325.433.433,0,0,1-.136.105.454.454,0,0,1-.168.042.44.44,0,0,1-.17-.026.457.457,0,0,1-.146-.091,1.66,1.66,0,0,0-1.24-.461,1,1,0,0,0-.737.414.432.432,0,0,1-.128.115.407.407,0,0,1-.161.056.417.417,0,0,1-.171-.01A.443.443,0,0,1,11.74,3.1a.47.47,0,0,1-.17-.3.461.461,0,0,1,.086-.334A1.864,1.864,0,0,1,13.013,1.7Zm-9.581.694a2.553,2.553,0,0,1,1.715-.7A1.859,1.859,0,0,1,6.7,2.463a.461.461,0,0,1-.082.634.433.433,0,0,1-.154.076.441.441,0,0,1-.334-.046A.449.449,0,0,1,6,3.012a.992.992,0,0,0-.322-.289A1.008,1.008,0,0,0,5.264,2.6a1.662,1.662,0,0,0-1.24.461.432.432,0,0,1-.146.091.437.437,0,0,1-.17.026.448.448,0,0,1-.166-.042A.453.453,0,0,1,3.4,3.029a.459.459,0,0,1,.028-.638Z"
          transform="translate(0.822 0.422)"
        />
        <path
          id="Path_8918"
          data-name="Path 8918"
          d="M13.013,1.7c.065-.006.13-.009.2-.009a2.554,2.554,0,0,1,1.715.7.456.456,0,0,1,.142.313.464.464,0,0,1-.115.325.433.433,0,0,1-.136.105.454.454,0,0,1-.168.042.44.44,0,0,1-.17-.026.457.457,0,0,1-.146-.091,1.66,1.66,0,0,0-1.24-.461,1,1,0,0,0-.737.414.432.432,0,0,1-.128.115.407.407,0,0,1-.161.056.417.417,0,0,1-.171-.01A.443.443,0,0,1,11.74,3.1a.47.47,0,0,1-.17-.3.461.461,0,0,1,.086-.334A1.864,1.864,0,0,1,13.013,1.7Zm-9.581.694a2.553,2.553,0,0,1,1.715-.7A1.859,1.859,0,0,1,6.7,2.463a.461.461,0,0,1-.082.634.433.433,0,0,1-.154.076.441.441,0,0,1-.334-.046A.449.449,0,0,1,6,3.012a.992.992,0,0,0-.322-.289A1.008,1.008,0,0,0,5.264,2.6a1.662,1.662,0,0,0-1.24.461.432.432,0,0,1-.146.091.437.437,0,0,1-.17.026.448.448,0,0,1-.166-.042A.453.453,0,0,1,3.4,3.029a.459.459,0,0,1,.028-.638Z"
          transform="translate(0.822 0.422)"
          fill="url(#wow-linear-gradient-4)"
        />
      </g>
    </g>
  </svg>
);

export const WowIcon = (props) => <Icon component={Wow} {...props} />;

export const Sad = () => (
  <svg
    id="Group_1846"
    data-name="Group 1846"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <defs>
      <linearGradient
        id="linear-gradient-sad"
        x1="0.5"
        y1="0.05"
        x2="0.5"
        y2="1.11"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#feea70" />
        <stop offset="1" stopColor="#f69b30" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2-sad"
        x1="0.5"
        y1="-2"
        x2="0.5"
        y2="-3"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#472315" />
        <stop offset="1" stopColor="#8b3a0e" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-3-sad"
        x1="0.5"
        y1="1.25"
        x2="0.5"
        y2="0.25"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#191a33" />
        <stop offset="0.872" stopColor="#3b426a" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-4-sad"
        x1="0.5"
        y1="3.589"
        x2="0.5"
        y2="2.589"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#e78e0d" />
        <stop offset="1" stopColor="#cb6000" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-5-sad"
        x1="0.5"
        y1="-1.486"
        x2="0.5"
        y2="-0.846"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#35cafc" />
        <stop offset="1" stopColor="#007edb" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-6-sad"
        x1="0.5"
        y1="-2.433"
        x2="0.5"
        y2="-3.433"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#6ae1ff" stopOpacity="0.286" />
        <stop offset="1" stopColor="#a8e3ff" stopOpacity="0.8" />
      </linearGradient>
    </defs>
    <path
      id="Path_8868"
      data-name="Path 8868"
      d="M20,10a10,10,0,1,1-2.929-7.071A10,10,0,0,1,20,10"
      transform="translate(0 0)"
      fill="url(#linear-gradient-sad)"
    />
    <path
      id="Path_8869"
      data-name="Path 8869"
      d="M5.333,13.206a.285.285,0,0,0,.313.294c.439,0,1.045-.781,3.021-.781s2.584.781,3.021.781A.285.285,0,0,0,12,13.206,3.255,3.255,0,0,0,8.667,11,3.255,3.255,0,0,0,5.333,13.206Z"
      transform="translate(1.333 2.75)"
      fill="url(#linear-gradient-2-sad)"
    />
    <path
      id="Path_8870"
      data-name="Path 8870"
      d="M3.6,9.167A1.666,1.666,0,0,1,5.017,7.334,1.665,1.665,0,0,1,6.434,9.167a2.188,2.188,0,0,1-.3,1.122.615.615,0,0,1-.376.281,2.922,2.922,0,0,1-1.487,0,.609.609,0,0,1-.375-.281,2.174,2.174,0,0,1-.3-1.122Zm8.168,0a1.666,1.666,0,0,1,1.416-1.832A1.665,1.665,0,0,1,14.6,9.167a2.188,2.188,0,0,1-.3,1.122.618.618,0,0,1-.376.281,2.963,2.963,0,0,1-1.486,0,.618.618,0,0,1-.376-.281,2.173,2.173,0,0,1-.3-1.122Z"
      transform="translate(0.9 1.833)"
      fill="url(#linear-gradient-3-sad)"
    />
    <path
      id="Path_8871"
      data-name="Path 8871"
      d="M3.6,9.167A1.666,1.666,0,0,1,5.017,7.334,1.665,1.665,0,0,1,6.434,9.167a2.188,2.188,0,0,1-.3,1.122.615.615,0,0,1-.376.281,2.922,2.922,0,0,1-1.487,0,.609.609,0,0,1-.375-.281,2.174,2.174,0,0,1-.3-1.122Zm8.168,0a1.666,1.666,0,0,1,1.416-1.832A1.665,1.665,0,0,1,14.6,9.167a2.188,2.188,0,0,1-.3,1.122.618.618,0,0,1-.376.281,2.963,2.963,0,0,1-1.486,0,.618.618,0,0,1-.376-.281,2.173,2.173,0,0,1-.3-1.122Z"
      transform="translate(0.9 1.833)"
    />
    <path
      id="Path_8872"
      data-name="Path 8872"
      d="M4.749,8a.566.566,0,0,1,.021.689.361.361,0,0,1-.56.12.566.566,0,0,1-.021-.687A.361.361,0,0,1,4.749,8ZM12.86,8a.568.568,0,0,1,.023.689.363.363,0,0,1-.562.12.566.566,0,0,1-.019-.687A.36.36,0,0,1,12.86,8Z"
      transform="translate(1.021 1.979)"
      fill="#4e506a"
    />
    <path
      id="Path_8873"
      data-name="Path 8873"
      d="M4.663,5.208a2.492,2.492,0,0,1,1-.274.716.716,0,0,1,.688.382c.219.411.12.5-.248.574A7.938,7.938,0,0,0,2.731,7.628c-.376.35-.736-.038-.545-.342A5.95,5.95,0,0,1,4.663,5.208Zm7.919.109a.716.716,0,0,1,.688-.382,2.487,2.487,0,0,1,1,.274,5.933,5.933,0,0,1,2.476,2.078c.191.3-.168.693-.544.342A7.944,7.944,0,0,0,12.828,5.89c-.367-.073-.464-.162-.246-.574Z"
      transform="translate(0.533 1.234)"
      fill="url(#linear-gradient-4-sad)"
    />
    <path
      id="Path_8874"
      data-name="Path 8874"
      d="M13.875,17.313A1.991,1.991,0,0,1,12,15.224a5.762,5.762,0,0,1,.8-2.684c.748-1.573.9-1.79,1.071-1.79s.324.217,1.071,1.79a5.762,5.762,0,0,1,.8,2.684A1.991,1.991,0,0,1,13.875,17.313Z"
      transform="translate(3 2.688)"
      fill="url(#linear-gradient-5-sad)"
    />
    <path
      id="Path_8875"
      data-name="Path 8875"
      d="M13.649,14.125a.787.787,0,0,1-.743-.825,2.285,2.285,0,0,1,.319-1.065c.3-.623.354-.708.424-.708s.129.085.424.708a2.289,2.289,0,0,1,.319,1.064.788.788,0,0,1-.743.826"
      transform="translate(3.227 2.882)"
      fill="url(#linear-gradient-6-sad)"
    />
  </svg>
);

export const SadIcon = (props) => <Icon component={Sad} {...props} />;

export const Angry = () => (
  <svg
    id="Group_1847"
    data-name="Group 1847"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <defs>
      <linearGradient
        id="linear-gradient-angry"
        x1="0.5"
        y1="-0.073"
        x2="0.5"
        y2="0.453"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#e04300" />
        <stop offset="1" stopColor="#ffa320" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2-angry"
        x1="0.5"
        y1="-4.802"
        x2="0.5"
        y2="-5.667"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#3d0d00" />
        <stop offset="1" stopColor="#661c04" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-3-angry"
        x1="0.5"
        y1="0.461"
        x2="0.5"
        y2="-0.539"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#191a33" />
        <stop offset="0.872" stopColor="#3b426a" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-4-angry"
        x1="0.5"
        y1="0.781"
        x2="0.5"
        y2="1.874"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#9a2f00" />
        <stop offset="1" stopColor="#d44800" />
      </linearGradient>
    </defs>
    <path
      id="Path_8904"
      data-name="Path 8904"
      d="M20,10a10,10,0,1,1-2.929-7.071A10,10,0,0,1,20,10"
      transform="translate(0 0)"
      fill="url(#linear-gradient-angry)"
    />
    <path
      id="Path_8905"
      data-name="Path 8905"
      d="M5.2,13.814c0,.66,1.566.555,3.5.555s3.5.105,3.5-.555c0-.8-1.568-1.314-3.5-1.314S5.2,13.019,5.2,13.814Z"
      transform="translate(1.3 3.125)"
    />
    <path
      id="Path_8906"
      data-name="Path 8906"
      d="M5.2,13.814c0,.66,1.566.555,3.5.555s3.5.105,3.5-.555c0-.8-1.568-1.314-3.5-1.314S5.2,13.019,5.2,13.814Z"
      transform="translate(1.3 3.125)"
      fill="url(#linear-gradient-2-angry)"
    />
    <path
      id="Path_8907"
      data-name="Path 8907"
      d="M3.6,10.189A1.664,1.664,0,0,1,5.1,8.4a1.663,1.663,0,0,1,1.5,1.789,1.985,1.985,0,0,1-.315,1.092.659.659,0,0,1-.4.275,3.354,3.354,0,0,1-1.575,0,.651.651,0,0,1-.4-.275A1.977,1.977,0,0,1,3.6,10.189Zm8,0A1.663,1.663,0,0,1,13.1,8.4a1.664,1.664,0,0,1,1.5,1.789,2,2,0,0,1-.315,1.092.651.651,0,0,1-.4.275,3.35,3.35,0,0,1-.788.094,3.427,3.427,0,0,1-.788-.094.655.655,0,0,1-.4-.275A1.986,1.986,0,0,1,11.6,10.189Z"
      transform="translate(0.9 2.1)"
      fill="url(#linear-gradient-3-angry)"
    />
    <path
      id="Path_8908"
      data-name="Path 8908"
      d="M3.6,10.189A1.664,1.664,0,0,1,5.1,8.4a1.663,1.663,0,0,1,1.5,1.789,1.985,1.985,0,0,1-.315,1.092.659.659,0,0,1-.4.275,3.354,3.354,0,0,1-1.575,0,.651.651,0,0,1-.4-.275A1.977,1.977,0,0,1,3.6,10.189Zm8,0A1.663,1.663,0,0,1,13.1,8.4a1.664,1.664,0,0,1,1.5,1.789,2,2,0,0,1-.315,1.092.651.651,0,0,1-.4.275,3.35,3.35,0,0,1-.788.094,3.427,3.427,0,0,1-.788-.094.655.655,0,0,1-.4-.275A1.986,1.986,0,0,1,11.6,10.189Z"
      transform="translate(0.9 2.1)"
    />
    <path
      id="Path_8909"
      data-name="Path 8909"
      d="M5.163,9.371a.351.351,0,0,1,.009.089.493.493,0,0,1-.984,0,.435.435,0,0,1,.1-.276C4.568,9.249,4.862,9.313,5.163,9.371Zm7.408.546a.476.476,0,0,1-.49-.435c.323-.048.644-.106.956-.17a.429.429,0,0,1,.026.149.476.476,0,0,1-.493.456Z"
      transform="translate(1.047 2.296)"
      fill="#4f4f67"
    />
    <path
      id="Path_8910"
      data-name="Path 8910"
      d="M10.625,7.75c0-.557.2-.75.556-.75s.518.345.633,1.333a27.517,27.517,0,0,0,4.028-.668.367.367,0,0,1,.4.334.422.422,0,0,1-.276.5,14.042,14.042,0,0,1-4.98,1.169.328.328,0,0,1-.356-.376Zm-3.689.583C7.051,7.345,7.219,7,7.569,7s.556.193.556.75V9.291a.328.328,0,0,1-.356.376A14.042,14.042,0,0,1,2.789,8.5.422.422,0,0,1,2.512,8a.369.369,0,0,1,.4-.334A27.517,27.517,0,0,0,6.936,8.333Z"
      transform="translate(0.625 1.75)"
    />
    <path
      id="Path_8911"
      data-name="Path 8911"
      d="M10.625,7.75c0-.557.2-.75.556-.75s.518.345.633,1.333a27.517,27.517,0,0,0,4.028-.668.367.367,0,0,1,.4.334.422.422,0,0,1-.276.5,14.042,14.042,0,0,1-4.98,1.169.328.328,0,0,1-.356-.376Zm-3.689.583C7.051,7.345,7.219,7,7.569,7s.556.193.556.75V9.291a.328.328,0,0,1-.356.376A14.042,14.042,0,0,1,2.789,8.5.422.422,0,0,1,2.512,8a.369.369,0,0,1,.4-.334A27.517,27.517,0,0,0,6.936,8.333Z"
      transform="translate(0.625 1.75)"
      fill="url(#linear-gradient-4-angry)"
    />
  </svg>
);

export const AngryIcon = (props) => <Icon component={Angry} {...props} />;

export const TakeCare = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.125"
    height="20"
    viewBox="0 0 20.125 20"
  >
    <defs>
      <radialGradient
        id="take-care-radial-gradient"
        cx="0.5"
        cy="0.5"
        r="0.733"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#feea70" />
        <stop offset="1" stopColor="#f7a739" />
      </radialGradient>
      <linearGradient
        id="take-care-linear-gradient"
        x1="-141.528"
        y1="831.533"
        x2="-141.528"
        y2="833.175"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#482314" />
        <stop offset="1" stopColor="#9a4111" />
      </linearGradient>
      <radialGradient
        id="take-care-radial-gradient-2"
        cx="0.52"
        cy="0.65"
        r="0.769"
        gradientTransform="translate(-735.753 -124.179) rotate(-90) scale(1 1.152)"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#3b446b" />
        <stop offset="0.688" stopColor="#202340" />
      </radialGradient>
      <radialGradient
        id="take-care-radial-gradient-3"
        cx="0.458"
        cy="0.649"
        r="0.769"
        gradientTransform="translate(-705.089 -108.394) rotate(-90) scale(1 1.099)"
      />
      <radialGradient
        id="take-care-radial-gradient-4"
        cx="0.478"
        cy="0.399"
        r="0.301"
        gradientTransform="matrix(-0.095, -0.968, 1.717, -1.155, -921.376, 441.595)"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#e38200" />
        <stop offset="1" stopColor="#cd6700" />
      </radialGradient>
      <radialGradient
        id="take-care-radial-gradient-5"
        cx="0.501"
        cy="0.259"
        r="0.309"
        gradientTransform="matrix(0.074, -0.982, 2.274, 1.124, -987.786, -281.422)"
      />
      <linearGradient
        id="take-care-linear-gradient-2"
        x1="-62.639"
        y1="44.661"
        x2="-62.663"
        y2="44.739"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#f34462" />
        <stop offset="1" stopColor="#cc0820" />
      </linearGradient>
      <radialGradient
        id="take-care-radial-gradient-6"
        cx="0.736"
        cy="0.444"
        r="0.289"
        gradientTransform="matrix(0.879, 0.348, -0.327, 0.937, 6.192, 23.886)"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#ff7091" stopOpacity="0.698" />
        <stop offset="1" stopColor="#fe6d8e" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="take-care-radial-gradient-7"
        cx="0.266"
        cy="0.299"
        r="0.289"
        gradientTransform="matrix(0.879, 0.348, -0.327, 0.937, 6.181, 23.902)"
      />
      <radialGradient
        id="take-care-radial-gradient-8"
        cx="0.236"
        cy="0.535"
        r="0.248"
        gradientTransform="matrix(-0.189, 0.98, -1.265, -0.277, -37.293, 108.736)"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#9c0600" />
        <stop offset="1" stopColor="#9c0600" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="take-care-radial-gradient-9"
        cx="1.001"
        cy="0.56"
        r="0.149"
        gradientTransform="matrix(0.807, 0.51, -0.6, 1.08, 12.187, 27.866)"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#9c0600" stopOpacity="0.502" />
        <stop offset="1" stopColor="#9c0600" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="take-care-radial-gradient-10"
        cx="0.038"
        cy="0.244"
        r="0.129"
        gradientTransform="matrix(0.88, -0.345, 0.417, 1.21, -26.659, -31.657)"
      />
      <radialGradient
        id="take-care-radial-gradient-11"
        cx="0.746"
        cy="0.771"
        r="0.174"
        gradientTransform="matrix(0.777, 0.56, -0.569, 0.897, 8.814, 38.23)"
      />
      <radialGradient
        id="take-care-radial-gradient-12"
        cx="0.945"
        cy="0.048"
        r="1.213"
        gradientTransform="matrix(0.895, 0, 0, -1, 0.099, 0.789)"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#eda83a" />
        <stop offset="1" stopColor="#ffdc5e" />
      </radialGradient>
      <radialGradient
        id="take-care-radial-gradient-13"
        cx="0.055"
        cy="0.048"
        r="1.213"
        gradientTransform="translate(0.104 0.789) rotate(180) scale(0.895 1)"
      />
    </defs>
    <g
      id="Group_1843"
      data-name="Group 1843"
      transform="translate(-2592 -2747)"
    >
      <path
        id="Path_8882"
        data-name="Path 8882"
        d="M20,10A10,10,0,1,1,10,0,10.029,10.029,0,0,1,20,10Z"
        transform="translate(2592 2747)"
        fillRule="evenodd"
        fill="url(#take-care-radial-gradient)"
      />
      <path
        id="Path_8888"
        data-name="Path 8888"
        d="M10.552,5.956c-.125-.375-4.125-.375-4.25,0s.75.875,2.125.875S10.677,6.331,10.552,5.956Z"
        transform="translate(2593.573 2748.419)"
        fillRule="evenodd"
        fill="url(#take-care-linear-gradient)"
      />
      <path
        id="Path_8889"
        data-name="Path 8889"
        d="M6.525,4.45c0,.875-.5,1.125-1.25,1.25S3.9,5.45,3.9,4.45c0-.75.375-1.75,1.375-1.75C6.15,2.7,6.525,3.7,6.525,4.45Z"
        transform="translate(2592.975 2747.675)"
        fillRule="evenodd"
        fill="url(#take-care-radial-gradient-2)"
      />
      <path
        id="Path_8890"
        data-name="Path 8890"
        d="M5.044,3.119a.435.435,0,0,1-.125.625c-.125.125-.375.25-.5,0a.568.568,0,0,1,0-.625.558.558,0,0,1,.625,0Z"
        transform="translate(2593.081 2747.756)"
        fill="#4e506a"
        fillRule="evenodd"
      />
      <path
        id="Path_8891"
        data-name="Path 8891"
        d="M10,4.45c0,.875.5,1.125,1.375,1.25.75.125,1.375-.25,1.375-1.25,0-.75-.375-1.75-1.375-1.75S10,3.7,10,4.45Z"
        transform="translate(2594.5 2747.675)"
        fillRule="evenodd"
        fill="url(#take-care-radial-gradient-3)"
      />
      <path
        id="Path_8892"
        data-name="Path 8892"
        d="M11.135,3.138c.125.125,0,.375-.125.625a.38.38,0,0,1-.5,0c-.125-.125,0-.375.125-.625C10.885,2.888,11.01,2.888,11.135,3.138Z"
        transform="translate(2594.615 2747.738)"
        fill="#4e506a"
        fillRule="evenodd"
      />
      <path
        id="Path_8893"
        data-name="Path 8893"
        d="M3.42,2.332c-.25.25,0,.625.375.5a7.321,7.321,0,0,1,3.5-.625c.375,0,.5,0,.375-.5,0-.375-.5-.625-1.5-.5A4.733,4.733,0,0,0,3.42,2.332Z"
        transform="translate(2592.83 2747.293)"
        fillRule="evenodd"
        fill="url(#take-care-radial-gradient-4)"
      />
      <path
        id="Path_8894"
        data-name="Path 8894"
        d="M10.709,1.207c-1-.125-1.5.125-1.5.5-.125.5,0,.5.375.5a6.746,6.746,0,0,1,3.5.625c.5.25.625-.25.375-.5A4.733,4.733,0,0,0,10.709,1.207Z"
        transform="translate(2594.291 2747.293)"
        fillRule="evenodd"
        fill="url(#take-care-radial-gradient-5)"
      />
      <path
        id="Path_8895"
        data-name="Path 8895"
        d="M11.7,8.9c-2.625-.75-3.5,1-3.5,1s.25-2-2.375-2.875c-2.5-.75-4,1.625-4.125,3-.25,3.125,2.5,6.625,3.5,7.875.125.375.5.375.875.375,1.5-.375,5.75-1.75,7.375-4.5C14.078,12.4,14.2,9.654,11.7,8.9Z"
        transform="translate(2592.422 2748.721)"
        fillRule="evenodd"
        fill="url(#take-care-linear-gradient-2)"
      />
      <path
        id="Path_8896"
        data-name="Path 8896"
        d="M11.7,8.9c-2.625-.75-3.5,1-3.5,1s.25-2-2.375-2.875c-2.5-.75-4,1.625-4.125,3-.25,3.125,2.5,6.625,3.5,7.875.125.375.5.375.875.375,1.5-.375,5.75-1.75,7.375-4.5C14.078,12.4,14.2,9.654,11.7,8.9Z"
        transform="translate(2592.422 2748.721)"
        fillRule="evenodd"
        fill="url(#take-care-radial-gradient-6)"
      />
      <path
        id="Path_8897"
        data-name="Path 8897"
        d="M11.7,8.9c-2.625-.75-3.5,1-3.5,1s.25-2-2.375-2.875c-2.5-.75-4,1.625-4.125,3-.25,3.125,2.5,6.625,3.5,7.875.125.375.5.375.875.375,1.5-.375,5.75-1.75,7.375-4.5C14.078,12.4,14.2,9.654,11.7,8.9Z"
        transform="translate(2592.422 2748.721)"
        fillRule="evenodd"
        fill="url(#take-care-radial-gradient-7)"
      />
      <path
        id="Path_8898"
        data-name="Path 8898"
        d="M11.7,8.9c-2.625-.75-3.5,1-3.5,1s.25-2-2.375-2.875c-2.5-.75-4,1.625-4.125,3-.25,3.125,2.5,6.625,3.5,7.875.125.375.5.375.875.375,1.5-.375,5.75-1.75,7.375-4.5C14.078,12.4,14.2,9.654,11.7,8.9Z"
        transform="translate(2592.422 2748.721)"
        fillRule="evenodd"
        fill="url(#take-care-radial-gradient-8)"
      />
      <path
        id="Path_8899"
        data-name="Path 8899"
        d="M11.7,8.9c-2.625-.75-3.5,1-3.5,1s.25-2-2.375-2.875c-2.5-.75-4,1.625-4.125,3-.25,3.125,2.5,6.625,3.5,7.875.125.375.5.375.875.375,1.5-.375,5.75-1.75,7.375-4.5C14.078,12.4,14.2,9.654,11.7,8.9Z"
        transform="translate(2592.422 2748.721)"
        fillRule="evenodd"
        fill="url(#take-care-radial-gradient-9)"
      />
      <path
        id="Path_8900"
        data-name="Path 8900"
        d="M11.7,8.9c-2.625-.75-3.5,1-3.5,1s.25-2-2.375-2.875c-2.5-.75-4,1.625-4.125,3-.25,3.125,2.5,6.625,3.5,7.875.125.375.5.375.875.375,1.5-.375,5.75-1.75,7.375-4.5C14.078,12.4,14.2,9.654,11.7,8.9Z"
        transform="translate(2592.422 2748.721)"
        fillRule="evenodd"
        fill="url(#take-care-radial-gradient-10)"
      />
      <path
        id="Path_8901"
        data-name="Path 8901"
        d="M11.7,8.9c-2.625-.75-3.5,1-3.5,1s.25-2-2.375-2.875c-2.5-.75-4,1.625-4.125,3-.25,3.125,2.5,6.625,3.5,7.875.125.375.5.375.875.375,1.5-.375,5.75-1.75,7.375-4.5C14.078,12.4,14.2,9.654,11.7,8.9Z"
        transform="translate(2592.422 2748.721)"
        fillRule="evenodd"
        fill="url(#take-care-radial-gradient-11)"
      />
      <path
        id="Path_8902"
        data-name="Path 8902"
        d="M15.885,7.9c.375-.75,1-.5,1.375-.375a1.145,1.145,0,0,1,.875,1.25,7.133,7.133,0,0,1-1.875,5.375c-2.875,3.25-7.75,2.75-8.25.75-.375-1.5,1.375-1.75,2-1.75v-.125c-.25-.125-.375-.25-.625-.375-.5-.375-.375-1.125.25-1a8.5,8.5,0,0,0,2.5.25c2.375-.25,2.75-2.125,3.75-4Z"
        transform="translate(2593.99 2748.846)"
        fill="url(#take-care-radial-gradient-12)"
      />
      <path
        id="Path_8903"
        data-name="Path 8903"
        d="M10.208,7.9c-.375-.75-1-.5-1.375-.375a1.145,1.145,0,0,0-.875,1.25,7.132,7.132,0,0,0,1.875,5.375c2.875,3.25,7.75,2.75,8.25.75.375-1.5-1.375-1.75-2-1.75v-.125c.25-.125.375-.25.625-.375.5-.375.375-1.125-.25-1a8.505,8.505,0,0,1-2.5.25c-2.375-.25-2.75-2.125-3.75-4Z"
        transform="translate(2584.042 2748.089)"
        fill="url(#take-care-radial-gradient-13)"
      />
    </g>
  </svg>
);

export const TakeCareIcon = (props) => <Icon component={TakeCare} {...props} />;
