import { Badge, Tooltip } from "antd";
import { FormErrorIcon, FormLoadingIcon, FormOKIcon } from "modules/panel/components/standardIcons";
import { useEffect, useMemo } from "react";
import { usePost } from "../postContext"
import Switch from "_components/forms/switch";
import useAPI from "services/hooks/useAPI";
import _ from "lodash";
import { GetTranslatedAdStatusString, GetTranslatedPostStatusString } from "../postTranslatedStrings";
import AwesomeIcon from "components/common/AwesomeIcon";
import { faBullhorn, faCheckCircle, faExclamationCircle, faFileLines, faHourglass, faPauseCircle, faPlayCircle, faStopCircle, faTrashCircle } from "@fortawesome/pro-light-svg-icons";

export type Status = "published" | "error" | "scheduled" | "draft" | "both" | "notPublished" | undefined | any;

const StatusIcons: any = {
    ok: <AwesomeIcon icon={faCheckCircle} size="xl" color="green"/>,
    pause: <AwesomeIcon icon={faPauseCircle} size="xl" color="grey"/>,
    play: <AwesomeIcon icon={faPlayCircle} size="xl"/>,
    stop: <AwesomeIcon icon={faStopCircle} size="xl"/>,
    warning: <AwesomeIcon icon={faExclamationCircle} size="xl" color="orange"/>,
    active: <AwesomeIcon icon={faCheckCircle} size="xl" color="green"/>,
    published: <AwesomeIcon icon={faCheckCircle} size="xl" color="green"/>,
    enabled: <AwesomeIcon icon={faCheckCircle} size="xl" color="green"/>,
    error: <AwesomeIcon icon={faExclamationCircle} size="xl" color="red"/>,
    unknown: <AwesomeIcon icon={faExclamationCircle} size="xl" color="orange"/>,
    mixed: <AwesomeIcon icon={faExclamationCircle} size="xl" color="orange"/>,
    scheduled: <AwesomeIcon icon={faPlayCircle} size="xl"/>,
    paused: <AwesomeIcon icon={faPauseCircle} size="xl" color="grey"/>,
    publishing: <AwesomeIcon icon={faPauseCircle} size="xl" color="grey"/>,
    ended: <AwesomeIcon icon={faStopCircle} size="xl"/>,
    with_issues: <AwesomeIcon icon={faExclamationCircle} size="xl" color="orange"/>,
    pending: <AwesomeIcon icon={faHourglass} size="xl" color="orange"/>,
    loading: <AwesomeIcon icon={faPlayCircle} size="xl"/>,
    notPublished: <AwesomeIcon icon={faExclamationCircle} size="xl" color="red"/>,

    pending_review: <AwesomeIcon icon={faHourglass} size="xl"/>,
    in_process: <AwesomeIcon icon={faHourglass} size="xl"/>,
    deleted: <AwesomeIcon icon={faTrashCircle} size="xl"/>,
    archived: <AwesomeIcon icon={faTrashCircle} size="xl"/>,
    disaproved: <AwesomeIcon icon={faExclamationCircle} size="xl" color="red"/>,
    preapproved: <AwesomeIcon icon={faCheckCircle} size="xl" color="grey"/>,
    pending_billing_info: <AwesomeIcon icon={faHourglass} size="xl" color="grey"/>,

};

const StatusIconsGray: any = {
    active: <AwesomeIcon icon={faPlayCircle} size="xl" color="grey"/>,
    error: <AwesomeIcon icon={faExclamationCircle} size="xl" color="grey"/>,
    paused: <AwesomeIcon icon={faPauseCircle} size="xl" color="grey"/>,
    ended: <AwesomeIcon icon={faStopCircle} size="xl" color="grey"/>,
};


export function StatusDisplay(props: { status: Status, style?: any, label?: any }) {

    const icon = useMemo(() => {

        if (!props.status || !StatusIcons[props.status]) return <AwesomeIcon icon={faExclamationCircle} size="xl" color="orange"/>
        return StatusIcons[props.status];

    }, [props.status]);

    return (
        <>
            <div className="status-display" style={{ display: "grid", placeItems: "center", height: "24px", width: "24px", ...props.style }}>
                {icon}
            </div>
            {props.label ? props.label : null}
        </>)

}

export function StatusDisplayWithBadge(props: { status: Status, count?: number | null | undefined }) {

    const icon = useMemo(() => {

        if (!props.status || !StatusIcons[props.status]) return <AwesomeIcon icon={faExclamationCircle} size="xl" color="orange"/>

        if (props.count && props.count > 0) return StatusIcons[props.status];
        return StatusIconsGray[props.status];

    }, [props.status]);

    return (
        <Badge count={props.count} size={"small"} className={`status-display-with-badge ${(props.count && props.count > 0) ? "with-count" : "no-count"}`}>
            <div className="status-display" style={{ display: "grid", placeItems: "center", height: "24px", width: "24px" }}>
                {icon}
            </div>
        </Badge>
    )
}


export function AdStatusDisplayWithCount(props: any) {

    const { post, reloadList, openDetails } = usePost();
    const { call, data, error } = useAPI({})
    const { promotionStatus, secondaryPromotionStatus, additionalStatusCount } = post.getGeneratedData();


    const summerizedStatus = useMemo(() => {

        return (
            <div style={{ display: "flex", flexDirection: "row" }}>
                {GetTranslatedAdStatusString(promotionStatus)}
                {(secondaryPromotionStatus || additionalStatusCount) ? <div style={{ paddingRight: "5px" }}>,</div> : ""}
                {secondaryPromotionStatus && GetTranslatedAdStatusString(secondaryPromotionStatus)}
                {(additionalStatusCount && additionalStatusCount > 0) ? <>, +{additionalStatusCount}</> : ""}
            </div>
        )

    }, [post])

    return (
        <>
            {summerizedStatus}
        </>
    )

}


export function AdStatusDisplay(props: any) {

    const { post, reloadList, openDetails } = usePost();
    const { call, data, error } = useAPI({})

    const status = props?.ad?.status ? props?.ad?.status.toLowerCase() : "";
    const effectiveStatus = props?.ad?.effective_status ? props?.ad?.effective_status.toLowerCase() : status;
    const configuredStatus = props?.ad?.configured_status ? props?.ad?.configured_status.toLowerCase() : status;
    const ended = (props?.ad?.adset?.end_time) ? new Date(props?.ad.adset.end_time) < new Date() : false;

    useEffect(() => {
        if (data || error) reloadList();
    }, [data, error])

    const detailedStatus = useMemo(() => {
        if (!props.ad) return "";
        if (props.provider === "facebook") return (
            <div>
                <p>Status: {status}</p>
                <p>Effective: {effectiveStatus}</p>
                <p>Configured: {configuredStatus}</p>
                {ended ? <p>Ended</p> : ""}
            </div>
        )
        if (props.provider === "instagram") return (
            <div>
                <p>Status: {status}</p>
                {ended ? <p>Ended</p> : ""}
            </div>
        )
    }, [props.ad]);


    const handleChange = (state: boolean) => {
        if (status === "ended") {
            openDetails({ page: "duration", ad: props.ad })
        } else {
            call(post.updateAdStatus(props.ad.id, state ? "active" : "paused", props.provider))
        }
    }

    const isActive = () => {
        if (status === "ended") return false;
        return props?.ad?.configured_status === "ACTIVE" || props?.ad?.configured_status === "active"
    }

    const content = useMemo(() => {


        // //TODO: Workaround untill proper ad management for instagram is implemented
        // if (props.provider === "instagram") return (
        //     <>
        //         <StatusDisplay status={status} label={GetTranslatedAdStatusString(status)}/>
        //         <Switch
        //                 onChange={handleChange}
        //                 value={true}
        //                 disabled={status === "error"}
        //             />
        //     </>
        //     )

        if (!status || status === "" || props?.ad.external_ad_id === "error") return <StatusDisplay status={error} label={GetTranslatedAdStatusString("error")}/>

        if (post.type !== "post") return <StatusDisplay status={effectiveStatus} label={GetTranslatedAdStatusString(effectiveStatus)}/>

        if (effectiveStatus === "active" || effectiveStatus === "paused") {

            if (ended) return <StatusDisplay status={"ended"} />

            return (
                <>
                    <StatusDisplay status={effectiveStatus} />
                    <Switch
                        onChange={handleChange}
                        value={true}
                        disabled={status === "error"}
                    />
                </>
            )

        }

        if (effectiveStatus.includes("paused")) {
            
            if (ended) return <StatusDisplay status={"ended"} />
            
            return (
            <>
                <StatusDisplay status={effectiveStatus} />
                <Switch
                    onChange={handleChange}
                    value={false}
                />
            </>
        )}

        if (effectiveStatus === "pending_review" && configuredStatus === "paused") {
            
            if (ended) return <StatusDisplay status={"ended"} />

            return (
            <>
                <StatusDisplay status={effectiveStatus} />
                <Switch
                    onChange={handleChange}
                    value={false}
                />
            </>
        )}

        if (ended) return <StatusDisplay status={"ended"} />

        return (
            <>
                <StatusDisplay status={effectiveStatus} />
                <Switch
                    onChange={handleChange}
                    value={false}
                    disabled={true}
                />
            </>
        )

        return <StatusDisplay status={effectiveStatus} label={GetTranslatedAdStatusString(effectiveStatus)}/>


    }, [status, effectiveStatus, configuredStatus, data])



    return (
        <div className="post-status-display ad-status-display">
            <Tooltip title={detailedStatus}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                    {/* {configuredStatus} */}
                    {content}
                </div>
            </Tooltip>
        </div>
    )



}

export function PlatformStatusDisplay(props: any) {

    return (
        <div className="platform-status-display">
            {GetTranslatedPostStatusString(props.status)}
        </div>
    )

    return (
        <div className="post-status-display">
            <Tooltip title={props.status}>
                <div>
                    <StatusDisplay status={props.status} />
                </div>
            </Tooltip>
        </div>
    )
}


export default function PostStatusDisplay(props: any) {

    const { post } = usePost();

    if (!post) return null;

    return (
        <div>
            {GetTranslatedPostStatusString(post.getPostStatus())}
        </div>
    )
}

export function CombinedPostAndAdStatusDisplay(props: any) {


    return (
        <div onClick={() => props.onClick && props.onClick()} className="combined-status-display">
            <div style={{ display: "grid", gridTemplateColumns: "30px 1fr", width: "150%", alignItems: "center" }}><AwesomeIcon icon={faFileLines} /><PostStatusDisplay /></div>
            <div style={{ display: "grid", gridTemplateColumns: "30px 1fr", width: "150%", alignItems: "center" }}><AwesomeIcon icon={faBullhorn} /><AdStatusDisplayWithCount /></div>
        </div>
    )
}
