import { useMemo } from "react";
import { useSelector } from "react-redux";

type Selector<T> = (...args: any[]) => T;

export default function useSelectorWithParams<T>(args: Selector<T> | [Selector<T>, ...any[]]) {

  const getInitial = () => {
    if (!args) return [];
    if (Array.isArray(args)) return args;
    return [args];
  }

  const [selector, ...rest] = getInitial();

  const data = useSelector((state: any) => {
    if (selector && typeof selector === "function") {
      return Array.isArray(selector)
        ? selector[0](state, ...rest)
        : selector(state, ...rest);
    } else {
      return null;
    }
  });

  const d = useMemo(() => {
    return data && data.toJS ? data.toJS() : data;
  }, [data]);

  return d as T;
}
