import { Provider } from "react";
import { Reducer } from "redux";
import { LayoutRouteConfig } from "providers/routing/layouts/layouts";
import panel from "modules/panel";
import siteGenerator from "modules/site-generator";
import smart from "modules/smart";
import bot from "modules/bot";
import call from "modules/call";
import forsant from "modules/forsant";
import { Selector } from "@reduxjs/toolkit";

const modules = {
  forsant,
  smart,
  siteGenerator,
  bot,
  call,
  panel,
};

export type Module = keyof typeof modules;

export type ModulesConfig = { [key in Module]: AppConfig };

export type AppConfig = {
  key: string;
  path: string;
  routes: LayoutRouteConfig[];
  store?: StoreConfig;
  context?: AppContext;
  apiUrl?: string;
  requirements?:
    | Requirement[]
    | { (requirements: Requirement[]): Requirement[] };
};

export type Requirement = {
  key: string;
  path: any;
  selector: Selector<any, boolean>;
  omitWhenFulfilled?: boolean;
  integration?: Platform;
  permission?: string;
};

export type StoreConfig = {
  key: string;
  reducers: Reducer<any>;
};

export type AppContext = Provider<{
  baseUrl: string;
  projectId: number;
  apiUrl: string;
  userToken: string;
  permissions: string[];
}>;

export type Providers = Platform[];

// export type Platform = keyof typeof platformsConfig;
// export type Integration = keyof typeof integrationFieldsConfig;

export type Platform = string;
export type Integration = string;


export default modules;
