import { RSAA } from "redux-api-middleware";

function keepRequestStatusUnderKey() {
  return (next) => (action) => {
    if (action && action[RSAA] && action[RSAA].requestStatusUnderKey) {
      let reducerKey = action[RSAA].requestStatusUnderKey;

      let meta = { meta: { reducerKey } };

      action[RSAA].types = action[RSAA].types.map((type) =>
        typeof type === "string" ? { type, ...meta } : { ...type, ...meta }
      );

      delete action[RSAA].requestStatusUnderKey;
    }

    next(action);
  };
}

export default keepRequestStatusUnderKey;
