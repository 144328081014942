import { useMemo } from "react";
import { InputNumber } from "antd";
import { InputNumberProps } from "antd/es/input-number";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { AdsSettingsCurrencySelector } from "modules/smart/smartSlice";

const BudgetInput = ({
  currency: overwriteCurrency,
  min,
  max,
  ...rest
}: InputNumberProps & {
  currency?: string;
  min?: number;
  max?: number;
}) => {

  const adsSettingsCurrency = useSelectorWithParams(AdsSettingsCurrencySelector);

  const currency: string = useMemo(
    () => (overwriteCurrency ? overwriteCurrency : adsSettingsCurrency),
    [adsSettingsCurrency, overwriteCurrency]
  );

  const parser = (value:string | undefined) => {
    if (!value) return 0;
    const adj = value && value.length > 0 && value.match(/\d/g)?.join("");
    if (!adj) return 0;
    return Math.min(Math.max(parseInt(adj), min || 0), max || 100000000);
  }

  return (
    <InputNumber
      min={min || 1}
      //formatter={(value) => value + " " + currency}
      parser={parser}
      {...rest}
      addonAfter={currency}
    />
  );
};

export default BudgetInput;
