import { Card, Space } from "antd";
import { useNotifications } from "./NotificationsDrawer";
import NotificationCard from "./NotificationCard";

const NotificationsList = () => {

    const {notifications} = useNotifications();

    const mappedNotifications = notifications.map((notification, index) => {

        return (
            <NotificationCard notification={notification} key={index} />
        )

    })

    return (
        <Space direction="vertical" style={{width: "100%"}}>
            {mappedNotifications}
        </Space>
    )

}

export default NotificationsList;