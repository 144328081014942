import { Select } from "antd";
import _ from "lodash";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { callAPIFunction } from "services/hooks/useAPI";
import useSuggestions from "services/hooks/useSuggestions";

type Single<Type> = {
    onChange: (value: Type) => void,
    value: Type | null | undefined,
}

type Multiple<Type> = {
    onChange: (value: Type[]) => void,
    value: Type[] | null | undefined,
}

type SelectWithSuggestionsProps<Type> = {
    callAPI: callAPIFunction,
    mode?: "multiple" | "single",
    adjustSuggestion: (suggestion: any) => {value: any, label: ReactNode, disabled: boolean},
    adjustValue: (value: any) => {value: any, label: ReactNode, disabled: boolean},
    uniqueValueProp?: string,
    disabled?: boolean,
    onBlur?: () => void,
} 

const SelectWithSuggestions = <Type extends any>(props: SelectWithSuggestionsProps<Type> & (Single<Type> | Multiple<Type>)) => {

    const checkBy = props.uniqueValueProp || "value";

    //Suggestions from API, based on users input
    const {suggestions, askForSuggestions, loading} = useSuggestions<Type>({
        callAPI: props.callAPI,
        adjustSuggestion: props.adjustSuggestion,
    })


    //All values, including both suggestions and props.value. Used to store objects since Select works only with simple values.
    const [allValues, setAllValues] = useState<any[]>([]);

    const mergeUniqueToAllValues = (newValues: any[]) => {
        setAllValues(v => {
            const checkBy = props.uniqueValueProp || "value";
            const newValuesFiltered = newValues.filter((v:any) => !allValues.find((av:any) => av[checkBy] === v[checkBy]))

            if (newValuesFiltered.length === 0) return v;
            return [...v, ...newValuesFiltered]
        })
    }


    useEffect(() => {
        if (suggestions && suggestions.length > 0) mergeUniqueToAllValues(suggestions);
    }, [suggestions]);

    useEffect(() => {
        if (!props.value) return;
        const pv = Array.isArray(props.value) ? props.value : [props.value];
        const adjustedNewValue = props.adjustValue ? pv.map(props.adjustValue) : pv;
        if (pv.length > 0) mergeUniqueToAllValues(adjustedNewValue);
    }, [props.value]);

    const value = useMemo(() => {
        if (!props.value) return [];
        const pv = Array.isArray(props.value) ? props.value : [props.value];
        const adjustedNewValue = props.adjustValue ? pv.map(props.adjustValue) : pv;
        return adjustedNewValue;
    }, [allValues, props.value])

    const handleChange = (newValue: any) => {

        if (props.mode === "multiple") {
        const foundValues = allValues.filter((av:any) => newValue.includes(av[checkBy]));
        props.onChange && props.onChange(foundValues);
        }

        if (props.mode === "single") {
        const foundValue = allValues.find((av:any) => av[checkBy] === newValue);
        props.onChange && props.onChange(foundValue);
        }
    }

    return (
        <>
        <Select 
            mode={props.mode === "multiple" ? "multiple" : undefined}
            filterOption={false}
            options={loading ? undefined : suggestions}
            onSearch={askForSuggestions}
            //onSelect={handleSelect}
            onChange={handleChange}
            showSearch
            loading={loading}
            value={value}
            onBlur={props.onBlur}
        />
        </>
    )

}

export default SelectWithSuggestions;

