import { LikeOutlined } from "@ant-design/icons";
import { Col, Row, Space, Statistic } from "antd";
import DateTimeDisplay from "components/displays/DateTimeDisplay";
import { ProviderConfig } from "modules/panel/config/ProviderConfig";
import { ReactionsIcons } from "modules/smart/components/list-item/base-list-item/reactions/reactions";
import { ReactNode, useEffect, useState } from "react";
import FormattedMessage from "components/common/FormattedMessage";
import { usePost } from "../postContext";
import PostStatusDisplay, { AdStatusDisplayWithCount } from "../displays/StatusDisplay";
import DetailsSection from "./DetailsSection";
import { GetTranslatedPostStatusString } from "../postTranslatedStrings";
import AwesomeIcon, { AwesomeFacebookBlue } from "components/common/AwesomeIcon";
import { faBullhorn, faFileLines } from "@fortawesome/pro-light-svg-icons";
import { TooltipIcon } from "components/common/tooltip-icon/TooltipIcon";

const PostStatsByProvider:Record<string, {name: string, label: ReactNode, showInCompactView?: boolean}[]> = {
    facebook: [
        {
            name: "reach",
            label: <FormattedMessage
                id="posts.list.header.reach"
                defaultMessage="Reach"
            />,
            showInCompactView: true
        },
        {
            name: "paid_reach",
            label: <FormattedMessage
                id="posts.list.header.paidReach"
                defaultMessage="Paid Reach"
            />
        },
        {
            name: "organic_reach",
            label: <FormattedMessage
                id="posts.list.header.organicReach"
                defaultMessage="Organic Reach"
            />,
        },
        {
            name: "interactions",
            label: <FormattedMessage
                id="posts.list.header.interactions"
                defaultMessage="Interactions"
            />,
            showInCompactView: true
        },
        {
            name: "clicks",
            label: <FormattedMessage
                id="posts.list.header.clicks"
                defaultMessage="Clicks"
            />,
            showInCompactView: true
        },
        {
            name: "actions",
            label: <FormattedMessage
                id="posts.list.header.result"
                defaultMessage="Result"
            />
        },
        {
            name: "cost_per_action_type",
            label: <FormattedMessage
                id="posts.list.header.costPerAction"
                defaultMessage="Cost Per Action"
            />
        }
    ],
    instagram: [
        {
            name: "reach",
            label: <FormattedMessage
                id="posts.list.header.reach"
                defaultMessage="Reach"
            />,
            showInCompactView: true
        },
        {
            name: "organic_reach",
            label: <FormattedMessage
                id="posts.list.header.organicReach"
                defaultMessage="Organic Reach"
            />
        },
        {
            name: "engagement",
            label: <FormattedMessage
                id="posts.list.header.engagement"
                defaultMessage="Engagement"
            />
        },
        {
            name: "impressions",
            label: <FormattedMessage
                id="posts.list.header.impressions"
                defaultMessage="Impressions"
            />
        },
        {
            name: "likes",
            label: <FormattedMessage
                id="posts.list.header.likes"
                defaultMessage="Likes"
            />,
            showInCompactView: true
        },
        {
            name: "comments",
            label: <FormattedMessage
                id="posts.list.header.comments"
                defaultMessage="Comments"
            />
        },
        {
            name: "total_interactions",
            label: <FormattedMessage
                id="posts.list.header.interactions"
                defaultMessage="Interactions"
            />,
            showInCompactView: true
        },
        {
            name: "saved",
            label: <FormattedMessage
                id="posts.list.header.saved"
                defaultMessage="Saved"
            />
        },
        {
            name: "shares",
            label: <FormattedMessage
                id="posts.list.header.shares"
                defaultMessage="Shares"
            />
        }
    ]
}

const ValueDisplay = (props: any) => {
    return (
        <Col md={props.md || 6} sm={props.sm || 12} xs={12}>
            <div style={{ display: "grid", gridTemplateRows: "1fr 1fr", placeItems: "center" }}>
                <div>
                    {props.label}
                </div>
                <div>
                    {props.value}
                </div>
            </div>
        </Col>
    )
}

const DisplayPostSource = (postSource: string) => {
    if (!postSource) return null;
    if (ProviderConfig.get(postSource)) {
        return ProviderConfig.get(postSource)?.displayName
    }
    if (postSource === "platform_form") {
        return (<FormattedMessage
            id="posts.postSource.platformForm"
            defaultMessage="this app" />)
    }
    return null;
}

export default function PostStats(props: any) {

    const [isOpen, setIsOpen] = useState(false);
    const { post } = usePost();

    useEffect(() => {
        setIsOpen(props.isOpen);
    }, [props.isOpen]);

    const reactions = post.getCombinedReactions() || {};

    const provider = post.getProviders()?.[0];
    if (!provider) return null;

    if (isOpen) {
        return (
            <DetailsSection
                ribbon={
                    <div onClick={() => setIsOpen(false)}>
                        <FormattedMessage
                            id="post.management.hideDetails"
                            defaultMessage="Hide Details"
                        />
                    </div>
                }
                onClick={() => setIsOpen((o: any) => !o)}>
                <>
                    <Row >
                        <Col span={24} style={{ display: "grid", placeItems: "center", paddingTop: "20px", paddingBottom: "20px" }}>
                            <div style={{ width: "100%", display: "flex", gap: "50px", justifyContent: "center" }}>
                                <div style={{ display: "flex", placeItems: "center", gap: "20px", justifyContent: "flex-start" }}>
                                    <AwesomeIcon icon={faFileLines} fixedWidth /><PostStatusDisplay provider="all" />
                                </div>
                                <div style={{ display: "flex", placeItems: "center", gap: "20px", justifyContent: "flex-start" }}>
                                    <AwesomeIcon icon={faBullhorn} fixedWidth /><AdStatusDisplayWithCount />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Space style={{ paddingTop: "20px", paddingBottom: "20px", justifyContent: "center"}} wrap size="large">
                        {PostStatsByProvider[provider].map((stat, index) => (
                            <Statistic
                                key={index}
                                title={stat.label}
                                value={post.getProviderInsights(stat.name, provider)}
                                style={{ display: "grid", placeItems: "center" }}
                            />
                        ))}
                    </Space>

                    {provider === "facebook" ? (

                    <Row style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                        <Col md={6} xs={8} style={{ display: "grid", placeItems: "center" }}>
                            <Statistic
                                value={reactions.like}
                                prefix={ReactionsIcons.like} />
                        </Col>
                        <Col md={6} xs={8} style={{ display: "grid", placeItems: "center" }}>
                            <Statistic
                                value={reactions.love}
                                prefix={ReactionsIcons.love} />
                        </Col>
                        <Col md={6} xs={8} style={{ display: "grid", placeItems: "center" }}>
                            <Statistic
                                value={reactions.haha}
                                prefix={ReactionsIcons.haha} />
                        </Col>
                        <Col md={6} xs={8} style={{ display: "grid", placeItems: "center" }}>
                            <Statistic
                                value={reactions.wow}
                                prefix={ReactionsIcons.wow} />
                        </Col>
                        <Col md={6} xs={8} style={{ display: "grid", placeItems: "center" }}>
                            <Statistic
                                value={reactions.sad}
                                prefix={ReactionsIcons.sad} />
                        </Col>
                        <Col md={6} xs={8} style={{ display: "grid", placeItems: "center" }}>
                            <Statistic
                                value={reactions.angry}
                                prefix={ReactionsIcons.angry} />
                        </Col>
                        <Col md={6} xs={8} style={{ display: "grid", placeItems: "center" }}>
                            <Statistic
                                value={reactions.takeCare}
                                prefix={ReactionsIcons.takeCare} />
                        </Col>
                    </Row>

                    ): null} 

                    <div style={{ display: "flex", }}>
                        {`This post was created on `}
                        <DateTimeDisplay dateTime={post.created_at} showDate style={{ marginLeft: "5px", marginRight: "5px" }} />
                        {`with `}
                        {DisplayPostSource(post.source)}
                    </div>
                </>


            </DetailsSection>
        )
    }

    return (
        <DetailsSection
            style={{ minHeight: "120px", display: "grid", placeItems: "center" }}
            ribbon={
                <div onClick={() => setIsOpen(true)}>
                    <FormattedMessage
                        id="post.management.showDetails"
                        defaultMessage="Show Details"
                    />
                </div>
            }
            onClick={() => setIsOpen((o: any) => !o)}>
            <Row style={{ width: "100%" }}>
                <Space style={{ width: "100%", justifyContent: "center" }} wrap size="large">

                    {PostStatsByProvider[provider].filter(stat => stat.showInCompactView).map((stat, index) => 
                    (
                        <Statistic
                            key={index}
                            title={stat.label}
                            value={post.getProviderInsights(stat.name, provider)}
                            style={{ display: "grid", placeItems: "center" }}
                        />
                    ))}

                </Space>
            </Row>
        </DetailsSection>
    )
}