import { Collapse, Input, Tooltip } from "antd";
import StandardButton from "components/common/StandardButton";
import { useEffect, useMemo, useState } from "react";
import FormattedMessage from "components/common/FormattedMessage";
import Tabs from "_components/tabs/tabs";
import { faCheckCircle, faCirclePlay, faCirclePlus, faLeft, faPlus, faPlusCircle, faRight, faTrashCircle } from "@fortawesome/pro-light-svg-icons";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import { GalleryWrapperForPostFormSingle } from "./GalleryWrappers";
import { ruleMax } from "tools/relaxForm/relaxPresets";
import URLInput from "components/inputs/URLInput";
import CTASelect from "tools/creationEditor/CTASelect";
import { CTAFieldRenderProps } from "modules/smart/components/create-post/form-generator";
import { useCreationEditor } from "../CreationEditor";
import useDataPrefill from "services/hooks/useDataPrefill";
import { creationContentSelector, patchCreation, patchCreationWithSmartAction } from "../creationsSlice";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";
import useAPI from "services/hooks/useAPI";
import _ from "lodash";
import CreationSection from "../editorTools/CreationSection";
import AwesomeIcon from "components/common/AwesomeIcon";
import useMemoDeepCompare from "services/hooks/useMemoDeepCompare";
import CarouselCard from "./CarouselCard";
import useEffectDeepCompare from "services/hooks/useEffectDeepCompare";
import CarouselCardGeneratorSection from "./CarouselCardGenerator";
import SectionStatusIconDisplay from "../editorTools/SectionStatusDisplay";


export default function CarouselCards(props: any) {

    const { creation, creationId } = useCreationEditor();
    const updateAPI = useAPI(patchCreation(creation.id))
    const slides = creation.content?.slides || {};
    const carousel:number[] = (creation.content?.carousel || []).map((i: any) => parseInt(i));
    const { sectionStatuses } = useCreationEditor()

    const hasSlides = slides && slides.length > 0;
    const hasCarousel = carousel && carousel.length > 0;

    const [activeKey, setActiveKey] = useState<string | undefined>(undefined);


    const addItem = (index: number) => {

        const newCarousel = [...carousel];
        let newSlideId:number = parseInt(Object.keys(slides).sort((a, b) => parseInt(a) - parseInt(b)).pop() || "1") + 1;
        newCarousel.push(newSlideId);

        updateAPI.call({
            body: {
                content: {
                    carousel: newCarousel,
                    slides: {
                        [newSlideId]: {
                            id: newSlideId,
                        }
                    }
                }
            }
        }).then(()=> {
            setActiveKey(`${newSlideId}`);
        })

    }




    const removeItem = (index: number) => {
        const items = [...carousel].map((i) => i != index ? i : null).filter((i) => i != null);
        //remove item at index from currentItems

        updateAPI.call({
            body: {
                content: {
                    carousel: items
                }
            }
        }).then(() => {
            setActiveKey(`${carousel[0]}`);
        })

    }


    const handleReorder = (newKeys: (number | string)[]) => {
        updateAPI.call({
            body: {
                content: {
                    carousel: newKeys.map(i => parseInt(i))
                }
            }
        }).then(() => {
        })

    }

    const mappedPanes = useMemoDeepCompare(() => {

        if (!creation) return [];
        if (!carousel) return [];

        const CardComponent = props.cardComponent || CarouselCard;

        return carousel.map((slideId: number, index: number) => {
            return {
                forceRender: true,
                title: (
                    <div>
                        <SectionStatusIconDisplay sectionName={`card-${slideId}`} />
                            <span style={{ padding: "0px 10px" }}>
                                {index + 1}
                            </span>
                    </div>
                ),
                key: `${slideId}`,
                content: (
                    <CardComponent 
                        slideId={slideId} 
                        removeItem={removeItem} 
                        carouselIndex={index}
                        numberOfItems={carousel.length} 
                    />
                ),
            }
        })

    }, [carousel])


    useEffect(() => {
        //if activeKey does not exist in carousel, set to first item
        if (!carousel) return;
        if (!activeKey) return;
        if (carousel.includes(parseInt(activeKey))) return;
       // console.log('setting active key to first item', carousel);
        setActiveKey(`${carousel[0]}`);
    }, [carousel])

    // useEffect(() => {
    // console.log('activeKey in cards', activeKey);
    // }, [activeKey]);

    if (!slides || slides.length == 0) return null;
    if (!carousel || carousel.length == 0) return null;

    return (
        <>
            <RelaxMultiform
                name="carouselItems"
                callAPI={patchCreationWithSmartAction(creationId, "ce_carousel")}
                initialValuesSelector={[creationContentSelector, creationId]}
            >
                <Tabs
                    activeKey={activeKey}
                    onChange={(key) => setActiveKey(key)}
                    buttons={[
                        <StandardButton 
                            icon={faPlus} 
                            size="small" 
                            shape="circle" 
                            onClick={() => addItem(carousel.length - 1)} 
                            disabled={carousel.length >= 5}
                        />,
                    ]}
                    panes={mappedPanes}
                    fitHeight="fit"
                    draggable
                    onReorder={handleReorder}
                />
            </RelaxMultiform>
        </>
    )

}