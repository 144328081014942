import StandardButton from "components/common/StandardButton"
import { forwardRef, useEffect, useMemo } from "react"
import FormattedMessage from "components/common/FormattedMessage";
import Gallery from "tools/gallery/Gallery"
import { useCreationEditor } from "../CreationEditor"
import React from "react";
import { faRecycle } from "@fortawesome/pro-light-svg-icons";
import useAPI from "services/hooks/useAPI";
import { RegenerateCreationImage } from "modules/panel/state/AISlice";
import AwesomeIcon from "components/common/AwesomeIcon";

const postImage = {
  "facebook": [
    {
      ratio: "f1.91:1",
      displayName: "1.91:1",
      aspect: 1.91,
      minWidth: 300,
      minHeight: 300,
    },
    {
      ratio: "f16:9",
      displayName: "16:9",
      aspect: 16 / 9,
      minWidth: 720,
      minHeight: 377,
    },
    {
      ratio: "f1:1",
      displayName: "1:1",
      aspect: 1,
      minWidth: 720,
      minHeight: 720,
    },
    {
      ratio: "f4:5",
      displayName: "4:5",
      aspect: 4 / 5,
      minWidth: 720,
      minHeight: 900,
    },
  ],
  "google": [
    {
      ratio: "g1.91:1",
      aspect: 1.91,
      displayName: "1.91:1",
      minWidth: 600,
      minHeight: 314,
    },
    {
      ratio: "g1:1",
      aspect: 1,
      displayName: "1:1",
      minWidth: 300,
      minHeight: 300,
    }
  ]
}

const postInstagramImage = {
  "instagram": postImage.facebook
}

const postInstagramThumbnail = {
  "instagram": [
    {
      ratio: "i9:16",
      displayName: "9:16",
      aspect: 9 / 16,
      minWidth: 600,
      minHeight: 600,
    }
  ]
}

const postLinkClick = {
  "facebook": [
    {
      ratio: "f1.91:1",
      displayName: "1.91:1",
      aspect: 1.91,
      minWidth: 1200,
      minHeight: 628,
    },
    {
      ratio: "f1:1",
      displayName: "1:1",
      aspect: 1,
      minWidth: 600,
      minHeight: 600,
    }
  ]
}

const aoaStandard = {
  "facebook": [
    {
      ratio: "f19.1",
      minWidth: 1200,
      minHeight: 628,
      aspect: 1.91,
      displayName: "1.91:1"
    },
    {
      ratio: "f1:1",
      minWidth: 600,
      minHeight: 600,
      aspect: 1,
      displayName: "1:1"
    }
  ],
  // "facebook-vertical": [
  //   {
  //     ratio: "fv9:16",
  //     displayName: "9:16",
  //     aspect: 9 / 16,
  //     minWidth: 628,
  //     minHeight: 628,
  //   }
  // ],
}

const aoaCarousel = {
  "facebook": [
    {
      ratio: "f1:1",
      minWidth: 600,
      minHeight: 600,
      aspect: 1,
      displayName: "1:1"
    },
  ],
}


const carousel = {
  "facebook": [
    {
      ratio: "f1:1",
      minWidth: 600,
      minHeight: 600,
      aspect: 1,
      displayName: "1:1"
    },
  ],
}

const carouselInstagram = {
  "instagram": [
    {
      ratio: "f1:1",
      minWidth: 600,
      minHeight: 600,
      aspect: 1,
      displayName: "1:1"
    },
  ],
}

const instagramStories = {
  "instagram": [
    {
      ratio: "f9:16",
      minWidth: 600,
      minHeight: 600,
      aspect: 9 / 16,
      displayName: "9:16"
    },
  ],
}

export const GalleryWrapperForPostFormSingle = (props: any) => {

  const { creation, type, subtype, creationId } = useCreationEditor();

  const cropPresets = useMemo(() => {

    if (!creation) return null;
    if (!type) return null;
    if (!subtype) return null;

    let preset: any;

    switch (subtype) {
      case "link":
        preset = postLinkClick;
        break;
      case "carousel":
        preset = carousel;
        break;
      case "instagram-image":
        preset = postInstagramImage;
        break;
      case "instagram-stories":
        preset = instagramStories;
        break;
      case "instagram-carousel":
        preset = carouselInstagram;
        break;
      case "instagram-link-call":
      case "instagram-link-message":
      case "instagram-link-social":
      case "instagram-link-website":
        preset = postInstagramImage;
      break;
      default:
        preset = postImage;
        break;
    }

    const providers = creation.content?.provider;

    //keep only presets that match the provider
    if (providers && providers.length > 0) {
      Object.keys(preset).forEach((key) => {
        if (!providers.includes(key.split('-')[0])) delete preset[key];
      })
    }

    return preset;

  }, [type, subtype]);

  const allowedTypes: ("image" | "video" | "file")[] = useMemo(() => {
    if (!subtype || !type) return [];
    if (subtype === "carousel") return ["image", "video"]
    if (subtype === "image") return ["image"];
    if (subtype === "video") return ["video"];
    if (subtype === "video_reel") return ["video"];
    if (subtype === "link") return ["image"];
    if (subtype === "messenger") return ["image", "video"];
    if (subtype === "instagram-image") return ["image"];
    if (subtype === "instagram-video") return ["video"];
    if (subtype === "instagram-carousel") return ["image", "video"];
    if (subtype === "instagram-stories") return ["image", "video"];
    if (subtype === "instagram-link-social") return ["image", "video"];
    if (subtype === "instagram-link-website") return ["image", "video"];
    if (subtype === "instagram-link-call") return ["image", "video"];
    if (subtype === "instagram-link-message") return ["image", "video"];
    return [];
  }, [type, subtype])

  const regenCall = useAPI(RegenerateCreationImage(creationId))

  const handleRegenerate = () => {
    regenCall.call();
  }


  if (!cropPresets) return null;

  if (creation && creation.template_id) {
    return (
      <Gallery
        requireCrop={true}
        allowedTools={["crop", "delete"]}
        allowedTypes={allowedTypes}
        cropPresets={cropPresets}
        onChange={props.onChange}
        onBlur={props.onBlur}
        value={props.value}
        showItemTooltip={true}
        fileRequirements={{
          maxFileSize: 8 * 1000 * 1000,
        }}
        uniqueIdentifier="hash"
        smartActionName="ce_media_upload"
        listSuffix={(
          <button style={{
            height: "100px",
            width: "100px",
            display: "grid",
            placeItems: "center",
            borderRadius: "20px",
            padding: "0px",
            margin: "0px",
          }}
            className="standard-border"
            onClick={handleRegenerate}
          >
            <AwesomeIcon icon={faRecycle} size="xl" />
          </button>
        )}
      />
    )
  }

  return (
    <Gallery
      requireCrop={true}
      allowedTools={["crop"]}
      allowedTypes={allowedTypes}
      singleImageOnly={true}
      cropPresets={cropPresets}
      onChange={props.onChange}
      onBlur={props.onBlur}
      value={props.value}
      showItemTooltip={true}
      fileRequirements={{
        maxFileSize: 8 * 1000 * 1000,
      }}
      uniqueIdentifier="hash"
      smartActionName="ce_media_upload"
    />
  )

}

export const GalleryWrapperForAOA = React.forwardRef((props: any, ref: any) => {
  const { creation, creationId } = useCreationEditor();

  const cropPresets = useMemo(() => {
    if (creation?.content?.format === "carousel") return aoaCarousel;
    return aoaStandard;
  }, [creation]);

  const type = creation?.content?.["media-type"]

  const mediaType = creation?.content?.["media-type"];
  const format = creation?.content?.format;

  const handleChange = (value: any) => {
    // console.log("handling change", value);
    props.onChange(value);
  }

  // useEffect(() => {
  //   console.log(`props.value`,props.value);
  // }, [props.value]);

  const regenCall = useAPI(RegenerateCreationImage(creationId))

  const handleRegenerate = () => {
    regenCall.call();
  }

  if (creation && creation.template_id) {
    return (
      <Gallery
        ref={ref}
        requireCrop={true}
        allowedTools={["crop", "delete"]}
        allowedTypes={[type]}
        cropPresets={cropPresets}
        onChange={handleChange}
        onBlur={props.onBlur}
        value={props.value || []}
        showItemTooltip={true}
        fileRequirements={{
          maxFileSize: 8 * 1000 * 1000,
        }}
        //valueChangeReplacesAll={true}
        //debounce={100}
        uniqueIdentifier="hash"
        smartActionName="ce_media_upload"
        listSuffix={(
          <button style={{
            height: "100px",
            width: "100px",
            display: "grid",
            placeItems: "center",
            borderRadius: "20px",
            padding: "0px",
            margin: "0px",
          }}
            className="standard-border"
            onClick={handleRegenerate}
          >
            <AwesomeIcon icon={faRecycle} size="xl" />
          </button>
        )}
      />
    )
  }

  if (!mediaType || !format) return <FormattedMessage
    id="apps.smart.containers.createPostAndAd.form.requireFormat"
    defaultMessage="Select media type and format first."
  />


  return (
    <Gallery
      ref={ref}
      requireCrop={true}
      allowedTools={["crop", "delete"]}
      allowedTypes={[type]}
      cropPresets={cropPresets}
      onChange={handleChange}
      onBlur={props.onBlur}
      value={props.value || []}
      showItemTooltip={true}
      fileRequirements={{
        maxFileSize: 8 * 1000 * 1000,
      }}
      //valueChangeReplacesAll={true}
      //debounce={100}
      uniqueIdentifier="hash"
      smartActionName="ce_media_upload"
    />
  )
})

export const GalleryWrapperThumbnail = (props: any) => {

  return (
    <Gallery
      requireCrop={true}
      allowedTools={["crop"]}
      allowedTypes={["image"]}
      singleImageOnly={true}
      cropPresets={postInstagramThumbnail}
      onChange={props.onChange}
      onBlur={props.onBlur}
      value={props.value}
      showItemTooltip={true}
      fileRequirements={{
        maxFileSize: 8 * 1000 * 1000,
      }}
      uniqueIdentifier="hash"
      smartActionName="ce_media_upload"
    />
  )

}