import { Spin } from "antd";
import { HorizontalCenter } from "components/common/Center";
import FormattedMessage from "components/common/FormattedMessage";
import { Title } from "components/common/StandardText";
import { useEffect } from "react";
import useAPI, { callAPIFunction, callAPIProps } from "services/hooks/useAPI"

const getStatements: callAPIProps = {
    url: ({ getApiUrl, userId }) => getApiUrl(`user/statements`),
    //auth: false,
    //customNormalizer: (response) => response.content || response,
}


export const StatementPageTermsOfService = () => {

    const statementCall = useAPI(getStatements, true)

    if (statementCall.loading) return <HorizontalCenter><Spin /></HorizontalCenter>

    const statement = statementCall.data?.find((s: any) => s.code === 'terms_of_service')

    return (
        <div>
                {statement?.statement}
        </div>
    )

}


export const StatementPageMarketingConsents = () => {

    const statementCall = useAPI(getStatements, true)

    if (statementCall.loading) return <HorizontalCenter><Spin /></HorizontalCenter>

    const statement = statementCall.data?.find((s: any) => s.code === 'marketing_consents')

    return (
        <div>
            {statement?.statement}
        </div>
    )

}