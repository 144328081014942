import TextArea from "antd/es/input/TextArea";
import StandardButton from "components/common/StandardButton";
import lexiconSlice, { AiCacheFieldSelector, GeneratePost, GetAiCache } from "modules/panel/state/lexiconSlice";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import useAPI, { APIErrorDisplay } from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import SelectFromLexicon from "../SelectFromLexicon";
import { AiPostList } from "./AIPostList";

export default function AILexiconTestPanel(props:any) {
  const [finalData, setFinalData] = useState({
    style_id: 0,
    type_id: 0,
    message: ""
  })

  const titles = useAPI(GetAiCache("title"), true)
  const {loading}= useAPI(GetAiCache("text"), true)
  const { call: addPost, loading: loadnigPost, error, StatusDisplay } = useAPI({});
  const aiCacheTitles = useSelectorWithParams([AiCacheFieldSelector, "title"])
  const aiCacheTexts = useSelectorWithParams([AiCacheFieldSelector, "text"])

  const handleSubmit = async () => {
    const { style_id, type_id, message } = finalData;
    const response = addPost(GeneratePost(style_id, type_id, message)).then((res)=>{
    });
  };
  

  if (loading) return StatusDisplay

  return (
    <>
      <RelaxMultiform
        name="lexicon"
        submitOverride={(data: any) => {
          setFinalData((prevData) => ({
            ...prevData,
            style_id: data.style === undefined ? prevData.style_id : data.style,
            type_id: data.type === undefined ? prevData.type_id : data.type,
            message: data.message === undefined ? prevData.message : data.message
          }));
        }}
        callAPI={{
          url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/ai/post`),
          method: "POST",
          auth: true,
          successDispatch: lexiconSlice.actions.add
        }}
      //initialValuesSelector={LexiconSelector}
      >
        <RelaxSingleField
          name="type"
          label={<FormattedMessage
            id="type"
            defaultMessage="Type" />
          }>
          <SelectFromLexicon selectedType="type"/>
        </RelaxSingleField>

        <RelaxSingleField
          name="style"
          label={<FormattedMessage
            id="style"
            defaultMessage="Style" />
          }>
          <SelectFromLexicon  selectedType="style"/>
        </RelaxSingleField>

        <RelaxSingleField
          name="message"
          label={<FormattedMessage
            id="message"
            defaultMessage="Message" />
          }>
          <TextArea rows={4} />
        </RelaxSingleField>

        <StandardButton loading={loadnigPost} onClick={handleSubmit}>Submit</StandardButton>
          <APIErrorDisplay error={error}/>
          <br/>
      </RelaxMultiform>
      <br />
         <AiPostList titles={aiCacheTitles} texts={aiCacheTexts}/>
    </>
  )



}