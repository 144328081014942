import { Button, Form, Input, Radio, Select } from "antd";
import useDebounce from "services/hooks/useDebounce";
import React, { useState, ReactNode, useEffect, useMemo } from "react";
import DateInput from "_components/forms/date-input";
import { PlatformSelector } from "_components/forms/platform-selector";
import useUserSettings from "services/hooks/useUserSettings";
import StandardButton from "components/common/StandardButton";
import { faArrowRotateRight } from "@fortawesome/pro-light-svg-icons";
import FormattedMessage from "components/common/FormattedMessage";
import DatePicker from "components/inputs/DatePicker";
import dayjs, { Dayjs } from 'dayjs';

type InfinityListQueryProps = {
    query?:any
    defaultRange?:any
    sortedBy?:any
    onChange:(value:any) => void
    sort?:boolean
    date?:boolean
    refresh?:boolean
    onReload:() => void
    children?: ReactNode
}

export const InfinityListDateRangePicker = (props: { value: [Dayjs, Dayjs], onChange: (date: [Dayjs, Dayjs]) => void }) => {

    const getRange = (): [Dayjs, Dayjs] => {
        if (props.value) return props.value;
        return [dayjs(), dayjs().add(1, "week")];
    }

    return (
        <DatePicker.RangePicker
            style={{maxWidth: "250px"}}
            value={getRange()}
            onChange={(date) => props.onChange(date)}
        />
    )
}

export default function InfinityListQuery(props: InfinityListQueryProps) {

    const { subscriptionSettings, updateSubscriptionSettings } = useUserSettings();
    


    const InitialQuery = useMemo(() => {
        if (props.query) return props.query;

        return {
            "view": "basic",
            "start": props.defaultRange ? dayjs().subtract(1, props.defaultRange) : dayjs().subtract(1, "week"),
            "end": dayjs(),
            "platform": "all",
            "search": undefined,
            "sort": props.sortedBy ? `-${props.sortedBy}` : "-created_at",
        }
    }, [props.query, subscriptionSettings]);

    const [query, setQuery] = useState<any>(InitialQuery)
    const outValue = useDebounce(query, 500);

    function handleValueChange(changed: any, all: any) {

        const newQuery = {
            ...query,
            "platform": all.platform || "all",
            "search": all.search,
            "sort": all.sort,
        };
        if (all.date) {
            newQuery["start"] = all.date[0];
            newQuery["end"] = all.date[1];
        }
        setQuery({ ...newQuery });
    }

    useEffect(() => {
        if (props.onChange) props.onChange(outValue);
    }, [outValue])

    useEffect(() => {
        if (!query) return;
        // updateSubscriptionSettings({
        //     postListQuery: query
        // })
    }, [query]);


    return (
        <Form className="infinity-tools"
            onValuesChange={handleValueChange}
            initialValues={query}
        >
            {props.children}
            {props.sort && (
                <Form.Item name="sort" className="sort">
                    <Select defaultValue={query.sort}>
                        <Select.Option value={props.sortedBy ? `-${props.sortedBy}` : "-created_at"}>
                            <FormattedMessage
                                id="posts.list.filtering.sort.newestFirst"
                                defaultMessage="Newest First"
                            />
                        </Select.Option>
                        <Select.Option value={props.sortedBy ? `${props.sortedBy}` : "created_at"}>
                            <FormattedMessage
                                id="posts.list.filtering.sort.oldestFirst"
                                defaultMessage="Oldest First"
                            />
                        </Select.Option>
                    </Select>
                </Form.Item>
            )}
            {props.date && (
                <Form.Item name="date" className="date">
                    <DatePicker.RangePicker
                        defaultValue={[query["start"], query["end"]]}
                    />
                </Form.Item>
            )}

            {(props.refresh === undefined || props.refresh === true) && (
                <StandardButton icon={faArrowRotateRight} onClick={() => props.onReload && props.onReload()} />
            )}

        </Form>
    );
}