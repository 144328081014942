import { faCheckCircle, faExclamationCircle, faQuestionCircle, faXmarkCircle } from "@fortawesome/pro-light-svg-icons"
import { ReactNode } from "react"
import AwesomeIcon from "./AwesomeIcon"
import "./common.less"

type ResultProps = {
    result: "success" | "error" | "warning" | "info" | "ok" | string,
    message: ReactNode,
    extra?: ReactNode,
    onClick?: () => void
}

const IconByResult: Record<ResultProps["result"], ReactNode> = {
    ok: <AwesomeIcon icon={faCheckCircle} />,
    success: <AwesomeIcon icon={faCheckCircle} />,
    error: <AwesomeIcon icon={faXmarkCircle} />,
    warning: <AwesomeIcon icon={faExclamationCircle} />,
    info: <AwesomeIcon icon={faQuestionCircle} />
}


export const StandardResult = ({ result, message, extra, onClick }: ResultProps) => {


    return (
        <div className="standard-result" onClick={onClick}>
            <div className={"result " + result} >
                <div className="icon">
                    {IconByResult[result]}
                </div>
                <div className="message">
                    {message}
                </div>
            </div>
            <div className="extra">
                {extra}
            </div>
        </div>
    )
}