
import FormattedMessage from "components/common/FormattedMessage";
import StandardButton from "components/common/StandardButton";
import { TagButton } from "components/common/TagButton";
import dayjs from "dayjs";
import { FacebookAdsSettingsSelector, smartSlice } from "modules/smart/smartSlice";
import { useEffect, useMemo, useRef } from "react";
import useAPI from "services/hooks/useAPI";
import { useSimpleModalWithAction } from "services/hooks/useModal";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { currentProjectSelector } from "state/projects/projectSelectors";
import RelaxField from "tools/relaxForm/RelaxField";
import { rulesetMax } from "tools/relaxForm/relaxPresets";
import useRelaxData from "tools/relaxForm/useRelaxData";
import PostTextEditorWithSuspense from "tools/textEditor/PostTextEditorWithSuspense";
import TextEditorNew from "tools/textEditor/TextEditorNew";

//pageName, projectName, date, type, objective, specialType, specialPart, typePart, datePart

const options = [
    {
        label: "Page Name",
        value: "{pageName}"
    },
    {
        label: "Project Name",
        value: "{projectName}"
    },
    {
        label: "Date",
        value: "{date}"
    },
    {
        label: "Type",
        value: "{type}"
    },
    {
        label: "Objective",
        value: "{objective}"
    },
    {
        label: "Special Type",
        value: "{specialType}"
    },
]


type CampaignNameCustomizerProps = {
    value: string;
    onChange: (newValue: string) => void;
    onBlur: () => void;
}

const CampaignNameCustomizerInput: React.FC<CampaignNameCustomizerProps> = (props) => {

    const editorRef = useRef(null);

    const handleInsert = (value: string) => {
        //props.onChange && props.onChange((props.value || "") + value);
        editorRef?.current?.insertText && editorRef.current.insertText(value);
    }

    const handleChange = (value: string) => {
        props.onChange && props.onChange(value);
    }


    return (

        <div>
            <div style={{ display: "flex", flexDirection: "row", paddingBottom: "10px" }}>
                {options.map((option, index) => (
                    <TagButton key={index} onClick={() => handleInsert(option.value)}>{option.label}</TagButton>
                ))}
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
            }}>
                <TextEditorNew
                    ref={editorRef}
                    value={props.value}
                    onChange={handleChange}
                    onBlur={props.onBlur}
                    wordsToHighlight={options.map(option => option.value)}
                    regexp={(kwd: string) => kwd}
                />
            </div>
        </div>
    )
}

const AskToChangeCampaingName = (props?: any) => {

    const [open, contextHolder] = useSimpleModalWithAction({
    });

    const handleOpen = (newValue: string) => {
        open({
            title: <FormattedMessage
                id="panel.containers.advancedSettings.campaignNamingScheme.confirmation.title"
                defaultMessage="Update campaign names"
            />,
            content: (
                <div>
                    <FormattedMessage
                        id="panel.containers.advancedSettings.campaignNamingScheme.confirmation.description"
                        defaultMessage="Are you sure you want to update the campaign names schema? This will rename all your campaigns."
                    />
                </div>
            ),
            onOk: () => {
            },
            callAPI: {
                url: ({ getApiUrl, projectId, smart }) => getApiUrl(`projects/${projectId}/smarts/${smart}/facebook-ads-settings`, 2),
                method: "PATCH",
                successDispatch: smartSlice.actions.updateFacebookAdsSettings,
                body: {
                    campaign_name_template: newValue
                }
            }
        })
    }

    return {
        open: handleOpen,
        contextHolder,
    }

}

const CampaignNameExampleDisplay = ({ name }: { name: string }) => {

    const project = useSelectorWithParams(currentProjectSelector);
    const facebookPage = project ? project.service_facebook_page.global_brand_page_name : "Facebook Page Name";

    const parsedName = useMemo(() => {
        if (!name) return "";
        if (!name.length) return "";

        return name
            .replace("{pageName}", facebookPage)
            .replace("{projectName}", project ? project.name : "Project Name")
            .replace("{date}", dayjs().format("DD.MM.YYYY g. HH:mm"))
            .replace("{type}", "link")
            .replace("{objective}", "OUTCOME_ENGAGEMENT")
            .replace("{specialType}", "housing")

    }, [name])

    if (!parsedName || !parsedName.length) return null;

    return (
        <>
            <FormattedMessage
                id="panel.containers.advancedSettings.campaignNamingScheme.example"
                defaultMessage="Example:"
            />
            <div className="ant-typography" style={{ display: "flex" }}>
                <code style={{ width: "100%", padding: "10px" }}>
                    {parsedName}
                </code>
            </div>
        </>
    )

}

const CampaignNameCustomizer: React.FC = () => {

    const { open, contextHolder } = AskToChangeCampaingName();

    const rd = useRelaxData({
        name: "campaign_name_template",
        initialValuesSelector: FacebookAdsSettingsSelector,
        rules: rulesetMax(512)
    });

    const handleSave = () => {
        open(rd.currentValue);
    }

    return (
        <div>
            <RelaxField
                relaxData={rd}
                mode="manual"
                label={
                    <FormattedMessage
                        id="panel.containers.advancedSettings.campaignNamingScheme"
                        defaultMessage="Campaign naming scheme"
                    />
                }
            >
                <CampaignNameCustomizerInput />

            </RelaxField>
            <div>
                <CampaignNameExampleDisplay name={rd.currentValue} />
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                <StandardButton
                    disabled={rd.hasErrors || !rd.changed}
                    onClick={handleSave}
                >
                    <FormattedMessage
                        id="common.save"
                        defaultMessage="Save"
                    />
                </StandardButton>
            </div>
            {contextHolder}
        </div>
    )


}

export default CampaignNameCustomizer;

