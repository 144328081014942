import React, { Component } from "react";
import Tabs from "_components/tabs/tabs";
import FormattedMessage from "components/common/FormattedMessage";
import FacebookAdsSettings from "./SettingsFacebookAds";
import SettingsBusiness from "./SettingsBusiness";
import SettingsGoogleKeywords from "./SettingsGoogleKeywords";
import SettingsGoogleGeneral from "./SettingsGoogleGeneral";
import SettingsAI from "modules/smart/components/AIGeneration/SettingsAI";
import AwesomeIcon from "components/common/AwesomeIcon";
import { faBooks, faPeople, faRobot, faUserTie } from "@fortawesome/pro-light-svg-icons";
import SettingsDataSources from "./SettingsDataSources";
import SettingsAIExampleClients from "./SettingsAIExampleClients";
import { FACEBOOK_PAGES, GOOGLE_AD_ACCOUNTS, ProviderConfig, getProviderFieldConfigByName } from "modules/panel/config/ProviderConfig";
import { ProviderFieldIcon } from "modules/panel/config/ProviderHelpers";
import PlatformIcon from "components/common/icons/PlatformIcon";

export default function Settings () {
    return (
      <Tabs
        className="stylized-content"
        panes={[
          {
            title: (
              <FormattedMessage
                id="apps.smart.containers.adsSettings.edit.business"
                defaultMessage="Business"
              />
            ),
            key: "business",
            content: <SettingsBusiness />,
            requiredPermission: "use module smart business settings",
          },
          {
            title: (
              <FormattedMessage
                id="apps.smart.containers.adsSettings.edit.facebook.ads"
                defaultMessage="Facebook Ads"
              />
            ),
            //integration: "facebook",
            key: "facebook-ads",
            content: <FacebookAdsSettings />,
            icon: <PlatformIcon platform="facebook" size="small"/>,
            requiredPermission: "use module smart facebook settings",
          },
          {
            title: (
              <FormattedMessage
                id="apps.smart.containers.adsSettings.edit.instagram.ads"
                defaultMessage="Instagram Ads"
              />
            ),
            key: "instagram-ads",
            content: <FacebookAdsSettings />,
            icon: <PlatformIcon platform="instagram" size="small"/>,
            requiredPermission: "use module smart instagram settings",
          },
          {
            title: (
              <FormattedMessage
                id="apps.smart.containers.adsSettings.edit.googleAds"
                defaultMessage="Google Ads"
              />
            ),
            key: "google-ads",
            content: <SettingsGoogleGeneral budget="full"/>,
            icon: <PlatformIcon platform="google" size="small"/>,
            requiredPermission: "use module smart google settings",
          },
          {
            title: (
              <FormattedMessage
                id="apps.smart.containers.adsSettings.edit.googleGeneral"
                defaultMessage="Google Ads"
              />
            ),
            key: "googleGeneralSettings",
            content: <SettingsGoogleGeneral budget="general"/>,
            icon: <ProviderFieldIcon field={GOOGLE_AD_ACCOUNTS} style={{width: "22px", height: "22px"}}/>,
            requiredPermission: "use module forsant google settings",
          },
          {
            title: (
              <FormattedMessage
                id="apps.smart.containers.adsSettings.edit.googleKeywords"
                defaultMessage="Google Keywords"
              />
            ),
            integration: "google",
            key: "google-keywords",
            content: <SettingsGoogleKeywords />,
            icon: <ProviderFieldIcon field={GOOGLE_AD_ACCOUNTS} style={{width: "22px", height: "22px"}}/>,
            requiredPermission: "use module forsant google keywords",
          },
          {
            title: (
              <FormattedMessage
              id="apps.smart.containers.adsSettings.edit.adSettings"
              defaultMessage="Ai Settings"
            />
            ),
            key: "ai-settings",
            content: <SettingsAI />,
            icon: <AwesomeIcon icon={faRobot} size="xl" style={{paddingRight: "10px"}}/>,
            requiredPermission: "use module smart ai settings",
          },
          {
            title: (
              <FormattedMessage
                id="apps.smart.containers.adsSettings.edit.dataSources"
                defaultMessage="Data Sources"
              />
            ),
            key: "dataSources",
            content: <SettingsDataSources />,
            icon: <AwesomeIcon icon={faBooks} size="xl" style={{paddingRight: "10px"}}/>,
            requiredPermission: "use module smart ai settings",
          },
          {
            title: (
              <FormattedMessage
                id="apps.smart.containers.adsSettings.edit.exampleClients"
                defaultMessage="Example Cleints"
              />
            ),
            key: "exampleClients",
            content: <SettingsAIExampleClients />,
            icon: <AwesomeIcon icon={faPeople} size="xl" style={{paddingRight: "10px"}}/>,
            requiredPermission: "use module smart ai settings",
          }
        ]}
      />
    );
}

