import React, { Children, ReactElement, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import FormattedMessage from "components/common/FormattedMessage";
import useAPI, { APIErrorDisplay, callAPIFunction, callAPIProps } from "./useAPI";

export type TasksProps = {
    tasks?: TaskProps[];
    onTaskUpdate?: (tasks: TaskWithState[]) => void;
    onStateChange?: (state: AllTaskState) => void;
}

export type TaskProps = {
    task: callAPIProps | callAPIFunction;
    autoStart?: boolean;
    displayName: string | ReactElement;
    onStarted?: () => void;
    onSuccess?: (data:any) => void;
    onError?: (error:any) => void;
}

export type TaskState = "idle" | "pending" | "success" | "error";

export const Task = React.forwardRef((props: TaskProps, ref: any) => {

    const getTask = () => {
        if (typeof props.task === "function") return props.task();
        return props.task;
    }

    const {call, data, loading, error, StatusDisplay} = useAPI(getTask(), false);
    const [taskState, setTaskState] = useState<TaskState>("idle");

    useEffect(() => {
        if (props.autoStart) startTask();
    }, [props.autoStart]);

    const startTask = (callAPI?:callAPIProps) => {
        setTaskState("pending");
        props.onStarted && props.onStarted();
        if (callAPI) {
            return call(callAPI);
        } else {
            return call(getTask());
        }
    }

    useEffect(() => {
        if (!data) return;
        setTaskState("success");
        props.onSuccess && props.onSuccess(data);
    }, [data]);

    useEffect(() => {
        if (!error) return;
        setTaskState("error");
        props.onError && props.onError(error);
    }, [error]);

    useImperativeHandle(ref, () => ({
        taskState,
        startTask,
    }))

    const taskStatus = useMemo(() => {
        if (loading) return (
            <FormattedMessage
                id="common.pleaseWait"
                defaultMessage="Please Wait"
            />
        )
        if (error) return (
            <APIErrorDisplay error={error} />
        )
        if (data) return (
            <FormattedMessage
            id="common.success"
            defaultMessage="Success"
        />
        )
    }, [loading, error, data]);

    return (
        <div className="task" style={{display: "flex", gap: "10px", flexDirection: "row"}}>
            <div className="task-name">
                {props.displayName}
            </div>
            <div className="task-status">
                {taskStatus}
            </div>
        </div>
    )

})

export type TaskWithState = TaskProps & {taskState?: TaskState};
export type AllTaskState = "idle" | "pending" | "partialSuccess" | "success" | "error";

export const TaskList = React.forwardRef((props: TasksProps, ref: any) => {

    const [tasks, setTasks] = useState<TaskWithState[] | undefined>(props.tasks);


    const addTask = (task: any) => {
        if (tasks) setTasks([...tasks, task])
        else setTasks([task]);
    }

    const checkTasks = (task: any, state: TaskState) => {
        task.taskState = state;
        if (!tasks) return;
        if (props.onTaskUpdate) props.onTaskUpdate(tasks);

        const allState = tasks.map(t => t.taskState);
        if (allState.every(s => s === "success")) {
            if (props.onStateChange) props.onStateChange("success");
            return;
        }
        if (allState.every(s => s === "error")) {
            if (props.onStateChange) props.onStateChange("error");
            return;
        }
        if (allState.some(s => s === "error")) {
            if (props.onStateChange) props.onStateChange("partialSuccess");
            return;
        }
        if (allState.some(s => s === "pending")) {
            if (props.onStateChange) props.onStateChange("pending");
            return;
        }
        if (props.onStateChange) props.onStateChange("idle");

    }

    useImperativeHandle(ref, () => ({
        tasks,
        addTask,
    }))

    return (
        <div className="task-list">
            {tasks && tasks.map((task, index) => 
            <Task 
            {...task}
            onError={(error) => {
                checkTasks(task, "error");
                task.onError && task.onError(error);
            }}
            onSuccess={(data) => {
                checkTasks(task, "success");
                task.onSuccess && task.onSuccess(data);
            }}
            onStarted={() => {
                checkTasks(task, "pending");
                task.onStarted && task.onStarted();
            }}
            /> )}
        </div>
    )


})

export const useTasks = () => {

    const ref: any = useRef();
    const addTask = () => ref.current?.addTask(true);
    const tasks = () => ref.current?.tasks(false);

    return [ref, tasks, addTask]
}

