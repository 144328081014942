
import { OrganizationProjectsSelector, OrganizationGetGroups, OrganizationGetManagers, OrganizationGetProjects, OrganizationGetInvitations, OrganizationGetOrganization } from "tools/organization/data/organizationSlice";
import useAPI, { APIErrorDisplay, callAPIProps } from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { ReactNode, useEffect, useState } from "react";
import Tabs from "_components/tabs/tabs";
import OrganizationProjectsTab from "./OrganizationProjectsTab";
import OrganizationGroupsTab from "./OrganizationGroupsTab";
import React from "react";
import { StandardModal, useStandardModal } from "services/hooks/useModal";
import OrganizationManagersTab from "./OrganizationManagersTab";
import OrganizationInvitationsTab from "./OrganizationInvitationsTab";
import OrganizationTableFull from "./components/OrganizationTableFull";
import TasksList from "tools/tasks/TasksList";
import OrganizationTab from "./OgranizationTab";
import FormattedMessage from "components/common/FormattedMessage";
import { GetProjectSettings } from "state/project/projectSettingsSlice";
import { currentFlatSubscriptionPermissionsSelector } from "state/subscriptions/subscriptionsSelectors";
import { Spin } from "antd";

export type tool = {
    key: string,
    label: ReactNode,
    title: ReactNode,
    component?: ReactNode,
    disabled?: (inputData: any) => boolean,
    toolAPICall?: (inputData: any, outputData: any) => callAPIProps,
}

export type toolsGroup = {
    key: string,
    label: ReactNode,
    tools: tool[]
}


const panes = [
    {
        title: <FormattedMessage
            id="organization.tab.organization"
            defaultMessage="Organization" />,
        key: "organization",
        content: <OrganizationTab />,
        requiredPermission:"use organization manage organization"
    },
    {
        title: <FormattedMessage
            id="organization.tab.projects"
            defaultMessage="Projects" />,
        key: "projects",
        content: <OrganizationProjectsTab />,
        requiredPermission:"use organization manage projects"
    },
    {
        title: <FormattedMessage
            id="organization.tab.groups"
            defaultMessage="Groups" />,
        key: "groups",
        content: <OrganizationGroupsTab />,
        requiredPermission:"create partner group"
    },
    {
        title: <FormattedMessage
            id="organization.tab.fullOrganization"
            defaultMessage="Full Organization" />,
        key: "full",
        content: <OrganizationTableFull />,
        requiredPermission:"use organization manage projects"
    },
    {
        title: <FormattedMessage
            id="organization.tab.managers"
            defaultMessage="Managers" />,
        key: "managers",
        content: <OrganizationManagersTab />,
        requiredPermission:"use orgazaniton show groups admin"
    },
    {
        title: <FormattedMessage
            id="organization.tab.invites"
            defaultMessage="Invites" />,
        key: "invites",
        content: <OrganizationInvitationsTab />,
        requiredPermission:"use organization manage invitations"
    },
    {
        title: <FormattedMessage
            id="organization.tab.tasks"
            defaultMessage="Tasks" />,
        key: "tasks",
        content: <TasksList mode="organization" />,
        requiredPermission:"use organization manage tasks"
    }
]

const OrganizationToolContext = React.createContext({} as any);
OrganizationToolContext.displayName = "Organization Tool Context";
export const useOrganizationTool = (): any => React.useContext(OrganizationToolContext);

export default function OrganizationManager () {
    const [loading, setLoading] = useState(true);

    const orgGetOrganization = useAPI(OrganizationGetOrganization());
    const orgGetProjects = useAPI(OrganizationGetProjects());
    const orgGetManagers = useAPI(OrganizationGetManagers());
    const orgGetGroups = useAPI(OrganizationGetGroups());
    const orgGetInvitations = useAPI(OrganizationGetInvitations());
    const projects = useSelectorWithParams(OrganizationProjectsSelector)

    const projectSettingsCall = useAPI(GetProjectSettings)

    const [toolModalRef, toolModalOpen, toolModalClose] = useStandardModal();
    const [tool, setTool] = useState<any>(null);
    const [inputData, setInputData] = useState<any>(null);
    const [outputData, setOutputData] = useState<any>(null);

    const toolAPICall = useAPI({})

    const handleCancel = () => {
       // console.log("Handle cancel")
    }

    useEffect(() => {
        const apiCalls = [
            orgGetOrganization.call(),
            orgGetProjects.call(),
            orgGetManagers.call(),
            orgGetGroups.call(),
            orgGetInvitations.call(),
            projectSettingsCall.call()
        ];
    
        Promise.all(apiCalls)
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    }, []);

    const handleOk = () => {   
        if (!tool.toolAPICall) return;
        toolAPICall.call(tool.toolAPICall({...inputData}, outputData)).then((res:any) => {
            if (res.status === 200 || res.status === 201 || res.status === 204) {
                toolModalClose();
            }
        })
    }

    const openToolModal = (tool: any) => {
        setTool(tool);
        if (!tool || !tool.component) return;
        toolModalOpen();
    }


    return (
        <OrganizationToolContext.Provider value={{
            tool: tool, 
            openTool: openToolModal,
            inputData: inputData,
            setInputData: setInputData,
            outputData: outputData,
            setOutputData: setOutputData
        }}>
            <Spin spinning={loading} size="large" style={{marginTop: "20px"}}>
                {!loading && <Tabs panes={panes} />}
            </Spin>
        <StandardModal
            ref = {toolModalRef}
            onCancel = {handleCancel}
            onOk = {handleOk}
            title = {tool?.title}
            loading = {toolAPICall.loading}
            modalProps = {{
                width: 800,
            }}
            >
                {tool?.component}
                <APIErrorDisplay api={toolAPICall} />
            </StandardModal>
        </OrganizationToolContext.Provider>
    )

}