import React, { FunctionComponent, useEffect } from "react";
import { userLanguageSelector } from "modules/panel/config/selectors/user";
import { useSelector } from "react-redux";
import AntProvider from "providers/languages/ant-provider";
import { userSettingsInterfaceLanguageSelector } from "state/user/userSettingsSlice";
import TranslationProvider from "./TranslationProvider";

export const SUPPORTED_LANGUAGES = ["pl", "en"] as const;

export type Language = typeof SUPPORTED_LANGUAGES[number];

const LanguageProvider: FunctionComponent = ({ children }) => {

  const language = useSelector(userSettingsInterfaceLanguageSelector);

  return (
    <AntProvider language={language}>
      <TranslationProvider language={language}>{children}</TranslationProvider>
    </AntProvider>
  );
};



export default LanguageProvider;
