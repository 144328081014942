import React from "react";
import { CheckOutlined } from '@ant-design/icons';
import FormattedMessage from "components/common/FormattedMessage";
import { TooltipIcon } from "components/common/tooltip-icon/TooltipIcon";
import './integrations.less'

const ConnectButton = ({ logo, name, url, connected = false }) => (
  <div style={{ display: "flex" }}>
    <div
      className={`integrations-connect-btn ${name}`}
      onClick={() => {
        window.location.href = url;
      }}
    >
      <span className="logo-container"><img src={logo} style={{width: "40px", height: "40px"}}/></span>
      <span className="continue">
        <FormattedMessage
          id="components.connectButton.continueWithPlatform"
          defaultMessage="Click to login and authorize with {platform}"
          values={{ platform: name }}
        />
      </span>
      <span></span>
      {connected && (
        <div className="connected-indicator">
          <CheckOutlined className="integrated-icon" />
        </div>
      )}
    </div>
    <div style={{marginTop:"25px"}}>
    <TooltipIcon title={
      <FormattedMessage
        id="components.connectButton.continueWithPlatform.tooltip"
        defaultMessage="Click to login and authorize with {platform}"
        values={{ platform: name }}
      />}
    />
    </div>

  </div>
);

export default ConnectButton;
