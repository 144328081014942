import { RSAA } from "redux-api-middleware";
import {
  getApiUrl,
  parseArrayToObjectWithSpecifedKey,
} from "services/helpers/api-helpers";
import { parseProjects } from "state/projects/projectHelpers";
import { TokenLocalStorage } from "services/helpers/local-storage";
import { LanguageHelper } from "providers/languages/helper";

export const LOGIN = "LOGIN";
export const LOGIN_REQUEST = `${LOGIN}_REQUEST`;
export const LOGIN_SUCCESS = `${LOGIN}_SUCCESS`;
export const LOGIN_FAILURE = `${LOGIN}_FAILURE`;

export const login = (loginForm) => {
  return (dispatch) => {
    dispatch({
      [RSAA]: {
        endpoint: getApiUrl("auth/login"),
        method: "POST",
        headers: { "Content-Type": "application/json" },
        types: [
          LOGIN_REQUEST,
          {
            type: LOGIN_SUCCESS,
            payload: (action, state, res) =>
              res.json().then((response) => {
                dispatch(setTimeoutToRefreshToken(response.token));
                return response;
              }),
          },
          LOGIN_FAILURE,
        ],
        body: JSON.stringify(loginForm),
      },
    });
  };
};

export const LOAD_USER = "LOAD_USER";
export const LOAD_USER_REQUEST = `${LOAD_USER}_REQUEST`;
export const LOAD_USER_SUCCESS = `${LOAD_USER}_SUCCESS`;
export const LOAD_USER_FAILURE = `${LOAD_USER}_FAILURE`;

export const loadUser = () => {
  return {
    [RSAA]: {
      endpoint: getApiUrl("user", 2),
      method: "GET",
      authorized: true,
      types: [
        LOAD_USER_REQUEST,
        {
          type: LOAD_USER_SUCCESS,
          payload: (action, state, res) =>
            res.json().then((response) => ({
              ...response,
              ...parseProjects(response.projects.data),
              access_tokens: parseArrayToObjectWithSpecifedKey(
                response.access_tokens,
                "provider"
              ),
              // websites: parseArrayToObjectWithSpecifedKey(
              //   response.features.website.data,
              //   "project_id"
              // ),
              // smart: parseArrayToObjectWithSpecifedKey(
              //   response.features.smart.data,
              //   "project_id"
              // ),
              product_subscriptions: parseArrayToObjectWithSpecifedKey(
                response.product_subscriptions,
                "id"
              ),
              statements: parseArrayToObjectWithSpecifedKey(
                response.statements,
                "code"
              ),
            })),
        },
        LOAD_USER_FAILURE,
      ],
    },
  };
};

export const LOGOUT = "LOGOUT";
export const LOGOUT_REQUEST = `${LOGOUT}_REQUEST`;
export const LOGOUT_SUCCESS = `${LOGOUT}_SUCCESS`;
export const LOGOUT_FAILURE = `${LOGOUT}_FAILURE`;

export const logout = (callback = false) => {
  TokenLocalStorage.set(null);

  return {
    [RSAA]: {
      endpoint: getApiUrl("auth/logout"),
      method: "GET",
      authorized: true,
      types: [
        LOGOUT_REQUEST,
        {
          type: LOGOUT_SUCCESS,
          payload: (action, state, res) =>
            res.json().then((response) => {
              if (callback) callback();
            }),
        },
        LOGOUT_FAILURE,
      ],
    },
  };
};

const calculateExpirationUnixTimestamp = (token) =>
  (token.expiration_timestamp = ((Date.now() / 1000) | 0) + token.expires_in);

export const setTimeoutToRefreshToken = (token) => {
  return (dispatch) => {
    calculateExpirationUnixTimestamp(token);
    TokenLocalStorage.set(token);

    let expiresInMiliseconds = token.expires_in * 1000;

    //5 seconds before expiration time sends request to refresh token.
    let timeoutIn = expiresInMiliseconds - 10 * 1000;

    setTimeout(() => {
      dispatch(refreshToken(token));
    }, timeoutIn);
  };
};

export const REFRESH_TOKEN = "REFRESH_TOKEN";

export const refreshToken = (token) => {
  return (dispatch) => {
    if (TokenLocalStorage.get() !== null) {
      fetch(getApiUrl("auth/refresh"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token.token_type} ${token.token}`,
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.token) {
            dispatch({
              type: REFRESH_TOKEN,
              payload: response,
            });

            dispatch(setTimeoutToRefreshToken(response.token));
          }
        });
    }
  };
};

const isTokenExpired = (token) =>
  token.expiration_timestamp < ((Date.now() / 1000) | 0);

export const isStorageTokenValid = () => {
  let storageToken = TokenLocalStorage.get();
  return storageToken && !isTokenExpired(storageToken);
};

export const RELOGIN_USER = "RELOGIN_USER";
export const RELOGIN_USER_REQUEST = `${RELOGIN_USER}_REQUEST`;
export const RELOGIN_USER_SUCCESS = `${RELOGIN_USER}_SUCCESS`;
export const RELOGIN_USER_FAILURE = `${RELOGIN_USER}_FAILURE`;

export const reloginUserFromStorage = () => {
  return (dispatch) => {
    const storageToken = TokenLocalStorage.get();
    if (isStorageTokenValid()) {
      dispatch({
        [RSAA]: {
          endpoint: getApiUrl("user/session"),
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${storageToken.token_type} ${storageToken.token}`,
          },
          types: [
            RELOGIN_USER_REQUEST,
            {
              type: RELOGIN_USER_SUCCESS,
              payload: (action, state, res) =>
                res.json().then((response) => {
                  let tokenWithUpdatedExpiratesIn = {
                    ...storageToken,
                    expires_in: response.token.expires_in,
                  };

                  if (response.token) {
                    dispatch({
                      type: LOGIN_SUCCESS,
                      payload: {
                        token: {
                          ...tokenWithUpdatedExpiratesIn,
                        },
                      },
                    });

                    dispatch(loadUser());
                    dispatch(
                      setTimeoutToRefreshToken(tokenWithUpdatedExpiratesIn)
                    );
                  }
                  return response;
                }),
            },
            RELOGIN_USER_FAILURE,
          ],
        },
      });
    }
  };
};

export const FACEBOOK_AUTH = "FACEBOOK_AUTH";
export const FACEBOOK_AUTH_REQUEST = `${FACEBOOK_AUTH}_REQUEST`;
export const FACEBOOK_AUTH_SUCCESS = `${FACEBOOK_AUTH}_SUCCESS`;
export const FACEBOOK_AUTH_FAILURE = `${FACEBOOK_AUTH}_FAILURE`;

export const authWithFacebook = ({ accessToken, userID: userId }) => {
  return (dispatch) => {

    const extraCode = localStorage.getItem("extraCode");


    dispatch({
      [RSAA]: {
        endpoint: getApiUrl("auth/social/facebook"),
        method: "POST",
        types: [
          FACEBOOK_AUTH_REQUEST,
          {
            type: FACEBOOK_AUTH_SUCCESS,
            payload: (action, state, res) =>
              res.json().then((response) => {
                dispatch(setTimeoutToRefreshToken(response.token));
                return response;
              }),
          },
          FACEBOOK_AUTH_FAILURE,
        ],
        body: JSON.stringify({
          accessToken,
          userId,
          language: LanguageHelper.getInitialLanguage(),
          ...(extraCode && { extraCode }),
        }),
      },
    });
  };
};
