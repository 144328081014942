import FormattedMessage from "components/common/FormattedMessage";
import { SubscriptionPaymentDueAmountDisplay, SubscriptionPaymentDueDateDisplay, Price, Duration, SubscriptionPriceWithBillingCycleDisplay, SubscriptionContractTypeDisplay, SubscriptionInvoiceStatusDisplay, SubscriptionInvoiceDateDisplay, Status } from "./SubscriptionListDisplays";
import SubscriptionDetailsDropdown from "./SubscriptionDetailsDropdown";
import { getProductLogo } from "providers/routing/layouts/components/product-logo/smallProductLogo";
import { size } from "lodash";
import StandardActorCard, { StandardProjectCard, StandardSubscriptionCard } from "components/common/StandardActorCard";
import { PaySubscriptionDebtButton } from "./SubscriptionDebtPayment";

const expandable = "minmax(150px, 1fr)";

const sizes = {
    wide: ["1fr", "1fr", "1fr", "1fr", "1fr", "1fr"],
    product: ["100px", "100px", "100px", "200px", "200px", "200px"],
    standard: ["200px", "200px", "200px", "200px", "200px", "200px"],
    project: [false, false, "200px", "200px", "200px", "200px"],
    mid: ["150px", "150px", "150px", "150px", "150px", "150px"],
    small: ["75px", "75px", "75px", "75px", "75px", "75px"],
    //standard: [expandable, expandable, expandable, expandable, expandable, expandable],
    pay: ["75px", "75px", "75px", "75px", "75px", "75px"],
    action: ["50px", "50px", "50px", "50px", "50px", "50px"],
}

export const subscriptionListHeading = [
    {
        key: "product",
        size: sizes.standard,
        render: () => <FormattedMessage
            id="panel.subscriptions.list.headings.subscription"
            defaultMessage="Subscription"
        />
    },
    {
        key: "project",
        size: sizes.project,
        render: () => <FormattedMessage
            id="panel.subscriptions.list.headings.project"
            defaultMessage="Project"
        />
    },
    {
        key: "paymentDueDate",
        size: sizes.mid,
        render: () => <FormattedMessage
            id="panel.subscriptions.list.headings.paymentDueDate"
            defaultMessage="Payment Due"
        />
    },
    {
        key: "paymentDueAmount",
        size: sizes.mid,
        render: () => <FormattedMessage
            id="panel.subscriptions.list.headings.paymentDueAmount"
            defaultMessage="Amount Due"
        />
    },
    {
        key: "payButton",
        size: sizes.pay,
        render: () => null,
    },

    // {
    //     key: "overdue",
    //     size: sizes.standard,
    //     render: () => <FormattedMessage
    //         id="panel.subscriptions.list.headings.overdue"
    //         defaultMessage="Overdue"
    //     />
    // },
    // {
    //     key: "activationDate",
    //     size: sizes.standard,
    //     render: () => <FormattedMessage
    //         id="panel.subscriptions.list.headings.activationDate"
    //         defaultMessage="Activation Date"
    //     />
    // },
    {
        key: "status",
        size: sizes.small,
        render: () => <FormattedMessage
            id="panel.subscriptions.list.headings.status"
            defaultMessage="Status"
        />
    },
    {
        key: "duration",
        size: sizes.mid,
        render: () => <FormattedMessage
            id="panel.subscriptions.list.headings.duration"
            defaultMessage="Duration"
        />
    },
    {
        key: "price",
        size: sizes.mid,
        render: () => <FormattedMessage
            id="panel.subscriptions.list.headings.price"
            defaultMessage="Price"
        />
    },
    // {
    //     key: "billingCycle",
    //     size: sizes.standard,
    //     render: () => <FormattedMessage
    //         id="panel.subscriptions.list.headings.billingCycle"
    //         defaultMessage="Billing Cycle"
    //     />
    //},
    {
        key: "type",
        size: sizes.mid,
        render: () => <FormattedMessage
            id="panel.subscriptions.list.headings.type"
            defaultMessage="Type"
        />
    },
    // {
    //     key: "history",
    //     size: sizes.standard,
    //     render: () => <FormattedMessage
    //         id="panel.subscriptions.list.headings.history"
    //         defaultMessage="History"
    //     />
    // },
    {
        key: "action",
        size: sizes.action,
        render: () => <FormattedMessage
            id="panel.subscriptions.list.headings.action"
            defaultMessage="Action"
        />
    }
];

export const subscriptionListColumns = [
    {
        key: "product",
        size: sizes.standard,
        render: (item: any) =>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "5px",
            width: "100%",
            justifyContent: "flex-start",
            paddingLeft: "10px"
        }}>
            <StandardSubscriptionCard subscription={item}/>
        </div>
    },
    {
        key: "project",
        size: sizes.project,
        render: (item: any) => <div
        style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "5px",
            width: "100%",
            justifyContent: "flex-start",
            paddingLeft: "10px"
        }}
        >
            <StandardProjectCard projectId={item?.project} />
        </div>
    },
    // {
    //     key: "paymentDue",
    //     size: sizes.standard,
    //     render: (item: any) => <PaymentDue subscription={item} />,
    // },
    // {
    //     key: "overdue",
    //     size: sizes.standard,
    //     render: (item: any) => <OverdueDisplay subscription={item} />,
    // },
    {
        key: "paymentDueDate",
        size: sizes.mid,
        render: (item: any) => <SubscriptionPaymentDueDateDisplay/>,
    },
    {
        key: "paymentDueAmount",
        size: sizes.mid,
        render: (item: any) => <SubscriptionPaymentDueAmountDisplay/>,
    },
    {
        key: "payButton",
        size: sizes.pay,
        render: (item: any) => <PaySubscriptionDebtButton />,
    },
    {
        key: "status",
        size: sizes.small,
        render: (item: any) => <Status />,
    },
    {
        key: "duration",
        size: sizes.mid,
        render: (item: any) => <Duration/>,
    },
    {
        key: "price",
        size: sizes.mid,
        render: (item: any) => <SubscriptionPriceWithBillingCycleDisplay />
    },
    {
        key: "type",
        size: sizes.mid,
        render: (item: any) => <SubscriptionContractTypeDisplay />
    },
    // {
    //     key: "history",
    //     size: sizes.standard,
    //     render: (item: any) => <History subscription={item} />,
    // },
    {
        key: "action",
        size: sizes.action,
        render: (item: any) => <SubscriptionDetailsDropdown subscription={item} />,
    }
];

export const paymentColumns = [
    {
        key: "id",
        size: sizes.standard,
        render: (item: any) => <div>ID: {item.stripe_number}</div>
    },
    {
        key: "empty_slot_1",
        size: sizes.standard,
        render: (item: any) => <div></div>
    },
    {
        key: "paymentDate",
        size: sizes.standard,
        render: (item: any) => <SubscriptionInvoiceDateDisplay invoice={item} />,
    },
    {
        key: "status",
        size: sizes.standard,
        render: (item: any) => <SubscriptionInvoiceStatusDisplay invoice={item} />,
    },
]