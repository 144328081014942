import { Image, Tooltip, Typography } from "antd";
import AwesomeIcon from "components/common/AwesomeIcon";
import _ from "lodash";
import { useMemo } from "react";
import { CreationTypes } from "../CreationConfig";
import { SmallText } from "components/common/StandardText";

export default function CreationTypeDisplay(props: any) {

    const type = useMemo(() => {

        const foundType = CreationTypes.find((type) => _.isEqual(type.type, [props.type, props.subtype]));
        if (foundType) return foundType;

        return null;
    }, [props.type, props.subtype])

    if (!type) return null;

    return (
        <Tooltip
            title={type.title}
        >
            <div style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "5px 0px"
            }}>
                <img src={type.image} style={{
                    height: "35px"
                }} />
                <SmallText>{type.title}</SmallText>
            </div>
        </Tooltip>
    )
}