import React, { Component } from "react";
import { LoadingOutlined } from '@ant-design/icons';
import { Select, Spin } from "antd";
import debounce from "lodash/debounce";
import FormattedMessage from "components/common/FormattedMessage";
import { getCompanyCategories } from "modules/forsant/actions/ads-settings";

type CompanyCategory = {
  label: string;
  id: number;
};

export type SearchSelectProps = {
  value?: CompanyCategory;
  onChange?: (value: CompanyCategory) => void;
  onBlur?: () => void;
};

type SearchSelectState = {
  options: CompanyCategory[];
  loading: boolean;
};

export class CompanySearchSelect extends Component<
  SearchSelectProps,
  SearchSelectState
> {
  constructor(props: SearchSelectProps) {
    super(props);

    this.state = {
      options: [],
      loading: false,
    };

    this.handleSearch = debounce(this.handleSearch, 500);
  }

  handleSearch = (term: string): void => {
    if (term.length > 2) {
      this.setState({
        loading: true,
      });

      handleSearch(term).then((options) => {
        this.setState({
          options,
          loading: false,
        });
      });
    }
  };

  onChange = (id: number, option: any) => {
    const { onChange } = this.props;

    if (onChange) onChange({ id, label: option.props.children });
  };

  optionsHasChosen = () => {
    const { value } = this.props;
    if (!value) return false;
    return this.state.options.find(({ id: optionId }) => optionId === value.id);
  };

  render() {
    const { value } = this.props;

    return (
      <Select
        showSearch
        placeholder={
          <FormattedMessage
            id="components.forms.inputs.searchSelect.search"
            defaultMessage="Search for category"
          />
        }
        notFoundContent={
          this.state.loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "35px",
              }}
            >
              <Spin
                indicator={
                  <LoadingOutlined style={{ fontSize: 25 }} spin />
                }
              />
            </div>
          ) : null
        }
        onSearch={this.handleSearch}
        loading={this.state.loading}
        style={{ width: "100%" }}
        value={value ? value.id : undefined}
        onChange={this.onChange}
        filterOption={false}
        onBlur={this.props.onBlur}
      >
        {this.state.options.map(({ label, id }: CompanyCategory) => (
          <Select.Option key={id} value={id}>
            {label}
          </Select.Option>
        ))}

        {!this.optionsHasChosen() && value && (
          <Select.Option value={value.id}>{value.label}</Select.Option>
        )}
      </Select>
    );
  }
}

const handleSearch = (q: string): Promise<CompanyCategory[]> =>
  new Promise((resolve) => {
    getCompanyCategories(q).then((response) =>
      resolve(
        response.map(({ display_name, id }) => {
          return {
            id,
            label: display_name,
          };
        })
      )
    );
  });

export default CompanySearchSelect;
