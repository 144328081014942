import { Input } from "antd"
import URLInput from "components/inputs/URLInput"
import RelaxList from "tools/relaxForm/RelaxList"
import { ruleArrayMax, ruleArrayMin, ruleMax } from "tools/relaxForm/relaxPresets"
import RelaxSingleField from "tools/relaxForm/RelaxField"
import PostTextEditorWithSuspense from "tools/textEditor/PostTextEditorWithSuspense"
import { useCreationEditor } from "../CreationEditor"
import { patchCreationWithSmartAction } from "../creationsSlice"
import { AOAMediaAndFormatSelect } from "../editors/EditorAOAAndLead"
import CreationSection, { SectionSimpleStateArrayCheck, SectionSimpleStateCheck } from "../editorTools/CreationSection"
import AOAEvents from "../inputs/AOAEvents"
import CreationBudgetInput from "../inputs/CreationBudgetInput"
import { GalleryWrapperForAOA, GalleryWrapperForPostFormSingle, GalleryWrapperThumbnail } from "../inputs/GalleryWrappers"
import { PostPublicationSettings } from "../editorTools/PostPublicationSettings";
import { useEffect, useMemo, useRef } from "react"
import _, { has } from "lodash"
import FormattedMessage from "components/common/FormattedMessage"
import LeadFormSelectForPosts from "../LeadFormSelectForPosts"
import CTASelect from "../CTASelect"
import Switch from "_components/forms/switch"
import Checkbox from "components/inputs/Checkbox"
import StandardButton from "components/common/StandardButton"
import { useCreationSection } from "../editorTools/useCreationSection"
import PhoneNumberInputWithCountrySelect from "_components/forms/PhoneNumberInputWithCountrySelect"

export const HelperGetRelaxExtraErrors = (fieldName: string, index: number) => {
    const field = document.getElementById(`ce-rf-${fieldName}-`+ index);
    if (!field) return null;
    const extraError = field.querySelector(".extra-error");
    if (!extraError) return null;
    return extraError;
}

export const EditorSectionName = (props: any) => {

    const { creationId, content } = useCreationEditor()
    const { ref, status, setStatusData, handleFieldError, sectionComponentProps } = useCreationSection({
        sectionName: "name",
        smartActionPrefix: "ce_name"
    });

    useEffect(() => {
        SectionSimpleStateArrayCheck("name", content, setStatusData);
    }, [content]);

    return (
        <CreationSection
            {...props}
            {...sectionComponentProps}
            tooltip={<FormattedMessage
                id="tools.creationEditor.sections.name.tooltip"
                defaultMessage="Name"
            />}
            title={<FormattedMessage
                id="tools.creationEditor.sections.name.label"
                defaultMessage="Name"
            />}
        >
            <span>Name of the ad, will not be publically visible.</span>
            <RelaxSingleField
                name={"name"}
                onErrors={(errors: any) => handleFieldError("name", errors)}
                callAPI={patchCreationWithSmartAction(creationId, "ce_name")}
                required
                rules={[ruleMax(30)]}
            >
                <Input />
            </RelaxSingleField>
        </CreationSection>
    )

}


export const EditorSectionListOfMedia = (props: any) => {
    const { creationId, content } = useCreationEditor()
    const { ref, status, setStatusData, handleFieldError, sectionComponentProps } = useCreationSection({
        sectionName: "media",
        smartActionPrefix: "ce_media",
        filterValidationResults: (results: any) => {
            if (!results) return null;
            let errors: string[] = []
            if (results.media) errors.push(results.media)
            if (results.format) errors.push(results.format)
            return errors;
        }
    });

    const galleryRef = useRef(null);

    useEffect(() => {

        if (!content) {
            setStatusData("initial");
            return;
        }

        if (!content.format || !content.media || !content["media-type"]) {
            setStatusData("error");
            return;
        }

        if (content.media.length === 0) {
            setStatusData("error");
            return;
        }

        if (content.media.length > 10) {
            setStatusData("error");
            return;
        }

        setStatusData("ready");
    }, [content]);


    return (
        <CreationSection
            {...props}
            {...sectionComponentProps}
            tooltip={<FormattedMessage
                id="tools.creationEditor.sections.media.tooltip"
                defaultMessage="Media"
            />}
            title={<FormattedMessage
                id="tools.creationEditor.sections.media.label"
                defaultMessage="Media"
            />}
        >
            <AOAMediaAndFormatSelect
                onMediaReset={() => {

                    console.log("media Ref", galleryRef.current)

                    if (galleryRef.current) {
                        galleryRef.current?.clearGallery();
                    }

                }}
            >
                
            <RelaxSingleField
                name={"media"}
                onErrors={(errors: any) => handleFieldError("media", errors)}
                callAPI={patchCreationWithSmartAction(creationId, "ce_media")}
                required
                rules={[{ noDuplicatesByHash: true }, ruleArrayMax(10), ruleArrayMin(1)]}
            >
                <GalleryWrapperForAOA 
                    ref={galleryRef}
                />
            </RelaxSingleField>
            </AOAMediaAndFormatSelect>  
        </CreationSection>
    )

}

export const EditorSectionMedia = (props: any) => {
    const { creationId, content } = useCreationEditor()
    const { ref, status, setStatusData, handleFieldError, sectionComponentProps } = useCreationSection({
        sectionName: "media",
        smartActionPrefix: "ce_media",
        filterValidationResults: (results: any) => {
            if (!results) return null;
            let errors: string[] = []
            if (results.media) errors.push(results.media)
            return errors;
        }
    });

    useEffect(() => {

        if (!content) {
            setStatusData("initial");
            return;
        }

        if (!content.media) {
            setStatusData("error");
            return;
        }

        if (content.media.length === 0) {
            setStatusData("error");
            return;
        }

        setStatusData("ready");
    }, [content]);

    return (
        <CreationSection
            {...props}
            {...sectionComponentProps}
            tooltip={<FormattedMessage
                id="tools.creationEditor.sections.media.tooltip"
                defaultMessage="Media"
            />}
            title={<FormattedMessage
                id="tools.creationEditor.sections.media.label"
                defaultMessage="Media"
            />}
        >
            <RelaxSingleField
                name={"media"}
                onErrors={(errors: any) => handleFieldError("media", errors)}
                callAPI={patchCreationWithSmartAction(creationId, "ce_media")}
                required
                rules={[{ noDuplicatesByHash: true }, ruleArrayMax(1), ruleArrayMin(1)]}
            >
                <GalleryWrapperForPostFormSingle 
                />
            </RelaxSingleField>
        </CreationSection>
    )

}

export const EditorSectionMediaWithThumbnail = (props: any) => {
    const { creationId, content } = useCreationEditor()
    const { ref, status, setStatusData, handleFieldError, sectionComponentProps } = useCreationSection({
        sectionName: "media",
        smartActionPrefix: "ce_media",
        filterValidationResults: (results: any) => {
            if (!results) return null;
            let errors: string[] = []
            if (results.media) errors.push(results.media)
            return errors;
        }
    });

    useEffect(() => {

        if (!content) {
            setStatusData("initial");
            return;
        }

        if (!content.media) {
            setStatusData("error");
            return;
        }

        if (content.media.length === 0) {
            setStatusData("error");
            return;
        }

        setStatusData("ready");
    }, [content]);

    return (
        <CreationSection
            {...props}
            {...sectionComponentProps}
            tooltip={<FormattedMessage
                id="tools.creationEditor.sections.media.tooltip"
                defaultMessage="Media"
            />}
            title={<FormattedMessage
                id="tools.creationEditor.sections.media.label"
                defaultMessage="Media"
            />}
        >
            <RelaxSingleField
                name={"media"}
                onErrors={(errors: any) => handleFieldError("media", errors)}
                callAPI={patchCreationWithSmartAction(creationId, "ce_media")}
                required
                rules={[{ noDuplicatesByHash: true }, ruleArrayMax(1), ruleArrayMin(1)]}
            >
                <GalleryWrapperForPostFormSingle />
            </RelaxSingleField>
            <RelaxSingleField
                name={"thumbnail"}
                onErrors={(errors: any) => handleFieldError("thumbnail", errors)}
                callAPI={patchCreationWithSmartAction(creationId, "ce_media")}
                required
                rules={[{ noDuplicatesByHash: true }, ruleArrayMax(1), ruleArrayMin(1)]}
                label={<FormattedMessage
                    id="tools.creationEditor.sections.media.thumbnail.label"
                    defaultMessage="Thumbnail"
                />}
            >
                <GalleryWrapperThumbnail />
            </RelaxSingleField>
        </CreationSection>
    )

}

export const EditorSectionTitle = (props: any) => {

    const { creationId, content } = useCreationEditor()
    const { ref, status, setStatusData, handleFieldError, sectionComponentProps } = useCreationSection({
        sectionName: "title",
        smartActionPrefix: "ce_title"
    });

    useEffect(() => {
        SectionSimpleStateArrayCheck("title", content, setStatusData);
    }, [content]);


    return (
        <CreationSection
            {...props}
            {...sectionComponentProps}
            tooltip={<FormattedMessage
                id="tools.creationEditor.sections.title.tooltip"
                defaultMessage="Title"
            />}
            title={<FormattedMessage
                id="tools.creationEditor.sections.title.label"
                defaultMessage="Title"
            />}
        >
            <RelaxSingleField
                name={"title"}
                required
                onErrors={(errors: any) => handleFieldError("title", errors)}
                callAPI={patchCreationWithSmartAction(creationId, "ce_title")}
                rules={[ruleMax(30)]}
            >
                <Input />
            </RelaxSingleField>
        </CreationSection>
    )

}

export const EditorSectionPhone = (props: any) => {

    const { creationId, content } = useCreationEditor()
    const { ref, status, setStatusData, handleFieldError, sectionComponentProps } = useCreationSection({
        sectionName: "phone",
        smartActionPrefix: "ce_phone"
    });

    useEffect(() => {
        SectionSimpleStateArrayCheck("phone", content, setStatusData);
    }, [content]);


    return (
        <CreationSection
            {...props}
            {...sectionComponentProps}
            tooltip={<FormattedMessage
                id="tools.creationEditor.sections.phone.tooltip"
                defaultMessage="Phone number that will be displayed in the ad."
            />}
            title={<FormattedMessage
                id="tools.creationEditor.sections.phone.label"
                defaultMessage="Phone"
            />}
        >
            <RelaxSingleField
                name={"phone"}
                required
                onErrors={(errors: any) => handleFieldError("phone", errors)}
                callAPI={patchCreationWithSmartAction(creationId, "ce_phone")}
            >
                <PhoneNumberInputWithCountrySelect />
            </RelaxSingleField>
        </CreationSection>
    )

}


export const EditorSectionLink = (props: any) => {

    const { creationId, content } = useCreationEditor()
    const { ref, status, setStatusData, handleFieldError, sectionComponentProps } = useCreationSection({
        sectionName: "link",
        smartActionPrefix: "ce_link"
    });

    useEffect(() => {
        SectionSimpleStateArrayCheck("link", content, setStatusData);
    }, [content]);


    return (
        <CreationSection
            {...props}
            {...sectionComponentProps}
            tooltip={<FormattedMessage
                id="tools.creationEditor.sections.link.tooltip"
                defaultMessage="Link that will be displayed in the ad."
            />}
            title={<FormattedMessage
                id="tools.creationEditor.sections.link.label"
                defaultMessage="Link"
            />}
        >
            <RelaxSingleField
                name={"link"}
                required
                onErrors={(errors: any) => handleFieldError("link", errors)}
                callAPI={patchCreationWithSmartAction(creationId, "ce_link")}
                rules={[{
                    custom: (value: any) => {

                        
                        try {
                            // Parse the URL
                            const parsedUrl = new URL(value);
                            
                            // Define the disallowed domains
                            const disallowedDomains = ['facebook.com', 'instagram.com'];
                    
                            // Extract the hostname from the parsed URL
                            const hostname = parsedUrl.hostname.toLowerCase();
                    

                            // Check if the hostname matches any of the disallowed domains
                            for (const domain of disallowedDomains) {
                                if (hostname.endsWith(domain) || hostname === domain) {
                                    return false; // Invalid URL
                                }
                            }
                    
                            return true; // Valid URL
                        } catch (e) {
                            // If URL parsing fails, it's not a valid URL
                            return false;
                        }
                    },
                    message: "Cannot use Facebook or Instagram links"
                }]}
            >
                <URLInput />
            </RelaxSingleField>
        </CreationSection>
    )

}

export const EditorSectionDescription = (props: any) => {

    const { creationId, content } = useCreationEditor()
    const { ref, status, setStatusData, handleFieldError, sectionComponentProps } = useCreationSection({
        sectionName: "description",
        smartActionPrefix: "ce_description"
    });

    useEffect(() => {
        SectionSimpleStateArrayCheck("description", content, setStatusData);
    }, [content]);


    return (
        <CreationSection
            {...props}
            {...sectionComponentProps}
            tooltip={<FormattedMessage
                id="tools.creationEditor.sections.description.tooltip"
                defaultMessage="Description"
            />}
            title={<FormattedMessage
                id="tools.creationEditor.sections.description.label"
                defaultMessage="Description"
            />}
        >
            <RelaxSingleField
                name={"description"}
                required
                onErrors={(errors: any) => handleFieldError("description", errors)}
                callAPI={patchCreationWithSmartAction(creationId, "ce_description")}
                rules={[ruleMax(30)]}
            >
                <Input />
            </RelaxSingleField>
        </CreationSection>
    )

}

export const EditorSectionMessage = (props: any) => {

    const { creationId, content, type, subtype } = useCreationEditor()
    const { ref, status, setStatusData, handleFieldError, sectionComponentProps, handleFieldSuccess } = useCreationSection({
        sectionName: "message",
        smartActionPrefix: "ce_message",
    });

    useEffect(() => {
        SectionSimpleStateArrayCheck("message", content, setStatusData);
    }, [content]);

    const rules = useMemo(() => {
        if (subtype === "image" || subtype === "link") return [ruleMax(3000)];
        return [ruleMax(1650)];
    }, [type, subtype])

    return (
        <CreationSection
            {...props}
            {...sectionComponentProps}
            tooltip={<FormattedMessage
                id="tools.creationEditor.sections.message.tooltip"
                defaultMessage="Message"
            />}
            title={<FormattedMessage
                id="tools.creationEditor.sections.message.label"
                defaultMessage="Message"
            />}
        >
            <RelaxSingleField
                name={"message"}
                onErrors={(errors: any) => handleFieldError("message", errors)}
                onSuccess={(res: any, data: any) => handleFieldSuccess("message", res)}
                callAPI={patchCreationWithSmartAction(creationId, "ce_message")}
                required
                rules={rules}
            >
                <PostTextEditorWithSuspense 
                    fieldMetadata={{
                        creationId: creationId,
                        field: "message"
                    }}
                />
            </RelaxSingleField>
        </CreationSection>
    )

}

export const EditorSectionListOfBodies = (props: any) => {

    const { creationId, content } = useCreationEditor()
    const { ref, status, setStatusData, handleFieldError, sectionComponentProps, handleFieldSuccess } = useCreationSection({
        sectionName: "bodies",
        smartActionPrefix: "ce_bodies"
    });

    useEffect(() => {
        SectionSimpleStateArrayCheck("bodies", content, setStatusData);
    }, [content]);


    return (
        <CreationSection
            {...props}
            {...sectionComponentProps}
            tooltip={<FormattedMessage
                id="tools.creationEditor.sections.bodies.tooltip"
                defaultMessage="Bodies"
            />}
            title={<FormattedMessage
                id="tools.creationEditor.sections.bodies.label"
                defaultMessage="Bodies"
            />}
            subfieldPortalElement={(i) => HelperGetRelaxExtraErrors("bodies", i)}
        >
            <RelaxList
                name={"bodies"}
                callAPI={patchCreationWithSmartAction(creationId, "ce_bodies")}
                initialValues={{ bodies: [" "] }}
                onSuccess={(res: any, data: any) => handleFieldSuccess("bodies", data)}
                onErrors={(errors: any) => handleFieldError("bodies", errors)}
                rules={[{ noDuplicates: true }, { noEmptyValues: true }, { max: 5 }, { min: 1 }, { required: true }]}
                elementRules={[ruleMax(1650)]}

            >
                <PostTextEditorWithSuspense 
                    fieldMetadata={{
                        creationId: creationId,
                        field: "bodies"
                    }}
                />
            </RelaxList>
        </CreationSection>
    )

}

export const EditorSectionListOfTitles = (props: any) => {

    const { creationId, content } = useCreationEditor()
    const { ref, status, setStatusData, handleFieldError, sectionComponentProps } = useCreationSection({
        sectionName: "titles",
        smartActionPrefix: "ce_titles"
    });

    useEffect(() => {
        SectionSimpleStateArrayCheck("titles", content, setStatusData);
    }, [content]);


    return (
        <CreationSection
            {...props}
            {...sectionComponentProps}
            tooltip={<FormattedMessage
                id="tools.creationEditor.sections.titles.tooltip"
                defaultMessage="Titles"
            />}
            title={<FormattedMessage
                id="tools.creationEditor.sections.titles.label"
                defaultMessage="Titles"
            />}
            subfieldPortalElement={(i) => HelperGetRelaxExtraErrors("titles", i)}
        >
            <RelaxList
                name={"titles"}
                onErrors={(errors: any) => handleFieldError("titles", errors)}
                callAPI={patchCreationWithSmartAction(creationId, "ce_titles")}
                initialValues={{ "titles": [" "] }}
                elementRules={[ruleMax(45)]}
                //rules={[{ noDuplicates: true }, { noEmptyValues: true }, { max: 5 }, { min: 1 }]}
            >
                <Input />
            </RelaxList>
        </CreationSection>
    )

}

export const EditorSectionListOfDescriptions = (props: any) => {

    const { creationId, content } = useCreationEditor()
    const { ref, status, setStatusData, handleFieldError, sectionComponentProps } = useCreationSection({
        sectionName: "descriptions",
        smartActionPrefix: "ce_descriptions"
    });

    useEffect(() => {
        SectionSimpleStateArrayCheck("descriptions", content, setStatusData);
    }, [content]);

    return (
        <CreationSection
            {...props}
            {...sectionComponentProps}
            tooltip={<FormattedMessage
                id="tools.creationEditor.sections.descriptions.tooltip"
                defaultMessage="Descriptions"
            />}
            title={<FormattedMessage
                id="tools.creationEditor.sections.descriptions.label"
                defaultMessage="Descriptions"
            />}
            subfieldPortalElement={(i) => HelperGetRelaxExtraErrors("descriptions", i)}
        >
            <RelaxList
                name={"descriptions"}
                onErrors={(errors: any) => handleFieldError("descriptions", errors)}
                callAPI={patchCreationWithSmartAction(creationId, "ce_descriptions")}
                initialValues={{ "descriptions": [" "] }}
                elementRules={[ruleMax(90)]}
                rules={[{ noDuplicates: true }, { noEmptyValues: true }, { max: 5 }, { min: 1 }]}
            >
                <Input />
            </RelaxList>
        </CreationSection>
    )

}

export const EditorSectionCTA = (props: any) => {

    const { creationId, content, type, subtype } = useCreationEditor()
    const { ref, status, setStatusData, handleFieldError, sectionComponentProps } = useCreationSection({
        sectionName: "cta",
        smartActionPrefix: "ce_ctas"
    });

    const show_cta_checkbox = subtype === "video"
    const has_cta = content?.has_cta || content?.cta?.data || content?.cta?.type;

    useEffect(() => {

        //console.log("cta", content?.cta)

        if (!content) return setStatusData("initial");

        if (!has_cta) return setStatusData("ready");

        //if (!content.cta) return setStatusData("error");
        if (!content.cta?.data && subtype !== "messenger") return setStatusData("error");
        //Type on message is always "message"
        if (!content.cta?.type && subtype !== "messenger") return setStatusData("error");
        return setStatusData("ready");
    }, [content]);

    return (
        <CreationSection
            {...props}
            {...sectionComponentProps}
            tooltip={<FormattedMessage
                id="tools.creationEditor.sections.cta.link.tooltip"
                defaultMessage="Call to action"
            />}
            title={<FormattedMessage
                id="tools.creationEditor.sections.cta.link.label"
                defaultMessage="Call to action"
            />}
        >
            {show_cta_checkbox && (
            <RelaxSingleField
                name={"has_cta"}
                callAPI={patchCreationWithSmartAction(creationId, "ce_ctas")}
                submitOnChange
                initialValues={{ has_cta: has_cta }}
                hideStatus
                finalizeSubmit={(value: any) => {
                    if (value.has_cta === false) return {
                        content: {
                            has_cta: false,
                            cta: null,
                        }
                    }
                    return {
                        content: {has_cta: true}
                    }
                }}
            >
                <Checkbox>
                    <FormattedMessage
                        id="tools.creationEditor.fields.cta.hasCTA.description"
                        defaultMessage="Include Call to Action"
                    />
                </Checkbox>
            </RelaxSingleField>
            )}
            {(!!has_cta || !show_cta_checkbox) && (
                <div style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 300px",
                    gap: "20px"
                }}>
                    <RelaxSingleField
                        name={["cta", "data"]}
                        onErrors={(errors: any) => handleFieldError("cta", errors)}
                        label={<FormattedMessage
                            id="tools.creationEditor.fields.cta.link.label"
                            defaultMessage="Call to action link"
                        />}
                        disabled={subtype === "messenger" ? true : false}
                        required={subtype === "messenger" ? false : true}
                        callAPI={patchCreationWithSmartAction(creationId, "ce_ctas")}
                        rules={[ruleMax(512)]}
                        finalizeSubmit={(value: any) => {
                            const currentCTA = content?.cta || {};
                            let newVal = { ...currentCTA, ...value.cta }
                            return {
                                content: { cta: newVal }
                            }
                        }}
                    >
                        <URLInput />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["cta", "type"]}
                        label={<FormattedMessage
                            id="tools.creationEditor.fields.cta.action.label"
                            defaultMessage="Call to action"
                        />}
                        required
                        submitOnChange
                        onErrors={(errors: any) => handleFieldError("type", errors)}
                        callAPI={patchCreationWithSmartAction(creationId, "ce_ctas")}
                        finalizeSubmit={(value: any) => {
                            const currentCTA = content?.cta || {};
                            let newVal = { ...currentCTA, ...value.cta }
                            return {
                                content: { cta: newVal }
                            }
                        }}
                    >
                        <CTASelect mode={"single"} actions={(props as CTAFieldRenderProps).actions} />
                    </RelaxSingleField>
                </div>
            )}
        </CreationSection>
    )

}

export const EditorSectionCTAOnly = (props: any) => {

    const { creationId, content } = useCreationEditor()
    const { ref, status, setStatusData, handleFieldError, sectionComponentProps } = useCreationSection({
        sectionName: "cta",
        smartActionPrefix: "ce_ctas",
        filterValidationResults: (results: any) => {
            if (!results) return null;
            let errors: string[] = []
            if (results.ctas) errors.push(results.ctas)
            return errors;
        }
    });

    useEffect(() => {
        if (!content) return setStatusData("initial");
        if (!content.ctas) return setStatusData("error");
        if (content.ctas.length === 0) return setStatusData("error");
        return setStatusData("ready");
    }, [content]);

    return (
        <CreationSection
            {...props}
            {...sectionComponentProps}
            tooltip={<FormattedMessage
                id="tools.creationEditor.sections.cta.tooltip"
                defaultMessage="Call to action"
            />}
            title={<FormattedMessage
                id="tools.creationEditor.sections.cta.label"
                defaultMessage="Call to action"
            />}>
            <div style={{
                display: "flex",
                flexDirection: "column",
            }}>
                <RelaxSingleField
                    name={"ctas"}
                    callAPI={patchCreationWithSmartAction(creationId, "ce_ctas")}
                    required
                    onErrors={(e) => handleFieldError("ctas", e)}
                    rules={[ruleMax(5)]}
                    finalizeSubmit={(values: any) => {
                        return {
                            content: {
                                ctas: values.ctas && values.ctas.map((cta: any) => ({ type: cta }))
                            }
                        }
                    }}
                >
                    <CTASelect mode={"multiple"} actions={(props as CTAFieldRenderProps).actions} />
                </RelaxSingleField>
            </div>
        </CreationSection>)

}

export const EditorSectionCTAWithMultipleLinks = (props: any) => {

    const { creationId, content } = useCreationEditor()
    const { ref, status, setStatusData, handleFieldError, sectionComponentProps } = useCreationSection({
        sectionName: "cta",
        smartActionPrefix: "ce_ctas",
        filterValidationResults: (results: any) => {
            if (!results) return null;
            let errors: string[] = []
            if (results.link) errors.push(results.link)
            if (results.ctas) errors.push(results.ctas)
            return errors;
        }
    });

    useEffect(() => {
        if (!content) return setStatusData("initial");
        if (!content.link) return setStatusData("error");
        if (!content.ctas) return setStatusData("error");
        if (content.ctas.length === 0) return setStatusData("error");
        return setStatusData("ready");
    }, [content]);

    return (
        <CreationSection
            {...props}
            {...sectionComponentProps}
            tooltip={<FormattedMessage
                id="tools.creationEditor.sections.cta.tooltip"
                defaultMessage="Call to action"
            />}
            title={<FormattedMessage
                id="tools.creationEditor.sections.cta.label"
                defaultMessage="Call to action"
            />}>
            <div style={{
                display: "flex",
                flexDirection: "column",
            }}>
                <RelaxSingleField
                    name={"link"}
                    onErrors={(errors: any) => handleFieldError("link", errors)}
                    callAPI={patchCreationWithSmartAction(creationId, "ce_ctas")}
                    label={<FormattedMessage
                        id="tools.creationEditor.fields.cta.link.label"
                        defaultMessage="Call to action link"
                    />}
                    required
                    rules={[ruleMax(512)]}
                >
                    <URLInput />
                </RelaxSingleField>
                <RelaxSingleField
                    name={"ctas"}
                    callAPI={patchCreationWithSmartAction(creationId, "ce_ctas")}
                    label={<FormattedMessage
                        id="tools.creationEditor.fields.cta.action.label"
                        defaultMessage="Call to action"
                    />}
                    required
                    submitOnChange
                    onErrors={(e) => handleFieldError("ctas", e)}
                    rules={[ruleMax(5)]}
                    finalizeSubmit={(values: any) => {
                        return {
                            content: {
                                ctas: values.ctas && values.ctas.map((cta: any) => ({ type: cta }))
                            }
                        }
                    }}
                >
                    <CTASelect mode={"multiple"} actions={(props as CTAFieldRenderProps).actions} />
                </RelaxSingleField>
            </div>
        </CreationSection>)

}

export const EditorSectionEvents = (props: any) => {

    const { creationId, content } = useCreationEditor()
    const { ref, status, setStatusData, handleFieldError, sectionComponentProps } = useCreationSection({
        sectionName: "events",
        smartActionPrefix: "ce_events"
    });

    useEffect(() => {
        SectionSimpleStateCheck("events", content, setStatusData);
    }, [content]);


    return (
        <CreationSection
            {...props}
            {...sectionComponentProps}
            tooltip={<FormattedMessage
                id="tools.creationEditor.sections.events.tooltip"
                defaultMessage="Events"
            />}
            title={<FormattedMessage
                id="tools.creationEditor.sections.events.label"
                defaultMessage="Events"
            />}>
            <RelaxSingleField
                name={"events"}
                onErrors={(errors: any) => handleFieldError("events", errors)}
                callAPI={patchCreationWithSmartAction(creationId, "ce_events")}
                label={<FormattedMessage
                    id="tools.creationEditor.fields.events.label"
                    defaultMessage="Events" />}
                initialValues={{ "events": [] }}
            >
                <AOAEvents />
            </RelaxSingleField>
        </CreationSection>
    )

}

export const EditorSectionBudget = (props: any) => {

    const { creationId, content } = useCreationEditor()
    const { ref, status, setStatusData, handleFieldError, sectionComponentProps } = useCreationSection({
        sectionName: "budget",
        smartActionPrefix: "ce_budget"
    });

    useEffect(() => {
        if (!content) return setStatusData("initial");
        if (!content.budget) return setStatusData("error");
        if (!content.days) return setStatusData("error");
        return setStatusData("ready");
    }, [content]);


    return (
        <CreationSection
            {...props}
            {...sectionComponentProps}
            tooltip={<FormattedMessage
                id="tools.creationEditor.sections.budget.tooltip"
                defaultMessage="Budget"
            />}
            title={<FormattedMessage
                id="tools.creationEditor.sections.budget.label"
                defaultMessage="Budget"
            />}>
            <CreationBudgetInput onErrors={handleFieldError} />
        </CreationSection>
    )

}

export const EditorSectionPublication = (props: any) => {

    const { creationId, content } = useCreationEditor()
    const { ref, status, setStatusData, handleFieldError, sectionComponentProps } = useCreationSection({
        sectionName: "publication",
        smartActionPrefix: "ce_publication"
    });

    useEffect(() => {

        if (!content) return setStatusData("initial");
        if (!content.publication_type) return setStatusData("error");
        if (content.publication_type === "planned" && !content.publication_date) return setStatusData("error");
        return setStatusData("ready");

    }, [content]);

    return (
        <CreationSection
            {...props}
            {...sectionComponentProps}
            tooltip={<FormattedMessage
                id="tools.creationEditor.sections.publication.tooltip"
                defaultMessage="Publication"
            />}
            title={
                <FormattedMessage
                    id="tools.creationEditor.sections.publication.label"
                    defaultMessage="Publication"
                />}>
            <PostPublicationSettings onErrors={handleFieldError} />
        </CreationSection>
    )

}

export const EditorSectionLeadForm = (props: any) => {

    const { creationId, content } = useCreationEditor()
    const { ref, status, setStatusData, handleFieldError, sectionComponentProps } = useCreationSection({
        sectionName: "leadForm",
        smartActionPrefix: "ce_leadForm",
        filterValidationResults: (results: any) => {
            if (!results) return null;
            let errors: string[] = []
            if (results.lead_gen_form_id) errors.push(results.lead_gen_form_id)
            return errors;
        }
    });

    useEffect(() => {
        SectionSimpleStateCheck("lead_gen_form_id", content, setStatusData);
    }, [content]);

    return (
        <CreationSection
            {...props}
            {...sectionComponentProps}
            tooltip={<FormattedMessage
                id="tools.creationEditor.sections.leadForm.tooltip"
                defaultMessage="Lead Form"
            />}
            title={<FormattedMessage
                id="tools.creationEditor.sections.leadForm.label"
                defaultMessage="Lead Form"
            />}
        >
            <RelaxSingleField
                name={"lead_gen_form_id"}
                onErrors={(errors: any) => handleFieldError("lead_gen_form_id", errors)}
                callAPI={patchCreationWithSmartAction(creationId, "ce_leadForm")}
                required
            >
                <LeadFormSelectForPosts />
            </RelaxSingleField>
        </CreationSection>
    )

}