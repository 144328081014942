import React, { useMemo } from "react";
import {
  LikeIcon,
  LoveIcon,
  HahaIcon,
  WowIcon,
  SadIcon,
  AngryIcon,
  TakeCareIcon,
} from "modules/smart/components/list-item/base-list-item/reactions/ReactionIcons";

export type ReactionsData = {
  [reaction in ReactionType]: number;
};

export type ReactionType = keyof typeof ReactionsIcons;

const Reactions = (reactions: ReactionsData) => {
  const sum = useMemo(
    () => Object.values(reactions).reduce((a, b) => a + b, 0),
    [reactions]
  );

  const nonZeroReactionsIcons = useMemo(
    () => (
      <>
        {Object.entries(reactions)
          .filter(([reaction, number]) => number !== 0)
          .map(
            ([reaction, number]) => ReactionsIcons[reaction as ReactionType]
          )}
      </>
    ),
    [reactions]
  );

  return (
    <div className="reactions-numbers">
      <div className="heading">
        <span className="number">{sum}</span>
        {nonZeroReactionsIcons}
      </div>
      <div className="details">
        {Object.entries(reactions).map(([reaction, number]) => (
          <div key={reaction}>
            {ReactionsIcons[reaction as ReactionType]}
            <span className="number">{number}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export const ReactionsIcons = {
  like: <LikeIcon key="like"/>,
  love: <LoveIcon key="love"/>,
  haha: <HahaIcon key="haha"/>,
  wow: <WowIcon key="wow"/>,
  sad: <SadIcon key="sad"/>,
  angry: <AngryIcon key="angry"/>,
  takeCare: <TakeCareIcon key="takeCare"/>,
};

export default Reactions;
