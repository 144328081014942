import { Collapse, Divider } from "antd"
import ChangePassword from "./ChangePassword";
import { callAPIProps } from "services/hooks/useAPI";
import { StandalonePaymentMethodSelect } from "components/purchases/PaymentMenthodSelect";
import { Link, useHistory } from "react-router-dom";
import StandardButton from "components/common/StandardButton";
import FormattedMessage from "components/common/FormattedMessage";
import {
  USER_DELETION,
  USER_DELETION_PAGE,
  getUrl,
} from "modules/panel/config/routes";

const AccountAdvancedSettings = () => {
  const history = useHistory();
  return (
    <Collapse>
      <Collapse.Panel key="changePassword" header="Change password">
        <ChangePassword />
      </Collapse.Panel>
      <Collapse.Panel key="paymentMethod" header="Payment Method">
        <StandalonePaymentMethodSelect />
      </Collapse.Panel>
      <Collapse.Panel key="security" header="Security">
        <StandardButton
          type="text"
          onClick={() => {
            history.push(getUrl(USER_DELETION));
          }}
        >
          <FormattedMessage
            id="panel.userSettings.advanced.security.deleteUser"
            defaultMessage="Delete user account"
          />
        </StandardButton>
      </Collapse.Panel>
    </Collapse>
  );
};

export default AccountAdvancedSettings;
