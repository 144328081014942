import { CropPresets, FileRequirements, SizeRequirements } from "./Gallery";

export const defaultCropPresets:CropPresets = {
  default: [
    {
      ratio: "16:9",
      aspect: 16/9,
    },
    {
      ratio: "1:1",
      aspect: 1,
    },
    {
      ratio: "4:3",
      aspect: 4/3,
    },
    {
      ratio: "free",
      aspect: 0,
    }
  ]
}

export const defaultFileRequirements:FileRequirements = {
  allowedTypes: ".png,.jpeg,.jpg",
  minFileSize: 1000,
  maxFileSize: 10000000,
}

const defaultSizeRequirements:SizeRequirements = {
  minWidth: 600,
  maxWidth: 10000,
  minHeight: 600,
  maxHeight: 10000,
}