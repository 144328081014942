import { Col } from "antd";
import { StandardRow } from "components/layout/StandardGrid";
import GeoPointsGoogle from "components/map/GeoPointsGoogle";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import useAPI, { callAPIProps } from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import BudgetInput from "_components/forms/budget-input";
import PeriodInput from "_components/forms/period-input";
import FormattedMessage from "components/common/FormattedMessage";
import { GoogleAdsSettingsSelector, GoogleSmartSettingsSelector, UpdateGoogleAdsSettings, smartSlice } from "modules/smart/smartSlice";
import { currentProjectIdSelector } from "services/store/scopeSlice";

const UpdateGoogleSmartSettings:callAPIProps = {
    url: ({ getApiUrl, projectId, smart }) => getApiUrl(`projects/${projectId}/smarts/${smart}/google-smart-settings`, 2),
    method: "PATCH",
    successDispatch: smartSlice.actions.updateGoogleSmartSettings,
}

export default function SettingsGoogleGeneral(props:any) {

    const { formatMessage, formatNumber } = useIntl();

    const { call, data, loading, error, StatusDisplay } = useAPI({
        ...UpdateGoogleSmartSettings, 
            method: "GET", 
        dispatchName: "getSmartSettings",
    }, true);

    const settings = useSelectorWithParams([GoogleSmartSettingsSelector]);
    const projectId = useSelector(currentProjectIdSelector);

    const budget = () => {
        if (props.budget === "full") {
            return (
                <RelaxMultiform
                name="googleGeneralSettings"
                initialValuesSelector={GoogleSmartSettingsSelector}
                callAPI={UpdateGoogleAdsSettings}
                finalizeSubmit={(values) => ({ ...values, project_id: projectId })}
                
            >

            <StandardRow>
                <Col xs={24} sm={12} md={6}>
                <h3>
                <FormattedMessage
                            id="apps.smart.forms.budget.google.displayAd"
                            defaultMessage="Display Ad"
                        />
                        </h3>
                    <RelaxSingleField
                        name="display_ad_daily_budget"
                        label={<FormattedMessage
                            id="apps.smart.components.adsSettings.dailyBudgetLabel.label"
                            defaultMessage="Your daily advertising budget"
                        />}
                        tooltip={
                            <FormattedMessage
                            id="apps.smart.components.adsSettings.dailyBudgetLabel.tooltip"
                            defaultMessage="Your daily advertising budget"
                        />
                        }
                        initialValue={15}
                        initialValuesSelector={GoogleAdsSettingsSelector}
                    >
                        <BudgetInput
                            currency={settings?.currency}
                            className="fullwidth"
                            min={1}
                        />
                    </RelaxSingleField>
                    <RelaxSingleField
                            name="display_ad_period"
                            label={<FormattedMessage
                                id="apps.smart.components.adsSettings.promotionPeriod.label"
                                defaultMessage="Period of time to promote post"
                            />}
                            tooltip={
                                <FormattedMessage
                                id="apps.smart.components.adsSettings.promotionPeriod.tooltip"
                                defaultMessage="Period of time to promote post"
                            />
                            }
                            initialValuesSelector={GoogleAdsSettingsSelector}
                            initialValue={7}
                        >
                            <PeriodInput
                                className="fullwidth"
                                formatMessage={formatMessage}
                            />
                        </RelaxSingleField>
                </Col>
                <Col xs={24} sm={12} md={6}>
                <h3>
                <FormattedMessage
                            id="apps.smart.containers.adsSettings.view.searchAds"
                            defaultMessage="Search Ads"
                        />
                        </h3>
                    <RelaxSingleField
                        name="search_ad_default_daily_budget"
                        label={<FormattedMessage
                            id="apps.smart.containers.adsSettings.view.searchAdsLabel.label"
                            defaultMessage="Default daily budget per campaign"
                        />}
                        tooltip={
                            <FormattedMessage
                            id="apps.smart.containers.adsSettings.view.searchAdsLabel.tooltip"
                            defaultMessage="Default daily budget per campaign"
                        />
                        }
                        initialValuesSelector={GoogleAdsSettingsSelector}
                        initialValue= {15}
                    >
                        <BudgetInput
                            currency={settings?.currency}
                            className="fullwidth"
                            min={1}
                        />
                    </RelaxSingleField>
                </Col>
            </StandardRow>
            </RelaxMultiform>
            )
        }
        return (        
            <StandardRow>
            <Col xxl={6} lg={6} md={24} sm={24} xs={24}>
                <RelaxSingleField
                    name="general_budget"
                    label={<FormattedMessage
                        id="panel.containers.advancedSettings.google.general.budget.label"
                        defaultMessage="Budget"
                    />}
                    tooltip={
                        <FormattedMessage
                        id="panel.containers.advancedSettings.google.general.budget.tooltip"
                        defaultMessage="Budget"
                    />
                    }
                    initialValuesSelector={GoogleSmartSettingsSelector}
                    callAPI={UpdateGoogleSmartSettings}
                >
                    <BudgetInput
                        currency={settings?.currency}
                        className="fullwidth"
                        min={1}
                    />
                </RelaxSingleField>
            </Col>
        </StandardRow>
        )
    }


    if (loading || error) return StatusDisplay

    return (
        <>
            <StandardRow>
                <Col span={24}>
                    <GeoPointsGoogle />
                </Col>
            </StandardRow>

                {budget()}

        </>
    )

}
