import { RSAA } from "redux-api-middleware";
import { getApiUrl } from "services/helpers/api-helpers";
//import { LanguageLocalStorage } from "services/helpers/local-storage";
import Action from "services/helpers/create-action";

export const USER_DATA_SET = "USER_DATA_SET";
export const USER_DATA_SET_REQUEST = `${USER_DATA_SET}_REQUEST`;
export const USER_DATA_SET_SUCCESS = `${USER_DATA_SET}_SUCCESS`;
export const USER_DATA_SET_FAILURE = `${USER_DATA_SET}_FAILURE`;

export const setUserData = (userData) => {
  return {
    [RSAA]: {
      endpoint: getApiUrl("user"),
      method: "PUT",
      authorized: true,
      types: [
        USER_DATA_SET_REQUEST,
        {
          type: USER_DATA_SET_SUCCESS,
          payload: (action, state, res) =>
            res.json().then((response) => {
              //LanguageLocalStorage.set(userData.language);

              return response;
            }),
        },
        USER_DATA_SET_FAILURE,
      ],
      body: JSON.stringify(userData),
    },
  };
};

export const updateUserPhoneNumber = new Action(
  "updateUserPhoneNumber",
  ({ phone, verifyCode }) => ({
    method: "PATCH",
    endpoint: getApiUrl(`user/phone`),
    authorized: true,
    normalizeJsonApiResponse: true,
    body: JSON.stringify({
      phone_number: phone,
      verify_code: verifyCode,
    }),
  })
);

export const setUserStatement = new Action("setUserStatement", (values) => ({
  method: "POST",
  endpoint: getApiUrl(`user/statement`),
  authorized: true,
  body: JSON.stringify(values),
  normalizeJsonApiResponse: true,
}));
