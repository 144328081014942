import React, { useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { GoogleMap, LoadScript, useJsApiLoader, Marker, Circle, Autocomplete, useLoadScript, Polygon, useGoogleMap } from '@react-google-maps/api';
import countriesBoundaries from "./countries.json";
import AwesomeIcon from 'components/common/AwesomeIcon';
import { faArrowsToCircle, faArrowsUpDown, faCaretDown, faCheckSquare, faExpand, faSquare } from '@fortawesome/pro-light-svg-icons';
import { Tooltip } from 'antd';
import useSelectorWithParams from 'services/hooks/useSelectorWithParams';
import { userSettingsInterfaceLanguageSelector } from 'state/user/userSettingsSlice';
import { userLanguageSelector } from 'modules/panel/config/selectors/user';

export const CountryPolygon = (countryCode: string) => {

  if (!countryCode) return null;

  const country = countriesBoundaries.find((c: any) => c.properties.ISO_A2 === countryCode);

  if (!country) return null;

  if (!country.geometry) return null;
  if (!country.geometry.coordinates[0]) return null;
  let coordinates:{lat: number, lng: number}[] = [];
  coordinates = country.geometry.coordinates[0].map((c: any) => ({lat: c[1], lng: c[0]}))
  return coordinates
}

const defaultMapStyle = [{
  featureType: "water",
  elementType: "geometry",
  stylers: [
    {
      color: "#e9e9e9",
    },
    {
      lightness: 17,
    },
  ],
},
{
  featureType: "landscape",
  elementType: "geometry",
  stylers: [
    {
      color: "#f5f5f5",
    },
    {
      lightness: 20,
    },
  ],
},
{
  featureType: "road.highway",
  elementType: "geometry.fill",
  stylers: [
    {
      color: "#ffffff",
    },
    {
      lightness: 17,
    },
  ],
},
{
  featureType: "road.highway",
  elementType: "geometry.stroke",
  stylers: [
    {
      color: "#ffffff",
    },
    {
      lightness: 29,
    },
    {
      weight: 0.2,
    },
  ],
},
{
  featureType: "road.arterial",
  elementType: "geometry",
  stylers: [
    {
      color: "#ffffff",
    },
    {
      lightness: 18,
    },
  ],
},
{
  featureType: "road.local",
  elementType: "geometry",
  stylers: [
    {
      color: "#ffffff",
    },
    {
      lightness: 16,
    },
  ],
},
{
  featureType: "poi",
  elementType: "geometry",
  stylers: [
    {
      color: "#f5f5f5",
    },
    {
      lightness: 21,
    },
  ],
},
{
  featureType: "poi.park",
  elementType: "geometry",
  stylers: [
    {
      color: "#dedede",
    },
    {
      lightness: 21,
    },
  ],
},
{
  elementType: "labels.text.stroke",
  stylers: [
    {
      visibility: "on",
    },
    {
      color: "#ffffff",
    },
    {
      lightness: 16,
    },
  ],
},
{
  elementType: "labels.text.fill",
  stylers: [
    {
      saturation: 36,
    },
    {
      color: "#333333",
    },
    {
      lightness: 40,
    },
  ],
},
{
  elementType: "labels.icon",
  stylers: [
    {
      visibility: "off",
    },
  ],
},
{
  featureType: "transit",
  elementType: "geometry",
  stylers: [
    {
      color: "#f2f2f2",
    },
    {
      lightness: 19,
    },
  ],
},
{
  featureType: "administrative",
  elementType: "geometry.fill",
  stylers: [
    {
      color: "#fefefe",
    },
    {
      lightness: 20,
    },
  ],
},
{
  featureType: "administrative",
  elementType: "geometry.stroke",
  stylers: [
    {
      color: "#fefefe",
    },
    {
      lightness: 17,
    },
    {
      weight: 1.2,
    },
  ],
},]

const containerStyle = {
  width: '100%',
  height: '100%'
};

const defaultCenter = {
  lat: 54,
  lng: 18
};

const standardCircleStyle = {
  strokeColor: '#2E4CC2',
  strokeOpacity: 0.5,
  strokeWeight: 2,
  fillColor: 'rgb(4, 50, 196)',
  fillOpacity: 0.2,
}


const libraries:["places"] = ["places"]

const Map = React.forwardRef((props: any, ref: any) => {

  const [isExpanded, setIsExpanded] = useState(false)
  const [autoFrame, setAutoFrame] = useState(true)
  const language = useSelectorWithParams(userLanguageSelector)

  const { isLoaded } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_API_KEY,
    libraries: libraries,
    mapIds: ["123"],
    language: language || "en",
  })

  const [map, setMap] = useState<any>(null)
  const [wasMoved, setWasMoved] = useState(false)

  const onLoad = React.useCallback(function callback(map:any) {
    const bounds = new window.google.maps.LatLngBounds(defaultCenter);
    map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map:any) {
    setMap(null)
  }, [])


  const markers = useMemo(() => {
    if (!props.markers) return []
    return props.markers && props.markers.map((marker:any) => 
    <Marker
    {...marker}
    onDragEnd={(e) => {
      const longitude = e.latLng?.lng();
      const latitude = e.latLng?.lat();
      if (props.onDragEnd) {
        props.onDragEnd(marker, longitude, latitude)
      }
      if (props.onMarkerLocationChange) {
        props.onMarkerLocationChange({ latitude, longitude });
      }
    }}
    /> ) 
  }, [props.markers])

  const circles = useMemo(() => {
    if (!props.circles) return []
    return props.circles.map((circle:any) => 
      <Circle options = {{...standardCircleStyle}} center = {circle.position} radius = {circle.radius} />
    )
  }, [props.circles])


  const regions = useMemo(() => {
    if (!props.regions) return []
    return props.regions.map((region:any) =>
      <Polygon path={CountryPolygon(region)} options = {{...standardCircleStyle}}/>
    )
  }, [props.regions])

  const frameView = () => {

    if (!props.markers || props.markers.length === 0) return;

    let countryMode = false;

    const bounds = new window.google.maps.LatLngBounds();
    props.markers && props.markers.forEach((marker:any) => {
      
      const isCountry = marker?.meta?.data?.attributes?.type === "country";
      if (isCountry) countryMode = true;
      bounds.extend(new window.google.maps.LatLng(marker.position.lat, marker.position.lng))
    });
    props.circles && props.circles.forEach((circle:any) => {


      const lat = circle.position.lat;
      const lng = circle.position.lng;
      const radius = circle.radius;

      if (!lat || !lng || !radius) return;

      if(isNaN(lat) || isNaN(lng) || isNaN(radius)) {
        console.error("Invalid circle definition:", circle);
        return;
    }
      
      const latRadian = lat * Math.PI / 180;
      const degLatKm = 111.321; // A rough value for converting degrees latitude to km
      const degLongKm = 111.321 * Math.cos(latRadian);
      
      // Convert circle radius from meters to degrees for both latitude and longitude
      const deltaLat = radius / 1000.0 / degLatKm;
      const deltaLng = radius / 1000.0 / degLongKm;
  
      const northmost = lat + deltaLat;
      const southmost = lat - deltaLat;
      const eastmost = lng + deltaLng;
      const westmost = lng - deltaLng;
  
      if(isNaN(northmost) || isNaN(southmost) || isNaN(eastmost) || isNaN(westmost)) {
        console.error("Invalid boundary calculation for circle:", circle);
        return;
    }

      bounds.extend(new window.google.maps.LatLng(northmost, lng));
      bounds.extend(new window.google.maps.LatLng(southmost, lng));
      bounds.extend(new window.google.maps.LatLng(lat, eastmost));
      bounds.extend(new window.google.maps.LatLng(lat, westmost));

    });


     const center = bounds.getCenter();

      if (map) {
        map.panTo(center);
        if (countryMode) {
          map.setZoom(4)
        } else {
          map.fitBounds(bounds);
        }

      }

  }


  const toggleExpand = () => {
    setIsExpanded(e => !e)
  }


  const l = props?.markers?.length

  useEffect(() => {
      frameView();
  }, [map])

  useEffect(() => {
    autoFrame && frameView();
  }, [props.markers])

  useEffect(() => {
    if (!map) return;
    if (autoFrame) frameView();
  }, [autoFrame]);

  const h = props.height ? props.height : 300;
  const height = typeof props.height === "string" ? props.height : `${isExpanded ? h * 2 : h}px`;


  useImperativeHandle(ref, () => ({
    map: map,
    frameView: frameView,
  }))
  

  return isLoaded ? (
      <GoogleMap
        id="map"
        mapContainerStyle={{...containerStyle, height: height}}
        //center={center}
        zoom={6}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          styles: defaultMapStyle,
          scrollwheel: props.scrollwheel,
          disableDefaultUI: true,
          maxZoom: 16,
        }}
      >
       {/* {centerMarker} */}
        {markers}
        {circles}
        {regions}
        <MapInside>
          <Tooltip title="AutoFrame">
            <AwesomeIcon icon={autoFrame ? faCheckSquare : faSquare} size="xl" onClick={() => setAutoFrame(e => !e)}
            />
          </Tooltip>
          <Tooltip title="Frame View">
            <AwesomeIcon icon={faArrowsToCircle} size="xl" onClick={frameView}
            />
          </Tooltip>
          <Tooltip title="Expand View">
            <AwesomeIcon icon={faArrowsUpDown} size="xl" onClick={toggleExpand}/>
          </Tooltip>
          {props.children}
        </MapInside>
      </GoogleMap>
  ) : <>Loading...</>
})

const MapInside = (props:any) => {

  const map = useGoogleMap()

  return (
    <div className="map-extra">
      {props.children}
    </div>
  )

  return null;
}

export default Map