import { ReactNode } from "react";
import FormattedMessage from "components/common/FormattedMessage";

export type KeywordConfig = {
    name: string,
    displayName: ReactNode,
    shortDisplayName?: ReactNode,
    tooltip?: ReactNode,
    icon?: ReactNode
}

export type KeywordPair = {
    keyword: KeywordConfig,
    remarketing?: KeywordConfig
}

export type KeywordType = "initial" | "employment" | "credit" | "housing" | "politics" | "gambling" | "forceInterest";

export const KeywordsConfig = new Map<KeywordType, KeywordPair>([
    ["initial", {
        keyword: {
            name: "initial_keyword",
            displayName: (
                <FormattedMessage
                    id="config.keywords.initial.keyword.displayName"
                    defaultMessage="Magic word"
                />
            ),
            shortDisplayName: (
                <FormattedMessage
                    id="config.keywords.initial.keyword.shortDisplayName"
                    defaultMessage="Magic word"
                />
            ),
            tooltip: (
                <FormattedMessage
                    id="config.keywords.initial.keyword.tooltip"
                    defaultMessage="Place this word in the content of the post published on your fanpage. We will automatically create an advertisement to created post. It can be a hashtag, e.g. #MyCompanyName"
                />
            )
        },
        remarketing: {
            name: "remarketing_keyword",
            displayName: (
                <FormattedMessage
                    id="config.keywords.initial.remarketing.displayName"
                    defaultMessage="Remarketing keyword"
                />
            ),
            tooltip: (
                <FormattedMessage
                    id="config.keywords.initial.remarketing.tooltip"
                    defaultMessage="Remarketing keyword"
                />
            )
        }
    }],
    ["employment", {
        keyword: {
            name: "employment_keyword",
            displayName: (
                <FormattedMessage
                    id="config.keywords.employment.keyword.displayName"
                    defaultMessage="Job offers campaign"
                />
            ),
            shortDisplayName: (
                <FormattedMessage
                    id="config.keywords.employment.keyword.shortDisplayName"
                    defaultMessage="Job offers"
                />
            ),
            tooltip: (
                <FormattedMessage
                    id="config.keywords.employment.keyword.tooltip"
                    defaultMessage="Place this word in the content of the post published on your fanpage. We will automatically create an advertisement to created post. It can be a hashtag, e.g. #MyCompanyName"
                />
            )
        },
        remarketing: {
            name: "employment_remarketing_keyword",
            displayName: (
                <FormattedMessage
                    id="config.keywords.employment.remarketing.displayName"
                    defaultMessage="Job offers remarketing keyword"
                />),
            tooltip: (
                <FormattedMessage
                    id="config.keywords.employment.remarketing.tooltip"
                    defaultMessage="Job offers remarketing keyword"
                />
            )
        }
    }],
    ["credit", {
        keyword: {
            name: "credit_keyword",
            displayName: (
                <FormattedMessage
                    id="config.keywords.credit.keyword.displayName"
                    defaultMessage="Credit offer campaign"
                />
            ),
            shortDisplayName: (
                <FormattedMessage
                    id="config.keywords.credit.keyword.shortDisplayName"
                    defaultMessage="Credit"
                />
            ),
            tooltip: (
                <FormattedMessage
                    id="config.keywords.credit.keyword.tooltip"
                    defaultMessage="Place this word in the content of the post published on your fanpage. We will automatically create an advertisement to created post. It can be a hashtag, e.g. #MyCompanyName"
                />
            )
        },
        remarketing: {
            name: "credit_remarketing_keyword",
            displayName: (
                <FormattedMessage
                    id="config.keywords.credit.remarketing.displayName"
                    defaultMessage="Credit remarketing keyword"
                />),
            tooltip: (
                <FormattedMessage
                    id="config.keywords.credit.remarketing.tooltip"
                    defaultMessage="Credit remarketing keyword"
                />
            )
        }
    }],
    ["housing", {
        keyword: {
            name: "housing_keyword",
            displayName: (
                <FormattedMessage
                    id="config.keywords.housing.keyword.displayName"
                    defaultMessage="Housing offer campaign"
                />
            ),
            shortDisplayName: (
                <FormattedMessage
                    id="config.keywords.housing.keyword.shortDisplayName"
                    defaultMessage="Housing"
                />
            ),
            tooltip: (
                <FormattedMessage
                    id="config.keywords.housing.keyword.tooltip"
                    defaultMessage="Place this word in the content of the post published on your fanpage. We will automatically create an advertisement to created post. It can be a hashtag, e.g. #MyCompanyName"
                />
            )
        },
        remarketing: {
            name: "housing_remarketing_keyword",
            displayName: (
                <FormattedMessage
                    id="config.keywords.housing.remarketing.displayName"
                    defaultMessage="Housing remarketing keyword"
                />),
            tooltip: (
                <FormattedMessage
                    id="config.keywords.housing.remarketing.tooltip"
                    defaultMessage="Housing remarketing keyword"
                />)
        }
    }],
    ["politics", {
        keyword: {
            name: "politics_keyword",
            displayName: (
                <FormattedMessage
                    id="config.keywords.politics.keyword.displayName"
                    defaultMessage="Politics offer campaign"
                />
            ),
            shortDisplayName: (
                <FormattedMessage
                    id="config.keywords.politics.keyword.shortDisplayName"
                    defaultMessage="Politics"
                />
            ),
            tooltip: (
                <FormattedMessage
                    id="config.keywords.politics.keyword.tooltip"
                    defaultMessage="Place this word in the content of the post published on your fanpage. We will automatically create an advertisement to created post. It can be a hashtag, e.g. #MyCompanyName"
                />
            )
        },
        remarketing: {
            name: "politics_remarketing_keyword",
            displayName: (
                <FormattedMessage
                    id="config.keywords.politics.remarketing.displayName"
                    defaultMessage="Politics remarketing keyword"
                />),
            tooltip: (
                <FormattedMessage
                    id="config.keywords.politics.remarketing.tooltip"
                    defaultMessage="Politics remarketing keyword"
                />)
        }
    }],
    ["gambling", {
        keyword: {
            name: "gambling_keyword",
            displayName: (
                <FormattedMessage
                    id="config.keywords.gambling.keyword.displayName"
                    defaultMessage="Gambling offer campaign"
                />
            ),
            shortDisplayName: (
                <FormattedMessage
                    id="config.keywords.gambling.keyword.shortDisplayName"
                    defaultMessage="Gambling"
                />
            ),
            tooltip: (
                <FormattedMessage
                    id="config.keywords.gambling.keyword.tooltip"
                    defaultMessage="Place this word in the content of the post published on your fanpage. We will automatically create an advertisement to created post. It can be a hashtag, e.g. #MyCompanyName"
                />
            )
        },
        remarketing: {
            name: "gambling_remarketing_keyword",
            displayName: (
                <FormattedMessage
                    id="config.keywords.gambling.remarketing.displayName"
                    defaultMessage="Gambling remarketing keyword"
                />),
                tooltip: (
                    <FormattedMessage
                        id="config.keywords.gambling.remarketing.tooltip"
                        defaultMessage="Gambling remarketing keyword"
                    />)
        }
    }],
    ["forceInterest", {
        keyword: {
            name: "force_interests_keyword",
            displayName: (
                <FormattedMessage
                    id="config.keywords.forceInterest.keyword.displayName"
                    defaultMessage="Force Interests"
                />
            ),
            tooltip: (
                <FormattedMessage
                    id="config.keywords.forceInterest.keyword.tooltip"
                    defaultMessage=""
                />
            )
        },
        // remarketing: {
        //     name: "force_interests_remarketing_keyword",
        //     displayName: (
        //     <FormattedMessage
        //         id="config.keywords.forceInterest.remarketing.displayName"
        //         defaultMessage="Force Interest remarketing keyword"
        //       />)
        // }
    }],
]);

export const KeywordNameByAttributeName = {
    "initial_keyword": "initial",
    "employment_keyword": "employment",
    "credit_keyword": "credit",
    "housing_keyword": "housing",
    "politics_keyword": "politics",
    "gambling_keyword": "gambling",
    "force_interests_keyword": "forceInterest",
    "remarketing_keyword": "initial",
    "employment_remarketing_keyword": "employment",
    "credit_remarketing_keyword": "credit",
    "housing_remarketing_keyword": "housing",
    "politics_remarketing_keyword": "politics",
    "gambling_remarketing_keyword": "gambling",
    "force_interests_remarketing_keyword": "forceInterest",
    "online_gambling_and_gaming": "gambling",
    "issues_elections_politics": "politics",
    "credit": "credit",
    "employment": "employment",
    "housing": "housing",   
}

export const KeywordObjectByAttributeName: any = {
    "initial_keyword": KeywordsConfig.get("initial")?.keyword,
    "employment_keyword": KeywordsConfig.get("employment")?.keyword,
    "credit_keyword": KeywordsConfig.get("credit")?.keyword,
    "housing_keyword": KeywordsConfig.get("housing")?.keyword,
    "politics_keyword": KeywordsConfig.get("politics")?.keyword,
    "gambling_keyword": KeywordsConfig.get("gambling")?.keyword,
    "force_interests_keyword": KeywordsConfig.get("forceInterest")?.keyword,
    "remarketing_keyword": KeywordsConfig.get("initial")?.remarketing,
    "employment_remarketing_keyword": KeywordsConfig.get("employment")?.remarketing,
    "credit_remarketing_keyword": KeywordsConfig.get("credit")?.remarketing,
    "housing_remarketing_keyword": KeywordsConfig.get("housing")?.remarketing,
    "politics_remarketing_keyword": KeywordsConfig.get("politics")?.remarketing,
    "gambling_remarketing_keyword": KeywordsConfig.get("gambling")?.remarketing,
    "force_interests_remarketing_keyword": KeywordsConfig.get("forceInterest")?.remarketing,
    "initial": KeywordsConfig.get("initial")?.keyword,
    "employment": KeywordsConfig.get("employment")?.keyword,
    "credit": KeywordsConfig.get("credit")?.keyword,
    "housing": KeywordsConfig.get("housing")?.keyword,
    "politics": KeywordsConfig.get("politics")?.keyword,
    "gambling": KeywordsConfig.get("gambling")?.keyword,
    "issues_elections_politics": KeywordsConfig.get("politics")?.keyword,
    "online_gambling_and_gaming": KeywordsConfig.get("gambling")?.keyword,
}