import { faGoogle } from "@fortawesome/free-brands-svg-icons"
import { faPlugCircleBolt } from "@fortawesome/pro-light-svg-icons"
import { AwesomeFacebookBlue, AwesomeIconFacebook } from "components/common/AwesomeIcon"
import StandardButton from "components/common/StandardButton"
import { useEffect, useMemo, useState } from "react"
import {FacebookPostPreview, FacebookFooter } from "./FacebookPostPreview"
import {GooglePostPreview, GoogleFooter } from "./GooglePostPreview"
import PhoneMockup from "./PhoneMockup"
import "./asset-preview.less"
import { useCreationEditor } from "tools/creationEditor/CreationEditor"
import { Creation } from "tools/creationEditor/creationsSlice"
import { InstagramPostPreview } from "./InstagramPostPreview"

const ProviderSelect = (props:any) => {

    const handleProviderSelect = (provider:string) => {
        props.onProviderSelect && props.onProviderSelect(provider)
    }

    return (
        <div style={{display: "flex", flexDirection: "row", position: "relative", top: "-20px", backgroundColor: "lightcyan"}}>
            <StandardButton 
            icon={AwesomeIconFacebook} 
            iconsProps={{color: "blue"}}
            onClick={() => handleProviderSelect("facebook")}
            />
            <StandardButton 
            icon={faGoogle} 
            onClick={() => handleProviderSelect("google")}
            />
        </div>
    )
}

const getPreviewProvider = (creation: Creation) => {
    if (!creation) return null;
    if (!creation.content) return null;
    if (!creation.content.provider || creation.content.provider.length === 0) return null;

    return creation.content.provider[0];

}

type CreationPreviewProps = {
    creation?: Creation
}

export default function CreationPreview (props:CreationPreviewProps) {

    const {creation: creationFromEditor} = useCreationEditor();
    const creation = props.creation || creationFromEditor;
    const [provider, setProvider] = useState(getPreviewProvider(creation))

    useEffect(() => {
        setProvider(getPreviewProvider(creation))
    }, [creation])

    const providerSelect = useMemo(() => {
        if (!creation) return null;
        if (creation.type === "post" || creation.type === "aoa") return <ProviderSelect onProviderSelect={(p:any) => setProvider(p)}/>
        return null;
    }, [creation])

    const providerPreview = () => {
        switch (provider) {
            case "facebook":
                return <FacebookPostPreview creation={creation}/>
            case "google":
                return <GooglePostPreview creation={creation}/>
            case "instagram": 
                return <InstagramPostPreview creation={creation}/>
            default:
                return null
        }
    }

    if (!creation) return null;

    return (
        <>
            <PhoneMockup provider={provider}>
                <div className="post-preview">
                    {providerPreview()}
                </div>
            </PhoneMockup>
        </>
    )
}