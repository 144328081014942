import { ReactNode } from "react";
import { mappedProvidersFields } from "./ProviderConfig";

type ProviderFieldIconProps = {
    field: string | {icon: ()=>ReactNode },
    className?: string,
    style?: React.CSSProperties,
    size?: "small" | "medium" | "large"
}

export const ProviderFieldIcon = ({ field, style, className, size }: ProviderFieldIconProps) => {

    if (!field) return null;

    const src = typeof field === "string" ? mappedProvidersFields.get(field).icon : field?.icon;

    if (!src) return null;

    return (
        <img
            src={src}
            style={style}
            className={`standard-avatar ${size ? size : "medium"} ${className ? className : ""}` }
        />
    );
};
