import { useEffect, useMemo, useRef, useState } from "react";
import useAPISmartActions from "services/hooks/useAPISmartActions";
import { useCreationEditor } from "../CreationEditor";


export type CreationSectionState = "initial" | "missingData" | "loading" | "error" | "ready" | null;

export type useCreationSectionProps = {
    sectionName: string,
    smartActionPrefix?: string,
    //Provides a list of all errors from validation. Filter to only show errors for this section. 
    //Will use the sectionName as key to get the errors from the validation results if no function is provided.
    filterValidationResults?: ((validationResults: any) => any) | string | string[],
    filterSubfieldValidationResults?: (validationResults: any) => any,
    filterSmartActions?: (smartActions: any) => any | string,
}

type ValidationErrors = Record<string, string | string[]> | string[] | null;

export type useCreationSection = {
    statusAPI: CreationSectionState,
    statusForm: CreationSectionState,
    statusValidation: CreationSectionState,
    statusData: CreationSectionState,
    status: CreationSectionState,
    setStatusData: (status: CreationSectionState) => void,
    ref: any,
    handleFieldError: (field: string, error: any) => void,
    handleFieldChange: (field: string, value: any) => void,
    handleFieldSuccess: (field: string, data: any) => void,
    sectionComponentProps: {
        statusAPI: CreationSectionState,
        statusForm: CreationSectionState,
        statusValidation: CreationSectionState,
        statusData: CreationSectionState,
        status: CreationSectionState,
        validationErrors: ValidationErrors,
        sectionName: string,
    }
}

export const validationErrorsToArray = (validationErrors: ValidationErrors): string[] => {
    if (!validationErrors) return [];
    if (typeof validationErrors === "string") return [validationErrors];
    if (Array.isArray(validationErrors)) return validationErrors;
    return Object.values(validationErrors).flat().filter((item) => !!item);
}

export const useCreationSection = (props: useCreationSectionProps): useCreationSection => {

    const sectionRef = useRef<any>(null);
    const [errors, setErrors] = useState<any>({});
    const { smartActions } = useAPISmartActions();
    const { setSectionStatus, errors: creationErrors, removeSection } = useCreationEditor()
    const [statusData, setStatusData] = useState<CreationSectionState>("initial");

    useEffect(() => {
        return () => {
            removeSection(props.sectionName);
        }
    }, [])

    //Validation errors from the whole creation filtered to only show errors for this section
    const sectionValidationErrors:ValidationErrors = useMemo(() => {
        if (!creationErrors) return null;
        if (props.filterValidationResults && typeof props.filterValidationResults === "function") return props.filterValidationResults(creationErrors);

        const keys:string[] = [];
        if (props.filterValidationResults) {
            if (typeof props.filterValidationResults === "string") keys.push(props.filterValidationResults);
            if (Array.isArray(props.filterValidationResults)) keys.push(...props.filterValidationResults);
        } else {
            keys.push(props.sectionName);
        }

        const errors:ValidationErrors = {};
        //find errors with keys that start with any of the "keys"
        Object.keys(creationErrors).forEach((key) => {
            if (keys.some((k) => key.startsWith(k))) errors[key] = creationErrors[key];
        })

        // keys.forEach((key) => {
        //     errors[key] = creationErrors[key];
        // })

        return errors;

    }, [creationErrors]);

    const sectionValidationStatus = useMemo(() => {
        if (!creationErrors) return "initial";
        const errorsArray = validationErrorsToArray(sectionValidationErrors);
        //console.log("errorArray", props.sectionName, errorsArray)
        if (errorsArray && errorsArray.length > 0) return "error";
        return "ready";
    }, [sectionValidationErrors]);

    const handleFieldError = (field: string, error: any) => {
        //console.log("Hook handling field error", field, error)
        setErrors((errors: any) => {
            let nr = { ...errors };
            if (!error && errors[field]) delete nr[field];
            else nr[field] = error;
            return nr;
        })
    }

    const handleFieldChange = (field: string, value: any) => {
        //console.log("Hook handling field change", field, value)
    }

    const handleFieldSuccess = (field: string, data: any) => {

        if (!data || !data.included) return;
        const creationErrors = data.included.find((item: any) => item._type === "creation_errors");
        if (!creationErrors) return;
        const errors = creationErrors?.errors;

    }

    const statusAPI: CreationSectionState = useMemo(() => {
        if (!props?.smartActionPrefix) return "ready";
        if (!smartActions) return "initial";
        const mySmartKeys = Object.keys(smartActions).filter((key) => key.startsWith(props.smartActionPrefix!));
        if (mySmartKeys.length === 0) return "initial";
        const mySmartActions = mySmartKeys.map((key) => smartActions[key]);
        if (mySmartActions.length === 0) return "initial";

        const atLeastOneError = mySmartActions.some((smartAction) => smartAction.state === "error");
        if (atLeastOneError) return "error";
        const allDone = mySmartActions.every((smartAction) => smartAction.state === "success");
        if (allDone) return "ready";
        const atLeastOnePending = mySmartActions.some((smartAction) => smartAction.state === "pending");
        if (atLeastOnePending) return "loading";

        return "ready";
    }, [smartActions])

    const statusForm: CreationSectionState = useMemo(() => {

        const errorsArray = Object.values(errors);
        if (errorsArray.some((error) => error)) return "error";
        return "ready";

    }, [errors])

    const status: CreationSectionState = useMemo(() => {

        //if at least one is loading return loading
        if (statusAPI === "loading" || statusData === "loading" || statusForm === "loading" || sectionValidationStatus === "loading") return "loading";

        //if at least one is error return error
        if (statusAPI === "error" || statusData === "error" || statusForm === "error" || sectionValidationStatus === "error") return "error";

        //if all are the same return that
        if (statusAPI === statusData && statusData === statusForm && statusForm === sectionValidationStatus) return statusAPI;

        return "ready"

    }, [statusData, statusAPI, statusForm, sectionValidationStatus])

    useEffect(() => {
        setSectionStatus(props.sectionName, status)
    }, [status]);


    return {
        ref: sectionRef,
        setStatusData,
        statusAPI,
        statusForm,
        statusValidation: sectionValidationStatus,
        statusData,
        status,
        handleFieldError,
        handleFieldChange,
        handleFieldSuccess,
        sectionComponentProps: {
            sectionName: props.sectionName,
            statusAPI,
            statusForm,
            statusValidation: sectionValidationStatus,
            statusData,
            status,
            validationErrors: sectionValidationErrors,
        },
    }

}
