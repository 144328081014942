import useAPI from "services/hooks/useAPI";
import lexiconSlice, { GetLexicon, LexiconTypeSelector } from "../state/lexiconSlice";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { Select } from "antd";
import { FormattedMessage } from "react-intl";
import { useEffect, useMemo, useRef } from "react";

const SelectFromLexicon = (props: any) => {
  const lexiconCall = useAPI(GetLexicon(props.selectedType), true);
  const lexiconData = useSelectorWithParams([LexiconTypeSelector, props.selectedType]);
  const options = useMemo(() => {
    return lexiconData.map((item: any) => ({
      value: item.id,
      label: item.name,
    }));
  }, [lexiconData]);
  return (
    <Select onBlur={props.onBlur} onChange={props.onChange} value={props.value} options={options}/>
 
  );
};

export default SelectFromLexicon;