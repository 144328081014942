import { Dropdown, Space } from "antd";
import StandardButton from "components/common/StandardButton";
import React from "react";
import { ReactNode, useMemo, useState } from "react";
import { callAPISettings } from "services/hooks/useAPI";
import useCallAfterUpdate from "services/hooks/useCallAfterUpdate";
import { StandardModal, useStandardModal } from "services/hooks/useModal";
import { tool, toolsGroup, useOrganizationTool } from "../OrganizationManager";


export default function OrganizationTools(props: any) {

    const { openTool, inputData} = useOrganizationTool();

    return (
        <Space wrap>{props.toolset?.tools && props.toolset.tools.map((t: tool) =>
            <StandardButton
                onClick={() => openTool(t)}
                disabled={t.disabled ? t.disabled(inputData) : false}
                >
                {t.label}
            </StandardButton>
        )}</Space>
    )


}
