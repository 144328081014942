import { Progress } from "antd";
import { ChunkUpload } from "services/hooks/useUploadFile";

export type GalleryUploadEntryProps = {
    item: ChunkUpload,
}

export default function GalleryUploadEntry(props: GalleryUploadEntryProps) {
    if (!props.item) return null;
    const { item } = props;

    //console.log("GalleryUploadEntry", item.progress);

    if (item.error) {
        return (
        <div style={{display: "flex", flexDirection: "row"}}>
        <div>{item?.file?.name}</div>
        <div style={{padding: "10px"}}></div>
        <div>Error while uploading</div> 
        </div>
        )
    }

    return (
        <div style={{display: "flex", flexDirection: "row"}}>
              <div>{item?.file?.name}</div>
            <Progress strokeLinecap="butt" percent={Math.floor(item.progress*100 || 0)}/>
            </div>
    )
}