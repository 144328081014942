import { ReactNode, useEffect } from "react";
import FormattedMessage from "components/common/FormattedMessage";
import {
    faMinusCircle,
    faPlusCircle,
    faQuestionCircle,
    faTrash,
    faHeart
} from "@fortawesome/pro-light-svg-icons";
import AwesomeIcon from "components/common/AwesomeIcon";
import { Keyword as KWD } from "./KeywordsConfig";
import { Space, Spin } from "antd";
import { useIntl } from "react-intl";
import { Text } from "components/common/StandardText";
import { KeywordFacebookGroupsConfig, KeywordGroup } from "./KeywordsConfig";
import useAPI from "services/hooks/useAPI";
import { GetEstimate } from "modules/smart/smartSlice";
import InterestKeyword from "./InterestKeyword";

export type KeywordProps = {
    keyword?: any;
    mode: "suggestion" | "selected";
    inclusion?: "included" | "excluded";
    onInclude?: any;
    onExclude?: any;
    onRemove?: any;
    onMouseEnter?: (keyword: any) => void;
    onMouseLeave?: (keyword: any) => void;
};

export const KeywordTooltip = ({
    keyword,
    actions,
}: {
    keyword: KWD;
    actions?: ReactNode;
}) => {
    const { formatNumber } = useIntl();
    const { data, loading, call } = useAPI({});

    useEffect(() => {
        const callTimer = setTimeout(
            () =>
                call(
                    GetEstimate({
                        audience_interest: [
                            { ...keyword, type: "include", label: keyword.name },
                        ],
                    })
                ),
            1000
        );

        return () => clearTimeout(callTimer);
    }, []);

    const lower_bound = data?.estimate_mau_lower_bound || keyword?.lower_bound;
    const upper_bound = data?.estimate_mau_upper_bound || keyword?.upper_bound;

    return (
        <Space direction="vertical">
            {keyword?.name && (
                <Space direction="horizontal" wrap>
                <div className={'keyword-label'}>
                    <AwesomeIcon icon={faHeart} className="keyword-icon" size="xl" />
                    <b style={{ position: "relative" }}>{keyword && keyword.name}</b>
                </div>
                </Space>
            )}
            {keyword?.lower_bound && keyword?.upper_bound && (
                <Space direction="horizontal" wrap>
                    <Text strong>
                        <FormattedMessage
                            id="components.keywords.details.audience"
                            defaultMessage="Audience"
                        />
                    </Text>
                    {!data || loading ? (
                        <Spin />
                    ) : (
                        <span>
                            {formatNumber(lower_bound)} - {formatNumber(upper_bound)}
                        </span>
                    )}
                </Space>
            )}
            {keyword?.path && (
                <Space direction="horizontal" wrap>
                    <Text strong>
                        <FormattedMessage
                            id="components.keywords.details.category"
                            defaultMessage="Category"
                        />
                    </Text>
                    {keyword?.path && keyword.path.join(" > ")}
                </Space>
            )}
            {keyword?.description?.length > 0 && (
                <Space direction="horizontal" wrap>
                    <Text strong>
                        <FormattedMessage
                            id="components.keywords.details.description"
                            defaultMessage="Description"
                        />
                    </Text>
                    {keyword.description}
                </Space>
            )}
            {actions && (
                <div
                    style={{
                        gap: "0px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                    }}
                    className="keyword-actions-wrapper"
                >
                    {actions}
                </div>
            )}
        </Space>
    );
};

export const KeywordActions = ({ keyword, mode, ...props }: KeywordProps) => {
    if (mode === "suggestion")
        return [
            <button
                className="keyword-action exclude"
                onClick={() => props.onExclude && props.onExclude(keyword)}
            >
                <AwesomeIcon icon={faMinusCircle} className="keyword-icon" />
                <label>
                    <FormattedMessage
                        id="KeywordsInput.exclude"
                        defaultMessage="Exclude"
                    />
                </label>
            </button>,
            <button
                className="keyword-action include"
                onClick={() => props.onInclude && props.onInclude(keyword)}
            >
                <AwesomeIcon icon={faPlusCircle} className="keyword-icon" />
                <label>
                    <FormattedMessage
                        id="components.forms.inputs.keyword.include"
                        defaultMessage="Include"
                    />
                </label>
            </button>,
        ];

    if (mode === "selected")
        return [
            <button
                className="keyword-action remove"
                onClick={() => props.onRemove && props.onRemove(keyword)}
            >
                <AwesomeIcon icon={faTrash} className="keyword-icon" />
                <FormattedMessage
                    id="components.forms.inputs.keyword.remove"
                    defaultMessage="Remove"
                />
            </button>,
        ];

    return null;
};

export default function Keyword({
    keyword,
    mode,
    inclusion,
    ...props
}: KeywordProps) {
    const icon = KeywordFacebookGroupsConfig[(keyword?.group as KeywordGroup) || "interests"]?.icon || faQuestionCircle;

    const actions = <KeywordActions keyword={keyword} mode={mode} {...props} />;

    return (
        <InterestKeyword
            keyword={keyword}
            className={`${mode} ${inclusion}`}
            icon={icon}
            actions={actions}
            modalContent={<KeywordTooltip keyword={keyword} actions={actions} />}
            tooltipContent={<KeywordTooltip keyword={keyword} />}
            {...props}
        />
    );
}
