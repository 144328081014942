const themes:any = {
fasttony: () =>
import(/* webpackChunkName: "fasttony-theme" */ "themes/fasttony"),
  forsant: () =>
    import(/* webpackChunkName: "forsant-theme" */ "themes/forsant"),
};

export type Theme = keyof typeof themes;

export default themes;
