import { ProductConfig } from "products";
import logo from "products/faceMaster/logoFaceMaster.svg";
import requirements from "modules/smart/config/requirements";
import { Requirement } from "modules";

const faceMasterConfig: ProductConfig = {
  name: "Facemaster",
  logo: logo,
  requirements: requirements as Requirement[],
  index: "/smart/posts/list",
};

export default faceMasterConfig;
