import { useCreationEditor } from "tools/creationEditor/CreationEditor";

export const GooglePostPreview = (props:any) => {

    const {creation} = useCreationEditor();

    return (
        <div className="google-post-preview">
            Google Post Preview
            <div>
                {creation?.message}
            </div>
        
        </div>
    )
}

export const GoogleFooter = (props:any) => {
    return (
        <div className="google-footer">
            Google footer
        </div>
    )
}