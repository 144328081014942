import { faFilter, faFilterList, faFilters } from "@fortawesome/pro-light-svg-icons";
import StandardButton from "components/common/StandardButton";
import { createContext, ReactNode, useContext, useState } from "react";
import { StandardDrawer, useStandardDrawer } from "services/hooks/useDrawer";
import useSimpleBreakpoint from "services/hooks/useSimpleBreakpoint";
import "./toolbar.less";

type ToolbarProps = {
    title: ReactNode,
    className?: string,
    smallToolbar?: ReactNode,
    largeToolbar?: ReactNode,
    drawerToolbar?: ReactNode,
    width?:string
}    

type ToolbarContextType = {
    mode: "small" | "large" | "drawer",
}

const ToolbarContext = createContext({} as ToolbarContextType);
ToolbarContext.displayName = "ToolbarContext";
export const useToolbar = ():ToolbarContextType => useContext(ToolbarContext);


const Toolbar = (props: ToolbarProps) => {

    const [drawerRef, open, close, isOpen] = useStandardDrawer();
    const bp = useSimpleBreakpoint();


    return (
        <>
        <ToolbarContext.Provider value={{mode: "small"}}>
        <div className={`${props.className} toolbar toolbar-horizontal`}>
        {bp[2] ? props.smallToolbar :  props.largeToolbar}
        <StandardButton onClick={open} icon={faFilterList} />
        </div>
        </ToolbarContext.Provider>
        <StandardDrawer
            ref={drawerRef}
            mask={true}
            title={props.title}
            layout="standard"
            zIndex={110}
            width={props.width}
        >
            <ToolbarContext.Provider value={{mode: "drawer"}}>
            <div className={`${props.className} toolbar toolbar-vertical`}>
                {props.drawerToolbar ? props.drawerToolbar : props.largeToolbar}
            </div>
            </ToolbarContext.Provider>
        </StandardDrawer>
        </>
    )


}

Toolbar.Spacer = () => <div style={{ flex: 1 }}></div>

export default Toolbar;