import React, { Component, ReactNode } from "react";
import {
  Redirect,
  Route as RouterRoute,
  RouteComponentProps,
} from "react-router-dom";
import { getUrl, LOGIN, LOGOUT } from "modules/panel/config/routes";
import { userIsLoggedSelector } from "modules/panel/config/selectors/user";
import { connect } from "react-redux";

type AuthorizedRouteProps = {
  path: string[];
  pending?: boolean;
  logged?: boolean;
  children: ReactNode;
};

class AuthorizedRoute extends Component<AuthorizedRouteProps> {
  static getLoginUrlWithFromState = (routeProps: RouteComponentProps<any>) => {
    const loginUrl: {
      pathname: string;
      state: {
        routeKey: string;
        from?: string;
      };
    } = {
      pathname: getUrl(LOGIN),
      state: {
        routeKey: "",
        from: "",
      },
    };

    if (routeProps.location.pathname !== getUrl(LOGOUT))
      loginUrl.state.from = routeProps.location.pathname;
    return loginUrl;
  };

  render() {
    const { path, logged, children } = this.props;
    return (
      <RouterRoute
        path={path}
        render={(props) => (
          <>
            {logged ? (
              children
            ) : (
              <Redirect to={AuthorizedRoute.getLoginUrlWithFromState(props)} />
            )}
          </>
        )}
      />
    );
  }
}

const stateToProps = (state: any) => {
  return {
    logged: userIsLoggedSelector(state),
  };
};

export default connect(stateToProps)(AuthorizedRoute);
