import useAPI from "services/hooks/useAPI";
import { IntegrationSelect } from "./IntegrationSelect";

export const SmsApiCampaignSelect = ({...rest}) => {
  const { call, data, loading } = useAPI(
    {
      url: ({ getApiUrl, projectId }) =>
        getApiUrl(`projects/${projectId}/lead-bot/config/sms-api`),
      method: "POST",
      customNormalizer: (data: any) => {
        return data?.data?.attributes?.contact_groups || [];
      },
    },
    true
  );

  return (
    <>
    <IntegrationSelect data={data} call={call} {...rest}/>
    </>
  )
};
