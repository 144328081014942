import { Space, Table } from "antd"
import StandardActorCard from "components/common/StandardActorCard"
import { useEffect, useMemo } from "react"
import useSelectorWithParams from "services/hooks/useSelectorWithParams"
import { libraryProjectsSelector } from "state/librarySlice"
import { OrganizationGroupsSelector, OrganizationManagersSelector } from "../data/organizationSlice"
import { GroupsOfManager } from "../tools/OrganizationToolHelpers"


export default function OrganizationTableManagers(props:any) {



    const projects = useSelectorWithParams(libraryProjectsSelector)
    const managers = useSelectorWithParams(OrganizationManagersSelector)
    const groups = useSelectorWithParams(OrganizationGroupsSelector)

    const columns = [
        {
            title: "Manager",
            render: (text:any, record:any) => <StandardActorCard actor={record} />,
            width: "300px"
        },
        {
            title: "Groups",
            render: (text:any, record:any) => <GroupsOfManager managerId={record.id} />,
        }
    ]
    

    const adjustedData = useMemo(() => {
        return managers;
    }, [managers])

    const adjustedColumns = useMemo(() => {
            return columns;
    }, [])

    const rowSelection = {
        type: "checkbox",
        onChange: (selectedRowKeys:any, selectedRows:any) => {
            props.onSelectedRows && props.onSelectedRows(selectedRowKeys)
        },
        getCheckboxProps: (record:any) => ({
            disabled: false,
            name: record.id,
        })
    }

    
    return (
            <Table 
                rowKey={record => record.id}
                columns = {adjustedColumns} 
                dataSource = {adjustedData}
                rowSelection = {rowSelection}
                pagination = {false}
                />
    )


}