import { Collapse } from "antd";
import StandardButton from "components/common/StandardButton";
import DateTimeDisplay from "components/displays/DateTimeDisplay";
import DateTimeInput from "components/inputs/DateTimeInput";
import { useEffect, useMemo, useState } from "react";
import FormattedMessage from "components/common/FormattedMessage";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import BudgetInput from "_components/forms/budget-input";
import { usePost } from "./postContext"
import DeletePostWithConfirmation from "./postTools/DeletePostWithConfirmation";
import DetailsSection from "./postTools/DetailsSection";
import PostCard from "./postTools/PostCard";
import PostStats from "./postTools/PostStats";
import RetryPromotionOfAdWithError from "./postTools/RetryPromotionOfAdWithError";
import Post from "models/post";
import RelaxForm from "tools/relaxForm/RelaxForm";
import dayjs, { Dayjs } from 'dayjs';
import RelaxSingleField from "tools/relaxForm/RelaxField";
import RelaxControls from "tools/relaxForm/RelaxControls";
import useRelaxData from "tools/relaxForm/useRelaxData";
import { PostActionTools } from "./postTools/PostActionTools";
import PostPlatformLinks from "./postTools/PostPlatformLinks";
import { currentProjectIntegration } from "state/projects/projectSelectors";

const AdSettingsAdjust = (props: {ad: any, adCreatedAtDate: any}) => {

    const { post, markChangesMade } = usePost();
    const rd = useRelaxData({
        mode: "manual"
    });

    return (
        <RelaxForm
                    relaxData = {rd}
                    name="adSettings"
                    mode="manual"
                    callAPI={post.updateFacebookAd(props.ad.id)}
                    initialValues={{ 
                        budget: post.getBudgetByAd(props.ad),
                        end_time: Post.safelyGetAdEndDate(props.ad)
                    }}
                    finalizeSubmit={(values:any) => {
                        const {end_time, budget} = values?.adSettings;
                        let newValues:any = {};

                        if (end_time) {
                            //console.log("using new end time", end_time)
                            newValues.end_time = dayjs(end_time).format("YYYY-MM-DD HH:mm:ss");
                        } else {
                            //console.log("using old end time", Post.safelyGetAdEndDate(props.ad))
                            newValues.end_time = dayjs(Post.safelyGetAdEndDate(props.ad)).format("YYYY-MM-DD HH:mm:ss");
                        }

                        if (budget) {
                            //console.log("using new budget", budget)
                            newValues.budget = budget;
                        } else {
                            //console.log("using old budget", post.getBudgetByAd(props.ad))
                            newValues.budget = post.getBudgetByAd(props.ad);
                        }
                        return newValues;
                    }}
                    onFinish={(v:any) => {
                        if (v.status === 200 || v.status === 201 || v.status === 204) {
                            markChangesMade();
                        }
                    }}    
                >
                <RelaxSingleField
                    name="budget"
                    mode="manual"
                    label={<FormattedMessage
                        id="smart.components.facebookAdEditForm.budget"
                        defaultMessage="Daily ad budget"
                    />}         >
                    <BudgetInput style={{ width: "100%" }} />
                </RelaxSingleField>
                <RelaxSingleField
                    name="end_time"
                    mode="manual"
                    label={<FormattedMessage
                        id="smart.components.facebookAdEditForm.endDate"
                        defaultMessage="When to stop the ad"
                    />}
                >
                    <DateTimeInput disabledDate={true} allowedPeriodStart={dayjs(props.adCreatedAtDate).add(3, "days")} allowedPeriodEnd={dayjs(props.adCreatedAtDate).add(179, "days")} />
                </RelaxSingleField>
                <RelaxControls 
                relaxData={rd}
                side="right"/>
                </RelaxForm>
    )

}

export default function PostManagementDetails(props: any) {

    const { post, markChangesMade } = usePost();
    const [panel, setPanel] = useState<string | undefined>();
    const adAccount = useSelectorWithParams(currentProjectIntegration("service_facebook_ads_account"));

    const adCreatedAtDate = useMemo(() => {
        if (props.details?.ad?.created_at) {
            return dayjs(props.details.ad.created_at)
        }
        return dayjs();
    }, [post]);

    if (!post) return null;

    useEffect(() => {
        setPanel(props.details.page)
    }, [props.details]);

    const handlePanelChange = (e: any) => {
        setPanel(e.pop());
    }

    const hasAd = () => {
        return !!props.details.ad
    }


    useEffect(() => {
        if (!hasAd() && panel === "budget") {
            setPanel(undefined);
        }
    }, [panel]);


    const postCard = useMemo(() => {
        if (!post) return null;
        return (
            <DetailsSection
            >
                <PostCard />
            </DetailsSection>

        )
    }, [post])

    const quickLinksCard = useMemo(() => {

        return (
            <DetailsSection>
               <PostPlatformLinks hasAd={hasAd()} details={props.details}/>
            </DetailsSection>
        )

    }, [post, props.details])

    const resultCard = useMemo(() => {
        return (
                <PostStats isOpen={props.details.page === "details"}/>
        )
    }, [post, props.details])

    const adCard = useMemo(() => {

        if (!hasAd()) return null;
        return (
            <DetailsSection>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: "20px" }}>
                    <div style={{ width: "70px", height: "70px", display: "grid", placeItems: "center" }}>
                        <h1 style={{ marginBottom: "0px" }}>
                            <FormattedMessage
                                id="smart.components.ad.ad"
                                defaultMessage="Ad"
                            />
                        </h1>
                    </div>
                    <div>

                        <FormattedMessage
                            id="smart.components.ad.activefrom"
                            defaultMessage="From"
                        />
                        :
                        <DateTimeDisplay dateTime={Post.safelyGetAdStartDate(props.details.ad)} showDate showTime />
                    </div>
                    <div>
                        <FormattedMessage
                            id="smart.components.ads.activeTo"
                            defaultMessage="To"
                        />
                        :
                        <DateTimeDisplay dateTime={Post.safelyGetAdEndDate(props.details.ad)} showDate showTime />
                    </div>
                </div>
            </DetailsSection>
        )

    }, [props.details.ad])

    const adSettingsCard = useMemo(() => {


        if (!hasAd()) return null;
        if (!props.details.ad.adset) return null;
        return (
            <DetailsSection>
                <AdSettingsAdjust ad = {props.details.ad} adCreatedAtDate={adCreatedAtDate} />
            </DetailsSection>
        )
    }, [props.details.ad, adCreatedAtDate])

    const promoteCard = useMemo(() => {
        return (
            <Collapse
                activeKey={panel}
                onChange={handlePanelChange}
                style={{ marginBottom: "20px" }}
            >
                <Collapse.Panel header={"Promote"} key="promote">
                    <PostActionTools />
                </Collapse.Panel>
            </Collapse>
        )
    }, [post, panel])


    const addErrorCard = useMemo(() => <RetryPromotionOfAdWithError ad={props.details.ad}/>,  [post, props.details.ad])


    return (
        <div>
            {postCard}
            {quickLinksCard}
            {promoteCard}
            {resultCard}
            {adCard}
            {addErrorCard}
            {adSettingsCard}
            <Collapse activeKey={panel} onChange={handlePanelChange}>

                <Collapse.Panel header="Advanced" key="advanced">

                    
                    <div style={{paddingTop: "20px", paddingBottom: "20px"}}>
                    <p>LocalID: {post.local_id}</p>
                    <p>Type: {post.getType()}</p>
                    <p>ID: {post.getId()}</p>
                    <p>FacebookID: {post.getId("facebook")}</p>
                    <p>GoogleID: {post.getId("google")}</p>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                    <DeletePostWithConfirmation/>
                    <StandardButton onClick={() => console.log("POST", post)}>Debug Print</StandardButton>
                    </div>
                </Collapse.Panel>
            </Collapse>
        </div>
    )
}