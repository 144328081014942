import { combineReducers } from "redux";
import loadingReducer from "modules/panel/reducers/request-status/loading";
import errorReducer from "modules/panel/reducers/request-status/error";
import successReducer from "modules/panel/reducers/request-status/success";
import loginStatusReducer from "modules/panel/reducers/login";
import userReducer from "modules/panel/reducers/user";
import siteGenerator from "modules/site-generator/reducers/reducers";
import notificationsReducer from "modules/panel/reducers/notifications";
import postsSlice from "modules/posts/state/postsSlice";
import projectCombineReducers from "state/project/projectCombineReducers";
import callMeSlice from "state/callMeSlice";
import userSettingsSlice from "state/user/userSettingsSlice";
import { creationsSlice } from "tools/creationEditor/creationsSlice";
import organizationSlice from "tools/organization/data/organizationSlice";
import { tasksSlice } from "tools/tasks/data/tasksSlice";
import { librarySlice } from "state/librarySlice";
import { subscriptionsSlice } from "state/subscriptions/subscriptionsSlice";
import lexiconSlice from "modules/panel/state/lexiconSlice";
import { InvoiceSlice } from "modules/panel/containers/user/invoice/InvoiceSlice";
import { BotsSlice } from "modules/bot/containers/BotsSlice";
import { smartSlice } from "modules/smart/smartSlice";
import aiSlice from "modules/panel/state/AISlice";
import NotificationsSlice from "modules/panel/components/notifications/NotificationsSlice";
import companySlice from "modules/panel/state/companySlice";
import { callAppSlice } from "state/callAppSlice";
import threadsSlice from "modules/bot/state/threadsSlice";
import { campaignsSlice } from "modules/forsant/state/campaignsSlice";
import { scopeSlice } from "./scopeSlice";
import { projectsSlice } from "state/projects/projectsSlice";
import { productsCatalogSlice } from "state/productsCatalogSlice";

export default combineReducers({
  scope: scopeSlice.reducer,
  loginStatus: loginStatusReducer, //rename => auth
  requestStatuses: combineReducers({
    loading: loadingReducer,
    error: errorReducer,
    success: successReducer,
  }), //refactor
  user: userReducer,
  userSettings: userSettingsSlice.reducer,
  subscriptionsSlice: subscriptionsSlice.reducer,
  
  //projects: projectsReducer,
  projectsSlice: projectsSlice.reducer,

  //products: productsReducer,
  notifications: notificationsReducer,
  notificationsSlice: NotificationsSlice.reducer,
  tasks: tasksSlice.reducer,
  posts: postsSlice.reducer,
  project: projectCombineReducers,
  callMe: callMeSlice.reducer,
  
  company: companySlice.reducer,

  creations: creationsSlice.reducer,
  organization: organizationSlice.reducer,
  library: librarySlice.reducer,
  lexicon: lexiconSlice.reducer,
  invoices: InvoiceSlice.reducer,
  bots: BotsSlice.reducer,

  smart: smartSlice.reducer,
  ai: aiSlice.reducer,
  callApp: callAppSlice.reducer,
  threads: threadsSlice.reducer,
  campaigns: campaignsSlice.reducer,
  siteGenerator: siteGenerator.reducers,
  productsCatalog: productsCatalogSlice.reducer
});
