import { Input, Spin } from "antd";
import { HorizontalCenter } from "components/common/Center";
import FormattedMessage from "components/common/FormattedMessage";
import { StandardFullColumn, StandardHalfFullColumn, StandardRow, StandardThirdColumn } from "components/layout/StandardGrid";
import { AiProjectInfoSelector, GetAiProjectInfo, UpdateAIProjectInfo, ProjectHasAICompanyDescriptionSelector, ProjectHasAIProjectInfoSelector } from "modules/panel/state/AISlice";
import SettingsAI from "modules/smart/components/AIGeneration/SettingsAI"
import ConnectedOnboardingControls from "providers/onboarding/controls"
import { useOnboarding } from "providers/onboarding/onboarding-context";
import { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import useAPI from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import RelaxField from "tools/relaxForm/RelaxField";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";



const OnboardingAICompanyInfo = () => {

    const fullfilled = useSelectorWithParams(ProjectHasAICompanyDescriptionSelector);
    const intl = useIntl();

    const placeholder = intl.formatMessage({
        id: "apps.smart.components.aiSettings.companyDescription.placeholder",
        defaultMessage: "Tell us about your company and what you do.",
      })

    return (
        <>
            <RelaxMultiform
                initialValuesSelector={AiProjectInfoSelector}
                callAPI={UpdateAIProjectInfo}
            >
                        <RelaxField
                            name={"company_description"}
                            label={<FormattedMessage
                                id="apps.smart.components.aiSettings.companyDescription.label"
                                defaultMessage="Company Description"
                            />}
                        >
                             <Input.TextArea 
                             rows={6}
                             placeholder={placeholder}
                             />
                        </RelaxField>
            </RelaxMultiform>
            <ConnectedOnboardingControls relaxDontSubmit={true} nextDisabled={!fullfilled} />
        </>
    )

}

export default OnboardingAICompanyInfo;