import React, { Component, useRef, useEffect} from "react";


  // forwardedRef: any;
  // content: any;
  // style: any;
 

const IFrameContainer = (props:any) => {

  const frame = useRef(null);

  useEffect(() => {

      const iframe = frame.current;
      if (iframe) {
        const document = (iframe as any).contentDocument;
        document.body.innerHTML = props.content;
      }
  });

    return (
      <iframe
        ref={frame}
        title="IFRAME"
        style={props.style}
      />
    );

}

export default IFrameContainer;
