import { useCallback, useEffect, useRef, useState } from "react";
import { GalleryItem } from "./Gallery";

export type GalleryItemDisplayProps = {
  item: Partial<GalleryItem>;
  provider?: string;
  size?: string;
}

export default function GalleryItemDisplay({ item, ...props }: GalleryItemDisplayProps) {

  const canvasRef = useRef(null);

  const [display, setDisplay] = useState<any>();

  if (!item) return null;
  
  const GetCrop = () => {
    if (!item) return null;
    if (!item.crop) return null;
    if (props.provider && item.crop[props.provider]) return item.crop[props.provider];
    if (Object.keys(item.crop).length === 1) return item.crop[Object.keys(item.crop)[0]];
    return item.crop["default"];
  }

  const BuildCroppedImage = async () => {

    //console.log("build cropped image", item)

    if (!item || !item.url) return null;
    const crop = GetCrop();
    const image = document.createElement("img");
    image.setAttribute("src", item.url);
    image.setAttribute("crossorigin", "anonymous");
    image.onload = function () {
      const canvas = document.createElement("canvas");

      if (crop) {
        canvas.width = crop.width;
        canvas.height = crop.height;
        canvas.getContext("2d")?.drawImage(
          image,
          crop.x,
          crop.y,
          crop.width,
          crop.height,
          0,
          0,
          crop.width,
          crop.height
        );
      } else {
        canvas.getContext("2d")?.drawImage(image, 0,0, image.width, image.height, 0, 0, canvas.width, canvas.height);
      }

      setDisplay(
        <img
          //style={{ width: "100%" }}
          className="gallery-item-display image"
          src={canvas.toDataURL('image/png')}
          alt="post"
        />
      );

      // canvas.toBlob((blob) => {
      //   const reader = new FileReader();
      //   reader.readAsDataURL(blob as Blob);

      //   reader.onload = () => {

      //     setDisplay(
      //       <img
      //         style={{ width: "100%" }}
      //         className="gallery-item-display image"
      //         src={reader.result as any}
      //         alt="post"
      //       />
      //     );
      //   };
      // });


    };
  }

  const BuildVideoThumbnail = async () => {

    if (!item || !item.url) return null;
    const video = document.createElement("video");
    video.setAttribute("src", item.url);
    video.setAttribute("crossorigin", "anonymous");

    setDisplay(<video src={item.url} style={{width: "100%"}}/>)

    // console.log("video", video)
    // const canvas = document.createElement("canvas");
    // canvas.getContext("2d")?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    // canvas.toBlob((blob) => {
    //   const reader = new FileReader();
    //   reader.readAsDataURL(blob as Blob);

    //   reader.onload = () => {
    //     console.log("loaded")
    //     setDisplay(
    //       <video
    //         style={{ width: "100%" }}
    //         src={reader.result as any}
    //       />
    //     );
    //   };
    // });

  }

  useEffect(() => {
    if (!item || !item.url) return;
        switch (item.type) {
          case "image":
              BuildCroppedImage();
            break;
          case "video":
              BuildVideoThumbnail();
            break;
          default:
            return;
        }
      
    
  },[item]);

  if (!display) return null;

  return display

}