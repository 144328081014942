import FormattedMessage from 'components/common/FormattedMessage';
import StandardButton from 'components/common/StandardButton';
import useAPI, { callAPIProps } from 'services/hooks/useAPI';
import { useHistory } from 'react-router-dom';
import { TokenLocalStorage } from 'services/helpers/local-storage';
import Center, { HorizontalCenter } from 'components/common/Center';
import { Space } from 'antd';
import AwesomeIcon from 'components/common/AwesomeIcon';
import { faRightFromBracket } from '@fortawesome/pro-light-svg-icons';
import { LOGIN } from 'modules/panel/config/routes';

const logout: callAPIProps = {
    url: ({ getApiUrl, projectId }) => getApiUrl(`auth/logout`),
    dispatchName: "LOGOUT"
}

type UseLogout = {
    logout: () => void,
    loading: boolean,
}

export const useLogout = ():UseLogout => {
    const logoutCall = useAPI(logout);
    const history = useHistory();

    const handleLogout = () => {
        logoutCall.call();
        TokenLocalStorage.set(null);
        history.push(LOGIN);
    }

    return {
        logout: handleLogout,
        loading: logoutCall.loading,
    }

}

export const LogoutButton = () => {

    const {logout, loading} = useLogout();

    return (
        <button
            data-testid="logoutBtn"
            onClick={logout}
            className='logout-btn'
        >
            <AwesomeIcon icon={faRightFromBracket} />
        </button>
    )

}

function LogoutPage() {

    const {logout, loading} = useLogout();
    const history = useHistory();

    return (
        <Center>
            <Space direction='vertical' size='middle'>
                <FormattedMessage
                    id="panel.containers.logout.areYouSure"
                    defaultMessage="Are you sure you want to log out?"
                />
            <Space>
            <StandardButton
                    data-testid="logoutBtn"
                    loading={loading}
                    onClick={logout}
                    size="small"
                >
                    <FormattedMessage
                        id="panel.containers.logout.logMeOut"
                        defaultMessage="Yes"
                    />
                </StandardButton>
                <StandardButton type="primary" onClick={() => history.goBack()} size="small">
                    <FormattedMessage
                        id="panel.containers.logout.cancel"
                        defaultMessage="Cancel"
                    />
                </StandardButton>
            </Space>
            </Space>
        </Center>
    );


}

export default LogoutPage;