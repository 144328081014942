import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import { Spin, Tooltip } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import StandardAvatar from "components/common/Avatar/StandardAvatar";
import ProductLogo from "providers/routing/layouts/components/product-logo/product-logo";
import { getProductLogo } from "providers/routing/layouts/components/product-logo/smallProductLogo";
import useAPI, { callAPISettings } from "services/hooks/useAPI";
import {
  StandardModal,
  useSimpleModalWithAction,
  useStandardModal,
} from "services/hooks/useModal";
import AwesomeIcon from "components/common/AwesomeIcon";
import { faMinus, faMinusCircle } from "@fortawesome/pro-light-svg-icons";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { projectWithSubscriptionsSelector } from "state/projects/projectsSelectors";
import { detachSubscriptionFromProject } from "state/subscriptions/subscriptionsEndpoints";

type DetachSubscriptionItemProps = {
  projectId: string;
  subscription: any;
};

export const DetachSubscriptionModal = () => {
  const { call } = useAPI({});

  const [open, contextHolder] = useSimpleModalWithAction({
    width: "600px",
  });

  const handleOpen = (
    product: string,
    projectName: string,
    projectId: string,
    subscriptionId: string | number,
    onOk?: any
  ) => {
    const modal = open({
      title: (
        <FormattedMessage
          id="panel.containers.companySettings.detachSubscriptionModal.title"
          defaultMessage="Detach Subscription"
        />
      ),
      content: (
        <div>
          <FormattedMessage
            id="panel.containers.companySettings.detachSubscriptionItem"
            defaultMessage="Click to detach {product} from {project} project"
            values={{ product, project: projectName }}
          />
        </div>
      ),
      onOk: () => {
        onOk && onOk();
      },
      callAPI: () => detachSubscriptionFromProject(subscriptionId, projectId)
    });

    return modal;
  };

  return {
    detachSubscriptionModal: handleOpen,
    open: handleOpen,
    contextHolder,
  };
};

export const DetachSubscriptionItem = (props: DetachSubscriptionItemProps) => {
  const product = props.subscription.product;
  const subscriptionId = props.subscription.id;
  const { detachSubscriptionModal, contextHolder } = DetachSubscriptionModal();
  const project = useSelectorWithParams(projectWithSubscriptionsSelector(props.projectId))

  if (!project) return null;
  
  return (
    <>
      <Tooltip
        key={product}
        placement="left"
        title={
          <FormattedMessage
            id="panel.containers.companySettings.detachSubscriptionItem"
            defaultMessage="Click to detach {product} from {project} project"
            values={{ product, project: project.name }}
          />
        }
      >
        <div>
          <StandardAvatar 
            size="large"
            image={getProductLogo(product)} 
            overlay={<AwesomeIcon icon={faMinusCircle} size="2xl"/>}
            onClick={() =>
              detachSubscriptionModal(
                product,
                project.name,
                project.id,
                subscriptionId
              )
            }
          />
          </div>
      </Tooltip>
      {contextHolder}
    </>
  );
};
