import { Input, InputNumber } from "antd";
import AwesomeIcon from "components/common/AwesomeIcon";
import StandardButton from "components/common/StandardButton";
import { useEffect, useMemo, useState } from "react";
import useSimpleBreakpoint from "services/hooks/useSimpleBreakpoint";
import { faCity, faClose, faEdit, faFlag, faLocationDot, faMap, faTrash } from "@fortawesome/pro-light-svg-icons";
import FormattedMessage from "components/common/FormattedMessage";

const typeIcons:Record<string, any> = ({
    "country": faFlag,
    "region": faMap,
    "city": faCity,
    "custom_location": faLocationDot
})


const customLocation = <FormattedMessage
id="apps.smart.components.adsSettings.GeoPointsFacebook.customLocation"
defaultMessage="Custom location"
/>

const invalidLocation = <FormattedMessage
id="apps.smart.components.adsSettings.GeoPointsFacebook.invalidLocation"
defaultMessage="Invalid location"
/>


const wholeCountry = <FormattedMessage
id="apps.smart.components.adsSettings.GeoPointsFacebook.wholeCountry"
defaultMessage="Whole country"
/>


const CustomLocationEdit = (props: any) => {

    const {point} = props;
    const [lat, setLat] = useState(point?.latitude);
    const [long, setLong] = useState(point?.longitude);
    const [address, setAddress] = useState(point?.address);

    useEffect(() => {
        setLat(point?.latitude);
    }, [point?.latitude]);

    useEffect(() => {
        setLong(point?.longitude);
    }, [point?.longitude]);

    useEffect(() => {
        setAddress(point?.address);
    }, [point?.address]);

    const handleNameChange = () => {
        const np = {...props.point};
        np.address = address

        if (props.onChange) props.onChange(np);
    }

    const handleLatLongChange = () => {

        const np = {...props.point};
        const pLat = parseFloat(lat);
        const pLong = parseFloat(long);

        if (isNaN(pLat) || isNaN(pLong)) return;
        if (pLat === point.latitude && pLong === point.longitude) return;

        np.latitude = pLat;
        np.longitude = pLong;
        np.data.attributes.latitude = pLat;
        np.data.attributes.longitude = pLong;


        if (props.onChange) props.onChange(np);
    }

    if (!point) return null;

        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "5px"
                }}
            >
            <Input 
                    value={address} 
                    onChange={(v) => setAddress(v.target.value)}
                    onBlur={handleNameChange}
                    />
            Lat:
            <Input 
                    value={lat} 
                    onChange={(v) => setLat(v.target.value)}
                    onBlur={handleLatLongChange}
                    style={{maxWidth: "250px"}}
                    />
            Long:
            <Input 
                    value={long} 
                    onChange={(v) => setLong(v.target.value)}
                    onBlur={handleLatLongChange}
                    style={{maxWidth: "250px"}}
            />
            </div>
        )
    



}

export default function GeoPointsEntryFacebook (props: any) {

    const [bp, bpi] = useSimpleBreakpoint();

    if (!props.point) return (
        <div className="geo-points-entry">
            {invalidLocation}
        </div>
    )

    const type = props?.point?.data?.attributes?.type;

   

    const customPointForm = useMemo(() => {
        if (type !== "custom_location") return null;

    }, [props.point])

    const displayAddress = useMemo(() => {
        if (type === "custom_location") return <CustomLocationEdit point={props.point} onChange={props.onChange}/>;
        if (type === "country") return props.point?.data?.attributes?.name;
        if (type === "region") return `${props.point?.data?.attributes?.name}, ${props.point?.data?.attributes?.country_name}`;
        if (type === "city") return `${props.point?.data?.attributes?.name}, ${props.point?.data?.attributes?.region}, ${props.point?.data?.attributes?.country_name}`;
        return props.point?.address;
    }, [props.point])

    const getMin = () => {
        if (props.point?.data?.attributes?.type === "custom_location") return 1;
        if (props.point?.radius === 0) return 0;
        return 17;
    }

    const handleRadiusChange = (value: number | null) => {
        if (!value) {
            if (props.onChange) props.onChange({...props.point, radius: value});
            return;
        }
        if (props.point?.data?.attributes?.type === "custom_location") {
            if (props.onChange) props.onChange({...props.point, radius: value});
            return;
        }
        let nv = value < 17 ? 17 : value;
        if (props.onChange) props.onChange({...props.point, radius: nv});
    }



    const forceZero = () => {
        if (props.onChange) props.onChange({...props.point, radius: 0});
    }

    return (
        <div className="geo-points-entry">
            <div className="geo-points-entry-type">
                <AwesomeIcon icon={typeIcons[type]} size="xl"/>
            </div>
            <div className="geo-points-entry-location">{displayAddress}</div>
            <div className="geo-points-entry-range">
                {type === "country" ? wholeCountry: 
                <InputNumber
                    min={getMin()}
                    max={80}
                    addonAfter={bpi >=3 ? "km" : null}
                    value={props.point?.radius}
                    onInput={(e) => {parseInt(e) === 0 && forceZero()}}
                    onChange={handleRadiusChange}
                />
            }   
            </div>
            <div className="geo-points-entry-remove">
                <StandardButton icon = {faTrash} shape="circle" onClick={() => props.onRemove && props.onRemove(props.point)}/>
            </div>
        </div>
    )
}