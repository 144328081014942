import { Grid } from "antd";
import { ReactNode } from "react";

export const StandardBoxes = (props:any) =>  {

    const { lg } = Grid.useBreakpoint();

    return (
        <div style={{
            display: "grid",
            gridTemplateColumns: lg ? "1fr 1fr" : "1fr",
            columnGap: "10px"
        }}>
            {props.children}
        </div>
    )


}

export type StandardBoxProps = {
    title?: string | ReactNode;
    children?: ReactNode | ReactNode[];
    style?: React.CSSProperties;
}

export const StandardBox = (props:StandardBoxProps) =>  {

        return (
            <div className={`dashboard-box`} style={props.style}>
                {props.title && <h2 className="title large">{props.title}</h2>}
                {props.children}
            </div>
        )
}