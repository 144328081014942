import { Spin, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { HorizontalCenter } from "components/common/Center";
import StandardStatusIcon from "components/common/StandardStatusIcon";
import { type } from "os";
import useAPI, { callAPIProps } from "services/hooks/useAPI";

const getCampaignsStats:callAPIProps = {
    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/google/campaigns/stats`),
    // fakeResponse: async (request) => ({
    //     status: 200,
    //     json: () => ({
    //         data: [
    //             {
    //                 "id": 20426019292,
    //                 "type": "google-campaign-stats",
    //                 "attributes": {
    //                   "budget_amount": 100,
    //                   "name": "Nazwa kampani",
    //                   "advertising_channel_type": "PERFORMANCE_MAX",
    //                   "status": "ENABLED",
    //                   "clicks": 1384,
    //                   "ctr": 0.2846565199506376,
    //                   "engagements": 1,
    //                   "average_cpc": 457861.27167630056,
    //                   "cost_micros": 633.68,
    //                   "impressions": 4862,
    //                   "active_view_impressions": 0,
    //                   "interactions": 1384
    //                 }
    //             }
    //         ]
    //     })
    // })
}

type GoogleCampaignStats = {
    type: string,
    campaign_id: number,
    campaign_name: string,
    status: string,
    clicks: number,
    ctr: number,
    enagements: number,
    average_cpc: number,
    cost_micros: number,
    impressions: number,
    active_view_impressions: number,
    interactions: number
}


const roundToDecimals = (num: number) => {
    return (Math.floor(num * 100 || 0)/100);
}

const campaignStatsColumns: ColumnsType<any> = [
    {
        key: "campaignName",
        title: "Campaign",
        dataIndex: "name"
    },
    {
        key: "status",
        title: "Status",
        dataIndex: "status",
        render: (status:string) => <StandardStatusIcon status={status}/>
    },
    {
        key: "type",
        title: "Type",
        dataIndex: "advertising_channel_type"
    },
    {
        key: "budget",
        title: "Budget",
        dataIndex: "budget_amount"
    },
    {
        key: "clicks",
        title: "Clicks",
        dataIndex: "clicks"
    },
    {
        key: "ctr",
        title: "CTR",
        dataIndex: "ctr",
        render: (ctr:number) => `${roundToDecimals(ctr)}`
    },
    {
        key: "enagements",
        title: "Enagements",
        dataIndex: "engagements"
    },
    {
        key: "average_cpc",
        title: "Average CPC",
        dataIndex: "average_cpc",
        render: (average_cpc:number) => `${roundToDecimals(average_cpc)}`
    },
    {
        key: "cost_micros",
        title: "Cost",
        dataIndex: "cost_micros"
    },
    {
        key: "impressions",
        title: "Impressions",
        dataIndex: "impressions"
    },
    {
        key: "active_view_impressions",
        title: "Active View Impressions",
        dataIndex: "active_view_impressions"
    },
    {
        key: "interactions",
        title: "Interactions",
        dataIndex: "interactions"
    }
]

const GoogleCampaignStats = () => {

    const statsCall = useAPI(getCampaignsStats, true)

    if (statsCall.loading || statsCall.error) return (
        statsCall.StatusDisplay
    )

    return (
        <Table
            pagination={false}
            dataSource={statsCall.data}
            columns={campaignStatsColumns}
            rowKey="campaign_id"
        />
    )
}

export default GoogleCampaignStats;