import { ReactNode, useEffect, useState } from "react";
import RelaxField from "tools/relaxForm/RelaxField";
import { NamePath } from "tools/relaxForm/relaxTypes";
import { Text, Title } from "components/common/StandardText";
import FormattedMessage from "components/common/FormattedMessage";
import TextEditorNew from "tools/textEditor/TextEditorNew";
import SurveyRating from "./SurveyRating";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { subscriptionCancellationSurveySelector } from "state/subscriptions/subscriptionsSelectors";
import { useCancellationWizard } from "modules/panel/components/user/subscriptions/cancellation/SubscriptionCancellationWizard";
import _ from "lodash";
import { Space } from "antd";

type Props = {
    name: NamePath,
    followupName: NamePath,
    question: ReactNode,
    questionAdditional?: ReactNode,
    questionComponent: ReactNode,
    followupQuestion: ReactNode,
    followupComponent: ReactNode,
    questionNumber: number,
    questionCount: number,
    questionProps?: any
}

const SurveyQuestionWithFollowup = (props: Props) => {

    const {subscriptionId} = useCancellationWizard();
    const [showFollowup, setShowFollowup] = useState(false);
    const survey = useSelectorWithParams([subscriptionCancellationSurveySelector, subscriptionId])


    const hasAnswer = !!_.get(survey, props.name);

    useEffect(() => {
        if (hasAnswer) setShowFollowup(true);
    }, [hasAnswer]);

    return (
        <>
        <Space direction="vertical" style={{width: "100%"}} size={"middle"}>
            <div>
                <Text>
                    <FormattedMessage 
                        id="tools.survey.questionNumber"
                        defaultMessage="Question {questionNumber} of {questionCount}:"
                        values={{
                            questionNumber: props.questionNumber,
                            questionCount: props.questionCount,
                        }}
                        />
                </Text>
            </div>
            <div>
                <p>
                    <Text>
                        {props.question}
                    </Text>
                </p>
                <p>
                    <Text type="secondary">
                        {props.questionAdditional}
                    </Text>
                </p>
            </div>
            <RelaxField
                name={props.name}
                {...props.questionProps}
                // onChange={(value:any) => {
                //     if (value && !showFollowup) setShowFollowup(true);
                // }}
                hideStatus={true}
            >
                {props.questionComponent}
            </RelaxField>
            {showFollowup && (
                <>
                    <p>
                        <Text>
                            {props.followupQuestion}
                        </Text>
                    </p>
                    <RelaxField
                        name={props.followupName}
                        hideStatus={true}
                    >
                        {props.followupComponent}
                    </RelaxField>
                </>
            
            )}
        </Space>
        </>
    );


}

type SurveyRatingWithFollowupProps = Props & {
    min: ReactNode,
    max: ReactNode
}

export const SurveyRatingWithFollowup = (props: SurveyRatingWithFollowupProps) => {
    return (
        <SurveyQuestionWithFollowup
            {...props}
            questionComponent={(
                <SurveyRating
                    min={props.min}
                    max={props.max} />
            )}
            questionProps={{
                submitOnChange: true
            }}
            followupComponent={<TextEditorNew />} 
        />
    )
}

export default SurveyQuestionWithFollowup;