import { ProductConfig } from "products";
import logo from "products/local3/logo.svg";
import requirements from "modules/smart/config/requirements";

const config: ProductConfig = {
    name: "PakietLocal2",
    logo, 
    requirements: requirements,
}

export default config;