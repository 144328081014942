import React from "react";
import ProductLogo from "providers/routing/layouts/components/product-logo/current-product-logo";
import TwoColumnsSidebar, {
  isSidebarConfig,
} from "providers/routing/layouts/templates/two-columns/sidebar";
import { TwoColumnsLayoutRouteConfig } from "providers/routing/layouts/templates/two-columns/two-columns";

const TwoColumnsLayoutContent: React.FunctionComponent<TwoColumnsLayoutRouteConfig> = ({
  title = false,
  subtitle = false,
  sidebar = false,
  sidebarPosition = "right",
  children,
}) => (
  <div
    className={`two-columns-layout-content sidebar-${
      sidebar ? sidebarPosition : "no"
    }`}
  >
    {sidebar && (
      <div className="sidebar-container">
        <div className="content">
          {isSidebarConfig(sidebar) ? (
            <TwoColumnsSidebar {...sidebar} />
          ) : (
            sidebar
          )}
        </div>
      </div>
    )}
    <div className="two-columns-layout__content">
      <div className="two-columns-layout-content">
        <div className="two-columns-layout-content__logo-container">
          <ProductLogo hideTitle={true} />
        </div>
        <div className="two-columns-layout-content__main">
          <div className="centered">
            {(title || subtitle) && (
              <div className="two-columns-layout-content__header">
                {title && <h1 className="header__title">{title}</h1>}
                {subtitle && <p className="header__subtitle">{subtitle}</p>}
              </div>
            )}
            <div>{children}</div>
          </div>
        </div>
        <div />
      </div>
    </div>
  </div>
);

export default TwoColumnsLayoutContent;
