import { Col, Input, Row } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import IncludeIcon from "modules/smart/components/advanced-settings/icons/include.svg";
import ExcludeIcon from "modules/smart/components/advanced-settings/icons/exclude.svg";

export default function SettingsFacebookAdvanced() {

    return (
        <>
            <Row gutter={30}>
                <Col xxl={12} lg={12} md={12} sm={24} xs={24}>
                    <RelaxSingleField
                        name="topCustomersVisitedPage"
                        disabled={true}
                        label={
                            <FormattedMessage
                                id="panel.containers.advancedSettings.topCustomersVisitedPage"
                                defaultMessage="Top customers visited page"
                            />
                        }
                    >
                        <Input
                            prefix={<img src={IncludeIcon} />}
                            placeholder="Website link"
                        />
                    </RelaxSingleField>

                    <RelaxSingleField
                        name="topCustomersVisitedPage"
                        disabled={true}
                        label={
                            <FormattedMessage
                                id="panel.containers.advancedSettings.excludedCustomersVisitedWebsite "
                                defaultMessage="Excluded customers visited website"
                            />
                        }
                    >
                        <Input
                            prefix={<img src={ExcludeIcon} />}
                            placeholder="Website link"
                        />
                    </RelaxSingleField>

                    <Row gutter={30}>
                        <Col xxl={12} lg={12} md={12} sm={24} xs={24}>

                            <RelaxSingleField
                                name="includedCustomersEvent"
                                disabled={true}
                                label={
                                    <FormattedMessage
                                        id="panel.containers.advancedSettings.includedCustomersEvent"
                                        defaultMessage="Included customers event"
                                    />
                                }
                            >
                            <Input
                                disabled={true}
                                prefix={<img src={IncludeIcon} />}
                                placeholder="Add to cart"
                            />
                            </RelaxSingleField>

                        </Col>

                        <Col xxl={12} lg={12} md={12} sm={24} xs={24}>

                            <RelaxSingleField
                                name="excludedCustomersEvent"
                                disabled={true}
                                label={
                                    <FormattedMessage
                                        id="panel.containers.advancedSettings.excludedCustomersEvent"
                                        defaultMessage="Excluded customers event"
                                    />
                                }
                            >
                            <Input
                                disabled={true}
                                prefix={<img src={ExcludeIcon} />}
                                placeholder="Page View"
                            />
                            </RelaxSingleField>

                        </Col>
                    </Row>

                    <Row gutter={30}>
                        <Col xxl={12} lg={12} md={12} sm={24} xs={24}>

                            <RelaxSingleField
                                name="includedCustomersEvent"
                                disabled={true}
                                label={
                                    <FormattedMessage
                                        id="panel.containers.advancedSettings.includedCustomersEvent"
                                        defaultMessage="Included customers event"
                                    />
                                }
                            >
                            <Input
                                disabled={true}
                                prefix={<img src={IncludeIcon} />}
                                placeholder="Add to cart"
                            />
                            </RelaxSingleField>

                        </Col>

                        <Col xxl={12} lg={12} md={12} sm={24} xs={24}>

                        </Col>
                    </Row>

                </Col>
            </Row>
        </>
    )

}

{/* <div className="disabled">


                <Row gutter={10}>
                  <Col xs={12}>
                   
                  </Col>
                  <Col xs={12}>
                    <FormItem
                      field="excludedCustomersEvent"
                      initialValue="page_views"
                      tooltip={
                        <FormattedMessage
                          id="panel.containers.advancedSettings.excludedCustomersEvent.tooltip"
                          defaultMessage="Excluded customers event"
                        />
                      }
                      label={
                        <FormattedMessage
                          id="panel.containers.advancedSettings.excludedCustomersEvent"
                          defaultMessage="Excluded customers event"
                        />
                      }
                      form={form}
                    >
                      <Input
                        disabled={true}
                        prefix={<img src={ExcludeIcon} />}
                      />
                    </FormItem> */}