import FormattedMessage from "components/common/FormattedMessage";
import OpeningHours from "modules/site-generator/components/OpeningHours";
import { GoogleSmartSettingsSelector, HasFilledOpeningHoursSelector, UpdateGoogleSmartSettings } from "modules/smart/smartSlice";
import ConnectedOnboardingControls from "providers/onboarding/controls";
import { useState } from "react";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import RelaxField from "tools/relaxForm/RelaxField";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";

export const OnboardingOpeningHours = () => {
  const settings = useSelectorWithParams(GoogleSmartSettingsSelector);
  const [hasErrors, setHasErrors] = useState(false);
  const fullfilled = useSelectorWithParams(HasFilledOpeningHoursSelector);

  return (
    <>
      <RelaxMultiform 
      initialValuesSelector={GoogleSmartSettingsSelector}
      callAPI={UpdateGoogleSmartSettings}>
        <div>
          <RelaxField
            name="working_hours"
            finalizeSubmit={(values: any) => {
              const restDays = settings.working_hours.filter(
                (day: any) => day.day === "saturday" || day.day === "sunday"
              );
              values.working_hours = values.working_hours.concat(restDays);
              return values;
            }}
            label={
              <FormattedMessage
                id="apps.websites.forms.companyData.openingHours.workweek"
                defaultMessage="Opening hours (Monday - Friday)"
              />
            }
            onErrors={(e:any) => setHasErrors(!!e)}
          >
            <OpeningHours type="workweek" />
          </RelaxField>
        </div>
        <div>
          <RelaxField
            name="working_hours"
            finalizeSubmit={(values: any) => {
              const restDays = settings.working_hours.filter(
                (day: any) => day.day !== "saturday" && day.day !== "sunday"
              );
              values.working_hours = values.working_hours.concat(restDays);
              return values;
            }}
            label={
              <FormattedMessage
                id="apps.websites.forms.companyData.openingHours.weekend"
                defaultMessage="Opening hours (Saturday - Sunday)"
              />
            }
            onErrors={(e:any) => setHasErrors(!!e)}
          >
            <OpeningHours type="weekend" />
          </RelaxField>
        </div>
      </RelaxMultiform>
      <ConnectedOnboardingControls nextDisabled={(!fullfilled || hasErrors)}/>
    </>
  );
};
