import React, { useMemo } from "react";
import { CalendarMode, useCalendar } from "./Calendar";
import CreationCard from "tools/creationEditor/CreationCard";
import dayjs, { Dayjs } from "dayjs";
import PostCard from "modules/posts/postTools/PostCard";
import AwesomeIcon from "components/common/AwesomeIcon";
import { Tag, Tooltip, Typography } from "antd";
import { faCamera, faCheckCircle, faPencil, faQuestion } from "@fortawesome/pro-light-svg-icons";
import Creation from "models/creation";
import Post from "models/post";
import Asset from "models/asset";
import { constructAsset } from "models/assetClassHelpers";
import { useCreationGlobal } from "tools/creationEditor/CreationGlobalContext";
import { TagButton } from "components/common/TagButton";
import { CreationStateTag } from "components/creations/CreationTags";
import CreationPreview from "modules/smart/components/AssetPreview/CreationPreview";

type CalendarEntryProps = {
  entry: any,
  day: Dayjs,
  mode?: CalendarMode | "upcoming" | "preview",
}

export const CalendarCardTop = (props: any) => {

  return (
    <div className="status-bar">
      <Tooltip title={props.type}>
        <AwesomeIcon icon={props.typeIcon} size="lg" className="type-icon" />
      </Tooltip>

      <Typography.Text className="template-type">{props.type}</Typography.Text>
      <Typography.Text type="secondary">
        <div className="publication-date">
          {props?.date && props.date.format && props.date.local().format("HH:mm")}
        </div>
      </Typography.Text>

      <Tooltip title={props.status}>
        {props.statusIcon}
      </Tooltip>
    </div>
  )

}

const getIconByStatus = (status: string) => {

  if (!status) return <AwesomeIcon icon={faQuestion} size="lg" />

  const s = status.toLowerCase();

  if (!s) return <AwesomeIcon icon={faQuestion} size="lg" />

  switch (s) {
    case "published":
      return <AwesomeIcon icon={faCheckCircle} size="lg" color="green" />;
    case "draft":
      return <AwesomeIcon icon={faPencil} size="lg" />
    case "scheduled":
      return <AwesomeIcon icon={faCheckCircle} size="lg" />;
    default:
      return <AwesomeIcon icon={faQuestion} size="lg" />
  }
}

const CalendarCreationEntry = (props: CalendarEntryProps) => {

  const { mode: calendarMode, previewManager } = useCalendar();
  const mode = props.mode || calendarMode;

  const { openCreation } = useCreationGlobal();

  const entry: Asset | null = useMemo(() => {
    if (!props.entry) return null;
    if (!props.entry.entryType) return null;
    return constructAsset(props.entry, props.entry.entryType);

  }, [props.entry]);

  const calendarInfo = useMemo(() => {
    if (!entry) return null;
    return entry.getCalendarInfo();
  }, [entry])

  const handleMouseEnter = () => {
    if (mode === "upcoming") return;
    if (!previewManager.isPreviewLocked) previewManager.setPreview(entry ? entry.id + "" : null)
  }

  const handleMouseLeave = () => {
    if (mode === "upcoming") return;
    if (!previewManager.isPreviewLocked) previewManager.setPreview(null)
  }

  const handleClick = () => {
    if (previewManager.isPreviewLocked && previewManager.previewEntry === entry?.id) {
      previewManager.openAndLockPreview(null);
    } else {
      previewManager.openAndLockPreview(entry ? entry.id + "" : null);
    }
  }


  if (!entry) return null;

  if (!calendarInfo) return null;

  if (mode === "preview" && props.entry.entryType === "creation") {
    return (
      <div
        key={`${entry.type}-${entry.id}`}
        data-calendar-entry-index={entry.id}
        data-calendar-entry-type={props.entry.entryType}
        data-calendar-entry-can-be-picked-up={calendarInfo.canBePickedUp}
        data-calendar-entry-date={calendarInfo.date}
        data-calendar-entry-future-only={calendarInfo.moveInFutureOnly}
        className={`calendar-entry calendar-creation-entry ${props.entry.entryType} mode-${mode}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
          <div className="entry-content">
            <CalendarCardTop
              type={calendarInfo?.type}
              date={calendarInfo?.date}
              typeIcon={calendarInfo?.typeIcon}
              status={calendarInfo?.status}
              statusIcon={getIconByStatus(calendarInfo?.status)}
            />
          </div>
        <CreationPreview creation={entry as Creation} />
      </div>
    )
  }

  // limit the number of characters in the description
  const tinyDesc = calendarInfo.description && calendarInfo.description.substring(0, 20) + "...";
  const shortDesc = calendarInfo.description && calendarInfo.description.substring(0, 160) + "...";

  const description = (mode === "month" || mode === "upcoming") ? <p className="description">{shortDesc}</p> : <p className="description">{calendarInfo.description}</p>

  return (
    <div
      key={`${entry.type}-${entry.id}`}
      data-calendar-entry-index={entry.id}
      data-calendar-entry-type={props.entry.entryType}
      data-calendar-entry-can-be-picked-up={calendarInfo.canBePickedUp}
      data-calendar-entry-date={calendarInfo.date}
      data-calendar-entry-future-only={calendarInfo.moveInFutureOnly}
      className={`calendar-entry calendar-creation-entry ${props.entry.entryType} mode-${mode}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <img className="entry-image" src={calendarInfo.image} />
      <div className="entry-details">

        <div className="entry-content">
          <CalendarCardTop
            type={calendarInfo?.type}
            date={calendarInfo?.date}
            typeIcon={calendarInfo?.typeIcon}
            status={calendarInfo?.status}
            statusIcon={getIconByStatus(calendarInfo?.status)}
          />
          {description}
          {props.entry.entryType === "creation" && entry.status !== "published" ? <TagButton onClick={() => openCreation(entry.id)} className="edit-button">Edit</TagButton> : null}
        </div>

        <div className="entry-footer">
          <div className="tags">
            {/* <Tag color="blue">Test</Tag> */}
          </div>
        </div>

      </div>



    </div>
  );


};

export default CalendarCreationEntry;