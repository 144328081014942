import { ProductConfig } from "products";
import logo from "products/insta/instaLogo.png";

import requirements from "modules/smart/config/requirements";
import { Requirement } from "modules";

const config: ProductConfig = {
  name: "Insta",
  logo,
  requirements: requirements as Requirement[]
};

export default config;
