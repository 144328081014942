import { faCheckCircle, faCrown, faExclamationCircle, faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import AwesomeIcon from "components/common/AwesomeIcon";
import FormattedMessage from "components/common/FormattedMessage";
import DateTimeDisplay from "components/displays/DateTimeDisplay";
import dayjs from "dayjs";
import { useMemo } from "react";
import { FormattedNumber, useIntl } from "react-intl";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import SubscriptionPaymentActions from "./SubscriptionPaymentActions";
import { useSubscriptionContext } from "./SubscriptionContext";
import CellValueWithSubscript from "modules/posts/displays/CellValueWithSubscript";
import { CenterdCell, LeftAlignedCell } from "modules/posts/CommonCells";
import CellWithMultipleValues from "modules/posts/displays/CellWithMultipleValues";
import { AdsSettingsCurrencySelector } from "modules/smart/smartSlice";

export const History = () => {

    const { subscription } = useSubscriptionContext();

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            color: "grey"
        }}>
            <div style={{
                display: "flex",
                flexDirection: "row",
                gap: "2px",
            }}>
                <FormattedMessage
                    id="panel.profile.subscriptions.history.started"
                    defaultMessage="Started"
                />
                <DateTimeDisplay showDate dateTime={subscription["created_at"]} />
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                gap: "2px",
            }}>
                <FormattedMessage
                    id="panel.profile.subscriptions.history.ends"
                    defaultMessage="Ends"
                />
                <DateTimeDisplay showDate dateTime={subscription["expiry"]} />
            </div>
        </div>
    )

}

export const Price = () => {

    const { subscription } = useSubscriptionContext();
    const currency = useSelectorWithParams(AdsSettingsCurrencySelector);

    const billingCycle = useMemo(() => {
        if (!subscription) return null;
        if (!subscription["billing_cycle"]) return null;
        return subscription["billing_cycle"];
    }, [subscription]);

    const billingCycleText = useMemo(() => {
        if (!billingCycle) return null;
        switch (billingCycle) {
            case "monthly":
                return <FormattedMessage
                    id="panel.profile.subscriptions.billingCycle.monthly"
                    defaultMessage="Monthly"
                />
            case "yearly":
                return <FormattedMessage
                    id="panel.profile.subscriptions.billingCycle.yearly"
                    defaultMessage="Yearly"
                />
            case "quarterly":
                return <FormattedMessage
                    id="panel.profile.subscriptions.billingCycle.quarterly"
                    defaultMessage="Quarterly"
                />
            default:
                return null;
        }

    }, [billingCycle]);

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
        }}>
            {subscription["price"] && <FormattedNumber value={subscription["price"]} style="currency" currency={currency} />}
            <div style={{
                color: "grey",
            }}>
                {billingCycleText}
            </div>
        </div>
    )


}

export const Status = () => {

    const { subscription } = useSubscriptionContext();


    const isEnded = () => {
        const end = new Date(subscription["expiry"]);
        const now = new Date();
        return end.getTime() < now.getTime();
    }

    const statusText = useMemo(() => {

        if (!subscription) return null;
        if (!subscription._generated) return null;

        if (subscription._generated.status === "canceled") return (
            <FormattedMessage
                id="panel.profile.subscriptions.status.canceled"
                defaultMessage="Canceled"
            />
        )

        if (subscription._generated.status === "ended") return (
            <FormattedMessage
                id="panel.profile.subscriptions.status.ended"
                defaultMessage="Ended"
            />
        )

        if (subscription._generated.status === "active") {

            if (isEnded()) return (
                <FormattedMessage
                    id="panel.profile.subscriptions.status.ended"
                    defaultMessage="Ended"
                />
            )

            return (
                <FormattedMessage
                    id="panel.profile.subscriptions.status.active"
                    defaultMessage="Active"
                />
            )
        }

    }, [subscription])

    return <CenterdCell>{statusText}</CenterdCell>

}


export const Duration = () => {

    const { subscription } = useSubscriptionContext();

    const isEnded = () => {
        const end = new Date(subscription["expiry"]);
        const now = new Date();
        return end.getTime() < now.getTime();
    }

    const rows = useMemo(() => {

        let r: any[] = [];

        r.push([
            <FormattedMessage
                id="panel.profile.subscriptions.started"
                defaultMessage="Started"
            />,
            <DateTimeDisplay showDate noWrapper dateTime={subscription["created_at"]} />
        ])

        if (isEnded()) {
            r.push([
                <FormattedMessage
                    id="panel.profile.subscriptions.ended"
                    defaultMessage="Ended"
                />,
                <DateTimeDisplay showDate noWrapper dateTime={subscription["expiry"]} />
            ])
        } else {
            r.push([
                <FormattedMessage
                    id="panel.profile.subscriptions.ends"
                    defaultMessage="Ends"
                />,
                <DateTimeDisplay showDate noWrapper dateTime={subscription["expiry"]} />
            ])
        }

        return r;

    }, [subscription]);

    return (
        <CellWithMultipleValues
            rows={rows}
        />
    )


}


export const SubscriptionPaymentDueAmountDisplay = () => {

    const { subscription } = useSubscriptionContext();
    const currency = useSelectorWithParams(AdsSettingsCurrencySelector);

    const totalDue = subscription?._generated?.totalDue

    const content = useMemo(() => {

        if (totalDue === null) return (
            <>
                <AwesomeIcon icon={faQuestionCircle} style={{ color: "grey" }} size="xl" />
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <FormattedMessage
                        id="panel.profile.subscriptions.noPaymentsDue"
                        defaultMessage="No payments due"
                    />
                </div>
            </>
        )

        if (!totalDue) return (
            <>
                <AwesomeIcon icon={faCheckCircle} style={{ color: "green" }} size="xl" />
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <FormattedMessage
                        id="panel.profile.subscriptions.paid"
                        defaultMessage="Paid"
                    />
                </div>
            </>
        )

        return (
            <>
                <AwesomeIcon icon={faExclamationCircle} style={{ color: "red" }} size="xl" />
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <FormattedNumber value={totalDue} style="currency" currency={currency} />
                    <div style={{
                        color: "grey",

                    }}>
                        <FormattedMessage
                            id="panel.profile.subscriptions.overdue"
                            defaultMessage="Overdue"
                        />
                    </div>
                </div>
            </>
        )

    }, [totalDue]);

    if (!subscription) return null;
    if (subscription._generated.isInternal) return null;
    if (subscription.status === "canceled") return null;

    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "10px",
            paddingLeft: "10px",
            width: "100%"
        }}>
            {content}
        </div>
    )




}

export const SubscriptionPaymentDueDateDisplay = () => {

    const { subscription } = useSubscriptionContext();

    const overdueValue = () => {
        if (!subscription) return null;
        if (!subscription["to_be_paid"]) return null;
        const due = parseInt(subscription["to_be_paid"]);
        if (due < 0) return 0
        return due
    };

    const oldestMissedPayment = subscription?._generated?.oldestMissedPayment;

    const timeSinceOldestMissedPayment = useMemo(() => {
        if (!oldestMissedPayment) return null;
        return dayjs().diff(dayjs(oldestMissedPayment), "days");
    }, [oldestMissedPayment]);

    const dueText = useMemo(() => {
        if (!timeSinceOldestMissedPayment) return (
            <FormattedMessage
                id="panel.subscriptions.allPaid"
                defaultMessage="All paid"
            />
        )
        if (timeSinceOldestMissedPayment < 0) return (
            <FormattedMessage
                id="panel.subscriptions.due"
                defaultMessage="Due in {days} days"
                values={{ days: timeSinceOldestMissedPayment }}
            />
        )
        if (timeSinceOldestMissedPayment >= 0) return (
            <FormattedMessage
                id="panel.subscriptions.overdue"
                defaultMessage="{days} days overdue"
                values={{ days: timeSinceOldestMissedPayment }}
            />
        )

        return null;

    }, [timeSinceOldestMissedPayment]);

    if (!subscription) return null;
    if (subscription._generated.isInternal) return (
        <LeftAlignedCell>
            <AwesomeIcon icon={faCrown} style={{ paddingRight: "5px" }} size="xl" />
            <FormattedMessage
                id="panel.subscriptions.ambassador"
                defaultMessage="Ambassador"
            />
        </LeftAlignedCell>
    )

    if (subscription.status === "canceled") return null;

    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "10px",
            paddingLeft: "10px",
            width: "100%"
        }}>
            <div>
                {dueText}
                {oldestMissedPayment ? <DateTimeDisplay
                    showDate
                    dateTime={oldestMissedPayment}
                    style={{
                        color: "grey",
                        paddingTop: '2px',
                        paddingBottom: '2px'
                    }}
                /> : null}
            </div>
            {/* {payButtons} */}
        </div>
    )

}



export const SubscriptionPriceWithBillingCycleDisplay = () => {

    const { subscription } = useSubscriptionContext();
    const intl = useIntl();

    const billingCycle = useMemo(() => {
        if (!subscription) return null;
        if (!subscription.billing_cycle) return null;

        if (subscription.billing_cycle === "monthly") return (
            <FormattedMessage
                id="panel.profile.subscriptions.billingCycle.monthly"
                defaultMessage="Monthly"
            />
        )

        if (subscription.billing_cycle === "yearly") return (
            <FormattedMessage
                id="panel.profile.subscriptions.billingCycle.yearly"
                defaultMessage="Yearly"
            />
        )

        if (subscription.billing_cycle === "quarterly") return (
            <FormattedMessage
                id="panel.profile.subscriptions.billingCycle.quarterly"
                defaultMessage="Quarterly"
            />
        )

        return subscription.billing_cycle;
    }, [subscription]);

    const price = useMemo(() => {
        if (!subscription) return null;
        if (!subscription.price) return "-";
        return intl.formatNumber(subscription.price, { style: 'currency', currency: subscription?._generated.currency });
    }, [subscription]);

    if (!subscription) return null;
    if (subscription._generated.isInternal) return null;

    return (
        <CellValueWithSubscript
            value={price}
            subscript={billingCycle}
            subscriptStyle={{
                color: "grey",
                fontSize: "1rem"
            }}

        />
    )

}

export const SubscriptionContractTypeDisplay = () => {

    const { subscription } = useSubscriptionContext();

    if (!subscription) return null;
    if (subscription._generated.isInternal) return null;
    const mandatoryPeriods = subscription.offer?.mandatory_periods;

    if (mandatoryPeriods > 0) return (
        <div>
            <FormattedMessage
                id="panel.profile.subscriptions.contractType.fixedTermContract"
                defaultMessage="Fixed term contract"
            />
        </div>
    )

    return (
        <div>
            <FormattedMessage
                id="panel.profile.subscriptions.contractType.indefiniteContract"
                defaultMessage="Indefinite contract"
            />
        </div>
    )
}



export const SubscriptionInvoiceStatusDisplay = (props: { invoice: any }) => {

    const due = props.invoice?.amount_due;
    const paid = props.invoice?.amount_paid;
    const isPaid = props.invoice?.paid;

    const content = useMemo(() => {
        if (isPaid) return (
            <>
                <AwesomeIcon icon={faCheckCircle} style={{ color: "green" }} size="xl" />
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <FormattedMessage
                        id="panel.profile.subscriptions.paid"
                        defaultMessage="Paid"
                    />
                </div>
            </>
        )

        return (
            <>
                <AwesomeIcon icon={faExclamationCircle} style={{ color: "red" }} size="xl" />
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <FormattedNumber value={due - paid} style="currency" currency={props.invoice?.currency} />
                    <div style={{
                        color: "grey",

                    }}>
                        <FormattedMessage
                            id="panel.profile.subscriptions.overdue"
                            defaultMessage="Overdue"
                        />
                    </div>
                </div>
            </>
        )
    }, [due, paid, isPaid]);

    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "10px",
            paddingLeft: "10px",
            width: "100%"
        }}>
            {content}
        </div>
    )
}

export const SubscriptionInvoiceDateDisplay = (props: { invoice: any }) => {

    const createdDisplay = () => (
        <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
        }}>
            <FormattedMessage
                id="panel.subscriptions.invoice.created"
                defaultMessage="Created"
            />
            <DateTimeDisplay noWrapper showDate dateTime={props.invoice?.created_at} />
        </div>
    )

    const paidDisplay = () => {
        if (props.invoice?.paid) return (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <FormattedMessage
                    id="panel.subscriptions.invoice.paid"
                    defaultMessage="Paid"
                />
                <DateTimeDisplay noWrapper showDate dateTime={props.invoice?.paid_at} />
            </div>
        )

        return (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <FormattedMessage
                    id="panel.subscriptions.invoice.due"
                    defaultMessage="Due"
                />
                <DateTimeDisplay noWrapper showDate dateTime={props.invoice?.due_at} />
            </div>

        )

    }

    return (
        <div style={{ width: "100%" }}>
            {createdDisplay()}
            {paidDisplay()}
        </div>
    )
}