import FormattedMessage from "components/common/FormattedMessage";
import { ReactNode } from "react";
import PostNumber from "modules/smart/components/list-item/base-list-item/number";
import { IntegrationIndicator } from "../components/bot-list-item/IntegrationIndicator";
import BotOptionsDropdown from "../components/BotOptionsDropdown";
import BotContextProvider from "../components/BotContext";

const columnHeadings: { [key: string]: ReactNode } = {
    "message": <FormattedMessage
        id="bot.containers.list.header.description"
        defaultMessage="Description"
    />,
    "integrations": <FormattedMessage
        id="bot.containers.list.header.integration"
        defaultMessage="Integration"
    />,
    "page_post_id": <FormattedMessage
        id="bot.containers.list.header.type"
        defaultMessage="Type"
    />,
    "keyword": <FormattedMessage
        id="bot.containers.list.header.activation"
        defaultMessage="Activation"
    />,
    "postMessage": <FormattedMessage
        id="bot.containers.list.header.post"
        defaultMessage="Post"
    />,
    "messages": <FormattedMessage
        id="bot.containers.list.header.messages"
        defaultMessage="Messages"
    />,
    "leads": <FormattedMessage
        id="bot.containers.list.header.leads"
        defaultMessage="Leads"
    />,
    "action": ""

}


const sizes = {
    standard: ["150px", "150px", "150px", "150px", "150px", "150px"],
    actionButton:[false, "300px","300px","300px","300px","300px"],
    message: [false, false, false, false, "1fr", "1fr"],
}


export const BotsHeadings = [
    {
        key: "message",
        size: sizes.message,
        render: () => columnHeadings["message"]
    },
    {
        key: "integrations",
        size: sizes.standard,
        render: () => columnHeadings["integrations"]
    },
    {
        key: "page_post_id",
        size: sizes.standard,
        render: () => columnHeadings["page_post_id"]
    },
    {
        key: "keyword",
        size: sizes.standard,
        render: () => columnHeadings["keyword"]
    },
    {
        key: "postMessage",
        size: sizes.standard,
        render: () => columnHeadings["postMessage"]
    },
    {
        key: "messages",
        size: sizes.standard,
        render: () => columnHeadings["messages"]
    },
    {
        key: "leads",
        size: sizes.standard,
        render: () => columnHeadings["leads"]
    },
    {
        key: "action",
        size: sizes.actionButton,
        render: () => columnHeadings["action"]
    },
];


export const DefaultColumnsBots = [
    {
        key: "message",
        size: sizes.message,
        render: (item: any) => (item.message ? item.message: '-'),
        
    },
    {
        key: "integrations",
        size: sizes.standard,
        render: (item: any) => (item.integrations[0]?.projectIntegration?.type ? <IntegrationIndicator type={item.integrations[0]?.projectIntegration?.type} /> : undefined),

    },
    {
        key: "page_post_id",
        size: sizes.standard,
        render: (item: any) => (item.page_post_id ? "post" : "fanpage"),

    },
    {
        key: "keyword",
        size: sizes.standard,
        render: (item: any) => (item.keyword ? item.keyword : '-'),
    },
    {
        key: "postMessage",
        size: sizes.standard,
        render: (item: any) => (item.postMessage ?  item.postMessage : '-'),
    },
    {
        key: "messages",
        size: sizes.standard,
        render: (item: any) => (item.messages ? <PostNumber value={item.messages} /> : '0'),
    },
    {
        key: "leads",
        size: sizes.standard,
        render: (item: any) => (item.leads ? <PostNumber value={item.leads} /> : '0'),
    },
    {
        key: "action",
        size: sizes.actionButton,
        render: (item: any) => (
          <>
            <div style={{display:"flex", marginTop:"10px"}}>
                <BotContextProvider botId={item.id} actionType="update"/>
                <BotOptionsDropdown botId={item.id} withPostControls={false} />
            </div>
          </>
        ),
    },
]