import { Alert } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import { VerifyPaymentMethod } from "modules/smart/smartSlice";
import ConnectedOnboardingControls from "providers/onboarding/controls";
import useAPI from "services/hooks/useAPI";

export const OnboardingPaymentMethod = () => {

  const checkPayment = useAPI(VerifyPaymentMethod());

  const handleNext = () => {
    const res = checkPayment.call().then((res) => {
      if (res.status === 200 || res.status === 204) {
        return true;
      } else {
        return false;
      }
    });

    return res
  };

  return (
    <>
      <p>
        Go to the Google Ads website and add a card. Once you’ve done that, come
        back here to your accounts.
      </p>
      {checkPayment.error && checkPayment.loading === false && (
        <Alert
          type="error"
          message={
            <FormattedMessage
              id="apps.forsant.checkAndContinue.error"
              defaultMessage="We have just checked if you fulfilled this step properly, unfortunately not - please read, and try again"
            />
          }
          style={{ margin: "10px 0" }}
          showIcon
        />
      )}

      <ConnectedOnboardingControls
        nextStepText={
          <FormattedMessage
            id="apps.forsant.checkAndContinue"
            defaultMessage="Check and continue"
          />
        }
        nextPending={checkPayment.loading}
        clickOverride={handleNext}
      />
    </>
  );
};
