import React from "react";
import Helper from "providers/routing/routes/helper";
import { LayoutRouteConfig } from "providers/routing/layouts/layouts";
import { Layout } from "providers/routing/layouts/templates/templates";
import FormattedMessage from "components/common/FormattedMessage";
import Offer from "modules/site-generator/containers/websitePages/CarserviceCategories";
import { IndustryCategoryCheckboxSelection } from "_components/onboarding/IndustryCategoryCheckboxSelection";
import { rulesetArrayMin, rulesetArrayMinMax } from "tools/relaxForm/relaxPresets";
import ResidenceRulesOnboarding from "../containers/onboarding/residence-rules-onboarding";
import RoomsOnboarding from "../containers/onboarding/rooms-onboarding";
import RoomList from "../containers/rooms/Rooms";
import IndustryCategories from "../containers/IndustryCategories";
import { TourismCategories } from "./industries/industryCategoriesDefinitions";
import WebsiteObjectLocation from "../containers/WebsiteObjectLocation";
import WebsiteObjectLocationOnboarding from "../containers/onboarding/WebsiteObjectLocationOnboarding";
import ResidenceRulesWithTabs from "../containers/residence-rules-with-tabs";
import WebsitePages from "../containers/websitePages/WebsitePages";
import { AwesomeLarge } from "components/common/AwesomeIcon";
import { faBed, faBrowser, faBuilding, faCar, faGrid, faImagePolaroid, faList, faPage, faScaleBalanced, faTag } from "@fortawesome/pro-light-svg-icons";
import { faFacebook, faGoogle } from "@fortawesome/free-brands-svg-icons";
import OnboardingCreateWebsiteAndIndustrySelect from "../onboarding/OnboardingCreateWebsiteAndIndustrySelect";
import WebsiteTheme from "../forms/WebsiteTheme";
import OnboardingWebsiteTheme from "../containers/onboarding/OnboardingWebsiteTheme";
import { BrandsAndManufacturers } from "../containers/brandsAndManufacturers/BrandsAndManufacturers";
import { OnboardingCarserviceBrands, OnboardingCarserviceCategories, OnboardingCarserviceManufacturers, OnboardingCarserviceTires } from "../containers/onboarding/OnboardingCarserviceCategories";
import WebsitePriceConfig from "../components/priceConfig/WebsitePriceConfig";
import { GoogleSearchads } from "modules/smart/containers/advertisements/GoogleSearchAds";
//import { OnboardingComapnyData } from "../containers/onboarding/OnboardingComapnyData";
import CompanyForm from "../components/CompanyForm";
import { OnboardingComapnyData } from "../containers/onboarding/OnboardingComapnyData";
import { SiteGeneratorGalleries } from "../containers/SiteGeneratorGalleries";
export const ONBOARDING_TIRES_MANUFACTURERS = "tiresManufacturers",
  BRANDS_AND_MANUFACTURERS = "brandsAndManufacturers",
  ONBOARDING_BRANDS = "onboardingBrands",
  ONBOARDING_MANUFACTURERS = "onboardingManufacturers",
  COMPANY_DATA = "companyData",
  ONBOARDING_COMPANY_DATA = "onboardingCompanyData",
  DASHBOARD = "dashboard",
  CATEGORIES = "categories",
  ONBOARDING_CATEGORIES = "onboardingCategories",
  GALLERIES = "galleries",
  WEBSITE_THEME = "websiteTheme",
  ONBOARDING_WEBSITE_THEME = "onboardingWebsiteTheme",
  ONBOARDING_VERIFY_IDENTITY = "onboardingVerifyIdentity",
  GOOGLE_SEARCH_ADS = "googleSearchAds",
  PRICE_LIST = "priceList",
  CUSTOM_PAGES = "customPages",
  ONBOARDING_INDUSTRY= "onboardingIndustry",
  ONBOARDING_CATEGORY_OBJECT_TYPE = "onboardingObjectType",  //object-type
  ONBOARDING_CATEGORY_LOCALIZATION = "onboardingLocalization", //localization
  ONBOARDING_CATEGORY_ATTRACTION_TYPE = "onboardingAttractionType", //attraction-type
  ONBOARDING_CATEGORY_REGIONAL_ATTRACTION_TYPE = "onboardingRegionalAttractionType", //regional-attraction-type
  ONBOARDING_CATEGORY_PARKING = "onboardingParking", //parking
  ONBOARDING_CATEGORY_INTERNET = "onboardingInternet", //internet
  ONBOARDING_CATEGORY_SECURITY = "onboardingSecurity", //security
  ONBOARDING_CATEGORY_SUPPORTED_LANGUAGES = "onboardingSupportedLanguages", //supported-languages
  ONBOARDING_CATEGORY_TECHNOLOGY = "onboardingTechnology", //technology
  ONBOARDING_CATEGORY_CLIMATE = "onboardingClimate", //climate
  ONBOARDING_CATEGORY_ATMOSPHERE = "onboardingAtmosphere", //atmosphere,
  ONBOARDING_CATEGORY_RELAX = "onboardingRelax", //relax
  ONBOARDING_CATEGORY_SPA = "onboardingSpa", //spa
  ONBOARDING_CATEGORY_KIDS = "onboardingKids", //kids
  ONBOARDING_CATEGORY_DISABLED_FACILITIES = "onboardingDisabledFacilities", //disabled-facilities
  ONBOARDING_CATEGORY_MEALS = "onboardingMeals", //meals
  ONBOARDING_CATEGORY_SPECIAL_TRANSPORT = "onboardingSpecialTransport", //special-transport
  ONBOARDING_CATEGORY_VIEW = "onboardingView", //view
  ONBOARDING_CATEGORY_FEEDING = "onboardingFeeding", //feeding
  ONBOARDING_CATEGORY_SPECIAL_OFFERS = "onboardingSpecialOfferts", //special-offers
  ONBOARDING_CATEGORY_NEARBY_ATTRACTIONS = "onboardingNearbyAttractions", //nearby-attractions
  ONBOARDING_CATEGORY_ACTIVE = "onboardingActive", //active
  ONBOARDING_CATEGORY_ACTIVE_SPORT = "onboardingActiveSport", //active sport
  ONBOARDING_CATEGORY_ACTIVE_RECREATION = "onboardingActiveRecreation", //active recreation
  ONBOARDING_CATEGORY_ACTIVE_EVENTS = "onboardingActiveEvents", //events
  ONBOARDING_CATEGORY_BEAUTY = "onboardingBeauty", //beauty
  ONBOARDING_RULES = "onboardingRules",
  ONBOARDING_ROOMS = "onboardingRooms",
  ONBOARDING_OBJECT_DATA = "onboardingObject",
  ONBOARDING_LOCATION_DATA = "onboardingLocation",
  ROOMS = "rooms",
  OBJECT_RULES = "objectRules",
  OBJECT_CATEGORIES = "objectCategories",
  OBJECT_DATA = "objects";

export const APP_PATH = "site-generator";

const routes: LayoutRouteConfig[] = [
  {
    key: CATEGORIES,
    path: "/categories",
    name: (
      <FormattedMessage
        id="apps.websites.routes.offer"
        defaultMessage="Offer"
      />
    ),
    icon: <AwesomeLarge icon={faGrid} />,
    layout: Layout.Panel,
    title: (
      <FormattedMessage
        id="apps.websites.routes.offer"
        defaultMessage="Offer"
      />
    ),
    content: Offer,
  },
  {
    key: COMPANY_DATA,
    path: "/company-data",
    name: (
      <FormattedMessage
        id="apps.websites.routes.companyData"
        defaultMessage="Company"
      />
    ),
    icon: <AwesomeLarge icon={faBuilding} />,
    layout: Layout.Panel,
    title: (
      <FormattedMessage
        id="apps.websites.routes.companyData"
        defaultMessage="Company"
      />
    ),
    content: CompanyForm,
  },
  {
    key: WEBSITE_THEME,
    path: "/website-theme",
    name: (
      <FormattedMessage
        id="apps.websites.routes.theme"
        defaultMessage="Theme"
      />
    ),
    icon: <AwesomeLarge icon={faBrowser} />,
    layout: Layout.Panel,
    title: (
      <FormattedMessage
        id="apps.websites.routes.theme"
        defaultMessage="Theme"
      />
    ),
    content: WebsiteTheme,
  },
  {
    key: BRANDS_AND_MANUFACTURERS,
    path: "/brands-and-manufacturers",
    name: (
      <FormattedMessage
        id="apps.websites.routes.brands"
        defaultMessage="Brands"
      />
    ),
    icon: <AwesomeLarge icon={faCar} />,
    layout: Layout.Panel,
    mainHasBackground: false,
    title: (
      <FormattedMessage
        id="apps.websites.routes.brands"
        defaultMessage="Brands"
      />
    ),
    content: BrandsAndManufacturers,
  },
  {
    key: GALLERIES,
    path: "/galleries",
    name: (
      <FormattedMessage
        id="apps.websites.routes.galleries"
        defaultMessage="Galleries"
      />
    ),
    icon: <AwesomeLarge icon={faImagePolaroid} />,
    layout: Layout.Panel,
    title: (
      <FormattedMessage
        id="apps.websites.routes.galleries"
        defaultMessage="Galleries"
      />
    ),
    content: SiteGeneratorGalleries,
   },
  {
    key: GOOGLE_SEARCH_ADS,
    path: "/advertisements/google-search-ads",
    integration: "google",
    name: (
      <FormattedMessage
        id="apps.websites.routes.googleSearchAds"
        defaultMessage="Google search ads"
      />
    ),
    icon: <AwesomeLarge icon={faGoogle} />,
    layout: Layout.Panel,
    title: (
      <FormattedMessage
        id="apps.websites.routes.googleSearchAds"
        defaultMessage="Google search ads"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.websites.routes.googleSearchAdsSubtitle"
        defaultMessage="Manage your Google search ads"
      />
    ),
    content: GoogleSearchads,
    fullHeight: true,
    mainHasBackground: false,
  },
  {
    key: PRICE_LIST,
    path: "/price-list",
    name: (
      <FormattedMessage
        id="apps.websites.routes.priceList"
        defaultMessage="Price list"
      />
    ),
    icon: <AwesomeLarge icon={faTag} />,
    layout: Layout.Panel,
    content: WebsitePriceConfig,
    //content: PriceList,
    title: (
      <FormattedMessage
        id="apps.websites.routes.priceList"
        defaultMessage="Price list"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.websites.routes.priceList.subtitle"
        defaultMessage="Don’t be afraid to show the prices of your services"
      />
    ),
    fullHeight: true,
    mainHasBackground: false,
  },
  {
    key: CUSTOM_PAGES,
    path: "/custom-pages",
    name: (
      <FormattedMessage
        id="apps.websites.routes.customPages"
        defaultMessage="Custom Pages"
      />
    ),
    icon: <AwesomeLarge icon={faPage} />,
    layout: Layout.Panel,
    content: WebsitePages,
    title: (
      <FormattedMessage
        id="apps.websites.routes.customPages"
        defaultMessage="Custom Pages"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.websites.routes.customPages.subtitle"
        defaultMessage="Custom pages for your website"
      />
    ),
    fullHeight: true,
    mainHasBackground: false,
  },
  {
    key: ONBOARDING_COMPANY_DATA,
    path: "/onboarding/company-data",
    layout: Layout.TwoColumns,

    displayInMenu: false,
    //layout: Layout.Blank,
    content: OnboardingComapnyData,
  },
  {
    key: ONBOARDING_TIRES_MANUFACTURERS,
    path: "/onboarding/tires",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingCarserviceTires,
    sidebar: <div></div>
  },
  {
    key: ONBOARDING_BRANDS,
    path: "/onboarding/brands",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingCarserviceBrands,
    sidebar: <div></div>
  },
  {
    key: ONBOARDING_MANUFACTURERS,
    path: "/onboarding/manufacturers",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingCarserviceManufacturers,
    sidebar: <div></div>,
  },
  {
    key: ONBOARDING_CATEGORIES,
    path: "/onboarding/categories",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    sidebar: <div></div>,
    content: OnboardingCarserviceCategories,
  },
  {
    key: ONBOARDING_WEBSITE_THEME,
    path: "/onboarding/website-theme",
    displayInMenu: false,
    //layout: Layout.Blank,
    layout: Layout.TwoColumns,
    content: OnboardingWebsiteTheme,
   },
   //OLD version of creating website
  // {
  //   key: ONBOARDING_VERIFY_IDENTITY,
  //   path: "/onboarding/verifying-identity",
  //   displayInMenu: false,
  //   layout: Layout.TwoColumns,
  //   content: VerifyIdentity,
  //   title: (
  //     <FormattedMessage
  //       id="apps.websites.containers.onboarding.verifyIdentity.heading"
  //       defaultMessage="Hi,"
  //     />
  //   ),
  //   subtitle: (
  //     <FormattedMessage
  //       id="apps.websites.containers.onboarding.verifyIdentity.subtitle"
  //       defaultMessage="Who are you?"
  //     />
  //   ),
  //   sidebar: {
  //     heading: (
  //       <FormattedMessage
  //         id="apps.websites.containers.onboarding.verifyIdentity.sidebar.heading"
  //         defaultMessage="Verifying identity"
  //       />
  //     ),
  //     description: (
  //       <FormattedMessage
  //         id="apps.websites.containers.onboarding.verifyIdentity.sidebar.descripition"
  //         defaultMessage="We are gonna get your data."
  //       />
  //     ),
  //   },
  // },
  {
    key: ONBOARDING_INDUSTRY,
    path: "/onboarding/industry",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingCreateWebsiteAndIndustrySelect,
  },
  {
    key: ONBOARDING_CATEGORY_OBJECT_TYPE,
    path: "/onboarding/object-type",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: ()=>(<IndustryCategoryCheckboxSelection industry="touristic" category="object-type" min={1} max={1} rules={rulesetArrayMinMax(1,1)}/>),
    sidebar: TourismCategories["object-type"]?.sidebar
  },
  {
    key: ONBOARDING_CATEGORY_LOCALIZATION,
    path: "/onboarding/localization",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: ()=>(<IndustryCategoryCheckboxSelection industry="touristic" category="localization" min={1} max={1}   rules={rulesetArrayMinMax(1,1)}/>),
    sidebar: TourismCategories["localization"]?.sidebar
  },
  {
    key: ONBOARDING_CATEGORY_ATTRACTION_TYPE,
    path: "/onboarding/attractionType",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: ()=>(<IndustryCategoryCheckboxSelection industry="touristic" category="attraction-type" min={1} max={1}   rules={rulesetArrayMinMax(1,1)}/>),
    sidebar: TourismCategories["attraction-type"]?.sidebar
  },
  {
    key: ONBOARDING_CATEGORY_REGIONAL_ATTRACTION_TYPE,
    path: "/onboarding/regionalAttraction",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: ()=>(<IndustryCategoryCheckboxSelection industry="touristic" category="regional-attraction-type" min={1} max={1}   rules={rulesetArrayMinMax(1,1)}/>),
    sidebar: TourismCategories["regional-attraction-type"]?.sidebar
  },
  {
    key: ONBOARDING_CATEGORY_PARKING,
    path: "/onboarding/parking",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: ()=>(<IndustryCategoryCheckboxSelection industry="touristic" category="parking" min={0} max={0}   rules={rulesetArrayMinMax(0,0)}/>),
    sidebar: TourismCategories["parking"]?.sidebar
  },
  {
    key: ONBOARDING_CATEGORY_INTERNET,
    path: "/onboarding/internet",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: ()=>(<IndustryCategoryCheckboxSelection industry="touristic" category="internet" min={1} max={1}   rules={rulesetArrayMinMax(1,1)}/>),
    sidebar: TourismCategories["internet"]?.sidebar
  },
  {
    key: ONBOARDING_CATEGORY_SECURITY,
    path: "/onboarding/security",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: ()=>(<IndustryCategoryCheckboxSelection industry="touristic" category="security" min={1} max={0}   rules={rulesetArrayMinMax(1,0)}/>),
    sidebar: TourismCategories["security"]?.sidebar

  },
  {
    key: ONBOARDING_CATEGORY_SUPPORTED_LANGUAGES,
    path: "/onboarding/languages",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: ()=>(<IndustryCategoryCheckboxSelection industry="touristic" category="supported-languages" min={1} max={0}  rules={rulesetArrayMinMax(1,0)}/>),
    sidebar: TourismCategories["supported-languages"]?.sidebar

  },
  {
    key: ONBOARDING_CATEGORY_TECHNOLOGY,
    path: "/onboarding/technology",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: ()=>(<IndustryCategoryCheckboxSelection industry="touristic" category="technology" min={0} max={0}  rules={rulesetArrayMinMax(0,0)}/>),
    sidebar: TourismCategories["technology"]?.sidebar

  },{
    key: ONBOARDING_CATEGORY_CLIMATE,
    path: "/onboarding/climate",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: ()=>(<IndustryCategoryCheckboxSelection industry="touristic" category="climate" min={0} max={0}  rules={rulesetArrayMinMax(0,0)}/>),
    sidebar: TourismCategories["climate"]?.sidebar

  },
  {
    key: ONBOARDING_CATEGORY_ATMOSPHERE,
    path: "/onboarding/atmosphere",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: ()=>(<IndustryCategoryCheckboxSelection industry="touristic" category="atmosphere" min={1} max={0}  rules={rulesetArrayMinMax(1,0)}/>),
    sidebar: TourismCategories["atmosphere"]?.sidebar

  },
  {
    key: ONBOARDING_CATEGORY_RELAX,
    path: "/onboarding/relax",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: ()=>(<IndustryCategoryCheckboxSelection industry="touristic" category="relax" min={0} max={0}  rules={rulesetArrayMinMax(0,0)}/>),
    sidebar: TourismCategories["relax"]?.sidebar

  },  {
    key: ONBOARDING_CATEGORY_SPA,
    path: "/onboarding/spa",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: ()=>(<IndustryCategoryCheckboxSelection industry="touristic" category="spa" min={0}  max={0} rules={rulesetArrayMinMax(0,0)}/>),
    sidebar: TourismCategories["spa"]?.sidebar

  }, 
  {
    key: ONBOARDING_CATEGORY_KIDS,
    path: "/onboarding/kids",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: ()=>(<IndustryCategoryCheckboxSelection industry="touristic" category="kids" min={0}  max={0} rules={rulesetArrayMinMax(0,0)}/>),
    sidebar: TourismCategories["kids"]?.sidebar

  }, 
  {
    key: ONBOARDING_CATEGORY_DISABLED_FACILITIES,
    path: "/onboarding/disabledFacilities",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: ()=>(<IndustryCategoryCheckboxSelection industry="touristic" category="disabled-facilities" min={1} max={0}  rules={rulesetArrayMinMax(1,0)}/>),
    sidebar: TourismCategories["disabled-facilities"]?.sidebar

  },  {
    key: ONBOARDING_CATEGORY_MEALS,
    path: "/onboarding/meals",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: ()=>(<IndustryCategoryCheckboxSelection industry="touristic" category="meals" min={1}  max={0} rules={rulesetArrayMinMax(1,0)}/>),
    sidebar: TourismCategories["meals"]?.sidebar

  },{
    key: ONBOARDING_CATEGORY_SPECIAL_TRANSPORT,
    path: "/onboarding/specialTransport",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: ()=>(<IndustryCategoryCheckboxSelection industry="touristic" category="special-transport" min={0} max={0}  rules={rulesetArrayMinMax(0,0)}/>),
    sidebar: TourismCategories["special-transport"]?.sidebar

  },{
    key: ONBOARDING_CATEGORY_VIEW,
    path: "/onboarding/view",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: ()=>(<IndustryCategoryCheckboxSelection industry="touristic" category="view" min={1}  max={0} rules={rulesetArrayMinMax(1,0)}/>),
    sidebar: TourismCategories["view"]?.sidebar

  },{
    key: ONBOARDING_CATEGORY_FEEDING,
    path: "/onboarding/feeding",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: ()=>(<IndustryCategoryCheckboxSelection industry="touristic" category="feeding" min={1}  max={0} rules={rulesetArrayMinMax(1,0)}/>),
    sidebar: TourismCategories["feeding"]?.sidebar

  },{
    key: ONBOARDING_CATEGORY_SPECIAL_OFFERS,
    path: "/onboarding/specialOffers",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: ()=>(<IndustryCategoryCheckboxSelection industry="touristic" category="special-offers" min={0}  max={0} rules={rulesetArrayMinMax(0,0)}/>),
    sidebar: TourismCategories["special-offers"]?.sidebar

  }
  // ,{
  //   key: ONBOARDING_CATEGORY_NEARBY_ATTRACTIONS,
  //   path: "/onboarding/nearbyAttractions",
  //   displayInMenu: false,
  //   layout: Layout.TwoColumns,
  //   content: ()=>(<IndustryCategoryCheckboxSelection industry="touristic" category="nearby-attractions" min={1}  max={0} rules={rulesetArrayMinMax(1,0)}/>),
  //   sidebar: TourismCategories["nearby-attractions"]?.sidebar

  // }
  ,{
    key: ONBOARDING_CATEGORY_ACTIVE_SPORT,
    path: "/onboarding/activeSport",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: ()=>(<IndustryCategoryCheckboxSelection industry="touristic" category="sport" min={0}  max={0} rules={rulesetArrayMinMax(0,0)}/>),
    sidebar: TourismCategories["sport"]?.sidebar

  },{
    key: ONBOARDING_CATEGORY_ACTIVE_RECREATION,
    path: "/onboarding/activeRecreation",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: ()=>(<IndustryCategoryCheckboxSelection industry="touristic" category="recreation" min={1}  max={0} rules={rulesetArrayMinMax(1,0)}/>),
    sidebar: TourismCategories["recreation"]?.sidebar

  },{
    key: ONBOARDING_CATEGORY_ACTIVE_EVENTS,
    path: "/onboarding/activeEvents",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: ()=>(<IndustryCategoryCheckboxSelection industry="touristic" category="events" min={0}  max={0} rules={rulesetArrayMinMax(0,0)}/>),
    sidebar: TourismCategories["events"]?.sidebar

  },{
    key: ONBOARDING_CATEGORY_BEAUTY,
    path: "/onboarding/activeBeauty",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: ()=>(<IndustryCategoryCheckboxSelection industry="touristic" category="beauty" min={0}  max={0} rules={rulesetArrayMinMax(0,0)}/>),
    sidebar: TourismCategories["beauty"]?.sidebar

  },{
    key: ONBOARDING_ROOMS,
    path: "/onboarding/rooms",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: RoomsOnboarding

  },
  {
    key: ONBOARDING_RULES,
    path: "/onboarding/rules",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: ResidenceRulesOnboarding
  },
  {
    key: ROOMS,
    path: "/rooms",
    name: (
      <FormattedMessage
        id="apps.websites.routes.rooms"
        defaultMessage="Rooms"
      />
    ),
    icon: <AwesomeLarge icon={faBed} />,
    layout: Layout.Panel,
    title: (
      <FormattedMessage
        id="apps.websites.routes.rooms"
        defaultMessage="Rooms"
      />
    ),
    mainHasBackground: false,
    content: RoomList,
  },
  {
    key: OBJECT_RULES,
    path: "/objectRules",
    name: (
      <FormattedMessage
        id="apps.websites.routes.rules"
        defaultMessage="Rules"
      />
    ),
    icon: <AwesomeLarge icon={faScaleBalanced} />,
    layout: Layout.Panel,
    title: (
      <FormattedMessage
        id="apps.websites.routes.rules"
        defaultMessage="Rules"
      />
    ),
    mainHasBackground: false,
    content: ResidenceRulesWithTabs,
  },
  {
    key: OBJECT_CATEGORIES,
    path: "/objectCategories",
    name: (
      <FormattedMessage
        id="apps.websites.routes.categories"
        defaultMessage="Categories"
      />
    ),
    icon: <AwesomeLarge icon={faGrid} />,
    layout: Layout.Panel,
    title: (
      <FormattedMessage
        id="apps.websites.routes.categories"
        defaultMessage="Categories"
      />
    ),
    content: IndustryCategories,
  },
  {
    key: OBJECT_DATA,
    path: "/objects",
    name: (
      <FormattedMessage
        id="apps.websites.routes.objectData"
        defaultMessage="Object"
      />
    ),
    icon: <AwesomeLarge icon={faList} />,
    layout: Layout.Panel,
    title: (
      <FormattedMessage
        id="apps.websites.routes.objectData"
        defaultMessage="Object"
      />
    ),
    content: WebsiteObjectLocation,
  },
  {
    key: ONBOARDING_LOCATION_DATA,
    path: "/onboarding/location",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: WebsiteObjectLocationOnboarding,
  },
];

export default routes;

export const getUrl = (routeKey: string) =>
  Helper.getPath(APP_PATH, routes, routeKey);
