import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Card, Col, Divider, Input, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import { StandardDrawer, useStandardDrawer } from "services/hooks/useDrawer";
import { RemoveRoom, RoomSelector, roomsSlice } from "modules/site-generator/reducers/roomsSlice";
import { useEffect, useMemo, useState } from "react";
import FormattedMessage from "components/common/FormattedMessage";
import useAPI from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { industryCategoriesAsObjects } from "state/industries/industriesSlice";
import Gallery from "tools/gallery/Gallery";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";
import BudgetInput from "_components/forms/budget-input";
import RoomCategorySelector from "./room-category-selector";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import StandardButton from "components/common/StandardButton";


export type RoomProps = {
    roomId?: string;
    onRoomDelete?: (roomId?: string) => void;
}

export const RoomListEntry = (props: RoomProps) => {

    const [ref, open, close] = useStandardDrawer();
    const room = useSelectorWithParams([RoomSelector,props.roomId])
    
    return (
        <>
         <Card
                actions={[
                    <EditOutlined key="edit" onClick={open} />,
                ]}
                style={{ width: "200px", maxWidth: "100vw" }}
                title={room?.name || "Unnamed room"}
            >
                <div style={{overflow: "hidden", height: "60px"}}>
                    {room?.description || "No Description"}
                </div>
            </Card>
            <StandardDrawer
                ref={ref}
                title={<FormattedMessage
                    id="apps.websites.forms.rooms.editRoomTitle"
                    defaultMessage="Edit Room"
                />}
                width="900px"
                layout="standard"
            >
                    <Room roomId={props.roomId} onRoomDelete={close}/>
            </StandardDrawer>
        </>
    )


}

export default function Room(props: RoomProps) {

    const { call, data } = useAPI({})
    const room = useSelectorWithParams([RoomSelector,props.roomId])

    const categories = useSelectorWithParams([industryCategoriesAsObjects, "touristic", room.categories])

    //const mappedValues = useMemo(() => categories && categories.map && categories.map((v:any) => v.id), [categories])

    return (
        <>
            <div>
            <div className="roomTools"><StandardButton icon={<DeleteOutlined />} onClick={() => {
                call(RemoveRoom(props.roomId))
                props.onRoomDelete && props.onRoomDelete(props.roomId)
            }}
            >Remove Room</StandardButton></div>
                <RelaxMultiform
                    submitOnChange
                    callAPI={{
                        url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/websites/object/room/${props.roomId}`),
                        method: "PATCH",
                        auth: true,
                        successDispatch: roomsSlice.actions.updateRoom,
                        passToDispatcher: { roomId: props.roomId },
                    }}
                    initialValuesSelector={[RoomSelector, props.roomId]}
                    ignoreChangesToInitialValues
                    name="rules">
                    <RelaxSingleField
                        name="name"
                        label={<FormattedMessage
                            id="apps.websites.forms.rooms.name"
                            defaultMessage="Room name"
                        />}
                    >
                        <Input />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name="description"
                        label={<FormattedMessage
                            id="apps.websites.forms.rooms.decription"
                            defaultMessage="Description"
                        />}
                    >
                        <TextArea  rows={4}/>
                    </RelaxSingleField>
                    <RelaxSingleField
                        name="media"
                        label={<FormattedMessage
                            id="apps.websites.forms.rooms.images"
                            defaultMessage="Images"
                        />}
                        ignoreUpdateBlockOnFocus
                        ignoreChangesToInitialValues = {false}
                        debounce={1}
                    >
                        <Gallery 
                            allowedTools={["crop", "delete"]} 
                            valueChangeReplacesAll={true}
                        />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name="price"
                        label={<FormattedMessage
                            id="apps.websites.forms.rooms.price"
                            defaultMessage="Price"
                        />}
                    >
                        <BudgetInput style={{ width: "200px" }}/>
                    </RelaxSingleField>
                    <RoomCategorySelector
                        label={<FormattedMessage
                            id="apps.websites.forms.rooms.category.kitchen"
                            defaultMessage="Kitchen"
                        />}
                        roomId={props.roomId}
                        roomCategories={categories}
                        industry="touristic"
                        category="kitchen" />
                    <RoomCategorySelector
                        label={<FormattedMessage
                            id="apps.websites.forms.rooms.category.bedroom"
                            defaultMessage="Bedroom"
                        />}
                        roomId={props.roomId}
                        roomCategories={categories}
                        industry="touristic"
                        category="bedroom" />
                    <RoomCategorySelector
                        label={<FormattedMessage
                            id="apps.websites.forms.rooms.category.bathroom"
                            defaultMessage="Bathroom"
                        />}
                        roomId={props.roomId}
                        roomCategories={categories}
                        industry="touristic"
                        category="bathroom" />
                    <RoomCategorySelector
                        label={<FormattedMessage
                            id="apps.websites.forms.rooms.category.livingroom"
                            defaultMessage="Livingroom"
                        />}
                        roomId={props.roomId}
                        roomCategories={categories}
                        industry="touristic"
                        category="part-of-the-common" />
                </RelaxMultiform>
            </div>
        </>
    )
}