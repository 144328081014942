import { Col, Input, Space, Typography } from "antd"
import StandardButton from "components/common/StandardButton"
import { StandardFullColumn, StandardHalfFullColumn, StandardRow } from "components/layout/StandardGrid"
import { ReactNode, useMemo } from "react"
import "./promo.less"
import { HorizontalCenter } from 'components/common/Center';
import Markdown from "components/markdown/Markdown"

type Promotion = {
    title?: string,
    subtitle?: string,
    description?: string,
    code?: string,
    link?: string,
    video?: string,
    price?: string,
    discount?: string,
    image?: string,
    priceAfterDiscound?: string,
}

const PromoItem = (props: Promotion) => {

    const side = useMemo(() => {

        if (props.video) {
            return (
                <HorizontalCenter>
                    <img src={props.image} width="100%" />
                    {/* <iframe width="560" height="315" src={props.video}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen>
                    </iframe> */}
                </HorizontalCenter>
            )
        }

    }, [])

    return (
        <div className="standard-box">
            <Typography.Title level={1}>{props.title}</Typography.Title>
            <StandardRow>

                <StandardHalfFullColumn>

                    {side}
                </StandardHalfFullColumn>
                <StandardHalfFullColumn style={{fontSize: "1.1rem"}}>
                    <Typography.Title level={3}>{props.subtitle}</Typography.Title>
                    <Typography.Paragraph  ><Markdown text={props.description}/></Typography.Paragraph>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        width: "80%",
                        gap: "50px"
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            <div className="promo-price">
                                <div className="promo-normal-price">
                                    <div className="price">{props.price}</div>
                                    {props.discount && <div className="normal-price">Reguralna cena</div>}
                                </div>
                                {props.discount && <>
                                    <div className="promo-discount">{props.discount}</div>
                                    <div className="promo-current-price">{props.priceAfterDiscound}</div>
                                </>
                                }
                            </div>

                            <div className="lowest-price-info">
                                <span>Najniższa cena w ciągu ostatnich 30 dni to 600 PLN.</span>
                            </div>
                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: "flex-end",
                        }}>
                            <StandardButton className="promo-buy" type="primary" href={props.link} target="_blank" rel="noreferrer noopener">KUP</StandardButton>
                        </div>


                    </div>
                    <HorizontalCenter style={{ paddingTop: "50px" }}>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px"
                        }}>
                            {props.code && <>Kod:<span style={{
                                fontSize: "2rem",
                                fontWeight: "600"
                            }}>{props.code}</span></>}
                        </div>
                    </HorizontalCenter>
                </StandardHalfFullColumn>
            </StandardRow>
     
            <StandardRow>
                
            <StandardHalfFullColumn>
                </StandardHalfFullColumn>
                <StandardHalfFullColumn>
                   
                </StandardHalfFullColumn>
            </StandardRow>
        </div>
    )
}

export default function PromoList() {

    return (
        <Space direction="vertical">
            <PromoItem
                title={"Akademia FastTony"}
                subtitle={"Jak prowadzić media społecznościowe aby przekonywać do zakupu"}
                description={`Ten kurs jest przygotowany w oparciu o doświadczenia klientów FastTony, którzy za pośrednictwem mediów społecznościowych zbudowali swoją rozpoznawalności i sprzedaż.  
                \\
                Chcąc w kursie umieścić konkretne przykłady i rozwiązania musiałem wybrać jedną branżę. Wybrałem branżę edukacyjną. 
                Uważam że to dobry przykład bo klient podejmuje decyzje o zakupie niematerialnego produktu wyłącznie o ... swoje wyobrażenie i złożoną mu przez sprzedawcę obietnicę. `}
                link={"https://akademia.fasttony.com/s/fasttony-sprzedaz.html"}
                video={"https://www.youtube.com/embed/bCHixNlUxSI"}
                price={"600.00 PLN"}
                //discount="-50%"
                //priceAfterDiscound={"48.50 PLN"}
                //code={"AkademiaFastTony"}
                image={"/images/academia_promo_3.png"}
            />
        </Space>
    )

}


