import { Collapse, Select, Space } from "antd"
import StandardButton from "components/common/StandardButton"
import { AiProjectInfoSelector, GenerateAIDemographics, UpdateAIProjectInfo } from "modules/panel/state/AISlice"
import useAPI from "services/hooks/useAPI"
import RelaxField from "tools/relaxForm/RelaxField"
import { AIDemographicsCategoriesType, AIDemographicsCategoryNames, AIDemographicsTables } from "./AIDemographicsConfig"
import { useMemo } from "react"
import CollapsePanel from "antd/es/collapse/CollapsePanel"
import RelaxMultiform from "tools/relaxForm/RelaxMultiform"
import useSelectorWithParams from "services/hooks/useSelectorWithParams"
import _ from "lodash"
import { useIntl } from "react-intl"
import FormattedMessage from "components/common/FormattedMessage"


/* 
personalCharacteristics
hobbies
interests
personalAspirations
professionalGoals
pains
mainChallenges
needs
dreams
budget
shoppingFrequency
preferredChannels
onlineBehavior
searchTerms
preferredBrands
triggers
barriers
*/

export const GetAIDemographicsFieldLabel = (name: string) => {
    switch (name) {
        case ("demographics"):
            return <FormattedMessage
                id="apps.smart.components.aiSettings.demographics.demographics.label"
                defaultMessage="Demographics"
            />
        case ("purchaseObjections"):
            return <FormattedMessage
                id="apps.smart.components.aiSettings.demographics.purchaseObjections.label"
                defaultMessage="Purchase Objections"
            />
        case ("shoppingBehaviors"):
            return <FormattedMessage
                id="apps.smart.components.aiSettings.demographics.shoppingBehaviors.label"
                defaultMessage="Shopping Behaviors"
            />
        case ("personalCharacteristics"):
            return <FormattedMessage
                id="apps.smart.components.aiSettings.demographics.personalCharacteristics.label"
                defaultMessage="Personal Characteristics"
            />
        case ("hobbies"):
            return <FormattedMessage
                id="apps.smart.components.aiSettings.demographics.hobbies.label"
                defaultMessage="Hobbies"
            />
        case ("interests"):
            return <FormattedMessage
                id="apps.smart.components.aiSettings.demographics.interests.label"
                defaultMessage="Interests"
            />
        case ("personalAspirations"):
            return <FormattedMessage
                id="apps.smart.components.aiSettings.demographics.personalAspirations.label"
                defaultMessage="Personal Aspirations"
            />
        case ("professionalGoals"):
            return <FormattedMessage
                id="apps.smart.components.aiSettings.demographics.professionalGoals.label"
                defaultMessage="Professional Goals"
            />
        case ("pains"):
            return <FormattedMessage
                id="apps.smart.components.aiSettings.demographics.pains.label"
                defaultMessage="Pains"
            />
        case ("mainChallenges"):
            return <FormattedMessage
                id="apps.smart.components.aiSettings.demographics.mainChallenges.label"
                defaultMessage="Main Challenges"
            />
        case ("needs"):
            return <FormattedMessage
                id="apps.smart.components.aiSettings.demographics.needs.label"
                defaultMessage="Needs"
            />
        case ("dreams"):
            return <FormattedMessage
                id="apps.smart.components.aiSettings.demographics.dreams.label"
                defaultMessage="Dreams"
            />
        case ("budget"):
            return <FormattedMessage
                id="apps.smart.components.aiSettings.demographics.budget.label"
                defaultMessage="Budget"
            />
        case ("shoppingFrequency"):
            return <FormattedMessage
                id="apps.smart.components.aiSettings.demographics.shoppingFrequency.label"
                defaultMessage="Shopping Frequency"
            />
        case ("preferredChannels"):
            return <FormattedMessage
                id="apps.smart.components.aiSettings.demographics.preferredChannels.label"
                defaultMessage="Preferred Channels"
            />
        case ("onlineBehavior"):
            return <FormattedMessage
                id="apps.smart.components.aiSettings.demographics.onlineBehavior.label"
                defaultMessage="Online Behavior"
            />
        case ("searchTerms"):
            return <FormattedMessage
                id="apps.smart.components.aiSettings.demographics.searchTerms.label"
                defaultMessage="Search Terms"
            />
        case ("preferredBrands"):
            return <FormattedMessage
                id="apps.smart.components.aiSettings.demographics.preferredBrands.label"
                defaultMessage="Preferred Brands"
            />
        case ("triggers"):
            return <FormattedMessage
                id="apps.smart.components.aiSettings.demographics.triggers.label"
                defaultMessage="Triggers"
            />
        case ("barriers"):
            return <FormattedMessage
                id="apps.smart.components.aiSettings.demographics.barriers.label"
                defaultMessage="Barriers"
            />
        default:
            return name
    }
}


const DemographicsEntry = (props: { name: string, category: string }) => {

    const name = props.category === "purchaseObjections" ? ["demographics", "purchaseObjections"] : ["demographics", props.category, props.name]

    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
        }}>
            {/* <div style={{width: "300px", display: "grid", placeItems: "center"}}>{props.name}</div> */}
            <RelaxField
                name={name}
                style={{ width: "100%", padding: "0px" }}
                label={GetAIDemographicsFieldLabel(props.name)}
                hideStatus={true}
                submitOnChange={true}
            >
                <Select mode="tags" style={{ width: "100%" }} />
            </RelaxField>
        </div>
    )
}


const AIDemographics = (props: { category: AIDemographicsCategoriesType }) => {

    const currentData = useSelectorWithParams([AiProjectInfoSelector, props.category])

    const mappedTable = useMemo(() => {

        return AIDemographicsTables[props.category].map((r) => <DemographicsEntry key={r} category={props.category} name={r} />)

    }, [props.category])

    return (
        <div>
            <RelaxMultiform
                callAPI={UpdateAIProjectInfo}
                initialValuesSelector={AiProjectInfoSelector}
                finalizeSubmit={(values) => {
                    const newData = _.merge(_.cloneDeep(currentData), _.cloneDeep(values))
                    return {
                        demographics: newData.demographics
                    };
                }}
            >
                {mappedTable}
            </RelaxMultiform>
        </div>
    )

}

export default AIDemographics;