import AOA from "models/aoa";
import Asset from "models/asset";
import Lead from "models/lead";
import Post from "models/post";
import TestAsset from "models/testAsset";
import React, { useEffect, useState, useRef, useContext, useMemo, useImperativeHandle, ReactNode } from "react";
import FormattedMessage from "components/common/FormattedMessage";
import { StandardDrawer, useStandardDrawer } from "services/hooks/useDrawer";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { AdDetailsColumns, AssetColumns, botsConfig, configByType, DefaultColumnsPosts, PlatformDetailsColumns } from "tools/infinityList/infinityTemplates";
import PostListEntry from "./postListEntry";
import PostManagementDetails from "./postManagementDetails";
import { FilterPost, PostSelectorByLocalId } from "./state/postsSlice";
import { userSettingsInterfaceSelector } from "state/user/userSettingsSlice";




export type PostContextReturn = {
    post: Post;
    openDetails: any,
    closeDetails: any,
    reloadList: any,
    markChangesMade: any,
    isOpen: boolean,
    isExpanded: boolean,
    setIsExpanded: any,
    config: any,
    options: any,
}

const PostContext = React.createContext({} as PostContextReturn);
PostContext.displayName = "Post Context";
export const usePost = (): PostContextReturn => useContext(PostContext);


export type PostContextProps = {
    postId: string | number;
}


const PostContextProvider = React.forwardRef((props: any) => {


    // const [post, setPost] = useState<Post | undefined>();
    const [ref, open, close, isOpen] = useStandardDrawer();
    const [detailParams, setDetailParams] = useState<any>({});
    const postObj = useSelectorWithParams([PostSelectorByLocalId, props.postId]);
    const [changesMade, setChangesMade] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const interfaceSettings = useSelectorWithParams(userSettingsInterfaceSelector)


    const config = useMemo(() => {
        if (!postObj) return null;
        if (props.options && props.options.bots) {
            return botsConfig;
        }

        return configByType[postObj.type] ? configByType[postObj.type] : configByType["asset"];
    }, [postObj]);

    const post = useMemo(() => {
        if (!props.postId || !postObj) return null;

        return config.getObject(postObj);

    }, [postObj])

    const openDetails = (details: any) => {
        //if (post?.type !== "post" || post?.type !== "aoa") return;
        setDetailParams({ ...details });
        open();
    }

    const handleListReload = () => {
        props.reloadList && props.reloadList();
    }

    const handleDrawerClose = () => {
        if (changesMade) {
            handleListReload();
        }
    }

    const markChangesMade = (shouldClose?: boolean) => {
        setChangesMade(true);
        if (shouldClose) {
            close();
            handleListReload();
        }
    }

    const shouldBeDisplayed = useMemo(() => FilterPost(post, interfaceSettings?.postListv2), [post, interfaceSettings])

    if (!shouldBeDisplayed) {
//        console.log("Post not displayed", post, interfaceSettings?.postListv2)
        return null;
    }

    if (!post) return <div>Loading...</div>

    return (
        <PostContext.Provider value={{
            post: post,
            openDetails: openDetails,
            closeDetails: close,
            reloadList: handleListReload,
            markChangesMade: markChangesMade,
            isOpen: isOpen,
            isExpanded: isExpanded,
            setIsExpanded: setIsExpanded,
            config,
            options: props.options,
        }}>
            <PostListEntry {...props} key={post.id} />
                <StandardDrawer
                    ref={ref}
                    width={"600px"}
                    layout="standard"
                    mask={true}
                    onClose={handleDrawerClose}
                >
                    <PostManagementDetails details={detailParams} onChangesMade={() => setChangesMade(true)} />
                </StandardDrawer>
        </PostContext.Provider>)

})

export default PostContextProvider;