import { Collapse, Input, Tooltip } from "antd";
import StandardButton from "components/common/StandardButton";
import { useEffect, useMemo } from "react";
import FormattedMessage from "components/common/FormattedMessage";
import { faCheckCircle, faLeft, faPlus, faPlusCircle, faRight, faTrashCan, faTrashCircle } from "@fortawesome/pro-light-svg-icons";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import { GalleryWrapperForPostFormSingle } from "./GalleryWrappers";
import { useCreationEditor } from "../CreationEditor";
import { creationContentSelector, patchCreation, patchCreationWithSmartAction } from "../creationsSlice";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";
import _ from "lodash";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { ValidationResult } from "../editorTools/ValidationResults";
import { createPortal } from "react-dom";
import { useCreationSection } from "../editorTools/useCreationSection";
import { creationSlideSelector } from "../creationHelpers";

const isFieldEmpty = (slide: any, field: string | string[]) => {
    return _.get(slide, field) === undefined || _.get(slide, field) === null;
}

const CarouselInstagramCard = (props: {
    removeItem: (index: number) => void,
    numberOfItems: number,
    carouselIndex: number,
    slideId: number,
}) => {

    const slideId = props.slideId;
    const sectionName = `card-${props.slideId}`;

    const { creation, creationId } = useCreationEditor()
    const slide = useSelectorWithParams([creationSlideSelector, creationId, slideId])
    const { ref, status, setStatusData, handleFieldError, sectionComponentProps } = useCreationSection({
        sectionName: sectionName,
        smartActionPrefix: `ce_card_${props.slideId}`,
        filterValidationResults: (errors: any) => {
            const matchingKeys = Object.keys(errors).filter((key) => key.startsWith(`slides.${props.slideId}`));
            return _.pick(errors, matchingKeys);
        },
    });


    useEffect(() => {
        if (!slide) setStatusData("initial");
        const checks = ["media"];
        const emptyFields = checks.filter((field) => isFieldEmpty(slide, field));
        if (emptyFields.length === 0) {
            setStatusData("ready");
        } else {
            setStatusData("error");
        }
    }, [slide]);

    const queryAndInjectError = (field: string, errors: string[]) => {
        const elem = document.querySelector(`#creationCard-${slideId} #rf-${field} .extra-error`);
        if (!elem) return [];
        return errors.map((error, i) => createPortal(<ValidationResult result={error} />, elem))
    }


    const validationErrors = () => {
        if (!sectionComponentProps?.validationErrors) return null;
        const errors:Record<string, string[]> = sectionComponentProps.validationErrors as Record<string, string[]>;
        
        if (!errors) return null;

        const errorDisplays:any = [];
        Object.keys(errors).forEach((key) => {
            if (key.endsWith("media")) errorDisplays.push(...queryAndInjectError("media", errors[key]));
        })

        return errorDisplays;

    }


    if (!slide) return null


    return (
        <div
            id={`creationCard-${slideId}`}
        >
            <RelaxMultiform
                name="carouselItems"
                callAPI={patchCreationWithSmartAction(creationId, `ce_card_${props.slideId}`)}
                initialValuesSelector={[creationSlideSelector, creationId, slideId]}
                finalizeSubmit={(values: any) => {
                    return {
                        content: {
                            slides: {
                                [slideId]: values
                            }
                        }
                    }
                }}
            >
                <div>
                    <RelaxSingleField
                        name={["media"]}
                        onErrors={(errors: any) => handleFieldError("media", errors)}
                        callAPI={patchCreationWithSmartAction(creationId, `ce_card_${props.slideId}_media`)}
                        label={<FormattedMessage
                            id="apps.smart.containers.createPostAndAd.form.media"
                            defaultMessage="Media"
                        />}
                    >
                        <GalleryWrapperForPostFormSingle />
                    </RelaxSingleField>
                   
                </div>
            </RelaxMultiform>
            <div style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
            }}>
                <Tooltip title={
                    <FormattedMessage
                        id="components.forms.list.delete"
                        defaultMessage="Remove"
                    />}>
                    <StandardButton
                        icon={faTrashCan}
                        disabled={props.numberOfItems <= 1}
                        onClick={() => props.removeItem && props.removeItem(slideId)}
                    />
                </Tooltip>
            </div>
            {validationErrors()}
        </div>
    )

}

export default CarouselInstagramCard;
