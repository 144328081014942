
import MultiSelectCheckbox from "_components/forms/multiselect-checbox";
import ConnectedOnboardingControls from "providers/onboarding/controls";
import { get, GetIndustryData, industryChildrenOfCategorySelector } from "state/industries/industriesSlice";
import { useEffect, useMemo, useState } from "react";
import { Input, Skeleton } from "antd";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { CategoriesAsObjectsSelector, CategoriesSlice, SelectedCategoriesInCategorySelector, UserHasFilledCategorySelector } from "modules/site-generator/reducers/categoriesSlice";
import { TourismCategories } from "modules/site-generator/config/industries/industryCategoriesDefinitions";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import useAPI from "services/hooks/useAPI";


export type props = {
    rules?: any,
    industry: string,
    category: string,
    min?: number,
    max?: number,
}

export const IndustryCategoryCheckboxSelection = (props: props) => {

    const data = useSelectorWithParams([industryChildrenOfCategorySelector, props.industry, props.category]) || {};

    const category = data && data.category || null;
    const options = data && data.options || null;

    const call = useAPI(GetIndustryData(props.industry))

    useEffect(()=>{
        window.debugStep = data;
        if(!data || !data.category) call.call()
    },[data])

    const values = useSelectorWithParams([SelectedCategoriesInCategorySelector, props.industry, props.category]);
    const fulfilled = useSelectorWithParams([UserHasFilledCategorySelector, props.industry, props.category, props.min || 0, props.max || 0]);
    const [hasErrors, setHasErrors] = useState(false);

    const mappedOptions = useMemo(() => options && options.map((o: any) =>
        ({ value: o.id, label: o.name })
    ), [options])

    const mappedValues = useMemo(() => values && values.map && values.map((v: any) => v.id), [values])

    if (!mappedOptions) {
        return (
            <Skeleton
                className="fake-label"
                active
                loading
                paragraph={{ rows: 4 }} />
        )
    }

    return (
        <>
            <h1>
                {(TourismCategories as any)?.[props.category]?.title || ""}
            </h1>
            <RelaxSingleField
                name="categories"
                rules={props.rules}
                initialValues={{ categories: mappedValues }}
                callAPI={{
                    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/websites/object/categories/${category?.id || ""}`),
                    method: "PATCH",
                    auth: true,
                    successDispatch: CategoriesSlice.actions.update,
                    passToDispatcher: { categories: values },
                }}
                submitOnChange
                onErrors={(e:any) => setHasErrors(!!e)}
            >
                <MultiSelectCheckbox min={props.min} max={props.max} options={mappedOptions} />
            </RelaxSingleField>
            <ConnectedOnboardingControls relaxDontSubmit={true} nextDisabled={(!fulfilled || hasErrors)} />
        </>
    )

  
}