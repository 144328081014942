import { Alert, Input, Space } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import StandardButton from "components/common/StandardButton";
import AddressDisplay from "components/displays/AddressDisplay";
import { StandardFullColumn, StandardHalfFullColumn, StandardRow } from "components/layout/StandardGrid";
import { companyDataConfirmedSelector, companyDataSelector, confirmCompanyData, updateCompanyData } from "modules/panel/state/companySlice";
import useAPI from "services/hooks/useAPI";
import { useSimpleModal } from "services/hooks/useModal";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import RelaxField from "tools/relaxForm/RelaxField";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";

const ConfirmationAlert = () => {

    const companyData = useSelectorWithParams(companyDataSelector)
    const dataConfirmed = useSelectorWithParams(companyDataConfirmedSelector)
    const [openModal, contextHolder] = useSimpleModal();
    const invoiceDataConfirmationCall = useAPI(confirmCompanyData(companyData.id))


    const handleConfirm = () => {
        invoiceDataConfirmationCall.call();
    }

    const showConfirmation = () => {
        openModal({
            title: "Confirmation",
            type: "confirm",
            width: 800,
            content: (
                <div>
                    <AddressDisplay address={companyData} />

                    <FormattedMessage
                        id="apps.panel.containers.onboarding.companyData.confirmation.title"
                        defaultMessage="Are you sure this data is correct?"
                    />
                </div>
            ),
            onOk: handleConfirm,
        })
        return false;
    }

    if (dataConfirmed) return null;


    return (
        <>
            <Alert
                type="info"
                message={(
                    <Space style={{ width: "100%", justifyContent: "space-between" }}>
                        <FormattedMessage
                            id="panel.containers.companySettings.dataNotConfirmed.message"
                            defaultMessage="Please confirm your company data."
                        />
                        <StandardButton onClick={showConfirmation}>Confirm</StandardButton>
                    </Space>
                )}
            />
            {contextHolder}
        </>
    )

}

function CompanyInvoicePage() {

    const companyData = useSelectorWithParams(companyDataSelector)
    const dataConfirmed = useSelectorWithParams(companyDataConfirmedSelector)

    return (
        <StandardRow>
            <StandardHalfFullColumn>


                <StandardRow>
                    <StandardFullColumn>
                        <ConfirmationAlert />
                    </StandardFullColumn>
                </StandardRow>

                <RelaxMultiform
                    initialValues={companyData}
                    callAPI={updateCompanyData(companyData.id)}
                    disabled={dataConfirmed}
                >

                    <StandardRow>
                        <StandardFullColumn>
                            <RelaxField
                                name="vat_number"
                                disabled={dataConfirmed}
                                label={
                                    <FormattedMessage
                                        id="panel.containers.companySettings.vatNumber"
                                        defaultMessage="VAT number"
                                    />
                                }>
                                <Input />
                            </RelaxField>
                        </StandardFullColumn>
                    </StandardRow>


                    <StandardRow>
                        <StandardFullColumn>
                            <RelaxField
                                name="name"
                                required
                                disabled={dataConfirmed}
                                label={
                                    <FormattedMessage
                                        id="panel.containers.companySettings.name"
                                        defaultMessage="Name"
                                    />
                                }>
                                <Input />
                            </RelaxField>
                        </StandardFullColumn>
                    </StandardRow>


                    <StandardRow>
                        <StandardHalfFullColumn>
                            <RelaxField
                                name="country"
                                required
                                disabled={dataConfirmed}
                                label={
                                    <FormattedMessage
                                        id="panel.containers.companySettings.country"
                                        defaultMessage="Country"
                                    />
                                }>
                                <Input />
                            </RelaxField>
                        </StandardHalfFullColumn>

                        <StandardHalfFullColumn>
                            <RelaxField
                                name="city"
                                required
                                disabled={dataConfirmed}
                                label={
                                    <FormattedMessage
                                        id="panel.containers.companySettings.city"
                                        defaultMessage="City"
                                    />
                                }>
                                <Input />
                            </RelaxField>
                        </StandardHalfFullColumn>
                    </StandardRow>

                    <StandardRow>
                        <StandardFullColumn>
                            <RelaxField
                                name="street"
                                required
                                disabled={dataConfirmed}
                                label={
                                    <FormattedMessage
                                        id="panel.containers.companySettings.street"
                                        defaultMessage="Street"
                                    />
                                }>
                                <Input />
                            </RelaxField>
                        </StandardFullColumn>
                    </StandardRow>

                    <StandardRow>
                        <StandardHalfFullColumn>

                            <RelaxField
                                name="street_number"
                                required
                                disabled={dataConfirmed}
                                label={
                                    <FormattedMessage
                                        id="panel.containers.companySettings.streetNumber"
                                        defaultMessage="Street number"
                                    />
                                }>
                                <Input />
                            </RelaxField>
                        </StandardHalfFullColumn>

                        <StandardHalfFullColumn>
                            <RelaxField
                                name="street_apartment"
                                disabled={dataConfirmed}
                                label={
                                    <FormattedMessage
                                        id="panel.containers.companySettings.streetApartment"
                                        defaultMessage="Apartment number"
                                    />
                                }>
                                <Input />
                            </RelaxField>
                        </StandardHalfFullColumn>

                    </StandardRow>

                    <StandardRow>
                        <StandardFullColumn>
                            <RelaxField
                                name="postal_code"
                                required
                                disabled={dataConfirmed}
                                label={
                                    <FormattedMessage
                                        id="panel.containers.companySettings.zipCode"
                                        defaultMessage="ZIP code"
                                    />
                                }>
                                <Input />
                            </RelaxField>
                        </StandardFullColumn>
                    </StandardRow>

                    {/* <StandardRow>
                        <StandardHalfFullColumn>
                            <RelaxField
                                name="website"
                                label={
                                    <FormattedMessage
                                        id="panel.containers.companySettings.website"
                                        defaultMessage="Website"
                                    />
                                }
                            >
                                <Input />
                            </RelaxField>
                        </StandardHalfFullColumn>
                        <StandardHalfFullColumn>
                            <RelaxField
                                name="phone"
                                label={
                                    <FormattedMessage
                                        id="panel.containers.companySettings.phone"
                                        defaultMessage="phone"
                                    />
                                }
                            >
                                <PhoneNumberInputWithCountrySelect />
                            </RelaxField>
                        </StandardHalfFullColumn> 
                    </StandardRow>*/} 


                </RelaxMultiform>
            </StandardHalfFullColumn>
        </StandardRow>
    );
}

export default CompanyInvoicePage;