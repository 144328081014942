import React, {Component} from 'react';
import MaskedInput from "_components/forms/masked-inputs/masked-input";

export const ZIP_CODE_PATTERN = [/\d/, /\d/, "-", /\d/, /\d/, /\d/];

export default class ZipCodeInput extends Component {
    render() {
        return (
            <MaskedInput
                pattern={ZIP_CODE_PATTERN}
                delimiters={["-"]}
                {...this.props}
            />
        )
    }
}
