import { Input, message } from "antd"
import { useRef } from "react"
import { Link, Route, Switch } from "react-router-dom"
import { callAPIProps } from "services/hooks/useAPI"
import { useSimpleModalWithAction } from "services/hooks/useModal"
import userSettingsSlice, { userSettingsTestSelector } from "state/user/userSettingsSlice"
import RelaxControls from "tools/relaxForm/RelaxControls"
import RelaxForm from "tools/relaxForm/RelaxForm"
import RelaxListNew from "tools/relaxForm/RelaxList"
import { ruleMax } from "tools/relaxForm/relaxPresets"
import RelaxSingleField from "tools/relaxForm/RelaxField"
import useRelaxData from "tools/relaxForm/useRelaxData"
import StandardButton from "components/common/StandardButton"

const patchUserSettings: callAPIProps = {
    url: ({ getApiUrl }) => getApiUrl("user/settings"),
    method: "PATCH",
    successDispatch: userSettingsSlice.actions.update,
}

const finalizeSubmit = (data: any) => {
    return { settings: { testing: { ...data } } }
}

export const Test_Form_SingleField_1 = () => {

    return (
        <div>
            <h1>Test_Form_SingleField_1</h1>
            <RelaxSingleField
                name="singleField"
                label="Single Field"
                callAPI={patchUserSettings}
                initialValuesSelector={userSettingsTestSelector}
                finalizeSubmit={finalizeSubmit}
                debug={true}
            >
                <Input />
            </RelaxSingleField>
        </div>
    )

}

export const Test_Form_SingleField_2 = () => {

    const rd = useRelaxData();
    const { currentValue, errors} = rd;

    return (
        <div>
            <h1>Test_Form_SingleField_2</h1>
            Single field, data as hook
            <div>
            CurrentValue: {currentValue}
            </div>
            <div>
            Errors: {errors}
            </div>
            <RelaxSingleField
                relaxData={rd}
                name="singleField"
                callAPI={patchUserSettings}
                initialValuesSelector={userSettingsTestSelector}
                finalizeSubmit={finalizeSubmit}
                rules={[{min: 5}, {max: 20}]}
            >
                <Input />
            </RelaxSingleField>
        </div>
    )

}

export const Test_Form_SingleField_ManualSubmit = () => {


    const rd = useRelaxData({});
    const { currentValue, errors} = rd;

    return (
        <div>
            <h1>Test_Form_SingleField_ManualSubmit</h1>
            Single field, data as hook, manual submit by passing hook
            <RelaxSingleField
                relaxData={rd}
                name="singleField"
                label="Single Field"
                callAPI={patchUserSettings}
                initialValuesSelector={userSettingsTestSelector}
                finalizeSubmit={finalizeSubmit}
                rules={[{min: 5}, {max: 20}]}
                mode="manual"
                debug={true}
            >
                <Input />
            </RelaxSingleField>
            <RelaxControls 
                relaxData={rd}
            />
        </div>
    )

}

export const Test_Form_SingleField_4 = () => {

    const formRef = useRef<any>(null);

    return (
        <div>
            <h1>Test_Form_SingleField_4</h1>
            Single field, data as hook, manual submit by passing ref
            <RelaxSingleField
                ref = {formRef}
                name="singleField"
                label="Single Field"
                callAPI={patchUserSettings}
                initialValuesSelector={userSettingsTestSelector}
                finalizeSubmit={finalizeSubmit}
                rules={[{min: 5}, {max: 20}]}
                disableSubmit
            >
                <Input />
            </RelaxSingleField>
            <RelaxControls 
                formRef = {formRef}
            />
        </div>
    )

}

export const Test_Form_1 = () => {

    const formRef = useRef<any>(null);
    const rd = useRelaxData({});

    return (
        <div>
            <h1>Test_Form_1</h1>
            Multiple fields, manual submit
            <RelaxForm
                relaxData={rd}
                callAPI={patchUserSettings}
                initialValuesSelector={userSettingsTestSelector}
                finalizeSubmit={finalizeSubmit}
                mode="manual"
                //debug={true}
                >
            <RelaxSingleField
                name="fieldA"
                label="Field a"
                rules={[{min: 5}, {max: 20}]}
            >
                <Input />
            </RelaxSingleField>
            <RelaxSingleField
                name="fieldB"
                label="Field b"
                rules={[{min: 5}, {max: 20}]}
            >
                <Input />
            </RelaxSingleField>
            </RelaxForm>
            <RelaxControls 
                relaxData={rd}
            />
        </div>
    )

}






export const Test_Form_PopupOnSuccess = () => {

    const [open, contextHolder] = useSimpleModalWithAction({});

    const rd = useRelaxData({
        onSuccess: (res: any) => {
            open({
                title: "Success",
                content: "Success!"
            })
        },
    });

    return (
        <div>
            <h1>Test_Form_PopupOnSuccess</h1>
            Single field, data as hook, manual submit by passing hook
            <RelaxSingleField
                relaxData={rd}
                name="singleField"
                label="Single Field"
                callAPI={patchUserSettings}
                initialValuesSelector={userSettingsTestSelector}
                finalizeSubmit={finalizeSubmit}
                rules={[{min: 5}, {max: 20}]}
                mode="manual"
                debug={true}
            >
                <Input />
            </RelaxSingleField>
            <RelaxControls 
                relaxData={rd}
            />
            {contextHolder}
        </div>
    )

}

export const Test_Form_List_1 = () => {

    return (
        <div>
            <h1>Test_Form_List_1</h1>
            <RelaxListNew
                name={"list"}
                initialValuesSelector={userSettingsTestSelector}
                callAPI={patchUserSettings}
                initialValues={{ "list": [" "] }}
                elementRules={[ruleMax(30)]}
                rules={[{ noDuplicates: true }, { noEmptyValues: true }, { max: 5 }, { min: 1 }]}
                finalizeSubmit={finalizeSubmit}
                //debug={true}
            >
                <Input />
            </RelaxListNew>
        </div>
    )

}


export const Test_Form_Standalone_Hook = () => {

    const rd = useRelaxData({
        initialValue: 5,
        callAPI: patchUserSettings,
        finalizeSubmit: (val: any) => ({
            settings: {
                testing: {
                    singleField: val
                }
            }
        })
    });

    const handleClick = () => {
        rd.setCurrentValue((cv:any) => cv + 1)
        rd.submit("manual");
    }

    return (
        <div>
            <h1>Test_Form_Standalone_Hook</h1>
           <StandardButton onClick={handleClick}>{rd.currentValue}</StandardButton>
        </div>
    )

}

const routes = [
    {
        path: "/panel/tests/forms/Test_Form_SingleField_1",
        component: Test_Form_SingleField_1,
    },
    {
        path: "/panel/tests/forms/Test_Form_SingleField_2",
        component: Test_Form_SingleField_2,
    },
    {
        path: "/panel/tests/forms/Test_Form_SingleField_ManualSubmit",
        component: Test_Form_SingleField_ManualSubmit,
    },
    {
        path: "/panel/tests/forms/Test_Form_SingleField_4",
        component: Test_Form_SingleField_4,
    },
    {
        path: "/panel/tests/forms/Test_Form_PopupOnSuccess",
        component: Test_Form_PopupOnSuccess,
    },
    {
        path: "/panel/tests/forms/Test_Form_List_1",
        component: Test_Form_List_1,
    },
    
    {
        path: "/panel/tests/forms/Test_Form_1",
        component: Test_Form_1,
    },

    {
        path: "/panel/tests/forms/Test_Form_Standalone_Hook",
        component: Test_Form_Standalone_Hook,
    },
]


export default function FormTesting () {

    return (
        <Switch>
        {routes.map((route, i) => (
            <Route key={i} path={route.path} component={route.component} />
        ))}
        <Route>
            <ul>
                {routes.map((route, i) => (<Link to={route.path}><li>{route.path}</li></Link>))}
            </ul>
        </Route>
        </Switch>
    )

}