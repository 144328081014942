import FormattedMessage from "components/common/FormattedMessage";
import SupportOffer from "./supportOffer";
import Tabs from "_components/tabs/tabs";
import { Invoices } from "../user/invoice/Invoices";
import CompanyInvoicePage from "./CompanyInvoicePage";
import SubscriptionList from "modules/panel/components/user/subscriptions/SubscriptionList";


export default function CompanySettingsPage () {

  const Panes = [
    {
      title:(
        <FormattedMessage
          id={"panel.containers.companySettings.invoiceDetails"}
          defaultMessage={"Invoice Details"}
        />
      ),
      key: "invoiceDetails",
      content: <CompanyInvoicePage />
    },
    {
      title: (
        <FormattedMessage
          id={"panel.companySettings.support"}
          defaultMessage={"Support"}
        />
      ),
      key: "support",
      content: <SupportOffer />
    },
    {
      title:(
        <FormattedMessage
        id={"panel.profile.subscriptions.title"}
        defaultMessage={"Subscriptions"}
      />
      ),
      key: "subscriptions",
      content: <SubscriptionList />
    },
    {
      title:(
        <FormattedMessage
        id={"panel.profile.payment.title"}
        defaultMessage={"Payments"}
      />
      ),
      key: "payments",
      content: <Invoices />
    }
  ]

  return (
    <Tabs 
      panes={Panes}
    />
  )
  
}
