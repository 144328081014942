import React, { PureComponent, useCallback, useEffect, useState, useMemo, useRef, ReactNode } from "react";
import InfinityListQuery from "./infinityListQuery";
import InfiniteScroll from 'react-infinite-scroller';
import { DefaultColumnsPosts, PostListHeaders } from "./infinityTemplates";
import { Grid, Spin, Table } from "antd";
import useDebounce from "services/hooks/useDebounce";
import Post from "models/post";
import { HorizontalCenter } from "components/common/Center";
import InfinityListHeading from "./infinityListHeading";
import { AutoSizer, InfiniteLoader, List } from "react-virtualized";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { PostsLastQuerySelector } from "modules/posts/state/postsSlice";
import "themes/base/components/infinity-list.less";
import { DefaultColumnsInvoices } from "modules/panel/containers/user/invoice/InvoiceHeadings";


export type InfinityProps = {
  headers?: any[];
  items: any[];
  loading?: boolean;
  error?: any;
  itemSkeleton: ReactNode | (() => ReactNode),
  renderItem: (item: any) => ReactNode,
  loadMoreData?: () => void;
  rowHeight?: number;
  additionalItems?: ReactNode[] | ReactNode;
  edgeThreshold?: number;
}

export default function InfinityList(props: InfinityProps) {

  const contentRef = useRef<any>(null);
  const lastQuery = useSelectorWithParams(PostsLastQuerySelector);

  const handleGetMoreData = async () => {
    props.loadMoreData && props.loadMoreData();
  }


  const handleScroll = (e: any) => {

    const { clientHeight: contentHeight, scrollHeight, scrollTop, scrollTopMax } = contentRef.current;

    if (scrollHeight - scrollTop + contentHeight < (props.edgeThreshold || 3000) ) {
      if (!props.loading) handleGetMoreData();
    }
    // if (scrollTop > scrollTopMax - (props.edgeThreshold || 600)) {
    //   if (!props.loading) handleGetMoreData();
    // }

  }

  const isRowLoaded = ({ index }: any) => !!props.items[index];

  const rowRenderer = ({ index, key, style }: any) => {

    let content;

    if (!isRowLoaded({ index })) {
      content = props.itemSkeleton || 'Loading...';
    } else {
      content = props.renderItem(props.items[index]);
    }


    return (
      <div key={props.items[index]?.id || index} style={style} id={`inf-list-elem-${props.items[index]?.id || index}`}>
        {content}
      </div>
    );
  };

  const mappedItems = useMemo(() => {
    if (!props.items) return [];
    if (props.items.length === 0) return [];
    return props.items.map((item: any, index: number) => rowRenderer({ item, index }))

  }, [props.items])

  useEffect(() => {
    if (!lastQuery) return;
    if (!contentRef.current) return;
    const { clientHeight: contentHeight, scrollHeight, scrollTop, scrollTopMax } = contentRef.current;
    if (scrollHeight - scrollTop + contentHeight < (props.edgeThreshold || 3000) ) {
      handleGetMoreData();
    }
  }, [lastQuery]);

  return (
    <>
      <InfinityListHeading
        columns={props.headers || DefaultColumnsPosts }
      />
      <div className="infinity-list"
        ref={contentRef}
        onWheel={handleScroll}
        onTouchMove={handleScroll}
      >
        {(!props.items || props.items.length === 0 && props.loading) && <HorizontalCenter style={{ padding: "40px" }}><Spin size="large" /></HorizontalCenter>}
        {mappedItems}
        {props.additionalItems}
      </div>
    </>
  )
}