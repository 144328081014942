import { useEffect, useMemo } from "react"
import FormattedMessage from "components/common/FormattedMessage";
import { useCreationEditor } from "tools/creationEditor/CreationEditor"
import './asset-preview.less'
import useAPI from "services/hooks/useAPI";
import { Creation, getCreationPreview } from "tools/creationEditor/creationsSlice";
import { HorizontalCenter } from "components/common/Center";
import { Spin } from "antd";

const isOlderThan24Hours = (generationTime:string) => {
    if (!generationTime) return false;
    try {
        const previewGenerationTime = new Date(generationTime)
        const now = new Date()
        const isOlderThan24Hours = now.getTime() - previewGenerationTime.getTime() > 24 * 60 * 60 * 1000
        return isOlderThan24Hours
    } catch (error) {
        return true;
    }
    return false;
}

export const InstagramPostPreview = ({creation}:{creation: Creation}) => {

    const previewUpdateCall = useAPI(getCreationPreview(creation?.id))


    const previewUrl = useMemo(() => {
        if (!creation) return null;
        if (!creation.preview) return null;
        //replace "&amp;t=" with "&t=" 
        if (creation.preview === "disabled") return null;
        return creation.preview.replace("&amp;t=", "&t=")
    }, [creation])


    useEffect(() => {

        if (!creation) return;
        if (previewUpdateCall.loading) return;
        if (previewUpdateCall.error) return;
        if (previewUpdateCall.data) return;
        if (!creation.preview || creation.preview === "") {
            previewUpdateCall.call();
            return;
        }

        if (!creation.preview_generation_time) {
            previewUpdateCall.call()
            return;
        }

        if (isOlderThan24Hours(creation.preview_generation_time)) {
            previewUpdateCall.call()
            return;
        }
       
        //more than 30 seconds have passed since creation.updated_at
        if (creation.preview === "disabled") {

            if (new Date().getTime() - new Date(creation.updated_at).getTime() > 30 * 1000) {
                previewUpdateCall.call()
                return;
            } else {
                setTimeout(() => {
                    previewUpdateCall.call()
                }, 15000);
                return;
            }
        }

        return;

    }, [creation, previewUpdateCall.loading])

    if (!previewUrl || previewUrl === "") return (
        <div style={{
            paddingTop: "30px",
            paddingLeft: "30px",
            paddingRight: "30px",
        }}>
                <FormattedMessage
                    id="components.postPreview.facebook.noPreview"
                    defaultMessage="Fill out missing data to generate preview"
                    />
        </div>
    )
    
    if (creation.preview === "disabled") return (
        <div style={{
            paddingTop: "30px",
            paddingLeft: "30px",
            paddingRight: "30px",
        }}>
            <HorizontalCenter>
                <Spin />
            </HorizontalCenter>
        </div>
    )

    return (
        <div className="instagram-post-preview">
            <iframe 
                scrolling="no"
                src={previewUrl}
                seamless={true}
            />
        </div>
    )
}

