import { Input, Select } from "antd";
import { useEffect, useMemo } from "react";
import useAPI, { callAPIProps } from "services/hooks/useAPI";

export default function FacebookLocaleLanguageSelect(props:any) {


    const {data: allLangs, loading, error} = useAPI({
        url: ({ getApiUrl }) => getApiUrl(`services/facebook/locales`),
    },true)

    const mappedLangs = useMemo(() => allLangs && allLangs.map((l:any) => <Select.Option key={l.id} value={l.id}>{l.name}</Select.Option> ), [allLangs])

    if (loading || error) {
        return <div></div>
    }

    return (
        <Select 
        {...props}
        value = {props.value || []}
        showSearch
        mode = "multiple"
        onChange={(value:any) => {
            props.onChange && props.onChange(value)
            props.onBlur && props.onBlur()
        }}
        filterOption={(input, option:any) => option && option.children.toLowerCase().includes(input.toLowerCase())}
        >
            {mappedLangs}
        </Select>
    )

}