import { Input } from "antd";
import { useEffect, useState } from "react";
import Tabs from "_components/tabs/tabs";
import TextEditor from "./textEditor";

export type HtmlEditorType = {
  value?: any;
  onChange?: any;
  onBlur?: any;
};

export default function HtmlEditor(props: HtmlEditorType) {
  const [value, setValue] = useState(props.value || "");
  const [updatedValue, setUpdatedValue] = useState(props.value || "");

  const debounceTimeout = 500;

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (props.onBlur) {
        props.onBlur();
      }
    }, debounceTimeout);

    return () => {
      clearTimeout(handler);
    };
  }, [value]); 

  const handleEditorChange = (c: string) => {
    setValue(c);
    if (props.onChange) props.onChange(c);
    
  };

  const handleCodeChange = (c: any) => {
    if (c?.target?.value) {
      setValue(c.target.value);
      if (props.onChange) props.onChange(c.target.value);
    }
  };

  return (
    <Tabs
      onChange={(value)=>setUpdatedValue(value)}
      panes={[
        {
          title: "Editor",
          key: "editor",
          forceRender: true,
          content: (
            <TextEditor
              value={value}
              updatedValue={updatedValue}
              onChange={handleEditorChange}
              toolbar={{}}
            />
          ),
        },
        {
          title: "Code",
          key: "code",
          forceRender: true,
          content: (
            <Input.TextArea
              style={{ height: "500px" }}
              value={value}
              onChange={handleCodeChange}
            />
          ),
        },
      ]}
    />
  );
}
