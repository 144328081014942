import { Spin } from "antd";
import { HorizontalCenter } from "components/common/Center";
import { ReactNode, Suspense, useEffect, useState } from "react";
import { useCreationEditor } from "../CreationEditor";
import { Creation } from "../creationsSlice";

export type EditorStage = {
    name: string;
    content: ReactNode;
    fulfilled: (creation: Creation) => boolean;
}

type EditorWithStagesProps = {
    stages: EditorStage[];
}

export default function EditorWithStages(props: EditorWithStagesProps) {


    const { creation } = useCreationEditor();
    const [currentStage, setCurrentStage] = useState<number | null>(null);

    const findNextStage = () => {
        if (!props.stages || props.stages.length === 0) return;
        if (currentStage === props.stages.length - 1) return;
        const stage = props.stages.findIndex(s => !s.fulfilled(creation));
        if (!props.stages[stage]) setCurrentStage(props.stages.length - 1);
        setCurrentStage(stage);
    }

    useEffect(() => {
        findNextStage();
    }, [creation, currentStage]);


    if (currentStage === null) return null;
    if (!props.stages[currentStage]) return null;


    return (
        <Suspense fallback={<HorizontalCenter><Spin /></HorizontalCenter>}>
            {props.stages[currentStage].content}
        </Suspense>
    )

}