import React, { Component } from "react";
import { Radio } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
class YesNoInput extends Component {
    render() {
        return (
            <Radio.Group
                {...this.props}
                className="fullwidth-radio">
                <Radio.Button value="yes">
                    <FormattedMessage
                        id="components.forms.inputs.yesNo.yes"
                        defaultMessage="Yes"
                    />
                </Radio.Button>
                <Radio.Button value="no">
                    <FormattedMessage
                        id="components.forms.inputs.yesNo.no"
                        defaultMessage="No"
                    />
                </Radio.Button>
            </Radio.Group>
        )
    }
}

export default YesNoInput;