import { useEffect } from "react"
import useSelectorWithParams from "services/hooks/useSelectorWithParams"
import OrganizationTable from "./components/OrganizationTable"
import OrganizationTableManagers from "./components/OrganizationTableManagers"
import OrganizationTools from "./components/OrgannizationTools"
import { OrganizationManagersSelector, PatchGroup } from "./data/organizationSlice"
import { toolsGroup, useOrganizationTool } from "./OrganizationManager"
import { OrganizationToolAssignManagersToGroup } from "./tools/OrganizationToolForms"

const managersTool:toolsGroup = {
    key: "managerTools",
    label: "Managers",
    tools: [
        {
            key: "assignToGroup",
            label: "Assign to group",
            title: "Assign managers to group",
            component: <OrganizationToolAssignManagersToGroup />,
            toolAPICall: (inputData: any, outputData: any) => {
                return {
                    ...PatchGroup(outputData.groupId),
                    body: {
                        managers: inputData.managers,
                        name: outputData.name
                    }
                }
            }
        },  
    ]
}


export default function OrganizationManagersTab (props:any) {

    const {setInputData} = useOrganizationTool();

    return (
        <>
            <OrganizationTools toolset={managersTool}/>
            <OrganizationTableManagers onSelectedRows={(rows: string[]) => setInputData((i:any) => ({...i, managers: rows}))}/>
        </>
    )
}