import React, { FunctionComponent } from "react";
import {
  Layout,
  Config,
} from "providers/routing/layouts/templates/templates";
import { RouteConfig } from "providers/routing/routes/routes";
import ProductLogo from "providers/routing/layouts/components/product-logo/current-product-logo";

export type BlankLayoutRouteConfig = {
  layout: Layout.Blank;
} & RouteConfig;

const BlankLayout: FunctionComponent = ({ children }) => (
  <div className="blank-page-layout">
    <div className="flex-centered">
     <ProductLogo/>
      <div className="main-wrapper">{children}</div>
    </div>
  </div>
);

export default {
  key: Layout.Blank,
  layout: BlankLayout,
} as Config;
