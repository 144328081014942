import { Crop } from "react-image-crop";
import FormattedMessage from "components/common/FormattedMessage";
import { FileRequirements, CropPresets, CropPreset, GalleryItem } from "./Gallery";

export const CheckSizeAgainstPreset = ({ width, height }: { width: number, height: number }, preset: CropPreset) => {
    //console.log("PRESET_CHECK: Checking if ", width, height, "fits", preset);
    const { minHeight, minWidth, maxHeight, maxWidth } = preset;
    if (minHeight && minHeight !== 0 && height < minHeight) return (false);
    if (minWidth && minWidth !== 0 && width < minWidth) return (false);
    if (maxHeight && maxHeight !== 0 && height > maxHeight) return (false);
    if (maxWidth && maxWidth !== 0 && width > maxWidth) return (false);
    return true;
}

const CheckSizeAgainstPlatformPreset = ({ width, height }: { width: number, height: number }, platformPresets: CropPreset[]) => {
    //console.log("PLATFORM_CHECK: Checking if ", width, height, "fits ", platformPresets);
    let fitsAtLeastOnePreset = false;
    platformPresets && platformPresets.forEach && platformPresets.forEach(preset => {
        const fitsPreset = CheckSizeAgainstPreset({ width, height }, preset);
        if (fitsPreset) fitsAtLeastOnePreset = true;
    })
    return fitsAtLeastOnePreset;
}

export const CheckSizeAgainstPresets = ({ width, height }: { width: number, height: number }, presets: CropPresets) => {
    let fitsAsLeastOnePresetPerPlatform = true;
    if (!presets) return true;
    for (const platform in presets) {
        const isAllowedOnThisPlatform = CheckSizeAgainstPlatformPreset({ height, width }, presets[platform]);
        if (!isAllowedOnThisPlatform) fitsAsLeastOnePresetPerPlatform = false;
        //  console.log("Platform", platform, isAllowedOnThisPlatform ? "OK" : "NOT OK");
    }
    return fitsAsLeastOnePresetPerPlatform;
}

export const ReadImageAndCheckSize = (file: any, fileRequirements?: FileRequirements, CropRatiosByPlatform?: CropPresets, rejectCallback?: any) => {


    return new Promise((resolve, reject) => {

        const wrongImage = (error: string, extra?: {}) => {
            if (rejectCallback) rejectCallback({
                error,
                extra: extra || {}
            });
            reject(false);
        }


        if (!file) return wrongImage("NO_FILE", "No file provided");
        if (fileRequirements) {
            if (fileRequirements.minFileSize && fileRequirements.minFileSize > 0 && file.size < fileRequirements.minFileSize) return wrongImage("FILE_TOO_SMALL", {
                minFileSize: fileRequirements.minFileSize,
                fileSize: file.size
            });
            if (fileRequirements.maxFileSize && fileRequirements.maxFileSize > 0 && file.size > fileRequirements.maxFileSize) return wrongImage("FILE_TOO_BIG", {
                maxFileSize: fileRequirements.maxFileSize,
                fileSize: file.size
            });
        }


        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const i = new Image();
            (i.src as any) = reader.result;
            i.onload = function () {
                const { height, width } = i;
                if (!CropRatiosByPlatform) resolve(true);
                const allowed = CheckSizeAgainstPresets({ height, width }, CropRatiosByPlatform!);
                if (allowed) {
                    //console.log("IMG FITS");
                    return resolve({ height, width });
                } else {
                    // console.log("IMG DOESN FIT");
                    return wrongImage("WRONG_RESOLUTION", {
                        height,
                        width
                    })
                }
            }
        }
        reader.onerror = (e) => {
            return wrongImage("FILE_ERROR", {})
        }
    })

}

//TODO: filter to find first unfilled preset.
//Compares crops that images has with preset platforms and finds out the first one that is missing. 
export const GetFirstUnsetCrop = (img: GalleryItem, presets: CropPresets): [string | boolean, any?, any?] => {


    if (!img) return [false];
    if (!presets || !Object.keys(presets).length) return [false];


    if (img?.crop?.default?.ratio === "none") {
        //console.log("none crop, removing")
        delete img.crop;
    }


    if (!img.crop) {
        const platform = Object.keys(presets)[0];
        return [platform, undefined, presets[platform][0]]
    }

    if (img.crop) {


        const availablePlatforms = Object.keys(presets);
        let platform;
        let crop: any;

        //check if for some reason existing crop doesnt match available platforms. 

        if (availablePlatforms.includes(Object.keys(img.crop)[0])) {
            platform = Object.keys(img.crop)[0];
            crop = img.crop[platform];
            return [platform, crop, presets[platform].find(c => c.ratio === crop.ratio)]
        } else {
            platform = availablePlatforms[0];
            return [platform, undefined, presets[platform][0]]
        }

    }

    return [false];

}

export const presetAbsoluteToRelative = (image: any, preset: any) => {
    const { clientWidth, clientHeight, naturalHeight, naturalWidth } = image;

    const heightRatio = clientHeight / naturalHeight;
    const widthRatio = clientWidth / naturalWidth;

    const res: any = { ...preset };

    if (preset.minWidth) res.minWidth = preset.minWidth * widthRatio;
    if (preset.maxWidth) res.maxWidth = preset.maxWidth * widthRatio;
    if (preset.minHeight) res.minHeight = preset.minHeight * heightRatio;
    if (preset.maxHeight) res.maxHeight = preset.maxHeight * heightRatio;

    res.scope = "relative"
    res.ratio = preset.ratio;

    return res;

}

export const cropAbsoluteToRelative = (image: any, crop: any) => {


    const { clientWidth, clientHeight, naturalHeight, naturalWidth } = image;
    const heightRatio = clientHeight / naturalHeight;
    const widthRatio = clientWidth / naturalWidth;

    const res: any = {};

    res.height = crop.height * heightRatio;
    res.width = crop.width * widthRatio;
    res.x = crop.x * widthRatio;
    res.y = crop.y * heightRatio;

    res.unit = crop.unit;

    res.scope = "relative"
    res.ratio = crop.ratio;

    return res;
}

export const cropRelativeToAbsolute = (image: any, crop: any) => {

    if (crop.scope === "absolute") return crop;

    const { clientWidth, clientHeight, naturalHeight, naturalWidth } = image;
    const heightRatio = clientHeight / naturalHeight;
    const widthRatio = clientWidth / naturalWidth;

    const res: any = {};

    res.height = crop.height / heightRatio;
    res.width = crop.width / widthRatio;
    res.x = crop.x / widthRatio;
    res.y = crop.y / heightRatio;

    res.unit = crop.unit;

    res.scope = "absolute"
    res.ratio = crop.ratio;

    return res;
}

export const absoluteToRelative = (image: any, options: any) => {
    //  console.log("Absolute to relative")

    const { clientWidth, clientHeight, naturalHeight, naturalWidth } = image;
    const heightRatio = clientHeight / naturalHeight;
    const widthRatio = clientWidth / naturalWidth;

    const res: any = {};

    const { minWidth, maxWidth, minHeight, maxHeight } = options;

    if (minWidth !== undefined) res.minWidth = Math.ceil(minWidth * widthRatio);
    if (maxWidth !== undefined) res.maxWidth = Math.ceil(maxWidth * widthRatio);
    if (minHeight !== undefined) res.minHeight = Math.ceil(minHeight * heightRatio);
    if (maxHeight !== undefined) res.maxHeight = Math.ceil(maxHeight * heightRatio);


    res.scope = "relative"

    return res;

}


export const RoundedClamp = (value: number, min?: number, max?: number) => {
    return Math.min(Math.max(value, min || 0), max || 100000000);
}

export const RoundAndClampCrop = (crop: Crop, width: number, height: number, preset: CropPreset) => {

    if (crop.x === undefined || crop.y === undefined || crop.width === undefined || crop.height === undefined) return crop;

    return {
        ...crop,
        x: RoundedClamp(crop.x!, 0, width),
        y: RoundedClamp(crop.y!, 0, height),
        width: RoundedClamp(crop.width!, preset.minWidth, preset.maxWidth),
        height: RoundedClamp(crop.height!, preset.minHeight, preset.maxHeight),
    }
}

export const ImageAsGalleryItem = (item: any): GalleryItem | null => {

    if (!item) return null;
    if (!item.url) return null;

    return {
        url: item.url,
        type: "image",
        crop: item.crop
    }
}

export const GetNumberOfPlatforms = (cropPresets: CropPresets) => {
    return Object.keys(cropPresets).length;
}

export const GetPlatformsFromCropPresets = (cropPresets: CropPresets) => {
    return Object.keys(cropPresets);
}

export const HasCropForPlatform = (platform: string, image: GalleryItem) => {
    return image.crop && image.crop[platform];
}

export const HasCropForAllPlatforms = (image: GalleryItem, cropPresets: CropPresets) => {
    const platforms = GetPlatformsFromCropPresets(cropPresets);
    return platforms.every(p => HasCropForPlatform(p, image));
}

export const GalleryFormValidator_HasCropForAllPlatforms = (cropPresets: CropPresets) => {
    return (image: GalleryItem, name: any, form: any) => {
        const hasCrops = HasCropForAllPlatforms(image, cropPresets);
        if (hasCrops) {
            return {
                status: "error",
                message: <FormattedMessage
                    id="forms.validation.gallery.cropsRequired"
                    defaultMessage="Items are not cropped."
                />
            }
        }
        return null;
    }
}

export const GetFileType = (file: any) => {
    if (file.type) {
        return file.type.split("/")[0];
    }
    return "unknown";
}

export const getUnsetProviders = (image: GalleryItem, cropPresets: CropPresets) => {
    const platforms = GetPlatformsFromCropPresets(cropPresets);
    const unsetPlatforms = platforms.filter(p => !HasCropForPlatform(p, image));
    return unsetPlatforms;
}


export const CanImageBeSplitToSlides = (image: { height: number, width: number }, numberOfSlides: number) => {
    const minSize = 600;
    if (image.height < minSize) return false;
    const minSpacing = 18;

    const minWidth = numberOfSlides * minSize + (numberOfSlides - 1) * minSpacing;
    return image.width >= minWidth;
}

export const AbsoluteCropToSlides = (crop: {height: number, width: number}) => {

    const width = crop.width;
    const height = crop.height;

    const minSize = 600;
    const minSpacing = 18;

    if (height < minSize) return null;
    if (width < minSize * 2 + minSpacing) return null;

    const slideSize = height;
    const slideSpacing = Math.max(0, Math.floor((slideSize / minSize) * minSpacing));
    const widthNeededForNSlides = (n: number) => n * slideSize + (n - 1) * slideSpacing;

    let maxNumberOfSlides = -1;

    if (width >= widthNeededForNSlides(5)) maxNumberOfSlides = 5;
    else if (width >= widthNeededForNSlides(4)) maxNumberOfSlides = 4;
    else if (width >= widthNeededForNSlides(3)) maxNumberOfSlides = 3;
    else if (width >= widthNeededForNSlides(2)) maxNumberOfSlides = 2;

    if (maxNumberOfSlides === -1) return null;

    return maxNumberOfSlides;


}