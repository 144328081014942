import { Result, Spin  } from 'antd';
import {HorizontalCenter} from 'components/common/Center';
import _ from 'lodash';
import { userIdSelector, userLanguageSelector } from 'modules/panel/config/selectors/user';
import {useEffect, useState, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getApiUrl, serializeObjectToURLParameters } from 'services/helpers/api-helpers';
import { normalizeObject } from 'services/helpers/JSONAPI-helpers';
import { getAuthorizationHeadersFromStorage } from 'services/helpers/local-storage';
import normalizer from "services/store/middlewares/api/json-api-normalizer/normalizer";
import useAPISmartActions from './useAPISmartActions';
import FormattedMessage from 'components/common/FormattedMessage';
import { useFeedback } from 'contexts/FeedbackContext';
import { CurrentAdsSettingsIdSelector } from 'modules/smart/smartSlice';
import { currentProjectIdSelector } from 'services/store/scopeSlice';
import useSelectorWithParams from './useSelectorWithParams';
import { SentryCaptureError } from 'services/sentry';

export type callAPIProps = {
    url?: string | ((params: urlFunctionParams) => string);
    dispatchName?: string;
    requestDispatch?: (...args: any[]) => void;
    successDispatch?: (...args: any[]) => void;
    failureDispatch?: (...args: any[]) => void;
    actionName?: string;
    method?: string | ((params: any, body: any) => string);
    hideNotifications?:boolean;
    auth?: boolean;
    body?: any;
    addToBody?: any;
    adjustBody?: (body:any) => any;
    //Clears current data while waiting for new one. 
    clearDataOnCall?: boolean;
    //Clears error while waiting for new one.1
    clearErrorOnCall?: boolean;
    updateData?: any;
    addToResult?: any;
    passToDispatcher?: any;
    adjustResponse?: (normalizedData: any, responseData: any, normalizer: (data:any)=>any) => any;
    customNormalizer?: (...args: any[]) => any;
    fakeResponse?: (request: any) => any;
    onSuccess?: (response: any, additional: any, request:any) => void;
    onFailure?: (response: any, additional: any, request:any) => void;
}

export type callAPIFunction = (...args: any[]) => callAPIProps;


export type callAPISettings = callAPIProps | callAPIFunction;

export const getCallAPIProps  = (cs: callAPISettings, ...params: any[]): callAPIProps => {
    if (typeof cs === "function") {
        return cs(...params);
    } 
    return cs;
}

export type urlFunctionParams = {
    getApiUrl: (url: string, version?: number) => string;
    projectId: number;
    userId: number;
    language: string;
    serializeQuery: any;
    smart?: number;
}

export default function useAPI(props: callAPIProps, callInstantly=false) {

    const {registerAction, resolveAction, getAction} = useAPISmartActions();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any>(undefined);
    const [error, setError] = useState<any>(undefined);
    const [jsonData, setJsonData] = useState<any>(undefined);

    const projectId = useSelectorWithParams(currentProjectIdSelector);
    const userId = useSelector(userIdSelector);
    const language = useSelector(userLanguageSelector);
    const smart = useSelector(CurrentAdsSettingsIdSelector);

    const {notifications} = useFeedback();

    const dispatch = useDispatch();

    useEffect(() => {
      if (callInstantly) {
          callAPI();
      }
    }, [])

    const StatusDisplay = useMemo(() => {
        //console.log("StatusDisplay", loading, data, error)
        if (loading) return <HorizontalCenter style={{padding: "20px"}}><Spin size="large" /></HorizontalCenter>
        if (error) return <HorizontalCenter style={{padding: "20px"}}><Result status="warning" title="Error Occured"/></HorizontalCenter>
        return <></>
    }, [loading, data, error]);

    const defaultProps = {
        method: "GET",
        auth: true,

    }

    const getUrl = (url: any, body:any) => {
        if (typeof url === "string") return url;
        if (typeof url === "function") return url({
                projectId: projectId,
                userId: userId,
                getApiUrl: getApiUrl,
                language: language,
                serializeQuery: serializeObjectToURLParameters,
                smart: smart
            });
    }

    const getMethod = (method: any, body: any) => {
        if (typeof method === "string") return method;
        if (typeof method === "function") return method({},body);
        return "GET"
    }

    const call = async (cp?: callAPISettings) => {
        if (!cp) return await callAPI();

        let callProps:callAPIProps = {};

        if (typeof cp === "function") {
            callProps = cp();
        } else {
            callProps = cp;
        }
            
        return await callAPI(callProps);
    }

    const clear = () => {
        setData(undefined);
        setError(undefined);
        setJsonData(undefined);
    }

    const updateData = (newData:any) => {
        setData(newData);
    }


    const normalizeResponseData = (options: any, responseData: any) => {
        try {
            let norm:any;
            const normalizerFunction = options.customNormalizer || normalizer;
            const normalizedData = normalizerFunction({...responseData});
            if (options && options.adjustResponse) {
                norm = options.adjustResponse(normalizedData, responseData, normalizer);
            } else {
                norm = normalizedData;
            }
            return norm;
        } catch (e) {
            console.log("Error while normalizing API response", e)
            return responseData;
        } 
    }


    const prepareCall = (callProps?: callAPIProps):{
        promise: Promise<any> | null
        options: any,
        preStringBody: any,
    } | null => {

        const options:any = {
            ...defaultProps,
            ...props,
            ...callProps
        }

        if (!options.url) return null;
        const url = getUrl(options.url, options.body);
        options.method = getMethod(options.method, options.body);

        if (options.auth) options.headers = getAuthorizationHeadersFromStorage();

        if (options.addToBody && typeof options.body === "object") {
            options.body = {...options.body, ...options.addToBody};
        }

        if (options.adjustBody) options.body = options.adjustBody(options.body);
        
        const preStringBody = options.body;
        if (typeof options.body === "object") options.body = JSON.stringify(options.body);


        if (options.clearDataOnCall) {
            setData(undefined);
            setJsonData(undefined);
        }
        if (options.clearErrorOnCall) setError(undefined);

        setLoading(true);

        if (options.dispatchName) dispatch({type: options.dispatchName+"_REQUEST"});
       // if (options.actionName) registerAction(options.actionName);
    
        let promise:Promise<any> | null = null;
        let alreadyWorking = false;

        if (options.actionName) {
            const action = getAction(options.actionName);
            //console.log(options.actionName + " found", action)
            if (action && action.state === "pending" && action.actionCall) {
                //console.log(options.actionName + " valid")
                promise = action.actionCall;
                alreadyWorking = true;
            } else {
                //console.log(options.actionName + " not valid")
            }
            
        } 

        if (promise) {
           //console.log(options.actionName + " promise was loaded from action", promise);
        } else {
           //console.log(options.actionName + " starting new promise")
            if (options.fakeResponse) {
                promise = options.fakeResponse({url:url, options});
            } else {
                promise = fetch(url, options);
            }

        }

        if (options.actionName && !alreadyWorking) {
            registerAction(options.actionName, promise);
        } else {
            //console.log(options.actionName + " already working")
        }

        return {
            promise: promise,
            options: options,
            preStringBody: preStringBody,
        };

    }

    const callAPI = async (callProps?: callAPIProps) => {

        const preparedCall = prepareCall(callProps);

        if (!preparedCall) return;
        const {promise, options, preStringBody} = preparedCall;

        if (options.requestDispatch) {
            dispatch(options.requestDispatch({
                additional: options.passToDispatcher, 
                ...preStringBody,
            }))
        }

        const response = await promise;

        const result:any = {};
        result.status = response.status;
        result.additional = options.addToResult;
        result.body = preStringBody;

        const additionals = {
            projectId: projectId,
            userId: userId,
        }

        if (response.status === 204) {
            setLoading(false);
            setError(undefined);
            setData(undefined);
            if (options.dispatchName) dispatch({
                type: options.dispatchName+"_SUCCESS", 
                payload: {}, 
                additional: options.passToDispatcher,
                request: preStringBody,
                ...additionals,
            });
            if (options.successDispatch) {
                dispatch(options.successDispatch({
                    additional: options.passToDispatcher, 
                    request: preStringBody,
                    ...additionals}))
            }
            if (options.actionName) resolveAction(options.actionName, "success", {});
            options.onSuccess && options.onSuccess({}, options.passToDispatcher, preStringBody);
            return result;
        }

        let resp:any;

        try {
            resp = await response.json();
        } catch (e) {
            //console.log("Error while parsing response", e);
            resp = undefined;
        }


        //await delay(2000);

        setLoading(false);

        if (resp) {

            let normalizedData:any;

            if ((response.status == 200 || response.status == 201) && resp != undefined) {
                setJsonData(resp);
                normalizedData = normalizeResponseData(options, resp)
                const included = resp.included ? resp.included.map((i:any) => normalizeObject(i)) : undefined;
                result.data = normalizedData;
                result.included = included;
                setData(normalizedData);
                setError(undefined);               
                if (options.dispatchName) 
                    dispatch({
                        type: options.dispatchName+"_SUCCESS", 
                        included: included,
                        payload: normalizedData, 
                        additional: options.passToDispatcher, 
                        request: preStringBody,
                        ...additionals});
                if (options.successDispatch) {
                    dispatch(options.successDispatch({
                        included: included,
                        data: normalizedData, 
                        additional: options.passToDispatcher, 
                        request: preStringBody,
                        ...additionals}))
                }
                if (options.actionName) resolveAction(options.actionName, "success", normalizedData);
                options.onSuccess && options.onSuccess(normalizedData, options.passToDispatcher, preStringBody);
                
            } else {
                
            if (response.status === 500) {
                SentryCaptureError({
                    message: "API 500 error",
                    response: response,
                    request: options,
                }, {
                    message: "API 500 error",
                    response: response,
                    request: options,
                })
            }

            if(options.hideNotifications !== true) {
                if (response.status === 401) {

                    notifications && notifications.open && notifications.open({
                        key: "api-401",
                        message: <FormattedMessage
                                id="services.api.errors.401.title"
                                defaultMessage="There is a problem with your account"
                        />,
                        description: <FormattedMessage
                                id="services.api.errors.401.description"
                                defaultMessage="You are not authorized to perform this action. Please log in again."
                            />,
                        duration: 5,
                    })
        
                }

                if (response.status === 500) {

                    notifications && notifications.open && notifications.open({
                        key: "api-500",
                        message: <FormattedMessage
                                id="services.api.errors.500.title"
                                defaultMessage="Something went wrong"
                                />,
                        description: <FormattedMessage
                                id="services.api.errors.500.description"
                                defaultMessage="There was an error while processing your request. Please try again later."
                                />,
                        duration: 5,
                    })
        
                }
            }
        
                
                normalizedData = {exception: resp.exception, errors: resp.errors, error: resp.error}
                result.error = normalizedData;

                setError(normalizedData);
                setData(undefined);
                if (options.dispatchName) 
                    dispatch({
                        type: options.dispatchName+"_FAILURE", 
                        payload: normalizedData, 
                        additional: options.passToDispatcher, 
                        request: options.body,
                        ...additionals});
                if (options.failureDispatch) {
                    dispatch(options.failureDispatch({
                        data: normalizedData, 
                        additional: options.passToDispatcher, 
                        request: options.body,
                        ...additionals}))
                }
                if (options.actionName) resolveAction(options.actionName, "error", normalizedData);
                options.onSuccess && options.onSuccess(normalizedData, options.passToDispatcher, preStringBody);
            }

            return result;

        } else {

            if (response.status === 500) {


                // notifications.open({
                //     key: "api-500",
                //     message: <FormattedMessage
                //             id="services.api.errors.500.title"
                //             defaultMessage="Something went wrong"
                //             />,
                //     description: <FormattedMessage
                //             id="services.api.errors.500.description"
                //             defaultMessage="There was an error while processing your request. Please try again later."
                //             />,
                //     duration: 5,
                // })
    
            }

            setError("Unknown error");
            setData(undefined);
            if (options.dispatchName) dispatch({type: options.dispatchName+"_FAILURE", payload: {}});
            if (options.actionName) resolveAction(options.actionName, "error", {});
            options.onFailure && options.onFailure({}, options.passToDispatcher, preStringBody);
        }

        return result;

    }

    return {
        loading, 
        data, 
        error,
        call,
        clear,
        updateData,
        jsonData,
        StatusDisplay
    }

}

export const APIErrorDisplay = (props:any )=> {

    if (!props.error) return null;
    if (typeof props.error === "string") return props.error;
    if (props.error.error) {
        if (typeof props.error.error === "string") return props.error.error;
        if (props.error.error.message) return props.error.error.message;
    }
    if (props.error.errors) {
        return props.error.errors.map && props.error.errors.map((e:any) => {
            if (typeof e === "string") return e
            if (typeof e === "object") {
                if (e.message) return e.message;
                if (e.detail) return e.detail;
                if (e.title) return e.title;
                return JSON.stringify(e);
            }
        })
    }
    if (props.error.exception) return props.error.exception;
    return <div>UnknownError</div>
}

export const ConvertErrorsToMapOfFields = (errors:any) => {
    try {
        if (!errors) return null;
        const result:any = {};
        errors.forEach && errors.forEach((e:any) => {
            if (!e) return;
            const msg = e.detail;
            const pointer:string = e?.source?.pointer;
            const field = pointer?.split("/").pop();
            if (field) {
                result[field] = msg || "unknown error";
            }
        })
        return result;
    } catch (e) {
        console.error("Error while converting errors", e);
    } 
    return null
}