import { Popconfirm } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import { DeleteBot } from "modules/bot/containers/BotsSlice"
import { ReactNode } from "react";
import useAPI from "services/hooks/useAPI"

type Props = {
  botId: any;
  children?: ReactNode;
  close?: any
}

export const DeleteBotBtn = ({ botId, children, close }: Props) => {

  const deleteBot = useAPI(DeleteBot(botId))

  const handleConfirm = () => {
    deleteBot.call(botId)
    close
  };

  return (
    <Popconfirm
      title={
        <FormattedMessage
          id="modules.bot.containers.list.doYouReallyWantToDelete"
          defaultMessage="Delete bot"
        />
      }
      placement="top"
      onConfirm={handleConfirm}
    >
      {children || (
        <a>
          <FormattedMessage
            id="modules.bot.containers.list.delete"
            defaultMessage="Remove bot"
          />
        </a>
      )}
    </Popconfirm>
  );
} 