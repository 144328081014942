import { Col, Row, Spin } from "antd";
import { HorizontalCenter } from "components/common/Center";
import FormattedMessage from "components/common/FormattedMessage";
import BudgetInput from "components/inputs/BudgetInput";
import CurrencySelect from "modules/forsant/components/currecy-select";
import ConnectedOnboardingControls from "providers/onboarding/controls";
import { callAPIProps } from "services/hooks/useAPI";
import useDataPrefill from "services/hooks/useDataPrefill";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import RelaxField from "tools/relaxForm/RelaxField";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";
import { GoogleSmartSettingsSelector, HasFilledBudgetSelector, smartSlice } from "modules/smart/smartSlice";


export const OnboardingBudget = () => {
  const fulfilled = useSelectorWithParams(HasFilledBudgetSelector);

  const UpdateForsantBudget: callAPIProps = {
    url: ({ getApiUrl, projectId, smart }) =>getApiUrl(`projects/${projectId}/smarts/${smart}/google-smart-settings`, 2),
    method: "PATCH",
    successDispatch: smartSlice.actions.updateGoogleSmartSettings,
  }
  const {loading} = useDataPrefill({
    dataSelector: GoogleSmartSettingsSelector,
    callAPI: UpdateForsantBudget,
    checkFunction: (data: any) => {
      if (!data) return false;

      let newData:{
        general_budget?: number,
        currency?: string
      } = {};

      if (data.general_budget === 0) newData.general_budget = 20
      if (data.currency === null) newData.currency = "PLN"

      if (Object.keys(newData).length > 0) {
        return newData;
      }

      return false;
    }
  })

  if (loading) return <HorizontalCenter><Spin /></HorizontalCenter>

  return (
    <>
      <RelaxMultiform
        callAPI={UpdateForsantBudget}
        initialValuesSelector={GoogleSmartSettingsSelector}
      >
        <Row gutter={30}>
          <Col xs={24} md={12}>
            <RelaxField
              name="general_budget"
              required={true}
              label={
                <FormattedMessage
                  id="apps.forsant.budget"
                  defaultMessage="Daily budget for each campaign"
                />
              }
            >
              <BudgetInput style={{ width: "100%" }} />
            </RelaxField>
          </Col>
          <Col xs={24} md={12}>
            <RelaxField
              name="currency"
              required={true}
              label={
                <FormattedMessage
                  id="apps.forsant.currency"
                  defaultMessage="Currency"
                />
              }
            >
              <CurrencySelect style={{ width: "100%" }} />
            </RelaxField>
          </Col>
        </Row>
      </RelaxMultiform>
      <ConnectedOnboardingControls nextDisabled={!fulfilled} />
    </>
  );
};
