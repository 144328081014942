import {createSlice, createSelector} from '@reduxjs/toolkit';
import { LOAD_USER_SUCCESS } from 'modules/panel/actions/user/login';
import { callAPIProps } from 'services/hooks/useAPI';
import { currentProjectIdSelector } from 'services/store/scopeSlice';


// export const ProjectCategoriesSelector = createSelector([
//     (state:any) => state.siteGenerator.categories,
//     currentProjectIdSelector
// ],
// (state:any, projectId:any) => state[projectId]


export type WebsiteObjectState = any;

const initialState: WebsiteObjectState = {

}


export const websiteObjectSlice = createSlice({
    name: "websiteObject",
    initialState, 
    reducers: {
        get: (state, action) => {
            state[action.payload.projectId] = action.payload.data;
        },
        update: (state, action) => {
            state[action.payload.projectId] = action.payload.data;      
        }
    },
    extraReducers: {
        [LOAD_USER_SUCCESS]: (state, action) => {
            if (!action?.payload?.websites) return; 
            for (const [key, value] of Object.entries(action.payload.websites)) {
                state[key] = (value as any)?.website_object
            }
        },
    }
})


export const GetWebsiteObject = ():callAPIProps => ({
    url: ({ getApiUrl, language, projectId}: any) => getApiUrl(`projects/${projectId}/websites/object`,1),
    method: "GET",
    successDispatch: websiteObjectSlice.actions.get,
})

export const UpdateWebsiteObject = ():callAPIProps => ({
    url: ({getApiUrl, projectId}) => getApiUrl(`projects/${projectId}/websites/object`),
    method: "PATCH",
    auth: true,
    successDispatch: websiteObjectSlice.actions.update
    //passToDispatcher: {categories: values},
})

export const ProjectWebsiteObjectSelector = createSelector([
    (state:any) => state?.siteGenerator.websiteObjects,
    currentProjectIdSelector
],
    (state:any, projectId:any) => state && state[projectId]
)

export const UserHasFilledRules = createSelector(
    [ProjectWebsiteObjectSelector as any],
    (state:any) => {
        return true;
    }
)

export default websiteObjectSlice.reducer;