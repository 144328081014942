import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { Form } from '@ant-design/compatible';
import WatchActions from "services/hoc/watch-actions";
import { getDisplayName, injectProps } from "services/helpers/hoc-helpers";
import { ProviderConfig } from "modules/panel/config/ProviderConfig";
import { subscriptionProviderFieldsSelector } from "state/subscriptions/subscriptionsSelectors";

//TODO: IDIOT - "past tense of set is set not setted ;)"

//TODO: would be cool to support onSuccess too

function WithCreatedForm(WrappedComponent) {
  class CreatedForm extends Component {
    constructor(props) {
      super(props);

      this.state = {
        fieldsSetted: false,
      };
    }

    componentDidMount() {
      if (this.props.mapPropsToFields) {
        let values = this.props.mapPropsToFields(this.props);
        this.props.form.setFields(values);
        this.setState({
          fieldsSetted: true,
        });
      }
    }

    handleSubmit = (e) => {
      if (e) e.preventDefault();
      this.props.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          if (this.props.parseValuesBeforeSend) {
            values = this.props.parseValuesBeforeSend(
              values,
              this.props,
              this.state
            );
          }
          this.props.submitForm.exec(values);
        }
      });
    };

    componentDidUpdate(prevProps) {
      if (
        !prevProps.submitForm.status.success &&
        this.props.submitForm.status.success
      ) {
        //todo: check if error is cleared after request. => can leads to bugs
        let redirectOnSuccess = this.props.redirectOnSuccess;

        if (redirectOnSuccess) {
          if (typeof redirectOnSuccess === "function") {
            redirectOnSuccess = redirectOnSuccess();
          }
          setTimeout(() => {
            this.props.history.push(redirectOnSuccess);
          }, 300);
        }
      }
    }

    getRequestResultFieldProps = (fieldName) => {
      let submitFormStatus = this.getFormStatus();

      if (submitFormStatus.pending) {
        return {
          validateStatus: "validating",
          hasFeedback: true,
        };
      }

      if (!submitFormStatus.pending && submitFormStatus.error) {
        const errors = submitFormStatus.error?.fields;
        if (errors) {
          if (errors[fieldName]) {
            return {
              validateStatus: "error",
              hasFeedback: true,
              help: errors[fieldName],
            };
          }
        } else {
          return {
            hasFeedback: true,
            validateStatus: "error",
          };
        }
      }

      if (!submitFormStatus.pending && submitFormStatus.success) {
        return {
          hasFeedback: true,
          validateStatus: "success",
        };
      }
    };

    getFormStatus = () =>
      this.props.statusKey
        ? this.props.submitForm.status.get(this.props.statusKey(this.props))
        : this.props.submitForm.status;

    render() {
      const {
        requestStatus,
        actions,
        parseValuesBeforeSend,
        redirectOnSuccess,
        submitForm,
        form,
        ...props
      } = this.props;

      return (
        <WrappedComponent
          form={{
            instance: form,
            status: this.getFormStatus(),
            submitForm: submitForm.exec,
            handleSubmit: this.handleSubmit,
            getRequestResultFieldProps: this.getRequestResultFieldProps,
            fieldsSetted: this.state.fieldsSetted,
          }}
          {...props}
        />
      );
    }
  }

  CreatedForm.displayName = `WithCreatedForm(${getDisplayName(
    WrappedComponent
  )})`;
  return CreatedForm;
}

export const CreateForm = (options) => (WrappedComponent) => {
  if (!options.redux) options.redux = {};
  // eslint-disable-next-line
  if (!options.redux.mapStateToProps) options.redux.mapStateToProps = () => {};
  if (!options.redux.mapDispatchToProps)
    // eslint-disable-next-line
    options.redux.mapDispatchToProps = () => {};

  let propsToInject = {};

  if (options.form.parseValuesBeforeSend)
    propsToInject.parseValuesBeforeSend = options.form.parseValuesBeforeSend;
  if (options.form.redirectOnSuccess)
    propsToInject.redirectOnSuccess = options.form.redirectOnSuccess;
  if (options.form.mapPropsToFields)
    propsToInject.mapPropsToFields = options.form.mapPropsToFields;
  if (options.form.statusKey) propsToInject.statusKey = options.form.statusKey;

  let composed = [];
  if (options.withRouter || options.form.redirectOnSuccess)
    composed.push(withRouter);

  let watchConfig = {
    ...options.watch,
    submitForm: {
      dispatch: (dispatch, ownProps) => {
        return (formValues) =>
          dispatch(options.form.dispatch(formValues, ownProps));
      },
      action: options.form.action,
      success: true,
    },
  };

  if (options.integration) {
    const fields = ProviderConfig.get(options.integration).fields;

    watchConfig = {
      ...watchConfig,
      // ...getIntegrationsWatchConfig(fields),
    };

    options.redux.mapStateToProps = (state, props) => ({
      // ...options.redux.mapStateToProps(state, props),
      connectedIntegrations: subscriptionProviderFieldsSelector(state)(
        options.integration
      ),
    });
  }

  composed.push(
    injectProps(propsToInject),
    WatchActions({
      watch: watchConfig,
      redux: {
        mapStateToProps: options.redux.mapStateToProps,
        mapDispatchToProps: options.redux.mapDispatchToProps,
      },
    }),
    Form.create(),
    WithCreatedForm
  );

  //if (options.integration) composed.push(WithConnectedIntegrations);

  return compose(...composed)(WrappedComponent);
};

export default CreateForm;
