import { getUrl, LOGIN } from "modules/panel/config/routes";
import { useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import queryString from "query-string";

export default function Invitation(props:any) {

    const location = useLocation()

    
    useEffect(() => {

        if (!location || !location.search) return;
        const query = queryString.parse(location.search);
        if (!query) return;
        if (!query.code) return;

        localStorage.setItem("invitation_code", query.code as string);
    
    }, []);

    return (
        <Redirect
          to={{
            pathname: getUrl(LOGIN)
          }}
        />
      );
}