import { createSlice } from "@reduxjs/toolkit";
import { ProjectLocalStorage, SubscriptionLocalStorage } from "services/helpers/local-storage";
import { RootState } from "./redux-store";

const old_getInitialProduct = () => {
    const product = localStorage.getItem("product");
    if (product && product !== "undefined" && product !== "null") return product;
    return import.meta.env.VITE_INSTANCE_DEFAULT_PRODUCT;
}

type ScopeSliceState = {
    subscription: string | null,
    project: string | null,
    product: string | null,
}

const initialState: ScopeSliceState = {
    subscription: null,
    project: null,
    product: old_getInitialProduct(),
}

export const scopeSlice = createSlice({
    name: "scope",
    initialState,
    reducers: {
        setScope: (state, action) => {
            state.subscription = String(action.payload.subscription);
            state.project = String(action.payload.project);
            state.product = action.payload.product;
        },
        setSubscription: (state, action) => {
            state.subscription = String(action.payload);
        },
        setProject: (state, action) => {
            state.project = String(action.payload);
        },
        setProduct: (state, action) => {
            state.product = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase("SET_APP_CONTEXT", (state, action) => {
                state.subscription = String(action.payload.subscriptionId);
                state.project = String(action.payload.projectId);
                state.product = action.payload.product;
            })
            .addCase("LOAD_USER_SUCCESS", (state, action) => {
                //console.log("LOAD_USER_SUCCESS", action.payload);

                const initialSubscriptionId = old_getInitialSubscriptionId(action.payload);
                if (initialSubscriptionId) SubscriptionLocalStorage.set(initialSubscriptionId);
                state.subscription = String(initialSubscriptionId);
                
                const initialProjectId = old_getInitialProjectId(action.payload.projects);
                if (initialProjectId) ProjectLocalStorage.set(initialProjectId);
                state.project = String(initialProjectId);

                const initialProduct = old_getInitialProduct();
                if (initialProduct) localStorage.setItem("product", initialProduct);
                state.product = String(initialProduct);
                
                if (initialSubscriptionId) {
                    const initialSub = action.payload.product_subscriptions[initialSubscriptionId];
                    if (initialSub) {
                        let initialProduct = (() => {
                            if (initialSub.product) return initialSub.product;
                            if (initialSub.roles && initialSub.roles[0]) {
                                if (typeof initialSub.roles[0] === "string") return initialSub.roles[0];
                                if (typeof initialSub.roles[0] === "object") return initialSub.roles[0].name;
                            }
                            return null;
                        })()
                        if (initialProduct) {
                            localStorage.setItem("product", initialProduct);
                            state.product = initialProduct;
                        }

                    }
                }

            })
    }
});

export const currentSubscriptionIdSelector = (state: RootState) => state.scope.subscription;
export const currentProjectIdSelector = (state: RootState) => state.scope.project;
export const currentProductSelector = (state: RootState) => state.scope.product;


export const old_getInitialProjectId = (projects: any) => {
    const projectInStorage = ProjectLocalStorage.get();
    const storageProject = projectInStorage ? projects[projectInStorage] : null;

    if (storageProject) return storageProject.id;

    const flatProjects = Object.values(projects);
    const firstProjectId = flatProjects.length > 0 ? flatProjects[0].id : false;

    if (firstProjectId) return firstProjectId;

    return null;
}

export const old_getInitialSubscriptionId = (userLoadPayload: any) => {
    const { product_subscriptions } = userLoadPayload;

    const initialProjectId = old_getInitialProjectId(userLoadPayload?.projects || []);

    if (initialProjectId) {
        const initialProjectSubscriptions = Object.values(
            product_subscriptions
        ).filter(
            (subscription) =>
                Array.isArray(subscription.projects) &&
                subscription.projects[0] &&
                subscription.projects[0] === initialProjectId &&
                Array.isArray(subscription.roles) &&
                subscription.roles[0] &&
                subscription.roles[0]
        );

        const subscriptionIdStorage = SubscriptionLocalStorage.get();
        const storageSubscription = initialProjectSubscriptions.find(
            (subscription) => subscription.id === Number(subscriptionIdStorage)
        );

        if (storageSubscription) return storageSubscription.id;

        const firstInitialProjectSubscription = initialProjectSubscriptions.length
            ? initialProjectSubscriptions[0].id
            : false;

        if (firstInitialProjectSubscription) return firstInitialProjectSubscription;
    }

    return null;
};