import { RootState } from "services/store/redux-store";
import { createSelector } from "@reduxjs/toolkit";
import { subscriptionsSelector } from "state/subscriptions/subscriptionsSelectors";
import { Project, ProjectWithSubscriptions } from "./projectTypes";
import { mapSubscriptionsToProject } from "./projectHelpers";
import subscription from "providers/subscription/subscription";
import { Subscription } from "state/subscriptions/subscriptionTypes";

export const projectsSliceSelector = (state: RootState): Record<string, Project> => state.projectsSlice;

// Selector to get a project by its ID
export const projectSelector = (id: string | number) => createSelector(
    projectsSliceSelector,
    (projects) => projects[id]
);

export const projectsSelector = createSelector(
    projectsSliceSelector,
    (projects) => projects
);

// Selector to get all projects as an array
export const projectsAsArraySelector = createSelector(
    projectsSliceSelector,
    (projects) => Object.values(projects || {}) 
);

// Selector to get filtered projects based on a filtering function
export const filteredProjectsSelector = (filterFn: (project: Project) => boolean) => createSelector(
    projectsAsArraySelector,
    (projectsArray) => projectsArray.filter(filterFn)
);

export const projectWithSubscriptionsSelector = (projectId: string) => createSelector(
    [projectSelector(projectId), subscriptionsSelector],
    (project, subscriptions):ProjectWithSubscriptions|null => {
        if (!project) return null;
        const projectSubscriptions = project.subscriptions.map(subscriptionId => subscriptions[subscriptionId]);
        return {
            ...project,
            subscriptions: projectSubscriptions
        };
    }
);

export const projectsWithSubscriptionsSelector = createSelector(
    [projectsSliceSelector, subscriptionsSelector],
    (projects, subscriptions) => {
        if (!projects) return {};
        const withSubs: Record<string, ProjectWithSubscriptions> = {};
        Object.keys(projects).map(projectId => {
            const project = projects[projectId];
            if (project) {
                withSubs[projectId] = mapSubscriptionsToProject(project, subscriptions);
            }
        });
        return withSubs;
    }
);

export const projectsAsArrayWithSubscriptionsSelector = createSelector(
    [projectsAsArraySelector, subscriptionsSelector],
    (projects, subscriptions) => {
        return projects.map(project => {
            return mapSubscriptionsToProject(project, subscriptions);
        });
    }
);

export const activeProjectsSelector = createSelector(
    [projectsAsArrayWithSubscriptionsSelector],
    (projects) => {
        return projects.filter((project) => {
            return project.subscriptions.some((subscription: Subscription) => subscription.status === "active");
        });
    }
);
