import { Col, Collapse, Input, Row, Select } from "antd";
import {
  StandardFullColumn,
  StandardHalfFullColumn,
  StandardRow,
} from "components/layout/StandardGrid";
import {
  KeywordsConfig,
  KeywordType,
} from "modules/panel/config/KeywordsConfig";
import { useMemo } from "react";
import FormattedMessage from "components/common/FormattedMessage";
import { useSelector } from "react-redux";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";
import {
  checkDuplicates,
  ruleKeywordRegex,
  ruleMax,
} from "tools/relaxForm/relaxPresets";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import PeriodInput from "_components/forms/period-input";
import WebsiteInput from "_components/forms/website-input";
import YesNoInput from "_components/forms/yes-no-input";
import { TooltipIcon } from "components/common/tooltip-icon/TooltipIcon";
import HelpPanelDocument from "tools/helpPanel/HelpPanelDocument";
import { AllAdsAndRemarkettingKeywords, SmartSelector, smartSlice } from "modules/smart/smartSlice";
import { currentProjectIdSelector } from "services/store/scopeSlice";
import { currentProjectOnboarded } from "state/projects/projectSelectors";

export const KeywordInput = ({
  keyword,
  isAutopromoted,
}: {
  keyword: string;
  isAutopromoted: boolean;
}) => {
  const config = KeywordsConfig.get(keyword as KeywordType);
  const settings = useSelectorWithParams([AllAdsAndRemarkettingKeywords]);

  if (!config) return null;

  const otherKeywordsArray = (value:string) =>{
    const obj = {...settings?.initial_keywords}
    delete obj[value]
    return Object.values(obj)
  }
  
  return (
    <Row gutter={30}>
      <Col xxl={12} lg={12} md={12} sm={24} xs={24}>
        <RelaxSingleField
          name={["initial_keywords", config.keyword.name]}
          disabled={isAutopromoted}
          label={config.keyword.displayName}
          tooltip={config.keyword?.tooltip}
          shouldReset={true}
          rules={[ruleKeywordRegex, ruleMax(20),checkDuplicates(otherKeywordsArray(config.keyword.name))]}
        >
          <Input />
        </RelaxSingleField>
      </Col>
      <Col xxl={12} lg={12} md={12} sm={24} xs={24}>
        {config.remarketing && (
          <RelaxSingleField
            name={["initial_keywords", config.remarketing.name]}
            label={config.remarketing?.displayName}
            tooltip={config.remarketing?.tooltip}
            shouldReset={true}
            disabled={
              !(
                settings?.initial_keywords[config.keyword.name] ||
                isAutopromoted
              )
            }
            rules={[ruleKeywordRegex, ruleMax(20),checkDuplicates(otherKeywordsArray(config.remarketing.name))]}
          >
            <Input />
          </RelaxSingleField>
        )}
      </Col>
    </Row>
  );
};

const AutopromoteKeywords = (props: any) => {
  const keywords = useSelectorWithParams([AllAdsAndRemarkettingKeywords]);
  //convert keywordsConfig to array

  const mappedOptions = useMemo((): JSX.Element[] => {
    return Array.from(KeywordsConfig)
      .filter(
        ([option, value]) =>
          option !== "forceInterest" &&
          keywords?.initial_keywords?.[value.keyword.name] !== null
      )
      .map(([option, value]) => (
        <Select.Option key={value.keyword.name} value={value.keyword.name}>
          {value.keyword.displayName}
        </Select.Option>
      ));
  }, [KeywordsConfig, keywords]);

  // useEffect(() => {
  //     console.log(`props.value`,props.value);
  // }, [props.value]);

  return (
    <>
      <Select
        {...props}
        mode="multiple"
        onChange={(newValue) => {
          props.onChange && props.onChange(newValue);
          props.onBlur && props.onBlur(newValue);
        }}
      >
        {mappedOptions}
      </Select>
    </>
  );
};

export default function SettingsBusiness() {
  const projectId = useSelectorWithParams(currentProjectIdSelector);
  const onboarded = useSelectorWithParams(currentProjectOnboarded);
  const settings = useSelectorWithParams([SmartSelector]);

  const isKeywordAutoPromoted = (keyword: string) => {
    return settings?.auto_promotion.includes(keyword);
  };

  return (
    <>
      <div style={{ height: "20px" }}></div>
      <StandardRow>
        <StandardHalfFullColumn>
          <RelaxMultiform
            name="businessSettings"
            initialValuesSelector={AllAdsAndRemarkettingKeywords}
            callAPI={{
              //url: ({ getApiUrl, projectId, smart }) => getApiUrl(`projects/${projectId}/smarts/${smart}`, 2),
              url: ({ getApiUrl, projectId }) =>
                getApiUrl(`projects/${projectId}/smart`, 1),
              method: "PATCH",
              successDispatch: smartSlice.actions.get,
            }}
            finalizeSubmit={(values) => ({ ...values, project_id: projectId })}
          >
            <StandardRow>
              <StandardFullColumn>
                <RelaxSingleField
                  name={"auto_promotion"}
                  initialValuesSelector={SmartSelector}
                  label={
                    <FormattedMessage
                      id="config.keywords.interests.autopromoteKeywords.label"
                      defaultMessage="Whitch keywords to autopromote"
                    />
                  }
                  tooltip={
                    <FormattedMessage
                      id="config.keywords.interests.autopromoteKeywords.tooltip"
                      defaultMessage="Whitch keywords to autopromote"
                    />
                  }
                >
                  <AutopromoteKeywords />
                </RelaxSingleField>
              </StandardFullColumn>
            </StandardRow>

            <StandardRow>
              <StandardFullColumn>
                <KeywordInput
                  keyword="initial"
                  isAutopromoted={isKeywordAutoPromoted("initial_keyword")}
                />
              </StandardFullColumn>
            </StandardRow>

            <StandardRow>
              <StandardHalfFullColumn>
                <RelaxSingleField
                  name={["initial_keywords", "force_interests_keyword"]}
                  label={
                    <FormattedMessage
                      id="config.keywords.interests.keyword.displayName.label"
                      defaultMessage="Magic Word - Interests Only"
                    />
                  }
                  tooltip={
                    <FormattedMessage
                      id="config.keywords.interests.keyword.displayName.tooltip"
                      defaultMessage="Magic Word - Interests Only"
                    />
                  }
                >
                  <Input />
                </RelaxSingleField>
              </StandardHalfFullColumn>
              <StandardHalfFullColumn>
                <RelaxSingleField
                  name="remarketing_time"
                  label={
                    <FormattedMessage
                      id="panel.containers.advancedSettings.retention.label"
                      defaultMessage="Retention"
                    />
                  }
                  tooltip={
                    <FormattedMessage
                      id="panel.containers.advancedSettings.retention.tooltip"
                      defaultMessage="Retention"
                    />
                  }
                  required={true}
                  //initialValues={7}
                  initialValuesSelector={SmartSelector}
                >
                  <PeriodInput />
                </RelaxSingleField>
              </StandardHalfFullColumn>
            </StandardRow>

            <div style={{ height: "20px" }}></div>

            <StandardRow>
              <StandardFullColumn
                style={{ display: "flex", alignItems: "center" }}
              >
                <div style={{ width: "calc(100% - 30px)" }}>
                  <Collapse>
                    <Collapse.Panel
                      header={
                        <FormattedMessage
                          id="apps.smart.forms.businessDetails.advancedSettings"
                          defaultMessage="Advanced Settings"
                        />
                      }
                      key="advanced"
                    >
                      <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                          <KeywordInput
                            keyword="employment"
                            isAutopromoted={isKeywordAutoPromoted(
                              "employment_keyword"
                            )}
                          />
                          <KeywordInput
                            keyword="housing"
                            isAutopromoted={isKeywordAutoPromoted(
                              "housing_keyword"
                            )}
                          />
                          <KeywordInput
                            keyword="credit"
                            isAutopromoted={isKeywordAutoPromoted(
                              "credit_keyword"
                            )}
                          />
                          <KeywordInput
                            keyword="politics"
                            isAutopromoted={isKeywordAutoPromoted(
                              "politics_keyword"
                            )}
                          />
                          <KeywordInput
                            keyword="gambling"
                            isAutopromoted={isKeywordAutoPromoted(
                              "gambling_keyword"
                            )}
                          />
                        </Col>
                      </Row>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div style={{ marginLeft: "auto" }}>
                  <TooltipIcon
                    title={
                      <FormattedMessage
                        id="apps.smart.forms.businessDetails.advancedSettings.tooltip"
                        defaultMessage="Advanced Settings"
                      />
                    }
                  />
                </div>
              </StandardFullColumn>
            </StandardRow>

            <div style={{ height: "20px" }}></div>

            <StandardRow>
              <StandardFullColumn>
                <RelaxSingleField
                  name="website"
                  label={
                    <FormattedMessage
                      id="apps.smart.forms.businessDetails.websiteAddress.label"
                      defaultMessage="Your website address"
                    />
                  }
                  required={true}
                  initialValuesSelector={SmartSelector}
                  disabled={onboarded}
                  tooltip={
                    <FormattedMessage
                      id="apps.smart.forms.businessDetails.websiteAddress.tooltip"
                      defaultMessage="Your website address"
                    />
                  }
                >
                  <WebsiteInput />
                </RelaxSingleField>
              </StandardFullColumn>
            </StandardRow>

            <div style={{ height: "20px" }}></div>

            <StandardRow>
              <Col
                xxl={12}
                lg={12}
                md={24}
                sm={24}
                xs={24}
                style={{ display: "flex", alignItems: "center" }}
              >
                <RelaxSingleField
                  name="has_fans"
                  label={
                    <FormattedMessage
                      id="apps.smart.components.adsSettings.fakeFans.label"
                      defaultMessage="Did you buy fake fans?"
                    />
                  }
                  required={true}
                  initialValues={"no"}
                  initialValuesSelector={SmartSelector}
                >
                  <YesNoInput />
                </RelaxSingleField>
                <TooltipIcon
                  title={
                    <FormattedMessage
                      id="apps.smart.components.adsSettings.fakeFans.tooltip"
                      defaultMessage="Did you buy fake fans?"
                    />
                  }
                />
              </Col>
              <Col
                xxl={12}
                lg={12}
                md={24}
                sm={24}
                xs={24}
                style={{ display: "flex", alignItems: "center" }}
              >
                <RelaxSingleField
                  name="new_market"
                  label={
                    <FormattedMessage
                      id="apps.smart.components.adsSettings.newMarket.label"
                      defaultMessage="Is this a new market?"
                    />
                  }
                  required={true}
                  initialValuesSelector={SmartSelector}
                  initialValues={"no"}
                >
                  <YesNoInput />
                </RelaxSingleField>
                <TooltipIcon
                  title={
                    <FormattedMessage
                      id="apps.smart.components.adsSettings.newMarket.tooltip"
                      defaultMessage="Is this a new market?"
                    />
                  }
                />
              </Col>
            </StandardRow>
          </RelaxMultiform>
        </StandardHalfFullColumn>
        <StandardHalfFullColumn>
          <HelpPanelDocument
            center
            pageId="apps.smart.settings.business.sider"
          />
        </StandardHalfFullColumn>
      </StandardRow>
    </>
  );
}
