import React from "react";
import { usePost } from "../postContext";
import StandardButton from "components/common/StandardButton";
import FormattedMessage from "components/common/FormattedMessage";
import useAPI from "services/hooks/useAPI";
import { RepublishAoa } from "../state/postsSlice";

export const AoaRepublish = () => {
  const { reloadList, post } = usePost();
  const { call, loading, error } = useAPI(RepublishAoa(post.id));

  return (
    <>
      <StandardButton
        type="primary"
        onClick={() => {
          call().then((res) => {
            if (res.starus === 200) {
              reloadList();
            }
          });
        }}
        loading={loading}
      >
        <FormattedMessage
          id="smart.components.posts.aoa.republish"
          defaultMessage="Republish"
        />
      </StandardButton>
      { error && <span className="error">{error?.error?.description}</span>}
    </>
  );
};
