import { Button, Modal, Radio, Spin, Tooltip } from "antd";
import { useMemo, useRef, useState } from "react"
import FormattedMessage from "components/common/FormattedMessage";
import { useGallery } from "./Gallery"
import { CanImageBeSplitToSlides, CheckSizeAgainstPreset, CheckSizeAgainstPresets } from "./imageHelpers";
import AwesomeIcon from "components/common/AwesomeIcon";
import StandardButton from "components/common/StandardButton";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import ImageCropHelp from "./imageCropHelp";
import PlatformIcon from "components/common/icons/PlatformIcon";

const splitCountDisplayText = {
    2: <FormattedMessage
        id="apps.tools.imageGallery.split.countDisplay.2"
        defaultMessage="2 Slides"
    />,
    3: <FormattedMessage
        id="apps.tools.imageGallery.split.countDisplay.3"
        defaultMessage="3 Slides"
    />,
    4: <FormattedMessage
        id="apps.tools.imageGallery.split.countDisplay.4"
        defaultMessage="4 Slides"
    />,
    5: <FormattedMessage
        id="apps.tools.imageGallery.split.countDisplay.5"
        defaultMessage="5 Slides"
    />,
}

export default function ImageCropTools(props: any) {


    const { croppedImage, cropPresets, enableCropTrack } = useGallery();
    const helpModalRef = useRef();

    const presets = useMemo(() => {
        if (!cropPresets) return;

        const platforms: any[] = [];
        const keys = Object.keys(cropPresets)
        for (const platform in cropPresets) {
            const platformName = platform.split("-")[0]
            platforms.push(
                <>
                    {keys.length > 1 && (
                        <div style={{ display: "grid", placeItems: "center" }}>
                            <PlatformIcon platform={platformName as any} />
                        </div>
                    )}
                    <Radio.Group
                        value={props.preset?.ratio}
                    >
                        {cropPresets[platform] && cropPresets[platform].map((preset: any) => {
                            const canBeSelected = props.currentImageData ? CheckSizeAgainstPreset(props.currentImageData, preset) : false
                            const isSelected = croppedImage?.crop?.[platform]?.ratio === preset.ratio
                            return (
                                <Tooltip
                                    title={!canBeSelected && "This image is too small for this crop"}
                                >
                                    <Radio.Button
                                        disabled={!canBeSelected}
                                        onClick={() => props.onPresetSelected && props.onPresetSelected(preset)}
                                        value={preset.ratio}
                                    >
                                        {preset.displayName || preset.ratio || ""}
                                        {isSelected && <AwesomeIcon icon={faCheckCircle} />}
                                    </Radio.Button>
                                </Tooltip>
                            )
                        })}
                    </Radio.Group>
                </>
            )
        }
        return platforms

    }, [croppedImage, cropPresets, props.preset, props.crop, props.aspect, props.currentImageData])



    const splitOptions = useMemo(() => {

        const maxSlides = props?.splitOptions?.maxSlides || 1;

        return (
            <div style={{ display: "flex", flexDirection: "row" }}>
                {Array.from({ length: maxSlides - 1 }).map((_, i) => {


                    const notTooSmallForSplit = CanImageBeSplitToSlides({ width: croppedImage?.width, height: croppedImage?.height }, i + 2)

                    return (
                        <Tooltip
                            title={notTooSmallForSplit ? "" : <FormattedMessage
                            id="apps.tools.imageGallery.split.tooSmallTooltip"
                            defaultMessage="This image is too small for this many slides."
                        />}
                        >
                            <div>
                                <StandardButton
                                    onClick={() => props.loadSplit && props.loadSplit(i + 2)}
                                    disabled={!notTooSmallForSplit}
                                >
                                    {splitCountDisplayText[i + 2]}
                                </StandardButton>
                            </div>
                        </Tooltip>
                    )
                })}
            </div>
        )


    }, [props.splitOptions])


    if (props.mode === "split") return (
        <div style={{
            position: "absolute",
            width: "100vw",
            height: "60px",
            bottom: "-65px",
            display: "grid",
            placeItems: "center",
            gridTemplateColumns: "1fr 1fr 1fr"
        }}>
            <StandardButton onClick={() => props.onCancel && props.onCancel()}>
                <FormattedMessage
                    id="apps.tools.imageGallery.cancel"
                    defaultMessage="Cancel"
                />
            </StandardButton>
            {splitOptions}
            <StandardButton type="primary" onClick={() => props.onSave && props.onSave()}>
                <FormattedMessage
                    id="apps.tools.imageGallery.saveSplit"
                    defaultMessage="Split"
                />
            </StandardButton>
        </div>
    )

    return (
        <>
            <div style={{
                position: "absolute",
                width: "100vw",
                height: "60px",
                bottom: "-65px",
                display: "grid",
                placeItems: "center",
            }}>
                <div style={{
                    display: "grid",

                    gridTemplateColumns: `repeat(${(presets?.length || 1) * 2}, auto auto)`,
                    justifyContent: "center",
                    columnGap: "10px"
                }}>

                    {props.isLoaded ? (
                        <>
                            {presets}
                            <StandardButton type="dashed" onClick={() => helpModalRef.current.open()}>
                                <FormattedMessage
                                    id="apps.tools.imageGallery.toolHelp"
                                    defaultMessage="?"
                                />
                            </StandardButton>
                            {enableCropTrack ? (
                                <StandardButton type="primary" onClick={() => props.onSave && props.onSave()}>
                                    <FormattedMessage
                                        id="apps.tools.imageGallery.nextCrop"
                                        defaultMessage="Next"
                                    />
                                </StandardButton>

                            ) : (<StandardButton type="primary" onClick={() => props.onSave && props.onSave()}>
                                <FormattedMessage
                                    id="apps.tools.imageGallery.saveCrop"
                                    defaultMessage="Save"
                                />
                            </StandardButton>)}
                        </>
                    ) : (
                        <Spin />
                    )}
                </div>
            </div>
            <ImageCropHelp ref={helpModalRef} />
        </>
    )
}