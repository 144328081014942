import useSelectorWithParams from "services/hooks/useSelectorWithParams"
import { CategoriesSelector, GetCategories } from "../brandsAndManufacturers/CarsSlice"
import useAPI from "services/hooks/useAPI"
import RelaxField from "tools/relaxForm/RelaxField"
import { CarServiceCategoryInput } from "../brandsAndManufacturers/CarsServiceCategoryInput"
import { useEffect, useMemo, useState } from "react"
import FormattedMessage from "components/common/FormattedMessage"
import { UpdateWebsite, WebsiteCarserviceCategoriesSelector } from "modules/site-generator/reducers/websiteSlice"
import { currentProjectIdSelector } from "services/store/scopeSlice"

export const CarserviceCategories = (props:any) => {

    const categories = useSelectorWithParams(WebsiteCarserviceCategoriesSelector);
    const categoryDefinitions = useSelectorWithParams(CategoriesSelector)
    const categoriesCall = useAPI(GetCategories, true)

    const checkedSubCategories:Map<number, number[]> = useMemo(() => {
        if (!categoryDefinitions?.length) return new Map();

        if (!categories?.length) return new Map();

        //Map where a key is a category id (from categoryDefinitions) and the value is an array of sub categories that are present in "categories" array.
        const checkedSubCategories:Map<number, number[]> = new Map();
        categoryDefinitions.forEach((category: any) => {
            const checkedSubCategoriesInThisCategory = category.sub_categories.filter((subCategory:any) => {
                return categories.includes(subCategory.sub_content_categories_id)
            }).map((subCategory:any) => subCategory.sub_content_categories_id)
            checkedSubCategories.set(category.id, checkedSubCategoriesInThisCategory)
        })

        return checkedSubCategories;

    }, [categories, categoryDefinitions])

    const projectId = useSelectorWithParams(currentProjectIdSelector)

    return (
        <>
            {categoryDefinitions?.length > 0 && (
                categoryDefinitions.map((category: any, index: number) => (
                    <RelaxField
                        shouldReset={true}
                        key={category.id}
                        name="categories"
                        submitOnChange
                        callAPI={UpdateWebsite}
                        finalizeSubmit={(values: any) => {
                            const newCheckedSubCategories = new Map(checkedSubCategories);
                            newCheckedSubCategories.set(category.id, values.categories);
                            return {
                                categories: Array.from(newCheckedSubCategories.values()).flat(),
                                projectId: projectId
                            }
                        }}
                        initialValues={{
                            categories: checkedSubCategories.get(category.id) || []
                        }}

                    >
                        <CarServiceCategoryInput
                            title={<FormattedMessage
                                id="category"
                                defaultMessage={category.name} />}
                            categoryId={category.id}
                            subCategories={category.sub_categories}
                            loading={categoriesCall.loading} 
                            />
                    </RelaxField>
                ))
            )}
        </>
    )
}

export default CarserviceCategories;