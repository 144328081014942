import { useEffect, useRef } from 'react';
import { isEqual } from 'lodash';

function useEffectDeepCompare(effect: React.EffectCallback, dependencies: any[]): void {
  const dependenciesRef = useRef(dependencies);

  if (!isEqual(dependencies, dependenciesRef.current)) {
    dependenciesRef.current = dependencies;
  }

  useEffect(effect, [dependenciesRef.current]);
}

export default useEffectDeepCompare;
