import React, { FunctionComponent, ReactNode } from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";

export type TwoColumnsLayoutSidebarProps = {
  heading: string | ReactNode;
  description?: string | ReactNode;
  buttonHref?: () => string;
  buttonText?: string | ReactNode;
  widget?: string | ReactNode;
};

const TwoColumnsLayoutSidebar: FunctionComponent<TwoColumnsLayoutSidebarProps> = ({
  heading,
  description,
  buttonHref,
  buttonText,
  widget,
}) => (
  <div className="two-columns-layout-sidebar">
    <h2 className="two-columns-layout-sidebar__heading">{heading}</h2>
    {description && (
      <div className="two-columns-layout-sidebar__description">
        {description}
      </div>
    )}
    {buttonHref && buttonText && (
      <Link to={buttonHref()}>
        <Button ghost className="button">
          {buttonText}
        </Button>
      </Link>
    )}
    <div className="two-columns-layout-sidebar__bg-widget">
      {typeof widget === "string" ? (
        <div className="bg-widget-text">{widget}</div>
      ) : (
        <>{widget}</>
      )}
    </div>
  </div>
);

export function isSidebarConfig(sidebar: TwoColumnsLayoutSidebarProps | ReactNode): sidebar is TwoColumnsLayoutSidebarProps {
  return !!(sidebar as TwoColumnsLayoutSidebarProps).heading
}

export default TwoColumnsLayoutSidebar;
