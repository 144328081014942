import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { isWithinRange } from "./CalendarHelpers";
import "dragula/dist/dragula.css";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import useAPI from "services/hooks/useAPI";
import { filteredCreationsAsArraySelector, getCreations, getCreationsWithQuery } from "tools/creationEditor/creationsSlice";
import _ from "lodash";
import dayjs from "dayjs";
import { GetPosts, PostsSelector } from "modules/posts/state/postsSlice";
import { CalendarEntriesManager } from "./Calendar";
import { TagButton } from "components/common/TagButton";
import AwesomeIcon from "components/common/AwesomeIcon";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import CalendarCreationEntry from "./CalendarCreationEntry";
import CalendarUpcoming from "./CalendarUpcoming";
import useMemoDeepCompare from "services/hooks/useMemoDeepCompare";

const NewPostTagButton = (props:any) => {
    return (
        <TagButton
        className="calendar-day-tool new-post-button" 
        color="rgb(86, 153, 255)"
        onClick={() => props.onClick && props.onClick()}
        >
            New Post! <AwesomeIcon icon={faPlus} />
        </TagButton>
    )
}

const useCalendarProjectEntries:CalendarEntriesManager = (props) => {

    //const { call, data, loading, error, StatusDisplay} = useAPI(getCreations, true)
    const postsCall = useAPI(GetPosts())
    const creationsCall = useAPI(getCreationsWithQuery())
    const allPosts = useSelectorWithParams([PostsSelector])
    const creations = useSelectorWithParams(filteredCreationsAsArraySelector({
        statusFilter: {
            "removed": false,
            "draft": true, 
            "published": true,
            "scheduled": true,
        },
        sort: "publish_at",
        from: props.range[0],
        to: props.range[1]
    }));

    useEffect(() => {
        if (!props.range) return;
        if (postsCall.loading) return;
        const [from, to] = props.range;
       
        const normalizedQuery:any = {
            "date[from]": from.format("YYYY-MM-DD"),
            "date[to]": to.format("YYYY-MM-DD"),
            "sort": "created_at",
            "included[0]": "fbpost",
            "included[1]": "gpost",
            "included[2]": "fbad",
            "included[3]": "gad",
            "included[4]": "aoa",
            "included[5]": "leadad"
        }

        postsCall.call(GetPosts(normalizedQuery))

    }, [props.range]);

    useEffect(() => {
        if (!props.range) return;
        if (creationsCall.loading) return;
        const [from, to] = props.range;
       
        creationsCall.call(getCreationsWithQuery({
            "sort": "publish_at",
            "publishFrom": from.format("YYYY-MM-DD"),
            "publishTo": to.format("YYYY-MM-DD"),
        }))

    }, [props.range]);

    const filteredPosts: any[] = useMemo(() => {
            
            if (!allPosts) return [];
            if (!props.range) return [];
    
            const filtered = allPosts.filter((post: any) => {
               
                return (isWithinRange(dayjs(post.created_at), props.range)) 
    
            })
    
            return filtered;
    }, [allPosts, props.range])

    const entries = useMemoDeepCompare(() => {
        const e: any[] = [];
        creations && creations.forEach((creation: any) => {
            if (!creation) return;
            const nc = _.cloneDeep(creation)
            nc.entryType = "creation";
            e.push(nc)
        })

        filteredPosts && filteredPosts.forEach((post: any) => {
            if (!post) return;
            const np = _.cloneDeep(post)
            np.entryType = "post";
            e.push(np)
        })

        return e;

    }, [creations, filteredPosts])


    return {
        entries,
        DayTool: NewPostTagButton,
        EntryComponent: CalendarCreationEntry,
        Sider: CalendarUpcoming,
        usePreview: true,
    };

}

export default useCalendarProjectEntries;