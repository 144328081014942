import { callAPIProps } from "services/hooks/useAPI";
import { subscriptionsSlice } from "./subscriptionsSlice";
import _ from "lodash";


export const GetSubscriptionCancelationPlans = (subscriptionId: number): callAPIProps => ({
    url: ({ getApiUrl }) => getApiUrl(`subscriptions/${subscriptionId}/cancel`),
    successDispatch: subscriptionsSlice.actions.getCancellationPlans,
    passToDispatcher: {
        subscriptionId
    }
});

export const CancelSubscription = (subscriptionId: number, cancelationPlan: string): callAPIProps => ({
    url: ({ getApiUrl }) => getApiUrl(`subscriptions/${subscriptionId}/cancel`),
    method: "POST",
    body: {
        plan: cancelationPlan,
    },
    clearDataOnCall: true
});

export const PatchCancellationSurvey = (subscriptionId: number): callAPIProps => ({
    url: ({ getApiUrl }) => getApiUrl(`subscriptions/${subscriptionId}/cancellationSurvey`),
    method: "PATCH",
    successDispatch: subscriptionsSlice.actions.updateCancellationSurvey,
});

export const GetCancellationSurvey = (subscriptionId: number): callAPIProps => ({
    url: ({ getApiUrl }) => getApiUrl(`subscriptions/${subscriptionId}/cancellationSurvey`),
    method: "GET",
    successDispatch: subscriptionsSlice.actions.updateCancellationSurvey,
});


export type purchaseSubscriptionBody = {
    project_id: string,
    plan_id: number,
    promo_code?: string,
    payment_id?: string,
}


export const purchaseSubscription = (body: purchaseSubscriptionBody, projectId: string, product: string): callAPIProps => ({
    url: ({ getApiUrl }) => getApiUrl("user/subscription", 3),
    method: "POST",
    body: body,
    adjustResponse: (normalized, raw, normalizer) => {

        normalized.projects = [projectId];
        normalized.product = product;
        normalized.roles = [product];

        return {
            subscription: normalized,
            payment_intent: raw.payment_intent
        };
    },
    dispatchName: "CREATE_SUBSCRIPTION",
    successDispatch: subscriptionsSlice.actions.newSubscription,
});


export const getSubscriptionsOffers = (productName?: string): callAPIProps => ({
    url: ({ getApiUrl }) => getApiUrl(`subscriptions-offers?product_name=${productName}`, 3),
});


export const getSubscription = (subscriptionId: number): callAPIProps => ({
    url: ({ getApiUrl }) => getApiUrl(`subscriptions/${subscriptionId}`),
    successDispatch: subscriptionsSlice.actions.getSubscription, customNormalizer: (data) => data,
    adjustResponse: (_normalized, raw, normalizer) => {
        //workaround for inconsistent API
        const adj = _.cloneDeep(raw);
        if (adj && adj.data) {
            adj.data.forEach((s: any) => {
                if (s?.relationships?.stripeInvoices) {
                    s.relationships.stripe_invoices = s.relationships.stripeInvoices;
                }
            })
        }
        const normalized = normalizer(adj);
        return normalized;
    }
})

export const attachSubscriptionToProject = (subscriptionId: string, projectId: string): callAPIProps => ({
    url: ({ getApiUrl }) => getApiUrl(`projects/${projectId}/${subscriptionId}`, 1),
    method: "POST",
   // dispatchName: "ATTACH_SUBSCRIPTION_TO_PROJECT",
    successDispatch: subscriptionsSlice.actions.attachSubscriptionToProject,
    passToDispatcher: {
        projectId: String(projectId),
        subscriptionId: String(subscriptionId),
    },
});

export const detachSubscriptionFromProject = (subscriptionId: string, projectId: string): callAPIProps => ({
    url: ({ getApiUrl }) => getApiUrl(`projects/${projectId}/${subscriptionId}`, 1),
    method: "DELETE",
    //dispatchName: "DETACH_SUBSCRIPTION_TO_PROJECT",
    successDispatch: subscriptionsSlice.actions.detachSubscriptionFromProject,
    passToDispatcher: {
        projectId: String(projectId),
        subscriptionId: String(subscriptionId),
    },
});