import React, { PureComponent, useEffect, useMemo, useState } from "react";
import {
  createIntl,
  createIntlCache,
  RawIntlProvider,
  IntlShape,
} from "react-intl";
import FullPageLoader from "_components/fullpage-loader";
import { Language } from "providers/languages";
import LanguageHelper from "providers/languages/helper";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { userSettingsInterfaceLanguageSelector } from "state/user/userSettingsSlice";
import FeatureErrorBoundry from "providers/routing/FeatureErrorBoundry";
import { useSelector } from "react-redux";


export const useTranslation = (props?:any):IntlShape | null => {

    const language = useSelector(userSettingsInterfaceLanguageSelector);
    const [intl, setIntl] = useState<IntlShape | null>(null);

    const cache = useMemo(() => createIntlCache(), []);

    const getMessages = async (lang: Language) => {
        try {
            const res = await fetch(`${props?.languageURL || import.meta.env.VITE_LANGUAGE_URL}/${lang}.json`, {cache: "reload"});
            const messages = await res.json();
            return messages;
        } catch (e) {
            console.error("Failed to get messages", e)
            return undefined;
        }
    }

    const loadLanguage = async (lang:any) => {
        const messages = await getMessages(lang);
        try {
            setIntl(createIntl({
                locale: language,
                messages: messages
            }, cache))
        } catch (e) {
            console.error("Failed to load language", e)
            console.log("fallback to en")
            loadFallback();
        }
    }

    
    const loadFallback = async () => {
        try {
            setIntl(createIntl({
                locale: "en"
            }, cache))
        } catch (e) {
            console.error("Failed to load fallback langauge", e)
        }
    }

    useEffect(() => {
        if (!language) return;
        loadLanguage(language);
    }, [language]);

    return intl;


}


const TranslationProvider = (props:any) => {

    const language = useSelector(userSettingsInterfaceLanguageSelector);
    const intl = useTranslation();

    if (!intl) return <FullPageLoader />;

    return (
        <RawIntlProvider key={language || "no-lang"} value={intl}>
            {props.children}
        </RawIntlProvider>
    )

}

const WithErrorBoundary = (props:any) => {

    return (
        <FeatureErrorBoundry
            fallback={<div>Failed to load translation</div>}
        >
            <TranslationProvider {...props} />
        </FeatureErrorBoundry>
    )

}

export default WithErrorBoundary;