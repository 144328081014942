import { Select } from "antd"
import { AiStrategySelector, GetAiStrategies } from "modules/panel/state/AISlice"
import { useEffect, useMemo } from "react";
import useAPI from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams"

type AiStrategySelectProps = {
    onChange?: (value: string) => void,
    onBlur?: () => void,
    value?: string,
}

const AIStrategySelect = (props: AiStrategySelectProps) => {

    const aiStrategies = useSelectorWithParams(AiStrategySelector);

    const options = useMemo(() => {

        if (!aiStrategies) return [];

        return [...aiStrategies.map((aiStrategy: any) => ({
            label: aiStrategy.name,
            value: aiStrategy.id
        })), {
            label: "Posts from images",
            value: "postsFromImages"
        }]

    }, [aiStrategies])

    useEffect(() => {
        if (!options.length) return;
        props.onChange && props.onChange(options[0].value)
        props.onBlur && props.onBlur();
    }, [options]);

    if (!options || !options.length) {
        return (
            <Select 
                disabled={true}
                style={{ width: "100%" }}
            />
        )
    }

    return (
        <Select 
            {...props}
            style={{ width: "100%" }}
            options={options}
            defaultValue={options[0].value}
            value={props.value || options[0].value}
             />
    )

}

export default AIStrategySelect;