import { Input, Row, Space, Typography } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import StandardActorCard from "components/common/StandardActorCard";
import StandardBox from "components/common/StandardBox";
import StandardButton from "components/common/StandardButton";
import { StandardRow } from "components/layout/StandardGrid";
import useSelectorWithParams from "services/hooks/useSelectorWithParams"
import ProjectInvoiceSettings from "./ProjectInvoiceSettings";
import Tabs from "_components/tabs/tabs";
import ManageProviderIntegrations from "modules/panel/components/integrations/ManageProviderIntegrations";
import ProjectStatus from "./ProjectStatus";
import { ProjectEdit } from "./ProjectEdit";
import { currentProjectSelector } from "state/projects/projectSelectors";

const GeneralProjectSettings = () => {

    const project = useSelectorWithParams(currentProjectSelector);

    return (
        <Space direction="vertical" style={{ width: "100%", height: "100%" }}>
            <StandardRow>
                <StandardBox>
                    <StandardActorCard actor={project} />
                </StandardBox>
            </StandardRow>
            <StandardRow>
                <StandardBox>
                    <ProjectEdit />
                </StandardBox>
            </StandardRow>
            <StandardRow>
                <StandardBox>
                    <ProjectInvoiceSettings />
                </StandardBox>
            </StandardRow>
        </Space>
    )
}

const panes = [
    {
        title: (
            <FormattedMessage
                id="apps.panel.projectSettings.tabs.project"
                defaultMessage="Project"
            />
        ),
        key: "project",
        content: <GeneralProjectSettings />,
    }, 
    {
        title: (
            <FormattedMessage
                id="apps.panel.projectSettings.tabs.integrations"
                defaultMessage="Integrations"
            />
        ),
        key: "integrations",
        content: <ManageProviderIntegrations />,
    }, 
    {
        title: (
            <FormattedMessage
                id="apps.panel.projectSettings.tabs.status"
                defaultMessage="Status"
            />
        ),
        key: "status",
        content: <ProjectStatus />,
    }
]


export default function ProjectSettings() {




    return (
            <Tabs
                panes={panes}
            />
    )

}


{/* <Space direction="vertical" style={{ width: "100%", height: "100%" }}>
    <StandardRow>
        <StandardBox>
            <StandardActorCard actor={project} />
        </StandardBox>
    </StandardRow>

    <StandardRow>
        <StandardBox>
            <ProjectInvoiceSettings />
        </StandardBox>
    </StandardRow>
</Space> */}