import { Select } from "antd";
import StandardButton from "components/common/StandardButton";
import { RefreshIcon } from "modules/panel/components/ft-icons";

type Props = {
  data: any;
  call: () => void;
};

export const IntegrationSelect = ({data, call, ...rest}: Props) => {

  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: "98%" }}>
        <Select style={{ width: "100%" }}  {...rest}>
          {data &&
            data.map((item: any, index: string) => (
              <Select.Option key={index} value={item.id || index} disabled={item.disabled}>
                {item.name}
              </Select.Option>
            ))}
        </Select>
      </div>
      <div style={{ marginRight: "-20px" }}>
        <StandardButton onClick={call}>
          <RefreshIcon />
        </StandardButton>
      </div>
    </div>
  );
};
