import { Button, Space } from "antd"
import StandardButton from "components/common/StandardButton"
import { AddPage, GetPages, ProjectWebsitePagesSelector } from "modules/site-generator/reducers/websitePagesSlice"
import { useEffect, useMemo } from "react"
import FormattedMessage from "components/common/FormattedMessage";
import useAPI from "services/hooks/useAPI"
import useSelectorWithParams from "services/hooks/useSelectorWithParams"
import { WebsitePageListEntry } from "./WebsitePage"
import { WebsiteSelector } from "modules/site-generator/reducers/websiteSlice"


export default function WebsitePagesList (props:any) {

    const pages = useSelectorWithParams([ProjectWebsitePagesSelector]);
    const website = useSelectorWithParams([WebsiteSelector]);
    const {call: apiAdd_call} = useAPI(AddPage(website.id));
    const {data} = useAPI(GetPages(website.id),true);

    const mappedPages = useMemo(() => {
        if (!pages) 
        return (
        <div>  
        </div>
        )
        return Object.values(pages).map((page: any) => (
        <WebsitePageListEntry pageId={page.id} website={website}/>
        ))
    }, [pages])

    const defaultPage = {
        title: "Nowa Strona",
        type: "html",
        place: "root",
        status: "draft",
    }
    

    return (
        <>
        <StandardButton 
        onClick={() => apiAdd_call({
            body: defaultPage
        })}>
            <FormattedMessage
            id="apps.websites.customPages.addPage"
            defaultMessage="Add Page"
            />
        </StandardButton>
        <div style={{padding: "20px"}}></div>
        <Space wrap>
            {mappedPages}
        </Space>
        </>
    )

}