import React, { FunctionComponent, ReactNode } from "react";
import { RouteConfig } from "providers/routing/routes/routes";
import { Layout, Config } from "providers/routing/layouts/templates/templates";
import ProductLogo from "providers/routing/layouts/components/product-logo/current-product-logo";
import { CloseOutlined } from '@ant-design/icons';
import { Layout as AntLayout } from "antd";
import { useHistory } from "react-router-dom";
import EditLayoutContent from "providers/routing/layouts/templates/edit/content";
const { Header } = AntLayout;

export type EditLayoutRouteConfig = {
  layout: Layout.Edit;
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  tabs?: boolean;
} & RouteConfig;

const EditLayout: FunctionComponent<EditLayoutRouteConfig> = ({
  children,
  title,
  subtitle = false,
}) => {
  const { goBack } = useHistory();

  return (
    <AntLayout className="edit-page-layout">
      <Header className="header">
        <div className="container">
          <div className="left-side">
            <ProductLogo id="product-logo"/>
          </div>
          <div className="right-side">
            <h1 className="page-name">{title}</h1>
            <CloseOutlined onClick={goBack} />
          </div>
        </div>
      </Header>

      {children}
    </AntLayout>
  );
};

export default {
  key: Layout.Edit,
  layout: EditLayout,
  content: EditLayoutContent,
} as Config;
