//TODO: ANTI-PATTERN! move logic to middleware!
import React from "react";
import { Map } from "immutable";
import { notification } from "antd";
import forOwn from "lodash/forOwn";
import { setRequestStatusReducer } from "services/helpers/api-helpers";
import { CLEAR_ACTIONS_STATUSES } from "modules/panel/actions/request-status";
import * as Sentry from "@sentry/browser";

const errorReducer = (state = Map({}), action) => {
  const { type } = action;

  if (type === CLEAR_ACTIONS_STATUSES) return state.deleteAll(action.actions);

  const matches = /(.*)_(REQUEST|FAILURE)/.exec(type);
  if (!matches) return state;

  const [, requestName, requestState] = matches;

  if (requestState === "REQUEST")
    return setRequestStatusReducer(state, requestName, false, action.meta);

  let errorMessage = [];

  if (action.payload.response && action.payload.response.errors) {
    forOwn(action.payload.response.errors, (error, field) => {
      let errorDescrptionsArray = [];
      if (Array.isArray(error)) {
        error.forEach((description) =>
          errorDescrptionsArray.push(description.detail)
        );
      } else errorDescrptionsArray = [error];

      if (error && error.source && error.source.pointer) {
        errorMessage.push(
          <div key={error.source.pointer} className="api-error">
            {errorDescrptionsArray.map((description) => (
              <span
                key={description.source.pointer}
                className="description-error"
              >
                {description.detail}
              </span>
            ))}
          </div>
        );
      } else if (error && error.message) {
        errorMessage.push(
          <div key={error.message} className="api-error">
            {error.message}
          </div>
        );
      }
    });

    const { code, message, title } = action.payload.response.errors;
    if (code || message || title)
      errorMessage.push(
        <div key={code} className="api-error">
          <span key={code} className="description-error">
            <strong>{title}</strong> {message}
          </span>
        </div>
      );

      const simplifiedErrors = (action.payload.response.errors || []).map(e => e.detail);
      
      const requestBody = action?.payload?.requestBody || action?.payload?.failurePayload?.requestBody;


    Sentry.withScope(function (scope) {
      scope.setTag("type", "form-response-error");
      scope.setLevel("warning");
      //scope.setExtra("action.payload", action.payload);
      //scope.setExtra("action.payload.response", action.payload.response.errors);
      scope.setExtra("Errors", simplifiedErrors);
      scope.setExtra("RequestBody", requestBody);
      scope.setTag("buildDate", process.env.BUILD_DATE);
      const se = Sentry.captureException(new Error("Form error"));

      
    });

  } else {
    //console.log("weird error", action)
  }

  if (errorMessage.length)
    notification["error"]({
      message: action.payload.response.exception,
      description: errorMessage,
    });

  let errorValue =
    requestState === "FAILURE"
      ? {
          response: action.payload.response,
          viewMessage: errorMessage,
          fields:
            action.payload &&
            action.payload.response &&
            action.payload.response.errors
              ? parseAPIErrors(action.payload.response.errors)
              : null,
          code:
            action.payload &&
            action.payload.response &&
            action.payload.response.code
              ? action.payload.response.code
              : null,
        }
      : false;

  return setRequestStatusReducer(state, requestName, errorValue, action.meta);
};

export const parseAPIErrors = (errors) => {
  let errorsKeyInfo = [];
  if (Array.isArray(errors)) {
    errors.forEach((error) => {
      if (error && error.source && error.source.pointer)
        errorsKeyInfo[error.source.pointer.split("/")[3]] = error.detail;
    });
  } else {
    errorsKeyInfo = { ...errors };
  }
  return errorsKeyInfo;
};

export default errorReducer;
