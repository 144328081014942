import FormattedMessage from "components/common/FormattedMessage";
import URLInput from "components/inputs/URLInput";
import { GoogleSmartSettingsSelector, HasFilledCompetitorsWebsitesSelector, UpdateGoogleSmartSettings } from "modules/smart/smartSlice";
import ConnectedOnboardingControls from "providers/onboarding/controls";
import { useEffect } from "react";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import RelaxList from "tools/relaxForm/RelaxList";
import { ruleMin, ruleNoDuplicates } from "tools/relaxForm/relaxPresets";
import useRelaxData from "tools/relaxForm/useRelaxData";

const OnbaordingCompetitorWebsites = () => {

    const fullfilled = useSelectorWithParams(HasFilledCompetitorsWebsitesSelector);
    const settings = useSelectorWithParams(GoogleSmartSettingsSelector);

    const v = settings?.competitors_websites?.length ? settings.competitors_websites : [""];

    const rd = useRelaxData({
        name: "competitors_websites",
        initialValues: { competitors_websites: v },
        callAPI: UpdateGoogleSmartSettings,
        rules: [{noEmptyValues: true}],
    });

    return (
        <div>
            <RelaxList
                name="competitors_websites"
                relaxData={rd}
                required
                label={
                    <FormattedMessage
                        id="apps.forsant.businessDetails.competitorsWebsites"
                        defaultMessage="Your competitors’ websites"
                    />
                }
            >
                <URLInput />
            </RelaxList>
            <ConnectedOnboardingControls relaxDontSubmit={true} nextDisabled={!(fullfilled && !rd.hasErrors)} />
        </div>
    )

}

export default OnbaordingCompetitorWebsites;