import { Collapse, Spin } from "antd";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import { HorizontalCenter } from "components/common/Center";
import { ExampleClientGendersFromFacebookAdsSettingsSelector, GetAiProjectInfo, ProjectAIExampleClientByGenederSelector, ProjectHasAIProjectInfoSelector, ProjectHasDemographicSelector, ProjectHasDemographicsInCategorySelector, useGenerateAIDemographics } from "modules/panel/state/AISlice";
import AIClientAvatar from "modules/smart/components/AIGeneration/AIClientAvatar";
import AIDemographics from "modules/smart/components/AIGeneration/AIDemographics";
import { AIDemographicsCategoriesType } from "modules/smart/components/AIGeneration/AIDemographicsConfig";
import SettingsAI from "modules/smart/components/AIGeneration/SettingsAI"
import ConnectedOnboardingControls from "providers/onboarding/controls"
import { useOnboarding } from "providers/onboarding/onboarding-context";
import { useEffect, useRef } from "react";
import useAPI from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";

const OnboardingAIClientAvatar = (props: {gender: "male" | "female"}) => {
    

    const fullfilled = useSelectorWithParams([ProjectAIExampleClientByGenederSelector, props.gender]);
    const {nextStep} = useOnboarding();
    
    const exampleClientGenders = useSelectorWithParams(ExampleClientGendersFromFacebookAdsSettingsSelector);

    useEffect(() => {
        if (exampleClientGenders && exampleClientGenders.length > 0 && !exampleClientGenders.includes(props.gender)) nextStep();
    }, [exampleClientGenders]);

    return (
        <>
            <HorizontalCenter>
                <AIClientAvatar gender={props.gender} />
            </HorizontalCenter>
            <ConnectedOnboardingControls relaxDontSubmit = {true} nextDisabled={!fullfilled}/>
        </>
    )

}

export default OnboardingAIClientAvatar;