import React, { FunctionComponent, ReactNode, ComponentClass, Suspense } from "react";
import { Layout } from "providers/routing/layouts/templates/templates";
import { Route as RouterRoute } from "react-router-dom";
import ConditionalWrapper from "_components/conditional-wrapper";
import { Platform } from "modules";

export type RouteConfig = {
  key: string;
  path: string;
  getUrl?: () => string;
  icon?: ReactNode;
  public?: boolean;
  badge?: any;
  layout: Layout;
  disabled?: boolean;
  name?: string | ReactNode;
  displayInMenu?: boolean;
  content: FunctionComponent<any> | ComponentClass<any> | RouteConfig[];
  requiredPermission?: boolean;

  onboarding?: boolean; //TODO: UNTIL NEW ONBOARDING!
  classes?: string[]; //new
  integration?: Platform;

  submenu?: {
    key: string;
    name: string | ReactNode;
    path: string;
  }[];
};

export type FlatRouteConfig = {
  content: FunctionComponent<any> | ComponentClass<any>;
} & RouteConfig;

type RoutesProps = {
  routes: FlatRouteConfig[];
  wrapper?: FunctionComponent<any> | ComponentClass<any>;
};

const Routes: FunctionComponent<RoutesProps> = ({
  routes,
  wrapper,
}) => routes.map(({ path, key, content: Content, ...rest }) => (
  <Suspense fallback={<div>Loading...</div>}>
      <RouterRoute
        key={`${key}Layout`}
        path={path}
        component={() => {
          const Wrapper = wrapper as
            | FunctionComponent<any>
            | ComponentClass<any>;

          return (
            <ConditionalWrapper
              condition={!!Wrapper}
              wrapper={(children) => <Wrapper {...rest}>{children}</Wrapper>}
            >
              <Content />
            </ConditionalWrapper>
          );
        }}
      />
  </Suspense>
    ));

export default Routes;
