import { Collapse, Input, Tooltip } from "antd";
import StandardButton from "components/common/StandardButton";
import { useEffect, useMemo } from "react";
import FormattedMessage from "components/common/FormattedMessage";
import { faCheckCircle, faLeft, faPlus, faPlusCircle, faRight, faTrashCan, faTrashCircle } from "@fortawesome/pro-light-svg-icons";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import { GalleryWrapperForPostFormSingle } from "./GalleryWrappers";
import { ruleMax, ruleMin, rulesetMinMax } from "tools/relaxForm/relaxPresets";
import URLInput from "components/inputs/URLInput";
import CTASelect from "tools/creationEditor/CTASelect";
import { CTAFieldRenderProps } from "modules/smart/components/create-post/form-generator";
import { useCreationEditor } from "../CreationEditor";
import useDataPrefill from "services/hooks/useDataPrefill";
import { creationContentSelector, patchCreation, patchCreationWithSmartAction } from "../creationsSlice";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";
import useAPI from "services/hooks/useAPI";
import _ from "lodash";
import CreationSection, { SectionSimpleStateCheck } from "../editorTools/CreationSection";
import AwesomeIcon from "components/common/AwesomeIcon";
import useMemoDeepCompare from "services/hooks/useMemoDeepCompare";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { ValidationResult } from "../editorTools/ValidationResults";
import { createPortal } from "react-dom";
import { useCreationSection } from "../editorTools/useCreationSection";
import { creationSlideSelector } from "../creationHelpers";

const isFieldEmpty = (slide: any, field: string | string[]) => {
    return _.get(slide, field) === undefined || _.get(slide, field) === null;
}

const CarouselCard = (props: {
    removeItem: (index: number) => void,
    numberOfItems: number,
    carouselIndex: number,
    slideId: number,
}) => {

    const slideId = props.slideId;
    const sectionName = `card-${props.slideId}`;

    const { creation, creationId } = useCreationEditor()
    const slide = useSelectorWithParams([creationSlideSelector, creationId, slideId])
    const { ref, status, setStatusData, handleFieldError, sectionComponentProps } = useCreationSection({
        sectionName: sectionName,
        smartActionPrefix: `ce_card_${props.slideId}`,
        filterValidationResults: (errors: any) => {
            const matchingKeys = Object.keys(errors).filter((key) => key.startsWith(`slides.${props.slideId}`));
            return _.pick(errors, matchingKeys);
        },
    });


    useEffect(() => {
        if (!slide) setStatusData("initial");
        const checks = ["headline", "media", ["cta", "data"],["cta","type"]];
        const emptyFields = checks.filter((field) => isFieldEmpty(slide, field));
        if (emptyFields.length === 0) {
            setStatusData("ready");
        } else {
            setStatusData("error");
        }
    }, [slide]);

    const queryAndInjectError = (field: string, errors: string[]) => {
        const elem = document.querySelector(`#creationCard-${slideId} #rf-${field} .extra-error`);
        if (!elem) return [];
        return errors.map((error, i) => createPortal(<ValidationResult result={error} />, elem))
    }


    const validationErrors = () => {
        if (!sectionComponentProps?.validationErrors) return null;
        const errors:Record<string, string[]> = sectionComponentProps.validationErrors as Record<string, string[]>;
        
        if (!errors) return null;

        const errorDisplays:any = [];
        Object.keys(errors).forEach((key) => {
            if (key.endsWith("headline")) errorDisplays.push(...queryAndInjectError("headline", errors[key]));
            if (key.endsWith("description")) errorDisplays.push(...queryAndInjectError("description", errors[key]));
            if (key.endsWith("media")) errorDisplays.push(...queryAndInjectError("media", errors[key]));
            if (key.endsWith("cta.data")) errorDisplays.push(...queryAndInjectError("cta-data", errors[key]));
            if (key.endsWith("cta.type")) errorDisplays.push(...queryAndInjectError("cta-type", errors[key]));
        })

        return errorDisplays;

    }


    if (!slide) return null


    return (
        <div
            id={`creationCard-${slideId}`}
        >
            <RelaxMultiform
                name="carouselItems"
                callAPI={patchCreationWithSmartAction(creationId, `ce_card_${props.slideId}`)}
                initialValuesSelector={[creationSlideSelector, creationId, slideId]}
                finalizeSubmit={(values: any) => {
                    return {
                        content: {
                            slides: {
                                [slideId]: values
                            }
                        }
                    }
                }}
            >
                <div>
                    <RelaxSingleField
                        name={["media"]}
                        onErrors={(errors: any) => handleFieldError("media", errors)}
                        callAPI={patchCreationWithSmartAction(creationId, `ce_card_${props.slideId}_media`)}
                        label={<FormattedMessage
                            id="apps.smart.containers.createPostAndAd.form.media"
                            defaultMessage="Media"
                        />}
                    >
                        <GalleryWrapperForPostFormSingle />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["headline"]}
                        onErrors={(errors: any) => handleFieldError("headline", errors)}
                        callAPI={patchCreationWithSmartAction(creationId, `ce_card_${props.slideId}_headline`)}
                        label={<FormattedMessage
                            id="apps.smart.containers.createPostAndAd.form.headline"
                            defaultMessage="Headline"
                        />}
                        required
                        rules={rulesetMinMax(3, 90)}
                    >
                        <Input />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["description"]}
                        onErrors={(errors: any) => handleFieldError("description", errors)}
                        callAPI={patchCreationWithSmartAction(creationId, `ce_card_${props.slideId}_description`)}
                        label={<FormattedMessage
                            id="apps.smart.containers.createPostAndAd.form.description"
                            defaultMessage="description"
                        />}
                        rules={[ruleMin(3)]}
                    >
                        <Input />
                    </RelaxSingleField>
                </div>
                <div style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 300px",
                    gap: "20px"
                }}>
                    <RelaxSingleField
                        name={["cta", "data"]}
                        onErrors={(errors: any) => handleFieldError("data", errors)}
                        callAPI={patchCreationWithSmartAction(creationId, `ce_card_${props.slideId}_cta_data`)}
                        label={<FormattedMessage
                            id="apps.smart.containers.createPostAndAd.form.callToActionLink"
                            defaultMessage="Call to action link"
                        />}
                        required
                        rules={[ruleMax(512)]}
                    >
                        <URLInput />
                    </RelaxSingleField>
                    <RelaxSingleField
                        name={["cta", "type"]}
                        onErrors={(errors: any) => handleFieldError("type", errors)}
                        callAPI={patchCreationWithSmartAction(creationId, `ce_card_${props.slideId}_cta_type`)}
                        label={<FormattedMessage
                            id="apps.smart.containers.createPostAndAd.form.callToAction"
                            defaultMessage="Call to action"
                        />}
                        required
                    >
                        <CTASelect mode={"single"} actions={(props as CTAFieldRenderProps).actions} />
                    </RelaxSingleField>

                </div>
            </RelaxMultiform>
            <div style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
            }}>
                <Tooltip title={
                    <FormattedMessage
                        id="components.forms.list.delete"
                        defaultMessage="Remove"
                    />}>
                    <StandardButton
                        icon={faTrashCan}
                        disabled={props.numberOfItems <= 1}
                        onClick={() => props.removeItem && props.removeItem(slideId)}
                    />
                </Tooltip>
            </div>
            {validationErrors()}
        </div>
    )

}

export default CarouselCard;
