import useAPI from "services/hooks/useAPI";
import PricingTable from "./PricingTable"
import { GetWebsitePriceList, GetWebsitePriceTemplates, NewPriceTemplate, ProjectWebsitePriceListSelector, ProjectWebsitePricesSelector, TemplateProps, WebsitePricingTemplatesForCategorySelector } from "modules/site-generator/reducers/websitePricesSlice";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import FormattedMessage from "components/common/FormattedMessage";
import Tabs from "_components/tabs/tabs";
import { WebsiteIndustrySelector } from "modules/site-generator/reducers/websiteSlice";
import { useEffect, useMemo, useState } from "react";
import { Select, Space } from "antd";
import StandardButton from "components/common/StandardButton";

const TemplateSelect = ({templates, category} : {category: string, templates:TemplateProps[]}) => {

    const newTableCall = useAPI({});
    const [selectedTemplate, setSelectedTemplate] = useState<any>(null);
    const getPriceLists = useAPI({})

    const options = useMemo(() => {
        if (!templates) return [];

        return templates.map((template:TemplateProps) => {
            return {
                label: template.name,
                value: template.id,
            }
        })

    }, [templates])

    return (
        <Space direction="horizontal" wrap style={{
            paddingTop: "50px"
        }}>
            <Select style={{
                minWidth: "200px"
            }}
            onChange={setSelectedTemplate}
            options={options}
            />
            <StandardButton 
            type="primary" 
            loading={newTableCall.loading}
            disabled={!selectedTemplate}
            onClick={() => {
                if (selectedTemplate) {
                    newTableCall.call(NewPriceTemplate(selectedTemplate)).then((res)=>{
                      if(res.status === 201 || res.status === 200){
                        getPriceLists.call(GetWebsitePriceList(category));
                      }
                    })
                }
            }}>
                <FormattedMessage id="apps.websites.containers.priceList.tabs.create" defaultMessage="Create" />
            </StandardButton>
        </Space>
    )

}

const PricingCategory = ({ category }: { category: string }) => {

    const {} = useAPI(GetWebsitePriceList(category), true);
    const prices = useSelectorWithParams([ProjectWebsitePriceListSelector, category])
    const templates = useSelectorWithParams([WebsitePricingTemplatesForCategorySelector, category])

    return (
        <>
        <div>
            {prices && prices.map && prices.map((price:any) => {

                return (
                    <PricingTable table={price} templates={templates}/>
                )
            })
            }
        </div>
        <TemplateSelect category={category} templates={templates}/>
        </>
    )
}



const carsPanes = [
    {
      title: (
        <FormattedMessage
          id="apps.websites.containers.priceList.tabs.tiresAndWheels"
          defaultMessage="Replacement of tires and wheels"
        />
      ),
      key: "tiresAndWheels",
      content: <PricingCategory category="tires_exchange" />,
    },
    {
      title: (
        <FormattedMessage
          id="apps.websites.containers.priceList.tabs.airConditioningService"
          defaultMessage="air conditioning service"
        />
      ),
      key: "airConditioningService",
      content: <PricingCategory category="air_conditioning_service" />,
    },
    {
      title: (
        <FormattedMessage
          id="apps.websites.containers.priceList.tabs.regenerationOfRims"
          defaultMessage="Regeneration of rims"
        />
      ),
      key: "regenerationOfRims",
      content: <PricingCategory category="rims_regeneration" />,
    },
    {
      title: (
        <FormattedMessage
          id="apps.websites.containers.priceList.tabs.carMechanics"
          defaultMessage="car mechanics"
        />
      ),
      key: "carMechanics",
      content: <PricingCategory category="car_service" />,
    },
  ];
  
const tourismPanes = [
    {
      title: (
        <FormattedMessage
          id="apps.websites.containers.priceList.tabs.tourismRooms"
          defaultMessage="Rooms"
        />
      ),
      key: "tourism_rooms",
      content: <PricingCategory category="tourism_rooms" />,
    },
    {
      title: (
        <FormattedMessage
          id="apps.websites.containers.priceList.tabs.tourismApartments"
          defaultMessage="Apartments"
        />
      ),
      key: "tourism_apartments",
      content: <PricingCategory category="tourism_apartments" />,
    },
    {
      title: (
        <FormattedMessage
          id="apps.websites.containers.priceList.tabs.tourism_cottages"
          defaultMessage="Cottages"
        />
      ),
      key: "tourism_cottages",
      content: <PricingCategory category="tourism_cottages" />,
    },
    {
      title: (
        <FormattedMessage
          id="apps.websites.containers.priceList.tabs.tourism_food"
          defaultMessage="Food"
        />
      ),
      key: "tourism_food",
      content: <PricingCategory category="tourism_food" />,
    },
    {
      title: (
        <FormattedMessage
          id="apps.websites.containers.priceList.tabs.tourism_spa"
          defaultMessage="Spa"
        />
      ),
      key: "tourism_spa",
      content: <PricingCategory category="tourism_spa" />,
    },
    {
      title: (
        <FormattedMessage
          id="apps.websites.containers.priceList.tabs.tourism_additional"
          defaultMessage="Additional"
        />
      ),
      key: "tourism_additional",
      content: <PricingCategory category="tourism_additional" />,
    },
  ];


const WebsitePriceConfig = () => {

    const templatesCall = useAPI(GetWebsitePriceTemplates, true);

    const industry = useSelectorWithParams(WebsiteIndustrySelector);
  
    const panes = useMemo(() => {
      if (industry === "touristic") return tourismPanes;
      return carsPanes;
    }, [industry])
  
    return <Tabs className="ant-tabs-sm" panes={panes} />;


}


export default WebsitePriceConfig;