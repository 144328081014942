import { Divider, Drawer as AntDrawer, Modal } from "antd";
import StandardButton from "components/common/StandardButton";
import React, { Children, ReactElement, ReactNode, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import useAPI, { APIErrorDisplay, callAPISettings, getCallAPIProps } from "./useAPI";

export type ModalProps = {
    mask?: "full" | "half" | "none" | true | false;
    layout?: "standard" | undefined;
    children?: null | ReactElement | ReactElement[];
    footer?: ReactElement | undefined;
    title?: string | ReactElement | undefined;
    width?: number | string | undefined;
    form?: any;
    modalProps?: any;
    onCancel?: () => void;
    onOk?: () => void;
    loading?: boolean;
}

export const StandardModal = React.forwardRef((props: ModalProps, ref: any) => {

    const [isOpen, setIsOpen] = useState(false);

    const setOpen = (open: boolean) => setIsOpen(open);

    useImperativeHandle(ref, () => ({
        isOpen,
        setIsOpen,
    }))

    const handleClose = () => {
        setOpen(false);
        props.onCancel && props.onCancel();
    }

    const handleOk = () => {
        props.onOk && props.onOk()
    }

    return (
        <Modal
            visible={isOpen}
            onCancel={handleClose}
            title={props.title}
            onOk={handleOk}
            okButtonProps={{ loading: props.loading }}
            className={`standard-modal ${props.layout}`}
            zIndex={1000}
            {...props.modalProps}
        >
            {props.children}
        </Modal>
    )


})

export const useStandardModal = (r?:any) => {

    const ref: any = useRef(r);
    const open = () => ref.current?.setIsOpen(true);
    const close = () => ref.current?.setIsOpen(false);

    return [ref, open, close]
}

export const useSimpleModal = () => {


    const [modal, contextHolder] = Modal.useModal();

    const open = (props: any) => {
        if (props.type) {
            modal[props.type](props)
            return;
        }
        modal.info(props)
    }

    return [open, contextHolder]

}

type SimpleModalWithActionProps = {
    callAPI: callAPISettings,
    successMessage?: ReactNode, 
    successProps?: any,
} & any;

type SimpleModalWithActionReturn = [
    open: (props?: any) => void,
    contextHolder: ReactNode
]

export const useSimpleModalWithAction = (props:SimpleModalWithActionProps):SimpleModalWithActionReturn => {


    const [modal, contextHolder] = Modal.useModal();
    const apiAction = useAPI(props.callAPI)
    const conf = useRef<any>();

    const open = (openProps?: any) => {
        let modalProps = {...props, ...openProps || {}};

        modalProps.onOk = () => {

            const callOpenProps = getCallAPIProps(openProps?.callAPI);
            const callProps = {...(props.callAPI && props.callAPI || {}), ...callOpenProps}

            apiAction.call(callProps).then((res:any) => {
                if (res.status === 200 || res.status === 204 || res.status === 201) {
                    props.successProps && success()
                    openProps.onOk && openProps.onOk(res)
                } else {
                    error(res)
                }
            })


        }
            
        
       conf.current = modal.confirm(modalProps)
       
       return conf.current;

    }

    const success = () => {

        if (conf.current) conf.current.destroy();

        modal.success({
            title: "Success",
            ...props.successProps,
        })
    }

    const error = (errorProps?: any) => {

        if (conf.current) conf.current.destroy();

        modal.error({
            title: "Error",
            content: <APIErrorDisplay error={errorProps.error} />,
            ...errorProps})
    }

    return [open, contextHolder]

}

export const StandardModalAction = (props: any) => {

    const [open, contextHolder] = useSimpleModalWithAction(props.actionProps);

    const handleOpen = () => {
        open();
    }

    return (
        <>
        <StandardButton {...props.buttonProps} onClick={handleOpen}>
            {props.buttonContent}
        </StandardButton>
        {contextHolder}
        </>
    )



}