import { subscriptionCancellationPlanSelector } from "state/subscriptions/subscriptionsSelectors";
import { useCancellationWizard } from "./SubscriptionCancellationWizard";
import { Text, Title } from "components/common/StandardText";
import FormattedMessage from "components/common/FormattedMessage";
import dayjs from "dayjs";
import { FormattedNumber, useIntl } from "react-intl";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { Descriptions, Divider, Table } from "antd";
import { CancellationPlansConfig } from "./SubscriptionCancellationConfig";


function SubscriptionCancellationPlanDisplay() {

    const { subscriptionId, goToStep, plan, subscription } = useCancellationWizard();
    const planData = useSelectorWithParams([subscriptionCancellationPlanSelector, subscriptionId, plan])

    if (!plan) return null;
    if (!planData) return null;
    if (!planData.stats) return null;

    const currency = planData.stats.currency;

    return (
        <div>
            <Title level={2}>
            {CancellationPlansConfig[plan].title}
            </Title>
            <Divider/>
            <div style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
            }}>
                <div>
                    <FormattedMessage
                        id="panel.subscriptions.cancellation.planSummary.contractStartDate"
                        defaultMessage="Contract signed on"
                    />
                </div>
                <div>
                    {dayjs(subscription.created_at).format("L")}
                </div>

                <div>
                    <FormattedMessage
                        id="panel.subscriptions.cancellation.planSummary.contractEndDate"
                        defaultMessage="Contract ends on"
                    />
                </div>
                <div>
                    {dayjs(subscription.ends_at).format("L")}
                </div>  

                <div>
                    <FormattedMessage
                        id="panel.subscriptions.cancellation.planSummary.contractExtensionDate"
                        defaultMessage="Contract extended on"
                    />
                </div>

                <div>
                    {dayjs(subscription.ends_at).subtract(14,"day").format("L")}
                </div>
            </div>

            <Divider />

            {/* <Table
                dataSource={planData.stats}
                columns={[
                    {
                        title: <FormattedMessage
                            id="panel.subscriptions.cancellation.planSummary.items.name"
                            defaultMessage="Item"
                        />,
                        dataIndex: "name",
                        key: "name",
                    },
                    {
                        title: <FormattedMessage
                            id="panel.subscriptions.cancellation.planSummary.items.price"
                            defaultMessage="Price"
                        />,
                        dataIndex: "price",
                        key: "price",
                        render: (value:number) => <FormattedNumber value={value} style="currency" currency={currency} />
                    },
                    {
                        title: <FormattedMessage
                            id="panel.subscriptions.cancellation.planSummary.items.quantity"
                            defaultMessage="Quantity"
                        />,
                        dataIndex: "quantity",
                        key: "quantity",
                    },
                    {
                        title: <FormattedMessage
                            id="panel.subscriptions.cancellation.planSummary.items.total"
                            defaultMessage="Total"
                        />,
                        dataIndex: "total",
                        key: "total",
                        render: (value:number) => <FormattedNumber value={value} style="currency" currency={currency} />
                    }
                ]}
                pagination={false}
            /> */}


            <div style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
            }}>

                <div>
                    <FormattedMessage
                        id="panel.subscriptions.cancellation.planSummary.promotionalSum"
                        defaultMessage="Promotional Sum"
                    />
                </div>
                <div>
                    <FormattedNumber value={planData.stats.promotional_sum} style="currency" currency={currency} />
                </div>

                <div>
                    <FormattedMessage
                        id="panel.subscriptions.cancellation.planSummary.sum"
                        defaultMessage="Sum"
                    />
                </div>

                <div>
                    <FormattedNumber value={planData.stats.sum} style="currency" currency={currency} />
                </div>

                <div>
                    <FormattedMessage
                        id="panel.subscriptions.cancellation.planSummary.endOfAgreement"
                        defaultMessage="End of Agreement"
                    />
                </div>

                <div>
                    {dayjs(planData.stats.end_of_agreement).format("L")}
                </div>

                <div>
                    <FormattedMessage
                        id="panel.subscriptions.cancellation.planSummary.debts"
                        defaultMessage="Debts"
                    />
                </div>

                <div>
                    <FormattedNumber value={planData.stats.debts} style="currency" currency={currency} />
                </div>

                <div>
                    <FormattedMessage
                        id="panel.subscriptions.cancellation.planSummary.prompts"
                        defaultMessage="Prompts"
                    />
                </div>

                <div>
                    <FormattedNumber value={planData.stats.prompts} style="currency" currency={currency} />
                </div>

                <div>
                    <FormattedMessage
                        id="panel.subscriptions.cancellation.planSummary.debtCollectionCosts"
                        defaultMessage="Debt Collection Costs"
                    />
                </div>

                <div>
                    <FormattedNumber value={planData.stats.debt_collection_costs} style="currency" currency={currency} />
                </div>

                <div>
                    <FormattedMessage
                        id="panel.subscriptions.cancellation.planSummary.termination"
                        defaultMessage="Termination"
                    />
                </div>

                <div>
                    <FormattedNumber value={planData.stats.termination} style="currency" currency={currency} />
                </div>

            </div>

            <div>
                <Text>
                    <FormattedMessage
                        id="panel.subscriptions.cancellation.planSummary.total"
                        defaultMessage="Total"
                    />
                    <FormattedNumber value={planData.stats.total} style="currency" currency={currency} />
                </Text>
            </div>
            <div>
                <Text>
                Your subscription will be cancelled on {dayjs(planData.stats.end_of_agreement).format("L")}
                </Text>
            </div>
        </div>
    );
}

export default SubscriptionCancellationPlanDisplay;