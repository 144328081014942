import { Drawer, Modal } from "antd";
import { UploadFile } from "antd/es/upload/interface";
import { useEffect, useMemo, useState } from "react";

import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
  } from 'NewReactImageCrop'
import ImageCrop from "./imageCrop";
import { useGallery } from "./Gallery";
import './image-gallery.less'

export default function ImageCropperModal (props:any) {


    const {croppedImage, closeCropper, changeCrop} = useGallery();

    const [isDragging, setIsDragging] = useState(false);

    const handleCancel = () => {
        closeCropper()
    }

    const handleOk = () => {
        closeCropper()
    }


        if (!croppedImage) return <></>;
        return (
            <Modal
                visible={true}
                onCancel={handleCancel}
                onOk={handleOk}
                maskClosable={!isDragging}
                closable={false}
                wrapClassName="cropper-modal"
                footer={null}
                width={"max-content"}
            >
                {/* This mask prevents modal from closing when user releases drag while outside of the cropper modal */}
                <div
                    className={`cropper-mask ${isDragging && "dragging"}`}>
                </div>
                <ImageCrop
                    image={croppedImage}
                    mode={props.mode}
                    onDragStart={() => setIsDragging(true)}
                    onDragEnd={() => setIsDragging(false)}
                    onCancel={handleCancel}
                />

            </Modal>
        )
    


}