import {createSlice, createSelector} from '@reduxjs/toolkit';
import { callAPIProps } from 'services/hooks/useAPI';


export type WebsiteTemplateState = any;

const initialState: WebsiteTemplateState = {
    templates: [],
    colorThemes: [],
}


export const websiteTemplateState = createSlice({
    name: "templates",
    initialState, 
    reducers: {
        get: (state, action) => {
            state.templates = action.payload.data;
            state.colorThemes = action.payload.included;
        },
        update: (state, action) => {
            state.templates = action.payload.data;
            state.colorThemes = action.payload.included; 
        }
    }
})


export const GetWebsiteTemplates = ():callAPIProps => ({
    url: ({ getApiUrl, language, projectId}: any) => getApiUrl(`projects/${projectId}/website/templates`,2),
    method: "GET",
    successDispatch: websiteTemplateState.actions.get,
})

export const ProjectWebsiteTemplatesSelector = createSelector([
    (state:any) => state?.siteGenerator.templates
],
    (state:any) => state.templates
)

export const ProjectWebsiteColorThemesSelector = createSelector([
    (state:any) => state?.siteGenerator.templates
],
    (state:any) => state.colorThemes
)



export default websiteTemplateState.reducer;