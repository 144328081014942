import React from "react";
import { Tabs } from "antd";




const SegmentRight = ({size = 50}: {size: number}) => (
  <>
    <svg version="1.1"
      viewBox="0 0 100 100"
      className="border right"
      style={{
        width: size,
        position: "absolute",
        right: -size * 0.5,
        bottom: "0px",
      }}>
      <path
        d="M 100 95 Q 70 95 50 50 Q 30 5 0 5"
      />
    </svg>
    <svg
      version="1.1"
      viewBox="0 0 100 100"
      className="background right"
      style={{
        width: size,
        position: "absolute",
        right: size * -0.5,
        bottom: "0px",
      }}>
      <path
        d="M 100 95 Q 70 95 50 50 Q 30 5 0 5 L -5 5 L -5 100 L 100 100 L 100 95"

      />
    </svg>
  </>
)

const SegmentLeft = ({size = 50}: {size: number}) => (
  <>
    <svg
      version="1.1"
      viewBox="0 0 100 100"
      className="border left"
      style={{
        width: size,
        position: "absolute",
        left: size * -0.5,
        bottom: "0px",
      }}>
      <path
        d="M 0 95 Q 30 95 50 50 Q 70 5 100 5"
      />
    </svg>
    <svg
      version="1.1"
      viewBox="0 0 100 100"
      className="background left"
      style={{
        width: size,
        position: "absolute",
        left: size * -0.5,
        bottom: "0px",
      }}>
      <path
        d="M 0 95 Q 30 95 50 50 Q 70 5 100 5 L 100 100 L 0 101 L 0 95"
      />

    </svg>
  </>
)

const SegmentLeftStart = ({size = 50}: {size: number}) => (
  <>
    <svg
      version="1.1"
      viewBox="0 0 100 100"
      className="border left"
      style={{
        width: size,
        position: "absolute",
        left: size * -0.5 - 1.5,
        bottom: "0px",
        overflow: "visible",
      }}>
      <path
        d="M 55 96 L 55 50 Q 70 5 100 5 L 105 5"
      />
    </svg>
    <svg
      version="1.1"
      viewBox="0 0 100 100"
      className="background left"
      style={{
        width: size,
        position: "absolute",
        left: size * -0.5 - 1,
        bottom: "0px",
      }}>
      <path
        d="M 55 100 Q 55 73 55 50 Q 70 5 100 5 L 100 100"
      />

    </svg>
  </>
)

const SegmentCenter = ({size = 50}: {size: number}) => (
  <>
    <svg
      version="1.1"
      viewBox="0 0 100 100"
      className="border center"
      style={{
        height: size,
        position: "absolute",
        left: size / 2,
        right: "0px",
        bottom: "0px",
        width: `calc(100% - ${size}px)`
      }}
      preserveAspectRatio="none"
    >
      <path
        d="M 0 5 L 100 5"
      />
    </svg>
    <svg
      version="1.1"
      viewBox="0 0 100 100"
      className="background center"
      style={{
        height: size,
        position: "absolute",
        left: size * 2/5,
        right: "0px",
        bottom: "0px",
        width: `calc(100% - ${size * 0.8}px)`
      }}
      preserveAspectRatio="none"
    >
      <path
        d="M 100 5 L 100 100 L 0 100 L 0 5"
      />
    </svg>
  </>
)

const SegmentBottom = ({size = 50}: {size: number}) => (
  <svg 
  version="1.1" 
  viewBox="0 0 100 100" 
  className="bottom border center"
  style={{
    height: size,
    position: "absolute",
    left: size * -0.4,
    bottom: "0px",
    width: `calc(100% + ${size}px)`
  }}
    preserveAspectRatio="none"
  >
    <path
      d="M 0 95 L 100 95"
      />
  </svg>
)

type SmoothTabBorderProps = {
  size?: number,
  first?: boolean,
  offset?: [number, number, number],
  zIndex?: number,
}

export const SmoothTabBorder = ({
  size = 50, 
  first = false, 
  offset=[0, 0, -2], 
  zIndex = -1
}:SmoothTabBorderProps) => {


  return (
    <div
      className="smooth-tab"
      style={{
        height: size || "50px",
        position: "absolute",
        right: offset[0],
        left: offset[1],
        bottom: offset[2],
        zIndex: zIndex,
      }}
    >
      {first ? <SegmentLeftStart size={size}/> : <SegmentLeft size={size}/>}
      <SegmentCenter size={size}/>
      <SegmentRight size={size}/>
      <SegmentBottom size={size}/>
    </div>
  )
}
