import { AppConfig } from "modules";
import routes, { APP_PATH } from "modules/site-generator/config/routes";
// import store from "apps/site-generator/reducers/reducers";

export default {
  key: "SiteGenerator",
  path: APP_PATH,
  routes,
  requirements: [],
  providers: ["facebook", "google"],
  // store,
} as AppConfig;
