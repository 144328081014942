import { createSelector, createSlice } from "@reduxjs/toolkit"
import useAPI, { callAPIFunction, callAPIProps } from "services/hooks/useAPI";
import { tasksSlice } from "tools/tasks/data/tasksSlice";
import { CreationType, EmptyCreationByType } from "./CreationConfig";
import dayjs, { Dayjs } from "dayjs";
import { userSettingsInterfaceCreationListSelector } from "state/user/userSettingsSlice";

export type CreationContent = {
    creation_id?: number;
    provider?: string[];
    message?: string;
    media?: any[];
    publication_type?: string;
    saveDraft?: boolean;
    budget?: number;
    events?: string[]
    [key: string]: any;
}

export type Creation = {
    id: number;
    project_id: number;
    status: "draft" | "removed" | "published" | "scheduled" | "inReview" | "bound_to_task" | "awaiting_acceptation";
    publish_at: null | string;
    type: 'post' | 'aoa';
    content: CreationContent;
    preview: null | string;
    preview_generation_time: null | string;
    campaign_id: null | number;
    template_id: null | number;
    group_display_name: null | string;
    error_message: null | string;
    created_at: string;
    updated_at: string;
    active_task_id: null | number;
    validated?: boolean
    errors?: any
}

export type CreationsSliceState = {
    list: Record<number, Creation>;
}

const initialState: CreationsSliceState = {
    list: {},
}

export type CreationListFilter = {
    showPosts?: boolean,
    showAOA?: boolean,
    sort?: "created_at" | "updated_at" | "publish_at" | "-created_at" | "-updated_at" | "-publish_at",
    statusFilter?: {
        [key: string]: boolean
    }
}

type DateFilter = {
    from?: Dayjs,
    to?: Dayjs,
}

type CreationFilterWithDates = CreationListFilter & DateFilter

const addOrUpdateCreation = (state: CreationsSliceState, creation: Creation) => {
    //console.log("Adding or updating creation", creation)
    const id = creation?.id;
    if (!id) return;
    state.list[id] = creation;
}

export const creationsSlice = createSlice({
    name: 'creations',
    initialState,
    reducers: {
        getAll: (state, action) => {
            state.list = {};
            action.payload.data.forEach((creation: Creation) => {
                addOrUpdateCreation(state, creation);
            })
        },
        getAllAndAdd: (state, action) => {
            action.payload.data.forEach((creation: Creation) => {
                addOrUpdateCreation(state, creation);
            })
        },
        get: (state, action) => {
            addOrUpdateCreation(state, action.payload.data);
        },
        getPreview: (state, action) => {
            const id = action?.payload?.additional?.id
            if (!id) return;
            try {
                const newPreviewLink = action?.payload?.data?.link;
                if (!newPreviewLink) return;
                if (!state.list[id]) return;
                state.list[id].preview = action?.payload?.data?.link;
            } catch (e) {
                console.error("Error setting preview", e)
            }
        },
        create: (state, action) => {
            addOrUpdateCreation(state, action.payload.data);
        },
        update: (state, action) => {
            addOrUpdateCreation(state, action.payload.data);
        },
        optimisticUpdate: (state, action) => {
           // console.log("Optimistic update", action)
            const id = action?.payload?.additional?.id
            if (!id) return;
            state.list[id].content = { ...state.list[id].content, ...action.payload.content }
        },
        delete: (state, action) => {
           // console.log("Removing creation", action)
            const id = action?.payload?.additional?.id
            state.list[id].status = "removed"
            // if (id) {
            //     delete state.list[id];
            // }
        },
        validate: (state, action) => {
          //  console.log("Validating creation", action)
            state.list[action.payload.id].validated = action.payload.isValid;
        },
        publish: (state, action) => {
           // console.log("Publishing creation", action)
            const creation = action.payload?.data;
            if (!creation) return;
            addOrUpdateCreation(state, creation);
        },
        requestPublication: (state, action) => {
           // console.log("Publishing creation request", action)
            const creation = action.payload?.data;
            if (!creation) return;
            addOrUpdateCreation(state, creation);
        },
        clear: (state, action) => {
            state.list = {};
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(tasksSlice.actions.acceptTask, (state, action) => {
               // console.log("acceptTask in creationslice", action.payload)
                if (action?.payload?.included) {
                    action?.payload?.included.forEach((i: any) => {
                        if (i && i._type === "creations") addOrUpdateCreation(state, i)
                    })
                }
            })
            .addCase(tasksSlice.actions.rejectTask, (state, action) => {
               // console.log("reject in creationslice", action.payload)
                if (action?.payload?.included) {
                    action?.payload?.included.forEach((i: any) => {
                        if (i && i._type === "creations") addOrUpdateCreation(state, i)
                    })
                }
            })
            .addCase(tasksSlice.actions.getProjectTasks, (state, action) => {
                if (action?.payload?.included) {
                    action?.payload?.included.forEach((i: any) => {
                        if (i && i._type === "creations") addOrUpdateCreation(state, i)
                    })
                }
            })
    }
})

const getDisallowedStatues = (filter?: CreationFilterWithDates) => {
    if (!filter) return;
    if (!filter.statusFilter) return;
    const keys = Object.keys(filter.statusFilter)
    return keys.filter((key: string) => filter.statusFilter[key] === false)
}

const CreationTypeFilter = (creation: Creation, filter?: CreationFilterWithDates) => {
    if (!filter) return true;
    if (filter.showPosts === false && creation.type === "post") return false;
    if (filter.showAOA === false && creation.type === "aoa") return false;
    return true;
}

const GetCreationTypeFilter = (filter?: CreationFilterWithDates) => (creation: Creation) => CreationTypeFilter(creation, filter);

const CreationStatusFilter = (creation: Creation, filter?: CreationFilterWithDates) => {
    const disallowedStatues = getDisallowedStatues(filter)
    if (!filter) return true;
    if (!filter.statusFilter) return true;
    if (disallowedStatues?.some((status: string) => creation.status === status)) {
        return false;
    }
    return true;
}

const GetCreationStatusFilter = (filter?: CreationFilterWithDates) => (creation: Creation) => CreationStatusFilter(creation, filter);

const getDateForQuery = (creation: Creation, queryName: string) => {
    switch (queryName) {
        case "created_at":
        case "-created_at":
            return creation.created_at;
        case "updated_at":
        case "-updated_at":
            return creation.updated_at;
        case "publish_at":
        case "-publish_at":
            return creation.publish_at;
        default:
            return creation.created_at;
    }
}

const CreationDateFilter = (creation: Creation, filter?: CreationFilterWithDates) => {

    if (!filter) return true;
    if (!filter.from && !filter.to) return true;

    const dateToFilterBy = dayjs(getDateForQuery(creation, filter.sort || "created_at"));

    if (!dateToFilterBy) return false;

    if (filter.from && filter.to) return filter.from.isBefore(dateToFilterBy) && filter.to.isAfter(dateToFilterBy);
    if (filter.from && !filter.to) return filter.from.isBefore(dateToFilterBy);
    if (!filter.from && filter.to) return filter.to.isAfter(dateToFilterBy);

    return true;

}

const GetCreationDateFilter = (filter?: CreationFilterWithDates) => (creation: Creation) => CreationDateFilter(creation, filter);

const CreationDateSort = (a: any, b: any, filter?: CreationFilterWithDates) => {

    if (!filter) return 0;
    if (!filter.sort) return 0;

    const dateA = getDateForQuery(a, filter.sort);
    const dateB = getDateForQuery(b, filter.sort);

    if (!dateA || !dateB) return 0;

    if (filter.sort === "created_at" || filter.sort === "updated_at" || filter.sort === "publish_at") {
        return dayjs(dateA).isBefore(dayjs(dateB)) ? -1 : 1;
    } else {
        return dayjs(dateA).isBefore(dayjs(dateB)) ? 1 : -1;
    }
}

const GetCreationDateSort = (filter?: CreationFilterWithDates) => (a: any, b: any) => CreationDateSort(a, b, filter);

export const FilterCreation = (creation: Creation, filter?: CreationFilterWithDates) => {

    const disallowedStatues = getDisallowedStatues(filter)
    //console.log("disallowedStatues",disallowedStatues)
    if (!filter) return creation;
    if (filter.showPosts === false && creation.type === "post") return null;
    if (filter.showAOA === false && creation.type === "aoa") return null;
    if (disallowedStatues?.some((status: string) => creation.status === status)) {
        return null;
    }
    return creation;
}

export const newCreation: callAPIFunction = (type: CreationType) => ({
    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/creations/`, 1),
    body: EmptyCreationByType(type),
    method: "POST",
    successDispatch: creationsSlice.actions.create,
})

export const getCreations: callAPIProps = {
    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/creations`, 1),
    method: "GET",
    successDispatch: creationsSlice.actions.getAll,
}

export const getCreationsWithQuery: callAPIFunction = (query: any) => ({
    url: ({ getApiUrl, projectId, serializeQuery }) => getApiUrl(`projects/${projectId}/creations${query ? "?" + serializeQuery(query) : ""}`, 1),
    method: "GET",
    successDispatch: creationsSlice.actions.getAllAndAdd,
})

export const validateCreation: callAPIFunction = (creationId: number) => ({
    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/creations/${creationId}/validate`, 2),
    method: "GET",
    successDispatch: creationsSlice.actions.update,
})

export const deleteCreation: callAPIFunction = (creationId: number) => ({
    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/creations/${creationId}`, 1),
    method: "DELETE",
    successDispatch: creationsSlice.actions.delete,
    passToDispatcher: { id: creationId }
})

export const unpublishCreation: callAPIFunction = (creationId: number) => ({
    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/creations/${creationId}/unpublish`, 1),
    method: "POST",
    successDispatch: creationsSlice.actions.update,
    passToDispatcher: { id: creationId }
})

export const getCreation: callAPIFunction = (creationId: number) => ({
    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/creations/${creationId}`, 1),
    method: "GET",
    successDispatch: creationsSlice.actions.get,
})

export const patchCreation: callAPIFunction = (creationId: number) => ({
    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/creations/${creationId}`, 1),
    method: "PATCH",
    successDispatch: creationsSlice.actions.update,
    requestDispatch: creationsSlice.actions.optimisticUpdate,
    passToDispatcher: { id: creationId }
})

export const patchCreationWithSmartAction: callAPIFunction = (creationId: number, action: string, optimisticUpdate?: boolean) => ({
    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/creations/${creationId}`, 1),
    method: "PATCH",
    successDispatch: creationsSlice.actions.update,
    requestDispatch: optimisticUpdate ? creationsSlice.actions.optimisticUpdate : undefined,
    actionName: action,
    passToDispatcher: { id: creationId }
})

export const publishCreation: callAPIFunction = (creationId: number) => ({
    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/creations/${creationId}/publish`, 1),
    method: "POST",
    successDispatch: creationsSlice.actions.publish,
})

export const requestPublication: callAPIFunction = (creationId: number) => ({
    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/creations/${creationId}/request`, 1),
    method: "POST",
    successDispatch: creationsSlice.actions.requestPublication,
})


export const getCreationPreview: callAPIFunction = (creationId: number) => ({
    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/creations/${creationId}/preview`, 1),
    method: "GET",
    successDispatch: creationsSlice.actions.getPreview,
    passToDispatcher: { id: creationId }
})

export const duplicateCreation = (creation: Creation) => {
    const updateCreationContent = { ...creation.content, save_draft: false }
    return {
        ...newCreation, body: {
            type: creation.type,
            content: updateCreationContent
        }
    }
}

export const creationsSelector = createSelector([
    (state: any):CreationsSliceState => state.creations,
],
    (creations) => creations.list
)

export const creationSelector = createSelector([
    creationsSelector,
    (state, creationId: number) => creationId,
],
    (creations, creationId) => creations[creationId]
);

export const creationsAsArraySelector = createSelector([
    creationsSelector,
], (creations) => {
    return (creations && Object.values(creations)).filter((c: any) => !!c) || [];
})

export const filteredCreationsAsArraySelector = (filter: CreationFilterWithDates) => createSelector([
    creationsAsArraySelector,
], (creations) => {

    try {
        const filtered = creations
            .filter(GetCreationTypeFilter(filter))
            .filter(GetCreationStatusFilter(filter))
            .filter(GetCreationDateFilter(filter));

        const sorted = filtered.sort(GetCreationDateSort(filter))

        return sorted;
    } catch (e) {
        console.error("Error filtering creations", e)
        return creations;
    }

})

export const creationContentSelector = createSelector([
    creationSelector,
], (creation) => {
    return creation && creation.content;
})

export const filteredCreationsCountSelector = createSelector(
    [
        creationsAsArraySelector,
        userSettingsInterfaceCreationListSelector,
    ],
    (creations, filters) => {

        const filteredCreations = creations.filter((creation:Creation) =>{
            return FilterCreation(creation,filters)
        })

        return creations.length - filteredCreations.length;
    }
);
