import { Collapse } from "antd";

export default function EditorSectionsCollapse(props:any) {
    return (
        <Collapse
        accordion={false}
        expandIcon={() => null}
        {...props}
      >
        {props.children}
        </Collapse>
    )
}