import { Grid } from "antd";
import { useMemo } from "react";

// [0,  1,  2,  3,  5,  5,]
// [xs, sm, md, lg, xl, xxl]

export default function useSimpleBreakpoint () {

    const breakpoint = Grid.useBreakpoint();

    const simpleBreakpoint:[string, number, boolean] = useMemo(() => {
        if (!breakpoint) return ["lg", 3, false];
        if (breakpoint.xxl) return ["xxl", 5, false];
        if (breakpoint.xl)  return ["xl", 4, false];
        if (breakpoint.lg)  return ["lg", 3, false];
        if (breakpoint.md)  return ["md", 2, true];
        if (breakpoint.sm)  return ["sm", 1, true];
        if (breakpoint.xs)  return ["xs", 0, true];
        return ["lg", 3, false];
    }, [breakpoint]);

    return simpleBreakpoint

}