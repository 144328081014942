import { RSAA } from "redux-api-middleware";

function addUserTokenToRequest({ dispatch, getState }) {
  return (next) => (action) => {
    if (action && action[RSAA]) {
      action[RSAA].headers = clearOutUndefinedValues({
        "Content-Type": "application/json",
        ...action[RSAA].headers,
      });

      if (action[RSAA].authorized) {
        let token = getState().loginStatus.get("token");

        action[RSAA].headers = {
          Authorization: `${token.get("type")} ${token.get("value")}`,
          ...action[RSAA].headers,
        };

        delete action[RSAA].authorized;
      }
    }

    next(action);
  };
}

function clearOutUndefinedValues(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}

export default addUserTokenToRequest;
