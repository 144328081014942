import { StandardHalfFullColumn, StandardRow } from "components/layout/StandardGrid";
import { CreationTypeConfig, CreationTypes } from "../CreationConfig";
import { Title } from "components/common/StandardText";
import StandardButton from "components/common/StandardButton";
import FormattedMessage from "components/common/FormattedMessage";
import { faChevronLeft, faChevronRight, faExpand, faLeftRight, faList, faPlus, faPlusCircle, faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import { useHelpPanel } from "tools/helpPanel/HelpPanelDrawer";
import { Card } from "antd";
import "./sales-funnel.less";
import AwesomeIcon from "components/common/AwesomeIcon";
import { useState } from "react";

const CreationTypeCard = (props: {
    type: any,
    onCreateNew?: () => void,
    onOpenList?: () => void,
    showDescription?: boolean,
}) => {

    const { openHelpPanel } = useHelpPanel();


    return (
        <Card
            hoverable={true}
            className="sales-funnel-card"
            cover={
                <div style={{ display: "grid", placeItems: "center", padding: "10px" }}>
                    <img
                        alt="example"
                        src={props.type.image}
                        style={{
                            height: "75px",
                            width: "75px",
                        }}
                    />
                </div>
            }
            onClick={() => { }}
        >
            <Card.Meta
                title={props.type.title}
                style={{
                    overflow: "hidden",
                    paddingBottom: "5px"
                }}
            />
            <div
                style={{
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <StandardButton
                    type="primary"
                    style={{ width: "100%" }}
                    size={props.showDescription ? undefined : "small"}
                    icon={faPlusCircle}
                    onClick={() => props.onCreateNew && props.onCreateNew()}
                >
                    <FormattedMessage
                        id="tools.creationEditor.post.actions.new"
                        defaultMessage="New Post"
                    />
                </StandardButton>
            </div>
        </Card>
    );
};

const ExpandCard = (props: {onClick?: () => void, isExpanded: boolean}) => {

    return (
        <Card
            hoverable={true}
            className="sales-funnel-card expand-card"
            onClick={() => props.onClick && props.onClick()}
        >
            <div style={{ display: "grid", placeItems: "center", height: "100%", width: "100%" }}>
                <AwesomeIcon icon={props.isExpanded ? faChevronLeft : faChevronRight} size={"4x"} color="grey" />
            </div>
        </Card>
    );
}

const ListOfCreationTypesForFunnelSegment = (props: {
    types: [string, string][]
    allowedTypes: CreationTypeConfig[],
    onOpen?: (type: CreationTypeConfig) => void,
    onNew?: (type: CreationTypeConfig) => void,
}) => {

    const [isExpanded, setIsExpanded] = useState(false);

    const allowedTypes = (props?.types || []).map((type) => {
        const allowedType = props.allowedTypes.find((t) => t.type[0] === type[0] && t.type[1] === type[1]);
        if (!allowedType) {
            return null;
        }
        return (
            <CreationTypeCard
                key={type[0] + "-" + type[1]}
                type={allowedType}
                showDescription={false}
                onCreateNew={() => props.onNew && props.onNew(allowedType)
                }
            />
        )
    }).filter(type => !!type)

    if (allowedTypes && allowedTypes.length >= 3) {
        return (
            <div className="sales-funnel-row-cards">
                {isExpanded ? (
                <>
                    {allowedTypes}
                    <ExpandCard isExpanded={isExpanded} onClick={() => setIsExpanded(e => !e)}/>
                </>
                ) : (
                    <>
                    {allowedTypes.slice(0, 2)}
                    <ExpandCard isExpanded={isExpanded} onClick={() => setIsExpanded(e => !e)}/>
                    </>
                )}
            </div>
        )
    }

    return (
        <div className="sales-funnel-row-cards">
            {allowedTypes}
        </div>
    )


}

const SalesFunnelCreationTypes = (props: {
    allowedTypes: CreationTypeConfig[],
    onOpen: (type: CreationTypeConfig) => void,
    onNew: (type: CreationTypeConfig) => void,
}) => {


    return (
        <div className="sales-funnel-grid">
            <div className="sales-funnel-row funnel-top">

                <div className="sales-funnel-items">
                    <Title level={3} noMargins>ToFu</Title>
                    <ListOfCreationTypesForFunnelSegment
                        allowedTypes={props.allowedTypes}
                        types={[
                            ["post", "video"],
                            ["post", "image"]
                        ]}
                        onNew={props.onNew}
                        onOpen={props.onOpen}
                    />
                </div>
                <div className="sales-funnel-segment">
                    <img src="/funnel/TopOfTheFunnel.svg" />
                    <div className="sales-funnel-segment-description">
                        <h3>
                            <FormattedMessage
                                id="misc.salesFunnel.stage.ToFu.title"
                                defaultMessage="Awareness Stage"
                            />
                        </h3>
                        <span>
                            <FormattedMessage
                                id="misc.salesFunnel.stage.ToFu.description"
                                defaultMessage="Blog and social media posts, infographics, multimedia, videos and podcasts, newsletters, white papers"
                            />
                        </span>
                    </div>
                </div>
            </div>
            <div className="sales-funnel-row funnel-middle">

                <div className="sales-funnel-items">
                    <Title level={3} noMargins>MoFu</Title>
                    <ListOfCreationTypesForFunnelSegment
                        allowedTypes={props.allowedTypes}
                        types={[
                            ["post", "video"],
                            ["post", "video"],
                            ["aoa", "aoa"],
                            ["post", "carousel"],
                            ["post", "link"]
                        ]}
                        onNew={props.onNew}
                        onOpen={props.onOpen}
                    />
                </div>
                <div className="sales-funnel-segment">
                    <img src="/funnel/MiddleOfTheFunnel.svg" />
                    <div className="sales-funnel-segment-description">
                        <h3>
                            <FormattedMessage
                                id="misc.salesFunnel.stage.MoFu.title"
                                defaultMessage="Consideration Stage"
                            />
                        </h3>
                        <span>
                            <FormattedMessage
                                id="misc.salesFunnel.stage.MoFu.description"
                                defaultMessage="Educational resources, webinars, surveys, downloads, promotional material"
                            />
                        </span>
                    </div>
                </div>
            </div>
            <div className="sales-funnel-row funnel-bottom">

                <div className="sales-funnel-items">
                    <Title level={3} noMargins>BoFu</Title>
                    <ListOfCreationTypesForFunnelSegment 
                    allowedTypes={props.allowedTypes} 
                    types={[
                        ["aoa", "w2l_event"], 
                        ["post", "message"], 
                        ["aoa", "event_aoa"]
                    ]} 
                    onNew={props.onNew}
                    onOpen={props.onOpen}
                    />
                </div>
                <div className="sales-funnel-segment">
                    <img src="/funnel/BottomOfTheFunnel.svg" />
                    <div className="sales-funnel-segment-description">
                        <h3>
                            <FormattedMessage
                                id="misc.salesFunnel.stage.BoFu.title"
                                defaultMessage="Decision Stage"
                            />
                        </h3>
                        <span>
                            <FormattedMessage
                                id="misc.salesFunnel.stage.BoFu.description"
                                defaultMessage="Case studies, demos and trials, consultations, customer stories, events"
                            />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default SalesFunnelCreationTypes;
