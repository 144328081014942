import FormattedMessage from "components/common/FormattedMessage";
import StandardActorCard, { ActorInfo } from "components/common/StandardActorCard"
import dayjs from "dayjs";
import { getProductLogo } from "providers/routing/layouts/components/product-logo/smallProductLogo"
import useAPI from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { getSubscriptions } from "state/subscriptions/subscriptionsSlice";
import { Subscription } from "state/subscriptions/subscriptionTypes";
import { subscriptionSelector } from "state/subscriptions/subscriptionsSelectors";

type Props = {
subscriptionId:string
extra?: React.ReactNode
}

type SubscriptionCardProps = {
  extra?: React.ReactNode;
  subscription: Subscription & { productName: any };
};

export const UnattachedSubscriptionsCard = (props: Props) => {
  const subscription = useSelectorWithParams(subscriptionSelector(props.subscriptionId));

  if (!subscription) return null;
  const productName = subscription?.product || (subscription?.roles ? subscription.roles?.[0] : "");
  const createdAt = subscription?.created_at ? dayjs(subscription.created_at).format("DD.MM.YYYY") : "";
  const expiry = subscription?.expiry ? dayjs(subscription.expiry).format("DD.MM.YYYY") : "";

  const extra = (
    <>
      <div className="extra-subscription-data">
        <div className="subscription-create">
          <span>
            <FormattedMessage
              id="panel.containers.companySettings.attachSubscription.created"
              defaultMessage="Created:"
            />
          </span>
          <span>{createdAt}</span>
        </div>
        <div className="subscription-expiry">
          <span>
            <FormattedMessage
              id="panel.containers.companySettings.attachSubscription.expiry"
              defaultMessage="Expiry:"
            />
          </span>
          <span>{expiry}</span>
        </div>
      </div>
    </>
  );

  return (
    <>
      <SubscriptionCard subscription={{...subscription, productName}} extra={extra} />
    </>
  );
};


export const SubscriptionCard = (props: SubscriptionCardProps) => {

  return (
    <>
        <StandardActorCard
          actor={{
            name: props.subscription?.name ? props.subscription?.name.replace(/\b\w/g, (c: any) => c.toUpperCase()) : "",
            image: getProductLogo(props.subscription.productName),
            subtitle: `ID: ${props.subscription?.id}`,
            value: props.subscription?.id,
          }}
          extra={props.extra}
        />
    </>
  );
  };