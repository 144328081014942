import { StoreConfig } from "modules";
import { combineReducers } from "redux";

import categoriesSlice from "./categoriesSlice";
import industriesSlice from "state/industries/industriesSlice";
import websiteObjectSlice from "./websiteObjectSlice";
import roomsSlice from "./roomsSlice";
import websiteTemplateSlice from "./websiteTemplateSlice";
import websiteSlice from "./websiteSlice";
import websitePagesSlice from "./websitePagesSlice";
import websitePricesSlice from "./websitePricesSlice";
import { CarsSlice } from "../containers/brandsAndManufacturers/CarsSlice";
import gallerySlice from "./gallerySlice";


export default {
  key: "siteGenerator",
  reducers: combineReducers({
    gallery: gallerySlice,
    categories: categoriesSlice,
    industries: industriesSlice,
    websiteObjects: websiteObjectSlice,
    rooms: roomsSlice,
    pages: websitePagesSlice,
    templates: websiteTemplateSlice,
    websites: websiteSlice.reducer,
    prices: websitePricesSlice.reducer,
    cars: CarsSlice.reducer,
  }),
} as StoreConfig;
