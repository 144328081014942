import { createSelector, createSlice } from "@reduxjs/toolkit";
import { callAPIProps } from "services/hooks/useAPI";

export type LexiconEntry = {
    id: number,
    type: string,
    name: string,
    prompt?: string,
    status: "active" | "inactive" | "draft",
    isUsed: boolean
}


const initialState: LexiconEntry[] = []

const lexiconSlice = createSlice({
    name: "lexicon",
    initialState: {
        aiCache: [],
        lexiconTable: []
    },
    reducers: {
        getAiCache: (state, action) => {
            const newData = action.payload.data.filter((entry: any) => {
              const isDuplicate = state.aiCache.some((existingEntry: any) => existingEntry.id === entry.id);
              return !isDuplicate;
            });
            state.aiCache = state.aiCache.concat(newData);
          },
        get: (state, action) => {
            const newData = action.payload.data.filter((entry:any) => {
                const isDuplicate = state.lexiconTable.some((existingEntry:any) => existingEntry.id === entry.id);
                return !isDuplicate;
              });
              state.lexiconTable = state.lexiconTable.concat(newData);
        },
        add: (state, action) => {
            state.aiCache = state.aiCache.concat(action.payload.data)
        }
    }
})

export const GetLexicon = (type: string): callAPIProps => ({
    url: ({ getApiUrl }: any) => getApiUrl(`ai/lexicon?type=${type}`, 1),
    method: "GET",
    successDispatch: lexiconSlice.actions.get,
})

export const GeneratePost = (style_id: number, type_id: number, message: string): callAPIProps => ({
    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/ai/post?message=${message}&type_id=${type_id}&style_id=${style_id}`, 1),
    method: "POST",
    successDispatch: lexiconSlice.actions.add,
})

export const GetAiCache = (field: string): callAPIProps => ({
    url: ({ getApiUrl, projectId }: any) => getApiUrl(`projects/${projectId}/ai/aicache?field=${field}`, 1),
    method: "GET",
    successDispatch: lexiconSlice.actions.getAiCache,
})

export const LexiconSelector = (state: any) => state.lexicon;

export const LexiconTableSelector = (state:any) => state.lexicon.lexiconTable

export const AiCacheSelector = (state: any) => state.lexicon.aiCache;

export const LexiconTypeSelector = createSelector([
  LexiconTableSelector,
  (state:any, type:string) => type
],
  (lexiconTable, type) => lexiconTable.filter((index:any) => index.type === type)
)

export const AiCacheFieldSelector= createSelector([
  AiCacheSelector,
  (state:any, field:string) => field
],
  (aiCache,field) => aiCache.filter((index:any) => index.field === field)
);



export default lexiconSlice;
