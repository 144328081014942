import { faTrash } from "@fortawesome/pro-light-svg-icons"
import { Space, Table } from "antd"
import CopyToClipboard from "components/common/CopyToClipboard"
import StandardActorCard from "components/common/StandardActorCard"
import StandardButton from "components/common/StandardButton"
import DateTimeDisplay from "components/displays/DateTimeDisplay"
import { useEffect, useMemo } from "react"
import { StandardModalAction } from "services/hooks/useModal"
import useSelectorWithParams from "services/hooks/useSelectorWithParams"
import { libraryProjectsSelector } from "state/librarySlice"
import { DeleteInvitation, OrganizationGroupsSelector, OrganizationIdSelector, OrganizationInvitationsSelector } from "../data/organizationSlice"
import { OrganizationRoleNames } from "../tools/OrganizationToolHelpers"


const makeInvitationUrl = (id:string) => `${window.location.origin}/panel/invitation?code=${id}`

export default function OrganizationTableInvitations(props:any) {

    const organizationId = useSelectorWithParams(OrganizationIdSelector)
    const projects = useSelectorWithParams(libraryProjectsSelector)
    const invitations = useSelectorWithParams(OrganizationInvitationsSelector)

    const groupsColumns = [
        {
            title: "Invite Code",
            dataIndex: "id",
            width: "100px"
        },
        {
            title: "email",
            dataIndex: "email",
        },
        {
            title: "Role",
            render: (text:any, record:any) => {
                const role = OrganizationRoleNames.find(r => r.value == record.role)
                if (role) return role.label;
                return record.role;
            }
        },
        {
            title: "Accepted",
            render: (text:any, record:any) => {
                if (record.accepted) return <DateTimeDisplay dateTime={record.accepted} showDate/>
                return <div>Not yet accepted</div>
            }
        },
        {
            title: "Valid until",
            render: (text:any, record:any) => <DateTimeDisplay dateTime={record.valid_time} showDate/>
        },
        {
            title: "Actions",
            render: (text:any, record:any) => {
                return (
                    <Space wrap>
                        <StandardModalAction 
                            buttonProps = {{
                                icon: faTrash,
                            }}
                            actionProps = {{
                                title: "Delete invitation",
                                content: "Are you sure you want to delete this invitation?",
                                callAPI: DeleteInvitation(record.id),
                            }}
                        />
                        <CopyToClipboard text={makeInvitationUrl(record.id)}/>
                    </Space>
                )
            }
        }
    ]
    

    const adjustedData = useMemo(() => {
        return invitations;
    }, [invitations])

    const adjustedColumns = useMemo(() => {
            return groupsColumns;
    }, [])

    const rowSelection = {
        type: "checkbox",
        onChange: (selectedRowKeys:any, selectedRows:any) => {
            props.onSelectedRows && props.onSelectedRows(selectedRowKeys)
        },
        getCheckboxProps: (record:any) => ({
            disabled: false,
            name: record.id,
        })
    }

    
    return (
            <Table 
                rowKey={record => record.id}
                columns = {adjustedColumns} 
                dataSource = {adjustedData}
                rowSelection = {rowSelection}
                pagination = {false}
                />
    )


}