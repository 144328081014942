import { useCalendar } from './Calendar';
import dragula from 'react-dragula';
import { useEffect, useMemo, useRef } from 'react';
import "dragula/dist/dragula.css";
import CalendarCreationEntry from './CalendarCreationEntry';
import { Badge, Tag, Typography } from 'antd';
import dayjs, { Dayjs } from "dayjs";
import AwesomeIcon from 'components/common/AwesomeIcon';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { AskToCreateCreation } from 'tools/creationEditor/CreationActionModals';
import { TagButton } from 'components/common/TagButton';
import { Text, Title } from 'components/common/StandardText';

type CalendarDayProps = {
    date: Dayjs,
}

const getIndexInParent = (el) => Array.from(el.parentNode.children).indexOf(el);

const CalendarDay = (props: CalendarDayProps) => {

    const { date, entries, drake, dayFilter, EntryComponent, DayTool } = useCalendar();
    const dayContainer = useRef<HTMLDivElement>(null);
    const { ask, contextHolder } = AskToCreateCreation();

    const entriesForDay = useMemo(() => {

        let myEntries: any[] = [];

        entries && entries.forEach((e) => {
            if (!e) return;

            if (dayFilter) {
                if (!dayFilter(e, props.date)) return;
                myEntries.push(e);
                return;
            }

            if (e.entryType === "creation") {
                const { publication_type, publication_date } = e.content;
                if (!publication_date) return;
                if (!publication_type) return;
                const pd = dayjs(publication_date);

                if (!pd.isSame(props.date, 'day')) return;

                myEntries.push(e)
            }
            if (e.entryType === "post") {
                if (!e.created_at) return;
                const pd = dayjs(e.created_at);

                if (!pd.isSame(props.date, 'day')) return;

                myEntries.push(e)
            }
            if (e.entryType === "event") {
                if (!e.date) return;
                const pd = dayjs(e.date);
                if (!pd.isSame(props.date, 'day')) return;
                myEntries.push(e)
            }

        })

        return myEntries;

    }, [entries, props.date]);


    const sameMonth = date.month() === props.date.month();
    const inThePast = props.date.isBefore(dayjs(), 'day');
    const today = props.date.isSame(dayjs(), 'day');
    const hasEntries = entriesForDay && entriesForDay.length > 0;

    useEffect(() => {
        const inluded = drake.containers.includes(dayContainer.current);
        if (inluded) return;
        drake.containers.push(dayContainer.current);
    }, []);



    return (
        <div
            key={props.date.format('YYYY-MM-DD')}
            className={`calendar-day ${sameMonth ? 'same-month' : 'other-month'} ${inThePast ? 'in-the-past' : ''} ${today ? 'today' : ''} ${entriesForDay.length > 0 ? '' : 'empty'}`}
        >
            <div className='calendar-day-header'>
                <div className='calendar-day-header-actions'>
                    {DayTool && <DayTool
                        date={props.date}
                        onClick={() => ask(props.date && props.date.set("hour", 12))}
                        isDayEmpty={entriesForDay.length === 0}
                    />}
                </div>
                <div className="calendar-day-header-date">
                    <Typography.Title level={5} type="secondary">{props.date.format('D').padStart(2, "0")}</Typography.Title>
                </div>

            </div>

            <div
                className='calendar-day-entries'
                ref={dayContainer}
                data-calendar-day-date={props.date.format('YYYY-MM-DD')}
            >
                {(entriesForDay || []).map((entry, index) => {
                    if (!entry) return null;
                    if (!EntryComponent) return null;
                    return <EntryComponent key={entry.id || entry.localId || `entry_${index}`} entry={entry} day={props.date} />
                })}
            </div>
            <div className='calendar-day-tool-wrapper'>
                {!hasEntries && DayTool && <DayTool
                    date={props.date}
                    onClick={() => ask(props.date && props.date.set("hour", 12))}
                    isDayEmpty={entriesForDay.length === 0}
                />}
            </div>

            <div className='calendar-day-footer'>
                {entriesForDay.length > 1 ? (
                    <div className='calendar-day-moreEntries'>
                            {`+${entriesForDay.length - 1}`}
                    </div>
                ) : null}

            </div>

            {contextHolder}
        </div>
    )


}

export default CalendarDay;