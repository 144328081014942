import { usePost } from "../postContext";
import { AoaRepublish } from "./AoaRepublish";
import PromotePost from "./PromotePost";

export const PostActionTools = () => {
  const { post } = usePost();
  const { promotionStatus } = post.getGeneratedData();

  if(post.type === "aoa" && promotionStatus === "error") return <AoaRepublish/>
  
  return <PromotePost />
};
