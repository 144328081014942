import { Spin } from "antd";
import { HorizontalCenter } from "components/common/Center";
import React, { ReactNode } from "react";
import { useEffect, useMemo, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import useAPI from "services/hooks/useAPI";
import { getSubscriptions } from "state/subscriptions/subscriptionsSlice";
import { SubscriptionCancellationPlan, SubscriptionCancellationPlanName } from "state/subscriptions/subscriptionTypes";
import { GetCancellationSurvey, GetSubscriptionCancelationPlans } from "state/subscriptions/subscriptionsEndpoints";
import { subscriptionSelector } from "state/subscriptions/subscriptionsSelectors";
import SubscriptionCancellationSelectPlan from "./SubscriptionCancellationSelectPlan";
import SubscriptionCancellationLoadPlan from "./SubscriptionCancellationLoadPlan";
import SubscriptionCancellationSurvey from "./SubscriptionCancellationSurvey";
import SubscriptionCancellationWinbackOffer from "./SubscriptionCancellationWinbackOffer";
import SubscriptionCancellationPayment from "./SubscriptionCancellationPayment";
import StandardButton from "components/common/StandardButton";
import SubscriptionCancellationBenefits from "./SubscriptionCancellationBenefits";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import SubscriptionCancellationSummary from "./SubscriptionCancellationSummary";
import SubscriptionCancellationOutstandingPaymentWarning from "./SubscriptionCancellationOutstandingPaymentWarning";

type Step = "loading" | "benefits" | "survey" | "selectPlan" | "outstandingPaymentWarning" | "summary" | "payment" | "winback" | "done";


type SubscriptionCancellationWizardContextType = {
    step: Step,
    subscriptionId: number,
    goToStep: (step: Step) => void,
    plan: SubscriptionCancellationPlanName | null,
    setPlan: (plan: SubscriptionCancellationPlanName) => void,
    subscription: any,
    closeWizard: () => void,
}

const SubscriptionCancellationWizardContext = React.createContext<SubscriptionCancellationWizardContextType>({});
SubscriptionCancellationWizardContext.displayName = "SubscriptionCancellationWizardContext";
export const useCancellationWizard = () => React.useContext(SubscriptionCancellationWizardContext);

type SubscriptionCancellationWizardStep = {
    name: string,
    component: ReactNode,
}

const CLOSE_WIZARD = "close"

export const subscriptionCancellationWizardSteps:Record<Step,SubscriptionCancellationWizardStep> = {
    "loading": {
        name: "Loading",
        component: <SubscriptionCancellationLoadPlan />,
    },
    "benefits": {
        name: "Benefits",
        component: <SubscriptionCancellationBenefits />,
    },
    "survey": {
        name: "Survey",
        component: <SubscriptionCancellationSurvey />,
    },
    "selectPlan": {
        name: "Select Plan",
        component: <SubscriptionCancellationSelectPlan />,
    },
    "summary": {
        name: "Summary",
        component: <SubscriptionCancellationSummary />,
    },
    "outstandingPaymentWarning": {
        name: "Outstanding Payment Warning",
        component: <SubscriptionCancellationOutstandingPaymentWarning />
    },
    "payment": {
        name: "Processing",
        component: <SubscriptionCancellationPayment />,
    },
    "winback": {
        name: "Winback",
        component: <SubscriptionCancellationWinbackOffer />,
    },
    "done": {
        name: "",
        component: undefined,
    }
}



function SubscriptionCancellationWizard({ subscriptionId, ...props }: { subscriptionId: number, onClose?: () => void }) {

    const { search } = useLocation();
    const [plan, _setPlan] = useState<SubscriptionCancellationPlanName | null>(null);
    const [step, setStep] = useState<Step>("loading");
    const subscriptionCall = useAPI(getSubscriptions(), true)
    const subscription = useSelectorWithParams([subscriptionSelector, subscriptionId])
    const cancellationSurvey = useAPI(GetCancellationSurvey(subscriptionId), true)

    useEffect(() => {
        if (subscriptionId) {
            setStep("loading");
        }
    }, [subscriptionId]);

    const stepComponent = useMemo(() => {
        return subscriptionCancellationWizardSteps[step]?.component || <div>Error</div>;
    }, [step])

    const goToStep = (stepName: Step) => setStep(stepName);

    const setPlan = (plan: any) => {
        _setPlan(plan);
    }

    const closeWizard = () => {
        props.onClose && props.onClose();
    }

    return (
        <SubscriptionCancellationWizardContext.Provider value={{
            step,
            subscriptionId,
            goToStep,
            plan,
            setPlan,
            subscription,
            closeWizard
        }}>
            <HorizontalCenter>
                <div style={{
                    width: "100%",
                    maxWidth: "900px",
                }}>
                    {stepComponent}
                </div>
            </HorizontalCenter>
        </SubscriptionCancellationWizardContext.Provider>
    )


}

export default SubscriptionCancellationWizard;