import { Col, InputNumber } from "antd";
import { StandardRow } from "components/layout/StandardGrid";
import GeoPointsFacebook from "components/map/GeoPointsFacebook";
import FacebookLocaleLanguageSelect from "modules/smart/components/forms/FacebookLocaleLanguageSelected";
import ConnectedOnboardingControls from "providers/onboarding/controls";
import useDataPrefill from "services/hooks/useDataPrefill";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import FormattedMessage from "components/common/FormattedMessage";
import { GenderInput } from "_components/forms/GenderInputs";
import { FacebookAdsSettingsSelector, HasFacebookAudienceFilledSelector, UpdateFacebookAdsSettings } from "modules/smart/smartSlice";

export default function OnboardingAudienceFacebook() {

    useDataPrefill({
        dataSelector: FacebookAdsSettingsSelector,
        callAPI: UpdateFacebookAdsSettings,
        checkFunction: (data: any) => {
            if (!data) return false;

            let newData = {};

            if (!data.age_from || !data.age_to) {
                newData = {
                    "age_from": 18,
                    "age_to": 65,
                }
            }

            if (!data.gender) {
                newData = {
                    ...newData,
                    gender: "all"
                }
            }

            if (Object.keys(newData).length > 0) {
                return newData;
            }

            return false;

        }
    })

    const fulfilled = useSelectorWithParams(HasFacebookAudienceFilledSelector);

    const setValueIfEmpty =
        (setValue: number) => (value: string | number | undefined) =>
            value ? value.toString() : setValue.toString();

    return (
        <>
            <RelaxMultiform
                name="facebookAdsSettings"
                initialValuesSelector={FacebookAdsSettingsSelector}
                callAPI={UpdateFacebookAdsSettings}
                finalizeSubmit={(values) => {
                    if (values.location_targeting) {
                        values.location_targeting.country_code = "PL"
                    }
                    return values;
                }}
            >
                <StandardRow>
                    <Col span={24}>
                        <GeoPointsFacebook />
                    </Col>
                </StandardRow>
                <div style={{ height: "25px" }}></div>

                <StandardRow>

                    <Col xs={24} sm={24} md={12} lg={12}>
                        <RelaxSingleField
                            name="age_from"
                            label={<FormattedMessage
                                id="components.forms.inputs.ageRange.From"
                                defaultMessage="Age from"
                            />}
                        >
                            <InputNumber
                                formatter={setValueIfEmpty(18)}
                                className="fullwidth from"
                            />
                        </RelaxSingleField>
                    </Col>


                    <Col xs={24} sm={24} md={12} lg={12}>
                        <RelaxSingleField
                            name="age_to"
                            label={<FormattedMessage
                                id="components.forms.inputs.ageRange.To"
                                defaultMessage="Age to"
                            />}
                        >
                            <InputNumber
                                formatter={setValueIfEmpty(65)}
                                className="fullwidth from"
                            />
                        </RelaxSingleField>
                    </Col>


                </StandardRow>

                <StandardRow>

                    <Col xs={24} sm={24} md={12} lg={12}>
                        <RelaxSingleField
                            name="gender"
                            initialValue={["all"]}
                            label={<FormattedMessage
                                id="apps.smart.components.adsSettings.genderLabel"
                                defaultMessage="Gender"
                            />}
                            submitOnChange
                            style={{width: "100%"}}
                        >
                            <GenderInput />
                        </RelaxSingleField>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12}>
                        <RelaxSingleField
                            name="locales"
                            label={<FormattedMessage
                                id="apps.smart.components.adsSettings.locales"
                                defaultMessage="Languages"
                            />}
                            style={{ width: "100%" }}
                        >
                            <FacebookLocaleLanguageSelect />
                        </RelaxSingleField>
                    </Col>
                </StandardRow>

            </RelaxMultiform>
            <ConnectedOnboardingControls relaxDontSubmit={true} nextDisabled={(!fulfilled)} />
        </>
    )
}