import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { userLanguageSelector } from "modules/panel/config/selectors/user";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";

const stripe = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY, {
    stripeAccount: import.meta.env.VITE_STRIPE_ACCOUNT
  });

function StripeContext({children}: {children: JSX.Element | JSX.Element[]}) {

    const userLanguage = useSelectorWithParams(userLanguageSelector);

    const stripeOptions = {
      locale: userLanguage,
    }
  
    return (
        <Elements stripe={stripe} options={stripeOptions}>
            {children}
        </Elements>
    );
}

export default StripeContext;