import { useMemo } from "react"
import "./asset-preview.less"

const PhoneMockupByProvider:Record<string, string> = {
    facebook: "/creationPreview/facebook.svg",
    google: "/creationPreview/google.svg",
    instagram: "/creationPreview/instagram.svg",
    youtube: "/creationPreview/youtube.svg",
}

export default function PhoneMockup (props:{provider: string, children: any}) {


    const src = PhoneMockupByProvider[props.provider || "blankPhone"]

    return (
        <div className="phone-mockup">
            <div className="phone-mockup-image-container">
                <img 
                className="phone-mockup-main-image"
                src={src}
                />
            </div>
            <div className="phone-mockup-content-container">
            <div className="phone-mockup-content">
                {props.children}
            </div>
            </div>
        </div>
    )
}