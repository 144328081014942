import _ from "lodash";
import { roundToDecimalPlaces } from "services/helpers/js-helpers";

type OfferAdditional = {
    featured: boolean;
    text: string;
}

type DiscountPercentage = {
    id: number;
    stripe_value: number;
}

type OneTimeFee = {
    id: number, 
    name: string,
    stripe_value: number,
    value: string,
    tax_rate?: Omit<OneTimeFee, 'tax_rate'>
}

type OfferMetaData = {
    id: number;
    name: string;
    description: string;
    price: number;
    currency: string;
}

export type SubscriptionOffer = {
    active: boolean;
    additional: OfferAdditional[];
    billing_cycle: string;
    charge_price: number;
    currency: string;
    description: string;
    discount_percentage: DiscountPercentage[];
    hide: boolean;
    id: number;
    mandatory_periods: number;
    name: string;
    one_time_fee: OneTimeFee[];
    order: number;
    price: number;
    specification: string;
    stripe_payment_plan_id: string;
    title: string;
    trial?: any;
  };

type OfferWithPricingDetails = {

    name: string;
    id: number | string;
    description?: string;
    title: string,
    currency: string,

    mandatoryPeriods: number,

    taxRate: number;

    cycle: string;

    additionalFees: {
        value: number,
        items: any[],
        net: number,
        gross: number,
    },

    subscription: {
        base: {
            net: number,
            gross: number,
            taxRate: number,
        },
        hasDiscount: boolean,
        withDiscount: {
            net: number,
            gross: number,
            taxRate: number,
        },
        taxRate: number,
    },

    netDiscount: number;
    netPrice: number;
    netWithDiscount: number;
    taxValue: number;
    gross: number;
    trial: number | null;
    paidNow: {
        net: number,
        gross: number,
        taxRate: number,
    },
    price: number,
}

type Coupon = {
    discount: string,
    offerPrice: number,
}


const DEFAULT_TAX_RATE = 0.23;

export const calculateOfferPricingDetails = (offer: SubscriptionOffer):OfferWithPricingDetails => {

    const taxRate = (() => {
        const taxRateObj = offer?.tax_rate?.[0];
        if (!taxRateObj) return DEFAULT_TAX_RATE;
        return taxRateObj.stripe_value/100 || DEFAULT_TAX_RATE
    })()

    const NetToGross = (value:number):number => value * (1+taxRate);

    let subscription = {
        net: offer.charge_price,
        taxRate: taxRate,
        gross: 0,
        taxValue: 0,
    }

    let oneTimeFees = {
        net: 0,
        taxValue: 0, 
        gross: 0,
    }

    const additionalFees: any[] = []

    offer.one_time_fee && offer.one_time_fee.forEach(fee => {

        const feeTax = fee?.tax_rate?.stripe_value ? fee.tax_rate.stripe_value/100 : taxRate;
        const net = fee.stripe_value;

        oneTimeFees.net += net;
        oneTimeFees.taxValue += net * feeTax;

        additionalFees.push({
            ...fee, 
            gross: NetToGross(fee.stripe_value)
        })

    })
    
    oneTimeFees.gross = oneTimeFees.net + oneTimeFees.taxValue;

    const total = subscription.gross + oneTimeFees.gross;

    const trial = offer?.trial?.[0] || null;

    if (trial) {
        trial.net = trial?.stripe_value;
        trial.taxValue = trial.net * taxRate;
        trial.total = trial.net + trial.taxValue;
        trial.length = trial?.value || 0;
    }


    const paidNowNet = subscription.net + oneTimeFees.net;
    const paidNow = {
        net: paidNowNet,
        taxRate: taxRate,
        gross: NetToGross(paidNowNet),
    }

    let pd:OfferWithPricingDetails = {
        name: offer.name,
        id: offer.id,
        description: offer.description,
        title: offer.title,
        currency: offer.currency,
        mandatoryPeriods: offer.mandatory_periods,
        taxRate: taxRate,
        cycle: offer.billing_cycle,
        additionalFees: {
            value: oneTimeFees.net,
            items: additionalFees,
            net: oneTimeFees.net,
            gross: NetToGross(oneTimeFees.net),
        },
        subscription: {
            base: {
                net: subscription.net,
                gross: NetToGross(subscription.net),
                taxRate: taxRate,
            },
            hasDiscount: false,
            withDiscount: {
                net: subscription.net,
                gross: NetToGross(subscription.net),
                taxRate: taxRate,
            },
            taxRate: taxRate,
        },
        paidNow: paidNow,
        trial: trial ? trial.net : null,
        price: subscription.net,
    };

    return pd;

    
}

export const applyCoupon = (offer?: SubscriptionOffer, coupon?: Coupon):OfferWithPricingDetails | null => {

    if (!offer) return null;

  //  console.log("Applying coupon", coupon)
   // console.log("Base offer", offer)

    const bo = calculateOfferPricingDetails(offer); //base offer

   // console.log("bo", bo)

    if (!coupon) return bo;
    if (!coupon.discount) return bo;
    const discount = parseFloat(coupon.discount);

    //console.log("Discount", discount)

    const wd = _.cloneDeep(bo); //with discount

    //console.log("wd", wd)

    wd.subscription.hasDiscount = true;

    wd.subscription.withDiscount = {
        net: roundToDecimalPlaces(bo.subscription.base.net - discount, 2),
        gross: roundToDecimalPlaces(bo.subscription.base.gross - discount * (1+bo.taxRate), 2),
        taxRate: bo.subscription.base.taxRate,
    }

    wd.paidNow = {
        net: roundToDecimalPlaces(wd.subscription.withDiscount.net + wd.additionalFees.net, 2),
        gross: roundToDecimalPlaces((wd.subscription.withDiscount.net + wd.additionalFees.net) * (1+bo.taxRate), 2),
        taxRate: bo.paidNow.taxRate,
    }



    //console.log("With discount", wd)

    return wd;
    

}