import { createSelector, createSlice } from "@reduxjs/toolkit";
import postsSlice from "modules/posts/state/postsSlice";
import organizationSlice from "tools/organization/data/organizationSlice";
import { tasksSlice } from "tools/tasks/data/tasksSlice";

export type LibrarySliceState = any;

const initialState: LibrarySliceState = {
    projects: {},
    organizations: {},
}

const addProjectsToIndex = (state: any, projects: any[]) => {

    if (!projects) return;

    if (Array.isArray(projects)) {
        projects.forEach((p:any) => {
            if (!state.projects[p.id]) state.projects[p.id] = {};

            const {id, name, image, status} = p;

            state.projects[p.id] = {id, name, image, status};
        })
        return;
    }

    if (typeof projects === "object") {
        Object.keys(projects).forEach((k:any) => {
            if (!state.projects[k]) state.projects[k] = {};

            const {id, name, image, status} = projects[k];

            state.projects[k] = {id, name, image, status};
        })
    }

}

export const librarySlice = createSlice({
    name: "library",
    initialState, 
    reducers: {
    },
    extraReducers: (builder) => {
        builder
        .addCase(organizationSlice.actions.getGroups, (state, action) => {
            if (action?.payload?.data && action.payload.data.forEach) {
                action.payload.data.forEach((g:any) => {
                    addProjectsToIndex(state, g.projects)
                })
            }
        })
        .addCase("LOAD_USER_SUCCESS", (state, action) => {
            if (action?.payload?.projects) {
                addProjectsToIndex(state, action.payload.projects)
            }
        })
        .addCase(tasksSlice.actions.getProjectTasks, (state, action) => {
            if (action?.payload?.included) {
                action?.payload?.included.forEach((i: any) => {
                    if (i && i._type ==="projects") addProjectsToIndex(state, [i])
                })
            }
        })
        .addCase(organizationSlice.actions.getOrganization, (state, action) => {
            if (action?.payload?.data) {
                state.organizations[action.payload.data.id] = {
                    id: action.payload.data.id,
                    name: action.payload.data.name,
                };
            }
        })
        .addCase(organizationSlice.actions.getProjects, (state, action) => {
            if (action?.payload?.data) {
                action?.payload?.data.forEach((i: any) => {
                    addProjectsToIndex(state, [i])
                })
            }
        })
        .addCase("PROJECT_CREATE_SUCCESS", (state, action) => {
            if (action?.payload) {
                addProjectsToIndex(state, [action.payload])
            }
        })
    }
})

export const librarySelector = (state:any) => state.library;

export const libraryProjectsSelector = createSelector([
    librarySelector
], (library) => library.projects)

export const libraryProjectAsArraySelector = createSelector([
    libraryProjectsSelector
], (projects) => {
    return Object.values(projects)
})

export const libraryProjectSelector = createSelector([
    libraryProjectsSelector,
    (state, id) => id
], (projects, id) => projects && projects[id]);

export const libraryAnyByTypeSelector = createSelector([
    librarySelector,
    (state, type) => type,
    (state, type, id) => id,
], (library, type, id) => {
    if (type === "project") {
        return library && library.projects && library.projects[id]
    }
    if (type === "organization") {
        return library && library.organizations && library.organizations[id]
    }
    return null;
})