import React, { ReactNode, FunctionComponent } from "react";
import { Button, Dropdown} from "antd";
import { Link } from "react-router-dom";
import { AwesomeIconInfo, AwesomeInfo } from "components/common/AwesomeIcon";
import StandardButton from "components/common/StandardButton";
const DashboardBox: FunctionComponent<{
  className?: string;
  title?: string | ReactNode;
  description?: string | ReactNode;
  menu?: string | ReactNode;
  moreIcon?: ReactNode;
  moreLink?: any;
  style?: any;
}> = ({ title, description, children, className, menu, moreIcon, moreLink, style}) => (
  <div className={`dashboard-box ${className ? className : ""}`} style={style}>
    {title && <h2 className="title large">{title}</h2>}

    {menu && (
      <Dropdown overlay={(menu as any)} placement="bottomRight">
        <StandardButton type="link" size="small" icon={AwesomeIconInfo} />
      </Dropdown>
    )}
        {moreIcon && (
      <div className={`more-icon ${menu && "second-row"}`}>
          {moreIcon}
      </div>
    )}
    {description && <p>{description}</p>}
    {children}
  </div>
);

export default DashboardBox;
