import { Radio } from "antd";
import DisplayIfHasPermission from "components/common/DisplayIfHasPermission";
import DateTimeInput from "components/inputs/DateTimeInput";
import OrganizationGroupSelect from "tools/organization/components/OrganizationGroupSelect";
import FormattedMessage from "components/common/FormattedMessage";
import { ruleMax } from "tools/relaxForm/relaxPresets";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import { useCreationEditor } from "../CreationEditor";
import { creationContentSelector, patchCreationWithSmartAction } from "../creationsSlice";
import dayjs, { Dayjs } from 'dayjs';
import Checkbox from "components/inputs/Checkbox";
import { useState } from "react";
import DisplayIfSelector from "components/common/DisplayIfSelector";
import { CurrentProjectIsInOrganizationSelector, OrganizationIdSelector } from "tools/organization/data/organizationSlice";

const PublicationTypeInput = (props: any) => {

    return (
        <>
            <Radio.Group
                {...props}
            >
                <Radio value="instant">
                    <FormattedMessage
                        id="tools.creationEditor.fields.publicationType.values.instant"
                        defaultMessage="Instant"
                    /></Radio>
                {/* {props.isAd ? null : <Radio value="darkPost" disabled={true}>
                    <FormattedMessage
                        id="tools.creationEditor.fields.publicationType.values.darkPost"
                        defaultMessage="Dark Post"
                    /></Radio>} */}
                <Radio value="planned">
                    <FormattedMessage
                        id="tools.creationEditor.fields.publicationType.values.planned"
                        defaultMessage="Planned"
                    />
                </Radio>
            </Radio.Group>
        </>
    )

}

export const PostPublicationSettings = (props: any) => {
    const { creation, content, creationId } = useCreationEditor();

    const isAd = creation.type === "aoa" || creation.type === "creative";
    const insta = creation?.content?.type && creation.content.type.startsWith("instagram");


    return (
        <>
            <RelaxSingleField
                name={"publication_type"}
                label={<FormattedMessage
                    id="tools.creationEditor.fields.publicationType.label"
                    defaultMessage="Publication Type"
                />}
                onErrors={(e) => props.onErrors && props.onErrors("publication_type", e)}
                callAPI={patchCreationWithSmartAction(creationId, "ce_publication_type")}
                finalizeSubmit={(value: any) => {
                    if (value.publication_type === "planned") {
                        if (!creation.content["publication_date"] || creation.content["publication_date"] === "") {
                            return {
                                content: {
                                    publication_date: dayjs().add(1, "day").utc().format("YYYY-MM-DD HH:mm:ss"),
                                    ...value
                                }
                            }
                        }
                    }
                    else {
                        return {
                            content: {
                                publication_date: null,
                                ...value
                            }
                        }
                    }

                    return {
                        content: value
                    };
                }}
                required
                submitOnChange
                rules={[ruleMax(30)]}
            >
                <PublicationTypeInput isAd={isAd} />
            </RelaxSingleField>
            {
                (isAd || insta) ? null : (
                    <RelaxSingleField
                    name="dark"
                    onErrors={(e) => props.onErrors && props.onErrors("publication_type", e)}
                    callAPI={patchCreationWithSmartAction(creationId, "ce_publication_dark")}
                    submitOnChange
                    hideStatus={true}
                    tooltip={<FormattedMessage
                        id="tools.creationEditor.fields.publicationType.values.darkPost.tooltip"
                        defaultMessage="Dark Post"
                    />}
                >
                    <Checkbox>
                        <FormattedMessage
                            id="tools.creationEditor.fields.publicationType.values.darkPost"
                            defaultMessage="Dark Post"
                        />
                    </Checkbox>
                </RelaxSingleField>
                )
            }
            <RelaxSingleField
                name={"save_draft"}
                hideStatus={true}
                callAPI={patchCreationWithSmartAction(creationId, "ce_publication_groups")}
                submitOnChange
            >
                <Checkbox>
                    <FormattedMessage
                        id="tools.creationEditor.fields.saveDraft.afterPublication.label"
                        defaultMessage="Keep draft after publication"
                    />
                </Checkbox>
            </RelaxSingleField>
            {
                creation?.content["publication_type"] === "planned" && (
                    <RelaxSingleField
                        name={"publication_date"}
                        label={<FormattedMessage
                            id="tools.creationEditor.fields.publicationDate.label"
                            defaultMessage="Publication Date"
                        />}
                        onErrors={(e) => props.onErrors && props.onErrors("publication_date", e)}
                        callAPI={patchCreationWithSmartAction(creationId, "ce_publication_date")}
                        required
                        rules={[ruleMax(30)]}
                        finalizeSubmit={(value: any) => {
                            return {
                                content: {
                                    publication_date: value.publication_date.format("YYYY-MM-DD HH:mm:ss")
                                }
                            }
                        }}
                        submitOnChange
                    >
                        <DateTimeInput
                            disabledDate={true}
                            allowedPeriodStart={dayjs()}
                            UTC={true}
                        />
                    </RelaxSingleField>
                )
            }
            <DisplayIfSelector selector={CurrentProjectIsInOrganizationSelector}>
            <DisplayIfHasPermission permission={["use creations request", "use creations publish"]}>
                <RelaxSingleField
                    callAPI={patchCreationWithSmartAction(creationId, "ce_publication_groups")}
                    onErrors={(e) => props.onErrors && props.onErrors("publication_groups", e)}
                    name="publication_target"
                    label={<FormattedMessage
                        id="tools.creationEditor.fields.targetGroups.label"
                        defaultMessage="Target groups"
                    />}
                    required
                >
                    <OrganizationGroupSelect mode="multiple"/>
                </RelaxSingleField>
            </DisplayIfHasPermission>
            </DisplayIfSelector>
        </>
    )

}


