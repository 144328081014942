import { useEffect, useState } from "react";
import useAPI, { callAPIProps } from "services/hooks/useAPI";
import { HorizontalCenter } from "components/common/Center";
import { Alert, Spin } from "antd";
import CreditCard from "modules/panel/components/payment/creditcard/CreditCard";
import StandardButton from "components/common/StandardButton";
import { usePayment } from "./Payment";
import { StripeCardInput, useStripeCardInput } from "./StripeCardInput";
import StripeContext from "providers/stripe/StripeContext";

export const getDefaultPaymentMethod: callAPIProps = {
    url: ({ getApiUrl }) => getApiUrl("user/default-payment", 2),
}


export type CardData = {
    card_brand: string,
    card_last_4: string,
    expiration_month: string,
    expiration_year: string,
    "3dSecureSupported": boolean,
}

function PaymentMethodSelect() {

    const paymentContext = usePayment();
    //Fallback, to that the component can be used outside of the payment context
    const setPaymentMethodOk = paymentContext?.setPaymentMethodOk || (() => null);

    const defaultPaymentCall = useAPI(getDefaultPaymentMethod, true);

    const [cardData, setCardData] = useState<CardData | null>(null);


    const { AddNewCard, ConfirmPayment } = useStripeCardInput(); 
    const [loading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const [method, setMethod] = useState<"defaultCard" | "newCard" | "loading">("loading");

    const [newCardStatus, setNewCardStatus] = useState(false);

    const handleAddNewCard = async () => {
        setError(null);
        if (!newCardStatus) return;
        setIsLoading(true);
        const [res, err] = await AddNewCard();
        setIsLoading(false);

        if (err) {
            setError(err);
            setPaymentMethodOk(false);
            return;
        }

        if (res) {
            setPaymentMethodOk(true);
            setMethod("defaultCard");
            setCardData(res as CardData);
        }

    }

    useEffect(() => {
        defaultPaymentCall.call().then((res) => {

            if (res && res.status === 200 && res.data && res.data.id) {
                setMethod("defaultCard");
                setPaymentMethodOk(true);
                setCardData(res.data);
            } else {
                setMethod("newCard");
            }

        })
    }, []);

    const changeMethod = (newMethod: "defaultCard" | "newCard") => {
        if (newMethod === "defaultCard" && cardData) {
            setPaymentMethodOk(true);
        } else {
            setPaymentMethodOk(false);
        }
        setMethod(newMethod);
        setError(null);
    }

    const errorAlert = error ? <Alert message={error} type="error"/> : null;

    if (defaultPaymentCall.loading || method === "loading") return <HorizontalCenter><Spin /></HorizontalCenter>

    if (method === "defaultCard" && cardData) {
        return (
            <>
                <CreditCard
                    brand={cardData.card_brand}
                    lastFour={cardData.card_last_4}
                    expires={`${cardData.expiration_month}/${cardData.expiration_year}`}
                />

                <HorizontalCenter>
                    <StandardButton type="text" onClick={() => changeMethod("newCard")}>
                        Use a different card
                    </StandardButton>
                </HorizontalCenter>
                {errorAlert}
            </>
        )
    }

    if (method === "newCard") return (
        <>
            <StripeCardInput 
                onCardStatusChange={(status) => setNewCardStatus(status)}
            />
            <HorizontalCenter>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                }}>

                    {(cardData && cardData.id) && (
                        <StandardButton 
                        type="text" 
                        onClick={() => changeMethod("defaultCard")}
                        disabled={loading}
                        >
                            Use default card
                        </StandardButton>
                    )}
                    <StandardButton 
                    type="primary" 
                    onClick={handleAddNewCard}
                    loading={loading}
                    disabled={!newCardStatus} 
                    >
                        Use this card
                    </StandardButton>
                </div>
            </HorizontalCenter>
            {errorAlert}
        </>
    )

    return (
        <div>
            Payment Method Select
        </div>

    );
}

export const StandalonePaymentMethodSelect = () => {
    return (
        <StripeContext>
            <PaymentMethodSelect />
        </StripeContext>
    )
}

export default PaymentMethodSelect;