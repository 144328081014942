import { ReactNode } from "react";
import FormattedMessage from "components/common/FormattedMessage";

export const TranslatedActionStrings: Record<string, ReactNode> = {
    "promote": <FormattedMessage
        id="post.action.promote"
        defaultMessage="Promote"
    />,
    "edit": <FormattedMessage
        id="post.action.edit"
        defaultMessage="Edit"
    />,
    "renew": <FormattedMessage
        id="post.action.renew"
        defaultMessage="Renew"
    />,
    "retry": <FormattedMessage
        id="post.action.retry"
        defaultMessage="Retry"
    />,
}

export const TranslatedPostStatusStrings: Record<string, ReactNode> = {
    "active": <FormattedMessage
        id="post.status.active"
        defaultMessage="Active"
    />,
    "published": <FormattedMessage
        id="post.status.published"
        defaultMessage="Published"
    />,
    "draft": <FormattedMessage
        id="post.status.draft"
        defaultMessage="Draft"
    />,
    "scheduled": <FormattedMessage
        id="post.status.scheduled"
        defaultMessage="Scheduled"
    />,
    "error": <FormattedMessage
        id="post.status.error"
        defaultMessage="Error"
    />,
    "deleted": <FormattedMessage
        id="post.status.deleted"
        defaultMessage="Deleted"
    />,
    "mixed": <FormattedMessage
        id="post.status.mixed"
        defaultMessage="Mixed"
    />,
    "notPromoted": <FormattedMessage
        id="post.status.notPromoted"
        defaultMessage="Not Promoted"
    />,
    "with_issues": <FormattedMessage
        id="post.status.with_issues"
        defaultMessage="With Issues"
    />,
    "hidden": <FormattedMessage
        id="post.status.hidden"
        defaultMessage="Hidden"
    />,
}

export const TranslatedAdStatusStrings: Record<string, ReactNode> = {
    "active": <FormattedMessage
        id="post.ad.status.active"
        defaultMessage="Active"
    />,
    "published": <FormattedMessage
        id="post.ad.status.published"
        defaultMessage="Published"
    />,
    "draft": <FormattedMessage
        id="post.ad.status.draft"
        defaultMessage="Draft"
    />,
    "scheduled": <FormattedMessage
        id="post.ad.status.scheduled"
        defaultMessage="Scheduled"
    />,
    "error": <FormattedMessage
        id="post.ad.status.error"
        defaultMessage="Error"
    />,
    "mixed": <FormattedMessage
        id="post.ad.status.mixed"
        defaultMessage="Mixed"
    />,
    "paused": <FormattedMessage
        id="post.ad.status.paused"
        defaultMessage="Paused"
    />,
    "ended": <FormattedMessage
        id="post.ad.status.ended"
        defaultMessage="Ended"
    />,
    "pending": <FormattedMessage
        id="post.ad.status.pending"
        defaultMessage="Pending"
    />,
    "disapproved": <FormattedMessage
        id="post.ad.status.disapproved"
        defaultMessage="Disapproved"
    />,
    "notPromoted": <FormattedMessage
        id="post.ad.status.notPromoted"
        defaultMessage="Not Promoted"
    />,
    "with_issues": <FormattedMessage
        id="post.ad.status.with_issues"
        defaultMessage="With Issues"
    />,
}

export const GetTranslatedPostStatusString = (status: string): ReactNode => {
    return TranslatedPostStatusStrings[status] || status;
}

export const GetTranslatedAdStatusString = (status: string): ReactNode => {
    return TranslatedAdStatusStrings[status] || status;
}

export const GetTranslatedActionString = (action: string): ReactNode => {
    return TranslatedActionStrings[action] || action;
}