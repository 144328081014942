import { Spin, Tooltip } from "antd"
import { HorizontalCenter } from "components/common/Center"
import FormattedMessage from "components/common/FormattedMessage"
import Post from "models/post"
import { PostListAdvancedSettings } from "modules/posts/PostConfig"
import PostContextProvider from "modules/posts/postContext"
import postsSlice, { GetPosts, PostsSelector, SortPostsByDate } from "modules/posts/state/postsSlice"
import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import useAPIWithIncrementsNoPages from "services/hooks/useAPIWithIncrementsNoPages"
import useSelectorWithParams from "services/hooks/useSelectorWithParams"
import InfinityList from "tools/infinityList/infinityList"
import InfinityListAdvancedSettings from "tools/infinityList/infinityListAdvancedSettings"
import InfinityListQuery from "tools/infinityList/infinityListQuery"
import { PostListHeaders } from "tools/infinityList/infinityTemplates"
import { GetBots } from "./BotsSlice"
import useAPI from "services/hooks/useAPI"
import { CreatePostIcon } from "modules/panel/components/ft-icons"
import { currentProjectIdSelector } from "services/store/scopeSlice"
import BotContextProvider from "../components/BotContext"


export type PostListProps = {
    provider?: string;
}

export const PostsListWithBots = (props: PostListProps) => {

    const projectId = useSelector(currentProjectIdSelector);
    const [addBotKey, setAddBotKey] = useState(0)
    const [query, setQuery] = useState<any>()
    const { } = useAPI(GetBots(), true)

    const { } = useAPIWithIncrementsNoPages({
        callAPI: GetBots,
        query: query
    })

    const posts = useSelectorWithParams([PostsSelector]);
    const dispatch = useDispatch();
    const { startWithNewQuery, getNextIncrement, loading, emptyResponses } = useAPIWithIncrementsNoPages({
        callAPI: GetPosts,
        query: query
    })

    useEffect(() => {
        reloadList();
    }, [query, projectId]);

    const reloadList = () => {
        dispatch(postsSlice.actions.clearPosts({ projectId }))
        ListGetPosts();
    }


    const onQueryChange = (query: any) => {
        const normalizedQuery: any = {
            "date[from]": query.start.format("YYYY-MM-DD"),
            "date[to]": query.end.format("YYYY-MM-DD"),
            "sort": query.sort || "created_at",
            "included[0]": "fbpost",
            "included[1]": "gpost",
            "included[2]": "fbad",
            "included[3]": "gad",
            "included[4]": "aoa",
            "included[5]": "leadad",
            "included[6]": "bot",
            "withBot": "true"

        }
        if (props.provider) {
            normalizedQuery["provider"] = props.provider;
        }

        if (query.search) {
            normalizedQuery["search"] = query.search;
        }
        reloadList();
        setQuery(normalizedQuery);
    }

    const GetMore = () => {
        getNextIncrement((q) => {
            if (!q) {
                return;
            }
            const oldQ = q;
            const previousFrom = new Date(oldQ["date[from]"]);
            const previousTo = new Date(oldQ["date[to]"]);

            let newFrom: Date;
            let newTo: Date;

            if (query?.sort === "created_at") {
                newFrom = new Date(previousTo.getTime());
                newTo = new Date(newFrom.getTime() + 1000 * 60 * 60 * 24 * 7);
            } else {
                newFrom = new Date(previousFrom.getTime() - 1000 * 60 * 60 * 24 * 7);
                newTo = new Date(previousFrom.getTime());
            }

            return {
                ...oldQ,
                "date[from]": newFrom.toISOString().split("T")[0],
                "date[to]": newTo.toISOString().split("T")[0]
            };
        })
    }

    const handleLoadNextPage = () => {
        if (emptyResponses < 3) GetMore();
    }


    const ListGetPosts = () => {
        if (!query) return;
        startWithNewQuery(query);
    }

    const sortedPosts = useMemo(() => {

        if (!posts) return [];

        //const filteredPosts = FilterPosts(posts, interfaceSettings?.postList)
        const filteredPosts = posts;

        if (!query || !query.sort) return posts;
        if (query.sort === "created_at") {
            return SortPostsByDate(filteredPosts, false);
        }
        if (query.sort === "-created_at") {
            return SortPostsByDate(filteredPosts, true);
        }
        return filteredPosts;

    }, [posts])


    const renderPost = (post: Post) => {
        return (
            <PostContextProvider key={post.local_id} postId={post.local_id} options={{ bots: true }} reloadList={reloadList} />
        )
    }

    const extra = useMemo(() => {

        if (posts && posts.length > 0 && loading) return (
            <HorizontalCenter style={{ padding: "30px" }} key="loading">
                <Spin />
            </HorizontalCenter>
        )
        if (emptyResponses >= 3) return (
            <HorizontalCenter style={{ padding: "30px" }} key="nothing">
                <h3>
                    <FormattedMessage
                        id="posts.list.error.nothingMore"
                        defaultMessage="Nothing more to show. Please change the date range."
                    />
                </h3>
            </HorizontalCenter>
        )
        return null
    }, [emptyResponses, loading])

    const queryInput = useMemo(() => {
        return (
            <InfinityListQuery
                onChange={onQueryChange}
                onReload={reloadList}
                sort={true}
                date={true}
            >

                <div style={{ height: "100%", display: "grid", placeItems: "center" }}>
                    <BotContextProvider
                        key={addBotKey}
                        setKey={setAddBotKey}
                        actionType="create"
                        btnContent={
                            <Tooltip title={
                                <FormattedMessage
                                    id="tools.bots.actions.new.tooltip"
                                    defaultMessage="New Bot"
                                />
                            }>
                                <span style={{ marginTop: "5px" }}>
                                    <CreatePostIcon />
                                </span>
                            </Tooltip>
                        }
                    />

                </div>

                <InfinityListAdvancedSettings settings={PostListAdvancedSettings} />
            </InfinityListQuery>
        )
    }, [query, addBotKey])



    return (

        <div className="infinity-wrapper">
            {queryInput}
            <InfinityList
                headers={PostListHeaders}
                items={sortedPosts}
                loading={loading}
                loadMoreData={handleLoadNextPage}
                itemSkeleton={() => "skeleton"}
                renderItem={renderPost}
                additionalItems={[
                    extra
                ]}
            />
        </div >
    )
}