import React, { PureComponent } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Form } from "@ant-design/compatible";
import { Button, Input, Alert } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import { loadUser, login, LOGIN } from "modules/panel/actions/user/login";
import { getUrl, RESET_PASSWORD } from "modules/panel/config/routes";
import FormItem from "_components/forms/form-item-wrapper/form-item-wrapper";
import CreateForm from "services/hoc/form-hoc";
import { SubscriptionConsumer } from "providers/subscription/subscription";
import { userIsLoggedSelector } from "modules/panel/config/selectors/user";
import {
  EmailEnvelopeIcon,
  PasswordLockIcon,
} from "modules/panel/components/ft-icons";
import FacebookAuthButton from "modules/panel/components/auth/facebook-auth-btn";
import StandardButton from "components/common/StandardButton";
import { LocalLanguageSelectInput } from "components/inputs/LanguageAndCountrySelectors";
import { HorizontalCenter } from "components/common/Center";

class LoginForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loginViaPasswordVisible: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.form.status.error !== this.props.form.status.error) {
      this.props.form.instance.setFields({
        password: {
          value: "",
        },
      });
    }

    if (!prevProps.form.status.success && this.props.form.status.success)
      this.props.loadUser();
  }

  showRegisterViaPassword = () =>
    this.setState({ loginViaPasswordVisible: true });

  render() {
    const locationState = this.props.location.state;
    return (
      <>
       {localStorage.getItem("userWantsToDeleteAccount") === "true" && (
          <Alert
            message={
              <FormattedMessage
                id="panel.containers.login.info.deleteAccount"
                defaultMessage="To start deleting account you must login"
              />
            }
            type="info"
            style={{
              marginBottom: "10px"
            }}
            showIcon
          />
        )}
        <SubscriptionConsumer>
          {({ index }) => (
            <>
              {this.props.logged && (
                <Redirect to={locationState ? locationState.from : index} />
              )}
            </>
          )}
        </SubscriptionConsumer>
        <FacebookAuthButton
          label={
            <FormattedMessage
              id="panel.containers.login.facebook"
              defaultMessage="Login with Facebook"
            />
          }
        />
        <strong
          className="or"
          style={{
            textAlign: "center",
            margin: "20px 0",
            display: "block",
          }}
        >
          <FormattedMessage
            id="panel.containers.login.or"
            defaultMessage="or"
          />
        </strong>
        {/* {!this.state.loginViaPasswordVisible && (
          <StandardButton
            onClick={this.showRegisterViaPassword}
            type="secondary"
          //style={{ marginBottom: "20px" }}
          >
            <FormattedMessage
              id="panel.containers.login.oldFashionedWay"
              defaultMessage="Old fashioned login"
            />
          </StandardButton>
        )}
        {this.state.loginViaPasswordVisible && ( */}
        <>
          {this.props.form.status.error && (
            <Alert
              message={
                <FormattedMessage
                  id="panel.containers.login.wrongPassword"
                  defaultMessage="Wrong username or password"
                />
              }
              type="warning"
            />
          )}
          <Form onSubmit={this.props.form.handleSubmit} className="login-form">
            <div className="content">
              <div className="login-via-email">
                <FormItem
                  field="email"
                  required={true}
                  validator="email"
                  label={
                    <FormattedMessage
                      id="panel.containers.login.email"
                      defaultMessage="Your email"
                    />
                  }
                  form={this.props.form}
                >
                  <Input
                    data-testid="email"
                    type="email"
                    suffix={<EmailEnvelopeIcon />}
                  />
                </FormItem>
                <FormItem
                  field="password"
                  required={true}
                  label={
                    <FormattedMessage
                      id="panel.containers.login.password"
                      defaultMessage="Your password"
                    />
                  }
                  form={this.props.form}
                >
                  <Input type="password" suffix={<PasswordLockIcon />} />
                </FormItem>
                <StandardButton
                  data-testid="loginBtn"
                  type="primary"
                  htmlType="submit"
                  //style={{ marginBottom: "30px" }}
                  loading={
                    this.props.form.status.pending ||
                    this.props.form.status.success
                  }
                >
                  <FormattedMessage
                    id="panel.containers.login.signIn"
                    defaultMessage="Login"
                  />
                </StandardButton>
              </div>
            </div>
          </Form>
        </>
        {/* )} */}

        <p className="form-addition-info">
          <FormattedMessage
            id="panel.containers.login.forgotPassword"
            defaultMessage="Forget your password buddy?"
          />
          <Link to={getUrl(RESET_PASSWORD)}>
            <FormattedMessage
              id="panel.containers.login.resetPassword"
              defaultMessage="Get a new password!"
            />
          </Link>
        </p>
        <HorizontalCenter>
          <div>
            <FormattedMessage
              id="panel.containers.login.tosWarning.message"
              defaultMessage="By logging in you agree to our {tosLink} and {privacyLink}"
              values={{
                tosLink: (
                  <a
                    href={import.meta.env.VITE_INSTANCE_TERMS_URL}
                    target="_blank"
                  >
                    <FormattedMessage
                      id="panel.containers.login.tosWarning.termsOfService"
                      defaultMessage="Terms of Service"
                    />
                  </a>
                ),
                privacyLink: (
                  <a
                    href={import.meta.env.VITE_INSTANCE_PRIVACY_POLICY_URL}
                    target="_blank"
                  >
                    <FormattedMessage
                      id="panel.containers.login.tosWarning.privacyPolicy"
                      defaultMessage="Privacy Policy"
                    />
                  </a>
                ),
              }}
            />
          </div>
        </HorizontalCenter>

       

        <div className="fixed-onboarding-controls">
          <div className="onboarding-language-input">
            <LocalLanguageSelectInput flagOnly={true} />
          </div>
        </div>
      </>
    );
  }
}

export default CreateForm({
  form: {
    dispatch: login,
    action: LOGIN,
  },
  redux: {
    mapStateToProps: (state) => {
      return {
        logged: userIsLoggedSelector(state),
      };
    },
    mapDispatchToProps: (dispatch) => {
      return {
        loadUser: () => dispatch(loadUser()),
      };
    },
  },
})(withRouter(LoginForm));
