
import { Menu } from "antd";
import { MoreOptionsDropdown } from "modules/smart/components/list-item/base-list-item/MoreOptionsDropdown";
import { DeleteBotBtn } from "./DeleteBotBtn";



type Props = {
  botId?: number;
  type?: string;
  postId?: string;
  previewUrl?: string;
  withPostControls?: boolean;
};

const BotOptionsDropdown = ({
  botId,
  postId,
  previewUrl,
  withPostControls = true,
}: Props) => (
  <MoreOptionsDropdown
    previewUrl={previewUrl}
    postId={postId}
    hidePostControls={!withPostControls}
  >
    {botId && (
      <Menu.Item>
        <DeleteBotBtn botId={botId} />
      </Menu.Item>
    )}  
  </MoreOptionsDropdown>
);

export default BotOptionsDropdown;
