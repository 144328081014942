import { ConsoleSqlOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import React from "react";
import FormattedMessage from "components/common/FormattedMessage";
import { TokenLocalStorage } from "services/helpers/local-storage";
import { userLanguageSelector } from "modules/panel/config/selectors/user";
import { useSelector } from "react-redux";

const { confirm } = Modal;

export function handleFacebookTokenErrorMiddleware({ dispatch, getState }) {
  return (next) => (action) => {

    if (action) {

    const { type, payload } = action;

    const matches = /(.*)_(FAILURE)/.exec(type);


    const errorCode =
      payload &&
      payload.response &&
      payload.response.error &&
      payload.response.error.code
        ? payload.response.error.code
        : undefined;

    let loginStatus = getState().loginStatus;

    if (matches && loginStatus.get("logged") && errorCode && errorCode === 6402)
      reauthFacebookPopup();

    if (payload && payload.included) {
      Promise.resolve(payload.included).then(
        (val) =>
          val &&
          val.map((notification) => {
            const { attributes } = notification;
            if (attributes && attributes.code && attributes.code === "6402") {
              reauthFacebookPopup();
            }
            return "";
          })
      );
    }

    if (payload && payload.access_tokens) {


      let needReauth = false;
      if (payload.access_tokens?.facebook?.need_reauth === true) needReauth = true;
      if (payload.access_tokens?.[0]?.need_reauth === true) needReauth = true;

      if (needReauth) {       
        //console.log("Need reauth")
        dispatch({type: "NEED_REAUTH", payload: {platform: "facebook"}})
       // const reauthModal = reauthFacebookPopup(false);
      }
    }

  }

    next(action);
  };
}

  export const reauthFacebookPopup = (closable = true) =>
  confirm({
    closable: closable,
    title: (
      <FormattedMessage
        id="services.middlewares.tokenError.facebook.title"
        defaultMessage="There is a problem with your Facebook integration"
      />
    ),
    content: (
      <FormattedMessage
        id="services.middlewares.tokenError.facebook.content"
        defaultMessage="You need to authorize Facebook again"
      />
    ),
    centered: true,
    okText: (
      <FormattedMessage
        id="services.middlewares.tokenError.facebook.authorize"
        defaultMessage="Authorize"
      />
    ),
    cancelButtonProps: {
      disabled: !closable,
    },
    cancelText: (
      <FormattedMessage
        id="services.middlewares.tokenError.facebook.later"
        defaultMessage="Later"
      />
    ),
    onOk() {
      window.location.href = `${import.meta.env.VITE_FACEBOOK_AUTH_URL}${
        TokenLocalStorage.get().token
      }`;
    },
  });

export default handleFacebookTokenErrorMiddleware;
