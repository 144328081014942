import { ReactNode } from "react";
import find from "lodash/find";
import { AppConfig } from "modules";
import { FlatRouteConfig, RouteConfig } from "providers/routing/routes/routes";
import { Layout } from "providers/routing/layouts/templates/templates";
import Layouts, { LayoutRouteConfig } from "providers/routing/layouts/layouts";
import { compile } from "path-to-regexp";

export const getFlatRoutesPrefixedByApp = (apps: AppConfig[]) => {
  let appsRoutes: FlatRouteConfig[] = [];

  apps.forEach((app) => {
    const prefixedFlatAppRoutes = getFlatRoutes(app.routes).map((route) => ({
      ...route,
      path: `/${app.path}${route.path}`,
    }));

    appsRoutes = [...appsRoutes, ...prefixedFlatAppRoutes];
  });

  return appsRoutes;
};

export const getFlatRoutes = (routes: RouteConfig[]) => {
  const flatRoutes: RouteConfig[] = [];

  routes.forEach((route) => {
    RoutesHelper.hasSubroutes(route.content)
      ? flatRoutes.concat(route.content)
      : flatRoutes.push(route);
  });

  return flatRoutes as FlatRouteConfig[];
};

export const getLayoutRoutes = (routes: FlatRouteConfig[], layout: Layout) =>
  routes.filter((route) => route.layout === layout);

export const getPublic = (routes: FlatRouteConfig[]) =>
  authorized(routes, false);

export const getAuthorized = (routes: FlatRouteConfig[]) =>
  authorized(routes, true);

export const authorized = (routes: FlatRouteConfig[], authorized: boolean) =>
  routes.filter((route) =>
    !authorized ? route.public === true : route.public !== true
  );

export const getPathsArray = (routes: FlatRouteConfig[]) =>
  routes.map((route) => route.path);

export class RoutesHelper {
  static getPath = (
    appPath: string,
    routes: LayoutRouteConfig[],
    routeKey: string,
    submenu?: string,
    options?: any
  ) => {
    const route = find(
      getFlatRoutes(routes),
      (route) => route.key === routeKey
    ) as LayoutRouteConfig;

    if (!route) {
      console.warn("We could not find route " + routeKey);
      return "";
    }

    let link = `/${appPath}${route.path}`;

    //TODO: Find and refactor all uses of options & submenu - it should be done via regexp-to-path (take crm as example)

    if (options && options.params) {
      return `/${appPath}${compile(route.path)(options.params)}`;
    }

    if (submenu) {
      route
        .submenu!.filter((route) => route.key === submenu)
        .map((submenu_route) => (link = `/${appPath}${submenu_route.path}`));
    }

    return link;
  };

  //TODO: only till ACL ready
  static getUrl = (
    appPath: string,
    layout: Layout,
    isPublic = false,
    routePath: string
  ) => {
    const layoutConfig = find(Layouts, (config) => config.key === layout);

    if (!layoutConfig) {
      console.warn("We could not find layout " + layout);
      return "";
    }

    return `/${appPath}${routePath}`;
  };

  static hasSubroutes = (
    content: RouteConfig[] | ReactNode
  ): content is RouteConfig[] => Array.isArray(content);

  static getSubmenuUrl = (
    appPath: string,
    routes: LayoutRouteConfig[],
    routeKey: string
  ) => {
    const keys = routeKey.split(".");
    return RoutesHelper.getPath(appPath, routes, keys[0], keys[1]);
  };
}

export default RoutesHelper;
