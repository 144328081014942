import { faFileImport, faInboxArrowDown } from "@fortawesome/pro-light-svg-icons"
import AwesomeIcon from "components/common/AwesomeIcon"
import FormattedMessage from "components/common/FormattedMessage"
import StandardButton from "components/common/StandardButton"
import { useEffect, useMemo, useState } from "react"
import { StandardModal, useStandardModal } from "services/hooks/useModal"
import Papa from 'papaparse';


export const MapPointImportToolModal = (props: any) => {


    const [ref, open, close] = useStandardModal();
    const [data, setData] = useState([]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            parseCSV(file);
        }
    };

    const parseCSV = (file) => {
        Papa.parse(file, {
            complete: (result) => {
                setData(result.data);
            },
            header: true,
        });
    };

    const results = useMemo(() => {
        return (
            <div style={{
                maxHeight: "200px",
                overflowY: "scroll",
            }}>
                <table style={{
                    width: "100%",
                }}>
                    <tr>
                        <th>Name</th><th>Latitude</th><th>Longitude</th><th>Radius</th>
                    </tr>
                    {data.map((d: any, i: number) => (
                        <tr>
                            <td>{d.name}</td><td>{d.latitude}</td><td>{d.longitude}</td><td>{d.radius}</td>
                        </tr>
                    ))}
                </table>
            </div>
        )
    }, [data])

    const handleOk = () => {
        close();
        props.onOk && props.onOk(data);
    }

    return (
        <>
            <StandardButton icon={faFileImport} size="xl" onClick={open} />
            <StandardModal
                ref={ref}
                width={"900px"}
                onCancel={close}
                title={<FormattedMessage
                    id="tools.map.import.title"
                    defaultMessage="Import map points"
                />}
                onOk={handleOk}
            >
                <div>
                    <FormattedMessage
                        id="tools.map.import.description"
                        defaultMessage="You can import a CSV file to add multiple map points at once."
                    />
                    <div>
                    <a href={import.meta.env.VITE_GEOPOINTS_EXAMPLE_CSV_URL} download>
                        <FormattedMessage
                            id="tools.map.import.download.example"
                            defaultMessage="Example CSV"
                        />
                        </a>
                    </div>

                    {data && data.length > 0 && (
                        <div>
                            <FormattedMessage
                                id="tools.map.import.summary"
                                defaultMessage="Imported points:"
                            />
                            {results}
                        </div>
                    )}
                    <input type="file" accept=".csv" onChange={handleFileChange} />
                </div>
            </StandardModal>
        </>
    )

}