import { Button, Col, Input, Row, Space } from "antd";
import StandardButton from "components/common/StandardButton";
import { StandardFullColumn, StandardHalfFullColumn, StandardRow, StandardThirdColumn } from "components/layout/StandardGrid";
import _ from "lodash";
import { ReactNode, useEffect, useMemo, useState } from "react";
import FormattedMessage from "components/common/FormattedMessage";
import useAPI from "services/hooks/useAPI";
import KeywordGoogle from "./KeywordGoogle";

export type GoogleKeywordType = {
    id: number,
    label: string,
    status: string,
    type: string,
}


const KeywordsGoogle = () => {

    const [keywords, setKeywords] = useState<GoogleKeywordType[]>([]);
    const [input, setInput] = useState<string>("");
    const [error, setError] = useState<string | ReactNode | null>(null);
    const { call, data, loading, error: apiError, StatusDisplay } = useAPI({
        url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/smart/google-ads-settings/keywords`),
    }, true)
    const { call: callAdd, data: addData } = useAPI({
        url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/smart/google-ads-settings/keywords`),
        method: "POST"
    })
    const { data: suggestionData, loading: suggestionsLoading, error: suggestionsError, StatusDisplay: suggestionsStatusDisplay } = useAPI({
        url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/services/google/ads/keywords/proposition?restrict=false`, 2),
        method: "GET"
    }, true)
    const { call: callUpdate, data: updateData } = useAPI({
        method: "PATCH"
    }, true)

    const suggestions = useMemo(() => {
        if (!suggestionData || suggestionData.length === 0) return [];
        const filtered = suggestionData.filter((suggestion: any) => {
            return !keywords.find((keyword: GoogleKeywordType) => keyword.label === suggestion.label.key)
        })

        return filtered.map((suggestion: any) => ({
            id: suggestion.id,
            label: suggestion.label?.key,
            status: "suggestion",
            type: "suggestion"
        }))
    }, [suggestionData, keywords])

    const safelyUpdateKeywords = (newKeywords: GoogleKeywordType[]) => {
        setKeywords(_.uniqBy(newKeywords, "id"))
    }

    useEffect(() => {
        if (!updateData) return;
        safelyUpdateKeywords(_.cloneDeep([...keywords, updateData]));
    }, [updateData]);

    useEffect(() => {
        if (data) { safelyUpdateKeywords(data); }
    }, [data]);

    useEffect(() => {
        if (addData) {
            safelyUpdateKeywords(_.cloneDeep([...keywords, addData]));
        }
    }, [addData]);

    const handleAdd = (type: string) => {

        if (checkInput(input) === false) return;

        const keyword = {
            label: input,
            status: "enabled",
            type: type
        }

        callAdd({
            body: keyword
        })
    }

    const handleAddSuggestion = (suggestion: any, type: string) => {

        const keyword = {
            label: suggestion,
            status: "enabled",
            type: type
        }

        callAdd({
            body: keyword
        })
    }

    const checkInput = (i: string) => {
        if (!i) {
            setError(null);
            return false;
        }
        if (i.length === 0) {
            setError(null);
            return false;
        }
        if (i.length > 80) {
            setError(
                <FormattedMessage
                    id="components.keywords.maxKeywordLength"
                    defaultMessage="Keyword can be maximum 80 characters long"
                />
            );
            return false;
        }
        if (keywords.find(k => k.label === i)) {
            setError(
                <FormattedMessage
                    id="components.keywords.noDuplicates"
                    defaultMessage="This keyword already exists"
                />)
            return false;
        }
        setError(null);
        return true;
    }


    const handleInput = (s: string) => {
        setInput(s);
        checkInput(s);
    }


    const handleActiveSwitch = (keyword: GoogleKeywordType, state: boolean) => {
        keyword.status = state ? "enabled" : "paused";
        callUpdate({
            url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/smart/google-ads-settings/keywords/${keyword.id}`),
            body: keyword
        })
        //setKeywords(_.cloneDeep(keywords))
    }

    const handleTypeChange = (keyword: GoogleKeywordType, type: "include" | "exclude" | "suggestion") => {
        if (keyword.status === "suggestion") {
            handleAddSuggestion(keyword.label, type)
        } else {
            keyword.type = type
            callUpdate({
                url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/smart/google-ads-settings/keywords/${keyword.id}`),
                body: keyword
            })
        }
        //setKeywords(_.cloneDeep(keywords))
    }

    const filterKeywordsByTypeAndMap = (type: "include" | "exclude" | "suggestion") => {
        if (!keywords || !keywords.length) return null;
        if (type === "suggestion") return suggestions.map((keyword: GoogleKeywordType) => (
            <KeywordGoogle
                keyword={keyword}
                onToggleActive={handleActiveSwitch}
                onTypeChange={handleTypeChange}
            />))
        return keywords.filter((keyword) => keyword.type === type).map((keyword: GoogleKeywordType) => (
            <KeywordGoogle
                keyword={keyword}
                onToggleActive={handleActiveSwitch}
                onTypeChange={handleTypeChange}
            />))
    }

    const mappedExcludes = useMemo(() => filterKeywordsByTypeAndMap("exclude"), [keywords])
    const mappedIncludes = useMemo(() => filterKeywordsByTypeAndMap("include"), [keywords])
    const mappedSuggestions = useMemo(() => filterKeywordsByTypeAndMap("suggestion"), [keywords, suggestions])

    if (loading || apiError) return (StatusDisplay)

    return (
        <div className="keywords-selector" style={{ paddingTop: "20px" }}>
            <StandardRow>
                <StandardThirdColumn>
                    <FormattedMessage
                        id="components.keywords.excluded"
                        defaultMessage="Excluded"
                    />
                    <div
                        className="standard-border"
                        style={{
                            display: "flex",
                            width: "100%",
                            height: "500px",
                            overflow: "scroll",
                            justifyContent: "start",
                            flexWrap: "wrap",
                            gap: "5px",
                            alignItems: "flex-start",
                            alignContent: "flex-start"
                        }}>
                        {mappedExcludes}
                    </div>
                </StandardThirdColumn>
                <StandardThirdColumn>

                    <FormattedMessage
                        id="components.keywords.included"
                        defaultMessage="Included"
                    />
                    <div
                        className="standard-border"
                        style={{
                            display: "flex",
                            width: "100%",
                            height: "500px",
                            overflow: "scroll",
                            justifyContent: "start",
                            flexWrap: "wrap",
                            gap: "5px",
                            alignItems: "flex-start",
                            alignContent: "flex-start"
                        }}>
                        {mappedIncludes}
                    </div>
                </StandardThirdColumn>
                <StandardThirdColumn>
                    <FormattedMessage
                        id="components.keywords.suggestions"
                        defaultMessage="Suggested Keywords"
                    />
                    <div
                        className="standard-border"
                        style={{
                            display: "flex",
                            width: "100%",
                            height: "500px",
                            overflow: "scroll",
                            justifyContent: "start",
                            flexWrap: "wrap",
                            gap: "5px",
                            alignItems: "flex-start",
                            alignContent: "flex-start"
                        }}>
                        {(suggestionsLoading || suggestionsError) ? suggestionsStatusDisplay : mappedSuggestions}
                    </div>
                </StandardThirdColumn>

            </StandardRow>
            <StandardRow>
                <StandardFullColumn>
                <div style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-start",
                    alignItems: "center",

                }}>
                    <Input placeholder="New Keyword" onChange={(e) => handleInput(e?.target?.value)} />
                    <StandardButton
                        onClick={() => handleAdd("exclude")}
                        disabled={!!error || !input || input.length === 0}
                        style={{
                            background: "transparent linear-gradient(180deg, #f85b5b 0%, #f85b5b 50%, #f44646 61%, #e80000 100%) 0% 0% no-repeat padding-box",
                            color: "white",
                            border: "none"
                        }}
                    >
                        <FormattedMessage
                            id="components.keywords.exclude"
                            defaultMessage="Exclude"
                        />
                    </StandardButton>
                    <StandardButton
                        onClick={() => handleAdd("include")}
                        disabled={!!error || !input || input.length === 0}
                        style={{
                            background: "transparent linear-gradient(183deg, #3d61ef 0%, #0432c4 100%) 0% 0% no-repeat padding-box",
                            color: "white",
                            border: "none"
                        }}
                    >
                        <FormattedMessage
                            id="components.keywords.include"
                            defaultMessage="Include"
                        />
                    </StandardButton>
                </div>
                </StandardFullColumn>
                <StandardRow>
                    <StandardFullColumn>
                        {error}
                    </StandardFullColumn>
                </StandardRow>
            </StandardRow>
        </div>
    )


}

export default KeywordsGoogle;