import React, { useCallback, useEffect, useRef, useState } from "react";
import { Alert, Col, Input, Row } from "antd";
import { FormHocProps } from "_components/forms/form-item-wrapper/form-item-wrapper";
import PhoneNumberInputWithCountrySelect from "_components/forms/PhoneNumberInputWithCountrySelect";
import RelaxField from "tools/relaxForm/RelaxField";
import StandardButton from "components/common/StandardButton";
import FormattedMessage from "components/common/FormattedMessage";
import OnboardingControls from "providers/onboarding/controls";
import useAPI from "services/hooks/useAPI";
import PhoneErrorAlert from "./PhoneErrorAlert";
import { userSelector } from "modules/panel/config/selectors/user";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";


const OnboardingVerifyPhone = (form: FormHocProps) => {

  const [step, setStep] = useState<'phoneFromApiStep'|'phoneStep' | 'verifyStep' | 'errorStep' | 'nextPage'>('phoneFromApiStep');
  const user = useSelectorWithParams(userSelector)
  const [phone, setPhone] = useState<string | null>(user.phone);
  const [verifyCode, setVerifyCode] = useState()


  const phoneNumberInputRef = useRef<any>(null);

  const { call: sendPhone, error: phoneError, loading: phoneLoading } = useAPI({
    url: ({ getApiUrl }) => getApiUrl("user/verify-sms", 2),
    method: "POST",
    dispatchName: "VERIFY_PHONE_VIA_SMS",
  });

  const { call: sendVerifyCode, error: verifyError, loading: verifyLoading } = useAPI({
    url: ({ getApiUrl }) => getApiUrl(`user/phone`, 1),
    method: "PATCH",
    dispatchName: 'USER_DATA_SET',
  });

  useEffect(()=>{
    if(!user.phone){
      setStep('phoneStep')
    }
    else{
      setPhone(user.phone)
    }
  },[])

  const handlePhone = () => {

    const phoneToSend = phone || user.phone;

    sendPhone({
      body: { phone: phoneToSend },
    }).then((res) => {
      if (res.status === 200 || res.status === 204) {
        setStep('verifyStep')
      }
      else {
        setStep('errorStep')
        setPhone(user.phone)
      }
    })

  };

  const handlePhoneFocus = () => {
    setStep('phoneStep')
    phoneNumberInputRef.current?.focus();
  }

  const handleVerifyCode = (event: any) => {
    const { value } = event.target;
    setVerifyCode(value);
  };

  const handleCheckPhone = () => {

    const values = {
      phone_number: phone,
      verify_code: verifyCode
    }

    sendVerifyCode({
      body: values
    }).then((res) => {
      if (res.status === 200 || res.status === 204) {
        setStep('nextPage')
      }
      else {
        setStep('errorStep')
      }
    })

  }

  return (
    <>
      <RelaxField
        name="phone"
        required={true}
        hideStatus={true}
        submitOverride ={(data:any)=>{
            setPhone(data.phone);
        }}
        label={
          <FormattedMessage
            id="panel.containers.register.phoneNumber"
            defaultMessage="Phone number"
          />
        }
        initialValuesSelector={userSelector}
        ref={phoneNumberInputRef}
        disabled={step === 'phoneStep' || phoneLoading ? false : true}
      >
        <PhoneNumberInputWithCountrySelect />
      </RelaxField>

      {step !== 'phoneStep'  && (
        <div style={{marginBottom: "30px"}}>
          <a onClick={handlePhoneFocus}>
            <FormattedMessage
              id="panel.containers.register.verificationCode.changeNumber"
              defaultMessage="Change number"
            />
          </a>
        </div>
      )}

      {step === 'errorStep' && (
        <PhoneErrorAlert
          phoneError={phoneError}
          verifyError={verifyError}
          setStep={setStep}
          handlePhone={handlePhone}
          handlePhoneFocus={handlePhoneFocus}
        />
      )}

      {step === 'phoneStep' || step === 'phoneFromApiStep' ? (
        <Row className="onboarding-controls" gutter={30}>
          <Col span={24}>
            <StandardButton onClick={handlePhone} loading={phoneLoading} type="primary">
              <FormattedMessage
                id="panel.containers.register.verificationCode.verifyPhone"
                defaultMessage="Next step"
              />
            </StandardButton>
          </Col>
        </Row>
      ) : (
        <>
          <label>
            <FormattedMessage
              id="panel.containers.register.verificationCode"
              defaultMessage="Verification code"
            />
          </label>
          <Input onChange={handleVerifyCode} disabled={step === 'nextPage' || verifyLoading ? true : false} value={verifyCode} />


          {step === 'verifyStep' || step === 'errorStep' ? (
            <StandardButton type="primary" onClick={handleCheckPhone} loading={verifyLoading} style={{ marginTop: "30px" }}>
              <FormattedMessage
                id="panel.containers.register.verifyPhone"
                defaultMessage="Verify number"
              />
            </StandardButton>
          ) : step === 'nextPage' ? (
            <>
              <Alert
                type="success"
                style={{marginTop : '10px'}}
                message={
                  <FormattedMessage
                    id="panel.containers.register.verifyPhone.correctly"
                    defaultMessage="Code verified correctly"
                  />
                }
              />
            </>
          ) : ('')}

        </>
      )}
      <OnboardingControls hideButtons = {step !== "nextPage"}/>
    </>
  );
};

export default OnboardingVerifyPhone;