import { ProductConfig } from "products";
import logo from "products/crm/logo.svg";
import requirements from "modules/call/config/requirements"; 

const config: ProductConfig = {
  name: "CRM",
  logo,
  requirements
};

export default config;
