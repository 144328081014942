import { Col, Input, Row, Spin, Table } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import React, { useEffect, useMemo } from "react";
import useAPI from "services/hooks/useAPI";
import {
  AddProduct,
  CatalogProps,
  DeleteProduct,
  GetProductsCatalog,
  UpdateCatalog,
  UpdateProduct,
} from "state/productsCatalogSlice";
import RelaxField from "tools/relaxForm/RelaxField";
import UploadProductsModal from "./UploadProductsModal";
import { productsColumns } from "./ProductsConfig";
import { ruleMin } from "tools/relaxForm/relaxPresets";
import ProductModal from "./ProductModal";

type Props = {
  catalog: CatalogProps;
};

export default function Catalog(props: Props) {
  const getProductsCatalog = useAPI(GetProductsCatalog(props.catalog.id), true);
  const updateCatalog = useAPI(UpdateCatalog(props.catalog.id));

  return (
    <>
      <Row>
        <Col>
          <RelaxField
            name="name"
            callAPI={UpdateCatalog(props.catalog.id)}
            initialValue={props.catalog.name}
            submitOverride={(data: any) => {
              updateCatalog.call().then(() => {
                getProductsCatalog.call();
              });
            }}
            label={
              <FormattedMessage
                id="panel.productCatalogs.catalog.name.input"
                defaultMessage="Catalog Name"
              />
            }
            rules={[ruleMin(3)]}
          >
            <Input />
          </RelaxField>
        </Col>
        <Col style={{ padding: "23px" }}>
          <UploadProductsModal catalogId={props.catalog.id} />
        </Col>
        <Col style={{ paddingTop: "23px" }}>
          <ProductModal
            action={"add"}
            catalogId={props.catalog.id}
            label={
              <FormattedMessage
                id="panel.productCatalogs.catalog.add.product"
                defaultMessage="Add Product"
              />
            }
          />
        </Col>
      </Row>
      <Row>
        <Spin spinning={getProductsCatalog.loading || updateCatalog.loading}>
          <Table
            style={{
              height: "100%",
            }}
            className="compact-table"
            sticky
            columns={productsColumns(
              props.catalog.id
            )}
            dataSource={props.catalog.products}
            pagination={false}
          />
        </Spin>
      </Row>
    </>
  );
}
