import CodeDisplay from "components/displays/CodeDisplay";
import { StandardBox } from "components/layout/StandardBoxes";
import { UserWebsiteUrl } from "modules/site-generator/reducers/websiteSlice";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import useAPI from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";

export default function Domain () {

    const {call, data, loading, error, StatusDisplay} = useAPI({
        url: ({getApiUrl, projectId}) => getApiUrl(`projects/${projectId}/website`,2),
      }, true)

    const domainSelector = useSelectorWithParams(UserWebsiteUrl)
    
      const domain = useMemo(() => {
        if (!data) return {}
        return {
            domain: data.domain,
            active: data.domain_active,
            ssl: data.domain_ssl,
            url: `${data.domain_ssl ? "https://" : "http://"}${data.domain}`
        }
      }, [data]);

      const ip = import.meta.env.VITE_WEBSITE_SERVER_IP || "127.0.0.1"

      const domainSettings = useMemo(() => {
        if (!domain) return null;
        
        return (`${domain.domain} IN A ${ip}\nwww.${domain.domain} IN A ${ip}\n${domain.domain} IN TXT "${domainSelector}"`)
      }, [domain])

      if (error || loading) return StatusDisplay;

      if (!domain || !domainSettings) return null;

      return (<CodeDisplay code={domainSettings} sendEmail={false}/>)


}

export const DomainBox = () =>  {

  return (
    <StandardBox
      title={"Domain"}
    >
      <Domain />
    </StandardBox>
  )

}