import React, { Component } from "react";
import Tabs from "_components/tabs/tabs";
import FormattedMessage from "components/common/FormattedMessage";
import SettingsAgency from "./SettingsAgency";
import SettingsFacebookAdvanced from "./SettingsFacebookAdvanced";
import { FACEBOOK_PAGES, ProviderConfig, getProviderFieldConfigByName } from "modules/panel/config/ProviderConfig";
import { ProviderFieldIcon } from "modules/panel/config/ProviderHelpers";

export default function SettingsAdvanced () {
    return (
      <Tabs
        className="stylized-content"
        panes={[
          {
            title: (
              <FormattedMessage
                id="apps.smart.containers.adsSettings.edit.facebook.ads"
                defaultMessage="Facebook Ads"
              />
            ),
            integration: "facebook",
            key: "facebook-ads",
            content: <SettingsFacebookAdvanced />,
            icon: <ProviderFieldIcon field={FACEBOOK_PAGES} />,
          },
          {
            title: (
              <FormattedMessage
                id="apps.smart.containers.advSettings.edit.agency"
                defaultMessage="Agency"
              />
            ),
            key: "agency",
            content: <SettingsAgency />,
          },
        ]}
      />
    );
}

