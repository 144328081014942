import { createSelector, createSlice, current } from "@reduxjs/toolkit";
import { callAPIFunction, callAPIProps } from "services/hooks/useAPI";

type WebsitePricesState = {
    templates: TemplateProps[],
    prices: any,
};

export type TemplateProps = {
    id: number,
    created_at: string,
    updated_at: string,
    description: string,
    name: string,
    page_name: string,
    prices: [],
    columns: PricingColumnTemplate[],
}

export type PricingColumnTemplate = {
    name: string,
    id: string | number,
    active?: boolean,
}

export type PricingColumn = PricingColumnTemplate & {
    value?: string | null,
}

export type PricingTemplate = {
    name: string,
    desciption: string,
    columns: PricingColumnTemplate[],
    services: Service[]
}

export type Service = {
    title: string,
    name?: string,
    order: number,
    serviceId: number,
    description: string,
    columns: PricingColumn[],
}

const initialState: WebsitePricesState = {
    templates: [],
    prices: {},
}

const parseColumnsInService = (service: any) => {

    if (service.columns === "") return {};
    if (!service || !service.columns) return;
    try {
        return JSON.parse(service.columns);
    } catch (e) {
        console.error("Error parsing columns in service", e)
    }
    return null
}

const websitePricesSlice = createSlice({
    name: "websitePrices",
    initialState,
    reducers: {
        getTemplates: (state, action) => {
            state.templates = action.payload.data;
        },
        getPriceLists: (state, action) => {
            const category = action.payload.data.page_name

            if (!action?.payload?.data?.data || !Array.isArray(action.payload.data.data)) return;
            const parsed: any[] = [];
            action.payload.data.data.forEach((item: any) => {

                const { data, template } = item;
                const { services, description, ...rest } = data;

                const newServices: any[] = [];

                const sortedTemplateColumns = rest.columns.map((rc: any) => template.columns.find((tc: any) => tc.id === rc))
                template.columns = sortedTemplateColumns;

                if (services && services.length > 0) {
                    services.forEach((service: any) => {

                        const parsedColumns = parseColumnsInService(service);

                        if (!parsedColumns) return {
                            ...service,
                            columns: []
                        }

                        const mappedColumns = template.columns.map((templatedColumn: any, index: number) => {

                            const price = parsedColumns[templatedColumn.id];

                            if (price) templatedColumn.active = true;

                            return {
                                ...templatedColumn,
                                active: templatedColumn.active,
                                value: price || null
                            }

                        })


                        const newService = {
                            ...service,
                            columns: mappedColumns
                        }
                        newServices.push(newService)
                    })
                }

                newServices.sort((a: any, b: any) => a.order - b.order)


                parsed.push({
                    ...rest,
                    services: newServices,
                    template,
                    category
                })

            })
            state.prices[category] = parsed;

        },
        updateService: (state, action) => {
            const attributes = action.payload.data.data.attributes
            const newService = {
                ...attributes,
                serviceId: attributes.id,
                order: 0
            }
            for (const key in state.prices) {
                if (state.prices.hasOwnProperty(key) && Array.isArray(state.prices[key])) {
                    const categoryArray = state.prices[key];
                    for (let i = 0; i < categoryArray.length; i++) {
                        if (categoryArray[i].id === newService.price_list_id) {
                            newService.columns = categoryArray[i].template.columns
                            categoryArray[i].services.forEach((service: Service) => {
                                service.order = service.order + 1
                            });
                            categoryArray[i].services.unshift(newService)
                        } else if (categoryArray[i].id === newService.id) {
                            categoryArray[i] = { ...categoryArray[i], ...newService }
                        }
                    }
                }
            }
        },
        updateRowTitle: (state, action) => {
            const attributes = action.payload.data.data.attributes
            const newService = {
                ...attributes,
                serviceId: attributes.id,
                order: 0
            }
            for (const key in state.prices) {
                if (state.prices.hasOwnProperty(key) && Array.isArray(state.prices[key])) {
                    const categoryArray = state.prices[key];
                    for (let i = 0; i < categoryArray.length; i++) {
                        if (categoryArray[i].id === newService.price_list_id) {
                            categoryArray[i].services.forEach((service: Service) => {
                                if (service.serviceId === newService.id) {
                                    service.title = newService.title
                                }
                            });
                        }
                    }
                }
            }
        },
        deleteRow: (state, action) => {
            for (const key in state.prices) {
                if (state.prices.hasOwnProperty(key) && Array.isArray(state.prices[key])) {
                    const categoryArray = state.prices[key];
                    for (let i = 0; i < categoryArray.length; i++) {
                        if (categoryArray[i].id === action.payload.additional.id) {
                            categoryArray[i] = {
                                ...categoryArray[i],
                                services: categoryArray[i].services.filter((service: Service) => service.serviceId !== action.payload.additional.serviceId),
                            };
                        }
                    }
                }
            }
        },
        updateSection: (state, action) => {
            const newSection = action.payload.data.data.attributes
            for (const key in state.prices) {
                if (state.prices.hasOwnProperty(key) && Array.isArray(state.prices[key])) {
                    const categoryArray = state.prices[key];
                    for (let i = 0; i < categoryArray.length; i++) {
                        if (categoryArray[i].id === newSection.id) {
                            categoryArray[i].services.forEach((service: Service) => service.columns.sort((a, b) => newSection.columns.indexOf(a.id) - newSection.columns.indexOf(b.id)))
                            categoryArray[i] = { ...categoryArray[i], ...newSection };
                        }
                    }
                }
            }
        },
        deleteSection: (state, action) => {
            for (const key in state.prices) {
                if (state.prices.hasOwnProperty(key) && Array.isArray(state.prices[key])) {
                    state.prices[key] = state.prices[key].filter((service: any) => service.id !== action.payload.additional.id);
                }
            }
        },
        updatePrice: (state, action) => {
            const { category, serviceId, listId, newValues } = action.payload.additional

            const cur = current(state);

            state.prices[category] = cur.prices[category].map((list: any) => {

                if (list.id !== listId) return list;

                const newServices = list.services.map((service: any) => {

                    if (service.serviceId === serviceId) {
                        const updatedColumns = service.columns.map((col: PricingColumn) => {
                            const newValue = newValues[col.id.toString()];
                            if (newValue !== undefined) {
                                return { ...col, value: newValue };
                            }
                            return col;
                        });

                        service = { ...service, columns: updatedColumns };
                        return {
                            ...service,
                        }
                    } else {
                        return service
                    }
                })

                return {
                    ...list,
                    services: newServices
                }

            })

        }
    }
})

export const GetWebsitePriceTemplates: callAPIProps = {
    url: ({ getApiUrl }) => getApiUrl(`projects/price-lists/templates`),
    method: "GET",
    successDispatch: websitePricesSlice.actions.getTemplates
}

export const GetWebsitePriceList: callAPIFunction = (template: string) => ({
    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/price-lists/${template}`),
    successDispatch: websitePricesSlice.actions.getPriceLists,
    customNormalizer: (data: any) => data
})

export const NewPriceTemplate: callAPIFunction = (template: string) => ({
    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/price-lists/${template}`, 2),
    method: "POST",
    successDispatch: websitePricesSlice.actions.getPriceLists,
    customNormalizer: (data: any) => data
})

export const NewPriceRow: callAPIFunction = (template: string) => ({
    url: ({ getApiUrl }) => getApiUrl(`projects/price-lists/${template}`, 2),
    method: "POST",
    successDispatch: websitePricesSlice.actions.updateService,
    customNormalizer: (data: any) => data
})

export const UpdatePriceColumn: callAPIFunction = (template: string) => ({
    url: ({ getApiUrl }) => getApiUrl(`projects/price-lists/${template}`, 2),
    method: "PATCH",
    successDispatch: websitePricesSlice.actions.updateService,
    customNormalizer: (data: any) => data
})

export const NewPriceList: callAPIFunction = (template: string) => ({
    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/price-lists/${template}`, 2),
    method: "POST",
    successDispatch: websitePricesSlice.actions.getPriceLists,
    customNormalizer: (data: any) => data
})

export const UpdateRowTitle: callAPIFunction = (list: number, service: number) => ({
    url: ({ getApiUrl }) => getApiUrl(`projects/price-lists/${list}/${service}`, 2),
    method: "PATCH",
    successDispatch: websitePricesSlice.actions.updateRowTitle,
    customNormalizer: (data: any) => data
})

export const DeleteRow: callAPIFunction = (id: number, serviceId: number) => ({
    url: ({ getApiUrl }) => getApiUrl(`projects/price-lists/${id}/${serviceId}`, 2),
    method: "DELETE",
    successDispatch: websitePricesSlice.actions.deleteRow,
    customNormalizer: (data: any) => data,
    passToDispatcher: {
        id,
        serviceId
    }
})

export const DeleteSection: callAPIFunction = (id: number) => ({
    url: ({ getApiUrl }) => getApiUrl(`projects/price-lists/${id}`, 2),
    method: "DELETE",
    successDispatch: websitePricesSlice.actions.deleteSection,
    customNormalizer: (data: any) => data,
    passToDispatcher: {
        id
    }
})

export const UpdateSection: callAPIFunction = (list: number) => ({
    url: ({ getApiUrl }) => getApiUrl(`projects/price-lists/${list}`, 2),
    method: "PATCH",
    successDispatch: websitePricesSlice.actions.updateSection,
    customNormalizer: (data: any) => data
})

export const UpdatePrice: callAPIFunction = (category: string, listId: number, serviceId: number, newValues: any[], serviceTitle: string) => ({
    url: ({ getApiUrl }) => getApiUrl(`projects/price-lists/${listId}/${serviceId}`, 2),
    method: "PATCH",
    successDispatch: websitePricesSlice.actions.updatePrice,
    customNormalizer: (data: any) => data,
    passToDispatcher: {
        listId,
        serviceId,
        category,
        newValues,
        serviceTitle
    },
    body: {
        columns: JSON.stringify(newValues),
        title: serviceTitle
    }
})

export const ReorderService: callAPIFunction = (listId: number, serviceId: number, newOrder: number) => ({
    url: ({ getApiUrl }) => getApiUrl(`projects/price-lists/${listId}/${serviceId}`, 2),
    method: "PATCH",
    body: {
        order: newOrder
    }
})

export const ProjectWebsitePricesSelector = createSelector([
    state => state.siteGenerator.prices
], (state: any) => state
)

export const WebsitePricingTemplatesSelector = createSelector([
    ProjectWebsitePricesSelector
], (state: any) => state && state.templates
)

export const WebsitePricingTemplatesForCategorySelector = createSelector([
    WebsitePricingTemplatesSelector,
    (state: any, category: string) => category
],
    (state: any, category: string) => state && state.filter((template: any) => template.page_name === category)
)

export const ProjectWebsitePriceListSelector = createSelector([
    ProjectWebsitePricesSelector,
    (state: any, category: string) => category
],
    (state: any, category: string) => state && state.prices && state.prices[category]
)


export default websitePricesSlice;