import FormattedMessage from "components/common/FormattedMessage";
import { ReactNode, createContext, useContext, useMemo, useState } from "react";
import { StandardDrawer, useStandardDrawer } from "services/hooks/useDrawer";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { Subscription } from "state/subscriptions/subscriptionTypes";
import { subscriptionSelector } from "state/subscriptions/subscriptionsSelectors";
import SubscriptionCancellationWizard from "./cancellation/SubscriptionCancellationWizard";
import { SubscriptionDebtPayment } from "./SubscriptionDebtPayment";

type SubscriptionContextProps = {
    subscriptionId: string;
    children?: ReactNode;
}

type SubscriptionDetailsPanel = "cancel" | "pay" | "";

type SubscriptionContext = {
    subscription: Subscription;
    openDetails: (panel?:SubscriptionDetailsPanel) => void;
    closeDetails: () => void;
}

export const SubscriptionContext = createContext<SubscriptionContext>({} as SubscriptionContext);
SubscriptionContext.displayName = "Subscription Context";
export const useSubscriptionContext = () => useContext(SubscriptionContext);

const SubscriptionContextProvider = (props: SubscriptionContextProps) => {

    const subscription = useSelectorWithParams(subscriptionSelector(props.subscriptionId))
    const [drawerRef, open, close] = useStandardDrawer();
    const [subpanelName, setSubpanelName] = useState<SubscriptionDetailsPanel>("");

    const handleOpen = (panel?:SubscriptionDetailsPanel) => {
        console.log("openDetails", panel);
        setSubpanelName(panel || "");
        open();
    }

    const value = {
        subscription,
        openDetails: handleOpen,
        closeDetails: close
    }

    const subpanel = useMemo(() => {

        if (!subpanelName) return null;

        if (subpanelName === "cancel") {
            return <SubscriptionCancellationWizard
                subscriptionId={subscription?.id}
                onClose={close}
            />
        }

        if (subpanelName === "pay") {
            return <SubscriptionDebtPayment />
        }

    }, [subscription, subpanelName])

    return (
        <SubscriptionContext.Provider value={value}>
            {props.children}
            <StandardDrawer
                ref={drawerRef}
                title={<FormattedMessage
                    id="panel.subscriptions.cancellation.modalTitle"
                    defaultMessage="Subscription cancellation"
                />}
                width= "900px"
            >
                {subpanel}
            </StandardDrawer>
        </SubscriptionContext.Provider>
    )
}

export default SubscriptionContextProvider;