import { Alert, Space, Spin } from "antd";
import { HorizontalCenter } from "components/common/Center";
import StandardButton from "components/common/StandardButton";
import { useEffect, useState } from "react";
import useAPI, { APIErrorDisplay, callAPIProps } from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import dayjs from 'dayjs';
import useScope from "services/hooks/useScope";
import { projectsSelector } from "state/projects/projectsSelectors";
import { CreateNewProject } from "state/projects/projectEndpoints";

const validateInvitation = (code:string):callAPIProps => ({
    url: ({getApiUrl}) => getApiUrl(`organization/invitations/${code}`,1),
})

const acceptInvitation = (code:string):callAPIProps => ({
    url: ({getApiUrl}) => getApiUrl(`organization/invitations/${code}/accept`,1),
    method: "POST",
    dispatchName: "INVITATION_ACCEPT",
})

export default function PrepareProject() {

    const projects = useSelectorWithParams(projectsSelector);
    const createProjectCall = useAPI(CreateNewProject);
    const validateInvitationCall = useAPI(validateInvitation(""));
    const acceptInvitationCall = useAPI(validateInvitation(""));
    const scope = useScope();

    const [invitationCode, setInvitationCode] = useState<string | null>(localStorage.getItem("invitation_code"))

    const createNewProject = () => {

        const extraCode = localStorage.getItem("extraCode");

        createProjectCall.call({body: {
            find_and_attach_subscriptions: true,
            ...extraCode ? {extraCode} : {}
        }}).then((res) => {

            console.log("here", res)

            if (res && res.status === 200 && res.data) {
                if (res?.data?.productSubscriptions?.[0]?.id) {
                    scope.openSubscription(res.data.productSubscriptions[0].id)
                } else {
                    scope.openProjectWithProduct(res.data.id, scope.product)
                }
            }
        })
    }

    useEffect(() => {


        const hasCode = invitationCode && invitationCode.length > 0;

        if (!projects || projects.length === 0 || Object.keys(projects).length === 0) {
            if (!hasCode) {
                createNewProject();
            } else {
                validateInvitationCall.call(validateInvitation(invitationCode))
            }
        }

    }, []);

    const handleAcceptInvitation = () => {
        acceptInvitationCall.call(acceptInvitation(invitationCode || "")).then((res: any) => {
            if (res && res.status === 200 && res.data) {
                scope.openProject(String(res.data.id))
                location.reload();
            }

        })
    }

    const handleRejectInvitation = () => {
        createNewProject();
    }

    if (createProjectCall.loading) {
        return (
            <HorizontalCenter><Spin /></HorizontalCenter>
        )
    }

    if (createProjectCall.error) {
        return (
            <APIErrorDisplay error={createProjectCall.error} />
        )
    }

    if (validateInvitationCall.error) {
        return (
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}>
                Invitation code found but could not be verified.
                <APIErrorDisplay error={validateInvitationCall.error} />
                <StandardButton onClick={handleRejectInvitation}>Create new project</StandardButton>
            </div>
        )
    }

    if (validateInvitationCall.data) {


        const {organization} = validateInvitationCall.data;
        const validTime = dayjs(validateInvitationCall.data.valid_time);
        const isValid = validTime.isAfter(dayjs());

        return (
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}>
                You have been invited to join:

                <h3 style={{
                    padding:"20px"
                }}>{organization.name}</h3>
                <div>Valid until: {validTime.format("DD MMMM YYYY")}</div>

                {!isValid && 
                    <Alert
                        type="error"
                        message="This invitation is no longer valid"
                    />
                }           

                <Space wrap style={{
                    padding: "20px"
                }}>
                    <StandardButton onClick={handleRejectInvitation}>Reject invitation</StandardButton>
                    <StandardButton type="primary" onClick={handleAcceptInvitation} disabled={!isValid}>Accept invitation</StandardButton>
                </Space>
            </div>
        )

    }

    return (
        <HorizontalCenter><Spin /></HorizontalCenter>
    )


}