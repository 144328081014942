import dayjs, { Dayjs } from "dayjs";
import { CalendarMode } from "./Calendar";

export const getCalendarRange = (date: Dayjs, mode: CalendarMode):[Dayjs, Dayjs] => {

    console.log("getCalendarRange", mode, date.format("DD/MM/YYYY"));

    switch (mode) {
        case "day":
            return [date.clone(), date.clone()];
        case "week":
            return [date.clone().startOf('week'), date.clone().endOf('week')];
    
        case "month":
        default:
            const s = date.clone().startOf('month').startOf('week')
            return [s, s.clone().add(6, 'weeks').subtract(1, 'day')];
    }

}

export const isWithinRange = (date: Dayjs, range: [Dayjs, Dayjs]) => {
    return date.isAfter(range[0]) && date.isBefore(range[1]);
}

export const calendarRangeToDays = (start: Dayjs, end: Dayjs) => {
    const days = [];
    for (let i = 0; i <= end.diff(start, 'days'); i++) {
        days.push(start.clone().add(i, 'days'));
    }
    return days;
}

export const moveEntryWithinDay = (entries: Map<string, any>, day: Dayjs, entry: string, newIndex: number) => {

}

export const moveEntryBetweenDays = (oldDay: Dayjs, newDay: Dayjs, entry: string) => {

}