import { Checkbox } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import { usePurchaseSubscription } from "./subscriptionPurchase/PurchaseSubscription";

function PurchaseConsent() {

    const {consents, setConsents} = usePurchaseSubscription();

    return (
        <div style={{
            width: "100%",
            paddingTop: "5px"
          }}>
            <Checkbox 
              value={consents.checkOne}
              className="checkbox-with-long-text"
              onClick={() => setConsents(c => ({...c, checkOne: !c.checkOne}))}
            >
              <FormattedMessage
                    id="panel.components.containers.creditCard.immidiateContractExecutionWarning"
                    defaultMessage="I declare that I agree to the immediate execution of the Agreement for the Provision of services and acknowledge that I will lose the right to withdraw from the Agreement within 14 days from the moment of full execution of the Agreement for the Provision of services."
                  />
                </Checkbox>
                <Checkbox
                  value={consents.checkTwo}
                  className="checkbox-with-long-text"
                  onClick={() => setConsents(c => ({...c, checkTwo: !c.checkTwo}))}
                >
              <FormattedMessage
                    id="panel.components.containers.creditCard.termOfServiceAgreementAcceptanceConfirmation"
                    defaultMessage="I declare that I have familiarized myself with the contents of the {tosLink} and accept its terms. I declare that I am authorized to enter into the Agreement for the Provision of services on behalf of and for the benefit of the Customer under the terms specified in the Order and Regulations, and by clicking the 'Buy Subscription' button, I agree to the immediate execution of the Agreement for the Provision of services."
                    values={{
                      tosLink: (
                      <a 
                      href={import.meta.env.VITE_INSTANCE_TERMS_URL}
                      target="_blank"
                      >
                        <FormattedMessage
                          id="panel.components.containers.creditCard.termOfServiceAgreementAcceptanceConfirmation.termsOfService"
                          defaultMessage="Terms of Service"
                        />
                      </a>
                      )
                    }}
                  />
                </Checkbox>
          </div>
    );
}

export default PurchaseConsent;