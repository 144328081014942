import ReactMarkdown from 'react-markdown';
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown';

export type MarkdownProps = {
    text: string,
    components?: Partial<ReactMarkdownOptions["components"]>
}

const Markdown = (props: MarkdownProps) => {

    return (
        <ReactMarkdown
            components={props.components}
        >
            {props.text}
        </ReactMarkdown>
    )

}

export default Markdown;