import { Alert, Button, Col, Collapse, Grid } from "antd";
import { useEffect, useMemo } from "react";
import { AdDetailsColumns, PlatformDetailsColumns } from "tools/infinityList/infinityTemplates";
import TemplateRow from "tools/infinityList/TemplateRow";
import { AdErrorDisplay, AdReviewDisplay, ProviderPostErrorDisplay } from "./displays/ErrorDisplays";
import { usePost } from "./postContext";

export type PostPlatformDetailsProps = {
    provider: string;
    details?: any;
}

export default function PostPlatformDetails(props: PostPlatformDetailsProps) {

    const {post, config} = usePost();

    const platformColumns = useMemo(() => config?.columns?.platform || null, [config])
    const adsColumns = useMemo(() => config?.columns?.ads || null, [config])

    const ads = useMemo(() => {
        if (!adsColumns) return null;
        const a = post?.getAdsForProvider(props.provider);
        if (!a || !a.length) return "There are not ads on this platform";
        return a.map((ad: any) => 
        <div key={ad.id}>
            <TemplateRow key={ad.id} size="medium" item={ad} columns={adsColumns} query={{ provider: props.provider}} details={{...props.details, post}}/>
            <AdReviewDisplay provider={props.provider} ad={ad} />
            <AdErrorDisplay ad={ad} />
        </div>
        )
    },[post, config, adsColumns])


    return (
        <Collapse
            className="post-platform-details"
            defaultActiveKey={props.provider}
        >
            <Collapse.Panel 
            header={(
                <div style={{width: "100%"}}>
                <TemplateRow size = "medium" item={post} columns={platformColumns} query={{ platform: props.provider, provider: props.provider }} />
                <ProviderPostErrorDisplay provider={props.provider} /> 
                </div>
            )} 
            key={props.provider}
            >
                <div style={{paddingLeft: "5px", paddingRight: "5px"}}>
                 {ads}
                 </div>
            </Collapse.Panel>
        </Collapse>
    )
}

