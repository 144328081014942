import { RSAA } from "redux-api-middleware";
import normalizer from "services/store/middlewares/api/json-api-normalizer/normalizer";

function normalizeJsonApiResponse() {
  return (next) => (action) => {
    if (action && action[RSAA] && action[RSAA].normalizeJsonApiResponse) {
      if (action[RSAA].types[1].payload) {
        const payloadFunction = action[RSAA].types[1].payload;
        action[RSAA].types[1].payload = (action, state, res) =>
          payloadFunction(action, state, res).then((response) =>
            normalizer(response)
          );
      } else {
        const payloadFn = (action, state, res) =>
          res.json().then((response) => normalizer(response));
        const type = action[RSAA].types[1];

        action[RSAA].types[1] =
          typeof type === "string"
            ? {
                type,
                payload: payloadFn,
              }
            : {
                ...type,
                payload: payloadFn,
              };
      }

      delete action[RSAA].normalizeJsonApiResponse;
    }

    next(action);
  };
}

export default normalizeJsonApiResponse;
