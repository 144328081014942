import FormattedMessage from "components/common/FormattedMessage";
import { useEffect, useMemo } from "react";
import useAPI from "services/hooks/useAPI";
import { SelectOrCreateProject } from "../../components/subscriptions/buy-product/SelectOrCreateProject";
import { Spin, Tooltip } from "antd";
import ProductLogo from "providers/routing/layouts/components/product-logo/product-logo";
import { COMPANY_BUY_PRODUCT, getUrl } from "modules/panel/config/routes";
import { useHistory } from "react-router-dom";
import { getProductLogo } from "providers/routing/layouts/components/product-logo/smallProductLogo";

const ListItem = ({ product }: { product: string }) => {

  const history = useHistory();

  const handleSelect = (projectId: string) => {
    //scope.openProjectWithProduct(projectId, product);
    history.push(
      getUrl(COMPANY_BUY_PRODUCT, {
        params: { product, projectId },
      })
    )
  }

  const logo = getProductLogo(product)
  //if (!logo) return null;

  return (
    <SelectOrCreateProject product={product} onSelect={handleSelect}>
      <Tooltip
        key={product}
        placement="left"
        title={
          <FormattedMessage
            id="panel.containers.companySettings.buyProduct"
            defaultMessage="Click to buy {product} product"
            values={{ product }}
          />
        }
      >
        <span>
          <ProductLogo name={product} />
        </span>
      </Tooltip>
    </SelectOrCreateProject>
  );
};

function ListOfProducts() {

  const productsCall = useAPI({
    url: ({ getApiUrl, projectId }) => getApiUrl(`info/products`),
  }, true)

  const mappedProducts = useMemo(() => {

    if (!productsCall?.data) {
      return [];
    }

    return productsCall.data.map((product: any) => {
      if (!product) return null;
      if (product.is_hidden === true) return null;
      return <ListItem key={product.id} product={product.name} />;
    });


  }, [productsCall.data])

  if (productsCall.loading) return productsCall.StatusDisplay;

  return (
    <div style={{
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
      gap: "10px",
    }}>
      {mappedProducts}
    </div>
  );
}

export default ListOfProducts;