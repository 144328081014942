type Attributes = {
  [name: string]: any;
};

type Link =
  | string
  | {
      href: string;
      meta?: {
        [name: string]: any;
      };
    };

type SelfLink = {
  self: Link;
};

type RelatedLink = {
  related: Link;
};

type Relationship = {
  links?: SelfLink | RelatedLink;
  data: null | ResourceIdentifier | ResourceIdentifier[];
};

type ResourceIdentifier = {
  id: number;
  type: string;
};

type ResourceObject = {
  attributes?: Attributes;
  relationships?: {
    [name: string]: Relationship;
  };
  links?: SelfLink;
} & ResourceIdentifier;

type Included = ResourceObject[];

export type DataResponse = {
  data: null | ResourceObject | ResourceObject[];
  included?: Included;
};

export type NormalizedResource<Attrs = any, Relations = any> = {
  id: number;
} & Attrs &
  Relations;

export type NormalizedData<Attrs = any, Relations = any> =
  | NormalizedResource<Attrs, Relations>
  | NormalizedResource<Attrs, Relations>[];

export default function normalizeData(response: any) {
  if (!response || !response.data || response.data === null) return null;

  const included = response.included || [];

  if (Array.isArray(response.data)) {
    return response.data.map((data: any) => {
      return parseJsonApiSimpleResourceData(data, included, false, {});
    });
  } else {
    return parseJsonApiSimpleResourceData(response.data, included, false, {});
  }
}

function parseJsonApiSimpleResourceData(
  data: any,
  included: any,
  useCache: boolean,
  options: any
) {
  if (!included.cached) {
    included.cached = {};
  }

  if (!(data.type in included.cached)) {
    included.cached[data.type] = {};
  }

  if (useCache && data.id in included.cached[data.type]) {
    return included.cached[data.type][data.id];
  }

  const attributes = data.attributes;

  

  const resource = attributes || {};
  resource.id = data.id;

  if (data.type === "post" || data.type === "post_facebook" || data.type === "post_google") {
    resource.post_type = data.type;
  }

  included.cached[data.type][data.id] = resource;

  if (data.relationships) {

    for (const relationName of Object.keys(data.relationships)) {
      const relationRef = data.relationships[relationName];

      if (relationRef && Array.isArray(relationRef)) {
        const items: any = [];

        relationRef.forEach((relationData: any, index: any) => {
          const item = findJsonApiIncluded(
            included,
            relationData.type,
            relationData.id,
            options
          );
          items.push(item);
        });

        resource[relationName] = items;
      } else if (Array.isArray(relationRef.data)) {
        const items: any = [];

        relationRef.data.forEach((relationData: any, index: any) => {
          const item = findJsonApiIncluded(
            included,
            relationData.type,
            relationData.id,
            options
          );
          items.push(item);
        });

        resource[relationName] = items;
      } else if (relationRef && relationRef.data) {
        resource[relationName] = findJsonApiIncluded(
          included,
          relationRef.data.type,
          relationRef.data.id,
          options
        );
      } else {
        resource[relationName] = null;
      }
    }
  }

  return resource;
}

function findJsonApiIncluded(included: any, type: any, id: any, options: any) {
  let found = null;

  included.forEach((item: any, index: any) => {
    if (item.type === type && item.id === id) {
      found = parseJsonApiSimpleResourceData(item, included, true, options);
    }
  });

  if (!found) {
    found = { id };
  }

  return found;
}

export function asResourceArray<Attrs, Relations>(
  normalizedData: undefined | NormalizedData<Attrs, Relations>
): NormalizedResource<Attrs, Relations>[] {
  if (Array.isArray(normalizedData)) return normalizedData;
  if (!normalizedData) return [];
  return [normalizedData];
}

export function mapArrayToObjectByProperty(arr:any[], prop?:string) {
  const mapped:any = {};
  arr.forEach(e => mapped[e[prop || "id"]] = e);
  return mapped;
}