import FormattedMessage from "components/common/FormattedMessage";
import { usePost } from "../postContext";
import StandardButton from "components/common/StandardButton";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { useMemo } from "react";
import { currentProjectIntegration } from "state/projects/projectSelectors";

function PostPlatformLinks(props: {hasAd: boolean, details: any}) {

    const { post, markChangesMade } = usePost();
    const adAccount = useSelectorWithParams(currentProjectIntegration("service_facebook_ads_account"));

    const facebookLinks = useMemo(() => {
        if (!post.getProviderPost("facebook")) return null;
        return (
            <>
            <div style={{display: "grid", placeItems: "center"}}>
            <FormattedMessage
                id="panel.containers.posts.openOnFacebook"
                defaultMessage="Open on Facebook"
            />:
            </div>
            <div>
                <StandardButton 
                type = "primary" 
                size = "small"
                href={`https://www.facebook.com/${post.getProviderPost("facebook")?.external_id}`}
                target="_blank">
                    Post
                </StandardButton>
                
                {props.hasAd && 
                <StandardButton 
                type = "primary" 
                size = "small"
                href={`https://business.facebook.com/adsmanager/manage/adsets/edit?act=${adAccount?.account_id}&selected_adset_ids=${props?.details?.ad?.adset?.id}`}
                target="_blank"
                disabled={!props?.details?.ad?.adset?.id}
                >
                    Adset
                </StandardButton>}
                
                {props.hasAd && 
                <StandardButton 
                type = "primary" 
                size = "small"
                href={`https://business.facebook.com/adsmanager/manage/ads/edit?act=${adAccount?.account_id}&selected_adset_ids=${props?.details?.ad?.adset?.id}&selected_ad_ids=${props?.details?.ad?.external_ad_id}`}
                target="_blank"
                disabled={!props?.details?.ad?.adset?.id || !props?.details?.ad?.external_ad_id}
                >
                    AD
                </StandardButton>}
            </div>
            </>
        )

    }, [post, props.details]);

    const instagramLink = useMemo(() => {
        if (!post.getProviderPost("instagram")) return null;
        return (
            <>
            <div style={{display: "grid", placeItems: "center"}}>
            <FormattedMessage
                id="panel.containers.posts.openOnInstagram"
                defaultMessage="Open on Instagram"
            />:
            </div>
            <div>
                <StandardButton 
                type = "primary" 
                size = "small"
                href={post.getProviderPost("instagram")?.permalink}
                target="_blank">
                    Post
                </StandardButton>
            </div>
            </>
        )

    }, [post, props.details]);
    
    return (
        <div style={{display: "flex", justifyContent: "space-between"}}>
            {facebookLinks}
            {instagramLink}
        </div>
    );
}

export default PostPlatformLinks;