import { Col, Input, Row } from "antd";
import useAPI from "services/hooks/useAPI"
import RelaxSingleField from "tools/relaxForm/RelaxField";
import { ToolIntegrations as toolIntegrationsConfig } from "modules/panel/config/ToolIntegrationsConfig";
import DashboardBox from "_components/dashboard/box";
import FormattedMessage from "components/common/FormattedMessage";
import { GetIntegrations, ProjectToolIntegrationSelector, UpdateIntegration } from "state/project/toolIntegrationsSlice";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";

type ToolIntegrationProps = {
    integration: string;
    key?: string;
}

const ToolIntegration = (props: ToolIntegrationProps) => {

    const config = toolIntegrationsConfig.get(props.integration);
    const integration = useSelectorWithParams([ProjectToolIntegrationSelector, props.integration]);

    if (!config) return null;

    return (
        <DashboardBox style={{ minHeight: "250px" }}>
            <h2>{config.displayName}</h2>
            {config.description}
            <div style={{ marginTop: "20px" }}></div>
            <RelaxSingleField
                name={["access_token"]}
                callAPI={UpdateIntegration(props.integration)}
                label={
                    <FormattedMessage
                        id="config.toolIntegrations.accessToken"
                        defaultMessage="Access token"
                    />
                }
            >
                <Input placeholder = {integration?.status === "active" ? "***************" : ""}/>
            </RelaxSingleField>
        </DashboardBox>
    )
}

export default function ToolIntegrations() {

    const { call: getIntegrations, loading, error, StatusDisplay } = useAPI(GetIntegrations, true);

    if (loading || error) return StatusDisplay;

    return (
        <Row gutter={30}>
            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                <ToolIntegration integration="fresh-mail" />
            </Col>
            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                <ToolIntegration integration="sms-api" />
            </Col>
            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                <ToolIntegration integration="get-response" />
            </Col>
            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                <ToolIntegration integration="mailer-lite" />
            </Col>
        </Row>
    )
}