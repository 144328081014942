import { createSlice, createSelector } from '@reduxjs/toolkit';
import { callAPIFunction, callAPIProps } from 'services/hooks/useAPI';
import _ from 'lodash';
import { GenerateFakePost, GeneratePostsFromDateSeed } from '../fakePostGenerator';
import { timeout } from 'services/helpers/fake-asset-generators';
import Post from 'models/post';
import { currentProjectIdSelector } from 'services/store/scopeSlice';
import dayjs from 'dayjs';
import { userSettingsInterfacePostListSelector } from 'state/user/userSettingsSlice';


export const NormalizePost = (post: any) => {

    return {
        ...post.attributes,
        local_id: CalculateLocalId({ ...post.attributes }),
        type: post.type || "unknown",
    }
}

const LocalFacebookID = (post: any) => post?.providers?.facebook?.external_id && `facebook-${post?.providers?.facebook?.external_id}`;
const LocalGoogleID = (post: any) => post?.providers?.google?.external_id && `google-${post?.providers?.google?.external_id}`;
const LocalInstagramID = (post: any) => post?.providers?.instagram?.external_id && `instagram-${post?.providers?.instagram?.external_id}`;

export const CalculateLocalId = (post: any): string => {
    if (post.id) return `local-${post.id}`;
    if (LocalFacebookID(post)) return LocalFacebookID(post);
    if (LocalGoogleID(post)) return LocalGoogleID(post);
    if (LocalInstagramID(post)) return LocalInstagramID(post);
    return `time-${new Date().getTime()}`;
}

export type PostsState = {
    list: any,
    lastQuery: any,
}

const initialState: PostsState = {
    list: {},
    lastQuery: null,
}

const addOrUpdatePost = (state: any, post: any) => {
    if (!post) return null;
    const localId = CalculateLocalId(post);

    if (!post.id) {
        //console.log("Adding post without ID", localId)
        state.list[localId] = { ...post, localId: localId };
        return;
    }

    if (post.id) {
        //console.log("Post has ID", post.id, post)
        //Update existing post with the same ID
        const existingPost: any = Object.values(state.list).find((p: any) => p.id === post.id);
        if (existingPost && existingPost.local_id) {
            //console.log("updating by id", post)
            state.list[existingPost.local_id] = post;
            return;
        }
        //Check if post with the same provider ID exists
        if (post.providers?.facebook?.external_id) {
            const postByFacebook: any = Object.values(state.list).find((p: any) => p.providers?.facebook?.external_id && p.providers?.facebook?.external_id === post.providers?.facebook?.external_id);
            if (postByFacebook) {
                //console.log("updating by facebook")
                state.list[postByFacebook.local_id] = post;
                return;
            }
        }
        if (post.providers?.google?.external_id) {
            const postByGoogle: any = Object.values(state.list).find((p: any) => p.providers?.google?.external_id && p.providers?.google?.external_id === post.providers?.google?.external_id);
            if (postByGoogle) {
                //console.log("updating by google")
                state.list[postByGoogle.local_id] = post;
                return;
            }
        }

        if (post.providers?.instagram?.external_id) {
            const postByInstagram: any = Object.values(state.list).find((p: any) => p.providers?.instagram?.external_id && p.providers?.instagram?.external_id === post.providers?.instagram?.external_id);
            if (postByInstagram) {
                //console.log("updating by instagram")
                state.list[postByInstagram.local_id] = post;
                return;
            }
        }

        //console.log("No matching post found")
        state.list[localId] = post;
        return;
    }

}

export const postsSlice = createSlice({
    name: "posts",
    initialState,
    reducers: {
        get: (state, action) => {
            // console.log("GOT POSTS: ", action)
            action.payload.data.forEach((post: any) => {
                addOrUpdatePost(state, post);
            })
            if (action.payload.additional) {
                state.lastQuery = action.payload.additional;
            }
            //return action?.payload?.data || [];
        },
        update: (state, action) => {
            //console.log("UPDATE POST", action)
            const post = action?.payload?.data;
            //console.log("POST", post)
            addOrUpdatePost(state, post);
        },
        clearPosts: (state, action) => {
            return {
                list: {},
                lastQuery: null,
            }
        },
        delete: (state, action) => {
            const postId = action.payload.additional.postId;
            const post = postId ? Object.values(state.list).find((post: any) => post.local_id === postId) : action.payload.additional.post;
            if (post) {
                delete state.list[post.local_id];
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase("bots/add", (state, action) => {
                const newBot = action.payload.data;
                const post = Object.values(state.list).find((post: any) => post.providers?.facebook?.external_id === newBot.page_post_id);
                if (post) {
                    const botToAdd = {
                        ...newBot,
                        botId: newBot.id,
                    };
                    post.bots.push(botToAdd);
                }
            })
            .addCase("bots/delete", (state, action) => {
                const botToDelete = action.payload.additional
                for (const postKey in state.list) {
                    const post = state.list[postKey];
                    if (post.bots) {
                        post.bots = post.bots.filter((bot: any) => bot.id !== botToDelete);
                    }
                }

            })
    }
})


const getFakePosts = (request: any) => {

    const searchParams = new URLSearchParams(request?.url?.split("?")?.[1] || "");
    const from = searchParams.get("date[from]") && new Date(searchParams.get("date[from]")) || new Date();
    const to = searchParams.get("date[to]") && new Date(searchParams.get("date[to]")) || new Date();
    const params = Array.from(searchParams.values())
    const hasFacebook = params.includes("fbad") || params.includes("fbpost");
    const hasInstagram = params.includes("igad") || params.includes("igpost");
    const provider = hasFacebook ? "facebook" : hasInstagram ? "instagram" : "google";

    const allPostsInRange = GeneratePostsFromDateSeed(from, to, provider);

    return {
        status: 200,
        json: () => ({
            data: allPostsInRange
        })
    }
}

const editFakePost = (newPost: any) => {
    return {
        status: 200,
        json: () => (newPost)
    }
}

export const SortPostsByDate = (posts: any[], newestFirst: boolean) => posts && posts.sort((a: any, b: any) => {
    const aDate = new Date(a.created_at);
    const bDate = new Date(b.created_at);
    if (newestFirst) return bDate.getTime() - aDate.getTime();
    return aDate.getTime() - bDate.getTime();
})

export const FilterPost = (post: Post, filter: any) => {
    if (!filter) return post;

    if(post.getPostStatus){
        const status = post.getPostStatus();

        if (status === "deleted") {
            if (filter.showDeleted !== true) return false;
        }
    
        if (status === "hidden") {
            if (filter.showHidden !== true) return false;
        }
    }

    if (filter.showPosts === false && post.type === "post") return false;
    if (filter.showAOA === false && post.type === "aoa") return false;

    if (filter.from) {
        if (dayjs(post.created_at).isBefore(dayjs(filter.from))) return false;
    }

    if (filter.to) {
        if (dayjs(post.created_at).isAfter(dayjs(filter.to))) return false;
    }

    return true;
}

export const GetPosts: callAPIFunction = (query: any): callAPIProps => {

    const call = {
        url: ({ getApiUrl, language, projectId, serializeQuery }: any) => getApiUrl(`projects/${projectId}/posts${query ? "?" + serializeQuery(query) : ""}`, 4),
        method: "GET",
        successDispatch: postsSlice.actions.get,
        customNormalizer: (data: any) => {
            if (!data.data) return []
            const norm = data.data.map((post: any) => NormalizePost(post));
            return norm;
        },
        passToDispatcher: query,
    }

    if (import.meta.env.VITE_DEBUG_FAKE_POSTS === "true") {
        return {
            ...call,
            fakeResponse: async (request) => await timeout(500).then(() => getFakePosts(request))
        }
    }

    return call;

}

export const EditPost: callAPIFunction = (body: any): callAPIProps => ({
    url: ({ getApiUrl, language, projectId, serializeQuery }: any) => getApiUrl(`projects/${projectId}`, 4),
    method: "PATCH",
    successDispatch: postsSlice.actions.update,
    customNormalizer: (data: any) => {
        return data
    },
    //fakeResponse: async (request) => await timeout(500).then(() => editFakePost(body))
})

export const RepublishAoa: callAPIFunction = (facebookAd: any): callAPIProps => ({
    url: ({ getApiUrl }: any) => getApiUrl(`aoa/${facebookAd}/republish`, 1),
    method: "POST",
    successDispatch: postsSlice.actions.update,
})

export const ProjectPostsSelector = createSelector([
    (state: any) => state?.posts?.list,
    currentProjectIdSelector
],
    (state: any, projectId: any) => state && state[projectId]
)

export const PostsSelector = createSelector(
    (state: any) => state?.posts?.list,
    (state: any) => state && Object.values(state)
)

export const PostSelector = createSelector([
    PostsSelector,
    (state: any, postId: any) => postId
], (posts: any, postId: any) => posts && posts[postId])

export const PostSelectorByLocalId = createSelector([
    PostsSelector,
    (state: any, postId: any) => postId
], (posts: any, postId: any) => {
    return _.find(posts, (post: any) => post.local_id === postId);
}
)

export const PostsLastQuerySelector = createSelector(
    (state: any) => state?.posts?.lastQuery,
    (state: any) => state
)

export const filteredPostsAsArraySelector = (filter:any) => createSelector(
    PostsSelector,
    (state: any) => state && Object.values(state).filter((post: any) => FilterPost(post, filter))
)

export const filteredPostsCountSelector = createSelector(
    [
        PostsSelector,
        userSettingsInterfacePostListSelector,
    ],
    (posts, filters) => {
        const postObjects = posts.map((post:Post) => new Post(post));

        const filteredPosts = postObjects.filter((post:Post) => {
            return FilterPost(post,filters)
        });

        return posts.length - filteredPosts.length;
    }
);


export default postsSlice;