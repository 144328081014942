import { combineReducers, createSelector, createSlice } from "@reduxjs/toolkit";
import { callAPIProps } from "services/hooks/useAPI";

export type ToolIntegrationsSliceState = any;

const initialState: ToolIntegrationsSliceState = {
    "sms-api": null,
    "fresh-mail": null,
    "get-response": null,
    "mailer-lite":null
};

export const toolIntegrationsSlice = createSlice({
    name: "toolIntegrations",
    initialState: initialState,
    reducers: {
        get: (state, action) => {
            if (action?.payload?.data) {
                action.payload.data.forEach((i: any) => {
                    if (i.type) {

                        const { type, id, status, projectId, ...rest } = i;

                        state[i.type] = {
                            type,
                            id,
                            status,
                            ...rest
                        };
                    }
                });
            }
        },
        update: (state, action) => {
            if (action?.payload?.data) {
                const { type, id, status, projectId, ...rest } = action.payload.data;

                state[type] = {
                    type,
                    id,
                    status,
                    ...rest
                };
            }
        }
    },
})

export const GetIntegrations: callAPIProps = {
    url: ({ getApiUrl, projectId }: any) => getApiUrl(`projects/${projectId}/integrations`, 1),
    successDispatch: toolIntegrationsSlice.actions.get,
}

export const UpdateIntegration = (integration:string):callAPIProps => ({
    url: ({ getApiUrl, language, projectId }: any) => getApiUrl(`projects/${projectId}/integrations/${integration}`, 1),
    method: "POST",
    successDispatch: toolIntegrationsSlice.actions.update,
})

export const ProjectToolIntegrationsSelector = createSelector([
    (state: any) => state.project.toolIntegrations
], (toolIntegrations) => toolIntegrations
)

export const ProjectToolIntegrationSelector = createSelector([
    ProjectToolIntegrationsSelector,
    (state: any, integration: string) => integration,
], (toolIntegrations, integration) => toolIntegrations && toolIntegrations[integration]
)