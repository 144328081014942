import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "services/store/redux-store";
import { currentSubscriptionIdSelector } from "services/store/scopeSlice";
import { isSubscriptionValid } from "./subscriptionsHelpers";
import { Map, List, fromJS } from "immutable";
import { Subscription, SubscriptionPermissions } from "./subscriptionTypes";
import { memoize } from "lodash";
import dayjs from "dayjs";

export const subscriptionsSliceSelector = (state: RootState) => state.subscriptionsSlice;

export const subscriptionsSelector = createSelector(
    subscriptionsSliceSelector,
    (subscriptions) => subscriptions.subscriptions
);
export const subscriptionsArraySelector = createSelector([
    subscriptionsSliceSelector
],
    (subscriptions): Subscription[] => subscriptions.subscriptions ? Object.values(subscriptions.subscriptions) : []
);

export const subscriptionsArrayWithFilterSelector = createSelector([
    subscriptionsArraySelector,
    (state, filter) => filter
],
    (subscriptions, filter) => {
        if (!filter) return subscriptions;
        const filtered = subscriptions.filter((s: any) => {
            if (filter.project && filter.project !== "all" && s.projects.indexOf(filter.project) === -1) return false;
            if (filter.product && filter.product !== "all" && s.product !== filter.product) return false;
            return true;
        });
        return filtered;
    }
);

export const projectSubscriptionsSelector = createSelector([
    subscriptionsArraySelector,
    (state, projectId) => projectId
],
    (subscriptions, projectId) => {
        return subscriptions.filter((s: any) => s.projects.indexOf(projectId) !== -1);
    }
);

export const projectValidSubscriptionsSelector = (projectId: string) => createSelector([
    (state) => projectSubscriptionsSelector(state, projectId),
],
    (subscriptions) => subscriptions.filter(isSubscriptionValid)
);

export const subscriptionSelector = (id: string | number) => createSelector(
    subscriptionsSelector,
    (subscriptions) => subscriptions[id]
);


export const subscriptionCancellationPlansSelector = createSelector([
    subscriptionsSliceSelector,
    (state, subscriptionId) => subscriptionId
],
    (subscriptions, subscriptionId) => {

        if (!subscriptionId) return [];
        if (!subscriptions.cancellationPlans) return [];
        if (!subscriptions.cancellationPlans[subscriptionId]) return [];

        return subscriptions.cancellationPlans[subscriptionId];
    }
);

export const subscriptionCancellationPlanSelector = createSelector([
    subscriptionCancellationPlansSelector,
    (state, subscriptionId, plan) => plan
],
    (plans, plan) => {
        if (!plans) return null;
        return plans.find((p: any) => p.plan === plan);
    }
);

export const subscriptionCancellationSurveySelector = (id: string | number) => createSelector([
    subscriptionSelector
],
    (subscription) => subscription?.cancellation_survey || null
);

export const currentSubscriptionSelector = createSelector(
    [
        (state) => state,
        currentSubscriptionIdSelector
    ],
    (state: any, currentSubscriptionId: any) => subscriptionSelector(currentSubscriptionId)(state)
);
  
export const SubscriptionCancellationPlansSelector = createSelector(
    [subscriptionsSliceSelector, (state, subscriptionId) => subscriptionId],
    (subscriptions, subscriptionId) => {
      if (!subscriptionId) return [];
      if (!subscriptions.cancellationPlans) return [];
      if (!subscriptions.cancellationPlans[subscriptionId]) return [];
  
      return subscriptions.cancellationPlans[subscriptionId];
    }
  );
  

export const unattachedSubscriptionsSelector = createSelector(
    [
        subscriptionsArraySelector
    ],
    (subscriptions) => subscriptions.filter( subscription => subscription.projects.length === 0)
);

export const currentSubscriptionStatusSelector = createSelector(
  currentSubscriptionSelector,
  (currentSubscription) =>
    currentSubscription
      ? currentSubscription.status.toLowerCase()
      : false
);

export const currentSubscriptionPaidSelector = createSelector(
  currentSubscriptionStatusSelector,
  (status) =>
    status === "active" || status === "cancelled" || status === "canceled" || status === "unpaid"
);


export const unattachedSubscriptionsGroupedByProductSelector = createSelector(
  unattachedSubscriptionsSelector,
  (unattachedSubscriptions) => {
    const filteredSubscriptions = unattachedSubscriptions.filter((sub) => sub.status==="active");

    return filteredSubscriptions.reduce((acc, subscription) => {
      const product = subscription.product;
      if (!acc[product]) {
        acc[product] = [];
      }
      acc[product].push(subscription);
      return acc;
    }, {} as Record<string, Subscription[]>);
  }
);

  export const currentFlatSubscriptionPermissionsSelector = createSelector(
    currentSubscriptionSelector,
    (currentSubscription) => {
      if (!currentSubscription || !currentSubscription.subscription_permissions) {
        return [];
      }
      const flatSubPerm = currentSubscription.subscription_permissions.map(
        (permission: SubscriptionPermissions) => permission.name
      );
      return flatSubPerm
    }
  );

  export const currentSubscriptionPermissionsSelector = createSelector(
    currentFlatSubscriptionPermissionsSelector,
    (flatPermissions) => {
  
      if (!flatPermissions)
        return {
          integration: [],
          providers: {},
          module: {},
        };
  
      let mappedPermissions = Map({});
  
      flatPermissions.forEach((permission) => {
        const path = permission.split(" ");
  
        path.shift();
        let value = path.pop();
          if (value) {
            mappedPermissions = mappedPermissions.updateIn(path, (list) =>
            List.isList(list) ? list.push(value) : List([value])
          );
        }
  
      });
  
      mappedPermissions = mappedPermissions
        .update("integration", (integration) => (integration ? integration : []))
        .toJS();
  
      mappedPermissions.providers = mappedPermissions.integration;
      mappedPermissions.integration = Object.keys(mappedPermissions.providers);
  
      return mappedPermissions;
    }
  );

  export const subscriptionProviderFieldsSelector = createSelector(
    currentSubscriptionPermissionsSelector,
    (subscription) =>
      memoize((integration) => subscription.providers[integration])
  );