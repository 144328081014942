import { ONBOARDING_AUTHORIZATION_SUCCESS } from "modules/panel/config/routes";
import { getUrl as getPanelUrl } from "modules/panel/config/routes";

export default [
  {
    // no requirement
    key: "authorizationSuccess",
    path: () => getPanelUrl(ONBOARDING_AUTHORIZATION_SUCCESS),
    selector: () => false,
    // <AuthorizationSuccessOnboarding/>
  },
];
