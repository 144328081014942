import { Space } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import StandardButton from "components/common/StandardButton"
import { Text } from "components/common/StandardText";
import { GenerateTextFromMedia } from "modules/panel/state/AISlice";
import { useEffect, useMemo } from "react";
import useAPI from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { useCreationEditor } from "tools/creationEditor/CreationEditor";
import { creationSelector } from "tools/creationEditor/creationsSlice";

type GenerateFromMediaProps = {
    creationId: string,
    onSelect?: (text: string) => void,
}

const GenerateFromMedia = (props: any) => {

    const generateCall = useAPI(GenerateTextFromMedia(props.creationId))
    const creation = useSelectorWithParams([creationSelector, props.creationId])

    const handleGenerate = () => {
        generateCall.call()
    }

    const handleSelect = (text: string) => {
        props.onSelect && props.onSelect(text);
    }

    const mappedOptions = useMemo(() => {

        if (!creation) return null;
        if (!creation.content?.aiSuggestions) return null;

        return creation.content.aiSuggestions.map((suggestion: any) => (
            <div style={{
                padding: "5px",
                position: "relative",
            }}
                className="standard-border"
            >
                {/* <Input.TextArea 
                value={field.content} 
                autoSize={{ minRows: 3, maxRows: 8 }}
                /> */}
                <div>
                    {suggestion.description}
                </div>
                <div style={{
                    position: "absolute",
                    bottom: "-4px",
                    right: "-4px",
                }}>
                    <StandardButton 
                        type={"primary"}
                        size="small"
                        style={{
                            height: "30px",
                            maxHeight: "30px",
                            minHeight: "30px",
                        }}
                        onClick={() => handleSelect(suggestion.description)} 
                        >
                            <FormattedMessage 
                                id={"tools.textEditor.replaceFromLexicon.useButton.label"} 
                                defaultMessage={"Use"}                    
                            />
                    </StandardButton>
                </div>
            </div>
        ))


    },[creation])

    return (
            <Space direction="vertical">
            <Text>
                <FormattedMessage
                    id="tools.textEditor.generateFromMedia.description"
                    defaultMessage="Click generate to get suggestions for this creation from the AI, based on the media used to create it."
                />
            </Text>
            <StandardButton 
                onClick={handleGenerate}
                loading={generateCall.loading}
            >
                <FormattedMessage
                    id="tools.textEditor.generateFromMedia.useButton.label"
                    defaultMessage="Generate from Media"
                />
            </StandardButton>
            {mappedOptions}
            </Space>
    )

}

export default GenerateFromMedia;