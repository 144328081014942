import React, { useEffect, useState, useRef, useContext, useMemo } from "react";
import { Form, Input, Slider, Switch as AntSwitch } from "antd";

export default function  Switch(props: any) {

    return (
        <AntSwitch 
        checked={props.value} 
        onChange={props.onChange} 
        style={{
            width: "20px",
            marginLeft: "7px",
            marginTop: "2px",
            marginBottom: "2px"
        }}/>
    )
}