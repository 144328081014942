import StandardButton from "components/common/StandardButton";
import { useImperativeHandle, useMemo, useRef, useState } from "react";
import useAPI from "services/hooks/useAPI";
import { StandardDrawer, useStandardDrawer } from "services/hooks/useDrawer";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import CreationEditor, { CreationEditorRef } from "./CreationEditor";
import CreationCard from "./CreationCard";
import { creationsAsArraySelector, creationsSelector, getCreations, newCreation } from "./creationsSlice";
import AwesomeIcon from "components/common/AwesomeIcon";
import { faPlusCircle } from "@fortawesome/pro-light-svg-icons";
import { Input } from "antd";
import { StandardModal, useStandardModal } from "services/hooks/useModal";
import { HorizontalCenter } from "components/common/Center";
import React from "react";
import FormattedMessage from "components/common/FormattedMessage";
import { AskToDeleteCreation } from "./CreationActionModals";

const CreationDrawer = React.forwardRef((props: any, ref: any) => {

    const { call, data, loading, error, StatusDisplay } = useAPI(getCreations, true)
    const creationAPI = useAPI(newCreation("post"))
    const [drawerRef, open, close, isOpen] = useStandardDrawer();
    const [creationType, setCreationType] = useState<any>(["post", "image"])
    
    const assetEditorRef = useRef<CreationEditorRef>(null);

    const creations = useSelectorWithParams(creationsAsArraySelector);

    const {askToDeleteCreation, contextHolder} = AskToDeleteCreation();

    const type = creationType?.type?.[0]
    const subType = creationType?.type?.[1]

    const filteredCreations = useMemo(() => {
        if (!creations) return []
        return creations
        .filter((c:any) => c?.status !== "removed" && c?.status !== "published" && c?.type === type && (c?.content?.type === subType || !c?.content?.type))
        .sort((a:any, b:any) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime())
    }, [creations, creationType])

    const mappedCreations = useMemo(() => {
        if (!filteredCreations) return null;
        if (filteredCreations.length === 0) return null;
        return filteredCreations.map((c: any) => {
            return (
                <CreationCard 
                creationId = {c.id} 
                mode="list"
                onDelete={() => {
                    askToDeleteCreation(c.id)
                }}
                />
            )
        })
    }, [filteredCreations])

    const handleNewDraft = (type: any) => {
        setCreationType(type)
        creationAPI.call(newCreation(type?.type)).then((r:any) => {
            if (r && r.status === 200) {
                handleOpen(r.data.id);
                close();
            }
        })
    }

    const handleOpen = (id: number) => {
        props.onOpen && props.onOpen(id);
    }

    const openWithCreationType = (type: any) => {
        setCreationType(type)
        open();
    }

    useImperativeHandle(ref, () => ({
        openWithCreationType,
        newDraft: handleNewDraft
    }))


    return (
        <>
            <StandardDrawer
                ref={drawerRef}
                width={"600px"}
                layout="standard"
                mask={true}
                title={creationType.title}
                zIndex={100}
            >
                 <div style={{display: "grid", placeItems: "center", padding: "10px"}}>
                <img alt="example" src={creationType.image} style={{height: "200px", width: "200px"}}/>
                </div>
                <div className="creation-list-wrapper">
                    <FormattedMessage
                        id="tools.creationDrawer.create.post.info"
                        defaultMessage="To create a post, select one of the existing drafts or create a new one. "
                        />
                    <div className="sticky-list-header">
                    <StandardButton onClick={() => handleNewDraft(creationType)} type="primary" style={{
                        width: "100%",
                        marginBottom: "1rem",
                        }}>
                        <AwesomeIcon icon={faPlusCircle}/>
                        <FormattedMessage
                        id="tools.creationEditor.post.actions.new"
                        defaultMessage="New Post"
                        />
                    </StandardButton>
                    </div>
                    <div className="creation-list">
                        {mappedCreations}
                    </div>
                </div>
                {contextHolder}
            </StandardDrawer>
        </>
    )


})

export default CreationDrawer;