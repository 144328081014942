import Icon from "@ant-design/icons";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import { Badge, Card, Tooltip } from "antd";
import Meta from "antd/es/card/Meta";
import AwesomeIcon from "components/common/AwesomeIcon";
import { useMemo } from "react";
import "./card-select.less"

type CardType = {
    title: string;
    description: string;
    value: any;
    image?: any;
    tooltip?: string;
}

type CardSelectProps = {
    cards: CardType[];
    value: any;
    onChange?: (value: any) => void;
    onSelect?: (value: any) => void;
    onBlur?: () => void;
    disabled?: boolean;
    style?: any;
    cardStyle?: any;
}

export default function CardSelect (props:CardSelectProps) {

    const handleClick = (card:any) => {
        if (props.onChange) props.onChange(card.value);
        if (props.onSelect) props.onSelect(card.value);
        if (props.onBlur) props.onBlur();
    }

    const cards = useMemo(() => {
        if (!props.cards) return null;
        return props.cards.map((card:any) => {

            const active = props.value === card.value 
            const disabled = props.disabled ? true : active;

            return <Tooltip
                title={card.tooltip}>
            <Card
                key={card.value}
                onClick={() => disabled ? "" : handleClick(card)}
                style={{
                    width: "120px",
                    height: "120px", 
                    ...props.cardStyle
                }}
                hoverable={disabled ? false : true}
                className={disabled ? "standard-disabled": ""}
                >
                    {card.image ? (
                    <div style={{width: '100%', height: "100%", display: "grid", placeItems: 'center'}}>
                        <card.image style={{width: "100%", height: "30px"}}/>
                    </div>
                    ) : null}
                    <p style={{wordBreak: "break-word"}}><b>{card.title}</b></p>
                    <p style={{wordBreak: "break-word"}}>{card.description}</p>
                {/* <Meta
                    title={card.title}
                    description={card.description}
                    style={{
                        display: "grid",
                        placeItems: "center",
                    }}
                /> */}
            </Card>
            </Tooltip>
       
        })
    }, [props.cards, props.value])

    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            ...props.style
        }}
        className="card-select-new"
        >
            {cards}
        </div>
    )

}