import { Row, Col, Input, Divider } from "antd";
import { useEffect, useMemo, useState } from "react";
import FormattedMessage from "components/common/FormattedMessage";
import RelaxMultiform, {
  useRelaxMultiform,
} from "tools/relaxForm/RelaxMultiform";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import Map from "./Map";
import { StandardRow } from "components/layout/StandardGrid";
import {
  GeocoderResultToParsedResult,
  useGoogleGeocoder,
} from "services/hooks/useGoogleGeocoder";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { CountrySelect } from "./CountrySelect";
import ZipCodeInput from "_components/forms/masked-inputs/zip-code-input";
import LocationMarkerDragEndInfo from "./LocationMarkerDragEndInfo";
import RegionSelect from "./RegionSelect";
import useAPI from "services/hooks/useAPI";
import { UpdateWebsiteTemaplate } from "modules/site-generator/reducers/websiteSlice";
import StandardButton from "components/common/StandardButton";

export type AddressProps = {
  address: string;
  city: string;
  voivodeship: string;
  region: string;
  country: string;
  zip_code: string;
  latitude: number;
  longitude: number;
};

export type InitialValuesAddressProps = AddressProps & {
  [data: string]: any;
};

type Props = {
  initialValuesSelector?: any;
};

export default function AddressWithMap(props: Props) {

  const updateAddress = useAPI(UpdateWebsiteTemaplate);
  const mf = useRelaxMultiform();
  const initialValuesSelector =
    mf?.multiformProps?.initialValuesSelector || props.initialValuesSelector;
  const initialValues: InitialValuesAddressProps | null = useSelectorWithParams(
    initialValuesSelector
  );
  const [showAlert, setShowAlert] = useState(false);


  const [mapState, setMapState] = useState<any>(
    initialValues
      ? {
          country: initialValues?.country,
          voivodeship: initialValues?.voivodeship,
          city: initialValues?.city,
          address: initialValues?.address,
          zip_code: initialValues?.zip_code,
          latitude: initialValues?.latitude,
          longitude: initialValues?.longitude,
        }
      : {}
  )

  const { search, searchByPoint } = useGoogleGeocoder();

  const marker = useMemo(() => {
    if ((mapState?.latitude, mapState?.longitude)) {
      return [
        {
          position: {
            lat: mapState?.latitude,
            lng: mapState?.longitude,
          },
          draggable: true,
        },
      ];
    }
  }, [mapState]);


  const handleMarkerDragEnd = (markerLocation: any) => {
    const { latitude, longitude } = markerLocation;

    searchByPoint(latitude, longitude).then((pointData) => {
      if (pointData && pointData.length > 0) {
        const parsedResult = GeocoderResultToParsedResult(pointData[0]);
        setMapState(parsedResult);
      }
    });
    setShowAlert(true);
  };

  const handleDismissNewLocation = () => {
    const previousAddress = {
      country: initialValues?.country,
      voivodeship: initialValues?.voivodeship,
      city: initialValues?.city,
      address: initialValues?.address,
      zip_code: initialValues?.zip_code,
      latitude: initialValues?.latitude,
      longitude: initialValues?.longitude,
    };

    setMapState(previousAddress);
    setShowAlert(false);
  };

  const handleSetNewLocation = () => {
    handleUpdateAddress(mapState)
    setShowAlert(false);
  };

  const handleUpdateAddress = (data:AddressProps) => {

  const isOnlyAddress = Object.keys(data).length === 1 && 'address' in data;
  const isOnlyZipCode = Object.keys(data).length === 1 && 'zip_code' in data;

  const queryData = isOnlyAddress || isOnlyZipCode ? { ...data, zip_code: mapState?.zip_code, city: mapState?.city } : data;

  const locationQueryString = Object.entries(queryData)
    .map(([key, value]) => `${value}`)
    .join(" ");

    if (locationQueryString === "") return;

    search(locationQueryString).then((pointData) => {
      if (pointData && pointData.length > 0) {
        const parsedResult = GeocoderResultToParsedResult(pointData[0]);
        updateAddress.call({
          body: {
            ...data,
            ...(data.address ? { latitude: parsedResult.latitude, longitude: parsedResult.longitude } : {})
          }
        }).then((res)=>{
          if(res.status === 200){
            setMapState(parsedResult)
          } 
        });
      }
    });
  };

  const isDisabledInputs = initialValues?.country != null ? false : true;

  return (
    <>
      <RelaxMultiform
        initialValues={initialValues}
        finalizeSubmit={(data) => {
          handleUpdateAddress(data);
        }}
        hideStatus={true}
      >
        <StandardRow
          align="middle"
          type="flex"
          style={{ alignItems: "stretch" }}
        >
          <Col xs={24} md={12} xl={8}>
            <FormattedMessage
              id="apps.websites.forms.companyData.address"
              defaultMessage="Address"
            />
            <Divider />
            <RelaxSingleField
              name="country"
              shouldReset={true}
              required
              label={
                <FormattedMessage
                  id="apps.websites.forms.companyData.country"
                  defaultMessage="Country"
                />
              }
            >
              <CountrySelect />
            </RelaxSingleField>

            {initialValues?.country && (
              <RelaxSingleField
                name="voivodeship"
                shouldReset={true}
                required
                label={
                  <FormattedMessage
                    id="apps.websites.forms.companyData.voivodeship"
                    defaultMessage="Voivodeship"
                  />
                }
              >
                <RegionSelect
                  initialValues={initialValues}
                />
              </RelaxSingleField>
            )}

            <RelaxSingleField
              name="city"
              shouldReset={true}
              required
              disabled={isDisabledInputs}
              label={
                <FormattedMessage
                  id="apps.websites.forms.companyData.city"
                  defaultMessage="City"
                />
              }
            >
              <Input />
            </RelaxSingleField>

            <RelaxSingleField
              name="address"
              shouldReset={true}
              required
              disabled={isDisabledInputs}
              label={
                <FormattedMessage
                  id="apps.websites.forms.companyData.address"
                  defaultMessage="Address"
                />
              }
            >
              <Input />
            </RelaxSingleField>

            <Row gutter={30}>
              <Col xs={24} md={12} lg={12}>
                <RelaxSingleField
                  name="zip_code"
                  shouldReset={true}
                  required
                  disabled={isDisabledInputs}
                  label={
                    <FormattedMessage
                      id="apps.websites.forms.companyData.zipCode"
                      defaultMessage="Zip code"
                    />
                  }
                >
                  <ZipCodeInput />
                </RelaxSingleField>
              </Col>
              <Col xs={24} md={12} lg={12}>
            
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={12} xl={16}>
            <Map
              markers={marker}
              scrollwheel={true}
              height="100%"
              onMarkerLocationChange={handleMarkerDragEnd}
            />
            {showAlert && (
              <LocationMarkerDragEndInfo
                initialValues={initialValues}
                currentValue={mapState}
                handleDismissNewLocation={handleDismissNewLocation}
                handleSetNewLocation={handleSetNewLocation}
              />
            )}
          </Col>
        </StandardRow>
      </RelaxMultiform>
    </>
  );
}
