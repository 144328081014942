import { Popover } from "antd";
import React from "react";
import { ReactNode, useMemo } from "react";
import { KeywordTooltip } from "./KeywordFacebook";

function KeywordsBoxWithTooltip(props: {children: ReactNode[]}) {

    const [hoveredKeyword, setHoveredKeyword] = React.useState<any>(null);

    const handleKeywordHover = (keyword: any, isHovering: boolean) => {
        if (isHovering) {
            setHoveredKeyword(keyword);
        } else {
            setHoveredKeyword(null);
        }
    }

    const mappedChildren = useMemo(() => {
        //Clone children, adding onMouseEnter and onMouseLeave props
        if (!props.children) return null;
        if (!Array.isArray(props.children)) return props.children;
        return React.Children.map(props.children, (child: any) => {
            return React.cloneElement(child, {
                onMouseEnter: (keyword: any) => handleKeywordHover(keyword, true),
                onMouseLeave: (keyword: any) => handleKeywordHover(keyword, false)
            });
        });
    }, [props.children])


    return (
         <Popover
            content={<KeywordTooltip keyword={hoveredKeyword} key={hoveredKeyword?.id || "kwd"}/>}
            destroyTooltipOnHide={true}
            showArrow={false}
            open={!!hoveredKeyword}
            placement="top"
        >
         {mappedChildren}
        </Popover>
    );
}

export default KeywordsBoxWithTooltip;