import { ReactNode } from "react";
import FormattedMessage from "components/common/FormattedMessage";

export type ToolIntegration = {
    name: string,
    displayName: ReactNode | string,
    description?: ReactNode | string,
    selector?: any,
}

export const ToolIntegrations = new Map<string, ToolIntegration>([
    ["fresh-mail", {
        name: "fresh-mail",
        displayName: "FreshMail",
        description: (<FormattedMessage
            id="config.toolIntegrations.integrations.freshMail.description"
            defaultMessage="Integrate with FreshMail. Integration allows you to automatically advertise leads to your mailing list."
          />),
    }],
    ["sms-api", {
        name: "sms-api",
        displayName: "SMS API",
        description: (<FormattedMessage
            id="config.toolIntegrations.integrations.smsApi.description"
            defaultMessage="The SMS API integration will allow you to automatically add phone numbers collected by the bot to your sms campaign."
            />),
    }],
    ["get-response", {
        name: "get-response",
        displayName: "GetResponse",
        description: (<FormattedMessage
            id="config.toolIntegrations.integrations.getResponse.description"
            defaultMessage="Integrate with GetResponse."
          />),
    }],
    ["mailer-lite", {
        name: "mailer-lite",
        displayName: "Mailer Lite",
        description: (<FormattedMessage
            id="config.toolIntegrations.integrations.mailerLite.description"
            defaultMessage="Integrate with Mailer Lite."
          />),
        
    }],
]);