import { ProductConfig } from "products";
import logo from "products/leadAds/logo.svg";
import requirements from "modules/bot/config/requirements";

const config: ProductConfig = {
  name: "LeadAds",
  logo,
  requirements: requirements,
  index: "/bot/list",
};

export default config;
