import { Col, Collapse, Row } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import StandardButton from "components/common/StandardButton";
import { TextList } from "modules/forsant/containers/onboarding/TextLists";
import { DisplayAdsWithSuggestions } from "modules/forsant/containers/onboarding/media/DisplayAds";
import {
  GoogleSmartSettingsSelector,
  UpdateGoogleSmartSettings,
} from "modules/smart/smartSlice";
import { useEffect, useState } from "react";
import useAPI from "services/hooks/useAPI";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";
import { RelaxValidationResults } from "tools/relaxForm/RelaxValidationResults";
import {
  rulesetArrayMinMax,
  rulesetMinMax,
} from "tools/relaxForm/relaxPresets";

// recreate Google Ads logic in backend isn't working, so only patch google_ads_settings in components
export const GoogleAdsSettings = () => {
  const [adsSettings, setAdsSettings] = useState<any>({});

  const recreateGoogleAds = useAPI({});

  const handleSubmit = () => {
    recreateGoogleAds
      .call({
        ...UpdateGoogleSmartSettings,
        // url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/services/google/ads/recreate`, 2),
        // method: "POST",
        // successDispatch: smartSlice.actions.updateGoogleSmartSettings,
        body: {
          ...adsSettings,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setAdsSettings({});
        }
      });
  };

  return (
    <>
      <RelaxMultiform
        initialValuesSelector={GoogleSmartSettingsSelector}
        submitOverride={(value: any) => {
          setAdsSettings({ ...adsSettings, ...value });
        }}
      >
        <Collapse>
          <Collapse.Panel key="headlines" header="Headlines">
            <TextList
              type="headlines"
              listRules={rulesetArrayMinMax(6, 50)}
              rules={rulesetMinMax(1, 30)}
            />

          </Collapse.Panel>
          <Collapse.Panel key="short_descriptions" header="Short Descriptions">
            <TextList
              type="short_descriptions"
              listRules={rulesetArrayMinMax(3, 50)}
              rules={rulesetMinMax(1, 60)}
            />    
          </Collapse.Panel>
          <Collapse.Panel key="descriptions" header="Descriptions">
            <TextList
              type="descriptions"
              listRules={rulesetArrayMinMax(3, 50)}
              rules={rulesetMinMax(1, 90)}
            />
          </Collapse.Panel>
          <Collapse.Panel key="horizontal" header="Horizontal Images">
            <DisplayAdsWithSuggestions
              type="horizontal"
              fieldName="horizontal_images"
              dispatch="getHorizontalImagesSuggestions"
              sizeRequirements={{
                minWidth: 600,
                minHeight: 314,
              }}
              rules={rulesetArrayMinMax(2, 20)}
              cropPresets={{
                default: [
                  {
                    ratio: "1.9:1",
                    aspect: 1.91,
                    minHeight: 314,
                    minWidth: 600,
                  },
                ],
              }}
            />
          </Collapse.Panel>
          <Collapse.Panel key="square" header="Square Images">
            <DisplayAdsWithSuggestions
              type="square"
              fieldName="square_images"
              dispatch="getSquareImagesSuggestions"
              sizeRequirements={{
                minWidth: 300,
                minHeight: 300,
              }}
              rules={rulesetArrayMinMax(1, 20)}
              cropPresets={{
                default: [
                  {
                    ratio: "1:1",
                    aspect: 1,
                    minHeight: 300,
                    minWidth: 300,
                  },
                ],
              }}
            />
          </Collapse.Panel>
        </Collapse>
        {/* <Row gutter={30}>
          <Col xs={24} md={24} lg={24}>
            <div>
              <FormattedMessage
                id="apps.forsant.adsSettings.videoAds.label"
                defaultMessage="Video ads"
              />
            </div>
            <VideoAds/>
          </Col>
        </Row> */}
      </RelaxMultiform>
      <RelaxValidationResults validationErrors={recreateGoogleAds?.error?.errors}/>
      <StandardButton
        onClick={handleSubmit}
        disabled={
          Object.keys(adsSettings).length === 0 || recreateGoogleAds.loading
        }
        type="primary"
      >
        <FormattedMessage
          id="apps.forsant.adsSettings.recreate.ads"
          defaultMessage="Recreate Ads"
        />
      </StandardButton>
    </>
  );
};
