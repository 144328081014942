import { createPortal } from "react-dom";
import { ValidationResult } from "tools/creationEditor/editorTools/ValidationResults";

type ValidationError = {
    detail: string;
    source: {
        pointer:string
    };
    [key:string]: any
  };

type ValidationErrors = ValidationError | ValidationError[];

export const RelaxValidationResults = ({ validationErrors }: { validationErrors: ValidationErrors }) => {
    // console.log(validationErrors)
    const queryAndInjectError = (field: string, errors: any[]) => {
        const query = `#rf-${field.replaceAll(".", "-")} .extra-error`;
        const elem = document.querySelector(query);
        if (!elem) return [];
        return errors.map((error, i) => createPortal(<ValidationResult key={i} result={error.details || error} />, elem));
    };

    if (!validationErrors) return null;
    if (typeof validationErrors === "string") return <ValidationResult result={validationErrors} />;
    if (Array.isArray(validationErrors)) {

        return validationErrors.map((res, i) => {
            if (res?.source?.pointer) {
                const path = res.source.pointer
                const parts = path.split('/');
                const fieldName = parts[parts.length - 1];
                return queryAndInjectError(fieldName, [res]);
            } else {
                return <ValidationResult key={i} result={res.details || res.detail || res} />;
            }

        });
    }

    const errorObjects = Object.keys(validationErrors).map((key) => {
        if (!validationErrors[key] || !validationErrors[key].length) return null;
        return queryAndInjectError(key, validationErrors[key]);
    });
    return <>{errorObjects}</>;
};
