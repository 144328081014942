import React, { Component } from "react";
import { Select } from "antd";
import FormattedMessage from "components/common/FormattedMessage";

const LANGUAGES = {
  en: (
    <FormattedMessage
      id="components.inputs.language.en"
      defaultMessage="English"
    />
  ),
  pl: (
    <FormattedMessage
      id="components.inputs.language.pl"
      defaultMessage="Polish"
    />
  ),
  ru: (
    <FormattedMessage
      id="components.inputs.language.ru"
      defaultMessage="Russian"
    />
  ),
  fr: (
    <FormattedMessage
      id="components.inputs.language.fr"
      defaultMessage="French"
    />
  ),
  es: (
    <FormattedMessage
      id="components.inputs.language.es"
      defaultMessage="Spanish"
    />
  ),
  
};

class LanguageSelect extends Component {
  generateOptions = () => {
    let options = [];
    for (let language in LANGUAGES) {
      // eslint-disable-next-line
      if (LANGUAGES.hasOwnProperty(language)) {
        options.push(
          <Select.Option value={language} key={language}>
            {LANGUAGES[language]}
          </Select.Option>
        );
      }
    }
    return options;
  };

  render() {
    return (
      <Select showSearch {...this.props}>
        {this.generateOptions()}
      </Select>
    );
  }
}

export default LanguageSelect;
