import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import {
    Editor,
    EditorState,
    ContentState,
    CompositeDecorator,
    Modifier,
} from "draft-js";
import { useTextTools } from "./TextTools";
import AwesomeIcon from "components/common/AwesomeIcon";
import { faX, faXmarkCircle } from "@fortawesome/pro-light-svg-icons";
import useCallAfterUpdate from "services/hooks/useCallAfterUpdate";

function findKeywordOccurrences(input:string, keywords:string[], regexp?:((kwd:string) => string) |undefined|null) {
    const results = {};

    for (let keyword of keywords) {
        const pattern = new RegExp(regexp ? regexp(keyword) : `(?<=^|\\s|[^a-zA-Z0-9#_])${keyword}(?=\\s|[^a-zA-Z0-9#_]|$)`, 'gmi');
        const occurrences = [];
        let match;
        while (match = pattern.exec(input)) {
            occurrences.push({ position: match.index, length: match[0].length });
        }
        results[keyword] = occurrences;
    }

    return results;
}


const createHighlightDecorator = (words: string[], regexp?:((kwd:string) => string) |undefined|null) =>
    new CompositeDecorator([
        {
            strategy: (contentBlock, callback) => {
                const text = contentBlock.getText();

                const matches = findKeywordOccurrences(text, words, regexp);
                for (let keyword in matches) {
                    for (let occurrence of matches[keyword]) {
                        callback(occurrence.position, occurrence.position + occurrence.length);
                    }
                }

            },
            component: ({ children, ...props }: any) => {

                return (
                    <mark
                    style={{
                        background: "rgba(61, 97, 239, 0.1)",
                        borderRadius: "5px",
                    }}
                    >
                        {children}
                    </mark>
                )

            },
        },
    ]);


const TextEditorNew = React.forwardRef((props: any, ref: any) => {

    const safeValue = useMemo(() => {

        if (!props.value) return "";
        if (Array.isArray(props.value)) {
            if (props.value.length === 0) return "";
            return props.value[0];
        };
        return props.value;

    }, [props.value])

    const makeEditorStateWithSafeValue = () => EditorState.createWithContent(ContentState.createFromText(safeValue ? safeValue : ""),createHighlightDecorator(props.wordsToHighlight || [], props.regexp))

    const [editorState, setEditorState] = useState(makeEditorStateWithSafeValue());
    const isFocused = useRef(false);
    const {openTextTools} = useTextTools();
    const update = useCallAfterUpdate(() => getPlainTextAndCallOnChange())

    const editor = useRef(null);

    const getPlainTextAndCallOnChange = () => {
        if (!editorState) return;
        const plainContent = editorState.getCurrentContent().getPlainText();
        if (plainContent === safeValue) return;
        props.onChange && props.onChange(plainContent)
    }


    useEffect(() => {
        if (isFocused.current) return;
        const plainContent = editorState.getCurrentContent().getPlainText();
        if (plainContent === safeValue) return;
        setEditorState(makeEditorStateWithSafeValue())
    }, [safeValue]);

    const handleChange = (newState: any) => {
        console.log("newState",newState)
        setEditorState(newState)
        //if (!wasFocused) return;
        update();
        //getPlainTextAndCallOnChange();
    }

    const insertText = (text: string) => {
        setEditorState((editorState) => {
        const currentContent = editorState.getCurrentContent();
        const currentSelection = editorState.getSelection();

        const newContent = Modifier.replaceText(
            currentContent,
            currentSelection,
            text + " "
        );

        const newEditorState = EditorState.push(
            editorState,
            newContent,
            "insert-characters"
        );

        EditorState.forceSelection(
            newEditorState,
            newContent.getSelectionAfter()
        )

        return newEditorState;
    })
        
    }

    const cleanEditorState = () =>{
        setEditorState(makeEditorStateWithSafeValue)
        update();
    }

    const focus = () => {
        if (isFocused.current) return;
        isFocused.current = true;
        (editor as any).current.focus()
    };

    const wrapperFocus = () => {
        if (isFocused.current) return;
        isFocused.current = true;
        (editor as any).current.focus()

    }


    useImperativeHandle(
        ref,
        () => ({
            editor,
            cleanEditorState,
            insertText,
            getText: () => editorState.getCurrentContent().getPlainText(),
        })
    )

    return (
        <div className={`ant-input ${props.className ? props.className : ""}`} 
        onClick={wrapperFocus}
        style={{
            height: "auto",
            minHeight: "55px",
            wordWrap: "break-word",
        }}
        >
            <Editor
                ref={editor}
                editorState={editorState}
                onChange={handleChange}
                onBlur={() => {
                    props.onBlur && props.onBlur(); 
                    isFocused.current = false}}
                onFocus={focus}
                spellCheck={true}
            />
        </div>
    )

})

export default TextEditorNew;