import { Alert, Divider, Input, Space, Table } from "antd";
import StandardButton from "components/common/StandardButton";
import DateTimeDisplay from "components/displays/DateTimeDisplay";
import React, { useImperativeHandle, useMemo, useState } from "react";
import { useEffect } from "react";
import FormattedMessage from "components/common/FormattedMessage";
import useAPI from "services/hooks/useAPI";
import { StandardModal, useSimpleModal, useStandardModal } from "services/hooks/useModal";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import { AcceptTask, RejectTask, TaskSelector, UnpublishTask } from "./data/tasksSlice";
import { Task, taskTypes } from "./data/taskTypeConfig";


type TaskModalProps = {
    taskId: string,
} & any;


const TaskResolutionMessage = (props: { task: Task }) => {

    if (!props.task) return null;

    const isPending = useMemo(() => {
        if (!props.task) return false;
        return props.task.status === "pending_acceptation";
    }, [props.task])

    if (isPending) return null;

    if (props.task.status === "accepted") {
        return <Alert
            message={<div>
                <FormattedMessage
                    id="tools.tasks.actions.acceptTask.result.success"
                    defaultMessage="This task was accepted."
                />
                <DateTimeDisplay showDate showTime dateTime={props.task.updated_at} />
            </div>
            }
            type="success"
            showIcon
        />
    }

    if (props.task.status === "rejected") {
        return <Alert
            message={
                <div>
                    <FormattedMessage
                        id="tools.tasks.actions.rejectTask.result.success"
                        defaultMessage="This task was rejected."
                    />
                    <DateTimeDisplay showDate showTime dateTime={props.task.updated_at} />
                </div>
            }
            type="error"
            showIcon
        />
    }

    return null;

}

const TaskModal = React.forwardRef((props: TaskModalProps, ref: any) => {

    const [modalRef, open, close] = useStandardModal(ref)
    const [taskId, setTaskId] = useState<string | null>(null);
    const task = useSelectorWithParams([TaskSelector, taskId])
    const resolveAPI = useAPI({})
    const fieldRef = React.useRef<any>();
    const [openSuccessModal, contextHolder] = useSimpleModal();

    const isPending = useMemo(() => {
        if (!task) return false;
        return task.status === "pending_acceptation";
    }, [task])

    const handleOpen = (taskId: string) => {
        setTaskId(taskId);
        open();
    }

    const handleAcceptTask = () => {
        if (!taskId || !task) return;
        resolveAPI.call({
            ...AcceptTask(taskId), body: {
                accept_message: fieldRef.current.currentValue,
            }
        }).then((res: any) => {

            if (res?.status === 200) {
                openSuccessModal({
                    title: "Success",
                    content: <FormattedMessage
                        id="tools.tasks.actions.acceptTask.result.success"
                        defaultMessage="Task accepted!"
                    />,
                    onOk: close,
                    onCancel: close,
                })
            } else {
                openSuccessModal({
                    title: "Error",
                    content: <FormattedMessage
                        id="tools.tasks.actions.acceptTask.result.error"
                        defaultMessage="An error occured! Pleas try again later."
                    />,
                    onOk: close,
                    onCancel: close,
                })
            }

        })
    }

    const handleRejectTask = () => {
        if (!taskId || !task) return;
        resolveAPI.call({
            ...RejectTask(taskId), body: {
                reject_message: fieldRef.current.currentValue,
            }
        }).then((res: any) => {

            if (res?.status === 200) {
                openSuccessModal({
                    title: "Success",
                    content: <FormattedMessage
                        id="tools.tasks.actions.rejectTask.result.success"
                        defaultMessage="Task rejected!"
                    />,
                    onOk: close,
                    onCancel: close,
                })
            } else {
                openSuccessModal({
                    title: "Error",
                    content: <FormattedMessage
                        id="tools.tasks.actions.rejectTask.result.error"
                        defaultMessage="An error occured! Pleas try again later."
                    />,
                    onOk: close,
                    onCancel: close,
                })
            }

        })
    }

    const handleUnpublishTask = () => {
        if (!taskId || !task) return;
        resolveAPI.call({
            ...UnpublishTask(taskId)
        }).then((res: any) => {

            if (res?.status === 200) {
                openSuccessModal({
                    title: "Success",
                    content: <FormattedMessage
                        id="tools.tasks.actions.unpublishTask.result.success"
                        defaultMessage="Task unpublished!"
                    />,
                    onOk: close,
                    onCancel: close,
                })
            } else {
                openSuccessModal({
                    title: "Error",
                    content: <FormattedMessage
                        id="tools.tasks.actions.unpublishTask.result.error"
                        defaultMessage="An error occured! Pleas try again later."
                    />,
                    onOk: close,
                    onCancel: close,
                })
            }

        })
    }


    const typeConfig = useMemo(() => {
        if (!task) return null;
        if (!task.type) return null;
        return taskTypes.get(task.type) || null;
    }, [task])

    // useEffect(() => {
    //     console.log(`task`,task);
    // }, [task]);


    useImperativeHandle(ref, () => ({
        open: handleOpen,
    }))

    return (
        <StandardModal
            ref={modalRef}
            title={`Resolve Task - ${typeConfig ? typeConfig.title : ""}`}
            modalProps={{
                footer: typeConfig && typeConfig.buttons(task, handleAcceptTask, handleRejectTask, handleUnpublishTask, close)
            }}
        >
            <div
                key={taskId || "none"}
            >
                <TaskResolutionMessage task={task} />
                {task && typeConfig && typeConfig.content(task)}
                {task && task.request_message && <div>{task.request_message}</div>}
                {isPending && 
                <>
                <Divider />
                <RelaxSingleField
                    ref={fieldRef}
                    name="message"
                    label="Message"
                >
                    <Input.TextArea rows={4} />
                </RelaxSingleField>
                </>
                }
                {contextHolder}
            </div>
        </StandardModal>
    )

})

export default TaskModal;