import { useEffect } from "react";
import { CalendarMode, useCalendar } from "./Calendar";
import CalendarDay from "./CalendarDay";
import { Typography } from "antd";
import dayjs from "dayjs";

const CalendarGrid = (props: any) => {

    const { date, mode, days, range} = useCalendar();

    return (
        <>
        <div className="calendar-grid-wrapper">
            <div className="calendar-grid-header">
                {[0, 1, 2, 3, 4, 5, 6].map((day, i) => (
                    <div key={`${i}`} className={`day ${dayjs().day() === i ? "today" : ""}`}>
                        <Typography.Text className="day-name">
                            {dayjs().weekday(i).format('dddd')}
                        </Typography.Text>
                        <div className="day-date">
                            {range?.[0] ? dayjs(range[0]).add(i, "days").format('D') : null}
                        </div>
                    </div>
                ))}
            </div>
            <div className="calendar-grid-container">
                <div className={`calendar-grid mode-${mode}`}>
                    {days && days.map((day) => {
                        return <CalendarDay key={day.format('YYYY-MM-DD')} date={day} />
                    })}
                </div>
            </div>
        </div>
        </>
    )

}

export default CalendarGrid;