
import FormattedMessage from "components/common/FormattedMessage";
import GoogleConfigCoupons from "./GoogleConfigCoupons";
import Tabs from "_components/tabs/tabs";
import GoogleConfigAddFound from "./GoogleConfigAddFounds";
import { GetProjectSettings } from "state/project/projectSettingsSlice";
import useAPI from "services/hooks/useAPI";
import { GoogleAdsSettings } from "./GoogleAdsSettings";

const GoogleConfig = () => {

  const ProjectSettingsCall = useAPI(GetProjectSettings, true)

    return (
        <Tabs
        panes={[
          {
            title: "Coupons",
            key: "coupons",
            content: <GoogleConfigCoupons />,
          },
          {
            title: "Add Founds",
            key: "addFounds",
            content: <GoogleConfigAddFound />,
          },
          {
            title: "Add Settings",
            key: "addSettings",
            content: <GoogleAdsSettings />,
          },
        ]}
        />
    )

}

export default GoogleConfig;