import { useEffect } from "react"
import useSelectorWithParams from "services/hooks/useSelectorWithParams"
import OrganizationTable from "./components/OrganizationTable"
import OrganizationTableGroups from "./components/OrganizationTableGroups"
import OrganizationTools from "./components/OrgannizationTools"
import { DeleteGroup, NewGroup, OrganizationGroupsSelector, OrganizationProjectsSelector, PatchGroup } from "./data/organizationSlice"
import { toolsGroup } from "./OrganizationManager"
import { OrganizationToolNewGroupWithoutProjects } from "./tools/OrganizationToolForms"
import FormattedMessage from "components/common/FormattedMessage"

const groupTools: toolsGroup = {
    key: "groupTools",
    label: "Group",
    tools: [
        {
            key: "createNewGroup",
            label: <FormattedMessage
                id="organization.organizationGroupsTab.newGroup.label"
                defaultMessage="New group" />,
            title: <FormattedMessage
                id="organization.organizationGroupsTab.newGroup.title"
                defaultMessage="Create New Group" />,
            component: <OrganizationToolNewGroupWithoutProjects />,
            toolAPICall: (inputData: any, outputData: any) => {
                return {
                    ...NewGroup(),
                    body: {
                        name: outputData.name
                    }
                }
            }
        },
        {
            key: "removeFromGroup",
            label: <FormattedMessage
                id="organization.organizationGroupsTab.removeFromGroup.label"
                defaultMessage="Remove from group" />,
            title: <FormattedMessage
                id="organization.organizationGroupsTab.removeFromGroup.title"
                defaultMessage="Remove from group" />,
        },
        {
            key: "removeGroup",
            label: <FormattedMessage
                id="organization.organizationGroupsTab.removeGroup.label"
                defaultMessage="Remove group" />,
            title: <FormattedMessage
                id="organization.organizationGroupsTab.removeGroup.title"
                defaultMessage="Remove Group" />,
            component: 
            <span>
                <FormattedMessage
                    id="organization.organizationGroupsTab.removeGroup.confirmation"
                    defaultMessage="Are you sure you want to remove this group" />
            </span>,
            toolAPICall: (inputData: any, outputData: any) => {
                return DeleteGroup(inputData.groups[0])
            }
        }, 
            
    ]
}

export default function OrganizationGroupsTab(props: any) {


    const groups = useSelectorWithParams(OrganizationGroupsSelector)


    return (
        <>
            <OrganizationTools toolset={groupTools} />
            <OrganizationTableGroups />
        </>
    )
}