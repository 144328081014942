import FormattedMessage from "components/common/FormattedMessage";
import flags from 'react-phone-number-input/flags';

export type Language = "pl" | "en" | "ru" | "fr" | "es";

type LanguageOption = {
    baseCode: Language;
    label: JSX.Element;
    flag: any;
}

export const LANGUAGES:Record<string, LanguageOption> = {
    en: {
        baseCode: "en",
        label: (
            <FormattedMessage
                id="components.inputs.language.en"
                defaultMessage="English"
            />
        ),
        flag: flags.GB
    },
    pl: {
        baseCode: "pl",
        label: (
            <FormattedMessage
                id="components.inputs.language.pl"
                defaultMessage="Polish"
            />
        ),
        flag: flags.PL
    },
    ru: {
        baseCode: "ru",
        label: (
            <FormattedMessage
                id="components.inputs.language.ru"
                defaultMessage="Russian"
            />
        ),
        flag: flags.RU
    },
    fr: {
        baseCode: "fr",
        label: (
            <FormattedMessage
                id="components.inputs.language.fr"
                defaultMessage="French"
            />
        ),
        flag: flags.FR,
    },
    es: {
        baseCode: "es",
        label: (
            <FormattedMessage
                id="components.inputs.language.es"
                defaultMessage="Spanish"
            />
        ),
        flag: flags.ES
    }
};

export const LANGUAGE_OPTIONS = Object.values(LANGUAGES);


