import { Typography, TypographyProps } from "antd"
import AwesomeIcon from "./AwesomeIcon"
import { faCheckCircle, faExclamationCircle } from "@fortawesome/pro-light-svg-icons"
import { ReactNode } from "react"
import { TextProps } from "antd/es/typography/Text"

const IconByType = {
    warning: faExclamationCircle,
    danger: faExclamationCircle,
    success: faCheckCircle
}

export const StandardStatusMessage = (props: { text: ReactNode, type: "warning" | "danger" | "success"}) => {
    return (
        <Typography.Text type={props.type}>
            <AwesomeIcon icon={IconByType[props.type]} style={{marginRight: "5px"}} size="xl"/>
            {props.text}
        </Typography.Text>
    )
}

export const Title = ({children, ...props}: { 
    noMargins?: boolean,
    className?: string,
    style?: React.CSSProperties,
    children?: ReactNode,
    center?: boolean
} & Partial<TypographyProps>) => {


    const {noMargins, style, center, ...rest} = props;

    let adjStyle:React.CSSProperties = {}

    if (noMargins) {
        adjStyle = {
            margin: 0,
            padding: 0
        }
    }

    if (center) {
        adjStyle = {
            display: "grid",
            placeItems: "center"
        }
    }

    adjStyle = {
        ...adjStyle,
        ...style
    }

    return (
        <Typography.Title {...rest} style={adjStyle}>
            {children}
        </Typography.Title>
    )
}

export const Text = ({children, ...props}: { 
    noMargins?: boolean,
    className?: string,
    style?: React.CSSProperties,
    children?: ReactNode,
} & Partial<TextProps>) => {

    const {noMargins, style, ...rest} = props;

    let adjStyle:React.CSSProperties = {}

    if (noMargins) {
        adjStyle = {
            margin: 0,
            padding: 0
        }
    }

    adjStyle = {
        ...adjStyle,
        ...style
    }

    return (
        <Typography.Text {...rest} style={adjStyle}>
            {children}
        </Typography.Text>
    )
}

export const SmallText = ({children, ...props}: { 
    noMargins?: boolean,
    className?: string,
    style?: React.CSSProperties,
    children?: ReactNode,
} & Partial<TextProps>) => {

    const {noMargins, style, ...rest} = props;

    let adjStyle:React.CSSProperties = {}

    if (noMargins) {
        adjStyle = {
            margin: 0,
            padding: 0
        }
    }

    adjStyle = {
        fontSize: "0.8rem",
        ...adjStyle,
        ...style
    }

    return (
        <Typography.Text {...rest} style={adjStyle}>
            {children}
        </Typography.Text>
    )
}
