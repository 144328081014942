import React, { useState, useRef, useEffect, useMemo } from 'react'

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
  containCrop,
} from 'NewReactImageCrop'

import 'react-image-crop/dist/ReactCrop.css'
import ImageCropTools from './imageCropTools'
import { CropPreset, useGallery } from './Gallery'
import { AbsoluteCropToSlides, absoluteToRelative, CanImageBeSplitToSlides, cropAbsoluteToRelative, cropRelativeToAbsolute, GetFirstUnsetCrop, presetAbsoluteToRelative, RoundAndClampCrop, RoundedClamp } from './imageHelpers'
import ImageCropSideTrack from './imageCropSideTrack'
import './image-gallery.less'
import useCallAfterUpdate from 'services/hooks/useCallAfterUpdate'
import useEffectDeepCompare from 'services/hooks/useEffectDeepCompare'

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

export type CurrentImageData = {
  width: number,
  height: number
}

const cleanSplitHelpers = () => {
  const slides = document.querySelectorAll(".crop-slide") || [];
  slides.forEach(slide => slide.remove())

  const separators = document.querySelectorAll(".crop-slide-separator") || [];
  separators.forEach(separator => separator.remove())
}

const updateSplitCropPreview = (image: any, crop: Crop, options?: {
  spaceHorizontal?: number,
  spaceVertical?: number
}) => {

  const { x, y, width, height } = crop;

  const slideSize = height;

  const absoluteCrop = cropRelativeToAbsolute(image, crop)
  const spaceBase = options?.spaceHorizontal !== undefined ? options?.spaceHorizontal : 18;

  const numberOfSlides = Math.min(5, Math.floor(width / slideSize));
  const slideSpacing = Math.max(0, Math.floor((slideSize / 600) * spaceBase));
  const subcrops: Crop[] = [];

  const e = document.querySelector(".ReactCrop__crop-selection");
  if (!e) return;

  cleanSplitHelpers();

  for (let i = 0; i < numberOfSlides; i++) {


    const slide = document.createElement('div');
    slide.classList.add("crop-slide");

    slide.style.top = `0px`;
    slide.style.left = `${0 + i * (slideSize + slideSpacing)}px`;
    slide.style.width = `${slideSize}px`;
    slide.style.height = `${slideSize}px`;

    //append the duplicated element to the DOM
    e?.appendChild(slide);



    if (i === numberOfSlides - 1) {
      const separator = document.createElement('div');
      separator.classList.add("crop-slide-separator");
      separator.classList.add("crop-slide-separator-end");
      separator.style.top = `0px`;
      separator.style.left = `${(i+1) * slideSize + (i) * slideSpacing }px`;
      separator.style.right = `0px`
      //separator.style.height = `${slideSize}px`;
      separator.style.bottom = "0px"

      e?.appendChild(separator);
    } else {
      const separator = document.createElement('div');
      separator.classList.add("crop-slide-separator");
      separator.style.top = `0px`;
      separator.style.left = `${(i + 1) * (slideSize) + i * slideSpacing}px`;
      separator.style.width = `${slideSpacing}px`;
      //separator.style.height = `${slideSize}px`;
      separator.style.bottom = "0px"

      e?.appendChild(separator);
    }

  }


}

export default function ImageCrop(props: {
  mode: "split" | undefined | null
}) {


  const { sizeRequirements, cropPresets, closeCropper, setItemCrop, croppedImage, enableCropTrack, handleSplit, splitOptions} = useGallery();



  const imgRef = useRef<HTMLImageElement>(null)
  const [crop, setCrop] = useState<Crop>()
  const [cropPreset, setCropPreset] = useState<CropPreset>();
  const [currentImageData, setCurrentImageData] = useState<CurrentImageData | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [subcrops, setSubcrops] = useState<Crop[]>([]);


  const setAbsoluteCrop = (newCrop: Crop) => {
    const relativeCrop = cropAbsoluteToRelative(imgRef.current, newCrop)
    //console.log("Setting absolute crop", currentImageData, newCrop, relativeCrop);
    setCrop(relativeCrop);
  }


  function loadExistingCropOrInitializeANewOne() {

    if (!cropPreset) return;
    if (!croppedImage) return;

    
    if (props.mode === "split") {
      setCrop(null);
      return;
    }

    const existingCrop = croppedImage?.crop?.[cropPreset.platform || "default"];
    if (existingCrop && existingCrop.ratio === cropPreset.ratio) {
      loadExistingCrop(existingCrop)
    } else {
      initializeNewCrop();
    }

  }

  function loadCropForSplit (slides: number) {
    console.log("Loading crop for split", slides)

    if (!imgRef.current) return;

    const { clientWidth, clientHeight, naturalHeight, naturalWidth } = imgRef.current;

    const slideSpacing = splitOptions?.spaceHorizontal || 18;
    const slideSize = 600;

    const width = slides * slideSize + (slides - 1) * slideSpacing;
    const height = 600;

    const newCrop: any = centerCrop(
      makeAspectCrop(
        {
          unit: 'px',
          width: width,
          height: height,
        },
        width / height,
        clientWidth,
        clientHeight,
      ),
      clientWidth,
      clientHeight,
    )
    newCrop.scope = "relative"

    setCrop(newCrop);

  }

  function loadExistingCrop(newCrop: Crop) {
    setAbsoluteCrop(newCrop);
  }

  function initializeNewCrop() {

    if (!imgRef.current) return;
    const { clientWidth, clientHeight, naturalHeight, naturalWidth } = imgRef.current;

    const newCrop: any = centerCrop(
      makeAspectCrop(
        {
          unit: 'px',
          width: 100000,
          height: 100000,
        },
        cropPreset?.aspect || 1,
        clientWidth,
        clientHeight,
      ),
      clientWidth,
      clientHeight,
    )
    newCrop.scope = "relative"


    setCrop(newCrop);

  }

  useEffectDeepCompare(() => {
    if (!crop) return;
    if (!imgRef.current) return;
    if (props.mode === "split") updateSplitCropPreview(imgRef.current, crop, splitOptions);
  }, [crop])

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {



    if (!croppedImage) return;
    if (!cropPresets) return;
    setIsLoaded(true);
    const { clientWidth, clientHeight, naturalHeight, naturalWidth } = e.currentTarget;


    if (naturalHeight && naturalWidth) setCurrentImageData({
      height: naturalHeight,
      width: naturalWidth
    })

    const [platform, newCrop, preset] = GetFirstUnsetCrop(croppedImage, cropPresets);

    if (preset) setCropPreset(preset);

    if (newCrop) {
      setCrop(cropAbsoluteToRelative(e.currentTarget, newCrop))
      return;
    }
  }

  const handleSave = () => {

    if (!croppedImage) return;
    if (!cropPreset) return;

    const { naturalHeight, naturalWidth } = (imgRef?.current || { naturalHeight: 0, naturalWidth: 0 })

    const cropAbsolute = cropRelativeToAbsolute(imgRef.current, crop);
    const cropContained = containCrop(cropAbsolute, cropPreset.aspect, "nw", naturalWidth, naturalHeight, cropPreset.minWidth, cropPreset.minHeight, cropPreset.maxWidth, cropPreset.maxHeight);
    const cropRounded = RoundAndClampCrop(cropContained, naturalWidth, naturalHeight, cropPreset);


    setItemCrop && setItemCrop(croppedImage, { crop: cropRounded, platform: cropPreset?.platform || "default", preset: cropPreset})

    if (props.mode === "split" && handleSplit) handleSplit();
    closeCropper();    

  }

  const relativePreset = useMemo(() => {
    if (!imgRef.current) return null;
    return presetAbsoluteToRelative(imgRef.current, cropPreset)
  }, [cropPreset])

  useEffect(() => {
    loadExistingCropOrInitializeANewOne();
  }, [relativePreset])


  const handleDragStart = () => {
    if (props.onDragStart) props.onDragStart()
  }

  const handleCropChange = (pixelCrop: Crop, percentCrop: Crop) => {
    //props.mode === "split" && updateSplitCropPreview(pixelCrop);
    setCrop(pixelCrop)
  }


  return (
    <div
      className='cropper-wrapper'
    >
      <ReactCrop
        crop={crop}
        onChange={handleCropChange}
        //onComplete={setCropCandidate}
        {...relativePreset} //Aspect, min and max
        className={`cropper ${props.mode === "split" ? "split" : ""}`}
        onDragStart={handleDragStart}
        onDragEnd={() => { if (props.onDragEnd) props.onDragEnd() }}
      >
        <img
          ref={imgRef}
          alt="Crop me"
          className='cropper-image'
          src={props.image.url}
          onLoad={onImageLoad}
        />
      </ReactCrop>
      <ImageCropTools
        isLoaded={isLoaded}
        crop={crop}
        preset={relativePreset}
        aspect={relativePreset?.aspect}
        onPresetSelected={setCropPreset}
        onSave={handleSave}
        imgRef={imgRef.current}
        currentImageData={currentImageData}
        onCancel={props.onCancel}
        mode={props.mode}
        loadSplit={loadCropForSplit}
        splitOptions={{
          maxSlides: 5,
        }}
      />

      {enableCropTrack && <ImageCropSideTrack />}
    </div>
  )
}
