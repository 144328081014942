import FormattedMessage from "components/common/FormattedMessage";
import useAPI from "services/hooks/useAPI";
import { GetCallMeConfig, ProjectCallMeConfigSelector, UpdateCallMeConfig } from "state/callMeSlice";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import PhoneNumberInputWithCountrySelect from "_components/forms/PhoneNumberInputWithCountrySelect";
import WebsiteInput from "_components/forms/website-input";
import Tabs from "_components/tabs/tabs";
import CallMeFormConfig from "./CallMeFormConfig";
import Switch from "_components/forms/switch";

export default function CallMeConfig() {

  const {} = useAPI(GetCallMeConfig, true)

  return (
    <RelaxMultiform
      name="callMe"
      callAPI={UpdateCallMeConfig}
      initialValuesSelector={ProjectCallMeConfigSelector}
    >
      <Tabs
        panes={[
          {
            title: "Config",
            key: "config",
            content: (
              <>
                <RelaxSingleField
                  name={"law_sms_active"}
                  label={<FormattedMessage
                    id="apps.callMe.config.lawSMSActive"
                    defaultMessage="Legal Inquiry"
                  />}
                >
                  <Switch />
                </RelaxSingleField>
                <RelaxSingleField
                  name={"law_sms_handling_number"}
                  label={<FormattedMessage
                    id="apps.callMe.config.lawSMSNumber"
                    defaultMessage="Number"
                  />}
                >
                  <PhoneNumberInputWithCountrySelect />
                </RelaxSingleField>
              </>

            )
          },
          {
            title: "Domain",
            key: "domain",
            content: (
                <RelaxSingleField
                name={"domain"}
                  label={<FormattedMessage
                    id="apps.callMe.config.domain"
                    defaultMessage="Domain"
                  />}
                >
                  <WebsiteInput />
                </RelaxSingleField>
            )
          },
          {
            title: "Form",
            key: "form",
            content: <CallMeFormConfig />
          }
        ]

        } />


    </RelaxMultiform>
  )


}

