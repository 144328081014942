import { Form, Select, Spin } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import _ from 'lodash';
import debounce from 'lodash/debounce';
import React, { useImperativeHandle } from 'react';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import useAPI, { callAPIProps, callAPIFunction } from 'services/hooks/useAPI';
import { useDebounceTrigger } from 'services/hooks/useDebounce';
import { useRelax } from 'tools/relaxForm/relaxForm';


//Ant Design Select doesnt support object values. 
//This wrapper adds that support. 

export type Props = {
    callAPI: callAPIFunction,
    value?: any, 
    onChange?: any,
    onSelect?: any,
    onOptionsLoaded?: any,
    placeholder?:any,
    mode?: "multiple" | "tags",
    valueIdName?: string,
    valueDisplayName?:string,
    disabled?: boolean,
    suggestionToValue?: (suggestion: any) => any,
    renderOption?: (option: any) => {value: string, label: ReactNode, disabled: boolean},
    clearOnSelect?: boolean,
}



const SelectWithSuggestions = React.forwardRef((props:Props, ref:any) => {

    const askForSuggestions = (q:any) => {
        if (q && q !== "") call(props.callAPI(q))
    }

    const [debounceAsk] = useDebounceTrigger(askForSuggestions, 500)
    const {call, data:suggestions, loading, clear} = useAPI(props.callAPI())
    const [allValues, setAllValues] = useState<any[]>([]);
    
    useEffect(()=> {
        if (!suggestions) return;
        let adjustedData:any[] = [];
        if (props.suggestionToValue) {
            adjustedData = suggestions.map((s:any) => props.suggestionToValue!(s))
        } else {
            adjustedData = suggestions;
        }
        setAllValues(v => _.unionBy(props.value || [], adjustedData, (a:any) => 
            props.valueIdName ? a[props.valueIdName] : a.id
        ))
    }, [suggestions])

    useEffect(() => {
        if (props.value && Array.isArray(props.value)) {
            const vo:any[] = [];
            props.value.forEach((v:any) => {
                if (typeof v === "object") vo.push(v);
            })
            setAllValues(v => _.unionBy(v, vo, (a:any) => props.valueIdName ? a[props.valueIdName] : a.id))
        }
    }, [props.value])

    const adjVal = useMemo(() => {
        if (!props.value) return [];

        if(Array.isArray(props.value)) {
            return props.value.map((v:any) => ({
                label: props.valueDisplayName ? v[props.valueDisplayName] : v.name,
                value: props.valueIdName ? v[props.valueIdName] : v.id,
            }))
        } else {
            return {
                label: props.valueDisplayName ? props.value[props.valueDisplayName] : props.value.name,
                value: props.valueIdName ? props.value[props.valueIdName] : props.value.id,
            }
        }
    }, [props.value])

    const handleSelect = (s:any) => {
        if (s && allValues) {
            const selected = allValues.find((e:any) => props.valueIdName ? e[props.valueIdName] : e.id === s);
            props.onChange(selected);
            if (props.onSelect) props.onSelect(selected);
        } else {
            props.onChange(s);
            if (props.onSelect) props.onSelect(s);
        }
        if (props.clearOnSelect) setAllValues([]);
    }

    useImperativeHandle(ref, () => ({
        clearSuggestions: clear,
    }))

    const options = useMemo(() => allValues && allValues.map && allValues.map((e: any) => {
        if (props.renderOption) return props.renderOption(e)
        return {
            label: props.valueDisplayName ? e[props.valueDisplayName] : e.name,
            value: props.valueIdName ? e[props.valueIdName] : e.id,
        }
    }), [allValues])
  
    return (
      <Select
        {...props}
        filterOption={false}
        //onChange={handleChange}
        onSearch={debounceAsk}
        value = {adjVal}
        notFoundContent={loading ? <Spin size="small" /> : null}
        onSelect={handleSelect}
        //mode="multiple"
        showSearch
        options={options}
      />
    );
});
  
  export default SelectWithSuggestions;