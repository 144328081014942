import { reauthSelector } from 'modules/panel/config/selectors/notifications';
import { userSelector } from 'modules/panel/config/selectors/user';
import React, { useEffect, useState, useContext, ReactNode, FC } from 'react';
import { useSelector } from 'react-redux';
import { Modal, notification } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import { TokenLocalStorage } from "services/helpers/local-storage";
import useSelectorWithParams from 'services/hooks/useSelectorWithParams';
import { getUserSettings, userSettingsSelector } from 'state/user/userSettingsSlice';
import useAPI from 'services/hooks/useAPI';
import { getProjectTasks, getUserTasks } from 'tools/tasks/data/tasksSlice';
import useAPISmartActions from 'services/hooks/useAPISmartActions';
import useSockets, { useSocketsManager } from "services/hooks/useSockets"
import { currentProjectIdSelector } from 'services/store/scopeSlice';
import { OrganizationInvitationModal } from 'modules/panel/containers/onboarding/projects/OrganizationInvitationModal';

export type actionRecord = {
  name: string;
  state: "loading" | "success" | "error";
  data?: any;
}

notification.config({
  placement: 'topRight',
  top: 70,
});

const APIContext = React.createContext({});
APIContext.displayName = "API Context";
export const useAPIContext = (): any => useContext(APIContext);


export const reauthFacebookPopup = {
  title: (
    <FormattedMessage
      id="services.middlewares.tokenError.facebook.title"
      defaultMessage="There is a problem with your Facebook integration"
    />
  ),
  content: (
    <FormattedMessage
      id="services.middlewares.tokenError.facebook.content"
      defaultMessage="You need to authorize Facebook again"
    />
  ),
  centered: true,
  okText: (
    <FormattedMessage
      id="services.middlewares.tokenError.facebook.authorize"
      defaultMessage="Authorize"
    />
  ),
  cancelText: (
    <FormattedMessage
      id="services.middlewares.tokenError.facebook.later"
      defaultMessage="Later"
    />
  ),
  cancelButtonProps: {
    //disabled: true,
  },
  closeable: false,

}

export const reauthGooglePopup = {
  title: (
    <FormattedMessage
      id="services.middlewares.tokenError.google.title"
      defaultMessage="There is a problem with your Google integration"
    />
  ),
  content: (
    <FormattedMessage
      id="services.middlewares.tokenError.google.content"
      defaultMessage="You need to authorize Google again"
    />
  ),
  centered: true,
  okText: (
    <FormattedMessage
      id="services.middlewares.tokenError.google.authorize"
      defaultMessage="Authorize"
    />
  ),
  cancelText: (
    <FormattedMessage
      id="services.middlewares.tokenError.google.later"
      defaultMessage="Later"
    />
  ),

}


export const APIContextProvider: FC = (props: any) => {

  const user = useSelectorWithParams(userSelector);
  const projectId = useSelectorWithParams(currentProjectIdSelector)
  const neededReauth = useSelector(reauthSelector);
  const loginStatus = useSelectorWithParams((state: any) => state.loginStatus);

  const userSettings = useSelectorWithParams(userSettingsSelector);
  const { call: callGetUserSettings } = useAPI(getUserSettings);

  const userTasksCall = useAPI(getUserTasks);
  const projectTasksCall = useAPI(getProjectTasks);

  const s = useSocketsManager();

  const privateSocket = useSockets({
    channel: `private-user.${user?.id}`,
    event: "new",
    onMessage: (message) => console.log(message),
});

  useEffect(() => {
    if (!loginStatus) return;
    if (!loginStatus.logged) return;

    if (!user) return;
    if (!userTasksCall.data) userTasksCall.call();
    //callGetUserSettings();
  }, [user]);

  useEffect(() => {
    if (user && user.id) callGetUserSettings();
  }, [user?.id]);



  useEffect(() => {
    if (!projectId) return;
    projectTasksCall.call();
  }, [projectId]);

  const getNotification = () => {

    if (!user) return null;
    if (!user.id) return null;

    //For some reason we want a magic code to skip those notifications.. for people banned on fb? idk
    if (localStorage.getItem("skipNotifications") === "true") return null;
    
    if (neededReauth && neededReauth === "facebook") {
      return (
        <Modal
          {...reauthFacebookPopup}
          open={true}
          onOk={() =>
            window.location.href = `${import.meta.env.VITE_FACEBOOK_AUTH_URL}${(TokenLocalStorage as any).get().token
            }`
          }
        >
          {reauthFacebookPopup.content}
        </Modal>
      )
    }
    if (neededReauth && neededReauth === "google") {
      return (
        <Modal
          {...reauthGooglePopup}
          open={true}
          onOk={() =>
            window.location.href = `${import.meta.env.VITE_GOOGLE_AUTH_URL}${(TokenLocalStorage as any).get().token
            }`
          }
        >
          {reauthGooglePopup.content}
        </Modal>
      )
    }

    if (localStorage.getItem("invitation_code")) {
      return (<OrganizationInvitationModal />)
    }



  }

  return (
    <APIContext.Provider value={{
    }}>
      {props.children}
      {getNotification()}
      {/* <SmartActionsDebugDisplay /> */}
    </APIContext.Provider>
  )

}

export const SmartActionsDebugDisplay = () => {


  const { smartActions } = useAPISmartActions();


  return (
    <div style={{ position: "fixed", top: 0, left: 0, zIndex: 9999, background: "white", padding: "1rem" }}>
      <h3>Smart Actions:</h3>
      <ul>
        {smartActions && Object.keys(smartActions).map((actionName) => {
          const action = smartActions[actionName];
          return (
            <li key={actionName}>
              <b>{action.name}</b> - {action.state}
            </li>
          )
        })}
      </ul>
    </div>
  )
}