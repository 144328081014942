import React, { PureComponent, useCallback, useEffect, useState, useMemo, useRef} from "react";


export default function useDebounce(value:any, delay:number) {

    const [debounced, setDebounced] = useState(value);

    useEffect(()=> {
        const handler = setTimeout(() => {
            setDebounced(value);
          }, delay);
    
        return () => {
            clearTimeout(handler);
          };
    },[value])

    return debounced;

}

export  function useDebounceTrigger(func: any,delay:number) {

  const [value, setValue] = useState<any>(0);
  const [valueToPass, setValueToPass] = useState<any>();

  useEffect(()=> {

    let handler:any;

    if (value > 0) {
      handler = setTimeout(() => {
          func(valueToPass);
        }, delay);
    }

    return () => {
        clearTimeout(handler);
      };

    },[value])

  const call = (v?:any) => {
    setValue((val:any) => val+1);
    setValueToPass(v);
  }

  return [call, value];


}