import RelaxSingleField from "tools/relaxForm/RelaxField"
import GeoPointsInputFacebook from "./GeoPointsInputFacebook"
import FormattedMessage from "components/common/FormattedMessage"
import { FacebookAdsSettingsSelector, smartSlice } from "modules/smart/smartSlice"


export default function GeoPointsFacebook(props: any) {

  return (
    <RelaxSingleField
      name={["location_targeting", "geo_points"]}
      initialValuesSelector={FacebookAdsSettingsSelector}
      callAPI={{
        url: ({ getApiUrl, projectId, smart }) => getApiUrl(`projects/${projectId}/smarts/${smart}/facebook-ads-settings`, 2),
        method: "PATCH",
        successDispatch: smartSlice.actions.updateFacebookAdsSettings,
        adjustResponse: (nd, rd, normalizer) => {
           //API BUG: Estimates are included as [0] in response, not in "included"
          return {...nd, _estimate: rd?.[0]?.[0]};
        }
      }}
      showBadgeMode="none"
      tooltip={
        <FormattedMessage
          id="apps.smart.components.adsSettings.GeoPointsFacebook.tooltip"
          defaultMessage="map"
        />
      }
      submitOnChange
    >
      <GeoPointsInputFacebook />
    </RelaxSingleField>
  )

}