import { ReactNode } from "react";
import AwesomeIcon from "components/common/AwesomeIcon";
import { faBullhorn, faCheckCircle, faExclamationCircle, faFileLines, faHourglass, faPauseCircle, faPlayCircle, faStopCircle, faTrashCircle } from "@fortawesome/pro-light-svg-icons";

const getIconByStatus = (status:string):ReactNode => {

    switch (status) {
        case "ok":
        case "enabled":
        case "published":
        case "active":
            return <AwesomeIcon icon={faCheckCircle} size="xl" color="green"/>
        case "pause":
        case "paused":
            return <AwesomeIcon icon={faPauseCircle} size="xl" color="grey"/>
        case "play":
        case "scheduled":
        case "publishing":
        case "in_process":
            return <AwesomeIcon icon={faPlayCircle} size="xl"/>
        case "stop":
        case "ended":
            return <AwesomeIcon icon={faStopCircle} size="xl"/>
        case "warning":
        case "error":
        case "unknown":
        case "unspecified":
        case "mixed":
        case "with_issues":
        case "notPublished":
        case "disaproved":
            return <AwesomeIcon icon={faExclamationCircle} size="xl" color="red"/>
        case "pending":
        case "pending_review":
        case "pending_billing_info":
        case "preapproved":
            return <AwesomeIcon icon={faHourglass} size="xl"/>
        case "deleted":
        case "archived":
        case "removed":
            return <AwesomeIcon icon={faTrashCircle} size="xl"/>
        default:
            return <AwesomeIcon icon={faExclamationCircle} size="xl" color="orange"/>
    }

}

export default function StandardStatusIcon ({ status, ...props }: { status: string} & any) {
        return getIconByStatus(status?.toLowerCase())
}