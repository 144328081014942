import { Alert, Button, Col, Divider, Input, Radio, Row, Space } from "antd";
import ButtonGroup from "antd/es/button/button-group";
import { AwesomeIconOK } from "components/common/AwesomeIcon";
import StandardButton from "components/common/StandardButton";
import { useRef, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import FormattedMessage from "components/common/FormattedMessage";
import useAPI, { callAPIProps } from "services/hooks/useAPI";
import { StandardModal, useStandardModal } from "services/hooks/useModal";
import RelaxSingleField from "tools/relaxForm/RelaxField";

export type CodeDisplayProps = {
    code: string;
    sendEmail?: boolean;
    sendCallApi?: callAPIProps;
}

export const CodeBox = ({code, style}:{code: string, style?: any}) => {
    return (
        <pre style={{ 
            backgroundColor: "lightGrey", 
            padding: "10px", 
            borderRadius: "10px", 
            minHeight: "150px", 
            width: "100%", 
            display: "flex",
            alignItems: "center",
            ...style}}>
        {code}
        </pre>
    )
}

export default function CodeDisplay(props: CodeDisplayProps) {

    const [ref, open, close] = useStandardModal()
    const [copied, setCopied] = useState(false);
    const {call, data, loading, error} = useAPI({
        method: "POST",
        ...props.sendCallApi
    });
    const email = useRef<string>("");

    return (
        <>
            <Row>
                <CodeBox code={props.code}/>
                <Space>
                    <ButtonGroup>
                    {props.sendEmail && <StandardButton block onClick={open}>Send email</StandardButton>}
                    <CopyToClipboard text={props.code} onCopy={() => setCopied(true)}>
                        <StandardButton block icon={copied && AwesomeIconOK}>{copied ? "Copied!" : "Copy"}</StandardButton>
                    </CopyToClipboard>
                    </ButtonGroup>
                    {copied && <Alert message="Copied!" type="success" showIcon />}
                    {loading && <Alert message="Sending" type="info" showIcon />}
                    {data && <Alert message="Sent" type="success" showIcon />}
                    {error && <Alert message="Error while sending" type="warning" showIcon />}
                </Space>
            </Row>
            <StandardModal
                ref={ref}
                title={(<FormattedMessage
                    id="apps.smart.components.copyToClipboardOrSendToDev.sendToDeveloper"
                    defaultMessage="Send e-mail to your developer"
                  />)}
                onOk={() => {
                    call({
                        body: {email: email.current}
                    })
                    close();
                }}
            >
                 <RelaxSingleField
                    name="email"
                    onChange={(e) => email.current = e}
                    label={<FormattedMessage
                            id="components.codeDisplay.sendModal.label"
                            defaultMessage="Email"
                        />}
                    >
                        <Input />
                </RelaxSingleField>
            </StandardModal>

        </>
    )
}


//{copied && <Alert message="Copied!" type="success" showIcon />}