import { Table } from "antd";
import useAPI from "services/hooks/useAPI";

const columns = [
  {
    title: "Campaign Name",
    dataIndex: "campaign_name",
    key: "campaign_name",
  },
  {
    title: "Target URL",
    dataIndex: "target_url",
    key: "target_url",
  },
  {
    title: "Search Keywords",
    dataIndex: "search_keywords",
    key: "search_keywords",
  },
  {
    title: "Ads Id",
    dataIndex: "adsId",
    key: "adsId",
  },
  {
    title: "Budget",
    dataIndex: "campaign_budget",
    key: "campaign_budget",
  },
  {
    title: "Currency",
    dataIndex: "currency",
    key: "currency",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status) => (status === "enabled" ? "Enabled" : "Disabled"),
  },
];

export const GoogleSearchads = () => {
  const { data, loading } = useAPI(
    {
      url: ({ getApiUrl, projectId }) =>
        getApiUrl(`services/google/search-ads/${projectId}`),
      method: "GET",
      customNormalizer:(data) => data?.data
    },
    true
  );

  const mappedCampains = data && data.map((campain) => ({
    key: campain.id,
    campaign_name: campain.campaign_name,
    target_url: campain.target_url,
    search_keywords: campain.search_keywords,
    adsId: campain.adsId,
    campaign_budget: campain.campaign_budget,
    currency: campain.currency,
    status: campain.status,
  }))


  return (
    <>
      <Table
        columns={columns}
        dataSource={mappedCampains}
        pagination={false}
        loading={loading}
      />
    </>
  );
};
