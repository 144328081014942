import { useEffect, useMemo } from "react";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { CreateNewProjectPopup } from "modules/panel/components/subscriptions/buy-product/CreateNewProjectPopup";
import ActorCardSelect from "components/inputs/actorCardSelect/ActorCardSelect";
import { usePurchaseSubscription } from "./PurchaseSubscription";
import { useStore } from "react-redux";
import { projectSubscriptionsSelector } from "state/subscriptions/subscriptionsSelectors";
import { projectsWithSubscriptionsSelector } from "state/projects/projectsSelectors";

type PurchaseProjectSelectProps = {

}

function PurchaseProjectSelect(props: PurchaseProjectSelectProps) {


    const store = useStore();
    const projects = useSelectorWithParams(projectsWithSubscriptionsSelector)
    const { projectId, product, setProjectId } = usePurchaseSubscription();

    const mappedProjects = useMemo(() => {
        if (!product) return [];
        const mp = Object.values(projects).map((p: any) => {
            const projectSubscriptions = projectSubscriptionsSelector(store.getState(), p.id);
            const hasThisProduct = projectSubscriptions && projectSubscriptions.some((s: any) => s.roles && s.roles.some((r: string) => r === product));
            return {
                label: p.name,
                value: p.id + "",
                image: p.image,
                name: p.name,
                disabled: hasThisProduct
            }
        })
        return mp;
    }, [projects, product])

    useEffect(() => {

        if (!projectId || !projects[projectId] || !mappedProjects.find((p: any) => p.value === projectId) || mappedProjects.find((p: any) => p.value === projectId && p.disabled)){

            const findFirstNotDisabled = () => {
                if (mappedProjects && mappedProjects.length > 0) {
                    const notDisabled = mappedProjects.find((p: any) => !p.disabled);
                    if (notDisabled) {
                        return notDisabled.value;
                    }
                }
            }
    
            if (mappedProjects && mappedProjects.length > 0) {
                if (projectId) {
                    const project = mappedProjects.find((p: any) => p.value === projectId);
                    if (!project || project.disabled) {
                        const firstNotDisabled = findFirstNotDisabled();
                        if (firstNotDisabled) {
                            setProjectId(firstNotDisabled);
                        }
                    }
                } else { 
                    const firstNotDisabled = findFirstNotDisabled();
                    if (firstNotDisabled) {
                        setProjectId(firstNotDisabled);
                    }
                }
            }

        }
    }, [mappedProjects]);



    const handleChange = (value: string) => {
        setProjectId(value);
    }


    const showList = () => {
        if (!projects) return false;
        if (Object.values(projects).length === 0) return false;
        if (Object.values(projects).length === 1) {
            const p = Object.values(projects)[0];
            if (!p.subscriptions || p.subscriptions.length === 0) return false;
        }
        return true;
    }

    if (!showList()) {
        return null;
    }


    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
        }}>
            <label>
                Project
            </label>
            <div style={{
                gap: "10px",
                width: "100%",
                display: "flex", 
                flexDirection: "row",
                paddingBottom: "10px"         
            }}>
                <ActorCardSelect 
                    onChange={handleChange}
                    value={String(projectId)}
                    actors={mappedProjects}
                    selectProps={{
                        showSearch: true, 
                        filterOption: (input, option) => 
                            option && option.name && option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        ,
                        placeholder: "Select a project"
                    }}
                    style={{
                        flexGrow: 1
                    }}
                />
                <CreateNewProjectPopup 
                hideLabel={true}
                onCreate={(projectId) => {
                    setProjectId(projectId);
                }}
                />
            </div>

        </div>
    );
}

export default PurchaseProjectSelect;