import React, { useEffect } from "react";
import { Redirect, useLocation, useParams } from "react-router-dom";
import {
  getUrl,
  LOGIN,
  COMPANY_BUY_PRODUCT,
} from "modules/panel/config/routes";
import {
  ProductLocalStorage,
} from "services/helpers/local-storage";
import { Product, isValidProduct } from "products";
import { useDispatch, useSelector } from "react-redux";
import { userIsLoggedSelector } from "modules/panel/config/selectors/user";

const SetOfferPurchaseOptions = () => {
  const isLogged = useSelector(userIsLoggedSelector);
  const dispatch = useDispatch();
  const { product, offerId } = useParams<{
    product: Product;
    offerId?: string;
  }>();
  const { search } = useLocation();

  useEffect(() => {

    if (offerId) {


      const showAll = new URLSearchParams(search).get("showAll")
      const promoCode = new URLSearchParams(search).get("promoCode");
      const partnerCode = new URLSearchParams(search).get("partnerCode");
      const bundle = new URLSearchParams(search).get("bundle");

      const lsSettings = localStorage.getItem("selectedOffer") ? JSON.parse(localStorage.getItem("selectedOffer") as string) : {};

      const toSet:any = {
        ...lsSettings,
        offerId: parseInt(offerId), 
      }

      if (showAll) toSet.showAll = showAll === "true";
      if (promoCode) toSet.promoCode = promoCode;
      if (partnerCode) toSet.partnerCode = partnerCode;
      if (product) toSet.product = product;
      if (bundle) toSet.bundle = bundle;

      localStorage.setItem("selectedOffer", JSON.stringify(toSet));
    }

    if (isValidProduct(product)) {
      ProductLocalStorage.set(product);
      //dispatch(setCurrentProduct(product));
    } else console.warn(`${product} is not a valid product!`);

  }, [product, offerId, dispatch, isLogged, search]);

  const buyProductUrl = getUrl(COMPANY_BUY_PRODUCT, {
    params: { product },
  });

  return (
    <Redirect
      to={
        isLogged
          ? buyProductUrl
          : {
            pathname: getUrl(LOGIN),
            state: {
              from: buyProductUrl,
            },
          }
      }
    />
  );
};

export default SetOfferPurchaseOptions;
