import React, { FunctionComponent } from "react";
import { Button, Layout as AntLayout } from "antd";
import { Link } from "react-router-dom";
import { PanelPageLayoutRoute } from "providers/routing/layouts/templates/panel/panel";
import StandardButton from "components/common/StandardButton";

const PanelPageLayout: FunctionComponent<PanelPageLayoutRoute> = ({
  title,
  button,
  children,
  subtitle,
  sidebar = false,
  fullHeight = false,
  mainHasBackground = true,
}) => (
  <>
    <div className="panel-heading">
      <h1 className="route-name">{title}</h1>
      {subtitle && <p className="underline"> {subtitle} </p>}
      {button && (
        <div>
          <Link to={button.url}>
            <StandardButton
              data-testid="panelPageHeadingBtn"
              className="header-button"
              type="primary"
              ghost
            >
              {button.label}
            </StandardButton>
          </Link>
        </div>
      )}
    </div>

    <div
      className={`main${fullHeight ? " full-height" : ""}${
        sidebar ? " has-sidebar" : ""
      }`}
      style={
        !mainHasBackground
          ? {
              padding: 0,
              background: "transparent",
              boxShadow: "none",
            }
          : undefined
      }
    >
      {children}
    </div>
    {sidebar && <div className="sidebar">{sidebar}</div>}
  </>
);

export default PanelPageLayout;
