import FormattedMessage from "components/common/FormattedMessage"
import { ReactNode } from "react"
import { faBriefcase, faFamily, faFlower, faGamepad, faGear, faGraduationCap, faHeart, faIdCard, faIndustry, faMoneyBill, faPeople, faSchool } from "@fortawesome/pro-light-svg-icons";


export type KeywordGroup = 
"interests" | 
"behaviors" | 
"eduschool" | 
"edumajor" | 
"workemployer" | 
"workposition" | 
"education_statuses" | 
"education_schools" | 
"education_majors" | 
"work_positions" |
"work_employers" |
"family_statuses" | 
"relationship_statuses" | 
"life_events"
;

export type Keyword = {
    id: string,
    name: string,
    label?: string,
    group: KeywordGroup,
    description: string,
    type: string,
    audience_size: number,
    lower_bound: number,
    upper_bound: number,
    path: string[],
}

export type KeywordGroupConfig = {
    icon: any,
    value: string,
    displayName: ReactNode,
}

export const KeywordFacebookGroupsConfig: Record<KeywordGroup, KeywordGroupConfig> = {
    "interests": {
        icon: faHeart,
        value: "interests",
        displayName: <FormattedMessage
            id="components.keywords.groups.interests"
            defaultMessage="Interests"
        />
    },
    "behaviors": {
        icon: faGamepad,
        value: "behaviors",
        displayName: <FormattedMessage
            id="components.keywords.groups.behaviors"
            defaultMessage="Behaviors"
        />
    },
    "eduschool": {
        icon: faSchool,
        value: "eduschool",
        displayName: <FormattedMessage
            id="components.keywords.groups.eduschool"
            defaultMessage="School"
        />
    },
    "education_schools": {
        icon: faSchool,
        value: "education_schools",
        displayName: <FormattedMessage
            id="components.keywords.groups.eduschool"
            defaultMessage="School"
        />
    },
    "edumajor": {
        icon: faGraduationCap,
        value: "edumajor",
        displayName: <FormattedMessage
            id="components.keywords.groups.edumajor"
            defaultMessage="Major"
        />
    },
    "education_majors": {
        icon: faGraduationCap,
        value: "education_majors",
        displayName: <FormattedMessage
            id="components.keywords.groups.edumajor"
            defaultMessage="Major"
        />
    },
    "workemployer": {
        icon: faBriefcase,
        value: "workemployer",
        displayName: <FormattedMessage
            id="components.keywords.groups.workemployer"
            defaultMessage="Employer"
        />
    },
    "work_employers": {
        icon: faBriefcase,
        value: "work_employers",
        displayName: <FormattedMessage
            id="components.keywords.groups.workemployer"
            defaultMessage="Employer"
        />
    },
    "workposition": {
        icon: faIdCard,
        value: "workposition",
        displayName: <FormattedMessage
            id="components.keywords.groups.workposition"
            defaultMessage="Position"
        />
    },
    "work_positions": {
        icon: faIdCard,
        value: "work_positions",
        displayName: <FormattedMessage
            id="components.keywords.groups.workposition"
            defaultMessage="Position"
        />
    },
    "industries": {
        icon: faIndustry,
        value: "industries",
        displayName: <FormattedMessage
            id="components.keywords.groups.industries"
            defaultMessage="Industries"
        />
    },
    "education_statuses": {
        icon: faGraduationCap,
        value: "education_statuses",
        displayName: <FormattedMessage
            id="components.keywords.groups.edustatus"
            defaultMessage="Education Status"
        />
    },
    "family_statuses": {
        icon: faFamily,
        value: "family_statuses",
        displayName: <FormattedMessage
            id="components.keywords.groups.familyStatuses"
            defaultMessage="Family Status"
        />
    },
    "relationship_statuses": {
        icon: faPeople,
        value: "relationship_statuses",
        displayName: <FormattedMessage
            id="components.keywords.groups.relationshipStatuses"
            defaultMessage="Relationship Status"
        />
    },
    "life_events": {
        icon: faFlower,
        value: "life_events",
        displayName: <FormattedMessage
            id="components.keywords.groups.lifeEvents"
            defaultMessage="Life Events"
        />
    }
}

