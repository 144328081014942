import React, { PureComponent, ReactNode } from "react";
import { Drawer, Layout as AntLayout } from "antd";
import { RouteConfig } from "providers/routing/routes/routes";
import { Config, Layout } from "providers/routing/layouts/templates/templates";
import { connect } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import ProductLogo from "providers/routing/layouts/components/product-logo/current-product-logo";
import User from "providers/routing/layouts/templates/panel/top-bar/user";
import {
  userAvatarSelector,
  userNameSelector,
} from "modules/panel/config/selectors/user";
import PanelContent from "providers/routing/layouts/templates/panel/content";
import { SubscriptionConsumer } from "providers/subscription/subscription";
import { ClosePanelIcon } from "modules/panel/components/ft-icons";
import SiderMenu from "./SiderMenu";
import { WindowSizeConsumer } from "services/hooks/useWindowSize";
import NotificationsNavbarWidget from "modules/panel/components/notifications/NotificationsNavbarWidget";
import { companyDataSelector } from "modules/panel/state/companySlice";
import ProjectIntegrationDisplay from "./top-bar/project-select/ProjectIntegrationDisplay";
import ProjectsDrawer from "modules/panel/containers/projects/ProjectsDrawer";
import { panelProjectsSelector } from "state/projects/projectSelectors";
import AwesomeIcon from "components/common/AwesomeIcon";
import { faGrid, faGrid2 } from "@fortawesome/pro-light-svg-icons";
import { Project } from "state/projects/projectTypes";
import { ProjectSelect } from "./top-bar/project-select/ProjectSelect";

const { Sider } = AntLayout;

const DESKTOP_SIDER_WIDTH = 180;
const DESKTOP_COLLAPSED_SIDER_WIDTH = 90;

export type PanelPageLayoutRoute = {
  layout: Layout.Panel;
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  sidebar?: ReactNode;
  button?: {
    url: () =>
      | {
          pathname: string;
          state: { routeKey: string };
        }
      | string;
    label: string | ReactNode;
  };
  fullHeight?: boolean;
  mainHasBackground?: boolean;
} & RouteConfig;

//TODO: delete code associated with routeKey - we want to use built in Link features to mark current route!

type PanelLayutProps = {
  userName: string;
  avatar: string;
  company: any;
  projects: {
    current: {
      avatar: string;
      id: string;
    };
    list: Project[];
  };
  setProject: (projectId: string) => void;
} & RouteComponentProps<any, any, { routeKey: string; from: string }>;

type PanelLayoutState = {
  collapsedNav: boolean;
  mobileNavVisible: boolean;
};

class PanelLayout extends PureComponent<PanelLayutProps, PanelLayoutState> {
  constructor(props: PanelLayutProps) {
    super(props);

    this.state = {
      collapsedNav: true,
      mobileNavVisible: false,
    };
  }

  toggleNav = () => {
    this.setState({
      collapsedNav: !this.state.collapsedNav,
    });
  };

  closeNavIfOpened = () => {
    if (!this.state.collapsedNav)
      this.setState({
        collapsedNav: true,
      });
  };

  setMobileNavVisibility = (visible: boolean) => {
    this.setState({
      mobileNavVisible: visible,
    });
  };

  render = () => {
    
    const projectId = this.props.projects.current.id;
    
    return (
      <WindowSizeConsumer>
        {({ isMobile }) => {
          return (
            <div id="panel">
              <SubscriptionConsumer>
                {({ apps }) => (
                  <>
                    {!isMobile ? (
                      <Sider
                        className="desktop-menu"
                        collapsed={this.state.collapsedNav}
                        width={DESKTOP_SIDER_WIDTH}
                        collapsedWidth={DESKTOP_COLLAPSED_SIDER_WIDTH}
                        trigger={null}
                        collapsible
                      >
                        <Link to="/panel/dashboard">
                          <ProductLogo hideTitle={true} />
                        </Link>
                        <SiderMenu
                          apps={apps}
                          isMobile={isMobile}
                          selected={"selected"}
                          onClick={this.closeNavIfOpened}
                        />
                        <div className="close-menu">
                          <AwesomeIcon icon={faGrid2} onClick={this.toggleNav} />
                        </div>
                      </Sider>
                    ) : (
                      <Drawer
                        className="mobile-menu"
                        visible={this.state.mobileNavVisible}
                        closable={false}
                        width={280}
                        onClose={() => this.setMobileNavVisibility(false)}
                        placement="left"
                      >
                        {/* <ProjectSelect
                          projects={this.props.projects.list}
                          currentProjectId={this.props.projects.current.id}
                        /> */}
                        <ProjectSelect/>
                        <SiderMenu
                          apps={apps}
                          isMobile={isMobile}
                          onClick={() => this.setMobileNavVisibility(false)}
                          selected={"selected"}
                        />
                      </Drawer>
                    )}
                  </>
                )}
              </SubscriptionConsumer>
              <div className="panel-container">
                <div className="panel-top-bar">
                  {!isMobile ? (
                    <>
                    <div className="project-settings-indicator">
                      <ProjectIntegrationDisplay
                        projectId={projectId}
                      />
                      </div>
                      {/* <ProjectSelect
                        projects={this.props.projects.list}
                        currentProjectId={this.props.projects.current.id}
                      /> */}
                      <ProjectSelect/>
                      <User
                        name={this.props.userName}
                        companyName={this.props.company.name}
                        avatar={this.props.avatar}
                      />
                      
                      <NotificationsNavbarWidget />
                      <ProjectsDrawer />
                    </>
                  ) : (
                    <AwesomeIcon
                      icon={faGrid2}
                      size="2x" 
                      onClick={() =>
                        this.setMobileNavVisibility(
                          !this.state.mobileNavVisible
                        )
                      }
                    />
                  )}
                </div>
                <div className="panel-content">{this.props.children}</div>
              </div>
            </div>
          );
        }}
      </WindowSizeConsumer>
    );
  };
}

const mapStateToProps = (state: any) => {
  return {
    userName: userNameSelector(state),
    company: companyDataSelector(state),
    projects: panelProjectsSelector(state),
    avatar: userAvatarSelector(state),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    
  };
};

export default {
  key: Layout.Panel,
  layout: withRouter(connect(mapStateToProps, mapDispatchToProps)(PanelLayout)),
  content: PanelContent,
} as Config;
