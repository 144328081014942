import { Col, Grid, Row } from "antd";
import DisplayIfHasPermission from "components/common/DisplayIfHasPermission";
import { StandardBoxes } from "components/layout/StandardBoxes";
import { StandardHalfFullColumn, StandardRow } from "components/layout/StandardGrid";
import { DomainBox } from "./Domain";
import Pixel, { PixelBox } from "./Pixel";
import { TrackingWebsiteBox } from "./TrackingWebsite";

export default function Tracking() {


    const { lg } = Grid.useBreakpoint();

    return (
        <StandardRow>
            <DisplayIfHasPermission permission={["use module panel tracking pixel"]}>
                <StandardHalfFullColumn>
                        <PixelBox />
                </StandardHalfFullColumn>
            </DisplayIfHasPermission>
            
            <DisplayIfHasPermission permission={["use module panel tracking domain"]}>
                <StandardHalfFullColumn>
                        <TrackingWebsiteBox />
                        <DomainBox />
                </StandardHalfFullColumn>
            </DisplayIfHasPermission>

        </StandardRow>
    )

}