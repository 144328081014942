import useSimpleBreakpoint from "services/hooks/useSimpleBreakpoint";
import { useMemo } from "react";
import { CalculateGridStyle } from "tools/infinityList/infinityTemplates";
import { isFirefox } from "react-device-detect";
import { DefaultColumnsInvoices } from "./InvoiceHeadings";
import TemplateRow from "tools/infinityList/TemplateRow";


const InvoiceListEntry = ({ item }: { item: any }) => {

    const columns = DefaultColumnsInvoices;

    return (
        <div className={`infinity-item-wrapper`}>
        <div className="ant-collapse post-entry-content">
        <TemplateRow size = "medium" item={item} columns={columns}  />
        </div>
        </div>

    );
};

export default InvoiceListEntry;

