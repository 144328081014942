
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { currentFlatSubscriptionPermissionsSelector } from "state/subscriptions/subscriptionsSelectors";


export type DisplayIfHasPermissionProps = {
    permission: string | string[] | (() => (string | string[]));
    children?: React.ReactNode | React.ReactNode[];
}

export default function DisplayIfHasPermission(props: DisplayIfHasPermissionProps) {
    const permissions = useSelectorWithParams(currentFlatSubscriptionPermissionsSelector);

    const required = useMemo(() => {
        if (typeof props.permission === "function") {
            return props.permission();
        }
        return props.permission;
    }, [props.permission]);

    const visible = useMemo(() => {
        if (!permissions) return false;
        if (Array.isArray(required)) {
            return required.some((permission) => permissions && permissions.includes(permission));
        } 
        return permissions.includes(required);
    },[permissions, required]);

    return <>{visible && props.children}</>;
}

