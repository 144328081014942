//import SuperPromoPage from "components/superPromo/SuperPromoPage";

import DisplayIfHasPermission from "components/common/DisplayIfHasPermission";
import { USER_DELETION_PAGE, getUrl } from "modules/panel/config/routes";
import { Redirect } from "react-router-dom";

export default function Dashboard(props: any) {

  if (localStorage.getItem("userWantsToDeleteAccount") === "true") {
    return (
      <Redirect
        to={{
          pathname: getUrl(USER_DELETION_PAGE),
        }}
      />
    );
  }

  return (
    <>
      <DisplayIfHasPermission permission="use module smart creations">
        <Redirect to="/smart/creations" />
      </DisplayIfHasPermission>
      <DisplayIfHasPermission permission="use module smart posts">
        <Redirect to="/smart/posts/list" />
      </DisplayIfHasPermission>
      <DisplayIfHasPermission permission="use module call phones">
        <Redirect to="/call/project/phones" />
      </DisplayIfHasPermission>
    </>
  );
}
