import { faPlusCircle } from "@fortawesome/pro-light-svg-icons";
import AwesomeIcon from "components/common/AwesomeIcon";
import FormattedMessage from "components/common/FormattedMessage";
import StandardButton from "components/common/StandardButton";
import TranslationProvider, { useTranslation } from "providers/languages/TranslationProvider";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import useCallAfterUpdate from "services/hooks/useCallAfterUpdate";
import { StandardDrawer, useStandardDrawer } from "services/hooks/useDrawer";
import { StandardModal } from "services/hooks/useModal";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { userSettingsInterfaceLanguageSelector } from "state/user/userSettingsSlice";
import { IntlShape, RawIntlProvider, createIntl, createIntlCache } from "react-intl";
import HelpPanelDocument from "./HelpPanelDocument";
import { useHistory, useLocation } from "react-router";
import HelpPanelSearch from "./HelpPanelSearch";

export type HelperDrawerRef = {
    openHelpPanel: (page: string, params?: any) => void;
    closeHelpPanel: () => void;
}

export const isValidHelpKey = (str: string) => {
    if (!str) return false;
    if (str.length === 0) return false;
    if (str.length > 100) return false;
    for (let i = 0; i < str.length; i++) {
        let ascii = str.charCodeAt(i);

        if (!( (ascii >= 48 && ascii <= 57)  || // Numbers
               (ascii >= 65 && ascii <= 90)  || // Uppercase letters
               (ascii >= 97 && ascii <= 122) || // Lowercase letters
               (ascii == 46) )) {               // Dot symbol
            return false;
        }
    }
    return true;
}

export const HelpPanelDrawer = React.forwardRef((props: any, ref: any) => {


    const [drawerRef, open, close, isOpen] = useStandardDrawer();
    const assetHelperRef = useRef<HelperDrawerRef>(null);
    const [pageId, setPageId] = useState<string | null>(null);
    const {intl} = useHelpPanel();

    const location = useLocation();
    const searchQuery = new URLSearchParams(location.search);
    const helpPageFromURL = searchQuery.get("help");
    const wasEverOpened = useRef<boolean>(false);

    useEffect(() => {
        if (helpPageFromURL && helpPageFromURL !== pageId) setPageId(helpPageFromURL);
    }, [helpPageFromURL]);

    useEffect(() => {
        if (!intl) return;
        if (pageId && !wasEverOpened.current) {
            wasEverOpened.current = true;
            openHelpPanel(pageId);
        }
    }, [pageId, intl]);

    const history = useHistory();


    const openAfterUpdate = useCallAfterUpdate(() => {
        open();
    })


    const openHelpPanel = (page: string, params?: any) => {

        if (!intl) return;


        const pageDoc = intl?.messages?.[page]

        if (!pageDoc || page === "") {
            console.warn("No help page found for", page)
            return;
        } 

        if (isValidHelpKey(pageDoc as string)) {
            if (intl?.messages?.[pageDoc as string]) {
                openHelpPanel(pageDoc as string)
                return;
            }
        }

        let searchParams = new URLSearchParams(location.search);
        searchParams.set("help", page);
        history.push(`${location.pathname}?${searchParams.toString()}`);

        openAfterUpdate();
    }

    const closeHelpPanel = () => {
        let searchParams = new URLSearchParams(location.search);
        searchParams.delete("help");
        history.push(`${location.pathname}?${searchParams.toString()}`);
        close();
    }

    useImperativeHandle(ref, () => ({
        openHelpPanel,
        closeHelpPanel,
    }));

    if (!intl) return null;

    return (
        <>  
            <StandardDrawer
                ref={drawerRef}
                width={"600px"}
                layout="standard"
                mask={true}
                title={<FormattedMessage 
                    id={"help.drawer.title"} 
                    defaultMessage={"Help"}                    
                />}
                zIndex={1000}
                onClose={closeHelpPanel}
            >
                <HelpPanelSearch/>
                <HelpPanelDocument pageId={pageId}/>
            </StandardDrawer>
        </>
    );
});


const HelperGlobalContext = React.createContext({} as any);
HelperGlobalContext.displayName = "Helper Global Context";
export const useHelpPanel = () => React.useContext(HelperGlobalContext);

export default function HelperGlobal(props: any) {
    const assetHelperRef = useRef<HelperDrawerRef>(null);
    const intl = useTranslation({
        languageURL: import.meta.env.VITE_HELP_LANGUAGE_URL
    });

    const openHelpPanel = (id: string, content: string) => {
        console.log("openHelpPanel", id, content)
        assetHelperRef.current?.openHelpPanel(id, content);
    }

    const closeHelpPanel = () => {
        assetHelperRef.current?.closeHelpPanel();
    }

    return (
        <HelperGlobalContext.Provider value={{
            openHelpPanel,
            closeHelpPanel,
            intl
        }}>
            {props.children}
            <HelpPanelDrawer ref={assetHelperRef} />
        </HelperGlobalContext.Provider>
    );
}

