
import { useEffect, useState } from "react";
import useAPI, { callAPIProps } from "services/hooks/useAPI"
import { useDebounceTrigger } from "services/hooks/useDebounce";

type APIWithRetryProps = callAPIProps & {
    delay?: number, 
    enabled?: boolean,
    maxRetries?: number,
    isDataReady?: (data: any) => boolean,
}


const useAPIWithRetry = (props: APIWithRetryProps) => {

    const {call, data:checkData, loading:apiLoading, error} = useAPI({
        ...props, 
    })
    const [retries, setRetires] = useState(5);
    const [isEnabled, setIsEnabled] = useState(props.enabled || false);
    const [data, setData] = useState<any>();
    const [loading, setLoading] = useState(props.enabled || false);

    const isReady = () => {
        if (!checkData) return false;
        if (props.isDataReady) return props.isDataReady(checkData);
        return checkData.ready !== false;
    }

    const handleCall = () => {
        if (props.maxRetries && retries > props.maxRetries) return;
        if (!isEnabled) return;

        if (!isReady()) call();
        
    }

    const [startCountdown] = useDebounceTrigger(handleCall, props.delay || 1000);

    useEffect(() => {

        if (!data && isReady()) {
            setData(checkData);
        }

      if (!isEnabled) return;
      if (checkData) {

        const isReady = props.isDataReady ? props.isDataReady(checkData) : checkData.ready !== false;

        if (!isReady) {

            if (props.maxRetries) {
                if (retries >= props.maxRetries) {
                    setIsEnabled(false);
                    return;
                }
            }

            setRetires(r => r = r + 1)
            startCountdown();
            
        } else {
            setLoading(false);
            setIsEnabled(false);
            setData(data);
            return;
        }

      } else {
        startCountdown();
      }
    }, [checkData, isEnabled])
    
    const enable = () => {
        setLoading(true);
        setRetires(0);
        setData(null);
        setIsEnabled(true);
        startCountdown();
    };

    return {enable, data, loading, error, checkData}

}

export default useAPIWithRetry;