import FormattedMessage from "components/common/FormattedMessage"
import { PixelBox } from "modules/panel/components/tracking/Pixel"
import ConnectedOnboardingControls from "providers/onboarding/controls"

export const OnboardingPixel = () =>{
    return(
        <>
         <h2>
          <FormattedMessage
            id="apps.smart.containers.onboarding.pixel.addTrackingCode.title"
            defaultMessage="Add tracking code to your website"
          />
        </h2>
        <p>
          <FormattedMessage
            id="apps.smart.containers.onboarding.pixel.addTrackingCode.description"
            defaultMessage="The pixel primary code monitors site activity by creating a reference point for measuring specific events. Copy the basic code below and paste it between the header tags on each page of the site:"
          />
        </p>
        <PixelBox/>
        <ConnectedOnboardingControls style={{ marginTop: "30px" }} /> 
        </>
    )
}