import { Component } from "react";
import * as Sentry from "@sentry/browser";
import AwesomeIcon from "components/common/AwesomeIcon";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { SentryCaptureError, sentryMonitor } from "services/sentry";


class FeatureErrorBoundry extends Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = { 
            hasError: false
        };
    }

    static getDerivedStateFromError(error: React.ErrorInfo) {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        SentryCaptureError(error, errorInfo);
    }

    render() {

        if (this.state.hasError) {
            return (
                <div style={{
                    display: "grid",
                    placeItems: "center",
                    height: "100%",
                    width: "100%",
                }}>
                    <div style={{
                        display: "grid",
                        placeItems: "center",
                    }}>
                    <AwesomeIcon icon={faExclamationTriangle} size="3x" color="red"/>
                    <h1>There was an error while rendering this component.</h1>
                    <h2>It has been reported to our development team.</h2>
                    <div>ErrorId: {sentryMonitor.lastEventId}</div>
                    </div>
                </div>
            )
        }

        return this.props.children;
    }

}

export default FeatureErrorBoundry;