import useSelectorWithParams from "services/hooks/useSelectorWithParams"
import FormattedMessage from "components/common/FormattedMessage"
import { CarserviceCategories } from "../websitePages/CarserviceCategories"
import ConnectedOnboardingControls from "providers/onboarding/controls"
import { UpdateWebsite, userHasFilledBrandsSelector, userHasFilledCarserviceCategoriesSelector, userHasFilledManufacturersSelector, userHasFilledTiresSelector, WebsiteCarserviceBrandsSelector, WebsiteCarserviceManufacturersSelector, WebsiteCarserviceTiresSelector } from "modules/site-generator/reducers/websiteSlice"
import useAPI, { callAPIProps } from "services/hooks/useAPI"
import { ReactNode } from "react"
import { HorizontalCenter } from "components/common/Center"
import { Spin } from "antd"
import { Title } from "components/common/StandardText"
import RelaxField from "tools/relaxForm/RelaxField"
import { CarServiceCategoryInput } from "../brandsAndManufacturers/CarsServiceCategoryInput"
import { CarserviceBrandsDefinitionsSelector, GetBrands, GetManufacturers, CarserviceManufacturersRestDefinitionsSelector, ManufacturersRestSelectorIDs, CarserviceManufacturersTiresDefinitionsSelector, ManufacturersTiresSelectorIDs } from "../brandsAndManufacturers/CarsSlice"
import { rulesetArrayMin } from "tools/relaxForm/relaxPresets"
import { CarserviceBrands, CarserviceManufacturers, CarserviceTires } from "../websitePages/CarserviceBrandsAndManufacturers"

export const OnboardingCarserviceCategories = (props: any) => {

    const fulfilled = useSelectorWithParams([userHasFilledCarserviceCategoriesSelector, props.min || 0]);

    return (
        <div>
            <h1>
                <FormattedMessage
                    id="apps.websites.containers.onboarding.categories.heading"
                    defaultMessage="Choose the services you perform."
                />
            </h1>
            <CarserviceCategories/>
            <ConnectedOnboardingControls relaxDontSubmit={true} nextDisabled={(!fulfilled)} />
        </div>
    )
}

export const OnboardingCarserviceBrands = () => {

    const definitionsCall = useAPI(GetBrands, true);
    const fulfilled = useSelectorWithParams(userHasFilledBrandsSelector);
    const definitions = useSelectorWithParams(CarserviceBrandsDefinitionsSelector)
    const checked = useSelectorWithParams(WebsiteCarserviceBrandsSelector)

    if (!definitions) return <HorizontalCenter><Spin /></HorizontalCenter>

    return (
        <div>
            <Title level={3}>
                <FormattedMessage
                    id="apps.websites.containers.onboarding.brands.heading"
                    defaultMessage="Choose the car brands that we service."
                />
            </Title>
            <CarserviceBrands />
            <ConnectedOnboardingControls relaxDontSubmit={true} nextDisabled={(!fulfilled)} />
        </div>
    )

}

export const OnboardingCarserviceManufacturers = () => {

    const fulfilled = useSelectorWithParams(userHasFilledManufacturersSelector);
    const definitions = useSelectorWithParams(CarserviceManufacturersRestDefinitionsSelector)

    if (!definitions) return <HorizontalCenter><Spin /></HorizontalCenter>

    return (
        <div>
            <Title level={3}>
            <FormattedMessage
                    id="apps.websites.containers.onboarding.manufacturers.heading"
                    defaultMessage="Choose the producers we work with."
                />
            </Title>
            <CarserviceManufacturers />
            <ConnectedOnboardingControls relaxDontSubmit={true} nextDisabled={(!fulfilled)} />
        </div>
    )

}

export const OnboardingCarserviceTires = () => {
    
    const fulfilled = useSelectorWithParams(userHasFilledTiresSelector);

    return (
        <div>
            <Title level={3}>
            <FormattedMessage
                    id="apps.websites.containers.onboarding.manufacturers.heading"
                    defaultMessage="Choose the producers we work with."
                />
            </Title>
            <CarserviceTires />
            <ConnectedOnboardingControls relaxDontSubmit={true} nextDisabled={(!fulfilled)} />
        </div>
    )

}


