import FormattedMessage from "components/common/FormattedMessage";
import { InfinityListSettingsCheckbox } from "tools/infinityList/infinityListSettingsCheckbox";

export const PostListAdvancedSettings = [
    {
        key: "filterType",
        label: (
                <FormattedMessage
                    id="posts.list.filtering.advancedSettings.filtering.type.label"
                    defaultMessage="Filter by type"/>
        ), 
        popupClassName: "post-list-advanced-settings-dropdown",
        children: [
            {
                key: "showPosts",
                label: (
                    <InfinityListSettingsCheckbox
                    message={
                        <FormattedMessage
                        id="posts.list.filtering.advancedSettings.showPosts.label"
                        defaultMessage="Show Posts"/>
                    }
                    path={["postListv2","showPosts"]}
                    />
                )

            },
            {
                key: "showAOA",
                label: (
                    <InfinityListSettingsCheckbox
                    message={
                        <FormattedMessage
                            id="posts.list.filtering.advancedSettings.showAOA.label"
                            defaultMessage="Show AOA"/>
                    }
                    path={["postListv2","showAOA"]}
                    />
                )
            },
        ]
    },
    {
        key: "filterStatus",
        label: (
                <FormattedMessage
                    id="posts.list.filtering.advancedSettings.filtering.status.label"
                    defaultMessage="Filter by status"/>
        ),
        popupClassName: "post-list-advanced-settings-dropdown",
        children: [
            {
                key: "showDeleted",
                label: (
                    <InfinityListSettingsCheckbox
                    message={
                        <FormattedMessage
                            id="posts.list.filtering.advancedSettings.showDeleted.label"
                            defaultMessage="Show deleted posts"/>
                    }
                    path={["postListv2","showDeleted"]}
                    />
                )
            },
            {
                key: "showHidden",
                label: (
                    <InfinityListSettingsCheckbox
                    message={
                        <FormattedMessage
                            id="posts.list.filtering.advancedSettings.showHidden.label"
                            defaultMessage="Show dark posts"/>
                    }
                    path={["postListv2","showHidden"]}
                    />
                )
            }
        ]
    },
]
