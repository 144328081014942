import { HorizontalCenter } from "components/common/Center";
import FormattedMessage from "components/common/FormattedMessage";
import { Title } from "components/common/StandardText";
import BuyNowCard from "components/purchases/BuyNowCard";
import { useEffect, useState } from "react";


function SubscriptionCancellationWinbackOffer() {

    const [timeLeft, setTimeLeft] = useState(60 * 5);

    //Timeleft is in seconds. Convert it to mm:ss
    const formatTime = (timeLeft: number) => {
        const minutes = Math.floor(timeLeft / 60);
        const seconds = timeLeft % 60;
        //pad with 0 if seconds is less than 10
        if (seconds < 10) {
            return `${minutes}:0${seconds}`;
        }
        return `${minutes}:${seconds}`;
    }

    //set a timer to update every second
    useEffect(() => {
        const timer = setInterval(() => {
            if (timeLeft <= 0) {
                clearInterval(timer);
                return;
            }
            setTimeLeft((prev) => prev - 1);
        }, 1000);
        return () => clearInterval(timer);
    }, []);



    return (
        <div>
            <Title>
                <FormattedMessage
                    id="panel.subscriptions.cancellation.cancellationSuccess"
                    defaultMessage="Your subscription has been cancelled."
                />
            </Title>
            <FormattedMessage
                    id="panel.subscriptions.cancellation.winback.offerDescription"
                    defaultMessage="We are sorry to see you go. We have a special offer for you to win you back."
                />
            <HorizontalCenter>
                <BuyNowCard product="Insta" offer={154} discount={50} />
            </HorizontalCenter>
            <Title level={3}>
                <FormattedMessage
                    id="panel.subscriptions.cancellation.winback.timer"
                    defaultMessage="Offer valid only for: {timeLeft}"
                    values={{
                        timeLeft: formatTime(timeLeft)
                    }}
                />


            </Title>
        </div>
    );
}

export default SubscriptionCancellationWinbackOffer;