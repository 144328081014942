import { Collapse, Divider, Space } from "antd";
import { HorizontalCenter } from "components/common/Center";
import StandardActorCard from "components/common/StandardActorCard";
import StandardButton from "components/common/StandardButton";
import { StandardHalfFullColumn, StandardRow } from "components/layout/StandardGrid";
import StandardAssetDrawer, { useAssetDrawer } from "providers/assetDrawer/StandardAssetDrawer";
import React, { useEffect, useImperativeHandle, useRef } from "react";
import useAPI, { callAPIProps } from "services/hooks/useAPI";
import useCallAfterUpdate from "services/hooks/useCallAfterUpdate";
import { StandardDrawer, useStandardDrawer } from "services/hooks/useDrawer";
import { useSimpleModalWithAction } from "services/hooks/useModal";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import OrganizationGroupSelect from "../components/OrganizationGroupSelect";
import { OrganizationProjectSelector } from "../data/organizationSlice";
import { GroupsOfProject } from "../tools/OrganizationToolHelpers";
import useOgranizationGroupHelper from "../tools/useOrganizationGroupHelper";
import GoogleMyBusinessCompanyInfoForm from "./GoogleMyBusinessCompanyInfoForm";
import GoogleMyBusinessReviews from "./GoogleMyBusinessReviews";


const ProjectPage = React.forwardRef((props:any, ref:any) => {

    const {asset, assetId} = useAssetDrawer();
    const {contextHolder, askToRemoveProjectFromGroup, addProjectToGroup} = useOgranizationGroupHelper();
    const selectedGroup = useRef<any>(null);

    return (
        <div style={{paddingTop: "20px"}}>
            <StandardRow>
                <Collapse style={{width: "100%"}}>
                    <Collapse.Panel header="Groups" key="groups">
                            <span>Current groups:</span>
                            <GroupsOfProject 
                            projectId={asset.id} 
                            closable={true}
                            onClose={(group:any) => askToRemoveProjectFromGroup(assetId, group.id)}
                            />
                            <div style={{height: "20px"}} />
                            <span>Add to group:</span>
                            <Space.Compact  style={{width: "100%"}}>
                                <OrganizationGroupSelect mode="single" onChange={(c:any) => selectedGroup.current = c}/>
                                <StandardButton onClick={() => addProjectToGroup(assetId, selectedGroup.current)}>Add</StandardButton>
                            </Space.Compact>
                        </Collapse.Panel>
                    <Collapse.Panel header="Google My Business" key="myBusiness">
                        <GoogleMyBusinessCompanyInfoForm projectId={asset.id} />
                    </Collapse.Panel>
                    <Collapse.Panel header="Reviews" key="Reviews">
                        <GoogleMyBusinessReviews projectId={asset.id} />
                    </Collapse.Panel>
                </Collapse>
            </StandardRow>
            {contextHolder}
        </div>
    )

})

export default ProjectPage;