import { RSAA, RSAACall } from "redux-api-middleware";

type ActionFn<Params, Response> = (
  params: Params,
  getState: () => any
) => ActionType<Params, Response>;

type ActionType<Params, Response> = {
  authorized?: boolean;
  normalizeJsonApiResponse?: boolean;
  modifyResponse?: ModifyResponseFn<Response>;
  requestPayload?: any;
  failurePayload?: any;
  readResponseMethod?: "text" | "json";
} & Omit<RSAACall, "types">;

type ModifyResponseFn<Response> = (response: Response) => any;

class Action<Params = any, Response = any> {
  actionName: string;
  actionFn: ActionFn<Params, Response>;

  type: {
    request: string;
    success: string;
    failure: string;
  };

  constructor(actionName: string, actionFn: ActionFn<Params, Response>) {
    this.actionName = actionName;
    this.actionFn = actionFn;

    this.type = {
      request: `${actionName}_REQUEST`,
      success: `${actionName}_SUCCESS`,
      failure: `${actionName}_FAILURE`,
    };
  }

  action = (params: Params) => this.getActionWrappedInTypes(params);

  private getActionWrappedInTypes = (params: Params) => {
    return (dispatch: any, getState: any) => {
      const { modifyResponse, requestPayload, failurePayload, readResponseMethod, ...action } =
        this.actionFn(params, getState);

      dispatch({
        [RSAA]: {
          ...action,
          types: this.getActionTypes(
            modifyResponse,
            requestPayload,
            failurePayload,
            readResponseMethod
          ),
        },
      });
    };
  };

  private getActionTypes = (
    modifyResponse?: ModifyResponseFn<Response>,
    requestPayload?: any,
    failurePayload?: any,
    readResponseMethod: "text" | "json" = "json"
  ) => {
    const actions = [];

    actions.push(
      requestPayload
        ? {
            type: this.type.request,
            payload: requestPayload,
          }
        : this.type.request
    );

    actions.push(
      modifyResponse
        ? {
            type: this.type.success,
            payload: async (action: any, state: any, res: any) => {
              try {
                const json = await res[readResponseMethod]();
                return modifyResponse(json);
              } catch (e) {
                return modifyResponse(undefined as any);
              }
            },
          }
        : this.type.success
    );

    actions.push(
      failurePayload ? {
        type: this.type.failure,
        payload: async (action: any, state: any, res: any) => {
          try {
            const json = await res[readResponseMethod]();
            return {response: json, failurePayload};
          } catch (e) {
            return failurePayload
          }
        }
      }
    : this.type.failure
    )
    return actions;
  };
}

export default Action;
