import { createSelector, createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { callAPIFunction, callAPIProps } from "services/hooks/useAPI";

type ThreadMessageAuthor = {
    name: string;
    email: string;
    id: string;
}

export type ThreadMessage = {
    message: string;
    from: ThreadMessageAuthor;
    created_time: string;
    id: string;
}

export type Thread = {
    id: string;
    messages: ThreadMessage[];
    participants: ThreadMessageAuthor[];
}

type ThreadSliceType = {
    threads: Record<string, Thread>,
}

const addOrUpdateThread = (state: ThreadSliceType, thread: Thread) => {
    state.threads[thread.id] = thread;
}

const addOrUpdateThreads = (state: ThreadSliceType, threads: Thread[]) => {
    threads.forEach(thread => {
        addOrUpdateThread(state, thread);
    })
}


const threadsSlice = createSlice({
    name: "threads",
    initialState: {
        threads: {},
    },
    reducers: {
        getThreads: (state, action) => {
            addOrUpdateThreads(state, action.payload.data)
        },
        getThread: (state, action) => {
            state.threads[action.payload.id] = action.payload;
        },
        updateThred: (state, action) =>{
            state.threads[action.payload.data.id] = action.payload.data
        }
    }
});

export const getThreads: callAPIProps = {
    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/fanpage/threads`),
    successDispatch: threadsSlice.actions.getThreads,
}

export const getThread: callAPIFunction = (threadId: string) => ({
    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/fanpage/threads/${threadId}`),
    successDispatch: threadsSlice.actions.getThread,
})

export const sendMessage: callAPIFunction = (threadId: string) => ({
    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/fanpage/threads/${threadId}/reply`),
    method: "POST",
    successDispatch: threadsSlice.actions.updateThred
})

export const threadsSelector = (state: { threads: ThreadSliceType }) => state.threads.threads;
export const threadByIdSelector = (state: { threads: ThreadSliceType }, threadId: string) => state.threads.threads[threadId];


export default threadsSlice;