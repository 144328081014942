import { ReactNode, useMemo } from "react";
import {
    Like,
    Love,
    Haha,
    Wow,
    Sad,
    Angry,
    TakeCare,
  } from "./ReactionIcons";
import { Popover, Tooltip } from "antd";
import ValueDisplayWithCounter from "components/common/ValueDisplayWithCounter";
  
export const ReactionsIcons:Record<string, JSX.Element> = {
    like: <Like />,
    love: <Love />,
    haha: <Haha />,
    wow:  <Wow />,
    sad: <Sad />,
    angry: <Angry />,
    takeCare: <TakeCare />,
  };

type ReactionsDisplayProps = {
    reactions:  {[key: string]: number};
}

const ReactionIcon = (props: {reaction: string, count?: number}) => {

    if (!ReactionsIcons[props.reaction]) return null;

    if (props.count !== undefined) return (
            <div 
            className="reaction-icon with-count"
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
                color: "black"
            }}
            >
                {props.count}{ReactionsIcons[props.reaction]}
            </div>
    )

    return (
        <div className="reaction-icon">
            {ReactionsIcons[props.reaction]}
        </div>
    )
}

export default function ReactionsDisplay (props:any) {


    const sortedReactions = useMemo(() => {
        if (!props.reactions) return null;
        if (Object.keys(props.reactions).length === 0) return null;
        //Convert props.reactions to an array of tuples containing the reaction and the number of times it was used
        const reactionsArray = Object.entries(props.reactions);
        //Sort the array by the number of times the reaction was used
        reactionsArray.sort((a:any, b:any) => b[1] - a[1]);

        //add missing reactions
        const reactionsKeys = Object.keys(props.reactions);
        const missingReactions = Object.keys(ReactionsIcons).filter((reaction) => !reactionsKeys.includes(reaction));
        missingReactions.forEach((reaction) => reactionsArray.push([reaction, 0]));
        
        return reactionsArray;

    }, [props.reactions])

    const sumOfReactions = useMemo(() => {
        if (!sortedReactions) return null;
        if (sortedReactions.length === 0) return null;
        //reduce sorted reactions to a single number
        return sortedReactions.reduce((a:any, b:any) => a + b[1], 0);
    }, [sortedReactions])


    const topReactions = useMemo(() => {
        if (!sortedReactions) return null;

        return (
        <div style={{
            display: "flex", 
            flexDirection: "row",
            gap: "1px"
        }}>
            <ReactionIcon key={"love"} reaction={"love"}/>
            <ReactionIcon key={"like"} reaction={"like"}/>
        </div>
        )

    }, [sortedReactions])

    const allReactions = useMemo(() => {

        if (!sortedReactions) return null;
        if (sortedReactions.length === 0) return null;

        return sortedReactions.map((reaction:any) => <ReactionIcon  key={reaction[0]} reaction={reaction[0]} count={reaction[1]}/>);

    }, [props.reactions])

    const allReactionsWrapper = useMemo(() => {
        if (!allReactions) return null;
        return (
            <div style={{
                display: "grid", 
                gridTemplateColumns: "auto",
            }}>
                {allReactions}
            </div>
        )
    }, [allReactions])

    return (
        <Popover 
            content={allReactionsWrapper}
            >
        <div style={{
            display: "flex",
            width: "fit-content",
            flexDirection: "column",
            placeItems: "center",
        }}>
            <ValueDisplayWithCounter value={sumOfReactions} parser={(value) => value.toFixed(0)}/>{topReactions}
        </div>
        </Popover>
    )

}