import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { Project } from "./projectTypes";
import { subscriptionsSlice } from "state/subscriptions/subscriptionsSlice";
import { extractIds } from "services/helpers/js-helpers";


const adjustProject = (project: Project) => {
    if (!project.subscriptions) project.subscriptions = [];
    if (project.product_subscriptions) project.subscriptions = project.product_subscriptions.map((s:any) => typeof s === "object" ? s.id : s);
    return {...project, id: String(project.id)};
}

const addOrUpdateProject = (state: Record<string, Project>, project: Project) => {
    if (!project) return;
    if (!project.id) return;

    const existingProject = state[project.id];

    if (existingProject) {
        state[project.id] = adjustProject({...existingProject, ...project});
        return;
    } 

    state[project.id] = adjustProject(project);
}


export const projectsSlice = createSlice({
    name: 'projectsSlice',
    initialState: {} as Record<string, Project>,
    reducers: {
        getProjects: (state, action: PayloadAction<Project[] | Record<string, Project>>) => {
            if (Array.isArray(action.payload)) {
                action.payload.forEach(project => {
                    addOrUpdateProject(state, project);
                });
            } else {
                Object.values(action.payload).forEach(project => {
                    addOrUpdateProject(state, project);
                });
            }
        },
        getProject: (state, action: PayloadAction<{data: Project}>) => {
            addOrUpdateProject(state, action.payload.data);
        },
        deleteProject: (state, action: PayloadAction<any>) => {
            delete state[action.payload.data.projectId];
        },
        newProject: (state, action: PayloadAction<Project>) => {
            addOrUpdateProject(state, action.payload);
        },
        updateProjectStatus: (state, action: PayloadAction<{projectId: string, status: string}>) => {
            if (state[action.payload.projectId]) {
                state[action.payload.projectId].status = action.payload.data.status;
            }
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase("LOAD_USER_SUCCESS", (state, action:any) => {
            if (action.payload.projects) {

                //User sends projects without subscriptions - but sends subscriptions separately.
                //Find which subscriptions are attached to the projects and add them to the projects.

                const cProjects:Record<string, any> = _.cloneDeep(action.payload.projects);
                const cSubscriptions:Record<string, any> = _.cloneDeep(action.payload.product_subscriptions);
 
                const projectsMappedToSubscriptions = Object.values(cProjects).map((project:any) => {
                    project.subscriptions = Object.values(cSubscriptions).filter((subscription:any) => subscription.projects && subscription.projects.includes(project.id)).map((subscription:any) => subscription.id);
                    return project;
                });
                

                Object.values(cProjects).forEach(project => {
                    const thisProjectSubscriptions = projectsMappedToSubscriptions.find((p:any) => p.id === project.id)?.subscriptions || [];
                    addOrUpdateProject(state, {...project as Project, subscriptions: thisProjectSubscriptions});
                });
            }
        })
        .addCase("LOGOUT_SUCCESS", (state, action:any) =>{
            Object.keys(state).forEach(key => {
                delete state[key];
            });
        })
        .addCase("PROJECT_UPDATE_CURRENT_SUCCESS", (state, action:any) => {
            addOrUpdateProject(state, action.payload);
        })
        // .addCase("PROJECT_CREATE_SUCCESS", (state, action:any) => {
        //     addOrUpdateProject(state, action.payload);
        // })
        // .addCase("PROJECT_DELETE_SUCCESS", (state, action:any) => {
        //     delete state[action.payload.projectId];
        // })
        .addCase(subscriptionsSlice.actions.newSubscription, (state, action:any) => {
            const projectId = String(action?.payload?.request?.project_id);
            const subscriptionId = String(action?.payload?.data?.subscription?.id);
            if (projectId && subscriptionId) {
                if (!state[projectId].subscriptions) state[projectId].subscriptions = [subscriptionId];
                if (!state[projectId].subscriptions.includes(subscriptionId)) state[projectId].subscriptions.push(subscriptionId);
            }
        })
        .addCase("GET_PROJECTS_SUCCESS", (state, action:any) => {
            if (action.payload.projects) {
                action.payload.projects.forEach((project:any) => {
                    addOrUpdateProject(state, project);
                });
            }
        })
        .addCase(subscriptionsSlice.actions.attachSubscriptionToProject, (state, action) => {

            if (action.payload) {
                const pid = action.payload.data.id;
                const prodSubArr = action.payload.data.productSubscriptions || action.payload.data.product_subscriptions || [];
                const prodSubs = extractIds(prodSubArr);
                if (state[pid]) {
                    state[pid].subscriptions = prodSubs;
                }
            }
        })
        .addCase(subscriptionsSlice.actions.detachSubscriptionFromProject, (state, action) => {
            if (action.payload) {
                const pid = action.payload.data.id;
                const prodSubArr = action.payload.data.productSubscriptions || action.payload.data.product_subscriptions || [];
                const prodSubs = extractIds(prodSubArr);
                if (state[pid]) {
                    state[pid].subscriptions = prodSubs;
                }
            }
        })
    }
});

