import { faEllipsis } from "@fortawesome/pro-light-svg-icons";
import { Dropdown } from "antd";
import AwesomeIcon from "components/common/AwesomeIcon";
import FormattedMessage from "components/common/FormattedMessage";
import StandardButton from "components/common/StandardButton";
import { Link } from "react-router-dom";
import { useMemo } from "react";
import { useSubscriptionContext } from "./SubscriptionContext";

const SubscriptionDetailsDropdown = (props: any) => {

    const {subscription, openDetails} = useSubscriptionContext();

    const dropdownMenuItems = useMemo(() => {

        if (!subscription) {
            return [];
        }

        const items = [
            {
                key: "details", label: (
                    <Link to={`/panel/subscriptions/${subscription?.id}`}>
                        <FormattedMessage
                            id="panel.profile.subscriptions.details"
                            defaultMessage="Details"
                        />
                    </Link>
                ),
                disabled: true,
            },
            {
                key: "extend", label: (
                    <Link to={`/panel/subscriptions/${subscription?.id}`}>
                        <FormattedMessage
                            id="panel.profile.subscriptions.extend"
                            defaultMessage="Extend"
                        />
                    </Link>
                ),
                disabled: true,
            }
        ];


        if (subscription.status !== "cancelled") {
            items.push({
                key: "cancel",
                // label: 
                // (
                //     <div onClick={(e) => {openDetails("cancel")}}>
                //     <FormattedMessage
                //         id="panel.profile.subscriptions.cancel"
                //         defaultMessage="Cancel"
                //     />
                //     </div>
                // ),
                label: 
                (
                    <a href="mailto:support@fasttony.com">
                    <FormattedMessage
                        id="panel.profile.subscriptions.cancel"
                        defaultMessage="Cancel"
                    />
                    </a>
                ),
                disabled: false
            });
        }

        return items;

    }, [subscription]);

    return (
        <>
            <Dropdown
                menu={{
                    items: dropdownMenuItems
                }}
                placement="bottomLeft"
            >
                <StandardButton type="link" size="small" onClick={(e) => e.stopPropagation()}>
                    <AwesomeIcon icon={faEllipsis} size="xl"/>
                </StandardButton>
            </Dropdown>
        </>
    )


}

export default SubscriptionDetailsDropdown;