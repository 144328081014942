import { ONBOARDING_WE_WILL_CONTACT_U } from "modules/panel/config/routes";
import { getUrl as getPanelUrl } from "modules/panel/config/routes";
import { userHasPhoneSelector } from "modules/panel/config/selectors/user";

export default [
  {
    // user have phone and phone_verified_at
    key: "weWillContactU",
    path: () => getPanelUrl(ONBOARDING_WE_WILL_CONTACT_U),
    selector: userHasPhoneSelector,
    // component: <AuthorizationSuccessOnboarding/>
    // phone, phone_verified_at
  },
];
