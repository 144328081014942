import { Col, Row, Button } from "antd";
import { SupportTier, SupportTierOffer, SupportFeature, supportFeatureNames, supportTierNames, supportConfig } from "modules/smart/config/supportConfig";
import FormattedMessage from "components/common/FormattedMessage";
import { faCheck, faCheckCircle, faXmarkCircle } from "@fortawesome/pro-light-svg-icons";
import AwesomeIcon from "components/common/AwesomeIcon";
import StandardButton from "components/common/StandardButton";
import './company.less';


export interface SupportCardProps {
  tier: SupportTier
}


const SupportCard = (props: SupportCardProps) => {

  const SupportCheckMark = (feature:SupportFeature) => {
    if (supportConfig[props.tier][feature]) {

      return (<AwesomeIcon icon={faCheckCircle} className="checkmark" color="blue"/>)
    } 
    return (<AwesomeIcon icon={faXmarkCircle} className="checkmark" color="grey"/>)
  }

  function getPrice() {
    const p = supportConfig[props.tier].price
    if (p === 0) {
      return (
    <div className="price">
        <FormattedMessage 
        id="apps.panel.support.price.free" 
        defaultMessage = "FREE"/>
    </div>)
    }
    return (
    <div className="price">
        {p}zł
        </div>
    )
  }

  function getPhoneTime() {
    if (!supportConfig[props.tier].contactPhone || supportConfig[props.tier].contactPhoneLimit === 0) {
      return
    }
    return (
      <>
    {" ("}<FormattedMessage id="apps.panel.support.feature.conatctPhoneLimit" defaultMessage = "Hours"/>:{supportConfig[props.tier].contactPhoneLimit}{")"}
    </>
    )
  }

  function getFeature(feature: SupportFeature) {
    return (
      <div className="feature">
        {SupportCheckMark(feature)}
        {supportFeatureNames[feature]}
        {(feature === SupportFeature.contactPhone) && getPhoneTime()}
      </div>
    )
  }

  return (
    <Col span = {8}  xs={24} md={24} lg={8}>
    <div className="support-card"> 
    <div className="tier">
      {supportTierNames[props.tier]}
    </div>
      {getPrice()}
      <div className="duration">
        <FormattedMessage id="apps.panel.support.duration" defaultMessage = "Monthly"/>
      </div>  
      <div className="detail"><FormattedMessage id="apps.panel.support.areaOfSupport" defaultMessage = "Area of support"/></div>
      {getFeature(SupportFeature.technicalSupport)}
      {getFeature(SupportFeature.accountAndPayments)}
      {getFeature(SupportFeature.contentAndCommunication)}
      {getFeature(SupportFeature.budgetOptimization)}
      <div className="detail"><FormattedMessage id="apps.panel.support.supportChannel" defaultMessage = "Support via"/></div>
      {getFeature(SupportFeature.contactMessenger)}
      {getFeature(SupportFeature.contactEmail)}
      {getFeature(SupportFeature.contactPhone)}
      {getFeature(SupportFeature.fullTime)}
      <div className="purchaseButton">
        {props.tier === "basic" ? (<StandardButton type="primary" disabled className="active">
        <FormattedMessage id="apps.panel.support.active" defaultMessage = "Active"/><br/>
      </StandardButton>) : 
      (<StandardButton type="primary" disabled>
        <FormattedMessage id="apps.panel.support.purchase" defaultMessage = "Activate"/><br/>
      </StandardButton>)
      }
      </div>
    </div>
    </Col>
  )
}

const SupportOffer = (props:any) => {


  return (
      <Row gutter={[24,24]} justify="center">
        <SupportCard key="basic" tier={SupportTier.basic} />
        <SupportCard key="advanced"  tier={SupportTier.advanced} />
        <SupportCard key="concierge"  tier={SupportTier.concierge} />
      </Row>
  )


}

export default SupportOffer;


// {SupportCheckMark(SupportFeature.technicalSupport)}<FormattedMessage id="apps.panel.support.feature.technicalSupport" defaultMessage = "Technical Support"/><br/>
// {SupportCheckMark(SupportFeature.accountAndPayments)}<FormattedMessage id="apps.panel.support.feature.accountAndPayments" defaultMessage = "Account and payments"/><br/>
// {SupportCheckMark(SupportFeature.contentAndCommunication)}<FormattedMessage id="apps.panel.support.feature.contentAndCommunication" defaultMessage = 'Content and communication'/><br/>
// {SupportCheckMark(SupportFeature.budgetOptimization)}<FormattedMessage id="apps.panel.support.feature.budgetOptimization" defaultMessage = "Budget optimization, peformance"/><br/>
// <div className="detail"><FormattedMessage id="apps.panel.support.supportChannel" defaultMessage = "Support via"/></div>
// {SupportCheckMark(SupportFeature.contactMessenger)}<FormattedMessage id="apps.panel.support.feature.contactMessenger" defaultMessage = "Messenger chat"/><br/>
// {SupportCheckMark(SupportFeature.contactEmail)}<FormattedMessage id="apps.panel.support.feature.contactEmail" defaultMessage = "E-mail"/><br/>
// {SupportCheckMark(SupportFeature.contactPhone)}<FormattedMessage id="apps.panel.support.feature.contactPhone" defaultMessage = "Phone"/>{getPhoneTime()}<br/>
// {SupportCheckMark(SupportFeature.fullTime)}<FormattedMessage id="apps.panel.support.feature.fullTime" defaultMessage = "24/7 concierge"/><br/>
