import { useMemo, useRef } from 'react';
import { isEqual } from 'lodash';

function useMemoDeepCompare<T>(valueFactory: () => T, dependencies: any[]): T {
  const dependenciesRef = useRef(dependencies);

  if (!isEqual(dependencies, dependenciesRef.current)) {
    dependenciesRef.current = dependencies;
  }

  return useMemo(valueFactory, [dependenciesRef.current]);
}

export default useMemoDeepCompare;