import { ReactNode } from "react";

export function CenterdCell (props: { children: ReactNode, onClick?: any, style?: any}) {
    return (
        <div 
        onClick={() => props.onClick && props.onClick()}
        className="centered-cell" 
        style={{
            height: "100%",
            width: "100%",
            display: "grid",
            placeItems: "center",
            ...props.style,
        }}>
            {props.children}
            </div>
    )
}


export function LeftAlignedCell (props: { children: ReactNode, onClick?: any, style?: any}) {
    return (
        <div 
        onClick={() => props.onClick && props.onClick()}
        className="left-aligned-cell" 
        style={{
            height: "100%",
            width: "100%",
            display: "flex",
            placeItems: "center",
            justifyContent: "flex-start",
            ...props.style,
        }}>
            {props.children}
            </div>
    )
}