
import Icon from '@ant-design/icons';
import { Avatar, Select } from 'antd';
import { Language, LANGUAGES, LANGUAGE_OPTIONS } from 'modules/panel/config/LanguagesConfig';
import { useDispatch } from 'react-redux';
import { callAPIProps } from 'services/hooks/useAPI';
import useSelectorWithParams from 'services/hooks/useSelectorWithParams';
import userSettingsSlice, { userSettingsInterfaceLanguageSelector, userSettingsInterfaceSelector } from 'state/user/userSettingsSlice';
import RelaxSingleField from 'tools/relaxForm/RelaxField';


const LanguageDisplay = (props: { language: Language, flagOnly?: boolean }) => {

    const language = LANGUAGES[props.language];

    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyItems: "center",
            height: "100%",
            width: "100%",
        }}>
            <Icon
                className='flag'
                component={language.flag}
            />
            {
                props.flagOnly ? null : (
                    <span
                        className='language-name'
                    >
                        {language.label}
                    </span>
                )}
        </div>
    )
}



export const LanguageSelect = (props: any) => {

    const mappedOptions = Object.values(LANGUAGES).map((lang) => ({
        ...lang,
        value: lang.baseCode,
        label: <LanguageDisplay language={lang.baseCode} flagOnly={props.flagOnly} />
    }));

    return (
        <Select
            className={`language-select ${props.flagOnly ? "flag-only" : ""}`}
            style={{ width: props.flagOnly ? "60px" : "200px" }}
            dropdownMatchSelectWidth={props.flagOnly ? 60 : true}
            options={mappedOptions}
            showArrow={props.flagOnly ? false : true}
            value={props.value}
            onChange={props.onChange}
            onBlur={props.onBlur}
        />
    )

}


const PatchUser: callAPIProps = {
    url: ({ getApiUrl, projectId }) => getApiUrl(`user`),
    method: "PUT",
    dispatchName: "USER_DATA_SET"
}

export const LanguageSelectInput = (props: any) => {

    return (
        <RelaxSingleField
            name="language"
            submitOnChange={true}
            callAPI={PatchUser}
            initialValuesSelector={userSettingsInterfaceSelector}
            style={{
                paddingBottom: "0px"
            }}
        >
            <LanguageSelect flagOnly={props.flagOnly} />
        </RelaxSingleField>
    )

}

export const LocalLanguageSelectInput = (props: any) => {

    const lang = useSelectorWithParams(userSettingsInterfaceLanguageSelector)
    const dispatch = useDispatch();

    const handleChange = (l: string) => {
        dispatch(userSettingsSlice.actions.changeLanguage({ language: l }))
    }

    return (
        <LanguageSelect
            flagOnly={props.flagOnly}
            value={lang}
            onChange={handleChange}
            hideLabel={true}
        />
    )

}