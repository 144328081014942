import { LayoutRouteConfig } from "providers/routing/layouts/layouts";
import Helper from "providers/routing/routes/helper";
import { Layout } from "providers/routing/layouts/templates/templates";
import FormattedMessage from "components/common/FormattedMessage";

import { OnboardingHorizontalAds, OnboardingSquareAds } from "../containers/onboarding/media/DisplayAds";
import { Headlines, ShortDescriptions, LongDescriptions } from "../containers/onboarding/TextLists";
import ManageCampaigns from "../containers/ManageCampaings";
import ForsantOnboardingBusinessDetails from "../containers/onboarding/ForsantOnboardingBusinessDetails";
import { OnboardingVideoAds } from "../containers/onboarding/media/VideoAds";
import { faChartSimple, faCog, faCogs, faGear, faSitemap } from "@fortawesome/pro-light-svg-icons";
import { AwesomeLarge } from "components/common/AwesomeIcon";
import OnboardingKeywordIdeas from "../components/keyword-ideas/OnboardingKeywordIdeas";
import OnboardingAudienceGoogle from "modules/smart/containers/onboarding/google/OnboardingAudienceGoogle";
import OrganizationManager from "tools/organization/OrganizationManager";
import OnboardingAdsAudience from "../containers/onboarding/OnboardingAdsAudience";
import GoogleConfig from "../components/google-config/GoogleConfig";
import GoogleStats from "../containers/GoogleStats";
import { OnboardingOpeningHours } from "../containers/onboarding/OnboardingOpeningHours";
import { OnboardingAdsInvitation } from "../containers/onboarding/verifiers/OnboardingAdsInvitation";
import { OnboardingPaymentMethod } from "../containers/onboarding/verifiers/OnboardingPaymentMethod";
import { OnboardingTrackingCode } from "../containers/onboarding/verifiers/OnboardingTrackingCode";
import { OnboardingBudget } from "../containers/onboarding/OnboardingBudget";

export const APP_PATH = "forsant";

export const MANAGE_CAMPAIGNS = "manage",
  ONBOARDING_GOOGLE_GEOLOCATION = "onboardingGoogleGeolocation",
  ONBOARDING_BUSINESS_DETAILS = "onboardingBusinessDetails",
  ONBOARDING_OPENING_HOURS = "onboardingOpeningHours",
  ONBOARDING_ADS_AUDIENCE = "onboardingAdsAudience",
  ONBOARDING_KEYWORD_IDEAS = "onboardingKeywordIdeas",
  ONBOARDING_HEADLINES = "onboardingHeadlines",
  ONBOARDING_SHORT_DESCRIPTIONS = "onboardingShortDescriptions",
  ONBOARDING_LONG_DESCRIPTIONS = "onboardingLongDescriptions",
  ONBOARDING_DISPLAY_ADS_HORIZONTAl = "onboardingDisplayAdsHorizontal",
  ONBOARDING_DISPLAY_ADS_SQUARE = "onboardingDisplayAdsSquare",
  ONBOARDING_VIDEO_ADS = "onboardingVideoAds",
  ONBOARDING_ADS_BUDGET = "onboardingAdsBudget",
  ONBOARDING_ADS_INVITATION = "onboardingAdsInvitation",
  ONBOARDING_PAYMENT_METHOD = "onboardingPaymentMethod",
  ONBOARDING_SUMMARY = "onboardingSummary",
  ORGANIZATION = "organization",
  CONFIG = "config",
  STATS = "stats";

const routes: LayoutRouteConfig[] = [
  {
    key: MANAGE_CAMPAIGNS,
    path: "/manage",
    icon: <AwesomeLarge icon={faGear} />,
    displayInMenu: true,
    layout: Layout.Panel,
    content: ManageCampaigns,
    mainHasBackground: true,
    name: (
      <FormattedMessage 
      id="apps.forsant.routing.manageCampaigns.title" 
      defaultMessage="Manage Campaigns" />
    ),
    title: <FormattedMessage 
      id="apps.forsant.routing.manageCampaigns.title" 
      defaultMessage="Manage Campaigns" />,
  },
  {
    key: STATS, 
    path: "/stats",
    displayInMenu: true,
    mainHasBackground: false,
    icon: <AwesomeLarge icon={faChartSimple} />,
    content: GoogleStats,
    layout: Layout.Panel,
    name: (
      <FormattedMessage 
      id="apps.forsant.routing.stats.title" 
      defaultMessage="Statistics" />
    ),
    title: <FormattedMessage 
      id="apps.forsant.routing.stats.title" 
      defaultMessage="Statistics" />,
  },
  {
    key: CONFIG, 
    path : "/config",
    displayInMenu: true,
    mainHasBackground: false,
    icon: <AwesomeLarge icon={faCogs} />,
    layout: Layout.Panel,
    content: GoogleConfig,
    name: (
      <FormattedMessage 
      id="apps.forsant.routing.googleConfig.title" 
      defaultMessage="Google Config" />
    ),
    title: <FormattedMessage 
      id="apps.forsant.routing.googleConfig.title" 
      defaultMessage="Google Config" />,
  },
  {
    key: ONBOARDING_GOOGLE_GEOLOCATION,
    path: "/onboarding/geolocation",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingAudienceGoogle,
    title: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.geolocation.title"
        defaultMessage="Google Ads Geolocation"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.geolocation.subtitle"
        defaultMessage="Tell us who your ideal customers are."
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.containers.onboarding.geolocation.sidebar.heading"
          defaultMessage="Geolocation"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.containers.onboarding.geolocation.sidebar.description"
          defaultMessage="Who knows your clients better than you. With location settings, you can target the geographic areas where your ads can appear."
        />
      ),
    },
  },
  {
    key: ONBOARDING_BUSINESS_DETAILS,
    path: "/onboarding/business-details",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: ForsantOnboardingBusinessDetails,
    title: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.businessDetails.title"
        defaultMessage="Business details"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.businessDetails.subtitle"
        defaultMessage="Complete survey to run your first ads."
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.containers.onboarding.businessDetails.sidebar.heading"
          defaultMessage="Business survey"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.containers.onboarding.businessDetails.sidebar.description"
          defaultMessage="It’s our business it to get known your business. Please, provide all necessary informations about your online marketing background."
        />
      ),
    },
  },
  {
    key: ONBOARDING_OPENING_HOURS,
    path: "/onboarding/opening-hours",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingOpeningHours,
    title: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.openingHours.title"
        defaultMessage="Opening hours"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.openingHours.subtitle"
        defaultMessage="What are your opening hours?"
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.containers.onboarding.openingHours.sidebar.heading"
          defaultMessage="Opening hours"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.containers.onboarding.openingHours.sidebar.description"
          defaultMessage="Let us know when your company is open. Based on this information, your ads will be delivered at the times you selected."
        />
      ),
    },
  },
  {
    key: ONBOARDING_ADS_AUDIENCE,
    path: "/onboarding/ads-audience",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingAdsAudience,
    //content: AdsAudience,
    title: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.adsAudience.title"
        defaultMessage="Google Ads Audience"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.adsAudience.subtitle"
        defaultMessage="WLet us know who your ideal customer is based on what your customers are looking for and your competitors websites."
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.containers.onboarding.adsAudience.sidebar.heading"
          defaultMessage="Audience"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.containers.onboarding.adsAudience.sidebar.description"
          defaultMessage="Who knows your clients better than you. Tell us who your ideal customers are."
        />
      ),
    },
  },
  {
    key: ONBOARDING_KEYWORD_IDEAS,
    path: "/onboarding/keyword-ideas",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingKeywordIdeas,
    //content: KeywordIdeas,
    title: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.keywordIdeas.title"
        defaultMessage="Keywords ideas"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.keywordIdeas.subtitle"
        defaultMessage="Select the target keywords to use in your ads."
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.containers.onboarding.keywordIdeas.sidebar.heading"
          defaultMessage="Keywords ideas"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.containers.onboarding.keywordIdeas.sidebar.description"
          defaultMessage="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel purus sed velit luctus iaculis."
        />
      ),
    },
  },
  {
    key: ONBOARDING_HEADLINES,
    path: "/onboarding/data/headlines",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: Headlines,
    //content: HeadlinesData,
    title: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.headlinesData.title"
        defaultMessage="Search Ads Headlines"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.headlinesData.subtitle"
        defaultMessage="Tell us who your ideal customers are."
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.containers.onboarding.headlinesData.sidebar.heading"
          defaultMessage="Headlines"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.containers.onboarding.headlinesData.sidebar.description"
          defaultMessage="The headline in Google Ads refers to the headings at the top of your text ads to help marketers promote their product or services."
        />
      ),
    },
  },
  {
    key: ONBOARDING_SHORT_DESCRIPTIONS,
    path: "/onboarding/data/short-descriptions",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: ShortDescriptions,
    title: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.shortDescriptions.title"
        defaultMessage="Search Ads Short Descriptions"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.shortDescriptions.subtitle"
        defaultMessage="Choose short descriptions for your ads."
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.containers.onboarding.shortDescriptions.sidebar.heading"
          defaultMessage="Short descriptions"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.containers.onboarding.shortDescriptions.sidebar.description"
          defaultMessage="The purpose of any description in your ads should be to both attract the right audience and deter the wrong audience."
        />
      ),
    },
  },
  {
    key: ONBOARDING_LONG_DESCRIPTIONS,
    path: "/onboarding/data/long-descriptions",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: LongDescriptions,
    title: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.longDescriptions.title"
        defaultMessage="Search Ads Long Descriptions"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.longDescriptions.subtitle"
        defaultMessage="Choose long descriptions for your ads."
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.containers.onboarding.longDescriptions.sidebar.heading"
          defaultMessage="Long descriptions"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.containers.onboarding.longDescriptions.sidebar.description"
          defaultMessage="The purpose of any description in your ads should be to both attract the right audience and deter the wrong audience."
        />
      ),
    },
  },
  {
    key: ONBOARDING_DISPLAY_ADS_HORIZONTAl,
    path: "/onboarding/media/display-ads/horizontal",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingHorizontalAds,
    //content: DisplayAdsHorizontalMedia,
    title: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.displayAdsHorizontalMedia.title"
        defaultMessage="Google Display Ads"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.displayAdsHorizontalMedia.subtitle"
        defaultMessage="Upload or select banners in 1.9:1 ratio."
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.containers.onboarding.displayAdsHorizontalMedia.sidebar.heading"
          defaultMessage="Google Display Ads"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.containers.onboarding.displayAdsHorizontalMedia.sidebar.description"
          defaultMessage="Display ads capture people’s attention across the Google Display Network’s millions websites and apps."
        />
      ),
    },
  },
  {
    key: ONBOARDING_DISPLAY_ADS_SQUARE,
    path: "/onboarding/media/display-ads/square",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingSquareAds,
    title: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.displayAdsSquareMedia.title"
        defaultMessage="Google Display Ads"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.displayAdsSquareMedia.subtitle"
        defaultMessage="Upload or select banners in 1:1 ratio."
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.containers.onboarding.displayAdsSquareMedia.sidebar.heading"
          defaultMessage="Google Display Ads"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.containers.onboarding.displayAdsSquareMedia.sidebar.description"
          defaultMessage="Display ads capture people’s attention across the Google Display Network’s millions websites and apps."
        />
      ),
    },
  },
  {
    key: ONBOARDING_VIDEO_ADS,
    path: "/onboarding/media/video-ads",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingVideoAds,
    title: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.videoAdsMedia.title"
        defaultMessage="Google Display Ads"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.videoAdsMedia.subtitle"
        defaultMessage="Upload or select videos to use in your ads."
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.containers.onboarding.videoAdsMedia.sidebar.heading"
          defaultMessage="Google Display Ads"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.containers.onboarding.videoAdsMedia.sidebar.description"
          defaultMessage="Video advertising on YouTube works, get started with online video advertising campaigns today."
        />
      ),
    },
  },
  {
    key: ONBOARDING_ADS_BUDGET,
    path: "/onboarding/budget",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingBudget,
    title: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.budget.title"
        defaultMessage="Google Ads Budget"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.budget.subtitle"
        defaultMessage="Set up your default campaign budget."
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.containers.onboarding.budget.sidebar.heading"
          defaultMessage="Budget setup"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.containers.onboarding.budget.sidebar.description"
          defaultMessage="How much wood would a woodchuck chuck, if a woodchuck could chuck wood?"
        />
      ),
    },
  },
  {
    key: ONBOARDING_ADS_INVITATION,
    path: "/onboarding/verify/ads-invitation",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingAdsInvitation,
    title: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.adsInvitationVerify.title"
        defaultMessage="Google Ads invitation"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.adsInvitationVerify.subtitle"
        defaultMessage="Accept the invitation in your mailbox."
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.containers.onboarding.adsInvitationVerify.sidebar.heading"
          defaultMessage="Accept invitation"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.containers.onboarding.adsInvitationVerify.sidebar.description"
          defaultMessage="To run ads you need advertising account. Please check your mail and accept our invitation to your new account."
        />
      ),
    },
  },
  {
    key: ONBOARDING_PAYMENT_METHOD,
    path: "/onboarding/verify/payment-method",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingPaymentMethod,
    title: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.paymentMethodVerify.title"
        defaultMessage="Payment method"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.paymentMethodVerify.subtitle"
        defaultMessage="Ad payment method to run ads"
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.containers.onboarding.paymentMethodVerify.sidebar.heading"
          defaultMessage="Payment method"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.containers.onboarding.paymentMethodVerify.sidebar.description"
          defaultMessage="The last step is to add the payment method to your advertising account. The process is absolutely safe."
        />
      ),
    },
  },
  {
    key: ONBOARDING_SUMMARY,
    path: "/onboarding/verify/tracking-code",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingTrackingCode,
    title: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.trackingCodeVerify.title"
        defaultMessage="Onboarding summary"
      />
    ),
    // subtitle: (
    //   <FormattedMessage
    //     id="apps.smart.containers.onboarding.trackingCodeVerify.subtitle"
    //     defaultMessage="Add tracking code to your website."
    //   />
    // ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.containers.onboarding.trackingCodeVerify.sidebar.heading"
          defaultMessage="It’s Endgame"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.containers.onboarding.trackingCodeVerify.sidebar.description"
          defaultMessage="This is the last step. Check what you still have to do to let the application work nicely."
        />
      ),
    },
  },
  {
    key: ORGANIZATION,
    path: "/organization",
    displayInMenu: true,
    layout: Layout.Panel,
    icon: <AwesomeLarge icon={faSitemap} />,
    content: OrganizationManager,
    mainHasBackground: false,
    title: (
      <FormattedMessage
        id="apps.smart.containers.organization.title"
        defaultMessage="Organization"
      />
    ),
  }
];

export default routes;

export const getUrl = (routeKey: string) =>
  Helper.getPath(APP_PATH, routes, routeKey);

export const getSubmenuUrl = (routeKey: string) =>
  Helper.getSubmenuUrl(APP_PATH, routes, routeKey);
