import React from "react";
import { Modal } from 'antd';
import FormattedMessage from "components/common/FormattedMessage";
import { TokenLocalStorage } from "services/helpers/local-storage";

export function handleUnauthorizedApiCallMiddleware({ dispatch, getState }) {
    return next => action => {

        if (action) {

        const { type, payload } = action;
        const matches = /(.*)_(FAILURE)/.exec(type);

        let state = getState();

        if (matches
            && state.loginStatus.get('logged')
            && payload
            && payload.status === 401) {

            Modal.warning({
                title: <FormattedMessage
                    id="services.middlewares.unathorized.title"
                    defaultMessage="You have been logged out"
                />,
                content: <FormattedMessage
                    id="services.middlewares.unathorized.subtitle"
                    defaultMessage='due to inactivity. Please log in to continue your work'
                />,
                centered: true,
                closable: false,
                okText: <FormattedMessage
                    id="services.middlewares.unathorized.logIn"
                    defaultMessage="Log in"
                />,
                onOk() {
                    TokenLocalStorage.set(null);
                    setTimeout(() => window.location.href = "/base/login", 300);
                },
            });
        }
        }

        next(action);
    }
}

export default handleUnauthorizedApiCallMiddleware;