import { ProductConfig } from "products";
import logo from "products/call/logo.svg";
import requirements from "modules/call/config/requirements";

const config: ProductConfig = {
  name: "CallApp",
  logo,
  requirements: requirements,
  providers: ["facebook", "google"],
};

export default config;
