import { Radio, Spin, Switch } from "antd";
import { HorizontalCenter } from "components/common/Center";
import StandardButton from "components/common/StandardButton";
import { ProviderConfig } from "modules/panel/config/ProviderConfig";
import { useEffect, useMemo, useState } from "react";
import useAPI from "services/hooks/useAPI";
import { useCreationEditor } from "../CreationEditor";
import { patchCreation } from "../creationsSlice";
import FormattedMessage from "components/common/FormattedMessage";
import PlatformIcon from "components/common/icons/PlatformIcon";

const ProviderSelector = (props: any) => {

    const p = ProviderConfig.get(props.provider);

    if (!p) return null;

    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
        }}>
            <PlatformIcon platform={props.platform} /> 
            {p.displayName} 
            <Switch 
            defaultChecked
            onChange={c => props.onChange && props.onChange(c)} />
        </div>
    )
}

export default function CreationTargetSelector(props:any) {


    const { creation, permissions} = useCreationEditor();
    const updateAPI = useAPI(patchCreation(creation.id))
    const [providers, setProviders] = useState<string[]>(["facebook", "google"])

    const canPublishOnFacebook:boolean = permissions && permissions.includes("use creations platform facebook");
    const canPublishOnGoogle:boolean = permissions && permissions.includes("use creations platform google");

    const allowedPublicationProviders = useMemo(() => {
        const allowed = [];
        if (permissions.includes("use creations platform facebook")) allowed.push("facebook");
        if (permissions.includes("use creations platform google")) allowed.push("google");
      //  if (permissions.includes("use creations platform instagram")) allowed.push("instagram");
        return allowed;
    }, [permissions])

    const isOneProviderOnly = (provider: string) => {
        return allowedPublicationProviders.length === 1 && allowedPublicationProviders[0] === provider;
    }

    const canPublishOn = (provider:string) => {
        return allowedPublicationProviders.includes(provider);
    }

    useEffect(() => {

        if (props.forceProvider) {
            setToOneProviderOnly(props.forceProvider)
            return;
        }

        if (isOneProviderOnly("facebook")) {
            setToOneProviderOnly("facebook")
            return;
        }

        if (isOneProviderOnly("google")) {
            setToOneProviderOnly("google")
            return;
        }

        // if (isOneProviderOnly("instagram")) {
        //     setToOneProviderOnly("instagram")
        //     return;
        // }

    }, [permissions])

    const setToOneProviderOnly = (provider:string) => {
        updateAPI.call({...patchCreation(creation.id), ...{
            body: {
                content: 
                {provider: [provider]}
            }
        }})
    }
    

    const handleNext = () => {
        if (!providers || providers.length === 0) return;
        updateAPI.call({...patchCreation(creation.id), ...{
            body: {
                content: 
                {provider: providers}
            }
        }})

    }

    const handleChange = (provider:string, change:boolean) => {
        if (change) {
            setProviders([...providers, provider])
        } else {
            setProviders(providers.filter(p => p !== provider))
        }
    }

    if (updateAPI.loading) {
        return <div>
            <HorizontalCenter><Spin /></HorizontalCenter>
        </div>
    }

    return (
        <div style={{
            width: "100%",
            height: "50vh",
            display: "grid",
            placeItems: "center",
        }}>
            <div>
                <div>
                    <FormattedMessage
                    id="tools.creationEditor.target.wherePublish"
                    defaultMessage="Where do you want to publish?"/>
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "20px",
                    width: "200px",
                    gap: "20px"
                }}>
                    {canPublishOn("facebook") ? <ProviderSelector provider="facebook" platform="facebook" onChange={(c:any) => handleChange("facebook", c)} /> : null}
                    {canPublishOn("google") ? <ProviderSelector provider="google" platform="google" onChange={(c:any) => handleChange("google", c)} /> : null}
                    <StandardButton type="primary" onClick={handleNext}>
                    <FormattedMessage
                    id="common.next"
                    defaultMessage="Next"/>
                        </StandardButton>
                </div>
            </div>
        </div>
    )

}
