import { useEffect } from "react";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { currentProjectIdSelector, currentSubscriptionIdSelector } from "services/store/scopeSlice";
import { currentFlatSubscriptionPermissionsSelector } from "state/subscriptions/subscriptionsSelectors";

export default function DebugFunctionsInserter() {

  const permissions = useSelectorWithParams(currentFlatSubscriptionPermissionsSelector)
  const projectId = useSelectorWithParams(currentProjectIdSelector);
  const subscriptionId = useSelectorWithParams(currentSubscriptionIdSelector);

  useEffect(() => {
    (window as any).debugBasicInfo = () => {
      return {
        projectId,
        subscriptionId,
      }
    };
  }, [projectId, subscriptionId])

  useEffect(() => {

    (window as any).debugPause = () => setTimeout(function () { debugger; }, 5000);

    if (import.meta.env.VITE_NODE_ENV === "development") {
      window.addEventListener('keydown', function (event) {
        if (event.altKey && event.key === 'p') {
          debugger;
        }
      });
    }


  }, [])



  useEffect(() => {
    (window as any).debugGetPermissions = () => permissions;
    (window as any).debugHasPermission = (permission: string) => permissions.includes(permission);
    (window as any).debugTranslation = (enable: boolean) => {
      localStorage.setItem("translationDebug", enable ? "true" : "false");
    }
    (window as any).debug = (enable: boolean) => localStorage.setItem("debugDisplay", enable ? "true" : "false")


    const debugTranslation = localStorage.getItem("translationDebug");
    (window as any).translationDebug = debugTranslation === "true";

    const debugDisplay = localStorage.getItem("debugDisplay");
    (window as any).debugDisplay = debugDisplay === "true";

  }, [permissions])

  return null;


}