import _ from "lodash";
import { useDispatch } from "react-redux";
import userSettingsSlice, { updateUserSettings, userSettingsInterfaceSelector, userSettingsSelector, userSettingsSubscriptionsSelector } from "state/user/userSettingsSlice";
import useAPI from "./useAPI";
import useSelectorWithParams from "./useSelectorWithParams";
import { currentSubscriptionIdSelector } from "services/store/scopeSlice";

export default function useUserSettings () {

    const userSettings = useSelectorWithParams(userSettingsSelector);
    const subscriptionSettings = useSelectorWithParams(userSettingsSubscriptionsSelector);
    const subscriptionId = useSelectorWithParams(currentSubscriptionIdSelector);
    const interfaceSettings = useSelectorWithParams(userSettingsInterfaceSelector);
    const {call, data, loading, error} = useAPI({})

    const dispatch = useDispatch();

    const updateSubscriptionSettings = (delta: any) => {
        const subSettings = {...subscriptionSettings, ...delta};
        if(subscriptionId) call(updateUserSettings({subscriptions: {[subscriptionId]: subSettings}}))
    }

    const updateInterfaceSettings = (delta: any) => {
        let is = {};
        _.merge(is, interfaceSettings, delta)
        call(updateUserSettings({interface: is}))
        dispatch(userSettingsSlice.actions.localUpdate({interface: is}))
    }


    return {
        userSettings,
        subscriptionSettings,
        updateSubscriptionSettings,
        interfaceSettings,
        updateInterfaceSettings
    }


}