import { Divider as AntDivider, DividerProps as AntDividerProps} from "antd"
import "./standard-divider.less"

type DividerProps = AntDividerProps & {
    size?: "small" | "medium" | "large";
}

const StandardDivider = ({className, ...props}: DividerProps) => {

    const _className = `standard-divider ${className ? className : ""} ${props.size ? props.size : "medium"}`

    return (
        <AntDivider 
            className={_className}
            {...props} 
        />
    )

}

export default StandardDivider;