import { Button, ButtonProps, Tooltip, TooltipProps } from "antd";
import { ReactNode, useMemo } from "react";
import AwesomeIcon, { AwesomeStandardIconProps } from "./AwesomeIcon";
import "./common.less";
import { IconDefinition, IconProp } from "@fortawesome/fontawesome-svg-core";

type StandardButtonProps = {
    fullWidth?: boolean;
    size?: "small" | "middle" | "large";
    className?: string;
    icon?: IconDefinition;
    iconProps?: AwesomeStandardIconProps;
    tooltip?: string;
    children?: ReactNode;
} & Omit<ButtonProps, 'icon'> & TooltipProps;


export default function StandardButton(props: StandardButtonProps) {


    const className = useMemo(() => {
        let c = "standard-button";
        if (props.fullWidth) c += ` fullwidth`
        if (props.size) c += ` ${props.size}`;
        if (props.className) c += ` ${props.className}`;
        if (props.icon) {
            if (props.children) c += " with-icon";
            else c += " icon-only";
        }
        return c;
    }, [])

    const icon = useMemo(() => {
        if (props.icon) return <AwesomeIcon icon={props.icon} {...props.iconProps} />
        return null;
    }, [props.icon])

    if (props.tooltip) {
        return (
            <Tooltip title={props.tooltip}>
                <Button {...props} className={className} icon={icon}>{props.children}</Button>
            </Tooltip>
        )
    }

    return (
        <Button {...props} className={className} icon={icon}>{props.children}</Button>
    )

}