import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import React from "react";
import { useIntl } from "react-intl";

export default function ErrorsInputHelper(props) {
  const intl = useIntl();
  const { rules } = props.rules;
  const { currentErrors, fieldValue } = props;

  return <>
    {!fieldValue && !currentErrors ? (
      ""
    ) : (
      <div className="custom-error-helper-list">
        {rules.map((rule, index) => {
          const { message } = rule;
          let isFullfiled = !currentErrors
            ? true
            : currentErrors.filter(
                (item) => item.props.id === message.props.id
              ).length === 0;
          if (!fieldValue) isFullfiled = false;
          return (
            <div
              key={"rule_" + index}
              className={`${isFullfiled ? "fulfilled" : ""}`}
            >
              {isFullfiled ? (
                <CheckCircleOutlined />
              ) : (
                <CloseCircleOutlined />
              )}
              {intl.formatMessage({
                id: message.props.id,
                defaultMessage: message.props.defaultMessage,
              })}
            </div>
          );
        })}
      </div>
    )}
  </>;
}
