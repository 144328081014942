import { faSmile } from "@fortawesome/pro-light-svg-icons";
import StandardButton from "components/common/StandardButton";
import { useRef } from "react";
import RelaxField from "tools/relaxForm/RelaxField";
import TextEditorNew from "tools/textEditor/TextEditorNew";
import { useTextTools } from "tools/textEditor/TextTools";
import { ChatContextProviderProps, useChat } from "./Chat";
import useAPI, { APIErrorDisplay } from "services/hooks/useAPI";

type ChatMessageInputProps = {
  sendMessage: ChatContextProviderProps["sendMessage"];
};

type editorRefProps = {
  editor: any,
  cleanEditorState: () => void,
  insertText: (value:string) => void,
  getText: () => string,
}

const ChatMessageInput = (props: ChatMessageInputProps) => {
  const editorRef = useRef<editorRefProps | null>(null);
  const { openTextTools } = useTextTools();
  const { currentConversationId } = useChat();
  const sendMessageCall = useAPI({});

  const handleInsert = (value: string) => {
    editorRef?.current?.insertText && editorRef.current.insertText(value);
  };

  const handleSend = (text: string) => {
    sendMessageCall.call({
      ...props.sendMessage(currentConversationId),
      body: { message: text },
    })
    editorRef?.current?.cleanEditorState && editorRef.current.cleanEditorState();
  };

  if (!currentConversationId) return null;

  return (
    <div>
      <div
        style={{
          display: "flex",
        }}
      >
        <TextEditorNew ref={editorRef} />
        <StandardButton
          icon={faSmile}
          onClick={() =>
            openTextTools({
              tool: "emoji",
              onInsert: (e: any) => handleInsert(e),
            })
          }
        />
        <StandardButton
          type="primary"
          loading={sendMessageCall.loading}
          onClick={() => handleSend(editorRef?.current?.getText())}
        //   disabled={}
        >
          Send
        </StandardButton>
      </div>
      <APIErrorDisplay error={sendMessageCall.error}/>
    </div>
  );
};

export default ChatMessageInput;
