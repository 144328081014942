import { Menu as AntMenu, Tooltip } from "antd";
import { useMemo, useState, useEffect } from "react"
import AwesomeIcon from "components/common/AwesomeIcon";
import { Link, useLocation } from "react-router-dom";


export default function SideMenuItem(props: any) {

  const route = props.route;
  const location = useLocation();

  const handleClick = () => {
    props.onClick && props.onClick(route);
  }

  const isCurrent = () => {
    return location.pathname === route.path;
  }

  if (props.isMobile) {
    return (
      <Tooltip title={route.name} placement="right">
        <Link to={route.path} onClick={handleClick}>
          <li key={route.key} className={`item ${isCurrent() ? "current" : ""} mobile`}>
            {route.icon}
            <div>{route.name}</div>
          </li>
        </Link>
      </Tooltip>
    )
  }

  return (
    <Tooltip title={route.name} placement="right">
      <Link to={route.path} onClick={handleClick}>
        <li key={route.key} className={`item ${isCurrent() ? "current" : ""}`}>
          <div className="item-highlight" style={props.route?.highlightStyle}></div>
          {route.icon}
        </li>
      </Link>
    </Tooltip>
  )

}