import RelaxMultiform from "tools/relaxForm/RelaxMultiform";
import CustomizableLocationForm from "_components/forms/customizableLocationForm";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import FormattedMessage from "components/common/FormattedMessage";
import { Button, Input, Row, Col, AutoComplete, Divider } from "antd";
import AddressWithMap from "components/map/AddressWithMap";
import { ProjectWebsiteObjectSelector, UpdateWebsiteObject } from "modules/site-generator/reducers/websiteObjectSlice";
import PhoneNumberInputWithCountrySelect from "_components/forms/PhoneNumberInputWithCountrySelect";

export default function WebsiteObjectLocation() {

    return (
        <RelaxMultiform
            name="locationForm"
            callAPI={UpdateWebsiteObject()}
            initialValuesSelector={ProjectWebsiteObjectSelector}
        >
            <h1>
            <FormattedMessage
        id="apps.websites.containers.onboarding.locationData.heading"
        defaultMessage="Location information"
      />
            </h1>
            {/* <FormattedMessage
                id="apps.websites.forms.companyData.name"
                defaultMessage="Name"
            /> */}
            <Divider />
            <RelaxSingleField
                name="company_name"
                required
                label={
                    <FormattedMessage
                        id="apps.websites.forms.companyData.locationName"
                        defaultMessage="Name"
                    />
                }
            >
                <Input />
            </RelaxSingleField>


            <AddressWithMap />

            <FormattedMessage
                id="apps.websites.forms.companyData.contact"
                defaultMessage="Contact Information"
            />
            <Divider />


            <Row gutter={30}>
            <Col xs={24} md={8}>
                <RelaxSingleField
                    name="phone"
                    required
                    label={
                        <FormattedMessage
                            id="apps.websites.forms.companyData.phone"
                            defaultMessage="Phone"
                        />
                    }
                >
                    <PhoneNumberInputWithCountrySelect />
                </RelaxSingleField>
                </Col>
                <Col xs={24} md={8}>
                <RelaxSingleField
                    name="email"
                    required
                    label={
                        <FormattedMessage
                            id="apps.websites.forms.companyData.email"
                            defaultMessage="Email"
                        />
                    }
                >
                    <Input />
                </RelaxSingleField>
                </Col>
                <Col xs={24} md={8}>
                <RelaxSingleField
                    name="www"
                    required
                    label={
                        <FormattedMessage
                            id="apps.websites.forms.companyData.website"
                            defaultMessage="Website"
                        />
                    }
                >
                    <Input />
                </RelaxSingleField>
                </Col>
            </Row>
            <Row gutter={30}>
                <Col xs={24}>
                <RelaxSingleField
                    name="bank_number"
                    required
                    label={
                        <FormattedMessage
                            id="apps.websites.forms.companyData.bankAccount"
                            defaultMessage="Bank Account Number"
                        />
                    }
                >
                    <Input />
                </RelaxSingleField>
                    </Col>
            </Row>
        </RelaxMultiform>
    )

}