import { createSelector, createSlice } from "@reduxjs/toolkit";
import { callAPIProps } from "services/hooks/useAPI";
import { scopeSlice } from "services/store/scopeSlice";

export type BotsObjectState = {
  botsList: { [key: string]: BotProps };
};

export type BotProps = {
  active: number;
  activity: [];
  confirmations: ConfirmationsProps[];
  error_message: string;
  id: number;
  integrations: IntegrationsProps[];
  keyword: string;
  leads: number;
  message: string;
  messages: number;
  page_post_id: string;
  postMessage: string;
  project_id: number;
  queue: [];
  success_message: string;
  temp?:boolean
};

type ConfirmationsProps = {
  id: number;
  private_replies_id: number;
  message: string;
  keyword: string;
  order: number;
  error_message: string;
  error:string
};

type IntegrationsProps = {
  id: number;
  private_replies_id: number;
  integration_group_external_id: string;
  subscribers_list_name: string;
  project_integration_id: number;
  subscribers_list_hash: string;
  sms_api_group_id: string;
  projectIntegration: {
    id: number;
    project_id: number;
    type: "mailer-lite" | "fresh-mail" | "sms-api";
    status: string;
  };
};

const initialState: BotsObjectState = {
  botsList: {},
};

export const BotsSlice = createSlice({
  name: "bots",
  initialState,
  reducers: {
    get: (state, action) => {
      if (action.payload.data) {
        action.payload.data.map((bot: any) => {
          state.botsList[bot.id] = bot;
        });
      }
    },
    getBot: (state, action) => {
      const { id, ...getBot } = action.payload.data;
      const bot = state.botsList[id];

      // if (!id) {
      //   console.warn("Bot ID not found", action.payload.data);
      //   return;
      // }

      if (bot) {
        state.botsList[id] = {
          ...bot,
          ...getBot,
        };
      } else {
        state.botsList[id] = {id:id, ...getBot};
      }
    },
    add: (state, action) => {
      const newBot = action.payload.data;
      state.botsList[newBot.id] = newBot;

      state.botsList = Object.fromEntries(
        Object.entries(state.botsList).filter(([id, bot]) => !bot.temp)
      );
    },
    addTempBot: (state, action) => {
      const newBot = action.payload.data;
      state.botsList[newBot.id] = newBot;
    },
    update: (state, action) => {

      const { id, ...updatedBotData } = action.payload.data;

      const existingBot = state.botsList[id];
      
      if (existingBot) {
        state.botsList[id] = {
          ...existingBot,
          ...updatedBotData,
        };
      }
    },
    delete: (state, action) => {
      const newBotsList = { ...state.botsList };
      delete newBotsList[action.payload.additional];
      state.botsList = newBotsList;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(scopeSlice.actions.setProject, (state, action) => {

      if (action.payload.project_id) {
        state.botsList = {};
      }

    })
    .addCase("SET_APP_CONTEXT", (state, action) => {
      if (action?.payload?.projectId) {
        state.botsList = {};
      }
    })
  }
});

export const SortBotsListByDate = (posts: any[], newestFirst: boolean) =>
  posts &&
  posts.sort((a: any, b: any) => {
    const aDate = new Date(a.created_at);
    const bDate = new Date(b.created_at);
    if (newestFirst) return bDate.getTime() - aDate.getTime();
    return aDate.getTime() - bDate.getTime();
  });

export const GetBots = (query?: any): callAPIProps => ({
  url: ({ getApiUrl, projectId }) =>getApiUrl(`projects/${projectId}/lead-bot`, 2),
  method: "GET",
  successDispatch: BotsSlice.actions.get,
  passToDispatcher: query,
});

export const GetBot = (botId: any): callAPIProps => ({
  url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/lead-bot/${botId}`, 1),
  method: "GET",
  successDispatch: BotsSlice.actions.getBot,
});

export const AddBot: callAPIProps = ({
  url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/lead-bot`, 3),
  method: "POST",
  successDispatch: BotsSlice.actions.add,
});

export const UpdateBot = (botId:any): callAPIProps => ({
  url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/lead-bot/${botId}`, 1),
  method: "PATCH",
  // successDispatch: BotsSlice.actions.update,
})

export const DeleteBot = (botId: any): callAPIProps => ({
  url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/lead-bot/${botId}`, 1),
  method: "DELETE",
  successDispatch: BotsSlice.actions.delete,
  passToDispatcher: botId,
});

export const ProjectBotsSelector = (state: any) =>
  Object.values(state.bots.botsList);

export const BotSelector = createSelector(
  [ProjectBotsSelector, (state: any, id: any) => id],
  (botsList: any, id: any) => {
    const selectedBot = botsList.find((bot: any) => bot.id === id);
    return selectedBot;
  }
);

export const TempBotSelector = createSelector(
  [ProjectBotsSelector],
  (botsList: any) => {
    const selectedBot = botsList.find((bot: any) => bot.temp === true);
    return selectedBot;
  }
)