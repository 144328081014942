import { Input } from "antd";
import { useState } from "react";
import { DeleteSection, UpdateSection } from "modules/site-generator/reducers/websitePricesSlice";
import useAPI from "services/hooks/useAPI";
import SwitchableInput from "_components/forms/SwitchableInput";
import { StandardModal, useStandardModal } from "services/hooks/useModal";
import FormattedMessage from "components/common/FormattedMessage";
import './price-config.less'
import AwesomeIcon from "components/common/AwesomeIcon";
import { faTrash } from "@fortawesome/pro-light-svg-icons";

type Props = {
    name: string
    id: number
}

export const RenameRow = (props: Props) => {

    const [name, setName] = useState<string>(props.name);
    const [ref, open, close] = useStandardModal()

    const update = useAPI(UpdateSection(props.id))
    const remove = useAPI(DeleteSection(props.id))
    const handleSave = () => {
        update.call({
            body: { name: name }
        })
    }
    
    return (
        <>
            <div className='rename-row'>
                <SwitchableInput
                    value={<h2 className="section-title">{props.name}</h2>}
                    edit={<Input defaultValue={props.name} onChange={(e) => setName(e.target.value)} />}
                    onSave={() => {
                        handleSave()
                    }}
                />
                <AwesomeIcon icon={faTrash} onClick={open}/>
            </div>
            <StandardModal
                ref={ref}
                title={(<FormattedMessage
                    id="apps.smart.components.price-list.delete.section.title"
                    defaultMessage="Delete Section"
                />)}
                onOk={() => {
                    remove.call()
                    close();
                }}
            >
                <FormattedMessage
                    id="apps.smart.components.price-list.delete.section.text"
                    defaultMessage="Are you sure to delete section?"
                />
            </StandardModal>
        </>

    )


}

