import { Alert } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import { providerAccessTokenSelector } from "modules/panel/config/selectors/user";
import { VerifyAdsInvitation } from "modules/smart/smartSlice";
import ConnectedOnboardingControls from "providers/onboarding/controls";
import { useMemo } from "react";
import useAPI from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";

export const OnboardingAdsInvitation = () => {
  const accessToken = useSelectorWithParams(providerAccessTokenSelector);
  const sendEmail = useAPI({
    url: ({ getApiUrl, projectId }) =>
      getApiUrl(`projects/${projectId}/google/adaccount/email`),
    method: "POST",
  });

  const checkEmail = useAPI(VerifyAdsInvitation());

  const email = useMemo(() => {
    if (accessToken && accessToken.toJS) return accessToken.toJS().email;
    return "";
  }, [accessToken]);

  const handleNext = () => {
    const res = checkEmail.call().then((res) => {
      if (res.status === 200 || res.status === 204) {
        return true;
      } else {
        return false;
      }
    });

    return res
  };

  return (
    <>
      <p>
        <FormattedMessage
          id="apps.forsant.adsInvitationVerify.description"
          defaultMessage="We have sent an e-mail invitation to the address {email}, please check
            your mailbox (check also your spam folder) and accept the Invitation by
            clicking on the link in it."
          values={{ email: email }}
        />
      </p>
      <a onClick={() => sendEmail.call()}>
        <FormattedMessage
          id="apps.forsant.adsInvitationVerify.Resend"
          defaultMessage="I didn't receive the email..."
        />
      </a>
      <div>
        {sendEmail.data && (
          <FormattedMessage
            id="apps.forsant.adsInvitationVerify.ResendSuccess"
            defaultMessage="Another emails was sent."
          />
        )}
      </div>
      {(checkEmail.error && checkEmail.loading === false)? (
  <Alert
    type="error"
    message={
      <FormattedMessage
        id="apps.forsant.checkAndContinue.error"
        defaultMessage="We have just checked if you fulfilled this step properly, unfortunately not - please read, and try again"
      />
    }
    style={{ margin: "10px 0" }}
    showIcon
  />
) : (
  ("")
)}
      <ConnectedOnboardingControls
        nextStepText={
          <FormattedMessage
            id="apps.forsant.checkAndContinue"
            defaultMessage="Check and continue"
          />
        }
        nextPending={checkEmail.loading}
        clickOverride={handleNext}
      />
    </>
  );
};
