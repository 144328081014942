import { Progress, Spin, Statistic } from "antd";
import { HorizontalCenter } from "components/common/Center";
import FormattedMessage from "components/common/FormattedMessage";
import StandardButton from "components/common/StandardButton";
import { Title } from "components/common/StandardText";
import { ProjectAIInfoDemographicsSelector, GenerateAIDemographics, GenerateAIExampleClient, GetAiProjectInfo, ProjectHasDemographicSelector, ProjectHasAIExampleClients, AiProjectInfoSelector } from "modules/panel/state/AISlice";
import ConnectedOnboardingControls from "providers/onboarding/controls"
import { useOnboarding } from "providers/onboarding/onboarding-context";
import { useEffect, useRef, useState } from "react";
import useAPI from "services/hooks/useAPI";
import useAPIWithRetry from "services/hooks/useAPIWithRetry";
import useCallAfterUpdate from "services/hooks/useCallAfterUpdate";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";

const gatheringTitle =
    <FormattedMessage
        id="apps.smart.components.aiSettings.aiGeneration.gathering.title"
        defaultMessage="Gathering data"
    />

const generatingTitle =
    <FormattedMessage
        id="apps.smart.components.aiSettings.aiGeneration.generating.title"
        defaultMessage="Generating demographics"
    />

const OnboardingAIExampleClientGeneration = () => {

    const hasExampleClients = useSelectorWithParams(ProjectHasAIExampleClients);
    const projectAIInfo = useSelectorWithParams(AiProjectInfoSelector);
    const generate = useAPI(GenerateAIExampleClient, false);
    const { nextStep, direction, previousStep } = useOnboarding();
    const [hasError, setHasError] = useState(false);
    const hasGenError = projectAIInfo?.example_client_status === "error";

    const { enable, data, loading, error, checkData } = useAPIWithRetry({
        ...GetAiProjectInfo,
        enabled: !hasExampleClients && !hasGenError,
        delay: 5000,
        isDataReady: (data) => {
            return (data.example_client && Object.keys(data.example_client).length > 0) || data.example_client_status === "error";
        }
    })

    useEffect(() => {

        const { example_client, example_client_status } = projectAIInfo;

        if (example_client_status === "in_process" || example_client_status === "in_queue") return;
        if (example_client_status === "none") {
            !generate.loading && generate.call();
        }
        if (example_client_status === "error") {
            setHasError(true);
            return;
        }

    }, []);

    useEffect(() => {

        if (hasExampleClients) {
            if (direction === "backward") {
                previousStep();
                return;
            }
            else {
                nextStep();
                return;
            }
        }

    }, [hasExampleClients]);


    if (hasError) {

        return (
            <div>
                <FormattedMessage
                    id="apps.smart.components.aiSettings.aiGeneration.error"
                    defaultMessage="Something went wrong. Please try again later."
                />
            </div>
        )

    }

    return (
        <div>
            <HorizontalCenter><Spin /></HorizontalCenter>
            <ConnectedOnboardingControls relaxDontSubmit={true} hideButtons={true} />
        </div>
    )
}

export default OnboardingAIExampleClientGeneration;

