import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import {ChatContextProviderProps, Conversation, Message, useChat} from "./Chat";
import { useEffect, useMemo } from "react";
import ChatMessage from "./ChatMessage";
import { Empty } from "antd";


type ChatMessagesProps = {
    conversationSelector: ChatContextProviderProps["conversationSelector"],
}

const ChatMessages = (props: ChatMessagesProps) => {

    const {currentConversationId: currentChatId} = useChat();
    const conversation = useSelectorWithParams([props.conversationSelector, currentChatId])

    const mappedMessages = useMemo(() => {

        const messages = conversation?.messages;

        if (!messages) return null;
        if (!messages.length) return null;
        return messages.map((message, index) => {
            return (
                <ChatMessage
                    key={index}
                    message={message}
                    isIncoming={message.author !== conversation?.speaker}
                />
            )
        })
    }, [conversation])

    if (!mappedMessages) return <Empty />
    if (!currentChatId) return <Empty />

    return (
        <ul className="standard-border chat-messages">
            {mappedMessages}
        </ul>
    )

}

export default ChatMessages;