import { Col, Row, Select } from "antd";
import { useEffect, useMemo } from "react";
import useAPI, { callAPIProps } from "services/hooks/useAPI";
import { SubscriptionOffer } from "state/subscriptions/subscriptionPricingHelpers";
import _ from "lodash";
import { usePurchaseSubscription } from "./PurchaseSubscription";
import OfferCard from "./OfferCard";
import CardSelect from "components/inputs/CardSelect";

type PurchaseOfferSelectProps = {
    showAll?: boolean;
    initialOfferId?: number;
}

const getSubscriptionsOffers = (productName?: string): callAPIProps => ({
    url: ({ getApiUrl }) => getApiUrl(`subscriptions-offers?product_name=${productName}`, 3),
})


function PurchaseSubscriptionOfferSelect(props: PurchaseOfferSelectProps) {

    const offersCall = useAPI({})
    const { product, offer, setOffer } = usePurchaseSubscription();

    useEffect(() => {
        if (!product) return;
        offersCall.call(getSubscriptionsOffers(product));
    }, [product]);

    const offers = useMemo(() => _.cloneDeep(offersCall.data), [offersCall.data]);

    const initialOffer = useMemo(() => {
        if (!offers) return null;
        if (!offers.length) return null;
        return offers.find((o: SubscriptionOffer) => o.id === props.initialOfferId);
    }, [offers, props.initialOfferId]);

    const filteredOffers = useMemo(() => {

        if (!offers) return null;
        if (!offers.length) return null;

        const active = offers.filter((o: SubscriptionOffer) => o.active);
        const visible = active.filter((o: SubscriptionOffer) => o.hide !== true);

        if (initialOffer) {
            if (initialOffer.hide) initialOffer.hide === false;
            if (props.showAll) return visible;
            return [initialOffer]
        }

        return visible;

    }, [offers]);

    useEffect(() => {

        if (!filteredOffers) return;
        if (!filteredOffers.length) return;

        if (initialOffer && !offer) {
            setOffer(initialOffer);
            return;
        }

        if (offer) {
            const found = filteredOffers.find((o: SubscriptionOffer) => o.id === offer.id);
            if (found) return;
          //  console.log("Offer not found in filtered offers", offer, filteredOffers[0])
            setOffer(filteredOffers[0]);
            return;
        }

        //can be used to preselect the first offer if none is selected
        //if (!offer) setOffer(filteredOffers[0]);

    }, [offers, initialOffer, offer]);

    const offerCards = useMemo(() => {
        if (!filteredOffers) return null;
        return filteredOffers.map((o: SubscriptionOffer) => ({
            title: o.title,
            description: o.description,
            image: o.image,
            value: o.id,
            tooltip: o.specification || o.description,
        }))
    } , [filteredOffers])

    const handleChange = (value: number) => {
        const selected = filteredOffers && filteredOffers.find((o: SubscriptionOffer) => o.id === value);
        if (selected) setOffer(selected);
    }


    if (!offerCards) return null;

    return (
        <Row gutter={16} wrap style={{paddingBottom: "12px"}}>
            <CardSelect 
                cards={offerCards}
                value={offer?.id}
                onChange={handleChange}
                cardStyle={{
                    width: "33%",
                }}
            />
        </Row>
    )

}

export default PurchaseSubscriptionOfferSelect;