import KeywordsGoogle from "components/keywords/KeywordsGoogle";
import _ from "lodash";
import ConnectedOnboardingControls from "providers/onboarding/controls";
import { useOnboarding } from "providers/onboarding/onboarding-context";
import FormattedMessage from "components/common/FormattedMessage";
import { StandardModal, useStandardModal } from "services/hooks/useModal";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { FacebookKeywordsSelector } from "modules/smart/smartSlice";


export default function OnboardingKeywordsGoogle () {

    const keywords = useSelectorWithParams([FacebookKeywordsSelector]);
    const [ref, open, close] = useStandardModal();
    const {nextStep} = useOnboarding();

    const checkNext = () => {
        if (!keywords || keywords.length === 0) {
            open();
            return false;
        }
        return true;
    }

    const modalOK = () => {
        nextStep();
    }

    
    return (
        <>
        <KeywordsGoogle />
            <StandardModal
                ref={ref}
                title={<FormattedMessage
                            id="smart.onboarding.facebookKeywords.skipStep.title"
                            defaultMessage="Are you sure?"
                        />}
                onOk={modalOK}
                >
                <FormattedMessage
                            id="smart.onboarding.facebookKeywords.skipStep.message"
                            defaultMessage="Are you sure you want to skip this step?"
                        />
                </StandardModal>
            <ConnectedOnboardingControls relaxDontSubmit = {true} clickOverride={checkNext}/>
        </>
    )

}

