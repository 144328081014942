import { Button, Col, Collapse, Input, InputNumber, Row, Select } from "antd";
import { EmailEnvelopeIcon, UploadIcon } from "modules/panel/components/ft-icons";
import FormattedMessage from "components/common/FormattedMessage";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import StandardButton from "components/common/StandardButton";
import CampaignNameCustomizer from "./CampaignNameCustomizer";
import { UPDATE_ADS_SETTINGS, UPDATE_FACEBOOK_ADS_SETTINGS } from "modules/smart/actions/ads-settings";
import { ruleMax, rulesetMax } from "tools/relaxForm/relaxPresets";
import { faUpload } from "@fortawesome/pro-light-svg-icons";
import ModerationSettings from "./ModerationSettings";

export default function SettingsAgency() {

    return (
        <>
            <Row gutter={30}>
                <Col xxl={12} lg={12} md={12} sm={24} xs={24}>
                    <Collapse>
                        <Collapse.Panel
                            header={
                                <FormattedMessage
                                    id="panel.containers.advancedSettings.campaignName"
                                    defaultMessage="Campaign name"
                                />
                            }
                            key="campaignName"
                        >
                            <CampaignNameCustomizer />
                        </Collapse.Panel>
                        <Collapse.Panel
                            header={
                                <FormattedMessage
                                id="panel.containers.advancedSettings.moderation"
                                defaultMessage="Moderation"
                                />
                            }
                            key="moderation"
                        >
                            <ModerationSettings />
                        </Collapse.Panel>
                    </Collapse>
                    <RelaxSingleField
                        name="basedOnCondition"
                        label={
                            <FormattedMessage
                                id="panel.containers.advancedSettings.basedOnCondition"
                                defaultMessage="Campaign based on the condition"
                            />
                        }
                    >
                        <Input.Group compact>
                            <InputNumber
                                value={10000}
                                disabled={true}
                                style={{ width: "30%" }}
                            />
                            <Select
                                value="interactions"
                                disabled={true}
                                style={{ width: "40%" }}
                            >
                                <Select.Option value="interactions">
                                    <FormattedMessage
                                        id="panel.containers.advancedSettings.interactions"
                                        defaultMessage="interactions"
                                    />
                                </Select.Option>
                            </Select>
                            <Select
                                value="24h"
                                disabled={true}
                                style={{ width: "30%" }}
                            >
                                <Select.Option value="24h">
                                    <FormattedMessage
                                        id="panel.containers.advancedSettings.24h"
                                        defaultMessage="24H"
                                    />
                                </Select.Option>
                            </Select>
                        </Input.Group>
                    </RelaxSingleField>

                </Col>
            </Row>
            <Row gutter={30}>
                <Col xxl={6} lg={6} md={12} sm={24} xs={24}>
                    <RelaxSingleField
                        name="agencyLogo"
                        label={
                            <FormattedMessage
                                id="panel.containers.advancedSettings.topCustomersEvent"
                                defaultMessage="Agency logo"
                            />
                        }
                        disabled={true}
                    >
                        <StandardButton
                            className="upload-btn"
                            type="dashed"
                            style={{ width: "100%" }}
                            icon={faUpload}
                        >
                            <FormattedMessage
                                id="panel.containers.advancedSettings.uploadFile"
                                defaultMessage="Upload file"
                            />
                        </StandardButton>

                    </RelaxSingleField>

                </Col>
                <Col xxl={6} lg={6} md={12} sm={24} xs={24}>
                    <RelaxSingleField
                        name="reportSchedule"
                        label={
                            <FormattedMessage
                                id="panel.containers.advancedSettings.reportSchedule"
                                defaultMessage="Report schedule"
                            />
                        }
                        disabled={true} 
                    >
                        <Input />
                    </RelaxSingleField>
                </Col>
            </Row>
            <Row gutter={30}>
                <Col xxl={12} lg={12} md={12} sm={24} xs={24}>
                    <RelaxSingleField
                        name="email"
                        label={
                            <FormattedMessage
                                id="panel.containers.advancedSettings.reportEmail"
                                defaultMessage="Email address for reports"
                            />
                        }
                        disabled={true}
                    >
                        <Input
                            type="email"
                            suffix={<EmailEnvelopeIcon />}
                        />
                    </RelaxSingleField>
                </Col>
            </Row>
            <Row gutter={30}>
                <Col xxl={12} lg={12} md={12} sm={24} xs={24}>
                    <RelaxSingleField
                        name="excludedIfSeen"
                        initialValue="Yesterday"
                        label={
                            <FormattedMessage
                            id="panel.containers.advancedSettings.howOftenDoCustomersComeBack"
                            defaultMessage="Excluded if saw an ad"
                          />
                        }
                        disabled={true} 
                    >
                      <Input />
                    </RelaxSingleField>
                </Col>
            </Row>
        </>
    )

}