import { GetWebsiteObject, ProjectWebsiteObjectSelector, UserHasFilledRules } from "modules/site-generator/reducers/websiteObjectSlice";
import ConnectedOnboardingControls from "providers/onboarding/controls";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useAPI from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import ResidenceRules from "../residence-rules";

export default function ResidenceRulesOnboarding (props:any) {

    const [hasErrors, setHasErrors] = useState(false);
    //const values = useSelectorWithParams([SelectedCategoriesInCategorySelector, props.industry, props.category]);
    const fulfilled = useSelectorWithParams([UserHasFilledRules]);
    const {data} = useAPI(GetWebsiteObject(),true)
    

    const handleFormStateChange = (formState: any) => {
        const {hasErrors} = formState;
        setHasErrors(hasErrors);
    }
        
    return (
        <>
        <ResidenceRules/>
        <ConnectedOnboardingControls 
        relaxDontSubmit = {true} 
        //nextDisabled={(!fulfilled || hasErrors)}
        />
        </>
    )
}