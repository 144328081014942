import { Alert, Col, Input, Row, Space, Typography } from "antd";
import StandardButton from "components/common/StandardButton";
import { useState } from "react";
import useAPI from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { ProjectSettingsSelector, UpdateProjectSettings } from "state/project/projectSettingsSlice";


const GoogleConfigCoupons = () => {

    const [code, setCode] = useState<string>("")
    const [wasSent, setWasSent] = useState<boolean>(false)

    const couponsCall = useAPI({})
    const settings = useSelectorWithParams(ProjectSettingsSelector)
    const coupons = settings?.coupons;

    const handleNewCoupon = () => {
        setWasSent(true)
        couponsCall.call({...UpdateProjectSettings, 
                body: {
                    settings: {
                        coupons: [...coupons || [], code]
                    }
                }
            })
    }
  

    function generateCouponCode() {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        const segments = [5, 6, 4]; // Lengths of each segment in the format
    
        return segments.map(segmentLength => 
            Array.from({ length: segmentLength })
                .map(() => chars.charAt(Math.floor(Math.random() * chars.length)))
                .join('')
        ).join('-');
    }


    return (
        <>
            <Row gutter={30}>
                <Col sm={12}>
                <div style={{
                    height: "55px",
                    display: "grid",
                    placeItems: "center"
                }} className="standard-border">
                    <h2>{code}</h2>
                </div>
                {
                    wasSent && (
                        <Alert message="Coupon was sent" type="success" showIcon />
                    )
                } 
                </Col>
                <Col>
                <Space direction="vertical">
                    <StandardButton onClick={() => {
                        setCode(generateCouponCode())
                        setWasSent(false)
                        }}>Generate</StandardButton>
                    <StandardButton 
                        disabled={code.length === 0}
                    onClick={handleNewCoupon}>Send</StandardButton>
                </Space>             
                </Col>
            </Row>
        </>
    )

}

export default GoogleConfigCoupons;