import { createPortal } from "react-dom";
import { usePayment } from "../Payment";
import { useEffect, useMemo, useState } from "react";
import PaymentCoupon from "./PaymentCoupon";
import PartnerCode from "./PartnerCode";
import OfferPricingSummary from "./OfferPricingSummary";
import "./offerPricingSummary.less";
import { usePurchaseSubscription } from "./PurchaseSubscription";
import { BlankOfferSummary } from "./BlankOfferSummary";

type Pages = "summary" | "promo" | "partner";


function SubscriptionOfferDisplay(props: any) {

    const { offer, promoCode, promoCodeCheck } = usePurchaseSubscription();
    const [page, setPage] = useState<Pages>("summary");

    if (!offer) return (
        <div className={`price-details-container ${props.summaryClassName ? props.summaryClassName : ""}`}>
            <BlankOfferSummary />
        </div>
    )

    if (page === "summary")
        return (
            <div className={`price-details-container ${props.summaryClassName ? props.summaryClassName : ""}`}>
                <OfferPricingSummary onMoveToPage={setPage} />
            </div>
        )

    if (page === "promo")
        return (
            <div className={`price-details-container ${props.summaryClassName ? props.summaryClassName : ""}`}>
                <PaymentCoupon onClick={() => setPage("summary")} {...offer} className={props.summaryClassName} />
            </div>
        )

    if (page === "partner")
        return (
            <div className={`price-details-container ${props.summaryClassName ? props.summaryClassName : ""}`}>
                <PartnerCode onClick={() => setPage("summary")} className={props.summaryClassName} />
            </div>
        )

    return null;
}

export function SubscriptionOfferDisplayInPortal(props: any) {

    const [target, setTarget] = useState<HTMLElement | null>(null);

    useEffect(() => {
        const t = document.getElementById('payment-sidebar');
        if (t) setTarget(t);
    }, []);


    if (!target) return null;
    return (
        <>
            {createPortal(<SubscriptionOfferDisplay {...props} />, target)}
        </>
    )
}



export default SubscriptionOfferDisplay;