import { useEffect, useState } from "react";

export default function useAPISmartActions () {

    const [smartActions, setSmartActions] = useState<any>(window.smartActions);

    useEffect(() => {

      window.addEventListener('smartActionUpdate', () => {
        setSmartActions({...window.smartActions});
      });

      return () => {
        window.removeEventListener('smartActionUpdate', () => {
          setSmartActions({...window.smartActions});
        });
      }

    }, [])

    const registerAction = (actionName:string, actionCall?: Promise<any>, actionData?: any) => {
      //console.log(`registering action: ${actionName}`, actionCall);

      if (!window.smartActions) window.smartActions = {};
      const newAction = {
        name: actionName, 
        state: "pending", 
        data: undefined, 
        actionCall: actionCall,
        ...actionData
      }

      window.smartActions[actionName] = newAction
      const event = new CustomEvent('smartActionUpdate');
      window.dispatchEvent(event);

  }

  const resolveAction = (actionName:string, newState:string, newData:any ) => {
    //console.log(`Action: ${actionName} resolved with: ${newState} and data:`, newData);
    
    if (!window.smartActions) window.smartActions = {};
    window.smartActions[actionName] = {
      ...window.smartActions[actionName],
      state: newState,
      data: newData,
      actionCall: null,
    }

    const event = new CustomEvent('smartActionUpdate');
    window.dispatchEvent(event);

}

const getAction = (actionName: string) => {

  return window.smartActions?.[actionName];

}

    return {
      smartActions,
      registerAction,
      resolveAction,
      getAction
    };

}