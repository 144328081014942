import { Space } from "antd";
import StandardButton from "components/common/StandardButton";
import { ReactNode, useMemo } from "react"
import FormattedMessage from "components/common/FormattedMessage";
import { CreationAcceptationTask, CreationPublicationButtons } from "tools/creationEditor/CreationTasks";
import { DataUpdateTask_GoogleMyBusiness } from "tools/organization/projectPage/GoogleMyBusinessCompanyInfoForm";

export type Task = any;

export type TaskType = {
    type: string,
    title: ReactNode,
    content: (task: Task) => ReactNode,
    buttons: (task: Task, onAccept: () => void, onReject: () => void, onUnpublish: () => void, onClose: () => void) => ReactNode,
}

const DefaultTaskButtons = (props: any) => {

    const task = props.task;
    const canBeUnpublished = useMemo(() => {
        if (!task) return false;
        if (!task.status) return true;
        if (task.status !== "completed") return false;
        return true;
    }, [task])

    const canBeAccepted = useMemo(() => {
        if (!task) return false;
        if (!task.status) return true;
        if (task.status !== "pending_acceptation") return false;
        return true;
    }, [task])

    const canBeRejected = useMemo(() => {
        if (!task) return false;
        if (!task.status) return true;
        if (task.status !== "pending_acceptation") return false;
        return true;
    }, [task])


    return (
        <Space wrap>
            <StandardButton onClick={props.onClose}>Cancel</StandardButton>
            {canBeUnpublished ? (
                <StandardButton type="primary" onClick={props.onUnpublish}>
                    <FormattedMessage
                        id="tools.tasks.actions.unpublish.button"
                        defaultMessage="Unpublish"
                    />
                </StandardButton>
            ) : (
                <>
                    <StandardButton type="primary" danger onClick={props.onReject} disabled={!canBeRejected}>
                        <FormattedMessage
                            id="tools.tasks.actions.reject.button"
                            defaultMessage="Reject"
                        />
                    </StandardButton>
                    <StandardButton type="primary" onClick={props.onAccept} disabled={!canBeAccepted}>
                        <FormattedMessage
                            id="tools.tasks.actions.accept.button"
                            defaultMessage="Accept"
                        />
                    </StandardButton>
                </>
            )}

        </Space>
    )
}

const DataUpdateTaskByType = (props: { task: Task }) => {

    if (!props?.task?.payload?.type) return null;

    switch (props?.task?.payload?.type) {
        case "google_my_business":
            return <DataUpdateTask_GoogleMyBusiness task={props.task} />
    }

    return null;

}

export const taskTypes = new Map<string, TaskType>([
    ["post_request", {
        type: "post_request",
        title: "Post Request",
        content: (task) => <CreationAcceptationTask task={task} />,
        buttons: (task, onAccept, onReject, onUnpublish, onClose) => <CreationPublicationButtons task={task} onAccept={onAccept} onReject={onReject} onUnpublish={onUnpublish} onClose={onClose} />
    }],
    ["data_update", {
        type: "data_update",
        title: "Data Update",
        content: (task) => DataUpdateTaskByType({ task }),
        buttons: (task, onAccept, onReject, onUnpublish, onClose) => <DefaultTaskButtons task={task} onAccept={onAccept} onReject={onReject} onUnpublish={onUnpublish} onClose={onClose} />
    }]
])
