
import "whatwg-fetch";
import ReduxStore from "services/store/redux-store";
import ReloginUser from "providers/relogin-user";
import OnboardingProvider from "providers/onboarding/onboarding-context";
import SubscriptionProvider from "providers/subscription/subscription";
import Routing from "providers/routing";
import { BrowserRouter } from "react-router-dom";
import Instance from "providers/instance";
import LanguageProvider from "providers/languages";
import { APIContextProvider } from "contexts/APIContext";

import { ModulesConfig } from "modules";
import themes from "themes";
import { WindowSizeProvider } from "services/hooks/useWindowSize";
import CreationGlobal from "tools/creationEditor/CreationGlobalContext";
import DebugFunctionsInserter from "services/debug/DebugFunctionsInserter";
import HelpPanelContext from "tools/helpPanel/HelpPanelDrawer";
import TextTools from "tools/textEditor/TextTools";
import NotificationsGlobal from "modules/panel/components/notifications/NotificationsDrawer";
import FeedbackContextProvider from "contexts/FeedbackContext";
import InitializeSentry from "services/sentry";
import AppScope from "providers/scope/AppScope";

InitializeSentry();

if (import.meta.env.VITE_INSTANCE_THEME && themes[import.meta.env.VITE_INSTANCE_THEME]) {
  themes[import.meta.env.VITE_INSTANCE_THEME]().then(() => {
  });
} else {
  console.log(`Theme ${import.meta.env.VITE_INSTANCE_THEME} not found, using default theme.`)
  themes["fasttony"]().then(() => {
  });
}

if ((window as any).FreshworksWidget){ 
  (window as any).FreshworksWidget('hide', 'ticketForm', ['custom_fields.cf_user_id', 'custom_fields.cf_panel_url', "custom_fields.cf_event_id"], {
});

}

window.appBuildDate = "__DATE__"


export default function App() {

  return (
    <>
    <BrowserRouter>
      <ReduxStore>
          <WindowSizeProvider>
          <LanguageProvider>
            <Instance
              render={(modules) => (
                <APIContextProvider>
                  <SubscriptionProvider
                    modules={modules as ModulesConfig}
                    render={(apps: any, requirements: any, index: any) => (
                      <ReloginUser>
                        <OnboardingProvider
                          appIndexRoute={index}
                          appRequirements={requirements}
                        >
                          <FeedbackContextProvider>
                          <HelpPanelContext>
                            <TextTools>
                              <NotificationsGlobal>
                              <CreationGlobal>
                                {/* <AppScope /> */}
                                <Routing apps={apps} />
                                <DebugFunctionsInserter />
                                {/* <SuperPromoDisplay /> */}
                              </CreationGlobal>
                              </NotificationsGlobal>
                            </TextTools>
                          </HelpPanelContext>
                          </FeedbackContextProvider>
                        </OnboardingProvider>
                      </ReloginUser>
                    )}
                  />
                  {/* <DetectAdBlock pathname={window.location.pathname} /> */}
                </APIContextProvider>
              )}
            />
          </LanguageProvider>
          </WindowSizeProvider>
      </ReduxStore>
    </BrowserRouter>
    </>
  );
}

