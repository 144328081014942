import { animated, config, Spring, useSpring } from "react-spring";
import { formatNumber } from "@formatjs/intl";

type ValueDisplayWithCounterProps = {
    value?: number | undefined | null;
    animate?: boolean;
    parser?: (value: number) => string;
}


//Formats a number to a string with a space as a thousands separator, padds the number to maintain the same width
const format = (val:number, fullValue: number) => {
    
    try {
    const numberOfDigits = fullValue.toFixed(2).length;
    const formatted = new Intl.NumberFormat('en-US',{ maximumFractionDigits: 2}).format(val).replaceAll(","," ")
    const padding = " ".repeat(Math.max(numberOfDigits - formatted.length, 0))
    
    return "" + padding + formatted;
    } catch (e) {
        console.warn("cant format", val, fullValue)
    } 
    return val;
}

export default function ValueDisplayWithCounter(props:ValueDisplayWithCounterProps) {

    const spring = useSpring({ 
        to: {val: props.value}, 
        from: { val: 0 },
        delay: 300,
        config: {
            duration: 2000,
        }
    });

    if (props.animate === false) return (
        <>
        {props.value}
        </>
    )


    return (
        <>
        <animated.div className={"number"}>
            {spring.val.interpolate((val:any) => props.parser ? props.parser(val) : format(val, props.value || 0))}
        </animated.div>
        </>
    )

}
