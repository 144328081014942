import React, { useEffect, useState } from "react";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { toolbarMinmal } from "./toolbarPresets";
import "./textEditor.less";

export type TextEditorProps = {
  value?: any,
  updatedValue?: any,
  onChange?: any,
  showRaw?: boolean,
  toolbar?: any
}

export default function TextEditor(props: TextEditorProps) {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [isEditorLoaded, setIsEditorLoaded] = useState(false);

  const rawDisplay = (<textarea
    disabled
    value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
  />)

  useEffect(() => {
    if (!!props.value && !isEditorLoaded) {
      handleUpdateEditorState(props.value, true)
    }
  }, [props.value])


  useEffect(() => {
    if (props.updatedValue !== undefined) {
      handleUpdateEditorState(props.updatedValue, false)
    }
  }, [props.updatedValue])

  const handleUpdateEditorState = (value: any, changeLoadedState: boolean) => {
    const blocksFromHtml = htmlToDraft(value);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const newEditorState = EditorState.createWithContent(contentState);

    setEditorState(newEditorState);
    if(changeLoadedState) setIsEditorLoaded(true);
  }

  const handleEditorChange = (newEditorState: any) => {
    setEditorState(newEditorState);
  }

  const handleContentChange = (newContentState: any) => {
    const updatedValue = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    if (props.onChange) props.onChange(updatedValue);
  }

  return (
    <div>
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        onContentStateChange={handleContentChange}
        wrapperClassName="textEditor-wrapper"
        editorClassName="textEditor-editor"
        toolbar={props.toolbar || toolbarMinmal}
        wrapperStyle={{ height: "600px", paddingBottom: "50px" }}
      />
      {props.showRaw && rawDisplay}
    </div>
  );
}