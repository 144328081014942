import SubscriptionList from "modules/panel/components/user/subscriptions/SubscriptionList";
import React, { Component } from "react";

export default function SubscriptionsPage() {

  return (
      <SubscriptionList />
  )

}

