
import _, { forEach } from "lodash";
import React, { ReactNode, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import ImageCropperModal from "./imageCropperModal";
import GalleryElementList from "./GalleryElementList";
import GalleryUploader from "./galleryUploader";
import ImageUploadErrorDisplay from "./imageUploadErrorDisplay";
import {
    arrayMove,
  } from '@dnd-kit/sortable';
import { defaultCropPresets } from "./imageConfigs";

import { useDebounceTrigger } from "services/hooks/useDebounce";
import useUploadFile, { ChunkUpload } from "services/hooks/useUploadFile";
import GalleryUploadEntry from "./GalleryUploadEntry";
import useBatchedArray from "services/hooks/useBatchedArray";
import { CheckSizeAgainstPresets, GetFirstUnsetCrop, getUnsetProviders } from "./imageHelpers";
import { getInitialCrops } from "./image";
import useUniqueHashList from "services/hooks/useUniqueHashList";
import useCallAfterUpdate from "services/hooks/useCallAfterUpdate";
import useSet from "services/hooks/useSet";
import useMemoDeepCompare from "services/hooks/useMemoDeepCompare";
import './image-gallery.less'
import { faRecycle } from "@fortawesome/pro-light-svg-icons";
import StandardButton from "components/common/StandardButton";


export type GalleryTool = "crop" | "delete" | "drag" | "select";

export type AllowedTools = GalleryTool[];



export type CropPreset = {
    ratio: string,
    aspect: number,
    platform?: string,
    displayName?: string,
} & SizeRequirements

export type CropPresets = Record<string,CropPreset[]>



export type FileRequirements = {
    allowedTypes?: string,
    minFileSize?: number, 
    maxFileSize?: number, 
}



export type SizeRequirements = {
    minWidth?: number,
    maxWidth?: number, 
    minHeight?: number,
    maxHeight?: number,
}


export type GalleryProps = {
    
    allowedTools?: AllowedTools,
    allowedTypes?: ("image" | "video" | "file")[],

    cropPresets?: CropPresets,
    fileRequirements?:FileRequirements,
    sizeRequirements?:SizeRequirements,

    requireCropToSelect?: boolean,
    autoSelectNewImages?: boolean,

    singleImageOnly?:boolean,

    hideUnfitImages?: boolean,
    initialItems?: GalleryItem[],

    showItemTooltip?: boolean,

    requireCrop?: boolean;

    onChange?:any,
    onBlur?:any,
    onCrop?:any,
    onSplit?:any,
    value?:any,
    id?:any,

    onFileUploaded?: (file:GalleryItem) => void,

    valueChangeReplacesAll?: boolean,
    enableCropTrack?: boolean,
    forceCropTrack?: boolean,

    debounce?: number,
    uniqueIdentifier?: string,  //Attribute by which to identify the gallery items. Defaults to "url"

    smartActionName?: string,

    mode?: "split" | undefined | null;

    splitOptions?: {
        spaceHorizontal?: number,
        spaceVertical?: number,
    }


    listSuffix?: ReactNode

}

export type GalleryHook = {
    galleryItems?: GalleryItem[],
    selectedImages?: string[],

    allowedTools?: AllowedTools,
    allowedTypes?: string[] | string,

    cropPresets?: CropPresets,
    errors?:any[],
    isCropping?: boolean,
    croppedImage?: GalleryItem | undefined,
    reorderImage?: any, 
    closeCropper?: any, 
    changeCrop?:any,
    clearCrops?:any,
    sizeRequirements?:SizeRequirements,
    fileRequirements?:FileRequirements,
    requireCrop?: boolean,
    showItemTooltip?: boolean,
    updateListOfUploads?:  (newList: Map<string, ChunkUpload>) => void,
    singleImageOnly?: boolean,
    clearGallery?: any,
    cancelUploads?: any,
    valueChangeReplacesAll?: boolean,
    enableCropTrack?: boolean,
    forceCropTrack?: boolean,
    deleteItem?: (item:GalleryItem) => void,
    cropItem?: (item:GalleryItem) => void,
    setItemCrop?: (item:GalleryItem, value:any) => void,
    selectItem?: (item:GalleryItem) => void,
    handleSplit?: () => void,
    splitOptions?: GalleryProps["splitOptions"],
}

export type GalleryItem = {
    url: string, 
    uuid?: string,
    type: "image" | "video" | "file",
    crop?: any,
    fileName?:string,
    width?: number,
    height?: number,
    hash?: string,
}


const ImageGalleryContext = React.createContext({});
ImageGalleryContext.displayName = "Image Gallery Context";
export const useGallery = ():GalleryHook => useContext(ImageGalleryContext);

const Gallery = React.forwardRef(({value= {}, ...props}:GalleryProps, ref:any) =>  {

    const [galleryItems, setGalleryItems] = useBatchedArray<GalleryItem>([]);
    const [selectedImages, setSelectedImages] = useState<string[]>([]);
    const [uploadErrors, setUploadErrors] = useState<any[]>([]);
    const [croppedImage, setCroppedImage] = useState<GalleryItem | undefined>(undefined);

    const removedHashes = useRef<Set<string>>(new Set());

    const {uploads, UploadFile, CancelUpload, RemoveFile, CancelAllUploads} = useUploadFile({
        smartActionName: props.smartActionName,
        onUpload: (file: Partial<ChunkUpload> & any) => {
            handleFileUploaded({
                                url: file.url || "",
                                type: file.type || "image",
                                fileName: file.file?.name,
                                width: file.file?.width,
                                height: file.file?.height,
                                hash: file.file?.hash,
                                uuid: file.uuid,
                            })
        }
    });



    const handleFormItemChange = () => {
        const val = prepareItems();

        //console.log("handleFormItemChange", val);
        //addMultipleHashes(val.map((item:GalleryItem) => item.hash || ""));

        if (props.onChange) (props.onChange(val));
        if (props.onBlur) (props.onBlur(val));
    }

    const [debouncedUpdate] = useDebounceTrigger(handleFormItemChange, props.debounce || 100);

    //const debouncedUpdate = () => {}

    //const debouncedUpdate = handleFormItemChange;

    //const debouncedUpdate = useCallAfterUpdate(handleFormItemChange);

    // useEffect(() => {
    //     console.log(`galleryItems`,galleryItems);
    // }, [galleryItems]);

    const safelyAddItems = (items: GalleryItem[]) => {
        const acceptedItems:GalleryItem[] = [];

        //Accept only items that were not removed.
        items.forEach((item:GalleryItem) => {
            const wasRemoved = removedHashes.current.has(item.hash || "");
            if (wasRemoved) return;
            acceptedItems.push(item);
        })

        const newImages = _.unionBy(galleryItems, acceptedItems, props.uniqueIdentifier || "url");

        setGalleryItems(newImages);

        return newImages;
    }

    const filloutMissingFieldsInItem = (item: any) => {
       
        const filenameWithType = item?.url?.split("/").pop().split("?").shift();
        const adjusted:any = {
            url: item?.url,
            type: item?.type || "image",
            filename: filenameWithType,
            width: item?.width,
            height: item?.height,
            crop: item?.crop,
            hash: item?.hash,
            uuid: item?.uuid,
            thumbnails: item?.thumbnails,
        }
        //adjusted.crop = item.crop;

        return adjusted;
    }

    useEffect(() => {
      if (props.initialItems) {
        const adjustedItems = props.initialItems.filter(i => (i && i.url)).map(filloutMissingFieldsInItem);
        safelyAddItems(adjustedItems);
      }
    }, [props.initialItems])
    


    const adjustedValue = useMemoDeepCompare(() => {


        if (!value) return [];
        if (value.map) {
            return value.map(filloutMissingFieldsInItem);
        } else if (typeof value === "string") {
            return [filloutMissingFieldsInItem({
                url: value
            })];
        }
        return [];

    }, [value])

    useEffect(() => {


        //if select is allowed
        if (props.allowedTools && props.allowedTools.includes("select")) {
            setSelectedImages(_.union(selectedImages, adjustedValue.map((v:any) => v.url)))
        }
        
        if (props.valueChangeReplacesAll) {
            setGalleryItems(adjustedValue);
            return;
        }

        safelyAddItems(adjustedValue);
        //debouncedUpdate();

    }, [adjustedValue])

    const handleFileUploaded = (file: GalleryItem) => {

        //console.log("handleFileUploaded", file);

        delete file.crop;
        if (file.type === "image") 
        file.crop = getInitialCrops(file, cropPresets)

        if (props.onFileUploaded) props.onFileUploaded(file);

        //If we are uploading a new image, we want to make sure its not on the list of unwanted images.
        //(in case we are reuploading something we removed)
        removedHashes.current = new Set(Array.from(removedHashes.current).filter(i => i !== file.hash || ""));

        if (props.singleImageOnly) {
            (file as any).index = 1;
            setGalleryItems([file])
        } else {
            (file as any).index = galleryItems.length+1;
            safelyAddItems([file])
        }

        if (props.singleImageOnly) {
            if (props.requireCropToSelect) 
                setCroppedImage(file) 
            else 
                debouncedUpdate();
        } else {
            if (!props.requireCropToSelect) 
                debouncedUpdate();
        }

    }

    const deleteItem = (item: GalleryItem) => {
        setGalleryItems([...galleryItems.filter(i => i.hash !== item.hash)])
        setSelectedImages([...selectedImages.filter(s => s !== item.url)])
        RemoveFile(item.url);
        debouncedUpdate();

        //If we are deleting an image, we want to make sure its on the list of unwanted images.
        removedHashes.current.add(item.hash || "");

    }

    const cropItem = (item: GalleryItem) => {
        setCroppedImage(item)
    }

    const setItemCrop = (item: GalleryItem, value: any) => {
        if (value.platform && value.crop && value.preset) {
            const newCrop = {
                ...value.crop, 
                ratio: value.preset.ratio,
                aspect: value.preset.aspect,
            }
            //console.log("new crop", newCrop, " for", item)
            const newItem = _.cloneDeep(item);
            _.set(newItem,["crop",value.platform],newCrop);

            const newGalleryItems = galleryItems.map((i:any) => {
                if (i.url === newItem.url) {
                    return newItem;
                }
                return i;
            })
            setGalleryItems(newGalleryItems);

            if (props.requireCropToSelect) {
                
                setSelectedImages(_.union(selectedImages, [newItem.url]))
            }

            if (props.enableCropTrack) {

                const UnsetProviders = getUnsetProviders(newItem, props.cropPresets as any);


                if (UnsetProviders && UnsetProviders.length > 0) {
                    cropItem(newItem);
                }

            }

        }
        debouncedUpdate();
    }

    const selectItem = (item: GalleryItem) => {
        if (selectedImages.includes(item.url)) {
            setSelectedImages([...selectedImages.filter(s => s !== item.url)]) 
        } else {
            if (props.requireCropToSelect && !item.crop) {
                setCroppedImage(item)   
            } else {
                setSelectedImages(_.union(selectedImages, [item.url]))
            }
        }
        debouncedUpdate();
    }


    const handleFileError = (file: GalleryItem, error: any) => {
        //console.log("File", file, "REJECTED --> ", error)
        setUploadErrors(errors => [...errors, {file, error}])
    }

    const reorderImage = (sourceIndex: number, destinationIndex: number) => {
    
            if (sourceIndex !== destinationIndex) {

                let indexes = galleryItems.map((i:any) => i.index);
                //console.log("before", [...indexes])

                const oldIndex = indexes.indexOf(sourceIndex);
                const newIndex = indexes.indexOf(destinationIndex);
                indexes = arrayMove(indexes, oldIndex, newIndex);
                //console.log("after", indexes)

                const newImages = indexes.map(ri => galleryItems.find((i:any) => i.index === ri));
                newImages.forEach((ni:any, index:number) => ni.index = index+1);
                setGalleryItems(newImages as any)
            }


    }

    const closeCropper = () => setCroppedImage(undefined)

    const prepareItem = (item: GalleryItem) => {
        if (!item) return;
        if (item.type === "image") return prepareImage(item);
        if (item.type === "video") return item;
        return item;
    }

    const prepareImage = (img: any) => {

        const preparedImage:any = {};
        preparedImage.url = img.url;
        preparedImage.crop = img.crop;
        //preparedImage.image = img.url;
        preparedImage.width = img.width;
        preparedImage.height = img.height;
        preparedImage.hash = img.hash;
        preparedImage.type = img.type || "image";
        preparedImage.uuid = img.uuid || "";

        return preparedImage;
    }
  
    const prepareItems = () => {

        let itemsToPrepare:GalleryItem[] = [];

        if (props.singleImageOnly) {
            if (galleryItems && galleryItems[0]) itemsToPrepare.push(galleryItems[0]);
        } else if (props.allowedTools && props.allowedTools.includes("select")) {

            if (selectedImages && selectedImages.length) {
                selectedImages.forEach((s, index) => {
                    const i: any = galleryItems.find(img => img.url === s);
                    if (!i) return;
                    itemsToPrepare.push(prepareItem(i));
                })}
        
        } else {
            itemsToPrepare = galleryItems;
        }

        return itemsToPrepare.map((i:any) => prepareItem(i));

    }

    const getPresets = () => {
        if (!props.cropPresets) return defaultCropPresets;
        //if (Array.isArray(props.cropPresets)) return {default: props.cropPresets}
        return props.cropPresets;
    }

    const cropPresets = useMemo(() => {

        const presets = getPresets();
        for (const platform in presets) {
            presets[platform].forEach(p => p.platform = platform);
        }
        return presets;
  
    },[props.cropPresets])

    const saveSplit = useCallAfterUpdate(() => {
        const item = galleryItems && galleryItems[0];
        if (!item) return;
        props.onSplit && props.onSplit(item);
    })

    const handleSplit = () => {
        saveSplit();
    }


    const clearCrops = () => {
      setGalleryItems(galleryItems.map(i => ({...i, crop: undefined})));
        props.onChange(galleryItems.map(i => ({...i, crop: undefined})));
        
        if (props.onBlur) (galleryItems.map(i => ({...i, crop: undefined})));
    }

    const clearGallery = () => {
        setGalleryItems([]);
        props.onChange([]);
        props.onBlur([]);
    }

    const mappedUploads = useMemo(() => {
        return (<div>
            {Array.from(uploads.values()).filter((f:any) => f.state !== "complete").filter((f:any) => {
                if (f.error && f.errorMsg === "canceled") return false;
                return true;
            }).map((f) => <GalleryUploadEntry key={f.id} item={f} />)}
          </div>)
    }, [uploads]);

    const contextValue:GalleryHook = {
        galleryItems, 
        selectedImages,
        errors: uploadErrors,
        allowedTools: props.allowedTools,
        allowedTypes: props.allowedTypes,
        //allowedCropRatios: cropPresets,
        cropPresets: cropPresets,
        reorderImage,
        croppedImage,
        closeCropper,
        sizeRequirements: props.sizeRequirements,
        fileRequirements: props.fileRequirements,
        clearCrops,
        cancelUploads: CancelAllUploads,
        requireCrop: props.requireCrop,
        showItemTooltip: props.showItemTooltip,
        singleImageOnly: props.singleImageOnly,
        clearGallery,
        enableCropTrack: props.enableCropTrack,
        forceCropTrack: props.forceCropTrack,
        deleteItem: deleteItem,
        cropItem: cropItem,
        setItemCrop: setItemCrop,
        selectItem: selectItem,
        handleSplit,
        splitOptions: props.splitOptions,
    }

    useImperativeHandle(ref, () => contextValue)


    return (
        <ImageGalleryContext.Provider value = {contextValue}>
        <div className="image-gallery">
            <GalleryElementList 
            childPosition="before"
            listPrefix={<GalleryUploader 
                allowedFileTypes={props.allowedTypes}
                onFileError={handleFileError}
                uploadFile={UploadFile}
                />}
            listSuffix={props.listSuffix}
            >
            </GalleryElementList>
            <ImageCropperModal mode={props.mode}/>
            <ImageUploadErrorDisplay />
        </div>
        {/* <div>{debugSelectList(selectedImages)}</div> */}
        {mappedUploads}
        </ImageGalleryContext.Provider>
    )

})

export default Gallery;



const debugSelectList = (select:any) => {
    return (
        <div>
            {select.map((s:any) => {
                return (
                    <div>{s}</div>
                )
            })}
        </div>
    )
}