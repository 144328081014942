import { Select } from "antd"
import FormattedMessage from "components/common/FormattedMessage"
import _ from "lodash"
import { useEffect, useMemo } from "react"
import useUserSettings from "services/hooks/useUserSettings"

type InfinityListSortOption = {
    value: string,
    label: React.ReactNode,
}

type InfinityListSortSettingsProps = {
    sortOptions: InfinityListSortOption[],
}

export const SortSettingsCreatedAt: InfinityListSortOption[] = [
    {
        value: "-created_at",
        label: <FormattedMessage 
            id="tools.list.sort.createdAt.newestFirst"
            defaultMessage="Creation Date: Newest First" 
        />
    },
    {
        value: "created_at",
        label: <FormattedMessage 
            id="tools.list.sort.createdAt.oldestFirst"
            defaultMessage="Creation Date: Oldest First" 
        />
    }
]

export const SortSettingsUpdatedAt: InfinityListSortOption[] = [
    {
        value: "-updated_at",
        label: <FormattedMessage 
            id="tools.list.sort.updatedAt.newestFirst"
            defaultMessage="Update Date: Newest First" 
        />
    },
    {
        value: "updated_at",
        label: <FormattedMessage 
            id="tools.list.sort.updatedAt.oldestFirst"
            defaultMessage="Update Date: Oldest First" 
        />
    }
]

export const SortSettingsPublishAt: InfinityListSortOption[] = [
    {
        value: "-publish_at",
        label: <FormattedMessage 
            id="tools.list.sort.publishAt.newestFirst"
            defaultMessage="Publish Date: Newest First" 
        />
    },
    {
        value: "publish_at",
        label: <FormattedMessage 
            id="tools.list.sort.publishAt.oldestFirst"
            defaultMessage="Publish Date: Oldest First" 
        />
    }
]


const InfinityListSortSettings = ({sortOptions, ...props}:InfinityListSortSettingsProps) => {

    const { updateInterfaceSettings, interfaceSettings } = useUserSettings();

    useEffect(() => {

        if (!interfaceSettings?.creationList?.sort) {
            const updatedSettings = _.cloneDeep(interfaceSettings);
            if (!updatedSettings.creationList) updatedSettings.creationList = {};
            updatedSettings.creationList.sort = "-created_at";
            updateInterfaceSettings(updatedSettings);
        }

        }, [interfaceSettings]);

    const mappedOptions = useMemo(() => {

        return sortOptions.map((option) => {
            return <Select.Option value={option.value} key={option.value}>
                {option.label}
            </Select.Option>
        })

    }, [sortOptions])

    const handleSortChange = (value: string) => {
        const updatedSettings = _.cloneDeep(interfaceSettings);
        if (!updatedSettings.creationList) updatedSettings.creationList = {};
        updatedSettings.creationList.sort = value;
        updateInterfaceSettings(updatedSettings);
    }

    return (
        <Select 
            onChange={handleSortChange}
            style={{width: "250px", maxWidth: "100%"}}
            popupClassName="select-with-small-text-in-dropdown"
            value={interfaceSettings?.creationList?.sort}
        >
            {mappedOptions}
        </Select>
    )

}

export default InfinityListSortSettings;