const ColorBox = ({color}:{color:string}) => {
    return (
        <div style={{
            height: 40,
            backgroundColor: color,
            border: '1px solid #ccc',
            borderRadius: 3,
            marginRight: 5,
            flexGrow: 1
        }} />
    )
}

const WebsiteColorThemeDisplay = ({theme}:{theme:any}) => {

    return (
        <div style={{display: "flex", flexDirection: "row", height: "100%", width: "100%", alignItems: "center"}}>
            <ColorBox color={theme.primary} />
            <ColorBox color={theme.secondary} />
            <ColorBox color={theme.background} />
        </div>
    )


}

export default WebsiteColorThemeDisplay;