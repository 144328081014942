import { userLanguageSelector } from "modules/panel/config/selectors/user"
import { ReactNode, useEffect, useMemo, useRef, useState } from "react"
import useAPI, { APIErrorDisplay, callAPIFunction } from "services/hooks/useAPI"
import useSelectorWithParams from "services/hooks/useSelectorWithParams"
import parse from "html-react-parser";
import OnboardingControls from "providers/onboarding/controls";
import React from "react";
import FormattedMessage from "components/common/FormattedMessage";
import StandardButton from "components/common/StandardButton";
import Checkbox from "components/inputs/Checkbox";
import Markdown from "components/markdown/Markdown";
type StatementProps = {
    statement: string,
    assumeReadAfterTime?: number,
    onRead?: () => void,
    read: boolean,
    style: React.CSSProperties,
}

export const getStatement: callAPIFunction = (statement: string, language: string) => ({
    url: ({ getApiUrl }) => getApiUrl(`info/legal-content?locale=${language}&type=${statement}`),
    auth: false,
    customNormalizer: (response) => response.content || response,
})

const submitStatement: callAPIFunction = (code: string, statement: string, state: boolean) => ({
    url: ({ getApiUrl }) => getApiUrl(`user/statement`),
    method: "POST",
    body: {
        code,
        statement,
        state
    },
    dispatchName: `SUBMIT_STATEMENT`
})

export const StatementDisplay = React.forwardRef((props: StatementProps, ref: any) => {

    const handleScroll = (e: any) => {
        const { clientHeight: contentHeight, scrollHeight, scrollTop } = ref.current;
        const scrollPercentage = (scrollTop + contentHeight) / scrollHeight;

        if (scrollPercentage > 0.98) {
            props.onRead && props.onRead();
        }
    }

    const handleKeyDown = (e: any) => {
        if (e.keyCode === 35) {
            props.onRead && props.onRead();
        }
    }

    if (!props.statement || typeof props.statement !== "string") {
        return null;
    }

    return (
        <div
            ref={ref}
            tabIndex={0}
            //onWheel={handleScroll}
            onScroll={handleScroll}
            onTouchMove={handleScroll}
            style={props.style}
            onKeyDown={handleKeyDown}
        >
            <Markdown text={props.statement} />
        </div>
    )
})

type OnboardingStatementProps = {
    statementCode: string,
    required?: boolean,
    assumeReadAfterTime?: number,
    checkboxText?: ReactNode,
}

const OnboardingStatement = (props: OnboardingStatementProps) => {

    const [read, setRead] = useState(false);
    const { call: callSubmitStatement, error } = useAPI({});
    const language = useSelectorWithParams(userLanguageSelector);
    const { call: callGetStatement, data: statement } = useAPI({});
    const contentRef = useRef<any>(null);
    const [accepted, setAccepted] = useState(false);
    const statementText =statement?.data?.[0]?.attributes?.body

    useEffect(() => {
        callGetStatement({
            ...getStatement(props.statementCode, language),
            onFailure(response, additional, request) {
                callGetStatement(getStatement(props.statementCode, "en"))
            },
        });
    }, []);

    useEffect(() => {
        if (props.assumeReadAfterTime) {
            setTimeout(() => {
                setRead(true);
            }, props.assumeReadAfterTime);
        }
    }, [])

    const submit = async (accepted: boolean, nextClick: any) => {

        const res = await callSubmitStatement(submitStatement(props.statementCode, statementText, accepted));

        if (res) {
            if (res.status === 200 || res.status === 201) {
                nextClick();
                return true;
            }
        }

        console.error("Failed to submit statement", res);

        return false;

    }

    const disabledRequired = !accepted;
    const disabledOptional = !accepted;


    return (
        <>
            <StatementDisplay
                ref={contentRef}
                assumeReadAfterTime={parseInt(import.meta.env.VITE_SCROLLED_STATEMENT_SET_AS_READ_AFTER_N_SECONDS as any) * 1000}
                statement={statementText}
                onRead={() => setRead(true)}
                read={read}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "70vh",
                    overflow: "scroll",
                    wordWrap: "break-word",
                }}
            />
            <APIErrorDisplay error={error} />
            <OnboardingControls
                //nextDisabled={nextDisabled}
                hideButtons
                customButtons={(handleNextClick: any) =>
                    <>
                        {props.required ? (
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>     
                                    <Checkbox value={accepted} onChange={setAccepted}>{props.checkboxText}</Checkbox>
                                    <StandardButton
                                        onClick={() => submit(true, handleNextClick)}
                                        disabled={disabledRequired}
                                        type="primary"
                                    >
                                        <FormattedMessage
                                            id="modules.panel.component.scrolledStatement.Continue"
                                            defaultMessage="Continue"
                                        />
                                    </StandardButton>
                            </div>
                        ) : (
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>   
                                    <Checkbox value={accepted} onChange={setAccepted} >{props.checkboxText}</Checkbox>
                                    <StandardButton
                                        onClick={() => submit(accepted, handleNextClick)}
                                        type="primary"
                                    >
                                        <FormattedMessage
                                            id="modules.panel.component.scrolledStatement.Continue"
                                            defaultMessage="Continue"
                                        />
                                    </StandardButton>
                            </div>
                        )}
                    </>



                }
                nextStepText={<FormattedMessage
                    id="modules.panel.component.scrolledStatement.acceptAndContinue"
                    defaultMessage="Accept and continue"
                />}
            />
        </>

    )

}

export const OnboardingTermsOfServiceStatement = () =>
    <OnboardingStatement
        statementCode="terms_of_service"
        required={true}
        checkboxText={<FormattedMessage
            id="modules.panel.component.scrolledStatement.termsOfService.checkboxText"
            defaultMessage="I agree to the terms of service"
        />}
    />

export const OnboardingMarketingConsentsStatement = () =>
    <OnboardingStatement
        statementCode="marketing_consents"
        checkboxText={<FormattedMessage
            id="modules.panel.component.scrolledStatement.marketingConsents.checkboxText"
            defaultMessage="I agree to the marketing consents"
        />}
    />