import SuccessPage from "_components/OnboardingSuccessPage"
import FormattedMessage from "components/common/FormattedMessage"
import ConnectedOnboardingControls from "providers/onboarding/controls"

const OnboardingAISuccess = () => {

    return (
        <>
            <SuccessPage>
                <p>
                    <FormattedMessage
                        id="apps.smart.containers.onboarding.ai.success"
                        defaultMessage="You have successfully configured your AI assistant"
                    />
                </p>
            </SuccessPage>
            <ConnectedOnboardingControls relaxDontSubmit={true} />
        </>
    )

}

export default OnboardingAISuccess;