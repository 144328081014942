import { Divider } from "antd";
import { useMemo } from "react";
import useAPI from "services/hooks/useAPI";
import { GetIndustryData } from "state/industries/industriesSlice";
import { rulesetArrayMinMax } from "tools/relaxForm/relaxPresets";
import { TourismCategories } from "../config/industries/industryCategoriesDefinitions";
import { IndustryCategory } from "./elements/IndustryCategory";

export default function IndustryCategories () {

    const industry = useAPI(GetIndustryData("touristic"), true);

    const cats = useMemo(() => {
        const panes: any[] = [];
        Object.keys(TourismCategories).forEach((k: string) => {

            const cat = (TourismCategories as any)[k]
            panes.push(
            <>
            {cat.title}
                <IndustryCategory
                    industry="touristic"
                    category={k}
                    min={cat.min || 0}
                    max={cat.max || 0}
                    rules={rulesetArrayMinMax(cat.min || 0, cat.max || 0)} />
                
                <Divider />
            </>
            )
        })
        return panes;
    }, [])

    if (industry.loading || industry.error) return industry.StatusDisplay

    return (
        <div>
            {cats}
        </div>
    )


}