import { GetWebsitePriceList, PricingTemplate, ReorderService, Service, UpdateRowTitle } from "modules/site-generator/reducers/websitePricesSlice";
import { PriceTableProps } from "./PricingTable";
import useAPI from "services/hooks/useAPI";
import AwesomeIcon from "components/common/AwesomeIcon";
import { faGripDotsVertical } from "@fortawesome/pro-light-svg-icons";
import { Select } from "antd";
import { StandardTooltip } from "modules/panel/testing/StandardTooltip";
import { useEffect, useRef } from "react";
import dragula from "dragula";
import "./price-config.less";

const getIndexInParent = (el: any) =>
  Array.from(el.parentNode.children).indexOf(el);

const RowHeading = ({
  service,
  template,
  table,
}: {
  service: Service;
  template: PricingTemplate;
  table: PriceTableProps;
}) => {
  const updateTitle = useAPI(UpdateRowTitle(table.id, service.serviceId));
  const mappedServiceOptions =
    template &&
    template.services &&
    template.services.map((service: Service) => {
      return {
        label: service.title,
        value: service.title,
      };
    });

  const handleChangeServiceOption = (value: string) => {
    updateTitle.call({
      body: {
        title: value,
      },
    });
  };

  return (
    <div
      className="price-row-heading"
      data-price-table-service-id={service.serviceId}
    >
      <AwesomeIcon icon={faGripDotsVertical} size="2xl" />
      <Select
        style={{
          width: "100%",
        }}
        value={service.title}
        options={mappedServiceOptions}
        onChange={(value) => handleChangeServiceOption(value)}
        onSearch={(value) => handleChangeServiceOption(value)}
        showSearch
      />
      <StandardTooltip title={service.description} />
    </div>
  );
};

export const Rows = ({
  table,
  getPriceLists,
}: {
  table: PriceTableProps;
  getPriceLists: any;
}) => {
  const { services, template } = table;
  const listRef = useRef<HTMLDivElement>(null);
  const reorderServiceCall = useAPI({});

  useEffect(() => {
    let start;

    const d = dragula([], {
      revertOnSpill: true,
      moves: (el) => {
        if (!el) return false;
        start = getIndexInParent(el);
        return true;
      },
    });
    listRef.current && d.containers.push(listRef.current);

    d.on("drop", (el, target, source, sibling) => {
      const indexAtParent = getIndexInParent(el);
      const serviceId = el.getAttribute("data-price-table-service-id");
      const service = services.find(
        (s: any) => s.serviceId === parseInt(serviceId || "0")
      );

      if (service !== undefined) {
        const parentService = services[indexAtParent];
        const newOrder =
          parentService.order > service.order
            ? parentService.order + 1
            : parentService.order;

        reorderServiceCall
          .call(ReorderService(table.id, serviceId, newOrder))
          .then((res) => {
            if (res.status === 200) {
              getPriceLists.call(GetWebsitePriceList(table.category));
            }
          });
      }
    });

    return () => {
      d.destroy();
    };
  }, [table]);

  return (
    <div ref={listRef} className="price-row-headings">
      {services &&
        services.map &&
        services.map((service: Service) => {
          return (
            <RowHeading
              service={service}
              template={template}
              table={table}
              key={service.serviceId}
            />
          );
        })}
    </div>
  );
};
