import ActorCardSelect from "components/inputs/actorCardSelect/ActorCardSelect";
import { getProductLogo } from "providers/routing/layouts/components/product-logo/smallProductLogo";
import { useMemo } from "react";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { projectsSelector } from "state/projects/projectsSelectors";
import { subscriptionsArraySelector } from "state/subscriptions/subscriptionsSelectors";


const FilterByProject = (props:any) => {

    const projects = useSelectorWithParams(projectsSelector)
    const mappedProjects = useMemo(() => {

        if (!projects) return [];


        const mp = Object.values(projects).map((project: any) => {
            return {
                value: project.id,
                name: project.name,
                image: project.image
            }
        })

        mp.push({
            value: "all",
            name: "All Projects",
            image: null,
        })

        return mp


    }, [projects]);

    return (
        <ActorCardSelect
            actors={mappedProjects}
            style={{ width: "200px" }}
            value={props.value}
            onChange={(v:any) => props.onChange(v)}
        />
    );

}

const FilterByProduct = (props:any) => {

    const subscriptions = useSelectorWithParams(subscriptionsArraySelector);

    const products = useMemo(() => {

        const p = new Set();

        subscriptions && subscriptions.forEach((sub:any) => {
            if (sub.product) {
                p.add(sub.product);
            }
        })

        const mp = Array.from(p).map((product) => {
            return {
                value: product,
                name: product,
                image: getProductLogo(product),
            }
        })

        mp.push({
            value: "all",
            name: "All Products",
            image: null,
        })

        return mp;

    }, [subscriptions]);


    return (
        <ActorCardSelect
            actors={products}
            style={{ width: "200px" }}
            value={props.value}
            onChange={(v:any) => props.onChange(v)}
        />
    );

}


const SubscriptionListQuery = (props: {value: any, onChange: any}) => {
    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
        }}>
            <FilterByProduct value={props.value?.product} onChange={(v) => props.onChange({...props.value, product: v})}/>
            <FilterByProject value={props.value?.project} onChange={(v) => props.onChange({...props.value, project: v})}/>
        </div>
    );
}

export default SubscriptionListQuery;