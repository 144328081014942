import FormattedMessage from "components/common/FormattedMessage"
import CreationCard from "./CreationCard"
import { useSimpleModalWithAction } from "services/hooks/useModal";
import { deleteCreation, newCreation, patchCreation, unpublishCreation } from "./creationsSlice";
import DateTimeInput from "components/inputs/DateTimeInput";
import { useEffect, useRef, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { extractValueFromStateSetter } from "services/helpers/reactHelpers";
import { Select } from "antd";
import { CreationTypes } from "./CreationConfig";
import { useCreationGlobal } from "./CreationGlobalContext";
import CreationCreationTool from "./CreationCreationTool";

export const AskToDeleteCreation = (props?:any) => {

    const [open, contextHolder] = useSimpleModalWithAction({
    });
    const {closeCreation} = useCreationGlobal();

    const handleOpen = (creationId: string) => {
        open({
            title: <FormattedMessage
                id="tools.creations.actions.delete.confirm.title"
                defaultMessage="Delete creation"
            />,
            content: (
                <div>
                    <FormattedMessage
                    id="tools.creations.actions.delete.confirm.message"
                    defaultMessage="Are you sure you want to delete this creation?"
                    />
                </div>
                ),
            onOk: () => {
            },
            callAPI: {...deleteCreation(creationId), onSuccess: () => {
                closeCreation();
            }}
        })
    }

    return {
        askToDeleteCreation: handleOpen,
        open: handleOpen,
        contextHolder,
    }

}


export const AskToUnpublishCreation = (props?:any) => {

    const [open, contextHolder] = useSimpleModalWithAction({
    });
    const {closeCreation} = useCreationGlobal();

    const handleOpen = (creationId: string) => {
        open({
            title: <FormattedMessage
                id="tools.creations.actions.unpublish.confirm.title"
                defaultMessage="Cancel publication"
            />,
            content: (
                <div>
                    <FormattedMessage
                    id="tools.creations.actions.unpublish.confirm.message"
                    defaultMessage="Are you sure you want to cancel publication and convert this creation back into to a draft?"
                    />
                </div>
                ),
            onOk: () => {
            },
            callAPI: {...unpublishCreation(creationId), onSuccess: () => {
                closeCreation();
            }}
        })
    }

    return {
        askToUnpublishCreation: handleOpen,
        open: handleOpen,
        contextHolder,
    }

}

const DateInputWrapper = (props:any) => {

    const [date, setDate] = useState<Dayjs>(dayjs(props.date || undefined));

    const handleChange = (newDate: Dayjs) => {
        setDate(newDate);
        props.onChange && props.onChange(newDate);
    }

    return (
        <DateTimeInput 
        value={date ? date.format("YYYY-MM-DD HH:mm:ss") : props.date}
        onChange={handleChange}/>   
    )


}


export const AskToRescheduleCreation = (props?:any) => {

    const [open, contextHolder] = useSimpleModalWithAction({
        width: "800px"
    });

    const [date, setDate] = useState<Dayjs>();

    const handleOpen = (creationId: string, initialDate: any, onOk?: any) => {

        setDate(initialDate);
        const modal = open({
            title: <FormattedMessage
                id="tools.creations.actions.reschedule.confirm.title"
                defaultMessage="Reschedule Creation"
            />,
            content: (
                <div>
                    <FormattedMessage
                    id="tools.creations.actions.reschedule.confirm.message"
                    defaultMessage="Reschedule this creation?"
                    />
                    <CreationCard creationId={creationId} mode="info"/>
                    <DateInputWrapper date={initialDate} onChange={setDate}/>
                </div>
                ),
            onOk: () => {
                onOk && onOk();
            },
            callAPI: () => {

                const d:any = extractValueFromStateSetter(setDate);

                return {
                    ...patchCreation(creationId), body: {
                        content: {
                            publication_date: d ? dayjs(d).format("YYYY-MM-DD HH:mm:ss")  : "err"
                        }
                    }
                }

            }
        })

        return modal;

    }

    return {
        askToRescheduleCreation: handleOpen,
        open: handleOpen,
        contextHolder,
    }

}



export const AskToCreateCreation = () => {

    const [open, contextHolder] = useSimpleModalWithAction({
        width: "800px"
    });

    const {openCreation} = useCreationGlobal();
    const data = useRef<any>({});

    const handleOpen = (initialDate: any, onOk?: any) => {

        data.current.date = initialDate;

        const modal = open({
            title: <FormattedMessage
                id="tools.creations.actions.create.confirm.title"
                defaultMessage="New Creation"
            />,
            content: <CreationCreationTool 
                initialDate={initialDate}
                type={["post", "image"]}
                onChange={(d:any) => data.current = d}
            />,
            onOk: (res) => {
                openCreation(res?.data?.id)
            },
            callAPI: () => {

                const {date, type} = data.current;
                const tp = type.split(".");

                return {
                    ...newCreation(type), 
                    body: {
                        type: tp[0],
                        content: {
                            publication_date: date || "err",
                            publication_type: "planned",
                            type: tp[1]
                        }
                    },
                }

            }
        })

        return modal;

    }

    return {
        ask: handleOpen,
        open: handleOpen,
        contextHolder,
    }

}