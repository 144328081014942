import dayjs, { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/es/generate/dayjs';
import generatePicker from 'antd/es/date-picker/generatePicker';


const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

export default DatePicker;


type DatePickerWithAdjustProps = {
    value?: any,
    onChange?: (date: any) => any,
} & any


export const DatePickerWithAdjust = (props: DatePickerWithAdjustProps) => {

    const {value, onChange, format, ...rest} = props;

    const v = value ? dayjs(value) : null;
    const f = format || "YYYY-MM-DD"

    return (
        <DatePicker
        value={v}
        onChange={(date) =>
            onChange && onChange(date ? date.format(f) : undefined)
        }
        {...rest}
        />
    )

}

export const WeekPicker = (props: DatePickerWithAdjustProps) => {

    const {value, onChange, format, ...rest} = props;

    const v = value ? dayjs(value).startOf("week") : null;
    const f = format || "YYYY-MM-DD"

    return (
        <DatePicker.WeekPicker
            {...rest}
            format={f}
            value={v}
            onChange={(date) =>{
                const d = date ? dayjs(date) : null;
                const weekStart = d && d.startOf("week").format(f);
                onChange && onChange(weekStart)
            }}
        />
    )

}