import { Select} from "antd";
import { Font, fonts} from "modules/site-generator/config/fonts"
import { useEffect, useMemo } from "react";

export type FontSelectProps = {
    type: string;
    templateId: number;
    fontClass?: any;
    onChange?:any;
}

export default function FontSelect (props:FontSelectProps) {

    useEffect(() => {
      //Injecting fonts

      const linkId = `font-${props.type}-style-${props.templateId}`;
    
      if (document.getElementById(linkId) === null) {


        //console.log("Injecting fonts")
        const style = document.createElement('style');
        document.head.appendChild(style);
        style.setAttribute('id', `font-style`);

        let fontsFamily = '';
        const link = document.createElement('link');
        link.setAttribute('id', linkId);

        document.head.appendChild(link);

        link.setAttribute('rel', 'stylesheet');
        fonts.map((f:Font) => {
            const itemName = f.font.replace(/\s/g, "-").toLowerCase();
            const itemNameForRequest = f.font.replace(/\s/g, "+");
            fontsFamily += 'family=' + itemNameForRequest + '&';

            return (
                (style as any).sheet.insertRule(`.template-font-${itemName} {font-family: ${f.font}, sans-serif !important;}`)
            );
        });

        link.setAttribute('href', `https://fonts.googleapis.com/css2?${fontsFamily}&amp;display=swap`);

    }
    
    }, [])
    

    const mappedFonts = useMemo(() => {
        return fonts.map((f:Font, index: number) => {

            return {
                value: f.font,
                label: (
                        <div className={`template-font-${f.name} template-font-item`}
                            style={{fontFamily: f.font}}
                        >{f.name}</div>
                )
            }
                
        })

    }, [])

    return (
        <>
        <Select
            onChange={(selected:any) => props.onChange(selected)} 
            options={mappedFonts}
            value={props.value}
        />
        </>
    )
}