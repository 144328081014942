import { Radio, Space, Typography } from "antd";
import { useCalendar } from "./Calendar";
import { faArrowLeftToLine, faCalendar, faCalendarDays, faCalendarWeek, faChevronLeft, faChevronRight, faCompress, faMaximize, faMinimize, faSidebar } from "@fortawesome/pro-light-svg-icons";
import StandardButton from "components/common/StandardButton";
import { StandardRow } from "components/layout/StandardGrid";
import { Title } from "components/common/StandardText";
import { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/es/date-picker/generatePicker';
import "./calendar-controls.less";
import useSimpleBreakpoint from "services/hooks/useSimpleBreakpoint";
import { useEffect } from "react";
import AwesomeIcon from "components/common/AwesomeIcon";
import FormattedMessage from "components/common/FormattedMessage";
import { useToolbar } from "components/toolbar/Toolbar";


const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);


const SiderToggle = () => {

    const { isShowingSider, setIsShowingSider, hasValidSider } = useCalendar();
    const breakpoint = useSimpleBreakpoint();
    const sizeIndex = breakpoint[1];
    const isSmall = sizeIndex < 3;
    const { mode } = useToolbar();

    if (!hasValidSider) return null;
    if (isSmall && mode!=="drawer") return null;

    const label = isShowingSider ? (
        <FormattedMessage id="calendar.toolbar.sidebar.hideSidebar" defaultMessage="Hide Sidebar" />
    ) : (
        <FormattedMessage id="calendar.toolbar.sidebar.showSidebar" defaultMessage="Show Sidebar" />
    )


    return (
        <StandardButton
            icon={faSidebar}
            onClick={() => setIsShowingSider(s => !s)}
            block={mode==="drawer"}
        >
            {mode === "drawer" ? label : null}
        </StandardButton>
    )

}

const FitToggle = () => {

    const { fitToScreen, setFitToScreen } = useCalendar();
    const { mode } = useToolbar();
    const breakpoint = useSimpleBreakpoint();
    const sizeIndex = breakpoint[1];
    const isSmall = sizeIndex < 3;

    const label = fitToScreen ? (
        <FormattedMessage id="calendar.toolbar.fit.fitToContent" defaultMessage="Extend" />
    ) : (
        <FormattedMessage id="calendar.toolbar.fit.fitToScreen" defaultMessage="Fit" />
    )

    if (isSmall) return null;

    return (
        <StandardButton
            icon={fitToScreen ? faMaximize : faMinimize}
            onClick={() => setFitToScreen(f => !f)}
        >
            {mode === "drawer" ? label : null}
        </StandardButton>
    )

}

const Date = () => {

    const { date, setDate, mode, setMode } = useCalendar();
    const breakpoint = useSimpleBreakpoint();
    const sizeIndex = breakpoint[1];
    const { mode: toolbarMode } = useToolbar();
    const isSmall = sizeIndex < 3;


    return (
        <div style={{
            display: "flex",
        }}>
            <StandardButton
                key="prev"
                icon={faChevronLeft}
                onClick={() => {
                    if (mode === "day") setDate(date.subtract(1, "day"));
                    if (mode === "week") setDate(date.subtract(1, "week"));
                    if (mode === "month") setDate(date.subtract(1, "month"));
                }}
            />
            <DatePicker
                key="picker"
                value={date}
                onChange={(date) => setDate(date)}
                picker={mode === 'day' ? 'date' : mode === 'week' ? 'week' : 'month'}

                format={(d) => {
                    if (mode === "month") {
                        if (toolbarMode === "drawer") return d.format("MMMM YYYY");
                        return isSmall ? d.format("MMMM YYYY") : d.format("MMMM");
                    }
                    if (mode === "week") {
                        const firstDayOfTheWeek = d.startOf("week");
                        const lastDayOfTheWeek = d.endOf("week");
                        const sameMonth = firstDayOfTheWeek.month() === lastDayOfTheWeek.month();
                        const sameYear = firstDayOfTheWeek.year() === lastDayOfTheWeek.year();
                        if (sameMonth) return `${firstDayOfTheWeek.format("DD")} - ${lastDayOfTheWeek.format("DD MMMM YYYY")}`
                        if (sameYear) return `${firstDayOfTheWeek.format("DD MMMM")} - ${lastDayOfTheWeek.format("DD MMMM")} ${lastDayOfTheWeek.format("YYYY")}`
                        return `${firstDayOfTheWeek.format("DD.MM.YYYY")} - ${lastDayOfTheWeek.format("DD.MM.YYYY")}`
                    }
                    return d.format("DD.MM.YYYY")
                }}
            />
            <StandardButton
                key="next"
                icon={faChevronRight}
                onClick={() => {
                    if (mode === "day") setDate(date.add(1, "day"));
                    if (mode === "week") setDate(date.add(1, "week"));
                    if (mode === "month") setDate(date.add(1, "month"));
                }}
            />
        </div>
    )

}

const Year = () => {

    const { date, setDate, setMode } = useCalendar();
    const breakpoint = useSimpleBreakpoint();
    const sizeIndex = breakpoint[1];
    const isSmall = sizeIndex < 3;



    return (
        isSmall ? null : (
            <div style={{
                display: "flex",
                flexGrow: 1,
            }}>
                <Title center noMargins className="year-display">{date.format("YYYY")}</Title>
            </div>)

    )
}


const weekLablelSall = <AwesomeIcon icon={faCalendarWeek} />
const weekLabelLarge = <><AwesomeIcon icon={faCalendarWeek} /><FormattedMessage id="common.time.week" defaultMessage="Week" /></>
const monthLabelSmall = <AwesomeIcon icon={faCalendarDays} />
const monthLabelLarge = <><AwesomeIcon icon={faCalendarDays} /><FormattedMessage id="common.time.month" defaultMessage="Month" /></>

const Mode = () => {


    const { date, setDate, setMode } = useCalendar();
    const breakpoint = useSimpleBreakpoint();
    const sizeIndex = breakpoint[1];
    const isSmall = sizeIndex < 3;
    const { mode } = useToolbar();

    if (mode === "drawer") return (
        <>
            <Radio.Group
                value={mode}
                style={{width: "1"}}
            >
                <Radio.Button value="week" style={{width: "50%"}} onClick={() => setMode('week')}>{weekLabelLarge}</Radio.Button>
                <Radio.Button value="month" style={{width: "50%"}} onClick={() => setMode('month')}>{monthLabelLarge}</Radio.Button>
            </Radio.Group>
        </>
    )

    return (
        <>
            <Radio.Group
                value={mode}
            >
                <Radio.Button value="week" onClick={() => setMode('week')}>{isSmall ? <AwesomeIcon icon={faCalendarWeek} /> : <><AwesomeIcon icon={faCalendarWeek} /><FormattedMessage id="common.time.week" defaultMessage="Week" /></>}</Radio.Button>
                <Radio.Button value="month" onClick={() => setMode('month')}>{isSmall ? <AwesomeIcon icon={faCalendarDays} /> : <><AwesomeIcon icon={faCalendarDays} /><FormattedMessage id="common.time.month" defaultMessage="Month" /></>}</Radio.Button>
            </Radio.Group>
        </>
    )

}

const CalendarControls = {
    SiderToggle,
    FitToggle,
    Date,
    Mode, 
    Year,
}

export default CalendarControls;