import React, { FunctionComponent, ReactNode } from "react";
import { RouteConfig } from "providers/routing/routes/routes";
import {
  Layout,
  Config,
} from "providers/routing/layouts/templates/templates";
import TwoColumnsContent from "providers/routing/layouts/templates/two-columns/content";
import { TwoColumnsLayoutSidebarProps } from "providers/routing/layouts/templates/two-columns/sidebar";
import backgroundOverlay from "providers/routing/layouts/templates/two-columns/background-overlay.svg";

export type TwoColumnsLayoutRouteConfig = {
  layout: Layout.TwoColumns;
  content: ReactNode;
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  sidebar?: TwoColumnsLayoutSidebarProps | ReactNode;
  sidebarPosition?: "right" | "left";
} & RouteConfig;

const TwoColumnsLayout: FunctionComponent = ({ children }) => (
  <div className="two-columns-layout">
    <div
      className="background-overlay"
      style={{ backgroundImage: `url(${backgroundOverlay})` }}
    />
    {children}
  </div>
);

export default {
  key: Layout.TwoColumns,
  layout: TwoColumnsLayout,
  content: TwoColumnsContent,
} as Config;
