import FormattedMessage from "components/common/FormattedMessage";
import StandardBox from "components/common/StandardBox";
import StandardButton from "components/common/StandardButton";
import useAPI from "services/hooks/useAPI";
import { StandardModal, useStandardModal } from "services/hooks/useModal";
import RelaxField from "tools/relaxForm/RelaxField";
import { Input, TabsProps, Tooltip } from "antd";
import { ruleMin } from "tools/relaxForm/relaxPresets";
import useRelaxData from "tools/relaxForm/useRelaxData";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import Catalog from "./Catalog";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { CreateCatalog, GetCatalogs, ProductsCatalogsArraySelector } from "state/productsCatalogSlice";
import Tabs from "_components/tabs/tabs";


export default function ProductCatalogs() {
  const { } = useAPI(GetCatalogs, true);
  const catalogs = useSelectorWithParams(ProductsCatalogsArraySelector);

  const createCatalog = useAPI(CreateCatalog);
  const [ref, open, close] = useStandardModal();
  const rd = useRelaxData();
  const { currentValue, setCurrentValue, errors, setErrors } = rd;

  const catalogsItems = catalogs.map(catalog => {
    return {
      title: catalog.name,
      key: catalog.id,
      content: <Catalog catalog={catalog} />
    };
  });

  return (
    <>
        <StandardModal
          ref={ref}
          onCancel={close}
          title={
            <FormattedMessage
              id="panel.productCatalogs.create.productsCatalogModal.title"
              defaultMessage="Type new catalog name"
            />
          }
          onOk={() => {
            if (errors === null && currentValue != null) {
              createCatalog
                .call({
                  body: {
                    name: currentValue,
                  },
                })
                .then((res) => {
                  if (res.status == 200) {
                    close();
                    setCurrentValue("");
                    setErrors([]);
                  }
                });
            } else {
              setErrors([
                <FormattedMessage
                  id="panel.productCatalogs.create.productsCatalogModal.error"
                  defaultMessage="New catalog name is required"
                />,
              ]);
            }
          }}
        >
          <div>
            <RelaxField
              relaxData={rd}
              hideStatus={true}
              label={
                <FormattedMessage
                  id="panel.productCatalogs.create.productsCatalogModal.description"
                  defaultMessage="Catalog name"
                />
              }
              rules={[ruleMin(3)]}
            >
              <Input />
            </RelaxField>
          </div>
        </StandardModal>

        <Tabs
          tabBarExtraContent={{
            right: (
              <Tooltip title={
                <FormattedMessage
                  id="panel.productCatalogs.create.Catalog.tooltip"
                  defaultMessage="Create catalog"
                />
              }>
              <StandardButton
                icon={faPlus}
                size="middle"
                shape="circle"
                onClick={open} />
              </Tooltip>

            ),
          }}
          panes={catalogsItems}
        />
    </>
  );
}
