import { Input } from "antd";
import { StandardFullColumn, StandardHalfFullColumn, StandardRow } from "components/layout/StandardGrid";
import FormattedMessage from "components/common/FormattedMessage";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import YesNoInput from "_components/forms/yes-no-input";
import OnboardingControls from "providers/onboarding/controls";
import { ruleForbiddenDomains, ruleKeywordRegex, ruleMax } from "tools/relaxForm/relaxPresets";
import { KeywordsConfig } from "modules/panel/config/KeywordsConfig";
import URLInput from "components/inputs/URLInput";
import { HasBusinessSurveyAdsSettingsFilledSelector, SmartSelector, smartSlice } from "../smartSlice";
import { currentProjectIdSelector } from "services/store/scopeSlice";



export default function OnboardingDomainAndKeyword() {

    const projectId = useSelectorWithParams(currentProjectIdSelector);
    const fulfilled = useSelectorWithParams(HasBusinessSurveyAdsSettingsFilledSelector);

    return (
        <RelaxMultiform
            name="businessSettings"
            initialValuesSelector={SmartSelector}
            callAPI={{
                //url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/smarts/${smartId}`, 2),
                url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/smart`, 1),
                method: "PATCH",
                successDispatch: smartSlice.actions.get,

            }}
            finalizeSubmit={(values) => ({ ...values, project_id: projectId })}
        >

            <StandardRow>
                <StandardFullColumn>
                    <RelaxSingleField
                        name={["initial_keywords", "initial_keyword"]}
                        label={KeywordsConfig.get("initial")?.keyword?.displayName}
                        rules={[ruleKeywordRegex, ruleMax(20)]}
                    >
                        <Input />
                    </RelaxSingleField>
                </StandardFullColumn>
                <StandardFullColumn>
                    <RelaxSingleField
                        name="website"
                        label={
                            <FormattedMessage
                                id="apps.smart.forms.businessDetails.websiteAddress"
                                defaultMessage="Your website address"
                            />
                        }
                        required={true}
                        initialValuesSelector={SmartSelector}
                        rules={[ruleForbiddenDomains(["facebook.com", "instagram.com", "fb.com"])]}
                    >
                        <URLInput />
                    </RelaxSingleField>
                </StandardFullColumn>
            </StandardRow>
            <StandardRow>
            <StandardHalfFullColumn>
                    <RelaxSingleField
                        name="has_fans"
                        label={
                            <FormattedMessage
                                id="apps.smart.components.adsSettings.fakeFans"
                                defaultMessage="Did you buy fake fans?"
                            />
                        }
                        required={true}
                        initialValue={"no"}
                    >
                        <YesNoInput />
                    </RelaxSingleField>
                </StandardHalfFullColumn>
                <StandardHalfFullColumn>
                    <RelaxSingleField
                        name="new_market"
                        label={
                            <FormattedMessage
                                id="apps.smart.components.adsSettings.newMarket"
                                defaultMessage="Is this a new market?"
                            />
                        }
                        required={true}
                        initialValue={"no"}
                    >
                        <YesNoInput />
                    </RelaxSingleField>
                </StandardHalfFullColumn>
            </StandardRow>
            <OnboardingControls relaxDontSubmit = {true} nextDisabled={(!fulfilled)}/>
        </RelaxMultiform>
        
    )

}