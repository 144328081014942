import { StandardBox } from "components/layout/StandardBoxes";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import useAPI from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { getSubscriptions } from "state/subscriptions/subscriptionsSlice";
import { subscriptionSelector } from "state/subscriptions/subscriptionsSelectors";
import SubscriptionContextProvider, { SubscriptionContext, useSubscriptionContext } from "./SubscriptionContext";
import { StandardRow } from "components/layout/StandardGrid";
import SmallProductLogo from "providers/routing/layouts/components/product-logo/smallProductLogo";
import TemplateRow from "tools/infinityList/TemplateRow";
import { HorizontalCenter } from "components/common/Center";
import { Empty } from "antd";
import { paymentColumns } from "./subscriptionListColumns";
import { StandardProjectCard } from "components/common/StandardActorCard";

function SubscriptionDetails() {

    const { subscription } = useSubscriptionContext();
    const s = subscription;

    const invoices = useMemo(() => {

        if (!subscription) return [];
        return subscription.stripe_invoices && subscription.stripe_invoices.map(invoice => {
            return (
                <li key={invoice.id}>
                    {`Invoice ${invoice.id} ->  ${invoice.amount_due}`}
                </li>
            )
        });

    }, [subscription])



    if (!subscription) return null;

    return (
        <>
            <StandardRow>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "10px",
                }}>
                    <StandardBox style={{
                        height: "120px",
                        display: "grid",
                        placeItems: "center"
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "10px"
                        }}>
                            <SmallProductLogo
                                name={s.combinedProduct?.product}
                                style={{
                                    width: "50px",
                                    height: "50px",
                                }}
                            />
                            {"  "}<div className="subscription-cell capitalize">{s.name}</div>
                        </div>
                    </StandardBox>

                    <StandardBox style={{
                        height: "120px",
                        display: "grid",
                        placeItems: "center"
                    }}>
                        <StandardProjectCard projectId={s?.project} />
                    </StandardBox>

                    <StandardBox style={{
                        height: "120px",
                        display: "grid",
                        placeItems: "center"
                    }}>

                        <ul>
                            <li>Id: {s.id}</li>
                            <li>stripe_status: {s.stripe_status}</li>
                        </ul>

                    </StandardBox>

                </div>
            </StandardRow>

            <StandardBox>

                <ul>
                    <li>Name: {s.name}</li>
                    <li>Id: {s.id}</li>
                    <li>Expiry: {s.expiry}</li>
                    <li>BillingCycle: {s.billingCycle}</li>
                    <li>stripe_status: {s.stripe_status}</li>

                    <li>Total due: {s.totalDue}</li>
                    <li>Oldest missed payment: {s.oldestMissedPayment}</li>

                </ul>

                <div>
                    <h3>Invoices</h3>
                    <ul>
                        {invoices}
                    </ul>
                </div>

            </StandardBox>
        </>
    );
}

const SubscriptionDetailsPage = () => {

    const { subscriptionId } = useParams<{
        subscriptionId: string
    }>();

    const subscription = useSelectorWithParams(subscriptionSelector(subscriptionId))
    const subscriptionCall = useAPI(getSubscriptions(), true)

    const value = {
        subscription
    }

    if (!subscription) return subscriptionCall.StatusDisplay

    return (
        <SubscriptionContext.Provider value={value}>
            <SubscriptionDetails />
        </SubscriptionContext.Provider>
    )


}


export default SubscriptionDetailsPage;