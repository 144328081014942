import { Spin } from "antd";
import { HorizontalCenter } from "components/common/Center";
import { HasUserSmartProject, smartSlice } from "modules/smart/smartSlice";
import { useOnboarding } from "providers/onboarding/onboarding-context";
import { useEffect } from "react";
import useAPI, { APIErrorDisplay } from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";

export default function PrepareSmartProject() {
  const {nextStep} = useOnboarding();
  const hasUserSmartProject = useSelectorWithParams(HasUserSmartProject);
  const { call, loading, error, data } = useAPI({
    url: ({ getApiUrl, projectId }) =>
      getApiUrl(`projects/${projectId}/smart`, 1),
    method: "POST",
    successDispatch: smartSlice.actions.get,
  });

  useEffect(() => {
    if (hasUserSmartProject === false) {
      call().then(() => nextStep())
    }
  }, [data, hasUserSmartProject]);

  if (loading) {
    return (
      <HorizontalCenter>
        <Spin />
      </HorizontalCenter>
    );
  }

  if (error) {
    return <APIErrorDisplay error={error} />;
  }

}
