import { AutoComplete, Input } from 'antd';
import React, { useMemo } from 'react'
import { provinces } from './Provinces';
import { AddressProps } from './AddressWithMap';

type Props = {
    initialValues: AddressProps
    onChange?: any;
    onBlur?: any;
    value?: any
}

export default function RegionSelect(props: Props) {

    const checkCountryFormat = (country:string) =>{
        if(country === "PL" || country === "Poland" || country === "Polska") return "PL"
        if(country === "DE" || country === "Germany" || country === "Niemcy") return "DE"

        return country;
    }

    const regionOptions = useMemo(() => {
        const formattedCountry = checkCountryFormat(props.initialValues?.country);
        
        if (formattedCountry && provinces[formattedCountry]) {
            return provinces[formattedCountry];
        }
        
        return [];
    }, [props.initialValues?.country]);

    const options = regionOptions.map((region:any) => ({
        value: region.label,
        label: region.label,
    }));

    return (
        <>
        {options.length > 0 ? (
                <AutoComplete
                    options={options}
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    filterOption={(inputValue, option: any) =>
                        option?.label.toLowerCase().includes(inputValue.toLowerCase())
                    }
                    onSelect={(value) => {
                        const selectedRegion = regionOptions.find((region: any) => region.label === value);
                        if (selectedRegion) {
                            props.onChange(selectedRegion.label);
                            props.onBlur(selectedRegion.label);
                        }
                    }}
                />
            ) : (
                <Input />
            )}
        </>
    )
}
