import { faCheck } from "@fortawesome/pro-light-svg-icons";
import { Button, Input } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import StandardButton from "components/common/StandardButton";
import { RegenerateCreationText } from "modules/panel/state/AISlice";
import { AiCacheFieldSelector, GetAiCache } from "modules/panel/state/lexiconSlice";
import { useMemo } from "react";
import useAPI from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { useCreationEditor } from "tools/creationEditor/CreationEditor";
import { creationSelector } from "tools/creationEditor/creationsSlice";

type Props = {
    creationId: string,
    field: string,
    onSelect?: (text: string) => void,
}

function ReplaceFromLexicon(props: Props) {


    const creation = useSelectorWithParams([creationSelector, props.creationId])
    const fieldName = `${creation?.type}|${props.field}`

    const RegenText = useAPI(RegenerateCreationText(props.creationId))
    const fieldValues = useSelectorWithParams([AiCacheFieldSelector, fieldName])
    const lexiconCall = useAPI(GetAiCache(fieldName), true)

    if (!creation) return null;

    const handleReplace = (text: string) => {
        props.onSelect && props.onSelect(text);
    }

    const handleGenerate = () => {
        RegenText.call()
    }

    const mappedFields = useMemo(() => fieldValues && fieldValues.map((field: any) => (
        <div style={{
            padding: "5px",
            position: "relative",
        }}
            className="standard-border"
        >
            {/* <Input.TextArea 
            value={field.content} 
            autoSize={{ minRows: 3, maxRows: 8 }}
            /> */}
            <div>
                {field.content}
            </div>
            <div style={{
                position: "absolute",
                bottom: "-4px",
                right: "-4px",
            }}>
                <StandardButton 
                    type={"primary"}
                    size="small"
                    style={{
                        height: "30px",
                        maxHeight: "30px",
                        minHeight: "30px",
                    }}
                    onClick={() => handleReplace(field.content)} 
                    >
                        <FormattedMessage 
                            id={"tools.textEditor.replaceFromLexicon.useButton.label"} 
                            defaultMessage={"Use"}                    
                        />
                </StandardButton>
            </div>
        </div>
    )), [fieldValues])

    if (lexiconCall.loading || lexiconCall.error) return lexiconCall.StatusDisplay

    return (
        <div>
            <StandardButton
                onClick={handleGenerate}
            >
                <FormattedMessage 
                    id={"tools.textEditor.replaceFromLexicon.generateButton.label"} 
                    defaultMessage={"Generate New Version"}                    
                />
            </StandardButton>
            <div style={{
                display: "flex",
                flexDirection: "column",
            }}>
                {mappedFields}
            </div>
        </div>
    );
}

export default ReplaceFromLexicon;