import { Requirement } from "modules";
import {
  getUrl,
  ONBOARDING_CATEGORY_ACTIVE,
  ONBOARDING_CATEGORY_ACTIVE_EVENTS,
  ONBOARDING_CATEGORY_ACTIVE_RECREATION,
  ONBOARDING_CATEGORY_ACTIVE_SPORT,
  ONBOARDING_CATEGORY_ATMOSPHERE,
  ONBOARDING_CATEGORY_BEAUTY,
  ONBOARDING_CATEGORY_CLIMATE,
  ONBOARDING_CATEGORY_DISABLED_FACILITIES,
  ONBOARDING_CATEGORY_FEEDING,
  ONBOARDING_CATEGORY_INTERNET,
  ONBOARDING_CATEGORY_KIDS,
  ONBOARDING_CATEGORY_LOCALIZATION,
  ONBOARDING_CATEGORY_MEALS,
  ONBOARDING_CATEGORY_OBJECT_TYPE,
  ONBOARDING_CATEGORY_PARKING,
  ONBOARDING_CATEGORY_REGIONAL_ATTRACTION_TYPE,
  ONBOARDING_CATEGORY_ATTRACTION_TYPE,
  ONBOARDING_CATEGORY_RELAX,
  ONBOARDING_CATEGORY_SECURITY,
  ONBOARDING_CATEGORY_SPA,
  ONBOARDING_CATEGORY_SPECIAL_OFFERS,
  ONBOARDING_CATEGORY_SPECIAL_TRANSPORT,
  ONBOARDING_CATEGORY_SUPPORTED_LANGUAGES,
  ONBOARDING_CATEGORY_TECHNOLOGY,
  ONBOARDING_CATEGORY_VIEW,
  ONBOARDING_ROOMS,
  ONBOARDING_RULES,
  ONBOARDING_LOCATION_DATA,
} from "modules/site-generator/config/routes";
import { UserHasFilledCategorySelector } from "modules/site-generator/reducers/categoriesSlice";
import { UserHasFilledRules } from "modules/site-generator/reducers/websiteObjectSlice";
import { UserHasFilledRooms } from "modules/site-generator/reducers/roomsSlice";

const requirementsTourism: Requirement[] = [
  {
    // no requirement
    key: "userHasFilledLocationData",
    path: () => getUrl(ONBOARDING_LOCATION_DATA),
    selector: (state: any) => true,
    omitWhenFulfilled: false,
    // component: <WebsiteObjectLocationOnboarding/>
  },
  {
    // no requirement
    key: "userHasFilledCategory_ObjectType",
    path: () => getUrl(ONBOARDING_CATEGORY_OBJECT_TYPE),
    selector: (state: any) =>
      UserHasFilledCategorySelector(state, "touristic", "object-type", 1, 1),
    omitWhenFulfilled: false,
    // component: <IndustryCategoryCheckboxSelection/>
  },
  {
    // check siteGenerator touristic localization
    key: "userHasFilledCategory_Localization",
    path: () => getUrl(ONBOARDING_CATEGORY_LOCALIZATION),
    selector: (state: any) =>
      UserHasFilledCategorySelector(state, "touristic", "localization", 1, 1),
    omitWhenFulfilled: false,
    // component: <IndustryCategoryCheckboxSelection/>
    // localization min=1 max=1
  },
  {
    // check siteGenerator touristic attraction-type
    key: "userHasFilledCategory_AttractionType",
    path: () => getUrl(ONBOARDING_CATEGORY_ATTRACTION_TYPE),
    selector: (state: any) =>
      UserHasFilledCategorySelector(
        state,
        "touristic",
        "attraction-type",
        1,
        1
      ),
    omitWhenFulfilled: false,
    // component: <IndustryCategoryCheckboxSelection/>
    // attraction-type min=1 max=1
  },
  {
    // check siteGenerator touristic regional-attraction-type
    key: "userHasFilledCategory_RegionalAttractionType",
    path: () => getUrl(ONBOARDING_CATEGORY_REGIONAL_ATTRACTION_TYPE),
    selector: (state: any) =>
      UserHasFilledCategorySelector(
        state,
        "touristic",
        "regional-attraction-type",
        1,
        1
      ),
    omitWhenFulfilled: false,
    // component: <IndustryCategoryCheckboxSelection/>
    // regional-attraction-type min=1 max=1
  },
  {
    // check siteGenerator touristic parking
    key: "userHasFilledCategory_Parking",
    path: () => getUrl(ONBOARDING_CATEGORY_PARKING),
    selector: (state: any) =>
      UserHasFilledCategorySelector(state, "touristic", "parking", 0, 0),
    omitWhenFulfilled: false,
    // component: <IndustryCategoryCheckboxSelection/>
    // parking min=0 max=0
  },
  {
    // check siteGenerator touristic internet
    key: "userHasFilledCategory_Internet",
    path: () => getUrl(ONBOARDING_CATEGORY_INTERNET),
    selector: (state: any) =>
      UserHasFilledCategorySelector(state, "touristic", "internet", 1, 1),
    omitWhenFulfilled: false,
    // component: <IndustryCategoryCheckboxSelection/>
    // internet min=1 max=1
  },
  {
    // check siteGenerator touristic security
    key: "userHasFilledCategory_Security",
    path: () => getUrl(ONBOARDING_CATEGORY_SECURITY),
    selector: (state: any) =>
      UserHasFilledCategorySelector(state, "touristic", "security", 1, 0),
    omitWhenFulfilled: false,
    // component: <IndustryCategoryCheckboxSelection/>
    // security min=1 max=0
  },
  {
    // check siteGenerator touristic supported-languages
    key: "userHasFilledCategory_SupportedLanguages",
    path: () => getUrl(ONBOARDING_CATEGORY_SUPPORTED_LANGUAGES),
    selector: (state: any) =>
      UserHasFilledCategorySelector(
        state,
        "touristic",
        "supported-languages",
        1,
        0
      ),
    omitWhenFulfilled: false,
    // component: <IndustryCategoryCheckboxSelection/>
    // supported-languages min=1 max=0
  },
  {
    // check siteGenerator touristic technology
    key: "userHasFilledCategory_Technology",
    path: () => getUrl(ONBOARDING_CATEGORY_TECHNOLOGY),
    selector: (state: any) =>
      UserHasFilledCategorySelector(state, "touristic", "technology", 1, 0),
    omitWhenFulfilled: false,
    // component: <IndustryCategoryCheckboxSelection/>
    // technology min=1 max=0
  },
  {
    // check siteGenerator touristic climate
    key: "userHasFilledCategory_Climate",
    path: () => getUrl(ONBOARDING_CATEGORY_CLIMATE),
    selector: (state: any) =>
      UserHasFilledCategorySelector(state, "touristic", "climate", 0, 0),
    omitWhenFulfilled: false,
    // component: <IndustryCategoryCheckboxSelection/>
    // climate min=0 max=0
  },
  {
    // check siteGenerator touristic atmosphere
    key: "userHasFilledCategory_Atmosphere",
    path: () => getUrl(ONBOARDING_CATEGORY_ATMOSPHERE),
    selector: (state: any) =>
      UserHasFilledCategorySelector(state, "touristic", "atmosphere", 1, 0),
    omitWhenFulfilled: false,
    // component: <IndustryCategoryCheckboxSelection/>
    // atmosphere min=1 max=0
  },
  {
    // check siteGenerator touristic relax
    key: "userHasFilledCategory_Relax",
    path: () => getUrl(ONBOARDING_CATEGORY_RELAX),
    selector: (state: any) =>
      UserHasFilledCategorySelector(state, "touristic", "relax", 0, 0),
    omitWhenFulfilled: false,
    // component: <IndustryCategoryCheckboxSelection/>
    // relax min=0 max=0
  },
  {
    // check siteGenerator touristic spa
    key: "userHasFilledCategory_Spa",
    path: () => getUrl(ONBOARDING_CATEGORY_SPA),
    selector: (state: any) =>
      UserHasFilledCategorySelector(state, "touristic", "spa", 0, 0),
    omitWhenFulfilled: false,
    // component: <IndustryCategoryCheckboxSelection/>
    // spa min=0 max=0
  },
  {
    // check siteGenerator touristic kids
    key: "userHasFilledCategory_Kids",
    path: () => getUrl(ONBOARDING_CATEGORY_KIDS),
    selector: (state: any) =>
      UserHasFilledCategorySelector(state, "touristic", "kids", 0, 0),
    omitWhenFulfilled: false,
    // component: <IndustryCategoryCheckboxSelection/>
    // kids min=0 max=0
  },
  {
    // check siteGenerator touristic disabled-facilities
    key: "userHasFilledCategory_DisabledFacilities",
    path: () => getUrl(ONBOARDING_CATEGORY_DISABLED_FACILITIES),
    selector: (state: any) =>
      UserHasFilledCategorySelector(
        state,
        "touristic",
        "disabled-facilities",
        1,
        0
      ),
    omitWhenFulfilled: false,
    // component: <IndustryCategoryCheckboxSelection/>
    // disabled-facilities min=1 max=0
  },
  {
    // check siteGenerator touristic meals
    key: "userHasFilledCategory_Meals",
    path: () => getUrl(ONBOARDING_CATEGORY_MEALS),
    selector: (state: any) =>
      UserHasFilledCategorySelector(state, "touristic", "meals", 1, 0),
    omitWhenFulfilled: false,
    // component: <IndustryCategoryCheckboxSelection/>
    // meals min=1 max=0
  },
  {
    // check siteGenerator touristic special-transport
    key: "userHasFilledCategory_SpecialTransport",
    path: () => getUrl(ONBOARDING_CATEGORY_SPECIAL_TRANSPORT),
    selector: (state: any) =>
      UserHasFilledCategorySelector(
        state,
        "touristic",
        "special-transport",
        0,
        0
      ),
    omitWhenFulfilled: false,
    // component: <IndustryCategoryCheckboxSelection/>
    // special-transport min=0 max=0
  },
  {
    // check siteGenerator touristic view
    key: "userHasFilledCategory_View",
    path: () => getUrl(ONBOARDING_CATEGORY_VIEW),
    selector: (state: any) =>
      UserHasFilledCategorySelector(state, "touristic", "view", 1, 0),
    omitWhenFulfilled: false,
    // component: <IndustryCategoryCheckboxSelection/>
    // view min=1 max=0
  },
  {
    // check siteGenerator touristic feeding
    key: "userHasFilledCategory_Feeding",
    path: () => getUrl(ONBOARDING_CATEGORY_FEEDING),
    selector: (state: any) =>
      UserHasFilledCategorySelector(state, "touristic", "feeding", 1, 0),
    omitWhenFulfilled: false,
    // component: <IndustryCategoryCheckboxSelection/>
    // feeding min=1 max=0
  },
  {
    // check siteGenerator touristic special-offers
    key: "userHasFilledCategory_SpecialOffer",
    path: () => getUrl(ONBOARDING_CATEGORY_SPECIAL_OFFERS),
    selector: (state: any) =>
      UserHasFilledCategorySelector(state, "touristic", "special-offers", 0, 0),
    omitWhenFulfilled: false,
    // component: <IndustryCategoryCheckboxSelection/>
    // special-offers min=0 max=0
  },
  {
    // check siteGenerator touristic sport
    key: "userHasFilledCategory_ActiveSport",
    path: () => getUrl(ONBOARDING_CATEGORY_ACTIVE_SPORT),
    selector: (state: any) =>
      UserHasFilledCategorySelector(state, "touristic", "sport", 0, 0),
    omitWhenFulfilled: false,
    // component: <IndustryCategoryCheckboxSelection/>
    // sport min=0 max=0
  },
  {
    // check siteGenerator touristic recreation
    key: "userHasFilledCategory_ActiveRecreation",
    path: () => getUrl(ONBOARDING_CATEGORY_ACTIVE_RECREATION),
    selector: (state: any) =>
      UserHasFilledCategorySelector(state, "touristic", "recreation", 1, 0),
    omitWhenFulfilled: false,
    // component: <IndustryCategoryCheckboxSelection/>
    // recreation min=1 max=0
  },
  {
    // check siteGenerator touristic events
    key: "userHasFilledCategory_ActiveEvents",
    path: () => getUrl(ONBOARDING_CATEGORY_ACTIVE_EVENTS),
    selector: (state: any) =>
      UserHasFilledCategorySelector(state, "touristic", "events", 0, 0),
    omitWhenFulfilled: false,
    // component: <IndustryCategoryCheckboxSelection/>
    // events min=0 max=0
  },
  {
    // check siteGenerator touristic beauty
    key: "userHasFilledCategory_Beauty",
    path: () => getUrl(ONBOARDING_CATEGORY_BEAUTY),
    selector: (state: any) =>
      UserHasFilledCategorySelector(state, "touristic", "beauty", 0, 0),
    omitWhenFulfilled: false,
    // component: <IndustryCategoryCheckboxSelection/>
    // beauty min=0 max=0
  },
  {
    // check siteGenerator have siteGenerator.rooms for current project
    key: "userHasFilledRooms",
    path: () => getUrl(ONBOARDING_ROOMS),
    selector: UserHasFilledRooms,
    omitWhenFulfilled: false,
    // component: <RoomsOnboarding/>
    // siteGenerator.rooms[currentProjectId]
  },
  {
    // check siteGenerator have siteGenerator.websiteObjects for current project
    key: "userHasFilledRules",
    path: () => getUrl(ONBOARDING_RULES),
    selector: UserHasFilledRules,
    omitWhenFulfilled: false,
    // component: <ResidenceRulesOnboarding/>
    // siteGenerator.websiteObjects[currentProjectId]
  },
];

export const disallowedRoutesTourism: string[] = [
  "offer",
  "categories",
  "brandsAndManufacturers",
  "galleries",
];

export default requirementsTourism;
