import { createSelector, createSlice } from "@reduxjs/toolkit";
import { callAPIProps } from "services/hooks/useAPI";
import { projectsSlice } from "state/projects/projectsSlice";



type ProjectSettings = any;

const initialState: ProjectSettings = {

}

export const projectSettingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        get: (state, action) => {
            return action.payload.data?.settings;
        }
    }

});


export const GetProjectSettings:callAPIProps = {
    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/settings`),
    method: "GET",
    successDispatch: projectSettingsSlice.actions.get,
    hideNotifications: true
}

export const UpdateProjectSettings:callAPIProps = {
    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/settings`),
    method: "PATCH",
    successDispatch: projectSettingsSlice.actions.get
}

export const ProjectSettingsSelector = createSelector([
    (state: any) => state.project.settings
], (settings) => settings
);