import { Space } from "antd";
import StandardButton from "components/common/StandardButton";
import { useMemo } from "react";
import FormattedMessage from "components/common/FormattedMessage";
import useAPI from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { Task } from "tools/tasks/data/taskTypeConfig";
import CreationCard from "./CreationCard";
import { creationSelector, getCreation } from "./creationsSlice";

export const CreationAcceptationTask = (props: {task: Task}) => {

    const creationId = props.task?.subject_id;
    const creationCall = useAPI(getCreation(creationId), true);
    const creation = useSelectorWithParams([creationSelector, creationId])
    

    if (!creationId) return null;
    if (!creation) return null;

    return (
        <div>
            Do you want to publish this creation? <br/>
            You can reject it and provide a message to the creator.
            <CreationCard creationId={creation.id} mode="info"/>
        </div>
        )

}

export const CreationPublicationButtons = (props: {task: Task, onAccept: () => void, onReject: () => void, onUnpublish: () => void, onClose: () => void}) => {

    const task = props.task;
    const creationId = props.task?.subject_id;
    const creation = useSelectorWithParams([creationSelector, creationId])

    const canBeUnpublished = useMemo(() => {
        if (!task) return false;
        if (!task.status) return true;
        if (task.status !== "completed") return false;
        return true;
    }, [task])

    const canBeAccepted = useMemo(() => {
        if (!task) return false;
        if (!creation) return false;
        if (creation.status !== "bound_to_task") return false;
        if (task.status !== "pending_acceptation") return false;
        return true;
    }, [task, creation])

    const canBeRejected = useMemo(() => {
        if (!task) return false;
        if (!creation) return false;
        if (creation.status !== "bound_to_task") return false;
        if (task.status !== "pending_acceptation") return false;
        return true;
    }, [task, creation])

    return (
        <Space wrap>
            <StandardButton onClick={props.onClose}>Cancel</StandardButton>
            {canBeUnpublished ? (
                <StandardButton type="primary" onClick={props.onUnpublish}>
                    <FormattedMessage
                        id="tools.tasks.actions.unpublish.button"
                        defaultMessage="Unpublish"
                    />
                </StandardButton>
            ) : (
                <>
                    <StandardButton type="primary" danger onClick={props.onReject} disabled={!canBeRejected}>
                        <FormattedMessage
                            id="tools.tasks.actions.reject.button"
                            defaultMessage="Reject"
                        />
                    </StandardButton>
                    <StandardButton type="primary" onClick={props.onAccept} disabled={!canBeAccepted}>
                        <FormattedMessage
                            id="tools.tasks.actions.accept.button"
                            defaultMessage="Accept"
                        />
                    </StandardButton>
                </>
            )}
        </Space>
    )

}