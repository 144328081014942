import { Radio } from "antd"
import FormattedMessage from "components/common/FormattedMessage"
import { AiStrategySelector } from "modules/panel/state/AISlice";
import { useEffect, useMemo } from "react";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";

const defaultNames = [
    <FormattedMessage
        id="apps.smart.components.aiGeneration.intensity.low"
        defaultMessage="Low"
    />,
    <FormattedMessage
        id="apps.smart.components.aiGeneration.intensity.medium"
        defaultMessage="Medium"
    />,
    <FormattedMessage
        id="apps.smart.components.aiGeneration.intensity.high"
        defaultMessage="High"
    />
]

const AIIntensitySelect = (props:any) => {

    const aiStrategies = useSelectorWithParams(AiStrategySelector);

    const options = useMemo(() => {

        if (!aiStrategies) return [];

        const myStrat = aiStrategies.find((aiStrategy: any) => aiStrategy.id === props.strategy)
        if (!myStrat) return [];

        return myStrat.aiIntensities.map((intensity: any) => ({
            label: intensity.name,
            value: intensity.id
        }))

    }, [aiStrategies, props.strategy])


    const mappedOptions = useMemo(() => {

        if (!options || !options.length) return [];

        return options.map((option: any, index: number) => (
            <Radio.Button value={option.value} style={{ flexGrow: 1, display: "grid", placeItems: "center" }}>
                {option.label || (options.length === 3 && defaultNames[index]) || option.value }
            </Radio.Button>
        ))

    }, [options])

    useEffect(() => {
        if (!options.length) return;
        props.onChange(options[0].value)
        props.onBlur();
    }, [options]);

    if (!options || !options.length) {
        return <div>
            Select a strategy first
        </div>
    }

    return (
        <Radio.Group style={{
            display: "flex"
        }}
        {...props}
        >
            {mappedOptions}
        </Radio.Group>
    )

}

export default AIIntensitySelect;