import { Alert, Space } from "antd";
import StandardButton from "components/common/StandardButton";
import { useEffect, useMemo, useState } from "react";
import useAPI, { callAPIProps, callAPISettings, getCallAPIProps } from "services/hooks/useAPI"
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { AcceptTask, CreateTask, PatchTask, RejectTask, TasksArraySelector, TaskSelector, TasksSelector } from "./data/tasksSlice";
import { Task } from "./data/taskTypeConfig"

export type useTaskMiddlewareProps = {
    selectTask: (tasks: Task[]) => Task | undefined,
    taskId?: string,
    taskTemplate?: Task,
    dataSelector: any,
    adjustData?: (data: any) => any,
    combineData?: (task: Task, data: any) => any,
    adjustPatchData?: (data: any) => any,
    areThereChanges?: (task: Task, data: any) => boolean,
}

export type useTaskMiddlewareReturn = {
    callAPI: callAPIProps,
    combinedData: any,
    statusAlert: any,
    tryToSelectTask: () => void,
}

//This component is used to combine the data from a task with the data from a selector
//Provides callAPIProps that combine the data and update the task instead of the normal endpoint.

const useTaskMiddleware = (props: useTaskMiddlewareProps): useTaskMiddlewareReturn => {

    const allTasks:Task[] = useSelectorWithParams(TasksArraySelector)
    const [taskId, setTaskId] = useState<string | undefined>(props.taskId)
    const task = useSelectorWithParams([TaskSelector, taskId])

    const tryToSelectTask = () => {
            const existingTask = props.selectTask ? props.selectTask(allTasks) : allTasks.find(t => t.id === taskId)
            if (existingTask) {
                setTaskId(existingTask.id)
            } else {
                setTaskId(undefined)
            }
    }


    useEffect(() => {
        if (!taskId) {
            tryToSelectTask();
        }
    }, []);

    const getTask = () => {
        return taskId ? allTasks.find(t => t.id === taskId) : props.selectTask(allTasks)
    }
    const taskResolveCall = useAPI({})

    const rawData = useSelectorWithParams(props.dataSelector)
    const data = props.adjustData ? props.adjustData(rawData) : rawData;

    const combinedData = useMemo(() => {

        const t = task || {};

        if (props.combineData) {
            return props.combineData(t, data)
        } else {
            return {
                ...data,
                ...task.payload,
            };
        }
    }, [task, data])

    const callAPI = useMemo(() => {

        let newCallAPI:callAPIProps;

        if (!task) {
            return {
                ...CreateTask,
                body: props.taskTemplate,
                adjustBody: (body:any) => {
                    const newPayload = {
                        ...body,
                        ...(props?.taskTemplate?.payload || {})
                    }
                    return {
                        ...props.taskTemplate,
                        payload: newPayload
                    }
                },
                onSuccess: (data:any) => {
                    if (data && data.id) setTaskId(data.id)
                }
            }
        } else {
            newCallAPI = PatchTask(task.id);
            newCallAPI.adjustBody = (body) => {
                const newPayload = {
                    ...task.payload,
                    ...body,
                }
                return {
                    type: "data_update",
                    payload: newPayload
                }
            }
            return newCallAPI;
        }
        
    }, [combinedData, props.taskTemplate])

    const handleAccept = () => {
        if (!taskId) return;
        taskResolveCall.call(AcceptTask(taskId)).then((res) => {
            if (res && res.status === 200) {
                setTaskId(undefined);
            }
        })
    }

    const handleReject = () => {
        if (!taskId) return;
        taskResolveCall.call(RejectTask(taskId)).then((res) => {
            if (res && res.status === 200) {
                setTaskId(undefined);
            }
        })
    }

    const TaskStatusAlert = useMemo(() => {


        if (!taskId) return null;

        return <Alert 
            message="You have unsaved changes"
            action={
                <Space direction="vertical">
                    <StandardButton fullWidth size="small" onClick={handleAccept}>Save</StandardButton>
                    <StandardButton fullWidth size="small" onClick={handleReject}>Cancel</StandardButton>
                </Space>
            }
        />

       // const changes = props.areThereChanges ? props.areThereChanges(task, combinedData) : (task ? true : false);

    }, [task, combinedData])

    return {
        callAPI: callAPI,
        combinedData: combinedData,
        statusAlert: TaskStatusAlert,
        tryToSelectTask: tryToSelectTask,
    };

}

export default useTaskMiddleware;