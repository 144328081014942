//BobbyBurger

import { ProductConfig } from "products";
import logo from "./blossom.png";
import requirements from "./requirements";


// post
// smart
// forsant
// call app
//insta

export const BobbyBurgerConfig: ProductConfig = {
    name: "BobbyBurger",
    logo, 
    requirements: requirements,
}

