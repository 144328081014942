import { Product } from "products/index";

const productsWithoutSubscription: Product[] = [
  "platformsAuthorization",
];

const isFreeProduct = (product: Product) =>
  productsWithoutSubscription.includes(product);

export default isFreeProduct;
