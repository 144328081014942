import React, { Component } from "react";
import { Input } from "antd";

class WebsiteInput extends Component {


  render() {
    return <Input {...this.props} />;
  }
}

export default WebsiteInput;
