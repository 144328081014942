import React, { Component } from "react";
import { conformToMask } from "react-text-mask";
import { Input } from "antd";

class MaskedInput extends Component {
  constructor(props) {
    super(props);

    this.input = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== undefined)
      this.updateCaretPosition(this.mask(this.props.value));
  }

  shouldComponentUpdate = (nextProps) => nextProps.value !== this.props.value || this.props.disabled;

  mask = (value = "") =>
    conformToMask((value || "").toString(10), this.props.pattern, { guide: false })
      .conformedValue;

  updateCaretPosition(maskedValue) {
    const position = this.props.delimiters.includes(
      maskedValue[maskedValue.length - 1]
    )
      ? maskedValue.length - 1
      : maskedValue.length;

    this.setCaretPosition(position);
  }

  setCaretPosition(pos) {
    const ctrl = this.input.current ? this.input.current.input : false;

    if (ctrl && ctrl.setSelectionRange) {
      ctrl.focus();
      ctrl.setSelectionRange(pos, pos);

      // IE8 and below
    } else if (ctrl.createTextRange) {
      var range = ctrl.createTextRange();
      range.collapse(true);
      range.moveEnd("character", pos);
      range.moveStart("character", pos);
      range.select();
    }
  }

  render() {
    let { transform, delimiters, pattern, value, ...props } = this.props;

    const maskedValue = this.mask(value);

    return (
      <Input
        {...props}
        ref={this.input}
        value={maskedValue}
        onChange={(e) => this.props.onChange(transform(e.target.value))}
      />
    );
  }
}

MaskedInput.defaultProps = {
  transform: (value) => value,
};

export default MaskedInput;
