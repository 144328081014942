import { Component } from "react";
import * as Sentry from "@sentry/browser";
import AwesomeIcon from "components/common/AwesomeIcon";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";


class SimpleErrorBoundry extends Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = { 
            hasError: false
        };
    }

    static getDerivedStateFromError(error: React.ErrorInfo) {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    }

    render() {

        if (this.state.hasError) {
            return (
                <div>
                    Error
                </div>
            )
        }

        return this.props.children;
    }

}

export default SimpleErrorBoundry;