import StandardActorCard from "components/common/StandardActorCard";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { libraryAnyByTypeSelector } from "state/librarySlice";
import { Task } from "./data/taskTypeConfig";

export default function TaskParticipantCard (props: {task: Task, participant: "source" | "target"}) {
    
    //console.log("Task", props.task)
    const participantType = props.task?.[props.participant + "_type"];
    const participantId = props.task?.[props.participant + "_id"];

    const participant = useSelectorWithParams([libraryAnyByTypeSelector, participantType, participantId])

    if (participant) {
        return (
            <StandardActorCard actor={participant} />
        )
    }

    return (
        null
    )

}