import React, { ReactNode, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Tabs as AntTabs, Menu, Dropdown } from "antd";
import {
  TabsProps as AntTabsProps,
  TabPaneProps as AntTabPaneProps,
} from "antd/es/tabs/index";
import { Platform } from "modules";
import { Context } from "providers/subscription/subscription";
import { SmoothTabBorder } from "./SmoothTabs";
import { useLocation } from "react-router";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import "./tabs.less";
import AwesomeIcon from "components/common/AwesomeIcon";
import { faGridRound2, faGripDots, faGripDotsVertical, faGripVertical } from "@fortawesome/pro-light-svg-icons";
import dragula from "dragula";
import { currentFlatSubscriptionPermissionsSelector } from "state/subscriptions/subscriptionsSelectors";

type Tab = AntTabPaneProps &
{
  title: any;
  content?: React.ReactNode;
  icon?: React.ReactNode;
  key: string;
  integration?: Platform;
}

type TabsProps = AntTabsProps & {
  panes: (Tab & { requiredPermission?: string })[];
  title?: any;
  buttons?: React.ReactNode;
  fitHeight?: "full" | "fit";
  draggable?: boolean;
  onReorder?: (newIndexes: (number | string)[]) => void;
}

const getIndexInParent = (el) => Array.from(el.parentNode.children).indexOf(el);
const getIndexFromElement = (el) => el?.children?.[0]?.children?.[0]?.getAttribute("data-tab-index");


const Tabs: React.FunctionComponent<TabsProps> = ({
  panes,
  title,
  buttons,
  className,
  fitHeight,
  defaultActiveKey,
  draggable,
  onReorder,
  ...restProps
}) => {
  const { providers } = useContext(Context);
  const permissions = useSelectorWithParams(currentFlatSubscriptionPermissionsSelector);
  const location = useLocation();

  const tabsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {

    if (!draggable) return;

    let start;

    const d = dragula([], {
      revertOnSpill: true,
      moves: (el, source, handle) => {
        if (!el) return false;
        if (!handle) return false;
        if (!handle.classList.contains("tab-drag-handle")) return false;
        start = getIndexInParent(el);
        return true;
      },
    })

    d.on("drag", (el, source) => {
      el.classList.add("dragging")
      tabsRef.current?.classList.add("dragging")
    })

    d.on("cancel", (el, container, source) => {
      el.classList.remove("dragging")
      tabsRef.current?.classList.remove("dragging")

    })

    d.on("drop", (el, target, source, sibling) => {

      el.classList.remove("dragging")
      tabsRef.current?.classList.remove("dragging")

      const tabChildren = Array.from(target?.children || [])

      const tabElements = tabChildren.filter((el) => !el.classList.contains("ant-tabs-ink-bar"))
      const newTabKeys = tabElements.map((el) => el.getAttribute("data-node-key") || "");

      onReorder && onReorder(newTabKeys)

    })

    try {
      tabsRef.current && d.containers.push(tabsRef.current?.children?.[0]?.children?.[0] as HTMLElement);
    } catch (e) {
      console.warn("Dragula error", e)
    }

  }, []);


  const urlActiveKey = (() => {
    if (location) {
      const pathname = location.pathname;
      const path = pathname.split("/").pop();
      const tab = panes.find((pane) => pane?.key === path);
      if (tab) return tab.key;
    }
    return undefined;
  })()

  const [activeKey, setActiveKey] = useState<string | undefined>(urlActiveKey);

  const anyKeyInUrl = urlActiveKey !== undefined;

  const handleTabClick = (key: string) => {
    // console.log("handleTabClick", key)
    // console.log("anyKeyInUrl", anyKeyInUrl)
    // console.log("currentKeyInUrl", urlActiveKey === key)
    setActiveKey(key)
    restProps.onChange && restProps.onChange(key)
  }

  useEffect(() => {
  //console.log('restProps.activeKey', restProps.activeKey);
    setActiveKey(restProps.activeKey);
  }, [restProps.activeKey]);

  // useEffect(() => {
  //   console.log('activeKey in tabs', activeKey);
  //   }, [activeKey]);

  const filteredPanes = useMemo(() => {

    if (!panes) return [];

    return panes
      .filter((pane) => !!pane)
      .filter(
        (pane) =>
          !pane.integration ||
          (pane.integration && providers.includes(pane.integration))
      ).filter((pane) => {
        if (!pane.requiredPermission) return true;
        return permissions.includes(pane.requiredPermission);
      })

  }, [panes, providers, permissions]);


  return (
      <AntTabs
        type="card"
        tabBarGutter={0}
        tabBarExtraContent={
          restProps.tabBarExtraContent ||
          <>
            {buttons && buttons}
            {title && <span className="tabs-title">{title}</span>}
          </>
        }
        renderTabBar={(props, DefaultTabBar) => {
          return (
            <DefaultTabBar ref={tabsRef} {...props} />
          )
        }}
        {...restProps}
        onTabClick={handleTabClick}
        activeKey={activeKey}
        //defaultActiveKey={getDefaultActiveKey()}
        className={`tabs-smooth ${className} ${fitHeight === "fit" && "fitHeight"} ${draggable ? "tabs-draggable" : null}`}
      >
        {filteredPanes
          .map(({ title, icon, content, ...restProps }, index) => (
            <AntTabs.TabPane
              tab={
                <>
                  <span className="tab-tab-content"
                    data-tab-index={index}
                  >
                    
                    {icon && <span className="icon">{icon}</span>}
                    <span className="title">{title}</span>
                    {draggable &&
                      <div className="tab-drag-handle">
                        <AwesomeIcon icon={faGripDotsVertical} size="lg" color="grey"/>
                      </div>}
                  </span>
                  <SmoothTabBorder first={index === 0} />
                  {/* {draggable ? null : <SmoothTabBorder first={index === 0} />} */}
                </>
              }
              {...restProps}
            >
              <div className="tabs-content">{content && content}</div>
            </AntTabs.TabPane>
          ))}
      </AntTabs>
  );
};

export default Tabs;

export const DraggableTabs: React.FunctionComponent<TabsProps> = (props) => {
  return <Tabs {...props} draggable />;
};