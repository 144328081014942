import dayjs, { Dayjs } from "dayjs";

export type AssetProps = {
    id: number | string;
    type: string;
    message?: string;
    image?: string;
}

export type CalendarInfo = {
    typeIcon: any;
    status: any;
    type: any;
    date: Dayjs;
    color: string;
    description: string;
    image: string;
    canBePickedUp: boolean;
    moveInFutureOnly: boolean;
}

export default class Asset implements AssetProps{

    id: number | string = -1;
    type = "none";
    message = "...";
    image = "";

    constructor(data?:any) {

        this.id = data.id;
        this.type = data.type;
        this.message = data.message;
        this.image = data.image;
    }

    getId():number | string | undefined {
        return this.id;
    }

    getType():string {
        return this.type;
    }

    getDescription():string | undefined {
        return this.message
    }
    
    getCalendarInfo():CalendarInfo {
        return {
            typeIcon: null,
            status: null,
            type: "asset",
            date: dayjs(),
            color: "white",
            description: this.message || "",
            image: this.image || "",
            canBePickedUp: false,
            moveInFutureOnly: false
        }
    }

} 