import { Collapse, Input, Spin } from "antd";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import { HorizontalCenter } from "components/common/Center";
import _ from "lodash";
import { AiProjectInfoSelector, GetAiProjectInfo, ProjectHasAIProjectInfoSelector, ProjectHasDemographicSelector, ProjectHasDemographicsInCategorySelector, ProjectHasDemographicsPurchaseObjectionsSelector, UpdateAIProjectInfo, useGenerateAIDemographics } from "modules/panel/state/AISlice";
import AIDemographics from "modules/smart/components/AIGeneration/AIDemographics";
import { AIDemographicsCategoriesType } from "modules/smart/components/AIGeneration/AIDemographicsConfig";
import SettingsAI from "modules/smart/components/AIGeneration/SettingsAI"
import ConnectedOnboardingControls from "providers/onboarding/controls"
import { useOnboarding } from "providers/onboarding/onboarding-context";
import { useEffect, useRef } from "react";
import useAPI from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import RelaxList from "tools/relaxForm/RelaxList"

const OnboardingAIPurchaseObjections = () => {
    
    const fullfilled = useSelectorWithParams(ProjectHasDemographicsPurchaseObjectionsSelector);
    const currentData = useSelectorWithParams([AiProjectInfoSelector, "purchaseObjections"])


    return (
        <>
            <RelaxList
                name={["demographics", "purchaseObjections"]}
                callAPI={UpdateAIProjectInfo}
                initialValuesSelector={AiProjectInfoSelector}
                finalizeSubmit={(values) => {
                    const newData = _.merge(_.cloneDeep(currentData), _.cloneDeep(values))
                    return {
                        demographics: newData.demographics
                    };
                }}
                >
                    <Input.TextArea autoSize={{minRows: 1, maxRows: 2}}/>
                </RelaxList>
            <ConnectedOnboardingControls relaxDontSubmit = {true} nextDisabled={!fullfilled}/>
        </>
    )

}

export default OnboardingAIPurchaseObjections;