import { faSmile } from "@fortawesome/pro-light-svg-icons";
import { Spin } from "antd";
import StandardButton from "components/common/StandardButton";
import EmojiPickerReact, { SkinTones } from "emoji-picker-react";
import { useEffect, useState, useTransition } from "react";
import FormattedMessage from "components/common/FormattedMessage";
import { StandardDrawer, useStandardDrawer } from "services/hooks/useDrawer";


const EmojiPicker = (props:any) => {
    return (
        <EmojiPickerReact 
            onEmojiClick={props.onPick}
            skinTonesDisabled={true}
            autoFocusSearch={false}
            defaultSkinTone={SkinTones.MEDIUM_DARK}
            searchDisabled={true} 
            lazyLoadEmojis={true}
            width="100%"
            height="100%"
        />
    )
}

export default EmojiPicker;