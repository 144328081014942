//This is a test page - a sandbox for working on individual components. 
//It is added to the menu (vial icon) only in the development mode. 
//It is added to .gitignore so you can do whatever you want here. 
//There is a script in package json that creates the "text.tsx" file after "yarn install"

export default function TestPage() {

  return (
    <div>
      This is a test page
    </div>
  )


}



