import RelaxSingleField from "tools/relaxForm/RelaxField"
import GeoPointsInputGoogle from "./GeoPointsInputGoogle"
import { GoogleAdsSettingsSelector, UpdateGoogleAdsSettings } from "modules/smart/smartSlice"

export default function GeoPointsGoogle (props:any) {
    
    return (
      <RelaxSingleField
        name={["location_targeting", "geo_points"]}
        initialValuesSelector={GoogleAdsSettingsSelector}
        callAPI={UpdateGoogleAdsSettings}
        showBadgeMode="none"
        >
          <GeoPointsInputGoogle />
        </RelaxSingleField>
    )

}