import { CropPreset, CropPresets, Image } from "./Gallery";
import { RoundAndClampCrop } from "./imageHelpers";


export const getAspectRatio = (image: Image) => image.width / image.height;

export const DoesImageFitPreset = (image: Image, preset: CropPreset) => {
    const {width, height} = image;
    const {minHeight, minWidth, maxHeight, maxWidth} = preset;
    if (minHeight && minHeight !== 0 && height < minHeight) return (false);
    if (minWidth && minWidth !== 0 && width < minWidth) return(false);
    if (maxHeight && maxHeight !== 0 && height > maxHeight) return(false);
    if (maxWidth && maxWidth !== 0 && width > maxWidth) return (false);
    return true;
}

export const DoesImageFitAnyPreset = (image: Image, presets: CropPreset[]) => {
    return presets.some(preset => DoesImageFitPreset(image, preset));
}

export const DoesImageFitAllProviderPresets = (image: Image, providerPresets: Record<string,CropPreset[]>) => {
    return Object.values(providerPresets).every(presets => DoesImageFitAnyPreset(image, presets));
}

export const DoesImageMatchPreset = (image: Image, preset: CropPreset) => {
    if (!DoesImageFitPreset(image, preset)) return false;
    if (getAspectRatio(image) !== preset.aspect) return false;
    return true;
}

type ImagePreset = {
    minWidth?: number;
    maxWidth?: number;
    minHeight?: number;
    maxHeight?: number;
    aspect?: number;
  }
  

type Crop = {
    x: number;
    y: number;
    width: number;
    height: number;
    rating: number;
  }
  
export function cropImageToFitPreset(image: Image, preset: ImagePreset & any): any {
    const { width, height } = image;
    const imageAspect = width / height;
    const {
      minWidth = 0,
      maxWidth = Number.POSITIVE_INFINITY,
      minHeight = 0,
      maxHeight = Number.POSITIVE_INFINITY,
      aspect = width / height,
    } = preset;
  
    let cropWidth:number;
    let cropHeight:number;

    if (!aspect) {
        cropWidth = Math.min(width, maxWidth);
        cropHeight = Math.min(height, maxHeight);
    } else if (aspect < 1) {
      // Portrait
      cropWidth = Math.min(width, height * aspect, maxWidth);
      cropHeight = cropWidth / aspect;
    } else {
      // Landscape
      cropHeight = Math.min(height, width / aspect, maxHeight);
      cropWidth = cropHeight * aspect;
    }

    const cropX = (width - cropWidth) / 2;
    const cropY = (height - cropHeight) / 2;
  
    // Calculate the rating as a percentage of the original image area
    const cropArea = cropWidth * cropHeight;
    const imageArea = width * height;
    const rating = (cropArea / imageArea) * 100;
  
    // Return the crop object with the rating
    return {
      x: cropX,
      y: cropY,
      width: cropWidth,
      height: cropHeight,
      rating,
      aspect: preset.aspect,
      platform: preset.platform,
      ratio: preset.ratio,
      unit: "px",
    };
}

const tryTocropImageToFitPreset = (image: Image, preset: CropPreset): Crop | null => {
  if (DoesImageFitPreset(image, preset)) {
    return cropImageToFitPreset(image, preset);
  }
  return null;
}

export function findBestCropForImage(image: Image, presets: CropPreset[]):any {
  //  console.log("Evaluating", image, presets)
    const cropCandidates = presets.map(preset => tryTocropImageToFitPreset(image, preset)).filter(crop => crop !== null);
    const bestCrop = cropCandidates.sort((a:any, b:any) => b.rating - a.rating)[0];
    return bestCrop || null;
}

export function getInitialCrops(image: Image & any, presets?: CropPresets):any {
  if (!image) return;
  if (!presets) return image.crop;

  let missingCrops: string[] = [];

  if (!image.crop) {
    missingCrops = Object.keys(presets);
  } else {
    for (const [provider, provPresets] of Object.entries(presets)) {
      if (!image.crop[provider]) {
        missingCrops.push(provider);
      }
    }
  } 

  if (!missingCrops || missingCrops.length === 0) return image.crop;

  const newCrops = missingCrops.reduce((acc, provider) => {
    const provPresets = presets[provider];
    const bestCrop = findBestCropForImage(image, provPresets);
    const bestPreset = provPresets.find(preset => preset.ratio === bestCrop.ratio);
    if (bestCrop) {
      (acc as any)[provider] = RoundAndClampCrop(bestCrop, image.width, image.height, bestPreset as any);
    }
    return acc;
  }, {});
  

  return newCrops
}