import { useEffect, useMemo } from "react";
import {
  FreshMailConfig,
  MailerLiteConfig,
  SimpleReplyConfig,
  SmsApiConfig,
} from "./BotBtnConfig";
import FormattedMessage from "components/common/FormattedMessage";
import { Col, Input, Radio, Row, Spin } from "antd";
import RelaxField from "tools/relaxForm/RelaxField";
import "./botForm.less";
import { BotIntegrationRadio } from "./BotIntegrationRadio";
import { BotPreview } from "./preview/BotPreview";
import { useBot } from "modules/bot/components/BotContext";
import { GetBot } from "../containers/BotsSlice";
import useAPI from "services/hooks/useAPI";

type Props = {
  botBasedOn: string;
  setBotBasedOn: (data: string) => void;
  integration?: string;
  setIntegration: (data: string) => void;
  actionType: string;
};

export const BotEditor = (props: Props) => {
  const { bot } = useBot();
  const getBot = useAPI(GetBot(bot?.id));

  useEffect(()=>{
    if(bot && bot.id){
      getBot.call()
    }
  },[])

  const hideStatus = props.actionType === "create" ? true : false;

  const selectedBotConfig = useMemo(() => {
    switch (props?.integration) {
      case "fresh-mail":
        return (
          <FreshMailConfig
            name={
              bot
                ? bot?.integrations?.[0]?.subscribers_list_hash
                  ? ["integrations", 0, "subscribers_list_hash"]
                  : ["integrations", 0, "integration_group_external_id"]
                : "integration_group_external_id"
            }
            hideStatus={hideStatus}
          />
        );
      case "sms-api":
        return (
          <SmsApiConfig
            name={
              bot
                ? bot?.integrations?.[0]?.sms_api_group_id
                  ? ["integrations", 0, "sms_api_group_id"]
                  : ["integrations", 0, "integration_group_external_id"]
                : "integration_group_external_id"
            }
            hideStatus={hideStatus}
          />
        );
      case "mailer-lite":
        return (
          <MailerLiteConfig
            name={
              bot
                ? ["integrations", 0, "integration_group_external_id"]
                : "integration_group_external_id"
            }
            hideStatus={hideStatus}
          />
        );
      default:
        return <SimpleReplyConfig hideStatus={hideStatus} />;
    }
  }, [bot, props.integration]);

  return (
    <>
      <Spin spinning={getBot.loading} size="large">
        <div className="bot-form">
          <div className="form">
            <BotIntegrationRadio
              integration={props.integration}
              setIntegration={props.setIntegration}
              actionType={props.actionType}
            />
            <Row>
              <Col md={24} lg={16}>
                <div className="ant-col ant-form-item-label">
                  <label className="ant-form-item-no-colon">
                    <FormattedMessage
                      id="modules.bot.form.activateBotBasedOn"
                      defaultMessage="Activate bot based on"
                    />
                  </label>
                </div>
                <Radio.Group
                  defaultValue={props.botBasedOn}
                  buttonStyle="solid"
                >
                  <Radio.Button
                    onClick={() => props.setBotBasedOn("all-comments")}
                    value={"all-comments"}
                  >
                    <FormattedMessage
                      id="components.forms.activatedOn.allComments"
                      defaultMessage="All comments"
                    />
                  </Radio.Button>
                  <Radio.Button
                    onClick={() => props.setBotBasedOn("keyword")}
                    value={"keyword"}
                  >
                    <FormattedMessage
                      id="components.forms.activatedOn.keyword"
                      defaultMessage="Keyword"
                    />
                  </Radio.Button>
                </Radio.Group>
              </Col>

              <Col md={24} lg={8}>
                {props.botBasedOn === "keyword" && (
                  <>
                    <RelaxField
                      style={{ margin: "30px 0 0 10px" }}
                      name="keyword"
                      className="ant-form-item-no-colon"
                      hideStatus={hideStatus}
                      label={
                        <FormattedMessage
                          id="components.forms.activatedOn.keyword"
                          defaultMessage="Keyword"
                        />
                      }
                      tooltip={
                        <FormattedMessage
                          id="components.forms.activatedOn.keyword.tooltip"
                          defaultMessage="Keyword"
                        />
                      }
                    >
                      <Input />
                    </RelaxField>
                  </>
                )}
              </Col>
            </Row>
            {selectedBotConfig}
          </div>

          {window.innerWidth >= 800 ? (
            <BotPreview integration={props.integration} bot={bot} />
          ) : (
            ""
          )}
        </div>
      </Spin>
    </>
  );
};
