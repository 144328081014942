import React, {
    useRef,
    useEffect,
    RefObject,
    useCallback,
    ChangeEvent,
    useState,
    useMemo
  } from "react";
import Icon from '@ant-design/icons';
import { Input, Select } from "antd";
import { useSelector } from "react-redux";
import { AsYouType } from "libphonenumber-js/min";
import usePrevious from "services/hooks/use-previous";
import { InputProps } from "antd/es/input";
import PhoneInput, {formatPhoneNumber, getCountryCallingCode} from 'react-phone-number-input'
//import 'react-phone-number-input/style.css'
import { userLanguageSelector } from "modules/panel/config/selectors/user";
import pl from 'react-phone-number-input/locale/pl.json';
import en from 'react-phone-number-input/locale/en.json';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import flags from 'react-phone-number-input/flags';
import FormattedMessage from "components/common/FormattedMessage";
import './phone-input-with-country.less'

type PhoneNumberProps = {
  value?: string;
  onChange?: (updatedValue: string) => void;
  disabled?: boolean;
} & InputProps;

export const CountrySelectDropdown = (props:any) => {

  const [isFocused, setIsFocused] = useState(false)

  const {
    value,
    onChange,
    options, 
    ...rest
  } = props

  const handleChange = useCallback((value: any) => {
    if (onChange) onChange(value);
	}, [onChange])

  return (
    <>
    <Select
    {...rest}
    //className="ant-input-group-addon"
    value = {value || "International"}
    onChange={handleChange}
    dropdownMatchSelectWidth = {false}
    showSearch = {true}
    onFocus={()=>setIsFocused(true)}
    onBlur={()=>setIsFocused(false)}
    filterOption={(input, option:any) =>  {
      if (option === undefined) return false;
      if (option === null) return false;
      if (!option.props.value) return false;
      return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
      || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0

    }
      
    }
    >
      {options.map((o:any) => (
      <Select.Option 
          key = {o.value}
					value={o.value}>
				{o.label}
      </Select.Option>))}
    </Select>
    {value && (
      <div style={{display: "relative"}} className="flag-wrapper">
    <Icon
      className={`PhoneInputFlag ${isFocused && "hidden"}`}
      component={(flags as any)[value]}
      />
      </div>
    )}

    </>
  )
}


const NumberInput = React.forwardRef((props:any, ref) => {
  return (
    <input 
    {...props}
    className= {props.className + " ant-input"}
    ref={ref}
    />
  )
})

const PhoneNumberInputWithCountrySelect = React.forwardRef((props: PhoneNumberProps, ref: React.Ref<PhoneNumberProps>) => {
    const { value, onChange, disabled, ...rest } = props;
    const language = useSelector(userLanguageSelector);

    const langPack = useMemo(() => {
      switch (language) {
        case "pl":
          return pl;
        case "en":
          return en;
        default:
          return en;
      }
    }, [language]);

    const handleChange = (e: any) => {
      if (props.onChange && e) props.onChange(e?.toString());
    };

    const handleBlur = (e: any) => {
      if (props.onBlur && e) props.onBlur(e?.toString());
    };

    return (
      <PhoneInput
        className="phoneInputWithCountrySelect"
        defaultCountry="PL"
        placeholder={props.placeholder}
        value={value}
        labels={langPack}
        countrySelectComponent={CountrySelectDropdown}
        inputComponent={NumberInput}
        flags={flags}
        onChange={handleChange}
        onBlur={handleBlur}
        withCountryCallingCode={true}
        international={true}
        disabled={disabled}
        ref={ref}
      />
    );
  }
);


export default PhoneNumberInputWithCountrySelect;
