import React, { FunctionComponent } from "react";
import { Language } from "providers/languages";
import { ConfigProvider } from "antd";
import pl_PL from "antd/es/locale/pl_PL";
import en_US from "antd/es/locale/en_US";
import { Locale } from "antd/es/locale-provider";
import isLeapYear from 'dayjs/plugin/isLeapYear' // import plugin
import 'dayjs/locale/pl' // import locale
import 'dayjs/locale/en' // import locale
import dayjs from "dayjs";
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import utc from 'dayjs/plugin/utc'
import dayOfYear from "dayjs/plugin/dayOfYear";
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)
dayjs.extend(isLeapYear) // use plugin
dayjs.extend(utc)
dayjs.extend(dayOfYear)
dayjs.extend(localizedFormat);


const antLocaleConfigs: { [language in Language]: Locale } = {
  en: en_US,
  pl: pl_PL,
};



const validateMessages:any = {
  string: {
    min: "min from config",
  }
}


const AntConfigProvider: FunctionComponent<{ language: Language }> = ({
  language,
  children,
}) => {
  
  const locale = antLocaleConfigs[language]
  language && dayjs.locale(language) 

  
  return (
  <ConfigProvider 
  locale={locale}
  form={{validateMessages}}
  >
    {children}
  </ConfigProvider>
)};

export default AntConfigProvider;
