import OnboardingKeywordIdeas from "modules/forsant/components/keyword-ideas/OnboardingKeywordIdeas";

const OnboardingAcceptKeywordSuggestions = () => {

    return (
        <OnboardingKeywordIdeas />
    )

}

export default OnboardingAcceptKeywordSuggestions;