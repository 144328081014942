import { useState, useMemo } from "react";


export const getGeocoder = (): google.maps.Geocoder | null => {
  if (!window.google?.maps?.Geocoder) return null;
  if (window.geocoder) return window.geocoder;
  window.geocoder = new window.google.maps.Geocoder();
  return window.geocoder;
};

function geocodeAsync(
  geocoder: google.maps.Geocoder,
  request: google.maps.GeocoderRequest
): Promise<google.maps.GeocoderResult[]> {
  return new Promise((resolve, reject) => {
    geocoder.geocode(request, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK && results) {
        resolve(results);
      } else {
        reject(status);
      }
    });
  });
}

export interface ParsedResult {
  country: string | null;
  region: string | null;
  voivodeship: string | null;
  city: string | null;
  zip_code: string | null;
  latitude: number;
  longitude: number;
  address: string; // Street and number combined
  display_address: string; // Includes address, city, and country
}

function getAddressComponent(
  components: google.maps.GeocoderAddressComponent[],
  type: string
): string | null {
  const component = components.find((c) => c.types.includes(type));
  return component ? component.long_name : null;
}

export function GeocoderResultToParsedResult(
  result: google.maps.GeocoderResult
): ParsedResult {

  const address_components = result.address_components;

  const country = getAddressComponent(address_components, "country");
  const region = getAddressComponent(
    address_components,
    "administrative_area_level_1"
  );
  // API need to add region parameter, then fix voivodeship for Poland
  const voivodeship = getAddressComponent(
    address_components,
    "administrative_area_level_1"
  );
  const city =
    getAddressComponent(address_components, "locality") ||
    getAddressComponent(address_components, "administrative_area_level_2") ||
    getAddressComponent(address_components, "sublocality") ||
    getAddressComponent(address_components, "postal_town");
  const zip_code = getAddressComponent(address_components, "postal_code");
  const street_number = getAddressComponent(address_components, "street_number");
  const route = getAddressComponent(address_components, "route");

  // Construct the 'address' field: street and number combined
  let address = "";
  if (street_number && route) {
    address = `${route} ${street_number}`;
  } else if (route) {
    address = route;
  } else if (street_number) {
    address = street_number;
  }

  // Construct the 'display_address' field: includes 'address', city, and country
  const displayAddressParts = [];
  if (address) displayAddressParts.push(address);
  if (city) displayAddressParts.push(city);
  if (country) displayAddressParts.push(country);
  const display_address = displayAddressParts.join(", ");

  const location = result.geometry.location;
  const latitude = location.lat();
  const longitude = location.lng();

  return {
    country,
    region,
    voivodeship,
    city,
    zip_code,
    latitude,
    longitude,
    address,
    display_address,
  };
}

export function useGoogleGeocoder() {
  const [results, setResults] = useState<google.maps.GeocoderResult[] | null>(null);
  const geocoder = getGeocoder();

  const searchSingle = async (
    term: string
  ): Promise<google.maps.GeocoderResult[] | undefined> => {
    if (!geocoder) return;
    try {
      const res = await geocodeAsync(geocoder, { address: term });
      setResults(res);
      return res;
    } catch (error) {
      console.error("Geocoding error:", error);
    }
  };

  const searchByPoint = async (
    lat: number,
    lng: number
  ): Promise<google.maps.GeocoderResult[] | undefined> => {
    if (!geocoder) return;
    try {
      const res = await geocodeAsync(geocoder, { location: { lat, lng } });
      setResults(res);
      return res;
    } catch (error) {
      console.error("Geocoding error:", error);
    }
  };

  const clear = () => {
    setResults(null);
  };

  const parsedResults = useMemo<ParsedResult[] | null>(() => {
    if (!results) return null;
    return results.map(GeocoderResultToParsedResult);
  }, [results]);

  return {
    geocoder,
    search: searchSingle,
    searchByPoint,
    results,
    parsedResults,
    clear,
  };
}
