import React, { ReactNode, FunctionComponent } from "react";
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Spin } from "antd";
import { ButtonProps } from "antd/es/button";
import './btn-with-icon.less';
import '../../../../../components/common/common.less';
import '../../../../../themes/base/overrides.less';
import '../../../../../themes/base/apps/smart/base-list-item.less';


type Props = {
  icon: ReactNode;
  filled?: boolean;
} & Omit<ButtonProps, "icon">;

const ButtonWithIcon: FunctionComponent<Props> = ({
  icon,
  loading,
  className = "",
  filled = false,
  children,
  ...props
}) => (
  <Button className={`btn-with-icon ${filled ? " filled" : ""} ${className}`} {...props}>
    <div className="icon-container">
      {loading ? <Spin indicator={<LoadingOutlined spin />} /> : icon}
    </div>
    <div className="label-container">{children}</div>
  </Button>
);

export default ButtonWithIcon;
