import { HorizontalCenter } from "components/common/Center"
import { ProjectAIExampleClientByGenederSelector } from "modules/panel/state/AISlice"
import useSelectorWithParams from "services/hooks/useSelectorWithParams"

type AIClientAvatarProps = {
    gender: "male" | "female"
}

const AIClientAvatar = (props: AIClientAvatarProps) => {

    const exampleClient = useSelectorWithParams([ProjectAIExampleClientByGenederSelector, props.gender])

    if (!exampleClient) return null;

    const { avatar, name, city, age, hobby, characterDescription, shoppingGoals, shoppingFears } = exampleClient;

    return (
        <div className="standard-border with-background" style={{padding: "0px"}}>
            <div style={{ display: "flex", flexDirection: "row", overflow: "hidden", justifyContent: "flex-start", gap: "20px"}}>

                <HorizontalCenter style={{ padding: "0px", width: "auto"}}>
                    <img src={avatar} alt="avatar" height="300px" width="300px" style={{ borderRadius: "8px 0px 0px 8px" }} />
                </HorizontalCenter>
                <div style={{padding: "10px"}}>
                        <h1>{name}</h1>
                    <div style={{}}>
                        <p>{characterDescription}</p>
                        <p>{shoppingGoals}</p>
                        <p>{shoppingFears}</p>
                    </div>
                </div>
            </div>
        </div>
    )

}


export default AIClientAvatar;