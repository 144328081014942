import { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { DASHBOARD, getUrl } from "modules/panel/config/routes";

export default function UserWantsToDeleteAccount() {
  useEffect(() => {
    console.log("here")
    localStorage.setItem("userWantsToDeleteAccount", JSON.stringify(true));
  });

  return (
    <>
      <Redirect
        to={{
          pathname: getUrl(DASHBOARD),
        }}
      />
    </>
  );
}
