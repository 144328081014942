import React, { useEffect, useState } from "react";
import { useRelaxContainer } from "./RelaxContainerContext";
import useRelaxData from "./useRelaxData";

export default function useRelaxWrapper ({ref: outsideRef, relaxData, ...props}:any) {

    const relaxHook = useRelaxData(props);
    const rd = relaxData ? relaxData : relaxHook;

    const parentContext = useRelaxContainer();
    const localRef = React.useRef<any>();
    const ref = outsideRef ? outsideRef : localRef;

    useEffect(() => {
            parentContext && parentContext.registerField && parentContext.registerField(ref);
        return () => {
          parentContext && parentContext.unregisterField && parentContext.unregisterField(ref);
        }
    }, [])

    return {
        relaxData: rd, 
        ref: ref,
    }

}