import facebookAdAccounts from "src/icons/providers/FacebookAdAccount.svg"
import facebookPixel from "src/icons/providers/FacebookPixel.svg";
import facebookInstagram from "src/icons/providers/FacebookInstagram.svg";
import facebookPage from "src/icons/providers/FacebookBox.svg";
import logoFacebook from "src/icons/providers/Facebook.svg";
import googleAdAccounts from "src/icons/providers/GoogleAds.svg";
import googleAnalytics from "src/icons/providers/GoogleAnalytics.svg";
import googleMyBusinesses from "src/icons/providers/GoogleMyBussiness.svg";
import logoGoogle from "src/icons/providers/Google.svg";

import { TokenLocalStorage } from "services/helpers/local-storage";
import {
  getUrl as getPanelUrl,
  ONBOARDING_SET_GOOGLE_INTEGRATION,
  ONBOARDING_SET_FACEBOOK_INTEGRATION,
} from "modules/panel/config/routes";
import { ReactNode } from "react";
import { userFacebookDeclinedPermissions, userGooglekDeclinedPermissions, userHasAuthorizedFacebook, userHasAuthorizedGoogle } from "./selectors/user";
import { projectHasFilledFacebookProviderAllFields, projectHasFilledGoogleProviderAllFields } from "state/projects/projectSelectors";

export const FACEBOOK_AD_ACCOUNTS = "facebookAdAccounts";
export const FACEBOOK_PIXELS = "facebookPixels";
export const FACEBOOK_PAGES = "facebookPages";
export const FACEBOOK_INSTAGRAMS = "facebookInstagramAccounts";
export const GOOGLE_AD_ACCOUNTS = "googleAdAccounts";
export const GOOGLE_ANALYTICS = "googleAnalytics";
export const GOOGLE_MY_BUSINESSES = "googleMyBusinesses";
export const GOOGLE_SEARCH_CONSOLES = "googleSearchConsoles";

export type Provider = "facebook" | "google";

export type ProviderConfigType = {
  name: Provider;
  displayName: string;
  logo: any;
  icon: () => ReactNode;
  getPath: () => void;
  getIntegrateUrl: any;
  declinedPermissionsSelector: any;
  authorizedSelector: any;
  fields: Map<string, ProviderField>;
  filledSelector: any;
};

export type ProviderField = {
  provider: Provider;
  name: string;
  icon: any;
  permissionName: string;
  stateFieldName: string;
  stateObjectFieldName: string;
  fieldDisplayName: string;
  retrivalUrl: string; // url to get values for this field from API
  getValueDisplayName: (data: any) => string;
  getActorCardData?: (data: any) => {
    name: string;
    subtitle: string;
    id: string;
  };
  getValueDisplayImage?: (data: any) => string;
  filterAvailableValues?: (formSelection: any, availableValues: any) => any[];
  addAutoGenerateOption?: boolean;
  allowEmpty?: boolean;
  showInProjectIndicators?: boolean;
};

type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export const getProviderDisplayName = (provider: Provider) => {
  const providerConfig = ProviderConfig.get(provider);
  if (providerConfig) {
    return providerConfig.displayName;
  }
  return "";
};

export const ProviderConfig = new Map<Provider, DeepPartial<ProviderConfigType>>([
  [
    "facebook",
    {
      name: "facebook",
      displayName: "Facebook",
      logo: "facebookLogo",
      icon: logoFacebook,
      getPath: () => getPanelUrl(ONBOARDING_SET_FACEBOOK_INTEGRATION),
      getIntegrateUrl: () =>
        `${import.meta.env.VITE_FACEBOOK_AUTH_URL}${
          TokenLocalStorage.get() ? TokenLocalStorage.get().token : ""
        }`,
      filledSelector: projectHasFilledFacebookProviderAllFields,
      declinedPermissionsSelector: userFacebookDeclinedPermissions,
      authorizedSelector: userHasAuthorizedFacebook,
      fields: new Map<string, Partial<ProviderField>>([
        [
          FACEBOOK_AD_ACCOUNTS,
          {
            provider: "facebook",
            name: "adAccounts",
            permissionName: "fbAdAccount",
            stateFieldName: "service_facebook_ads_account_id",
            stateObjectFieldName: "service_facebook_ads_account",
            icon: facebookAdAccounts,
            fieldDisplayName: "Ad Account",
            getValueDisplayName: (data: any) =>
              `${data.name} (${data.account_id})`,
            getActorCardData: (data: any) => {
              return {
                name: data?.name,
                subtitle: data?.account_id,
                id: data?.id,
              };
            },
            retrivalUrl: "services/facebook/ads",
            showInProjectIndicators: true,
          },
        ],
        [
          FACEBOOK_PIXELS,
          {
            provider: "facebook",
            name: "facebookPixels",
            permissionName: "pixel",
            stateFieldName: "service_facebook_ads_account_pixels_id",
            stateObjectFieldName: "service_facebook_ads_account_pixels",
            icon: facebookPixel,
            fieldDisplayName: "Pixel",
            getValueDisplayName: (data: any) =>
              `${data.name} (${data.pixel_id})`,
            getActorCardData: (data: any) => {
              return {
                name: data?.name,
                subtitle: data?.pixel_id,
                id: data?.id,
              };
            },
            filterAvailableValues: (selectedValues: any, allValues: any) =>
              allValues.filter(
                (v: any) =>
                  v.service_facebook_ads_account_id ===
                  selectedValues.facebookAdAccounts
              ),
            retrivalUrl: "services/facebook/ads/pixels",
            addAutoGenerateOption: true,
            showInProjectIndicators: true,
          },
        ],
        [
          FACEBOOK_PAGES,
          {
            provider: "facebook",
            name: "facebookPages",
            permissionName: "fanpage",
            stateFieldName: "service_facebook_page_id",
            stateObjectFieldName: "service_facebook_page",
            icon: facebookPage,
            fieldDisplayName: "Page",
            filterAvailableValues: (selectedValues: any, allValues: any) =>
              allValues.filter((v: any) => v.can_use === "yes"),
            getValueDisplayName: (data: any) =>
              `${data.global_brand_page_name} (${data.page_id})`,
            getActorCardData: (data: any) => {
              return {
                name: data?.global_brand_page_name,
                subtitle: data?.page_id,
                id: data?.id,
              };
            },
            getValueDisplayImage: (data: any) => data.picture,
            retrivalUrl: "services/facebook/fanpage",
            showInProjectIndicators: true,
          },
        ],
        [
          FACEBOOK_INSTAGRAMS,
          {
            provider: "facebook",
            name: "facebookInstagrams",
            permissionName: "instagram",
            stateFieldName: "service_facebook_ads_account_instagrams_id",
            stateObjectFieldName: "service_facebook_ads_account_instagrams",
            icon: facebookInstagram,
            fieldDisplayName: "Instagram",
            getValueDisplayName: (data: any) =>
              `${data.name} (${data.instagram_id})`,
            getActorCardData: (data: any) => {
              return {
                name: data?.name,
                subtitle: data?.instagram_id,
                id: data?.id,
              };
            },
            filterAvailableValues: (selectedValues: any, allValues: any) =>
              allValues.filter(
                (v: any) =>
                  v.service_facebook_ads_account_id ===
                  selectedValues.facebookAdAccounts
              ),
            retrivalUrl: "services/facebook/ads/instagrams",
            getValueDisplayImage: (data: any) => data.picture,
            allowEmpty: true,
          },
        ],
      ]),
    },
  ],
  [
    "google",
    {
      name: "google",
      displayName: "Google",
      logo: "googleLogo",
      icon: logoGoogle,
      getPath: () => getPanelUrl(ONBOARDING_SET_GOOGLE_INTEGRATION),
      getIntegrateUrl: () =>
        `${import.meta.env.VITE_GOOGLE_AUTH_URL}${
          TokenLocalStorage.get() ? TokenLocalStorage.get().token : ""
        }`,
      filledSelector: projectHasFilledGoogleProviderAllFields,
      declinedPermissionsSelector: userGooglekDeclinedPermissions,
      authorizedSelector: userHasAuthorizedGoogle,
      fields: new Map<string, Partial<ProviderField>>([
        [
          GOOGLE_MY_BUSINESSES,
          {
            provider: "google",
            name: GOOGLE_MY_BUSINESSES,
            permissionName: "myBusiness",
            stateFieldName: "service_google_my_business_id",
            stateObjectFieldName: "service_google_my_business",
            icon: googleMyBusinesses,
            fieldDisplayName: "My Business",
            getValueDisplayName: (data: any) => `${data.location_name}`,
            getActorCardData: (data: any) => {
                return {
                  name: data?.location_name,
                  subtitle: data?.service_id,
                  id: data?.id,
                };
              },
            retrivalUrl: "services/google/mybusiness",
            showInProjectIndicators: true,
          },
        ],
        [
          GOOGLE_AD_ACCOUNTS,
          {
            provider: "google",
            name: GOOGLE_AD_ACCOUNTS,
            permissionName: "gAdAccount",
            stateFieldName: "service_google_ads_id",
            stateObjectFieldName: "service_google_ads",
            icon: googleAdAccounts,
            fieldDisplayName: "Ad Account",
            getValueDisplayName: (data: any) =>
              `${data.client_name} (${data.client_account_id})`,
            getActorCardData: (data: any) => {
              return {
                name: data?.client_name,
                subtitle: data?.client_account_id,
                id: data?.id,
              };
            },
            retrivalUrl: "services/google/ads/accounts",
            addAutoGenerateOption: true,
          },
        ],
        [
          GOOGLE_ANALYTICS,
          {
            provider: "google",
            name: GOOGLE_ANALYTICS,
            permissionName: "analytics",
            stateFieldName: "service_google_analytics_id",
            stateObjectFieldName: "service_google_analytics",
            icon: googleAnalytics,
            fieldDisplayName: "Analytics",
            getValueDisplayName: (data: any) =>
              `${data.name} (${data.service_id})`,
            getActorCardData: (data: any) => {
              return {
                name: data?.name,
                subtitle: data?.service_id,
                id: data?.id,
              };
            },
            retrivalUrl: "services/google/analytics",
            addAutoGenerateOption: true,
          },
        ],
        [
          GOOGLE_SEARCH_CONSOLES,
          {
            provider: "google",
            name: GOOGLE_SEARCH_CONSOLES,
            permissionName: "searchConsole",
            stateFieldName: "service_google_search_console",
            stateObjectFieldName: "service_google_search_console",
            icon: googleAnalytics,
            fieldDisplayName: "Search Console",
            getValueDisplayName: (data: any) => data.site_url,
            getActorCardData: (data: any) => {
              return {
                name: data?.site_url,
                subtitle: data?.site_url,
                id: data?.id,
              };
            },
            retrivalUrl: "services/google/sites",
            addAutoGenerateOption: true,
          },
        ],
      ]),
    },
  ],
]);

export const mappedProvidersFields = Array.from(ProviderConfig.values()).reduce(
  (accumulator, provider) => {
    for (const [key, value] of provider?.fields?.entries()) {
      accumulator.set(key, value);
    }
    return accumulator;
  },
  new Map()
);

export const getProviderFieldConfigByName = (name: string) => {
  return mappedProvidersFields.get(name);
};

