import { Modal } from "antd";
import React from "react";
import FormattedMessage from "components/common/FormattedMessage";

const { confirm } = Modal;

export function handleGoogleTokenErrorMiddleware({ dispatch, getState }) {
  return (next) => (action) => {

    if (action) {

    const { type, payload } = action;
    const matches = /(.*)_(FAILURE)/.exec(type);

    const errorCode =
      payload &&
      payload.response &&
      payload.response.error &&
      payload.response.error.code
        ? payload.response.error.code
        : undefined;

    let loginStatus = getState().loginStatus;

    if (
      matches &&
      loginStatus.get("logged") &&
      errorCode &&
      (errorCode === "7404" || errorCode === "7403" || errorCode === "7402")
    ) {
      reauthGoogleToken(loginStatus);
    }

    if (payload && payload.included) {
      Promise.resolve(payload.included).then(
        (val) =>
          val &&
          val.map((notification) => {
            const { attributes } = notification;
            if (
              attributes && attributes.code &&
              (attributes.code === "7404" ||
                attributes.code === "7403" ||
                attributes.code === "7402")
            ) {
              reauthGoogleToken(loginStatus);
            }
            return "";
          })
      );
    }

    if (payload && payload.access_tokens) {

      let needReauth = false;
      if (payload.access_tokens?.google?.need_reauth === true) needReauth = true;
      if (payload.access_tokens?.[0]?.need_reauth === true) needReauth = true;

      //const needReauth = payload.access_tokens?.google?.need_reauth;
      if (needReauth) {
        dispatch({type: "NEED_REAUTH", payload: {platform: "google"}})
      }
    }
    }

    next(action);
  };
}

export const reauthGoogleToken = (loginStatus, closable = true) =>
  confirm({
    closable: closable,
    title: (
      <FormattedMessage
        id="services.middlewares.tokenError.google.title"
        defaultMessage="There is a problem with your Google integration"
      />
    ),
    content: (
      <FormattedMessage
        id="services.middlewares.tokenError.google.content"
        defaultMessage="You need to authorize Google again"
      />
    ),
    centered: true,
    okText: (
      <FormattedMessage
        id="services.middlewares.tokenError.google.authorize"
        defaultMessage="Authorize"
      />
    ),
    cancelButtonProps: {
      disabled: !closable,
    },
    cancelText: (
      <FormattedMessage
        id="services.middlewares.tokenError.google.later"
        defaultMessage="Later"
      />
    ),
    onOk() {
      window.location.href = `${import.meta.env.VITE_GOOGLE_AUTH_URL}${loginStatus.getIn([
        "token",
        "value",
      ])}`;
    },
  });

export default handleGoogleTokenErrorMiddleware;
