import { Alert, Col, Row } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import StandardButton from "components/common/StandardButton";
import useAPI from "services/hooks/useAPI";
import {
  getSubscriptions,
} from "state/subscriptions/subscriptionsSlice";
import { Subscription } from "state/subscriptions/subscriptionTypes";
import { subscriptionsArraySelector } from "state/subscriptions/subscriptionsSelectors";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import InfinityList from "tools/infinityList/infinityList";
import {
  subscriptionWithDueListColumns,
  subscriptionWithDueListHeading,
} from "../subscriptions/SubscriptionWithDueListHeading";
import SubscriptionListEntryWrapper from "../subscriptions/SubscriptionListEntry";
import { StandardModal, useStandardModal } from "services/hooks/useModal";
import { forwardRef, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DASHBOARD, LOGIN } from "modules/panel/config/routes";
import { useLogout } from "modules/panel/containers/public/LogoutPage";
import { Text, Title } from "components/common/StandardText";
import UserAccountSummary from "../UserAccountSummary";

type DeleteUserModalProps = {
  onClose: () => void;
};

export const DeleteUser = () => {
  const subscriptionsCall = useAPI(getSubscriptions(), true);
  const subscriptions = useSelectorWithParams(subscriptionsArraySelector);
  const [ref, open, close] = useStandardModal();
  const history = useHistory();
  const [warningAccepted, setWarningAccepted] = useState<boolean>(false);

  const renderItem = (item: any) => (
    <SubscriptionListEntryWrapper
      subscription={item}
      subscriptionColumns={subscriptionWithDueListColumns}
    />
  );

  const subscriptiosWithDue =
    subscriptions &&
    subscriptions?.filter(
      (subscription: Subscription) =>
        (subscription?._generated?.totalDue > 0 ||
          subscription?._generated?.status === "active") &&
        subscription?._generated?.isInternal === false
    );

  const handleGoBack = () => {
    history.push(DASHBOARD);
  };

  useEffect(() => {
    localStorage.removeItem("userWantsToDeleteAccount");
  }, [])


  if (!warningAccepted) {

    return (
      <div>

        <Title>
          <FormattedMessage
            id="panel.user.deleteAccount.title"
            defaultMessage="Delete Account"
          />
        </Title>

        <UserAccountSummary />

        <Text>
          <FormattedMessage
            id="panel.user.deleteAccount.warning"
            defaultMessage="Deleting your account will delete all your data. This action is irreversible. Are you sure you want to proceed?"
          />
        </Text>

        <Row gutter={30} style={{ paddingTop: "20px" }}>
          <Col xs={24} md={12}>
            <StandardButton onClick={handleGoBack}>
              <FormattedMessage
                id="panel.user.deleteAccount.back"
                defaultMessage="Back"
              />
            </StandardButton>
          </Col>
          <Col xs={24} md={12}>
            <StandardButton
              type="primary"
              onClick={() => setWarningAccepted(true)}
            >
              <FormattedMessage
                id="panel.user.deleteAccount.warning.accept"
                defaultMessage="I understand and want to proceed"
              />
            </StandardButton>
          </Col>
        </Row>
      </div>
    )

  }

  return (
    <>
      <Title>
        <FormattedMessage
          id="panel.user.deleteAccount.title"
          defaultMessage="Delete Account"
        />
      </Title>

      <DeleteUserModal ref={ref} onClose={close}/>
      {subscriptiosWithDue && subscriptiosWithDue?.length > 0 ? (
        <>
          <div className="infinity-wrapper" style={{ maxHeight: "400px" }}>
            <InfinityList
              headers={subscriptionWithDueListHeading}
              items={subscriptiosWithDue || []}
              loading={subscriptionsCall.loading}
              itemSkeleton={() => "skeleton"}
              renderItem={renderItem}
            />
          </div>
          <Alert
            message={
              <FormattedMessage
                id="panel.user.deleteAccount.subscriptionWithDue.info.message"
                defaultMessage="You have active or unpaid subscriptions, you must close them to delete your account"
              />
            }
            description={
              <FormattedMessage
                id="panel.user.deleteAccount.subscriptionWithDue.info.description"
                defaultMessage="Contact support if you have any problems"
              />
            }
            type="warning"
            icon
          />
        </>
      ) : (
        <span style={{ fontSize: "24px" }}>
          <FormattedMessage
            id="panel.user.deleteAccount.warning"
            defaultMessage="Are you sure to delete your account?"
          />
        </span>
      )}
      <Row gutter={30} style={{ paddingTop: "20px" }}>
        <Col xs={24} md={12}>
          <StandardButton onClick={handleGoBack}>
            <FormattedMessage
              id="panel.user.deleteAccount.back"
              defaultMessage="Back"
            />
          </StandardButton>
        </Col>
        <Col xs={24} md={12}>
          <StandardButton
            type="primary"
            disabled={subscriptiosWithDue && subscriptiosWithDue?.length > 0 ? true : false}
            onClick={open}
          >
            <FormattedMessage
              id="panel.user.deleteAccount.delete"
              defaultMessage="Delete Account"
            />
          </StandardButton>
        </Col>
      </Row>
    </>
  );
};

const DeleteUserModal = forwardRef((props: DeleteUserModalProps, ref: any) => {

  const [state, setState] = useState<"none" | "success" | "error">("none");
  const { call, error, loading } = useAPI({
    url: ({ getApiUrl }) => getApiUrl(`user`, 1),
    method: "DELETE",
  });

  const history = useHistory();

  const handleDeleteUser = () => {
    call().then((res) => {
      if (res.status === 200 || res.status === 204) {
        localStorage.setItem(
          "userWantsToDeleteAccount",
          JSON.stringify(false)
        );
        setState("success");
      } else {
        setState("error");
      }
    })
  }

  const handleClose = () => {
    history.goBack();
  }

  const handleGoodbye = () => {
    //clear local storage
    localStorage.clear();
    window.location.reload();

//    history.push(LOGIN);
  }

  const getButtons = () => {

    if (state === "error") {
      return (
        <StandardButton
          key="back"
          onClick={handleClose}
        >
          <FormattedMessage
            id="common.close"
            defaultMessage="Close"
          />
        </StandardButton>
      )
    }

    if (state === "success") {

      return (
        <StandardButton
          key="back"
          onClick={handleGoodbye}
        >
          <FormattedMessage
            id="common.close"
            defaultMessage="Close"
          />
        </StandardButton>
      )

    }

    return [
      <StandardButton
        key="back"
        onClick={handleClose}
      >
        <FormattedMessage
          id="common.cancel"
          defaultMessage="Cancel"
        />
      </StandardButton>,
      <StandardButton
        key="submit"
        type="primary"
        onClick={handleDeleteUser}
        loading={loading}>
        <FormattedMessage
          id="panel.user.deleteAccountModal.confirm"
          defaultMessage="Delete account"
        />
      </StandardButton>
    ]

  }

  const getContent = () => {

    if (state === "error") {
      return (
        <Alert 
            message={(
              <div>
                {error?.error?.message}
                <FormattedMessage
                  id="panel.user.deleteAccountModal.error"
                  defaultMessage="There was an error deleting the account. Please try again later."
                />
              </div>
            )} 
            type="error" 
          />
      )
    }

    if (state === "success") {
      return (
        <Alert 
            message={(
              <FormattedMessage
                id="panel.user.deleteAccountModal.success"
                defaultMessage="Account successfully deleted"
              />
            )} 
            type="success" 
          />
      )
    }

    return (
      <FormattedMessage
        id="panel.user.deleteAccountModal.warningFinal"
        defaultMessage="Are you sure you want to delete your account?"
      />
    )


  }

  return (
    <>
      <StandardModal
        ref={ref}
        title={
          <FormattedMessage
            id="panel.user.deleteAccountModal.title"
            defaultMessage="Delete user account"
          />
        }
        modalProps={{ footer: getButtons() }}
        onCancel={() => state === "success" ? handleGoodbye() : handleClose()}
      >
        {getContent()}
      </StandardModal>
    </>
  );
});
