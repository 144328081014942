import { useEffect, useState } from 'react'
import { WebsitePageProps } from './WebsitePage';
import useAPI from 'services/hooks/useAPI';
import useSelectorWithParams from 'services/hooks/useSelectorWithParams';
import { WebsiteSelector } from 'modules/site-generator/reducers/websiteSlice';
import { AddPageDescription, UpdatePage, UpdatePageContent, WebsitePageSelector } from 'modules/site-generator/reducers/websitePagesSlice';
import RelaxFormWrapper from 'tools/relaxForm/RelaxForm';
import { StandardFullColumn, StandardRow, StandardThirdColumn } from 'components/layout/StandardGrid';
import RelaxField from 'tools/relaxForm/RelaxField';
import { Col, Input, Select } from 'antd';
import FormattedMessage from 'components/common/FormattedMessage';
import useRelaxData from 'tools/relaxForm/useRelaxData';
import HtmlEditor from 'tools/textEditor/HtmlEditor';
import StandardButton from 'components/common/StandardButton';
import RelaxMultiform from 'tools/relaxForm/RelaxMultiform';

const defaultPageDescription = {
  name: "Nazwa Treści",
  content: "Treść Strony",
  language_code: "pl",
};

export default function WebsitePageEditor(props: WebsitePageProps) {

  const page = useSelectorWithParams(WebsitePageSelector(props.pageId))
  const [type, setType] = useState("html");
  const { call, data } = useAPI({});
  const website = useSelectorWithParams([WebsiteSelector]);

  const handleTypeChange = (type: any) => {
    setType(type);
  };

  useEffect(() => {
    if (!page) return;
    if (
      !page.websiteCustomPageDescriptions ||
      page.websiteCustomPageDescriptions.length === 0
    ) {
      call({
        ...AddPageDescription(website.id, page.id),
        body: defaultPageDescription,
      });
    }
  }, [page]);

  if (!page) return null;

  return (
    <>
      <RelaxMultiform
        callAPI={UpdatePage(website.id, page.id)}
        initialValues={page}
      >
        <StandardRow>
          <StandardFullColumn>
            <RelaxField
              name={["title"]}
              label={
                <FormattedMessage
                  id="apps.websites.customPages.page.title"
                  defaultMessage="Title"
                />
              }
            >
              <Input />
            </RelaxField>
          </StandardFullColumn>
        </StandardRow>
        <StandardRow>
          <StandardThirdColumn>
            <RelaxField
              name={["type"]}
              label={
                <FormattedMessage
                  id="apps.websites.customPages.page.type"
                  defaultMessage="Type"
                />
              }
              onChange={handleTypeChange}
            >
              <Select defaultValue="html">
                <Select.Option value="html">HTML</Select.Option>
                <Select.Option value="iframe_url">iFrame</Select.Option>
              </Select>
            </RelaxField>
          </StandardThirdColumn>
          <StandardThirdColumn>
            <RelaxField
              name={["status"]}
              label={
                <FormattedMessage
                  id="apps.websites.customPages.page.status"
                  defaultMessage="Status"
                />
              }
              onChange={handleTypeChange}
            >
              <Select defaultValue="draft">
                <Select.Option value="active">
                  <FormattedMessage
                    id="apps.websites.customPages.page.status.active"
                    defaultMessage="Active"
                  />
                </Select.Option>
                <Select.Option value="draft">
                  <FormattedMessage
                    id="apps.websites.customPages.page.status.draft"
                    defaultMessage="Draft"
                  />
                </Select.Option>
              </Select>
            </RelaxField>
          </StandardThirdColumn>
          <StandardThirdColumn>
            <RelaxField
              name={["place"]}
              label={
                <FormattedMessage
                  id="apps.websites.customPages.page.place"
                  defaultMessage="Place"
                />
              }
            >
              <Select defaultValue="root">
                <Select.Option value="root">
                  <FormattedMessage
                    id="apps.websites.customPages.page.place.root"
                    defaultMessage="Root"
                  />
                </Select.Option>
                <Select.Option value="attraction">
                  <FormattedMessage
                    id="apps.websites.customPages.page.place.attraction"
                    defaultMessage="Attraction"
                  />
                </Select.Option>
                <Select.Option value="special_offer">
                  <FormattedMessage
                    id="apps.websites.customPages.page.place.specialOffer"
                    defaultMessage="Special Offer"
                  />
                </Select.Option>
              </Select>
            </RelaxField>
          </StandardThirdColumn>
        </StandardRow>
      </RelaxMultiform>
      {page.websiteCustomPageDescriptions &&
        page.websiteCustomPageDescriptions.map(
          (content: any, index: number) => (
            <WebsitePageContent
              pageId={props.pageId}
              website={website}
              content={content}
            />
          )
        )}
      <StandardRow justify="end">
        <Col>
          <StandardButton key="delete" onClick={props.displayDeleteConfirmation}>
            <FormattedMessage id="common.delete" defaultMessage="Delete" />
          </StandardButton>
        </Col>
      </StandardRow>

    </>
  );
}

const WebsitePageContent = (props: {
  pageId?: string | number;
  website?: any;
  content?: any;
}) => {
  const page = useSelectorWithParams(WebsitePageSelector(props.pageId))

  if (!page) return null;

  return (
    <RelaxMultiform
      callAPI={UpdatePageContent(props.website?.id, page.id, props.content.id)}
      initialValues={props.content}
    >
      <StandardRow>
        <StandardFullColumn>
          <RelaxField
            name={"name"}
            label={
              <FormattedMessage
                id="apps.websites.customPages.page.content"
                defaultMessage="Name"
              />
            }
          >
            <Input />
          </RelaxField>
        </StandardFullColumn>
      </StandardRow>

      <StandardRow>
        {page.type === "html" && (
          <StandardFullColumn>
            <RelaxField name={["content"]} >
              <HtmlEditor />
            </RelaxField>
          </StandardFullColumn>
        )}
        {page.type === "iframe_url" && (
          <StandardFullColumn>
            <RelaxField name={["content"]} label="iFrame URL">
              <Input />
            </RelaxField>
          </StandardFullColumn>
        )}
      </StandardRow>
    </RelaxMultiform>
  );
};