import FormattedMessage from "components/common/FormattedMessage";
import { ReactNode } from "react";
import requirementsTourism, { disallowedRoutesTourism } from "./requirements/requirementsTourism";
import requirementsCarservice, { disallowedRoutesCarservice } from "./requirements/requirementsCarservice";


export type IndustryConfig = {
    value: string,
    label: ReactNode,
    requirements?: any[],
    disabled?: boolean,
    disallowedRoutes?: string[],
}

const industriesConifg:IndustryConfig[] = [
    {
        value: "touristic",
        label: <FormattedMessage id="apps.websites.config.industries.tourism" defaultMessage="Tourism"/>,
        requirements: [
            ...requirementsTourism
        ],
        disallowedRoutes: disallowedRoutesTourism
    },
    {
        value: "carservice",
        label: <FormattedMessage id="apps.websites.config.industries.carservice" defaultMessage="Carservice"/>,
        requirements: [
            ...requirementsCarservice
        ],
        disallowedRoutes: disallowedRoutesCarservice
    },
    {
        value: "flowers",
        label: <FormattedMessage id="apps.websites.config.industries.flowers" defaultMessage="Flowers"/>,
        disabled: true,
        requirements: [
        ]
    },
    {
        value: "animals",
        label: <FormattedMessage id="apps.websites.config.industries.animals" defaultMessage="Animals"/>,
        disabled: true,
        requirements: [
        ]
    }
]

const defaultIndustry = {
    value: "default",
    label: <FormattedMessage id="apps.websites.config.industries.default" defaultMessage="Industry"/>,
    requirements: [
    ]
}

export default industriesConifg;

export const GetIndustryByName = (name: string):IndustryConfig => industriesConifg.find((industry) => industry.value === name) || defaultIndustry;

