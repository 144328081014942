import { faCheckCircle, faCircle, faExclamationCircle, faHourglass, faHourglassStart, faQuestionCircle, faSpinner } from "@fortawesome/pro-light-svg-icons";
import { Collapse, Tooltip } from "antd";
import AwesomeIcon from "components/common/AwesomeIcon";
import React, { useEffect, useRef } from "react";
import { ReactNode, useMemo, useState } from "react";
import useAPISmartActions from "services/hooks/useAPISmartActions";
import { useCreationEditor } from "../CreationEditor";
import { ValidationResult } from "./ValidationResults";
import DebugDisplay from "components/common/DebugDisplay";
import { StandardTooltip } from "modules/panel/testing/StandardTooltip";
import { createPortal } from "react-dom";
import _ from "lodash";
import { CreationSectionState } from "./useCreationSection";
import SectionStatusIconDisplay, { getSectionStatusIcon } from "./SectionStatusDisplay";

export type CreationSectionProps = {
    title: ReactNode,
    panelKey: string,
    children?: ReactNode,
    tooltip?: ReactNode,

    errors?: any[],

    smartActionPrefix?: string,

    statusAPI: CreationSectionState,
    statusData: CreationSectionState,
    statusForm: CreationSectionState,
    statusValidation: CreationSectionState,
    status: CreationSectionState,
    sectionName: string,

    validationErrors?: string | string[] | null | Record<string, string | string[]>;
    subfieldErrors?: any[] | null;

    checkSectionStatus?: () => CreationSectionState;
    subfieldPortalElement?: string | ((field: string | number) => Element | null);

}


const CreationSection = React.forwardRef((props: CreationSectionProps, ref: any) => {

    const header = useMemo(() => {
        return (
            <div style={{
                display: "flex",
                flexDirection: "row",
                gap: "5px"
            }}>
                <SectionStatusIconDisplay sectionName={props.sectionName} />
                <div>{props.title}</div>
                <div style={{ flexGrow: 1 }} />
                <DebugDisplay>
                    <div className="debug" style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "5px"
                    }}>
                        <div>A:{getSectionStatusIcon(props.statusAPI)}</div>
                        <div>D:{getSectionStatusIcon(props.statusData)}</div>
                        <div>F:{getSectionStatusIcon(props.statusForm)}</div>
                        <div>V:{getSectionStatusIcon(props.statusValidation)}</div>
                    </div>
                </DebugDisplay>
                <StandardTooltip
                    title={props.tooltip ? props.tooltip : ""}
                    >
                    <AwesomeIcon icon={faQuestionCircle} size="xl" color={"grey"} 
                    />
                </StandardTooltip>
            </div>

        )
    }, [props.status, props.statusAPI, props.statusData, props.statusForm, props.statusValidation])


    const queryAndInjectError = (field: string, errors: string[]) => {
        const query = `#creationSection-${props.sectionName} #ce-rf-${field.replaceAll(".","-")} .extra-error`;
        const elem = document.querySelector(query);
        if (!elem) return [];
        return errors.map((error, i) => createPortal(<ValidationResult result={error} />, elem))
    }


    const validationResults = useMemo(() => {
        //console.log("validation results: ", props.sectionName, props.validationErrors)
        if (!props.validationErrors) return null;
        if (typeof props.validationErrors === "string") return <ValidationResult result={props.validationErrors} />
        if (Array.isArray(props.validationErrors)) return props.validationErrors.map((res, i) => <ValidationResult key={i} result={res} />)
        return Object.keys(props.validationErrors).map((key) => {
            if (!props.validationErrors[key] || !props.validationErrors[key].length) return null;
            return queryAndInjectError(key, props.validationErrors[key]);
        })
        return null;
    }, [props.validationErrors])

    const subfieldValidationResults = useMemo(() => {
        //console.log("subfield validation results: ", props.panelKey, props.subfieldErrors)
        if (!props.subfieldErrors) return null;
        if (!props.subfieldErrors.length) return null;
        if (!props.subfieldPortalElement) return null;

        return props.subfieldErrors.map((error, i) => {

            let targetElement:Element|null = null;
            if (typeof props.subfieldPortalElement === "function") {
                targetElement = props.subfieldPortalElement(i);
            } else {
                if (!props.subfieldPortalElement) return null;
                targetElement = document.getElementById(props.subfieldPortalElement);
            }

            if (!targetElement) return null;
            return createPortal(<ValidationResult key={i} result={error} />, targetElement)
        })

    }, [props.subfieldErrors])

    return (
        <Collapse.Panel
            id={`creationSection-${props.sectionName}`}
            forceRender={true}
            {...props}
            key={props.panelKey}
            header={header}>
            {props.children}
            {validationResults}
            {/* {subfieldValidationResults} */}
        </Collapse.Panel>
    )

});


export const SectionSimpleStateCheck = (fieldName: string | string [], content: any, setStatusData: any) => {
    if (!content) {
        setStatusData("initial");
        return;
    }

    if (_.get(content, fieldName) === undefined || _.get(content, fieldName) === null) {
        setStatusData("error");
        return;
    }

    setStatusData("ready");
}

export const SectionSimpleStateArrayCheck = (fieldName: string, content: any, setStatusData: any) => {
    if (!content) {
        setStatusData("initial");
        return;
    }

    if (!content[fieldName]) {
        setStatusData("error");
        return;
    }

    if (content[fieldName].length === 0) {
        setStatusData("error");
        return;
    }

    setStatusData("ready");
}


export default CreationSection;