import StandardButton from "components/common/StandardButton";
import { useMemo, useState } from "react";
import useAPI from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { getSubscriptionsOffers } from "state/subscriptions/subscriptionsEndpoints";
import products from "products";
import { useHistory } from "react-router-dom";
import "./purchases.less";
import PriceDisplay from "./subscriptionPurchase/PriceDisplay";
import FormattedMessage from "components/common/FormattedMessage";
import AwesomeIcon from "components/common/AwesomeIcon";
import { faLockKeyhole } from "@fortawesome/pro-light-svg-icons";
import HelpPanelDocument from "tools/helpPanel/HelpPanelDocument";
import { calculateOfferPricingDetails, SubscriptionOffer } from "state/subscriptions/subscriptionPricingHelpers";
import { useHelpPanel } from "tools/helpPanel/HelpPanelDrawer";
import Ribbon from "antd/es/badge/Ribbon";
import Checkbox from "components/inputs/Checkbox";
import { currentProjectSelector } from "state/projects/projectSelectors";


type BuyNowCardProps = {
    product?: string,
    offer?: number | string,
    discount?: number,
}

const BuyNowCard = (props: BuyNowCardProps) => {

    const product = props.product || "FastTonyPl";
    const project = useSelectorWithParams(currentProjectSelector);
    const offersCall = useAPI(getSubscriptionsOffers(product), true);

    const offer = useMemo(() => {

        if (!offersCall.data) return null;

        if (!offersCall.data) return null;
        if (offersCall.data.length === 0) return null;

        let offer;
        if (!props.offer) return;

        offer = offersCall.data.find((o: SubscriptionOffer) => (o.id === props.offer || o.name === props.offer || o.billing_cycle === props.offer) && o.active===true && o.hide===false);

        if (!offer) return;
        return calculateOfferPricingDetails(offer);

    }, [offersCall.data, props.offer])

    const { openHelpPanel } = useHelpPanel();

    let history = useHistory();

    const handleOpenHelper = (e) => {
        e.preventDefault();
        e.stopPropagation();
        openHelpPanel(`products.${product}.description`);
    };

    const handleGoToStore = () => {

        if (!offer) return;
        if (isChecked !== "checked") {
            setIsChecked("notChecked");
            return;

        }

        const lsSettings = localStorage.getItem("selectedOffer") ? JSON.parse(localStorage.getItem("selectedOffer") as string) : {};

        const toSet:any = {
          ...lsSettings,
          offerId: parseInt(offer.id), 
          product: product,
        }
  
        localStorage.setItem("selectedOffer", JSON.stringify(toSet));

        history.push(`/panel/company/products/buy/${product}/${project.id}`);


    }

    const [isChecked, setIsChecked] = useState<"none" | "checked" | "notChecked">("none");

    const content = (
        <div className="buy-now-card standard-border with-background with-hover">
            <div className="product">
                <img src={products[product] ? products[product].logo : ""} className="product" />
                <h1>{products[product].name}</h1>
            </div>


            {offer ? (
                <div className="offer">
                    <PriceDisplay price={offer?.subscription?.base} currency={offer?.currency} cycle={offer.cycle} />
                    <div className="product-desciption">
                        <HelpPanelDocument pageId={`products.${product}.shortDescription`} className="product-desciption" />
                    </div>
                    <a onClick={handleOpenHelper}>
                        <FormattedMessage
                            id="panel.containers.payment.buyNowCard.offerDetails"
                            defaultMessage="See offer details"
                        />
                    </a>
                </div>
            ) : (
                <div className="offer">
                </div>
            )}

            <Checkbox className={`consent-checkbox ${isChecked === "notChecked" ? "checkbox-required" : ""}`} value={isChecked === "checked"} onChange={(c) => setIsChecked(c ? "checked" : "notChecked")}>
                <FormattedMessage
                    id="panel.containers.payment.buyNowCard.immidiateExecutionConsent"
                    defaultMessage="I agree to the immediate execution of the Service Agreement."
                />
            </Checkbox>

            <div className="actions">

                <div className="secure-payment">
                    <AwesomeIcon icon={faLockKeyhole} size="xl" />
                    <FormattedMessage
                        id="panel.containers.payment.buyNowCard.securePayment"
                        defaultMessage="Secure Payment"
                    />
                </div>

                <StandardButton type="primary" className="button" onClick={handleGoToStore}>
                    <FormattedMessage
                        id="panel.containers.payment.buyNowCard.buyNow"
                        defaultMessage="Buy now"
                    />
                </StandardButton>
            </div>



        </div>
    )

    if (offersCall.loading) return offersCall.StatusDisplay;

    if (props.discount) {

        const heightByDiscount = () => {
            if (props.discount >= 50) return ["35px", "1.8rem"];
            return ["25px", "1rem"];
        }

        return (
            <Ribbon className="promo-ribbon" text={<h1 style={{ color: "white", fontSize: heightByDiscount()[1] }}>{`-${props.discount}%`}</h1>}
                color="orange"
                style={{
                    height: heightByDiscount()[0],
                    top: "12px"
                }}>
                {content}
            </Ribbon>
        )
    } else {
        return content;
    }



}

export default BuyNowCard;