export default function RelaxDebugView ({relaxData, ...props}:any) {

    const rd = relaxData

    const {
        currentValue, 
        hasErrors,
        changed,
        touched, 
        initialValue,
        isFocused,
        optimisticValue,
        canSendStatus,
        canSend
    } = rd; 

    if (!rd.settings.debug) return null;

    return (
        <div className="debug"> 
        <div>
            Name: {rd.settings.name}
        </div>
        <div>
            {hasErrors && "Errors" }
        </div>
        <div>
            {touched && "Touched"}
        </div>
        <div>
            {changed && "Changed"}
        </div>
        <div>
            {isFocused && "Focused"}
        </div>
        <div>
            Can send: {canSend ? "Yes" : "No"} {canSendStatus}
        </div>
        <div>
            Initial: {typeof initialValue === "object" ? JSON.stringify(initialValue) : initialValue}
        </div>
        <div>
            Current: {typeof currentValue === "object" ? JSON.stringify(currentValue) : currentValue}
        </div>
        <div>
            Optimistic: {typeof optimisticValue === "object" ? JSON.stringify(optimisticValue) : optimisticValue}
        </div>
        </div>
    )


}