import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import {
    Editor,
    EditorState,
    ContentState,
    CompositeDecorator,
    Modifier,
} from "draft-js";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import useCallAfterUpdate from "services/hooks/useCallAfterUpdate";
import { useTextTools } from "./TextTools";
import { faHashtag, faImage, faRecycle, faRobot, faSmile } from "@fortawesome/pro-light-svg-icons";
import StandardButton from "components/common/StandardButton";
import { useCreationEditor } from "tools/creationEditor/CreationEditor";
import { AllAdsAndRemarkettingKeywords } from "modules/smart/smartSlice";
import { currentFlatSubscriptionPermissionsSelector } from "state/subscriptions/subscriptionsSelectors";

export function doesTextContainKeywords(text:string, keywords:string[]) {

    for (let keyword of keywords) {
        const pattern = new RegExp(`(?<=^|\\s|[^a-zA-Z0-9#_])${keyword}(?=\\s|[^a-zA-Z0-9#_]|$)`, 'gmi');
        if (pattern.test(text)) return true;
    }

    return false;

}


function findKeywordOccurrences(input:string, keywords:string[]) {
    const results = {};

    for (let keyword of keywords) {
        const pattern = new RegExp(`(?<=^|\\s|[^a-zA-Z0-9#_])${keyword}(?=\\s|[^a-zA-Z0-9#_]|$)`, 'gmi');
        const occurrences = [];
        let match;
        while (match = pattern.exec(input)) {
            occurrences.push({ position: match.index, length: match[0].length });
        }
        results[keyword] = occurrences;
    }

    return results;
}

const createHighlightDecorator = (words: string[]) =>
    new CompositeDecorator([
        {
            strategy: (contentBlock, callback) => {
                const text = contentBlock.getText();

                const matches = findKeywordOccurrences(text, words);
                for (let keyword in matches) {
                    for (let occurrence of matches[keyword]) {
                        callback(occurrence.position, occurrence.position + occurrence.length);
                    }
                }

            },
            component: ({ children }: any) => {
                return (
                    <mark
                        style={{
                            background: "rgba(61, 97, 239, 0.1)",
                            borderRadius: "5px",
                        }}
                    >
                        {children}
                    </mark>
                );
            },
        },
    ]);


const PostTextEditor = React.forwardRef((props: any, ref: any) => {

    const permissions = useSelectorWithParams(currentFlatSubscriptionPermissionsSelector)
    const keywords = useSelectorWithParams([AllAdsAndRemarkettingKeywords])
    const keywordStrings:string[] = useMemo(() => {
        if (!keywords) return [];
        if (!keywords.initial_keywords) return [];
        const {auto_promotion, ...rest} = keywords.initial_keywords;
        return (Object.values(rest).filter(kw => kw !== null) as string[])
    }, [keywords])

    const safeValue = useMemo(() => {

        if (!props.value) return "";
        if (Array.isArray(props.value)) {
            if (props.value.length === 0) return "";
            return props.value[0];
        };
        return props.value;

    }, [props.value])

    const makeEditorStateWithSafeValue = () => EditorState.createWithContent(ContentState.createFromText(safeValue ? safeValue : ""),createHighlightDecorator(keywordStrings || []))

    const [editorState, setEditorState] = useState(makeEditorStateWithSafeValue());
    const isFocused = useRef(false);
    const {openTextTools} = useTextTools();

    const editor = useRef(null);

    const getPlainTextAndCallOnChange = () => {
        if (!editorState) return;
        const plainContent = editorState.getCurrentContent().getPlainText();
        if (plainContent === safeValue) return;

        props.onChange && props.onChange(plainContent)
    }

    const triggerChangeAfterUpdate = useCallAfterUpdate((close:boolean) => {
        //console.log("triggering change after update")
        getPlainTextAndCallOnChange();
        if (close && props.onBlur) props.onBlur();
    })

    useEffect(() => {
        if (isFocused.current) return;
        const plainContent = editorState.getCurrentContent().getPlainText();
        if (plainContent === safeValue) return;
        setEditorState(makeEditorStateWithSafeValue())
    }, [safeValue]);

    const handleChange = (newState: any) => {
        setEditorState(newState)
        //if (!wasFocused) return;
        getPlainTextAndCallOnChange();
    }

    const insertText = (text: string) => {
        setEditorState((editorState) => {
        const currentContent = editorState.getCurrentContent();
        const currentSelection = editorState.getSelection();

        const newContent = Modifier.replaceText(
            currentContent,
            currentSelection,
            text + " "
        );

        const newEditorState = EditorState.push(
            editorState,
            newContent,
            "insert-characters"
        );

        EditorState.forceSelection(
            newEditorState,
            newContent.getSelectionAfter()
        )

        return newEditorState;
    })
        
    }

    const replaceText = (text: string) => {
        if (!text || typeof text !== "string") return;
        setEditorState(EditorState.createWithContent(ContentState.createFromText(text),createHighlightDecorator(keywordStrings || [])))
    }

    const focus = () => {
        if (isFocused.current) return;
        isFocused.current = true;
        (editor as any).current.focus()
    };

    const wrapperFocus = () => {
        if (isFocused.current) return;
        isFocused.current = true;
        (editor as any).current.focus()
    }


    useImperativeHandle(
        ref,
        () => ({
            editor,
            insertText,
        })
    )

    const {creationId} = useCreationEditor();

    const tools = useMemo(() => {

        const tools = [];

        if (keywordStrings && keywordStrings.length > 0) tools.push(
            <StandardButton icon={faHashtag} onClick={() => openTextTools({
                tool: "keywords",
                onInsert: (e: any) => insertText(e),
                onOpen: triggerChangeAfterUpdate,
                onClose: () => triggerChangeAfterUpdate(close),
            })}/>
        )

        tools.push(
            <StandardButton icon={faSmile} onClick={() => openTextTools({
                tool: "emoji",
                onInsert: (e: any) => insertText(e),
                onOpen: triggerChangeAfterUpdate,
                onClose: () => triggerChangeAfterUpdate(close),
            })}/>
        )

        if (permissions && permissions.includes("use module smart ai generate")) tools.push(
            <StandardButton icon={faRecycle} onClick={() => openTextTools({
                tool: "replaceFromLexicon",
                onInsert: (e: any) => {
                    replaceText(e);
                    //triggerChangeAfterUpdate(close)
                },
                onOpen: triggerChangeAfterUpdate,
                onClose: () => triggerChangeAfterUpdate(close),
                width: "800px",
                props: {
                    ...(props.fieldMetadata || {})
                }
            })}/>
        )

        //if (permissions && permissions.includes("use module smart ai generate")) tools.push(
        tools.push(
            <StandardButton icon={faImage} onClick={() => openTextTools({
                tool: "generateFromMedia",
                onInsert: (e: any) => {
                    replaceText(e);
                    //triggerChangeAfterUpdate(close)
                },
                onOpen: triggerChangeAfterUpdate,
                onClose: () => triggerChangeAfterUpdate(close),
                width: "800px",
                props: {
                    ...(props.fieldMetadata || {})
                }
            })}/>
        )

       return tools;
    }, [])

    return (
        <div className="message-input">
            <div className="input-container">
                <div className="ant-input" onClick={wrapperFocus}>
                    <Editor
                        ref={editor}
                        editorState={editorState}
                        onChange={handleChange}
                        onBlur={() => {
                            props.onBlur && props.onBlur(); 
                            isFocused.current = false}}
                        onFocus={focus}
                        spellCheck={true}
                    />
                </div>
            </div>
           <div className="toolbar-wrapper">
                <div className="toolbar">
                    {tools}
                </div>
            </div>
        </div>
    )

})

export default PostTextEditor;