import WizardButtons from "components/misc/WizardButtons";
import { useCancellationWizard } from "./SubscriptionCancellationWizard";
import { Title } from "components/common/StandardText";
import FormattedMessage from "components/common/FormattedMessage";
import StandardActorCard, { StandardSubscriptionCard } from "components/common/StandardActorCard";
import ProductLogo from "providers/routing/layouts/components/product-logo/product-logo";
import { ReactNode, useMemo } from "react";
import { useStore } from "react-redux";
import { libraryProjectSelector } from "state/librarySlice";
import { getProductLogo } from "providers/routing/layouts/components/product-logo/smallProductLogo";
import SubscriptionCancellationSummaryAlert from "./SubscriptionCancellationSummaryAlert";


const title = <Title>
    <FormattedMessage
        id="panel.subscriptions.cancellation.selectionConfirmation.title"
        defaultMessage="Subscription Cancellation"
    />
</Title>

function SubscriptionCancellationBenefits() {

    const { subscriptionId, goToStep, subscription, closeWizard } = useCancellationWizard();
    const state = useStore();



    const connectedProjects = useMemo(() => {

        if (!subscription) return [];
        if (!subscription.projects) return [];

        const mappedProjects: ReactNode[] = [];

        subscription.projects.forEach((p: any) => {
            const project = libraryProjectSelector(state.getState(), p);
            if (!project) return;
            mappedProjects.push(<StandardActorCard actor={project} />)
        });

        return mappedProjects;

    }, [subscription])

    return (
        <>
            <SubscriptionCancellationSummaryAlert />
            <div style={{ padding: "20px 0" }}>
                {connectedProjects.length > 0 ? (
                    <>
                        <FormattedMessage
                            id="panel.subscriptions.cancellation.selectionConfirmation.connectedProjects"
                            defaultMessage="This subscription is connected to following projects:"
                        />
                        {connectedProjects}
                    </>
                ) : (
                    <FormattedMessage
                        id="panel.subscriptions.cancellation.selectionConfirmation.noConnectedProjects"
                        defaultMessage="This subscription is not connected to any projects."
                    />
                )}
            </div>

            <div style={{ padding: "20px 0" }}>
                <FormattedMessage
                    id="panel.subscriptions.cancellation.selectionConfirmation.instructions"
                    defaultMessage="Here are the instructions for cancelling your subscription. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam quis aliquam ultricies, nisl nisl aliquet nunc, quis aliquam nisl nisl vitae nisl."
                />
            </div>
            <WizardButtons
                canGoBack
                canGoForward
                backLabel={<FormattedMessage
                    id="common.cancel"
                    defaultMessage="Cancel"
                    />}
                onNext={() => goToStep("selectPlan")}
                onBack={() => closeWizard()}
            />
        </>
    );
}

export default SubscriptionCancellationBenefits;