import { ProductConfig } from "products";
import logo from "products/callCenter/FastTonyCallCenter-Logo-Small.png";

const config: ProductConfig = {
  name: "CallCenter",
  logo,
  requirements: []
};

export default config;
