import { Input, Radio } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import StandardButton from "components/common/StandardButton";
import DateTimeInput from "components/inputs/DateTimeInput";
import { StandardModal, useSimpleModal, useStandardModal } from "services/hooks/useModal";
import RelaxField from "tools/relaxForm/RelaxField";
import RelaxFormWrapper from "tools/relaxForm/RelaxForm";
import AIStrategySelect from "./AIStrategySelect";
import AIStrategyAndIntensitySelect from "./AIStrategyAndIntensitySelect";
import useRelaxData from "tools/relaxForm/useRelaxData";
import { useEffect, useState } from "react";
import useAPI from "services/hooks/useAPI";
import dayjs, { Dayjs } from "dayjs";
import DatePicker, { WeekPicker } from "components/inputs/DatePicker";
import { rulesetMax } from "tools/relaxForm/relaxPresets";
import { GetAiStrategies } from "modules/panel/state/AISlice";
import Gallery from "tools/gallery/Gallery";


const StandardStrategyForm = (props: any) => {

    return (
        <RelaxFormWrapper
                    relaxData={props.rd}
                    name="form"
                    hideStatus={true}
                    mode="manual"
                >
                    <RelaxField
                        name="start"
                        label={
                            <FormattedMessage
                                id="apps.smart.components.aiGeneration.createCampaignForm"
                                defaultMessage="Start time"
                            />
                        }
                        initialValue={dayjs().startOf("week").add(1, "week")}
                    >
                        <WeekPicker 
                            disabledDate={(current: Dayjs) => {
                                return current.isBefore(dayjs().startOf("week").add(1, "week"))
                            }}
                        />
                    </RelaxField>
                    <AIStrategyAndIntensitySelect />
                    <RelaxField
                        name="comment"
                        label={
                            <FormattedMessage
                                id="apps.smart.components.aiGeneration.instructions"
                                defaultMessage="Instructions"
                            />
                        }
                        rules={rulesetMax(160)}
                        >
                        <Input.TextArea />
                    </RelaxField>
                </RelaxFormWrapper>
    )

}

const PostsFromImagesForm = (props: any) => {
    return (
        <RelaxFormWrapper
                    relaxData={props.rd}
                    name="form"
                    hideStatus={true}
                    mode="manual"
                >
                    <RelaxField
                        name="start"
                        label={
                            <FormattedMessage
                                id="apps.smart.components.aiGeneration.createCampaignForm"
                                defaultMessage="Start time"
                            />
                        }
                        initialValue={dayjs().startOf("week").add(1, "week")}
                    >
                        <WeekPicker 
                            disabledDate={(current: Dayjs) => {
                                return current.isBefore(dayjs().startOf("week").add(1, "week"))
                            }}
                        />
                    </RelaxField>
                    <RelaxField
                        name="focus_date_start"
                        label={
                            <FormattedMessage
                                id="apps.smart.components.aiGeneration.focusDateStart"
                                defaultMessage="Start date for the period for the AI to focus on"
                            />
                        }>
                        <DatePicker />
                        </RelaxField>
                        <RelaxField
                            name="focus_date_end"
                            label={
                                <FormattedMessage
                                    id="apps.smart.components.aiGeneration.focusDateEnd"
                                    defaultMessage="End date for the period for the AI to focus on"
                                />
                            }>
                            <DatePicker />
                        </RelaxField>
                    <RelaxField
                        name="photos"
                        label={
                            <FormattedMessage
                                id="apps.smart.components.aiGeneration.photos"
                                defaultMessage="Photos"
                            />
                        }
                        >
                            <Gallery 
                                allowedTypes={["image"]}
                                allowedTools={[]}
                            />
                        </RelaxField>
                    <RelaxField
                        name="posts_length"
                        label={
                            <FormattedMessage
                                id="apps.smart.components.aiGeneration.postsLength"
                                defaultMessage="Posts length"
                            />
                        }
                        >
                            <Radio.Group defaultValue={400}>
                                <Radio.Button value={200}>200</Radio.Button>
                                <Radio.Button value={400}>400</Radio.Button>
                                <Radio.Button value={600}>600</Radio.Button>
                            </Radio.Group>
                        </RelaxField>
                    <RelaxField
                        name="comment"
                        label={
                            <FormattedMessage
                                id="apps.smart.components.aiGeneration.instructions"
                                defaultMessage="Instructions"
                            />
                        }
                        rules={rulesetMax(160)}
                        >
                        <Input.TextArea />
                    </RelaxField>
                </RelaxFormWrapper>
    )
}

const AICampaignGenerationModal = (props: any) => {

    const [modalRef, open, close] = useStandardModal();
    const rd = useRelaxData({});
    const [openSuccessModal, contextHolder] = useSimpleModal();
    const strategyCall = useAPI(GetAiStrategies, true)
    const [strategy, setStrategy] = useState<any>(null);
    
    const generateCall = useAPI({
        url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/ai/campaign`),
    })

    const handleGenerate = () => {

        const generationData = {
            start: dayjs().utc().startOf("week").add(1, "week").format("YYYY-MM-DD HH:mm:ss"),
            comment: "Simple ad promoting my page",
            ...rd?.currentValue,
            photos: rd?.currentValue?.photos?.map((photo: any) => photo.url) || null,
            focus_date_start: dayjs(rd.currentValue.focus_date_start).format("YYYY-MM-DD HH:mm:ss"),
            focus_date_end: dayjs(rd.currentValue.focus_date_end).format("YYYY-MM-DD HH:mm:ss"),
        }

        if (rd?.currentValue?.start) generationData.start = dayjs(rd?.currentValue?.start).startOf("week").format("YYYY-MM-DD HH:mm:ss")
        
        generateCall.call({
            url: ({ getApiUrl, projectId }) => strategy === "postsFromImages" ? getApiUrl(`projects/${projectId}/ai/campaign-from-photos`) : getApiUrl(`projects/${projectId}/ai/campaign`),
            method: "POST",
            body: { ...generationData }
        }).then((res) => {

            if (res?.status === 200) {
                openSuccessModal({
                    title: "Success",
                    content: <FormattedMessage
                        id="apps.smart.components.aiGeneration.createCampaignForm.success.message"
                        defaultMessage="Your creations are being generated"
                    />,
                    onOk:close
                })
            } else {
                openSuccessModal({
                    title: "Error",
                    content: <FormattedMessage
                        id="apps.smart.components.aiGeneration.createCampaignForm.error.message"
                        defaultMessage="There was an error while generating creations."
                />,
                })
            }

        })
    }

    return (
        <>
            <StandardModal
                ref={modalRef}
                modalProps={{
                    footer: 
                        <StandardButton
                            onClick={() => {
                                handleGenerate()
                            }}
                            
                disabled={ rd.extraStatus === "fieldErrors"}
                            type="primary"
                            loading={generateCall.loading}
                        >
                            <FormattedMessage
                        id="apps.smart.components.aiGeneration.generateButton"
                        defaultMessage="Generate!"
                    />
                        </StandardButton>
                }}
                title={
                    <FormattedMessage
                        id="apps.smart.components.aiGeneration.title"
                        defaultMessage="Generate with AI"
                    />
                }
            >
                <div>
                <AIStrategySelect value={strategy} onChange={(s) => setStrategy(s)}/>
                {strategy === "postsFromImages" ? <PostsFromImagesForm rd={rd} /> : null}
                {(strategy && strategy !== "postsFromImages") ? <StandardStrategyForm rd={rd} /> : null}
                </div>
            </StandardModal>
            <StandardButton
                onClick={open}
                type="primary"
            >
                <FormattedMessage
                    id="apps.smart.components.aiGeneration.buttonToOpenTheModal"
                    defaultMessage="Generate with AI"
                />
            </StandardButton>
            {contextHolder}
        </>
    )


}

export default AICampaignGenerationModal;