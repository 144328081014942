import useSelectorWithParams from "services/hooks/useSelectorWithParams"
import { OrganizationGroupsSelector, OrganizationIdSelector, OrganizationManagersSelector, OrganizationProjectsSelector, OrganizationSelector } from "../data/organizationSlice"

type useOrganizationReturnType = {
    projects: any[],
    groups: any[],
    managers: any[],
    organization: any,
    organizationId: string | number 
}

export default function useOrganization():useOrganizationReturnType {

    const organizationId = useSelectorWithParams(OrganizationIdSelector);
    const organization = useSelectorWithParams(OrganizationSelector);
    const projects = useSelectorWithParams(OrganizationProjectsSelector);
    const groups = useSelectorWithParams(OrganizationGroupsSelector);
    const managers = useSelectorWithParams(OrganizationManagersSelector);

    return {
        projects,
        groups,
        managers,
        organizationId,
        organization
    }

}
