import React, { FunctionComponent } from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import ErrorPage from "modules/panel/components/error-page";
import FormattedMessage from "components/common/FormattedMessage";
import { useLocation } from "react-router-dom";
import StandardButton from "components/common/StandardButton";

export type NotFoundLocationState = {
  from: string;
  redirectTo: string;
};

export const NotFoundComponent: FunctionComponent = () => {
  const location = useLocation<NotFoundLocationState>();
  const { from, redirectTo } = location.state;

  return (
    <ErrorPage
      title={
        <FormattedMessage
          id="panel.providers.routing.notFound.title"
          defaultMessage="Not found"
        />
      }
      subtitle={
        <FormattedMessage
          id="panel.providers.routing.notFound.subtitle"
          defaultMessage="Requested page does not exist, or you do not have appropriate permissions to access."
        />
      }
      backgroundText={"404"}
      url={from}
      buttons={
        <Link to={redirectTo}>
          <StandardButton type="primary">
            <FormattedMessage
              id="panel.providers.routing.notFound.takeMeBack"
              defaultMessage="Show me the better road"
            />
          </StandardButton>
        </Link>
      }
    />
  );
};

export default NotFoundComponent;
