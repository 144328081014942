import { CardCvcElement, CardExpiryElement, CardNumberElement, Elements, ElementsConsumer, useElements, useStripe } from "@stripe/react-stripe-js";
import { PaymentIntent, StripeError, loadStripe } from "@stripe/stripe-js";
import { Col, Row } from "antd";
import Center, { HorizontalCenter } from "components/common/Center";
import FormattedMessage from "components/common/FormattedMessage";
import { StandardFullColumn, StandardHalfFullColumn, StandardRow } from "components/layout/StandardGrid";
import { userEmailSelector, userLanguageSelector } from "modules/panel/config/selectors/user";
import { ReactNode, useEffect, useMemo, useState } from "react";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { usePayment } from "./Payment";
import useAPI, { callAPIProps } from "services/hooks/useAPI";
import { CardData } from "./PaymentMenthodSelect";

export const changeDefaultPaymentMethod: callAPIProps = {
  url: ({ getApiUrl }) => getApiUrl("user/default-payment", 2),
  method: "POST",
}


const stripeElementOptions = {
  options: {
    style: {
      base: {
        lineHeight: "46px",
        fontSize: "20px",
        fontWeight: 300,
      },
    },
  },
};


export function StripeCardInput(props: {onCardStatusChange: (status: boolean) => void}) {

  const [cardNumberStatus, setCardNumberStatus] = useState(false);
  const [cardExpiryStatus, setCardExpiryStatus] = useState(false);
  const [cardCvcStatus, setCardCvcStatus] = useState(false);

  const handleChange = (event: any) => {
    if (event.elementType === "cardNumber") {
      setCardNumberStatus(event.complete);
    }
    if (event.elementType === "cardExpiry") {
      setCardExpiryStatus(event.complete);
    }
    if (event.elementType === "cardCvc") {
      setCardCvcStatus(event.complete);
    }
  }

  useEffect(() => {
    if (cardNumberStatus && cardExpiryStatus && cardCvcStatus) {
      props.onCardStatusChange && props.onCardStatusChange(true);
    } else {
      props.onCardStatusChange && props.onCardStatusChange(false);
    }
  }, [cardNumberStatus, cardExpiryStatus, cardCvcStatus]);

  return (
            <div style={{
              width: "100%",
              display: "grid",
              placeItems: "center",
            }}>
              <div style={{
                maxWidth: "400px",
              }}>
              <StandardRow>
                <StandardFullColumn>
                  <label>
                    <FormattedMessage
                      id="panel.components.containers.creditCard.creditCardNumber"
                      defaultMessage="Credit card number"
                    />
                  </label>
                  <div className="ant-input">
                    <CardNumberElement 
                    {...stripeElementOptions} 
                      onChange={handleChange}
                    />
                  </div>
                </StandardFullColumn>
              </StandardRow>
              <StandardRow>
                <StandardHalfFullColumn>
                  <label>
                    <FormattedMessage
                      id="panel.components.containers.creditCard.expirationDate"
                      defaultMessage="Expiration date"
                    />
                  </label>
                  <div className="ant-input">
                    <CardExpiryElement 
                    {...stripeElementOptions} 
                    
                    onChange={handleChange}/>
                  </div>
                </StandardHalfFullColumn>
                <StandardHalfFullColumn>
                  <label>
                    <FormattedMessage
                      id="panel.components.containers.creditCard.cvc"
                      defaultMessage="CVC"
                    />
                  </label>
                  <div className="ant-input">
                    <CardCvcElement {...stripeElementOptions} 
                    
                    onChange={handleChange}/>
                  </div>
                </StandardHalfFullColumn>
              </StandardRow>
              <div style={{
                fontSize: "12px",
              }}>
                <FormattedMessage
                  id="panel.components.containers.creditCard.reacurringPaymentsWarning"
                  defaultMessage="By submitting you credit card information you agree to recurring payments in agreement with our terms of service."
                />
              </div>
              </div>
            </div>
  )

}


export function useStripeCardInput() {

  const stripe = useStripe();
  const elements = useElements();
  const email = useSelectorWithParams(userEmailSelector);
  const setDefaultPaymentCall = useAPI(changeDefaultPaymentMethod);


  const AddNewCard = async (): Promise<[CardData | null, StripeError | null | string]> => {

    if (!stripe) return [null, "NO_STRIPE"]
    if (!elements) return [null, "NO_ELEMENTS"]

    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) return [null, "NO_CARD_ELEMENT"]

    const paymentMethodResult = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        email: email
      }
    });

    if (paymentMethodResult.error) {
      return [null, paymentMethodResult.error];
    }

    const res = await setDefaultPaymentCall.call({
      body: {
        payment_id: paymentMethodResult.paymentMethod.id
      }
    })


    if (res) {
      
      if (res.status === 200 && res.data) {
        return [res.data as CardData, null]
      }
    
      if (res.status === 400) {

        const err = res?.error?.errors?.[0]?.detail || "UNKNOWN_ERROR"

        return [null, err]
      }
    
    }


    return [null, "UNKNOWN_ERROR"]
  }

  const ConfirmPayment = async (paymentIntent:PaymentIntent): Promise<[true | null, StripeError | null | string]> => {
      if (!stripe) return [null, "NO_STRIPE"]

      const { client_secret, payment_method } = paymentIntent;
      if (!client_secret) return [null, "NO_CLIENT_SECRET"]
      if (!payment_method) return [null, "NO_PAYMENT_METHOD"]
      const confirmResult = await stripe.confirmCardPayment(
        client_secret,
      );


      if (confirmResult.paymentIntent?.status === "succeeded") {
        return [true, null]
      }

      return [null, confirmResult.error || "UNKNOWN_ERROR"]


  }

  return { AddNewCard, ConfirmPayment };

}
