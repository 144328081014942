import React from "react";
import { CloseCircleOutlined } from '@ant-design/icons';
import { Typography } from "antd";
import {FormattedMessage} from "react-intl";
import './integrations.less'

const { Paragraph, Text } = Typography;

const DeclinedPermissions = ({ declinedPermissions }) => {
    return (
        <>
            {
                declinedPermissions &&
                declinedPermissions.size &&
                <div className="providers-declined-permissions">
                    <Text className="title">
                        <FormattedMessage
                            id="panel.components.integrations.declinedPermissions.title"
                            defaultMessage="You need to accept following permissions in order to use app."
                        />
                    </Text>
                    { generateRows(declinedPermissions) }
                </div>
            }
        </>
    )
};

const generateRows = declinedPermissions => {
    let generatedRows = [];
    declinedPermissions.forEach(permission => generatedRows.push(
        <Paragraph className="row" key={ permission }>
            <CloseCircleOutlined style={{ color: 'red' }} /> { permission }
        </Paragraph>
    ));
    return generatedRows;
};

export default DeclinedPermissions;