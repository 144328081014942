import { createSelector, createSlice } from "@reduxjs/toolkit"
import { callAPIFunction, callAPIProps } from "services/hooks/useAPI"
type CompanySliceState = {
    id: number,
    name: string,
    email: string,
    street: string,
    street_number: string,
    street_apartment: string,
    postal_code: string,
    city: string,
    country: string,
    vat_number: string,
    regon: string,
    website: string,
    data_are_valid: string,
}

const initialState: Partial<CompanySliceState> = {

}

const companySlice = createSlice({
    name: "company",
    initialState,
    reducers: {
        get: (state, action) => {
            state = action.payload.data
            return state;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase("LOAD_USER_SUCCESS", (state, action) => {
            state = {
                id: action?.payload?.user?.company_id,
                name: action?.payload?.user?.company_name,
                email: action?.payload?.user?.company_email,
                street: action?.payload?.user?.company_street,
                street_number: action?.payload?.user?.company_street_number,
                street_apartment: action?.payload?.user?.company_street_apartment,
                postal_code: action?.payload?.user?.company_postal_code,
                city: action?.payload?.user?.company_city,
                country: action?.payload?.user?.company_country,
                vat_number: action?.payload?.user?.company_vat_number,
                regon: action?.payload?.user?.company_regon,
                website: action?.payload?.user?.company_website,
                data_are_valid: action?.payload?.user?.company_data_are_valid,
            }

            return state

        })
    }
})

export const companySelector = createSelector([
    (state:any) => state.company,
], (company) => company)

export const companyDataSelector = createSelector([
    companySelector
], (company) => company)

export const companyDataConfirmedSelector = createSelector([
    companySelector
], (company):boolean => company?.data_are_valid && company?.data_are_valid.length > 0
)

export const updateCompanyData:callAPIFunction = (companyId: number) => ({
    url: ({ getApiUrl, projectId }) => getApiUrl(`user/company-data/${companyId}`, 1),
    method: "PATCH",
    successDispatch: companySlice.actions.get
})

export const confirmCompanyData:callAPIFunction = (companyId: number) => ({
    url: ({ getApiUrl, projectId }) => getApiUrl(`user/company-data/${companyId}/confirm`, 1),
    method: "POST",
    successDispatch: companySlice.actions.get
})

export default companySlice;