import { createSelector, createSlice } from "@reduxjs/toolkit";
import { callAPIProps } from "services/hooks/useAPI";

export type CatalogProps = {
  created_at: string
  external_id: null | number
  id: string
  name: string
  product_feed_external_id: string
  products: CatalogProductProps[]
  project:{ 
    id: number 
  }
  project_id: number
  updated_at: string
}

export type CatalogProductProps = {
  id:string;
  product_catalog_id: string;
  retailer_item_id: string;
  google_product_category: string;
  title: string;
  description: string;
  availability: string;
  condition: string;
  price: number;
  link: string;
  image_link: string;
  brand: string;
  additional_image_link?: string;
  color: string;
  item_group_id: string;
  material: string;
  pattern: string;
  product_type: string;
  custom_label_0?: string;
  custom_label_1?: string;
  custom_label_2?: string;
  custom_label_3?: string;
  custom_label_4?: string;
  markup: number;
  delivery_cost: number;
  force_promotion: boolean;
};

export type CallAppState = {
    catalogs: Record<string, CatalogProps> 
};

const initialState: CallAppState = {
    catalogs:{}
};

export const productsCatalogSlice = createSlice({
  name: "productsCatalog",
  initialState,
  reducers: {
    get: (state, action) => {
      if (Array.isArray(action.payload.data)) {
        action.payload.data.forEach((catalog: CatalogProps) => {
          state.catalogs[catalog.id] = catalog;
        });
      }
    },
    update: (state, action) => {
        state.catalogs[action.payload.data.id] = action.payload.data
    },
    getProducts: (state, action) => {
      if(action.payload.additional !== undefined){
        state.catalogs[action.payload.additional.catalogId].products = action.payload.data
      }
    },
    addProduct: (state, action) => {
      if (action.payload.additional !== undefined) {
        const { catalogId } = action.payload.additional;
        const newProduct = action.payload.data;

        state.catalogs[catalogId].products.push(newProduct);
      }
    },
    updateProduct: (state, action) => {
      if (action.payload.additional !== undefined) {
        const { catalogId, productId } = action.payload.additional;
        const updatedProductData = action.payload.data;
        state.catalogs[catalogId].products = state.catalogs[catalogId].products.map(
          (product) => product.id === productId ? updatedProductData : product
        );
      }
    },
    deleteProduct: (state, action) => {
      if (action.payload.additional !== undefined) {
        const { catalogId, productId } = action.payload.additional;
        state.catalogs[catalogId].products = state.catalogs[catalogId].products.filter(
          (product) => product.id !== productId
        );
      }
    },
  },
});

export const GetCatalogs: callAPIProps = {
  url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/product-catalogs`, 1),
  method: "GET",
  successDispatch: productsCatalogSlice.actions.get,
};

export const GetCatalog = (catalogId: string): callAPIProps => ({
    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/product-catalogs/${catalogId}`, 1),
    method: "GET",
    successDispatch: productsCatalogSlice.actions.get,
  });

export const CreateCatalog: callAPIProps = {
    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/product-catalogs`, 1),
    method: "POST",
    successDispatch: productsCatalogSlice.actions.update,
};

export const UpdateCatalog = (catalogId: string): callAPIProps => ({
    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/product-catalogs/${catalogId}`, 1),
    method: "PATCH",
    successDispatch: productsCatalogSlice.actions.update,
  });

export const GetProductsCatalog = (catalogId: string): callAPIProps => ({
    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/product-catalogs/${catalogId}/products`, 1),
    method: "GET",
    successDispatch: productsCatalogSlice.actions.getProducts,
    passToDispatcher:{
      catalogId:catalogId
    }
  });

export const AddProduct = (catalogId: string): callAPIProps => ({
  url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/product-catalogs/${catalogId}/products`, 1),
  method: "POST",
  successDispatch: productsCatalogSlice.actions.addProduct,
  passToDispatcher:{
    catalogId:catalogId
  }
});

export const UpdateProduct = (catalogId: string, productId:string): callAPIProps => ({
  url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/product-catalogs/${catalogId}/products/${productId}`, 1),
  method: "PATCH",
  successDispatch: productsCatalogSlice.actions.updateProduct,
  passToDispatcher:{
    catalogId:catalogId,
    productId:productId
  }
});

export const DeleteProduct = (catalogId: string, productId:string): callAPIProps => ({
  url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/product-catalogs/${catalogId}/products/${productId}`, 1),
  method: "DELETE",
  successDispatch: productsCatalogSlice.actions.deleteProduct,
  passToDispatcher:{
    catalogId:catalogId,
    productId:productId
  }
});


export const ProductsSelector = (state:any) => state.productsCatalog;

export const ProductsCatalogsSelector = createSelector([
    ProductsSelector
], (products) => products.catalogs)

export const ProductsCatalogsArraySelector = createSelector([
  ProductsSelector
],
  (products): CatalogProps[] => products.catalogs ? Object.values(products.catalogs) : []
);
