import FormattedMessage from "components/common/FormattedMessage";
import PaymentStatus from "modules/panel/components/invoice-list-item/status";
import {DownloadButton} from "components/DownloadButton";
import Number from "modules/smart/components/list-item/base-list-item/number";
import { ReactNode } from "react";
import { InfinityColumnProps } from "tools/infinityList/infinityTemplates";


const columnHeadings: { [key: string]: ReactNode } = {
    "transactionId": <FormattedMessage
        id="panel.containers.invoices.header.status"
        defaultMessage="Transaction ID"
    />,
    "date": <FormattedMessage
        id="panel.containers.invoices.header.type"
        defaultMessage="Date"
    />,
    "amount": <FormattedMessage
        id="panel.containers.invoices.header.reach"
        defaultMessage="Amount"
    />,
    "paymentStatus": <FormattedMessage
        id="panel.containers.invoices.header.clicks"
        defaultMessage="Payment status"
    />,
    "vatInvoiceId": <FormattedMessage
        id="panel.containers.invoices.header.reactions"
        defaultMessage="VAT invoice ID"
    />,
    "action": ""
}

const sizes = {
    transactionId: [false, false, false, false, "1fr", "1fr"],
    standard: ["100px", "100px", "100px", "200px", "200px", "200px"],
    action: [false, "100px", "100px", "200px", "200px", "200px"],
    
}


export const InvoicesHeadings = [
    {
        key: "id",
        size: sizes.transactionId,
        render: () => columnHeadings["transactionId"]
    },
    {
        key: "invoice_created",
        size: sizes.standard,
        render: () => columnHeadings["date"]
    },
    {
        key: "total",
        size: sizes.standard,
        render: () => columnHeadings["amount"]
    },
    {
        key: "status",
        size: sizes.standard,
        render: () => columnHeadings["paymentStatus"]
    },
    {
        key: "invoice_number",
        size: sizes.standard,
        render: () => columnHeadings["vatInvoiceId"]
    },
    {
        key: "action",
        size: sizes.action,
        render: () => columnHeadings["action"]
    },
];


export const DefaultColumnsInvoices =  [
    {
        key: "id",
        size: sizes.transactionId,
        render: (item: any) => (item ? item.id : '-'),
    },
    {
        key: "invoice_created",
        size: sizes.standard,
        render: (item: any) => (item.invoice_created ? item.invoice_created : '-'),
        
    },
    {
        key: "total",
        size: sizes.standard,
        render: (item: any) => (item.total ? <Number value={item.total} isCurrency={true} currency={item.currency} /> : '-'),
    },
    {
        key: "status",
        size: sizes.standard,
        render: (item: any) => (item.status ? <PaymentStatus status={item.status} /> : '-'),
    },
    {
        key: "invoice_number",
        size: sizes.standard,
        render: (item: any) => (item.invoice_number ? item.invoice_number : '-'),
    },
    {
        key: "action",
        size: sizes.action,
        render: (item: any) => (
            <DownloadButton 
                unavailableTooltip={
                    <FormattedMessage
                    id="panel.profile.payment.table.download.notYetAvailable"
                    defaultMessage="Not yet available. You can download invoices only with status paid"
                  />
                }
                url={`user/invoice/${item.id}`}
                fileName={`invoice-${item.invoice_number}.pdf`}
                available={!!item?.invoice_id}
                label={
                    <FormattedMessage
                    id="panel.profile.payment.table.download"
                    defaultMessage="Download"
                  />
                }
            />
          ),
    },
]

