import { useEffect, useMemo } from "react";
import { Conversation, useChat } from "./Chat";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { Comment, Empty } from "antd";
import dayjs from "dayjs";
import { Title } from "components/common/StandardText";
import FormattedMessage from "components/common/FormattedMessage";
import StandardAvatar from "components/common/Avatar/StandardAvatar";
import AwesomeIcon from "components/common/AwesomeIcon";
import { faUser } from "@fortawesome/pro-light-svg-icons";

type ChatConversationProps = {
  conversation: Conversation;
};

const ChatConversation = (props: ChatConversationProps) => {
  const { setCurrentConversationId } = useChat();

  const avatar = props.conversation.conversation_with ? (
    <h3 style={{ margin: 0 }}>
      {props.conversation.conversation_with
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase())
        .join("")}
    </h3>
  ) : (
    <StandardAvatar
      image={<AwesomeIcon icon={faUser} color="black" />}
      size={"large"}
    />
  );

  return (
    <li
      className="chat-conversation"
      onClick={() => setCurrentConversationId(props.conversation.id)}
    >
      <Comment
        author={props.conversation.conversation_with}
        datetime={
          props.conversation.lastMessage_at
            ? dayjs(props.conversation.lastMessage_at).format("L LT")
            : null
        }
        avatar={
          <div
            style={{
              backgroundColor: "#efefef",
              borderRadius: "20px",
              width: "40px",
              height: "40px",
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {avatar}
          </div>
        }
        content={<p>{props.conversation.lastMessage}</p>}
        actions={[]}
      />
    </li>
  );
};

type ChatConversationsListProps = {
  conversationsSelector: (state: any) => Conversation[];
};

const ChatConversationList = (props: ChatConversationsListProps) => {
  const conversations = useSelectorWithParams(props.conversationsSelector);

  const mappedConversations = useMemo(() => {
    if (!conversations) return null;
    if (!conversations.length) return <Empty />;

    return conversations.map((conversation, index) => (
      <ChatConversation conversation={conversation} />
    ));
  }, [conversations]);

  return (
    <div className="standard-border chat-conversation-list">
      <ul>{mappedConversations}</ul>
    </div>
  );
};

export default ChatConversationList;
