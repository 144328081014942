import React, { FunctionComponent, ReactNode } from "react";
import { Layout } from "providers/routing/layouts/templates/templates";
import { Layout as AntLayout } from "antd";
const { Content } = AntLayout;

export type EditLayoutContent = {
  layout: Layout.Edit;
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  tabs?: boolean;
};

const EditLayoutContent: FunctionComponent<EditLayoutContent> = ({
  children,
  tabs = false,
  subtitle = false,
}) => (
  <Content className={`edit-layout-content${tabs ? " with-tabs" : ""}`}>
    <div className="container">
      {subtitle && <h2>{subtitle}</h2>}
      {children && children}
    </div>
  </Content>
);

export default EditLayoutContent;
