import { createContext, useContext, useEffect, useMemo, useState } from "react";
import ChatMessage from "./ChatMessage";
import dayjs, { Dayjs } from "dayjs";
import "./chat.less";
import ChatMessageInput from "./ChatMessageInput";
import useAPI, { callAPIProps, callAPISettings } from "services/hooks/useAPI";
import ChatConversationList from "./ChatConversationList";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import ChatMessages from "./ChatMessages";

export type Message = {
    message: string;
    author: string;
    date: Dayjs;
}

export type Conversation = {
    id: string;
    messages: Message[];
    conversation_with: string;
    lastMessage: string;
    lastMessage_at: string;
    speaker: string;
}

type ChatContextType = {
    currentConversationId: string;
    setCurrentConversationId: (id: string) => void;
    conversation: Conversation | null;
}

export type ChatContextProviderProps = {
    sendMessage: (conversationId: string) => callAPIProps;
    threadsSelector: (state: any) => any[];
    conversationSelector: (state: any, id:string) => Conversation;
    conversationsSelector: (state: any) => Conversation[];
}

const ChatContext = createContext<ChatContextType>({
    currentConversationId: "",
    setCurrentConversationId: () => {},
    conversation: null
}); 

ChatContext.displayName = "ChatContext";
export const useChat = (): ChatContextType => useContext(ChatContext);


const Chat = (props:ChatContextProviderProps) => {

    const [currentConversationId, setCurrentConversationId] = useState<string>("")
    const conversation = useSelectorWithParams([props.conversationSelector, currentConversationId]);

    const value = {
        sendMessage: props?.sendMessage,
        currentConversationId,
        conversation,
        setCurrentConversationId
    }

    return (
        <ChatContext.Provider value={value}>
            <div className="chat">
            <ChatConversationList conversationsSelector={props.conversationsSelector}/>
            <div>
            <ChatMessages conversationSelector={props.conversationSelector} />
            <ChatMessageInput sendMessage={props.sendMessage}/>
            </div>
            </div>
        </ChatContext.Provider>
    )

};

export default Chat;