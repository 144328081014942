import React, { ReactNode } from "react";
import FormattedMessage from "components/common/FormattedMessage";

export enum SupportTier {
    basic = "basic",
    advanced = "advanced",
    concierge = "concierge"
}

export enum SupportFeature {
    technicalSupport = "technicalSupport",
    accountAndPayments = "accountAndPayments",
    contentAndCommunication = "contentAndCommunication",
    budgetOptimization = "budgetOptimization",
    contactMessenger = "contactMessenger",
    contactEmail = "contactEmail",
    contactPhone = "contactPhone",
    contactPhoneLimit = "contactPhoneLimit",
    fullTime= "fullTime",
}

export type SupportTierOffer = {
    price: number;
    technicalSupport?: boolean;
    accountAndPayments?: boolean;
    contentAndCommunication?: boolean;
    budgetOptimization?: boolean;
    contactMessenger?: boolean;
    contactEmail?: boolean;
    contactPhone?: boolean;
    contactPhoneLimit?: number;
    fullTime?: boolean;
}


export const supportFeatureNames: {
    [key in SupportFeature]: ReactNode
} = {
    [SupportFeature.technicalSupport]:(<FormattedMessage id="apps.panel.support.feature.technicalSupport" defaultMessage = "Technical Support"/>),
    [SupportFeature.accountAndPayments]:(<FormattedMessage id="apps.panel.support.feature.accountAndPayments" defaultMessage = "Account and payments"/>),
    [SupportFeature.contentAndCommunication]:(<FormattedMessage id="apps.panel.support.feature.contentAndCommunication" defaultMessage = 'Content and communication'/>),
    [SupportFeature.budgetOptimization]:(<FormattedMessage id="apps.panel.support.feature.budgetOptimization" defaultMessage = "Budget optimization, peformance"/>),
    [SupportFeature.contactMessenger]:(<FormattedMessage id="apps.panel.support.feature.contactMessenger" defaultMessage = "Messenger chat"/>),
    [SupportFeature.contactEmail]:(<FormattedMessage id="apps.panel.support.feature.contactEmail" defaultMessage = "E-mail"/>),
    [SupportFeature.contactPhone]:(<FormattedMessage id="apps.panel.support.feature.contactPhone" defaultMessage = "Phone"/>),
    [SupportFeature.contactPhoneLimit]:(<FormattedMessage id="apps.panel.support.feature.contactPhoneLimit" defaultMessage = "hours"/>),
    [SupportFeature.fullTime]:(<FormattedMessage id="apps.panel.support.feature.fullTime" defaultMessage = "24/7 concierge"/>),
}


export const supportTierNames: {
  [key in SupportTier]: ReactNode
} = {
  [SupportTier.basic]:(<FormattedMessage id="apps.panel.support.tier.basic" defaultMessage = "Basic Support"/>),
  [SupportTier.advanced]:(<FormattedMessage id="apps.panel.support.tier.advanced" defaultMessage = "Advanced Support"/>),
  [SupportTier.concierge]:(<FormattedMessage id="apps.panel.support.tier.concierge" defaultMessage = "Concierge Support"/>),
}

export const supportConfig: {
  [key in SupportTier]: SupportTierOffer
} = {
  [SupportTier.basic]: {
    price: 0,
    technicalSupport: true,
    accountAndPayments: true,
    contentAndCommunication: false,
    budgetOptimization: false,
    contactMessenger: true,
    contactEmail: true,
    contactPhone: false,
    contactPhoneLimit: 0,
    fullTime: false,
  },
  [SupportTier.advanced]: {
    price: 199,
    technicalSupport: true,
    accountAndPayments: true,
    contentAndCommunication: true,
    budgetOptimization: false,
    contactMessenger: true,
    contactEmail: true,
    contactPhone: true,
    contactPhoneLimit: 1,
    fullTime: false,
  },
  [SupportTier.concierge]: {
    price: 999,
    technicalSupport: true,
    accountAndPayments: true,
    contentAndCommunication: true,
    budgetOptimization: true,
    contactMessenger: true,
    contactEmail: true,
    contactPhone: true,
    contactPhoneLimit: 5,
    fullTime: true,
  }
}