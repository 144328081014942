import BudgetInputFacebook from "components/inputs/BudgetInputFacebook";
import { useEffect, useState } from "react";
import FormattedMessage from "components/common/FormattedMessage";
import useAPI from "services/hooks/useAPI";
import useDataPrefill from "services/hooks/useDataPrefill";
import { ruleMax } from "tools/relaxForm/relaxPresets";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import PeriodInput from "_components/forms/period-input";
import { useCreationEditor } from "../CreationEditor";
import { creationContentSelector, patchCreation, patchCreationWithSmartAction } from "../creationsSlice";

export default function CreationBudgetInput(props: any) {

    const { creation, type, subtype, creationId, content } = useCreationEditor();
    const [minBudget, setMinBudget] = useState<number>(0);
    const { call, data, loading } = useAPI(patchCreationWithSmartAction(creationId, "ce_budget"))

    useEffect(() => {
        if (content && minBudget) {

            let md: any = {};

            // if (!content.days) {
            //     md.days = 5;
            // }

            if (!content.budget) {
                md.budget = minBudget;
            }

            if (Object.keys(md).length > 0 && !loading) {
                call({ body: { content: md } });
            }

        }
    }, [content, minBudget]);

    const getCost = () => {
        const d = creation?.content?.days;
        const b = creation?.content?.budget;
        const c = d * b;
        if (!isNaN(c)) return c;
        return ""
    }


    return (
        <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <RelaxSingleField
                name={"budget"}
                callAPI={patchCreationWithSmartAction(creationId, "ce_budget")}
                onErrors={(e) => props.onErrors && props.onErrors("budget", e)}
                label={<FormattedMessage
                    id="apps.smart.containers.createPostAndAd.form.budget.label"
                    defaultMessage="Budget"
                />}
                tooltip={
                    <FormattedMessage
                    id="apps.smart.containers.createPostAndAd.form.budget.tooltip"
                    defaultMessage="Budget"
                />
                }
                style={{
                    width: "40%"
                }}
            >
                <BudgetInputFacebook
                    defaultBudget={(minBudget: number) => {
                        if (subtype === "leads_ad") return Math.floor(minBudget * 5);
                        return minBudget * 5;
                    }}
                    minimumBudget={(minBudget: number) => {
                        if (subtype === "leads_ad") return minBudget * 3;
                        return minBudget;
                    }}
                    onDefaultBudgetLoaded={(minBudget: number) => {
                        setMinBudget(minBudget);
                    }}
                    required
                />
            </RelaxSingleField>
            <RelaxSingleField
                name={"days"}
                onErrors={(e) => props.onErrors && props.onErrors("days", e)}
                callAPI={patchCreationWithSmartAction(creationId, "ce_budget")}
                label={<FormattedMessage
                    id="apps.smart.containers.createPostAndAd.form.days.label"
                    defaultMessage="Days"
                />}
                tooltip={
                    <FormattedMessage
                    id="apps.smart.containers.createPostAndAd.form.days.tooltip"
                    defaultMessage="Days"
                />
                }
                rules={[
                    ruleMax(180)
                ]}
                style={{
                    width: "40%"
                }}
                required
            >
                <PeriodInput />
            </RelaxSingleField>
            <RelaxSingleField
                name={"cost"}
                callAPI={patchCreationWithSmartAction(creationId, "ce_budget")}
                label={<FormattedMessage
                    id="apps.smart.containers.createPostAndAd.form.cost.label"
                    defaultMessage="Cost"
                />}
                tooltip={
                    <FormattedMessage
                    id="apps.smart.containers.createPostAndAd.form.cost.tooltip"
                    defaultMessage="Cost"
                />
                }
                style={{
                    width: "20%"
                }}
            >
                <div className="postCostDisplay" style={{
                    display: "grid",
                    placeItems: "center",
                    height: "100%",
                    width: "100%",
                }}>
                    {getCost()}
                </div>
            </RelaxSingleField>
        </div>
    )

}