export const normalize = (value: any) => {
    if (!value) return null;
    const included:any[] = norm(value.included || [], [], false);
    return norm(value, included);
}

export const normalizeObject = (o: any) => {
    const n:any = {};
    if (o.id) n.id = o.id;
    if (o.type) n["_type"] = o.type;
    if (o.attributes) {
        Object.keys(o.attributes).forEach((key) => {
            n[key] = o.attributes[key];
        });
    }
    return n;
}
  
const norm = (value:any, included:any[], solveRelationships=true):any => {
//console.log("norming value", value);
if (!value) return null;
if (typeof value === "string" || typeof value === "number") return value;
if (Array.isArray(value)) return value.map((v:any) => norm(v, included, solveRelationships));
if (value.data) return norm(value.data, included);

const n:any = {};

    if (value.id) {
    n.id = value.id;
    n["_id"] = value.id;
    }
    if (value.type) n["_type"] = value.type;
    if (value.attributes) {
        Object.keys(value.attributes).forEach((key) => {
            n[key] = value.attributes[key];
        });
    } else {
        if (included && included.length) {
            const found = included.find((i:any) => i.id === value.id && i["_type"] === value.type);
            if (found) {
            Object.keys(found).forEach((key) => {
                if (key !== "id" && key !== "_id" && key !== "type" && key !== "_type") n[key] = found[key];
            });
            }
        }
    }
    if (solveRelationships && value.relationships) {
    Object.keys(value.relationships).forEach((key) => {
        n[key] = norm(value.relationships[key], included);
    });
    }
return n;
}
