import OnboardingControls from "providers/onboarding/controls";
import { UserEmailsOfType } from "./emails";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { userHasAtLeastOneCriticalEmailSelector } from "modules/panel/config/selectors/user";

const OnboardingContactEmail = () => {

    const fulfilled = useSelectorWithParams(userHasAtLeastOneCriticalEmailSelector)

    return (
        <div>
            <UserEmailsOfType type="critical" />
            <OnboardingControls nextDisabled={!fulfilled} />
        </div>

    )

}

export default OnboardingContactEmail;