import React, { PureComponent } from "react";
import {
  isStorageTokenValid,
  LOGIN,
  RELOGIN_USER,
  reloginUserFromStorage,
} from "modules/panel/actions/user/login";
import ConnectActionsToStore from "services/hoc/watch-actions";
import FullPageLoader from "_components/fullpage-loader";
import { clearActionsStatuses } from "modules/panel/actions/request-status";
import { userIsLoggedSelector } from "modules/panel/config/selectors/user";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";

class ReloginUser extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tried: false,
    };
  }

  componentDidMount() {
    if (isStorageTokenValid()) {
      this.props.relogin.exec();
    } else {
      this.showChildren();
    }
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.isLogged !== this.props.isLogged ||
      (!prevProps.relogin.status.error && !!this.props.relogin.status.error)
    ) {
      this.showChildren();
    }
  }

  showChildren = () => {
    this.props.clearLoginStatus();
    this.setState({
      tried: true,
    });
  };


  render() {

    return (
      <>
        {this.state.tried || this.props.isLogged ? (
          this.props.children
        ) : (
          <FullPageLoader />
        )}
      </>
    );
  }
}

export default ConnectActionsToStore({
  watch: {
    relogin: {
      dispatch: (dispatch) => {
        return () => dispatch(reloginUserFromStorage());
      },
      action: RELOGIN_USER,
      success: true,
    },
  },
  redux: {
    mapStateToProps: (state) => {
      return {
        isLogged: userIsLoggedSelector(state),
      };
    },
    mapDispatchToProps: (dispatch) => {
      return {
        clearLoginStatus: () => dispatch(clearActionsStatuses([LOGIN])),
      };
    },
  },
})(ReloginUser);
