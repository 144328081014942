import FormattedMessage from "components/common/FormattedMessage"
import StandardButton from "components/common/StandardButton"
import { useCreationGlobal } from "../CreationGlobalContext"
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { creationsAsArraySelector } from "../creationsSlice";

const CreationOpenQueue = () => {

    const {openCreation} = useCreationGlobal();
    const creations = useSelectorWithParams(creationsAsArraySelector);
    const filtered = creations?.filter((c:any) => c.status === "draft");


    return (
        <StandardButton
        onClick={() => {openCreation(filtered[0].id, filtered)}}
        type="primary"
    >
            <FormattedMessage
                id="tools.creationsList.confirmationQueue.openButton"
                defaultMessage="Confirm planned creations"
            />
    </StandardButton>
    )

}

export default CreationOpenQueue;