import { Language, SUPPORTED_LANGUAGES } from "providers/languages";
import { LanguageLocalStorage } from "services/helpers/local-storage";

export class LanguageHelper {
  static getInitialLanguage = () => {
    const browserLanguage = LanguageHelper.getBrowserLanguage();
    const storageLanguage = LanguageLocalStorage.get();

    if (!storageLanguage) LanguageLocalStorage.set(browserLanguage);

    return storageLanguage || browserLanguage;
  };

  static getBrowserLanguage: { (): Language } = () =>
    window.navigator.language.split("-")[0] as Language;

  static isValidLanguage = (lang: string): boolean =>
    SUPPORTED_LANGUAGES.includes(lang as any);
}

export default LanguageHelper;
