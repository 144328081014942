import { ReactNode } from "react"

type Props =  {
    value?: ReactNode;
    subscript?: ReactNode;
    onClick?: () => void;
    subscriptStyle?: React.CSSProperties;
    subscriptClassName?: string;
    valueStyle?: React.CSSProperties;
    valueClassName?: string;
    wrapperStyle?: React.CSSProperties;
    wrapperClassName?: string;
}

export default function CellValueWithSubscript (props:Props) {

    return (
        <div
            className={`result-cell ${props.wrapperClassName ? props.wrapperClassName : ""}`}
            style={props.wrapperStyle}
            onClick={() => props.onClick && props.onClick()}
        >
            <div className={`result-value ${props.valueClassName ? props.valueClassName : ""}`} style={props.valueStyle}>{props.value}</div>
            {props.subscript && <div className={`result-subscript ${props.subscriptClassName ? props.subscriptClassName : ""}`} style={props.subscriptStyle}>{props.subscript}</div>}
        </div>
    )
}