import { Col, Input, Row } from "antd";
import { useAssetDrawer } from "providers/assetDrawer/StandardAssetDrawer";
import React from "react";
import { PatchGroup } from "../data/organizationSlice";
import useOgranizationGroupHelper from "../tools/useOrganizationGroupHelper";
import RelaxFormWrapper from "tools/relaxForm/RelaxForm";
import RelaxField from "tools/relaxForm/RelaxField";
import FormattedMessage from "components/common/FormattedMessage";


const GroupPage = React.forwardRef((props: any, ref: any) => {

    const { asset } = useAssetDrawer();
    const { contextHolder } = useOgranizationGroupHelper();

    return (
        <div style={{ paddingTop: "20px" }}>
            <Row style={{ width: "100%" }}>

                <RelaxFormWrapper
                    label={
                        <FormattedMessage
                            id="organization.gropu.name.label"
                            defaultMessage="Group Name" />
                    }
                    initialValues={asset}
                    callAPI={PatchGroup(asset.id)}>
                    <Col span={24}>
                        <RelaxField
                            name="name">
                            <Input />
                        </RelaxField>
                    </Col>

                </RelaxFormWrapper>
            </Row>
            {contextHolder}
        </div>
    )

})

export default GroupPage;