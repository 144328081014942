import { faBallotCheck, faEraser, faPlusCircle, faTrashCan, faTrashCircle, faX } from "@fortawesome/pro-light-svg-icons";
import { Radio, Tooltip } from "antd";
import { HorizontalCenter } from "components/common/Center";
import StandardButton from "components/common/StandardButton";
import DateTimeInput from "components/inputs/DateTimeInput";
import { useEffect, useMemo, useState } from "react";
import FormattedMessage from "components/common/FormattedMessage";
import { useDispatch } from "react-redux";
import useAPI from "services/hooks/useAPI";
import useAPISmartActions from "services/hooks/useAPISmartActions";
import useDataPrefill from "services/hooks/useDataPrefill";
import { StandardModal, useSimpleModal, useStandardModal } from "services/hooks/useModal";
import { useCreationEditor } from "../CreationEditor";
import { Creation, creationContentSelector, creationsSlice, deleteCreation, duplicateCreation, newCreation, patchCreation, publishCreation, requestPublication, validateCreation } from "../creationsSlice";
import { AskToCreateCreation, AskToDeleteCreation, AskToUnpublishCreation } from "../CreationActionModals";
import useCreationActions from "./useCreationActions";
import { GenericPublicationErrorMessage, GenericPublicationSuccessMessage } from "../CreationConfig";
import dayjs from "dayjs";
import { getValueFromPossibleFunction } from "services/helpers/js-helpers";
import { CanCreationByUnpublished } from "../creationHelpers";
import ProgressDisplay from "components/misc/ProgressDisplay";
import { RootState } from "services/store/redux-store";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import { AllAdsAndRemarkettingKeywords } from "modules/smart/smartSlice";
import { doesTextContainKeywords } from "tools/textEditor/PostTextEditorNew";

export const CreationActionReset = () => {

    const { creation } = useCreationEditor();
    const clearAPI = useAPI(patchCreation(creation?.id))

    return (
        <Tooltip title={
            <FormattedMessage
                id="tools.creationEditor.actions.reset.tooltip"
                defaultMessage="Reset Fields"
            />
        }>
            <StandardButton icon={faEraser} onClick={() => clearAPI.call({ ...patchCreation(creation?.id), body: { content: [] } })} />
        </Tooltip>
    )

}

export const CreationActionUnpublish = (props: {creation: Creation}) => {

    const { creation } = useCreationEditor();
    const { askToUnpublishCreation, contextHolder } = AskToUnpublishCreation();

    const c = props.creation || creation;
    const canBeUnpublished = CanCreationByUnpublished(c);

    return (
        <>
            <Tooltip title={
                <FormattedMessage
                    id="tools.creationEditor.actions.unpublish.tooltip"
                    defaultMessage="Cancel Publication"
                />
            }>
                <StandardButton
                    icon={faX}
                    onClick={() => askToUnpublishCreation(c?.id)}
                    disabled={!canBeUnpublished}
                    type="primary"
                >
                    <FormattedMessage
                        id="tools.creationEditor.actions.unpublish.button"
                        defaultMessage="Cancel Publication"
                    />
                </StandardButton>
            </Tooltip>
            {contextHolder}
        </>
    )

}

export const CreationActionDelete = (props: { creation?: any }) => {

    const { creation } = useCreationEditor();
    const { askToDeleteCreation, contextHolder } = AskToDeleteCreation();

    const c = props.creation || creation;
    const canBeDeleted = c ? c.status === "draft" : false;

    return (
        <>
            <Tooltip title={
                <FormattedMessage
                    id="tools.creationEditor.actions.delete.tooltip"
                    defaultMessage="Delete Creation"
                />
            }>
                <StandardButton
                    icon={faTrashCan}
                    onClick={() => askToDeleteCreation(c?.id)}
                    disabled={!canBeDeleted}
                />
            </Tooltip>
            {contextHolder}
        </>
    )

}

export const CreationActionNew = (props: { inDrawer?: boolean }) => {

    const { ask, contextHolder } = AskToCreateCreation();


    return (
        <>
            <Tooltip title={
                <FormattedMessage
                    id="tools.creationEditor.actions.new.tooltip"
                    defaultMessage="New Creation"
                />
            }>
                <StandardButton
                    icon={faPlusCircle}
                    onClick={() => ask(dayjs())}
                    style={props.inDrawer ? { width: '280px' } : { width: 'auto' }}
                >
                    {props.inDrawer && (
                        <FormattedMessage
                            id="tools.creationEditor.actions.new.button.label"
                            defaultMessage="New Creation"
                        />
                    )}
                </StandardButton>
            </Tooltip>
            {contextHolder}
        </>
    )

}

export const CreationActionValidate = () => {

    const { validate } = useCreationActions();

    return (
        <Tooltip title={
            <FormattedMessage
                id="tools.creationEditor.actions.validate.tooltip"
                defaultMessage="Validate Creation"
            />
        }>
            <StandardButton icon={faBallotCheck} onClick={validate} />
        </Tooltip>
    )

}

export const TextHasKeywordsSelector = (text:string) => createSelector(
    [AllAdsAndRemarkettingKeywords], 
    (initial_keywords) => {
        const keywords:string[] = Object.values(initial_keywords?.initial_keywords || {})
        return doesTextContainKeywords(text, keywords)
    })


const willBePromotedSelector = (creation: Creation) => (state: RootState) => {

    if (!creation) return false;
    if (creation.type === "aoa") return true;
    if (!creation.content) return false;
    if (!creation.content.message) return false;

    return TextHasKeywordsSelector(creation.content.message)(state);

}

export default function CreationMainActions(props: any) {
    const { creation, creationId, content, permissions, close: closeEditor, isReadyToPublish, typeConfig } = useCreationEditor();
    const dispatch = useDispatch();
    const [openSuccessModal, contextHolder] = useSimpleModal();
    const { smartActions } = useAPISmartActions();
    const create = useAPI(newCreation(creation.type))

    const willBePromoted = useSelectorWithParams(willBePromotedSelector(creation));

    const isWaitingForOtherActions = useMemo(() => {

        if (!smartActions) return false;
        const creationActions = Object.values(smartActions).filter((action: any) => action.name.startsWith("ce_"));
        const pendingActions = creationActions.filter((action: any) => action.state === "pending");

        if (pendingActions.length > 0) return true;
        return false;

    }, [smartActions])

    const validateAPI = useAPI(validateCreation(null))
    const publishAPI = useAPI(publishCreation(null))
    const updateAPI = useAPI(publishCreation(null))

    // const { call } = useDataPrefill({
    //     dataSelector: (state: any) => creationContentSelector(state, creationId),
    //     callAPI: patchCreation(creationId),
    //     checkFunction: (data: any) => {
    //         if (!data.publication_type) return { content: { publication_type: "instant" } };
    //         return false;
    //     }
    // })

    const isWaiting = useMemo(() => {

        const validateLoading = validateAPI.loading;
        const publishLoading = publishAPI.loading;
        const updateLoading = updateAPI.loading;


        if (validateLoading) return true;
        if (publishLoading) return true;
        if (updateLoading) return true;
        //if (isWaitingForOtherActions) return true;
        return false;

        if (validateAPI.loading) return true;
        if (publishAPI.data || updateAPI.data) return false;
        if (publishAPI.loading || updateAPI.loading) return true;
        return validateAPI.loading || updateAPI.loading;
    }, [validateAPI, publishAPI, updateAPI, isWaitingForOtherActions])

    const handlePublish = (action?: string) => {
        if (!creation) return;


        validateAPI.call(validateCreation(creation?.id)).then((res) => {

            if (res?.status === 200) {
                if (action === "request") {

                    publishAPI.call({
                        ...requestPublication(creation?.id), body: {
                            payload: {
                                creation_id: creation?.id,
                                publication_target: [creation?.content?.publication_target],
                            },
                        }
                    }).then((res) => {

                        if (res?.status === 200) {

                            openSuccessModal({
                                title: "Success",
                                content: <FormattedMessage
                                    id="tools.creationEditor.actions.requestPublication.result.success"
                                    defaultMessage="Request sent successfully!"
                                />,
                                onOk: closeEditor,
                                onCancel: closeEditor,
                            })
                        } else {
                            openSuccessModal({
                                title: "Error",
                                content: <FormattedMessage
                                    id="tools.creationEditor.actions.requestPublication.result.error"
                                    defaultMessage="There was an error sending your request. Please try again later."
                                />,
                                onOk: closeEditor,
                                onCancel: closeEditor,
                            })
                        }

                    })

                    return;

                }

                publishAPI.call(publishCreation(creation?.id)).then((res) => {

                
                    if (res?.status === 200) {

                        if (creation.content.save_draft == true) {
                            create.call(duplicateCreation(creation))
                        }

                        if (willBePromoted) {
                            openSuccessModal({
                                title: "Success",
                                content: <ProgressDisplay />,
                                icon: null,
                                okText: "Skip",
                                width: 1200,
                                onOk: closeEditor,
                                onCancel: closeEditor,
                            })
                        }  else {
                            let msg = typeConfig?.publicationSuccessMessage && getValueFromPossibleFunction(typeConfig.publicationSuccessMessage, creation)
                            if (!msg) msg = GenericPublicationSuccessMessage(typeConfig);
    
                            openSuccessModal({
                                title: "Success",
                                content: msg,
                                onOk: closeEditor,
                                onCancel: closeEditor,
                            })
                        }
 

                   
                    } else {

                        let msg = typeConfig?.publicationErrorMessage && getValueFromPossibleFunction(typeConfig.publicationErrorMessage, creation)
                        if (!msg) msg = GenericPublicationErrorMessage(typeConfig);

                        openSuccessModal({
                            title: "Error",
                            content: msg,
                            onOk: closeEditor,
                            onCancel: closeEditor,
                        })
                    }

                })

            } else if (res?.error) {
                let errors = res?.error?.errors;
                if (errors) {
                    console.log("errors", errors);
                }
            }
        });
    }

    const updateBackToDraft = () => {
        updateAPI.call({ body: { status: "draft" } })
    }


    const mainAction = useMemo(() => {
        if (!creation) return null;

        if (creation.status === "planned") return <StandardButton loading={isWaiting} type="primary" onClick={updateBackToDraft}>
            <FormattedMessage
                id="tools.creationEditor.actions.cancelPlanned.button"
                defaultMessage="Cancel Publication"
            />
        </StandardButton>

        const actions: any[] = [];

        const canBeUnpublished = CanCreationByUnpublished(creation);

        if (canBeUnpublished) {
            actions.push(<CreationActionUnpublish creation={creation} />)
            return actions;
        }

        if (permissions.includes("use creations request")) {
            if (creation.content["publication-type"] === "planned") {
                actions.push(<StandardButton
                    loading={isWaiting}
                    type="primary"
                    disabled={!isReadyToPublish}
                    onClick={() => handlePublish("request")}>
                    <FormattedMessage
                        id="tools.creationEditor.actions.requestPublication.button"
                        defaultMessage="Request Publication"
                    />
                </StandardButton>)
            } else {
                actions.push(<StandardButton
                    loading={isWaiting}
                    type="primary"
                    onClick={() => handlePublish("request")}
                    disabled={!isReadyToPublish}
                >
                    <FormattedMessage
                        id="tools.creationEditor.actions.requestPublication.button"
                        defaultMessage="Request Publication"
                    />
                </StandardButton>)
            }
        }

        if (permissions.includes("use creations publish")) {
            if (creation.content["publication-type"] === "planned") {
                actions.push(<StandardButton
                    loading={isWaiting}
                    type="primary"
                    disabled={!isReadyToPublish}
                    onClick={() => handlePublish("publish")}>
                    <FormattedMessage
                        id="tools.creationEditor.actions.schedule.button"
                        defaultMessage="Schedule"
                    />
                </StandardButton>)
            } else {

                if (willBePromoted) {

                    actions.push(
                        <StandardButton
                            loading={isWaiting}
                            type="primary"
                            disabled={!isReadyToPublish}
                            onClick={() => handlePublish("publish")}>
                            <FormattedMessage
                                id="tools.creationEditor.actions.publishAndPromote.button"
                                defaultMessage="Publish and Promote"
                            />
                        </StandardButton>)

                } else {

                    actions.push(
                        <StandardButton
                            loading={isWaiting}
                            type="primary"
                            disabled={!isReadyToPublish}
                            onClick={() => handlePublish("publish")}>
                            <FormattedMessage
                                id="tools.creationEditor.actions.publish.button"
                                defaultMessage="Publish"
                            />
                        </StandardButton>)

                }


            }

        }

        return actions;

    }, [creation, isWaiting, isReadyToPublish])

    return (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            {contextHolder}
            <div style={{ display: "flex" }}>
                <CreationActionDelete />
                {/* <CreationActionReset /> */}
                <CreationActionValidate />
                {props.children}
            </div>
            <Tooltip
                title={isReadyToPublish ? null : <FormattedMessage
                    id="tools.creationEditor.actions.notReadyToPublish.tooltip"
                    defaultMessage="There are errors in your post. Please fix them before publishing."
                />}
            >
                <div style={{ display: "flex" }}>
                    {mainAction}
                </div>
            </Tooltip>
        </div>

    )

}
