import SuccessPage from "_components/OnboardingSuccessPage";
import FormattedMessage from "components/common/FormattedMessage";
import ConnectedOnboardingControls from "providers/onboarding/controls";

export const OnboardingSuccessGoogle = () =>{
    return (
        <>
            <SuccessPage>
                <p>
                    <FormattedMessage
                        id="apps.smart.containers.onboarding.google.success"
                        defaultMessage="You have successfully configured Google Ads settings."
                    />
                </p>
            </SuccessPage>
            <ConnectedOnboardingControls />
        </>

    );
}