import { EditFilled } from "@ant-design/icons";
import { Button, Col, Collapse, Grid } from "antd";
import { StandardDrawer, useStandardDrawer } from "services/hooks/useDrawer";
import Post from "models/post";
import React, { PureComponent, useCallback, useEffect, useState, useMemo, useRef, ReactNode } from "react";
import { AssetColumns, CalculateGridStyle, InfinityColumnProps } from "tools/infinityList/infinityTemplates";
import TemplateRow from "tools/infinityList/TemplateRow";
import PostContextProvider, { usePost } from "./postContext";
import PostManagementDetails from "./postManagementDetails";
import PostPlatformDetails from "./postPlatformDetails";
import useSimpleBreakpoint from "services/hooks/useSimpleBreakpoint";
import { isFirefox } from 'react-device-detect';
import { faChevronsDown } from "@fortawesome/pro-light-svg-icons";
import AwesomeIcon from "components/common/AwesomeIcon";
import { SmoothTabBorder } from "_components/tabs/SmoothTabs";
import FormattedMessage from "components/common/FormattedMessage";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { currentFlatSubscriptionPermissionsSelector } from "state/subscriptions/subscriptionsSelectors";

export type PostListEntryProps = {
    post: any;
    content?: ReactNode;
    options: any;
}



export default function PostListEntry(props: PostListEntryProps): React.ReactElement {


    const { post, openDetails, setIsExpanded, isExpanded, config, options } = usePost();
    const size = useSimpleBreakpoint();
    const [s, sizeIndex] = size;
    const permissions = useSelectorWithParams(currentFlatSubscriptionPermissionsSelector);

    const columns: InfinityColumnProps[] = useMemo(() => config?.columns?.main || AssetColumns, [post])

    const calculateGridStyle = (col: any) => {

        const style = CalculateGridStyle(col, sizeIndex);

        return {
            ...style,
            paddingRight: isFirefox ? "5px" : "0px"
        }
    }

    const handleOpen = (e: any, details: any) => {
        e && e.stopPropagation();
        openDetails(details);
    }

    const mapToGridCell = (c: any, q: any) => {
        const style: any = { ...c.style };
        if (c.render) {
            if (!c.size || !c.size[sizeIndex] || c.size[sizeIndex] === false || c.size[sizeIndex] === "0") return;
            return (
                <div
                    key={c.key}
                    className={`infinity-element-wrapper ${c.key}`}
                    style={{
                        ...c.wrapperStyle,
                        gridArea: c.key
                    }}>
                    <div className={`infinity-element ${c.center ? "center" : ""}`} style={style}>
                        {c.render(post, { ...props.options, platform: options.platform, provider: options.platform, size: c.size, sizeIndex: sizeIndex, handleOpen, ...q })}
                    </div>
                </div>
            )
        }
        return null;
    }

    const mapToGridRow = (columns: any, query: any) => {
        return columns.map((c: any) => mapToGridCell(c, query));
    }


    const elements = useMemo(() => {
        if (!columns) return null;
        if (!post) return null;
        return mapToGridRow(columns, {})
    }, [post, columns, sizeIndex])


    const expandedContent = useMemo(() => {

        if (!post) return null;
        if (!post.getProviderPosts) return null;
        if (post.getProviderPosts().length === 0) return null;

        const allowedProviders:string[] = [];

        if (permissions.includes("use postlist facebook")) allowedProviders.push("facebook");
        if (permissions.includes("use postlist instagram")) allowedProviders.push("instagram");
        if (permissions.includes("use postlist google")) allowedProviders.push("google");

        return (
            <div
                className="expanded-item"
                onClick={() => setIsExpanded(false)}
            >
                {post.getProviderPosts && post.getProviderPosts().map((p: string) => {

                    if (!allowedProviders.includes(p)) return null;

                    return (
                        <PostPlatformDetails key={p} provider={p} details={{ handleOpen }} />
                    )
                })
                }

                {props.content}
            </div>
        )
    }, [props.content, post]);


    if (!post) return <div>Loading...</div>;

    return (
        <div
            key={post.getId()}
            className={`infinity-item-wrapper`}
            onClick={(e) => setIsExpanded(!isExpanded)}
        >
            <Collapse
                expandIcon={() => null}
                onChange={(e) => setIsExpanded(e.length > 0)}
            >
                <Collapse.Panel
                    className="post-entry-content"
                    header={(
                        <>
                            <div
                                className={"column-grid"}
                                style={{ ...calculateGridStyle(columns) }}>
                                {elements}
                            </div>

                            <div className="click-to-expand">


                                {/* <div style={{
                                    width: "20px",
                                    height: "20px",
                                    position: "relative",
                                    display: "grid",
                                    placeItems: "center",
                                    bottom: "-42px",
                                    backgroundColor: "white",
                                    border: "2px solid #A4A4A4",
                                    borderRadius: "30px",
                                }}>
                                    <AwesomeIcon icon={faChevronsDown} style={{
                                        position: "relative",
                                        left: "-1px"
                                    }}/>
                                </div> */}

                                        <div className={`expand-instructions ${isExpanded ? "expanded" : ""}`}>
                                        <SmoothTabBorder
                                            size={20}
                                            first={false}
                                            offset={[-10, -10, -2]}
                                            zIndex={2}
                                        />
                                        <div className="instructions">
                                            <FormattedMessage
                                                id="common.clickToExpand"
                                                defaultMessage="Click to expand"
                                            />
                                        </div>
    
                                    </div>
                                    
                               
                            </div>
                        </>
                    )} key="base">
                    {!props.options.bots && (
                        expandedContent
                    )}
                </Collapse.Panel>
            </Collapse>



        </div>
    )

}
