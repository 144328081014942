import { createSelector, createSlice } from "@reduxjs/toolkit"
import { AIDemographicsCategoriesType } from "modules/smart/components/AIGeneration/AIDemographicsConfig"
import { callAPIProps } from "services/hooks/useAPI"
import _ from "lodash"
import { FacebookAdsSettingsSelector } from "modules/smart/smartSlice"
import { currentProjectIdSelector } from "services/store/scopeSlice"
import { creationsSlice } from "tools/creationEditor/creationsSlice"

type GenerationStatus = "none" | "ready" | "in_queue" | "in_process" | "failed";

export type AIStrategyIntensity = {
    id: number, 
    name: string,
}

export type AIStrategy = {
    id: number,
    name: string,
    aiIntensities: AIStrategyIntensity[]
}

export type AIStyle = {
    id: number,
    name: string,
}

export type AIProjectInfo = {
    company_description: string,
    client_description: string,
    client_company_status: GenerationStatus,
    info: {
        name: string,
        location: string,
        industry: string,
        language: string,
    },
    demographics: any | null,
    demographics_status: GenerationStatus,
    example_client: {
        male? : any, 
        famale? : any,
    } | null,
    example_client_status: GenerationStatus,
}

type AISliceType = {
    strategies: AIStrategy[],
    styles: AIStyle[],
    projectInfo: Record<string | number, AIProjectInfo>, 
}

const initialProjectInfo = {
    company_description: "",
        client_description: "",
        info: {
            name: "",
            location: "",
            industry: "",
            language: "",
        },
        demographics: null,
}

const initialState: AISliceType = {
    strategies: [],
    styles: [],
    projectInfo: {}
}


const addProjectsToIndex = (state: any, projects: any) => {

    if (!projects) return;

    if (Array.isArray(projects)) {
        projects.forEach((p:any) => {
            if (!state.projectInfo[p.id]) state.projectInfo[p.id] = {};
            state.projectInfo[p.id] = p.project_ai_info
        })
        return;
    }

    if (typeof projects === "object") {
        Object.keys(projects).forEach((k:any) => {
            if (!state.projectInfo[k]) state.projectInfo[k] = {};
           // aiSlice.actions.getProjectInfo({projectId: k, data: projects[k].project_ai_info})
            
            adjustProjectAIInfo(state, k, projects[k].project_ai_info)
            
        })
        return
    }

}

const adjustProjectAIInfo = (state: any, id: string | number, projectAiInfo: any) => {
    if (!id) return;
    if (!projectAiInfo) return;
    const {client_description, company_description, demographics, info, example_client} = projectAiInfo;

    let adjExampleClient = null;
    if (example_client && !Array.isArray(example_client)) {
        const {male, female, male_avatar, female_avatar} = example_client;
        adjExampleClient = {
            male: male ? {
                ...male, 
                avatar: male_avatar
            } : null,
            female: female ? {
                ...female,
                avatar: female_avatar
            } : null
        } 
    }

    state.projectInfo[id] = {
        client_description,
        company_description,
        demographics,
        info,
        example_client: adjExampleClient,
        demographics_status: projectAiInfo.demographics_status,
        client_company_status: projectAiInfo.client_company_status,
        example_client_status: projectAiInfo.example_client_status,
    }
}


const aiSlice = createSlice({
    name: "ai",
    initialState,
    reducers: {
        getStrategies: (state, action) => {
            state.strategies = action.payload.data;
        },
        getStyles: (state, action) => {
            state.styles = action.payload.data;
        },
        getProjectInfo: (state, action) => {

            if (!action.payload.projectId) return;

            adjustProjectAIInfo(state, action.payload.projectId, action.payload.data)

        }
    },
    extraReducers: (builder) => {
        builder
        .addCase("LOAD_USER_SUCCESS", (state, action) => {
            if (action?.payload?.projects) {
                addProjectsToIndex(state, action.payload.projects)
            }
        })
    }
})


export const GetAiStrategies: callAPIProps = {
    url: ({ getApiUrl, projectId }: any) => getApiUrl(`ai/strategy?sort=created_at`, 1),
    successDispatch: aiSlice.actions.getStrategies,
}

export const GetAiStyles: callAPIProps = {
    url: ({ getApiUrl, projectId }: any) => getApiUrl(`ai/style?sort=created_at`, 1),
    successDispatch: aiSlice.actions.getStyles,
}

export const GetAiProjectInfo: callAPIProps = {
    url: ({ getApiUrl, projectId }: any) => getApiUrl(`projects/${projectId}/ai/project-ai-info`),
    successDispatch: aiSlice.actions.getProjectInfo,
}

export const UpdateAIProjectInfo: callAPIProps = {
    url: ({ getApiUrl, projectId }: any) => getApiUrl(`projects/${projectId}/ai/project-ai-info`),
    method: "PATCH",
    successDispatch: aiSlice.actions.getProjectInfo,
}

export const GenerateAIDemographics: callAPIProps = {
    url: ({ getApiUrl, projectId }: any) => getApiUrl(`projects/${projectId}/ai/project-ai-info/demographics`),
    method: "POST",
}

export const GenerateAIExampleClient: callAPIProps = {
    url: ({ getApiUrl, projectId }: any) => getApiUrl(`projects/${projectId}/ai/project-ai-info/example-client`),
    method: "POST",
}

export const RegenerateCreationText = (creation: string): callAPIProps => ({
    url: ({ getApiUrl, projectId }: any) => getApiUrl(`projects/${projectId}/ai/creation-text/${creation}`, 1),
    method: "PATCH",
})

export const RegenerateCreationImage = (creation: string): callAPIProps => ({
    url: ({ getApiUrl, projectId }: any) => getApiUrl(`projects/${projectId}/ai/creation-image/${creation}`, 1),
    method: "PATCH",
})

export const GenerateTextFromMedia = (creationId: string): callAPIProps => ({
    url: ({ getApiUrl, projectId }: any) => getApiUrl(`projects/${projectId}/ai/${creationId}/describe-photo`, 1),
    method: "POST",
    successDispatch: creationsSlice.actions.get
})

export const AISelector = (state: any) => state.ai;

export const AiStrategySelector = createSelector([
    AISelector
], (ai) => ai.strategies);

export const AiStyleSelector = createSelector([
    AISelector
], (ai) => ai.styles);

export const AiProjectInfoSelector = createSelector([
    currentProjectIdSelector,
    AISelector
], (id, ai) => ai.projectInfo && ai.projectInfo[id] || null);

export const ProjectAIInfoDemographicsSelector = createSelector([
    AiProjectInfoSelector
], (projectInfo) => projectInfo?.demographics);

export const ProjectHasAIProjectInfoSelector = createSelector([
    AiProjectInfoSelector
    ], (projectInfo) => {
        if (!projectInfo) return false;
        if (!projectInfo.info) return false;
        if (!projectInfo.info.name || projectInfo.info.name === "") return false;
        if (!projectInfo.info.location || projectInfo.info.location === "") return false;
        if (!projectInfo.info.industry || projectInfo.info.industry === "") return false;
        if (!projectInfo.info.language || projectInfo.info.language === "") return false;
        if (!projectInfo.company_description || projectInfo.company_description === "") return false;
        if (!projectInfo.client_description || projectInfo.client_description === "") return false;
        return true;
    }
)

export const ProjectHasAICompanyDescriptionSelector = createSelector([
    AiProjectInfoSelector
    ], (projectInfo) => {
        if (!projectInfo) return false;
        if (!projectInfo.company_description || projectInfo.company_description === "") return false;
        return true;
    }
)

export const ProjectHasAIClientDescriptionSelector = createSelector([
    AiProjectInfoSelector
    ], (projectInfo) => {
        if (!projectInfo) return false;
        if (!projectInfo.client_description || projectInfo.client_description === "") return false;
        return true;
    }
)

export const ProjectHasAILanguageSelector = createSelector([
    AiProjectInfoSelector
    ], (projectInfo) => {
        if (!projectInfo) return false;
        if (!projectInfo.info) return false;
        if (!projectInfo.info.language) return false;
        if (projectInfo.info.language === "") return false;
        return true;
    }
)

export const ProjectHasDemographicSelector = createSelector([
    AiProjectInfoSelector
    ], (projectInfo) => {

        if (!projectInfo) return false;
        if (!projectInfo.info) return false;
        if (!projectInfo.demographics) return false;
        if (projectInfo.demographics === "") return false;
        if (Array.isArray(projectInfo.demographics)) return false;
        if (Object.keys(projectInfo.demographics).length === 0 && projectInfo.demographics.constructor === Object) return false;

        return true;
    }
)

export const ProjectHasDemographicsInCategorySelector = createSelector([
    ProjectAIInfoDemographicsSelector,
    (state:any, category: AIDemographicsCategoriesType) => category,
], (demographics, category) => {
    

    if (!demographics) return false;
    if (!demographics[category]) return false;
    if (demographics[category] === "") return false;
    
    if (Object.keys(demographics[category]).length === 0) return false;
    if (Object.values(demographics[category]).find((v:any) => !Array.isArray(v))) return false;
    if (Object.values(demographics[category]).find((v:any) => v.length === 0)) return false;


    return true;
});

export const ProjectHasDemographicsPurchaseObjectionsSelector = createSelector([
    ProjectAIInfoDemographicsSelector
], (demographics) => {
    if (!demographics) return false;
    if (!demographics.purchaseObjections) return false;
    if (demographics.purchaseObjections === "") return false;
    if (demographics.purchaseObjections.length === 0) return false;
    return true;
})

export const ProjectAIExampleClientsSelector = createSelector([
    AiProjectInfoSelector
], (projectInfo) => {
    if (!projectInfo) return null;
    if (!projectInfo.example_client) return null;
    return projectInfo.example_client;
})

export const ProjectHasAIExampleClients = createSelector([
    ProjectAIExampleClientsSelector
], (clients) => {

    if (!clients) return false; 
    if (typeof clients !== "object") return false;
    return Object.keys(clients).length > 0;
}
)

export const ProjectAIExampleClientByGenederSelector = createSelector([
    ProjectAIExampleClientsSelector,
    (state, gender) => gender
], (exampleClients, gender) => {
    if (!exampleClients) return null;
    if (!gender) return null;
    return exampleClients[gender] ? exampleClients[gender] : null;
})

export const ExampleClientGendersFromFacebookAdsSettingsSelector = createSelector([
    FacebookAdsSettingsSelector, 
], (facebookAdsSettings) => {

    if (!facebookAdsSettings) return [];
    const g = facebookAdsSettings.gender

    if (g === "all") return ["male", "female"]
    return [g]

});

export const ProjectHasAIExampleClientByGenderSelector = createSelector([
    ProjectAIExampleClientByGenederSelector
], (exampleClient) => {
    if (!exampleClient) return false;
    return true;
})

export default aiSlice;

