import { ProductConfig } from "products";
import logo from "products/leadMax/FastTonyLeadMax-Logo.png";
import requirements from "modules/smart/config/requirements";
import { Requirement } from "modules";

const config: ProductConfig = {
  name: "LeadMax",
  logo,
  requirements: requirements as Requirement[],
};

export default config;
