import { Data } from "@react-google-maps/api";
import { Card, Radio, Select, Space } from "antd";
import Item from "antd/es/list/Item";
import Transfer from "components/inputs/Transfer";
import { useEffect, useState } from "react";
import FormattedMessage from "components/common/FormattedMessage";
import { getApiUrl } from "services/helpers/api-helpers"
import useAPI from "services/hooks/useAPI"
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { GetIntegrations, ProjectToolIntegrationSelector } from "state/project/toolIntegrationsSlice";
import RelaxSingleField from "tools/relaxForm/RelaxField";

const GetResponseCampaign = () => {

    const { data: allCampaigns, loading: allLoading, error: allError, StatusDisplay: allStatus } = useAPI({
        url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/get-response/campaigns`),
    }, true)

    const { data: selectedCampaign, loading, error, StatusDisplay } = useAPI({
        url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/get-response/selected-campaign`),
    }, true)

    if (loading || error) return StatusDisplay

    if (!allCampaigns) return null;

    return (
        <RelaxSingleField
            name="get_response_campaign_id"
            callAPI={{
                url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/get-response/selected-campaign`),
                method: "POST"
            }}
            label={(<FormattedMessage
                defaultMessage="GetResponse Campaign"
                id="modules.call.getresponse.campaign"
            />)}
        >
            <Radio.Group defaultValue={selectedCampaign?.get_response_campaign_id}>
                {allCampaigns?.map((campaign: any) => (<Radio.Button value={campaign.id}>{campaign?.name}</Radio.Button>))}
            </Radio.Group>
        </RelaxSingleField>
    )
}

const GetResponseForms = (props:{onSelect?: any}) => {

    const { data, loading, error, StatusDisplay } = useAPI({
        url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/leads-manager/forms`),
    }, true)


    if (loading || error || !data) return StatusDisplay

    return (
        <RelaxSingleField
            name="forms"
            label={(<FormattedMessage
                defaultMessage="Forms"
                id="modules.call.getresponse.forms"
            />)}
            onChange={(value:any) => {
                props.onSelect && props.onSelect(value);
            }}
            initialValues={{forms: data?.[0]?.id}}
        >
            <Select>
                {data?.map((form: any) => (
                    <Select.Option value={form.id}>{form?.name}</Select.Option>
                ))}
            </Select>

        </RelaxSingleField>
    )



}



const GetResponseFormFields = (props: {projectIntegrationId?: number, formId?: string}) => {

    const {call, data, loading, error, StatusDisplay } = useAPI({});

    useEffect(() => {

        if (props.projectIntegrationId && props.formId) {
            call({
                url: ({ getApiUrl, projectId, serializeQuery }) => getApiUrl(`projects/${projectId}/leads-manager/form-fields?${serializeQuery({
                    project_integrations_id: props.projectIntegrationId,
                    form_id: props.formId
                })}`)
            })
        }
    }, [props.projectIntegrationId, props.formId]);


    if (loading || error || !data) return StatusDisplay

    return (
        <RelaxSingleField
            name="selected_fields"
            label={(<FormattedMessage
                defaultMessage="Fields"
                id="modules.call.getresponse.fields"
            />)}
            callAPI={{
                url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/leads-manager/form-fields`),
                method: "POST",
                addToBody: {
                    form_id: props.formId,
                    project_integrations_id: props.projectIntegrationId
                },
            }}
            finalizeSubmit={(data:any) => {
                return ({
                    selected_fields: (data?.selected_fields || []).map((item:any) => ({
                        id: item.id, 
                        key: item.key, 
                        type: item.type, 
                        to: item.key, 
                        from: item.key
                    }))
                })
            }}
            initialValues={{selected_fields: data?.[0]?.selected_fields}}
        >
            <Transfer
                dataSource={data[0].available_fields}
            />
        </RelaxSingleField>
    )

}



export default function GetResponseConfig() {

    const [selectedForm, setSelectedForm] = useState<any>();
    const { call: getIntegrations, loading, error, StatusDisplay } = useAPI(GetIntegrations, true);
    const integration = useSelectorWithParams([ProjectToolIntegrationSelector, "get-response"]);

    if (loading || error) return StatusDisplay;

    if (!integration) {
        return (
            <div>
                <FormattedMessage
                    id="config.toolIntegrations.missingIntegration"
                    defaultMessage="You are missing the integration with {integration}."
                    values={{
                        integration: "GetResponse"
                    }}
                />
            </div>
        )
    }

    return (
        <>
            <GetResponseCampaign />
            <GetResponseForms onSelect={setSelectedForm}/>
            <GetResponseFormFields formId={selectedForm} projectIntegrationId={integration.id}/>

        </>
    )
}