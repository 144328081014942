import { useMemo } from "react"

type CenterProps = {
    children: React.ReactNode
    style?: React.CSSProperties
    className?: string,
    vertical?: boolean,
    horizontal?: boolean,
}

const CenterWrapper = ({children, style, vertical, horizontal, ...props}: CenterProps) => {


    const combinedStyle = useMemo(() => {

        let s:any = {display: "grid", placeItems: "center"}
        if (vertical) s = {...s, height: "100%"}
        if (horizontal) s = {...s, width: "100%"}
        return {...s, ...style}
    }, [vertical, horizontal, style])

    return (
        <div style={combinedStyle} {...props}>
            {children}
        </div>
    )
}

export const HorizontalCenter = ({children, ...props}: CenterProps) => {
    return (
        <CenterWrapper horizontal {...props}>
            {children}
        </CenterWrapper>
    )
}

export const VerticalCenter = ({children, ...props}: CenterProps) => {
    return (
        <CenterWrapper vertical {...props}>
            {children}
        </CenterWrapper>
    )
}

const Center = ({children, ...props}: CenterProps) => {
    return (
        <CenterWrapper horizontal vertical {...props}>
            {children}
        </CenterWrapper>
    )
}

export default Center;