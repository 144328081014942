import { Checkbox, Row } from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { Title } from "components/common/StandardText";
import { ReactNode, useEffect, useMemo, useState } from "react";

type CarServiceCategoryInputProps = {
    onChange?: (value: any) => void;
    value?: any;
    onBlur?: () => void;
    subCategories: any[];
    title: ReactNode;
    loading: boolean;
}

export const CarServiceCategoryInput = (props: CarServiceCategoryInputProps) => {

    const carsServiceSubcategories = useMemo(() => {

        return props.subCategories.map((carService: any) => {
            return {
                id: carService.id ? carService.id : carService.sub_content_categories_id,
                label: carService.name,
            };
        });

    }, [props.subCategories]);

    return (
        <>
            <Title level={3}>
            {props.title}
            </Title>
            <Checkbox.Group onChange={props.onChange} value={props.value}>
                <div style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "5px"
                }}>
                {carsServiceSubcategories.map((subcategory: any) => (
                        <Checkbox
                            style={{
                                margin: "0"
                            }}
                            value={subcategory.id}
                            >
                            {subcategory.label}
                        </Checkbox>
                ))}
                </div>
            </Checkbox.Group>
        </>
    );
};