import { ReactNode } from "react"
import AIDemographics from "./AIDemographics"
import FormattedMessage from "components/common/FormattedMessage"

export type AIDemographicsCategoriesType = "demographics" | "shoppingBehaviors" | "purchaseObjections"

export const AIDemographicsCategories: Record<string, ReactNode> = {
    personalCharacteristics:    <FormattedMessage id="aiSettings.demographics.categories.personalCharacteristics"       defaultMessage="Personal Characteristics" />,
    hobbies:                    <FormattedMessage id="aiSettings.demographics.categories.hobbies"                       defaultMessage="Hobbies" />,
    interests:                  <FormattedMessage id="aiSettings.demographics.categories.interests"                     defaultMessage="Interests" />,
    personalAspirations:        <FormattedMessage id="aiSettings.demographics.categories.personalAspirations"           defaultMessage="Personal Aspirations" />,
    professionalGoals:          <FormattedMessage id="aiSettings.demographics.categories.professionalGoals"             defaultMessage="Professional Goals" />,
    pains:                      <FormattedMessage id="aiSettings.demographics.categories.pains"                         defaultMessage="Pains" />,
    mainChallenges:             <FormattedMessage id="aiSettings.demographics.categories.mainChallenges"                defaultMessage="Main Challenges" />,
    needs:                      <FormattedMessage id="aiSettings.demographics.categories.needs"                         defaultMessage="Needs" />,
    dreams:                     <FormattedMessage id="aiSettings.demographics.categories.dreams"                        defaultMessage="Dreams" />,
    budget:                     <FormattedMessage id="aiSettings.demographics.categories.budget"                        defaultMessage="Budget" />,
    shoppingFrequency:          <FormattedMessage id="aiSettings.demographics.categories.shoppingFrequency"             defaultMessage="Shopping Frequency" />,
    preferredChannels:          <FormattedMessage id="aiSettings.demographics.categories.preferredChannels"             defaultMessage="Preferred Channels" />,
    onlineBehavior:             <FormattedMessage id="aiSettings.demographics.categories.onlineBehavior"                defaultMessage="Online Behavior" />,
    searchTerms:                <FormattedMessage id="aiSettings.demographics.categories.searchTerms"                   defaultMessage="Search Terms" />,
    preferredBrands:            <FormattedMessage id="aiSettings.demographics.categories.preferredBrands"               defaultMessage="Preferred Brands" />,
    triggers:                   <FormattedMessage id="aiSettings.demographics.categories.triggers"                      defaultMessage="Triggers" />,
    barriers:                   <FormattedMessage id="aiSettings.demographics.categories.barriers"                      defaultMessage="Barriers" />,
    purchaseObjections:         <FormattedMessage id="aiSettings.demographics.categories.purchaseObjections"            defaultMessage="Purchase Objections" />,
}


export const AIDemographicsTables:Record<AIDemographicsCategoriesType, string[]> = {
    demographics: ["personalCharacteristics", "hobbies", "interests", "personalAspirations", "professionalGoals", "pains", "mainChallenges", "needs", "dreams"],
    shoppingBehaviors: ["budget", "shoppingFrequency", "preferredChannels", "onlineBehavior", "searchTerms", "preferredBrands", "triggers", "barriers"],
    purchaseObjections: ["purchaseObjections"],
}

export const AIDemographicsCategoryNames:Record<AIDemographicsCategoriesType, ReactNode> = {
    demographics: <FormattedMessage id="aiSettings.demographics.categoryNames.demographics" defaultMessage="Demographics" />,
    shoppingBehaviors: <FormattedMessage id="aiSettings.demographics.categoryNames.shoppingBehaviors" defaultMessage="Shopping Behaviors" />,
    purchaseObjections: <FormattedMessage id="aiSettings.demographics.categoryNames.purchaseObjections" defaultMessage="Purchase Objections" />,
}