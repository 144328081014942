import { useMemo, useRef } from "react";
import FormattedMessage from "components/common/FormattedMessage";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { filteredTasksCountSelector, getProjectTasks, TasksArraySelector } from "./data/tasksSlice";
import TaskModal from "./TaskModal";
import Toolbar from "components/toolbar/Toolbar";
import InfinityListQuery from "tools/infinityList/infinityListQuery";
import { InfinityListResetFilters } from "tools/infinityList/infinityListResetFilters";
import InfinityListAdvancedSettings from "tools/infinityList/infinityListAdvancedSettings";
import InfinityListAdvancedSettingsCheckboxs from "tools/infinityList/infinityListAdvancedSettingsCheckboxs";
import useAPI from "services/hooks/useAPI";
import InfinityList from "tools/infinityList/infinityList";
import { TaskColumns, TasksListAdvancedSettings, TasksListHeaders } from "./TaskConfig";
import TemplateRow from "tools/infinityList/TemplateRow";
import '../../themes/base/components/infinity-list.less'


export default function TasksList(props: { mode?: "organization" }) {

    const getTasks = useAPI(getProjectTasks)
    const tasks = useSelectorWithParams(TasksArraySelector);
    const taskModal = useRef<any>();
    const filteredTasksCount = useSelectorWithParams(filteredTasksCountSelector)

    const adjustedTasks = useMemo(() => {
        //sort tasks by "updated_at", newest first
        if (!tasks) return []
        return tasks.sort((a: any, b: any) => {
            if (a.updated_at > b.updated_at) return -1;
            if (a.updated_at < b.updated_at) return 1;
            return 0;
        })
    }, [tasks])

    const reloadList = () => {
        getTasks.call()
    }

    const renderTask = (task: any) => {
        return (
            <div
                className="infinity-item-wrapper standard-border with-standard-border"
            >
                <TemplateRow size="medium" item={task} columns={TaskColumns(props.mode, taskModal)} />
            </div>
        )

    }


    const queryInput = useMemo(() => {
        return (
            <InfinityListQuery
                onChange={() => null}
                onReload={reloadList}
                sort={true}
                date={false}
            >
                <InfinityListResetFilters filteredItems={filteredTasksCount} resetSettings={'tasksList'} />
                <InfinityListAdvancedSettings settings={TasksListAdvancedSettings} />
            </InfinityListQuery>
        )
    }, [filteredTasksCount])


    return (
        <>
        <div className="infinity-wrapper">
            <Toolbar
                width="280px"
                title={(
                    <FormattedMessage
                        id="panel.components.post.list.posts"
                        defaultMessage="Posts"
                    />
                )}
                smallToolbar={(
                    <>
                        <Toolbar.Spacer />
                        <InfinityListQuery
                            onChange={() => null}
                            onReload={reloadList}
                            sort={false}
                            date={false}
                            refresh={true}
                        />
                    </>
                )}
                largeToolbar={(<>
                    <Toolbar.Spacer />
                    {queryInput}
                </>
                )}
                drawerToolbar={(<>
                    <InfinityListQuery
                        onChange={() => null}
                        onReload={reloadList}
                        sort={true}
                        date={true}
                        refresh={false}
                    >
                        <InfinityListAdvancedSettingsCheckboxs settings={TasksListAdvancedSettings} />
                    </InfinityListQuery>
                </>
                )}
            />

            <InfinityList
                headers={TasksListHeaders(props.mode)}
                items={adjustedTasks}
                loading={getTasks.loading}
                itemSkeleton={() => "skeleton"}
                renderItem={renderTask}
            />
            </div>
            <TaskModal
                ref={taskModal}
            />
        </>
    )


}