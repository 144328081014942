import {
  authWithFacebook,
  FACEBOOK_AUTH,
  loadUser,
  LOAD_USER,
} from "modules/panel/actions/user/login";
import React from "react";
import { withRouter } from "react-router-dom";
import WatchActions from "services/hoc/watch-actions";
import StandardButton from "components/common/StandardButton";
import FormattedMessage from "components/common/FormattedMessage";
import AwesomeIcon from "components/common/AwesomeIcon";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { Spin } from "antd";

class FacebookAuthButton extends React.Component {
  login = () =>
    window.FB.login(
      ({ authResponse: fbAuthObject, status }) => {
        if (status === "connected")
          this.props.authWithFacebook.exec(fbAuthObject);
      },
      {
        scope: "email",
      }
    );

  componentDidUpdate(prevProps) {
    if (
      !prevProps.authWithFacebook.status.success &&
      this.props.authWithFacebook.status.success
    )
      this.props.loadUser.exec();
  }

  // old login button
  // render() {
  //   return (
  //     <StandardButton
  //       className="facebook-auth"
  //       onClick={this.login}
  //       type="primary"
  //       loading={
  //         this.props.authWithFacebook.status.pending ||
  //         this.props.loadUser.status.pending
  //       }
  //     >
  //       {this.props.label}
  //     </StandardButton>
  //   );
  // }

  render() {
    return (
      <div style={{ margin: "0 auto", textAlign: "center" }}>
        <Spin
          spinning={
            this.props.authWithFacebook.status.pending ||
            this.props.loadUser.status.pending
          }
        >
          <StandardButton
            style={{
              border: "none",
              color: "white",
              backgroundColor: "rgb(24, 119, 242)",
              fontSize: "2em",
              fontWeight: "bold",
              width: "auto",
            }}
            onClick={this.login}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <AwesomeIcon icon={faFacebook} color="white" size="2x" />
              <FormattedMessage
                id="app.login.auth.facebook"
                defaultMessage="Continue with Facebook"
              />
            </div>
          </StandardButton>
        </Spin>
      </div>
    );
  }
}

export default WatchActions({
  watch: {
    authWithFacebook: {
      action: FACEBOOK_AUTH,
      dispatch: (dispatch) => (response) =>
        dispatch(authWithFacebook(response)),
      success: true,
    },
    loadUser: {
      action: LOAD_USER,
      dispatch: (dispatch) => () => dispatch(loadUser()),
    },
  },
})(withRouter(FacebookAuthButton));
