import StandardButton from "components/common/StandardButton";
import { useCancellationWizard } from "./SubscriptionCancellationWizard";
import { Text, Title } from "components/common/StandardText";
import FormattedMessage from "components/common/FormattedMessage";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";
import RelaxField from "tools/relaxForm/RelaxField";
import { Input, Radio, Space } from "antd";
import WizardButtons from "components/misc/WizardButtons";
import { PatchCancellationSurvey } from "state/subscriptions/subscriptionsEndpoints";
import { subscriptionCancellationSurveySelector } from "state/subscriptions/subscriptionsSelectors";
import SimpleWizard, { SimpleWizardRef, SimpleWizardStep } from "tools/wizard/SimpleWizard";
import SurveyRating from "components/inputs/survey/SurveyRating";
import SurveyQuestionWithFollowup, { SurveyRatingWithFollowup } from "components/inputs/survey/SurveyQuestionWithFollowup";
import TextEditorNew from "tools/textEditor/TextEditorNew";
import { useRef } from "react";
import CompanyCategorySelect from "modules/forsant/components/business-details/company-search-select";
import SubscriptionCancellationSummaryAlert from "./SubscriptionCancellationSummaryAlert";

const DefinitelyYes = <FormattedMessage
    id="tools.subscriptions.rating.definitelyYes"
    defaultMessage="Definitely Yes"
/>

const DefinitelyNo = <FormattedMessage
    id="tools.subscriptions.rating.definitelyNo"
    defaultMessage="Definitely No"
/>


const steps: SimpleWizardStep[] = [
    {
        name: "question1",
        component: (
            <SurveyQuestionWithFollowup
                key="question1"
                question={(
                    <FormattedMessage
                        id="subscription.cancellation.survey.question1.question"
                        defaultMessage="What industry do you represent?"
                    />
                )}
                questionAdditional={(
                    <FormattedMessage
                        id="subscription.cancellation.survey.question1.additional"
                        defaultMessage="Enter 5 characters and wait for suggestions to appear."
                    />
                )}
                name={["data", "industryRepresentation"]}
                questionComponent={(
                    <CompanyCategorySelect />
                )}
                followupName={""}
                followupQuestion={undefined}
                followupComponent={undefined}
                questionNumber={1}
                questionCount={7} />
        )
    },
    {
        name: "question2",
        component: (
            <SurveyRatingWithFollowup
                key="question2"
                question={(
                    <FormattedMessage
                        id="subscription.cancellation.survey.question2.question"
                        defaultMessage="How would you rate our overall quality of service on a scale of 1 to 10"
                    />
                )}
                name={["data", "overallServiceQuality"]}
                min={DefinitelyNo}
                max={DefinitelyYes}
                followupName={["data", "discontinuationReasons"]}
                followupQuestion={(
                    <FormattedMessage
                        id="subscription.cancellation.survey.question2.followup"
                        defaultMessage="What were the main reasons that led you to discontinue our services?"
                    />
                )}
                questionNumber={2}
                questionCount={7} />
        )
    },
    {
        name: "question3",
        component: (
            <SurveyRatingWithFollowup
                key="question3"
                question={(
                    <FormattedMessage
                        id="subscription.cancellation.survey.question3.question"
                        defaultMessage="How would you rate the ease of use of our FastTony.com application?"
                    />
                )}
                questionAdditional={(
                    <FormattedMessage
                        id="subscription.cancellation.survey.question3.additional"
                        defaultMessage="Rate from 1 to 10, where 1 is very difficult and 10 is very easy."
                    />
                )}
                name={["data", "appEaseOfUse"]}
                min={DefinitelyNo}
                max={DefinitelyYes}
                followupName={["data", "appFeatureSuggestions"]}
                followupQuestion={(
                    <FormattedMessage
                        id="subscription.cancellation.survey.question3.followup"
                        defaultMessage="Are there any features that you think should be added to our application?"
                    />
                )}
                questionNumber={3}
                questionCount={7} />
        )
    },
    {
        name: "question4",
        component: (
            <SurveyRatingWithFollowup
                key="question4"
                question={(
                    <FormattedMessage
                        id="subscription.cancellation.survey.question4.question"
                        defaultMessage="How would you rate the value for money of our service?"
                    />
                )}
                questionAdditional={(
                    <FormattedMessage
                        id="subscription.cancellation.survey.question4.additional"
                        defaultMessage="Rate from 1 to 10, where 1 is very poor value for money and 10 is excellent value for money."
                    />
                )}
                name={["data", "valueForMoney"]}
                min={DefinitelyNo}
                max={DefinitelyYes}
                followupName={["data", "pricingStructureFeedback"]}
                followupQuestion={(
                    <FormattedMessage
                        id="subscription.cancellation.survey.question4.followup"
                        defaultMessage="Was our price appropriate for the value offered? Do you have any comments on the pricing structure?"
                    />
                )}
                questionNumber={4}
                questionCount={7} />
        )
    },
    {
        name: "question5",
        component: (
            <SurveyRatingWithFollowup
                key="question5"
                question={(
                    <FormattedMessage
                        id="subscription.cancellation.survey.question5.question"
                        defaultMessage="How satisfied are you with the response time to reported issues?"
                    />
                )}
                questionAdditional={(
                    <FormattedMessage
                        id="subscription.cancellation.survey.question5.additional"
                        defaultMessage="Rate from 1 to 10, where 1 is very dissatisfied and 10 is very satisfied."
                    />
                )}
                name={["data", "issueResponseSatisfaction"]}
                min={DefinitelyNo}
                max={DefinitelyYes}
                followupName={["data", "unmetExpectations"]}
                followupQuestion={(
                    <FormattedMessage
                        id="subscription.cancellation.survey.question5.followup"
                        defaultMessage="Are there specific features or aspects of our application that did not meet your expectations?"
                    />
                )}
                questionNumber={5}
                questionCount={7} />
        )
    },
    {
        name: "question6",
        component: (
            <SurveyRatingWithFollowup
                key="question6"
                question={(
                    <FormattedMessage
                        id="subscription.cancellation.survey.question6.question"
                        defaultMessage="How would you rate our communication and customer support?"
                    />
                )}
                questionAdditional={(
                    <FormattedMessage
                        id="subscription.cancellation.survey.question6.additional"
                        defaultMessage="Rate from 1 to 10, where 1 is very poor and 10 is excellent."
                    />
                )}
                name={["data", "communicationAndSupport"]}
                min={DefinitelyNo}
                max={DefinitelyYes}
                followupName={["data", "supportImprovementSuggestions"]}
                followupQuestion={(
                    <FormattedMessage
                        id="subscription.cancellation.survey.question6.followup"
                        defaultMessage="Do you have any suggestions on how we could improve our support or customer contact?"
                    />
                )}
                questionNumber={6}
                questionCount={7} />
        )
    },
    {
        name: "question7",
        component: (
            <SurveyQuestionWithFollowup
                key={"question7"}
                question={(
                    <FormattedMessage
                        id="subscription.cancellation.survey.question7.question"
                        defaultMessage="Do you have any other comments or suggestions that could help us improve our offer?"
                    />
                )}
                name={["data", "generalFeedback"]}
                questionComponent={(
                    <Input />
                )}
                followupName={""}
                followupQuestion={undefined}
                followupComponent={undefined}
                questionNumber={7}
                questionCount={7} />
        )
    },
]

const STEP_AFTER_SURVEY = "payment";
const STEP_BEFORE_SURVEY = "benefits";

function SubscriptionCancellationSurvey() {

    const { subscriptionId, goToStep } = useCancellationWizard();
    const wizardRef = useRef<SimpleWizardRef>(null);

    const skipSurvey = () => {
        goToStep(STEP_AFTER_SURVEY);
    }

    const nextStep = () => {
        wizardRef.current?.goToStep();
    }

    const previousStep = () => {
        const stepIndex = wizardRef.current?.stepIndex;
        console.log("Going back from", stepIndex)
        if (!stepIndex || stepIndex === 0) {
            goToStep(STEP_BEFORE_SURVEY);
            return;
        }
        wizardRef.current?.goToStep(stepIndex - 1);
    }

    return (
        <>
        <Space direction="vertical" size="middle" style={{width: "100%"}}>
            <SubscriptionCancellationSummaryAlert />
            <RelaxMultiform
                callAPI={PatchCancellationSurvey(subscriptionId)}
                initialValuesSelector={[subscriptionCancellationSurveySelector, subscriptionId]}
            >
                <SimpleWizard
                    steps={steps}
                    ref={wizardRef}
                    onWizardComplete={() => {
                        goToStep(STEP_AFTER_SURVEY);
                    }}
                >

                </SimpleWizard>
            </RelaxMultiform>
            <WizardButtons
                canGoBack
                canGoForward
                onNext={nextStep}
                onBack={previousStep}
            >
                <StandardButton
                    onClick={skipSurvey}
                    type="text"
                >
                    <FormattedMessage
                        id="subscription.cancellation.survey.skip"
                        defaultMessage="Skip Survey"
                    />
                </StandardButton>
            </WizardButtons>
        </Space>
        </>
    );
}

export default SubscriptionCancellationSurvey;


