import StandardButton from "components/common/StandardButton";
import _ from "lodash";
import { GoogleSmartSettingsSelector, UpdateGoogleSmartSettings } from "modules/smart/smartSlice";
import ConnectedOnboardingControls from "providers/onboarding/controls";
import { useOnboarding } from "providers/onboarding/onboarding-context";
import useAPI, { callAPIProps } from "services/hooks/useAPI";
import useMemoDeepCompare from "services/hooks/useMemoDeepCompare";
import RelaxField from "tools/relaxForm/RelaxField";
import useRelaxData from "tools/relaxForm/useRelaxData";

const updateSettings:callAPIProps = {
    url: ({getApiUrl, projectId}) => getApiUrl(`projects/${projectId}/services/google/ads/keywords/proposition`, 2),
}

const getKeywords:callAPIProps = {
    url: ({getApiUrl, projectId}) => getApiUrl(`projects/${projectId}/services/google/ads/keywords/proposition`, 2),
}

const KeywordIdea = (props: any) => {
    return (
        <StandardButton
            size = "small"
            style={{
                width: "auto",
                padding: "6px",
                maxHeight: "35px",
                minHeight: "35px",
                backgroundColor: props.keyword?.type === "include" ? "white" : "lightgrey",
            }}
            onClick={() => {
                props.onClick && props.onClick(props.keyword)
            }}
            type = {props.keyword?.type === "include" ? "default" : "dashed"}
        >
            {props.keyword?.label}
        </StandardButton>
    )
}

const KeywordIdeas = (props:any) => {

    const {call, loading, error, data, StatusDisplay} = useAPI({...getKeywords,
        adjustResponse: (data: any) => {
            const adjusted = (data || []).map((k:any) => ({
                label: k.label.key,
                type: (props.value?.find && props.value.find((k:any) => k.label === k.label.key)?.type ) || "include",
            }))
            return adjusted
        },
        onSuccess: (data: any) => {
            props.onChange && props.onChange(data)
        },
    }, true);


    const handleClick = (keyword: any) => {

        if (!keyword) return;

        keyword.type = (keyword.type === "include") ? "exclude" : "include"

        props.onChange && props.onChange(props.value.map((k:any) => {
            if (k.label === keyword.label) return keyword;
            return k;
        }))
        props.onBlur && props.onBlur()
    }

    const mappedIdeas = useMemoDeepCompare(() => {

        if (!props.value) return [];

        return props.value.map((keyword: any) =>  { 

            const kwd = {
                label: keyword.label,
                type: keyword.type || "include",
            }
       
            return <KeywordIdea 
                keyword={kwd} 
                key={kwd.label}
                onClick={handleClick}
            />
        })
    }, [props.value])


    if (loading || error) return (
    <div
    style={{
        height: "200px"
    }}
    >
        {StatusDisplay}
    </div>)

    return (

        <div style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            overflow: "scroll",
        }}>
            {mappedIdeas}
        </div>
    )

}



const OnboardingKeywordIdeas = () => {

    const {nextStep} = useOnboarding();
    const rd = useRelaxData({
        onSuccess: (data: any) => {
            nextStep();
        }
    });

    const handleNext = () => {
        rd.submit("manual");
    }

    return (
        <div
        style={{
            display: "flex",
            flexDirection: "column",
        }}
    >  
            <RelaxField
                relaxData={rd}
                name="keywords"
                mode="manual"
                hideStatus={true}
                initialValuesSelector={GoogleSmartSettingsSelector}
                callAPI={UpdateGoogleSmartSettings}
                finalizeSubmit={(data: any) => {
                    return {
                        keywords: data.keywords.filter((k:any) => k.type === "include").map((k:any) => ({
                            type: k.type,
                            label: k.label
                        }))
                    }
                }}
            >
                    <KeywordIdeas />
            </RelaxField>
            <ConnectedOnboardingControls clickOverride={handleNext} nextPending={rd.apiHandler.loading}/>
        </div>
    )

}

export default OnboardingKeywordIdeas;