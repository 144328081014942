export type Font = {
    name: string,
    font: string,
}

export const fonts :Font[] = [
{
    "name": "Roboto",
    "font": "Roboto"
},
{
    "name": "Open Sans",
    "font": "Open Sans"
},
{
    "name": "Lato",
    "font": "Lato"
},
{
    "name": "Montserrat",
    "font": "Montserrat"
},
{
    "name": "Source Sans Pro",
    "font": "Source Sans Pro"
},
{
    "name": "Raleway",
    "font": "Raleway"
},
{
    "name": "Roboto Mono",
    "font": "Roboto Mono"
},
{
    "name": "Poppins",
    "font": "Poppins"
},
{
    "name": "Muli",
    "font": "Muli"
},
{
    "name": "Tomorrow",
    "font": "Tomorrow"
},
{
    "name": "Nunito",
    "font": "Nunito"
},
{
    "name": "Titillium Web",
    "font": "Titillium Web"
},
{
    "name": "Rubik",
    "font": "Rubik"
},
{
    "name": "Fira Sans",
    "font": "Fira Sans"
},
{
    "name": "Public Sans",
    "font": "Public Sans"
},
{
    "name": "Nunito Sans",
    "font": "Nunito Sans"
},
{
    "name": "Josefin Sans",
    "font": "Josefin Sans"
},
{
    "name": "Libre Franklin",
    "font": "Libre Franklin"
},
{
    "name": "Barlow",
    "font": "Barlow"
},
{
    "name": "Source Code Pro",
    "font": "Source Code Pro"
},
{
    "name": "Exo 2",
    "font": "Exo 2"
},
{
    "name": "Overpass",
    "font": "Overpass"
},
{
    "name": "EB Garamond",
    "font": "EB Garamond"
},
{
    "name": "Barlow Condensed",
    "font": "Barlow Condensed"
},
{
    "name": "IBM Plex Sans",
    "font": "IBM Plex Sans"
},
{
    "name": "Zilla Slab",
    "font": "Zilla Slab"
},
{
    "name": "Kanit",
    "font": "Kanit"
},
{
    "name": "Fira Sans Condensed",
    "font": "Fira Sans Condensed"
},
{
    "name": "Exo",
    "font": "Exo"
},
{
    "name": "Prompt",
    "font": "Prompt"
},
{
    "name": "Cormorant Garamond",
    "font": "Cormorant Garamond"
},
{
    "name": "Alegreya",
    "font": "Alegreya"
},
{
    "name": "Alegreya Sans",
    "font": "Alegreya Sans"
},
{
    "name": "Barlow Semi Condensed",
    "font": "Barlow Semi Condensed"
},
{
    "name": "Spectral",
    "font": "Spectral"
},
{
    "name": "Crimson Pro",
    "font": "Crimson Pro"
},
{
    "name": "Fira Sans Extra Condensed",
    "font": "Fira Sans Extra Condensed"
},
{
    "name": "IBM Plex Serif",
    "font": "IBM Plex Serif"
},
{
    "name": "Kulim Park",
    "font": "Kulim Park"
},
{
    "name": "Taviraj",
    "font": "Taviraj"
},
{
    "name": "Montserrat Alternates",
    "font": "Montserrat Alternates"
},
{
    "name": "Cormorant",
    "font": "Cormorant"
},
{
    "name": "Sarabun",
    "font": "Sarabun"
},
{
    "name": "Alegreya Sans SC",
    "font": "Alegreya Sans SC"
},
{
    "name": "IBM Plex Mono",
    "font": "IBM Plex Mono"
},
{
    "name": "Be Vietnam",
    "font": "Be Vietnam"
},
{
    "name": "Mali",
    "font": "Mali"
},
{
    "name": "Bai Jamjuree",
    "font": "Bai Jamjuree"
},
{
    "name": "Trirong",
    "font": "Trirong"
},
{
    "name": "Alegreya SC",
    "font": "Alegreya SC"
},
{
    "name": "Krub",
    "font": "Krub"
},
{
    "name": "Rosario",
    "font": "Rosario"
},
{
    "name": "Livvic",
    "font": "Livvic"
},
{
    "name": "Chakra Petch",
    "font": "Chakra Petch"
},
{
    "name": "IBM Plex Sans Condensed",
    "font": "IBM Plex Sans Condensed"
},
{
    "name": "Cormorant Infant",
    "font": "Cormorant Infant"
},
{
    "name": "Niramit",
    "font": "Niramit"
},
{
    "name": "Proza Libre",
    "font": "Proza Libre"
},
{
    "name": "Spectral SC",
    "font": "Spectral SC"
},
{
    "name": "K2D",
    "font": "K2D"
},
{
    "name": "Grenze",
    "font": "Grenze"
},
{
    "name": "Kodchasan",
    "font": "Kodchasan"
},
{
    "name": "KoHo",
    "font": "KoHo"
},
{
    "name": "Fahkwang",
    "font": "Fahkwang"
}
]

export const fonts_header:Font[] = [{
    "name": "Roboto",
    "font": "Roboto"
},
{
    "name": "Open Sans",
    "font": "Open Sans"
},
{
    "name": "Lato",
    "font": "Lato"
},
{
    "name": "Montserrat",
    "font": "Montserrat"
},
{
    "name": "Source Sans Pro",
    "font": "Source Sans Pro"
},
{
    "name": "Raleway",
    "font": "Raleway"
},
{
    "name": "Roboto Mono",
    "font": "Roboto Mono"
},
{
    "name": "Poppins",
    "font": "Poppins"
},
{
    "name": "Muli",
    "font": "Muli"
},
{
    "name": "Tomorrow",
    "font": "Tomorrow"
},
{
    "name": "Nunito",
    "font": "Nunito"
},
{
    "name": "Titillium Web",
    "font": "Titillium Web"
},
{
    "name": "Rubik",
    "font": "Rubik"
},
{
    "name": "Fira Sans",
    "font": "Fira Sans"
},
{
    "name": "Public Sans",
    "font": "Public Sans"
},
{
    "name": "Nunito Sans",
    "font": "Nunito Sans"
},
{
    "name": "Josefin Sans",
    "font": "Josefin Sans"
},
{
    "name": "Libre Franklin",
    "font": "Libre Franklin"
},
{
    "name": "Barlow",
    "font": "Barlow"
},
{
    "name": "Source Code Pro",
    "font": "Source Code Pro"
},
{
    "name": "Exo 2",
    "font": "Exo 2"
},
{
    "name": "Overpass",
    "font": "Overpass"
},
{
    "name": "EB Garamond",
    "font": "EB Garamond"
},
{
    "name": "Barlow Condensed",
    "font": "Barlow Condensed"
},
{
    "name": "IBM Plex Sans",
    "font": "IBM Plex Sans"
},
{
    "name": "Zilla Slab",
    "font": "Zilla Slab"
},
{
    "name": "Kanit",
    "font": "Kanit"
},
{
    "name": "Fira Sans Condensed",
    "font": "Fira Sans Condensed"
},
{
    "name": "Exo",
    "font": "Exo"
},
{
    "name": "Prompt",
    "font": "Prompt"
},
{
    "name": "Cormorant Garamond",
    "font": "Cormorant Garamond"
},
{
    "name": "Alegreya",
    "font": "Alegreya"
},
{
    "name": "Alegreya Sans",
    "font": "Alegreya Sans"
},
{
    "name": "Barlow Semi Condensed",
    "font": "Barlow Semi Condensed"
},
{
    "name": "Spectral",
    "font": "Spectral"
},
{
    "name": "Crimson Pro",
    "font": "Crimson Pro"
},
{
    "name": "Fira Sans Extra Condensed",
    "font": "Fira Sans Extra Condensed"
},
{
    "name": "IBM Plex Serif",
    "font": "IBM Plex Serif"
},
{
    "name": "Kulim Park",
    "font": "Kulim Park"
},
{
    "name": "Taviraj",
    "font": "Taviraj"
},
{
    "name": "Montserrat Alternates",
    "font": "Montserrat Alternates"
},
{
    "name": "Cormorant",
    "font": "Cormorant"
},
{
    "name": "Sarabun",
    "font": "Sarabun"
},
{
    "name": "Alegreya Sans SC",
    "font": "Alegreya Sans SC"
},
{
    "name": "IBM Plex Mono",
    "font": "IBM Plex Mono"
},
{
    "name": "Be Vietnam",
    "font": "Be Vietnam"
},
{
    "name": "Mali",
    "font": "Mali"
},
{
    "name": "Bai Jamjuree",
    "font": "Bai Jamjuree"
},
{
    "name": "Trirong",
    "font": "Trirong"
},
{
    "name": "Alegreya SC",
    "font": "Alegreya SC"
},
{
    "name": "Krub",
    "font": "Krub"
},
{
    "name": "Rosario",
    "font": "Rosario"
},
{
    "name": "Livvic",
    "font": "Livvic"
},
{
    "name": "Chakra Petch",
    "font": "Chakra Petch"
},
{
    "name": "IBM Plex Sans Condensed",
    "font": "IBM Plex Sans Condensed"
},
{
    "name": "Cormorant Infant",
    "font": "Cormorant Infant"
},
{
    "name": "Niramit",
    "font": "Niramit"
},
{
    "name": "Proza Libre",
    "font": "Proza Libre"
},
{
    "name": "Spectral SC",
    "font": "Spectral SC"
},
{
    "name": "K2D",
    "font": "K2D"
},
{
    "name": "Grenze",
    "font": "Grenze"
},
{
    "name": "Kodchasan",
    "font": "Kodchasan"
},
{
    "name": "KoHo",
    "font": "KoHo"
},
{
    "name": "Fahkwang",
    "font": "Fahkwang"
}]

export const fonts_text:Font[] = [
    {
        "name": "Roboto",
        "font": "Roboto"
    },
    {
        "name": "Open Sans",
        "font": "Open Sans"
    },
    {
        "name": "Lato",
        "font": "Lato"
    },
    {
        "name": "Montserrat",
        "font": "Montserrat"
    },
    {
        "name": "Source Sans Pro",
        "font": "Source Sans Pro"
    },
    {
        "name": "Raleway",
        "font": "Raleway"
    },
    {
        "name": "Roboto Mono",
        "font": "Roboto Mono"
    },
    {
        "name": "Poppins",
        "font": "Poppins"
    },
    {
        "name": "Muli",
        "font": "Muli"
    },
    {
        "name": "Tomorrow",
        "font": "Tomorrow"
    },
    {
        "name": "Nunito",
        "font": "Nunito"
    },
    {
        "name": "Titillium Web",
        "font": "Titillium Web"
    },
    {
        "name": "Rubik",
        "font": "Rubik"
    },
    {
        "name": "Fira Sans",
        "font": "Fira Sans"
    },
    {
        "name": "Public Sans",
        "font": "Public Sans"
    },
    {
        "name": "Nunito Sans",
        "font": "Nunito Sans"
    },
    {
        "name": "Josefin Sans",
        "font": "Josefin Sans"
    },
    {
        "name": "Libre Franklin",
        "font": "Libre Franklin"
    },
    {
        "name": "Barlow",
        "font": "Barlow"
    },
    {
        "name": "Source Code Pro",
        "font": "Source Code Pro"
    },
    {
        "name": "Exo 2",
        "font": "Exo 2"
    },
    {
        "name": "Overpass",
        "font": "Overpass"
    },
    {
        "name": "EB Garamond",
        "font": "EB Garamond"
    },
    {
        "name": "Barlow Condensed",
        "font": "Barlow Condensed"
    },
    {
        "name": "IBM Plex Sans",
        "font": "IBM Plex Sans"
    },
    {
        "name": "Zilla Slab",
        "font": "Zilla Slab"
    },
    {
        "name": "Kanit",
        "font": "Kanit"
    },
    {
        "name": "Fira Sans Condensed",
        "font": "Fira Sans Condensed"
    },
    {
        "name": "Exo",
        "font": "Exo"
    },
    {
        "name": "Prompt",
        "font": "Prompt"
    },
    {
        "name": "Cormorant Garamond",
        "font": "Cormorant Garamond"
    },
    {
        "name": "Alegreya",
        "font": "Alegreya"
    },
    {
        "name": "Alegreya Sans",
        "font": "Alegreya Sans"
    },
    {
        "name": "Barlow Semi Condensed",
        "font": "Barlow Semi Condensed"
    },
    {
        "name": "Spectral",
        "font": "Spectral"
    },
    {
        "name": "Crimson Pro",
        "font": "Crimson Pro"
    },
    {
        "name": "Fira Sans Extra Condensed",
        "font": "Fira Sans Extra Condensed"
    },
    {
        "name": "IBM Plex Serif",
        "font": "IBM Plex Serif"
    },
    {
        "name": "Kulim Park",
        "font": "Kulim Park"
    },
    {
        "name": "Taviraj",
        "font": "Taviraj"
    },
    {
        "name": "Montserrat Alternates",
        "font": "Montserrat Alternates"
    },
    {
        "name": "Cormorant",
        "font": "Cormorant"
    },
    {
        "name": "Sarabun",
        "font": "Sarabun"
    },
    {
        "name": "Alegreya Sans SC",
        "font": "Alegreya Sans SC"
    },
    {
        "name": "IBM Plex Mono",
        "font": "IBM Plex Mono"
    },
    {
        "name": "Be Vietnam",
        "font": "Be Vietnam"
    },
    {
        "name": "Mali",
        "font": "Mali"
    },
    {
        "name": "Bai Jamjuree",
        "font": "Bai Jamjuree"
    },
    {
        "name": "Trirong",
        "font": "Trirong"
    },
    {
        "name": "Alegreya SC",
        "font": "Alegreya SC"
    },
    {
        "name": "Krub",
        "font": "Krub"
    },
    {
        "name": "Rosario",
        "font": "Rosario"
    },
    {
        "name": "Livvic",
        "font": "Livvic"
    },
    {
        "name": "Chakra Petch",
        "font": "Chakra Petch"
    },
    {
        "name": "IBM Plex Sans Condensed",
        "font": "IBM Plex Sans Condensed"
    },
    {
        "name": "Cormorant Infant",
        "font": "Cormorant Infant"
    },
    {
        "name": "Niramit",
        "font": "Niramit"
    },
    {
        "name": "Proza Libre",
        "font": "Proza Libre"
    },
    {
        "name": "Spectral SC",
        "font": "Spectral SC"
    },
    {
        "name": "K2D",
        "font": "K2D"
    },
    {
        "name": "Grenze",
        "font": "Grenze"
    },
    {
        "name": "Kodchasan",
        "font": "Kodchasan"
    },
    {
        "name": "KoHo",
        "font": "KoHo"
    },
    {
        "name": "Fahkwang",
        "font": "Fahkwang"
    }
]