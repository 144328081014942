import { Rate } from "antd";
import "./survey-rating.less";
import { ReactNode } from "react";

type SurveyRatingProps = {
    value?: number,
    onChange?: (value: number) => void,
    onBlur?: () => void,
    min?: ReactNode,
    max?: ReactNode,
}

function SurveyRating(props: SurveyRatingProps) {

    return (
        <div className="survey-rating-wrapper">
            <div className="survey-legend min">{props.min}</div>
            <Rate 
                className="standard-border survey-rating"
                character={({ index }) => (index || 0 ) + 1 + ""}
                allowHalf={false}
                count={10}
                {...props}
            />
            <div className="survey-legend max">{props.max}</div>
        </div>
    );
}

export default SurveyRating;