import React, { useState, Component, useEffect } from "react";
import { Button, Col, Row, Spin, Tooltip } from "antd";
import { OnboardingConsumer } from "providers/onboarding/onboarding-context";
import usePrevious from "services/hooks/use-previous";
import { Redirect } from "react-router-dom";
import FormattedMessage from "components/common/FormattedMessage";
import { LanguageSelectInput } from "components/inputs/LanguageAndCountrySelectors";
import StandardButton from "components/common/StandardButton";
import { LogoutButton } from "modules/panel/containers/public/LogoutPage";
import { ProjectSelect } from "providers/routing/layouts/templates/panel/top-bar/project-select/ProjectSelect";

const OnboardingControls = ({
  previousStep,
  nextStep,
  currentStep,
  logout,
  projects,
  indexRoute,
  form = false,
  nextPending = false,
  nextDisabled = false,
  previousStepText = false,
  previousStepStyle = {},
  nextStepText = false,
  nextStepStyle = {},
  hideButtons = false,
  style = {},
  clickOverride = false,
  customButtons,
}) => {


  let isInFormHoc = false;
  let formRequestPending = false;
  let formRequestError = false;

  if (form) {
    isInFormHoc = true;
    formRequestPending = form.status?.pending;
    formRequestError = form.status?.error;
  }


  const prevStatus = usePrevious({ formRequestPending, formRequestError });
  if (
    prevStatus &&
    prevStatus.formRequestPending &&
    !formRequestPending &&
    !formRequestError
  ) nextStep();


  const awaitNextStep = async () => {
    const c = await clickOverride();
    if (c) {
      nextStep()
    } else {
      console.log("Can't progress, form error")
    }
  }



  const handleNextClick = () => {

    if (clickOverride) {
      awaitNextStep();
      return;
    }
  
   
      if (!isInFormHoc) {
        nextStep();
      } else {

        const { instance, handleSubmit } = form;
        !instance.isFieldsTouched() && currentStep.fulfilled === true
          ? nextStep()
          : handleSubmit();

      }
    

  };

  const { isLastStep, availablePreviousBtn } = currentStep.controls;

  return <>
    {currentStep.index === null && <Redirect to={indexRoute} />}
    {hideButtons === false && (
      <Row className="onboarding-controls" style={style} gutter={30}>
        {availablePreviousBtn && (
          <Col span={12}>
            <StandardButton
              className="previous-step-btn"
              disabled={formRequestPending}
              style={previousStepStyle}
              onClick={() => previousStep()}
            >
              {previousStepText ? (
                previousStepText
              ) : (
                <FormattedMessage
                  id="panel.providers.onboarding.controls.previous"
                  defaultMessage="Previous step"
                />
              )}
            </StandardButton>
          </Col>
        )}
        <Col span={availablePreviousBtn ? 12 : 24}>
          <StandardButton
            data-testid="nextStep"
            className="next-step-btn"
            type="primary"
            disabled={nextDisabled}
            style={nextStepStyle}
            loading={formRequestPending || nextPending}
            onClick={handleNextClick}
          >
            {isLastStep ? (
              <FormattedMessage
                id="panel.providers.onboarding.controls.finish"
                defaultMessage="Finish"
              />
            ) : nextStepText ? (
              nextStepText
            ) : (
              <FormattedMessage
                id="panel.providers.onboarding.controls.next"
                defaultMessage="Next step"
              />
            )}
          </StandardButton>
        </Col>
      </Row>
    )}
    {customButtons && customButtons(handleNextClick)}
    <div className="fixed-onboarding-controls">
      <div className="change-project">
        {/* <ProjectSelect
          projects={projects.list}
          avatar={projects.current.avatar}
          currentProjectId={projects.current.id}
          onSelect={() => null}
          newOption={false}
        /> */}
        <ProjectSelect/>
      </div>
      <div className="onboarding-language-input">
        <LanguageSelectInput flagOnly={true} />
      </div>
      <Tooltip placement="bottom" title="Logout">
        <LogoutButton />
      </Tooltip>
    </div>
  </>;
};

export default class ConnectedOnboardingControls extends Component {
  render() {
    return (
      <OnboardingConsumer>
        {({
          previousStep,
          nextStep,
          currentStep,
          logout,
          indexRoute,
          projects,
        }) => {
          return (
            <OnboardingControls
              previousStep={previousStep}
              nextStep={nextStep}
              currentStep={currentStep}
              logout={logout}
              projects={projects}
              indexRoute={indexRoute}
              {...this.props}
            />
          );
        }}
      </OnboardingConsumer>
    );
  }
}

