import ConnectButton from "modules/panel/components/integrations/ConnectBtn";
import { useSelector } from "react-redux";
import { ProviderConfig } from "modules/panel/config/ProviderConfig";
import { userIntegrationsSelector } from "modules/panel/config/selectors/user";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";

const UserIntegrations = () => {
  const userProviders = useSelectorWithParams(userIntegrationsSelector);

  return (

    <>
      {Object.keys(userProviders).map((platform) => {
        return (
          <ConnectButton
            key={platform}
            name={ProviderConfig.get(platform)?.displayName}
            logo={ProviderConfig.get(platform)?.icon}
            url={ProviderConfig.get(platform)?.getIntegrateUrl()}
            connected={userProviders?.[platform]?.authorized}
          />
        );
      })}
    </>
  )
};

export default UserIntegrations;
