import { Select } from "antd";
import StandardActorCard, { ActorInfo } from "components/common/StandardActorCard";
import { ReactNode, useMemo } from "react";
import "./actor-card-select.less";

type Props = {
  actors: ActorForSelect[] | {
    renderLabel: ReactNode
    value: string | number;
  }[] | any;
  value?: string;
  style?: React.CSSProperties;
  onChange?: (value: string) => void;
  selectProps?: any;
};

type ActorForSelect = {
  name: string;
  image: string;
  subtitle: string;
  value: string | number;
  extra?: ReactNode;

}

function ActorCardSelect(props: Props) {
  
  const handleChange = (e: any) => {
    props.onChange && props.onChange(e);
  };

  const mappedOptions = useMemo(() => {
    if(!props.actors) return null;
      return props.actors.map((actor: any, index: number) => {
        if(actor){
            return {
              value: actor.value || actor.name || index,
              label: actor.renderLabel ? actor.renderLabel : <StandardActorCard actor={actor} extra={actor.extra} size="fullWidth"/>,
              name: actor.name || actor.value || index,
              disabled: actor.disabled || false,
            };
          }
      });
   
  }, [props.actors]);
  
  return (
    <Select
      style={props.style ? props.style : {width:"300px"}}
      className="actor-card-select"
      options={mappedOptions}
      value={props.value}
      onChange={handleChange}
      {...props.selectProps}
    />
  );
}

export default ActorCardSelect;
