import { faFrame, faHand, faImages, faPanorama } from "@fortawesome/pro-light-svg-icons";
import { Crop } from "NewReactImageCrop";
import AwesomeIcon from "components/common/AwesomeIcon";
import StandardButton from "components/common/StandardButton";
import CardSelect from "components/inputs/CardSelect";
import { useEffect, useMemo, useState } from "react";
import useAPI from "services/hooks/useAPI";
import Gallery, { GalleryItem } from "tools/gallery/Gallery";
import { patchCreation, patchCreationWithSmartAction } from "../creationsSlice";
import { useCreationEditor } from "../CreationEditor";
import { Card, Collapse, Space } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import { ReplaceSlidesInCreation } from "../creationHelpers";
import CreationSection from "../editorTools/CreationSection";
import { useCreationSection } from "../editorTools/useCreationSection";
import _ from "lodash";

const getSlideCrops = (crop: Crop, options: {numberOfSlides?: number, space?: number}): Crop[] => {

    const slideSize = crop.height;

    if (slideSize < 600) {
        return [];
    }

    // slideSpacing is 18 when size is 600 and scales 
    // linearly with size
    const sliceSpacingBase = options?.space !== undefined ? options.space : 18;
    const slideSpacing = Math.max(0, Math.floor((slideSize / 600) * sliceSpacingBase));
    const numberOfSlidesToCreate = options?.numberOfSlides || Math.min(5, Math.floor(crop.width / slideSize));

    const subcrops: Crop[] = [];

    for (let i = 0; i < numberOfSlidesToCreate; i++) {
        const subcrop: Crop = {
            unit: "px",
            x: crop.x + (i * slideSize) + (i * slideSpacing),
            y: crop.y,
            width: slideSize,
            height: slideSize,
        }
        subcrops.push(subcrop);
    }

    return subcrops;
}

const getLastSlideId = (creation: any) => {
    try {
        return parseInt(Object.keys(creation.content.slides).reduce((a, b) => Math.max(a, b)));
    } catch (e) {
        return 0;
    }
}

const SplitIntoSlides = (props:any) => {

    const { creation, creationId, content, config } = useCreationEditor();
    const updateAPI = useAPI(patchCreation(creation.id))

    const getProvider = () => {
        if (content && content.provider && content.provider.length > 0) {
            return content.provider[0];
        }
        return "default"
    }

    const splitOptions = useMemo(() => {
        if (!config) return {};
        if (config.name === "instagram-carousel") {
            return {
                spaceHorizontal: 0
            }
        }
        return {};
    }, [config]);

    const handleSplit = (item:GalleryItem) => {

        if (!item) return;
        if (!item.crop) return;
        if (!item.crop.default) return;

        const space = splitOptions?.spaceHorizontal !== undefined ? splitOptions.spaceHorizontal : 18;

        const crops = getSlideCrops(item.crop.default, {space: space});
        if (crops && crops.length > 0) {

            //Find the highest key in the slides object
            const lastSlideId = getLastSlideId(creation);

            const carousel = _.cloneDeep(creation?.content?.carousel) || [];
            const slides = _.cloneDeep(creation?.content?.slides) || {};

            const newCarousel: number[] = [];

            crops.forEach((crop, index) => {

                const existingSlideId = carousel[index];
                const existingSlide = slides[existingSlideId];

                const newId = lastSlideId + index + 1;

                newCarousel.push(newId);
                slides[newId] = {
                    ...(existingSlide || {}),
                    id: newId,
                    media: [{
                        ...item,
                        crop: {
                            [getProvider()]: crop
                        }
                    }]
                }

            })

            updateAPI.call({
                body: {
                    content: {
                        carousel: newCarousel,
                        slides: slides
                    }
                }
            })
        }


        props.onClose && props.onClose();
    }



    return (
        <div>
            <Gallery
                onSplit={(data) => handleSplit(data)}
                singleImageOnly={true}
                mode="split"
                splitOptions={splitOptions}
                requireCropToSelect={true}
                requireCrop={true}
                autoSelectNewImages={true}
                allowedTools={["crop"]}
                cropPresets={{
                    default: [
                        {
                            ratio: "free",
                            aspect: 0,
                            minWidth: 1218,
                            minHeight: 600,
                        }
                    ]
                }}
            />
            <Space>
            <StandardButton onClick={() => props.onBack && props.onBack()}>
                <FormattedMessage
                    id="common.back"
                    defaultMessage="Back"
                />
            </StandardButton>
            </Space>
        </div>
    )

}

const CardsFromMultiplePictures = (props: any) => {

    const [items, setItems] = useState<GalleryItem[]>([]);
    const { creation, creationId, content } = useCreationEditor();
    const updateAPI = useAPI(patchCreation(creation.id))

    
    const getProvider = () => {
        if (content && content.provider && content.provider.length > 0) {
            return content.provider[0];
        }
        return "default"
    }

    const handleCreateSlides = () => {
        console.log("Creating slides from items", items);
        if (!items) return;
        if (items.length === 0) return;
        if (items.length > 5) return;

        const newSlides = {};
        const newCarousel: number[] = [];  
        const lastSlideId = getLastSlideId(creation);

        items.forEach((item, index) => {

            const newId = lastSlideId + index + 1;

            newCarousel.push(newId);
            newSlides[newId] = {
                id: newId,
                headling: index + "",
                media: [item]
            }
        })

        updateAPI.call({
            body: {
                content: {
                    carousel: newCarousel,
                    slides: newSlides
                }
            }
        })

    }

    const canCreateSlides = items && items.length > 0;

    return (
        <div>
            <Gallery
                onChange={(data) => data && data[0] && setItems(data)}
                cropPresets={{
                    [getProvider()]: [
                        {
                            ratio: "1:1",
                            minWidth: 600,
                            minHeight: 600,
                            aspect: 1,
                            displayName: "1:1"
                        }
                    ]
                }}
            />
            <Space>
            <StandardButton onClick={() => props.onBack && props.onBack()}>
                <FormattedMessage
                    id="common.back"
                    defaultMessage="Back"
                />
            </StandardButton>
            <StandardButton 
            onClick={handleCreateSlides}
            disabled={!canCreateSlides}
            >
                Create!
            </StandardButton>
            </Space>
        </div>
    )
}

const CardsManual = (props: any) => {

    const { creation, creationId } = useCreationEditor();
    const updateAPI = useAPI(patchCreation(creation.id))
    const slides = creation.content?.slides || {};
    const carousel:number[] = (creation.content?.carousel || []).map((i: any) => parseInt(i));

    const hasSlides = slides && slides.length > 0;
    const hasCarousel = carousel && carousel.length > 0;

    useEffect(() => {

        if (!hasSlides && !hasCarousel) {

            updateAPI.call({
                body: {
                    content: {
                        carousel: [1],
                        slides: {
                            1: {
                                id: 1,
                            }
                        }
                    }
                }
            })

        }


    }, [hasSlides, hasCarousel])

    return (
        <StandardButton onClick={() => props.onBack && props.onBack()}>
        <FormattedMessage
            id="common.back"
            defaultMessage="Back"
        />
        </StandardButton>
    );

}

const CarouselCardGenerator = (props: {onClose: () => void}) => {

    const [mode, setMode] = useState<"panorama" | "multiplePictures" | "manual" | null>(null);

    if (mode === "panorama") return <SplitIntoSlides onBack={() => setMode(null)} onClose={props.onClose}/>;
    if (mode === "multiplePictures") return <CardsFromMultiplePictures onBack={() => setMode(null)} onClose={props.onClose}/>;
    if (mode === "manual") return <CardsManual onBack={() => setMode(null)} onClose={props.onClose}/>;

    return (
    <CardSelect
        style={{ padding: "30px 0px" }}
        value={mode}
        onChange={(value) => setMode(value)}
        cardStyle={{
            width: "30%",
            height: "150px"
        }}
        cards={[
            {
                value: "panorama",
                title: <FormattedMessage
                        id="creationEditor.carouselCardGenerator.panorama.title"
                        defaultMessage="Panorama"
                    />,
                description: <FormattedMessage
                        id="creationEditor.carouselCardGenerator.panorama.description"
                        defaultMessage="Cards with panorama effect"
                    />,
                image: () => <AwesomeIcon icon={faPanorama} size="2xl" style={{ padding: "10px" }} />
            },
            {
                value: "multiplePictures",
                title: <FormattedMessage
                        id="creationEditor.carouselCardGenerator.multiplePictures.title"
                        defaultMessage="Multiple pictures"
                    />,
                description: <FormattedMessage
                        id="creationEditor.carouselCardGenerator.multiplePictures.description"
                        defaultMessage="Create cards from multiple pictures"
                    />,
                image: () => <AwesomeIcon icon={faImages} size="2xl" style={{ padding: "10px" }} />
            },
            {
                value: "manual",
                title: <FormattedMessage 
                        id="creationEditor.carouselCardGenerator.manual.title"
                        defaultMessage="Manual"
                    />,
                description: <FormattedMessage
                        id="creationEditor.carouselCardGenerator.manual.description"
                        defaultMessage="Create cards manually"
                    />,
                image: () => <AwesomeIcon icon={faHand} size="2xl" style={{ padding: "10px" }} />
            }
        ]}
    />
    )

}

function CarouselCardGeneratorSection(props: {onClose: () => void}) {

    const { creationId, content } = useCreationEditor()
    const { ref, status, setStatusData, handleFieldError, sectionComponentProps } = useCreationSection({
        sectionName: "cardGenerator",
        smartActionPrefix: "ce_cardGenerator",
    });

    useEffect(() => {
        if (!content || !content.carousel || content.carousel.length === 0) {
            setStatusData("error");
            return;
        }
        setStatusData("ready");
    }, [content]);

    return (
        <CreationSection
            {...props}
            {...sectionComponentProps}
            title={<FormattedMessage
                id="tools.creationEditor.sections.carouselGenerator.title"
                defaultMessage="Cards" />}
            tooltip={<FormattedMessage
                id="tools.creationEditor.sections.carouselGenerator.tooltip"
                defaultMessage="Choose how you want to create your cards" />}           
                 >
            <div>
                <FormattedMessage
                    id="creationEditor.carouselCardGenerator.description"
                    defaultMessage="Choose how you want to create your cards"
                />
                <CarouselCardGenerator onClose={props.onClose}/>
            </div>
        </CreationSection>
    );
}

export default CarouselCardGeneratorSection;

