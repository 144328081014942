import FormattedMessage from "components/common/FormattedMessage"
import SelectWithSuggestions from "components/forms/SelectWithSuggestionsNew"
import URLInput from "components/inputs/URLInput"
import { StandardFullColumn, StandardRow } from "components/layout/StandardGrid"
import { GoogleSmartSettingsSelector, SmartSelector, UpdateGoogleSmartSettings, smartSlice } from "modules/smart/smartSlice"
import { callAPIFunction } from "services/hooks/useAPI"
import useSelectorWithParams from "services/hooks/useSelectorWithParams"
import { userSettingsInterfaceLanguageSelector } from "state/user/userSettingsSlice"
import RelaxField from "tools/relaxForm/RelaxField"
import RelaxList from "tools/relaxForm/RelaxList"

const SettingsDataSources = () => {
    
    const language = useSelectorWithParams([userSettingsInterfaceLanguageSelector]);
    const getKeywords:callAPIFunction = (q) => ({
        url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/services/google/ads/keywords?language=${language}&type=query&query=${q}`, 2),
    })

    return (
        <>
                        <div style={{ height: "20px" }}></div>
            <StandardRow>
            <StandardFullColumn>
            <RelaxField
                initialValuesSelector={GoogleSmartSettingsSelector}
                callAPI={UpdateGoogleSmartSettings}
                name="keywords_seed"
                required
                label={
                    <FormattedMessage
                        id="apps.forsant.businessDetails.keywordSuggestion"
                        defaultMessage="Keyword suggestion"
                    />
                }
                submitOnChange
            >
                <SelectWithSuggestions
                    mode="multiple"
                    callAPI={getKeywords}
                    adjustValue={(v) => ({ value: v.label, label: v.label, disabled: false })}
                    adjustSuggestion={(s) => ({ value: s.text, label: s.text, disabled: false })}
                    uniqueValueProp="label"
                />
            </RelaxField>

            <RelaxField
                        name="website"
                        label={
                            <FormattedMessage
                                id="apps.smart.forms.businessDetails.websiteAddress"
                                defaultMessage="Your website address"
                            />
                        }
                        initialValuesSelector={SmartSelector}
                        callAPI={{
                            //url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/smarts/${smartId}`, 2),
                            url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/smart`, 1),
                            method: "PATCH",
                            dispatchName: smartSlice.actions.get,
            
                        }}
                        //finalizeSubmit={(values) => ({ ...values, project_id: projectId })}
                        disabled
                    >
                        <URLInput/>
                    </RelaxField>


            <RelaxList
                name="competitors_websites"
                initialValuesSelector={GoogleSmartSettingsSelector}
                callAPI={UpdateGoogleSmartSettings}
                required
                label={
                    <FormattedMessage
                        id="apps.forsant.businessDetails.competitorsWebsites"
                        defaultMessage="Your competitors’ websites"
                    />
                }
            >
                <URLInput />
            </RelaxList>
            </StandardFullColumn>
            </StandardRow>
        </>
    )

}

export default SettingsDataSources;