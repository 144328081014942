import { Tooltip } from "antd";
import { KeywordObjectByAttributeName } from "modules/panel/config/KeywordsConfig";
import { ProviderConfig } from "modules/panel/config/ProviderConfig";
import { ReactNode, useMemo } from "react";
import FormattedMessage from "components/common/FormattedMessage";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { usePost } from "../postContext";
import { AllAdsAndRemarkettingKeywords } from "modules/smart/smartSlice";

const DisplayPostSource = (postSource: string) => {
    if (!postSource) return null;
    if (ProviderConfig.get(postSource)) {
        return ProviderConfig.get(postSource)?.displayName
    }
    if (postSource === "platform_form") {
        return (<FormattedMessage
            id="posts.postSource.platformForm"
            defaultMessage="this app" />)
    }
    return null;
}

export function PostSourceDisplay() {
    return (
        <div>
            This post was made....
        </div>
    )
}

export function ProviderSourceDisplay(props: any) {
    return (
        <div>
            <FormattedMessage
                id="post.info.organicResultsInfo"
                defaultMessage="ORGANIC results (free of charge)"
            />
        </div>
    )
}

const typeStandard = <FormattedMessage
    id="post.info.typeStandard"
    defaultMessage="Typ Standard"
/>

const typeAOA = <FormattedMessage
    id="post.info.typeAOA"
    defaultMessage="Typ AOA"
/>

const typeLeadAds = <FormattedMessage
    id="post.info.typeLeadAds"
    defaultMessage="Typ Lead Ads"
/>

const adTypeRemarketing = <FormattedMessage
    id="post.info.adType.Remarketing"
    defaultMessage="Remarketing"
/>

const adTypeRequireInterests = <FormattedMessage
    id="post.info.adType.RequireInterests"
    defaultMessage="Require Interests"
/>

const adTypeAiAudience = <FormattedMessage
    id="post.info.adType.AiAudience"
    defaultMessage="AI Audience"
/>

//(query.sizeIndex >= 2) ? (KeywordObjectByAttributeName[item?.special_type]?.displayName || "") : (KeywordObjectByAttributeName[item?.special_type]?.shortDisplayName || "")

export function AdSourceAndCategoryDisplay(props: { ad: any }) {

    const keywords = useSelectorWithParams([AllAdsAndRemarkettingKeywords])
    const {post} = usePost();

    const specialCategory: null | ReactNode = useMemo(() => {
        if (!props.ad) return null;
        if (!props.ad.special_type) return null;
        if (props.ad.special_type === "none") return KeywordObjectByAttributeName["initial_keyword"]?.displayName
        return KeywordObjectByAttributeName[props.ad.special_type]?.displayName || null;
    }, [props.ad])


    const prefix = useMemo(() => {
        if (!post?.type) return "";
        if (post.type === "post") return typeStandard;
        if (post.type === "AOA" || post.type === "aoa") return typeAOA;
        if (post.type === "leadAds" || post.type === "leadAd" || post.type === "leadads") return typeLeadAds;
        return "";
    }, [post])

    const type: null | ReactNode = useMemo(() => {
        if (!props.ad) return null;
        if (!props.ad.special_type) return "";
        if (props.ad.special_type === "none") return ""

        const kwd = KeywordObjectByAttributeName[props.ad.special_type];
        return kwd?.shortDisplayName || props.ad.special_type;
    }, [props.ad])

    const adType = useMemo(() => {
        if (!props.ad) return null;
        if (props.ad.remarketing) return adTypeRemarketing;
        if (props.ad.require_interests) return adTypeRequireInterests;
        return adTypeAiAudience;
    }, [props.ad, post])

    const keywordUsed = useMemo(() => {

        try {
            if (!props.ad) return null;
            if (props.ad.triggered_by) return props.ad.triggered_by;
    
            const propertyName = KeywordObjectByAttributeName[props.ad.special_type]?.name;
    
            if (propertyName) {
                const keyword = keywords?.initial_keywords[propertyName]
                if (keyword) return keyword;
            }
    
            return null;
        } catch (error) {
            console.log(error)
        }
        return null;

    }, [props.ad, keywords])

    const source: null | ReactNode = useMemo(() => {
        if (!props.ad) return null;
        if (!props.ad.source) return null;
        if (props.ad.source === "promote_button")
            return (
                <Tooltip
                    title={
                        <FormattedMessage
                            id="post.info.adSummary.creation.manual.tooltip"
                            defaultMessage="This AD was created by clicking the 'Promote' button."
                        />
                    }>
                        <span>
                    <FormattedMessage
                        id="post.info.adSummary.creation.manual"
                        defaultMessage="This AD was created manually"
                    />
                    </span>
                </Tooltip>
            )
        if (props.ad.source !== "unknown")
            return (
                <Tooltip
                    title={
                        <FormattedMessage
                            id="post.info.adSummary.creation.automatic.tooltip"
                            defaultMessage="This AD was created automatically when your keyword was detected in the post."
                        />
                    }>
                        <span>
                    <FormattedMessage
                        id="post.info.adSummary.automatic.manual"
                        defaultMessage="This AD was created automatically"
                    />
                    </span>
                </Tooltip>
            )
        return null;
    }, [props.ad])

    if (!props.ad) return null;
    if (!source && !specialCategory) {
        return null
    }
    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", width: "100%", paddingRight: "40px" }}>
            <div>
                {source}
            </div>
            <div>
                {prefix} {type}: {adType} {keywordUsed ? <b>{keywordUsed}</b> : null}
            </div>
        </div>
    )


}

// {specialCategory} {specialCategoryTag && <b>{specialCategoryTag}</b>}