import { AppConfig } from "modules";
import routes, { APP_PATH } from "modules/forsant/config/routes";
import requirements from "modules/forsant/config/requirements";

export default {
  key: "Forsant",
  path: APP_PATH,
  routes,
  requirements: requirements as any,
  providers: ["facebook"],
} as AppConfig;
