import { Col, Divider, Empty, Row, Switch } from "antd"
import { useEffect, useMemo } from "react"
import FormattedMessage from "components/common/FormattedMessage";
import useAPI from "services/hooks/useAPI"
import useSelectorWithParams from "services/hooks/useSelectorWithParams"
import RelaxSingleField from "tools/relaxForm/RelaxField"
import { campaignSelector, GetCampaigns, UpdateCampaignStatus } from "../state/campaignsSlice"

export type Campaign = {
    id: number; 
    type: string;
    status: string;
    name: string;
}

export type CampaignStats = {
    budget_ammout: number;
    status: string;
    clicks: number;
    ctr: number;
    enagements: number;
    average_cpc: number;
    cost_micros: number;
    impressions: number;
    active_view_impressions: number;
    interactions: number;
}

type CampaignProps = {
    campaign: Campaign;
}

export const Campaign = (props: CampaignProps) => {

    return (
        <>
        <Row>
            <Col sm={24} md={22}>
            <h3>{props?.campaign?.name}</h3>
            </Col>
            <Col sm={24} md={2}>
            <RelaxSingleField
                name="status"
                callAPI={UpdateCampaignStatus(props.campaign.id)}
                finalizeSubmit={(data:any) => {
                    if (data.status) return ({status: "enabled"});
                    if (data.status === false) return ({status: "paused"});
                }}
                >
                    <Switch
                        checked={props?.campaign?.status === "ENABLED"}
                        checkedChildren={<FormattedMessage id="components.forms.inputs.switch.enabled" defaultMessage="Enabled"/>}
                        unCheckedChildren={<FormattedMessage id="components.forms.inputs.switch.disabled" defaultMessage="Disabled"/>}
                    />
                </RelaxSingleField>
            </Col>
        </Row>
        </>
    )

}

export default function ManageCampaigns() {

    const {data, StatusDisplay} = useAPI(GetCampaigns, true)

    const campaigns = useSelectorWithParams([campaignSelector]);

    useEffect(() => {
        console.log(`campaigns`, campaigns);
    }, [campaigns]);

    const mappedCampaigns = useMemo(() => {
        if (!campaigns) return StatusDisplay;
        if (campaigns.length === 0) {
            return (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={(
                <FormattedMessage 
                id="apps.forsant.containers.manageCampaigns.noCampaigns" 
                defaultMessage="You have no campaigns" />
            )}/>
            );
        }
        return campaigns.map((campaign: Campaign, index: number) => (
            <>
                {index > 0 && <Divider />}
                <Campaign key={campaign.id} campaign={campaign} />
            </>
        ))
    }, [campaigns])

    return (
        <div>
                {campaigns ? mappedCampaigns : StatusDisplay}
        </div>
    )

}