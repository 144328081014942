import React, { Component } from "react";
import OnboardingControls from "providers/onboarding/controls";
import AppDownloadInstructions from "modules/call/components/AppDownloadInstructions";

const YouCanDownloadAppHereOnboarding = () => (
  <>
    <AppDownloadInstructions />
    <OnboardingControls />
  </>
);

export default YouCanDownloadAppHereOnboarding;
