import React, { PureComponent } from "react";
import { LoadingOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Input, Button, Modal } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import FormItem from "_components/forms/form-item-wrapper/form-item-wrapper";
import {
  SET_RESETTED_PASSWORD,
  setResettedPassword,
  GET_HIDDEN_EMAIL,
  getHiddenEmail,
} from "modules/panel/actions/user/password";
import CreateForm from "services/hoc/form-hoc";
import {
  compareToFirstPasswordRule,
  validateToNextPasswordRule,
} from "services/helpers/forms-helpers";
import {
  getUrl,
  TOKEN_PARAMETER,
  LOGIN,
  RESET_PASSWORD,
} from "modules/panel/config/routes";
import { hiddenEmailSelector } from "modules/panel/config/selectors/user";
import {
  EmailEnvelopeIcon,
  PasswordLockIcon,
} from "modules/panel/components/ft-icons";
import StandardButton from "components/common/StandardButton";

const { confirm } = Modal;

class SetResettedPasswordForm extends PureComponent {
  componentDidMount() {
    this.props.getHiddenEmail.exec(this.props.match.params[TOKEN_PARAMETER]);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.getHiddenEmail.status.error !==
      this.props.getHiddenEmail.status.error
    ) {
      confirm({
        title: (
          <FormattedMessage
            id="panel.containers.resetPassword.set.email.wrongTokenModal.heading"
            defaultMessage="This link is expired"
          />
        ),
        content: (
          <FormattedMessage
            id="panel.containers.resetPassword.set.email.wrongTokenModal.description"
            defaultMessage="You can generate it once again."
          />
        ),
        centered: true,
        okText: (
          <FormattedMessage
            id="panel.containers.resetPassword.set.email.wrongTokenModal.tryAgain"
            defaultMessage="Try again"
          />
        ),
        cancelText: (
          <FormattedMessage
            id="panel.containers.resetPassword.set.email.wrongTokenModal.iRememberPassword"
            defaultMessage="I remember my password"
          />
        ),
        closable: false,
        onOk: () => {
          this.props.history.push(getUrl(RESET_PASSWORD));
        },
        onCancel: () => {
          this.props.history.push(getUrl(LOGIN));
        },
      });
    }
  }

  render() {
    return (
      <Form onSubmit={this.props.form.handleSubmit}>
        <Form.Item
          field="email"
          label={
            <FormattedMessage
              id="panel.containers.resetPassword.set.email"
              defaultMessage="Your email"
            />
          }
          disabled
          validateStatus={!this.props.hiddenEmail ? "error" : "success"}
        >
          <Input
            disabled
            value={this.props.hiddenEmail}
            type="email"
            suffix={
              !this.props.hiddenEmail ? (
                <LoadingOutlined />
              ) : (
                <EmailEnvelopeIcon />
              )
            }
          />
        </Form.Item>

        <FormItem
          field="password"
          required={true}
          validator="password"
          hasFeedback={true}
          showHelp={false}
          label={
            <FormattedMessage
              id="panel.containers.register.password"
              defaultMessage="Password"
            />
          }
          form={this.props.form}
          showValidatorResults={true}
        >
          <Input type="password" suffix={<PasswordLockIcon />} />
        </FormItem>

        <FormItem
          field="password_confirmation"
          required={true}
          rules={[
            compareToFirstPasswordRule(this.props.form.instance, "password"),
          ]}
          validator="password"
          label={
            <FormattedMessage
              id="panel.containers.resetPassword.set.passwordConfirmation"
              defaultMessage="Your password confirmation"
            />
          }
          form={this.props.form}
        >
          <Input type="password" suffix={<PasswordLockIcon />} />
        </FormItem>
        <StandardButton
          type="primary"
          htmlType="submit"
          loading={this.props.form.status.pending}
        >
          <FormattedMessage
            id="panel.containers.resetPassword.set.requestBtn"
            defaultMessage="Set new password"
          />
        </StandardButton>
      </Form>
    );
  }
}

export default CreateForm({
  watch: {
    getHiddenEmail: {
      action: GET_HIDDEN_EMAIL,
      dispatch: (dispatch) => {
        return (token) => dispatch(getHiddenEmail(token));
      },
    },
  },
  form: {
    dispatch: setResettedPassword,
    action: SET_RESETTED_PASSWORD,
    redirectOnSuccess: () => getUrl(LOGIN),
    parseValuesBeforeSend: (values, props) => {
      values.token = props.match.params[TOKEN_PARAMETER];
      return values;
    },
  },
  redux: {
    mapStateToProps: (state) => {
      return {
        hiddenEmail: hiddenEmailSelector(state),
      };
    },
  },
  withRouter: true,
})(SetResettedPasswordForm);
