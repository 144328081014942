import FormattedMessage from "components/common/FormattedMessage";

type Address = {
    company_name: string;
    name: string;
    company_begin_date: string;
    country: string;
    nip: string;
    vat_number: string;
    regon: string;
    address: string;
    street: string;
    street_number: string;
    street_apartment: string;
    zip_code: string;
    postal_code: string;
    city: string;
    voivodeship: string;
};

type AddressLabel = {
    [K in keyof Address]: React.ReactNode;
};

const addressLabels: AddressLabel = {
    company_name: <FormattedMessage id="components.displays.address.companyName" defaultMessage="Company name" />,
    name: <FormattedMessage id="components.displays.address.name" defaultMessage="Name" />,
    company_begin_date: <FormattedMessage id="components.displays.address.companyBeginDate" defaultMessage="Company begin date" />,
    country: <FormattedMessage id="components.displays.address.country" defaultMessage="Country" />,
    nip: <FormattedMessage id="components.displays.address.nip" defaultMessage="NIP" />,
    vat_number: <FormattedMessage id="components.displays.address.vatNumber" defaultMessage="VAT number" />,
    regon: <FormattedMessage id="components.displays.address.regon" defaultMessage="REGON" />,
    address: <FormattedMessage id="components.displays.address.address" defaultMessage="Address" />,
    street: <FormattedMessage id="components.displays.address.street" defaultMessage="Street" />,
    street_number: <FormattedMessage id="components.displays.address.streetNumber" defaultMessage="Street number" />,
    street_apartment: <FormattedMessage id="components.displays.address.streetApartment" defaultMessage="Street apartment" />,
    zip_code: <FormattedMessage id="components.displays.address.zipCode" defaultMessage="Zip code" />,
    postal_code: <FormattedMessage id="components.displays.address.postalCode" defaultMessage="Postal code" />,
    city: <FormattedMessage id="components.displays.address.city" defaultMessage="City" />,
    voivodeship: <FormattedMessage id="components.displays.address.voivodeship" defaultMessage="Voivodeship" />,
}

function AddressDisplay(props: { address: Partial<Address>}) {
    if (!props.address) return null;

    const filteredAddress = Object.keys(props.address)
        .filter(key => key in addressLabels)
        .reduce((obj, key) => {
            obj[key] = props.address[key];
            return obj;
        }, {} as Partial<Address>);
        
    return (
        <div>
            {Object.keys(filteredAddress).map((key) => {
                const label = addressLabels[key as keyof Address];
                const value = filteredAddress[key as keyof Address];
                return (
                    <div>
                        <b>{label}</b>: {value}
                    </div>
                )
            })}
        </div>
    )
}

export default AddressDisplay;