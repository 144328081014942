import { useEffect, useMemo } from "react";
import { useCalendar } from "./Calendar";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { filteredPostsAsArraySelector } from "modules/posts/state/postsSlice";
import dayjs from "dayjs";
import { filteredCreationsAsArraySelector } from "tools/creationEditor/creationsSlice";

const CalendarUpcoming = () => {

    const {entries, EntryComponent, range} = useCalendar();

   // const creationsInRange = useSelectorWithParams
    const postsInRange = useSelectorWithParams(filteredPostsAsArraySelector({
        from: range?.[0],
    }))

    const creationsInRange = useSelectorWithParams(filteredCreationsAsArraySelector({
        statusFilter: {
            "removed": false,
            "draft": true, 
            "published": true,
            "scheduled": true,
        },
        sort: "publish_at",
        from: range?.[0],
    }));



    const mappedEntries = useMemo(() => {

        const posts = (postsInRange || []).map((p:any) => ({...p, entryType: "post", _filterBy: p.created_at}));
        const creations = (creationsInRange || []).map((c:any) => ({...c, entryType: "creation", _filterBy: c.publish_at}));

        const allEntries = [...posts, ...creations].sort((a:any, b:any) => dayjs(a._filterBy).isBefore(dayjs(b._filterBy)) ? -1 : 1);

        return (allEntries || []).map((entry, index) => {
            if (!entry) return null;
            if (!EntryComponent) return null;
            return <EntryComponent key={entry.id || entry.localId || `entry_${index}`} entry={entry} mode="upcoming" />
        })
    }, [postsInRange]);

    return (
        <div className="calendar-upcoming">
            <div className="calendar-upcoming-header">
                Upcoming
            </div>
            <div className="calendar-upcoming-wrapper">
                <div className="calendar-upcoming-entries">
                    {mappedEntries}
                </div>
            </div>
        </div>
    )
}

export default CalendarUpcoming;