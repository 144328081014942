import useAPI from "services/hooks/useAPI";
import { usePayment } from "../Payment";
import { useEffect, useState } from "react";
import { PricingPlanLocalStorage } from "services/helpers/local-storage";
import { SubscriptionOffer } from "state/subscriptions/subscriptionPricingHelpers";
import { notification } from "antd";
import AwesomeIcon from "components/common/AwesomeIcon";
import { faSmile } from "@fortawesome/pro-light-svg-icons";

type PromoCodeCheckProps = {
    promoCode: string | undefined,
    setPromoCode: (promoCode: string) => void,
    setIsPromoCodeValid: (isPromoCodeValid: boolean) => void,
    offer: SubscriptionOffer | null | undefined,
}

function usePromoCodeCheck(props:PromoCodeCheckProps) {

    const {promoCode, setPromoCode, setIsPromoCodeValid, offer} = props;
    const [discount, setDiscount] = useState<{
        discount: number,
        offerPrice: number,
    } | null>(null);

    useEffect(() => {
        const ls = PricingPlanLocalStorage.get();
        if (ls && ls.promoCode) checkPromoCode(ls.promoCode);
    }, []);

    
    useEffect(() => {
        if (!promoCode) return;
        if (promoCode.length === 0) return;
        if (!offer) return;
        checkCodeInBackground();
    }, [offer]);



    const promoCodeCheckCall = useAPI({
        url: ({ getApiUrl }) => getApiUrl("user/subscription/coupon"),
        method: "POST",
        customNormalizer: (data) => data
    })

    const checkCodeInBackground = async () => {

        if (!offer) return;
        if (!promoCode) return;
        const codeCheck = await promoCodeCheckCall.call({
            body: {
                offer_id: offer.id,
                promo_code: promoCode
            },
        })


        if (codeCheck && codeCheck.status === 200) {
            
            notification.open({
                key: "coupon-success",
                icon: <AwesomeIcon icon={faSmile} color="green" />,
                message: "Coupon applied!",
                description: "Coupon applied successfully!",
                duration: 5,
            })

            try {
                setDiscount({
                    discount: codeCheck.data.data.prices.discount,
                    offerPrice: codeCheck.data.data.prices.offerPrice,
                });
            } catch (e) {
                console.warn("Error setting discount", e);
            }
            setIsPromoCodeValid(true);
            return true;
        } else {
            setIsPromoCodeValid(false);
            setDiscount(null);
            setPromoCode("");
            return false;
        }

    }

    const checkPromoCode = async (newCode:string) => {
        setPromoCode(newCode);

        if (!offer) return;
        if (!newCode) return;
        const codeCheck = await promoCodeCheckCall.call({
            body: {
                offer_id: offer.id,
                promo_code: newCode
            },
        })


        if (codeCheck && codeCheck.status === 200) {
            
            notification.open({
                key: "coupon-success",
                icon: <AwesomeIcon icon={faSmile} color="green" />,
                message: "Coupon applied!",
                description: "Coupon applied successfully!",
                duration: 5,
            })

            try {
                setDiscount({
                    discount: codeCheck.data.data.prices.discount,
                    offerPrice: codeCheck.data.data.prices.offerPrice,
                });
            } catch (e) {
                console.warn("Error setting discount", e);
            }
            setIsPromoCodeValid(true);
            return true;
        } else {
            setIsPromoCodeValid(false);
            return false;
        }

    }

    const clear = () => {
        promoCodeCheckCall.clear();
        setPromoCode("");
        setIsPromoCodeValid(false);
        setDiscount(null);
    }

    return {
        checkPromoCode,
        discount,
        loading: promoCodeCheckCall.loading,
        error: promoCodeCheckCall.error,
        clear: clear,
    }

}

export default usePromoCodeCheck;