import { Input } from "antd";
import { useEffect, useState } from "react";
import FormattedMessage from "components/common/FormattedMessage";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import MultiSelectCheckbox from "_components/forms/multiselect-checbox";
import Switch from "_components/forms/switch";
import OpeningHoursNew from "../components/OpeningHours";
import { websiteObjectSlice, GetWebsiteObject, ProjectWebsiteObjectSelector } from "../reducers/websiteObjectSlice";


const optionsAgeLimits = [
    {
        value: "more_then_25",
        label: (
            <FormattedMessage
                id="apps.websites.forms.residenceRules.ageLimits.25"
                defaultMessage="25 and up"
            />
        )
    },
    {
        value: "more_then_35",
        label: (
            <FormattedMessage
                id="apps.websites.forms.residenceRules.ageLimits.35"
                defaultMessage="35 and up"
            />
        )
    }, {
        value: "more_then_45",
        label: (
            <FormattedMessage
                id="apps.websites.forms.residenceRules.ageLimits.45"
                defaultMessage="45 and up."
            />
        )
    },
    {
        value: "no_limit",
        label: (
            <FormattedMessage
                id="apps.websites.forms.residenceRules.ageLimits.noLimits"
                defaultMessage="There are no age limits."
            />
        )
    },
]

const optionsSmoking = [
    {
        value: "smoking_not_allowed",
        label: (
            <FormattedMessage
                id="apps.websites.forms.residenceRules.smoking.forbidden"
                defaultMessage="Smoking is forbidden"
            />
        )
    },
    {
        value: "smoking_allowed",
        label: (
            <FormattedMessage
                id="apps.websites.forms.residenceRules.smoking.allowed"
                defaultMessage="Smoking is allowed"
            />
        )
    }, {
        value: "drugs_not_allowed",
        label: (
            <FormattedMessage
                id="apps.websites.forms.residenceRules.smoking.noDrugs"
                defaultMessage="Useage of any drugs is stricly forbidden."
            />
        )
    }
]

const optionsPartying = [
    {
        value: "party_not_allowed",
        label: (
            <FormattedMessage
                id="apps.websites.forms.residenceRules.partying.forbidden"
                defaultMessage="Partying is forbidden"
            />
        )
    },
    {
        value: "party_allowed",
        label: (
            <FormattedMessage
                id="apps.websites.forms.residenceRules.partying.allowed"
                defaultMessage="Partying is allowed"
            />
        )
    }
]


const optionsAnimals = [
    {
        value: "animals_not_allowed",
        label: (
            <FormattedMessage
                id="apps.websites.forms.residenceRules.animals.forbidden"
                defaultMessage="Pets are not accepted."
            />
        )
    },
    {
        value: "animals_allowed",
        label: (
            <FormattedMessage
                id="apps.websites.forms.residenceRules.animals.allowed"
                defaultMessage="Pets are allowed."
            />
        )
    }, {
        value: "animals_allowed_with_payment",
        label: (
            <FormattedMessage
                id="apps.websites.forms.residenceRules.animlas.fee"
                defaultMessage="Pets are welcome but require and additional fee"
            />
        )
    }
]

const optionsChildren = [
    {
        value: "without_children",
        label: (
            <FormattedMessage
                id="apps.websites.forms.residenceRules.children.withoutChildren"
                defaultMessage="Children are not allowed."
            />
        )
    },
    {
        value: "with_children_with_beds",
        label: (
            <FormattedMessage
                id="apps.websites.forms.residenceRules.children.withBeds"
                defaultMessage="Beds for children are available."
            />
        )
    }, {
        value: "children_without_beds",
        label: (
            <FormattedMessage
                id="apps.websites.forms.residenceRules.children.withoutBeds"
                defaultMessage="Beds for children are not available."
            />
        )
    }
]

const optionsCancelation = [
    {
        value: "prepayment_forfeited",
        label: (
            <FormattedMessage
                id="apps.websites.forms.residenceRules.cancelation.prepaymentForfeited"
                defaultMessage="No refunds."
            />
        )
    },
    {
        value: "back_100_before_30_days",
        label: (
            <FormattedMessage
                id="apps.websites.forms.residenceRules.cancelation.100before30"
                defaultMessage="Full refund up to 30 days in advance."
            />
        )
    }, {
        value: "back_50_before_15_days",
        label: (
            <FormattedMessage
                id="apps.websites.forms.residenceRules.cancelation.50before15"
                defaultMessage="50% refund up to 15 days in advance."
            />
        )
    },
    {
        value: "always_refund",
        label: (
            <FormattedMessage
                id="apps.websites.forms.residenceRules.cancelation.fullRefunds"
                defaultMessage="Full refunds."
            />
        )
    }
]

export default function ResidenceRules(props: any) {

    const rules = useSelectorWithParams([ProjectWebsiteObjectSelector])
    const [reception, setReception] = useState<boolean>(false);

    useEffect(() => {
      if (rules && rules.reception247 !== undefined) setReception(rules.reception247)
    }, [rules]);
    

    return (
        <>
            <RelaxMultiform
                submitOnChange
                callAPI={{
                    url: ({getApiUrl, projectId}) => getApiUrl(`projects/${projectId}/websites/object`),
                    method: "PATCH",
                    auth: true,
                    successDispatch: websiteObjectSlice.actions.update
                    //passToDispatcher: {categories: values},
                }}
                initialValuesSelector={ProjectWebsiteObjectSelector}
                //callAPItoGetData={GetWebsiteObject()}
                finalizeSubmit={(values:any) => {

                    return values;

                    let arr = values.workingHours || [];

                    if (values.checkInHours) {
                        arr = arr.concat(values.checkInHours);
                        delete values.checkInHours;
                    }

                    if (values.checkOutHours) {
                        arr = arr.concat(values.checkOutHours);
                        delete values.checkOutHours;
                    }

                    if (values.receptionOpeningHours) {
                        arr = arr.concat(values.receptionOpeningHours);
                        delete values.receptionOpeningHours;
                    }

                    values.workingHours = arr;
                    return values;
                }}
                name="rules">

                <RelaxSingleField
                    name="check_in_hours"
                    finalizeSubmit={(values: any) => {
                        console.log("values",values);
                        return values;
                    }}
                    label={<FormattedMessage
                        id="apps.websites.forms.residenceRules.checkIn"
                        defaultMessage="Check in"
                    />}
                    callAPItoGetData={GetWebsiteObject()}
                >
                    <OpeningHoursNew type="week"  injectPropertyType="check_in_hours"/>
                </RelaxSingleField>

                <RelaxSingleField
                    name="check_out_hours"
                    finalizeSubmit={(values: any) => {
                        return values;
                    }}
                    label={<FormattedMessage
                        id="apps.websites.forms.residenceRules.checkOut"
                        defaultMessage="Check Out"
                    />}
                >
                    <OpeningHoursNew type="week" injectPropertyType="check_out_hours"/>
                </RelaxSingleField>

                <RelaxSingleField
                    name="reception247"
                    label={<FormattedMessage
                        id="apps.websites.forms.residenceRules.reception247"
                        defaultMessage="Online Reception"
                    />}
                >
                    <Switch />
                </RelaxSingleField>

                {(!reception) && (
                    <RelaxSingleField
                    name="reception_hours"
                    finalizeSubmit={(values: any) => {
                        return values;
                    }}
                    label={<FormattedMessage
                        id="apps.websites.forms.residenceRules.reception"
                        defaultMessage="Reception"
                    />}
                >
                    <OpeningHoursNew type="week" injectPropertyType="reception_hours"/>
                </RelaxSingleField>
                )}
                

                <RelaxSingleField
                    name="cancel_reservation"
                    label={<FormattedMessage
                        id="apps.websites.forms.residenceRules.cancelationPolicy"
                        defaultMessage="Cancelation Policy"
                    />}
                    finalizeSubmit={(values: any) => ({cancel_reservation: values.cancel_reservation[0] || ""})}
                >
                    <MultiSelectCheckbox 
                    min={1}
                    max={1}
                    options={optionsCancelation}/>
                </RelaxSingleField>

                <RelaxSingleField
                    name="kids"
                    label={<FormattedMessage
                        id="apps.websites.forms.residenceRules.kids"
                        defaultMessage="Kids"
                    />}
                    finalizeSubmit={(values: any) => ({kids: values.kids[0] || ""})}

                >
                                        <MultiSelectCheckbox 
                    min={1}
                    max={1}
                    options={optionsChildren}/>
                </RelaxSingleField>


                <RelaxSingleField
                    name="age_limits"
                    label={<FormattedMessage
                        id="apps.websites.forms.residenceRules.ageLimits"
                        defaultMessage="Age Limits"
                    />}
                    finalizeSubmit={(values: any) => ({age_limits: values.age_limits[0] || ""})}

                >
                    <MultiSelectCheckbox 
                    min={1}
                    max={1}
                    options={optionsAgeLimits}/>
                </RelaxSingleField>

                <RelaxSingleField
                    name="smoking"
                    label={<FormattedMessage
                        id="apps.websites.forms.residenceRules.ageLimits"
                        defaultMessage="Age Limits"
                    />}
                    finalizeSubmit={(values: any) => ({smoking: values.smoking[0] || ""})}

                >
                    <MultiSelectCheckbox 
                    min={1}
                    max={1}
                    options={optionsSmoking}/>
                </RelaxSingleField>

                <RelaxSingleField
                    name="party"
                    label={<FormattedMessage
                        id="apps.websites.forms.residenceRules.party"
                        defaultMessage="Partying"
                    />}
                    finalizeSubmit={(values: any) => ({party: values.party[0] || ""})}

                >
                    <MultiSelectCheckbox 
                    min={1}
                    max={1}
                    options={optionsPartying}/>
                </RelaxSingleField>

                <RelaxSingleField
                    name="CurfewHours"
                    finalizeSubmit={(values: any) => {
                        return values;
                    }}
                    label={<FormattedMessage
                        id="apps.websites.forms.residenceRules.curfew"
                        defaultMessage="Curfew"
                    />}
                >
                    <OpeningHoursNew type="week" injectPropertyType="curfew_hours"/>
                </RelaxSingleField>

                <RelaxSingleField
                    name="animals"
                    label={<FormattedMessage
                        id="apps.websites.forms.residenceRules.pets"
                        defaultMessage="Pets"
                    />}
                    finalizeSubmit={(values: any) => ({animals: values.animals[0] || ""})}

                >
                    <MultiSelectCheckbox 
                    min={1}
                    max={1}
                    options={optionsAnimals}/>
                </RelaxSingleField>
            </RelaxMultiform>
        </>
    )

}