import { TranslatedMessageWithKey } from "components/common/FormattedMessage"
import StandardActorCard from "components/common/StandardActorCard"
import { StandardResult } from "components/common/StandardResults"
import { Title } from "components/common/StandardText"
import { useMemo } from "react"
import useAPI from "services/hooks/useAPI"
import useSelectorWithParams from "services/hooks/useSelectorWithParams"
import { currentProjectSelector } from "state/projects/projectSelectors"

export type Status = {
    id: number,
    subject: string,
    subject_id: number | string,
    status: string,
    message_id: string,
    message: string,
    products?: string[],
    payload?: any;
}

const ProjectStatus = () => {

    const project = useSelectorWithParams(currentProjectSelector);
    const projectStatusCall = useAPI({
        url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/status`),
    }, true)


    const filtered = useMemo(() => {

        if (!projectStatusCall.data) return {
            general: [],
            grouped: {}
        };

        const filtered: Status[] = (projectStatusCall.data as Status[]).filter((status: any) => status.subject === "project")

        // group "filtered" by products

        const general: Status[] = [];
        const grouped: { [key: string]: Status[] } = {}

        filtered.forEach((status: Status) => {
            if (!status.products || status.products.length === 0) {
                general.push(status);
                return;
            }

            status.products.forEach((product: string) => {
                if (!grouped[product]) {
                    grouped[product] = []
                }

                grouped[product].push(status)
            })
        })

        return { general, grouped }

    }, [projectStatusCall.data])

    return (
        <div>
            <div>
                <div style={{padding: "10px"}}>
                    <StandardActorCard actor={project} />
                </div>
                <ol>
                    {filtered.general.map((status: Status) => <li><StandardResult
                        onClick={() => console.log("status", status)}
                        result={status.status}
                        message={
                            <TranslatedMessageWithKey
                                messageId={status.message_id}
                                message={status.message}
                            />}
                    />
                    </li>
                    )}
                </ol>
            </div>
            <div>
                {projectStatusCall.loading || projectStatusCall.error ? projectStatusCall.StatusDisplay :Object.keys(filtered.grouped).map((product: string) => {

                    return (
                        <div>
                            <Title level={2}>{product}</Title>
                            <ol>
                                {filtered.grouped[product].map((status: Status) => <li>
                                    <StandardResult

                                        onClick={() => console.log("status", status)}
                                        result={status.status}
                                        message={
                                            <TranslatedMessageWithKey
                                                messageId={status.message_id}
                                                message={status.message}
                                            />}
                                    />
                                </li>
                                )}
                            </ol>
                        </div>
                    )

                })}
            </div>
        </div>
    )


}

export default ProjectStatus;