import { AppConfig } from "modules";
import routes, { APP_PATH } from "modules/smart/config/routes";
import requirements from "modules/smart/config/requirements";
// import store from "apps/smart/reducers/reducers";

export default {
  key: "Smart",
  path: APP_PATH,
  routes,
  requirements,
  providers: ["facebook"],
  // store,
} as AppConfig;