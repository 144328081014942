import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { RootState } from "services/store/redux-store"

type DisplayIfSelectorProps = {
    selector: (state: RootState) => boolean;
    children?: React.ReactNode;
}

export default function DisplayIfSelector(props: DisplayIfSelectorProps) {
    const visible = useSelectorWithParams(props.selector);

    if (!props.children) return null;
    if (!props.selector) return null;

    return <>{visible && props.children}</>;
}