import { Alert } from "antd";
import { useEffect, useMemo } from "react";
import { usePost } from "../postContext";


const tryToExtractErrors = (error: any):null | string[] => {
    try {
        if (!error) return null;
        if (typeof error === "string") {
            if (error.length < 1) return null;
            return [error]
        };
        if (Array.isArray(error)) return error;
        if (typeof error === "object") return Object.values(error);
        return null;
    } catch (e) {
        console.warn("Error extracting error", e);
        return null;
    }
}

export function AdReviewDisplay (props:any) {


    const adReviews = props?.ad?.generated?.reviews;

    if (!adReviews) return null;
    const reviews = Object.entries(adReviews).map(([key, value]) => {
        return (
            <Alert key={key} message={`${key as string}: ${value as string}`} type="warning" showIcon />
        )
    })

    return (
        <>
        {reviews}
        </>
    )
}

export function ProviderPostErrorDisplay (props:any) {

    const {post} = usePost();

    const errors = useMemo(() => {
        if (!post) return null;
        if (!post.providers) return null;
        if (!post.providers[props.provider]) return null;
        let pp = post.providers[props.provider];
        let e:string[] = [];
        if (pp.error_reason) e = e.concat(tryToExtractErrors(pp.error_reason) || []);
        if (pp.error) e = e.concat(tryToExtractErrors(pp.error) || []);
        if (pp.failed_reason) e = e.concat(tryToExtractErrors(pp.failed_reason) || []);
        if (e.length < 1) return null;
        return e;
    }, [post])

    const mappedErrors = useMemo(() => {
        if (!errors) return null;
        return errors.map((e, i) => <div key={i}>{e}</div>)
    }, [errors])

    if (!errors) return null;

    return (
        <div style={{width: "100%"}}>
        <Alert message={mappedErrors} type="error" showIcon />
        </div>
    )


}


export function AdErrorDisplay (props:any) {


    const errors = useMemo(() => {
        if (!props.ad) return null;
        let e:string[] = [];
        if (props.ad.error_reason) e = e.concat(tryToExtractErrors(props.ad.error_reason) || []);
        if (props.ad.error) e = e.concat(tryToExtractErrors(props.ad.error) || []);
        if (props.ad.failed_reason) e = e.concat(tryToExtractErrors(props.ad.failed_reason) || []);

        if (props.ad?.adset?.error?.error_user_title) {
            e = e.concat(tryToExtractErrors(props.ad.adset.error.error_user_title) || []);
        }

        if (props.ad?.adset?.error?.message) {
            e = e.concat(tryToExtractErrors(props.ad.adset.error.message) || []);
        }

        if (props.ad?.adset?.error?.error?.error_user_title) {
            e = e.concat(tryToExtractErrors(props.ad.adset.error.error.error_user_title) || []);
        }

        if (e.length < 1) return null;
        return e;
    }, [props.ad])

    const mappedErrors = useMemo(() => {
        if (!errors) return null;
        return errors.filter(e => e.length > 0).map((e, i) => <div key={i}>{e}</div>)
    }, [errors])

    if (!errors) return null;

    return (
        <div style={{width: "100%"}}>
        <Alert message={mappedErrors} type="error" showIcon />
        </div>
    )


}

