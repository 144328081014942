import { RSAA } from "redux-api-middleware";

function addProjectIdToRequest({ dispatch, getState }) {
  return (next) => (action) => {
    if (action && action[RSAA] && action[RSAA].attachProjectId) {
      let currentProjectId = getState().projects.get("currentProjectId");

      action[RSAA].body = JSON.stringify({
        ...action[RSAA].body,
        project_id: currentProjectId,
      });

      delete action[RSAA].attachProjectId;
    }

    next(action);
  };
}

export default addProjectIdToRequest;
