import { Input } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import _ from "lodash";
import { useEffect } from "react";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import useTaskMiddleware from "tools/tasks/useTaskMiddleware";
import OrganizationGroupSelect from "../components/OrganizationGroupSelect";
import { OrganizationSelector } from "../data/organizationSlice";
import { useOrganizationTool } from "../OrganizationManager";
import { GoogleMyBusinessCompanyInfoForm_Fields } from "../projectPage/GoogleMyBusinessCompanyInfoForm";
import { InputProjectsDisplay, ListOfProjects } from "./OrganizationToolHelpers"
import useOrganization from "./useOrganization";
import { currentProjectIdSelector } from "services/store/scopeSlice";

//Adds selected projects to the selected group
export const OrganizationToolAddProjectsToGroup = (props:any) => {

    const {inputData, setOutputData, outputData} = useOrganizationTool();
    const {groups} = useOrganization();


    const handleGroupChange = (groupId:string) => {

        const group = groups.find((group:any) => group.id === groupId)
        if (!group) return;

        setOutputData((o:any) => ({...o, group}))
    }

    return (
        <div>
            <InputProjectsDisplay />
            <h3><FormattedMessage 
                id="organization.tool.addProjectsToGroup.selectGroup"
                defaultMessage="Select group"
            /></h3>
            <OrganizationGroupSelect onChange={handleGroupChange} mode="single"/>
        </div>
    )
}

//Creates a new group with the selected projects
export const OrganizationToolNewGroupWithProjects = (props:any) => {

    const {inputData, setOutputData, outputData} = useOrganizationTool();

    return (
        <div>
            <InputProjectsDisplay />
            <h3>Name of the new group</h3>
            <RelaxSingleField
                name="name"
                submitOverride={(data:any) => {setOutputData((o:any) => ({...o, name: data.name}))}}
                >
                <Input />
            </RelaxSingleField>
        </div>
    )


}

//Creates a new group with the selected projects
export const OrganizationToolNewGroupWithoutProjects = (props:any) => {

    const {inputData, setOutputData, outputData} = useOrganizationTool();

    return (
        <div>
            <h3>Name of the new group</h3>
            <RelaxSingleField
                name="name"
                submitOverride={(data:any) => {setOutputData((o:any) => ({...o, name: data.name}))}}
                >
                <Input />
            </RelaxSingleField>
        </div>
    )


}

//Assigns managers to a group
export const OrganizationToolAssignManagersToGroup = (props:any) => {

    const {inputData, setOutputData, outputData} = useOrganizationTool();

    const handleGroupChange = (groupId:string) => {
        setOutputData((o:any) => ({...o, groupId}))
    }


    return (
        <div>
            <h3>Selected Managers</h3>
            <ListOfProjects projects={inputData?.managers} />
            <h3>Select group</h3>
            <OrganizationGroupSelect onChange={handleGroupChange}/>
        </div>
    )


}

//Edit google my business info
export const OrganizationToolEditGoogleMyBusiness = (props:any) => {

    const {inputData, setOutputData, outputData, setInputData} = useOrganizationTool();
    const currentProjectId = useSelectorWithParams(currentProjectIdSelector)
    const organization = useSelectorWithParams(OrganizationSelector)
    const {projects} = useOrganization();



    const getCombinedDataFromAllSelectedProjects = () => {
        const selectedProjects = projects.filter((p:any) => inputData?.projects?.includes(p.id))
        if (selectedProjects.length === 0) return null;
        const normalizedData:any[] = selectedProjects.map((p:any) => p.serviceGoogleMyBusiness?.normalized_object)

        let combinedData:any = {};

        for (let i = 0; i < normalizedData.length; i++) {
          for (let prop in normalizedData[i]) {
            if (!combinedData.hasOwnProperty(prop)) {
                combinedData[prop] = normalizedData[i][prop];
            } else if (combinedData[prop] !== "*" && combinedData[prop] !== normalizedData[i][prop]) {
                combinedData[prop] = "*";
            }
          }
        }
        
        for (let prop in combinedData) {
            for (let i = 0; i < normalizedData.length; i++) {
              if (!normalizedData[i].hasOwnProperty(prop)) {
                combinedData[prop] = "*";
                break;
              }
            }
          }

        return combinedData;


    }

  const {callAPI, combinedData, statusAlert, tryToSelectTask} = useTaskMiddleware({
    dataSelector: () => null,
    adjustData: (data) => {
        const combinedData = getCombinedDataFromAllSelectedProjects();
      return data?.serviceGoogleMyBusiness?.normalized_object
    },
    selectTask: (tasks) => {

     const tasksForSelectedProjects = (tasks || []).filter(t => _.isEqual(t.payload?.projects, inputData?.projects))
     if (!tasksForSelectedProjects || tasksForSelectedProjects.length === 0) return null;

        const pendingTasks = tasksForSelectedProjects.filter(t => t.status === "pending_acceptation");

        if (pendingTasks.length === 0) return null;

        const newestTask = pendingTasks.sort((a, b) => {
            return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
        })[0];

        if (newestTask) return newestTask;

        return null;

    },
    taskTemplate: {
      source_id: currentProjectId,
      source_type: "project",
      type: "data_update", 
      target_type: "organization",
      target_id: organization?.id || 1,
      payload: {
        type: "google_my_business",
        projects: inputData?.projects, 
      }
    },
    combineData: (task, data) => {
      return {
        ...data,
        ...task.payload,
      };
    }
  })

  useEffect(() => {
    tryToSelectTask();
}, [inputData.projects]);

    return (
        <div>
            {statusAlert}
            <InputProjectsDisplay />
            <RelaxMultiform
                name="google_my_business"
                callAPI={callAPI}
                initialValues={combinedData}
                >
            <GoogleMyBusinessCompanyInfoForm_Fields />
            </RelaxMultiform>
        </div>
    )

}