import React from "react";

const FullPageLoader = () => (
  <div id="panel-loader">
    <div className="loader"></div>
    <span className="name">{import.meta.env.VITE_INSTANCE_NAME}</span>
  </div>
);

export default FullPageLoader;
