import React, { FunctionComponent, Suspense } from "react";
import {
  Route as RouterRoute,
  Switch as RouterSwitch,
  Redirect,
  useLocation,
} from "react-router-dom";
import { AppConfig } from "modules";
import Routes from "providers/routing/routes/routes";
import Layouts from "providers/routing/layouts/layouts";
import ErrorBoundary from "providers/routing/error-boundary";
import AuthorizedRoute from "providers/routing/routes/private";
import NotFoundComponent from "providers/routing/routes/not-found";
import { getUrl, LOGIN, DASHBOARD } from "modules/panel/config/routes";
import AppsContext from "providers/routing/apps-context";
import {
  getFlatRoutesPrefixedByApp,
  getLayoutRoutes,
  getPathsArray,
  getPublic,
  getAuthorized,
} from "providers/routing/routes/helper";
import { userIsLoggedSelector } from "modules/panel/config/selectors/user";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { currentFlatSubscriptionPermissionsSelector } from "state/subscriptions/subscriptionsSelectors";

export const NOT_FOUND_URL = "/not-found";

type RoutingProps = {
  apps: AppConfig[];
};

const FallbackRedirect = () => {
  
  const logged = useSelectorWithParams(userIsLoggedSelector);
  const permissions = useSelectorWithParams(currentFlatSubscriptionPermissionsSelector);

  if (!logged) {
    return <Redirect to={getUrl(LOGIN)} />
  }

  if (permissions.length === 0) {
    console.warn("Logged in, but no permissions, redirecting to dashboard")
    return <Redirect to={getUrl(DASHBOARD)} />
  }

  return (
    <Redirect to={getUrl(LOGIN)} />
  )
}

const Routing: FunctionComponent<RoutingProps> = ({ apps }) => {
  const routes = getFlatRoutesPrefixedByApp(apps);


  return (
    // <BrowserRouter>
    <ErrorBoundary>
      <AppsContext apps={apps}>
        <RouterSwitch>
          {Layouts.map(
            ({
              key: layoutKey,
              layout: Layout,
              animation,
              content: contentWrapper,
            }) => {
              const layoutProps = {
                layout: layoutKey,
                wrapper: contentWrapper,
                animation,
              };

              const layoutRoutes = getLayoutRoutes(routes, layoutKey),
                publicRoutes = getPublic(layoutRoutes),
                authorizedRoutes = getAuthorized(layoutRoutes);

              return (
                <RouterRoute
                  key={`Layout${layoutKey}Route`}
                  path={getPathsArray(layoutRoutes)}
                >
                  <Layout>
                    <Suspense fallback={<div>Loading...</div>}>
                      <RouterRoute
                        key={`Layout${layoutKey}PublicRoute`}
                        path={getPathsArray(publicRoutes)}
                      >
                        <Routes routes={publicRoutes} {...layoutProps} />
                      </RouterRoute>
                    </Suspense>
                    <Suspense fallback={<div>Loading...</div>}>
                      <AuthorizedRoute
                        key={`Layout${layoutKey}AuthorizedRoute`}
                        path={getPathsArray(authorizedRoutes)}
                      >
                        <Routes routes={authorizedRoutes} {...layoutProps} />
                      </AuthorizedRoute>
                    </Suspense>
                  </Layout>
                </RouterRoute>
              );
            }
          )}
          <RouterRoute
            key="NotFound"
            path={NOT_FOUND_URL}
            component={NotFoundComponent}
          />
          <FallbackRedirect />
        </RouterSwitch>
      </AppsContext>
    </ErrorBoundary>
    // </BrowserRouter>
  );
};

export default Routing;
