import { fromJS } from "immutable";
import {
  REFRESH_TOKEN,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  LOAD_USER_SUCCESS,
  FACEBOOK_AUTH_SUCCESS,
} from "modules/panel/actions/user/login";

let initialValue = {
  logged: false,
  token: {
    value: null,
    type: null,
  },
};

function loginStatusReducer(state = fromJS(initialValue), action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return state.set(
        "token",
        fromJS({
          value: action.payload.token.token,
          type: action.payload.token.token_type,
        })
      );

    case FACEBOOK_AUTH_SUCCESS:
      return state.set(
        "token",
        fromJS({
          value: action.payload.token.token,
          type: action.payload.token.token_type,
        })
      );

    case LOAD_USER_SUCCESS:
      return state.set("logged", true);

    case REFRESH_TOKEN:
      return state.setIn(["token", "value"], action.payload.token.token);

    case LOGOUT_SUCCESS:
      return state.set("logged", false).set(
        "token",
        fromJS({
          value: null,
          type: null,
        })
      );

    case LOGOUT_FAILURE:
      return state.set("logged", false).set(
        "token",
        fromJS({
          value: null,
          type: null,
        })
      );

    default:
      return state;
  }
}

export default loginStatusReducer;
