import { Alert, Col, Input, Row, Select, Spin } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import RelaxForm from "tools/relaxForm/RelaxForm"
import RelaxField from "tools/relaxForm/RelaxField";
import { GetWebsiteTemplates, ProjectWebsiteColorThemesSelector, ProjectWebsiteTemplatesSelector } from "../reducers/websiteTemplateSlice";
import RelaxControls from "tools/relaxForm/RelaxControls";
import TemplateCustomizationTools from "./WebsiteTemplateCustomization";
import useRelaxData from "tools/relaxForm/useRelaxData";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";
import WebsitePreviewDisplay from "./WebsitePreviewDisplay";
import { HorizontalCenter } from "components/common/Center";
import { UpdateWebsiteTemaplate, UserWebsiteUrl, WebsiteThemeConfigSelector } from "../reducers/websiteSlice";
import useAPI from "services/hooks/useAPI";
import WebsiteColorThemeDisplay from "./WebsiteColorThemeDisplay";
import _ from "lodash";


export default function WebsiteTheme(props: any) {


    const getThemesCall = useAPI(GetWebsiteTemplates(), true);

    const templates = useSelectorWithParams([ProjectWebsiteTemplatesSelector]);
    const colorThemes = useSelectorWithParams([ProjectWebsiteColorThemesSelector]);


    const websiteUrl = useSelectorWithParams([UserWebsiteUrl]);
    const storedConfig = useSelectorWithParams(WebsiteThemeConfigSelector);

    // useEffect(() => {
    //     console.log('templates', templates);
    // }, [templates]);

    // useEffect(() => {
    //     console.log('colorThemes', colorThemes);
    // }, [colorThemes]);

    const rd = useRelaxData({
        callAPI: UpdateWebsiteTemaplate,
        initialValues: storedConfig,
        finalizeSubmit: (data: any) => {

            const newConfig = {
                ...storedConfig,
                ...data,
                template_id: selected?.id,
                template_number: selected?.template_number,
            }

            if (!newConfig.color_theme_id) delete newConfig.color_theme_id;
            if (!newConfig.color_theme) delete newConfig.color_theme;

            return newConfig;

        }
    });
    const previewRef = useRef<any>(null);

    const selectedId = rd.currentValue?.template_id || storedConfig.template_id;

    const selected = useMemo(() => {
        if (!templates) return null;
        return templates.find((template: any) => template.id === selectedId);
    }, [templates, selectedId])

    const matchingColorThemes = useMemo(() => {

        if (!selected) return null;
        if (!colorThemes) return null;
        return colorThemes.filter((ct: any) => ct.template_number === selected.id).map((ct: any) => ({
            value: ct.id,
            label: <WebsiteColorThemeDisplay theme={ct} />
        }));
    }, [selectedId, colorThemes]);


    const isBranded = selected?.branded;
    const hasMatchingColorThemes = matchingColorThemes && matchingColorThemes.length > 0;

    const mappedTemplates = useMemo(() => {
        if (!templates || !templates.length) return [];
        return templates.map((t: any) => {
            return (
                <Select.Option
                    value={t.id}
                >
                    {t.name}
                </Select.Option>)
        })

    }, [templates])

    const getNewPreview = () => {

        const cv = rd.currentValue || {};

        const sel = _.cloneDeep(selected);

        delete sel?.font_header;
        delete sel?.font_text;


        const adjustedPreviewConfig = {
            ...storedConfig,
            ...cv,
            ...sel,
        }

        adjustedPreviewConfig.template_number = sel?.template_number
        adjustedPreviewConfig.template_id = sel?.id;
        adjustedPreviewConfig.template_color_theme = cv.color_theme_id || storedConfig.color_theme_id;

        if (adjustedPreviewConfig.font_header && typeof adjustedPreviewConfig.font_header === "object") {
            adjustedPreviewConfig.font_header = adjustedPreviewConfig.font_header.font;
        }

        if (adjustedPreviewConfig.font_text && typeof adjustedPreviewConfig.font_text === "object") {
            adjustedPreviewConfig.font_text = adjustedPreviewConfig.font_text.font;
        }

        delete adjustedPreviewConfig.color_theme_id;


        previewRef.current?.getPreview && previewRef.current?.getPreview(adjustedPreviewConfig);
    }


    // useEffect(() => {
    // console.log('selected', selected);
    // }, [selected]);

    useEffect(() => {
        getNewPreview();
    }, [rd.currentValue]);

    if (!templates)
        return (
            <HorizontalCenter><Spin /></HorizontalCenter>
        )

    return (
        <>
            <Row gutter={32}>
                <Col lg={8} md={12} sm={24} xs={24}>
                    <RelaxForm
                        relaxData={rd}
                        hideStatus={true}
                        mode="manual"
                        callAPI={{
                            url: `${websiteUrl}/template_preview`,
                            method: "POST",
                        }}
                    >
                        <RelaxField
                            name="template_id"
                            disabled={false}
                        >
                            <Select>
                                {mappedTemplates}
                            </Select>
                        </RelaxField>


                        {hasMatchingColorThemes ? (
                            <RelaxField
                                label="Color Theme"
                                name="color_theme_id">
                                <Select
                                    options={matchingColorThemes}
                                />
                            </RelaxField>
                        ) : (
                            isBranded ?
                                <Alert message="This theme does not support customization" />
                                :
                                <RelaxMultiform
                                    disabled={isBranded}>
                                    <TemplateCustomizationTools />
                                </RelaxMultiform>
                        )


                        }

                    </RelaxForm>
                    <RelaxControls relaxData={rd} />
                </Col>
                <Col lg={16} md={12} sm={24} xs={24}>
                    <div style={{
                        height: "100%",
                        minHeight: "500px",
                        width: "100%",
                        display: "grid",
                        placeItems: "center"
                    }}>
                        <WebsitePreviewDisplay
                            ref={previewRef}
                        />
                    </div>
                </Col>
            </Row>
        </>
    )

}