import { useEffect } from "react";
import { useCalendar } from "./Calendar";
import useAPI from "services/hooks/useAPI";
import { creationSelector, creationsSlice, patchCreation } from "tools/creationEditor/creationsSlice";
import { useStore } from "react-redux";
import _ from "lodash";
import { AskToRescheduleCreation } from "tools/creationEditor/CreationActionModals";
import dayjs, { Dayjs } from 'dayjs';

export default function CalendarCreationObserver () {

    const {drake} = useCalendar();
    const store = useStore()
    const dispatch = store.dispatch;
    const {askToRescheduleCreation, contextHolder} = AskToRescheduleCreation();

    useEffect(() => {

        if (!drake) return;

        drake.on("drop", (el, target, source, sibling) => {

            const entryId = el.getAttribute("data-calendar-entry-index");
            const entryType = el.getAttribute("data-calendar-entry-type");
            const from = source.getAttribute("data-calendar-day-date");
            const to = target.getAttribute("data-calendar-day-date");

            
           // console.log("Dropped-Obsevrer", entryType, entryId, " from ", from, " to ", to)
            if (!entryId || !entryType || !from || !to) return;

            const creation = creationSelector(store.getState(), entryId as any);
            if (!creation) return;

            const plannedDateTime = dayjs(creation.content.publication_date)
            const plannedDate = plannedDateTime.format("YYYY-MM-DD");
            const plannedTime = plannedDateTime.format("HH:mm:ss");
            const targetDate = dayjs(to).format("YYYY-MM-DD");
            const newPlannedDate = dayjs(targetDate + " " + plannedTime).format("YYYY-MM-DD HH:mm:ss");

           // console.log("Creation", creation)
            //console.log("New planned date", newPlannedDate)


            if (!newPlannedDate) return;

            drake && drake.cancel(true)
            const modal = askToRescheduleCreation(creation.id, newPlannedDate, () => {
                el.setAttribute("style", `${el.style.cssText};display: none;`);
            })
            console.log("reschedule modal", modal)

        });

    }, []);



    return (
        <>
        {contextHolder}
        </>
    )

}