import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { callAPIProps } from "services/hooks/useAPI";

export type Notification = {
    id: string;
    title: string;
    message: string;
    type?: string;
}

const exampleNotifications: Notification[] = [
    {
        title: "AI campaign is ready!",
        message: "Your AI campaign is ready."
    },
    {
        title: "Notification 2",
        message: "This is a different notification"
    },
    {
        title: "Notification 3",
        message: "This is a third notification"
    },
]

type NotificationsSliceState = {
    notifications: Record<string, Notification>;
}

const NotificationsSliceInitialState: NotificationsSliceState = {
    notifications: {},
}

const NotificationsSlice = createSlice({
    name: "notificationsSlice",
    initialState: NotificationsSliceInitialState,
    reducers: {
        GetNotifications: (state: NotificationsSliceState, action: PayloadAction<{data:Notification[]}>) => {

          //  console.log("GOT_NOTIFICATIONS", action)

            const notifications = action.payload;
            notifications.data.forEach(notification => {
                state.notifications[notification.id] = notification;
            })
        },
        addNotification: (state: NotificationsSliceState, action: PayloadAction<Notification>) => {

          //  console.log("Add notification", action.payload)

            const { id, title, message } = action.payload;
            state.notifications[id] = { id, title, message };
        },
        removeNotification: (state: NotificationsSliceState, action: PayloadAction<Notification>) => {
            const { id } = action.payload;
            delete state.notifications[id];
        },
        clearNotifications: (state: NotificationsSliceState) => {
            state.notifications = {};
        }
    }
})

export const GetNotifications:callAPIProps = {
    url: ({ getApiUrl, projectId, userId}) => getApiUrl(`users/${userId}/notifications?sort=created_at`),
    successDispatch: NotificationsSlice.actions.GetNotifications,
}

export const NotificationsSliceSelector = createSelector([(state) => state.notificationsSlice], (state) => state);
export const NotificationsSelector = createSelector([NotificationsSliceSelector], (state) => state.notifications);
export const NotificationsAsArraySelector = createSelector([NotificationsSelector], (state) => state ? Object.values(state) : []);
export const NotificationSelector = createSelector([
    NotificationsSelector,
    (state: NotificationsSliceState, id: string) => id,
    ],(state, id) => state[id]
)

export default NotificationsSlice;