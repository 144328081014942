import { RSAA } from "redux-api-middleware";
import { getApiUrl } from "services/helpers/api-helpers";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_REQUEST = `${RESET_PASSWORD}_REQUEST`;
export const RESET_PASSWORD_SUCCESS = `${RESET_PASSWORD}_SUCCESS`;
export const RESET_PASSWORD_FAILURE = `${RESET_PASSWORD}_FAILURE`;

export const resetPassword = (formValues) => {
  return {
    [RSAA]: {
      endpoint: getApiUrl("user/password-token"),
      method: "POST",
      types: [
        RESET_PASSWORD_REQUEST,
        RESET_PASSWORD_SUCCESS,
        RESET_PASSWORD_FAILURE,
      ],
      body: JSON.stringify(formValues),
    },
  };
};

export const SET_RESETTED_PASSWORD = "SET_RESETED_PASSWORD";
export const SET_RESETTED_PASSWORD_REQUEST = `${SET_RESETTED_PASSWORD}_REQUEST`;
export const SET_RESETTED_PASSWORD_SUCCESS = `${SET_RESETTED_PASSWORD}_SUCCESS`;
export const SET_RESETTED_PASSWORD_FAILURE = `${SET_RESETTED_PASSWORD}_FAILURE`;

export const setResettedPassword = (formValues) => {
  return {
    [RSAA]: {
      endpoint: getApiUrl("user/password-reset"),
      method: "POST",
      types: [
        SET_RESETTED_PASSWORD_REQUEST,
        SET_RESETTED_PASSWORD_SUCCESS,
        SET_RESETTED_PASSWORD_FAILURE,
      ],
      body: JSON.stringify(formValues),
    },
  };
};

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_REQUEST = `${CHANGE_PASSWORD}_REQUEST`;
export const CHANGE_PASSWORD_SUCCESS = `${CHANGE_PASSWORD}_SUCCESS`;
export const CHANGE_PASSWORD_FAILURE = `${CHANGE_PASSWORD}_FAILURE`;

export const changePassword = (formValues) => {
  return {
    [RSAA]: {
      endpoint: getApiUrl("user/password-change"),
      method: "POST",
      authorized: true,
      types: [
        CHANGE_PASSWORD_REQUEST,
        CHANGE_PASSWORD_SUCCESS,
        CHANGE_PASSWORD_FAILURE,
      ],
      body: JSON.stringify(formValues),
    },
  };
};

export const GET_HIDDEN_EMAIL = "GET_HIDDEN_EMAIL";
export const GET_HIDDEN_EMAIL_REQUEST = `${GET_HIDDEN_EMAIL}_REQUEST`;
export const GET_HIDDEN_EMAIL_SUCCESS = `${GET_HIDDEN_EMAIL}_SUCCESS`;
export const GET_HIDDEN_EMAIL_FAILURE = `${GET_HIDDEN_EMAIL}_FAILURE`;

export const getHiddenEmail = (formValues) => {
  return {
    [RSAA]: {
      endpoint:  getApiUrl("user/hide-mail"),
      method: "POST",
      authorized: true,
      types: [
        GET_HIDDEN_EMAIL_REQUEST,
        GET_HIDDEN_EMAIL_SUCCESS,
        GET_HIDDEN_EMAIL_FAILURE,
      ],
      body: JSON.stringify({
        token: formValues,
      }),
    },
  };
};
