
import { useCallback, useEffect, useMemo, useRef } from "react"
import { AllowedTools, GalleryItem, GalleryTool, useGallery } from "./Gallery"
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Tooltip } from "antd"
import GalleryItemDisplay from "./GalleryItemDisplay"
import AwesomeIcon, { AwesomeClose, AwesomeOk } from "components/common/AwesomeIcon";
import { faArrowsUpDownLeftRight, faCamera, faCrop, faTrash } from "@fortawesome/pro-light-svg-icons";
import './image-gallery.less'

export type GalleryElementProps = {
    item: GalleryItem,
    isSelected?: boolean,
    id: any
}

export default function GalleryElement(props: GalleryElementProps) {

    const { allowedTools, cropPresets, requireCrop, showItemTooltip, deleteItem, cropItem, selectItem} = useGallery();
    const imageRef = useRef<HTMLImageElement>(null);

    const displayAsSelected = useMemo(() => {
        if (allowedTools && allowedTools.includes("select")) {
            return props.isSelected
        } else {
            return true;
        }
    }, [allowedTools, props.isSelected]);

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: props.id });

    

    const image = useMemo(() => {

        return (
            <div style={{width: "100%", height: "100%", display: "grid", placeItems: "center"}}>
                <div style={{position: "absolute", height: "100%", width: "100%", display: "grid", placeItems: "center"}}>
                {props?.item?.type === "video" ? <AwesomeIcon icon={faCamera} /> : null}
                </div>
                <GalleryItemDisplay item={props.item} />
            </div>
        )


        // if (props?.item?.type === "image") {
        //     const url = props?.item?.thumbnails?.[500] || props?.item?.url;
        //     if (url) return (
        //         <img
        //             className={`image-gallery-element-image ${!displayAsSelected && "not-selected"}`}
        //             src={url}
        //             ref={imageRef}
        //             style={{
        //                 maxHeight: "100%",
        //                 maxWidth: "100%"
        //             }}
        //             onLoad={(e) => {
        //                 //console.log("LOAD _ e", e);
        //                 //console.log("LOAD _ Image loaded", imageRef.current?.naturalWidth, imageRef.current?.naturalHeight);
        //                 (props.item as any).width = (e.target as any).naturalWidth;
        //                 (props.item as any).height = (e.target as any).naturalHeight;
        //             }}
        //         />
        //     )
        // }
        // if (props?.item?.type === "video") {
        //     return (
        //         <div style={{width: "100%", height: "100%", display: "grid", placeItems: "center"}}>
        //             <div style={{position: "absolute", height: "100%", width: "100%", display: "grid", placeItems: "center"}}>
        //             <AwesomeIcon icon={faCamera} />
        //             </div>
        //             <GalleryItemDisplay item={props.item} />
        //         </div>
        //     )
        // }
        // return <div>Error</div>
    }, [props.item, props.isSelected])

    const toolSelect = (slot: number) => (
        <div
            onClick={() => selectItem && selectItem(props.item)}
            className={`tool area${slot}`}
        >
            {props.isSelected ? <AwesomeClose color="white"/> : <AwesomeOk color="white"/>}
        </div>
    )


    const toolCrop = (slot: string) => {
        if (props.item.type === "video") return null;
        return (
        <div
            onClick={() => cropItem!(props.item)}
            className={`tool area${slot}`}
        >
            <AwesomeIcon icon={faCrop} color="white"/>
        </div>
    )}

    const toolDelete = (slot: string) => (
        <div
            onClick={() => deleteItem!(props.item)}
            className={`tool area${slot}`}
        >
            <AwesomeIcon icon={faTrash} color="white"/>
        </div>
    )

    const toolDrag = (slot: string) => (
        <div
            className={`tool area${slot}`}
            {...listeners}
            {...attributes}
        >
            <AwesomeIcon icon={faArrowsUpDownLeftRight} color="white"/>
        </div>
    )

    const toolMap: Record<GalleryTool, any> = {
        "crop": toolCrop,
        "delete": toolDelete,
        "drag": toolDrag,
        "select": toolSelect
    }

    const tools = useMemo(() => {
        const t: any[] = [];
        if (!allowedTools || !allowedTools.length) return t;

        allowedTools.forEach((tool, i) => {
            t.push(toolMap[tool](i + 1));
        })

        return (
            <div
                className={`tools t${t.length}`}
            >
                {t}
            </div>
        );
    }, [allowedTools, props.isSelected, props.item])

    const cropBadges = () => {

        if ((props.item as any).crop) {
            return (<div className="crop-badge">
            <AwesomeIcon icon={faCrop} color="green"/>
            </div>)
        }
 
    }

    const selectedBadge = useMemo(() => {
        if (!props.isSelected) return;
        return (
            <div className="selection-badge">
                <AwesomeOk color="green"/>
            </div>
        )
    }, [allowedTools, props.isSelected])

    const errorStyle = {
        borderRadius: "21px",
        borderWidth: "2px",
        borderColor: "#e80000",
        borderStyle: "solid",
    }

    const validStyle = {
        borderRadius: "21px",
        borderWidth: "2px",
        borderColor: "#3d61ef",
        borderStyle: "solid",
    }

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const styleWithError = {
        ...style,
        ...errorStyle,
    }

    const styleWithNoError = {
        ...style,
        ...validStyle,
    }

    const newTools = () => {
        return null;
        return (
            <div className="newTools">
                CROP
                </div>
        )
    }

    const getStyle = () => {


        if (props.item.type === "image") {
            if (!requireCrop) return style;
            const imgFile = props.item as any;
            if (!imgFile.crop) return styleWithError;
            let missingCrop = false;
            const platforms = Object.keys(cropPresets || {});
            const crops = Object.keys(imgFile.crop);

            if (platforms.length !== crops.length) {
                missingCrop = true;
            }
    
            if (missingCrop) {
                return styleWithError
            } else {
                return styleWithNoError
            }
        }

        return style
       
    }

    if (showItemTooltip) {
        return (
            <Tooltip
                title={props?.item?.fileName || props?.item?.url}>
            <div 
                style={getStyle()}
                ref={setNodeRef} >
                <div
                    className="element"
                // style={getItemStyle(
                //     snapshot.isDragging,
                //     provided.draggableProps.style
                //   )}
                >
                    {image}
                    {selectedBadge}
                    {cropBadges()}
                    {tools}
                    {newTools()}
                </div>
            </div>
            </Tooltip>
        );
    }

    return (
        <div 
            style={getStyle()}
            ref={setNodeRef} >
            <div
                className="element"
            // style={getItemStyle(
            //     snapshot.isDragging,
            //     provided.draggableProps.style
            //   )}
            >
                {image}
                {selectedBadge}
                {cropBadges()}
                {tools}
                {newTools()}
            </div>
        </div>
    );


}