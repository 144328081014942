import FormattedMessage from "components/common/FormattedMessage";
import StandardButton from "components/common/StandardButton";
import { Title } from "components/common/StandardText";
import { DeleteProjectButton } from "modules/panel/components/subscriptions/buy-product/DeleteProjectPopup";
import ProjectIntegrationDisplay from "providers/routing/layouts/templates/panel/top-bar/project-select/ProjectIntegrationDisplay";
import useScope from "services/hooks/useScope";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { projectWithSubscriptionsSelector } from "state/projects/projectsSelectors";
import ProjectSubscriptionsWithManager from "./ProjectSubscriptionsWithManager";
import AwesomeIcon from "components/common/AwesomeIcon";
import { faGear, faStore } from "@fortawesome/pro-light-svg-icons";
import { getUrl, PRODUCTS } from "modules/panel/config/routes";
import { Link } from "react-router-dom";
import { currentProjectIdSelector } from "services/store/scopeSlice";
import StandardDivider from "components/common/Divider/StandardDivider";
import { unattachedSubscriptionsSelector } from "state/subscriptions/subscriptionsSelectors";
import { useToolbar } from "components/toolbar/Toolbar";
import { Badge } from "antd";


type ProjectListItemProps = {
  projectId: string;
  manage?: boolean;
  setManage?: (mng: boolean) => void;
};

const VisitStoreButton = () => {
  return (
    <div style={{
      display: "grid",
      placeItems: "center",
      border: "2px solid var(--border-color-base)",
      borderRadius: "10px",
      color: "#293a40",
      height: "40px",
      padding: "0px 10px",
      cursor: "pointer"
    }}>
      <Link to={getUrl(PRODUCTS)} style={{ color: "black" }}>
        <AwesomeIcon icon={faStore} size="xl" />
        <FormattedMessage
          id="common.visitTheStore"
          defaultMessage="Visit the store!"
        />
      </Link>
    </div>
  )
}

type ManageSubscriptionsButtonProps = {
  manage: boolean,
  setManage: (mng: boolean) => void
}


const ManageSubscriptionsButton = (props: ManageSubscriptionsButtonProps) => {

  const unattachedSubscriptions = useSelectorWithParams(unattachedSubscriptionsSelector);
  const { mode } = useToolbar();


  return (
    <Badge
      count={unattachedSubscriptions?.length}
    >
              <button style={{
                display: "grid",
                placeItems: "center",
                border: "2px solid var(--border-color-base)",
                borderRadius: "10px",
                color: "#293a40",
                height: "40px",
                padding: "0px 10px",
                cursor: "pointer"
              }}>
                <div onClick={() => props.setManage && props.setManage(!props.manage)}>
                  <AwesomeIcon icon={faGear} size="xl" />
                  {props.manage ? (
                    <FormattedMessage
                      id="panel.components.project.list.manage.close"
                      defaultMessage="Close"
                    />
                  ) : (
                    <FormattedMessage
                      id="panel.components.project.list.manage"
                      defaultMessage="Manage"
                    />
                  )}
                </div>
              </button>
    </Badge>
  )
}

export const ProjectListItemFull = (props: ProjectListItemProps) => {

  const project = useSelectorWithParams(projectWithSubscriptionsSelector(props.projectId))
  const currentProjectId = useSelectorWithParams(currentProjectIdSelector);
  const isSelected = currentProjectId === props.projectId;


  const scope = useScope();
  const handleOpen = () => scope.openProject(String(props.projectId));

  if (!project) return null;

  const { subscriptions } = project;

  return (
    <>
      <div className={`standard-border ${isSelected ? "active" : ""}`}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            justifyContent: "space-between",
          }}
        >
          <Title
            level={2}
            onClick={handleOpen}
            noMargins
          >
            {project.name}
          </Title>

          <div style={{ display: "flex" }}>
            {props.isSelected === false && (
              <StandardButton
                onClick={handleOpen}
                type="primary"
              >
                <FormattedMessage
                  id="panel.components.project.listItem.choose"
                  defaultMessage="Choose"
                />
              </StandardButton>
            )}

            <DeleteProjectButton projectId={props.projectId} />
          </div>
        </div>

        <StandardDivider size="medium" />

        <div style={{ paddingBottom: "20px" }}>
          <Title level={5}>
            <FormattedMessage
              id="panel.components.project.listItem.integrations"
              defaultMessage="Integrations"
            />
          </Title>
          <div
            style={{
              display: "flex",
              gap: "10px",
            }}
          >
            <ProjectIntegrationDisplay projectId={props.projectId} />
          </div>
        </div>

        <div>
          <Title level={5}>
            <FormattedMessage
              id="panel.components.project.listItem.subscriptions"
              defaultMessage="Subscriptions"
            />
          </Title>
          <div
            style={{
              display: "flex",
              gap: "10px",
            }}
          >
            <ProjectSubscriptionsWithManager projectId={props.projectId} manageMode={props.manage}>
              <ManageSubscriptionsButton 
                manage={props.manage} 
                setManage={props.setManage}
              />
              <div style={{ flexGrow: 1 }}>
              </div>
              <VisitStoreButton />
            </ProjectSubscriptionsWithManager>
          </div>
        </div>
      </div>
    </>
  );
};

export const ProjectListItemBasic = (props: ProjectListItemProps & { onClick?: (subId: string) => void }) => {

  const project = useSelectorWithParams(projectWithSubscriptionsSelector(props.projectId))
  const currentProjectId = useSelectorWithParams(currentProjectIdSelector);
  const isSelected = currentProjectId === props.projectId;

  const scope = useScope();
  const handleOpen = () => scope.openProject(String(props.projectId));

  if (!project) return null;
  const { subscriptions } = project;


  return (
    <div className={`standard-border ${isSelected ? "active" : ""}`}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          justifyContent: "space-between",
        }}
      >
        <Title
          level={2}
          onClick={handleOpen}
          noMargins
        >
          {project.name}
        </Title>


      </div>

      <div>

        <div
          style={{
            display: "flex",
            gap: "10px",
          }}
        >
          <ProjectSubscriptionsWithManager projectId={props.projectId} onOpen={(subId) => props.onClick && props.onClick(subId)}>
            <div style={{ flexGrow: 1 }}>
            </div>
            <VisitStoreButton />
          </ProjectSubscriptionsWithManager>
        </div>
      </div>
    </div>
  )

}