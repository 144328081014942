import { Alert, Spin } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import { CodeBox } from "components/displays/CodeDisplay";
import { VerifyTrackingCode } from "modules/smart/smartSlice";
import ConnectedOnboardingControls from "providers/onboarding/controls";
import { getApiUrl } from "services/helpers/api-helpers";
import useAPI from "services/hooks/useAPI";

const Pixel = () => {
  const { call, data, loading } = useAPI({
      url: ({ projectId }) => getApiUrl(`projects/${projectId}/pixel`),
    },
    true
  );

  if (!data || !data.html) 
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '30px' }}>
      <Spin size="large" spinning={loading} />
    </div>
  );

  return (
    <div style={{ paddingTop: "20px" }}>
      <h2>
        <FormattedMessage
          id="apps.smart.containers.pixel.addTrackingCode"
          defaultMessage="Add tracking code to your website"
        />
      </h2>
      <CodeBox code={data.html} />
      <div style={{ paddingTop: "20px" }}>
        <p>
          <FormattedMessage
            id="panel.tracking.pixel.extraInfo"
            defaultMessage="If the user is redirected to a thank you page after sending the form, paste the code there."
          />
        </p>
        <CodeBox
          code={
            "<script> window.dataLayer = window.dataLayer || []; window.dataLayer.push({ 'event': 'lead', }); </script> "
          }
          style={{ minHeight: "75px" }}
        />
        <p>
          <FormattedMessage
            id="panel.tracking.pixel.extraInfo2"
            defaultMessage="If the form is sent dynamically (does not redirect to another page) then we must run the following method after a successful send:"
          />
        </p>
        <CodeBox
          code={"<script>window.dataLayer.push({'event': 'lead'});</script>"}
          style={{ minHeight: "75px" }}
        />
      </div>
    </div>
  );
};

export const OnboardingTrackingCode = () => {
  const sitesVerify = useAPI(VerifyTrackingCode());

  const handleNext = () => {
    const res = sitesVerify.call().then((res) => {
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    });

    return res
  };

  return (
    <>
      <FormattedMessage
        id="apps.smart.containers.onboarding.trackingCode.description"
        defaultMessage={`The pixel primary code monitors site activity by creating a reference
        point for measuring specific events. Copy the basic code below and paste
        it between the <head> and </head> tags on each page of your website.`}
      />
      <Pixel />
      {sitesVerify.error && sitesVerify.loading === false && (
        <Alert
          type="error"
          message={
            <FormattedMessage
              id="apps.forsant.checkAndContinue.error"
              defaultMessage="We have just checked if you fulfilled this step properly, unfortunately not - please read, and try again"
            />
          }
          style={{ margin: "10px 0" }}
          showIcon
        />
      )}

      <ConnectedOnboardingControls
        nextStepText={
          <FormattedMessage
            id="apps.forsant.checkAndContinue"
            defaultMessage="Check and continue"
          />
        }
        nextPending={sitesVerify.loading}
        clickOverride={handleNext}
      />
    </>
  );
};
