import FormattedMessage from "components/common/FormattedMessage";
import { ReactNode, createContext, forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import { StandardDrawer, useStandardDrawer } from "services/hooks/useDrawer";
import NotificationsList from "./NotificationsList";
import useSockets from "services/hooks/useSockets";
import { useAPIContext } from "contexts/APIContext";
import { notification } from "antd";
import NotificationsSlice, { GetNotifications, Notification, NotificationsAsArraySelector } from "./NotificationsSlice";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { useDispatch } from "react-redux";
import useAPI from "services/hooks/useAPI";
import { useFeedback } from "contexts/FeedbackContext";
import { currentProjectIdSelector } from "services/store/scopeSlice";

type NotificationsDrawerRef = {
    openNotifications: () => void;
    closeNotifications: () => void;
}

type NotificationsContextType = {
    openNotifications: () => void;
    closeNotifications: () => void;
    notifications: Notification[];
}


export const NotificationsDrawer = forwardRef((props: any, ref: any) => {

    const [drawerRef, open, close, isOpen] = useStandardDrawer();

    const openNotifications = () => {
        open();
    }

    const closeNotifications = () => {
        close();
    }

    useImperativeHandle(ref, () => ({
        openNotifications,
        closeNotifications,
    }));


    return (
        <StandardDrawer
            ref={drawerRef}
            width={"600px"}
            layout="standard"
            mask={true}
            title={<FormattedMessage
                id={"notifications.panel.title"}
                defaultMessage={"Notifications"}
            />}
            zIndex={1000}
            onClose={closeNotifications}
        >
            <NotificationsList />
        </StandardDrawer>
    )

})


const NotificationsContext = createContext<NotificationsContextType>({
    openNotifications: () => { },
    closeNotifications: () => { },
    notifications: [],
});
NotificationsContext.displayName = "NotificationsContext";
export const useNotifications = () => useContext(NotificationsContext);

export default function NotificationsGlobal({children}: {children?: ReactNode}) {

    const notificationsRef = useRef<NotificationsDrawerRef>(null);
    const notifications = useSelectorWithParams(NotificationsAsArraySelector);
    const dispatch = useDispatch();
    const {notifications: notifAPI} = useFeedback();

    const notifCall = useAPI(GetNotifications)
    const projectId = useSelectorWithParams(currentProjectIdSelector);

    useEffect(() => {
        if (projectId) {
            notifCall.call();
        }
    }, [projectId]);

    const s = useSockets({
        channel: "notifications",
        event: "new",
        onMessage: (message) => newNotification(message),
    });


    const newNotification = (notification: Notification) => {
        //console.log("New notification", notification);
        
        dispatch(NotificationsSlice.actions.addNotification(notification));

        notifAPI.open({
            key: "newNotification",
            message: notification.title,
            description: <FormattedMessage 
                    id="notifications.newNotification.description"
                    defaultMessage="Click here to view"
                />,
            duration: 5,
            onClick: () => {
                openNotifications();
            }, 
        })
    }

    useEffect(() => {

        window.addEventListener("debug-notification", (event: any) => {
            if (event.detail) {
                newNotification(event.detail);
            }
        });

    }, [])

    const openNotifications = () => {
        notification.close("newNotification");
        notificationsRef.current?.openNotifications();
    }

    const closeNotifications = () => {
        notificationsRef.current?.closeNotifications();
    }

    return (
        <NotificationsContext.Provider value={{
            openNotifications: openNotifications,
            closeNotifications: closeNotifications,
            notifications: notifications
        }}>
            {children}
            <NotificationsDrawer ref={notificationsRef} />
        </NotificationsContext.Provider>
    )

}