import {createSlice, PayloadAction, createSelector} from '@reduxjs/toolkit';
import _ from 'lodash';
import { getApiUrl } from 'services/helpers/api-helpers';
import { callAPIProps } from "services/hooks/useAPI";


const cleanupEntry = (e:any) => {
    const {id, path, alias, name} = e;
    return {
        id, 
        path: path.split('|').filter((e:any) => e.length !== 0).map((e:any) => parseInt(e)), 
        name,
        alias
    }
}

export type IndustryState = any;

const initialState: IndustryState = {
}

export const industriesSlice = createSlice({
    name: "industries",
    initialState, 
    reducers: {
        get: (state, action) => {

            //console.log("Action", action)

            const clean = action.payload.data.map((e:any) => cleanupEntry(e));


            let tree:any = {};
            clean.forEach((e:any) => {
                if (e.path === "") tree[e.id] = {};
            })

            clean.forEach((v:any, i:any, a:any) => {

                const p = v.path;
                if (p.length !== 0 ) {

                    const c = _.get(tree, p);
                    if (!c) tree = _.set(tree, p, {});
                    tree = _.set(tree, p, {...(_.get(tree, p)), [v.id]: v.id});
                }
            })

            state[action.payload.additional.industry] = {
                tree, 
                list: clean,
            }

        },
    }
})

//Returns industry with a given name
export const industrySelector = createSelector([
    (state:any) => state.siteGenerator.industries,
    (state:any, industry: any) => industry],
    (state, industry) => state[industry]
)

// Returns category with given name or id
export const industryCategorySelector = createSelector([
    industrySelector,
    (state, industry, category) => category,
],(industry:any, category: any) => {

    if (!industry || !industry.list || !category) return null;

    let c:any; 
    if (typeof category === "object") {
        c = industry.list.find((e:any) => e.id === category.id || e.alias === category.alias)
    } else {
        c = industry.list.find((e:any) => e.id === category || e.alias === category)
    }

    return c;

})

//Returns all children of a category. 
export const industryChildrenOfCategorySelector = createSelector([
    industrySelector, 
    (state, industry, category) => industryCategorySelector(state as never, industry, category),
], (industry:any, category: any) => {

    if (!industry || !industry.list || !category) return null;

    const filtered = industry.list.filter((e: any) => {
        if (e.path[e.path.length -1] === category.id) return true;
        return false;
    })

    return {category, options: filtered}
})

//Returns all categories in category
export const industryAllInCategorySelector = createSelector([
    industrySelector,
    (state:any, industry: any, category: any) => category,
],(state:any, category: any) => {
    if (!state || !state.list) return {category: null, options: []};

    let c:any;
    if (typeof category === "number") c = category;
    if (typeof category === "string") c = state.list.find((e:any) => e.alias == category);

    if (!c) return {category: null, options: []};

    const filtered = state.list.filter((e: any) => {
        if (e.path[e.path.length -1] === c.id) return true;
        return false;
    })
    const mapped = filtered.map((e:any) => ({value: e.id, label: e.name}));
    return {category: c, options: mapped};
})

export const industryCategoriesAsObjects = createSelector([
    (industrySelector as any),
    (state: any, industry: any, children: any) => children
], (industry: any, children: any) => {

    if (!industry || !industry.list || industry.length === 0) return [];
    if (!children || children.length === 0) return [];
    const childIds = children.map((c:any) => c.id);

    return industry.list.filter((c:any) => childIds.includes(c.id));

}
)

export const GetIndustryData = (industry:string):callAPIProps => ({
    url: ({ getApiUrl, language}: any) => getApiUrl(`content/categories/${language}/${industry}`,2),
    successDispatch: industriesSlice.actions.get,
    passToDispatcher: {industry: industry},
    actionName: "getIndustryData"
})

export const CreateWebsiteObject = (industry:string):callAPIProps => ({
    url: ({ getApiUrl}: any) => getApiUrl(`websites/websites`),
    method: "POST",
    successDispatch: industriesSlice.actions.get,
    passToDispatcher: {industry: industry}
})


export const {get} = industriesSlice.actions;
export default industriesSlice.reducer;
