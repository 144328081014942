import React, { FunctionComponent } from "react";
import { userTokenSelector } from "modules/panel/config/selectors/user";
import { AppConfig } from "modules";
import { useSelector } from "react-redux";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { currentProjectIdSelector } from "services/store/scopeSlice";

const AppsContext: FunctionComponent<{ apps: AppConfig[] }> = ({
  apps,
  children,
}) => {
  const currentProjectId = useSelectorWithParams(currentProjectIdSelector);
  const userToken = useSelector(userTokenSelector);

  let childrenInAppsContexts = children;

  apps.forEach(({ apiUrl = import.meta.env.VITE_API_URL, context, path }) => {
    if (context) {
      const AppProvider = context;

      childrenInAppsContexts = (
        <AppProvider
          value={{
            apiUrl,
            baseUrl: `/${path}`,
            projectId: currentProjectId,
            userToken,
            permissions: [
              "createBoard",
              "editBoard",
              "manageCampaigns",
              "editLead",
              "editProspect",
              "transitionLeadToProspect",
            ],
          }}
        >
          {childrenInAppsContexts}
        </AppProvider>
      );
    }
  });

  return <>{childrenInAppsContexts}</>;
};

export default AppsContext;
