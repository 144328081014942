import React, {Component} from 'react'

class PassFormItemPropsToChildren extends Component {
    onChange = value => {
        this.props.onChange(value);
        if (this.props.input.props
            && this.props.input.props.onChange) this.props.input.props.onChange(value);
    }

    render() {
        return (
            <>
                {React.cloneElement(this.props.input, {
                    id: this.props.id,
                    value: this.props.value,
                    checked: this.props.checked,
                    onChange: this.onChange,
                    'data-__meta': this.props['data-__meta'],
                    'data-__field': this.props['data-__field'],
                })}
            </>
        )
    }
}

export default PassFormItemPropsToChildren;