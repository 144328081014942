import { Collapse, Spin } from "antd";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import { HorizontalCenter } from "components/common/Center";
import { GetAiProjectInfo, ProjectHasAIProjectInfoSelector, ProjectHasDemographicSelector, ProjectHasDemographicsInCategorySelector, useGenerateAIDemographics } from "modules/panel/state/AISlice";
import AIDemographics from "modules/smart/components/AIGeneration/AIDemographics";
import { AIDemographicsCategoriesType } from "modules/smart/components/AIGeneration/AIDemographicsConfig";
import SettingsAI from "modules/smart/components/AIGeneration/SettingsAI"
import ConnectedOnboardingControls from "providers/onboarding/controls"
import { useOnboarding } from "providers/onboarding/onboarding-context";
import { useEffect, useRef } from "react";
import useAPI from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";

const OnboardingAIDemographics = (props: {category: AIDemographicsCategoriesType}) => {
    

    const fullfilled = useSelectorWithParams([ProjectHasDemographicsInCategorySelector, props.category]);
    const {direction} = useOnboarding();


    return (
        <>
            <AIDemographics category={props.category}/>
            <ConnectedOnboardingControls relaxDontSubmit = {true} nextDisabled={!fullfilled}/>
        </>
    )

}

export default OnboardingAIDemographics;