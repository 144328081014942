import { Input, Typography } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import StandardButton from "components/common/StandardButton";
import { StandardFullColumn, StandardHalfFullColumn, StandardRow } from "components/layout/StandardGrid";
import { companyDataSelector } from "modules/panel/state/companySlice";
import { useRef } from "react";
import useSelectorWithParams from "services/hooks/useSelectorWithParams"
import { currentProjectIdSelector } from "services/store/scopeSlice";
import { EditProject, EditProjectRecursionFix } from "state/projects/projectEndpoints";
import { currentProjectSelector } from "state/projects/projectSelectors";
import RelaxSingleField from "tools/relaxForm/RelaxField";


const InputWithCopyFromSettings = (props: any) => {

    const companyData = useSelectorWithParams(companyDataSelector);

    const handleClick = () => {
        if (companyData && companyData.name) {
            props.onChange && props.onChange(companyData.name)
        }
    }

    return (
        <StandardButton onClick={handleClick}>
            <FormattedMessage
                id="panel.projectSettings.invoiceSettings.copyFromInvoice"
                defaultMessage="Copy from invoice data"
                />
        </StandardButton>
    )

}

export default function ProjectInvoiceSettings() {

    const projectId = useSelectorWithParams(currentProjectIdSelector);
    const beneficiaryRef = useRef<any>(null);
    const payerRef = useRef<any>(null);

    return (
        <>
            <StandardRow>
                <StandardFullColumn>
                    <Typography.Title level={3}>
                        <FormattedMessage
                            id="panel.projectSettings.invoiceSettings.title"
                            defaultMessage="Invoice Settings"
                        />
                    </Typography.Title>
                </StandardFullColumn>
            </StandardRow>
            <StandardRow>
                <StandardHalfFullColumn>
                    <Typography.Title level={4}>
                        <FormattedMessage
                            id="panel.projectSettings.invoiceSettings.beneficiaty.header"
                            defaultMessage="Beneficiary"
                        />
                    </Typography.Title>
                    <RelaxSingleField
                        name={"beneficiary"}
                        ref={beneficiaryRef}
                        initialValuesSelector={currentProjectSelector}
                        callAPI={EditProjectRecursionFix}
                        label={
                            <FormattedMessage
                                id="panel.projectSettings.invoiceSettings.beneficiary.label"
                                defaultMessage="Name" />
                        }
                        tooltip={
                            <FormattedMessage
                                id="panel.projectSettings.invoiceSettings.beneficiary.tooltip"
                                defaultMessage="Name of the beneficiary"
                            />
                        }
                    >
                        <Input />
                    </RelaxSingleField>
                    <InputWithCopyFromSettings onChange = {
                        (value: any) => beneficiaryRef.current?.setValueAndSend(value)
                    }/>
                </StandardHalfFullColumn>
                <StandardHalfFullColumn>
                    <Typography.Title level={4}>
                        <FormattedMessage
                            id="panel.projectSettings.invoiceSettings.payer.header"
                            defaultMessage="Payer"
                        />
                    </Typography.Title>
                    <RelaxSingleField
                        name={"payer"}
                        ref={payerRef}
                        initialValuesSelector={currentProjectSelector}
                        callAPI={EditProjectRecursionFix}
                        label={
                            <FormattedMessage
                                id="panel.projectSettings.invoiceSettings.payer.label"
                                defaultMessage="Name" />
                        }
                        tooltip={
                            <FormattedMessage
                                id="panel.projectSettings.invoiceSettings.payer.tooltip"
                                defaultMessage="Name of the payer"
                            />
                        }
                    >
                        <Input />
                    </RelaxSingleField>
                    <InputWithCopyFromSettings onChange = {
                        (value: any) => payerRef.current?.setValueAndSend(value)
                    }/>
                </StandardHalfFullColumn>
            </StandardRow></>
    )

}