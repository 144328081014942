import { InputNumber, Spin } from "antd";
import { HorizontalCenter } from "components/common/Center";
import { useEffect, useMemo } from "react";
import useAPI from "services/hooks/useAPI";

export default function BudgetInputFacebook(props:any) {

    const {call, data, loading} = useAPI({
        url: ({getApiUrl, projectId}) => getApiUrl(`services/facebook/${projectId}/minBudget`),
        method: 'GET',
        customNormalizer: (data:any) => data?.data,
    }, true);
;

    const minBudget = useMemo(() => {
        if (!data) return 0;
        try {
            const min = data.min_daily_budget ? Math.ceil(data.min_daily_budget) : 0;
            if (props.minimumBudget) return props.minimumBudget(min);
            
        return min;
        } catch (e) {
            console.log("Error in minBudget", e);
        }
        return 0;
    }, [data])

    const defaultBudget = useMemo(() => {
        try {
            if (props.defaultBudget) return props.defaultBudget(minBudget);
        }   
        catch (e) {
            console.log("Error in defaultBudget", e);
        }
        return minBudget;
    }, [props.value, data])

    useEffect(() => {
        if (!props.value && defaultBudget) props.onChange(defaultBudget);
        if (defaultBudget) props.onDefaultBudgetLoaded && props.onDefaultBudgetLoaded(defaultBudget);
    }, [defaultBudget])

    
    if (loading) return (
        <HorizontalCenter><Spin /></HorizontalCenter>
    )
      
    return (
        <InputNumber
        value={props.value ? props.value : defaultBudget}
        onChange={props.onChange}
        onBlur={props.onBlur}
        min={minBudget}
        addonAfter={data?.currency}
        style={{
            width: "100%"
        }}
        />
    )

}