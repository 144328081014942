import { Input } from "antd";
import { HorizontalCenter } from "components/common/Center";
import StandardActorCard from "components/common/StandardActorCard";
import { StandardRow } from "components/layout/StandardGrid";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import Gallery from "tools/gallery/Gallery";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import { OrganizationSelector, PatchOrganization } from "./data/organizationSlice";
import FormattedMessage from "components/common/FormattedMessage";
import useAPI from "services/hooks/useAPI";


export default function OrganizationTab(props: any) {

    const patchOrganization = useAPI(PatchOrganization())
    const organization = useSelectorWithParams(OrganizationSelector)

    return (
        <>
            <FormattedMessage
            id="organization.OrganizationTab.yourOrganization"
            defaultMessage="Your Organization:" />
            <StandardRow>
                <HorizontalCenter>
                    <RelaxMultiform
                        name="OrganizationTab"
                        initialValues={organization}
                        callAPI={patchOrganization}
                    >
                        <RelaxSingleField
                            name="name"
                            label={
                                <FormattedMessage
                                id="organization.OrganizationTab.name.label"
                                defaultMessage="Name"/>
                            }
                        >
                            <Input />
                        </RelaxSingleField>
                        <RelaxSingleField
                            name="logo"
                            label={
                                <FormattedMessage
                                id="organization.OrganizationTab.logo.label"
                                defaultMessage="Logo"/>
                            }
                        >
                            <Gallery
                                allowedTypes={["image"]}
                                singleImageOnly
                            />
                        </RelaxSingleField>
                        <RelaxSingleField
                            name="description"
                            label={
                                <FormattedMessage
                                id="organization.OrganizationTab.description.label"
                                defaultMessage="Description"/>
                            }
                        >
                            <Input.TextArea />
                        </RelaxSingleField>
                    </RelaxMultiform>
                </HorizontalCenter>
            </StandardRow>
        </>
    )


}