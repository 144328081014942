import { Tooltip } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import StandardButton from "components/common/StandardButton";
import { faDownload } from "@fortawesome/pro-light-svg-icons";
import axios from "axios";
import { getApiUrl } from "services/helpers/api-helpers";
import { ReactNode } from "react";
import { getAuthorizationHeaders } from "services/helpers/local-storage";


type Props = {
  label?: ReactNode;
  url: string;
  fileName: string;
  available?: boolean;
  unavailableTooltip?: ReactNode;
};

const generateDownloadFromBlob = (blob: any, fileName: string) => {
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  document.body.append(link);
  link.click();
  link.remove();
  setTimeout(() => URL.revokeObjectURL(link.href), 1000);
};

export const DownloadButton = (props: Props) => {

  const handleDownloadClick = () => {
    axios
      .get(getApiUrl(props.url), {
        responseType: "blob",
        headers: getAuthorizationHeaders()
      })
      .then((response) => {
        generateDownloadFromBlob(response.data, props.fileName);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };

  const tooltipMsg = props.unavailableTooltip ? props.unavailableTooltip : (
    <FormattedMessage
      id="common.unavailable"
      defaultMessage="Unavailable"
    />
  )

  return (
    <Tooltip
      title={
        props.available ? null : tooltipMsg
      }
    >
      <div>
      <StandardButton
        onClick={handleDownloadClick}
        disabled={!props.available}
        icon={faDownload}
      >
        {props.label ? props.label : (
          <FormattedMessage
            id="common.download"
            defaultMessage="Download"
          />
        )}
      </StandardButton>
      </div>
    </Tooltip>
  );
};