import useAPI from "services/hooks/useAPI";
import { useCancellationWizard } from "./SubscriptionCancellationWizard";
import { useEffect } from "react";
import { GetSubscriptionCancelationPlans } from "state/subscriptions/subscriptionsEndpoints";
import { HorizontalCenter } from "components/common/Center";
import { Spin } from "antd";

function SubscriptionCancellationLoadPlan() {

    const {subscriptionId, goToStep} = useCancellationWizard();
    const planCall = useAPI({});

    useEffect(() => {
        if (subscriptionId) {
            planCall.call(GetSubscriptionCancelationPlans(subscriptionId)).then((r:any) => {
                if (r && r.status === 200) {
                    goToStep("benefits");
                }
            });
        }
    }, [subscriptionId]);

    return (<HorizontalCenter><Spin /></HorizontalCenter>);
}

export default SubscriptionCancellationLoadPlan;