import useAPI from "services/hooks/useAPI";
import { CarserviceBrandsDefinitionsSelector, CarserviceManufacturersRestDefinitionsSelector, CarserviceManufacturersTiresDefinitionsSelector, GetBrands, GetManufacturers, ManufacturersRestSelectorIDs, ManufacturersTiresSelectorIDs } from "../brandsAndManufacturers/CarsSlice";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { UpdateWebsite, WebsiteCarserviceBrandsSelector, WebsiteCarserviceManufacturersSelector } from "../../reducers/websiteSlice";
import RelaxField from "tools/relaxForm/RelaxField";
import { CarServiceCategoryInput } from "../brandsAndManufacturers/CarsServiceCategoryInput";
import { HorizontalCenter } from "components/common/Center";
import { Spin } from "antd";
import { rulesetArrayMin } from "tools/relaxForm/relaxPresets";


export const CarserviceBrands = () => {

    const definitionsCall = useAPI(GetBrands, true);
    const definitions = useSelectorWithParams(CarserviceBrandsDefinitionsSelector)
    const checked = useSelectorWithParams(WebsiteCarserviceBrandsSelector)

    return (
        <RelaxField
            callAPI={UpdateWebsite}
            name={"brands"}
            submitOnChange
            initialValues={{
                brands: checked
            }}
            rules={rulesetArrayMin(1)}
        >
            <CarServiceCategoryInput
                subCategories={definitions}
            />
        </RelaxField>
    )

}

const Manufacturers_TiresFilteredByDefinition = (state: any) => {
    const tiresIds = ManufacturersTiresSelectorIDs(state);
    const checkedManufacturers = WebsiteCarserviceManufacturersSelector(state);
    return checkedManufacturers.filter((manufacturer: any) => tiresIds.includes(manufacturer))
}

const Manufacturers_RestFilteredByDefinition = (state: any) => {
    const restIds = ManufacturersRestSelectorIDs(state);
    const checkedManufacturers = WebsiteCarserviceManufacturersSelector(state);
    return checkedManufacturers.filter((manufacturer: any) => restIds.includes(manufacturer))
}


export const CarserviceManufacturers = () => {

    const definitionsCall = useAPI(GetManufacturers, true);
    const definitions = useSelectorWithParams(CarserviceManufacturersRestDefinitionsSelector)
    const checked = useSelectorWithParams(WebsiteCarserviceManufacturersSelector)
    const filtered_tires = useSelectorWithParams(Manufacturers_TiresFilteredByDefinition)

    if (!definitions) return <HorizontalCenter><Spin /></HorizontalCenter>

    return (
        <RelaxField
            callAPI={UpdateWebsite}
            name="manufacturers"
            submitOnChange
            initialValues={{
                manufacturers: checked
            }}
            finalizeSubmit={(values: any) => {
                return { manufacturers: [...values.manufacturers, ...(filtered_tires || [])] }
            }}
            rules={rulesetArrayMin(1)}
        >
            <CarServiceCategoryInput
                subCategories={definitions}
            />
        </RelaxField>
    )

}

export const CarserviceTires = () => {

    const definitionsCall = useAPI(GetManufacturers, true);
    const definitions = useSelectorWithParams(CarserviceManufacturersTiresDefinitionsSelector)
    const checked = useSelectorWithParams(WebsiteCarserviceManufacturersSelector)
    const filtered_rest = useSelectorWithParams(Manufacturers_RestFilteredByDefinition)

    if (!definitions) return <HorizontalCenter><Spin /></HorizontalCenter>

    return (
        <RelaxField
            callAPI={UpdateWebsite}
            name="manufacturers"
            submitOnChange
            initialValues={{
                manufacturers: checked
            }}
            finalizeSubmit={(values: any) => {
                return { manufacturers: [...values.manufacturers, ...(filtered_rest || [])] }
            }}
            rules={rulesetArrayMin(1)}
        >
            <CarServiceCategoryInput
                subCategories={definitions}
            />
        </RelaxField>
    )

}

