import { Progress, Select, Typography } from "antd";
import { ProviderConfig, ProviderField } from "modules/panel/config/ProviderConfig";
import { useEffect, useMemo, useCallback, useState } from "react";
import FormattedMessage from "components/common/FormattedMessage";
import useAPIWithRetry from "services/hooks/useAPIWithRetry";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import useEffectDeepCompare from "services/hooks/useEffectDeepCompare";
import useMemoDeepCompare from "services/hooks/useMemoDeepCompare";
import { ProviderFieldIcon } from "modules/panel/config/ProviderHelpers";
import { currentProjectAnyField } from "state/projects/projectSelectors";



export type ProviderIntegrationFieldProps = {
    provider: "facebook" | "google",
    values: any,
    field: string,
    onChange?: (field: string, value: string | undefined) => void
}

const ProfilePictureWithFallbackToIcon = (props: any) => {

    const [displayIcon, setDisplayIcon] = useState(props.fieldConfig.getValueDisplayImage ? false : true);

    if (displayIcon) return <ProviderFieldIcon field={props.fieldConfig} />
    if (props.fieldConfig.getValueDisplayImage) return (
        <img style={{
            height: "36px",
            width: "36px"
        }}
            src={props.fieldConfig.getValueDisplayImage(props.image)}
            onError={() => setDisplayIcon(true)}
        />

    )


}

export default function ProviderIntegrationField(props: ProviderIntegrationFieldProps) {


    const providerConfig = ProviderConfig.get(props.provider);
    const fieldConfig = (providerConfig!.fields!.get as any)(props.field) as ProviderField;
    const savedValue = useSelectorWithParams([currentProjectAnyField, fieldConfig.stateFieldName])
    const selectedValue = useMemoDeepCompare(() => savedValue || props.values[props.field], [props.values, props.field, savedValue])
    const storedObject = useSelectorWithParams([currentProjectAnyField, fieldConfig.stateObjectFieldName])

    // console.log("Field: ", props.field)
    // console.log("Saved Value", savedValue)

    const { enable, data, loading, error, checkData } = useAPIWithRetry({
        url: ({ getApiUrl }) => getApiUrl(fieldConfig.retrivalUrl, 3),
        enabled: !storedObject,
    })

    const handleChange = (v: string | undefined) => {
        props.onChange && props.onChange(props.field, v);
    }

    const filteredOptions = useMemo(() => {
        if (!data) return [];

        //Filter options to removes invalid ones (e.g. only show Facebook pixels that match the selected AdAccount)
        const filteredOptions = fieldConfig.filterAvailableValues ? fieldConfig.filterAvailableValues(props.values, data) : data;

        //If we aleady have the value saved, add it to the list even if it normally wouldn't be there
        if (savedValue) {
            const savedValueOption = data.find((o: any) => o.id === savedValue);
            if (savedValueOption) filteredOptions.push(savedValueOption);
        }

        if (fieldConfig.addAutoGenerateOption) {

            if (props.field === "facebookPixels") {
                if (!filteredOptions || filteredOptions.length === 0) {
                    filteredOptions.push("autoGenerate")
                }
            } else {
                filteredOptions.push("autoGenerate")
            }
        }
        return filteredOptions;
    }, [props.values, data])

    //If saved value exists we pretend to selected to trigger change on the whole form. 
    useEffect(() => {
        if (!savedValue) return;
        handleChange(savedValue)
    }, [savedValue]);


    const mappedOptions = useMemo(() => {

        if (storedObject) {
            return (
                <Select.Option
                    key={storedObject.id}
                    value={storedObject.id}
                    name={fieldConfig.getValueDisplayName(storedObject)}
                >
                    <div className="provider-integration-entry">
                        <ProfilePictureWithFallbackToIcon image={storedObject} fieldConfig={fieldConfig} />
                        {fieldConfig.getValueDisplayName(storedObject)}
                    </div>
                </Select.Option>
            )
        }

        return filteredOptions.map((o: any) => {

            if (o === "autoGenerate") {
                return (
                    <Select.Option
                        key={"autoGenerate"}
                        value={"autoGenerate"}
                    >
                        <div className="provider-integration-entry">
                            <ProviderFieldIcon field={props.field} />
                            <FormattedMessage
                                id="panel.components.integrations.field.willBeCreated"
                                defaultMessage="Create new {integrationName} automatically"
                                values={{ integrationName: fieldConfig.fieldDisplayName }}
                            />
                        </div>
                    </Select.Option>
                )
            }

            return (
                <Select.Option
                    key={o.id}
                    value={o.id}
                    name={fieldConfig.getValueDisplayName(o)}
                >
                    <div className="provider-integration-entry">
                        <ProfilePictureWithFallbackToIcon image={o} fieldConfig={fieldConfig} />
                        {fieldConfig.getValueDisplayName(o)}
                    </div>
                </Select.Option>
            )

        })
    }, [filteredOptions, storedObject])



    useEffectDeepCompare(() => {

        //console.log("here", selectedValue, filteredOptions)
        if (savedValue) return;
        if (loading) return;
        //console.log("useEffect on field", props.field, props.values)
       // console.log("filteredOptions", filteredOptions)

        //Our current value is no longer available
        if (selectedValue && selectedValue !== "autoGenerate" && !filteredOptions.map((o: any) => o.id).includes(selectedValue)) {

            if (filteredOptions && filteredOptions.length > 0) {
                handleChange(undefined);
                //handleChange(filteredOptions[0].id);
            } else if (fieldConfig.addAutoGenerateOption) {
                handleChange("autoGenerate");
            } else {
                handleChange(undefined);
            }

        }

        //we have no value, but we have options
        if (!selectedValue && filteredOptions && filteredOptions.length === 1) {
            handleChange(filteredOptions[0].id)
        }

    }, [selectedValue, filteredOptions, loading]);

    const loadingProgress = useMemo(() => {
        if (!loading) return 1;
        if (!checkData) return 0;
        if (checkData.ready) return 1;
        const progressString = checkData?.inf?.progress
        if (!progressString) return 0;
        const parts = progressString.split("/");
        const percentage = parseInt(parts[0]) / parseInt(parts[1]) * 100;
        const rounded =  Math.round(percentage * 100) / 100;
        return rounded;
    }, [loading, checkData])


    const isDisabled = () => {
        if (savedValue) return true;
        if (loading) return true;
        if (!mappedOptions) return true;
        return false;
    }

  //  console.log("Field", props.field, props.values[props.field], filteredOptions)

    if (loading) {
        return (
            <>
            <Typography.Text type="secondary">{fieldConfig.fieldDisplayName}</Typography.Text>
            <div style={{
                height: "55px",
                display: "grid",
                gridTemplateColumns: "1fr",
                alignItems: "center",
            }} className="standard-border">
                <Progress percent={loadingProgress} />
            </div>
            </>
        )
    }

    return (
        <>
        <Typography.Text type="secondary">{fieldConfig.fieldDisplayName}</Typography.Text>
        <Select
            placeholder={loading ? "Loading" : fieldConfig.fieldDisplayName}
            loading={loading}
            disabled={isDisabled()}
            value={selectedValue}
            showSearch
            onChange={handleChange}
            filterOption={(input, option) => {
                if (option?.name) {
                    if (option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0) return true;
                }

                if (option?.value === "autoGenerate") return true;

                return false;
            }}
            style={{ width: "100%" }}
        >
            {mappedOptions}
        </Select>
        </>
    )

}