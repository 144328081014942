import React, {useState, useMemo, useEffect} from "react";
import { InputNumber } from "antd";
import { useIntl, defineMessages } from 'react-intl';

const PeriodInput = (props) => {
    const intl = useIntl();
    const { formatMessage } = intl;

    const [val, setVal] = useState(props.value);

    const handleChange = value => {
        setVal(value);
        props.onChange && props.onChange(value);
    }

    const suffixMessage = useMemo(() => formatMessage(messages.dayPlurar, { days: val }), [val])

    const min = props.min || 1;

    const parser = value => {
        const adj = (value && value.length > 0 )? value.match(/\d/g).join("") : value
        return adj < min ? min : adj;
    }

    return (
        <InputNumber
            style={{width: "100%"}}
            {...props}
            min={min}
            parser={parser}
            addonAfter={suffixMessage}
            onChange={handleChange}
        />
    )
};

export const messages = defineMessages({
    dayPlurar: {
        id: "apps.smart.components.adsSettings.daysPlural",
        defaultMessage: "{days, plural, one {day} other {days}}",
    }
})


export default PeriodInput;