import { ExampleClientGendersFromFacebookAdsSettingsSelector } from "modules/panel/state/AISlice";
import AIClientAvatar from "modules/smart/components/AIGeneration/AIClientAvatar"
import useSelectorWithParams from "services/hooks/useSelectorWithParams";

const SettingsAIExampleClients = () => {

    const exampleClientGenders = useSelectorWithParams(ExampleClientGendersFromFacebookAdsSettingsSelector)

    return (
        <div>
            {exampleClientGenders.includes("female") && <AIClientAvatar gender="female" />}
            {exampleClientGenders.includes("male") && <AIClientAvatar gender="male" />}
        </div>
    )

}

export default SettingsAIExampleClients;