import React, { useEffect } from "react";
import SuccessPage from "_components/OnboardingSuccessPage";
import FormattedMessage from "components/common/FormattedMessage";

const AuthorizationSuccessOnboarding = () => {
  useEffect(() => localStorage.clear(), []);

  return (
    <SuccessPage>
      <p>
        <FormattedMessage
          id="panel.containers.onboarding.authorizationSuccess.thanks"
          defaultMessage="Thank you for participating in the Forsant project. You help us develop artificial intelligence. You are great."
        />
      </p>
    </SuccessPage>
  );
};

export default AuthorizationSuccessOnboarding;
