import { Rule } from "antd/es/form";
import FormattedMessage from "components/common/FormattedMessage";
import { ReactNode } from "react";
import { RelaxError, RelaxRule } from "./relaxTypes";

const msgRuleMin = (min: any) => <FormattedMessage
    id="forms.validation.length.min"
    defaultMessage="Min length is {min}."
    values={{ min }}
/>

const msgRuleMax = (max: any) =>
    <FormattedMessage
        id="forms.validation.length.max"
        defaultMessage="Max length is {max}."
        values={{ max }}
    />

const msgRuleNoDuplicates = () => 
    <FormattedMessage
        id="forms.validation.noDuplicates"
        defaultMessage="No duplicates allowed."
    />

const msgRuleNoEmpty = () =>
    <FormattedMessage
        id="forms.validation.noEmpty"
        defaultMessage="No empty values allowed."
    />

const msgRuleMin8Characters = () =>
    <FormattedMessage
        id="services.formsValidators.minCharacters"
        defaultMessage="min. 8 characters,"
    />

const msgRuleMin1Uppercase = () => 
    <FormattedMessage
    id="services.formsValidators.minUppercase"
    defaultMessage="min. 1 uppercase character"
  />

const msgRuleMin1Lowercase = () =>
    <FormattedMessage
        id="services.formsValidators.minLowercase"
        defaultMessage="min. 1 lowercase character"
    />

const msgRuleMin1Number = () =>
    <FormattedMessage
        id="services.formsValidators.minNumber"
        defaultMessage="min. 1 number"
    />

const msgRuleMin1Special = () =>
    <FormattedMessage
        id="services.formsValidators.minSpecial"
        defaultMessage="min. 1 special character"
    />


export const validateRule = (value: any, rule: RelaxError): ReactNode | null => {
    //console.log("Validating value: ", value, " with rule: ", rule);
    if (!rule) return null;

    if (rule.required) {
        if (!value) return (
            <FormattedMessage
                id="forms.validation.required"
                defaultMessage="This field is required."
            />
        )
        if (typeof value === "string" && value === "") return (
            <FormattedMessage
                id="forms.validation.required"
                defaultMessage="This field is required."
            />
        )
    }

    if (rule.min) {
        if (!value) return rule.message || msgRuleMin(rule.min);
        if (typeof value === "number") {
            if (value < rule.min) return rule.message || msgRuleMin(rule.min);
        } else if (typeof value === "string") {
            if (value.length < rule.min) return rule.message || msgRuleMin(rule.min);
        } else if (Array.isArray(value)) {
            if (value.length < rule.min) return rule.message || msgRuleMin(rule.min);
        }
    }

    if (rule.max) {
        //if (!value) return msgRuleMax(rule.max);
        if (typeof value === "number") {
            if (value > rule.max) return rule.message || msgRuleMax(rule.max);
        } else if (typeof value === "string") {
            if (value.length > rule.max)  return rule.message || msgRuleMax(rule.max);
        } else if (Array.isArray(value)) {
            if (value.length > rule.max)  return rule.message || msgRuleMax(rule.max);
        } 
    }

    if (rule.noDuplicates) {
        if (Array.isArray(value)) {
            //find if the "value" has any duplicates
            const duplicates = value.filter((item: any, index: any) => value.indexOf(item) != index)
            if (duplicates.length > 0) return rule.message || msgRuleNoDuplicates();
        } 
    }

    if (rule.noDuplicatesByHash) {
        if (Array.isArray(value)) {
            //find if the "value" has any duplicates
            const duplicates = value.filter((item: any, index: any) => value.findIndex((v: any) => v.hash === item.hash) != index)
            if (duplicates.length > 0) return rule.message || msgRuleNoDuplicates();
        }
    }

    if (rule.noEmptyValues) {
        if (!value) return msgRuleNoEmpty();
        if (Array.isArray(value)) {
            //find if the "value" has any duplicates
            const emptyValues = value.filter((item: any) => !item)
            if (emptyValues.length > 0) return rule.message || msgRuleNoEmpty();
        } 
    }

    if (rule.password || rule.type === "password") {
        const min8characters = new RegExp("^(?=.{8,}$).*$");
        if (!min8characters.test(value)) return rule.message || msgRuleMin8Characters();
        const min1uppercase = new RegExp("^(?=.*[A-Z]).*$");
        if (!min1uppercase.test(value)) return rule.message || msgRuleMin1Uppercase();
        const min1lowercase = new RegExp("^(?=.*[a-z]).*$");
        if (!min1lowercase.test(value)) return rule.message || msgRuleMin1Lowercase();
        const min1number = new RegExp("^(?=.*[0-9]).*$");
        if (!min1number.test(value)) return rule.message || msgRuleMin1Number();
        const min1special = new RegExp("^(?=.*[!@#$%^&*]).*$");
        if (!min1special.test(value)) return rule.message || msgRuleMin1Special();
    }

    if(rule.custom){
        if(rule.custom(value) === false) return rule.message  
    }


    return null;

}

export const validateValue = (value: any, rules: RelaxRule[]): ReactNode[] | null => {
    //console.log("Validation: ", value, " with rules: ", rules);

    if (!rules) return null;

    const errors: any[] = [];

    for (const rule of rules) {
        const result = validateRule(value, rule);
        if (result) errors.push(result);
    }

    if (errors.length === 0) return null;

    return errors;
}


export const findErrorsToInjectInList = (value: any, rules: any[]): any[] => {

    if (!rules) return [];

    const e:any[] = [];

        if (rules.find((r: any) => r.noDuplicates)) {

            value && value.forEach((v: any, index: number) => {
                if (value && value.filter((v2: any) => v2 === v).length > 1) {
                    e[index] = msgRuleNoDuplicates();
                } else {
                    e[index] = null;
                }
            });

        }

    return e;
}