import { Input, Spin } from "antd";
import React, { useMemo } from "react";
import { Suspense, useEffect } from "react";
import { useCreationEditor } from "tools/creationEditor/CreationEditor";
import CroppedImage from "tools/gallery/CroppedImage";
import GalleryItemDisplay from "tools/gallery/GalleryItemDisplay";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import { EditorSectionCTA, EditorSectionDescription, EditorSectionMedia, EditorSectionMessage, EditorSectionPublication, EditorSectionTitle } from "../editorSections/EditorSections";
import EditorSectionsCollapse from "../editorSections/EditorSectionsCollapse";
import CreationTargetSelector from "../editorTools/CreationTargetSelector";
import FieldMessage, { FieldCTA, FieldDescription, FieldMediaSingle, FieldPostType, FieldTitle } from "../Fields";
import EditorWithStages from "./EditorWithStages";
import useDataPrefill from "services/hooks/useDataPrefill";
import { Creation, creationContentSelector, patchCreation } from "../creationsSlice";
import { patchCreationWithSmartAction } from "../creationsSlice"

const EditorNormalPost = (props: any) => {

    const { creation, subtype, creationId } = useCreationEditor();
    const messangerDataPrefill = useDataPrefill({
        dataSelector: (state: any) => creationContentSelector(state, creationId),
        callAPI: patchCreationWithSmartAction(creationId, "ce_ctas"),
        checkFunction: (data: any) => {
            if (!data) return false;
            if (data?.type !== "messenger") return false;
            if (!data?.cta) return {
                content: {
                    cta: {
                        type: "MESSAGE_PAGE"
                    }
                }
            }
            return false;
        }
    })

    const cta = useMemo(() => {
        if (!subtype) return [];
        if (subtype === "video") return ['SUBSCRIBE', 'ADD_TO_CART', 'BUY_NOW', 'LEARN_MORE', 'SIGN_UP', 'DOWNLOAD', 'SHOP_NOW', 'BOOK_TRAVEL']
        if (subtype === "messenger") return ["MESSAGE_PAGE"];
        if (subtype === "image") return ['SUBSCRIBE', 'ADD_TO_CART', 'BUY_NOW', 'LEARN_MORE', 'SIGN_UP', 'DOWNLOAD', 'SHOP_NOW', 'BOOK_TRAVEL']

    }, [subtype])

    const ctaField = useMemo(() => {
        if (!subtype) return null;
        if (subtype === "image") return null;
        return <EditorSectionCTA actions={cta} />
    }, [subtype])

    return (
        <div>
            <EditorSectionsCollapse>
                {subtype !== "image" && <EditorSectionTitle />}
                <EditorSectionMessage />
                <EditorSectionMedia />
                {subtype === "link" && <EditorSectionDescription />}
                {ctaField}
                <EditorSectionPublication />
            </EditorSectionsCollapse>
        </div>
    )

}

const EditorNormalPostWithStages = (props: any) => {

    const { creation } = useCreationEditor();

    return (
        <EditorWithStages
            stages={[
                {
                    name: "type",
                    content: <FieldPostType />,
                    fulfilled: (creation: Creation) => {
                        if (!creation) return false;
                        if (!creation.content) return false;
                        if (creation.content.type) return true;
                        return false
                    }
                },
                {
                    name: "target",
                    content: <CreationTargetSelector />,
                    fulfilled: (creation: Creation) => {
                        if (creation.content.provider && creation.content.provider.length > 0) return true;
                        return false;
                    }
                },
                {
                    name: "editor",
                    content: <EditorNormalPost />,
                    fulfilled: (creation: Creation) => false
                }
            ]}
        />
    )

}

export default EditorNormalPostWithStages;