import { Link, Route, Router, Switch } from "react-router-dom"
import FormTesting from "./FormTesting/FormTesting"
import useAPI, { callAPIProps } from "services/hooks/useAPI"
import userSettingsSlice, { userSettingsTestSelector } from "state/user/userSettingsSlice"
import useSelectorWithParams from "services/hooks/useSelectorWithParams"
import { useEffect } from "react"
import StandardButton from "components/common/StandardButton"

const patchUserSettings: callAPIProps = {
    url: ({ getApiUrl }) => getApiUrl("user/settings"),
    method: "PATCH",
    successDispatch: userSettingsSlice.actions.update,
}


const initializeTesting: callAPIProps = {
    url: ({ getApiUrl }) => getApiUrl("user/settings"),
    method: "POST",
    successDispatch: userSettingsSlice.actions.update,
    body: {
        settings: {
            testing: {
                initialized: true,
                singleField: "HelloWorld"
            }
        }
    }
}


const getTesting: callAPIProps = {
    url: ({ getApiUrl }) => getApiUrl("user/settings"),
    method: "GET",
    successDispatch: userSettingsSlice.actions.update,
}

const routes = [
    {
        path: "/panel/tests/forms",
        component: FormTesting,
    },
]


export default function TestIndex() {

    const initializeTestingCall = useAPI(initializeTesting)
    
    const getTestingCall = useAPI(getTesting)
    const testValuesSelector = useSelectorWithParams(userSettingsTestSelector)

    useEffect(() => {
        getTestingCall.call();
    }, []);

    useEffect(() => {
        if (!testValuesSelector) initializeTestingCall.call();
    }, []);

    if (!testValuesSelector) return (<div>Loading...</div>);

    return (
        <div id="test-index">
            <Switch>
                {routes.map((route, i) => (
                    <Route key={i} path={route.path} component={route.component} />
                ))}
                <Route>
                    <ul>
                        {routes.map((route, i) => (<Link to={route.path}><li>{route.path}</li></Link>))}
                    </ul>
                </Route>
            </Switch>
            <StandardButton onClick={() => initializeTestingCall.call()}>Reset</StandardButton>
        </div>
    )

}