import React, { useEffect, useState, useRef, useMemo } from "react";
import { Input, Select, Space, Tooltip } from "antd"
import useAPI, { callAPIProps } from "services/hooks/useAPI"
import FormattedMessage from "components/common/FormattedMessage";
import { getApiUrl } from "services/helpers/api-helpers"
import DashboardBox from "_components/dashboard/box"
import { userCustomEmailSelector, userCustomEmailsOfTypeSelector } from "modules/panel/config/selectors/user";
import useAPIMultiRequest from "services/hooks/useAPIMultiRequest";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import _ from "lodash";
import { TooltipIcon } from "components/common/tooltip-icon/TooltipIcon";


const labels = {
  "casual": {
    label:
      <FormattedMessage
        id={"panel.profile.emails.casual.label"}
        defaultMessage={"Casual alerts"}
      />
    ,
    tooltip:
      <FormattedMessage
        id={"panel.profile.emails.casual.tooltip"}
        defaultMessage={"Casual alerts for normal communication"}
      />
  },
  "critical": {
    label: 
    <FormattedMessage
      id={"panel.profile.emails.critical.label"}
      defaultMessage={"Critical alerts"}
    />
  ,
  tooltip: 
    <FormattedMessage
      id={"panel.profile.emails.critical.tooltip"}
      defaultMessage={"Critical alerts for hacking attepts or loss of access."}
    />
  },
  "webinar": {
    label: 
    <FormattedMessage
      id={"panel.profile.emails.webinar.label"}
      defaultMessage={"Webinar alerts"}
    />
  ,
  tooltip:
    <FormattedMessage
      id={"panel.profile.emails.webinar.tooltip"}
      defaultMessage={"Invitation to webinars, tutorials and premium materials."}
    />
  },
  "invoice": {
    label:
    <FormattedMessage 
      id={"panel.profile.emails.invoice.label"}
      defaultMessage={"Invoice alerts"}
    />
  ,
  tooltip:
    <FormattedMessage
      id={"panel.profile.emails.invoice.tooltip"}
      defaultMessage={"Invoices and related communication."}
    />
  },
  "review": {
    label:
    <FormattedMessage 
      id={"panel.profile.emails.review.label"}
      defaultMessage={"Reviews"}
    />
  ,
  tooltip:
    <FormattedMessage
      id={"panel.profile.emails.review.tooltip"}
      defaultMessage={"Reviews"}
    />
  }
}

const isValidEmail = (email: string) => {
    return email.includes("@") && !email.includes(" ")
  }

const newEmail = (email: string, type: string): callAPIProps => ({
    url: ({ getApiUrl, userId }) => getApiUrl(`user/emails/${userId}`),
    method: "POST",
    body: {
      email,
      type
    },
    dispatchName: "POST_USER_CUSTOM_EMAIL"
  })

const deleteEmail = (emailId: string): callAPIProps => ({
    url: ({ getApiUrl, userId }) => getApiUrl(`user/emails/${emailId}`),
    method: "DELETE",
    dispatchName: "DELETE_USER_CUSTOM_EMAIL",
    passToDispatcher: { id: emailId }
  })

export const UserEmailsOfType = (props: any) => {

    const { newRequest } = useAPIMultiRequest({});
    const currentEmailsObjects = useSelectorWithParams([userCustomEmailsOfTypeSelector, props.type])
    const [emails, setEmails] = useState<string[]>(currentEmailsObjects.map((email: any) => email.email))

    const savedEmails = useMemo(() => currentEmailsObjects.map((email: any) => email.email), [currentEmailsObjects])

    useEffect(() => {
      if (!_.isEqual(emails, savedEmails)) {
        setEmails(savedEmails)
      }
    }, [savedEmails]);

    const handleChange = (value: string[]) => {
      //split by new line
      const emailsArray = [...new Set(value.map(v => v.replace(/\n/g, " ").split(" ")).flat())]


      const correctEmails = emailsArray.filter(isValidEmail)
      //find new emails
      const newEmails = correctEmails.filter((email: string) => !savedEmails.includes(email))
      newEmails.forEach(email => {
        newRequest(newEmail(email, props.type))
      })

      //find deleted emails
      const deletedEmails = savedEmails.filter((email: string) => !correctEmails.includes(email))

      deletedEmails.forEach(email => {
        const emailObject = currentEmailsObjects.find((emailObject: any) => emailObject.email === email)
        if (emailObject) {
          newRequest(deleteEmail(emailObject.id))
        }
      })

      setEmails((emailsArray || []).filter(isValidEmail))
    }

    const {label, tooltip} = (labels as any)[props.type] || {
      label: "",
      tooltip: ""
    }


    return (
      <Tooltip title={tooltip}>
      <Space direction="vertical" style={{ width: "100%" }}>
      <span>{label}</span>
      <div style={{ 
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "10px"
        }}>
        <Select
          mode="tags"
          onChange={handleChange}
          value={emails}
          style={{width: "100%"}}
        />
      <TooltipIcon title={tooltip}/>
      </div>
      </Space>
      </Tooltip>
    )
  }


export default function UserEmails() {

  const { data, loading } = useAPI({
    url: ({ userId }) => getApiUrl(`user/emails/${userId}`),
    method: "GET",
    auth: true,
    dispatchName: "GET_USER_EMAILS"
  }, true)

  if (loading || !data) {
    return (
      <div>
        Loading...
      </div>
    )
  }


  return (
    <>
      <UserEmailsOfType type="casual" />
      <UserEmailsOfType type="critical" />
      <UserEmailsOfType type="webinar" />
      <UserEmailsOfType type="invoice" />
      <UserEmailsOfType type="review" />
    </>
  )

}

export const UserEmailsDashboardBox = () => {
  return (
    <DashboardBox
      title={
        <FormattedMessage
          id={"panel.profile.emails.title"}
          defaultMessage={"E-mail alerts"}
        />
      }
      description={
        <FormattedMessage
          id={"panel.profile.emails.description"}
          defaultMessage={
            "Stay up to date with all notifications. We grouped all of the most important types of notifications by priority."
          }
        />
      }
    >
      <UserEmails />
    </DashboardBox>
  )
}