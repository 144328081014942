import { Collapse, Empty } from "antd";
import SubscriptionContextProvider, { useSubscriptionContext } from "./SubscriptionContext";
import TemplateRow from "tools/infinityList/TemplateRow";
import { paymentColumns, subscriptionListColumns } from "./subscriptionListColumns";
import { useEffect, useMemo } from "react";
import { HorizontalCenter } from "components/common/Center";

type Props = {
  subscriptionColumns?: Column[]
}

type Column = {
    key: string;
    size: {};
    render: (item: any) => JSX.Element;
}

function SubscriptionListEntry(props:Props) {

  const {subscription} = useSubscriptionContext();

  // useEffect(() => {
  //   console.log('subscription', subscription);
  // }, [subscription]);

  const stripeInvoices = useMemo(() => {
    if (!subscription) return <HorizontalCenter><Empty /></HorizontalCenter>;
    if (!subscription.stripe_invoices) return <HorizontalCenter><Empty /></HorizontalCenter>;
    if (subscription.stripe_invoices.length === 0) return <HorizontalCenter><Empty /></HorizontalCenter>;

    return subscription.stripe_invoices.map((invoice: any) => {
      return (
        <TemplateRow key={invoice.id} item={invoice} columns={paymentColumns} 
          style={{
              paddingLeft: "5px"
          }}
        />
      )
    })

  }, [subscription])

    return (
          <div className={`infinity-item-wrapper`}>
              <Collapse
                expandIcon={() => null}
              >
                <Collapse.Panel header={
                  <TemplateRow item={subscription} columns={props.subscriptionColumns ? props.subscriptionColumns : subscriptionListColumns} />
                  } key={subscription.id}>
                    {stripeInvoices}
                </Collapse.Panel>
              </Collapse>
            </div>
      );
}


const SubscriptionListEntryWrapper = ({ subscription, subscriptionColumns }: { subscription: any ,subscriptionColumns?:Column[] }) => {
  return (
    <SubscriptionContextProvider subscriptionId={subscription.id}>
      <SubscriptionListEntry subscriptionColumns={subscriptionColumns}/>
    </SubscriptionContextProvider>
  )
}

export default SubscriptionListEntryWrapper;