import { Icon } from "@ant-design/compatible";
import { useMemo, useRef } from "react"

import { GoogleKeywordType } from "./KeywordsGoogle";
import { Keywords } from "./KeywordStrings";
import StandardButton from "components/common/StandardButton";
import InterestKeyword from "./InterestKeyword";
import AwesomeIcon from "components/common/AwesomeIcon";
import { faDialOff, faMinusCircle, faPlusCircle, faToggleOff, faTrash } from "@fortawesome/pro-light-svg-icons";
import FormattedMessage from "components/common/FormattedMessage";


export type KeywordProps = {
    keyword: GoogleKeywordType,
    enabled?: boolean,
    onToggleActive?: any,
    onTypeChange?: any,
}

export default function KeywordGoogle(props: KeywordProps) {

    const kwdRef:any = useRef(null);
    const isWide = (kwdRef.current) ? kwdRef.current.clientWidth > 120 : true


    const handleTypeChange = (target: "include" | "exclude" | "suggestion") => {
        props.onTypeChange && props.onTypeChange(props.keyword, target)
    }

    const handleToggleActive = () => {
        props.onToggleActive && props.onToggleActive(props.keyword, props.keyword.status === "enabled" ? false : true)
    }

    const actions = useMemo(() => {

        if (props.keyword.type === "suggestion") return [
            <button
                className="keyword-action exclude"
                onClick={() => handleTypeChange("exclude")}
            >
                <AwesomeIcon icon={faMinusCircle} className="keyword-icon"/>
                <label><FormattedMessage
                    id="KeywordsInput.exclude"
                    defaultMessage="Exclude"
                /></label>
            </button>,
            <button
                className="keyword-action include"
                onClick={() => handleTypeChange("include")}
            >
                
                <AwesomeIcon icon={faPlusCircle} className="keyword-icon"/>
                <label><FormattedMessage
                    id="components.forms.inputs.keyword.include"
                    defaultMessage="Include"
                /></label>
                
            </button>
        ]
    
        if (props.keyword.type === "include" || props.keyword.type === "exclude") {
            if (props.keyword.status === "paused") return [
                <button
                    className="keyword-action activate"
                    onClick={handleToggleActive}
                >
                    <AwesomeIcon icon={faToggleOff} className="keyword-icon"/>
                    <FormattedMessage
                        id="KeywordsInput.activate"
                        defaultMessage="Activate"
                    />
                </button>
            ]
            return [
                <button
                    className="keyword-action deactivate"
                    onClick={handleToggleActive}
                >
                    <AwesomeIcon icon={faToggleOff} className="keyword-icon"/>
                    <FormattedMessage
                        id="KeywordsInput.deactivate"
                        defaultMessage="Deactivate"
                    />
                </button>
            ]
        }

    }, [props.keyword, props.enabled])

    if (!props.keyword) return null;
    
    const {type, status} = props.keyword;

    const getClassName = () => {
        if (status === "paused") return ("paused")
        return type
    }   

    return (
        <InterestKeyword 
            keyword={{
                name: props.keyword.label,
            }} 
            className={getClassName()}
            actions={actions}
            modalContent={<div>Modal</div>}
            tooltipContent={null}
        />
    )

}

