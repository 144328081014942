import { faDotCircle } from "@fortawesome/pro-light-svg-icons";
import { Statistic } from "antd";
import AwesomeIcon from "components/common/AwesomeIcon";
import FormattedMessage from "components/common/FormattedMessage";
import { GetConversionIconAndName } from "../displays/Conversions/ConversionsDisplay";



const getActionTypeName = (action_type:string) => {

    if (!action_type) return "-";
    switch (action_type) {
        case "lead":
            return <FormattedMessage
                id="posts.result.lead"
                defaultMessage="Lead"
                />
        case "landing_page_view":
            return <FormattedMessage
                id="posts.result.landingPageView"
                defaultMessage="Landing Page View"
                />
        case "post_engagement":
            return <FormattedMessage
                id="posts.result.postEngagement"
                defaultMessage="Post Engagement"
                />
        case "video_view":
            return <FormattedMessage
                id="posts.result.videoView"
                defaultMessage="Video View"
                />
        case "link_click":
            return <FormattedMessage
                id="posts.result.linkClick"
                defaultMessage="Link Click"
                />
    }

    const fromConversion = GetConversionIconAndName(action_type);
    return fromConversion?.name || action_type;

}

export default function ResultDisplay (props:any) {

    if (!props.ad) return null
    if (!props.ad.insights) return null

    const {action_type, actions, cost_per_action_type} = props.ad.insights;

    if (!action_type) return null;

    return (
        <div 
        className="result-display"
        >
            {actions} / {cost_per_action_type.toFixed && cost_per_action_type.toFixed(3)}
            <div className="result-subscript type">{getActionTypeName(action_type)}</div>
        </div>
    )
}