import { useEffect, useRef, useState } from "react";


//Will call the callback after the next render

export default function useCallAfterUpdate(callback: (...args:any[]) => void) {
    
    const [request, setRequest] = useState<number>(0);
    const val = useRef();

    useEffect(() => {
        if (request) {
            if (val.current && Array.isArray(val.current)) {
                callback(...val.current as any)
            } else {
                callback(val.current);
            }
        }
    }, [request]);
    
    const requestCall = (...v:any) => {
        val.current = v;
        setRequest(r => r + 1);
    }

    return requestCall;

}