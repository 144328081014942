import { ProductConfig } from "products";
import logo from "products/callMe/logo.svg";
import requirements from "modules/call/config/requirements";

const config: ProductConfig = {
  name: "CallMe",
  logo,
  requirements: requirements,
  providers: ["facebook"],
};

export default config;
