import { Spin } from "antd";
import { HorizontalCenter } from "components/common/Center";
import { useOnboarding } from "providers/onboarding/onboarding-context";
import { useEffect } from "react";
import useAPI from "services/hooks/useAPI"
import { UpdateProjectStatus } from "state/projects/projectEndpoints"

const OnboardingUpdateProjectStatus = () => {

    const statusCall = useAPI(UpdateProjectStatus)
    const onboarding = useOnboarding();

    useEffect(() => {
        statusCall.call().then((res: any) => {
            onboarding.nextStep();
        })
    }, []);

    return (
        <div>
            <HorizontalCenter><Spin /></HorizontalCenter>
        </div>
    )

}

export default OnboardingUpdateProjectStatus;