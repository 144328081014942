import Action from "services/helpers/create-action";
import { userLanguageSelector } from "modules/panel/config/selectors/user";

export const getMarketingConsents = new Action(
  "getMarketingConsents",
  (body, getState) => ({
    method: "GET",
    endpoint: `${
      import.meta.env.VITE_INSTANCE_MARKETING_CONSENTS_DIRECTORY_URL
    }/${userLanguageSelector(getState())}.json`,
    modifyResponse: (data) => ({
      content: data.content,
    }),
  })
);

export const getTermsOfServiceStatement = new Action(
  "getTermsOfServiceStatement",
  (body, getState) => ({
    method: "GET",
    endpoint: `${
      import.meta.env.VITE_INSTANCE_TERMS_OF_SERVICE_DIRECTORY_URL
    }/${userLanguageSelector(getState())}.json`,
    modifyResponse: (data) => ({
      content: data.content,
    }),
  })
);
