import { DeleteOutlined } from "@ant-design/icons";
import StandardButton from "components/common/StandardButton";
import { useEffect, useMemo, useState } from "react";
import useAPI, { APIErrorDisplay } from "services/hooks/useAPI";
import { StandardModal, useStandardModal } from "services/hooks/useModal";
import { usePost } from "../postContext";
import PostCard from "./PostCard";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import FormattedMessage from "components/common/FormattedMessage";

export default function DeletePostWithConfirmation (props:any) {

    const { post, closeDetails, markChangesMade} = usePost();
    const [ref, open, close] = useStandardModal();
    const {call, data, loading, error, clear} = useAPI({});

    useEffect(() => {
        clear();
    }, []);

    const handleOpen = () => {
        clear();
        open();
    }

    const handleOk = () => {
        if (loading) return;
        if (error || data) {
            close();
            return;
        }   
        handleDelete();
    }

    const handleDelete = () => {
        if (!post) return;
        call(post.actionRemovePost).then((res) =>{
            if(res.status === 200 || res.status === 204 ) close()
        })
    }


    const modalContent = useMemo(() => {

        if (loading) return "Loading...";
        if (error) return (
            <>
            <APIErrorDisplay error={error} />
            </>
        );
        if (data) return "Success";

        return (
            <>
            <PostCard />
            <div style={{padding: "20px"}}>
                <FormattedMessage 
                    id="posts.tools.delete.confirmation"
                    defaultMessage="Are you sure you want to delete this post?"
                />
            </div>
            </>
        )

    }, [loading, error, data, post]);



    if (!post) return null;

    return (
        <>
        <StandardButton onClick={handleOpen} icon={faTrash}>
            <FormattedMessage 
                id="posts.tools.delete.button"
                defaultMessage="Delete Post"
            />
        </StandardButton>
        <StandardModal
            ref = {ref}
            onCancel = {close}
            modalProps={{
                footer: [
                        <StandardButton 
                        onClick={handleOk}
                        type="primary"
                        disabled={loading}
                        >
                            <FormattedMessage
                                id="common.ok"
                                defaultMessage="Ok"
                            />
                        </StandardButton>
                ]
            }}
        >
            <>
            {modalContent}
            </>
        </StandardModal>
        </>
    )

}