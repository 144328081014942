import StandardButton from "components/common/StandardButton";
import _ from "lodash";
import React, { useContext, useEffect, useMemo, useState, useImperativeHandle, useRef, ReactNode} from "react"
import { APIErrorDisplay } from "services/hooks/useAPI";
import { RelaxContainerContext } from "./RelaxContainerContext";
import RelaxErrors from "./RelaxError";
import { RelaxFieldProps } from "./relaxTypes";
import useMemoDeepCompare from "services/hooks/useMemoDeepCompare";
import useRelaxWrapper from "./useRelaxWrapper";
import RelaxDebugView from "./RelaxDebugView";

export type RelaxFormProps = Omit<RelaxFieldProps, "name"> & {
    submitButton?: ReactNode,
    manualSubmit?: boolean,
}

const RelaxForm = React.forwardRef(({children, relaxData, ...props}: RelaxFormProps, ref: React.Ref<any>) => {

    const rd = relaxData
    
    const {
        currentValue, 
        setCurrentValue,
        hasErrors,
        errors,
        settings,
        hasParentContext, 
        parentContext,
        optimisticValue,
        submit,
        extraStatus,
        setExtraStatus,
    } = rd; 

    useEffect(() => {
        rd.setBackFilledProps(props);
    }, []);

    const [fieldErrors, setFieldErrors] = useState<any>(null);
    const hasFieldErrors = Object.values((fieldErrors || {})).some((e: any) => e);
    const registeredFields = useRef<Map<string, any>>(new Map());
  
    useEffect(() => {
        if (hasFieldErrors) {
            setExtraStatus("fieldErrors") 
        } else {
            setExtraStatus(null)
        }
    }, [hasFieldErrors]);

    const { loading: apiLoading, data: apiData, error: apiError, clear: apiClear} = rd.apiHandler;

    const registerField = (field: any) => {
        //console.log("Registering field", field)
        
        if (field && field.current) {
            const f = field.current.name;
            registeredFields.current.set(f, field);
        }
    }

    const unregisterField = (field: any) => {
        //console.log("Unregistering field", field)
        //registeredFields.current.delete(field);
    }

    const tryToSubmit = (m:string) => {
        const validation = validateFields();
        const hasErrors = validation.some((v: any) => v);
        if (hasErrors) {
           // console.log("Has errors, not submitting");
            return;
        }
        submit(m);
    }

    const manualSubmit = () => {
        Array.from(registeredFields.current).forEach(([k, v]) => v.current.forceUpdate());
        tryToSubmit("manual");
    }

    const validateFields = () => {
        //console.log("Validate fields", registeredFields.current)
        return Array.from(registeredFields.current).map(([k, v]) => v.current.validate());
    }

    const handleFieldChange = (field: any, value: any) => {
        //console.log("handleFieldChange", field, value)
        setCurrentValue((cv:any) => {
            cv = cv || {};
            cv = _.set(cv, field, value);
            //console.log("new cv", cv)
            return _.cloneDeep(cv)
        });
    }

    const handleFieldBlur = (field: any) => {
        if (props.mode === "manual") return;
        submit();
    }

    const handleFieldErrors = (field: any, fe: any) => {

       //console.log("handleFieldErrors", field, fe)

        const fn = typeof field === "string" ? field : field.join(".");

        setFieldErrors((oldValue: any) => _.cloneDeep(_.set(oldValue || {}, fn, fe)));
    }

    useEffect(() => {
        if (hasParentContext && parentContext.handleFieldErrors) parentContext.handleFieldErrors(settings.name, hasFieldErrors);
    }, [fieldErrors]);

    const updateAllFields = (newData: any) => {
        Array.from(registeredFields.current).forEach(([k, v]) => {
            const newValue = _.get(newData, k);
            if (newValue) v.current.setValueAndSend(newValue);
        });
        setCurrentValue(newData);
    }

    useImperativeHandle(ref, () => ({
        ...rd, 
        manualSubmit,
        updateAllFields
    }))

    const adjSettings = useMemoDeepCompare(() => {
        if (settings.mode === "manual") return {...settings, disableSubmit: true};
        return settings
    }, [settings])



    return (
        <RelaxContainerContext.Provider value={{
            settings: adjSettings,
            //data: rd,
            handleFieldChange, 
            handleFieldBlur,
            handleFieldErrors,
            registerField, 
            unregisterField,
        }}>
            {children}
            <RelaxErrors errors={errors} />
            {settings.debug && <RelaxDebugView relaxData={rd} />}
            <APIErrorDisplay error={apiError} />
        </RelaxContainerContext.Provider>
    )

})

const RelaxFormWrapper = React.forwardRef(({children, ...props}: any, ref:any) => {

    const adjustedProps = {
        ...props, 
        submitOnChange: false, 
        ref: ref,
    }

    const {relaxData: _relaxData, ref: _ref} = useRelaxWrapper(adjustedProps);

    if (!_relaxData) return null;

    return (
        <RelaxForm ref={_ref} relaxData={_relaxData} {...adjustedProps} submitOnChange={false}> 
            {children}
        </RelaxForm>
    )

})

export default RelaxFormWrapper;