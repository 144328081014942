import AOA from "./aoa";
import Asset from "./asset";
import Creation from "./creation";
import Lead from "./lead";
import Post from "./post";

export function constructAsset(asset: any, type: string) {
    switch (type) {
        case "post":
            return new Post(asset);
        case "aoa":
            return new AOA(asset)
        case "leadads": 
            return new Lead(asset)
        case "creation":
            return new Creation(asset)
        case "asset":
            return new Asset(asset)
        default:
            return new Asset(asset)
    }
}