import { Checkbox } from "antd";
import StandardButton from "components/common/StandardButton";
import { useMemo, useState } from "react";
import FormattedMessage from "components/common/FormattedMessage";
import { KeywordsConfig, KeywordType } from "modules/panel/config/KeywordsConfig";
import { StandardModal, useStandardModal } from "services/hooks/useModal";
import { usePost } from "../postContext";
import { TaskList, TaskProps, useTasks } from "services/hooks/useTasks";
import _ from "lodash";
import { StandardFullColumn, StandardHalfFullColumn, StandardRow } from "components/layout/StandardGrid";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { TooltipIcon } from "components/common/tooltip-icon/TooltipIcon";
import { AllAdsAndRemarkettingKeywords } from "modules/smart/smartSlice";

const PromotionState = (props: any) => {

    const [ref, tasks, addTasks] = useTasks();
    const { post } = usePost();

    const getTasks = (): TaskProps[] => {
        return props.keywords.map((keyword: any) => {
            return {
                displayName: keyword,
                autoStart: true,
                task: () => post.publishAdToFacebookPost(keyword)
            }
        })
    }

    return (
        <div>
            <TaskList
                ref={ref}
                tasks={getTasks()}
                onStateChange={(state) => {
                    if (props.onTasksStateChange) props.onTasksStateChange(state);
                }}
            />
        </div>
    )
}

export default function PromotePost() {

    const keywords = useSelectorWithParams(AllAdsAndRemarkettingKeywords);
    const [ref, open, close] = useStandardModal();
    const [selectedKeywords, setSelectedKeywords] = useState<string[] | null>(["initial_keyword"]);
    const [attempt, setAttempt] = useState(0);
    const keywordTypes = Array.from(KeywordsConfig.keys())
    const [promotionState, setPromotionState] = useState<string>("idle");
    const { reloadList } = usePost();

    const getKeywordCheckbox = (keyword: string) => {

        if (!keyword) return null;
        if (!keywords) return null;

        const config = KeywordsConfig.get(keyword as KeywordType);
        if (!config) return null;

        const kwdValue = keywords.initial_keywords[config.keyword.name || ""];
        const rmkKwdValue = keywords.initial_keywords[config.remarketing?.name || ""];

        if (!kwdValue) return null;

        if (config.remarketing && rmkKwdValue) {
            return (
                <StandardRow key={keyword} style={{ width: "100%", paddingBottom: "10px" }}>
                    <StandardHalfFullColumn>
                    {config.keyword.displayName}
                    <div style={{display:"flex"}}>
                        <Checkbox value={config.keyword.name}>{kwdValue}</Checkbox>
                        <TooltipIcon
                            title={config.keyword.tooltip}
                        />
                        </div>
                       
                    </StandardHalfFullColumn>
                    <StandardHalfFullColumn>
                        {config.remarketing.displayName}
                        <div style={{display:"flex"}}>
                        <Checkbox value={config.remarketing.name}>{rmkKwdValue}</Checkbox>
                        <TooltipIcon
                            title={config.remarketing.tooltip}
                        />
                        </div>
                    </StandardHalfFullColumn>
                </StandardRow>
            )
        } else {
            return (
                <StandardRow key={keyword} >
                    <StandardFullColumn>
                        {config.keyword.displayName}
                        <div style={{display:"flex"}}>
                        <Checkbox value={config.keyword.name}>{kwdValue}</Checkbox>
                        <TooltipIcon
                            title={config.keyword.tooltip}
                        />
                        </div>
                    </StandardFullColumn>
                </StandardRow>
            )
        }

    }

    const mappedKeywords = useMemo(() => {
        return keywordTypes.map((keyword) => {
            return getKeywordCheckbox(keyword);
        })
    }, [keywordTypes])


    return (
        <>
            <Checkbox.Group className="standard-checkbox vertical" defaultValue={["initial_keyword"]} onChange={(c) => setSelectedKeywords(c as any)}>
                {mappedKeywords}
            </Checkbox.Group>
            <div style={{ height: "20px" }}></div>
            <StandardButton
                type="primary"
                disabled={!selectedKeywords || selectedKeywords.length == 0}
                onClick={() => {
                    setAttempt(attempt => attempt + 1);
                    open();
                }}>
                <FormattedMessage
                    id="smart.components.posts"
                    defaultMessage="Promote!"
                />
            </StandardButton>
            <StandardModal
                ref={ref}
                title="Publishing post"
                onCancel={() => {
                    reloadList();
                    close();
                }}
                onOk={() => {
                    reloadList();
                    close();
                }}
                modalProps={{
                    closable: false,
                    maskClosable: false,
                    footer: [
                        <StandardButton
                            onClick={() => {
                                reloadList();
                                close();
                            }}
                            type="primary"
                            disabled={promotionState === "idle" || promotionState === "pending"}
                        >Ok</StandardButton>
                    ]
                }}
            >
                <>
                    <PromotionState
                        keywords={selectedKeywords ? [...selectedKeywords] : []} key={attempt}
                        onTasksStateChange={(state: string) => {
                            setPromotionState(state);
                        }}
                    />
                </>
            </StandardModal>
        </>
    )
}