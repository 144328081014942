import { Button, Space, Spin } from "antd"
import {HorizontalCenter} from 'components/common/Center';
import { AddRoom, GetRooms, ProjectRoomsSelector } from "modules/site-generator/reducers/roomsSlice"
import { useEffect, useMemo } from "react"
import useAPI from "services/hooks/useAPI"
import useSelectorWithParams from "services/hooks/useSelectorWithParams"
import { GetIndustryData, industrySelector } from "state/industries/industriesSlice"
import { GridDivider } from "_components/grid/StandardGrid"
import Room, { RoomListEntry } from "./room"
import StandardButton from "components/common/StandardButton"

export default function RoomList (props:any) {

    const {data} = useAPI(GetRooms(),true)
    const rooms = useSelectorWithParams([ProjectRoomsSelector]);

    const {call: apiAdd_call} = useAPI(AddRoom())

    const {call: industryApiCall, data: categories} = useAPI(GetIndustryData("touristic"));
    const industry = useSelectorWithParams([industrySelector, "touristic"])

    useEffect(() => {
        if (!industry) {
            industryApiCall();
        }
    }, []);


    const mappedRooms = useMemo(() => {
        if (!rooms) 
        return (
        <div>
            
        </div>
        )
        //return rooms.map((r: any) => <div>{r.id}</div>)
        return Object.values(rooms).map((r: any) => (
        <RoomListEntry roomId={r.id} />
        ))
    }, [rooms])
    
    const handleAddRoom = () => {
        apiAdd_call();
    }

    if (!industry || !rooms) {
        return (
            <HorizontalCenter style={{padding: "20px"}}><Spin size="large" /></HorizontalCenter>
        )
    }

    return (
        <>
        <StandardButton onClick={handleAddRoom}>Add Room</StandardButton>
        <div style={{padding: "20px"}}></div>
        <Space wrap>
            {mappedRooms}
        </Space>
        </>
    )

}