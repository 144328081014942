import FormattedMessage from "components/common/FormattedMessage";
import { useEffect } from "react";
import useAPI from "services/hooks/useAPI";
import useCallAfterUpdate from "services/hooks/useCallAfterUpdate";
import { useSimpleModalWithAction } from "services/hooks/useModal";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { NewGroup, OrganizationGroupSelector, OrganizationGroupsSelector,OrganizationProjectsSelector,  OrganizationIdSelector, PatchGroup } from "../data/organizationSlice";
import useOrganization from "./useOrganization";


const getGroupProjects = (group:any) => {
    return group.projects.map((project:any) => project.id)
}

const getGroupManagers = (group:any) => {
    return group.managers.map((manager:any) => manager.id)
}

export default function useOrganizationGroupHelper (props?:any) {

    const {groups, projects} = useOrganization();
    const patchGroup = useAPI({})
    const [open, contextHolder] = useSimpleModalWithAction({
    });


    const newGroup = (name:string) => {
        patchGroup.call({
            ...NewGroup(),
            body: {
                name
            }
        })
    }

    const addProjectToGroup = (projectId:string | number, groupId: string | number) => {
        const group = groups.find((group:any) => group.id === groupId)
        if (!group) return;

        const gp = getGroupProjects(group);
        if (gp.includes(projectId)) return;

        const newProjects = [...gp, projectId]
        patchGroup.call({
            ...PatchGroup(groupId),
            body: {
                projects: newProjects
            }
        })
    }

    const getGroupManagersWithNewManager = (groupId:string | number, managerId:string | number) => {
        if (!groupId) return;
        if (!managerId) return;
        const group = groups.find((group:any) => group.id === groupId)
        if (!group) return;
        return [...getGroupManagers(group), managerId]
    }

    const getGroupManagersWithoutManager = (groupId:string | number, managerId:string | number) => {
        if (!groupId) return;
        if (!managerId) return;
        const group = groups.find((group:any) => group.id === groupId)
        if (!group) return;
        return [...getGroupManagers(group).filter((id:string) => id !== managerId)]
    }

    const addManagerToGroup = (projectId:string | number, groupId: string | number) => {
        patchGroup.call({
            ...PatchGroup(groupId),
            body: {
                managers: getGroupManagersWithNewManager(groupId, projectId)
            }
        })
    }

    const removeManagerFromGroup = (projectId:string | number, groupId: string | number) => {
        patchGroup.call({
            ...PatchGroup(groupId),
            body: {
                managers: getGroupManagersWithoutManager(groupId, projectId)
            }
        })
    }


    const removeProjectFromGroup = (projectId:string | number, groupId: string | number) => {
        const group = groups.find((group:any) => group.id === groupId)

        const gp = getGroupProjects(group);
        
        if (!gp.includes(projectId)) return;

        const newProjects:string[] = gp.filter((id:string) => id !== projectId)

        patchGroup.call({
            ...PatchGroup(groupId),
            body: {
                projects: newProjects
            }
        })
    }

    const addAfterUpdate = useCallAfterUpdate(addProjectToGroup)
    const removeAfterUpdate = useCallAfterUpdate(removeProjectFromGroup)

    const askToRemoveProjectFromGroup = (projectId:string | number, groupId:string | number) => {

        if (!groupId) return;
        if (!projectId) return;

        const group = groups.find((group:any) => group.id === groupId)
        if (!group) return;
        
        const project = projects.find((project:any) => project.id === projectId)
        if (!project) return;
        const newProjects = group.projects.filter((project:any) => project.id !== projectId).map((project:any) => project.id)

        open({
            title: <FormattedMessage
                id="tools.organization.actions.removeProjectFromGroup.title"
                defaultMessage="Remove Project from Group"/>,
            content: <FormattedMessage
            id="tools.organization.actions.removeProjectFromGroup.confirmationMessage"
            defaultMessage="Are you sure you want to remove {project} from {group}?"
            values={{
                project: <b>{project.name}</b>,
                group: <b>{group.name}</b>
            }}
            />,
            onOk: () => {
            },
            callAPI: {...PatchGroup(groupId), body: {projects: newProjects}}
        })
    }

    const askToRemoveManagerFromGroup = (managerId:string | number, groupId:string | number) => {

        const group = groups.find((group:any) => group.id === groupId)
        if (!group) return;
        const managerProject = projects.find((project:any) => project.id === managerId)
        if (!managerProject) return;

        const newManagers = getGroupManagersWithoutManager(groupId, managerId)

        open({
            title: <FormattedMessage
                id="tools.organization.actions.removeManagerFromGroup.title"
                defaultMessage="Remove Manager from Group"/>,
            content: <FormattedMessage
            id="tools.organization.actions.removeManagerFromGroup.confirmationMessage"
            defaultMessage="Are you sure you want to remove {project} from {group}?"
            values={{
                project: <b>{managerProject.name}</b>,
                group: <b>{group.name}</b>
            }}
            />,
            onOk: () => {
            },
            callAPI: {...PatchGroup(groupId), body: {managers: newManagers}}
        })

    }

    return {
        addProjectToGroup: addAfterUpdate,
        removeProjectFromGroup: removeAfterUpdate,
        newGroup: newGroup,
        loading: patchGroup.loading,
        error: patchGroup.error,
        contextHolder,
        askToRemoveProjectFromGroup,
        askToRemoveManagerFromGroup,
    }

}