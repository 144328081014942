import { faEdit } from "@fortawesome/pro-light-svg-icons";
import { Table } from "antd";
import StandardActorCard from "components/common/StandardActorCard";
import StandardButton from "components/common/StandardButton";
import StandardAssetDrawer from "providers/assetDrawer/StandardAssetDrawer";
import { useMemo, useRef } from "react";
import { OrganizationProjectSelector, } from "../data/organizationSlice";
import { useOrganizationTool } from "../OrganizationManager";
import ProjectPage  from "../projectPage/ProjectPage";
import { GroupsOfProject } from "../tools/OrganizationToolHelpers";
import useOrganization from "../tools/useOrganization";
import useOrganizationGroupHelper from "../tools/useOrganizationGroupHelper";


type props = {
    onSelectedRows?: (selectedRows:string[]) => void
}


export default function OrganizationTableProjects(props:props) {

    const {projects, groups} = useOrganization();
    const projectDrawerRef = useRef<any>(null)

    const {inputData, setInputData} = useOrganizationTool();
    const {contextHolder, askToRemoveProjectFromGroup} = useOrganizationGroupHelper();
    
    const adjustedData = useMemo(() => {
        return projects;
    }, [projects, groups])

    const handleOpenProjectDrawer = (projectId:string) => {
        projectDrawerRef.current.open(projectId)
    }

    const adjustedColumns = useMemo(() => {
            return [
                {
                    title: "Project",
                    render: (text:any, record:any) => <StandardActorCard actor={record} />,
                    fixed: "left",
                    width: "300px"
                },
                {
                    title: "Location",
                    render: (text:any, record:any) => <p>{record?.serviceGoogleMyBusiness?.normalized_object?.title}</p>,
                },
                {
                    title: "Website",
                    render: (text:any, record:any) => <p>{record?.serviceGoogleMyBusiness?.normalized_object?.websiteUri}</p>,
                },
                {
                    title: "Groups",
                    render: (text:any, record:any) => <GroupsOfProject 
                    projectId={record.id} 
                    closable={true}
                    onClose={(group:any) => askToRemoveProjectFromGroup(record.id, group.id)}
                    />,
                }, 
                {
                    title: "",
                    fixed: "right",
                    width: "50px",
                    render: (text:any, record:any) => <StandardButton icon={faEdit} onClick={() => handleOpenProjectDrawer(record.id)}/>,
                }
            ]
    }, [projects, groups])

    const rowSelection = {
        type: "checkbox",
        onChange: (selectedRowKeys:any, selectedRows:any) => {
            setInputData((i:any) => ({...i, projects: selectedRowKeys}))
        },
        getCheckboxProps: (record:any) => ({
            disabled: false,
            name: record.id,
        }),
        selectedRowKeys: inputData?.projects || []
    }

    if (!adjustedData) return (
        <div>
            No data to display
        </div>
    )

    return (
        <div>
            {contextHolder}
            <Table 
                rowKey={record => record.id}
                columns = {adjustedColumns} 
                dataSource = {adjustedData}
                rowSelection = {rowSelection}
                pagination = {false}
                style={{
                    height: "100%"
                }}
                className="compact-table"
                />
            <StandardAssetDrawer
            ref={projectDrawerRef}
            assetSelector={OrganizationProjectSelector}
            title={(asset:any) => <StandardActorCard actor={asset} />}
            width="950px"
        >
                <ProjectPage />
            </StandardAssetDrawer>
        </div>
    )

}