import React, { ReactNode } from "react";
import { Avatar } from "antd";
import FormattedMessage from "components/common/FormattedMessage";

type Props = {
  title?: ReactNode | string;
  underline?: string;
  image?: string;
  icon?: ReactNode;
  className?: string;
};

export const PanelTopBarItem = ({
  title = (
    <FormattedMessage
      id="panel.projectSelect.project.emptyName"
      defaultMessage="Empty name"
    />
  ),
  underline = "-",
  className = "",
  image,
  icon,
}: Props) => (
  <div className={`panel-top-bar-item ${className}`}>
    <div className="avatar">
      <Avatar shape="square" size={40} src={image} />
    </div>
    <div className="details">
      <span className="label">{title}</span>
      <span className="role">{underline}</span>
    </div>
    {icon && <div className="icon">{icon}</div>}
  </div>
);

export default PanelTopBarItem;
