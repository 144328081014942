import FormattedMessage from "components/common/FormattedMessage";
import StandardButton from "components/common/StandardButton";
import { Tooltip } from "antd";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { Subscription } from "state/subscriptions/subscriptionTypes";
import {
  StandardModal,
  useStandardModal,
} from "services/hooks/useModal";
import useAPI, { APIErrorDisplay } from "services/hooks/useAPI";
import { forwardRef, useMemo } from "react";
import { projectWithSubscriptionsSelector, projectsSelector } from "state/projects/projectsSelectors";
import { faTrashCan } from "@fortawesome/pro-light-svg-icons";
import { Project } from "state/projects/projectTypes";
import { currentProjectIdSelector } from "services/store/scopeSlice";
import { DeleteProject } from "state/projects/projectEndpoints";

type modalProps = {
  projectId: string;
  onClose: () => void;
};

export const DeleteProjectButton = (props: { projectId: string }) => {
  const [ref, open, close] = useStandardModal();
  const project = useSelectorWithParams(projectWithSubscriptionsSelector(props.projectId));
  const projects: { [key: string]: Project } = useSelectorWithParams(projectsSelector);
  const currentProjectId = useSelectorWithParams(currentProjectIdSelector);


  const canBeDeleted = useMemo(() => {
    if (currentProjectId == props.projectId) return false;
    if (Object.keys(projects).length === 1) return false;
    if (project?.subscriptions) {
      return !project.subscriptions.some(
        (subscription: Subscription) => subscription.status === "active"
      );
    }
    return false;
  }, [project]);

  return (
    <>
      <Tooltip
        title={canBeDeleted ? "" :
          <FormattedMessage
            id="panel.components.project.delete.tooltip.disabled"
            defaultMessage="You can't delete the current project or a project with active subscriptions."
          />
        }
      >
        <div>
          <StandardButton
            type="primary"
            onClick={() => open()}
            disabled={!canBeDeleted}
            icon={faTrashCan}
          >
            <FormattedMessage
              id="panel.components.project.delete.label"
              defaultMessage="Delete Project"
            />
          </StandardButton>
        </div>

      </Tooltip>
      <DeleteProjectModal
        projectId={props.projectId}
        ref={ref}
        onClose={close}
      />
    </>
  )

};

export const DeleteProjectModal = forwardRef((props: modalProps, ref: any) => {
  const { call, error } = useAPI(DeleteProject(props.projectId));

  return (
    <>
      <StandardModal
        ref={ref}
        title={
          <FormattedMessage
            id="panel.containers.companySettings.attachSubscriptionModal.title"
            defaultMessage="Attach Subscription"
          />
        }
        onOk={() => {
          call().then((res) => {
            if (res.status === 200 || res.status === 204) {
              props.onClose();
            }
          });
        }}
      >
        <FormattedMessage
          id="panel.components.project.listItem.delete.modal.description"
          defaultMessage="Are you sure to remove the project?"
        />
        <div style={{ color: "red" }}>
          <APIErrorDisplay error={error} />
        </div>
      </StandardModal>
    </>
  );
});
