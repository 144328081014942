import FormattedMessage from "components/common/FormattedMessage"
import { useIntl } from "react-intl"

type PriceDisplayProps = {
    price: {
        net: number,
        gross: number,
        taxRate: number
    },
    currency: string,
    className?: string,
    style?: React.CSSProperties
}

type RecurringPriceDisplayProps = PriceDisplayProps & {
    billingCycle: string
}

const PriceDisplay = (props: PriceDisplayProps) => {

    const { formatNumber } = useIntl();

    const { net, gross, taxRate } = (() => {

        if (props.cycle == "yearly") return {
            net: props.price.net / 12,
            gross: props.price.gross / 12,
            taxRate: props.price.taxRate
        }

        return props.price

    })()   


    if (props.cycle === "yearly") return (
        <div className="price-display">
            <span className="net">
                {formatNumber(net, {
                    style: "currency",
                    currency: props.currency
                })}/<FormattedMessage 
                id="payments.common.perMonth" 
                defaultMessage="mo"
                /> 
            </span>
            <span className="gross">
            <FormattedMessage 
                id="payments.common.priceWithVAT" 
                defaultMessage="price with VAT"
                /> {" "}
                {formatNumber(gross, {
                    style: "currency",
                    currency: props.currency
                })}{" "}
            </span>
        </div>
    )


    return <div className="price-display">
        <span className="net">
            {formatNumber(net, {
                style: "currency",
                currency: props.currency
            })}
        </span>
        <span className="gross">
        <FormattedMessage 
                id="payments.common.priceWithVAT" 
                defaultMessage="price with VAT"
                /> {" "}
            {formatNumber(gross, {
                style: "currency",
                currency: props.currency
            })}
        </span>
    </div>

}

//{taxRate*100}% VAT


export default PriceDisplay;