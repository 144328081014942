import { ProductConfig } from "products";
import logo from "./logo.png";
import requirements from "modules/smart/config/requirements";
import { Requirement } from "modules";

const config: ProductConfig = {
  name: "Creator",
  logo,
  requirements: requirements as Requirement[],
  index: "/smart/posts/list",
};

export default config;
