import { Button, Col, Row, Spin } from "antd";
import { useEffect, useMemo, useState } from "react";
import useAPI from "services/hooks/useAPI";
import Keyword from "./KeywordFacebook";
import _ from "lodash";
import FormattedMessage from "components/common/FormattedMessage";
import { useDebounceTrigger } from "services/hooks/useDebounce";
import { TooltipIcon } from "components/common/tooltip-icon/TooltipIcon";
import KeywordSearchWithSuggestions from "./KeywordsSearchWithSuggestions";
import Center from "components/common/Center";
import "./keywords.less";
import { Text } from "components/common/StandardText";
import {
    faBriefcase,
    faGamepad,
    faGear,
    faGraduationCap,
    faHeart,
    faIdCard,
    faIndustry,
    faSchool,
} from "@fortawesome/pro-light-svg-icons";
import KeywordsAdvancedTable from "./KeywordAdvancedTable";
import { GetBehaviors, GetEstimate } from "modules/smart/smartSlice";
import AwesomeIcon from "components/common/AwesomeIcon";
import { KeywordFacebookGroupsConfig, KeywordGroup } from "./KeywordsConfig";
import KeywordsBoxWithTooltip from "./KeywordsBoxWithTooltip";

//interests, behaviors, eduschool, edumajor, workemployer, workposition

export const GetGroupConfig = (group: KeywordGroup) =>
    KeywordFacebookGroupsConfig[group] ||
    KeywordFacebookGroupsConfig["interests"];

export const KeywordsDemo = () => {
    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: "400px 100px 100px",
                gridTemplateRows: "repeat(10, 50px)",
                alignItems: "center",
            }}
        >
            interests - zainteresowania
            <AwesomeIcon icon={faHeart} size="2xl" />
            behaviors - zachowania
            <AwesomeIcon icon={faGamepad} size="2xl" />
            education_schools - edukacja(szkoła)
            <AwesomeIcon icon={faSchool} size="2xl" />
            education_majors - edukacja(wykształcenie)
            <AwesomeIcon icon={faGraduationCap} size="2xl" />
            work_employers - zatrudnienie(pracodawca)
            <AwesomeIcon icon={faBriefcase} size="2xl" />
            work_positions - zatrudnienie(stanowisko)
            <AwesomeIcon icon={faIdCard} size="2xl" />
            industries (branże) <div></div>
            <AwesomeIcon icon={faIndustry} size="2xl" />
        </div>
    );
};

export type KeywordsProps = {
    platform?: string;
    value?: any;
    onChange?: any;
    simpleModeOnly?: boolean;
};

export default function KeywordsFacebook(props: KeywordsProps) {
    const estimateCall = useAPI(GetEstimate());
    const [includes, setIncludes] = useState<any[]>([]);
    const [excludes, setExcludes] = useState<any[]>([]);
    const [mode, setMode] = useState<"normal" | "advanced">("normal");
    const [getEstimate] = useDebounceTrigger(estimateCall.call, 1000);

    const getBehaviorsCall = useAPI(GetBehaviors, true);

    const areEqual = (a: any, b: any) => {
        if ((!a && b) || (a && !b)) return false;
        if (a === b) return true;
        return _.isEmpty(_.differenceWith(a, b, (a: any, b: any) => a.id === b.id));
    };

    const safelyAddNewSelection = (ns: any) => {
        if (!ns || !ns.length) return;
        const newIncludes = ns
            .filter((k: any) => k.type === "include")
            .map((k: any) => ({
                ...k,
                id: k.id,
                name: k.name || k.label || "unknown",
                group: k.group || "interests",
                type: k.type,
            }));
        const newExcludes = ns
            .filter((k: any) => k.type === "exclude")
            .map((k: any) => ({
                ...k,
                id: k.id,
                name: k.name || k.label || "unknown",
                group: k.group || "interests",
                type: k.type,
            }));
        if (!areEqual(newIncludes, includes) || !areEqual(newExcludes, excludes)) {
            setIncludes(_.unionBy(includes, newIncludes, (i: any) => i.id));
            setExcludes(_.unionBy(excludes, newExcludes, (i: any) => i.id));
        }
    };

    useEffect(() => {
        getEstimate();
    }, [includes, excludes]);

    useEffect(() => {
        if (props.value) {
            safelyAddNewSelection(props.value);
        }
    }, [props.value]);

    const margeIncludesAndExcludes = () => {
        const merged: any[] = [];
        (includes || []).forEach((i: any) =>
            merged.push({
                ...i,
                id: i.id,
                label: i.name,
                type: "include",
                group: i.group,
            })
        );
        (excludes || []).forEach((i: any) =>
            merged.push({
                ...i,
                id: i.id,
                label: i.name,
                type: "exclude",
                group: i.group,
            })
        );
        return merged;
    };

    const getSuggestionsQuery = (): string => {
        let qString = "";

        const q: any = {};

        const serializeArray = (
            arrayName: string,
            propertyNames: string[],
            a: any[]
        ) => {
            let res = "";
            if (!a || !a.length) return "";
            a.forEach((item: any, index: number) => {
                propertyNames.forEach((p: string) => {
                    res += `${arrayName}[${index}][${p}]=${item[p]}&`;
                });
            });
            return res;
        };

        if (includes && includes.length)
            qString += serializeArray(
                "targeting_list",
                ["name", "id", "type"],
                (includes || []).map((s: any) => ({
                    name: s.name,
                    id: s.id,
                    type: s.group,
                }))
            );
        if (excludes && excludes.length) {
            if (qString && qString.length > 0) qString += "&";
            qString += serializeArray(
                "excludes",
                ["name", "id", "type"],
                (excludes || []).map((s: any) => ({
                    name: s.name,
                    id: s.id,
                    type: s.group,
                }))
            );
        }

        return qString;
    };

    //GET SUGGESTIONS
    const { call: getSuggestions, data: suggestions, loading: loadingSuggestion } = useAPI({
        url: ({ getApiUrl, serializeQuery, language, projectId }) =>
            getApiUrl(
                `services/facebook/${projectId}/suggestions?${getSuggestionsQuery()}`
            ),
        method: "GET",
        auth: true,
    });

    useEffect(() => {
        debounceAsk();
        props.onChange && props.onChange(margeIncludesAndExcludes());
    }, [includes, excludes]);

    const [debounceAsk] = useDebounceTrigger(getSuggestions, 500);

    const handleInclude = (v: any) => {
        //console.log("Handling include", v, excludes);
        if (!v || !v.id) return;
        if (excludes && excludes.find((e) => e.id === v.id)) {
            //console.log("Was Excluded, including")
            setExcludes((exc) => _.reject(exc, { id: v.id }));
        } else {
            setIncludes((inc) => _.unionBy(inc, [v], (e: any) => e.id));
        }
    };

    const handleExclude = (v: any) => {
        //console.log("Handling exclude", v, [...includes]);
        if (includes && includes.find((e) => e.id === v.id)) {
            //console.log("was included, excluding")
            setIncludes((inc) => _.reject(inc, { id: v.id }));
        } else {
            setExcludes((exc) => _.unionBy(exc, [v], (e: any) => e.id));
        }
    };

    const handleRemove = (v: any) => {
        //console.log("Handling remove", v)
        if (!v) return;
        setExcludes((exc) => _.reject(exc, { id: v.id }));
        setIncludes((inc) => _.reject(inc, { id: v.id }));
    };

    const mappedSuggestions = useMemo(() => {
        if (!suggestions || !suggestions.length) return;

        //filter includes and excludes from suggestions
        const filteredSuggestions = suggestions.filter((s: any) => {
            return (
                !(includes && includes.find((i) => i.id === s.id)) &&
                !(excludes && excludes.find((i) => i.id === s.id))
            );
        });

        return filteredSuggestions.map((s: any) => (
            <Keyword
                mode="suggestion"
                keyword={s}
                key={s.id}
                onInclude={handleInclude}
                onExclude={handleExclude}
            />
        ));
    }, [suggestions, includes, excludes]);

    const mappedSelection = useMemo(() => {
        const mapped: any[] = [];
        (includes || []).forEach((i: any) =>
            mapped.push(
                <Keyword
                    mode="selected"
                    inclusion="included"
                    keyword={i}
                    key={i.id}
                    onInclude={handleInclude}
                    onExclude={handleExclude}
                    onRemove={handleRemove}
                />
            )
        );
        (excludes || []).forEach((i: any) =>
            mapped.push(
                <Keyword
                    mode="selected"
                    inclusion="excluded"
                    keyword={i}
                    key={i.id}
                    onInclude={handleInclude}
                    onExclude={handleExclude}
                    onRemove={handleRemove}
                />
            )
        );
        return mapped;
    }, [includes, excludes]);

    const advancedTable = useMemo(
        () => (
            <KeywordsAdvancedTable
                key="keywords-advanced"
                value={props.value}
                onInclude={handleInclude}
                onExclude={handleExclude}
                onRemove={handleRemove}
            />
        ),
        [props.value]
    );

    const displayInitialInstructions = !props.value?.length;

    if (mode === "normal")
        return (
            <>
                <div className="keywords-input" style={{ width: "100%" }}>
                    {displayInitialInstructions ? (
                        <Center style={{
                            paddingBottom: "50px"
                        }}>
                            <FormattedMessage
                                id="components.keywords.initialInstructions"
                                defaultMessage="Start by searching for interests and behaviors to target your audience."
                            />
                        </Center>
                    ) : (
                        <Row>
                            <Col xs={24} md={24} lg={12}>
                                <div style={{ paddingLeft: "10px", marginBottom: "2px" }}>
                                    <FormattedMessage
                                        id="components.keywords.keywordIdeas"
                                        defaultMessage="Keyword Ideas"
                                    />
                                </div>

                                <ul
                                    className="standard-border keywords-list"
                                    style={{ marginRight: "5px" }}
                                >
                                    {mappedSuggestions?.length > 0 ? (
                                        <KeywordsBoxWithTooltip>
                                        {mappedSuggestions}
                                        </KeywordsBoxWithTooltip>
                                    ) : (
                                        <Center>
                                            <Spin />
                                        </Center>
                                    )}
                                </ul>
                            </Col>
                            <Col xs={24} md={24} lg={12}>
                                <div style={{ paddingLeft: "10px", marginBottom: "2px" }}>
                                    <FormattedMessage
                                        id="components.keywords.selectedIdeas"
                                        defaultMessage="Selected Ideas"
                                    />
                                </div>
                                <ul
                                    className="standard-border keywords-list"
                                    style={{ marginLeft: "5px" }}
                                >
                                    <KeywordsBoxWithTooltip>
                                        {mappedSelection}
                                    </KeywordsBoxWithTooltip>
                                </ul>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col xs={24}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <KeywordSearchWithSuggestions
                                    onSelect={handleInclude}
                                    includes={includes}
                                    excludes={excludes}
                                />
                                <div style={{ marginLeft: "6px" }}>
                                    <TooltipIcon
                                        title={
                                            <FormattedMessage
                                                id="components.keywords.groups.select.tootlip"
                                                defaultMessage="Serach Interests and Behaviors"
                                            />
                                        }
                                    />
                                </div>
                            </div>
                        </Col>
                        {props.simpleModeOnly !== true && <Button type="text" size="small" onClick={() => setMode("advanced")}><FormattedMessage
                                                id="components.keywords.advancedMode.open"
                                                defaultMessage="Open advanced mode"
                                            /></Button>}
                    </Row>
                </div>
            </>

        );

    return (
        <>
            <div className="keywords-input" style={{ width: "100%" }}>
                <Row>{advancedTable}</Row>
                <Button type="text" size="small"  onClick={() => setMode("normal")}><FormattedMessage
                                                id="components.keywords.advancedMode.close"
                                                defaultMessage="BackToSimpleView"
                                            /></Button>
            </div>
        </>
    );
}
