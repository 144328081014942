import { Alert } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import StandardButton from "components/common/StandardButton";
import { AddressProps, InitialValuesAddressProps } from "./AddressWithMap";

type Props = {
  currentValue: AddressProps;
  handleDismissNewLocation: any;
  handleSetNewLocation: any;
  initialValues?: InitialValuesAddressProps | null;
};

export default function LocationMarkerDragEndInfo(props: Props) {

    const canAcceptNewLocationByPin = () => {      
        const currentValueComplete = 
          props.currentValue?.address &&
          props.currentValue?.zip_code &&
          props.currentValue?.voivodeship &&
          props.currentValue?.city &&
          props.currentValue?.country;
      
        return currentValueComplete ? true : false;
    };

  return (
    <>
      <Alert
        message={
          <>
            <FormattedMessage
              id="apps.websites.forms.companyData.new.location"
              defaultMessage="Google find new address on marker:"
            />
            <ul>
              <li>
                <FormattedMessage
                  id="apps.websites.forms.companyData.new.location.address"
                  defaultMessage="Address: {address}"
                  values={{
                    address: props.currentValue?.address
                      ? props.currentValue?.address
                      : "None",
                  }}
                />
              </li>

              <li>
                <FormattedMessage
                  id="apps.websites.forms.companyData.new.location.city"
                  defaultMessage="City: {city}"
                  values={{
                    city: props.currentValue?.city
                      ? props.currentValue?.city
                      : "None",
                  }}
                />
              </li>
              <li>
                <FormattedMessage
                  id="apps.websites.forms.companyData.new.location.voivodeship"
                  defaultMessage="Voivodeship: {voivodeship}"
                  values={{
                    voivodeship: props.currentValue?.voivodeship
                      ? props.currentValue?.voivodeship
                      : "None",
                  }}
                />
              </li>

              <li>
                <FormattedMessage
                  id="apps.websites.forms.companyData.new.location.country"
                  defaultMessage="Country: {country}"
                  values={{
                    country: props.currentValue?.country
                      ? props.currentValue?.country
                      : "None",
                  }}
                />
              </li>

              <li>
                <FormattedMessage
                  id="apps.websites.forms.companyData.new.location.zip_code"
                  defaultMessage="Zip Code: {zip_code}"
                  values={{
                    zip_code: props.currentValue?.zip_code
                      ? props.currentValue?.zip_code
                      : "None",
                  }}
                />
              </li>
            </ul>
          </>
        }
        description={
          <>
            {canAcceptNewLocationByPin() ? (
              <>
                <FormattedMessage
                  id="apps.websites.forms.companyData.new.location.confiramtion"
                  defaultMessage="You want new location?"
                />
                <div>
                  <StandardButton onClick={props.handleDismissNewLocation}>
                    <FormattedMessage
                      id="apps.websites.forms.companyData.new.location.no"
                      defaultMessage="No"
                    />
                  </StandardButton>

                  <StandardButton onClick={props.handleSetNewLocation}>
                    <FormattedMessage
                      id="apps.websites.forms.companyData.new.location.yes"
                      defaultMessage="Yes"
                    />
                  </StandardButton>
                </div>
              </>
            ) : (
              <>
                <FormattedMessage
                  id="apps.websites.forms.companyData.new.location.noAddress.info"
                  defaultMessage="The new location requires full address info. Find another location on the map with your company correct address or enter the address manually."
                />
                <StandardButton onClick={props.handleDismissNewLocation}>
                  <FormattedMessage
                    id="apps.websites.forms.companyData.new.location.ok"
                    defaultMessage="Ok"
                  />
                </StandardButton>
              </>
            )}
          </>
        }
        type="info"
        style={{
          position: "absolute",
          zIndex: 9999,
          top: "10px",
          right: "10px",
        }}
      />
    </>
  );
}
