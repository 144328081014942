import { Radio, Select } from "antd";
import FormattedMessage from "components/common/FormattedMessage";

export function GenderInput(props: any) {

    return (
        <Select {...props} 
            options={[
                {
                    label: <FormattedMessage
                        id="components.forms.inputs.gender.male"
                        defaultMessage="Male"
                    />,    
                    value: "male"
                },
                {
                    label: <FormattedMessage
                        id="components.forms.inputs.gender.female"
                        defaultMessage="Female"
                    />,
                    value: "female"
                },
                {
                    label: <FormattedMessage
                        id="components.forms.inputs.gender.all"
                        defaultMessage="All"
                    />,
                    value: "all"
                }
            ]}
        />

    )

}
