import React, { useEffect } from "react";
import { Redirect, useParams, useLocation } from "react-router-dom";
import {
  getUrl,
  LOGIN,
  COMPANY_BUY_PRODUCT,
} from "modules/panel/config/routes";
import queryString from "query-string";

const LocalStorageOverride = () => {

  const location = useLocation()

  useEffect(() => {

    if (!location || !location.search) return;
    const query = queryString.parse(location.search);

    Object.keys(query).forEach((key) => {
      console.log(key, "-->",  query[key])
      localStorage.setItem(key, query[key] as string);
    })


  }, []);



  return (
    <Redirect
      to={{
        pathname: getUrl(LOGIN)
      }}
    />
  );
};

export default LocalStorageOverride;
