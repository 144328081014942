import { Input, Spin } from "antd"
import CardSelect from "components/inputs/CardSelect"
import URLInput from "components/inputs/URLInput"
import { CTAFieldRenderProps } from "modules/smart/components/create-post/form-generator"
import CTASelect from "tools/creationEditor/CTASelect"
import FormattedMessage from "components/common/FormattedMessage";
import RelaxList from "tools/relaxForm/RelaxList"
import { ruleArrayMax, ruleArrayMin, ruleMax, ruleNoDuplicates } from "tools/relaxForm/relaxPresets"
import RelaxSingleField from "tools/relaxForm/RelaxField"
//import PostTextEditor from "tools/textEditor/PostTextEditorNew"
import { GalleryWrapperForAOA, GalleryWrapperForPostFormSingle } from "./inputs/GalleryWrappers"
import { useCreationEditor } from "./CreationEditor"
import AOAEvents from "./inputs/AOAEvents"
import React, { Suspense } from "react"
import PostTextEditorWithSuspense from "tools/textEditor/PostTextEditorWithSuspense"
import OrganizationGroupSelect from "tools/organization/components/OrganizationGroupSelect"
import _ from "lodash"
import { patchCreationWithSmartAction } from "./creationsSlice"
import LeadFormSelectForPosts from "./LeadFormSelectForPosts"

export const FieldName = (props: any) => {
    return (
        <RelaxSingleField
            name={"name"}
            label={<FormattedMessage
                id="tools.creationEditor.fields.name.label"
                defaultMessage="Name"
            />}
            required
            rules={[ruleMax(30)]}
        >
            <Input />
        </RelaxSingleField>
    )
}

export const FieldTitle = (props: any) => {
    return (
        <RelaxSingleField
            name={"title"}
            label={<FormattedMessage
                id="tools.creationEditor.fields.title.label"
                defaultMessage="Title"
            />}
            required
            rules={[ruleMax(30)]}
        >
            <Input />
        </RelaxSingleField>
    )
}

export const FieldMessage = (props: any) => {
    return (
        <RelaxSingleField
            name={"message"}
            label={<FormattedMessage
                id="tools.creationEditor.fields.message.label"
                defaultMessage="Message"
            />}
            required
            rules={[ruleMax(1500)]}
        >
            <PostTextEditorWithSuspense />
        </RelaxSingleField>
    )
}

export const FieldDescription = (props: any) => {
    return (
        <RelaxSingleField
            name={"description"}
            label={<FormattedMessage
                id="tools.creationEditor.fields.description.label"
                defaultMessage="Description"
            />}
            rules={[ruleMax(90)]}
        >
            <Input />
        </RelaxSingleField>
    )
}

export const FieldListOfBodies = (props: any) => {

    const {creationId} = useCreationEditor()

    return (
        <RelaxList
            name={"bodies"}
            callAPI={patchCreationWithSmartAction(creationId, "ce_bodies")}
            initialValues={{bodies: [" "]}}
            rules={[{ noDuplicates: true }, { noEmptyValues: true }, {max: 5}, {min: 1}, {required: true}]}
            elementRules={[ruleMax(1500)]}
            label={<FormattedMessage
                id="tools.creationEditor.fields.bodies.label"
                defaultMessage="Bodies"
            />}
        >
             <PostTextEditorWithSuspense />
        </RelaxList>
    )
}

export const FieldListOfTitles = (props: any) => {

    const {creationId} = useCreationEditor()

    return (
        <RelaxList
            name={"titles"}
            callAPI={patchCreationWithSmartAction(creationId, "ce_titles")}
            label={<FormattedMessage
                id="tools.creationEditor.fields.titles.label"
                defaultMessage="Titles"
            />}
            initialValues={{"titles":[" "]}}
            rules={[{ noDuplicates: true }, { noEmptyValues: true }, {max: 5}, {min: 1}]}
        >
             <Input />
        </RelaxList>
    
    )
}

export const FieldListOfDescriptions = (props: any) => {
    return (
        <RelaxList
            name={"descriptions"}
            label={<FormattedMessage
                id="tools.creationEditor.fields.descriptions.label"
                defaultMessage="Descriptions"
            />}
            initialValues={{"descriptions":[" "]}}
            elementRules={[ruleMax(90)]}
            rules={[{ noDuplicates: true }, { noEmptyValues: true }, {max: 5}, {min: 1}]}
        >
             <Input />
        </RelaxList>
    
    )
}

export default FieldMessage;

export const FieldAOAEvents = (props:any) => {
    
    return (
    <RelaxSingleField
        name={"events"}
        label={<FormattedMessage
            id="tools.creationEditor.fields.events.label"
            defaultMessage="Events" />}
        initialValues={{"events":[]}}
    >
        <AOAEvents />
    </RelaxSingleField>
)
}

export const FieldLeadForm = (props:any) => {
    return (
        <RelaxSingleField
        name={"lead_gen_form_id"}
        label={
            <FormattedMessage
            id="tools.creationEditor.fields.leadForm.label"
              defaultMessage="Lead Form"
            />}
    >
        <LeadFormSelectForPosts/>
    </RelaxSingleField>
    )
}


//MEDIA

export const FieldAOAMedia = (props: any) => {
    return (
        <RelaxSingleField
            name={"media"}
            label={<FormattedMessage
                id="tools.creationEditor.fields.media.label"
                defaultMessage="Media"
            />}
            required
            rules={[{noDuplicatesByHash: true}, ruleArrayMax(10), ruleArrayMin(1)]}
        >
            <GalleryWrapperForAOA />
        </RelaxSingleField>
    )
}

export const FieldMediaSingle = (props: any) => {

    return (
        <RelaxSingleField
            name={"media"}
            label={<FormattedMessage
                id="tools.creationEditor.fields.media.label"
                defaultMessage="Media"
            />}
            required
        >
            <GalleryWrapperForPostFormSingle />
        </RelaxSingleField>
    )
}

//CTA

export const FieldCTA = (props: any) => {
    return (
        <div style={{
            display: "grid",
            gridTemplateColumns: "1fr 300px",
            gap: "20px"
        }}>
        <RelaxSingleField
            name={["cta", "data"]}
            label={<FormattedMessage
                id="tools.creationEditor.fields.cta.link.label"
                defaultMessage="Call to action link"
              />}
            required
            rules={[ruleMax(512)]}
            finalizeSubmit={(value: any, full: any) => {
                let newVal = {...value}
                newVal = _.set(newVal, ["cta", "type"], full?.cta?.type)
                return {
                    content: {...newVal}
                }
            }}
        >
            <URLInput />
        </RelaxSingleField>
        <RelaxSingleField
            name={["cta", "type"]}
            label={<FormattedMessage
                id="tools.creationEditor.fields.cta.action.label"
                defaultMessage="Call to action"
              />}
            required
            finalizeSubmit={(value: any, full: any) => {
                let newVal = {...value}
                newVal = _.set(newVal, ["cta", "data"], full?.cta?.data)
                return {
                    content: {...newVal}
                }
            }}
        >
            <CTASelect mode={"single"} actions = {(props as CTAFieldRenderProps).actions}/>
        </RelaxSingleField>
        </div>
    )
}

export const FieldCTAMultiple = (props: any) => {
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
        }}>
        <RelaxSingleField
            name={"link"}
            label={<FormattedMessage
                id="tools.creationEditor.fields.cta.link.label"
                defaultMessage="Call to action link"
              />}
            required
            rules={[ruleMax(512)]}
        >
            <URLInput />
        </RelaxSingleField>
        <RelaxSingleField
            name={"ctas"}
            label={<FormattedMessage
                id="tools.creationEditor.fields.cta.action.label"
                defaultMessage="Call to action"
              />}
            required
            rules={[ruleMax(5)]}
            finalizeSubmit={(values: any) => {
                return {
                    content: {
                        ctas: values.ctas && values.ctas.map((cta: any) => ({type: cta}))
                    }
                }
            }}
        >
            <CTASelect mode={"multiple"} actions = {(props as CTAFieldRenderProps).actions}/>
        </RelaxSingleField>
        </div>
    )
}

export const FieldCTAButtons = (props:any) => {
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
        }}>
        <RelaxSingleField
            name={"ctas"}
            label={<FormattedMessage
                id="tools.creationEditor.fields.cta.action.label"
                defaultMessage="Call to action"
              />}
            required
            rules={[ruleMax(5)]}
            finalizeSubmit={(values: any) => {
                return {
                    content: {
                        ctas: values.ctas && values.ctas.map((cta: any) => ({type: cta}))
                    }
                }
            }}
        >
            <CTASelect mode={"multiple"} actions = {(props as CTAFieldRenderProps).actions}/>
        </RelaxSingleField>
        </div>
    )
}

export const FieldPostType = (props: any) => {
    return (
        <RelaxSingleField
            name={"type"}
            label={<FormattedMessage
                id="tools.creationEditor.fields.postType.label"
                defaultMessage="Post type"
            />}
            required
        >
            <Input />
        </RelaxSingleField>
    )
}



export const FieldTargetGroup = (props: any) => {
    return (
        <RelaxSingleField
            name="publication_target"
            label={<FormattedMessage
                id="tools.creationEditor.fields.targetGroups.label"
                defaultMessage="Target groups"
            />}
            required
        >
            <OrganizationGroupSelect mode="multiple"/>
        </RelaxSingleField>

    )
}
