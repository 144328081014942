import siteGeneratorRequirementsBase from "modules/site-generator/config/industries/requirements/requirementsBase";
import { ProductConfig } from "products";
import SiteGenerator from "products/siteGenerator/SiteGenerator.png";


const config: ProductConfig = {
  name: "Site Generator",
  logo: SiteGenerator,
  requirements: siteGeneratorRequirementsBase,
};

export default config;
