import { ProductConfig } from "products";
import logo from "products/forsant/logo.png";
import requirements from "products/auth-user/requirements";
import { Requirement } from "modules";

const config: ProductConfig = {
  name: "Platforms authorization",
  logo,
  requirements: requirements as Requirement[],
  providers: ["google"],
};

export default config;
