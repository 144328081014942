import React from "react";

type Props = {
  status: "paid" | string;
};

const PaymentStatus = ({ status }: Props) => (
  <div
    className={`payment-status-indicator ${
      status === "paid" ? "paid" : "unpaid"
    }`}
  >
    {status}
  </div>
);

export default PaymentStatus;
