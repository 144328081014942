import { Button, Modal, Upload } from "antd";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { InboxOutlined, PlusOutlined } from '@ant-design/icons';
import { FileRequirements, SizeRequirements, useGallery } from "./Gallery";
import _, { reject } from "lodash";
import { UploadFile } from "antd/es/upload/interface";
import FormattedMessage from "components/common/FormattedMessage";
import { CheckSizeAgainstPresets, ReadImageAndCheckSize } from "./imageHelpers";
import useUploadFile from "services/hooks/useUploadFile";

export type GallerUploaderProps = {
  maxFiles?: number,
  allowedFileTypes?: string[] | string,
  onFileError?: any,
  uploadFile?: any,
}

type FileRejectionReason = {
  error: string,
  extra?: any
}

export async function generateHashForFile(file: File): Promise<string> {
  const buffer = await file.arrayBuffer();
  const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
}

export async function fetchImageAsFile(url: string): Promise<File> {
  const response = await fetch(url);
  const blob = await response.blob();
  const file = new File([blob], 'image.jpg', { type: blob.type });
  return file;
}

export async function generateHashFromUrl(url: string): Promise<string> {
  const file = await fetchImageAsFile(url);
  const hash = await generateHashForFile(file);
  return hash;
}

const MAX_FILE_SIZE = 4 * 1024 * 1024 * 1024; //GB

export default function GalleryUploader(props: GallerUploaderProps) {

  const {cropPresets, fileRequirements, updateListOfUploads, allowedTypes, singleImageOnly} = useGallery();


  const rejectFile = (file: UploadFile, reason: FileRejectionReason) => {
    if (props.onFileError) props.onFileError(file, reason)
  }

  const beforeUpload = async (file: UploadFile) => {
    const isOK = await verifyFilesBeforeUpload(file); 
    if (!isOK) {
      return;
    }
    (file as any).width = (isOK as any).width;
    (file as any).height = (isOK as any).height;

    const hash = await generateHashForFile(file);
    (file as any).hash = hash;
    props.uploadFile && props.uploadFile(file)
  }

  const verifyFilesBeforeUpload = async (file: any) => {

    if (!file) return false;

    const type = file.type.slice(0,5)

    if (allowedTypes && !allowedTypes.includes(type)) {
      rejectFile(file, {error: "WRONG_FILE_TYPE", extra: {
        fileType: type,
        allowedTypes
      }})
      return false;
    }

    if (type === "video") {
      if (file.size > MAX_FILE_SIZE) { 
        rejectFile(file, {error: "FILE_TOO_BIG", extra: {
          fileSize: file.size,
          maxSize: MAX_FILE_SIZE
        }})
        return false;
      } 
      return true;
    }
    if (type === "image") {
    const allowed =  await ReadImageAndCheckSize(file, fileRequirements, cropPresets, (reason:FileRejectionReason)=>rejectFile(file, reason))
    return allowed;
    } 
    return false;
  }

  const allowedFileTypes = useMemo(() => {

    if (!props.allowedFileTypes) return ["image/*"];
    
    const allowed:string[] = [];

    if (Array.isArray(props.allowedFileTypes)) {
    props.allowedFileTypes.includes("image") && allowed.push("image/*");
    props.allowedFileTypes.includes("video") && allowed.push("video/*");
    props.allowedFileTypes.includes("audio") && allowed.push("audio/*");
    } else {
      allowed.push(props.allowedFileTypes);
    }

    return allowed;
  }, [props.allowedFileTypes])


  return (
    <>
      <Upload.Dragger
        beforeUpload={beforeUpload}
        accept={allowedFileTypes.join(",")}
        multiple={singleImageOnly ? false : true}
        className="uploader"
        itemRender={(item) => {
          return null
        }}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p>
          <FormattedMessage
                    id="apps.tools.imageGallery.upload"
                    defaultMessage="Upload"
                    />
        </p>
      </Upload.Dragger>
    </>
  )

}