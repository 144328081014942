import { ReactNode } from "react";

//Displays content only if debug mode is enabled
type DebugDisplayProps = {
    children?: ReactNode;
}

export default function DebugDisplay (props:DebugDisplayProps) {

    const debug = (window as any).debugDisplay;

    if (!debug) return null;

    return props.children;

}