import { createSelector } from "@reduxjs/toolkit";
import { Creation, creationSelector } from "./creationsSlice";
import _ from "lodash";
import dayjs from "dayjs";

export const creationErrorsSelector = createSelector([
    creationSelector,
], (creation) => {

    return creation && creation.errors;
})


export const creationSlideSelector = createSelector([
    creationSelector,
    (state, creation, slide: string | number) => slide 
], (creation, slide) => {
    if (!creation) return null;
    if (!creation.content) return null;
    if (!creation.content.slides) return null;
    return creation.content.slides[slide];
})

export const ReplaceSlidesInCreation = (creation: Creation, slides: Record<string, any>) => {

    const c = _.cloneDeep(creation);
    const oldCarousel = c.content.carousel;
    const newCarousel = Object.keys(slides);

    console.log("ReplaceSlidesInCreation", oldCarousel, newCarousel, slides)

    newCarousel.forEach((slideId, index) => {

        const oldSlide = oldCarousel && oldCarousel[index];
        const newSlide = slides[slideId];

        if (!oldSlide) return;
        if (!newSlide) return;

        newSlide.headline = oldSlide.headline;
        newSlide.description = oldSlide.description;
        newSlide.cta = _.cloneDeep(oldSlide.cta);

    });

    c.content.carousel = newCarousel;
    c.content.slides = slides;

    console.log("after changes", _.cloneDeep(creation));


    return creation;

}

export const CanCreationBeDeleted = (creation: Creation) => {
    if (!creation) return false;
    return creation.status === "draft";
}

export const CanCreationByUnpublished = (creation: Creation) => {

    if (!creation) return false;
    if (!creation.publish_at) return false;

    const date = dayjs.utc(creation.publish_at);
    const now = dayjs.utc();
    if (creation.status === "scheduled" && date.isAfter(now)) return true;

    return false;

}