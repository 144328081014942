
import Helper from "providers/routing/routes/helper";
import { LayoutRouteConfig } from "providers/routing/layouts/layouts";
import { Layout } from "providers/routing/layouts/templates/templates";
import FormattedMessage from "components/common/FormattedMessage";

import Settings from "modules/smart/containers/settingsAds/Settings";
import OnboardingKeywordsFacebook from "../containers/onboarding/facebook/OnboardingKeywordsFacebook";
import SettingsAdvanced from "../containers/settingsAdvanced/SettingsAdvanced";

import OnboardingDomainAndKeyword from "../onboarding/OnboardingDomainAndKeyword";
import { AwesomeLarge } from "components/common/AwesomeIcon";
import { faCalendarDays, faChartLineUp, faFilePlus, faFolder, faHashtag, faLockKeyhole, faPenToSquare } from "@fortawesome/pro-light-svg-icons";
import OnboardingAudienceFacebook from "../containers/onboarding/facebook/OnboardingAudienceFacebook";
import OnboardingAudienceGoogle from "../containers/onboarding/google/OnboardingAudienceGoogle";
import OnboardingKeywordsGoogle from "../containers/onboarding/google/OnboardingKeywordsGoogle";
import { InfinitePostListWithErrorBoundry } from "modules/posts/InfinityPostList";
import OnboardingBudgetFacebook from "../containers/onboarding/facebook/OnboardingBudgetFacebook";
import CreationMainPage from "tools/creationEditor/CreationMainPage";
import CreationsInfinityList from "tools/creationEditor/CreationsInfinityList";
import { OnboardingSuccessFacebook } from "../containers/onboarding/facebook/OnboardingSuccessFacebook";
import { OnboardingSuccessGoogle } from "../containers/onboarding/google/OnboardingSuccessGoogle";
import { OnboardingBudgetGoogle } from "../containers/onboarding/google/OnboardingBudgetGoogle";
import { OnboardingPixel } from "../containers/onboarding/OnboardingPixel";
import OnboardingAICompanyInfo from "../containers/onboarding/AI/OnboardingAICompanyInfo";
import OnboardingAIClientInfo from "../containers/onboarding/AI/OnboardingAIClientInfo";
import OnboardingAIDemographics from "../containers/onboarding/AI/OnboardingAIDemographics";
import OnboardingAIPurchaseObjections from "../containers/onboarding/AI/OnboardingAIPurchaseObjections";
import OnboardingAIGeneration, { OnboardingAIGenerationCompleted } from "../containers/onboarding/AI/OnboardingAIGeneration";
import OnboardingAIBasicInfo from "../containers/onboarding/AI/OnboardingAIBasicInfo";
import OnboardingAcceptKeywordSuggestions from "../containers/onboarding/google/OnboardingAcceptKeywordSuggestions";
import OnboardingCompetitorWebsites from "../containers/onboarding/google/OnboardingCompetitorWebsites";
import OnboardingAISuccess from "../containers/onboarding/AI/OnboardingAISuccess";
import OnboardingAIExampleClientGeneration from "../containers/onboarding/AI/OnboardingAIExampleClientGeneration";
import OnboardingAIClientAvatar from "../containers/onboarding/AI/OnboardingAIClientAvatar";
import ProjectCalendar from "tools/calendar/ProjectCalendar";
import PrepareSmartProject from "modules/panel/containers/onboarding/projects/PrepareSmartProject";
import ProductCatalogs from "../components/productCatalogs/ProductCatalogs";

export const CREATE_POST = "createPostInfo",
  POSTS = "posts",
  CREATIONS = "creations",
  CALENDAR = "calendar",
  ADS_SETTINGS = "adsSettings",
  ADVANCED_SETTINGS = "advancedSettings",
  ONBOARDING_PREPARE_PROJECT = "onboardingPrepareProject",
  ONBOARDING_BUSINESS_DETAILS = "onboardingBusinessDetails",
  ONBOARDING_GOOGLE_AUDIENCE = "onboardingGoogleAudience",
  ONBOARDING_GOOGLE_BUDGET = "onboardingGoogleBudget",
  ONBOARDING_GOOGLE_KEYWORD = "onboardingGoogleKeyword",
  ONBOARDING_GOOGLE_SUCCESS = "onboardingGoogleSuccess",
  ONBOARDING_FACEBOOK_AUDIENCE = "onboardingFacebookAudience",
  ONBOARDING_FACEBOOK_BUDGET = "onboardingFacebookBudget",
  ONBOARDING_FACEBOOK_KEYWORD = "onboardingFacebookKeyword",
  ONBOARDING_FACEBOOK_SUCCESS = "onboardingFacebookSuccess",
  ONBOARDING_PIXEL = "onboardingPixel",
  ONBOARDING_AI_BASIC_INFO = "onboardingAIBasicInfo",
  ONBOARDING_AI_COMPANY_INFO = "onboardingAICompanyInfo",
  ONBOARDING_AI_CLIENT_INFO = "onboardingAIClientInfo",
  ONBOARIDNG_AI_GENERATION = "onboardingAIGeneration",
  ONBOARDING_AI_GENERATION_COMPLETED = "onboardingAIGenerationCompleted",
  ONBOARDING_ACCEPT_KEYWORD_SUGGESTIONS = "onboardingAcceptKeywordSuggestions",
  ONBOARDING_COMPETITOR_WEBSITES = "onboardingCompetitorWebsites",
  ONBOARDING_AI_DEMOGRAPHIC_DEMOGRAPHICS = "onboardingAIDemographicDemographics",
  ONBOARDING_AI_DEMOGRAPHIC_SHOPPING_BEHAVIORS = "onboardingAIDemographicShoppingBehaviors",
  ONBOARDING_AI_DEMOGRAPHIC_PURCHASE_OBJECTIONS = "onboardingAIDemographicPurchaseObjections",
  ONBOARDING_AI_EXAMPLE_CLIENT_GENERATION = "onboardingAIExampleClientGeneration",
  ONBOARDING_AI_EXAMPLE_CLIENT_MALE = "onboardingAIExampleClientMale",
  ONBOARDING_AI_EXAMPLE_CLIENT_FEMALE = "onboardingAIExampleClientFemale",
  ONBOARDING_AI_SUCCESS = "onboardingAISuccess",
  PRODUCT_CATALOGS = "productCatalogs";

export const APP_PATH = "smart";

const routes: LayoutRouteConfig[] = [
  {
    key: CREATE_POST,
    path: "/posts/create",
    name: (
      <FormattedMessage
        id="apps.smart.routing.createPost"
        defaultMessage="Create post"
      />
    ),
    icon: <AwesomeLarge icon={faFilePlus} />,
    layout: Layout.Panel,
    content: CreationMainPage,
    fullHeight: true,
    mainHasBackground: false,
    title: (
      <FormattedMessage
        id="apps.smart.routing.createPost"
        defaultMessage="Create post"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.smart.routing.createPost.subtitle"
        defaultMessage="Choose what post you want to publish."
      />
    ),
  },
  {
    key: CREATIONS,
    path: "/creations",
    name: (
      <FormattedMessage
        id="apps.smart.routing.creations"
        defaultMessage="Creations"
      />
    ),
    icon: <AwesomeLarge icon={faPenToSquare} />,
    layout: Layout.Panel,
    content: CreationsInfinityList,
    //content: CreationsList,
    mainHasBackground: false,
    title:  (
      <FormattedMessage
        id="apps.smart.routing.creations"
        defaultMessage="Creations"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.smart.routing.creations.subtitle"
        defaultMessage="Your drafts, upcoming posts and ads."
      />
    ),
  },
  {
    key: POSTS,
    path: "/posts/list",
    name: (
      <FormattedMessage
        id="apps.smart.routing.postsAndAds"
        defaultMessage="Posts and ads"
      />
    ),
    icon: <AwesomeLarge icon={faChartLineUp} />,
    layout: Layout.Panel,
    title: (
      <FormattedMessage
        id="apps.smart.routing.postsAndAds.title"
        defaultMessage="Your posts and ads"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.smart.routing.postsAndAds.subtitle"
        defaultMessage="All your posts and ads in one place."
      />
    ),
    content: InfinitePostListWithErrorBoundry,
    fullHeight: true,
    mainHasBackground: false,
  },
  {
    key: CALENDAR,
    path: "/calendar",
    name: (
      <FormattedMessage
        id="apps.smart.routing.calendar"
        defaultMessage="Calendar"
      />
    ),
    icon: <AwesomeLarge icon={faCalendarDays} />,
    layout: Layout.Panel,
    title: (
      <FormattedMessage
        id="apps.smart.routing.calendar.title"
        defaultMessage="Your calendar"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.smart.routing.calendar.subtitle"
        defaultMessage="All your posts and ads in one place."
      />
    ),
    content: ProjectCalendar,
    fullHeight: true,
    mainHasBackground: false,
  },
  {
    key: PRODUCT_CATALOGS,
    path: `/productCatalogs`,
    name: (
      <FormattedMessage
        id="panel.routing.productCatalogs"
        defaultMessage="Product Catalogs"
      />
    ),
    title: (
      <FormattedMessage
        id="panel.routing.productCatalogs"
        defaultMessage="Product Catalogs"
      />
    ),
    icon: <AwesomeLarge icon={faFolder} />,
    layout: Layout.Panel,
    content: ProductCatalogs,
    displayInMenu: true,
    mainHasBackground: false
  },
  {
    key: ADS_SETTINGS,
    path: "/advertisements/settings",
    name: (
      <FormattedMessage
        id="apps.smart.routing.adsSettings"
        defaultMessage="Ads settings"
      />
    ),
    icon: <AwesomeLarge icon={faHashtag} />,
    layout: Layout.Panel,
    title: (
      <FormattedMessage
        id="apps.smart.routing.adsSettings"
        defaultMessage="Ads settings"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.smart.routing.adsSettingsSubtitle"
        defaultMessage="Personalize your smart ads settings."
      />
    ),
    content: Settings,
    mainHasBackground: false,
  },
  {
    key: ADVANCED_SETTINGS,
    path: "/advanced-settings",
    name: (
      <FormattedMessage
        id="apps.smart.routing.advancedSettings"
        defaultMessage="Advanced settings"
      />
    ),
    icon: <AwesomeLarge icon={faLockKeyhole} />,
    layout: Layout.Panel,
    title: (
      <FormattedMessage
        id="apps.smart.routing.advancedSettings"
        defaultMessage="Advanced settings"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.smart.routing.advancedSettings.subtitle"
        defaultMessage="Gain an advantage and use the power of Smart Ads"
      />
    ),
    //sidebar: <ContactOurSupportSidebar />,
    content: SettingsAdvanced,
    fullHeight: true,
    mainHasBackground: false,
  },
  {
    key: ONBOARDING_PREPARE_PROJECT,
    path: "/onboarding/prepare",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: PrepareSmartProject,
    title: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.prepare.title"
        defaultMessage="Preparing your project"
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.routing.prepareProject.sidebar.title"
          defaultMessage="Preparing your project"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.routing.prepareProject.sidebar.subtitle"
          defaultMessage="We need to know a little more about your business to create your first ad."
        />
      ),
    },
  },
  {
    key: ONBOARDING_GOOGLE_AUDIENCE,
    path: "/onboarding/audience/google",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingAudienceGoogle,
    //content: AudienceGoogleOnboarding,
    title: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.google.audience.title"
        defaultMessage="Where are your Google clients?"
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.containers.onboarding.google.audience.sidebar.title"
          defaultMessage="Google audience setup"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.containers.onboarding.google.audience.sidebar.subtitle"
          defaultMessage="Who knows your clients better than you. Tell us who your ideal customers are."
        />
      ),
    },
  },
  {
    key: ONBOARDING_FACEBOOK_AUDIENCE,
    path: "/onboarding/audience/facebook",
    displayInMenu: false,
    layout: Layout.TwoColumns,
   // content: AudienceFacebookOnboarding,
    content: OnboardingAudienceFacebook,
   title: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.facebook.audience.title"
        defaultMessage="Where are your Facebook clients?"
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.containers.onboarding.facebook.sidebar.title"
          defaultMessage="Facebook audience setup"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.containers.onboarding.facebook.sidebar.subtitle"
          defaultMessage="Who knows your clients better than you. Tell us who your ideal customers are."
        />
      ),
    },
  },
  {
    key: ONBOARDING_GOOGLE_BUDGET,
    path: "/onboarding/budget/google",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingBudgetGoogle,
    title: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.google.budget.title"
        defaultMessage="Google budget"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.google.budget.subtitle"
        defaultMessage="Your default campaign budget"
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.routing.budget.sidebar.title"
          defaultMessage="Budget setup"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.routing.budget.sidebar.subtitle"
          defaultMessage="Set a Marketing Budget to Fit Your Small Business Goals."
        />
      ),
    },
  },
  {
    key: ONBOARDING_FACEBOOK_BUDGET,
    path: "/onboarding/budget/facebook",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    //content: BudgetFacebookOnboarding,
    content: OnboardingBudgetFacebook,
    title: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.facebook.budget.title"
        defaultMessage="Facebook budget"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.facebook.budget.subtitle"
        defaultMessage="Your default campaign budget"
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.routing.budget.sidebar.title"
          defaultMessage="Budget setup"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.routing.budget.sidebar.subtitle"
          defaultMessage="Set a Marketing Budget to Fit Your Small Business Goals."
        />
      ),
    },
  },

  {
    key: ONBOARDING_GOOGLE_KEYWORD,
    path: "/onboarding/keywords/google",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingKeywordsGoogle,
    //content: KeywordsGoogleOnboarding,
    title: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.google.keywords.title"
        defaultMessage="Google Ads Audience"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.google.keywords.subtitle"
        defaultMessage="Choose targeting keyword"
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.routing.keywords.sidebar.title"
          defaultMessage="Keywords setup"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.routing.keywords.sidebar.subtitle"
          defaultMessage="Who knows your clients better than you. Tell us who your ideal customers are."
        />
      ),
    },
  },
  {
    key: ONBOARDING_FACEBOOK_KEYWORD,
    path: "/onboarding/keywords/facebook",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingKeywordsFacebook,
    //content: KeywordsFacebookOnboarding,
    title: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.facebook.keywords.title"
        defaultMessage="Facebook Ads Audience"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.facebook.keywords.subtitle"
        defaultMessage="Choose targeting keyword"
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.routing.keywords.sidebar.title"
          defaultMessage="Keywords setup"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.routing.keywords.sidebar.subtitle"
          defaultMessage="Who knows your clients better than you. Tell us who your ideal customers are."
        />
      ),
    },
  },
  {
    key: ONBOARDING_FACEBOOK_SUCCESS,
    path: "/onboarding/success/facebook",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingSuccessFacebook,
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.facebookSuccess.sidebar.title"
          defaultMessage="Success"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.facebookSuccess.sidebar.description"
          defaultMessage="Facebook Ads settings are configured successfully."
        />
      ),
    },
  },
  {
    key: ONBOARDING_GOOGLE_SUCCESS,
    path: "/onboarding/success/google",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingSuccessGoogle,
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.googleSuccess.sidebar.title"
          defaultMessage="Success"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.googleSuccess.sidebar.description"
          defaultMessage="Google Ads settings are configured successfully."
        />
      ),
    }
  },
  {
    key: ONBOARDING_BUSINESS_DETAILS,
    path: "/onboarding/business-details",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingDomainAndKeyword,
    //content: BusinessDetailsOnboarding,
    title: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.businessDetails.title"
        defaultMessage="Business details"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.businessDetails.subtitle"
        defaultMessage="Complete survey to run your first ad"
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.businessDetails.sidebar.title"
          defaultMessage="Business survey"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.businessDetails.sidebar.description"
          defaultMessage="It’s our business it to get known your business. Please, provide all necessary informations about your online marketing background."
        />
      ),
    },
  },
  {
    key: ONBOARDING_PIXEL,
    path: "/onboarding/summary",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingPixel,
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.pixel.sidebar.title"
          defaultMessage="It's endgame"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.pixel.sidebar.description"
          defaultMessage="This is the last step. Check what you still have to do to let the application work nicely."
        />
      ),
    },
  },
  {
    key: ONBOARDING_AI_BASIC_INFO,
    path: "/onboarding/ai-basic-info",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingAIBasicInfo,
    title: (
      <FormattedMessage
      id="apps.smart.routing.onboarding.aiBasicInfo.title"
      defaultMessage="Basic info"
    />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.ai-aiVBasicInfo.subtitle"
        defaultMessage="Basic information about your business"
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.aiBasicInfo.sidebar.title"
          defaultMessage="Basic info"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.aiBasicInfo.sidebar.description"
          defaultMessage="Please provide us with basic information about your business"
        />
      ),
    }
  },
  {
    key: ONBOARDING_AI_COMPANY_INFO,
    path: "/onboarding/ai-company-info",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingAICompanyInfo,
    title: (
      <FormattedMessage
      id="apps.smart.routing.onboarding.aiCompanyInfo.title"
      defaultMessage="Company info"
    />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.ai-aiCompanyInfo.subtitle"
        defaultMessage="Basic information about your company"
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.aiCompanyInfo.sidebar.title"
          defaultMessage="Company info"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.aiCompanyInfo.sidebar.description"
          defaultMessage="Please provide us with basic information about your company"
        />
      ),
    }
  },
  {
    key: ONBOARDING_AI_CLIENT_INFO,
    path: "/onboarding/ai-client-info",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingAIClientInfo,
    title: (
      <FormattedMessage
      id="apps.smart.routing.onboarding.aiClientInfo.title"
      defaultMessage="Client info"
    />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.smart.containers.onboarding.ai-aiClientInfo.subtitle"
        defaultMessage="Basic information about your clients"
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.aiClientInfo.sidebar.title"
          defaultMessage="Client info"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.aiClientInfo.sidebar.description"
          defaultMessage="Please provide us with basic information about your clients"
        />
      ),
    }
  },
  {
    key: ONBOARIDNG_AI_GENERATION,
    path: "/onboarding/ai-generation",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingAIGeneration,
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.aiGeneration.sidebar.title"
          defaultMessage="AI generation"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.aiGeneration.sidebar.description"
          defaultMessage="Please provide us with basic information about your clients"
        />
      ),
    }
  },
  {
    key: ONBOARDING_AI_DEMOGRAPHIC_DEMOGRAPHICS,
    path: "/onboarding/ai-demographics",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: () => <OnboardingAIDemographics category="demographics" />,
    title: (
      <FormattedMessage
      id="apps.smart.routing.onboarding.aiDemographics.title"
      defaultMessage="Demographics"
    />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.aiDemographics.sidebar.title"
          defaultMessage="AI generation"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.aiDemographics.sidebar.description"
          defaultMessage="Please provide us with basic information about your clients"
        />
      ),
    }
  },
  {
    key: ONBOARDING_AI_DEMOGRAPHIC_SHOPPING_BEHAVIORS,
    path: "/onboarding/ai-shopping-behaviors",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: () => <OnboardingAIDemographics category="shoppingBehaviors" />,
    title: (
      <FormattedMessage
      id="apps.smart.routing.onboarding.aiShoppingBehaviors.title"
      defaultMessage="Shopping Behaviors"
    />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.aiShoppingBehaviors.sidebar.title"
          defaultMessage="AI generation"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.aiShoppingBehaviors.sidebar.description"
          defaultMessage="Please provide us with basic information about your clients"
        />
      ),
    }
  },
  {
    key: ONBOARDING_AI_DEMOGRAPHIC_PURCHASE_OBJECTIONS,
    path: "/onboarding/ai-purchase-objections",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingAIPurchaseObjections,
    title: (
      <FormattedMessage
      id="apps.smart.routing.onboarding.aiPurchaseObjections.title"
      defaultMessage="Purchase Objections"
    />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.aiPurchaseObjections.sidebar.title"
          defaultMessage="AI generation"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.aiPurchaseObjections.sidebar.description"
          defaultMessage="Please provide us with basic information about your clients"
        />
      ),
    }
  },
  {
    key: ONBOARDING_AI_GENERATION_COMPLETED,
    path: "/onboarding/ai-generation-completed",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingAIGenerationCompleted,
    title: (
      <FormattedMessage
      id="apps.smart.routing.onboarding.aiGenerationCompleted.title"
      defaultMessage="AI generation completed"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.smart.routing.onboarding.aiGenerationCompleted.subtitle"
        defaultMessage="Your AI generation is completed"
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.aiGenerationCompleted.sidebar.title"
          defaultMessage="AI generation completed"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.aiGenerationCompleted.sidebar.description"
          defaultMessage="Please provide us with basic information about your clients"
        />
      ),
    }
  },
  {
    key: ONBOARDING_ACCEPT_KEYWORD_SUGGESTIONS,
    path: "/onboarding/accept-keyword-suggestions",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingAcceptKeywordSuggestions,
    title: (
      <FormattedMessage
      id="apps.smart.routing.onboarding.acceptKeywordSuggestions.title"
      defaultMessage="Keywords"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.smart.routing.onboarding.acceptKeywordSuggestions.subtitle"
        defaultMessage="Your keywords"
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.acceptKeywordSuggestions.sidebar.title"
          defaultMessage="Keywords"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.acceptKeywordSuggestions.sidebar.description"
          defaultMessage="Please provide us with basic information about your clients"
        />
      ),
    }
  },
  {
    key: ONBOARDING_COMPETITOR_WEBSITES,
    path: "/onboarding/competitor-websites",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingCompetitorWebsites,
    title: (
      <FormattedMessage
      id="apps.smart.routing.onboarding.competitorWebsites.title"
      defaultMessage="Competitor websites"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.smart.routing.onboarding.competitorWebsites.subtitle"
        defaultMessage="Your competitor websites"
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.competitorWebsites.sidebar.title"
          defaultMessage="Competitor websites"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.competitorWebsites.sidebar.description"
          defaultMessage="Please provide us with basic information about your clients"
        />
      ),
    }
  },
  
  {
    key: ONBOARDING_AI_EXAMPLE_CLIENT_GENERATION,
    path: "/onboarding/ai-example-client-generation",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingAIExampleClientGeneration,
    title: (
      <FormattedMessage
      id="apps.smart.routing.onboarding.exampleClientGeneration.title"
      defaultMessage="Generating example clients"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="apps.smart.routing.onboarding.exampleClientGeneration.subtitle"
        defaultMessage="Generating example clients"
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.exampleClientGeneration.sidebar.title"
          defaultMessage="Generating example clients"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.exampleClientGeneration.sidebar.description"
          defaultMessage="Generating example clients"
        />
      ),
    }
  },
  {
    key: ONBOARDING_AI_EXAMPLE_CLIENT_FEMALE,
    path: "/onboarding/ai_example-client-female",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: () => <OnboardingAIClientAvatar gender="female" />,
    title: (
      <FormattedMessage
      id="apps.smart.routing.onboarding.exampleClient.female.title"
      defaultMessage="Example client"
      />
    ),
    subtitle: (
      <FormattedMessage
      id="apps.smart.routing.onboarding.exampleClient.female.subtitle"
      defaultMessage="Example client"
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.exampleClient.female.sidebar.title"
          defaultMessage="Generating example clients"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.exampleClient.female.sidebar.description"
          defaultMessage="Generating example clients"
        />
      )
    }
  },
  {
    key: ONBOARDING_AI_EXAMPLE_CLIENT_MALE,
    path: "/onboarding/ai_example-client-male",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: () => <OnboardingAIClientAvatar gender="male" />,
    title: (
      <FormattedMessage
      id="apps.smart.routing.onboarding.exampleClient.male.title"
      defaultMessage="Example client"
      />
    ),
    subtitle: (
      <FormattedMessage
      id="apps.smart.routing.onboarding.exampleClient.male.subtitle"
      defaultMessage="Example client"
      />
    ),
    sidebar: {
      heading: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.exampleClient.male.sidebar.title"
          defaultMessage="Generating example clients"
        />
      ),
      description: (
        <FormattedMessage
          id="apps.smart.routing.onboarding.exampleClient.male.sidebar.description"
          defaultMessage="Generating example clients"
        />
      )
    }
  },
  {
    key: ONBOARDING_AI_SUCCESS,
    path: "/onboarding/success/AISuccess",
    displayInMenu: false,
    layout: Layout.TwoColumns,
    content: OnboardingAISuccess,
  },
];

export default routes;

export const getUrl = (routeKey: string) =>
  Helper.getPath(APP_PATH, routes, routeKey);
export const getSubmenuUrl = (routeKey: string) =>
  Helper.getSubmenuUrl(APP_PATH, routes, routeKey);
