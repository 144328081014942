import { Input } from "antd";
import { useState } from "react";

export default function URLInput (props:any) {


    const handleChange = (value:any) => {
        if (value) {
            let nw:string;
            if (value === "https:/") {nw = "https://";} 
            else if (value.startsWith("http://") || value.startsWith("https://")) {
                nw = value;
            } else {
                nw = "https://" + value;
            }
            props.onChange && props.onChange(nw);
        } else {
            props.onChange && props.onChange(null);
        }
    }

    return (
        <Input
            {...props} 
            onChange={(e) => handleChange(e.target.value)}
        />
    )
}