import { Transfer as AntTransfer } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";

export type TransferProps = {
    dataSource?: any[];
    source?: any[];
    target?: any[];
    keyVariable?: string;
    labelVariable?: string;
    onChange?: (value:any[]) => void;
    value?: any;
}

export default function Transfer (props: TransferProps) {

    const [selected, setSelected] = useState<any[]>([]);
    const [target, setTarget] = useState<any[]>([]);

    const getKeyValue = (item: any) => props.keyVariable ? item[props.keyVariable] : item.key;

    const handleChange = (nextTargetKeys: any, direction: any, moveKeys: any) => {
        setSelected([]);

        let nt:any[] = [];
        if (direction == "right") {
            nt = _.union(target, moveKeys);
        } else {
            nt = _.difference(target, moveKeys);
        }

        setTarget(nt);

        const v:any[] = props.dataSource?.filter((d) => nt.includes(d.key)) || [];
        props.onChange && props.onChange(v);

    }

    useEffect(() => {
         setTarget(props.value?.map((v:any) => getKeyValue(v)) || []);
    }, [props.value]);

    const handleSelectChange = (sourceSelectedKeys: any, targetSelectedKeys: any) => {
        const sk = Array.isArray(sourceSelectedKeys) ? sourceSelectedKeys : [sourceSelectedKeys]
        const tk = Array.isArray(targetSelectedKeys) ? targetSelectedKeys : [targetSelectedKeys]
        setSelected([...sk, ...tk]);
    }

    return (
        <AntTransfer
            className="new-ant-transfer"
            oneWay={false}
            showSelectAll={false}
            selectedKeys={selected}
            targetKeys={target}
            dataSource={props.dataSource}
            render={item => props.labelVariable ? item[props.labelVariable] : item.label}
            onChange={handleChange}
            onSelectChange={handleSelectChange}
        />
    )
}