import { useEffect } from "react";
import useAPI, { callAPIProps, callAPISettings } from "./useAPI"
import useSelectorWithParams from "./useSelectorWithParams";
import useEffectDeepCompare from "./useEffectDeepCompare";

type DataPrefillProps = {
    dataSelector: any,
    callAPI: callAPIProps,
    checkFunction: (data: any) => any
}

type DataPrefillReturn = {
    call: (forceBody?: any) => void,
    loading: boolean,
    error: any
}

const useDataPrefill = (props: DataPrefillProps):DataPrefillReturn => {
 
    const data = useSelectorWithParams(props.dataSelector);
    const {call, data: responseData, loading, error} = useAPI(props.callAPI);

    const checkAndCall = (forceBody?: any) => {
        const check = props.checkFunction(data);
        if (check) {
            if (!loading) {
                if (forceBody) {
                    call({body: forceBody});
                    return;
                }
                call({body: check});
            }
        }
    }

    useEffectDeepCompare(() => {
        checkAndCall();
    }, [data])

    const forceCall = (forceBody?: any) => checkAndCall(forceBody);

    return {
        call: forceCall,
        loading,
        error,
    }

}

export default useDataPrefill;