import FormattedMessage from "components/common/FormattedMessage";
import { Title } from "components/common/StandardText";
import BuyNowCard from "components/purchases/BuyNowCard";
import ListOfProducts from "modules/panel/containers/products/ListOfProducts";

const ProductsPage = () => {

    return (
        <div>
            <Title>
                <FormattedMessage
                    id="panel.pages.products.specialOffers"
                    defaultMessage="Special Offers"
                />
            </Title>
            <div style={{
                display: "flex",
                gap: "10px",
                justifyContent: "space-between",
            }}>
                <BuyNowCard
                    product="FastTonyPl"
                    offer="yearly"
                />
                <BuyNowCard
                    product="Insta"
                    offer="yearly"
                />
                <BuyNowCard
                    product="Post"
                    offer="yearly"
                />
            </div>
            <Title>
                <FormattedMessage
                    id="panel.pages.products.allProducs"
                    defaultMessage="All Products"
                />
            </Title>
            <ListOfProducts />
        </div>
    )

}

export default ProductsPage;