import { callAPIProps } from "services/hooks/useAPI";
import { projectsSlice } from "./projectsSlice";
import _ from "lodash";

export const CreateNewProject:callAPIProps = {
    url: ({ getApiUrl }) => getApiUrl(`projects`, 3),
    method: "POST",
    successDispatch: projectsSlice.actions.getProject
}

export const DeleteProject = (projectId: string | number): callAPIProps => ({
    url: ({ getApiUrl }) => getApiUrl(`projects/${projectId}`),
    method: "DELETE",
    successDispatch: projectsSlice.actions.deleteProject,
    customNormalizer: (data: any) => ({...data, projectId: projectId})
})

export const EditProject:callAPIProps = {
    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}`),
    method: "PATCH",
    successDispatch: projectsSlice.actions.getProject,
}

export const EditProjectRecursionFix:callAPIProps = {
    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}`),
    method: "PATCH",
    successDispatch: projectsSlice.actions.getProject,
    customNormalizer: (data:any) => data,
    adjustResponse: (normalizedData, responseData, normalizer) => {

   

        const findInIncluded = (type:string) => {
            const found = responseData.included.find((item:any) => item.type === type);
            if (!found) return null;
            return found.attributes;
        }

        const atr = responseData.data.attributes;

        const adjusted:any = {
            id: atr.id,
            name: atr.name,
            payer: atr.payer,
            beneficiary: atr.beneficiary,
            service_facebook_ads_account_id: atr.service_facebook_ads_account_id,
            service_facebook_ads_account_instagrams_id: atr.service_facebook_ads_account_instagrams_id,
            service_facebook_ads_account_pixels_id: atr.service_facebook_ads_account_pixels_id,
            service_facebook_page_id: atr.service_facebook_page_id,
            service_google_ads_id: atr.service_google_ads_id,
            service_google_analytics_id: atr.service_google_analytics_id,
            service_google_my_business_id: atr.service_google_my_business_id,
            service_google_sites_id: atr.service_google_sites_id,
        }

        if (findInIncluded("service_facebook_ads_account")) {
            adjusted.service_facebook_ads_account = findInIncluded("service_facebook_ads_account");
        }
        if (findInIncluded("service_facebook_ads_account_instagrams")) {
            adjusted.service_facebook_ads_account_instagrams = findInIncluded("service_facebook_ads_account_instagrams");
        }
        if (findInIncluded("service_facebook_ads_account_pixels")) {
            adjusted.service_facebook_ads_account_pixels = findInIncluded("service_facebook_ads_account_pixels");
        }
        if (findInIncluded("service_facebook_page")) {
            adjusted.service_facebook_page = findInIncluded("service_facebook_page");
        }
        if (findInIncluded("service_google_ads")) {
            adjusted.service_google_ads = findInIncluded("service_google_ads");
        }
        if (findInIncluded("service_google_analytics")) {
            adjusted.service_google_analytics = findInIncluded("service_google_analytics");
        }
        if (findInIncluded("service_google_my_business")) {
            adjusted.service_google_my_business = findInIncluded("service_google_my_business");
        }
        if (findInIncluded("service_google_sites")) {
            adjusted.service_google_sites = findInIncluded("service_google_sites");
        }
        

        return adjusted;

    }
}

export const UpdateProjectStatus:callAPIProps = {
    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/updateStatus`),
    method: "PATCH",
    successDispatch: projectsSlice.actions.updateProjectStatus,
    customNormalizer: (data: any) => data,
    adjustResponse: (normalizedData, responseData, normalizer) => {
        return {
            status: responseData.status
        }
    }
}