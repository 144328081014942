import { createSelector } from "@reduxjs/toolkit";
import { currentProjectIdSelector } from "services/store/scopeSlice";
import { projectsSelector } from "./projectsSelectors";
import { subscriptionsSelector } from "state/subscriptions/subscriptionsSelectors";
import { mapSubscriptionsToProject } from "./projectHelpers";

export const projectByIdSelector = (projectId:number | string) => createSelector(
    projectsSelector,
    (projects) => projects && projects?.[projectId] ? projects[projectId] : null
  );

export const currentProjectSelector = createSelector([
    currentProjectIdSelector,
    projectsSelector
], (projectId, projects) => {
    return projects[projectId];
});

export const currentProjectWithSubscriptionsSelector = createSelector([
    currentProjectSelector,
    subscriptionsSelector,
], (project, subscriptions) => {
    return mapSubscriptionsToProject(project, subscriptions);
});

export const currentProjectIntegration = (field:string) => createSelector(
    currentProjectSelector,
    (currentProject) => currentProject[field] ?? null
);

export const currentProjectAnyField = createSelector(
    [currentProjectSelector,
    (state, field) => field],
    (currentProject, field) =>currentProject ? currentProject[field] : null
);

export const currentProjectOnboarded = createSelector(
    currentProjectSelector,
    (currentProject) =>
      currentProject ? currentProject.status !== "onboarding" : false
);

export const userHasActiveProject = createSelector(
    currentProjectIdSelector,
    (currentProjectId) => currentProjectId !== "null"
  );
  
export const panelProjectsSelector = createSelector(
    projectsSelector,
    currentProjectIdSelector,
    currentProjectSelector,
    (projects, id, project) => {
      return {
        current: {
          id:id,
          name:project?.name,
          avatar:project?.service_facebook_page?.global_brand_page_name,
        },
        list: projects,
      };
    }
  );

export const projectHasFilledGoogleProviderAllFields = createSelector(
  currentProjectSelector,
  (currentProject) => {
    return currentProject && currentProject.service_google_ads && currentProject.service_google_my_business && currentProject.service_google_analytics ? true : false
  }
)

export const projectHasFilledFacebookProviderAllFields = createSelector(
  currentProjectSelector,
  (currentProject) => {
    return currentProject && currentProject.service_facebook_page && currentProject.service_facebook_ads_account && currentProject.service_facebook_ads_account_pixels ? true : false
  }
)

  