import useAPI from "services/hooks/useAPI";
import { IntegrationSelect } from "./IntegrationSelect";

export const MailerLiteCampaignSelect = ({ ...rest }) => {
  const { call, data } = useAPI(
    {
      url: ({ getApiUrl, projectId }) =>
        getApiUrl(
          `projects/${projectId}/integrations/mailer-lite/groups`
        ),
      method: "GET",
      // fakeResponse: async (request) => ({
      //   status: 200,
      //   json: () => ({
      //     data: [
      //       {
      //         id: 2825239,
      //         total_recipients: 5,
      //         type: "regular",
      //         date_created: "2016-02-17 15:22:40",
      //         date_send: "2016-02-17 17:28:48",
      //         name: "imported template",
      //         subject: "subject",
      //         status: "sent",
      //         opened: {
      //           count: 2,
      //           rate: 40,
      //         },
      //         clicked: {
      //           count: 1,
      //           rate: 20,
      //         },
      //       },
      //       {
      //         id: 1111111,
      //         total_recipients: 5,
      //         type: "regular",
      //         date_created: "2016-02-17 15:22:40",
      //         date_send: "2016-02-17 17:28:48",
      //         name: "imported template",
      //         subject: "subject",
      //         status: "sent",
      //         opened: {
      //           count: 2,
      //           rate: 40,
      //         },
      //         clicked: {
      //           count: 1,
      //           rate: 20,
      //         },
      //       },
      //     ],
      //   }),
      // }),
    },
    true
  );

  return (
    <>
      <IntegrationSelect data={data} call={call} {...rest} />
    </>
  );
};
