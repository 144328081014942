import { Alert, Input, Space, Typography, notification } from "antd";
import StandardButton from "components/common/StandardButton";
import { useEffect, useState } from "react";
import useAPI, { APIErrorDisplay, callAPIProps } from "services/hooks/useAPI"
import { usePayment } from "../Payment";
import FormattedMessage from "components/common/FormattedMessage";
import { usePurchaseSubscription } from "./PurchaseSubscription";
import usePartnerCodeCheck from "./usePartnerCodeCheck";
import StandardActorCard from "components/common/StandardActorCard";


function PartnerCode(props: {
    onClick: (page: string) => void;
    className?: string;
}) {


    const { partnerCodeManager } = usePurchaseSubscription();
    const { checkPartnerCode, partner, loading, acceptPartner, inputCode, updateCode, partnerCode, error } = partnerCodeManager;


    useEffect(() => {
        if (partner) {
            if (inputCode !== partnerCode) updateCode(partnerCode);
        }
    }, []);

    const handleAccept = async () => {

        //console.log("accept", inputCode, partnerCode, partner)

        if (!partner) {
            checkPartnerCode(inputCode);
            return
        }

        if (partnerCode !== inputCode) {
            checkPartnerCode(inputCode);
            return;
        }

        acceptPartner();
        props.onClick && props.onClick("summary");

    }

    const partnerDisplay = partner ? <StandardActorCard actor={partner} className="standard-border with-background" /> : null;

    const errorMsg = error ? (
        <Alert
            message={(
                <FormattedMessage
                    id="tools.purchase.partnerCode.error"
                    defaultMessage="Invalid code"
                />
            )}
            type="error" 
        />
        ) : null

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px"
        }}
            className={props.className}
        >
            <Space
                direction="vertical"
            >

                <Typography.Title
                    level={3}>
                    {partner ?
                        <FormattedMessage
                            id="tools.purchase.partnerCode.yourPartner"
                            defaultMessage="Your Partner"
                        />
                        :
                        <FormattedMessage
                            id="tools.purchase.partnerCode.title"
                            defaultMessage="Partner Code"
                        />
                    }

                </Typography.Title>

                {partnerDisplay}

                <Typography.Text>
                    <FormattedMessage
                        id="tools.purchase.partnerCode.description"
                        defaultMessage="Code of your FastTony partner."
                    />
                </Typography.Text>
                <Input
                    onChange={(e) => updateCode(e.target.value)}
                    value={inputCode}
                    disabled={loading}
                />

                {errorMsg}

                <div style={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: "space-between"
                }}>
                    <StandardButton
                        type="text"
                        onClick={() => props.onClick && props.onClick("summary")}
                    >
                        <FormattedMessage
                            id="common.back"
                            defaultMessage="Back"
                        />
                    </StandardButton>
                    <StandardButton
                        onClick={handleAccept}
                        loading={loading}
                    >
                        {partner ?
                            <FormattedMessage
                                id="tools.purchase.partnerCode.accept"
                                defaultMessage="Accept"
                            />
                            :
                            <FormattedMessage
                                id="tools.purchase.partnerCode.useCode"
                                defaultMessage="Use this code"
                            />
                        }
                    </StandardButton>
                </div>

            </Space>
        </div>
    )
}

export default PartnerCode;