import { Input } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { useDebounceTrigger } from "services/hooks/useDebounce";

export const GalleryExternalUpload = (props:any) => {

    const [id, setId] = useState<string | null>("")


    const handleChange = (value:any) => {
        if (!value) return null;

        const videoId = value.split("v=")[1].split("&")[0];
        setId(videoId);
        props.onChange && props.onChange(`https://www.youtube.com/watch?v=${videoId}`);
    }

    const debouncedChangeHandler = useDebounceTrigger(handleChange, 500)

    const preview = useMemo(() => {
        if (!id) return null;
        return (
            <div>
                <img 
                style={{
                    height: "100px",
                    borderRadius: "10px"
                    }} 
                src={`https://img.youtube.com/vi/${id}/0.jpg`}/>
            </div>
        )
    }, [id])

    return (
        <div style={{display: "flex", flexDirection: "row"}} onBlur={props.onBlur}>
            <div
                style={{
                    display: "grid", 
                    placeItems: "center", 
                    width: "100%",
                    padding: "10px"
                }}
            >
            <Input 
            onChange={(e) => handleChange(e.target.value)}
            placeholder="https://www.youtube.com/watch?v=xxxxxxxxxxx"
            />
            </div>
            {preview}
        </div>
    )
}

//https://www.youtube.com/watch?v=TXcRwVjQojo&list=RDTXcRwVjQojo&start_radio=1