import RelaxSingleField from "tools/relaxForm/RelaxField";
import CompanyCategorySelect from "modules/forsant/components/business-details/company-search-select";
import FormattedMessage from "components/common/FormattedMessage";
import PhoneNumberInputWithCountrySelect from "_components/forms/PhoneNumberInputWithCountrySelect";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import ConnectedOnboardingControls from "providers/onboarding/controls";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";
import RelaxList from "tools/relaxForm/RelaxList";
import URLInput from "components/inputs/URLInput";
import { DatePickerWithAdjust } from "components/inputs/DatePicker";
import { GoogleSmartSettingsSelector, HasFilledBusinessDetailsSelector, UpdateGoogleSmartSettings } from "modules/smart/smartSlice";
import { ruleForbiddenDomains } from "tools/relaxForm/relaxPresets";

export default function ForsantOnboardingBusinessDetails() {
    const settings = useSelectorWithParams([GoogleSmartSettingsSelector]);

    const fulfilled = useSelectorWithParams(HasFilledBusinessDetailsSelector);

    return (
        <>
            <RelaxMultiform
                initialValues={{ ...settings, phones: (settings?.phones?.length > 0 ? settings.phones : ["+48"]) }}
                callAPI={UpdateGoogleSmartSettings}
                name="business-details">

                <RelaxSingleField
                    name="company_category"
                    required
                    label={
                        <FormattedMessage
                            id="apps.forsant.businessDetails.companyCategory"
                            defaultMessage="Company category"
                        />
                    }
                >
                    <CompanyCategorySelect />
                </RelaxSingleField>

                <RelaxSingleField
                    name="website_url"
                    required
                    label={
                        <FormattedMessage
                            id="apps.forsant.businessDetails.website"
                            defaultMessage="Company website"
                        />
                    }
                    rules={[ruleForbiddenDomains(["facebook.com", "instagram.com", "fb.com"])]}
                >
                    <URLInput />
                </RelaxSingleField>

                
                <RelaxList
                    name="phones"
                    label={
                        <FormattedMessage
                            id="apps.forsant.businessDetails.phones"
                            defaultMessage="Company phone number (same as on your website)"
                        />
                    }
                    rules={[{min: 1}]}
                >
                    <PhoneNumberInputWithCountrySelect />
                </RelaxList>



                <RelaxSingleField
                    name="start_date"
                    required
                    label={
                        <FormattedMessage
                            id="apps.forsant.businessDetails.creationDate"
                            defaultMessage="Company creation date"
                        />
                    }
                    // finalizeSubmit={(value: any) => {
                    //     return {
                    //         start_date: value.start_date.format("YYYY-MM-DD HH:mm:ss")
                    //     }
                    // }}
                >
                    <DatePickerWithAdjust />
                </RelaxSingleField>
            </RelaxMultiform>
            <ConnectedOnboardingControls nextDisabled={!fulfilled}/>
        </>
    )
}