import { faPlus } from "@fortawesome/pro-light-svg-icons"
import { Button, Input, Space, Table } from "antd"
import { ColumnType } from "antd/es/table"
import AwesomeIcon, { AwesomeIconDelete, AwesomeIconEdit } from "components/common/AwesomeIcon"
import StandardButton from "components/common/StandardButton"
import PasswordWithConfirmation from "components/forms/PasswordWithConfirmation"
import { userPhoneSelector } from "modules/panel/config/selectors/user"
import React from "react"
import { ReactElement, ReactNode, useEffect, useMemo, useRef, useState } from "react"
import FormattedMessage from "components/common/FormattedMessage";
import { useSelector } from "react-redux"
import useAPI, { APIErrorDisplay } from "services/hooks/useAPI"
import { StandardModal, useStandardModal } from "services/hooks/useModal"
import RelaxForm from "tools/relaxForm/RelaxForm"
import { ruleRequired, rulesetPassword } from "tools/relaxForm/relaxPresets"
import PhoneNumberInputWithCountrySelect from "_components/forms/PhoneNumberInputWithCountrySelect"
import RelaxSingleField from "tools/relaxForm/RelaxField"
import useRelaxData from "tools/relaxForm/useRelaxData"
import useSelectorWithParams from "services/hooks/useSelectorWithParams"


const AddPhoneForm = React.forwardRef(({ onAdded }: { onAdded: () => void }) => {

    const addPhone = useAPI({})
    const [modalRef, openModal, closeModal] = useStandardModal();
    const rd = useRelaxData({})

    const handleOK = () => {
        addPhone.call({
            url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/phones/register`),
            method: "POST",
            body: {
                ...rd.currentValue
            }
        }).then(res => {
            if (!res) return;
            if (res.status === 200 || res.status === 201) {
                closeModal();
                if (onAdded) onAdded();
            }
        })
    }

    return (
        <>
            <StandardButton shape="circle" icon={faPlus} tooltip="Add Phone" onClick={openModal} />
            <StandardModal
                mask="full"
                ref={modalRef}
                title={
                    <FormattedMessage
                        id="module.call.phones.addPhone"
                        defaultMessage="Add Phone"
                    />
                }
                onOk={handleOK}
                modalProps={{
                    okText: (
                        <FormattedMessage
                            id="common.add"
                            defaultMessage="Add"
                        />
                    ),
                }}
            >
                <RelaxForm
                    hideStatus={true}
                    relaxData={rd}
                    mode="manual"
                    onError={addPhone.error}
                >
                    <RelaxSingleField
                        name="phone"
                        label="Number"
                        rules={[ruleRequired]}
                    >
                        <PhoneNumberInputWithCountrySelect />
                    </RelaxSingleField>
                    <PasswordWithConfirmation />
                </RelaxForm>
                <APIErrorDisplay error={addPhone.error}/>
            </StandardModal>
        </>
    )

})


const EditPhone = React.forwardRef((props: any, ref: any) => {

    const editPhone = useAPI({})
    const [modalRef, openModal, closeModal] = useStandardModal();
    const rd = useRelaxData({})

    const handleOK = () => {
        editPhone.call({
            url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/phones/${props.phone.id}`),
            method: "PATCH",
            body: {
                new_phone: rd.currentValue.phone,
                new_password: rd.currentValue.password,
                new_password_confirmation: rd.currentValue.confirm_password,
            }
        }).then(res => {
            if (!res) return;
            if (res.status === 200 || res.status === 201) {
                closeModal();
                if (props.onEdit) props.onEdit();
            }
        })
    }

    return (
        <>
            <StandardButton
                disabled={props.phone?.id === "user"}
                shape="circle"
                icon={AwesomeIconEdit}
                tooltip={<FormattedMessage
                    id="module.call.phones.editPhone"
                    defaultMessage="Edit Phone" />}
                onClick={openModal} />
            <StandardModal
                mask="full"
                title={<FormattedMessage
                    id="module.call.phones.editingPhone"
                    defaultMessage="Editing Phone {number}"
                    values={{ number: props.phone?.phone_number }} />}
                ref={modalRef}
                onOk={handleOK}
                modalProps={{
                    okText: (
                        <FormattedMessage
                            id="common.edit"
                            defaultMessage="Edit"
                        />
                    ),
                }}
            >
                <RelaxForm
                    hideStatus={true}
                    relaxData={rd}
                    name="new-phone"
                    layout="vertical"
                    mode="manual"
                    initialValues={{
                        phone: props.phone?.phone_number,
                    }}
                >
                    <RelaxSingleField
                        name="phone"
                        label="Number"
                        rules={[ruleRequired]}
                    >
                        <PhoneNumberInputWithCountrySelect />
                    </RelaxSingleField>
                    <PasswordWithConfirmation />
                </RelaxForm>
                <APIErrorDisplay error={editPhone.error}/>
            </StandardModal>
        </>
    )
})

const DeletePhoneAction = (props: any) => {

    const [modalRef, openModal, closeModal] = useStandardModal();
    const { call } = useAPI({
        url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/phones/${props.phone.id}`),
        method: "DELETE"
    })

    const handleDone = (r: any) => {
        if (r && r.status === 204) {
            closeModal();
            if (props.onDelete) props.onDelete();
        }
    }

    return (
        <>
            <StandardButton disabled={props.phone?.id === "user"} shape="circle" icon={AwesomeIconDelete} tooltip="Delete" onClick={openModal} />
            <StandardModal
                mask="full"
                title={<FormattedMessage
                    id="module.call.phones.deletingPhone"
                    defaultMessage="Deleting Phone {number}"
                    values={{ number: props.phone?.phone_number }}
                />}
                ref={modalRef}
                onOk={() => { call().then(handleDone) }}
            >
                <p>Are you sure you want to delete this phone?</p>
                {props.phone?.phone_number}
            </StandardModal>
        </>
    )

}


export default function PhoneList(props: any) {

    const userPhone = useSelectorWithParams(userPhoneSelector);

    const { call: getPhones, data, loading, error, StatusDisplay } = useAPI({
        url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/phones`),
    }, true)

    const PhonesWithUserIncluded = useMemo(() => {
        return [
            {
                id: "user",
                phone_number: userPhone,
                verified: true,
            }, ...(data || [])
        ]

    }, [data, userPhone])


    const columns: ColumnType<any>[] = [
        {
            title: "Phone",
            key: "phone",
            width: "100%",
            render: (phone) => {
                if (phone.id === "user") {
                    return <b>{phone.phone_number} {"(Primary)"}</b>
                }
                return `${phone.phone_number}`
            }
        },
        {
            title: <FormattedMessage
                id="module.call.phones.verifiedColumn"
                defaultMessage="Verified" />,
            dataIndex: "verified",
            key: "verified",
            render: ver => ver ? <FormattedMessage
                id="module.call.phones.verified"
                defaultMessage="Verified" /> : <FormattedMessage
                id="module.call.phones.notVerified"
                defaultMessage="NotVerified" />
        },
        {
            title: "Actions",
            key: "actions",
            render: (item: any) => (
                <Space>
                    <EditPhone phone={item} onEdit={() => getPhones()} />
                    {/* <EditPhoneNumber phone={item} onEdit={() => getPhones()}/>
                    <EditPhonePassword phone={item} onEdit={() => getPhones()}/> */}
                    <DeletePhoneAction phone={item} onDelete={() => getPhones()} />
                </Space>
            )
        }
    ];

    const mainTools = useMemo(() => {
        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                <div style={{ width: "100%" }}>
                    <span><FormattedMessage
                        id="module.call.phones.yourPhones"
                        defaultMessage="Your Phones" /></span>
                </div>
                <AddPhoneForm onAdded={() => getPhones()} />
            </div>
        )
    }, [])



    if (loading || error) return StatusDisplay;

    return (
        <>
            <Table
                title={() => mainTools}
                dataSource={PhonesWithUserIncluded}
                columns={columns}
                pagination={false}
            />
        </>
    )
}