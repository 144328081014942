import React from "react";
import { ReactNode } from "react";
import './overlay.less'

type Props = {
  children: ReactNode;
  overlay?: ReactNode;
  onHover?: boolean;
};

const Overlay = ({ children, overlay, onHover }: Props) => (
  <div className={`overlay-container ${onHover && "on-hover"}`}>
    {overlay && <div className="overlay">{overlay}</div>}
    {children}
  </div>
);

export default Overlay;
