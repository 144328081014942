import { Empty, Rate, Space } from "antd"
import StandardActorCard from "components/common/StandardActorCard"
import StandardBox from "components/common/StandardBox"
import DateTimeDisplay from "components/displays/DateTimeDisplay"
import { useEffect, useMemo } from "react"
import useAPI, { callAPIProps } from "services/hooks/useAPI"
import useSelectorWithParams from "services/hooks/useSelectorWithParams"
import { OrganizationProjectSelector } from "../data/organizationSlice"
import StandardButton from "components/common/StandardButton"
import { ProjectSettingsSelector, UpdateProjectSettings } from "state/project/projectSettingsSlice"


const ReviewModarationRequest = (props: { review: Review }) => {

    const settings = useSelectorWithParams(ProjectSettingsSelector)

    const moderationRequests = settings?.moderationRequests;
    const wasRequested = moderationRequests?.includes(props.review.review_id)

    const moderationRequestCall = useAPI({})

    const handleRequestForModaration = () => {
        moderationRequestCall.call({...UpdateProjectSettings, 
                body: {
                    settings: {
                        moderationRequests: [...moderationRequests || [], props.review.review_id]
                    }
                }
            })
    }

    if (wasRequested) return (
        <StandardButton style={{
            padding: "8px",
            height: "30px",
            minHeight: "30px"
        }}
        disabled
        >
            Requested
        </StandardButton>
    )

    return (
        <StandardButton style={{
            padding: "8px",
            height: "30px",
            minHeight: "30px"
        }}
        onClick={handleRequestForModaration}
        >
            Request Moderation
        </StandardButton>
    )

}

//google my business reviews
type Review = {
    review_id: string,
    reviewer_name: string,
    reviewer_photo: string | null,
    response: string,
    rating: string,
    comment: string,
    create_time: string,
    update_time: string,
}

const getReviewsCall: callAPIProps = {
    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/mybusiness/reviews`)
}

const StartRatingToNumber = (starRating: string) => {
    switch (starRating) {
        case "ONE":
            return 1;
        case "TWO":
            return 2;
        case "THREE":
            return 3;
        case "FOUR":
            return 4;
        case "FIVE":
            return 5;
        default:
            return 0;
    }
}

const ReviewDisplay = (props: { review: Review }) => {

    const adjustedReviewer = {
        name: props.review.reviewer_name,
        image: props.review.reviewer_photo,
    }

    const stars = StartRatingToNumber(props.review.rating)

    return (
        <StandardBox>
            <>
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "10px"
            }}>
                <StandardActorCard actor={adjustedReviewer} style={{ width: "200px" }} />
                <DateTimeDisplay showDate showTime dateTime={props.review.create_time} />
                <Rate disabled defaultValue={stars} />
                <ReviewModarationRequest review={props.review} />
            </div>
            {props.review.comment}
            {props?.review?.response ?
                (
                        <div><b>Reply:</b>{props.review.response}</div>
                )
                : null}
            </>
        </StandardBox>
    )
}



export default function GoogleMyBusinessReviews(props: any) {

    const project = useSelectorWithParams([OrganizationProjectSelector, props.projectId])
    const getReviews = useAPI(getReviewsCall, true)

    const reviews: Review[] | null = useMemo(() => {
        if (!getReviews.data) return null;
        return getReviews.data.map((review: any) => ({
            ...review,
        }))
    }, [getReviews.data])

    const mappedReviews = useMemo(() => {
        if (!reviews) return null;
        return reviews.map((review: Review) => {
            return <ReviewDisplay review={review} />
        })
    }, [reviews])

    if (!project) return null;

    return (
        <div>
            {mappedReviews && mappedReviews.length > 0 ? mappedReviews : <Empty description="No Reviews"/>}
        </div>
    )

}