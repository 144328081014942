import { Select, Spin } from "antd";
import { HorizontalCenter } from "components/common/Center";
import useAPI from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { OrganizationGetGroups, OrganizationGroupsSelector, OrganizationIdSelector } from "../data/organizationSlice";


export default function OrganizationGroupSelect (props:any) {

    const groups = useSelectorWithParams(OrganizationGroupsSelector)
    const orgGetGroups = useAPI(OrganizationGetGroups(), true);
    
    if (orgGetGroups.loading) return <HorizontalCenter><Spin /></HorizontalCenter>

    return (
        <Select 
            onChange={props.onChange}
            onBlur={props.onBlur}
            style={{width: "100%"}}
            mode={props.mode || "single"}
        >
                    {groups?.map((form: any) => (
            <Select.Option value={form.id}>{form?.name}</Select.Option>
        ))}
        </Select>
    )
}

export const OrganizationGroupDisplay = (props: {groupId: string}) => {

    const groups = useSelectorWithParams(OrganizationGroupsSelector)
    const orgGetGroups = useAPI(OrganizationGetGroups(), true);
    
    if (orgGetGroups.loading) return <HorizontalCenter><Spin /></HorizontalCenter>

    const group = groups?.find((group: any) => group.id === props.groupId)
    return <span>{group?.name}</span>

}

export const OgranizationGroupsDisplay = (props: {groups: string[] | string}) => {

    const groups = useSelectorWithParams(OrganizationGroupsSelector)
    const orgGetGroups = useAPI(OrganizationGetGroups(), true);
    
    if (orgGetGroups.loading) return <HorizontalCenter><Spin /></HorizontalCenter>

    const groupIds = Array.isArray(props.groups) ? props.groups : [props.groups]
    return <span>{groupIds.map((groupId: string) => {
        const group = groups?.find((group: any) => group.id === groupId)
        return group?.name
    }).join(", ")}</span>

}