import { useEffect, useMemo } from "react";
import { useCancellationWizard } from "./SubscriptionCancellationWizard";
import useAPI, { APIErrorDisplay } from "services/hooks/useAPI";
import { CancelSubscription, getSubscription } from "state/subscriptions/subscriptionsEndpoints";
import { Alert } from "antd";
import { AwesomeNotOk } from "components/common/AwesomeIcon";
import StandardButton from "components/common/StandardButton";
import FormattedMessage from "components/common/FormattedMessage";
import PaymentMethodSelect, { StandalonePaymentMethodSelect } from "components/purchases/PaymentMenthodSelect";
import { useSimpleModal } from "services/hooks/useModal";
import Payment from "components/purchases/Payment";
import SubscriptionCancellationSummaryAlert from "./SubscriptionCancellationSummaryAlert";

function SubscriptionCancellationPayment() {

    const { subscriptionId, plan, goToStep } = useCancellationWizard();
    const { call, data, loading, error } = useAPI({})
    const susbcriptionCall = useAPI(getSubscription(subscriptionId))


    const handleCancelRequest = () => {
        if (!subscriptionId || !plan) return;
        call(CancelSubscription(subscriptionId, plan.plan)).then((res) => {
            console.log("Cancel request success", res);
            susbcriptionCall.call();
            goToStep("winback");
        })
    }

    const [openConfirmationModal, contextHolder] = useSimpleModal();


    const handleClick = () => {
        openConfirmationModal({
            title: <FormattedMessage
                id="panel.subscriptions.cancellation.selectPlan.confirmationModal.title"
                defaultMessage="Subscription Cancellation"
            />,
            content: <FormattedMessage
                id="panel.subscriptions.cancellation.selectPlan.confirmationModal.message"
                defaultMessage="Are you sure?"
            />,
            onOk: () => {
                handleCancelRequest();
            },
            type: "confirm"
        })
    }

    const handleSuccess = () => {
        goToStep("winback");
    }

    const errorAlert = useMemo(() => {

        if (!error) return null;

        return (
            <>
                <Alert message={
                    <>
                        <APIErrorDisplay error={error} />
                        <StandardButton onClick={handleCancelRequest}>
                            <FormattedMessage
                                id="common.retry"
                                defaultMessage="Retry"
                            />
                        </StandardButton>
                    </>
                } type="error" icon={<AwesomeNotOk />} />

            </>
        )

    }, [error])

    return (
        <div>
            <SubscriptionCancellationSummaryAlert />
            <div style={{
                paddingTop: "20px"
            }}></div>
            <Payment
                paymentCall={CancelSubscription(subscriptionId, plan!)}
                onComplete={handleSuccess}
                canPay={true}
                onCancel={() => goToStep("selectPlan")}
                paymentButtonLabel={<FormattedMessage
                    id="panel.subscriptions.cancellation.selectPlan.confirmationModal.confirmButton"
                    defaultMessage="Cancel subscription"
                />
                }
            >
                <PaymentMethodSelect />
            </Payment>
            {errorAlert}
            {contextHolder}
        </div>
    );
}

export default SubscriptionCancellationPayment;