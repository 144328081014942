import BudgetInputFacebook from "components/inputs/BudgetInputFacebook";
import FormattedMessage from "components/common/FormattedMessage";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import PeriodInput from "_components/forms/period-input";
import OnboardingControls from "providers/onboarding/controls";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";
import { useEffect, useMemo, useState } from "react";
import useAPI from "services/hooks/useAPI";
import { HorizontalCenter } from "components/common/Center";
import { Spin } from "antd";
import useDataPrefill from "services/hooks/useDataPrefill";
import { FacebookAdsSettingsSelector, HasFacebookBudgetFilledSelector, UpdateFacebookAdsSettings } from "modules/smart/smartSlice";

export default function OnboardingBudgetFacebook () {

    const {call, data, loading} = useAPI({
        url: ({getApiUrl, projectId}) => getApiUrl(`services/facebook/${projectId}/minBudget`),
        method: 'GET',
        customNormalizer: (data:any) => data?.data,
    }, true);

    useDataPrefill({
        dataSelector: FacebookAdsSettingsSelector,
        callAPI: UpdateFacebookAdsSettings,
        checkFunction: (data: any) => {
            if (!data) return false;
            let p:any = {};

            if (!data.standard_period) p.standard_period = 3;
            if (!data.link_period) p.link_period = 3;
            if (!data.video_period) p.video_period = 3;
            if (!data.message_period) p.message_period = 3;

            if (Object.getOwnPropertyNames(p).length === 0) return null;
            return p;
        }
    })


    const fulfilled = useSelectorWithParams(HasFacebookBudgetFilledSelector);
    const settings = useSelectorWithParams(FacebookAdsSettingsSelector);

    const [budget, setBudget] = useState<number>(settings.standard_daily_advertising_budget);
    const [period, setPeriod] = useState<number>(settings.standard_period || 3);

    useEffect(() => {
        if (data) {
            setBudget(Math.ceil(data.min_daily_budget) * 2);
        }
    }, [data]);

    const budgetDisplay = useMemo(() => {
        return (
            <h1>
                {budget} x {period} = {budget * period}
            </h1>
        )
    }, [budget, period])

    if (loading) return (<HorizontalCenter><Spin /></HorizontalCenter>)

    return (
        <RelaxMultiform
            name="businessSettings"
            initialValues={{
                budget: settings.standard_daily_advertising_budget,
                period: settings.standard_period,
            }}
            callAPI={UpdateFacebookAdsSettings}
            finalizeSubmit={(values) => {
                values.budget && setBudget(values.budget)
                values.period && setPeriod(values.period)
                return {
                    link_daily_advertising_budget: values.budget,
                    link_period: values.period,
                    message_daily_advertising_budget: values.budget,
                    message_period: values.period,
                    standard_daily_advertising_budget: values.budget,
                    standard_period: values.period,
                    video_daily_advertising_budget: values.budget,
                    video_period: values.period,
                }
            }}
        >
            <FormattedMessage
              id="apps.smart.forms.budget.facebook"
              defaultMessage="Facebook"
            />
            <RelaxSingleField
                            name="budget"
                            label={<FormattedMessage
                                id="apps.smart.components.adsSettings.dailyBudgetLabel"
                                defaultMessage="Your daily advertising budget"
                            />}
                            submitOnChange={true}
                        >
                            <BudgetInputFacebook
                                className="fullwidth"
                                defaultBudget={(minBudget:number) => minBudget*2}
                            />
                        </RelaxSingleField>
                        <RelaxSingleField
                            name="period"
                            label={<FormattedMessage
                                id="apps.smart.components.adsSettings.promotionPeriod"
                                defaultMessage="Period of time to promote post"
                            />}
                            initialValues={{period: period}}
                            submitOnChange={true}
                        >
                            <PeriodInput
                                className="fullwidth"
                            />
                        </RelaxSingleField>

                        {budgetDisplay}
                        
           <OnboardingControls relaxDontSubmit = {true} nextDisabled={(!fulfilled)}/>
        </RelaxMultiform>
    )
}