import { Select } from "antd";
import { useEffect, useMemo, useState } from "react";
import Fuse from "fuse.js";
import { isValidHelpKey, useHelpPanel } from "./HelpPanelDrawer";
import { useIntl } from "react-intl";
import FormattedMessage from "components/common/FormattedMessage";

const HelpPanelSearch = (props:any) => {

    const {intl} = useHelpPanel();
    const [options, setOptions] = useState<any[]>([]);
    const {openHelpPanel} = useHelpPanel();



    const messages = useMemo(() => {
        
        if (!intl || !intl.messages) return [];
        const msg = intl.messages;
        const adjMsg:any = {};

        for (var key in msg) {

            const v = msg[key];
            
            if (!v || v.length === "") continue;
            if (isValidHelpKey(v)) continue;
            adjMsg[key] = v;
        }

        return Object.entries(adjMsg).map(([key, value]) => ({ key, value }))
    
    }, [intl])


    const fuseOptions = {
        keys: ["key", "value"],
        includeScore: true,
    }

    const fuse = useMemo(() => new Fuse(messages, fuseOptions), [messages])

    const handleSearch = (value:any) => {

        const result = fuse.search(value);
        const mappedOptions = result.map((res:any) => {

            const text = res.item.value;
            const title = text.split("\n")[0].replace("#", "").trim();

            return {
                label: title,
                value: res.item.key
            }

        })
        setOptions(mappedOptions)

    }

    const handleSelect = (value:any) => {
        openHelpPanel(value);
    }

    return (
        <Select 
        style={{
            width: "100%"
        }}
        placeholder={<FormattedMessage id="help.search.placeholder" defaultMessage="Search..."/>}
        showSearch
        onSearch={handleSearch}
        onSelect={handleSelect}
        options={options}
        />
    )

}

export default HelpPanelSearch;