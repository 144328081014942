import { Checkbox, Col, Row } from "antd";
import useDataSelector from "services/hooks/useDataSelector";
import { useEffect, useMemo } from "react";

export type multiselectProps = {
    gridSizing?: any,
    onChange?:any,
    value?:any,
    options?:any,
    min?: number, 
    max?: number,
}

const SingleCheckbox = (props:any) => {
    return (
        <Checkbox value={props.value}>{props.displayName}</Checkbox>
    )
}

export const MultiSelectCheckbox = (props:multiselectProps) => {

    const wrappedOptions = useMemo(()=>{
        //console.log("Options updated", props.options);
        //console.log("Values", props.value)
        return props.options;
    },[props.options])

    const mappedOptions = useMemo(() => 
        ((wrappedOptions as any) || []).map((o:any) =>
            <Col {...{lg:8,xs:24}}{ ...props.gridSizing} key={o.value}>
                <SingleCheckbox value={o.value} displayName={o.label} />
            </Col>
        ),
        [wrappedOptions, props.value])

    const handleChange = (checkedValue: any) => {
        if (props.max === 1) {
            if (checkedValue.length > 1) 
            checkedValue = [checkedValue[checkedValue.length-1]]
        }
        if (props.onChange) props.onChange(checkedValue);
    }

    return (
        <>
        <Checkbox.Group 
        onChange={handleChange}
        value={props.value}>
            <Row>
            {mappedOptions}
            </Row>
        </Checkbox.Group>
        </>
    )

}

export default MultiSelectCheckbox;