import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { useCancellationWizard } from "./SubscriptionCancellationWizard";
import { SubscriptionCancellationPlan, SubscriptionCancellationPlanName } from "state/subscriptions/subscriptionTypes";
import { subscriptionCancellationPlansSelector } from "state/subscriptions/subscriptionsSelectors";
import { ReactNode, useEffect, useState } from "react";
import { Card, Divider, Radio, Select, Space } from "antd";
import SubscriptionCancellationPlanDisplay, { CancellationPlansStrings } from "./SubscriptionCancellationPlanDisplay";
import { HorizontalCenter } from "components/common/Center";
import { Title } from "components/common/StandardText";
import FormattedMessage from "components/common/FormattedMessage";
import StandardButton from "components/common/StandardButton";
import StandardActorCard, { StandardSubscriptionCard } from "components/common/StandardActorCard";
import ProductLogo from "providers/routing/layouts/components/product-logo/product-logo";
import WizardButtons from "components/misc/WizardButtons";
import { useSimpleModal } from "services/hooks/useModal";
import { FormattedNumber } from "react-intl";
import SubscriptionCancellationSummaryAlert from "./SubscriptionCancellationSummaryAlert";
import { CancellationPlansConfig } from "./SubscriptionCancellationConfig";


function SubscriptionCancellationSelectPlan() {

    const { subscriptionId, goToStep, plan, setPlan, subscription, closeWizard } = useCancellationWizard();

    const plans = useSelectorWithParams([subscriptionCancellationPlansSelector, subscriptionId]);

    useEffect(() => {
        setPlan(plans?.[0]);
    }, [plans]);


    const handleSelect = (selectedPlan: SubscriptionCancellationPlanName) => {
        setPlan(selectedPlan);
        if (selectedPlan === "cancel") {
            goToStep("outstandingPaymentWarning")
        } else {
            goToStep("summary")
        }
    }

    return (
        <>
            <SubscriptionCancellationSummaryAlert />
            <Space direction="vertical" size="middle" style={{ width: "100%", paddingTop: "50px" }}>

                <Card
                    title={
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}>
                            <Title
                                noMargins
                                style={{
                                    overflow: "hidden"
                                }}
                                level={2}>
                                {CancellationPlansConfig.close.title}
                            </Title>
                            <StandardButton
                                onClick={() => handleSelect("close")}
                                size="small"

                            >
                                <FormattedMessage
                                    id="common.select"
                                    defaultMessage="Select"
                                />
                            </StandardButton>
                        </div>
                    }
                >

                    <p>
                        {CancellationPlansConfig.close.description}
                    </p>
                    <p>
                        {CancellationPlansConfig.close.outcome}
                    </p>
                </Card>

                <Card
                    title={
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            
                            alignItems: "center"
                        }}>
                            <Title
                                noMargins
                                style={{
                                    overflow: "hidden"
                                }}
                                level={2}>
                                {CancellationPlansConfig.cancelNow.title}
                            </Title>
                            <StandardButton
                                onClick={() => handleSelect("closeNow")}
                                size="small"
                            >
                                <FormattedMessage
                                    id="common.select"
                                    defaultMessage="Select"
                                />
                            </StandardButton>
                        </div>
                    }
                >



                    <p>
                        {CancellationPlansConfig.cancelNow.description}
                    </p>
                    <p>
                        {CancellationPlansConfig.cancelNow.outcome}
                    </p>
                </Card>

                <Card
                    title={
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            
                            alignItems: "center"
                        }}>
                            <Title
                                noMargins
                                level={2}
                                style={{
                                    overflow: "hidden"
                                }}
                                >
                                {CancellationPlansConfig.cancel.title}

                            </Title>
                            <StandardButton
                                onClick={() => handleSelect("cancel")}

                                size="small"
                            >
                                <FormattedMessage
                                    id="common.select"
                                    defaultMessage="Select"
                                />
                            </StandardButton>
                        </div>
                    }>
                    <p>
                        {CancellationPlansConfig.cancel.description}
                    </p>
                    <p>
                        {CancellationPlansConfig.cancel.outcome}
                    </p>
                </Card>






                <WizardButtons
                    canGoBack={true}
                    canGoForward={"hidden"}
                    onBack={closeWizard}
                />

            </Space>

        </>
    )

}

export default SubscriptionCancellationSelectPlan;