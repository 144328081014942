import { ReactNode, useMemo } from "react";
import StandardAvatar from "./Avatar/StandardAvatar";
import { getProductLogo } from "providers/routing/layouts/components/product-logo/smallProductLogo";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { libraryProjectSelector } from "state/librarySlice";
import "./common.less";
import { IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { Tooltip } from "antd";
import { Subscription } from "state/subscriptions/subscriptionTypes";
import { projectByIdSelector } from "state/projects/projectSelectors";

type ActorCardProps = {
    actor?: any,
    extra?: ReactNode,
    style?: any,
    className?: string,
    size?: "small" | "medium" | "large" | "fullWidth" | undefined | null
}

export type ActorInfo = {
    name: string;
    image: string;
    subtitle: string;
    icon?: IconDefinition;
}

export default function StandardActorCard(props: ActorCardProps) {
    const info = useMemo(() => {

        if (!props.actor) return null;

        const a = props.actor;
        let i: Partial<ActorInfo> = {}
        if (a.img) i.image = a.img;
        if (a.image) i.image = a.image;
        if (a.logo) i.image = a.logo;

        if (a.userName) i.name = a.userName;
        if (a.name) i.name = a.name;
        if (a.title) i.name = a.title;

        if (a.role) i.subtitle = a.role;
        if (a.subtitle) i.subtitle = a.subtitle;
        if (a.status) i.subtitle = a.status;

        return a;


    }, [props])

    if (!info) return null;

    return (
        <Tooltip
            title={
                <>
                    <span>{info.name}</span>
                    <br />
                    <span>{info.subtitle}</span>
                </>
            }
        >
            <div
                className={`standard-actor-card ${props.className ? props.className : ""} ${props.size ? props.size : "medium"}`}
            >
                <div className="standard-actor-card-avatar">
                    <StandardAvatar image={info.image} size={props.size}/>
                </div>
                <div className="standard-actor-card-text">
                    <span className="standard-actor-card-title">{info.name}</span>
                    <span className="standard-actor-card-subtitle">
                        {info.subtitle}
                    </span>
                </div>
                {props.extra}
            </div>
        </Tooltip>
    )

}


export function StandardSubscriptionCard(props: { subscription: Subscription }) {

    if (!props.subscription) return null;

    return (
        <StandardActorCard actor={{
            name: props.subscription?.name ? props.subscription.name.replace(/\b\w/g, (c:string) => c.toUpperCase()) : props.subscription?.combinedProduct?.product,
            image: getProductLogo(props.subscription?.combinedProduct?.product),
            subtitle: `ID: ${props.subscription?.id}`
        }} />
    )
}

export const StandardProjectCard = ({ projectId, extra, style }: {projectId:string | number, extra?:ReactNode, style?:any}) => {

    const project = useSelectorWithParams(projectByIdSelector(projectId));

    if (!project) return null;

    return <StandardActorCard actor={project} style={style} extra={extra} size="fullWidth"/>

}