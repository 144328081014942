import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Input } from "antd";
import { useState } from "react";
import RelaxSingleField from "tools/relaxForm/RelaxField";

type Passwords = {
    password: string,
    passwordConfirmation: string
}

export default function PasswordWithConfirmation() {

    const [passwords, setPasswords] = useState<Passwords>({
        password: "",
        passwordConfirmation: "",
    });

    const areEqual = passwords.password === passwords.passwordConfirmation;
    const extraErrors = areEqual ? [] : ["Passwords don't match"];

    return (
        <>
            <RelaxSingleField
                name="password"
                label="Password"
                rules={[{type: "password"}]}
                extraErrors={extraErrors}
                onChange={(e:any) => setPasswords((np:Passwords) => ({...np, password: e}))}
                mode="manual"
            >
                <Input.Password
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
            </RelaxSingleField>
            <RelaxSingleField
                name="confirm_password"
                label="Confirm Password"
                rules={[{type: "password"}]}
                extraErrors={extraErrors}
                onChange={(e:any) => setPasswords((np:Passwords) => ({...np, passwordConfirmation: e}))}
                mode="manual"
            >
                <Input.Password
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
            </RelaxSingleField>
        </>
    )
}