import { ProductConfig } from "products";
import logo from "./FastTonyHospital-Logo.png";
import requirements from "./requirements";

//'FastTonyPl', 'SmartCall', 'Post', 'forsant'
export const hospital1Config: ProductConfig = {
    name: "Hospital1",
    logo, 
    requirements: requirements,
}

//'FastTonyPl', 'SmartCall', 'Post', 'forsant', 'CallMe', 'LeadAds'
export const hospital2Config: ProductConfig = {
    name: "Hospital2",
    logo, 
    requirements: requirements,
}
