import { FunctionComponent, ComponentClass, ReactNode } from "react";
import { Animation } from "providers/routing/switch/animated";

export enum Layout {
  Panel = "PANEL_LAYOUT",
  TwoColumns = "TWO_COLUMNS_LAYOUT",
  Edit = "EDIT_PAGE_LAYOUT",
  Blank = "BLANK_LAYOUT",
}

export type Config = {
  key: Layout;
  layout: FunctionComponent<{ children: ReactNode }> | ComponentClass<any>; 
  content?: FunctionComponent<
    any
  >;
  animation?: Animation;
};
