import { useMemo } from "react";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { GetIndustryData, industryChildrenOfCategorySelector } from "state/industries/industriesSlice";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import MultiSelectCheckbox from "_components/forms/multiselect-checbox";

export default function RoomCategorySelector (props:any) {

    const {category, options} = useSelectorWithParams([industryChildrenOfCategorySelector, props.industry, props.category], {   
        //callAPIIfNull: GetIndustryData(props.industry)  
    }) || {};

    const mappedOptions = useMemo(() => options && options.map((o:any) => 
       ({value: o.id, label: o.name})   
    ), [options])

    const mappedValues = useMemo(() => {
       // console.log("Mapping", props.roomCategories)
      //  console.log("Category", category)
        if (!props.roomCategories) return [];
        if (!category) return [];
        const mapped =  props.roomCategories
        .filter((c:any) => c.path && c.path[c.path.length-1] === category.id)
        .map((c:any) => c.id)
        //console.log(`room ${props.roomId}, ${props.category}`, mapped)
        return mapped;
    }, [props.roomCategories])

    

    return (
       <RelaxSingleField
        name={"categories"}
        label={props.label}
        callAPI={{
            url: ({getApiUrl, projectId}) => getApiUrl(`projects/${projectId}/websites/object/room/${props.roomId}/categories/${category?.id || ""}`),
            method: "PATCH",
            auth: true,
            //successDispatch: CategoriesSlice.actions.update,
            passToDispatcher: {roomId: props.roomId, category: category},
        }}
        initialValues={{categories: mappedValues}}
       >
                <MultiSelectCheckbox min = {1} max = {0} options={mappedOptions} />
       </RelaxSingleField>
    )
}