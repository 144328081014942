import FormattedMessage from "components/common/FormattedMessage"

export const BlankOfferSummary: React.FC = (props:any) => {

    return (
        <div className={`subscription-price-details ${props.className ? props.className : ""}`}>
        <div></div>
  
        <div>
          <div className="heading">
            <h1>
              <FormattedMessage
                id="panel.containers.payment.summarySidebar.billingDetails"
                defaultMessage="Billing details"
              />
            </h1>
            <p className="offer-title">{<FormattedMessage
                    id="panel.containers.payment.summarySidebar.lengthInfo.BLANK"
                    defaultMessage="Choose your plan"
                />}</p>
          </div>
          
          <div className="line">
            <div className="name">
              <h3>
                <FormattedMessage
                  id="panel.containers.payment.summarySidebar.subscription"
                  defaultMessage="Subscription"
                />
              </h3>
    
              {/* {
                o.cycle === "yearly" && (
                  <p className="underline">
                    {montlyPriceOfYearlySubscription()}
                  </p>
                )
              } */}
    
              <p className="underline">
              <span>
                    <FormattedMessage
                        id="panel.containers.payment.summarySidebar.subscription.priceNet"
                        defaultMessage="Price {price} net."
                        values={{ price: "-" }}
                    />
                </span>
                <span>
                    -
                </span>
              </p>
    
    
            </div>
            <div className="value">-
            </div>
          </div>
          {/* {o.additionalFees &&
            o.additionalFees.items.map(({ name, value, gross }) => (
              <div className="line">
                <div className="name">
                  <h3>{name}</h3>
                  <p className="underline">
                    {netPriceWithPeriod(value, "oneTime")}
                  </p>
                </div>
                <div className="value">{formatWithCurrency(gross)}
                </div>
              </div>
            ))} */}

          <div className="line pay-now">
            <div className="name">
              <h3>
                <FormattedMessage
                  id="panel.containers.payment.summarySidebar.youPayNow"
                  defaultMessage="You pay now"
                />
              </h3>
              <p className="underline">
                <span>
                  <FormattedMessage
                    id="panel.containers.payment.summarySidebar.subscription.priceNet"
                    defaultMessage="Price {price} net."
                    values={{ price: "-" }}
                  />
                </span>
                <span>
                  {" "}
                  <FormattedMessage
                    id="panel.containers.payment.summarySidebar.includesVat"
                    defaultMessage="Includes {taxRate}% tax"
                    values={{ taxRate: "-" }}
                  />
                </span>
              </p>
            </div>
            <div className="value">-</div>
          </div>
        </div>
  
            <div style={{height: "200px"}}></div>
  
        
  
      </div>
    )

}