import * as Sentry from "@sentry/browser";
import { ExtraErrorData } from "@sentry/integrations";
import { createReduxEnhancer, reactRouterV5Instrumentation } from "@sentry/react";
import React from "react";
import { useLocation } from "react-router-dom";

type SentryMonitor = {
    lastEventId?: string;
    lastCalls?: any;
}

export const sentryMonitor: SentryMonitor = {
    lastEventId: undefined,
}

const bindToFreshdesk = () => {
    //const freshdeskLauncher = document.querySelector("[data-testid='launcher-wrapper']");
    //find div with class "launcher-button" 

    const queryInIframe = (iframe: Element, selector: string) => {

        const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
        const iframeElement = iframeDocument.querySelector(selector);
        return iframeElement;

    }

    const freshdeskIframe = document.querySelector("#launcher-frame");
    if (!freshdeskIframe) return;

    let freshdeskLauncher;
    freshdeskLauncher = queryInIframe(freshdeskIframe, ".launcher-button");

    if (freshdeskLauncher) {
        freshdeskLauncher.addEventListener("click", () => {
            if (!sentryMonitor.lastEventId) {
                SentryCaptureSupportPopup({});
            }
            setTimeout(() => {

                const formIframe = document.querySelector("#widget-frame");
                if (!formIframe) return;
                const sendButton = queryInIframe(formIframe, "#form-button");

                if (sendButton) {
                    sendButton.addEventListener("click", () => {
                        // if (!sentryMonitor.lastEventId) SentryCaptureSupportPopup({});
                    })
                }
            }, 1000)



        })
    }
}

const InitializeSentry = () => {
    if (import.meta.env.VITE_SENTRY_DSN) {
        Sentry.init({
            dsn: import.meta.env.VITE_SENTRY_DSN,
            environment: window.location.host,
            integrations: [
                new ExtraErrorData({ depth: 4 }) as any,
                new Sentry.Integrations.Breadcrumbs({
                    console: false,
                    xhr: false,
                    fetch: true,
                }),
                // new Sentry.BrowserTracing({
                //     routingInstrumentation: reactRouterV5Instrumentation(
                //         React.useEffect,
                //       ),     
                // })
            ],
            normalizeDepth: 5,
            beforeSend: (event, hint) => {
                console.log("Sentry event", event, hint);
                if (event.event_id) {
                    sentryMonitor.lastEventId = event.event_id;
                }

                window.FreshworksWidget && window.FreshworksWidget('prefill', 'ticketForm', {
                    custom_fields: {
                        cf_event_id: sentryMonitor.lastEventId
                    }
                })

                return event;
            },
        });
    }

    //freshdesk observer


    setTimeout(bindToFreshdesk, 2000);

}

const ignoredActions: string[] = [
    "RELOGIN_USER_REQUEST",
    "RELOGIN_USER_SUCCESS",
    "RELOGIN_USER_FAILURE",
    "LOGIN_SUCCESS",
    "LOGIN_FAILURE",
    "LOGIN_REQUEST",
    "LOAD_USER_REQUEST",
    "LOAD_USER_SUCCESS",
    "CLEAR_ACTIONS_STATUSES",
    "userSettings/get",
    "notificationsSlice/GetNotifications",
    "tasks/getUserTasks",
    "tasks/getProjectTasks"
]

export const sentryReduxEnhancer = createReduxEnhancer({
    actionTransformer: (action) => {
        if (ignoredActions.includes(action.type)) {
            return null;
        }
        return action;
    }
})

export const SentryCaptureSupportPopup = (data: any) => {
    Sentry.withScope((scope) => {
        scope.setTag("type", "support-popup");
        scope.setExtras(data as any);
        scope.setTag("buildDate", import.meta.env.BUILD_DATE);
        Sentry.captureEvent({
            message: "Support popup",
            extra: {
                error: data,
            },
        });
    });
}

export const SentryCaptureError = (error: any, info: any) => {

    try {

        Sentry.withScope((scope) => {
            scope.setTag("type", "feature-error");
            scope.setExtras(error as any);
            scope.setTag("buildDate", import.meta.env.BUILD_DATE);
            Sentry.captureEvent({
                message: "Feature Error",
                extra: {
                    error: info,
                },
            });
        });

    } catch (e) {
        console.error("Error capturing error", e);

    }

}

export const SentryCaptureException = (error: Error, errorInfo: React.ErrorInfo) => {
    Sentry.withScope((scope) => {
        scope.setTag("type", "general-app-error");
        scope.setExtras(errorInfo as any);
        scope.setTag("buildDate", import.meta.env.BUILD_DATE);
        Sentry.captureException(error);
    });
}



export default InitializeSentry;

