import { CodeBox } from "components/displays/CodeDisplay";
import { StandardBox } from "components/layout/StandardBoxes";
import FormattedMessage from "components/common/FormattedMessage";
import useAPI from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import {
  DomainSelector,
  UserWebsiteUrl,
} from "modules/site-generator/reducers/websiteSlice";

export default function TrackingWebsite() {
  const { call, data, loading, error, StatusDisplay } = useAPI(
    {
      url: ({ getApiUrl, projectId }) =>
        getApiUrl(`projects/${projectId}/website`, 2),
    },
    true
  );

  const websiteUrl = useSelectorWithParams(UserWebsiteUrl);
  const domain = useSelectorWithParams(DomainSelector);

  if (error || loading) return StatusDisplay;

  return (
    <>
      <FormattedMessage
        id={"website.box.description"}
        defaultMessage={
          "Below you will find the working address and the correct one for your website."
        }
      />
      <div style={{ height: "10px" }} />
      <CodeBox code={websiteUrl} style={{ height: "50px", minHeight: "50px" }} />
      <CodeBox code={domain} style={{ height: "50px", minHeight: "50px" }} />
    </>
  );
}

export const TrackingWebsiteBox = () => {
  return (
    <StandardBox
      title={
        <FormattedMessage
          id={"website.box.address"}
          defaultMessage={"Website address"}
        />
      }
    >
      <TrackingWebsite />
    </StandardBox>
  );
};
