import React, { ReactNode, useEffect, useImperativeHandle, useState } from "react";
import useCallAfterUpdate from "services/hooks/useCallAfterUpdate";
import { StandardDrawer, useStandardDrawer } from "services/hooks/useDrawer";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";

export type AssetDrawerProps = {
    assetId: number | string;
    assetSelector: any;
    title: (asset: any) => ReactNode;
    width?: string | number;
} & any;

export type AssetContextType = any;

export type AssetContextHook = {
    asset: any,
    assetId: number | string | null,
};


export const AssetDrawerContext = React.createContext({} as AssetContextType);
AssetDrawerContext.displayName = "Asset Context";
export const useAssetDrawer = (): AssetContextHook => React.useContext(AssetDrawerContext);

const AssetDrawer = React.forwardRef((props: AssetDrawerProps, ref: any) => {

    const [assetId, setAssetId] = useState<number | string | null>(props.assetId);
    const asset = useSelectorWithParams([props.assetSelector, assetId])
    const [drawerRef, open, close, isOpen] = useStandardDrawer();

    const openAfterUpdate = useCallAfterUpdate(() => {
        open();
    })

    const openAsset = (id: number | string) => {
        if (id) {
            setAssetId(id);
            openAfterUpdate();
        }
    }

    useImperativeHandle(ref, () => ({
        asset,
        assetId,
        open: openAsset,
        close
    }))

    if (!assetId) return null;
    if (!asset) return null;

    return (
        <AssetDrawerContext.Provider value={{
            asset,
            assetId,
        }}>
            <StandardDrawer
                ref={drawerRef}
                title={props.title ? props.title(asset) : assetId}
                width={props.width || "1200px"}
            >
                <div key={assetId}>
                    {props.children}
                </div>
            </StandardDrawer>
        </AssetDrawerContext.Provider>
    )

});

export default AssetDrawer;