import { Input } from "antd";
import ColorPicker from "components/inputs/ColorPicker";
import { StandardFullColumn, StandardHalfFullColumn, StandardQuaterColumn, StandardRow } from "components/layout/StandardGrid";
import FormattedMessage from "components/common/FormattedMessage";
import useAPI from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { GetCallMeCustomization, ProjectCallMeCustomizationSelector, UpdateCallMeCustomization } from "state/callMeSlice";
import Gallery from "tools/gallery/Gallery";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";
import RelaxSingleField from "tools/relaxForm/RelaxField";

export default function CallMeFormConfig() {

    const { call } = useAPI(GetCallMeCustomization, true)
    const data = useSelectorWithParams(ProjectCallMeCustomizationSelector);

    return (
        <RelaxMultiform
            callAPI={UpdateCallMeCustomization}
            name={"callMeForm"}
            initialValuesSelector={ProjectCallMeCustomizationSelector}
        >
            <StandardRow>
                <StandardHalfFullColumn>
                    <RelaxSingleField
                        name={["configuration", "logo_url"]}
                        label={<FormattedMessage
                            id="apps.callMe.config.logoUrl"
                            defaultMessage="Logo URL"
                        />}
                        debounce={500}
                        finalizeSubmit={(f: any) => {
                            return {configuration: {logo_url: f.configuration.logo_url?.[0]?.url}}
                        }}
                    >
                        <Gallery
                            allowedTypes={["image"]}
                            allowedTools={["delete"]}
                            singleImageOnly={true}
                        />
                    </RelaxSingleField>
                </StandardHalfFullColumn>
            </StandardRow>
            <StandardRow>
                <StandardQuaterColumn>
                    <RelaxSingleField
                        name={["configuration", "first_color"]}
                        label={<FormattedMessage
                            id="apps.callMe.config.firstColor"
                            defaultMessage="Primary Color"
                        />}
                        debounce={500}
                        showBadgeMode={"none"}
                    >
                        <ColorPicker />
                    </RelaxSingleField>
                </StandardQuaterColumn>
                <StandardQuaterColumn>
                    <RelaxSingleField
                        name={["configuration", "second_color"]}
                        label={<FormattedMessage
                            id="apps.callMe.config.secondColor"
                            defaultMessage="Secondary Color"
                        />}
                        debounce={500}
                        showBadgeMode={"none"}
                    >
                        <ColorPicker />
                    </RelaxSingleField>
                </StandardQuaterColumn>
            </StandardRow>
            <StandardRow>

                <StandardFullColumn>
                    <RelaxSingleField
                        name={["configuration", "custom_header"]}
                        label={<FormattedMessage
                            id="apps.callMe.config.customHeader"
                            defaultMessage="Custom Header"
                        />}
                        debounce={500}
                        showBadgeMode={"none"}
                    >
                        <Input />
                    </RelaxSingleField>
                </StandardFullColumn>
            </StandardRow>
            <StandardRow>
                <StandardFullColumn>
                    <RelaxSingleField
                        name={["configuration", "custom_description"]}
                        label={<FormattedMessage
                            id="apps.callMe.config.customDescription"
                            defaultMessage="Custom Description"
                        />}
                        debounce={500}
                        showBadgeMode={"none"}
                    >
                        <Input />
                    </RelaxSingleField>
                </StandardFullColumn>
            </StandardRow>
            <StandardRow>
                <StandardFullColumn>
                    <RelaxSingleField
                        name={["configuration", "custom_law_info"]}
                        label={<FormattedMessage
                            id="apps.callMe.config.customLawInfo"
                            defaultMessage="Custom Law Info"
                        />}
                        debounce={500}
                        showBadgeMode={"none"}
                    >
                        <Input />
                    </RelaxSingleField>
                </StandardFullColumn>
            </StandardRow>
            <StandardRow>
                <StandardFullColumn>
                    <RelaxSingleField
                        name={["configuration", "custom_law_checkbox_info"]}
                        label={<FormattedMessage
                            id="apps.callMe.config.customLawCheckboxInfo"
                            defaultMessage="Custom Law Checkbox Info"
                        />}
                        debounce={500}
                        showBadgeMode={"none"}
                    >
                        <Input />
                    </RelaxSingleField>
                </StandardFullColumn>
            </StandardRow>
        </RelaxMultiform>
    )
}