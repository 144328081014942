import dayjs, { Dayjs } from "dayjs";
import { CreationType, CreationTypes } from "./CreationConfig";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import DateTimeInput from "components/inputs/DateTimeInput";
import { Select } from "antd";
import CreationTypeSelect from "./CreationTypeSelect";
import useEffectNotFirst from "services/hooks/useEffectNotFirst";

type CreationCreationToolProps = {
    type?: CreationType;
    initialDate?: Dayjs;
    onChange?: (data: any) => void;
}

export default function CreationCreationTool(props: CreationCreationToolProps) {

    const [date, setDate] = useState<Dayjs>(props.initialDate || dayjs().hour(16).minute(0).second(0).millisecond(0));
    const [type, setType] = useState<string>(props.type?.join(".") || "");

    useEffect(() => {

        let d = {};
        if (date) d = { ...d, date: date.utc().format("YYYY-MM-DD HH:mm:ss") };
        if (type) d = { ...d, type };
        props.onChange && props.onChange(d);

    }, [date, type]);

    return (
        <div>
            <FormattedMessage
                id="tools.creations.actions.create.selectType"
                defaultMessage="Which type of a creation do you want to create?"
            />
            <CreationTypeSelect 
                value = {type}
                onChange = {setType}
            />
            <FormattedMessage
                id="tools.creations.actions.create.selectDate"
                defaultMessage="Adjust time of the publication"
            />
            <DateTimeInput
                value={date.format("YYYY-MM-DD HH:mm:ss")}
                onChange={(d) => setDate(dayjs(d))} />
        </div>
    )

}