import MultiSelectCheckbox from "_components/forms/multiselect-checbox";
import { get, GetIndustryData, industryChildrenOfCategorySelector } from "state/industries/industriesSlice";
import { useEffect, useMemo, useState } from "react";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { CategoriesAsObjectsSelector, CategoriesSlice, SelectedCategoriesInCategorySelector, UserHasFilledCategorySelector } from "modules/site-generator/reducers/categoriesSlice";
import RelaxSingleField from "tools/relaxForm/RelaxField";


export type props = {
    rules?: any,
    industry: string,
    category: string,
    min?: number,
    max?: number,
}

export const IndustryCategory = (props: props) => {

    const { category, options } = useSelectorWithParams([industryChildrenOfCategorySelector, props.industry, props.category]) || {};
    const values = useSelectorWithParams([SelectedCategoriesInCategorySelector, props.industry, props.category]);

    const mappedOptions = useMemo(() => options && options.map((o: any) =>
        ({ value: o.id, label: o.name })
    ), [options])

    const mappedValues = useMemo(() => values && values.map && values.map((v: any) => v.id), [values])

    if (!values) {
        return (
            <div>Loading...</div>
        )
    }

    return (
        <RelaxSingleField
            name="categories"
            rules={props.rules}
            initialValues={{ categories: mappedValues }}
            callAPI={{
                url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/websites/object/categories/${category?.id || ""}`),
                method: "PATCH",
                auth: true,
                successDispatch: CategoriesSlice.actions.update,
                passToDispatcher: { categories: values },
            }}
            submitOnChange
        >
            <MultiSelectCheckbox min={props.min} max={props.max} options={mappedOptions} />
        </RelaxSingleField>
    )

}