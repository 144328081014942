import App from "app";
import "env.d";
import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';

import "themes/base/public-index.less";

const container = document.getElementById('root');

const root = createRoot(container!);
root.render(<App/>);

//ReactDOM.render(<App />, container);