import useAPI from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { getSubscriptions } from "state/subscriptions/subscriptionsSlice";
import { subscriptionsArrayWithFilterSelector } from "state/subscriptions/subscriptionsSelectors";
import './subscription-list.less';
import InfinityList from "tools/infinityList/infinityList";
import { subscriptionListHeading } from "./subscriptionListColumns";
import SubscriptionListEntry from "./SubscriptionListEntry";
import SubscriptionListQuery from "./SubscriptionListQuery";
import { useState } from "react";
import { AllSubscriptionsPaymentDueAlert } from "./SubscriptionDebtPayment";


const SubscriptionList = (props: any) => {

  const subscriptionsCall = useAPI(getSubscriptions(), true);
  const [filter, setFilter] = useState<{
    project?:string,
    product?:string,
  }>({
    project: "all",
    product: "all"
  });

  const subscriptions = useSelectorWithParams([subscriptionsArrayWithFilterSelector, filter]);

  const renderItem = (item: any) => <SubscriptionListEntry subscription={item} />


  return (
    <>
      <AllSubscriptionsPaymentDueAlert />
      <div className="infinity-wrapper">
        <SubscriptionListQuery value={filter} onChange={setFilter}/>
        <InfinityList
          headers={subscriptionListHeading}
          items={subscriptions || []}
          loading={subscriptionsCall.loading}
          itemSkeleton={() => "skeleton"}
          renderItem={renderItem}
        />
      </div >
      </>
  );
};

export default SubscriptionList;

