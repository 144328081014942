import React, { ReactNode } from "react";
import modules, { ModulesConfig } from "modules";
import instanceModifiers from "instances";

type Props = {
  render: (modules: ModulesConfig) => ReactNode;
};

export type ModifyModulesFunction = (modules: ModulesConfig) => ModulesConfig;

const Instance = ({ render }: Props) => {
  let instanceModules = modules;

  if (import.meta.env.VITE_INSTANCE_CONFIG !== "") {
    const instanceModulesModifier =
      (instanceModifiers as any)[import.meta.env.VITE_INSTANCE_CONFIG];
    if (instanceModulesModifier) {
      instanceModules = instanceModulesModifier(instanceModules);
    } else
      console.warn(
        `There is no config for '${import.meta.env.VITE_INSTANCE_CONFIG}' instance. See the env file.`
      );
  }

  return <>{render(instanceModules)}</>;
};

export default Instance;
