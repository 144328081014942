import { Space, Table } from "antd"
import StandardActorCard from "components/common/StandardActorCard"
import { useEffect, useMemo, useRef } from "react"
import useSelectorWithParams from "services/hooks/useSelectorWithParams"
import { libraryProjectsSelector } from "state/librarySlice"
import { OrganizationGroupSelector, OrganizationGroupsSelector } from "../data/organizationSlice"
import { useOrganizationTool } from "../OrganizationManager"
import { ListOfProjectsAsTags, SimpleListOfProjects } from "../tools/OrganizationToolHelpers"
import useOrganizationGroupHelper from "../tools/useOrganizationGroupHelper"
import StandardButton from "components/common/StandardButton"
import { faEdit } from "@fortawesome/pro-light-svg-icons"
import StandardAssetDrawer from "providers/assetDrawer/StandardAssetDrawer";
import GroupPage from "../projectPage/GroupPage"


export default function OrganizationTableGroups(props: any) {

    const groupDrawerRef = useRef<any>(null)
    const projects = useSelectorWithParams(libraryProjectsSelector)
    const groups = useSelectorWithParams(OrganizationGroupsSelector)
    const { inputData, setInputData } = useOrganizationTool();
    const { contextHolder, askToRemoveProjectFromGroup, askToRemoveManagerFromGroup } = useOrganizationGroupHelper();

    const handleOpenGroupDrawer = (groupId: string) => {
        groupDrawerRef.current.open(groupId)
    }

    const groupsColumns = [
        {
            title: "Name",
            dataIndex: "name",
        },
        {
            title: "Managers",
            render: (text: any, record: any) => <ListOfProjectsAsTags
                projects={record.managers}
                onClose={(id: number) => askToRemoveManagerFromGroup(id, record.id)}
            />
        },
        {
            title: "Projects",
            render: (text: any, record: any) =>
                <ListOfProjectsAsTags
                    projects={record.projects}
                    onClose={(id: number) => askToRemoveProjectFromGroup(id, record.id)}
                />
        },
        {
            title: "",
            fixed: "right",
            width: "50px",
            render: (text: any, record: any) => <StandardButton icon={faEdit} onClick={() => handleOpenGroupDrawer(record.id)} />,
        }
    ]


    const adjustedData = useMemo(() => {
        return groups;
    }, [groups])

    const adjustedColumns = useMemo(() => {
        return groupsColumns;
    }, [])

    const rowSelection = {
        type: "checkbox",
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            setInputData((i: any) => ({ ...i, groups: selectedRowKeys }))
        },
        getCheckboxProps: (record: any) => ({
            disabled: false,
            name: record.id,
        }),
        selectedRowKeys: inputData?.groups || []
    }


    return (
        <>
            {contextHolder}
            <Table
                rowKey={record => record.id}
                columns={adjustedColumns}
                dataSource={adjustedData}
                rowSelection={rowSelection}
                pagination={false}
            />
            <StandardAssetDrawer
                ref={groupDrawerRef}
                assetSelector={OrganizationGroupSelector}
                title={(asset: any) => <StandardActorCard actor={asset} />}
                width="950px"
            >
                <GroupPage />
            </StandardAssetDrawer>

        </>
    )


}