import FormattedMessage from "components/common/FormattedMessage";
import ProductModal from "./ProductModal";
import { CatalogProductProps } from "state/productsCatalogSlice";
import RelaxFormWrapper from "tools/relaxForm/RelaxForm";
import { Col, Input, InputNumber, Row, Select } from "antd";
import RelaxField from "tools/relaxForm/RelaxField";
import URLInput from "components/inputs/URLInput";
import TextArea from "antd/lib/input/TextArea";
import Checkbox from "components/inputs/Checkbox";
import { ruleMin } from "tools/relaxForm/relaxPresets";
import CurrencySelect from "modules/forsant/components/currecy-select";
import AwesomeIcon from "components/common/AwesomeIcon";
import { faAsterisk } from "@fortawesome/pro-light-svg-icons";

export const productsColumns = (catalogId: string) => [
  {
    title: (
      <FormattedMessage
        id="panel.productCatalogs.table.column.title"
        defaultMessage="Title"
      />
    ),
    render: (record: any) => record.title,
  },
  //   {
  //     title: (
  //       <FormattedMessage
  //         id="panel.productCatalogs.table.column.description"
  //         defaultMessage="Description"
  //       />
  //     ),
  //     render: (record: any) => record.description,
  //   },
  {
    title: (
      <FormattedMessage
        id="panel.productCatalogs.table.column.availability"
        defaultMessage="Availability"
      />
    ),
    render: (record: any) => record.availability,
  },
  //   {
  //     title: (
  //       <FormattedMessage
  //         id="panel.productCatalogs.table.column.condition"
  //         defaultMessage="Condition"
  //       />
  //     ),
  //     render: (record: any) => record.condition,
  //   },
  {
    title: (
      <FormattedMessage
        id="panel.productCatalogs.table.column.price"
        defaultMessage="Price"
      />
    ),
    render: (record: any) => record.price,
  },
  {
    title: (
      <FormattedMessage
        id="panel.productCatalogs.table.column.brand"
        defaultMessage="Brand"
      />
    ),
    render: (record: any) => record.brand,
  },
  //   {
  //     title: (
  //       <FormattedMessage
  //         id="panel.productCatalogs.table.column.color"
  //         defaultMessage="Color"
  //       />
  //     ),
  //     render: (record: any) => record.color,
  //   },
  {
    title: (
      <FormattedMessage
        id="panel.productCatalogs.table.column.material"
        defaultMessage="Material"
      />
    ),
    render: (record: any) => record.material,
  },
  {
    title: (
      <FormattedMessage
        id="panel.productCatalogs.table.column.pattern"
        defaultMessage="Pattern"
      />
    ),
    render: (record: any) => record.pattern,
  },
  //   {
  //     title: (
  //       <FormattedMessage
  //         id="panel.productCatalogs.table.column.product_type"
  //         defaultMessage="Product Type"
  //       />
  //     ),
  //     render: (record: any) => record.product_type,
  //   },
  {
    title: (
      <FormattedMessage
        id="panel.productCatalogs.table.column.Markup"
        defaultMessage="Markup"
      />
    ),
    render: (record: any) => record.markup,
  },
  //   {
  //     title: (
  //       <FormattedMessage
  //         id="panel.productCatalogs.table.column.deliveryCost"
  //         defaultMessage="Delivery Cost"
  //       />
  //     ),
  //     render: (record: any) => record.delivery_cost,
  //   },
  {
    title: (
      <FormattedMessage
        id="panel.productCatalogs.table.column.forcePromotion"
        defaultMessage="Force Promotion"
      />
    ),
    render: (record: any) => (record.force_promotion ? "Yes" : "No"),
  },
  {
    title: (
      <FormattedMessage
        id="panel.productCatalogs.table.column.actions"
        defaultMessage="Actions"
      />
    ),
    render: (record: any) => {
      return (
        <>
          <ProductModal
            action={"edit"}
            label={
              <FormattedMessage
                id="panel.productCatalogs.catalog.edit.product"
                defaultMessage="Edit Product"
              />
            }
            product={record}
            catalogId={catalogId}
          />
          <ProductModal
            action={"delete"}
            label={
              <FormattedMessage
                id="panel.productCatalogs.catalog.delete.product"
                defaultMessage="Delete Product"
              />
            }
            product={record}
            catalogId={catalogId}
          />
        </>
      );
    },
  },
];

const formLayout = {
  standard: {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 8 },
    lg: { span: 6 },
  },
  fullWidth: {
    lg: { span: 24 },
  },
};

export const productColumns = (rd: any, product?: CatalogProductProps) => {
  return (
    <RelaxFormWrapper relaxData={rd} initialValues={product} hideStatus={true}>
      <Row gutter={[16, 16]}>
        <Col {...formLayout.standard}>
          <RelaxField
            name="google_product_category"
            label={
              <FormattedMessage
                id="panel.productCatalogs.product.form.label.google_product_category"
                defaultMessage="Google Product Category"
              />
            }
          >
            <Input size="small" />
          </RelaxField>
        </Col>
        <Col {...formLayout.standard}>
          <RelaxField
            name="title"
            required
            label={
              <>
                <FormattedMessage
                  id="panel.productCatalogs.product.form.label.title"
                  defaultMessage="Title"
                />
                <AwesomeIcon icon={faAsterisk} size="2xs" color="red" />
              </>
            }
            rules={[ruleMin(3)]}
          >
            <Input size="small" />
          </RelaxField>
        </Col>

        <Col {...formLayout.standard}>
          <RelaxField
            name="availability"
            required
            label={
              <>
                <FormattedMessage
                  id="panel.productCatalogs.product.form.label.availability"
                  defaultMessage="Availability"
                />
                <AwesomeIcon icon={faAsterisk} size="2xs" color="red" />
              </>
            }
          >
            <Select
              options={[
                {
                  value: "in stock",
                  label: (
                    <FormattedMessage
                      id="panel.productCatalogs.product.form.select.availability.inStock"
                      defaultMessage="In stock"
                    />
                  ),
                },
                {
                  value: "available for order",
                  label: (
                    <FormattedMessage
                      id="panel.productCatalogs.product.form.select.availability.availableForOrder"
                      defaultMessage="Available for order"
                    />
                  ),
                },
                {
                  value: "out of stock",
                  label: (
                    <FormattedMessage
                      id="panel.productCatalogs.product.form.select.availability.outOfStock"
                      defaultMessage="Out of stock"
                    />
                  ),
                },
                {
                  value: "discontinued",
                  label: (
                    <FormattedMessage
                      id="panel.productCatalogs.product.form.select.availability.discontinued"
                      defaultMessage="Discontinued"
                    />
                  ),
                },
              ]}
            />
          </RelaxField>
        </Col>
        <Col {...formLayout.standard}>
          <RelaxField
            name="condition"
            required
            label={
              <>
                <FormattedMessage
                  id="panel.productCatalogs.product.form.label.condition"
                  defaultMessage="Condition"
                />
                <AwesomeIcon icon={faAsterisk} size="2xs" color="red" />
              </>
            }
          >
            <Select
              options={[
                {
                  value: "new",
                  label: (
                    <FormattedMessage
                      id="panel.productCatalogs.product.form.select.condition.new"
                      defaultMessage="New"
                    />
                  ),
                },
                {
                  value: "used",
                  label: (
                    <FormattedMessage
                      id="panel.productCatalogs.product.form.select.condition.used"
                      defaultMessage="Used"
                    />
                  ),
                },
                {
                  value: "refubrished",
                  label: (
                    <FormattedMessage
                      id="panel.productCatalogs.product.form.select.condition.refubrished"
                      defaultMessage="Refubrished"
                    />
                  ),
                },
              ]}
            />
          </RelaxField>
        </Col>
        <Col {...formLayout.fullWidth}>
          <RelaxField
            name="link"
            required
            label={
              <>
                <FormattedMessage
                  id="panel.productCatalogs.product.form.label.link"
                  defaultMessage="Link"
                />
                <AwesomeIcon icon={faAsterisk} size="2xs" color="red" />
              </>
            }
            rules={[ruleMin(3)]}
          >
            <URLInput size="small" />
          </RelaxField>
        </Col>
        <Col {...formLayout.fullWidth}>
          <RelaxField
            name="image_link"
            required
            label={
              <>
                <FormattedMessage
                  id="panel.productCatalogs.product.form.label.image_link"
                  defaultMessage="Image Link"
                />
                <AwesomeIcon icon={faAsterisk} size="2xs" color="red" />
              </>
            }
            rules={[ruleMin(3)]}
          >
            <URLInput size="small" />
          </RelaxField>
        </Col>
        <Col {...formLayout.fullWidth}>
          <RelaxField
            name="additional_image_link"
            label={
              <FormattedMessage
                id="panel.productCatalogs.product.form.label.additional_image_link"
                defaultMessage="Additional Image Link"
              />
            }
          >
            <URLInput size="small" />
          </RelaxField>
        </Col>
        <Col {...formLayout.standard}>
          <RelaxField
            name="price"
            required
            label={
              <>
                <FormattedMessage
                  id="panel.productCatalogs.product.form.label.price"
                  defaultMessage="Price"
                />
                <AwesomeIcon icon={faAsterisk} size="2xs" color="red" />
              </>
            }
            rules={[ruleMin(1)]}
          >
            <InputNumber style={{ width: "100%" }} />
          </RelaxField>
        </Col>
        <Col {...formLayout.standard}>
          <RelaxField
            name="currency"
            required={true}
            label={
              <>
                <FormattedMessage
                  id="apps.forsant.currency"
                  defaultMessage="Currency"
                />
                <AwesomeIcon icon={faAsterisk} size="2xs" color="red" />
              </>
            }
          >
            <CurrencySelect style={{ width: "100%" }} />
          </RelaxField>
        </Col>
        <Col {...formLayout.standard}>
          <RelaxField
            name="brand"
            required
            label={
              <>
                <FormattedMessage
                  id="panel.productCatalogs.product.form.label.brand"
                  defaultMessage="Brand"
                />
                <AwesomeIcon icon={faAsterisk} size="2xs" color="red" />
              </>
            }
            rules={[ruleMin(3)]}
          >
            <Input size="small" />
          </RelaxField>
        </Col>
        <Col {...formLayout.standard}>
          <RelaxField
            name="retailer_item_id"
            label={
              <>
                <FormattedMessage
                  id="panel.productCatalogs.product.form.label.retailer_item_id"
                  defaultMessage="Retailer Id"
                />
                <AwesomeIcon icon={faAsterisk} size="2xs" color="red" />
              </>
            }
            rules={[ruleMin(3)]}
          >
            <Input style={{ width: "100%" }} />
          </RelaxField>
        </Col>
        <Col {...formLayout.standard}>
          <RelaxField
            name="color"
            label={
              <FormattedMessage
                id="panel.productCatalogs.product.form.label.color"
                defaultMessage="Color"
              />
            }
          >
            <Input size="small" />
          </RelaxField>
        </Col>
        <Col {...formLayout.standard}>
          <RelaxField
            name="material"
            label={
              <FormattedMessage
                id="panel.productCatalogs.product.form.label.material"
                defaultMessage="Material"
              />
            }
          >
            <Input size="small" />
          </RelaxField>
        </Col>
        <Col {...formLayout.standard}>
          <RelaxField
            name="pattern"
            label={
              <FormattedMessage
                id="panel.productCatalogs.product.form.label.pattern"
                defaultMessage="Pattern"
              />
            }
          >
            <Input size="small" />
          </RelaxField>
        </Col>
        <Col {...formLayout.standard}>
          <RelaxField
            name="product_type"
            label={
              <FormattedMessage
                id="panel.productCatalogs.product.form.label.product_type"
                defaultMessage="Product Type"
              />
            }
          >
            <Input size="small" />
          </RelaxField>
        </Col>
        <Col {...formLayout.standard}>
          <RelaxField
            name="custom_label_0"
            label={
              <FormattedMessage
                id="panel.productCatalogs.product.form.label.custom_label_0"
                defaultMessage="Custom Label 0"
              />
            }
          >
            <Input size="small" />
          </RelaxField>
        </Col>
        <Col {...formLayout.standard}>
          <RelaxField
            name="custom_label_1"
            label={
              <FormattedMessage
                id="panel.productCatalogs.product.form.label.custom_label_1"
                defaultMessage="Custom Label 1"
              />
            }
          >
            <Input size="small" />
          </RelaxField>
        </Col>
        <Col {...formLayout.standard}>
          <RelaxField
            name="custom_label_2"
            label={
              <FormattedMessage
                id="panel.productCatalogs.product.form.label.custom_label_2"
                defaultMessage="Custom Label 2"
              />
            }
          >
            <Input size="small" />
          </RelaxField>
        </Col>
        <Col {...formLayout.standard}>
          <RelaxField
            name="custom_label_3"
            label={
              <FormattedMessage
                id="panel.productCatalogs.product.form.label.custom_label_3"
                defaultMessage="Custom Label 3"
              />
            }
          >
            <Input size="small" />
          </RelaxField>
        </Col>
        <Col {...formLayout.standard}>
          <RelaxField
            name="custom_label_4"
            label={
              <FormattedMessage
                id="panel.productCatalogs.product.form.label.custom_label_4"
                defaultMessage="Custom Label 4"
              />
            }
          >
            <Input size="small" />
          </RelaxField>
        </Col>
        <Col {...formLayout.standard}>
          <RelaxField
            name="markup"
            label={
              <FormattedMessage
                id="panel.productCatalogs.product.form.label.markup"
                defaultMessage="Markup"
              />
            }
          >
            <InputNumber style={{ width: "100%" }} />
          </RelaxField>
        </Col>
        <Col {...formLayout.standard}>
          <RelaxField
            name="delivery_cost"
            label={
              <FormattedMessage
                id="panel.productCatalogs.product.form.label.delivery_cost"
                defaultMessage="Delivery Cost"
              />
            }
          >
            <InputNumber style={{ width: "100%" }} />
          </RelaxField>
        </Col>
        <Col {...formLayout.standard}>
          <RelaxField
            name="force_promotion"
            label={
              <FormattedMessage
                id="panel.productCatalogs.product.form.label.force_promotion"
                defaultMessage="Force Promotion"
              />
            }
          >
            <Checkbox />
          </RelaxField>
        </Col>
        <Col span={24}>
          <RelaxField
            name="description"
            required
            label={
              <>
                <FormattedMessage
                  id="panel.productCatalogs.product.form.label.description"
                  defaultMessage="Description"
                />
                <AwesomeIcon icon={faAsterisk} size="2xs" color="red" />
              </>
            }
            rules={[ruleMin(1)]}
          >
            <TextArea rows={4} size="small" />
          </RelaxField>
        </Col>
      </Row>
    </RelaxFormWrapper>
  );
};
