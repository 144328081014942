import WizardButtons from "components/misc/WizardButtons";
import { useCancellationWizard } from "./SubscriptionCancellationWizard";
import FormattedMessage from "components/common/FormattedMessage";
import { useSimpleModal } from "services/hooks/useModal";
import SubscriptionCancellationPlanDisplay from "./SubscriptionCancellationPlanDisplay";
import SubscriptionCancellationSummaryAlert from "./SubscriptionCancellationSummaryAlert";
import { useEffect, useMemo } from "react";
import { CancellationPlansConfig } from "./SubscriptionCancellationConfig";

function SubscriptionCancellationSummary() {

    const { subscriptionId, plan, setPlan, subscription, goToStep} = useCancellationWizard();

    const summaryComponent = useMemo(() => {
        if (!plan) return null;
        return CancellationPlansConfig[plan]?.summaryComponent || null;
    }, [plan]);

    return (
        <>
            {summaryComponent}
            <WizardButtons
                canGoBack={true}
                canGoForward={true}
                onBack={() => goToStep("benefits")}
                onNext={() => goToStep("survey")}
                nextLabel={<FormattedMessage
                    id="panel.subscriptions.cancellation.selectPlan.nextButton"
                    defaultMessage="Cancel subscription"
                />}
                nextPrimary={true}
            />
        </>
    );
}

export default SubscriptionCancellationSummary;