import { Comment } from "antd";
import { Message } from "./Chat";
import StandardAvatar from "components/common/Avatar/StandardAvatar";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { currentProjectSelector } from "state/projects/projectSelectors";

type ChatMessageProps = {
  message: Message;
  isIncoming?: boolean;
};

const ChatMessage = (props: ChatMessageProps) => {
  const currentProject = useSelectorWithParams(currentProjectSelector);

  const author = props.message?.author || "Anonymous";
  const message = props.message?.message || "No message";
  const date = props.message?.date
    ? props.message.date.format("YYYY-MM-DD HH:mm")
    : "No date";
  const avatar =
    props.isIncoming === false && props.message?.author ? (
      <StandardAvatar image={currentProject.image} size={"large"} />
    ) : (
      // <StandardAvatar image={<AwesomeIcon icon={faUser} color="black"/>} size={"large"} />
      <h3 style={{ margin: 0 }}>
        {props.message?.author
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase())
          .join("")}
      </h3>
    );

  return (
    <li
      className={`chat-message ${props.isIncoming ? "incoming" : "outgoing"}`}
    >
      <Comment
        author={author}
        datetime={date}
        avatar={
          <div
            style={{
              backgroundColor: "#efefef",
              borderRadius: "20px",
              width: "40px",
              height: "40px",
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {avatar}
          </div>
        }
        content={<p>{message}</p>}
        actions={[]}
      />
    </li>
  );
};

export default ChatMessage;
