import { Tooltip } from "antd";
import { FormattedMessage as IntlFormattedMessage } from "react-intl";

export type FormattedMessageProps = {
    id: string;
    defaultMessage: string;
    values?: any;
}


export default function FormattedMessage(props: FormattedMessageProps) {

    const debug = (window as any).translationDebug;

    if (debug) {

        return (
            <Tooltip
                title={(<div
                    onClick={() => navigator.clipboard.writeText(props.id)}>
                    APP: {props.id}
                </div>)}
            >
                <>
                    <IntlFormattedMessage {...props} />
                </>
            </Tooltip>
        )
    }

    return (
        <IntlFormattedMessage {...props} />
    )


}

export const TranslatedMessageWithKey = ({ message, messageId }: { message: string, messageId: string }) => {

    const debug = (window as any).translationDebug;

    if (debug) {

        return (
            <Tooltip
                title={(<div
                    onClick={() => navigator.clipboard.writeText(messageId)}>
                    API: {messageId}
                </div>)}
            >
                <>
                    {message}
                </>
            </Tooltip>
        )
    }

    return (
        <>{message}</>
    )

}