import Icon from '@ant-design/icons';
import React from "react";

export const DashboardIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 24.5 24.5"
    >
      <path
        fillRule="evenodd"
        d="M7 12.2l3.9 1.3c.1 0 .1.1.2.2l1.3 3.9 4-9.3L7 12.2zm0 0l3.9 1.3c.1 0 .1.1.2.2l1.3 3.9 4-9.3L7 12.2zm0 0l3.9 1.3c.1 0 .1.1.2.2l1.3 3.9 4-9.3L7 12.2zm0 0l3.9 1.3c.1 0 .1.1.2.2l1.3 3.9 4-9.3L7 12.2zm0 0l3.9 1.3c.1 0 .1.1.2.2l1.3 3.9 4-9.3L7 12.2zm0 0l3.9 1.3c.1 0 .1.1.2.2l1.3 3.9 4-9.3L7 12.2zm0 0l3.9 1.3c.1 0 .1.1.2.2l1.3 3.9 4-9.3L7 12.2zm0 0l3.9 1.3c.1 0 .1.1.2.2l1.3 3.9 4-9.3L7 12.2zm0 0l3.9 1.3c.1 0 .1.1.2.2l1.3 3.9 4-9.3L7 12.2zm0 0l3.9 1.3c.1 0 .1.1.2.2l1.3 3.9 4-9.3L7 12.2zm0 0l3.9 1.3c.1 0 .1.1.2.2l1.3 3.9 4-9.3L7 12.2zM12.2.5C5.8.5.5 5.8.5 12.2S5.8 24 12.2 24 24 18.7 24 12.2 18.7.5 12.2.5zm0 21c-5.1 0-9.2-4.2-9.2-9.2S7.2 3 12.2 3s9.2 4.2 9.2 9.2-4.1 9.3-9.2 9.3zm-1.4-8c.1 0 .1.1.2.2l1.3 3.9 4-9.3-9.3 4 3.8 1.2zM7 12.2l3.9 1.3c.1 0 .1.1.2.2l1.3 3.9 4-9.3L7 12.2zm0 0l3.9 1.3c.1 0 .1.1.2.2l1.3 3.9 4-9.3L7 12.2zm0 0l3.9 1.3c.1 0 .1.1.2.2l1.3 3.9 4-9.3L7 12.2zm0 0l3.9 1.3c.1 0 .1.1.2.2l1.3 3.9 4-9.3L7 12.2zm0 0l3.9 1.3c.1 0 .1.1.2.2l1.3 3.9 4-9.3L7 12.2zm0 0l3.9 1.3c.1 0 .1.1.2.2l1.3 3.9 4-9.3L7 12.2zm0 0l3.9 1.3c.1 0 .1.1.2.2l1.3 3.9 4-9.3L7 12.2zm0 0l3.9 1.3c.1 0 .1.1.2.2l1.3 3.9 4-9.3L7 12.2zm0 0l3.9 1.3c.1 0 .1.1.2.2l1.3 3.9 4-9.3L7 12.2zm0 0l3.9 1.3c.1 0 .1.1.2.2l1.3 3.9 4-9.3L7 12.2zm0 0l3.9 1.3c.1 0 .1.1.2.2l1.3 3.9 4-9.3L7 12.2z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const CreatePostIcon = () => {
  return (
    <svg
      id="Iconly_Light-Outline_Paper_Plus"
      dataname="Iconly/Light-Outline/Paper Plus"
      xmlns="http://www.w3.org/2000/svg"
      width="17.051"
      height="19.867"
      viewBox="0 0 17.051 19.867"
    >
      <g id="Paper_Plus" dataname="Paper Plus" transform="translate(0 0)">
        <path
          id="Combined_Shape"
          dataname="Combined Shape"
          d="M0,15.327V4.491A4.6,4.6,0,0,1,4.569.012h6.273a.758.758,0,0,1,.265,0h.129a.752.752,0,0,1,.541.23l5.066,5.279a.747.747,0,0,1,.208.519v9.3a4.531,4.531,0,0,1-4.47,4.526H4.4A4.476,4.476,0,0,1,0,15.327ZM1.5,4.509V15.34a2.969,2.969,0,0,0,2.913,3.027h8.159a3.027,3.027,0,0,0,2.979-3.025V6.984H13.543a3.332,3.332,0,0,1-3.319-3.325V1.512H4.573A3.09,3.09,0,0,0,1.5,4.509Zm10.224-.85a1.828,1.828,0,0,0,1.821,1.824h1.184l-3-3.131Zm-4.13,9.7v-1.7h-1.7a.75.75,0,0,1,0-1.5h1.7v-1.7a.75.75,0,0,1,1.5,0v1.7h1.7a.75.75,0,0,1,0,1.5h-1.7v1.7a.75.75,0,0,1-1.5,0Z"
          transform="translate(0 0)"
        />
      </g>
    </svg>
  );
};

export const ProfileIcon = () => {
  return (
    <svg
      id="Iconly_Light-Outline_Profile"
      dataname="Iconly/Light-Outline/Profile"
      xmlns="http://www.w3.org/2000/svg"
      width="15.84"
      height="19.87"
      viewBox="0 0 15.84 19.87"
    >
      <g id="Profile" transform="translate(0 0)">
        <path
          id="Combined_Shape"
          dataname="Combined Shape"
          d="M0,16.174c0-3.3,4.521-3.677,7.921-3.677,1.958,0,7.919,0,7.919,3.7,0,3.295-4.52,3.677-7.919,3.677C5.962,19.87,0,19.87,0,16.174Zm1.5,0c0,1.458,2.16,2.2,6.421,2.2s6.419-.733,6.419-2.177S12.18,14,7.921,14,1.5,14.729,1.5,16.174Zm6.389-5.555A5.31,5.31,0,0,1,7.921,0a5.31,5.31,0,0,1,0,10.619ZM4.038,5.31A3.873,3.873,0,0,0,7.892,9.192l.029.715V9.192A3.882,3.882,0,1,0,4.038,5.31Z"
          transform="translate(0 0)"
        />
      </g>
    </svg>
  );
};

export const CheckmarkTrue = () => {
  return (
  <svg 
  id="Component_179_138" 
  dataname="Component 179 – 138" 
  xmlns="http://www.w3.org/2000/svg" 
  width="14" 
  height="14" 
  viewBox="0 0 14 14">
  <circle 
  id="Ellipse_168" 
  dataname="Ellipse 168" 
  cx="7" cy="7" r="7" fill="#0432C4"/>
  <path 
  id="Stroke_3" 
  dataname="Stroke 3" 
  d="M8.44,11.447l1.82,1.82L13.9,9.627" 
  transform="translate(-4.17 -4.446)" 
  fill="none" 
  stroke="#fff" 
  strokeLinecap="round" 
  strokeLinejoin="round" 
  strokeWidth="1.5"/>
</svg>

  );
};

export const CheckmarkCross = () => {
  return (
<svg id="Component_179_136" dataname="Component 179 – 136" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
  <circle id="Ellipse_168" dataname="Ellipse 168" cx="7" cy="7" r="7" fill="#293A404D"/>
  <g id="Shield_Fail" dataname="Shield Fail" transform="translate(90.015 45.054)">
    <g id="Iconly_Light_Shield_Fail" dataname="Iconly/Light/Shield Fail" transform="translate(-95 -50)">
      <g id="Shield_Fail-2" dataname="Shield Fail">
        <path id="Stroke_3" dataname="Stroke 3" d="M13.864,13.825l-3.758-3.758" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
        <path id="Stroke_5" dataname="Stroke 5" d="M10.106,13.825l3.758-3.758" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
      </g>
    </g>
  </g>
</svg>
  );
};

export const SuitcaseIcon = () => {
  return (
  <svg 
  id="Iconly_Light-Suitcase"
  xmlns="http://www.w3.org/2000/svg" 
  width="19.99" 
  height="19.74" 
  viewBox="0 0 19.99 19.74">
    <g id="Work" transform="translate(0)">
      <path 
      id="Combined_Shape" 
      dataname="Combined Shape" 
      d="M4.195,19.74A4.008,4.008,0,0,1,.216,16.049l-.19-2.508a.751.751,0,0,1,.691-.806.761.761,0,0,1,.806.692l.189,2.508a2.5,2.5,0,0,0,2.483,2.3H15.8a2.5,2.5,0,0,0,2.483-2.3l.19-2.508a.765.765,0,0,1,.8-.692.751.751,0,0,1,.691.806l-.19,2.508A4.009,4.009,0,0,1,15.8,19.74Zm5.05-5.063v-1.8a19.045,19.045,0,0,1-8.874-2.4A.75.75,0,0,1,0,9.831V6.381a3.809,3.809,0,0,1,3.81-3.8H5.769A2.965,2.965,0,0,1,8.7,0h2.58a2.965,2.965,0,0,1,2.936,2.58H16.19a3.809,3.809,0,0,1,3.8,3.811v3.44a.752.752,0,0,1-.371.646,19.058,19.058,0,0,1-8.874,2.4v1.8a.75.75,0,1,1-1.5,0ZM1.5,6.381V9.393a18.07,18.07,0,0,0,8.444,2l.05,0,.05,0a18.1,18.1,0,0,0,8.445-2v-3a2.307,2.307,0,0,0-2.3-2.31H3.81A2.307,2.307,0,0,0,1.5,6.381Zm11.195-3.8a1.462,1.462,0,0,0-1.41-1.08H8.7A1.462,1.462,0,0,0,7.3,2.58Z"
      transform="translate(0 0)"
      fill="#293a40"/>
    </g>
  </svg>
  );
};

export const AdvertisementIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <path
        fillRule="evenodd"
        stroke="#fff"
        strokeLinejoin="round"
        d="M21.5 3.5H17a3 3 0 00-3-3H8a2.916 2.916 0 01-3 0H3.5a3 3 0 00-3 3v18a3 3 0 003 3H5a3 3 0 003-3v-6h3a3 3 0 003 3h7.5a3 3 0 003-3v-9a3 3 0 00-3-3zM5 21.5H3.5v-18H5zM21.5 11v4.5H14v-3H8v-9h6v3h7.5z"
      />
    </svg>
  );
};

export const StatisticsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25.005 25.008"
    >
      <path
        fillRule="evenodd"
        stroke="#fff"
        strokeLinejoin="round"
        d="M3.596 6.572a10.38 10.38 0 015.92-2.956V3.5a3 3 0 013-3 11.992 11.992 0 0111.992 11.992 3 3 0 01-3 3h-.118A10.493 10.493 0 113.597 6.574zm15.278-.441a8.965 8.965 0 00-6.36-2.634v8.994h8.994a8.972 8.972 0 00-2.634-6.36zM5.715 19.29a7.495 7.495 0 0012.644-3.8h-5.846a3 3 0 01-3-3V6.644a7.5 7.5 0 00-3.8 12.644z"
      />
    </svg>
  );
};

export const SettingsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25.1 25"
    >
      <path
        fillRule="evenodd"
        d="M10.5 10l-.8 5h5l.8-5h-5zm0 0l-.8 5h5l.8-5h-5zm0 0l-.8 5h5l.8-5h-5zm0 0l-.8 5h5l.8-5h-5zm0 0l-.8 5h5l.8-5h-5zm0 0l-.8 5h5l.8-5h-5zm10.9 3.2c-.2-.1-.4-.3-.3-.6l.1-.5c0-.2.2-.3.3-.4 2.1-.8 3.1-3.1 2.3-5.1-.3-.7-.7-1.3-1.3-1.8-.1-.1-.2-.2-.2-.3-.3-2-2-3.5-3.9-3.5-1.7 0-3.2 1.1-3.7 2.7-.2.2-.4.3-.6.3h-.5c-.2 0-.4-.1-.5-.3-.3-1-1.1-1.8-2-2.3-1-.5-2-.5-3-.2-1.1.4-2 1.3-2.4 2.4-.2.3-.4.4-.6.4C4 4 3 4.4 2.2 5.2 1.5 5.9 1 6.9 1 8c0 1.7 1.1 3.2 2.7 3.8.2.1.4.3.3.6l-.1.5c0 .2-.2.3-.3.4-1 .4-1.8 1.1-2.2 2.1-.4 1-.5 2.1-.1 3.1.3.7.7 1.3 1.3 1.8.1.1.2.2.2.3.1 1.1.7 2 1.5 2.6.8.7 1.9.9 2.9.8 1.5-.2 2.7-1.2 3.2-2.6.1-.2.3-.3.5-.3h.5c.2 0 .4.1.5.3.3 1 1.1 1.8 2 2.3 1 .5 2 .5 3 .2 1.2-.4 2-1.3 2.4-2.4.1-.2.3-.3.5-.3 1.1 0 2.1-.4 2.8-1.2.8-.8 1.2-1.8 1.2-2.8.2-1.9-.8-3.4-2.4-4zM20 19h-2l-.2 1.3c-.1.5-.4 1-.8 1.3-.3.2-.7.3-1.1.3h-.4c-1-.2-1.7-1.2-1.6-2.2l.1-.7H9l-.2 1.3c-.1.5-.4 1-.8 1.3s-1 .4-1.5.3c-1-.2-1.7-1.2-1.6-2.2L5 19c-1.1 0-2-.9-2-2s.9-2 2-2h.6l.8-5H5c-1.1 0-2-.9-2-2s.9-2 2-2h2.1l.2-1.3c.1-.5.4-1 .8-1.3s1-.4 1.5-.3c1 .2 1.7 1.2 1.6 2.2l-.1.7h5l.2-1.3c.2-1 1-1.7 1.9-1.7 1.1 0 2 .9 2 2v.3l-.1.7c1.1 0 1.9.9 1.9 2s-.9 2-2 2h-.6l-.8 5H20c1.1 0 2 .9 2 2s-.8 2-2 2zM9.6 15h5l.8-5h-5l-.8 5zm.9-5l-.8 5h5l.8-5h-5zm0 0l-.8 5h5l.8-5h-5z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const OfferIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <path
        fill="#293a40"
        fillRule="evenodd"
        stroke="#fff"
        strokeLinejoin="round"
        d="M12.5.5a12 12 0 1012 12 12 12 0 00-12-12zm0 21a8.977 8.977 0 01-7.252-3.687c.1-.035.054-.132-.245-.338a8.913 8.913 0 01-.642-1.154A36.021 36.021 0 015 12.5a1.419 1.419 0 00-1.367-1.49 8.9 8.9 0 01.995-2.868A8.118 8.118 0 016.5 8a1.231 1.231 0 001.1-.887 2.824 2.824 0 000-2.158A9 9 0 1112.5 21.5zm6-7.5c-3 3 1.5-4.5 0-6a11.371 11.371 0 00-6-3 3.227 3.227 0 00-3 3c0 1.5 3 1.5 4.5 1.5S17 8 15.5 11s-3 0-6 0S8 17 11 15.5s3 6 6 3 4.5-7.5 1.5-4.5z"
      />
    </svg>
  );
};

export const CompanyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 24.916"
    >
      <path
        fill="#293a40"
        fillRule="evenodd"
        stroke="#fff"
        strokeLinejoin="round"
        d="M23.805.739a1.518 1.518 0 00-1.47-.06l-5.445 2.7L8.598.633h-.01l-.1-.042-.18-.045a1.847 1.847 0 00-.3-.03 1.363 1.363 0 00-.345.045c-.04.011-.072.035-.111.048a1.487 1.487 0 00-.242.1c-.045.024-.1.035-.15.062l-5.835 2.9A1.458 1.458 0 00.5 5v17.92a1.488 1.488 0 00.705 1.269 1.521 1.521 0 00.795.225 1.545 1.545 0 00.675-.165l5.445-2.7 8.4 2.793a1.54 1.54 0 001.155-.09l6-2.986a1.474 1.474 0 00.825-1.329V2.009A1.49 1.49 0 0023.8.74zM3.505 5.92l3-1.5v14.575l-3 1.5zm6-1.836l6 1.986v14.769l-6-1.986zm12 14.919l-3 1.5V5.928l3-1.5z"
      />
    </svg>
  );
};

export const ThemeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <path
        fill="#293a40"
        fillRule="evenodd"
        stroke="#fff"
        strokeLinejoin="round"
        d="M21.5.5h-18a3 3 0 00-3 3v12a3 3 0 003 3H8v3H5a1.5 1.5 0 000 3h15a1.5 1.5 0 000-3h-3v-3h4.5a3 3 0 003-3v-12a3 3 0 00-3-3zm-7.5 21h-3v-3h3zm7.5-6h-18v-12h18z"
      />
    </svg>
  );
};

export const AccountIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 21.992 25.001"
    >
      <path
        fillRule="evenodd"
        stroke="#fff"
        strokeLinejoin="round"
        d="M19.952 15.454a15.067 15.067 0 00-3.789-2.033A7.47 7.47 0 0011.88.557a6.876 6.876 0 00-1.767 0 7.471 7.471 0 00-4.284 12.864 15.056 15.056 0 00-3.787 2.033 3.78 3.78 0 000 6.093 15.053 15.053 0 0017.91 0 3.783 3.783 0 000-6.093zM6.487 8.001a4.485 4.485 0 014.05-4.47 3.643 3.643 0 01.933 0 4.5 4.5 0 11-4.983 4.47zm11.674 11.136a12.038 12.038 0 01-14.328 0 .785.785 0 010-1.274 12.044 12.044 0 0114.328 0 .786.786 0 010 1.274z"
      />
    </svg>
  );
};

export const ProjectsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <path
        fill="#293a40"
        fillRule="evenodd"
        stroke="#fff"
        strokeLinejoin="round"
        d="M21.5.5h-18a3 3 0 00-3 3v18a3 3 0 003 3h18a3 3 0 003-3v-18a3 3 0 00-3-3zm-18 3h3v3h-3zm18 18h-18v-12h18zm0-15h-12v-3h12zM17 14H8a1.5 1.5 0 01-1.5-1.5h0A1.5 1.5 0 018 11h9a1.5 1.5 0 011.5 1.5h0A1.5 1.5 0 0117 14zm-6 4.5H8A1.5 1.5 0 016.5 17h0A1.5 1.5 0 018 15.5h3a1.5 1.5 0 011.5 1.5h0a1.5 1.5 0 01-1.5 1.5z"
      />
    </svg>
  );
};

export const SubscriptionIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 28.429 25"
    >
      <path
        fill="#293a40"
        fillRule="evenodd"
        stroke="#fff"
        d="M24.5 7.357V.5H5.643A5.143 5.143 0 00.5 5.643v15.429A3.439 3.439 0 003.929 24.5H24.5a3.439 3.439 0 003.429-3.429V7.357zM5.643 3.928h15.429v3.429H5.643a1.7145 1.7145 0 110-3.429zM24.5 21.071H3.929V10.786H24.5zm-5.143-3.429a1.714 1.714 0 10-1.714-1.714 1.714 1.714 0 001.714 1.715z"
      />
    </svg>
  );
};

export const SupportIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <path
        fill="#293a40"
        fillRule="evenodd"
        stroke="#fff"
        strokeLinejoin="round"
        d="M12.5.5a12 12 0 1012 12 12 12 0 00-12-12zm5.149 15.029a5.817 5.817 0 000-6.057l2.181-2.181a8.962 8.962 0 010 10.419zM9.5 12.5a3 3 0 113 3 3 3 0 01-3-3zm8.21-7.33l-2.181 2.181a5.817 5.817 0 00-6.057 0L7.291 5.17a8.962 8.962 0 0110.418 0zM5.17 7.291l2.181 2.181a5.817 5.817 0 000 6.057L5.17 17.71a8.963 8.963 0 010-10.419zm2.121 12.54l2.181-2.181a5.817 5.817 0 006.057 0l2.181 2.181a8.962 8.962 0 01-10.419 0z"
      />
    </svg>
  );
};

export const AdwordsIcon = () => {
  return (
    <svg
      className="icon adwords"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="15.999"
      viewBox="0 0 16 15.999"
    >
      <g id="adwords-2">
        <path
          id="Union_2"
          dataname="Union 2"
          d="M11.777,16A2.607,2.607,0,0,1,9.3,14.2l-.747-2.294,3.157-9.7,4.268,13.109a.522.522,0,0,1-.5.683ZM.522,16a.522.522,0,0,1-.5-.683L4.9.36a.522.522,0,0,1,.5-.36h5.217a.522.522,0,0,1,.5.683L6.7,14.2A2.608,2.608,0,0,1,4.223,16Z"
        />
      </g>
    </svg>
  );
};

export const PostsIcon = () => {
  return (
    <svg
      className="icon posts"
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
    >
      <g
        id="Mask_Group_23"
        dataname="Mask Group 23"
        transform="translate(-47 -276)"
        clipPath="url(#clipPath)"
      >
        <g id="n-edit" transform="translate(46.244 275.557)">
          <path
            id="Path_2306"
            dataname="Path 2306"
            d="M9.831,4.537H1.512V22.687H19.662V14.368"
            fill="none"
            strokeLinecap="square"
            strokeMiterlimit="10"
            strokeWidth="1.512"
          />
          <path
            id="Path_2307"
            dataname="Path 2307"
            d="M12.1,15.125,7.562,16.637,9.075,12.1,19.662,1.512l3.025,3.025Z"
            fill="none"
            strokeLinecap="square"
            strokeMiterlimit="10"
            strokeWidth="1.512"
          />
        </g>
      </g>
    </svg>
  );
};

export const AdsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <path
        fill="#293a40"
        fillRule="evenodd"
        d="M21.5 4H17c-.3 0-.5-.2-.5-.5C16.5 2.1 15.4 1 14 1H8.1c-1 .5-2.2.5-3.3 0H3.5C2.1 1 1 2.1 1 3.5v18C1 22.9 2.1 24 3.5 24H5c1.4 0 2.5-1.1 2.5-2.5v-6c0-.3.2-.5.5-.5h3c.3 0 .5.2.5.5 0 1.4 1.1 2.5 2.5 2.5h7.5c1.4 0 2.5-1.1 2.5-2.5v-9C24 5.1 22.9 4 21.5 4zm-16 17.5c0 .3-.2.5-.5.5H3.5c-.3 0-.5-.2-.5-.5v-18c0-.3.2-.5.5-.5H5c.3 0 .5.2.5.5v18zm16.5-6c0 .3-.2.5-.5.5H14c-.3 0-.5-.2-.5-.5V13H8c-.3 0-.5-.2-.5-.5v-9c0-.3.2-.5.5-.5h6c.3 0 .5.2.5.5V6h7c.3 0 .5.2.5.5v9z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const AdvertisementsIcon = () => {
  return (
    <svg
      className="icon ads"
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
    >
      <g
        id="Mask_Group_31"
        dataname="Mask Group 31"
        transform="translate(-47 -340)"
        clipPath="url(#clipPath)"
      >
        <g id="notification" transform="translate(47.047 340)">
          <path
            id="Path_2330"
            dataname="Path 2330"
            d="M9.672,13.718l2.363,5.857a1.909,1.909,0,0,1-1.057,2.481h0A1.91,1.91,0,0,1,8.495,21L4.888,12.128"
            fill="none"
            stroke="#293a40"
            strokeMiterlimit="10"
            strokeWidth="1.432"
          />
          <ellipse
            id="Ellipse_47"
            dataname="Ellipse 47"
            cx="3.938"
            cy="7.875"
            rx="3.938"
            ry="7.875"
            transform="translate(14.319 0.716)"
            fill="none"
            stroke="#293a40"
            strokeLinecap="square"
            strokeMiterlimit="10"
            strokeWidth="1.432"
          />
          <path
            id="Path_2331"
            dataname="Path 2331"
            d="M17.183,6.444a2.148,2.148,0,0,1,0,4.3"
            fill="none"
            stroke="#293a40"
            strokeLinecap="square"
            strokeMiterlimit="10"
            strokeWidth="1.432"
          />
          <path
            id="Path_2332"
            dataname="Path 2332"
            d="M17.478.87,2.864,5.728A2.826,2.826,0,0,0,.716,8.591a2.826,2.826,0,0,0,2.148,2.864l14.614,4.858"
            fill="none"
            stroke="#293a40"
            strokeMiterlimit="10"
            strokeWidth="1.432"
          />
        </g>
      </g>
    </svg>
  );
};

export const PixelIcon = () => {
  return (
    <svg
      id="Component_27_2"
      dataname="Component 27 – 2"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Rectangle_1160"
        dataname="Rectangle 1160"
        width="24"
        height="24"
        fill="none"
      />
      <g id="code" transform="translate(-1143.195 -1024.618)">
        <g id="Path_8713" dataname="Path 8713">
          <path
            id="Path_8717"
            dataname="Path 8717"
            d="M1149.195,1041.368a.748.748,0,0,1-.53-.22l-4-4a.75.75,0,0,1,0-1.061l4-4a.75.75,0,0,1,1.06,1.061l-3.47,3.47,3.47,3.469a.751.751,0,0,1-.53,1.281Z"
          />
        </g>
        <g id="Path_8714" dataname="Path 8714">
          <path
            id="Path_8718"
            dataname="Path 8718"
            d="M1161.195,1041.368a.751.751,0,0,1-.53-1.281l3.469-3.469-3.469-3.47a.75.75,0,0,1,1.06-1.061l4,4a.75.75,0,0,1,0,1.061l-4,4A.748.748,0,0,1,1161.195,1041.368Z"
          />
        </g>
        <g id="Line_71" dataname="Line 71">
          <path
            id="Path_8719"
            dataname="Path 8719"
            d="M1153.195,1045.368a.763.763,0,0,1-.182-.023.75.75,0,0,1-.546-.909l4-16a.75.75,0,1,1,1.455.364l-4,16A.751.751,0,0,1,1153.195,1045.368Z"
          />
        </g>
      </g>
    </svg>
  );
};

export const SubscriptionsIcon = () => {
  return (
    <svg
      className="icon subscriptions"
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
    >
      <g
        id="Mask_Group_28"
        dataname="Mask Group 28"
        transform="translate(-51 -679)"
        clipPath="url(#clipPath)"
      >
        <g id="credit-card" transform="translate(51 679)">
          <line
            id="Line_64"
            dataname="Line 64"
            x2="21.563"
            transform="translate(0.719 7.188)"
            fill="none"
            stroke="#293a40"
            strokeLinecap="square"
            strokeMiterlimit="10"
            strokeWidth="1.438"
          />
          <line
            id="Line_65"
            dataname="Line 65"
            x2="21.563"
            transform="translate(0.719 10.781)"
            fill="none"
            stroke="#293a40"
            strokeLinecap="square"
            strokeMiterlimit="10"
            strokeWidth="1.438"
          />
          <line
            id="Line_66"
            dataname="Line 66"
            x2="5.031"
            transform="translate(3.594 15.094)"
            fill="none"
            stroke="#293a40"
            strokeLinecap="square"
            strokeMiterlimit="10"
            strokeWidth="1.438"
          />
          <line
            id="Line_67"
            dataname="Line 67"
            x2="1.438"
            transform="translate(17.969 15.094)"
            fill="none"
            stroke="#293a40"
            strokeLinecap="square"
            strokeMiterlimit="10"
            strokeWidth="1.438"
          />
          <path
            id="Path_2318"
            dataname="Path 2318"
            d="M.719,17.969V5.031A1.437,1.437,0,0,1,2.156,3.594H20.844a1.437,1.437,0,0,1,1.438,1.438V17.969a1.437,1.437,0,0,1-1.437,1.438H2.156A1.437,1.437,0,0,1,.719,17.969Z"
            fill="none"
            stroke="#293a40"
            strokeLinecap="square"
            strokeMiterlimit="10"
            strokeWidth="1.438"
          />
        </g>
      </g>
    </svg>
  );
};

export const HelpDeskIcon = () => {
  return (
    <svg
      className="icon help-desk"
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
    >
      <g
        id="Mask_Group_29"
        dataname="Mask Group 29"
        transform="translate(-51 -736)"
        clipPath="url(#clipPath)"
      >
        <g id="lifering" transform="translate(51 736)">
          <path
            id="Path_2319"
            dataname="Path 2319"
            d="M9.344,21.331a10.076,10.076,0,0,1-7.675-7.675"
            fill="none"
            stroke="#293a40"
            strokeMiterlimit="10"
            strokeWidth="1.438"
          />
          <path
            id="Path_2320"
            dataname="Path 2320"
            d="M21.331,13.656a10.076,10.076,0,0,1-7.675,7.675"
            fill="none"
            stroke="#293a40"
            strokeMiterlimit="10"
            strokeWidth="1.438"
          />
          <path
            id="Path_2321"
            dataname="Path 2321"
            d="M13.656,1.669a10.076,10.076,0,0,1,7.675,7.675"
            fill="none"
            stroke="#293a40"
            strokeMiterlimit="10"
            strokeWidth="1.438"
          />
          <path
            id="Path_2322"
            dataname="Path 2322"
            d="M1.669,9.344A10.076,10.076,0,0,1,9.344,1.669"
            fill="none"
            stroke="#293a40"
            strokeMiterlimit="10"
            strokeWidth="1.438"
          />
          <path
            id="Path_2323"
            dataname="Path 2323"
            d="M9.344,6.953V.719h4.313V6.953"
            fill="none"
            stroke="#293a40"
            strokeMiterlimit="10"
            strokeWidth="1.438"
          />
          <path
            id="Path_2324"
            dataname="Path 2324"
            d="M16.047,9.344h6.234v4.313H16.047"
            fill="none"
            stroke="#293a40"
            strokeMiterlimit="10"
            strokeWidth="1.438"
          />
          <path
            id="Path_2325"
            dataname="Path 2325"
            d="M13.656,16.047v6.234H9.344V16.047"
            fill="none"
            stroke="#293a40"
            strokeMiterlimit="10"
            strokeWidth="1.438"
          />
          <path
            id="Path_2326"
            dataname="Path 2326"
            d="M6.953,13.656H.719V9.344H6.953"
            fill="none"
            stroke="#293a40"
            strokeMiterlimit="10"
            strokeWidth="1.438"
          />
          <circle
            id="Ellipse_45"
            dataname="Ellipse 45"
            cx="5.031"
            cy="5.031"
            r="5.031"
            transform="translate(6.469 6.469)"
            fill="none"
            stroke="#293a40"
            strokeLinecap="square"
            strokeMiterlimit="10"
            strokeWidth="1.438"
          />
        </g>
      </g>
    </svg>
  );
};

const Facebook = () => (
  <svg
    className="icon facebook"
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="34.787"
    viewBox="0 0 35 34.787"
  >
    <path
      id="Path_6147"
      dataname="Path 6147"
      d="M35,17.5A17.5,17.5,0,1,0,14.766,34.787V22.559H10.322V17.5h4.443V13.645c0-4.386,2.613-6.809,6.61-6.809a26.911,26.911,0,0,1,3.917.342v4.307H23.086a2.529,2.529,0,0,0-2.852,2.733V17.5h4.854l-.776,5.059H20.234V34.787A17.5,17.5,0,0,0,35,17.5Z"
      fill="#1877f2"
    />
  </svg>
);

export const FacebookIcon = (props) => <Icon component={Facebook} {...props} />;

const FacebookAdvertisingAccount = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        y1="0.01"
        x2="0.5"
        y2="1.01"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#263b74" />
        <stop offset="1" stopColor="#4d78bf" />
      </linearGradient>
    </defs>
    <g id="Group_2357" dataname="Group 2357" transform="translate(-165 -529)">
      <path
        id="Path_9065"
        dataname="Path 9065"
        d="M48,24.093a24,24,0,1,0-27.75,23.8V31.058H14.156V24.093H20.25V18.785c0-6.038,3.583-9.374,9.065-9.374a36.768,36.768,0,0,1,5.372.471v5.929H31.661c-2.981,0-3.911,1.857-3.911,3.763v4.52h6.656l-1.064,6.965H27.75V47.894A24.071,24.071,0,0,0,48,24.093Z"
        transform="translate(165 529)"
        fill="#1877f2"
      />
      <path
        id="Path_8755"
        dataname="Path 8755"
        d="M38,48H10A10.022,10.022,0,0,1,0,38V10A10.022,10.022,0,0,1,10,0H38A10.022,10.022,0,0,1,48,10V38A10.022,10.022,0,0,1,38,48Z"
        transform="translate(213 577) rotate(180)"
        fill="url(#linear-gradient)"
      />
      <path
        id="Path_8756"
        dataname="Path 8756"
        d="M36.142,61.818s3.608,8.779,4.243,9.234a4.436,4.436,0,0,0,3.507,0c.839-.506,1.525-.81,1.27-1.568a31.672,31.672,0,0,1-.966-4.655,5.161,5.161,0,0,0,1.5.126c.381-.076.534-.379.254-1.063s-.966-2-.966-2,6.149-.127,10.545,1.67,6,3.39,6.124,4.706,1.143,1.037,1.525,1.037,1.4.2,1.4-.961V44.287c0-.632-.152-.987-.762-.987H62.492a.739.739,0,0,0-.813.81s-.229,3.188-5.057,4.807a43.228,43.228,0,0,1-11.638,2.15c-1.779,0-10.418-.278-10.418-.278L32,53.748v5.161l2.134,2.884Z"
        transform="translate(139.601 494.971)"
        fill="#fff"
      />
      <path
        id="Path_8757"
        dataname="Path 8757"
        d="M44.959,73.178c-1.83,0-10.393-.278-10.393-.278L32,75.86v5.161L34.134,83.9l2.033.051a.11.11,0,0,1,.025.076h8.766Z"
        transform="translate(139.601 472.86)"
        fill="#829ec6"
      />
    </g>
  </svg>
);

export const FacebookAdvertisingAccountIcon = (props) => (
  <Icon component={FacebookAdvertisingAccount} {...props} />
);

const MyBusiness = () => (
  <svg
    className="icon google-my-business"
    dataname="Group 461"
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="29.482"
    viewBox="0 0 34 29.482"
  >
    <path
      id="Path_799"
      dataname="Path 799"
      d="M3.542,372.055H32.955v16.5a2.162,2.162,0,0,1-2.156,2.156H5.7a2.162,2.162,0,0,1-2.156-2.156v-16.5Z"
      transform="translate(-1.19 -361.231)"
      fill="#4a8af4"
      fillRule="evenodd"
    />
    <path
      id="Path_800"
      dataname="Path 800"
      d="M-10.69,313.109l-.522,1.841q-.005.1-.005.209A4.267,4.267,0,0,0-6.928,319.4a4.268,4.268,0,0,0,4.286-4.1q0-.074,0-.148t0-.148c0-.02,0-.041,0-.061h-.014l1.41-10.824H-6.71A2.164,2.164,0,0,0-8.8,305.747l-1.892,7.361Z"
      transform="translate(11.217 -304.125)"
      fill="#80adf7"
      fillRule="evenodd"
    />
    <path
      id="Path_801"
      dataname="Path 801"
      d="M50.964,314.949h0V304.125h-7.09l-1.41,10.824q-.005.1,0,.209a4.251,4.251,0,0,0,8.5.148q0-.074,0-.148t0-.148c0-.02,0-.041,0-.061Z"
      transform="translate(-33.91 -304.125)"
      fill="#4a5bb9"
      fillRule="evenodd"
    />
    <path
      id="Path_802"
      dataname="Path 802"
      d="M9.764,372.444a4.3,4.3,0,0,1-6.222,3.607v.012l14.706,14.65H30.8a2.162,2.162,0,0,0,2.156-2.156V376.007a4.245,4.245,0,0,0,2.235-3.6q0-.074,0-.148t0-.148l0-.061H26.727c0,.02,0,.04,0,.06,0,.049,0,.1,0,.148s0,.1,0,.148c0,.02,0,.04,0,.06a4.244,4.244,0,0,1-8.481-.06,4.244,4.244,0,0,1-8.482.033Z"
      transform="translate(-1.19 -361.231)"
      fill="#4a7de2"
      fillRule="evenodd"
    />
    <path
      id="Path_803"
      dataname="Path 803"
      d="M104.153,314.1l-1.3-9.973H95.8v10.824h0q-.005.1-.005.209a4.245,4.245,0,0,0,8.487.148q0-.074,0-.148t0-.148c0-.02,0-.041,0-.061h-.018l-.111-.852Z"
      transform="translate(-78.745 -304.125)"
      fill="#80adf7"
      fillRule="evenodd"
    />
    <path
      id="Path_804"
      dataname="Path 804"
      d="M141.517,314.949q-.005.1,0,.209a4.235,4.235,0,0,0,8.468.148q0-.074,0-.148t0-.148c0-.02,0-.041,0-.061l-.447-1.841-1.892-7.361a2.164,2.164,0,0,0-2.088-1.622H140.09l1.41,10.824Z"
      transform="translate(-115.981 -304.125)"
      fill="#4a5bb9"
      fillRule="evenodd"
    />
    <path
      id="Union_3"
      dataname="Union 3"
      d="M.619,8.433A5.819,5.819,0,0,1,5.82,0,5.594,5.594,0,0,1,9.713,1.516L8.045,3.184a3.156,3.156,0,0,0-2.225-.87,3.471,3.471,0,0,0-3.258,2.4,3.45,3.45,0,0,0,0,2.211A3.515,3.515,0,0,0,7.789,8.77,2.682,2.682,0,0,0,8.952,7.012H5.82V4.761H11.3a6.439,6.439,0,0,1,.105,1.162v.04a5.681,5.681,0,0,1-1.734,4.268,5.559,5.559,0,0,1-3.851,1.41A5.817,5.817,0,0,1,.619,8.433Z"
      transform="translate(16.348 14.415)"
      fill="#fff"
      stroke="rgba(0,0,0,0)"
      strokeWidth="1"
    />
    <path
      id="Path_809"
      dataname="Path 809"
      d="M-2.645,372.055h-8.567q-.005.1-.005.209a4.267,4.267,0,0,0,4.289,4.244,4.269,4.269,0,0,0,4.286-4.1c0-.049,0-.1,0-.148s0-.1,0-.148c0-.02,0-.04,0-.061Z"
      transform="translate(11.217 -361.231)"
      fill="#78a1e4"
      fillRule="evenodd"
    />
    <path
      id="Path_810"
      dataname="Path 810"
      d="M104.316,372.055H95.842q-.005.1,0,.209a4.242,4.242,0,0,0,8.481.148q0-.074,0-.148t0-.148c0-.02,0-.04,0-.061Z"
      transform="translate(-78.779 -361.231)"
      fill="#78a1e4"
      fillRule="evenodd"
    />
    <path
      id="Path_811"
      dataname="Path 811"
      d="M51.043,372.055H42.582q-.005.1-.005.209a4.235,4.235,0,0,0,8.468.148c0-.049,0-.1,0-.148s0-.1,0-.148c0-.02,0-.04,0-.061Z"
      transform="translate(-34.005 -361.231)"
      fill="#4857ad"
      fillRule="evenodd"
    />
    <path
      id="Path_812"
      dataname="Path 812"
      d="M157.482,372.055h-8.461q0,.1,0,.209a4.235,4.235,0,0,0,8.468.148q0-.074,0-.148t0-.148c0-.02,0-.04,0-.061Z"
      transform="translate(-123.484 -361.231)"
      fill="#4857ad"
      fillRule="evenodd"
    />
  </svg>
);

export const MyBusinessIcon = (props) => (
  <Icon component={MyBusiness} {...props} />
);

const GoogleSearch = () => (
  <svg
    className="icon google-search"
    xmlns="http://www.w3.org/2000/svg"
    width="27.147"
    height="30.082"
    viewBox="0 0 27.147 30.082"
  >
    <g
      id="Group_540"
      dataname="Group 540"
      transform="translate(-92.501 369.001)"
    >
      <g id="Group_534" dataname="Group 534">
        <path
          id="Path_6259"
          dataname="Path 6259"
          d="M113.937-357.233a3.737,3.737,0,0,1,5.137,1.291,3.764,3.764,0,0,1-1.287,5.154c-.034.021-.07.041-.105.061L98.171-339.454a3.738,3.738,0,0,1-5.137-1.292,3.764,3.764,0,0,1,1.287-5.154l.105-.061,19.511-11.272"
          fill="#79be82"
        />
      </g>
      <g id="Group_535" dataname="Group 535">
        <path
          id="Path_6260"
          dataname="Path 6260"
          d="M99.947-365.236a3.734,3.734,0,0,0-3.716-3.75,3.734,3.734,0,0,0-.789.081,3.861,3.861,0,0,0-2.941,3.8v22.286l7.44.135.006-22.552"
          fill="#3d993d"
        />
      </g>
      <g id="Group_536" dataname="Group 536">
        <path
          id="Path_6261"
          dataname="Path 6261"
          d="M114.029-350.749a3.913,3.913,0,0,0,5.1-1.377,3.708,3.708,0,0,0-1.3-5.08l-.035-.02L98.189-368.468a3.694,3.694,0,0,0-5.072,1.251c-.027.044-.052.088-.077.133a3.721,3.721,0,0,0,1.334,5.089l.037.021,19.618,11.225"
          fill="#58ab5e"
        />
        <path
          id="Path_6264"
          dataname="Path 6264"
          d="M114.029-350.749a3.913,3.913,0,0,0,5.1-1.377,3.708,3.708,0,0,0-1.3-5.08l-.035-.02L98.189-368.468a3.694,3.694,0,0,0-5.072,1.251c-.027.044-.052.088-.077.133a3.721,3.721,0,0,0,1.334,5.089l.037.021,19.618,11.225"
          fill="#58ab5e"
        />
      </g>
      <ellipse
        id="Ellipse_78"
        dataname="Ellipse 78"
        cx="3.723"
        cy="3.772"
        rx="3.723"
        ry="3.772"
        transform="translate(92.511 -346.462)"
        fill="#2d7d33"
      />
    </g>
  </svg>
);

export const GoogleSearchIcon = (props) => (
  <Icon component={GoogleSearch} {...props} />
);

const FacebookFanpage = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <g id="Group_2362" dataname="Group 2362" transform="translate(-147 -423)">
      <path
        id="Union_21"
        dataname="Union 21"
        d="M-76-312h28a10,10,0,0,1,10,10v28a10,10,0,0,1-10,10H-76a10,10,0,0,1-10-10v-28A10,10,0,0,1-76-312Z"
        transform="translate(233 735)"
        fill="#1877f2"
      />
      <path
        id="Path_9782"
        dataname="Path 9782"
        d="M16.875,40V25.939H11.8V20.122h5.078V15.689c0-5.043,2.986-7.829,7.554-7.829a30.574,30.574,0,0,1,4.477.393v4.952H26.384c-2.484,0-3.259,1.551-3.259,3.142v3.775h5.547l-.887,5.817h-4.66V40Z"
        transform="translate(152 427)"
        fill="#fff"
      />
    </g>
  </svg>
);

export const FacebookFanpageIcon = (props) => (
  <Icon component={FacebookFanpage} {...props} />
);

const FacebookAdvertising = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 35 35"
  >
    <g id="Group_646" dataname="Group 646" transform="translate(136 18)">
      <rect
        id="Rectangle_519"
        dataname="Rectangle 519"
        width="8.008"
        height="3.559"
        transform="translate(-121.466 -7.915)"
        fill="#3b434f"
      />
      <path
        id="Path_6266"
        dataname="Path 6266"
        d="M-57.248,89V99.4H-76l7.961-6.843,3.184,2.9,1.747-1.482,2.386-2.024Z"
        transform="translate(-51.102 -91.131)"
        fill="#3b434f"
      />
      <path
        id="Path_6267"
        dataname="Path 6267"
        d="M-104.708-18h-27.585A3.707,3.707,0,0,0-136-14.292V13.292A3.707,3.707,0,0,0-132.292,17h27.585A3.707,3.707,0,0,0-101,13.292V-14.292A3.707,3.707,0,0,0-104.708-18Zm-1.186,26.992a1.484,1.484,0,0,1-1.483,1.483H-128.14a1.484,1.484,0,0,1-1.483-1.483V-2.873a1.484,1.484,0,0,1,1.483-1.483h4.3V-8.508a1.484,1.484,0,0,1,1.483-1.483h9.195a1.484,1.484,0,0,1,1.483,1.483v4.153h4.3a1.484,1.484,0,0,1,1.483,1.483Z"
        transform="translate(0)"
        fill="#3b434f"
      />
    </g>
  </svg>
);

export const FacebookAdvertisingIcon = (props) => (
  <Icon component={FacebookAdvertising} {...props} />
);

const FacebookPixel = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <g id="Group_2361" dataname="Group 2361" transform="translate(-150 -612)">
      <rect
        id="Rectangle_1341"
        dataname="Rectangle 1341"
        width="48"
        height="48"
        rx="10"
        transform="translate(150 612)"
        fill="#4161b8"
      />
      <g
        id="Group_2324"
        dataname="Group 2324"
        transform="translate(-11.818 -10.436)"
      >
        <path
          id="Path_9777"
          dataname="Path 9777"
          d="M1.541,0A1.541,1.541,0,0,1,3.083,1.541V8.735A1.541,1.541,0,0,1,0,8.735V1.541A1.541,1.541,0,0,1,1.541,0Z"
          transform="translate(191.371 641.307) rotate(-45)"
          fill="#fff"
        />
        <path
          id="Path_9778"
          dataname="Path 9778"
          d="M1.541,0A1.541,1.541,0,0,1,3.083,1.541V8.735A1.541,1.541,0,1,1,0,8.735V1.541A1.541,1.541,0,0,1,1.541,0Z"
          transform="translate(193.551 653.712) rotate(-135)"
          fill="#fff"
        />
        <path
          id="Path_9779"
          dataname="Path 9779"
          d="M1.541,0A1.541,1.541,0,0,1,3.083,1.541V8.735A1.541,1.541,0,0,1,0,8.735V1.541A1.541,1.541,0,0,1,1.541,0Z"
          transform="translate(180.265 651.532) rotate(135)"
          fill="#fff"
        />
        <path
          id="Path_9780"
          dataname="Path 9780"
          d="M1.541,0A1.541,1.541,0,0,1,3.083,1.541V8.735A1.541,1.541,0,1,1,0,8.735V1.541A1.541,1.541,0,0,1,1.541,0Z"
          transform="translate(178.085 639.127) rotate(45)"
          fill="#fff"
        />
        <path
          id="Path_9781"
          dataname="Path 9781"
          d="M1.541,0A1.541,1.541,0,0,1,3.083,1.541V14.9A1.541,1.541,0,0,1,0,14.9V1.541A1.541,1.541,0,0,1,1.541,0Z"
          transform="translate(188.701 638.545) rotate(30)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

export const FacebookPixelIcon = (props) => (
  <Icon component={FacebookPixel} {...props} />
);

const GoogleMyBusiness = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <g id="Group_2360" dataname="Group 2360" transform="translate(-853 -423)">
      <rect
        id="Rectangle_1339"
        dataname="Rectangle 1339"
        width="48"
        height="48"
        rx="10"
        transform="translate(853 423)"
        fill="#ebf3ff"
      />
      <path
        id="Path_799"
        dataname="Path 799"
        d="M3.542,372.055H24.3V383.7a1.526,1.526,0,0,1-1.522,1.522H5.064A1.526,1.526,0,0,1,3.542,383.7V372.055Z"
        transform="translate(863.118 71.68)"
        fill="#4a8af4"
        fillRule="evenodd"
      />
      <path
        id="Path_800"
        dataname="Path 800"
        d="M-10.845,310.466l-.368,1.3q0,.073,0,.147a3.012,3.012,0,0,0,3.027,3,3.013,3.013,0,0,0,3.025-2.892c0-.035,0-.069,0-.1s0-.07,0-.1c0-.014,0-.029,0-.043h-.01l1-7.641H-8.035A1.528,1.528,0,0,0-9.51,305.27l-1.335,5.2Z"
        transform="translate(876.217 131.969)"
        fill="#80adf7"
        fillRule="evenodd"
      />
      <path
        id="Path_801"
        dataname="Path 801"
        d="M48.464,311.766h0v-7.64h-5l-1,7.641q0,.073,0,.147a3,3,0,0,0,6,.1c0-.035,0-.069,0-.1s0-.07,0-.1c0-.014,0-.029,0-.043Z"
        transform="translate(828.574 131.969)"
        fill="#4a5bb9"
        fillRule="evenodd"
      />
      <path
        id="Path_802"
        dataname="Path 802"
        d="M7.934,372.33a3.035,3.035,0,0,1-4.392,2.546v.009l10.381,10.341h8.859A1.526,1.526,0,0,0,24.3,383.7v-8.859a3,3,0,0,0,1.578-2.538q0-.052,0-.1t0-.1l0-.043H19.908c0,.014,0,.028,0,.042,0,.035,0,.069,0,.1s0,.07,0,.1c0,.014,0,.028,0,.042a3,3,0,0,1-5.987-.042,3,3,0,0,1-5.987.023Z"
        transform="translate(863.118 71.68)"
        fill="#4a7de2"
        fillRule="evenodd"
      />
      <path
        id="Path_803"
        dataname="Path 803"
        d="M101.7,311.164l-.917-7.039H95.8v7.64h0q0,.073,0,.147a3,3,0,0,0,5.991.1c0-.035,0-.069,0-.1s0-.07,0-.1c0-.014,0-.029,0-.043h-.012l-.078-.6Z"
        transform="translate(781.24 131.969)"
        fill="#80adf7"
        fillRule="evenodd"
      />
      <path
        id="Path_804"
        dataname="Path 804"
        d="M141.1,311.766q0,.073,0,.147a2.99,2.99,0,0,0,5.978.1c0-.035,0-.069,0-.1s0-.07,0-.1c0-.014,0-.029,0-.043l-.315-1.3-1.335-5.2a1.528,1.528,0,0,0-1.474-1.145H140.09l1,7.641Z"
        transform="translate(741.928 131.969)"
        fill="#4a5bb9"
        fillRule="evenodd"
      />
      <path
        id="Path_805"
        dataname="Path 805"
        d="M149.925,443.692v-.022a3.677,3.677,0,0,0-.062-.659h-3.214v1.277h1.837a1.517,1.517,0,0,1-.681,1v.828h1.1a3.165,3.165,0,0,0,1.018-2.419Z"
        transform="translate(735.594 6.569)"
        fill="#fff"
        stroke="rgba(0,0,0,0)"
        strokeWidth="1"
      />
      <path
        id="Path_806"
        dataname="Path 806"
        d="M125.861,455.6a3.323,3.323,0,0,0,2.259-.8l-1.1-.828a2.1,2.1,0,0,1-1.156.315,2.027,2.027,0,0,1-1.91-1.361h-1.14v.855a3.425,3.425,0,0,0,3.05,1.818Z"
        transform="translate(756.382 -2.122)"
        fill="#fff"
        stroke="rgba(0,0,0,0)"
        strokeWidth="1"
      />
      <path
        id="Path_807"
        dataname="Path 807"
        d="M121.476,437.989a1.89,1.89,0,0,1,0-1.254v-.855h-1.14a3.207,3.207,0,0,0,0,2.964l1.14-.855Z"
        transform="translate(758.857 12.817)"
        fill="#fff"
        stroke="rgba(0,0,0,0)"
        strokeWidth="1"
      />
      <path
        id="Path_808"
        dataname="Path 808"
        d="M125.861,422.5a1.879,1.879,0,0,1,1.3.493l.979-.947a3.341,3.341,0,0,0-2.283-.86,3.425,3.425,0,0,0-3.05,1.818l1.14.855a2.027,2.027,0,0,1,1.91-1.361Z"
        transform="translate(756.382 25.695)"
        fill="#fff"
        stroke="rgba(0,0,0,0)"
        strokeWidth="1"
      />
      <path
        id="Path_809"
        dataname="Path 809"
        d="M-5.166,372.055h-6.047q0,.073,0,.147a3.012,3.012,0,0,0,3.027,3,3.013,3.013,0,0,0,3.025-2.892c0-.035,0-.069,0-.1s0-.07,0-.1c0-.014,0-.029,0-.043Z"
        transform="translate(876.217 71.68)"
        fill="#78a1e4"
        fillRule="evenodd"
      />
      <path
        id="Path_810"
        dataname="Path 810"
        d="M101.822,372.055H95.841q0,.073,0,.147a2.994,2.994,0,0,0,5.987.1q0-.052,0-.1t0-.1c0-.014,0-.029,0-.043Z"
        transform="translate(781.204 71.68)"
        fill="#78a1e4"
        fillRule="evenodd"
      />
      <path
        id="Path_811"
        dataname="Path 811"
        d="M48.553,372.055H42.581q0,.073,0,.147a2.99,2.99,0,0,0,5.978.1c0-.035,0-.069,0-.1s0-.07,0-.1c0-.014,0-.029,0-.043Z"
        transform="translate(828.474 71.68)"
        fill="#4857ad"
        fillRule="evenodd"
      />
      <path
        id="Path_812"
        dataname="Path 812"
        d="M154.992,372.055h-5.972q0,.073,0,.147a2.99,2.99,0,0,0,5.978.1q0-.052,0-.1t0-.1c0-.014,0-.029,0-.043Z"
        transform="translate(734.006 71.68)"
        fill="#4857ad"
        fillRule="evenodd"
      />
    </g>
  </svg>
);

export const GoogleMyBusinessIcon = (props) => (
  <Icon component={GoogleMyBusiness} {...props} />
);

const GoogleAdvertising = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <g id="Group_2359" dataname="Group 2359" transform="translate(-853 -517)">
      <rect
        id="Rectangle_1338"
        dataname="Rectangle 1338"
        width="48"
        height="48"
        rx="10"
        transform="translate(853 517)"
        fill="#f1ffeb"
      />
      <rect
        id="Rectangle_636"
        dataname="Rectangle 636"
        width="7.167"
        height="21.339"
        rx="3.584"
        transform="translate(875.669 529.995) rotate(30)"
        fill="#fbbd04"
      />
      <rect
        id="Rectangle_637"
        dataname="Rectangle 637"
        width="7.167"
        height="21.339"
        rx="3.584"
        transform="translate(872.124 533.525) rotate(-30)"
        fill="#438edc"
      />
      <circle
        id="Ellipse_114"
        dataname="Ellipse 114"
        cx="3.633"
        cy="3.633"
        r="3.633"
        transform="translate(866.311 543.477)"
        fill="#34a952"
      />
    </g>
  </svg>
);

export const GoogleAdvertisingIcon = (props) => (
  <Icon component={GoogleAdvertising} {...props} />
);

const GoogleAnalytics = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <g id="Group_2358" dataname="Group 2358" transform="translate(-853 -612)">
      <rect
        id="Rectangle_1337"
        dataname="Rectangle 1337"
        width="48"
        height="48"
        rx="10"
        transform="translate(853 612)"
        fill="#fff5eb"
      />
      <circle
        id="Ellipse_113"
        dataname="Ellipse 113"
        cx="2.5"
        cy="2.5"
        r="2.5"
        transform="translate(866.5 643)"
        fill="#e37400"
      />
      <rect
        id="Rectangle_634"
        dataname="Rectangle 634"
        width="5"
        height="15"
        rx="2.5"
        transform="translate(874.5 633)"
        fill="#e37400"
      />
      <rect
        id="Rectangle_635"
        dataname="Rectangle 635"
        width="5"
        height="24"
        rx="2.5"
        transform="translate(882.5 624)"
        fill="#f9ab00"
      />
    </g>
  </svg>
);

export const GoogleAnalyticsIcon = (props) => (
  <Icon component={GoogleAnalytics} {...props} />
);

const GoogleChrome = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 48 48"
  >
    <g className="nc-icon-wrapper">
      <path
        fill="#FFFFFF"
        d="M1,24c0,12.682,10.318,23,23,23s23-10.318,23-23S36.682,1,24,1S1,11.318,1,24z"
      />
      <path
        fill="#E86C60"
        d="M14.077,22.812C14.667,17.857,18.889,14,24,14h20.709C40.983,6.313,33.101,1,24,1 C16.887,1,10.518,4.247,6.296,9.335L14.077,22.812z"
      />
      <circle fill="#43A6DD" cx="24" cy="24" r="8" />
      <path
        fill="#72C472"
        d="M27.93,33.193C26.722,33.711,25.395,34,24,34c-3.694,0-6.919-2.019-8.65-5.006L15.34,29L4.989,11.071 C2.474,14.758,1,19.21,1,24c0,11.37,8.294,20.833,19.148,22.671L27.93,33.193z"
      />
      <path
        fill="#EFD358"
        d="M29.974,16C32.412,17.825,34,20.727,34,24c0,1.82-0.497,3.523-1.35,4.994L32.66,29L22.308,46.931 C22.867,46.972,23.43,47,24,47c12.683,0,23-10.317,23-23c0-2.812-0.509-5.507-1.437-8H29.974z"
      />
    </g>
  </svg>
);

export const GoogleChromeIcon = (props) => (
  <Icon component={GoogleChrome} {...props} />
);

const Logout = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 20 20.16"
  >
    <path
      id="mini_x_2"
      d="M143.851,749.124l6.589-6.644a2.034,2.034,0,0,0,0-2.847,1.989,1.989,0,0,0-2.829,0l-6.591,6.644-6.589-6.644a1.989,1.989,0,0,0-2.829,0,2.034,2.034,0,0,0,0,2.847l6.589,6.644-6.589,6.642a2.034,2.034,0,0,0,0,2.847,1.991,1.991,0,0,0,2.829,0l6.589-6.644,6.591,6.644a1.991,1.991,0,0,0,2.829,0,2.034,2.034,0,0,0,0-2.847Z"
      transform="translate(-131.021 -739.043)"
      fill="#bec3c5"
      fillRule="evenodd"
    />
  </svg>
);

export const LogoutIcon = (props) => <Icon component={Logout} {...props} />;

const Location = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="31"
    viewBox="0 0 29 31"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.295"
        y1="0.11"
        x2="0.898"
        y2="0.898"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#6483fc" />
        <stop offset="1" stopColor="#2e4cc2" />
      </linearGradient>
    </defs>
    <path
      id="Subtraction_1"
      dataname="Subtraction 1"
      d="M14.5,31A13.951,13.951,0,0,1,4.247,26.46,15.953,15.953,0,0,1,0,15.5,15.953,15.953,0,0,1,4.247,4.54a13.848,13.848,0,0,1,20.506,0A15.953,15.953,0,0,1,29,15.5a15.953,15.953,0,0,1-4.247,10.96A13.951,13.951,0,0,1,14.5,31ZM13.955,6c-.149,0-.3,0-.449.013a8.033,8.033,0,0,0-7.273,9.933c1.139,4.95,5.859,7.143,6.794,7.533l.018.007a2.3,2.3,0,0,0,.828.155h.007a1.416,1.416,0,0,0,.222-.022,1.1,1.1,0,0,0-.21-2.183h-.017a10.514,10.514,0,0,1-2.3-1.359,8.419,8.419,0,0,1-3.1-4.451,5.813,5.813,0,0,1,5.289-7.42l.165,0a5.587,5.587,0,0,1,5.587,5.516,1.092,1.092,0,0,0,.334.779,1.139,1.139,0,0,0,.8.323h.014a1.114,1.114,0,0,0,1.114-1.1A7.819,7.819,0,0,0,13.955,6ZM18.39,19.232a1.1,1.1,0,0,0,0,2.2h1.129v1.1a1.128,1.128,0,0,0,2.254,0v-1.1H22.9a1.1,1.1,0,0,0,0-2.2H21.773v-1.1a1.128,1.128,0,0,0-2.254,0v1.1ZM13.9,11.514h-.027a2.2,2.2,0,0,0,.049,4.409h.017a2.2,2.2,0,0,0-.039-4.409Z"
      fill="url(#linear-gradient)"
    />
  </svg>
);

export const LocationIcon = (props) => <Icon component={Location} {...props} />;

const LocationInclude = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="19.602"
    viewBox="0 0 20 19.602"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#3d61ef" />
        <stop offset="1" stopColor="#0432c4" />
      </linearGradient>
    </defs>
    <path
      id="location_1_add"
      d="M168.484,230.244a2.45,2.45,0,1,0,2.505,2.45,2.474,2.474,0,0,0-2.505-2.45Zm10.021,8.575H177.26v-1.226a1.253,1.253,0,0,0-2.505,0v1.226H173.5a1.225,1.225,0,0,0,0,2.45h1.253v1.225a1.253,1.253,0,0,0,2.505,0v-1.225h1.253a1.225,1.225,0,0,0,0-2.45Zm-10.021,2.45s-4.813-1.874-6-6.456a6.459,6.459,0,0,1,5.877-8.245,6.207,6.207,0,0,1,6.391,6.125,1.21,1.21,0,0,0,.371.866,1.267,1.267,0,0,0,.886.359,1.237,1.237,0,0,0,1.253-1.225,8.688,8.688,0,0,0-9.185-8.563,8.925,8.925,0,0,0-8.081,11.037c1.353,5.879,7.28,8.256,7.568,8.378a2.58,2.58,0,0,0,.928.172,1.555,1.555,0,0,0,.247-.025,1.223,1.223,0,0,0-.252-2.425Z"
      transform="translate(-159.735 -224.116)"
      fillRule="evenodd"
      fill="url(#linear-gradient)"
    />
  </svg>
);

export const LocationIncludeIcon = (props) => (
  <Icon component={LocationInclude} {...props} />
);

const LocationExclude = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="19.562"
    viewBox="0 0 20 19.562"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#f85b5b" />
        <stop offset="1" stopColor="#e80000" />
      </linearGradient>
    </defs>
    <path
      id="location_1_minus"
      d="M210.471,238.789h-5a1.224,1.224,0,1,0,0,2.446h5a1.224,1.224,0,1,0,0-2.446Zm-10-8.557a2.445,2.445,0,1,0,2.5,2.445,2.469,2.469,0,0,0-2.5-2.446Zm0,11s-4.8-1.871-5.991-6.443a6.446,6.446,0,0,1,5.865-8.228,6.195,6.195,0,0,1,6.379,6.113,8.4,8.4,0,0,1-.275,2.163,1.211,1.211,0,0,0,1.212,1.5,1.235,1.235,0,0,0,1.214-.928,10.484,10.484,0,0,0,.349-2.739,8.67,8.67,0,0,0-9.166-8.545,8.907,8.907,0,0,0-8.065,11.015c1.351,5.867,7.265,8.239,7.553,8.361a2.574,2.574,0,0,0,.926.172,1.567,1.567,0,0,0,.247-.025h.012a1.221,1.221,0,0,0-.262-2.421Z"
      transform="translate(-191.733 -224.117)"
      fillRule="evenodd"
      fill="url(#linear-gradient)"
    />
  </svg>
);

export const LocationExcludeIcon = (props) => (
  <Icon component={LocationExclude} {...props} />
);

const FacebookCircle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="29.818"
    viewBox="0 0 30 29.818"
  >
    <path
      id="Path_6147"
      dataname="Path 6147"
      d="M30,15A15,15,0,1,0,12.656,29.818V19.336H8.848V15h3.809V11.7c0-3.759,2.239-5.836,5.666-5.836a23.067,23.067,0,0,1,3.358.293V9.844H19.788a2.168,2.168,0,0,0-2.444,2.342V15H21.5l-.665,4.336h-3.5V29.818A15,15,0,0,0,30,15Z"
      fill="#1877f2"
    />
  </svg>
);

export const FacebookCircleIcon = (props) => (
  <Icon component={FacebookCircle} {...props} />
);

const FacebookCircleBlack = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25.5 25.6"
  >
    <path
      fill="#293a40"
      d="M13.9 25.6v-10h2.9l.3-2h-3.2v-3.1c0-1.6 1.1-2.6 2.8-2.6h.8V6.4c-.5-.1-1.2-.1-2-.1-2.4 0-3.8 1.4-3.8 4v3.5H8.6v2h3.1v9.9l-.9-.1C4.6 24.5 0 19.2 0 12.9 0 5.8 5.7 0 12.8 0s12.7 5.8 12.7 12.9c0 6.2-4.6 11.7-10.7 12.6l-.9.1zM12.8 1.5C6.5 1.5 1.5 6.6 1.5 12.9c0 5.3 3.6 9.8 8.7 11v-6.6H7.1v-5h3.1v-2c0-3.4 2-5.5 5.2-5.5 1.3 0 2.7.2 2.8.2l.6.1v4.3h-2.2c-.9 0-1.2.3-1.2 1.1v1.6h3.4l-.7 5h-2.7v6.7c5-1.2 8.6-5.8 8.6-11 0-6.2-5-11.3-11.2-11.3z"
    />
  </svg>
);

export const FacebookCircleBlackIcon = (props) => (
  <Icon component={FacebookCircleBlack} {...props} />
);

const Instagram = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 30 30.001"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        y1="0.008"
        x2="0.5"
        y2="0.998"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#e09b3d" />
        <stop offset="0.3" stopColor="#c74c4d" />
        <stop offset="0.6" stopColor="#c21975" />
        <stop offset="1" stopColor="#7024c4" />
      </linearGradient>
    </defs>
    <g id="instagram" transform="translate(0.001)">
      <path
        id="Union_18"
        dataname="Union 18"
        d="M5292.936,11399a8.948,8.948,0,0,1-8.937-8.937v-12.127a8.947,8.947,0,0,1,8.937-8.937h12.126a8.945,8.945,0,0,1,8.937,8.937v12.127a8.946,8.946,0,0,1-8.937,8.938Zm-5.919-21.064v12.127a5.92,5.92,0,0,0,5.919,5.919h12.126a5.918,5.918,0,0,0,5.919-5.919v-12.127a5.918,5.918,0,0,0-5.919-5.919h-12.126A5.92,5.92,0,0,0,5287.017,11377.937Zm4.222,6.063a7.76,7.76,0,1,1,7.761,7.761A7.77,7.77,0,0,1,5291.239,11384Zm3.018,0a4.742,4.742,0,1,0,4.743-4.74A4.742,4.742,0,0,0,5294.257,11384Zm10.657-7.7a1.859,1.859,0,1,1,1.859,1.859A1.86,1.86,0,0,1,5304.914,11376.3Z"
        transform="translate(-5284 -11369)"
        fill="url(#linear-gradient)"
      />
    </g>
  </svg>
);

const InstagramColor = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={48}
    height={48}
    viewBox="0 0 132 132"
  >
    <script />
    <script />
    <defs>
      <radialGradient
        xlinkHref="#a"
        id="c"
        cx={158.429}
        cy={578.088}
        r={65}
        fx={158.429}
        fy={578.088}
        gradientTransform="matrix(0 -1.98198 1.8439 0 -1031.402 454.004)"
        gradientUnits="userSpaceOnUse"
      />
      <radialGradient
        xlinkHref="#b"
        id="d"
        cx={147.694}
        cy={473.455}
        r={65}
        fx={147.694}
        fy={473.455}
        gradientTransform="matrix(.17394 .86872 -3.5818 .71718 1648.348 -458.493)"
        gradientUnits="userSpaceOnUse"
      />
      <linearGradient id="b">
        <stop offset={0} stopColor="#3771c8" />
        <stop offset={0.128} stopColor="#3771c8" />
        <stop offset={1} stopColor="#60f" stopOpacity={0} />
      </linearGradient>
      <linearGradient id="a">
        <stop offset={0} stopColor="#fd5" />
        <stop offset={0.1} stopColor="#fd5" />
        <stop offset={0.5} stopColor="#ff543e" />
        <stop offset={1} stopColor="#c837ab" />
      </linearGradient>
    </defs>
    <path
      fill="url(#c)"
      d="M65.03 0C37.888 0 29.95.028 28.407.156c-5.57.463-9.036 1.34-12.812 3.22-2.91 1.445-5.205 3.12-7.47 5.468C4 13.126 1.5 18.394.595 24.656c-.44 3.04-.568 3.66-.594 19.188-.01 5.176 0 11.988 0 21.125 0 27.12.03 35.05.16 36.59.45 5.42 1.3 8.83 3.1 12.56 3.44 7.14 10.01 12.5 17.75 14.5 2.68.69 5.64 1.07 9.44 1.25 1.61.07 18.02.12 34.44.12 16.42 0 32.84-.02 34.41-.1 4.4-.207 6.955-.55 9.78-1.28a27.22 27.22 0 0 0 17.75-14.53c1.765-3.64 2.66-7.18 3.065-12.317.088-1.12.125-18.977.125-36.81 0-17.836-.04-35.66-.128-36.78-.41-5.22-1.305-8.73-3.127-12.44-1.495-3.037-3.155-5.305-5.565-7.624C116.9 4 111.64 1.5 105.372.596 102.335.157 101.73.027 86.19 0H65.03z"
      transform="translate(1.004 1)"
    />
    <path
      fill="url(#d)"
      d="M65.03 0C37.888 0 29.95.028 28.407.156c-5.57.463-9.036 1.34-12.812 3.22-2.91 1.445-5.205 3.12-7.47 5.468C4 13.126 1.5 18.394.595 24.656c-.44 3.04-.568 3.66-.594 19.188-.01 5.176 0 11.988 0 21.125 0 27.12.03 35.05.16 36.59.45 5.42 1.3 8.83 3.1 12.56 3.44 7.14 10.01 12.5 17.75 14.5 2.68.69 5.64 1.07 9.44 1.25 1.61.07 18.02.12 34.44.12 16.42 0 32.84-.02 34.41-.1 4.4-.207 6.955-.55 9.78-1.28a27.22 27.22 0 0 0 17.75-14.53c1.765-3.64 2.66-7.18 3.065-12.317.088-1.12.125-18.977.125-36.81 0-17.836-.04-35.66-.128-36.78-.41-5.22-1.305-8.73-3.127-12.44-1.495-3.037-3.155-5.305-5.565-7.624C116.9 4 111.64 1.5 105.372.596 102.335.157 101.73.027 86.19 0H65.03z"
      transform="translate(1.004 1)"
    />
    <path
      fill="#fff"
      d="M66.004 18c-13.036 0-14.672.057-19.792.29-5.11.234-8.598 1.043-11.65 2.23-3.157 1.226-5.835 2.866-8.503 5.535-2.67 2.668-4.31 5.346-5.54 8.502-1.19 3.053-2 6.542-2.23 11.65C18.06 51.327 18 52.964 18 66s.058 14.667.29 19.787c.235 5.11 1.044 8.598 2.23 11.65 1.227 3.157 2.867 5.835 5.536 8.503 2.667 2.67 5.345 4.314 8.5 5.54 3.054 1.187 6.543 1.996 11.652 2.23 5.12.233 6.755.29 19.79.29 13.037 0 14.668-.057 19.788-.29 5.11-.234 8.602-1.043 11.656-2.23 3.156-1.226 5.83-2.87 8.497-5.54 2.67-2.668 4.31-5.346 5.54-8.502 1.18-3.053 1.99-6.542 2.23-11.65.23-5.12.29-6.752.29-19.788 0-13.036-.06-14.672-.29-19.792-.24-5.11-1.05-8.598-2.23-11.65-1.23-3.157-2.87-5.835-5.54-8.503-2.67-2.67-5.34-4.31-8.5-5.535-3.06-1.187-6.55-1.996-11.66-2.23-5.12-.233-6.75-.29-19.79-.29zm-4.306 8.65c1.278-.002 2.704 0 4.306 0 12.816 0 14.335.046 19.396.276 4.68.214 7.22.996 8.912 1.653 2.24.87 3.837 1.91 5.516 3.59 1.68 1.68 2.72 3.28 3.592 5.52.657 1.69 1.44 4.23 1.653 8.91.23 5.06.28 6.58.28 19.39s-.05 14.33-.28 19.39c-.214 4.68-.996 7.22-1.653 8.91-.87 2.24-1.912 3.835-3.592 5.514-1.68 1.68-3.275 2.72-5.516 3.59-1.69.66-4.232 1.44-8.912 1.654-5.06.23-6.58.28-19.396.28-12.817 0-14.336-.05-19.396-.28-4.68-.216-7.22-.998-8.913-1.655-2.24-.87-3.84-1.91-5.52-3.59-1.68-1.68-2.72-3.276-3.592-5.517-.657-1.69-1.44-4.23-1.653-8.91-.23-5.06-.276-6.58-.276-19.398s.046-14.33.276-19.39c.214-4.68.996-7.22 1.653-8.912.87-2.24 1.912-3.84 3.592-5.52 1.68-1.68 3.28-2.72 5.52-3.592 1.692-.66 4.233-1.44 8.913-1.655 4.428-.2 6.144-.26 15.09-.27zm29.928 7.97a5.76 5.76 0 1 0 5.76 5.758c0-3.18-2.58-5.76-5.76-5.76zm-25.622 6.73c-13.613 0-24.65 11.037-24.65 24.65 0 13.613 11.037 24.645 24.65 24.645C79.617 90.645 90.65 79.613 90.65 66S79.616 41.35 66.003 41.35zm0 8.65c8.836 0 16 7.163 16 16 0 8.836-7.164 16-16 16-8.837 0-16-7.164-16-16 0-8.837 7.163-16 16-16z"
    />
  </svg>
  
  )

export const InstagramIcon = (props) => (
  <Icon component={InstagramColor} {...props} />
);

const GoogleDisplay = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="20"
    viewBox="0 0 18 20"
  >
    <g
      id="Group_1604"
      dataname="Group 1604"
      transform="translate(-1392 -122.969)"
    >
      <g
        id="Group_534"
        dataname="Group 534"
        transform="translate(1392 130.437)"
      >
        <path
          id="Path_6259"
          dataname="Path 6259"
          d="M106.714-357.412a2.474,2.474,0,0,1,3.406.858,2.506,2.506,0,0,1-.853,3.427l-.07.041L96.26-345.592a2.475,2.475,0,0,1-3.406-.859,2.507,2.507,0,0,1,.853-3.427l.07-.041,12.937-7.494"
          transform="translate(-92.501 357.768)"
          fill="#79be82"
        />
      </g>
      <g
        id="Group_535"
        dataname="Group 535"
        transform="translate(1392 122.979)"
      >
        <path
          id="Path_6260"
          dataname="Path 6260"
          d="M97.438-366.493a2.479,2.479,0,0,0-2.464-2.493,2.471,2.471,0,0,0-.523.054,2.566,2.566,0,0,0-1.95,2.526v14.817l4.933.09,0-14.994"
          transform="translate(-92.501 368.986)"
          fill="#3d993d"
        />
      </g>
      <g
        id="Group_536"
        dataname="Group 536"
        transform="translate(1392.02 122.969)"
      >
        <path
          id="Path_6261"
          dataname="Path 6261"
          d="M106.785-356.866a2.591,2.591,0,0,0,3.382-.916,2.469,2.469,0,0,0-.86-3.377l-.023-.013-13-7.474a2.446,2.446,0,0,0-3.363.832c-.018.029-.034.059-.051.088a2.477,2.477,0,0,0,.885,3.383l.025.014,13.008,7.463"
          transform="translate(-92.531 369.001)"
          fill="#58ab5e"
        />
        <path
          id="Path_6264"
          dataname="Path 6264"
          d="M106.785-356.866a2.591,2.591,0,0,0,3.382-.916,2.469,2.469,0,0,0-.86-3.377l-.023-.013-13-7.474a2.446,2.446,0,0,0-3.363.832c-.018.029-.034.059-.051.088a2.477,2.477,0,0,0,.885,3.383l.025.014,13.008,7.463"
          transform="translate(-92.531 369.001)"
          fill="#58ab5e"
        />
      </g>
      <ellipse
        id="Ellipse_78"
        dataname="Ellipse 78"
        cx="2.469"
        cy="2.508"
        rx="2.469"
        ry="2.508"
        transform="translate(1392.007 137.954)"
        fill="#2d7d33"
      />
    </g>
  </svg>
);

export const GoogleDisplayIcon = (props) => (
  <Icon component={GoogleDisplay} {...props} />
);

const GoogleDisplayAd = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="20"
    viewBox="0 0 18 20"
  >
    <g
      id="Group_1604"
      dataname="Group 1604"
      transform="translate(-1392 -122.969)"
    >
      <g
        id="Group_534"
        dataname="Group 534"
        transform="translate(1392 130.437)"
      >
        <path
          id="Path_6259"
          dataname="Path 6259"
          d="M106.714-357.412a2.474,2.474,0,0,1,3.406.858,2.506,2.506,0,0,1-.853,3.427l-.07.041L96.26-345.592a2.475,2.475,0,0,1-3.406-.859,2.507,2.507,0,0,1,.853-3.427l.07-.041,12.937-7.494"
          transform="translate(-92.501 357.768)"
          fill="#79be82"
        />
      </g>
      <g
        id="Group_535"
        dataname="Group 535"
        transform="translate(1392 122.979)"
      >
        <path
          id="Path_6260"
          dataname="Path 6260"
          d="M97.438-366.493a2.479,2.479,0,0,0-2.464-2.493,2.471,2.471,0,0,0-.523.054,2.566,2.566,0,0,0-1.95,2.526v14.817l4.933.09,0-14.994"
          transform="translate(-92.501 368.986)"
          fill="#3d993d"
        />
      </g>
      <g
        id="Group_536"
        dataname="Group 536"
        transform="translate(1392.02 122.969)"
      >
        <path
          id="Path_6261"
          dataname="Path 6261"
          d="M106.785-356.866a2.591,2.591,0,0,0,3.382-.916,2.469,2.469,0,0,0-.86-3.377l-.023-.013-13-7.474a2.446,2.446,0,0,0-3.363.832c-.018.029-.034.059-.051.088a2.477,2.477,0,0,0,.885,3.383l.025.014,13.008,7.463"
          transform="translate(-92.531 369.001)"
          fill="#58ab5e"
        />
        <path
          id="Path_6264"
          dataname="Path 6264"
          d="M106.785-356.866a2.591,2.591,0,0,0,3.382-.916,2.469,2.469,0,0,0-.86-3.377l-.023-.013-13-7.474a2.446,2.446,0,0,0-3.363.832c-.018.029-.034.059-.051.088a2.477,2.477,0,0,0,.885,3.383l.025.014,13.008,7.463"
          transform="translate(-92.531 369.001)"
          fill="#58ab5e"
        />
      </g>
      <ellipse
        id="Ellipse_78"
        dataname="Ellipse 78"
        cx="2.469"
        cy="2.508"
        rx="2.469"
        ry="2.508"
        transform="translate(1392.007 137.954)"
        fill="#2d7d33"
      />
    </g>
  </svg>
);

export const GoogleDisplayAdIcon = (props) => (
  <Icon component={GoogleDisplayAd} {...props} />
);

const GoogleSearchAd = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.336"
    height="20"
    viewBox="0 0 20.336 20"
  >
    <g
      id="Group_541"
      dataname="Group 541"
      transform="translate(-75.19 106.467)"
    >
      <path
        id="Path_6268"
        dataname="Path 6268"
        d="M102.87-78.23a3.645,3.645,0,0,1-4.93.01l-4.63-4.49a12.545,12.545,0,0,0,3.17-2.16,12.254,12.254,0,0,0,1.99-2.59l4.43,4.32.03.03A3.456,3.456,0,0,1,102.87-78.23Z"
        transform="translate(-8.384 -9.203)"
        fill="#1a73e8"
      />
      <g
        id="HTML_to_SVG-2"
        dataname="HTML to SVG-2"
        transform="translate(75.19 -106.467)"
      >
        <g id="Group_538" dataname="Group 538">
          <path
            id="Path_6269"
            dataname="Path 6269"
            d="M89.052-104.032a8.166,8.166,0,0,0-11.635,0,8.351,8.351,0,0,0-2.227,5.7,8.35,8.35,0,0,0,2.6,6.08,8.16,8.16,0,0,0,9.194,1.436,8.165,8.165,0,0,0,2.064-1.423,8.062,8.062,0,0,0,1.3-1.706,8.4,8.4,0,0,0,1.113-4.189A8.358,8.358,0,0,0,89.052-104.032Zm-8.419,8.273a3.743,3.743,0,0,1,0-5,3.614,3.614,0,0,1,2.468-.968,3.63,3.63,0,0,1,2.709,1.212,3.741,3.741,0,0,1,.007,4.993A3.642,3.642,0,0,1,80.633-95.759Z"
            transform="translate(-75.19 106.467)"
            fill="#669df6"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const GoogleSearchAdIcon = (props) => (
  <Icon component={GoogleSearchAd} {...props} />
);

const GoogleSearchMagnifier = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.336"
    height="20"
    viewBox="0 0 20.336 20"
  >
    <g
      id="Group_541"
      dataname="Group 541"
      transform="translate(-75.19 106.467)"
    >
      <path
        id="Path_6268"
        dataname="Path 6268"
        d="M102.87-78.23a3.645,3.645,0,0,1-4.93.01l-4.63-4.49a12.545,12.545,0,0,0,3.17-2.16,12.254,12.254,0,0,0,1.99-2.59l4.43,4.32.03.03A3.456,3.456,0,0,1,102.87-78.23Z"
        transform="translate(-8.384 -9.203)"
        fill="#1a73e8"
      />
      <g
        id="HTML_to_SVG-2"
        dataname="HTML to SVG-2"
        transform="translate(75.19 -106.467)"
      >
        <g id="Group_538" dataname="Group 538">
          <path
            id="Path_6269"
            dataname="Path 6269"
            d="M89.052-104.032a8.166,8.166,0,0,0-11.635,0,8.351,8.351,0,0,0-2.227,5.7,8.35,8.35,0,0,0,2.6,6.08,8.16,8.16,0,0,0,9.194,1.436,8.165,8.165,0,0,0,2.064-1.423,8.062,8.062,0,0,0,1.3-1.706,8.4,8.4,0,0,0,1.113-4.189A8.358,8.358,0,0,0,89.052-104.032Zm-8.419,8.273a3.743,3.743,0,0,1,0-5,3.614,3.614,0,0,1,2.468-.968,3.63,3.63,0,0,1,2.709,1.212,3.741,3.741,0,0,1,.007,4.993A3.642,3.642,0,0,1,80.633-95.759Z"
            transform="translate(-75.19 106.467)"
            fill="#669df6"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const GoogleSearchMagnifierIcon = (props) => (
  <Icon component={GoogleSearchMagnifier} {...props} />
);

export const TabPlus = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    width="156.252"
    height="87.699"
    viewBox="0 0 156.252 87.699"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.228"
        y1="0.057"
        x2="0.737"
        y2="0.746"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#88d185" />
        <stop offset="1" stopColor="#07aa00" />
      </linearGradient>
      <filter
        id="Union_11"
        x="0"
        y="0"
        width="156.252"
        height="87.699"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="3" result="blur" />
        <feFlood floodColor="#293a40" floodOpacity="0.02" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Group_798" dataname="Group 798" transform="translate(-116 -114)">
      <g transform="matrix(1, 0, 0, 1, 116, 114)" filter="url(#Union_11)">
        <g
          id="Union_11-2"
          dataname="Union 11"
          transform="translate(-249 6)"
          fill="url(#linear-gradient)"
        >
          <path
            d="M326.553,69.7H258V8a8,8,0,0,1,8-8h61.553V.022C360.182,1.4,372.238,69.7,396.251,69.7Z"
            stroke="none"
          />
          <path
            d="M 388.9612426757812 68.70018768310547 C 388.5625915527344 68.47968292236328 388.1650695800781 68.24101257324219 387.7691955566406 67.98440551757812 C 385.3045654296875 66.38684844970703 382.8504943847656 64.09130096435547 380.2667846679688 60.96659088134766 C 375.5166625976562 55.22180938720703 371.08056640625 47.59011840820312 366.384033203125 39.51035308837891 C 361.0535888671875 30.34002685546875 355.5416564941406 20.85748291015625 349.1959228515625 13.56793689727783 C 345.7991027832031 9.665918350219727 342.4975280761719 6.764627456665039 339.1026000976562 4.698318481445312 C 335.3148803710938 2.392936706542969 331.5230712890625 1.190027475357056 327.5104675292969 1.020845651626587 L 327.0334777832031 1.000736594200134 L 265.9998168945312 1.000736594200134 C 262.1399230957031 1.000736594200134 258.9996948242188 4.140918254852295 258.9996948242188 8.000736236572266 L 258.9996948242188 68.70018768310547 L 326.552734375 68.70018768310547 L 388.9612426757812 68.70018768310547 M 396.2513427734375 69.70018768310547 C 389.3897705078125 69.70018768310547 326.552734375 69.70018768310547 326.552734375 69.70018768310547 L 257.9996948242188 69.70018768310547 L 257.9996948242188 8.000736236572266 C 257.9996948242188 3.582772970199585 261.5816650390625 0.000736594432964921 265.9998168945312 0.000736594432964921 L 327.5526123046875 0.000736594432964921 L 327.5526123046875 0.02173659391701221 C 360.1824951171875 1.397463917732239 372.238037109375 69.70018768310547 396.2513427734375 69.70018768310547 Z"
            stroke="none"
            fill="rgba(112,112,112,0.2)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const TabPlusIcon = (props) => <Icon component={TabPlus} {...props} />;

export const FullFacebook = () => (
  <svg
    id="logo-facebook"
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
  >
    <path
      id="Path_8693"
      dataname="Path 8693"
      d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Z"
      transform="translate(0)"
      fill="#fff"
    />
    <path
      id="Path_8692"
      dataname="Path 8692"
      d="M26.558,23.461l.665-4.336h-4.16V16.311a2.168,2.168,0,0,1,2.444-2.343H27.4V10.277a23.026,23.026,0,0,0-3.357-.293c-3.427,0-5.666,2.077-5.666,5.836v3.3H14.566v4.336h3.809V33.942a15.134,15.134,0,0,0,4.688,0V23.461Z"
      transform="translate(-5.719 -4.125)"
      fill="#1a78f2"
    />
  </svg>
);

export const FullFacebookIcon = (props) => (
  <Icon component={FullFacebook} {...props} />
);

export const FullGoogle = () => (
  <svg
    id="google"
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
  >
    <path
      id="Path_8694"
      dataname="Path 8694"
      d="M17.936,8.135a9.036,9.036,0,0,1,5.778,2.077l4.527-4.246A15.373,15.373,0,0,0,15.155,2.251a15.185,15.185,0,0,0-10.862,8.09l5.1,3.874a9,9,0,0,1,8.539-6.08Z"
      transform="translate(-2.706 -2)"
      fill="#d94f3d"
    />
    <path
      id="Path_8695"
      dataname="Path 8695"
      d="M8.23,20.892a8.7,8.7,0,0,1,.461-2.782l-5.1-3.874a14.748,14.748,0,0,0,0,13.312l5.1-3.874a8.7,8.7,0,0,1-.461-2.782Z"
      transform="translate(-2 -5.895)"
      fill="#f2c042"
    />
    <path
      id="Path_8696"
      dataname="Path 8696"
      d="M38.638,20H24.1v6.135h8.238a7.323,7.323,0,0,1-3.153,4.2l5.065,3.845C37.486,31.32,39.388,26.669,38.638,20Z"
      transform="translate(-8.801 -7.729)"
      fill="#5085ed"
    />
    <path
      id="Path_8697"
      dataname="Path 8697"
      d="M23.088,32.908a9.8,9.8,0,0,1-5.152,1.253A9,9,0,0,1,9.4,28.081l-5.1,3.874A15.271,15.271,0,0,0,17.936,40.3a15.282,15.282,0,0,0,10.217-3.545Z"
      transform="translate(-2.706 -10.302)"
      fill="#57a75c"
    />
  </svg>
);

export const FullGoogleIcon = (props) => (
  <Icon component={FullGoogle} {...props} />
);

const EmailEnvelope = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="26"
    viewBox="0 0 32 26"
  >
    <g id="Group_1642" dataname="Group 1642" transform="translate(-847 -498)">
      <path
        id="Path_707"
        dataname="Path 707"
        d="M2,5,16,16,30,5"
        transform="translate(847 495)"
        fill="none"
        stroke="rgba(41,58,64,0.3)"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <rect
        id="Rectangle_114"
        dataname="Rectangle 114"
        width="30"
        height="24"
        rx="3"
        transform="translate(848 499)"
        fill="none"
        stroke="rgba(41,58,64,0.3)"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export const EmailEnvelopeIcon = (props) => (
  <Icon component={EmailEnvelope} {...props} />
);

const PasswordLock = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="32"
    viewBox="0 0 28 32"
  >
    <g id="Group_1497" dataname="Group 1497" transform="translate(-840 -615)">
      <path
        id="Path_2300"
        dataname="Path 2300"
        d="M22,7a6.018,6.018,0,0,0-6-6h0a6.018,6.018,0,0,0-6,6v5"
        transform="translate(838 615)"
        fill="none"
        stroke="#bec3c5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <rect
        id="Rectangle_319"
        dataname="Rectangle 319"
        width="26"
        height="19"
        rx="3"
        transform="translate(841 627)"
        strokeWidth="2"
        stroke="#bec3c5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        fill="none"
      />
      <line
        id="Line_60"
        dataname="Line 60"
        y2="3"
        transform="translate(854 638)"
        fill="none"
        stroke="#bec3c5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <circle
        id="Ellipse_44"
        dataname="Ellipse 44"
        cx="3"
        cy="3"
        r="3"
        transform="translate(851 632)"
        strokeWidth="2"
        stroke="#bec3c5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        fill="none"
      />
    </g>
  </svg>
);

export const PasswordLockIcon = (props) => (
  <Icon component={PasswordLock} {...props} />
);

const WarningCircle = () => (
  <svg
    className="c-warning"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <circle
      id="Ellipse_41"
      dataname="Ellipse 41"
      cx="15"
      cy="15"
      r="15"
      transform="translate(1 1)"
      strokeWidth="2"
      stroke="#e80000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      fill="none"
    />
    <line
      id="Line_57"
      dataname="Line 57"
      y2="12"
      transform="translate(16 7)"
      fill="none"
      stroke="#e80000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <circle
      id="Ellipse_42"
      dataname="Ellipse 42"
      cx="1.5"
      cy="1.5"
      r="1.5"
      transform="translate(14.5 23)"
      fill="#e80000"
    />
  </svg>
);

export const WarningCircleIcon = (props) => (
  <Icon component={WarningCircle} {...props} />
);

const AcceptSign = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24.265"
    height="21.341"
    viewBox="0 0 24.265 21.341"
  >
    <path
      id="accept_1"
      d="M584.269,724.432a1.37,1.37,0,0,1-.975-.4l-6.877-6.907a1.393,1.393,0,0,1,0-1.95,1.371,1.371,0,0,1,1.95,0l5.755,5.78,11.422-15.3a1.372,1.372,0,0,1,1.925-.279,1.393,1.393,0,0,1,.279,1.935l-12.378,16.575a1.369,1.369,0,0,1-1,.549C584.334,724.431,584.3,724.432,584.269,724.432Z"
      transform="translate(-574.884 -704.092)"
      fill="#fff"
      stroke="#fff"
      strokeWidth="2"
      fillRule="evenodd"
    />
  </svg>
);

export const AcceptSignIcon = (props) => (
  <Icon component={AcceptSign} {...props} />
);

const CancelSign = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31.113"
    height="31.113"
    viewBox="0 0 31.113 31.113"
  >
    <path
      id="mini_plus"
      d="M54.8,747.8H48.2v-6.6a2.2,2.2,0,1,0-4.4,0v6.6H37.2a2.2,2.2,0,0,0,0,4.4h6.6v6.6a2.2,2.2,0,0,0,4.4,0v-6.6h6.6a2.2,2.2,0,0,0,0-4.4Z"
      transform="translate(-547.301 -482.247) rotate(-45)"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);

export const CancelSignIcon = (props) => (
  <Icon component={CancelSign} {...props} />
);

const ArrowDownBlack = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9.999"
    height="5"
    viewBox="0 0 9.999 5"
  >
    <path
      id="Path_8690"
      dataname="Path 8690"
      d="M8.645,3.182,5,6.1,1.354,3.182a.833.833,0,1,0-1.042,1.3L4.479,7.817a.833.833,0,0,0,1.042,0L9.686,4.484a.833.833,0,1,0-1.042-1.3Z"
      transform="translate(0 -3)"
      fill="#000"
    />
  </svg>
);

export const ArrowDownBlackIcon = (props) => (
  <Icon component={ArrowDownBlack} {...props} />
);

const PostsTab = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
  >
    <defs>
      <linearGradient
        id="a"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#3d61ef" />
        <stop offset="1" stopColor="#0432c4" />
      </linearGradient>
    </defs>
    <path
      className="a"
      d="M213,160H195a3,3,0,0,0-3,3v18a3,3,0,0,0,3,3h18a3,3,0,0,0,3-3V163A3,3,0,0,0,213,160Zm-18,3h18v12H195Zm18,18H195v-3h18Zm-7.5-9-4.5-6-4.5,7.5h15l-3-4.5Zm1.5-4.5a1.5,1.5,0,1,0-1.5-1.5A1.5,1.5,0,0,0,207,167.5Z"
      transform="translate(-191.5 -159.5)"
    />
  </svg>
);

export const PostsTabIcon = (props) => <Icon component={PostsTab} {...props} />;

const ListView = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    {/*<defs>*/}
    {/*    <style>.a{fill:#293a40;}</style>*/}
    {/*</defs>*/}
    <path
      className="a"
      d="M12.75,0h-6A.709.709,0,0,0,6,.75v1.5A.709.709,0,0,0,6.75,3h6a.709.709,0,0,0,.75-.75V.75A.709.709,0,0,0,12.75,0Z"
      transform="translate(-1.5)"
    />
    <path
      className="a"
      d="M12.75,6h-6A.709.709,0,0,0,6,6.75v1.5A.709.709,0,0,0,6.75,9h6a.709.709,0,0,0,.75-.75V6.75A.709.709,0,0,0,12.75,6Z"
      transform="translate(-1.5 -1.5)"
    />
    <path
      className="a"
      d="M12.75,12h-6a.709.709,0,0,0-.75.75v1.5a.709.709,0,0,0,.75.75h6a.709.709,0,0,0,.75-.75v-1.5A.709.709,0,0,0,12.75,12Z"
      transform="translate(-1.5 -3)"
    />
    <path
      className="a"
      d="M2.25,0H.75A.709.709,0,0,0,0,.75v1.5A.709.709,0,0,0,.75,3h1.5A.709.709,0,0,0,3,2.25V.75A.709.709,0,0,0,2.25,0Z"
    />
    <path
      className="a"
      d="M2.25,6H.75A.709.709,0,0,0,0,6.75v1.5A.709.709,0,0,0,.75,9h1.5A.709.709,0,0,0,3,8.25V6.75A.709.709,0,0,0,2.25,6Z"
      transform="translate(0 -1.5)"
    />
    <path
      className="a"
      d="M2.25,12H.75a.709.709,0,0,0-.75.75v1.5A.709.709,0,0,0,.75,15h1.5A.709.709,0,0,0,3,14.25v-1.5A.709.709,0,0,0,2.25,12Z"
      transform="translate(0 -3)"
    />
  </svg>
);

export const ListViewIcon = (props) => <Icon component={ListView} {...props} />;

const GridView = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    {/*<defs>*/}
    {/*    <style>.a{fill:#293a40;}</style>*/}
    {/*</defs>*/}
    <path
      className="a"
      d="M4.5,0H.75A.709.709,0,0,0,0,.75V4.5a.709.709,0,0,0,.75.75H4.5a.709.709,0,0,0,.75-.75V.75A.709.709,0,0,0,4.5,0Z"
    />
    <path
      className="a"
      d="M13.5,0H9.75A.709.709,0,0,0,9,.75V4.5a.709.709,0,0,0,.75.75H13.5a.709.709,0,0,0,.75-.75V.75A.709.709,0,0,0,13.5,0Z"
      transform="translate(-2.25)"
    />
    <path
      className="a"
      d="M4.5,9H.75A.709.709,0,0,0,0,9.75V13.5a.709.709,0,0,0,.75.75H4.5a.709.709,0,0,0,.75-.75V9.75A.709.709,0,0,0,4.5,9Z"
      transform="translate(0 -2.25)"
    />
    <path
      className="a"
      d="M13.5,9H9.75A.709.709,0,0,0,9,9.75V13.5a.709.709,0,0,0,.75.75H13.5a.709.709,0,0,0,.75-.75V9.75A.709.709,0,0,0,13.5,9Z"
      transform="translate(-2.25 -2.25)"
    />
  </svg>
);

export const GridViewIcon = (props) => <Icon component={GridView} {...props} />;

const ArrowDownWhite = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9.999"
    height="5"
    viewBox="0 0 9.999 5"
  >
    <path
      id="Path_8690"
      dataname="Path 8690"
      d="M8.645,3.182,5,6.1,1.354,3.182a.833.833,0,1,0-1.042,1.3L4.479,7.817a.833.833,0,0,0,1.042,0L9.686,4.484a.833.833,0,1,0-1.042-1.3Z"
      transform="translate(0 -3)"
      fill="#fff"
    />
  </svg>
);

export const ArrowDownWhiteIcon = (props) => (
  <Icon component={ArrowDownWhite} {...props} />
);

const ArrowDown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9.999"
    height="5"
    viewBox="0 0 9.999 5"
  >
    <path
      id="Path_8690"
      dataname="Path 8690"
      d="M8.645,3.182,5,6.1,1.354,3.182a.833.833,0,1,0-1.042,1.3L4.479,7.817a.833.833,0,0,0,1.042,0L9.686,4.484a.833.833,0,1,0-1.042-1.3Z"
      transform="translate(0 -3)"
      fill="#fff"
    />
  </svg>
);

export const ArrowDownIcon = (props) => (
  <Icon component={ArrowDown} {...props} />
);

const SuccessThumb = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="200"
    height="200"
    viewBox="0 0 200 200"
  >
    <defs>
      <linearGradient
        id="a"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#3d61ef" />
        <stop offset="1" stopColor="#0432c4" />
      </linearGradient>
    </defs>
    <path
      className="a"
      fill="url(#a)"
      d="M591,350.5H541V313a25,25,0,0,0-25-25H503.5a25,25,0,0,0-23.213,15.714L461.538,350.5H441a25,25,0,0,0-25,25V463a25,25,0,0,0,25,25h25a25,25,0,0,1,25,0h50a25,25,0,0,0,17.671-7.321l32.041-32.029,17.962-17.975A25,25,0,0,0,616,413V375.5A25,25,0,0,0,591,350.5ZM441,463V375.5h12.5V463Zm150-50-17.975,17.963L541,463H491l-12.5-12.5v-75l25-62.5H516v62.5h75Z"
      transform="translate(-416 -288)"
    />
  </svg>
);

export const SuccessThumbIcon = (props) => (
  <Icon component={SuccessThumb} {...props} />
);

const FileIcon = () => (
  <svg
    viewBox="64 64 896 896"
    focusable="false"
    className=""
    data-icon="file-image"
    width="1em"
    height="1em"
    fill="currentColor"
    aria-hidden="true"
  >
    <path d="M553.1 509.1l-77.8 99.2-41.1-52.4a8 8 0 0 0-12.6 0l-99.8 127.2a7.98 7.98 0 0 0 6.3 12.9H696c6.7 0 10.4-7.7 6.3-12.9l-136.5-174a8.1 8.1 0 0 0-12.7 0zM360 442a40 40 0 1 0 80 0 40 40 0 1 0-80 0zm494.6-153.4L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0 0 42 42h216v494z"></path>
  </svg>
);

export const FileIconIcon = (props) => <Icon component={FileIcon} {...props} />;

const GalleryItem = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
  >
    <path
      fillRule="evenodd"
      d="M8 1H3.5C2.1 1 1 2.1 1 3.5V8c0 1.4 1.1 2.5 2.5 2.5H8c1.4 0 2.5-1.1 2.5-2.5V3.5C10.5 2.1 9.4 1 8 1zm.5 7c0 .3-.2.5-.5.5H3.5c-.3 0-.5-.2-.5-.5V3.5c0-.3.2-.5.5-.5H8c.3 0 .5.2.5.5V8zm13-7H17c-1.4 0-2.5 1.1-2.5 2.5V8c0 1.4 1.1 2.5 2.5 2.5h4.5c1.4 0 2.5-1.1 2.5-2.5V3.5C24 2.1 22.9 1 21.5 1zm.5 7c0 .3-.2.5-.5.5H17c-.3 0-.5-.2-.5-.5V3.5c0-.3.2-.5.5-.5h4.5c.3 0 .5.2.5.5V8zM8 14.5H3.5C2.1 14.5 1 15.6 1 17v4.5C1 22.9 2.1 24 3.5 24H8c1.4 0 2.5-1.1 2.5-2.5V17c0-1.4-1.1-2.5-2.5-2.5zm.5 7c0 .3-.2.5-.5.5H3.5c-.3 0-.5-.2-.5-.5V17c0-.3.2-.5.5-.5H8c.3 0 .5.2.5.5v4.5zm13-7H17c-1.4 0-2.5 1.1-2.5 2.5v4.5c0 1.4 1.1 2.5 2.5 2.5h4.5c1.4 0 2.5-1.1 2.5-2.5V17c0-1.4-1.1-2.5-2.5-2.5zm.5 7c0 .3-.2.5-.5.5H17c-.3 0-.5-.2-.5-.5V17c0-.3.2-.5.5-.5h4.5c.3 0 .5.2.5.5v4.5z"
      clipRule="evenodd"
    />
  </svg>
);

export const GalleryIcon = (props) => (
  <Icon component={GalleryItem} {...props} />
);

const StatusActive = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.5"
    height="22.5"
    viewBox="0 0 22.5 22.5"
  >
    <g
      id="Component_13_73"
      dataname="Component 13 – 73"
      transform="translate(0.75 0.75)"
    >
      <g id="c-check" transform="translate(-1 -1)">
        <circle
          id="Ellipse_43"
          dataname="Ellipse 43"
          cx="10.5"
          cy="10.5"
          r="10.5"
          transform="translate(1 1)"
          fill="none"
          stroke="#07aa00"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
        <path
          id="Path_8689"
          dataname="Path 8689"
          d="M28.846,24.038l-5.4-3A.3.3,0,0,0,23,21.3v6a.3.3,0,0,0,.446.262l5.4-3a.3.3,0,0,0,0-.524Z"
          transform="translate(-14.5 -13)"
          fill="#07aa00"
        />
      </g>
    </g>
  </svg>
);

export const StatusActiveIcon = (props) => (
  <Icon component={StatusActive} {...props} />
);

const PublishedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.5"
    height="22.5"
    viewBox="0 0 22.5 22.5"
  >
    <g
      id="Component_13_2"
      dataname="Component 13 – 2"
      transform="translate(0.75 0.75)"
    >
      <g id="c-check" transform="translate(-1 -1)">
        <circle
          id="Ellipse_43"
          dataname="Ellipse 43"
          cx="10.5"
          cy="10.5"
          r="10.5"
          transform="translate(1 1)"
          fill="none"
          stroke="#293a40"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-miterlimit="10"
          strokeWidth="1.5"
        />
      </g>
      <path
        id="Path_8690"
        dataname="Path 8690"
        d="M9,14l2,2,5-5"
        transform="translate(-2 -3)"
        fill="none"
        stroke="#293a40"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke-miterlimit="10"
        strokeWidth="1.5"
      />
    </g>
  </svg>
);

export const StatusPublishedIcon = (props) => (
  <Icon component={PublishedIcon} {...props} />
);

const StatusError = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.5"
    height="22.5"
    viewBox="0 0 22.5 22.5"
  >
    <g
      id="Component_13_82"
      dataname="Component 13 – 82"
      transform="translate(0.75 0.75)"
    >
      <g id="c-warning" transform="translate(-1 -1)">
        <circle
          id="Ellipse_41"
          dataname="Ellipse 41"
          cx="10.5"
          cy="10.5"
          r="10.5"
          transform="translate(1 1)"
          fill="none"
          stroke="#e80000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
        <line
          id="Line_57"
          dataname="Line 57"
          y2="8.4"
          transform="translate(11.5 5.2)"
          fill="none"
          stroke="#e80000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
        <circle
          id="Ellipse_42"
          dataname="Ellipse 42"
          cx="1.05"
          cy="1.05"
          r="1.05"
          transform="translate(10.45 16.4)"
          fill="#e80000"
        />
      </g>
    </g>
  </svg>
);
export const StatusErrorIcon = (props) => (
  <Icon component={StatusError} {...props} />
);

const StatusWarning = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.5"
    height="22.5"
    viewBox="0 0 22.5 22.5"
  >
    <g
      id="Component_13_82"
      dataname="Component 13 – 82"
      transform="translate(0.75 0.75)"
    >
      <g id="c-warning" transform="translate(-1 -1)">
        <circle
          id="Ellipse_41"
          dataname="Ellipse 41"
          cx="10.5"
          cy="10.5"
          r="10.5"
          transform="translate(1 1)"
          fill="none"
          stroke="rgba(0, 0, 0, 0.25)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
        <line
          id="Line_57"
          dataname="Line 57"
          y2="8.4"
          transform="translate(11.5 5.2)"
          fill="none"
          stroke="rgba(0, 0, 0, 0.25)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
        <circle
          id="Ellipse_42"
          dataname="Ellipse 42"
          cx="1.05"
          cy="1.05"
          r="1.05"
          transform="translate(10.45 16.4)"
          fill="rgba(0, 0, 0, 0.25)"
        />
      </g>
    </g>
  </svg>
);
export const StatusWarningIcon = (props) => (
  <Icon component={StatusWarning} {...props} />
);

const StatusPending = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.537"
    height="22.5"
    viewBox="0 0 22.537 22.5"
  >
    <g
      id="Component_13_83"
      dataname="Component 13 – 83"
      transform="translate(0.75 0.75)"
    >
      <g id="Group_1712" dataname="Group 1712" transform="translate(-3 -3)">
        <path
          id="Path_8686"
          dataname="Path 8686"
          d="M24,13.5a10.558,10.558,0,1,1-.969-4.362"
          transform="translate(0 0)"
          fill="none"
          stroke="#f4a900"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
        <path
          id="Path_8687"
          dataname="Path 8687"
          d="M24.837,3,24.3,7.1l-4.1-.533"
          transform="translate(-0.89 2)"
          fill="none"
          stroke="#f4a900"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
      </g>
    </g>
  </svg>
);
export const StatusPendingIcon = (props) => (
  <Icon component={StatusPending} {...props} />
);

const StatusPaused = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.5"
    height="22.5"
    viewBox="0 0 22.5 22.5"
  >
    <g
      id="Component_13_76"
      dataname="Component 13 – 76"
      transform="translate(0.75 0.75)"
    >
      <g id="Group_1794" dataname="Group 1794">
        <g id="c-check">
          <circle
            id="Ellipse_43"
            dataname="Ellipse 43"
            cx="10.5"
            cy="10.5"
            r="10.5"
            fill="none"
            stroke="#f4a900"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
        </g>
        <rect
          id="Rectangle_1149"
          dataname="Rectangle 1149"
          width="2"
          height="7"
          rx="1"
          transform="translate(7.308 7)"
          fill="#f4a900"
        />
        <rect
          id="Rectangle_1150"
          dataname="Rectangle 1150"
          width="2"
          height="7"
          rx="1"
          transform="translate(11.692 7)"
          fill="#f4a900"
        />
      </g>
    </g>
  </svg>
);
export const StatusPausedIcon = (props) => (
  <Icon component={StatusPaused} {...props} />
);

const StatusEnded = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.5"
    height="22.5"
    viewBox="0 0 22.5 22.5"
  >
    <g
      id="Component_13_75"
      dataname="Component 13 – 75"
      transform="translate(0.75 0.75)"
    >
      <g id="c-check">
        <circle
          id="Ellipse_43"
          dataname="Ellipse 43"
          cx="10.5"
          cy="10.5"
          r="10.5"
          fill="none"
          stroke="#293a40"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
      </g>
      <rect
        id="Rectangle_1151"
        dataname="Rectangle 1151"
        width="5"
        height="5"
        rx="1"
        transform="translate(8 8)"
        fill="#293a40"
      />
    </g>
  </svg>
);
export const StatusEndedIcon = (props) => (
  <Icon component={StatusEnded} {...props} />
);

const ClosePanel = () => (
  <svg
    id="Component_133_2"
    dataname="Component 133 – 2"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <clipPath id="clipPath">
        <path
          id="Clip_7"
          dataname="Clip 7"
          d="M0,0H4.427V7.331H0Z"
          transform="translate(0.11 0.335)"
          fill="none"
        />
      </clipPath>
    </defs>
    <g
      id="Iconly_Light-Outline_Logout"
      dataname="Iconly/Light-Outline/Logout"
      transform="translate(2 2)"
    >
      <g id="Logout" transform="translate(0)">
        <path
          id="Combined_Shape"
          dataname="Combined Shape"
          d="M4.433,20A4.439,4.439,0,0,1,0,15.565V4.436A4.44,4.44,0,0,1,4.433,0H9.308a4.441,4.441,0,0,1,4.436,4.436v.931a.75.75,0,1,1-1.5,0V4.436A2.938,2.938,0,0,0,9.308,1.5H4.433A2.937,2.937,0,0,0,1.5,4.436V15.565A2.938,2.938,0,0,0,4.433,18.5H9.319a2.928,2.928,0,0,0,2.925-2.924v-.943a.75.75,0,0,1,1.5,0v.943A4.43,4.43,0,0,1,9.319,20Zm11.894-6.555a.75.75,0,0,1,0-1.06l1.641-1.635H7.745a.75.75,0,0,1,0-1.5H17.972L16.33,7.616a.749.749,0,1,1,1.058-1.062l2.913,2.9a.747.747,0,0,1,.235.508v.005s0,.009,0,.013,0,.013,0,.019,0,.013,0,.019,0,.008,0,.013v.006a.745.745,0,0,1-.049.231v0l0,.011,0,.007,0,.007,0,.011v0a.738.738,0,0,1-.111.175.756.756,0,0,1-.057.06l-2.914,2.9a.75.75,0,0,1-1.06,0Z"
          transform="translate(0)"
          fill="#293a40"
        />
        <g id="Group_8" dataname="Group 8" transform="translate(16 6)">
          <path
            id="Clip_7-2"
            dataname="Clip 7"
            d="M0,0H4.427V7.331H0Z"
            transform="translate(0.11 0.335)"
            fill="none"
          />
        </g>
      </g>
    </g>
    <rect
      id="Rectangle_1167"
      dataname="Rectangle 1167"
      width="24"
      height="24"
      fill="none"
    />
  </svg>
);
export const ClosePanelIcon = (props) => (
  <Icon component={ClosePanel} {...props} />
);

const CouponButton = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="15"
    viewBox="0 0 16 15.969"
  >
    <path
      className="cls-1"
      d="M10 4.981a1 1 0 001 1h4a1.006 1.006 0 001-1v-4a1 1 0 00-2 0v1.718a7.982 7.982 0 101.921 6.411 1 1 0 00-1-1.136 1 1 0 00-.984.857 6.016 6.016 0 11-1.48-4.853H11a1 1 0 00-1 1.003z"
      fillRule="evenodd"
    />
  </svg>
);
export const CouponButtonIcon = (props) => (
  <Icon component={CouponButton} {...props} />
);

const More = () => (
  <svg
    viewBox="64 64 896 896"
    focusable="false"
    className=""
    data-icon="ellipsis"
    width="1em"
    height="1em"
    fill="currentColor"
    aria-hidden="true"
  >
    <path d="M176 511a56 56 0 1 0 112 0 56 56 0 1 0-112 0zm280 0a56 56 0 1 0 112 0 56 56 0 1 0-112 0zm280 0a56 56 0 1 0 112 0 56 56 0 1 0-112 0z"></path>
  </svg>
);
export const MoreIcon = (props) => <Icon component={More} {...props} />;

const BigAlert = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150">
    <defs>
      <linearGradient
        id="a"
        x1=".5"
        x2=".5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#f85b5b" />
        <stop offset="1" stopColor="#e80000" />
      </linearGradient>
    </defs>
    <path
      d="M651 210.75A56.25 56.25 0 11594.75 267 56.25 56.25 0 01651 210.75m0-18.75a75 75 0 1075 75 75 75 0 00-75-75zm0 93.75a9.375 9.375 0 019.375 9.375A9.375 9.375 0 01651 304.5a9.375 9.375 0 01-9.375-9.375A9.375 9.375 0 01651 285.75zm0-56.25a9.375 9.375 0 019.375 9.375V267a9.375 9.375 0 01-9.375 9.375 9.375 9.375 0 01-9.375-9.375v-28.125A9.375 9.375 0 01651 229.5z"
      transform="translate(-576 -192)"
      fillRule="evenodd"
      fill="url(#a)"
    />
  </svg>
);
export const BigAlertIcon = (props) => <Icon component={BigAlert} {...props} />;

const BigCheck = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="150"
    height="149.999"
    viewBox="0 0 150 149.999"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#88d185" />
        <stop offset="1" stopColor="#07aa00" />
      </linearGradient>
    </defs>
    <path
      id="Union_15"
      dataname="Union 15"
      d="M17,84a75,75,0,1,1,75,75A75,75,0,0,1,17,84Zm18.75,0A56.25,56.25,0,1,0,92,27.75,56.25,56.25,0,0,0,35.75,84ZM74.6,114.172,54.4,93.965A10.1,10.1,0,1,1,68.682,79.679L81.744,92.743l25.817-25.816,7.453-7.453A10.1,10.1,0,1,1,129.3,73.76l-16.57,16.569L88.887,114.171a10.1,10.1,0,0,1-14.284,0Z"
      transform="translate(-17 -9)"
      fill="url(#linear-gradient)"
    />
  </svg>
);
export const BigCheckIcon = (props) => <Icon component={BigCheck} {...props} />;

const SubscriptionSwitch = () => (
  <svg
    id="Iconly_Light-Outline_Category"
    dataname="Iconly/Light-Outline/Category"
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <g id="Category" transform="translate(3.333 3.333)">
      <path
        id="Combined_Shape"
        dataname="Combined Shape"
        d="M19.34,30.767c-1.007-1.248-1.007-2.951-1.007-5.309l1.249-.042H18.333c0-4.783.3-7.083,7.083-7.083s7.083,2.3,7.083,7.083c0,2.382,0,4.1-1.007,5.351-1.157,1.438-3.265,1.733-6.076,1.733S20.5,32.205,19.34,30.767Zm1.494-5.351v.042c0,1.845,0,3.179.451,3.741s1.754.8,4.131.8,3.69-.255,4.133-.8S30,27.3,30,25.457c0-4.273,0-4.623-4.584-4.623S20.833,21.18,20.833,25.416ZM1.007,30.767C0,29.518,0,27.816,0,25.458l1.25-.042H0c0-4.783.3-7.083,7.083-7.083s7.083,2.3,7.083,7.083c0,2.382,0,4.1-1.007,5.351C12,32.205,9.892,32.5,7.083,32.5S2.164,32.205,1.007,30.767ZM2.5,25.416v.042c0,1.845,0,3.179.452,3.741s1.754.8,4.131.8,3.69-.255,4.132-.8.451-1.895.451-3.741c0-4.273,0-4.623-4.584-4.623S2.5,21.18,2.5,25.416ZM19.34,12.434c-1.007-1.248-1.007-2.951-1.007-5.309l1.249-.042H18.333c0-4.783.3-7.083,7.083-7.083S32.5,2.3,32.5,7.083c0,2.382,0,4.1-1.007,5.351-1.157,1.438-3.265,1.732-6.076,1.732S20.5,13.872,19.34,12.434Zm1.494-5.351v.042c0,1.845,0,3.179.451,3.741.441.546,1.754.8,4.131.8s3.69-.255,4.133-.8S30,8.97,30,7.124C30,2.85,30,2.5,25.416,2.5S20.833,2.847,20.833,7.083ZM1.007,12.434C0,11.186,0,9.483,0,7.125l1.25-.042H0C0,2.3.3,0,7.083,0s7.083,2.3,7.083,7.083c0,2.382,0,4.1-1.007,5.351C12,13.872,9.892,14.166,7.083,14.166S2.164,13.872,1.007,12.434ZM2.5,7.083v.042c0,1.845,0,3.179.452,3.741.439.546,1.754.8,4.131.8s3.69-.255,4.132-.8.451-1.895.451-3.741c0-4.274,0-4.623-4.584-4.623S2.5,2.847,2.5,7.083Z"
        fill="#4e5d64"
        stroke="#4e5d64"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export const SubscriptionSwitchIcon = (props) => (
  <Icon component={SubscriptionSwitch} {...props} />
);

const PostsAndAds = () => (
  <svg
    id="Iconly_Light-Outline_Activity"
    dataname="Iconly/Light-Outline/Activity"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g id="Activity" transform="translate(2 2)">
      <path
        id="Combined_Shape"
        dataname="Combined Shape"
        d="M5.629,20.7C2.262,20.7,0,18.338,0,14.819V6.737C0,3.211,2.262.842,5.629.842H12.9a.75.75,0,0,1,0,1.5H5.629C3.121,2.342,1.5,4.066,1.5,6.737v8.082c0,2.7,1.581,4.385,4.128,4.385h8.6c2.508,0,4.129-1.721,4.129-4.385V7.779a.75.75,0,0,1,1.5,0v7.04c0,3.52-2.262,5.884-5.63,5.884Zm-.869-6.854A.751.751,0,0,1,4.622,12.8L7.616,8.906a.746.746,0,0,1,.5-.286.735.735,0,0,1,.557.155l2.82,2.215,2.468-3.183a.75.75,0,1,1,1.185.918L12.217,12.5a.751.751,0,0,1-1.057.131L8.342,10.421,5.811,13.71a.751.751,0,0,1-1.052.139ZM15.3,2.672a2.672,2.672,0,1,1,2.672,2.673A2.675,2.675,0,0,1,15.3,2.672Zm1.5,0A1.172,1.172,0,1,0,17.968,1.5,1.174,1.174,0,0,0,16.8,2.672Z"
        transform="translate(0 0)"
      />
    </g>
  </svg>
);

export const PostsAndAdsIcon = (props) => (
  <Icon component={PostsAndAds} {...props} />
);

const Refresh = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="18"
    viewBox="0 0 16 18"
  >
    <path
      id="ic24-refresh"
      d="M15.707,5.293l-2-2a1,1,0,0,0-1.5,1.32l.083.094.318.316Q12.307,5,12,5a8,8,0,1,0,8,8,1,1,0,0,0-2,0,6,6,0,1,1-6-6q.281,0,.557.025l-.264.267a1,1,0,0,0,1.32,1.5l.094-.083,2-2a1,1,0,0,0,.083-1.32l-.083-.094-2-2Z"
      transform="translate(-4 -3)"
      fillRule="evenodd"
    />
  </svg>
);

export const RefreshIcon = (props) => <Icon component={Refresh} {...props} />;

const Play = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="13.2"
    viewBox="0 0 12 13.2"
  >
    <path
      id="Path_8920"
      dataname="Path 8920"
      d="M34.692,27.076l-10.8-6A.6.6,0,0,0,23,21.6v12a.6.6,0,0,0,.891.524l10.8-6a.6.6,0,0,0,0-1.049Z"
      transform="translate(-23 -21)"
    />
  </svg>
);

export const PlayIcon = (props) => <Icon component={Play} {...props} />;

const Update = () => (
  <svg
    id="Iconly_Light-Outline_Edit"
    dataname="Iconly/Light-Outline/Edit"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g id="Edit" transform="translate(2 3)">
      <path
        id="Combined_Shape"
        dataname="Combined Shape"
        d="M11.5,18.94a.75.75,0,0,1,0-1.5h7.253a.75.75,0,0,1,0,1.5Zm-9.884,0a.749.749,0,0,1-.731-.577L.064,14.891a2.373,2.373,0,0,1,.461-2.037L9.944,1.073l.007-.009,0,0A3.006,3.006,0,0,1,14.116.653l1.7,1.326.018.014A2.8,2.8,0,0,1,17.1,3.767a2.87,2.87,0,0,1-.368,2.2c-.018.028-.036.055-1.865,2.347a.747.747,0,0,1-.131.164c-1.366,1.712-3.677,4.6-7.59,9.5a2.445,2.445,0,0,1-1.886.914l-3.639.046ZM1.7,13.792a.877.877,0,0,0-.171.755l.68,2.884,3.039-.038a.944.944,0,0,0,.733-.352c1.933-2.418,5.04-6.306,7.178-8.982L8.9,4.788Zm12.4-6.9c.82-1.027,1.36-1.7,1.41-1.769a1.351,1.351,0,0,0,.141-1,1.406,1.406,0,0,0-.652-.887c-.059-.04-1.265-.976-1.779-1.375l-.029-.022a1.512,1.512,0,0,0-.942-.331,1.473,1.473,0,0,0-1.13.519l-1.279,1.6Z"
      />
    </g>
  </svg>
);

export const UpdateIcon = (props) => <Icon component={Update} {...props} />;

const Filter = () => (
  <svg
    id="Iconly_Light-Outline_Filter_2"
    dataname="Iconly/Light-Outline/Filter 2"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g id="Filter_2" dataname="Filter 2" transform="translate(1.334 1.333)">
      <path
        id="Fill_1"
        dataname="Fill 1"
        d="M5.431,13a.768.768,0,0,1-.764-.77V9.182a2.926,2.926,0,0,0-.8-2.015.478.478,0,0,1-.044-.041L.6,3.691A2.188,2.188,0,0,1,0,2.185V1.561A1.554,1.554,0,0,1,1.543,0h9.914A1.553,1.553,0,0,1,13,1.561v.624a2.2,2.2,0,0,1-.594,1.505L9.175,7.126a2.927,2.927,0,0,0-.828,2.048V10.7A1.372,1.372,0,0,1,7.636,11.9l-1.841,1A.764.764,0,0,1,5.431,13ZM1.543,1A.553.553,0,0,0,1,1.561v.624A1.19,1.19,0,0,0,1.324,3L4.516,6.4a.432.432,0,0,1,.034.032,3.916,3.916,0,0,1,1.116,2.75v2.656l1.491-.812a.372.372,0,0,0,.19-.328V9.175A3.906,3.906,0,0,1,8.452,6.436l3.224-3.43A1.193,1.193,0,0,0,12,2.185V1.561A.552.552,0,0,0,11.458,1Z"
        transform="translate(0 0)"
        fill="#293a40"
        stroke="#293a40"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export const FilterIcon = (props) => <Icon component={Filter} {...props} />;

const Budget = () => (
  <svg
    id="refund"
    xmlns="http://www.w3.org/2000/svg"
    width="46"
    height="46"
    viewBox="0 0 46 46"
  >
    <path
      id="Path_8748"
      dataname="Path 8748"
      d="M24,1A22.8,22.8,0,0,1,37.331,5.258L40.6,2.485a1,1,0,0,1,1.639.638L43.632,14.19a1,1,0,0,1-1.261,1.088L31.5,12.25a1,1,0,0,1-.379-1.726l3.032-2.569A18.99,18.99,0,1,0,43,24a2,2,0,0,1,4,0A23,23,0,1,1,24,1Z"
      transform="translate(-1 -1)"
      fill="#293a40"
    />
    <path
      id="Path_8749"
      dataname="Path 8749"
      d="M18.656,30.086l-.971-.242a.5.5,0,0,0-.606.364l-.485,1.94a.5.5,0,0,0,.364.606l.97.243A22.84,22.84,0,0,0,23,33.734V37h2V33.638a7.868,7.868,0,0,0,3.979-1.515A5.2,5.2,0,0,0,31,27.9c-.048-3.3-3.5-4.969-6-5.555V16.519a14.558,14.558,0,0,1,3.209.464l.961.277a.5.5,0,0,0,.619-.342L30.344,15A.5.5,0,0,0,30,14.38l-.961-.277A17.589,17.589,0,0,0,25,13.524V11H23v2.6a8.248,8.248,0,0,0-3.517,1.115,4.968,4.968,0,0,0-2.3,3.508c-.648,4.11,3.171,6.117,5.814,6.763v5.739a20.158,20.158,0,0,1-4.341-.639ZM28,27.938a2.2,2.2,0,0,1-.884,1.833A4.668,4.668,0,0,1,25,30.6V25.453C26.38,25.9,27.983,26.742,28,27.938ZM20.148,18.7a2.033,2.033,0,0,1,.841-1.377,4.75,4.75,0,0,1,2.011-.7V21.85C21.7,21.4,19.873,20.459,20.148,18.7Z"
      transform="translate(-1 -1)"
      fill="#293a40"
    />
  </svg>
);

export const BudgetIcon = (props) => <Icon component={Budget} {...props} />;

const Period = () => (
  <svg
    id="time-machine"
    xmlns="http://www.w3.org/2000/svg"
    width="46"
    height="46"
    viewBox="0 0 46 46"
  >
    <path
      id="Path_8751"
      dataname="Path 8751"
      d="M23.5,25.5a1.5,1.5,0,0,1,0-3l12.567-.433L36.5,9.5a1.5,1.5,0,0,1,3,0L40,24a2,2,0,0,1-2,2Z"
      transform="translate(-15 -2.01)"
      fill="#293a40"
    />
    <path
      id="Path_8752"
      dataname="Path 8752"
      d="M24,1A22.8,22.8,0,0,1,37.331,5.258L40.6,2.485a1,1,0,0,1,1.639.638L43.632,14.19a1,1,0,0,1-1.261,1.088L31.5,12.25a1,1,0,0,1-.379-1.726l3.032-2.569A18.99,18.99,0,1,0,43,24a2,2,0,0,1,4,0A23,23,0,1,1,24,1Z"
      transform="translate(-1 -1)"
      fill="#293a40"
    />
  </svg>
);

export const PeriodIcon = (props) => <Icon component={Period} {...props} />;

const CloseModal = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42.426"
    height="42.426"
    viewBox="0 0 42.426 42.426"
  >
    <path
      id="mini_plus"
      d="M27,12H18V3a3,3,0,1,0-6,0v9H3a3,3,0,1,0,0,6h9v9a3,3,0,1,0,6,0V18h9a3,3,0,1,0,0-6Z"
      transform="translate(0 21.213) rotate(-45)"
      fillRule="evenodd"
    />
  </svg>
);

export const CloseModalIcon = (props) => (
  <Icon component={CloseModal} {...props} />
);

const Eye = () => (
  <svg
    id="Iconly_Light-Outline_Show"
    dataname="Iconly/Light-Outline/Show"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g id="Show" transform="translate(2 4)">
      <path
        id="Combined_Shape"
        dataname="Combined Shape"
        d="M10,16.1H10c-4.136,0-7.85-2.9-9.936-7.757a.765.765,0,0,1,0-.591C2.147,2.9,5.861,0,10,0H10c4.136,0,7.851,2.9,9.937,7.757a.75.75,0,0,1,0,.591C17.853,13.2,14.139,16.1,10,16.1ZM1.57,8.051c1.857,4.1,5.009,6.552,8.43,6.554s6.574-2.452,8.429-6.554C16.573,3.951,13.422,1.5,10,1.5S3.427,3.951,1.57,8.051Zm4.519,0A3.911,3.911,0,1,1,10,11.963,3.916,3.916,0,0,1,6.089,8.053Zm1.5,0A2.412,2.412,0,1,0,10,5.641,2.413,2.413,0,0,0,7.589,8.053Z"
        transform="translate(0 0.001)"
      />
    </g>
  </svg>
);

export const EyeIcon = (props) => <Icon component={Eye} {...props} />;

const Arrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.107"
    height="7.664"
    viewBox="0 0 13.107 7.664"
  >
    <g
      id="Iconly_Light-Outline_Arrow_-_Right_2"
      dataname="Iconly/Light-Outline/Arrow - Right 2"
      transform="translate(12.566 0.541) rotate(90)"
    >
      <g
        id="Arrow_-_Right_2"
        dataname="Arrow - Right 2"
        transform="translate(0 12) rotate(-90)"
      >
        <path
          id="Stroke_1"
          dataname="Stroke 1"
          d="M.17.17A.581.581,0,0,1,.926.114L.991.17,6,5.179,11.009.17a.581.581,0,0,1,.756-.056L11.83.17a.581.581,0,0,1,.056.756L11.83.991,6.411,6.411a.581.581,0,0,1-.756.056l-.065-.056L.17.991A.581.581,0,0,1,.17.17Z"
          transform="translate(0 0)"
          fill="#4e5d65"
          stroke="#4e5d65"
          strokeWidth="1"
        />
      </g>
    </g>
  </svg>
);

export const ArrowIcon = (props) => <Icon component={Arrow} {...props} />;

const ProjectSettings = () => (
  <svg
    id="Iconly_Light_Setting"
    dataname="Iconly/Light/Setting"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g id="Setting" transform="translate(2.186 1.519)">
      <path
        id="Path_33946"
        d="M11.685,3.564l-.415-.72a1.275,1.275,0,0,0-1.739-.47h0a1.269,1.269,0,0,1-1.739-.452,1.22,1.22,0,0,1-.171-.61h0A1.275,1.275,0,0,0,6.346,0H5.51A1.269,1.269,0,0,0,4.241,1.275h0A1.275,1.275,0,0,1,2.965,2.532a1.22,1.22,0,0,1-.61-.171h0a1.275,1.275,0,0,0-1.739.47l-.445.732A1.275,1.275,0,0,0,.634,5.3h0a1.275,1.275,0,0,1,.638,1.1,1.275,1.275,0,0,1-.638,1.1h0A1.269,1.269,0,0,0,.171,9.245h0l.421.726a1.275,1.275,0,0,0,1.739.494h0a1.263,1.263,0,0,1,1.733.464,1.22,1.22,0,0,1,.171.61h0A1.275,1.275,0,0,0,5.51,12.815h.836a1.275,1.275,0,0,0,1.275-1.269h0A1.269,1.269,0,0,1,8.9,10.27a1.3,1.3,0,0,1,.61.171h0a1.275,1.275,0,0,0,1.739-.464h0l.439-.732a1.269,1.269,0,0,0-.464-1.739h0a1.269,1.269,0,0,1-.464-1.739,1.251,1.251,0,0,1,.464-.464h0a1.275,1.275,0,0,0,.464-1.733h0Z"
        transform="translate(0 0)"
        fill="none"
        stroke="#58666e"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <circle
        id="Ellipse_737"
        cx="1.757"
        cy="1.757"
        r="1.757"
        transform="translate(4.174 4.65)"
        fill="none"
        stroke="#58666e"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export const ProjectSettingsIcon = (props) => (
  <Icon component={ProjectSettings} {...props} />
);

const Tooltip = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 55">
    <g id="Group_1959" dataname="Group 1959" transform="translate(-893 -545)">
      <rect
        id="Rectangle_1223"
        dataname="Rectangle 1223"
        width="40"
        height="55"
        transform="translate(893 545)"
        fill="none"
      />
      <g
        id="Iconly_Light-Outline_Danger_Circle"
        dataname="Iconly/Light-Outline/Danger Circle"
        transform="translate(925 585) rotate(180)"
      >
        <g id="Danger_Circle" dataname="Danger Circle">
          <path
            id="Combined_Shape"
            dataname="Combined Shape"
            d="M0,12A12,12,0,1,1,12,24,12.013,12.013,0,0,1,0,12Zm1.8,0A10.2,10.2,0,1,0,12,1.8,10.212,10.212,0,0,0,1.8,12Zm9,4.555a1.194,1.194,0,0,1,1.193-1.2H12a1.2,1.2,0,1,1-1.205,1.2Zm.294-3.808v-5.3a.9.9,0,0,1,1.8,0v5.3a.9.9,0,1,1-1.8,0Z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const TooltipIcon = (props) => <Icon component={Tooltip} {...props} />;

const Upload = () => (
  <svg
    id="Iconly_Light-Outline_Upload"
    dataname="Iconly/Light-Outline/Upload"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g id="Upload" transform="translate(2 2)">
      <path
        id="Combined_Shape"
        dataname="Combined Shape"
        d="M4.435,20.529A4.44,4.44,0,0,1,0,16.094V11.219A4.44,4.44,0,0,1,4.435,6.784h.932a.75.75,0,1,1,0,1.5H4.435A2.937,2.937,0,0,0,1.5,11.219v4.875a2.937,2.937,0,0,0,2.935,2.935H15.565A2.939,2.939,0,0,0,18.5,16.094V11.21a2.928,2.928,0,0,0-2.924-2.925h-.943a.75.75,0,0,1,0-1.5h.943A4.43,4.43,0,0,1,20,11.21v4.884a4.44,4.44,0,0,1-4.435,4.435Zm5.565-7H9.249V2.558L7.616,4.2A.75.75,0,1,1,6.554,3.14L9.469.212a.769.769,0,0,1,1.062,0L13.447,3.14A.75.75,0,0,1,12.385,4.2L10.75,2.557V13.531Z"
        transform="translate(0 0.009)"
      />
    </g>
  </svg>
);

export const UploadIcon = (props) => <Icon component={Upload} {...props} />;

const Download = () => (
  <svg
    id="Iconly_Light-Outline_Download"
    dataname="Iconly/Light-Outline/Download"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g id="Download" transform="translate(2 3)">
      <path
        id="Combined_Shape"
        dataname="Combined Shape"
        d="M4.435,18.476A4.44,4.44,0,0,1,0,14.042V9.157A4.43,4.43,0,0,1,4.426,4.732h.941a.75.75,0,1,1,0,1.5H4.426A2.927,2.927,0,0,0,1.5,9.157v4.885a2.937,2.937,0,0,0,2.935,2.935h11.14A2.93,2.93,0,0,0,18.5,14.052V9.167a2.938,2.938,0,0,0-2.934-2.935h-.932a.75.75,0,0,1,0-1.5h.932A4.44,4.44,0,0,1,20,9.167v4.884a4.43,4.43,0,0,1-4.424,4.424Zm5.547-4.935H9.963a.747.747,0,0,1-.508-.235l-2.9-2.913A.75.75,0,1,1,7.616,9.334l1.635,1.642V.75a.75.75,0,0,1,1.5,0V10.976l1.635-1.642a.75.75,0,1,1,1.062,1.059l-2.9,2.913a.747.747,0,0,1-.508.234H9.982Z"
        transform="translate(0 0)"
      />
    </g>
  </svg>
);

export const DownloadIcon = (props) => <Icon component={Download} {...props} />;

const Phone = () => (
  <svg
    id="Iconly_Light-Outline_Call"
    dataname="Iconly/Light-Outline/Call"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g id="Call" transform="translate(2 2)">
      <path
        id="Fill_1"
        dataname="Fill 1"
        d="M15.59,20.5h-.028c-2.493,0-5.742-1.985-9.658-5.9C1.971,10.67-.015,7.41,0,4.914A3.72,3.72,0,0,1,1.2,2.1c.014-.017.056-.059.08-.083L1.294,2C2.716.581,3.683,0,4.631,0h.026C5.774.015,6.569.851,7.575,1.909c.252.267.523.552.82.848a2.957,2.957,0,0,1,.71,3.606c-.364,1.043-.679,1.943,1.207,3.83a3.931,3.931,0,0,0,2.609,1.482,3.616,3.616,0,0,0,1.218-.278,4.412,4.412,0,0,1,1.463-.324A3.016,3.016,0,0,1,17.746,12.1c.3.3.585.571.836.81,1.064,1.011,1.9,1.81,1.918,2.93.012.954-.568,1.928-2,3.361l-.635-.43.536.525A3.725,3.725,0,0,1,15.59,20.5ZM2.357,3.063c-.009.011-.055.058-.109.112A2.244,2.244,0,0,0,1.5,4.922c-.008,1.431.933,4.089,5.464,8.618C11.478,18.053,14.131,19,15.561,19h.021a2.258,2.258,0,0,0,1.744-.744,2.4,2.4,0,0,1,.174-.166c1-1.007,1.506-1.756,1.5-2.226-.006-.486-.61-1.061-1.446-1.857L17.548,14c-.271-.257-.56-.533-.863-.836a1.551,1.551,0,0,0-1.093-.591,3.074,3.074,0,0,0-.957.239,5.013,5.013,0,0,1-1.712.366,5.372,5.372,0,0,1-3.671-1.926C6.7,8.7,7.271,7.065,7.689,5.87c.3-.863.44-1.257-.354-2.052-.284-.284-.537-.549-.781-.805l-.065-.069-.006-.007c-.79-.83-1.36-1.43-1.844-1.437H4.63c-.478,0-1.221.51-2.272,1.561,0,0,0,0,0,0Z"
        transform="translate(0 0)"
      />
    </g>
  </svg>
);

export const PhoneIcon = (props) => <Icon component={Phone} {...props} />;

const AdvancedSettings = () => (
  <svg
    id="Iconly_Light-Outline_Lock"
    dataname="Iconly/Light-Outline/Lock"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g id="Lock" transform="translate(4 2)">
      <path
        id="Combined_Shape"
        dataname="Combined Shape"
        d="M4.542,20A4.547,4.547,0,0,1,0,15.459V11.171A4.55,4.55,0,0,1,3.052,6.88V5.3A5.28,5.28,0,0,1,4.62,1.538,5.131,5.131,0,0,1,8.375,0a5.306,5.306,0,0,1,5.3,5.3V6.88a4.55,4.55,0,0,1,3.052,4.291v4.288A4.547,4.547,0,0,1,12.183,20ZM1.5,11.171v4.288A3.045,3.045,0,0,0,4.542,18.5h7.641a3.045,3.045,0,0,0,3.042-3.042V11.171a3.045,3.045,0,0,0-3.042-3.042H4.542A3.045,3.045,0,0,0,1.5,11.171ZM12.173,6.628V5.3a3.8,3.8,0,0,0-3.8-3.8H8.356a3.8,3.8,0,0,0-3.8,3.784V6.628Zm-4.56,7.8V12.2a.75.75,0,0,1,1.5,0v2.221a.75.75,0,1,1-1.5,0Z"
        transform="translate(0 0)"
      />
    </g>
  </svg>
);

export const AdvancedSettingsIcon = (props) => (
  <Icon component={AdvancedSettings} {...props} />
);

const FirstHashtag = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <g id="Group_2174" dataname="Group 2174" transform="translate(-893 -545)">
      <rect
        id="Rectangle_1223"
        dataname="Rectangle 1223"
        width="40"
        height="40"
        transform="translate(893 545)"
        fill="none"
      />
      <g
        id="Group_2106"
        dataname="Group 2106"
        transform="translate(66.071 257.27)"
      >
        <g id="Group_2012" dataname="Group 2012">
          <g id="Path_8715" dataname="Path 8715">
            <path
              id="Path_8720"
              dataname="Path 8720"
              d="M854.53,310.72v.01a.091.091,0,0,1,.07.03v-.04Zm.59-9.78a1.925,1.925,0,0,0,.01-.24,2.827,2.827,0,0,0-2.82-2.81h-.02a2.79,2.79,0,0,0-2.78,2.37l-.08.43h-2.72a2.823,2.823,0,0,0-4.4-2.28,2.893,2.893,0,0,0-1.21,1.85l-.07.43h-1.11a2.851,2.851,0,0,0,0,5.7h.16l-.45,2.71a2.852,2.852,0,0,0-.9,5.43v.21a2.835,2.835,0,0,0,5.63.47l.07-.42h2.7a2.837,2.837,0,0,0,2.28,2.7,2.469,2.469,0,0,0,.54.05,2.827,2.827,0,0,0,2.15-.99v-3.27h-.57l-.27,1.67a1.341,1.341,0,0,1-1.32,1.09,1.94,1.94,0,0,1-.24-.02,1.34,1.34,0,0,1-1.07-1.46l.2-1.27h-5.69l-.27,1.67a1.335,1.335,0,0,1-1.54,1.09,1.319,1.319,0,0,1-1.09-1.48l.2-1.18-.75-.12a1.349,1.349,0,0,1,.23-2.68h.98l.94-5.7h-1.92a1.351,1.351,0,0,1,0-2.7h2.38l.27-1.67a1.344,1.344,0,0,1,.57-.86,1.305,1.305,0,0,1,1-.2,1.32,1.32,0,0,1,1.06,1.47l-.18,1.26h5.68l.28-1.69a1.305,1.305,0,0,1,1.31-1.11h.01a1.312,1.312,0,0,1,1.32,1.32,1.693,1.693,0,0,1-.01.23l-.19,1.14.74.12a1.383,1.383,0,0,1,.88.55,1.4,1.4,0,0,1,.25.8,1.349,1.349,0,0,1-1.36,1.33h-.99l-.25,1.54a2.461,2.461,0,0,1,.72-.11h1.19a2.851,2.851,0,0,0,.52-5.37Z"
            />
          </g>
          <g id="Group_1734" dataname="Group 1734">
            <path
              id="Path_8721"
              dataname="Path 8721"
              d="M845.091,306.142l-.492,2.994a.6.6,0,0,0,.491.691.553.553,0,0,0,.1.009h2.958a.6.6,0,0,0,.594-.5l.493-2.994a.6.6,0,0,0-.491-.692h0a.562.562,0,0,0-.108-.008h-2.957a.6.6,0,0,0-.588.5Z"
              fillRule="evenodd"
            />
          </g>
        </g>
        <g id="Group_2103" dataname="Group 2103">
          <g id="Group_2102" dataname="Group 2102">
            <path
              id="Path_9058"
              dataname="Path 9058"
              d="M854.6,310.72v.04a.091.091,0,0,0-.07-.03v-.01Z"
            />
          </g>
        </g>
        <g id="Group_2105" dataname="Group 2105">
          <g id="Group_2104" dataname="Group 2104">
            <path
              id="Path_9059"
              dataname="Path 9059"
              d="M854.53,310.72v.01a.091.091,0,0,1,.07.03v-.04Z"
            />
            <path
              id="Path_9060"
              dataname="Path 9060"
              d="M856.67,308.81v8.76H854.6v-6.85h-1.25v-1.91Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const FirstHashtagIcon = (props) => (
  <Icon component={FirstHashtag} {...props} />
);

const SecondHashtag = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <g id="Group_2180" dataname="Group 2180" transform="translate(-893 -545)">
      <rect
        id="Rectangle_1223"
        dataname="Rectangle 1223"
        width="40"
        height="40"
        transform="translate(893 545)"
        fill="none"
      />
      <g
        id="Group_2110"
        dataname="Group 2110"
        transform="translate(65.218 222.185)"
      >
        <g id="Group_2012" dataname="Group 2012">
          <g id="Path_8715" dataname="Path 8715">
            <path
              id="Path_8720"
              dataname="Path 8720"
              d="M855.12,335.69a1.925,1.925,0,0,0,.01-.24,2.827,2.827,0,0,0-2.82-2.81h-.02a2.79,2.79,0,0,0-2.78,2.37l-.08.43h-2.72a2.823,2.823,0,0,0-4.4-2.28,2.893,2.893,0,0,0-1.21,1.85l-.07.43h-1.11a2.851,2.851,0,0,0,0,5.7h.16l-.45,2.71a2.852,2.852,0,0,0-.9,5.43v.21a2.835,2.835,0,0,0,5.63.47l.07-.42h2.7a2.837,2.837,0,0,0,2.28,2.7,3.289,3.289,0,0,0,.54.05h.07v-.7a2.523,2.523,0,0,1,.14-.82,1.632,1.632,0,0,1-.22.02,1.94,1.94,0,0,1-.24-.02,1.34,1.34,0,0,1-1.07-1.46l.2-1.27h-5.69l-.27,1.67a1.335,1.335,0,0,1-1.54,1.09,1.319,1.319,0,0,1-1.09-1.48l.2-1.18-.75-.12a1.349,1.349,0,0,1,.23-2.68h.98l.94-5.7h-1.92a1.351,1.351,0,0,1,0-2.7h2.38l.27-1.67a1.344,1.344,0,0,1,.57-.86,1.264,1.264,0,0,1,1-.2,1.32,1.32,0,0,1,1.06,1.47l-.18,1.25h5.68l.28-1.68a1.323,1.323,0,0,1,1.31-1.12h.01a1.327,1.327,0,0,1,1.32,1.33,1.693,1.693,0,0,1-.01.23l-.19,1.14.74.12a1.344,1.344,0,0,1,.88.55,1.4,1.4,0,0,1,.25.8,1.349,1.349,0,0,1-1.36,1.33h-.99l-.5,3.02a5.1,5.1,0,0,1,1.64-.69l.14-.84a2.848,2.848,0,0,0,.9-5.43Z"
            />
          </g>
          <g id="Group_1734" dataname="Group 1734">
            <path
              id="Path_8721"
              dataname="Path 8721"
              d="M845.091,340.89l-.492,2.994a.6.6,0,0,0,.491.692.819.819,0,0,0,.1.009h2.958a.6.6,0,0,0,.594-.5l.493-2.994a.6.6,0,0,0-.491-.692h0a.563.563,0,0,0-.108-.009h-2.957a.6.6,0,0,0-.588.5Z"
              fillRule="evenodd"
            />
          </g>
        </g>
        <g id="Group_2109" dataname="Group 2109">
          <g id="Group_2108" dataname="Group 2108">
            <path
              id="Path_9061"
              dataname="Path 9061"
              d="M858.5,351.32v1.67h-5.98v-1.4a31.029,31.029,0,0,0,2.69-2.36,3.5,3.5,0,0,0,1.15-2.18,1.318,1.318,0,0,0-.1-.55.142.142,0,0,0-.02-.06.807.807,0,0,0-.84-.55.829.829,0,0,0-.32.06c-.41.14-.65.58-.65,1.25h-1.92a3.234,3.234,0,0,1,.63-1.87,2.93,2.93,0,0,1,2.32-1.05h.08a2.568,2.568,0,0,1,2.69,1.59c.02.04.03.09.05.14a3.355,3.355,0,0,1,.09.79c0,1.92-1.72,3.45-3.11,4.52Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const SecondHashtagIcon = (props) => (
  <Icon component={SecondHashtag} {...props} />
);

const Emoji = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <defs>
      <clipPath id="emoji-clip-path">
        <path
          id="emoji-path"
          d="M0,0A5.179,5.179,0,0,0,5.179,5.179,5.179,5.179,0,0,0,10.359,0"
        />
      </clipPath>
    </defs>
    <g id="Group_2181" dataname="Group 2181" transform="translate(-893 -545)">
      <rect
        id="Rectangle_1223"
        dataname="Rectangle 1223"
        width="40"
        height="40"
        transform="translate(893 545)"
        fill="none"
      />
      <g
        id="Iconly_Light-Outline_Danger_Circle"
        dataname="Iconly/Light-Outline/Danger Circle"
        transform="translate(903 555)"
      >
        <g
          id="Danger_Circle"
          dataname="Danger Circle"
          transform="translate(0 0)"
        >
          <path
            id="Combined_Shape"
            dataname="Combined Shape"
            d="M10,20A10,10,0,1,1,20,10,10.011,10.011,0,0,1,10,20ZM10,1.5A8.5,8.5,0,1,0,18.5,10,8.509,8.509,0,0,0,10,1.5Z"
          />
        </g>
      </g>
      <path
        id="eyes"
        d="M22.556,27.213,20.636,26.06a.42.42,0,1,0-.433.72l1.376.827-1.368.888a.42.42,0,1,0,.457.7l1.878-1.22A.45.45,0,0,0,22.556,27.213Zm5.212,0,1.919-1.153a.42.42,0,1,1,.433.72l-1.376.827,1.368.888a.42.42,0,1,1-.457.7l-1.878-1.22A.45.45,0,0,1,27.767,27.213Z"
        transform="translate(887.931 535.525)"
        fillRule="evenodd"
      />
      <g
        id="group-mask-1"
        transform="translate(907.931 564.999)"
        clipPath="url(#emoji-clip-path)"
      >
        <path
          id="smile"
          d="M0,12.709S1.277,12,5.1,12s5.257.709,5.257.709L5.1,16.152Z"
          transform="translate(0 -9.853)"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

export const EmojiIcon = (props) => <Icon component={Emoji} {...props} />;

const PhoneFrame = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="422.001"
    height="837.001"
    viewBox="0 0 422.001 837.001"
  >
    <g
      id="Group_2213"
      dataname="Group 2213"
      transform="translate(-718 -189.999)"
    >
      <g
        id="Group_1965"
        dataname="Group 1965"
        transform="translate(718 189.999)"
        opacity="0.3"
      >
        <path
          id="Path_8955"
          dataname="Path 8955"
          d="M3112.126,1696.483h-.8v-82.7c0-27.422-22.615-49.651-50.514-49.651h-306.18c-27.9,0-50.513,22.229-50.513,49.651v82.7h-.8a6.537,6.537,0,0,0-6.6,6.483v57.205a6.537,6.537,0,0,0,6.6,6.484h.8V1780.3h-.8a6.537,6.537,0,0,0-6.6,6.484v57.206a6.537,6.537,0,0,0,6.6,6.483h.8v501.007c0,27.422,22.614,49.651,50.513,49.651h306.18c27.9,0,50.514-22.229,50.514-49.651V1801.741h.8a6.54,6.54,0,0,0,6.6-6.483v-92.292A6.54,6.54,0,0,0,3112.126,1696.483Zm-15.5,652.041c0,22.272-18.372,40.326-41.03,40.326H2759.842c-22.659,0-41.026-18.054-41.026-40.326V1616.736c0-22.272,18.367-40.325,41.026-40.325H3055.6c22.658,0,41.03,18.054,41.03,40.325Z"
          transform="translate(-2696.72 -1564.13)"
          fill="rgba(41,58,64,0.15)"
        />
      </g>
      <g
        id="Group_1973"
        dataname="Group 1973"
        transform="translate(750.299 218.811)"
      >
        <rect
          id="Rectangle_521"
          dataname="Rectangle 521"
          width="43.983"
          height="5.498"
          rx="2.749"
          transform="translate(140.21 0)"
          fill="rgba(255,255,255,0.15)"
        />
        <rect
          id="Rectangle_523"
          dataname="Rectangle 523"
          width="326.046"
          height="168.829"
          transform="translate(0 77.85)"
          fill="none"
        />
        <rect
          id="Rectangle_525"
          dataname="Rectangle 525"
          width="120.592"
          height="4.466"
          rx="2.233"
          transform="translate(124.578 779.911)"
          fill="rgba(41,58,64,0.15)"
        />
        <g
          id="Group_60"
          dataname="Group 60"
          transform="translate(8494.105 -4672.561)"
        >
          <g
            id="Group_6"
            dataname="Group 6"
            transform="translate(-8214.646 4688.004)"
          >
            <rect
              id="Rectangle_8"
              dataname="Rectangle 8"
              width="16.661"
              height="16.661"
              rx="2"
              transform="translate(0 0)"
              fill="#157cfc"
            />
            <path
              id="Path_3"
              dataname="Path 3"
              d="M-265.034,587.463l-3.185-1.686a1.469,1.469,0,0,1-.781-1.3v-3.633a1.469,1.469,0,0,1,.781-1.3l3.185-1.686a1.468,1.468,0,0,1,2.155,1.3v7.006A1.468,1.468,0,0,1-265.034,587.463Z"
              transform="translate(287.706 -574.382)"
              fill="#157cfc"
            />
          </g>
          <g id="noun_Phone_170052" transform="translate(-8248.801 4687.166)">
            <path
              id="Path_4"
              dataname="Path 4"
              d="M14.682,10.726c.393.393.788.785,1.18,1.18a1.429,1.429,0,0,1,0,2.2c-.493.5-.984.995-1.487,1.48a.3.3,0,0,0-.076.4,8.8,8.8,0,0,0,1.345,2.156,15.5,15.5,0,0,0,3.758,3.42c.312.2.657.338.982.515A.3.3,0,0,0,20.8,22c.492-.509,1-1.006,1.5-1.5a1.42,1.42,0,0,1,2.146,0q1.212,1.2,2.414,2.412a1.435,1.435,0,0,1-.009,2.187c-.457.461-.939.9-1.371,1.381a2.712,2.712,0,0,1-2.319.881,9.626,9.626,0,0,1-3.685-1.073,20.062,20.062,0,0,1-6.653-5.206A18.334,18.334,0,0,1,9.58,15.67a7.081,7.081,0,0,1-.539-3.01,2.442,2.442,0,0,1,.769-1.653c.512-.488,1-1,1.505-1.5a1.429,1.429,0,0,1,2.15,0C13.875,9.912,14.277,10.321,14.682,10.726Z"
              transform="translate(-9.027 -9.022)"
              fill="#157cfc"
            />
          </g>
          <g
            id="Group_59"
            dataname="Group 59"
            transform="translate(-8179.404 4681.097)"
          >
            <path
              id="Exclusion_3"
              dataname="Exclusion 3"
              d="M8.568,17.137a8.568,8.568,0,1,1,8.569-8.569A8.578,8.578,0,0,1,8.568,17.137Zm0-10.525A1.429,1.429,0,0,0,7.141,8.04v4.284a1.428,1.428,0,1,0,2.856,0V8.04A1.43,1.43,0,0,0,8.568,6.611Zm0-3.332A1.428,1.428,0,1,0,10,4.708,1.429,1.429,0,0,0,8.568,3.28Z"
              transform="translate(6.432 6.432)"
              fill="#157cfc"
            />
            <path
              id="Path_16"
              dataname="Path 16"
              d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Z"
              transform="translate(0)"
              fill="#157cfc"
              opacity="0.179"
            />
          </g>
        </g>
      </g>
      <g
        id="Group_65"
        dataname="Group 65"
        transform="translate(9399.455 -4797.529)"
      >
        <g
          id="Group_64"
          dataname="Group 64"
          transform="translate(-8491.372 5753.484)"
        >
          <rect
            id="Rectangle_13"
            dataname="Rectangle 13"
            width="112.131"
            height="30"
            rx="15"
            transform="translate(42.917 0)"
            fill="#e4e6eb"
          />
          <path
            id="Exclusion_2"
            dataname="Exclusion 2"
            d="M8.852,17.7A8.855,8.855,0,0,1,5.407.7,8.855,8.855,0,0,1,12.3,17.009,8.8,8.8,0,0,1,8.852,17.7ZM4.736,10.47a.919.919,0,0,0-.65,1.569,6.75,6.75,0,0,0,9.533,0,.919.919,0,0,0-1.3-1.3,4.906,4.906,0,0,1-6.933,0A.914.914,0,0,0,4.736,10.47ZM11.8,4.918a1.475,1.475,0,1,0,1.476,1.475A1.477,1.477,0,0,0,11.8,4.918Zm-5.9,0A1.475,1.475,0,1,0,7.377,6.393,1.477,1.477,0,0,0,5.9,4.918Z"
            transform="translate(130.95 5.902)"
            fill="#157dfc"
          />
        </g>
        <g
          id="Group_63"
          dataname="Group 63"
          transform="translate(-8636.455 5758.102)"
        >
          <g
            id="Group_9"
            dataname="Group 9"
            transform="translate(32.459 1.584)"
          >
            <g id="Group_8" dataname="Group 8">
              <path
                id="Path_7"
                dataname="Path 7"
                d="M-278.275,446.8h-3.048l-1.5-2.248a.88.88,0,0,0-.732-.392h-5.279a.88.88,0,0,0-.732.392l-1.5,2.248h-3.048a2.639,2.639,0,0,0-2.639,2.639v9.678a2.639,2.639,0,0,0,2.639,2.639h15.837a2.639,2.639,0,0,0,2.639-2.639V449.44A2.639,2.639,0,0,0-278.275,446.8Zm-7.918,11.437a4.4,4.4,0,0,1-4.4-4.4,4.4,4.4,0,0,1,4.4-4.4,4.4,4.4,0,0,1,4.4,4.4A4.4,4.4,0,0,1-286.193,458.238Z"
                transform="translate(296.751 -444.161)"
                fill="#157dfc"
              />
              <circle
                id="Ellipse_1"
                dataname="Ellipse 1"
                cx="2.639"
                cy="2.639"
                r="2.639"
                transform="translate(7.918 7.039)"
                fill="#157dfc"
              />
            </g>
          </g>
          <g
            id="Group_11"
            dataname="Group 11"
            transform="translate(67.869 1.475)"
          >
            <path
              id="Path_9"
              dataname="Path 9"
              d="M-166.237,445h-13.582A2.181,2.181,0,0,0-182,447.181v13.342a2.181,2.181,0,0,0,2.181,2.181h13.582a2.181,2.181,0,0,0,2.181-2.181V447.181A2.181,2.181,0,0,0-166.237,445Zm-13.582,2.153h13.582a.028.028,0,0,1,.028.028v9.347l-5.582-3.089a3.057,3.057,0,0,0-2.56-.185l-5.5,2.076v-8.149A.028.028,0,0,1-179.819,447.153Z"
              transform="translate(182 -445)"
              fill="#157dfc"
            />
            <circle
              id="Ellipse_3"
              dataname="Ellipse 3"
              cx="1.475"
              cy="1.475"
              r="1.475"
              transform="translate(3.934 3.934)"
              fill="#157dfc"
            />
          </g>
          <path
            id="Exclusion_1"
            dataname="Exclusion 1"
            d="M8.852,17.7A8.852,8.852,0,1,1,17.7,8.852,8.862,8.862,0,0,1,8.852,17.7ZM5.41,7.869a.984.984,0,0,0,0,1.967H7.869v2.459a.984.984,0,1,0,1.967,0V9.836h2.459a.984.984,0,1,0,0-1.967H9.836V5.41a.984.984,0,0,0-1.967,0V7.869Z"
            transform="translate(0 1.475)"
            fill="#157dfc"
          />
          <g id="Group_12" dataname="Group 12" transform="translate(100.328)">
            <path
              id="Path_10"
              dataname="Path 10"
              d="M-161.164,592.6a3.836,3.836,0,0,0,3.836-3.836v-4.932A3.836,3.836,0,0,0-161.164,580,3.836,3.836,0,0,0-165,583.836v4.932A3.836,3.836,0,0,0-161.164,592.6Z"
              transform="translate(168.471 -580)"
              fill="#157cfc"
            />
            <path
              id="Path_11"
              dataname="Path 11"
              d="M-156.72,595.1a1.1,1.1,0,0,0-1.1-1.1,1.1,1.1,0,0,0-1.1,1.1,5.121,5.121,0,0,1-5.115,5.115,5.121,5.121,0,0,1-5.115-5.115,1.1,1.1,0,0,0-1.1-1.1,1.1,1.1,0,0,0-1.1,1.1,7.312,7.312,0,0,0,6.211,7.216v1h-2.192a1.1,1.1,0,0,0-1.1,1.1,1.1,1.1,0,0,0,1.1,1.1h6.576a1.1,1.1,0,0,0,1.1-1.1,1.1,1.1,0,0,0-1.1-1.1h-2.192v-1A7.312,7.312,0,0,0-156.72,595.1Z"
              transform="translate(171.333 -586.328)"
              fill="#157cfc"
            />
          </g>
        </g>
        <g id="noun_like_1555605" transform="translate(-8325.957 5757.91)">
          <g id="Group_13" dataname="Group 13" transform="translate(0 0)">
            <path
              id="Path_12"
              dataname="Path 12"
              d="M5.022,26.7H1.431a.592.592,0,0,0-.6.6v9.574a.592.592,0,0,0,.6.6h3.59a.592.592,0,0,0,.6-.6V27.3A.592.592,0,0,0,5.022,26.7Z"
              transform="translate(-0.833 -18.941)"
              fill="#157cfc"
            />
            <path
              id="Path_13"
              dataname="Path 13"
              d="M33.194,13.095a1.894,1.894,0,0,0-1.948-1.962h-5.8a6.482,6.482,0,0,0,.5-5.09A2.583,2.583,0,0,0,23.668,4.2h-.017a1.254,1.254,0,0,0-1.326,1.148c-.149,1.512-.812,4.187-1.761,5.136a8.417,8.417,0,0,1-2.618,1.689c-.164.08-.344.168-.533.262,0,.041.006.082.006.124v9.477l.4.139a16.507,16.507,0,0,0,5.964,1.2h4.687a1.894,1.894,0,0,0,1.948-1.962,2.115,2.115,0,0,0-.175-.845,1.851,1.851,0,0,0,1.075-.6,2.011,2.011,0,0,0,.486-1.334,2.113,2.113,0,0,0-.175-.843,1.894,1.894,0,0,0,1.562-1.93,2.028,2.028,0,0,0-.531-1.387A2.033,2.033,0,0,0,33.194,13.095Z"
              transform="translate(-11.693 -4.197)"
              fill="#157cfc"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const PhoneFrameIcon = (props) => (
  <Icon component={PhoneFrame} {...props} />
);

const Integrations = () => (
  <svg
    id="Iconly_Light-Outline_Swap"
    dataname="Iconly/Light-Outline/Swap"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g id="Swap" transform="translate(2 3)">
      <path
        id="Combined_Shape"
        dataname="Combined Shape"
        d="M14.313,17.687h0l0,0h0a.753.753,0,0,1-.082-.07l0,0-4.078-4.1a.75.75,0,0,1,.979-1.13l.084.073,2.8,2.808V3.464a.75.75,0,0,1,1.493-.1l.007.1v11.8l2.8-2.808a.75.75,0,0,1,1.136.974l-.073.084-4.078,4.1h0l0,0a.748.748,0,0,1-.97.071ZM4.084,14.47l-.006-.1V2.566l-2.8,2.81a.751.751,0,0,1-.977.075L.221,5.378A.751.751,0,0,1,.146,4.4l.073-.085L4.3.221A.746.746,0,0,1,4.6.034h0l.015,0h0l.012,0,.007,0,.01,0,.009,0,.008,0,.01,0h.007l.011,0h.007l.011,0H4.9l.015,0h0l.016,0h.005l.01,0h.007l.009,0H4.97l.009,0h.006l.01,0H5a.746.746,0,0,1,.274.127l0,0a.754.754,0,0,1,.074.064l.006.006,4.078,4.1a.75.75,0,0,1-.979,1.131l-.085-.073-2.8-2.81v11.8a.75.75,0,0,1-1.493.1Z"
        transform="translate(0.083 0.082)"
      />
    </g>
  </svg>
);

export const IntegrationsIcon = (props) => (
  <Icon component={Integrations} {...props} />
);

const Invoices = () => (
  <svg
    id="Component_29_5"
    dataname="Component 29 – 5"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path id="Path_8716" dataname="Path 8716" d="M0,0H24V24H0Z" fill="none" />
    <g
      id="Iconly_Light-Outline_Wallet"
      dataname="Iconly/Light-Outline/Wallet"
      transform="translate(2 2)"
    >
      <g id="Wallet" transform="translate(0 0)">
        <path
          id="Combined_Shape"
          dataname="Combined Shape"
          d="M6,19.173a6,6,0,0,1-6-6V6A6,6,0,0,1,6,0h8.645a6,6,0,0,1,6,6v7.178a6,6,0,0,1-6,6ZM1.5,6v7.178a4.5,4.5,0,0,0,4.5,4.5h8.645a4.5,4.5,0,0,0,4.5-4.5V12.9h-5.3a3.441,3.441,0,1,1,0-6.881h5.3V6a4.5,4.5,0,0,0-4.5-4.5H6A4.5,4.5,0,0,0,1.5,6ZM11.9,9.455a1.945,1.945,0,0,0,1.942,1.94h5.3V7.513h-5.3A1.946,1.946,0,0,0,11.9,9.455Zm4.088.688a.75.75,0,0,1,0-1.5H16.3a.75.75,0,0,1,0,1.5Z"
          transform="translate(0 0)"
          fill="#293a40"
        />
      </g>
    </g>
  </svg>
);

export const InvoicesIcon = (props) => <Icon component={Invoices} {...props} />;

const Reorder = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="24.2"
    viewBox="0 0 13 24.2"
  >
    <g id="Group_2126" dataname="Group 2126" transform="translate(1936 534)">
      <circle
        id="Ellipse_152"
        dataname="Ellipse 152"
        cx="2.5"
        cy="2.5"
        r="2.5"
        transform="translate(-1936 -534)"
      />
      <circle
        id="Ellipse_157"
        dataname="Ellipse 157"
        cx="2.5"
        cy="2.5"
        r="2.5"
        transform="translate(-1928 -534)"
      />
      <circle
        id="Ellipse_153"
        dataname="Ellipse 153"
        cx="2.5"
        cy="2.5"
        r="2.5"
        transform="translate(-1936 -524.4)"
      />
      <circle
        id="Ellipse_156"
        dataname="Ellipse 156"
        cx="2.5"
        cy="2.5"
        r="2.5"
        transform="translate(-1928 -524.4)"
      />
      <circle
        id="Ellipse_154"
        dataname="Ellipse 154"
        cx="2.5"
        cy="2.5"
        r="2.5"
        transform="translate(-1936 -514.8)"
      />
      <circle
        id="Ellipse_155"
        dataname="Ellipse 155"
        cx="2.5"
        cy="2.5"
        r="2.5"
        transform="translate(-1928 -514.8)"
      />
    </g>
  </svg>
);

export const ReorderIcon = (props) => <Icon component={Reorder} {...props} />;

const Delete = () => (
  <svg
    id="Iconly_Light-Outline_Delete"
    dataname="Iconly/Light-Outline/Delete"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g id="Delete" transform="translate(3 2)">
      <path
        id="Combined_Shape"
        dataname="Combined Shape"
        d="M5.263,19.958a2.967,2.967,0,0,1-3.018-2.829c-.315-2.84-.854-9.534-.859-9.6a.749.749,0,0,1,.687-.808.771.771,0,0,1,.808.688c0,.068.543,6.738.855,9.557a1.472,1.472,0,0,0,1.558,1.494c2.5.052,5.051.056,7.8.005a1.5,1.5,0,0,0,1.625-1.507c.311-2.794.851-9.482.856-9.55a.768.768,0,0,1,.807-.688.752.752,0,0,1,.688.808c-.006.068-.548,6.779-.86,9.594a2.977,2.977,0,0,1-3.09,2.842C11.79,19.986,10.5,20,9.247,20,7.891,20,6.571,19.985,5.263,19.958ZM14.441,4.989H.75a.75.75,0,1,1,0-1.5H4.018a.9.9,0,0,0,.88-.723l.243-1.216A2.043,2.043,0,0,1,7.113,0h4.233a2.033,2.033,0,0,1,1.962,1.506l.254,1.261a.9.9,0,0,0,.879.723h3.268a.75.75,0,1,1,0,1.5Zm-2.222-1.5a2.388,2.388,0,0,1-.128-.428l-.243-1.216a.525.525,0,0,0-.5-.346H7.113a.529.529,0,0,0-.512.391l-.233,1.17a2.38,2.38,0,0,1-.128.428Z"
        transform="translate(0 0)"
        stroke="rgba(0,0,0,0)"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export const DeleteIcon = (props) => <Icon component={Delete} {...props} />;

const Message = () => (
  <svg
    id="Iconly_Light-Outline_Chat"
    dataname="Iconly/Light-Outline/Chat"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g id="Chat" transform="translate(1 1)">
      <path
        id="Combined_Shape"
        dataname="Combined Shape"
        d="M6.219,20.5a3.584,3.584,0,0,0-1.105-.329,4.306,4.306,0,0,0-1.193.3,5.01,5.01,0,0,1-1.563.355,1.628,1.628,0,0,1-1.206-.482c-.8-.8-.432-1.889-.134-2.761a4.282,4.282,0,0,0,.3-1.21,3.492,3.492,0,0,0-.338-1.135A10.749,10.749,0,1,1,18.349,18.35a10.635,10.635,0,0,1-3.532,2.343,10.8,10.8,0,0,1-4.093.8A10.655,10.655,0,0,1,6.219,20.5Zm.6-1.377a9.341,9.341,0,0,0,7.441.175,9.155,9.155,0,0,0,3.033-2.012A9.25,9.25,0,1,0,2.355,14.648a4.749,4.749,0,0,1,.46,1.729,5.388,5.388,0,0,1-.378,1.693l0,.007c-.145.426-.364,1.068-.222,1.21a.226.226,0,0,0,.163.046,4.222,4.222,0,0,0,1.034-.269l.022-.007.026-.009a5.385,5.385,0,0,1,1.652-.373A4.641,4.641,0,0,1,6.815,19.128Zm6.878-7.965a1,1,0,0,1,.995-1H14.7a1,1,0,1,1-1,1Zm-4.009,0a1,1,0,0,1,1-1h.009a1,1,0,1,1-1,1Zm-4.009,0a1,1,0,0,1,.995-1h.009a1,1,0,1,1-1,1Z"
        transform="translate(0 0)"
      />
    </g>
  </svg>
);

export const GrayPlus = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33.941"
    height="33.941"
    viewBox="0 0 33.941 33.941"
  >
    <g
      id="Group_2129"
      dataname="Group 2129"
      transform="translate(17.184 0.379) rotate(45)"
    >
      <circle
        id="Ellipse_138"
        dataname="Ellipse 138"
        cx="12"
        cy="12"
        r="12"
        transform="translate(-0.419 -0.117)"
        fill="#293a40"
        opacity="0.1"
      />
      <path
        id="mini_plus"
        d="M12.449,5.533H8.3V1.383a1.383,1.383,0,1,0-2.766,0V5.533H1.383a1.383,1.383,0,1,0,0,2.766h4.15v4.15a1.383,1.383,0,0,0,2.766,0V8.3h4.15a1.383,1.383,0,1,0,0-2.766Z"
        transform="translate(1.934 11.883) rotate(-45)"
        fill="#293a40"
        fillRule="evenodd"
      />
    </g>
  </svg>
);

export const GrayPlusIcon = (props) => <Icon component={GrayPlus} {...props} />;

export const MessageIcon = (props) => <Icon component={Message} {...props} />;

const FreshMail = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
  >
    <path
      id="Path_9297"
      dataname="Path 9297"
      d="M20.506,11.555a.406.406,0,0,0,.12-.3v-1a.693.693,0,0,0-.651-.651H10.528a4.99,4.99,0,0,1,.3,2.7l3.9,2.471a.776.776,0,0,0,.352.075H15.1a.722.722,0,0,0,.329-.075l5.045-3.2Zm-.053,1.326-5.022,3.2a.924.924,0,0,1-.352.074.729.729,0,0,1-.329-.074l-4.319-2.748a3.943,3.943,0,0,1-.906.944v.906a5.239,5.239,0,0,0,1.631,3.893,5.3,5.3,0,0,0,3.922,1.655h.128A5.3,5.3,0,0,0,19,19.079a5.245,5.245,0,0,0,1.624-3.892v-2.6a.291.291,0,0,1-.045.146c-.02.035-.045.078-.075.128A.072.072,0,0,0,20.453,12.88Zm3.473,10.527a12.1,12.1,0,0,1-8.848,3.654,12.1,12.1,0,0,1-8.848-3.654,12.1,12.1,0,0,1-3.651-8.85,12.1,12.1,0,0,1,3.653-8.85A12.111,12.111,0,0,1,15.08,2.061a12.107,12.107,0,0,1,8.847,3.647,12.1,12.1,0,0,1,3.653,8.85,12.1,12.1,0,0,1-3.653,8.85ZM8.35,10.462a2.75,2.75,0,0,0-.9,1.123,1.277,1.277,0,0,0-.12.277,4.214,4.214,0,0,0,.2,2.823,4.73,4.73,0,0,0,2-1.251,2.577,2.577,0,0,0,.329-.449,3.012,3.012,0,0,0,.352-1.078,4.569,4.569,0,0,0-.3-2.224.065.065,0,0,1-.03-.053,5.777,5.777,0,0,0-1.527.832Zm.7-1.206q-.148-.044-.3-.075-2.7-.8-4.221,2.1a4.882,4.882,0,0,0,2.021.621h.075a2.157,2.157,0,0,1,.2-.621,3.182,3.182,0,0,1,.823-1.153,5.05,5.05,0,0,1,1.4-.876Z"
      transform="translate(-2.58 -2.061)"
      fill="#acd432"
      fillRule="evenodd"
    />
  </svg>
);

export const FreshMailIcon = (props) => (
  <Icon component={FreshMail} {...props} />
);

const SmsApi = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
  >
    <g
      id="Group_2204"
      dataname="Group 2204"
      transform="translate(-878.789 -666.762)"
    >
      <circle
        id="Ellipse_167"
        dataname="Ellipse 167"
        cx="12.5"
        cy="12.5"
        r="12.5"
        transform="translate(878.789 666.762)"
        fill="#092a53"
      />
      <g
        id="Group_2203"
        dataname="Group 2203"
        transform="translate(880.068 676.791)"
      >
        <path
          id="Path_9298"
          dataname="Path 9298"
          d="M949.906,844.945h-3.728l.51.78h3.728Z"
          transform="translate(-943.665 -844.945)"
          fill="#3489cc"
        />
        <path
          id="Path_9299"
          dataname="Path 9299"
          d="M915.71,920.026H901.516l.51.715h14.108Z"
          transform="translate(-901.516 -915.799)"
          fill="#fefefe"
        />
        <path
          id="Path_9300"
          dataname="Path 9300"
          d="M988.154,894.721h-10.4l.51.753h10.4Z"
          transform="translate(-973.46 -891.919)"
          fill="#3489cc"
        />
        <path
          id="Path_9301"
          dataname="Path 9301"
          d="M1028.445,870.357h-4.876l.51.758h4.876Z"
          transform="translate(-1016.699 -868.927)"
          fill="#6eabdf"
        />
        <path
          id="Path_9302"
          dataname="Path 9302"
          d="M1039.74,844.945l.51.78h7.292l3.262,2.022,2.947-1.824v3.249h-6.209l.51.715h6.35v-4.941Zm9.157.78h3.655l-1.7,1.31Z"
          transform="translate(-1031.96 -844.945)"
          fill="#fefefe"
        />
      </g>
    </g>
  </svg>
);

export const SmsApiIcon = (props) => <Icon component={SmsApi} {...props} />;

const Unlock = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.725"
    height="20"
    viewBox="0 0 16.725 20"
  >
    <g id="Unlock" transform="translate(-3.75 -1.75)">
      <path
        id="Path_9808"
        dataname="Path 9808"
        d="M16.424,5.562A4.563,4.563,0,0,0,7.552,7.031V9.2"
        fill="none"
        stroke="#130f26"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_9809"
        dataname="Path 9809"
        d="M15.933,21H8.292A3.793,3.793,0,0,1,4.5,17.207V12.92A3.793,3.793,0,0,1,8.292,9.127h7.641a3.793,3.793,0,0,1,3.792,3.793v4.288A3.793,3.793,0,0,1,15.933,21Z"
        fill="none"
        stroke="#130f26"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        fillRule="evenodd"
      />
      <path
        id="Path_9810"
        dataname="Path 9810"
        d="M12.113,13.953v2.222"
        fill="none"
        stroke="#130f26"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  </svg>
);

export const UnlockIcon = (props) => <Icon component={Unlock} {...props} />;


const Plus = () => (
  <svg 
  xmlns="http://www.w3.org/2000/svg" 
  width="16" 
  height="15.989" 
  viewBox="0 0 16 15.989">
    <g id="Group_2189" data-name="Group 2189" transform="translate(-15.583 -15.586)">
      <path 
        id="Fill_1" 
        dataname="Fill 1" 
        d="M1.359,15.989A1.359,1.359,0,0,1,0,14.631V1.359a1.359,1.359,0,0,1,2.717,0V14.631a1.359,1.359,0,0,1-1.359,1.359" 
        transform="translate(22.225 15.586)" 
        fill="#fff" />
      <path 
        id="Fill_3" 
        dataname="Fill 3" 
        d="M14.641,2.717H1.359A1.359,1.359,0,1,1,1.359,0H14.641a1.359,1.359,0,1,1,0,2.717" 
        transform="translate(15.583 22.219)" 
        fill="#fff" />
    </g>
  </svg>

)

export const PlusIcon = (props) => <Icon component={Plus} {...props} />

const Minus = () => (
  <svg 
  xmlns="http://www.w3.org/2000/svg" 
  width="16" 
  height="2.717" 
  viewBox="0 0 16 2.717">
  <path 
    id="Fill_3" 
    dataname="Fill 3" 
    d="M14.641,2.717H1.359A1.359,1.359,0,1,1,1.359,0H14.641a1.359,1.359,0,1,1,0,2.717" 
    fill="#fff"/>
  </svg>
)

export const MinusIcon = (props) => <Icon component={Minus} {...props} />


const AllPlatforms = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <g id="Group_2362" dataname="Group 2362" transform="translate(-147 -423)">
      <path
        id="Union_21"
        dataname="Union 21"
        d="M -76 -312 h 28 a 10 10 0 0 1 10 10 v 28 a 10 10 0 0 1 -10 10 H -76 a 10 10 0 0 1 -10 -10 v -28 A 10 10 0 0 1 -76 -312 Z M -62 -272 A 1 1 0 0 0 -62 -304 A 1 1 0 0 0 -62 -272"
        transform="translate(233 735)"
        fill="#86878c"
      />
    </g>
  </svg>
);

export const AllPlatformsIcon = (props) => <Icon component={AllPlatforms} {...props} />;

export const PlatfromIcon = (props) => {
  switch (props.platform) {
    case "facebook":
        return <FacebookIcon {...props}/>
    case "google":
        return <GoogleSearchIcon {...props}/>
    default:
        return null;
  }
}