import { ActorInfo } from "components/common/StandardActorCard"
import { useEffect, useState } from "react"
import useAPI, { callAPIProps } from "services/hooks/useAPI"

const checkPromoCodeEndpoint = (code:string):callAPIProps => ({
    url: ({ getApiUrl, projectId }) => getApiUrl(`seller/validate?code=${code}`),
})

type UsePartnerCodeCheckProps = {
    partnerCode: string;
    setPartnerCode: (partnerCode: string) => void;
}

type UsePartnerCodeCheck = {
    checkPartnerCode: (partnerCode: string) => void;
    partner: ActorInfo | null;
    partnerCode: string;
    loading: boolean;
    acceptPartner: () => void;
    inputCode: string;
    updateCode: (newCode: string) => void;
    error: boolean;
}

const adjustPartner = (partner: any) => {
    return {...partner, image: partner.profile, subtitle: "Partner"}

}

const usePartnerCodeCheck = (props:UsePartnerCodeCheckProps):UsePartnerCodeCheck => {

    const [inputCode, setInputCode] = useState<string>("");                 // input code
    const [codeCanditate, setCodeCanditate] = useState<string | null>("")   // valid code, not yet accepted
    const {partnerCode, setPartnerCode} = props                             // accepted code

    const [partner, setPartner] = useState<ActorInfo | null>(null)
    const [error, setError] = useState<boolean>(false)
    const checkCall = useAPI({})

    const resetCode = () => {
        setCodeCanditate(null);
        setPartner(null);
        setPartnerCode("");
        setInputCode("");
    }

    const loadCode = (code: string) => {
        //console.log("loading code", code)
        setCodeCanditate(code);
        setInputCode(code);
    }

    const partnerCodeIsCorrect = (code: string, newPartner: ActorInfo) => {
        //console.log("accepting code", code, partner)
        setPartnerCode(code);
        setInputCode(code);
        setPartner(adjustPartner(newPartner));
    }

    const checkPartnerCode = async (newPartnerCode: string) => {
        setError(false);
        setCodeCanditate(newPartnerCode);
        const checkRes = await checkCall.call(checkPromoCodeEndpoint(newPartnerCode))
        if (!checkRes || checkRes.status === 404) {
            resetCode();
            setError(true)
            return null
        } else if (checkRes.status === 200) {
            partnerCodeIsCorrect(newPartnerCode, checkRes.data)
            return checkRes.data
        }

    }

    const loadLocalCode = async () => {
        const so = localStorage.getItem("selectedOffer") ? JSON.parse(localStorage.getItem("selectedOffer") as string) : null;
        const lsPartner = so?.partnerCode || "";
        if (!lsPartner || !lsPartner.length) return;
        loadCode(lsPartner);
        await checkPartnerCode(lsPartner);
    }

    useEffect(() => {
        loadLocalCode();
    }, []);

    const updateCode = (newCode: string) => {
        setInputCode(newCode);
    }
    


    const acceptPartner = () => {
        //console.log("partner", partner, codeCanditate)
        if (partner && codeCanditate) {
            //console.log("accepting partner", partner, codeCanditate)
            setPartnerCode(codeCanditate)
            setInputCode(codeCanditate)
            return true;
        } 
        return false;
    }


    return {
        checkPartnerCode,
        partnerCode,
        acceptPartner,
        partner,
        loading: checkCall.loading,
        inputCode,
        updateCode,
        error
    }

}

export default usePartnerCodeCheck;