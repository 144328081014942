import React from "react";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import { composeWithDevTools } from "redux-devtools-extension";
import reducers from "services/store/reducers";
import middlewares, { middlewareArray } from "services/store/middlewares";
import { fromJS, isMap } from "immutable";
import _ from "lodash";
import { sentryReduxEnhancer } from "services/sentry";

const store = configureStore({
  reducer: reducers,
  middleware: middlewareArray,
  enhancers: [sentryReduxEnhancer],
})

function ReduxStore({ children }: { children: React.ReactNode }) {
  return (<Provider store={store}>{children}</Provider>);
}

export type RootState = ReturnType<typeof reducers>;
export type AppDispatch = typeof store.dispatch;

export default ReduxStore;
