import {createSlice, createSelector} from '@reduxjs/toolkit';

import produce from "immer";
import { LOAD_USER_SUCCESS } from 'modules/panel/actions/user/login';
import { currentProjectIdSelector } from 'services/store/scopeSlice';
import { industrySelector } from 'state/industries/industriesSlice';

export type CategoriesState = any;

const initialState: CategoriesState = {

}

export const CategoriesSlice = createSlice({
    name: "categories",
    initialState,
    reducers: {
        get: (state, action) => {
            
            state[action.payload.projectId] = action?.payload?.data?.categories?.map((c: any) => c.id);
        },
        update: (state, action) => {
            const before = action?.payload?.additional?.categories?.map((c:any) => c.id) || [];
            const after = action?.payload?.request?.categories || [];
            


            if (!state[action.payload.projectId]) state[action.payload.projectId] = [];

           // state[action.payload.projectId] = state[action.payload.projectId].filter((e:any) => !before.includes(e.id));
            //state[action.payload.projectId] = state[action.payload.projectId].concat(action?.payload.additional.categories);

            state[action.payload.projectId] = produce(state[action.payload.projectId], 
                (draft: any) => {
                    return draft.filter((e:any) => !before.includes(e.id)).concat(action?.payload.data)}
            )

        }
    },
    extraReducers: {
        [LOAD_USER_SUCCESS]: (state, action) => {
            if (!action?.payload?.projects) return; 
            for (const [key, value] of Object.entries(action.payload.projects)) {
                state[key] = (value as any)?.website?.website_object?.categories
            }
        }
    }
})

export const ProjectCategoriesSelector = createSelector([
    (state:any) => state.siteGenerator.categories,
    currentProjectIdSelector
],
(state:any, projectId:any) => state[projectId]
)

export const CategoriesAsObjectsSelector = createSelector([
    ProjectCategoriesSelector,
    (state:any, industry:any, parent: any) => (industrySelector as any)(state, industry),
],(selected, industry) => {

    if (!selected || !industry || !industry.list) return [];

    const combined:any[] = [];
    selected.forEach((t: any) => {
        const e = industry.list.find((i:any) => i.id === t);
        if (e) combined.push(e);
    })

    return combined;
})

export const SelectedCategoriesInCategorySelector = createSelector([
    ProjectCategoriesSelector as any,
    (state:any, industry:any, parent: any) => parent
],
    (categories: any, parent: string) => {

        if (!categories) return [];

        return categories.filter((c:any) => c && c.alias_path && c.alias_path[c.alias_path.length-1] === parent);

    }   
)

// export const SelectedCategoriesInCategorySelector = createSelector([
//     CategoriesAsObjectsSelector as any,
//     (state:any, industry:any, parent: any) => (industryCategorySelector as any)(state, industry, parent)
// ],
//     (categories: any, parent: any) => {

//         return categories.filter((c:any) => c.path[c.path.length-1] == parent.id)
//     }   
// )

export const UserHasFilledCategorySelector = createSelector([
    ProjectCategoriesSelector as any,
    (state:any, industry:any, category: any, min: number, max:number) => industry,
    (state:any, industry:any, category: any, min: number, max:number) => category,
    (state:any, industry:any, category: any, min: number, max:number) => min, 
    (state:any, industry:any, category: any, min: number, max:number) => max,
],
    (selected:any, industry:any, category: string, min: number, max:number) => {

      //  console.log(`Checking ${industry}/${category}, min: ${min}, max: ${max}:`, selected);

    
        if (!selected) return false;
        if (!industry) return false;
        if (!category) return false;

        const inThisCategory:any[] = selected.filter((c:any) => c && c.alias_path && c.alias_path[c.alias_path.length-1] === category);

        //console.log("In this category", inThisCategory)

        if (min > 0 && inThisCategory.length < min) {
          //  console.log("Below minimum");
        //  console.log(`Checking ${industry}/${category}, min: ${min}, max: ${max} --> Below Minimum`);
            return false;
        }

        if (max > 0 && inThisCategory.length > max) {
          //  console.log("Above maximum");
        //  console.log(`Checking ${industry}/${category}, min: ${min}, max: ${max} --> Above Maximum`);
            return false;
        }

        //console.log("OK");
      //  console.log(`Checking ${industry}/${category}, min: ${min}, max: ${max} --> OK`);
        return true;
    }
)


export default CategoriesSlice.reducer;