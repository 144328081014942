import LanguageSelect from "_components/forms/language-select";
import PhoneNumberInputWithCountrySelect from "_components/forms/PhoneNumberInputWithCountrySelect";
import { Input } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import { userSelector } from "modules/panel/config/selectors/user";
import { callAPIProps } from "services/hooks/useAPI";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";
import RelaxSingleField from "tools/relaxForm/RelaxField";

const PatchUser:callAPIProps = {
    url: ({getApiUrl}) => getApiUrl("user", 1),
    method: "PUT",
    dispatchName: "USER_DATA_SET",
    customNormalizer: (value) => {
        return {user: value.user};
    }
}

export default function UserProfile () {
    
    return (
        <RelaxMultiform
            name="userProfile"
            callAPI={PatchUser}
            initialValuesSelector={userSelector}
            >
                <RelaxSingleField
                    name="first_name"
                    label={<FormattedMessage 
                        id="panel.user.profile.firstName.label"
                        defaultMessage="First name"
                    />}
                    >
                        <Input />
                </RelaxSingleField>
                <RelaxSingleField
                    name="last_name"
                    label={<FormattedMessage
                        id="panel.user.profile.lastName.label"
                        defaultMessage="Last name"
                    />}
                    >
                        <Input />
                </RelaxSingleField>
                <RelaxSingleField
                    name="email"
                    label={<FormattedMessage
                        id="panel.user.profile.email.label"
                        defaultMessage="Email"
                    />}
                    disabled
                    >
                        <Input />
                </RelaxSingleField>
                <RelaxSingleField
                    name="phone"
                    label={<FormattedMessage
                        id="panel.user.profile.phone.label"
                        defaultMessage="Phone"
                    />}
                    >
                        <PhoneNumberInputWithCountrySelect />
                </RelaxSingleField>
                <RelaxSingleField
                    name="language"
                    label={<FormattedMessage
                        id="panel.user.profile.language.label"
                        defaultMessage="Language"
                    />}
                    >
                        <LanguageSelect />
                </RelaxSingleField>
            </RelaxMultiform>
    )

}