import FormattedMessage from "components/common/FormattedMessage";
import StandardButton from "components/common/StandardButton";
import { ReactNode } from "react";

type WizardButtonsType = {
    canGoForward?: boolean | "hidden" | "disabled" | "loading",
    canGoBack?: boolean | "hidden" | "disabled" | "loading",
    onNext?: () => void,
    onBack?: () => void,
    nextLabel?: ReactNode,
    backLabel?: ReactNode,
    nextPrimary?: boolean,
    children?: ReactNode,
}

const WizardButtons = (props: WizardButtonsType) => {

    const { canGoForward, canGoBack, onNext, onBack } = props;

    const showNext = canGoForward !== "hidden";

    const backButton = () => {

        const showBack = canGoBack !== "hidden";

        if (!showBack) return <div></div>;

        const label = props.backLabel ? props.backLabel : (
            <FormattedMessage
                id="common.back"
                defaultMessage="Back" />
        )

        return (
            <StandardButton 
                onClick={onBack}
                disabled={canGoBack === "disabled" || canGoBack === false}
                loading={canGoBack === "loading"}
            >
                {label}
            </StandardButton>
        )

    }

    const nextButton = () => {

        if (!showNext) return <div></div>;

        const label = props.nextLabel ? props.nextLabel : (
            <FormattedMessage
                id="common.next"
                defaultMessage="Next" />
        )

        return (
            <StandardButton 
                onClick={onNext}
                disabled={canGoForward === "disabled" || canGoForward === false}
                loading={canGoForward === "loading"}
                type={props.nextPrimary ? "primary" : "default"}
            >
                {label}
            </StandardButton>
        )

    }

    return (
        <div style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px"
        }}>
            {backButton()}
            {props.children}
            {nextButton()}
        </div>
    )

}

export default WizardButtons;