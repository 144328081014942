import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons"
import { Tooltip } from "antd"
import AwesomeIcon from "components/common/AwesomeIcon"
import FormattedMessage from "components/common/FormattedMessage";
import StandardButton from "components/common/StandardButton";
import { ReactNode, useMemo } from "react";
import { useHelpPanel } from "tools/helpPanel/HelpPanelDrawer";


export const StandardTooltip = (props: { title: ReactNode, link?: string, color?: string, icon?:any}) => {

  const { openHelpPanel } = useHelpPanel();

  const link = () => {
      if (props.link) return props.link;
      if (typeof props.title === "object") return (props?.title as any)?.props?.id;
      return props.title;
  }

  const handleOpenHelper = (e) => {
    e.preventDefault();
    e.stopPropagation();
    openHelpPanel(link(), props.title);
  };

  return (
    <Tooltip title={props.title}>
      <AwesomeIcon 
        onClick={handleOpenHelper}
        icon={props.icon || faQuestionCircle} size="xl" color={props.color || "grey"} />
    </Tooltip>
  );
};