import React from "react";
import { ReactNode } from "react";
import { Skeleton, Badge } from "antd";
import products, { Product } from "products";
import Overlay from "_components/Overlay";

type Props = {
  name?: string;
  style?: React.CSSProperties;
};

const SmallProductLogo = (props:Props) => (
    <img
    className="logo-small"
    style={props.style}
    src={getProductLogo(props.name as Product)}
    />
);

export const getProductLogo = (productName: Product) =>
  products[productName] ? products[productName].logo : "";

export default SmallProductLogo;
