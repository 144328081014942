import useSelectorWithParams from "./useSelectorWithParams";
import { useDispatch, useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import { ProductLocalStorage, ProjectLocalStorage, SubscriptionLocalStorage } from "services/helpers/local-storage";
import { projectValidSubscriptionsSelector, subscriptionSelector } from "state/subscriptions/subscriptionsSelectors";
import { currentProductSelector, currentProjectIdSelector, currentSubscriptionIdSelector } from "services/store/scopeSlice";
import { projectByIdSelector } from "state/projects/projectSelectors";

const DEFAULT_ROUTE = "/smart/dashboard";

export const isSubscriptionValid = (subscription: any) => {
   // console.log("Checking if subscription is valid", subscription)
    if (!subscription) return false;

    //inactive or expired 
    if (subscription.status === "cancelled" || subscription.status === "canceled") {
        const isStillValid = new Date(subscription.ends_at) > new Date();
        if (!isStillValid) return false;
    } else if (subscription.status !== "active") return false;

    //no roles
    if (!subscription.roles || subscription.roles.length === 0) return false;

    //no projects
    if (!subscription.projects || subscription.projects.length === 0) return false;

    return true;
}

const getValidSubscriptions = (project: any) => {
    if (!project) return [];
    if (!project.product_subscriptions) return [];
    return project.product_subscriptions.filter(isSubscriptionValid);
}

const hasValidSubscriptions = (project: any) => {
    return getValidSubscriptions(project).length > 0;
}

export const canLoadSubscription = (subscription: any) => {
    if (!subscription) return false;
    if (subscription.status === "active") return true;
    if (subscription.status === "cancelled" || subscription.status === "canceled") {
        return new Date(subscription.ends_at) > new Date();
    }
    return false;
}

function useScope() {

    const projectId = useSelectorWithParams(currentProjectIdSelector);
    const subscriptionId = useSelectorWithParams(currentSubscriptionIdSelector);
    const product = useSelectorWithParams(currentProductSelector);
    const store = useStore();
    const history = useHistory();

    const dispatch = useDispatch();

    const changeScope = (newProduct: string, newProject: string | null, newSubscription: string | null, goTo?:string) => {

        dispatch({type: "SET_APP_CONTEXT", payload: {
            product: newProduct,
            projectId: newProject,
            subscriptionId: newSubscription
        }})

        if (newProduct) {
            localStorage.setItem("product", newProduct);
            ProductLocalStorage.set(newProduct as any);
        } else {
            localStorage.removeItem("product");
        }

        if (newProject) {
            localStorage.setItem("project", newProject.toString());
            ProjectLocalStorage.set(newProject as any);
        } else {
            localStorage.removeItem("project");
        }

        if (newSubscription) {
            localStorage.setItem("subscription", newSubscription.toString());
            SubscriptionLocalStorage.set(newSubscription as any);
        } else {
            localStorage.removeItem("subscription");
        }


        if (goTo && goTo.length > 0) {
            console.log("Changing route to", goTo);
            history.push(goTo);
        }   
        
    }

    const openSubscription = (subscriptionId: number, goTo?: string) => {
        const newSub = subscriptionSelector(subscriptionId)(store.getState());

        if (!newSub) {
            console.warn(`Attempted to open subscription ${subscriptionId} but it was not found.`);
            return;
        }

        const newProduct = newSub.product;

        if (!newProduct) {
            console.warn(`Attempted to open subscription ${subscriptionId} but it has no product.`);
            return;
        }

        const newProject = newSub.project;

        if (!newProject) {
            console.warn(`Attempted to open subscription ${subscriptionId} but it has no project.`);
            return;
        }

        changeScope(newProduct, newProject, subscriptionId, goTo);

    }

    const openNewSubscription = (subscriptionId: string) => {
        if (projectId) {
            changeScope(product, projectId, subscriptionId)
        } else {
            changeScope(product, null, subscriptionId)
        }
    }

    const openProject = (newProjectId: string, goTo?: string) => {

        //const p = useSelectorWithParams(projectByIdSelector(projectId));
        const p = projectByIdSelector(newProjectId)(store.getState());
        const newProject = p ? p : null;

        if (!newProject) {
            console.warn(`Attempted to open project ${newProjectId} but it was not found.`);
            return;
        }


        const validSubscriptions = projectValidSubscriptionsSelector(newProjectId)(store.getState());


        if (validSubscriptions.length === 0) {
            console.warn(`Attempted to open project ${newProjectId} but it has no valid subscriptions. Going to the store.`);


            const newProduct = product || import.meta.env.VITE_INSTANCE_DEFAULT_PRODUCT;
    
            changeScope(newProduct, newProjectId, null, goTo);

            return;
        }

        const newSubscription = validSubscriptions[0];

        const newProduct = newSubscription.product;

        changeScope(newProduct, newProjectId, newSubscription.id, goTo);


    }

    const openProjectWithProduct = (projectId: string, product: string, goTo?: string) => {
        
        //const p = useSelectorWithParams(projectByIdSelector(projectId));
        const p = projectByIdSelector(projectId)(store.getState());
        const newProject = p ? p : null;

        if (!newProject) {
            console.warn(`Attempted to open project ${projectId} but it was not found.`);
            return;
        }


        const newProduct = product;
        const newSubscription = subscriptionId;

        changeScope(newProduct, projectId, newSubscription);
    }

    const loadProductScope = (newProduct: string) => {

        dispatch({type: "SET_APP_CONTEXT", payload: {
            product: newProduct,
            projectId: null,
            subscriptionId: null
        }})
    }

    return ({
        projectId,
        subscriptionId,
        product,
        openSubscription,
        openNewSubscription,
        openProject,
        openProjectWithProduct,
        changeScope,
        loadProductScope
    });
}

export default useScope;