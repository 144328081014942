import WebsiteTheme from "modules/site-generator/forms/WebsiteTheme";
import { UserHasFilledWebsiteTheme } from "modules/site-generator/reducers/websiteSlice";
import OnboardingControls from "providers/onboarding/controls";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";

const OnboardingWebsiteTheme = () => {

    const fullfilled = useSelectorWithParams(UserHasFilledWebsiteTheme)

    return (
        <>
            <WebsiteTheme/>
            <OnboardingControls nextDisabled={!fullfilled}/>
        </>
    )

}

export default OnboardingWebsiteTheme;
