import FormattedMessage from "components/common/FormattedMessage";
import { CarserviceBrands, CarserviceManufacturers, CarserviceTires } from "../websitePages/CarserviceBrandsAndManufacturers";
import { Title } from "components/common/StandardText";
import { Collapse } from "antd";

export const BrandsAndManufacturers = () => {

    return (
        <Collapse>
            <Collapse.Panel
                header={<FormattedMessage
                    id="apps.websites.forms.brands.title"
                    defaultMessage="Brands"
                />}
                key="brands"
            >
                <CarserviceBrands />
            </Collapse.Panel>
            <Collapse.Panel
                header={<FormattedMessage
                    id="apps.websites.forms.manufacturers.title"
                    defaultMessage="Manufacturers"
                />}
                key="manufacturers"
            >
                <CarserviceManufacturers />
            </Collapse.Panel>
            <Collapse.Panel
                header={<FormattedMessage
                    id="apps.websites.routes.tires"
                    defaultMessage="Tires"
                />}
                key="tires"
            >
                <CarserviceTires />
            </Collapse.Panel>
        </Collapse>
    )


}