import { Col } from "antd"
import { Title } from "components/common/StandardText"
import { StandardHalfFullColumn, StandardRow } from "components/layout/StandardGrid"
import { userHasFlagSelector, userSelector } from "modules/panel/config/selectors/user"
import useSelectorWithParams from "services/hooks/useSelectorWithParams"
import { currentProductSelector } from "services/store/scopeSlice"

const ALT = "https://www.chatbase.co/chatbot-iframe/0IsD2S5Vlc5sEVPe7HRgq"
const DEFAULT = "https://www.chatbase.co/chatbot-iframe/n7E303md7dsAvpp958tAJ"


export default function AiAssistant() {

    const hasSecretFeature = useSelectorWithParams([userHasFlagSelector, "secretPoliticalBook"])
    const product = useSelectorWithParams(currentProductSelector);


    if (hasSecretFeature || product === "PostLite") {
        return (
            <StandardRow>
                <StandardHalfFullColumn>
                    <div className="standard-border" style={{
                        height: "80vh",
                        backgroundColor: "white"
                    }}>
                        <iframe
                            src={ALT}
                            width="100%"
                            height="100%"
                            frameborder="0"
                        >
                        </iframe>
                    </div>
                </StandardHalfFullColumn>
                <Col xs={0} sm={0} md={0} lg={12} xl={12} xxl={12}>
                    <div className="standard-border" style={{
                        backgroundColor: "white",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <img
                            src="/images/wyboryKsiazka.png"
                            style={{
                                width: "80%"
                            }} />
                        <Title level={3}> Zbudowane na podstawie prac i książek dr. Sergiusza Trzeciaka</Title>
                    </div>
                </Col>
            </StandardRow>
        )
    }

    return (
        <StandardRow>
            <StandardHalfFullColumn>
                <div className="standard-border" style={{
                    height: "80vh",
                    backgroundColor: "white"
                }}>
                    <iframe
                        src={DEFAULT}
                        width="100%"
                        height="100%"
                        frameborder="0"
                    >
                    </iframe>
                </div>
            </StandardHalfFullColumn>
            <Col xs={0} sm={0} md={0} lg={12} xl={12} xxl={12}>
                <div className="standard-border" style={{
                    backgroundColor: "white",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <video controls width="500px">
                        <source src={'https://cdn.fasttony.com/post/chatTutorial.mp4'} type="video/mp4" />
                    </video>
                </div>
            </Col>
        </StandardRow>
    )

}

