import _ from "lodash";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import useEffectDeepCompare from "services/hooks/useEffectDeepCompare";
import useEffectNotFirst from "services/hooks/useEffectNotFirst";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import useValueWithOverride from "services/hooks/useValueWithOverride";
import { useRelaxContainer } from "./RelaxContainerContext";
import { useRelaxMultiform } from "./RelaxMultiform";
import { validateValue } from "./ruleEvaluation";
import useMemoDeepCompare from "services/hooks/useMemoDeepCompare";
import useRelaxAPIHandler from "./useRelaxAPIHandler";
import { RelaxDataProps, RelaxDataType } from "./relaxTypes";
import useCallAfterUpdate from "services/hooks/useCallAfterUpdate";

const useInitialValue = (settings: any) => {

    const name = settings.name;
    const [initialValue, setInitialValue] = useState<any>(null);
    const [initialValueObject, setInitialValueObject] = useState<any>(null);



    const initialValueObjectFromSelector = useSelectorWithParams(settings.initialValuesSelector, {
        callAPIIfNull: settings.callAPItoGetData
    });

    useEffectDeepCompare(() => {
        if (initialValueObjectFromSelector) {
            setInitialValueObject(initialValueObjectFromSelector);
        }
    }, [initialValueObjectFromSelector])

    const safeInitialValueSetter = (v: any) => {
        setInitialValue((iv:any) => {
            if (_.isEqual(iv, v)) return iv;
            return v;
        })
    }

    useEffect(() => {

        try {

            //console.log("Initial values changed", settings.initialValues, initialValueObjectFromSelector, settings.shouldReset)

            let iv = null;

            const selectorValue = initialValueObjectFromSelector ? _.get(initialValueObjectFromSelector, name, "") : null;
            const propValue = settings.initialValue || (settings.initialValues ? _.get(settings.initialValues, name, "") : null);


            if (settings.adjustInitialValue) {
                iv = settings.adjustInitialValue(selectorValue, propValue);
            } else if (selectorValue) {
                iv = selectorValue;
            } else if (propValue) {
                iv = propValue;
            }

            if (_.isEqual(iv, initialValue)) {
                //console.log("Object changed, but value the same")
                return;
            }


            //Default - reset only if undefined
            if (settings.shouldReset === undefined) {
                if (initialValue === undefined || initialValue === null) {
                    //console.log("Resetting: ResetIfNoValue")
                    safeInitialValueSetter(iv);
                    return;
                }
                return;
            }

            if (typeof settings.shouldReset === "function") {
                if (settings.shouldReset(iv, initialValue)) {
                    //console.log("Resetting: shouldReset function returned true")
                    safeInitialValueSetter(iv);
                    return;
                } else {
                    //console.log("Not resetting: shouldReset function returned false")
                    return;
                }
            } else if (settings.shouldReset === true) {
                // console.log("Resetting: TRUE")
                safeInitialValueSetter(iv);
                return;
            } else if (settings.shouldReset === false) {
                //console.log("Not resetting: FALSE")
                return;
            } else if (settings.shouldReset === "ResetIfNoValue") {
                if (initialValue === null || initialValue === undefined) {
                    //console.log("Resetting: ResetIfNoValue")
                    safeInitialValueSetter(iv);
                    return;
                } else {
                    //console.log("ResetIfNoValue: ", initialValue, iv)
                    return;
                }
            }

                // //console.log("No Should reset, using default");
                // if (!_.isEqual(iv, initialValue)) safeInitialValueSetter(iv);
            

        } catch (e) {
            //console.error("Error getting initial value for field", name, e);
            safeInitialValueSetter(null)
        }

    }, [settings.initialValues, initialValueObjectFromSelector, name, settings.adjustInitialValue, settings])


    return {
        fieldInitialValue: initialValue,
        initialValueObject,
        setInitialValue
    };

}

export default function useRelaxData (props?:RelaxDataProps):RelaxDataType {

    const { multiformProps } = useRelaxMultiform();

    const parentContext = useRelaxContainer();
    const {settings: parentSettings} = parentContext;
    const hasParentContext = parentContext && Object.getOwnPropertyNames(parentContext).length > 0;

    const [backFilledProps, setBackFilledProps] = useState<any>(null);

    const settings = useMemoDeepCompare(() => {

        const s = {
            ...multiformProps,
            ...parentSettings,
            ...backFilledProps,
            ...props,
        }

        if (s.required) {
            s.rules = s.rules || [];
            s.rules.push({required: true});
        }
        
        if (!s.name) s.name = "";

        return s;

    }, [multiformProps, props, parentSettings, backFilledProps]);

    const name = settings.name;

    const [currentValue, setCurrentValue] = useState<any>(null);
    const [optimisticValue, setOptimisticValue] = useState<any>(null);


    const [errors, setErrors] = useState<ReactNode[] | null>(null);
    const {value: touched, setValue: setTouched} = useValueWithOverride<boolean>(props?.touched !== undefined ? props.touched : null);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [extraStatus, setExtraStatus] = useState<any>(null);

    const {initialValueObject, fieldInitialValue, setInitialValue} = useInitialValue(settings);
    const loadedInitialValue = useRef(false);

    const changed = !_.isEqual(currentValue, optimisticValue);

    const validate = () => {
        const err = validateValue(currentValue, settings.rules);
        setErrors(err);
        return err;
    }

    const hasErrors = useMemo(() => {
        return (errors || []).length > 0 || (settings.extraErrors || []).length > 0
    }, [errors, settings.extraErrors]);

    useEffectNotFirst(() => {
        reset("initial")
    }, [fieldInitialValue]);

    useEffectNotFirst(() => {
        validate();
    }, [currentValue]);

    const validateAfterUpdate = useCallAfterUpdate(validate);

    useEffectNotFirst(() => {
        //console.log("Errors changed", errors)
        settings.onErrors && settings.onErrors(errors);
        if (hasParentContext && parentContext.handleFieldErrors) parentContext.handleFieldErrors(name, hasErrors);
    }, [errors]);

    useEffectNotFirst(() => {
        settings.onTouch && settings.onTouch(touched);
    }, [touched]);

    useEffectNotFirst(() => {
        settings.onChangesMade && settings.onChangesMade(changed);
    }, [changed]);

    const reset = (reason?: string) => {
       // console.log("resetting", settings.name, "to", fieldInitialValue, " reason:", reason)
        loadedInitialValue.current = true;
        setCurrentValue(fieldInitialValue);
        setOptimisticValue(fieldInitialValue);
        setErrors(null);
        setTouched(false);
        validateAfterUpdate();
    }


    const onSend = (data: any) => {
    //    console.log("OnSend", currentValue)
        setOptimisticValue(_.cloneDeep(currentValue));
       // sentValue.current = _.cloneDeep(currentValue);
    }

    const onSaved = (res: any, data: any) => {

    }

    const apiHandler = useRelaxAPIHandler({
        settings, 
        currentValue, 
        onSaved,
        onSend,
    });



    const canSendStatus:string | true = (() => {

        if (settings.disableSubmit) return "disabled";
        if (!changed) return "unchanged";
        if (hasErrors) return "hasErrors";
        if (extraStatus) return extraStatus;
        
        return true;

    })()
    

    const canSend = canSendStatus === true;


    const submit = async (mode: string) => {

      //  console.log("Submit", mode, currentValue, canSend)

        if (!canSend && ( mode !== "manual" && mode !== "force")) return false;

        //console.log("trying to submit", mode, currentValue, canSend ? "can send" : "cannot send")

        const loading = apiHandler.loading;

        //Simple queue system - if a request is already in progress, next request is stored. 

        if (loading) {

            apiHandler.resendRequest.current = currentValue;
            return true;
        } else {
            return await apiHandler.requestCall(currentValue);
        }

        return true;

    }

    
    const submitAfterUpdate = useCallAfterUpdate(submit);

    return {
        extraStatus,
        setExtraStatus,
        errors,
        setErrors,
        validate,
        currentValue,
        setCurrentValue,
        initialValue: fieldInitialValue, 
        reset,
        settings,
        hasErrors,
        changed, 
        touched, 
        setTouched,
        parentContext,
        hasParentContext,
        initialValueObject,
        name,
        fieldIdPrefix: settings.fieldIdPrefix || "",
        isFocused,
        setIsFocused,
        optimisticValue,
        setOptimisticValue,
        setInitialValue,
        apiHandler,
        submit: submitAfterUpdate,
        backFilledProps,
        setBackFilledProps,
        canSend,
        canSendStatus,
    }

}