import { createContext, forwardRef, ReactNode, useContext, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { findNextStep } from "./WizardHelperHooks";


export type SimpleWizardStep = {
    name: string;
    component: ReactNode;
}

export type SimpleWizardContext = {
    step: string | null;
    stepIndex: number;
    steps: SimpleWizardStep[] | undefined;
    goToStep: (newStep?: string | number) => void; //Navigate to this step
    closeWizard: () => void; //Close the wizard
}

export type SimpleWizardProps = {
    steps: SimpleWizardStep[];
    children?: ReactNode;
    onStepChanged?: (step: string) => void;
    onWizardStart?: () => void;
    onWizardComplete?: () => void;
    onWizardClose?: () => void;
}

export type SimpleWizardRef = SimpleWizardContext;

const SimpleWizardContext = createContext<SimpleWizardContext>({} as SimpleWizardContext);
SimpleWizardContext.displayName = "Simple Wizard Context";
export const useWizard = () => useContext(SimpleWizardContext);

const SimpleWizard = forwardRef<SimpleWizardRef, SimpleWizardProps>(({steps, ...props}, ref) => {

    const [step, setStep] = useState<string | null>(null);

    const currentStep:ReactNode = useMemo(() => {
        if (!step) return null;
        return steps.find((s) => s.name === step)?.component;
    }, [step, steps]);

    const stepIndex = steps.findIndex((s) => s.name === step);

    // useEffect(() => console.log("Step", step), [step]);
    // useEffect(() => console.log("Steps updated", steps), [steps]);

    const goToStep = (newStep?: string | number) => {
        if (!steps) return;
        if (typeof newStep === "number") {

            console.log("Finding next step", newStep, steps.length);

            if (newStep < 0 || newStep >= steps.length) return;
            setStep(steps[newStep].name);
            return;
        } else if (!newStep) {
            const currentIndex = steps.findIndex((s) => s.name === step);

          //  console.log("Current Index", currentIndex);

            if (currentIndex === -1) {
                setStep(steps[0].name);
                return;
            }
            const nextIndex = currentIndex + 1;

            if (nextIndex >= steps.length) {
                endWizard();
                return;
            } else {
                setStep(steps[nextIndex].name);
                return;
            }
        }

        setStep(newStep);
    }

    const endWizard = () => {
    //    console.log("Ending Wizard");
        setStep(null);
        props.onWizardComplete && props.onWizardComplete();
        props.onWizardClose && props.onWizardClose();
    }

    useEffect(() => {
        if (steps && steps.length > 0 && !step) setStep(steps[0].name);
    }, []);


    const providedValue: SimpleWizardContext = {
        step,
        stepIndex,
        steps,
        goToStep,
        closeWizard: endWizard
    }

    useImperativeHandle(ref, () => providedValue);

    return (
        <SimpleWizardContext.Provider value={providedValue}>
            {currentStep}
            {props.children}
        </SimpleWizardContext.Provider>
    )

})

export default SimpleWizard;