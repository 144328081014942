import useAPI from "services/hooks/useAPI";
import {
  GallerySelector,
  GetGalleryObject,
  UpdateGalleryGroup,
} from "../reducers/gallerySlice";
import RelaxField from "tools/relaxForm/RelaxField";
import Gallery from "tools/gallery/Gallery";
import { Col, Row } from "antd";
import { Title } from "components/common/StandardText";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import FormattedMessage from "components/common/FormattedMessage";

const galleryTitleDictionary = {
  inside: (
    <FormattedMessage
      id="apps.websites.containers.gallery.title.inside"
      defaultMessage="Inside"
    />
  ),
  outside: (
    <FormattedMessage
      id="apps.websites.containers.gallery.title.outside"
      defaultMessage="Outside"
    />
  ),
  at_work: (
    <FormattedMessage
      id="apps.websites.containers.gallery.title.atWork"
      defaultMessage="At work"
    />
  ),
  logo: (
    <FormattedMessage
      id="apps.websites.containers.gallery.title.logo"
      defaultMessage="Logo"
    />
  ),
  team: (
    <FormattedMessage
      id="apps.websites.containers.gallery.title.team"
      defaultMessage="Team"
    />
  ),
  certificate: (
    <FormattedMessage
      id="apps.websites.containers.gallery.title.certificate"
      defaultMessage="Certificate"
    />
  ),
};

export const SiteGeneratorGalleries = () => {
  const getGalleriesCall = useAPI(GetGalleryObject, true);
  const galleries = useSelectorWithParams(GallerySelector);

  if (getGalleriesCall.loading) return getGalleriesCall.StatusDisplay;

  return (
    <>
      {galleries &&
        Object.keys(galleries).map((galleryKey, index) => (
          <div key={galleryKey} className="gallery gallery-row">
            <Title level={4}>
              {galleryTitleDictionary[galleryKey] || galleryKey}
            </Title>
            <Row justify="start">
              <Col>
                <RelaxField
                  name={galleryKey}
                  callAPI={UpdateGalleryGroup(galleryKey)}
                  initialValues={galleries}
                  finalizeSubmit={(data: any) => {
                    return {
                      media: data[galleryKey],
                      group: galleryKey,
                    };
                  }}
                >
                  <Gallery
                    allowedTools={["crop", "delete"]}
                    allowedTypes={["image"]}
                  />
                </RelaxField>
              </Col>
            </Row>
          </div>
        ))}
    </>
  );
};
