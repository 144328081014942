import { createContext, useContext, ReactNode, useState, useEffect } from "react";
import PaymentMethodSelect from "./PaymentMenthodSelect";
import { useStripeCardInput } from "./StripeCardInput";
import StandardButton from "components/common/StandardButton";
import StripeContext from "providers/stripe/StripeContext";
import useAPI, { APIErrorDisplay, callAPIProps } from "services/hooks/useAPI";
import { Alert, Form } from "antd";
import { HorizontalCenter } from "components/common/Center";
import FormattedMessage from "components/common/FormattedMessage";

type PaymentState = "loading" | "user" | "ready" | "newCard" | "newCardFailed" | "confirmingPayment" | "paymentComplete" | "paymentFailed";

type PaymentContextProps = {

    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
    submitPayment: () => Promise<void>;

    newCardStatus: boolean;
    setNewCardStatus: (newCardStatus: boolean) => void;

}

const PaymentContext = createContext<PaymentContextProps>({});
PaymentContext.displayName = "PaymentContext";
export const usePayment = () => useContext(PaymentContext);

type PaymentProviderProps = {
    children?: ReactNode;
    onCancel?: () => void;
    onComplete?: (data: any) => void;
    onError?: (error: string) => void;
    paymentCall?: callAPIProps;
    canPay?: boolean;
    showCancel?: boolean;
    paymentButtonLabel?: ReactNode;
    hideButtons?: boolean;
}

const PaymentProvider = ({ children, ...props }: PaymentProviderProps) => {


    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<ReactNode | null>("");
    const [state, setState] = useState<PaymentState>("loading");
    const [paymentMethodOk, setPaymentMethodOk] = useState(false);

    const purchaseCall = useAPI({});
    const { ConfirmPayment } = useStripeCardInput();


    const handlePaymentError = (_error: ReactNode, _state?: PaymentState) => {
        setIsLoading(false);
        setError(_error);
        if (_state) {
            setState(_state);
        } else {
            setState("paymentFailed");
        }
        props.onError && props.onError(typeof _error === "string" ? _error : "ERROR");
    }

    const handlePaymentComplete = (successData: any) => {
        setIsLoading(false);
        setState("paymentComplete");
        setError(null);
        props.onComplete && props.onComplete(successData);
    }

    const canPurchase = (() => {
        if (!props.canPay) return false;
        if (!paymentMethodOk) return false;
        return true;
    })()

    const handlePayment = async () => {

        if (!canPurchase) return;
        if (!props.paymentCall) return;


        setIsLoading(true);
        const purchaseCallRes = await purchaseCall.call(props.paymentCall);

        if (purchaseCallRes && purchaseCallRes.status === 200) {
            if (purchaseCallRes.data.payment_intent) {
                setState("confirmingPayment");
                const [confirmation, confirmationError] = await ConfirmPayment(purchaseCallRes.data.payment_intent);
                if (confirmationError) {
                    if (typeof confirmationError === "string") {
                        return handlePaymentError(confirmationError);
                    } else {
                        return handlePaymentError(confirmationError?.message || "STRIPE_ERROR");
                    }
                } else {
                    return handlePaymentComplete(purchaseCallRes?.data);
                }
            } else {
                return handlePaymentComplete(purchaseCallRes?.data);
            }
        } else {

            const e = purchaseCallRes?.error?.errors?.[0]?.message

            return handlePaymentError((<APIErrorDisplay error={purchaseCallRes.error} />) || "PURCHASE_ERROR");
        }

    }

    const value = {
        handlePayment,

        isLoading,
        setIsLoading,

        paymentMethodOk,
        setPaymentMethodOk,

    }



    const showDebug = (window as any).debugPayment
    //const showDebug = true;

    return (
        <PaymentContext.Provider value={value}>
            {showDebug && (
                <ul style={{
                    position: "fixed",
                    top: 0,
                    left: 200,
                    backgroundColor: "lightgray",
                    fontSize: "8px"
                }}>
                    <li>State: {state}</li>
                    <li>PaymentMethodOk: {paymentMethodOk ? "true" : "false"}</li>
                    <li>CanPurchase: {canPurchase ? "true" : "false"}</li>
                </ul>
            )}
            {children}
            {error && <Alert message={error} type="error" />}
            {props.hideButtons !== true && (<div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
            }}>
                <StandardButton
                    onClick={() => props.onCancel && props.onCancel()}
                >
                    <FormattedMessage
                        id="common.cancel"
                        defaultMessage="Cancel"
                    />
                </StandardButton>
                <StandardButton
                    type="primary"
                    onClick={handlePayment}
                    disabled={!canPurchase}
                    loading={isLoading}
                >
                    {
                        props.paymentButtonLabel || <FormattedMessage id="common.pay" defaultMessage="Purchase" />
                    }
                   
                </StandardButton>
            </div>)}
        </PaymentContext.Provider>
    );
};

const Payment = ({ children, ...props }: PaymentProviderProps) => {
    return (
        <StripeContext>
            <PaymentProvider {...props}>
                {children}
            </PaymentProvider>
        </StripeContext>
    );
}

export default Payment;