import Checkbox from "components/inputs/Checkbox"
import _ from "lodash";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import useUserSettings from "services/hooks/useUserSettings";
import { userSettingsInterfaceSelector } from "state/user/userSettingsSlice";


type SettingsCheckboxProps = {
    message: React.ReactNode;
    path: string[];
};

export const InfinityListSettingsCheckbox: React.FC<SettingsCheckboxProps> = (props) => {
    const { updateInterfaceSettings } = useUserSettings();
    const interfaceSettings = useSelectorWithParams(userSettingsInterfaceSelector);

    const optionPath =[...props.path]
    const handleCheckboxClick = (e: any) => {

        const updatedSettings: any = _.cloneDeep(interfaceSettings)
        _.set(updatedSettings,optionPath,e.target.checked)
        
        updateInterfaceSettings(updatedSettings);
    };

    return (
        <Checkbox
            onClick={handleCheckboxClick}
            checked={_.get(interfaceSettings,optionPath,true)}
        >
            {props.message}
        </Checkbox>
    );
};