import React from "react";
import { Select } from "antd";
import FormattedMessage from "components/common/FormattedMessage";

const CurrencySelect = (props: Record<string, unknown>) => (
  <Select
    {...props}
    placeholder={
      <FormattedMessage
        id="apps.forsant.components.currencyInput.placeholder"
        defaultMessage="Choose currency"
      />
    }
  >
    <Select.Option value="EUR">
      <FormattedMessage
        id="apps.forsant.components.currencyInput.eur"
        defaultMessage="Euro"
      />
    </Select.Option>
    <Select.Option value="PLN">
      <FormattedMessage
        id="apps.forsant.components.currencyInput.pln"
        defaultMessage="Polish zloty"
      />
    </Select.Option>
  </Select>
);

export default CurrencySelect;
