export const createErrorSelector = (action) => (state) =>
  state.requestStatuses.error.get(action)
    ? state.requestStatuses.error.get(action)
    : false;

export const createLoadingSelector = (action) => (state) =>
  state.requestStatuses.loading.get(action)
    ? state.requestStatuses.loading.get(action)
    : false;

export const createSuccessSelector = (action) => (state) =>
  state.requestStatuses.success.get(action)
    ? state.requestStatuses.success.get(action)
    : false;
