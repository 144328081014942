import { faBug, faCamera, faCheckCircle, faEllipsis, faPenCircle, faPenSquare, faTrash, faTrashCircle } from "@fortawesome/pro-light-svg-icons"
import { Divider, Dropdown, Input, MenuProps, Typography } from "antd"
import AwesomeIcon from "components/common/AwesomeIcon"
import { HorizontalCenter } from 'components/common/Center';
import StandardAvatar from "components/common/Avatar/StandardAvatar"
import StandardButton from "components/common/StandardButton"
import DateTimeDisplay from "components/displays/DateTimeDisplay"
import { ReactNode, useMemo } from "react"
import FormattedMessage from "components/common/FormattedMessage";
import { StandardModal, useStandardModal } from "services/hooks/useModal"
import useSimpleBreakpoint from "services/hooks/useSimpleBreakpoint"
import { CreationStatusString } from "./CreationConfig"
import "./creations.less"
import useSelectorWithParams from "services/hooks/useSelectorWithParams"
import { Creation, creationSelector, getCreations } from "./creationsSlice"
import GalleryItemDisplay from "tools/gallery/GalleryItemDisplay"
import { useCreationGlobal } from "./CreationGlobalContext";
import useAPI from "services/hooks/useAPI";

const noPhoto = <AwesomeIcon icon={faCamera} size="2xl" />

type CrationCardProps = {
    creationId: string | number,
    mode?: "list" | "info" | "calendarSmall" | "calendarLarge" | "queue",
    onOpen?: () => void,
    onDelete?: (creation: Creation) => void,
    extraInfo?: string[] | ((creation: Creation) => ReactNode)
}

const CreationCard = (props: CrationCardProps) => {

    //const {} = useAPI(getCreations,true) 
    const creation = useSelectorWithParams([creationSelector, props.creationId])

    const [s, si, isMobile] = useSimpleBreakpoint();
    const { openCreation } = useCreationGlobal();

    const isDone = creation?.status === "published";

    const image = useMemo(() => {
        if (!creation) return null;
        return <StandardAvatar image={creation?.content?.media?.[0]} size={70} />
    }, [creation])

    const dropdownOptions = useMemo(() => {
        if (!creation) return [];
        const options = [];
        if (isMobile && !isDone) options.push({
            key: "edit",
            label: "Edit",
            icon: <AwesomeIcon icon={faPenCircle} />,
            onClick: () => openCreation(creation.id)
        })
        options.push({
            key: "delete",
            label: "Delete",
            icon: <AwesomeIcon icon={faTrashCircle} />,
            onClick: () => props.onDelete && props.onDelete(creation.id)
        })
        options.push({
            key: "debug",
            label: "Debug",
            icon: <AwesomeIcon icon={faBug} />,
            onClick: () => {
                console.log("creation", creation)
            }
        })
        return options;
    }, [isMobile, creation]);

    const tools = useMemo(() => {
        if (!creation) return null;
        return (
            <div className="tools-wrapper">
                {(isMobile || isDone) ? null : <StandardButton
                    icon={faPenCircle}
                    iconProps={{ size: "xl" }}
                    style={{ height: "20px" }}
                    onClick={() => {
                        openCreation(creation.id);
                        props.onOpen && props.onOpen()
                    }}>
                    <FormattedMessage
                        id="common.edit"
                        defaultMessage="Edit" />
                </StandardButton>}
                <Dropdown menu={{ items: dropdownOptions }}>
                    <StandardButton icon={faEllipsis} />
                </Dropdown>
            </div>
        )
    }, [creation, props.mode, isMobile, isDone])

    const extraInfoDisplay = () => {

        if (!props.extraInfo) return null;
        if (typeof props.extraInfo === "function") return props.extraInfo(creation);

        return (
            <>
                {props.extraInfo?.map((infoKey, index) => (
                    <div key={index}>
                        {infoKey}: {creation[infoKey as keyof typeof creation]}
                    </div>
                ))}
            </>
        );
    };

    if (!creation) return null;

    if (props.mode === "calendarSmall") return (
        <>
            <div className="creation-card calendar-small">

                <div className="content">
                    {creation?.content?.message}
                </div>
                {extraInfoDisplay()}
            </div>

        </>

    )

    if (props.mode === "calendarLarge") return (

        <>
            <div className="creation-card calendar-small">

                <div className="media-center">
                    <GalleryItemDisplay item={creation?.content?.media?.[0]} />
                </div>

                <div className="content">
                    {creation?.content?.message}
                </div>
                {extraInfoDisplay()}
            </div>

        </>


    )

    if (props.mode === "queue") return (
        <>
            <div
                className="creation-card card queue"
                onClick={() => {
                    openCreation(creation.id);
                    props.onOpen && props.onOpen()
                }}>
                <div className="bottom-row">
                    <div className="media-wrapper">
                        {image}
                    </div>
                    <div className="message-wrapper">
                        {creation?.content?.message}
                        {extraInfoDisplay()}
                    </div>
                </div>
            </div>

        </>

    )

    return (
        <>
            <div className="creation-card card">
                <div className="top-row">
                    <div className="status-wrapper">
                        <div className="draft-status">{CreationStatusString(creation.status)}</div>
                        <div className="review-status"></div>
                    </div>
                    <div className="title-wrapper">
                        <div className="title">{creation?.content?.title || "Untitled"}</div>
                        <div className="edit-info">
                            <FormattedMessage
                                id="tools.creationEditor.card.lastEdited"
                                defaultMessage="Last edited:" />
                            <div>
                                <DateTimeDisplay
                                    dateTime={creation.updated_at}
                                    showDate
                                    showTime
                                />
                            </div>
                        </div>
                    </div>
                    {tools}
                </div>
                <Divider />
                <div className="bottom-row">
                    <div className="media-wrapper">
                        {image}
                    </div>
                    <div className="message-wrapper">
                        {creation?.content?.message}
                    </div>
                </div>
                <Divider />
                {extraInfoDisplay()}
            </div>
        </>
    )

}

export default CreationCard;