import { ProductConfig } from "products";
import logo from "./FastTonyPost-Logo-Small.png";
import { Requirement } from "modules";
import { ProjectAIInfoDemographicsSelector, ProjectHasAIClientDescriptionSelector, ProjectHasAICompanyDescriptionSelector, ProjectHasAIExampleClientByGenderSelector, ProjectHasAIExampleClients, ProjectHasAILanguageSelector, ProjectHasAIProjectInfoSelector, ProjectHasDemographicSelector, ProjectHasDemographicsInCategorySelector, ProjectHasDemographicsPurchaseObjectionsSelector } from "modules/panel/state/AISlice";
import { ONBOARDING_AI_CLIENT_INFO, ONBOARDING_AI_COMPANY_INFO, ONBOARDING_AI_DEMOGRAPHIC_DEMOGRAPHICS, ONBOARDING_AI_DEMOGRAPHIC_PURCHASE_OBJECTIONS, ONBOARDING_AI_DEMOGRAPHIC_SHOPPING_BEHAVIORS, getUrl, ONBOARIDNG_AI_GENERATION, ONBOARDING_AI_BASIC_INFO, ONBOARDING_ACCEPT_KEYWORD_SUGGESTIONS, ONBOARDING_COMPETITOR_WEBSITES, ONBOARDING_AI_GENERATION_COMPLETED, ONBOARDING_AI_SUCCESS, ONBOARDING_AI_EXAMPLE_CLIENT_GENERATION, ONBOARDING_AI_EXAMPLE_CLIENT_FEMALE, ONBOARDING_AI_EXAMPLE_CLIENT_MALE } from "modules/smart/config/routes";
import {
  getUrl as getSmartUrl,
  ONBOARDING_FACEBOOK_AUDIENCE,
  ONBOARDING_PREPARE_PROJECT,
} from "modules/smart/config/routes";
import { createSelector } from "@reduxjs/toolkit";
import { HasFacebookAudienceFilledSelector, HasFilledCompetitorsWebsitesSelector, HasFilledKeywordIdeasSelector, HasFilledKeywordsSeedSelector, HasUserSmartProject, HasWebsiteFilledSelector } from "modules/smart/smartSlice";

export const AIBasicInfoSelector = createSelector([
  ProjectHasAILanguageSelector,
  HasFilledKeywordsSeedSelector,
  HasWebsiteFilledSelector,
], (hasLanguage, hasKeywords, hasWebsite) => {
  return hasLanguage && hasKeywords && hasWebsite;
}
)


// Initial info about the project -> demographics generation
export const requirements_ai_basic = [
  {
    // check ai have language, forsat have keywords_seed and smart have website for current project
    key: "hasAIBasicInfo",
    path: () => getSmartUrl(ONBOARDING_AI_BASIC_INFO),
    selector: AIBasicInfoSelector,
    omitWhenFulfilled: false,
    forceDisplayOnOnboardedProjects: true,
    // component: <OnboardingAIBasicInfo/>
    // aiProject[currentProject].info.language
    // forsant[currentProject].keywords_seed
    // smart[currentProject].website
  },
  {
    // check ai have company_description
    key: "hasAICompanyInfo",
    path: () => getSmartUrl(ONBOARDING_AI_COMPANY_INFO),
    selector: ProjectHasAICompanyDescriptionSelector,
    permission: "use module smart ai settings",
    omitWhenFulfilled: false,
    forceDisplayOnOnboardedProjects: true,
    // component: <OnboardingAICompanyInfo/>
    // aiProject[currentProject].company_description
  },
  {
    // check ai have client_description
    key: "hasAIClientInfo",
    path: () => getSmartUrl(ONBOARDING_AI_CLIENT_INFO),
    selector: ProjectHasAIClientDescriptionSelector,
    permission: "use module smart ai settings",
    omitWhenFulfilled: false,
    forceDisplayOnOnboardedProjects: true,
    // component: <OnboardingAIClientInfo/>
    // aiProject[currentProject].client_description
  },
  {
    // check ai have info, demographics.constructor
    key: "hasAIProjectInfoGenerated",
    path: () => getSmartUrl(ONBOARIDNG_AI_GENERATION),
    selector: ProjectHasDemographicSelector,
    permission: "use module smart ai settings",
    omitWhenFulfilled: false,
    forceDisplayOnOnboardedProjects: true,
    // component: <OnboardingAIGeneration/>
    // aiProject[currentProject].info
    // aiProject[currentProject].demographics.constructor
  },
]

export const requirements_ai_demographics = [
  {
    // check ai have demographics
    key: "hasDemographicsGenerationCompleted",
    path: () => getSmartUrl(ONBOARDING_AI_GENERATION_COMPLETED),
    selector: ProjectAIInfoDemographicsSelector,
    omitWhenFulfilled: false,
    forceDisplayOnOnboardedProjects: true,
    // component: <OnboardingAIGenerationCompleted/>
    // aiProject[currentProject].demographics
  },
  {
    // check ai have demographics with category "demographics"
    key: "hasAIProjectInfoDemographicDemographics",
    path: () => getSmartUrl(ONBOARDING_AI_DEMOGRAPHIC_DEMOGRAPHICS),
    selector: (state: any) => ProjectHasDemographicsInCategorySelector(state, "demographics"),
    permission: "use module smart ai settings",
    omitWhenFulfilled: false,
    forceDisplayOnOnboardedProjects: true,
    // component: <OnboardingAIDemographics/>
    // aiProject[currentProject].demographics["demographics"]
  },
  {
    // check ai have demographics with category "shoppingBehaviors"
    key: "hasAIProjectInfoDemographicShoppingBehaviors",
    path: () => getSmartUrl(ONBOARDING_AI_DEMOGRAPHIC_SHOPPING_BEHAVIORS),
    selector: (state: any) => ProjectHasDemographicsInCategorySelector(state, "shoppingBehaviors"),
    permission: "use module smart ai settings",
    omitWhenFulfilled: false,
    forceDisplayOnOnboardedProjects: true,
    // component: <OnboardingAIDemographics/>
    // aiProject[currentProject].demographics["shoppingBehaviors"]
  },
  {
    // check ai have demographics.purchaseObjections
    key: "hasAIProjectInfoDemographicPurchaseObjections",
    path: () => getSmartUrl(ONBOARDING_AI_DEMOGRAPHIC_PURCHASE_OBJECTIONS),
    selector: ProjectHasDemographicsPurchaseObjectionsSelector,
    permission: "use module smart ai settings",
    omitWhenFulfilled: false,
    forceDisplayOnOnboardedProjects: true,
    // component: <OnboardingAIPurchaseObjections/>
    // aiProject[currentProject].demographics.purchaseObjections
  },
  {
    // check ai have example_client 
    key: "hasAIProjectInfoExampleClients",
    path: () => getSmartUrl(ONBOARDING_AI_EXAMPLE_CLIENT_GENERATION),
    selector: ProjectHasAIExampleClients,
    permission: "use module smart ai settings",
    omitWhenFulfilled: false,
    forceDisplayOnOnboardedProjects: true,
    // component: <OnboardingAIExampleClientGeneration/>
    // aiProject[currentProject].example_client > 0
  },
]

export const requirements_ai_example_clients = [
  {
    // check ai have example_client with gender female
    key: "hasAIProjectInfoExampleClientFemale",
    path: () => getSmartUrl(ONBOARDING_AI_EXAMPLE_CLIENT_FEMALE),
    selector: (state: any) => ProjectHasAIExampleClientByGenderSelector(state, "female"),
    permission: "use module smart ai settings",
    omitWhenFulfilled: false,
    forceDisplayOnOnboardedProjects: true,
    // component: <OnboardingAIClientAvatar/>
    // aiProject[currentProject].example_client["female"]
  },
  {
    // check ai have example_client with gender male
    key: "hasAIProjectInfoExampleClientMale",
    path: () => getSmartUrl(ONBOARDING_AI_EXAMPLE_CLIENT_MALE),
    selector: (state: any) => ProjectHasAIExampleClientByGenderSelector(state, "male"),
    permission: "use module smart ai settings",
    omitWhenFulfilled: false,
    forceDisplayOnOnboardedProjects: true,
    // component: <OnboardingAIClientAvatar/>
    // aiProject[currentProject].example_client["male"]
  },
]

const requirements = [
  {
    // check current project have smart
    key: "userHasSmartProject",
    path: () => getSmartUrl(ONBOARDING_PREPARE_PROJECT),
    selector: HasUserSmartProject,
    omitWhenFulfilled: true,
    // component: <PrepareSmartProject/>
    // smart[currentProject]
  },
  ...requirements_ai_basic,
  {
    // check smart have google_smart_settings competitors_websites
    key: "hasDefinedCompetitorWebsites",
    path: () => getSmartUrl(ONBOARDING_COMPETITOR_WEBSITES),
    selector: HasFilledCompetitorsWebsitesSelector,
    omitWhenFulfilled: false,
    forceDisplayOnOnboardedProjects: true,
    // component: <OnboardingCompetitorWebsites/>
    // adsSettings.get("competitors_websites").size >= 1
  },
  {
    // check smart have google_smart_settings keywords
    key: "hasAcceptedKeywordSuggestions",
    path: () => getSmartUrl(ONBOARDING_ACCEPT_KEYWORD_SUGGESTIONS),
    selector: HasFilledKeywordIdeasSelector,
    omitWhenFulfilled: false,
    forceDisplayOnOnboardedProjects: true,
    // component: <OnboardingAcceptKeywordSuggestions/>
    // adsSettings.get("keywords").size >= 1
  },
  {
    // check smart have facebook_ads_settings parameters
    key: "hasFacebookAudienceFilled",
    path: () => getSmartUrl(ONBOARDING_FACEBOOK_AUDIENCE),
    selector: HasFacebookAudienceFilledSelector,
    integration: "facebook",
    omitWhenFulfilled: false,
    forceDisplayOnOnboardedProjects: true,
    // component: <OnboardingAudienceFacebook/>
    // gender !== null &&
    // age_from !== null &&
    // age_to !== null &&
    // location_targeting.geo_points.length > 0
  },
  ...requirements_ai_demographics,
  ...requirements_ai_example_clients,
  {
    // on requirements
    key: "hasSeenSuccessPage",
    path: () => getSmartUrl(ONBOARDING_AI_SUCCESS),
    selector: () => true,
    omitWhenFulfilled: true,
    forceDisplayOnOnboardedProjects: false,
    // component: <OnboardingAISuccess/>
  }
];



export const configPost: ProductConfig = {
  name: "Post",
  logo,
  requirements: requirements as Requirement[],
  index: "/smart/posts/list",
};

export const configPostLite: ProductConfig = {
  name: "PostLite",
  logo,
  requirements: requirements as Requirement[],
  index: "/smart/posts/list",
}