import { Col, InputNumber } from "antd";
import { StandardRow } from "components/layout/StandardGrid";
import GeoPointsGoogle from "components/map/GeoPointsGoogle";
import ConnectedOnboardingControls from "providers/onboarding/controls";
import useAPI, { callAPIProps } from "services/hooks/useAPI";
import useDataPrefill from "services/hooks/useDataPrefill";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import FormattedMessage from "components/common/FormattedMessage";
import { GenderInput } from "_components/forms/GenderInputs";
import { GetSmart, GoogleAdsSettingsSelector, HasGoogleAudienceFilledSelector, UpdateGoogleAdsSettings } from "modules/smart/smartSlice";
import { currentProjectIdSelector } from "services/store/scopeSlice";


export default function OnboardingAudienceGoogle() {
  const {} = useAPI(GetSmart, true);

  const fulfilled = useSelectorWithParams(HasGoogleAudienceFilledSelector);
  const projectId = useSelectorWithParams(currentProjectIdSelector);

  useDataPrefill({
    dataSelector: GoogleAdsSettingsSelector,
    callAPI: UpdateGoogleAdsSettings,
    checkFunction: (data: any) => {
      if (!data) return false;

      let newData = {};

      if (!data.age_from || !data.age_to) {
        newData = {
          age_from: 18,
          age_to: 65,
        };
      }

      if (!data.gender) {
        newData = {
          ...newData,
          gender: "all",
        };
      }

      if (Object.keys(newData).length > 0) {
        return newData;
      }

      return false;
    },
  });

  const setValueIfEmpty =
    (setValue: number) => (value: string | number | undefined) =>
      value ? value.toString() : setValue.toString();

  return (
    <>
      <RelaxMultiform
        name="googleAdsSettings"
        callAPI={UpdateGoogleAdsSettings}
        initialValuesSelector={GoogleAdsSettingsSelector}
        finalizeSubmit={(values) => {
          if (values.location_targeting) {
            values.location_targeting.country_code = "PL";
          }
          return { ...values, project_id: projectId };
        }}
      >
        <StandardRow>
          <Col span={24}>
            <GeoPointsGoogle />
          </Col>
        </StandardRow>
        <div style={{ height: "25px" }}></div>

        <StandardRow>
          <Col xs={24} sm={24} md={12} lg={12}>
            <RelaxSingleField
              name="age_from"
              initialValue={18}
              label={
                <FormattedMessage
                  id="components.forms.inputs.ageRange.From"
                  defaultMessage="Age from"
                />
              }
            >
              <InputNumber
                formatter={setValueIfEmpty(18)}
                className="fullwidth from"
              />
            </RelaxSingleField>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12}>
            <RelaxSingleField
              name="age_to"
              initialValue={65}
              label={
                <FormattedMessage
                  id="components.forms.inputs.ageRange.To"
                  defaultMessage="Age to"
                />
              }
            >
              <InputNumber
                formatter={setValueIfEmpty(65)}
                className="fullwidth from"
              />
            </RelaxSingleField>
          </Col>
        </StandardRow>

        <StandardRow>
          <Col xs={24} sm={24} md={12} lg={12}>
            <RelaxSingleField
              name="gender"
              initialValue={["all"]}
              label={
                <FormattedMessage
                  id="apps.smart.components.adsSettings.genderLabel"
                  defaultMessage="Gender"
                />
              }
            >
              <GenderInput />
            </RelaxSingleField>
          </Col>
        </StandardRow>
      </RelaxMultiform>
      <ConnectedOnboardingControls
        relaxDontSubmit={true}
        nextDisabled={!fulfilled}
      />
    </>
  );
}
