import { Collapse, Input } from "antd"
import FormattedMessage from "components/common/FormattedMessage"
import StandardButton from "components/common/StandardButton"
import { LanguageSelectInput } from "components/inputs/LanguageAndCountrySelectors"
import { StandardFullColumn, StandardHalfFullColumn, StandardRow } from "components/layout/StandardGrid"
import { AiProjectInfoSelector, GetAiProjectInfo, UpdateAIProjectInfo } from "modules/panel/state/AISlice"
import { useMemo } from "react"
import useAPI from "services/hooks/useAPI"
import RelaxField from "tools/relaxForm/RelaxField"
import RelaxMultiform from "tools/relaxForm/RelaxMultiform"
import AIDemographics, { GetAIDemographicsFieldLabel } from "./AIDemographics"
import useSelectorWithParams from "services/hooks/useSelectorWithParams"
import RelaxList from "tools/relaxForm/RelaxList"
import _ from "lodash"

const SettingsAI = () => {

    const aiSettingsCall = useAPI(GetAiProjectInfo, true)
    const purchaseObjections = useSelectorWithParams([AiProjectInfoSelector, "purchaseObjections"])

    return (
        <>
                        <div style={{ height: "20px" }}></div>
        <RelaxMultiform
            initialValuesSelector={AiProjectInfoSelector}
            callAPI={UpdateAIProjectInfo}
        >
            <StandardRow>
                <StandardHalfFullColumn>     
                    <RelaxField
                        name={["info", "language"]}
                        label={<FormattedMessage
                            id="apps.smart.components.aiSettings.infoLanguage.label"
                            defaultMessage="Language"
                        />}
                    >
                        <Input />
                    </RelaxField>
                </StandardHalfFullColumn>
            </StandardRow>
            <StandardRow>
                <StandardFullColumn>
                    <RelaxField
                        name={"company_description"}
                        label={<FormattedMessage
                            id="apps.smart.components.aiSettings.companyDescription.label"
                            defaultMessage="Company Description"
                        />}
                    >
                        <Input.TextArea />
                    </RelaxField>
                </StandardFullColumn>
            </StandardRow>
            <StandardRow>
                <StandardFullColumn>
                    <RelaxField
                        name={"client_description"}
                        label={<FormattedMessage
                            id="apps.smart.components.aiSettings.clientDescription.label"
                            defaultMessage="Client Description"
                        />}
                    >
                        <Input.TextArea />
                    </RelaxField>
                </StandardFullColumn>
            </StandardRow>
        </RelaxMultiform>
        <StandardRow>
            <StandardFullColumn>     
        <Collapse>
            <Collapse.Panel key="demographics" header={GetAIDemographicsFieldLabel("demographics")}>
                <AIDemographics category="demographics"/>
            </Collapse.Panel>
            <Collapse.Panel key="shoppingBehaviors" header={GetAIDemographicsFieldLabel("shoppingBehaviors")}>
                <AIDemographics category="shoppingBehaviors"/>
            </Collapse.Panel>
            <Collapse.Panel key="purchaseObjections" header={GetAIDemographicsFieldLabel("purchaseObjections")}>
            <RelaxList
                name={["demographics", "purchaseObjections"]}
                callAPI={UpdateAIProjectInfo}
                initialValuesSelector={AiProjectInfoSelector}
                finalizeSubmit={(values) => {
                    const newData = _.merge(_.cloneDeep(purchaseObjections), _.cloneDeep(values))
                    return {
                        demographics: newData.demographics
                    };
                }}
                >
                    <Input.TextArea autoSize={{minRows: 1, maxRows: 2}}/>
                </RelaxList>
            </Collapse.Panel>
        </Collapse>
        </StandardFullColumn>    
        </StandardRow>

        </>
    )

}

export default SettingsAI