import {
  getApiUrl,
  serializeObjectToURLParameters,
} from "services/helpers/api-helpers";
import { getAuthorizationHeadersFromStorage } from "services/helpers/local-storage";
import normalizeData from "services/store/middlewares/api/json-api-normalizer/normalizer";

export type CompanyCategory = {
  display_name: string;
  category_id: string;
  id: number;
};

export const getCompanyCategories: {
  (q: string): Promise<CompanyCategory[]>;
} = (q) => {
  return fetch(
    getApiUrl(
      `services/google/industries?${serializeObjectToURLParameters({
        "filter[display_name]": q,
      })}`
    ),
    {
      method: "GET",
      headers: getAuthorizationHeadersFromStorage(),
    }
  )
    .then((res) => (res.status === 200 ? res.json() : { data: [] }))
    .then(normalizeData);
};
