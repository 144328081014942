import { Button, Dropdown, Input, Popover, Rate, Row, Select, Space, Switch, Table, Tooltip } from "antd"
import FormattedMessage from "components/common/FormattedMessage";
import { Text } from "components/common/StandardText";
import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl"
import useAPI, { callAPIProps } from "services/hooks/useAPI"
import { KeywordLabel } from "./KeywordsSearchWithSuggestions";
import AwesomeIcon, { AwesomeClose } from "components/common/AwesomeIcon";
import { faEyeSlash, faGear, faMinusCircle, faPlusCircle } from "@fortawesome/pro-light-svg-icons";
import Center from "components/common/Center";
import StandardButton from "components/common/StandardButton";
import Checkbox from "components/inputs/Checkbox";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { BehaviorsSelector } from "modules/smart/smartSlice";
import _ from "lodash";
import { Keyword } from "./KeywordsConfig";
import { KeywordActions } from "./KeywordFacebook";

const getQuery = (q: any): callAPIProps => {

    const query = {
        q: q,
        type: "query",
        group: "interests",
        ["page[size]"]: 150
    }

    return {
        url: ({ getApiUrl, serializeQuery }) => getApiUrl(`services/facebook/interests?${serializeQuery(query)}`),
        method: "GET",
        auth: true,
    }
}


const KeywordsAdvancedTable = (props: any) => {

    const intl = useIntl();
    const [filter, setFilter] = useState("")
    const [keywords, setKeywords] = useState(new Map<string, Keyword[]>())
    const { call, data: suggestions, loading, clear } = useAPI(getQuery(""))
    const [options, setOptions] = useState<any>({
        showBehaviors: false,
        showCurrentValues: true,
    })
    const [removed, setRemoved] = useState<Keyword[]>([])

    const behaviors = useSelectorWithParams(BehaviorsSelector)

    const handleSearch = (value: string) => {
        call(getQuery(value)).then((data) => {
            if (data?.data?.length) {
                setKeywords((m) => {
                    m.set(value, data.data)
                    return new Map(m)
                })
            }
        })
    }

    const handleDeselect = (value: string) => {
        setKeywords((m) => {
            m.delete(value)
            return new Map(m)
        })
    }

    const removeSpecificKeyword = (keyword: Keyword) => {
        setRemoved((r) => _.uniq([...r, keyword.id]))
    }

    const allKeywords = useMemo(() => {

        const all = [];

        for (let [key, value] of keywords) {
            all.push(...value)
        }

        if (options.showBehaviors) {
            all.push(...behaviors)
        }

        if (props.value && options.showCurrentValues) {
            all.push(...props.value)
        }

        return all.filter((v) => !removed.includes(v.id))

    }, [keywords, options, removed, props.value])

    const columns = useMemo(() => {

        return [
            {
                title: (
                    <FormattedMessage
                        id="components.keywords.advancedTable.headings.name"
                        defaultMessage="Name"
                    />
                ),
                render: (v: any) => {
                    return (
                            <KeywordLabel
                                keyword={v}
                                inclusion={null}
                            />
                    )
                }
            },
            {
                title: (
                    <FormattedMessage
                        id="components.keywords.advancedTable.headings.inclusion"
                        defaultMessage="Inclusion"
                    />
                ),
                width: "300px",
                render: (v: any) => {
                    if (props.value) {

                        const inValue = props.value.find((value: any) => value.id === v.id)

                        if (inValue) return (
                            <KeywordActions
                                keyword={inValue as Keyword}
                                mode={"selected"}
                                onRemove={() => props.onRemove(v)}
                                onExclude={() => props.onExclude(v)}
                                onInclude={() => props.onInclude(v)}
                            />
                        )
                    }
                    return (
                        <div style={{
                            display: "flex"
                        }}>
                            <KeywordActions
                                keyword={v}
                                mode={"suggestion"}
                                onRemove={() => props.onRemove(v)}
                                onExclude={() => props.onExclude(v)}
                                onInclude={() => props.onInclude(v)}
                            />
                        </div>
                    )

                }
            },
            {
                title: (
                    <FormattedMessage
                        id="components.keywords.advancedTable.headings.lowerBound"
                        defaultMessage="Lower Bound"
                    />
                ),
                width: "150px",
                render: (v: any) => intl.formatNumber(v.lower_bound) !== "NaN" ? intl.formatNumber(v.lower_bound) : "",
                sorter: (a: any, b: any) => a.lower_bound - b.lower_bound,
            },
            {
                title: (
                    <FormattedMessage
                        id="components.keywords.advancedTable.headings.upperBound"
                        defaultMessage="Upper Bound"
                    />
                ),
                width: "150px",
                render: (v: any) => intl.formatNumber(v.upper_bound) !== "NaN" ? intl.formatNumber(v.upper_bound) : "",
                sorter: (a: any, b: any) => a.upper_bound - b.upper_bound,
            },
            {
                title: "",
                width: "50px",
                render: (v: any) => <AwesomeIcon icon={faEyeSlash} onClick={() => removeSpecificKeyword(v)} />
            }
        ]

    }, [options])

    return (
        <div className="keywords-advanced">
            <Row>
                <div style={{ paddingLeft: "10px", marginBottom: "2px" }}>
                    <FormattedMessage
                        id="components.keywords.keywordIdeas"
                        defaultMessage="Keyword Ideas"
                    />
                </div>
                <Table
                    className="standard-border table-minimal-padding"
                    style={{
                        height: "400px",
                        padding: "0px"
                    }}
                    sticky
                    columns={columns}
                    dataSource={allKeywords}
                    pagination={false}

                />
                <div
                    style={{
                        width: "100%",
                        display: 'flex',
                        direction: "row"
                    }}
                >
                    <Dropdown
                        menu={{
                            items: [
                                {
                                    key: "showBehaviors",
                                    label: (
                                        <Checkbox value={options.showBehaviors} onChange={(v: any) => setOptions((o: any) => ({ ...o, showBehaviors: v }))}>Behaviors</Checkbox>
                                    )
                                },
                                {
                                    key: "showCurrentValues",
                                    label: (
                                        <Checkbox value={options.showCurrentValues} onChange={(v: any) => setOptions((o: any) => ({ ...o, showCurrentValues: v }))}>Show Current Selection</Checkbox>
                                    )
                                },
                                {
                                    key: "showRemoved",
                                    label: (
                                        <Text onClick={() => setRemoved([])}>Show removed</Text>
                                    )
                                }
                            ]
                        }}
                        placement="topLeft"
                    >

                        <StandardButton icon={faGear} onClick={(e: any) => {
                            e.preventDefault();
                        }}>
                            <FormattedMessage
                                id="posts.list.filtering.advancedSettings"
                                defaultMessage="Settings" />
                        </StandardButton>
                    </Dropdown>
                    <Select
                        mode="tags"
                        showSearch={false}
                        onSelect={handleSearch}
                        onDeselect={handleDeselect}
                        style={{
                            width: "100%"
                        }}
                    //dropdownRender={()=><></>}
                    />
                </div>
            </Row>
        </div>
    )

}

export default KeywordsAdvancedTable;