import React, { Component } from "react";
import MaskedInput from "_components/forms/masked-inputs/masked-input";

export const COUNTRY_PREFIX_PATTERN = [/\w/, /\w/, " "];

export const NIP_PATTERN = [
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
];

export default class NipInput extends Component {
  isFirstCharacterDigit = (value) =>
    value && value.length ? /^\d+$/.test(value[0]) : false;

  render() {
    const withoutPrefix = this.isFirstCharacterDigit(this.props.value);

    return (
      <MaskedInput
        pattern={
          withoutPrefix
            ? NIP_PATTERN
            : [...COUNTRY_PREFIX_PATTERN, ...NIP_PATTERN]
        }
        delimiters={[" "]}
        transform={(value) =>
          value
            .replace(new RegExp(" ", "g"), "")
            .substr(0, withoutPrefix ? 10 : 12)
        }
        {...this.props}
      />
    );
  }
}
