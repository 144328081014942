import { ProductConfig } from "products";
import logo from "./logoAdChampion.svg";
import requirements from "modules/forsant/config/requirements";

const adChampionConfig: ProductConfig = {
  name: "AdChampion",
  logo,
  requirements: requirements as any,
};

export default adChampionConfig;