import { faCartCircleCheck, faCartCirclePlus, faCartPlus, faCashRegister, faCreditCard, faEye, faSquareCheck, faStarOfLife, faUserPlus, IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { Popover } from "antd";
import AwesomeIcon from "components/common/AwesomeIcon";
import ValueDisplayWithCounter from "components/common/ValueDisplayWithCounter";
import { ReactNode, useMemo } from "react";
import FormattedMessage from "components/common/FormattedMessage";

type conversion = {
    action_type: string,
    value?: number,
    cost?: number,
}

type conversionIconAndName = {
    icon: IconDefinition,
    name: ReactNode,
}

export const GetConversionIconAndName = (action_type: string):conversionIconAndName => {
    if (!action_type) return {
        icon: faStarOfLife,
        name: <FormattedMessage
        id="posts.conversions.unknown"
        defaultMessage="Unknown"
        />
    }
    switch (action_type) {
        case "AddToCart":
        case "add_to_cart":
            return {
                icon: faCartPlus,
                name: <FormattedMessage
                    id="posts.conversions.add_to_cart"
                    defaultMessage="Add to cart"
                />
            }
        case "InitiateCheckout":
        case "initiate_checkout":
            return {
                icon: faCreditCard,
                name: <FormattedMessage
                    id="posts.conversions.initiated_checkout"
                    defaultMessage="Initiated checkout"
                />
            }
        case "AddPaymentInfo":
        case "add_payment_info":
            return {
                icon: faCreditCard,
                name: <FormattedMessage
                    id="posts.conversions.add_payment_info"
                    defaultMessage="Add payment info"
                />
            }
        case "Purchase":
        case "purchase":
            return {
                icon: faCashRegister,
                name: <FormattedMessage
                    id="posts.conversions.purchase"
                    defaultMessage="Purchase"
                />
            }
        case "Lead":
        case "lead":
            return {
                icon: faSquareCheck,
                name: <FormattedMessage
                    id="posts.conversions.lead"
                    defaultMessage="Lead"
                />
            }
        case "CompleteRegistration":
        case "complete_registration":
            return {
                icon: faUserPlus,
                name: <FormattedMessage
                    id="posts.conversions.complete_registration"
                    defaultMessage="Complete registration"
                />
            }
        case "ContentView":
        case "content_view":
        case "view_content":
            return {
                icon: faEye,
                name: <FormattedMessage
                    id="posts.conversions.content_view"
                    defaultMessage="Content view"
                />
            }
    }
    return {
        icon: faStarOfLife,
        name: action_type
    }
}

export const ConversionIcons: Record<string, IconDefinition> = {
    "AddToCart": faCartCirclePlus,
    "InitiatedCheckout": faCartCircleCheck,
    "AddPaymentInfo": faCreditCard,
    "Purchase": faCashRegister,
    "Lead": faSquareCheck,
    "CompleteRegistration": faUserPlus,
    "ContentView": faEye,
}

const ConversionIcon = (props: { action_type: string, value?: number, cost_per_result?: number }) => {
    const { action_type, value, cost_per_result } = props;
    const {icon, name} = GetConversionIconAndName(action_type);

    if (props.value !== undefined) {
        return (
            <div className="conversion-icon with-value" style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "20px",
            }}>
                <div style={{display: "grid", placeItems: "center"}}>
                        <AwesomeIcon icon={icon} style={{width: "20px"}}/>
                </div>
                <div style={{
                    justifySelf: "flex-start",
                    width: "auto",
                    flexGrow: 1,
                }}>{name}</div>
                <div>{value} / {cost_per_result?.toFixed(3)}</div>
            </div>
        )
    }

    return (
        <div className="conversion-icon">
            <AwesomeIcon icon={icon} />
        </div>)
}

export default function ConversionsDisplay(props: { conversions: conversion[] }) {


    const sortedConversions = useMemo(() => {
        if (!props.conversions) return [];
        return [...props.conversions].sort((a: conversion, b: conversion) => {
            if (a.value === undefined) return 1;
            if (b.value === undefined) return -1;
            return b.value - a.value;
        })
    }, [props.conversions])

    const allConversions = useMemo(() => {
        if (!props.conversions) return null;
        return props.conversions.map((conversion: any) => {
            if (!conversion) return null;
            return (
                <ConversionIcon key={conversion.action_type} {...conversion} />
            )
        })
    }, [props.conversions])

    const sumOfConversions = props.conversions ? props.conversions.reduce((sum: number, conversion: conversion) => {
        return sum + (conversion.value || 0);
    }, 0) : 0;

    const topConversions = useMemo(() => {
        if (!sortedConversions) return null;
        if (sortedConversions.length === 0) return null;
        return (
            <div 
            className="top-conversions" 
            style={{
                display: "flex", 
                flexDirection: "row",
            }}>
                {sortedConversions.slice(0, 3).map((conversion: any) => <ConversionIcon key={conversion.action_type} {...{action_type: conversion.action_type}}  />)}
            </div>
        )
    }, [sortedConversions])

    if (!props.conversions) return null;

    if (sumOfConversions === 0) return null;

    return (
        <div className="conversions-display">
            <Popover
                content={allConversions}
            >
                <div style={{
                    display: "flex",
                    width: "fit-content",
                    flexDirection: "column",
                    placeItems: "center",
                }}>
                    <ValueDisplayWithCounter value={sumOfConversions} parser={(value) => value.toFixed(0)} />{topConversions}
                </div>
            </Popover>
        </div>
    )




}