import { ExclamationCircleOutlined } from "@ant-design/icons";
import StandardButton from "components/common/StandardButton";
import { useMemo } from "react";
import FormattedMessage from "components/common/FormattedMessage";
import { usePost } from "../postContext";
import DetailsSection from "./DetailsSection";

export default function RetryPromotionOfAdWithError (props:any) {

    const {post} = usePost();
    const adStatus = useMemo(() => {
        if (props.ad) {
            return post.getAdStatus(props.ad)
        }
        return null;
    }, [post, props.ad]);

    const handleRetry = () => {
    }

    if (!post) return null;
    if (!props.ad) return null;
    if (adStatus !== "error") return null;

    return (
        <DetailsSection>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: "20px" }}>
                <div style={{ width: "70px", height: "70px", display: "grid", placeItems: "center" }}>
                    <h1 style={{ marginBottom: "0px" }}>
                       <ExclamationCircleOutlined/>
                    </h1>
                </div>
                <div>
                    <FormattedMessage
                        id="smart.components.ad.error"
                        defaultMessage="This ad could not be published. Please check the ad details and try again."
                    />
                </div>
                <div>
                <StandardButton type="primary" disabled={true} onClick={handleRetry}>Promote</StandardButton>
                </div>
            </div>
        </DetailsSection>
    )
}