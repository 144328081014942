import {createSlice, createSelector} from '@reduxjs/toolkit';
import { callAPIProps } from 'services/hooks/useAPI';
import _ from 'lodash';
import { currentProjectSelector } from 'state/projects/projectSelectors';

export type OrganizationSliceState = any;

const initialState: OrganizationSliceState = {
    name: "Organization",
    description: "Organization-description",
    logo: "",
    projects: {},
    managers: {},
    groups: {},
    invitations: [],
}

const addProjectToState = (state:any, project:any) => {
    if (!state.projects) state.projects = {};
    if (!state.projects[project.id]) state.projects[project.id] = {};
    state.projects[project.id] = project;
}
const addProjectsToState = (state:any, projects:any) => {
    if (!state.projects) state.projects = {};
    if (projects && projects.forEach) {
        projects.forEach((p:any) => addProjectToState(state, p))
    }
}

const addGroupToState = (state:any, group:any) => {
    if (!state.groups) state.groups = {};
    if (!state.groups[group.id]) state.groups[group.id] = {};
    state.groups[group.id] = group;
}

const addGroupsToState = (state:any, projects:any) => {
    if (!state.groups) state.groups = {};
    if (projects && projects.forEach) {
        projects.forEach((p:any) => addGroupToState(state, p))
    }
}

const addManagerToState = (state:any, manager:any) => {
    if (!state.managers) state.managers = {};
    if (!state.managers[manager.id]) state.managers[manager.id] = {};
    state.managers[manager.id] = manager;
}

const addManagersToState = (state:any, managers:any) => {
    if (!state.managers) state.managers = {};
    if (managers && managers.forEach) {
        managers.forEach((p:any) => addManagerToState(state, p))
    }
}

export const organizationSlice = createSlice({
    name: "organization",
    initialState, 
    reducers: {
        getOrganization: (state, action) => {

            if (action.payload.data) {
                state.name = action.payload.data?.name;
                state.description = action.payload.data?.description;
                state.logo = action.payload.data?.logo;
            }

            // if (!state[action.payload.projectId]) state[action.payload.projectId] = {};
            // if (action.payload.data && action.payload.data.forEach) {
            //     const sorted = _.sortBy(action.payload.data, "order")
            //     sorted.forEach((r:any) => {
            //         state[action.payload.projectId][r.id] = r;
            //     })
            // }
        },
        getProjects: (state, action) => {
            addProjectsToState(state, action.payload.data);
        },
        getManagers: (state, action) => {
            addManagersToState(state, action.payload.data);
        },
        getIntitations: (state, action) => {
            state.invitations = action.payload.data;
        },
        newInvitation: (state, action) => {
            state.invitations.push(action.payload.data);
        },
        deleteInvitation: (state, action) => {
            //state.invitations = state.invitations.filter((i:any) => i.id !== action.payload.data.id);
        },
        getGroups: (state, action) => {
            addGroupsToState(state, action.payload.data);
        },
        newGroup: (state, action) => {
            addGroupToState(state, action.payload.data);
        },
        removeGroup: (state, action) => {
            delete state.groups[action.payload.additional.groupId];
        },
        updateGroup: (state, action) => {
            addGroupToState(state, action.payload.data)
        },
    }
})

export const OrganizationGetOrganization = ():callAPIProps => ({
    url: ({projectId, getApiUrl }) => getApiUrl(`projects/${projectId}/organization/`),
    method: "GET",
    successDispatch: organizationSlice.actions.getOrganization,
    hideNotifications: true
})

export const PatchOrganization = ():callAPIProps => ({
    url: ({projectId, getApiUrl }) => getApiUrl(`projects/${projectId}/organization/`),
    method: "PATCH",
    successDispatch: organizationSlice.actions.getOrganization,
})


export const OrganizationGetProjects = ():callAPIProps => ({
    url: ({projectId, getApiUrl }) => getApiUrl(`projects/${projectId}/organization/projects`),
    method: "GET",
    successDispatch: organizationSlice.actions.getProjects,
    hideNotifications: true
})

export const OrganizationGetManagers = ():callAPIProps => ({
    url: ({projectId, getApiUrl }) => getApiUrl(`projects/${projectId}/organization/managers`),
    method: "GET",
    successDispatch: organizationSlice.actions.getManagers,
    hideNotifications: true
})


export const OrganizationGetInvitations = ():callAPIProps => ({
    url: ({projectId, getApiUrl }) => getApiUrl(`projects/${projectId}/organization/invitations`),
    method: "GET",
    successDispatch: organizationSlice.actions.getIntitations,
    hideNotifications: true
})



export const NewInvitation = ():callAPIProps => ({
    url: ({projectId, getApiUrl }) => getApiUrl(`projects/${projectId}/organization/invitations`),
    method: "POST",
    successDispatch: organizationSlice.actions.newInvitation,
})

export const PatchInvitation = (invId: string):callAPIProps => ({
    url: ({projectId, getApiUrl }) => getApiUrl(`projects/${projectId}/organization/invitations/${invId}`),
    method: "PATCH",
    successDispatch: organizationSlice.actions.newGroup,
})

export const DeleteInvitation = (invId: string):callAPIProps => ({
    url: ({projectId, getApiUrl }) => getApiUrl(`projects/${projectId}/organization/invitations/${invId}`),
    method: "DELETE",
    successDispatch: organizationSlice.actions.deleteInvitation,
})

export const OrganizationGetGroups = ():callAPIProps => ({
    url: ({projectId, getApiUrl }) => getApiUrl(`projects/${projectId}/organization/groups`),
    method: "GET",
    successDispatch: organizationSlice.actions.getGroups,
    hideNotifications: true
})


export const PatchGroup = (groupId: string | number):callAPIProps => ({
    url: ({projectId, getApiUrl }) => getApiUrl(`projects/${projectId}/organization/groups/${groupId}`),
    method: "PATCH",
    successDispatch: organizationSlice.actions.updateGroup,
})

export const NewGroup = ():callAPIProps => ({
    url: ({projectId, getApiUrl }) => getApiUrl(`projects/${projectId}/organization/groups`),
    method: "POST",
    successDispatch: organizationSlice.actions.newGroup,
})

export const DeleteGroup = (groupId: string | number):callAPIProps => ({
    url: ({projectId, getApiUrl }) => getApiUrl(`projects/${projectId}/organization/groups/${groupId}`),
    method: "DELETE",
    successDispatch: organizationSlice.actions.removeGroup,
    passToDispatcher:{
        groupId:groupId
    }
})



export const OrganizationIdSelector = createSelector(
    [currentProjectSelector], 
    (project) => project ? project.organization_id : null
)

export const CurrentProjectIsInOrganizationSelector = createSelector(
    [currentProjectSelector], 
    (project) => project ? project.organization_id !== null : false
)

export const OrganizationSelector = createSelector(
    [(state:any) => state.organization],
    (state) => state
)

export const OrganizationProjectsSelector = createSelector(
    [OrganizationSelector],
    (state) => {
        if (!state.projects) return [];
        return Object.values(state.projects);
    }
)


export const OrganizationProjectSelector = createSelector(
    [
        OrganizationProjectsSelector,
        (state, projectId) => projectId
    ],
    (projects, projectId) => projects ? projects.find((p:any) => p.id === projectId) : null
)

export const OrganizationManagersSelector = createSelector(
    [OrganizationSelector],
    (state) => {
        if (!state.managers) return [];
        return Object.values(state.managers);
    }
)

export const OrganizationManagerSelector = createSelector(
    [
        OrganizationManagersSelector,
        (state, managerId) => managerId
    ],
    (managers, managerId) => managers ? managers.find((p:any) => p.id === managerId) : null
)

export const OrganizationInvitationsSelector = createSelector(
    [OrganizationSelector],
    (state) => state.invitations ? state.invitations : []
)

export const OrganizationInvitationSelector = createSelector(
    [
        OrganizationInvitationsSelector,
        (state, invitationId) => invitationId
    ],
    (invitations, invitationId) => invitations ? invitations.find((p:any) => p.id === invitationId) : null
)

export const OrganizationGroupsSelector = createSelector(
    [OrganizationSelector],
    (state) => {
        if (!state.groups) return [];
        return Object.values(state.groups);
    }
)

export const OrganizationGroupSelector = createSelector(
    [
        OrganizationGroupsSelector,
        (state, groupId) => groupId
    ],
    (groups, groupId) => groups ? groups.find((p:any) => p.id === groupId) : null
)


export default organizationSlice;