import { Input, Radio, Select } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import { useMemo, useState } from "react";
import RelaxField from "tools/relaxForm/RelaxField";
import { AiStrategySelector, GetAiStrategies } from "modules/panel/state/AISlice"
import useAPI from "services/hooks/useAPI";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import AIStrategySelect from "./AIStrategySelect";
import AIIntensitySelect from "./AIIntensitySelect";
import React from "react";

const AIStrategyAndIntensitySelect = (props: any) => {

    const strategyCall = useAPI(GetAiStrategies, true)
    const [strategy, setStrategy] = useState(1);

    return (
        <>
            <RelaxField
                name="strategy"
                label={
                    <FormattedMessage
                        id="apps.smart.components.aiGeneration.strategy"
                        defaultMessage="Strategy"
                    />
                }
                onChange={setStrategy}
            >
                <AIStrategySelect />
            </RelaxField>
            <RelaxField
                name="ai_intensity_id"
                label={
                    <FormattedMessage
                        id="apps.smart.components.aiGeneration.intensity"
                        defaultMessage="Intensity"
                    />
                }
            >
                <AIIntensitySelect strategy={strategy}/>
            </RelaxField>
        </>
    )

}


export default AIStrategyAndIntensitySelect;