import { createSelector, createSlice } from "@reduxjs/toolkit";
import { callAPIProps } from "services/hooks/useAPI";
import { currentProjectIdSelector } from "services/store/scopeSlice";

export type CampaingsState = any;

const initialState: CampaingsState = {

}

export const campaignsSlice = createSlice({
    name: "campaigns",
    initialState,
    reducers: {
        get: (state, action) => {
            state[action.payload.projectId] = action.payload.data;

        },
        patch: (state, action) => {
            const updatedCampaign = state[action.payload.projectId].map((camp: any) => {
                if (camp.id === action.payload.data[0].id) {
                    return { ...action.payload.data[0] };
                }
                return camp;
            })
            state[action.payload.projectId] = updatedCampaign
        }
    }
})

export const campaignSelector = createSelector([
    currentProjectIdSelector,
    (state: any) => state.campaigns],
    (projectId, campaigns) => projectId ? campaigns[projectId] : null
)


export const GetCampaigns: callAPIProps = {
    url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/google/campaigns`),
    successDispatch: campaignsSlice.actions.get,
    // fakeResponse: async (request) => ({
    //     status: 200,
    //     json: () => ({
    //         data: [
    //             {
    //                 "id": 20426019292,
    //                 "type": "google-campaign-stats",
    //                 "attributes": {
    //                   "budget_amount": 100,
    //                   "name": "Nazwa kampani",
    //                   "advertising_channel_type": "PERFORMANCE_MAX",
    //                   "status": "ENABLED",
    //                   "clicks": 1384,
    //                   "ctr": 0.2846565199506376,
    //                   "engagements": 1,
    //                   "average_cpc": 457861.27167630056,
    //                   "cost_micros": 633.68,
    //                   "impressions": 4862,
    //                   "active_view_impressions": 0,
    //                   "interactions": 1384
    //                 }
    //             }
    //         ]
    //     })
    // })
}

export const UpdateCampaignStatus = (campaignId: any): callAPIProps => {
    return {
        url: ({ getApiUrl, projectId }) => getApiUrl(`projects/${projectId}/google/campaigns/${campaignId}/status`),
        method: "PATCH",
        successDispatch: campaignsSlice.actions.patch,
        //fakeResponse: async (request) => await timeout(500).then(() => editFakeCampaign())
    }
}

// const editFakeCampaign = () => {
//     return {
//         status: 200,
//         json: () => ({
//             "data": [
//                 {
//                     "type": "google_campaigns",
//                     "id": 1,
//                     "attributes": {
//                         "id": 1,
//                         "project_id": 20,
//                         "type": "performance",
//                         "settings": [],
//                         "status": "DISABLED",
//                         "resource_name": "customers/1686674031/campaigns/21057458333",
//                         "budget_resource_name": "customers/1686674031/campaignBudgets/13393465830",
//                         "proximity_resource_name": null,
//                         "name": "[Forsant API] - performance campaign 1709043443",
//                         "created_at": null,
//                         "updated_at": null
//                     },
//                     "relationships": {
//                         "project": {
//                             "data": {
//                                 "type": "projects",
//                                 "id": 20
//                             }
//                         },
//                         "googleAdGroups": {
//                             "data": []
//                         },
//                         "googleAssetGroups": {
//                             "data": []
//                         },
//                         "googleCampaignLocations": {
//                             "data": []
//                         }
//                     }
//                 }
//             ],
//         })
//     }
// }
