import { Language } from "providers/languages";
import LanguageHelper from "providers/languages/helper";
import { isValidTimestamp } from "services/helpers/forms-helpers";
import { Product } from "products";

type Config<T> = {
  key: string;
  event?: string;
  validate?: (val: T) => boolean;
  json?: boolean;
};

export class LocalStorageItem<T> {
  config: Config<T>;

  constructor(config: Config<T>) {
    this.config = {
      ...config,
      key: this.getPrefixedCapitalizedKey(config.key),
    };
  }

  set(value: T | null) {
    if (this.validate(value)) {
      const newValue: string = this.isString(value)
        ? value
        : JSON.stringify(value);

      this.config.event && window.dispatchEvent(new Event(`${this.config.event}`));
      localStorage.setItem(this.config.key, newValue);
    }
  }

  get(): T | null {
    let item = localStorage.getItem(this.config.key);

    if (item && this.config.json)
      try {
        item = JSON.parse(item);
      } catch (e) {
        console.warn(`${this.config.key}: could not parse. Value cleared.`);
        this.clear();
        return null;
      }

    if (item !== "null" && this.validate((item as unknown) as T))
      return item as T | null;

    return null;
  }

  validate = (val: T | null): boolean => {
    if (val && this.config.validate && !this.config.validate(val)) {
      console.warn(
        `${this.config.key}: is not valid value! Value cleared - check your validation rules.`
      );
      this.clear();
      return false;
    }

    return true;
  };

  clear = () => this.set(null);

  private getPrefixedCapitalizedKey = (key: string): string =>
    `${import.meta.env.VITE_INSTANCE_LOCAL_STORAGE_PREFIX}${key
      .charAt(0)
      .toUpperCase()}${key.slice(1)}`;

  private isString(value: T | string | null): value is string {
    return typeof value === "string";
  }
}

//Token
export const TokenLocalStorage = new LocalStorageItem<{
  expiration_timestamp: number;
  expires_in: number;
  token_type: string;
  token: string;
}>({
  key: "token",
  event: "token",
  json: true,
  validate: (val) =>
    isValidTimestamp(val.expiration_timestamp) &&
    Number.isInteger(val.expires_in) &&
    val.expires_in > 0 &&
    val.token_type === "bearer" &&
    typeof val.token === "string",
});

export const getAuthorizationHeaders = () => {
  const token = TokenLocalStorage.get();

  if (token) {
    return {
      Authorization: `${token.token_type} ${token.token}`,
    };
  } else
    console.warn(
      "getAuthorizationHeadersFromStorage func. : Token not available in storage!"
    );
}

export const getAuthorizationHeadersFromStorage = () => {
  const token = TokenLocalStorage.get();

  if (token) {
    return {
      "Content-Type": "application/json",
      Authorization: `${token.token_type} ${token.token}`,
    };
  } else
    console.warn(
      "getAuthorizationHeadersFromStorage func. : Token not available in storage!"
    );
};

//Project
export const ProjectLocalStorage = new LocalStorageItem<number>({
  key: "project",
});

export const PricingPlanLocalStorage = new LocalStorageItem<{
  offerId: string;
  showAll?: boolean;
  promoCode?: string;
  partnerCode?: string;
  product?: string;
}>({
  key: "pricingPlan",
  json: true,
});

//Selected product
export const ProductLocalStorage = new LocalStorageItem<Product>({
  key: "product",
});

//Subscription
export const SubscriptionLocalStorage = new LocalStorageItem<number>({
  key: "subscription",
});

//PromoCode
export const PromoCodeLocalStorage = new LocalStorageItem<string>({
  key: "promoCode",
});


//Language
export const LanguageLocalStorage = new LocalStorageItem<string>({
  key: "language",
  validate: (val) => LanguageHelper.isValidLanguage(val),
});

