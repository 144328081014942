import { faEmptySet, faFaceFrown } from "@fortawesome/pro-light-svg-icons";
import { createSelector } from "@reduxjs/toolkit";
import StandardAvatar from "components/common/Avatar/StandardAvatar";
import AwesomeIcon from "components/common/AwesomeIcon";
import FormattedMessage from "components/common/FormattedMessage";
import { AttachSubscriptionItem } from "modules/panel/components/subscriptions/subscriptionn-manager/AttachSubscriptionModal";
import { DetachSubscriptionItem } from "modules/panel/components/subscriptions/subscriptionn-manager/DetachSubscriptionModal";
import { userPermissionsSelector } from "modules/panel/config/selectors/user";
import { getProductLogo } from "providers/routing/layouts/components/product-logo/smallProductLogo";
import useScope from "services/hooks/useScope";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { projectSelector, projectWithSubscriptionsSelector } from "state/projects/projectsSelectors";
import { subscriptionsArraySelector, unattachedSubscriptionsSelector } from "state/subscriptions/subscriptionsSelectors";
import { Subscription } from "state/subscriptions/subscriptionTypes";

type ProjectSubscriptionsWithManagerProps = {
    projectId: string;
    manageMode?: boolean;
    children?: React.ReactNode;
    onOpen?: (subscriptionId: string) => void;
}

export const subscriptionsThatCanBeAttachedToProjectSelector = (projectId: string) => createSelector(
    [
        subscriptionsArraySelector,
        (state) => projectSelector(projectId)(state)
    ],
    (subscriptions, project) => {
        if (!project) return [];
        if (!subscriptions) return [];
        const unattached = subscriptions.filter((s: Subscription) => s.projects.length === 0);
        //console.log("unattached", unattached.map(s => s.id))
        const attachedToThisProject = subscriptions.filter((s: Subscription) => s.projects.indexOf(projectId) !== -1);
        //console.log("attached to this project", attachedToThisProject.map(s => s.id))
        const productsOfThisProject = attachedToThisProject.map(s => s.product);
        //console.log("products of this project", productsOfThisProject)
        const canBeAttached = unattached.filter((s: Subscription) => productsOfThisProject.indexOf(s.product) === -1);
        //console.log("can be attached", canBeAttached.map(s => s.id))
        return canBeAttached;
        return [];
    }
);

export const subscriptionsThatCanBeAttachedToProjectGroupedByProductSelector = (projectId: string) => createSelector(
    [subscriptionsThatCanBeAttachedToProjectSelector(projectId)],
    (subscriptions) => {
        if (!subscriptions) return {};
        const grouped: Record<string, Subscription[]> = {};
        subscriptions.map(sub => {
            if (!grouped[sub.product]) grouped[sub.product] = [];
            grouped[sub.product].push(sub);
        });
        return grouped;
    }
);

const ProjectSubscriptionsWithManager = (props: ProjectSubscriptionsWithManagerProps) => {

    const project = useSelectorWithParams(projectWithSubscriptionsSelector(props.projectId))
    const { subscriptions } = project || {};
    const scope = useScope();
    const userPermissions = useSelectorWithParams(userPermissionsSelector);
    const canUnpin = userPermissions && userPermissions.includes("detach-subscription")

    const subscriptionsThatCanBeAttachedToProject = useSelectorWithParams(subscriptionsThatCanBeAttachedToProjectGroupedByProductSelector(props.projectId));

    const subs = (subscriptions || []).map(sub => (
        <StandardAvatar
            size={"large"}
            image={sub && getProductLogo(sub?.product)}
            onClick={() => {
                scope.openSubscription(sub?.id)
                if (props.onOpen) props.onOpen(sub.id);
            }}
        />)
    )

    const empty = <div style={{
        height: "40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }}>
        <AwesomeIcon icon={faFaceFrown} size="xl" />
        <div>
            <FormattedMessage
              id="panel.components.project.listItem.subscriptions.empty"
              defaultMessage="No Subscriptions"
            />
        </div>
    </div>

    if (props.manageMode) {

        if (canUnpin) {
            return (
                <>
                    {subscriptions?.map(sub => <DetachSubscriptionItem projectId={props.projectId} subscription={sub} />)}
                    {Object.keys(subscriptionsThatCanBeAttachedToProject).map(product => <AttachSubscriptionItem projectId={props.projectId} product={product} />)}
                    {props.children}
                </>
            )
        }

        return (
            <>
                {(subscriptions && subscriptions.length > 0) ? subs : empty}
                {Object.keys(subscriptionsThatCanBeAttachedToProject).map(product => <AttachSubscriptionItem projectId={props.projectId} product={product} />)}  
                {props.children}
            </>
        )
    }

    return (
        <>
            {(subscriptions && subscriptions.length > 0) ? subs : empty}
            {props.children}
        </>
    )



}

export default ProjectSubscriptionsWithManager;