import { Input, Spin } from "antd";
import React, { useState } from "react";
import { Suspense, useEffect } from "react";
import useAPI from "services/hooks/useAPI";
import { useCreationEditor } from "tools/creationEditor/CreationEditor";
import RelaxSingleField from "tools/relaxForm/RelaxField";
import { Creation, patchCreation } from "../creationsSlice";
import CreationTargetSelector from "../editorTools/CreationTargetSelector";
import { PostPublicationSettings } from "../editorTools/PostPublicationSettings";
import FieldMessage, { FieldCTA, FieldMediaSingle, FieldPostType, FieldTitle } from "../Fields";
import CarouselCards from "../inputs/CarouselCards";
import EditorWithStages from "./EditorWithStages";
import EditorSectionsCollapse from "../editorSections/EditorSectionsCollapse";
import { EditorSectionMessage, EditorSectionPublication } from "../editorSections/EditorSections";
import CarouselCardGeneratorSection from "../inputs/CarouselCardGenerator";

const EditorCarousel = (props: any) => {

    const { creation, creationId } = useCreationEditor();
    const updateCreation = useAPI(patchCreation(creationId));
    const [sectionKey, setSectionKey] = useState();

    const hasCards = creation.content && creation.content.carousel && creation.content.carousel.length > 0;

    const handleClose = () => {
        setSectionKey(undefined);
    }

    useEffect(() => {
        if (!creation) return;
        if (!creation.content || !creation.content.provider) {
            updateCreation.call({
                body: {
                    content: {
                        provider: ["facebook"],
                    }
                }
            })
        }
    }, []);

    if (!hasCards) return (
        <EditorSectionsCollapse>
            <EditorSectionMessage />
            <CarouselCardGeneratorSection onClose={() => setSectionKey(undefined)}/>
            <EditorSectionPublication />
        </EditorSectionsCollapse>
    )

    return (
        <>
        <EditorSectionsCollapse
            activeKey={sectionKey}
            onChange={(key) => setSectionKey(key)}
        >
            <EditorSectionMessage />
            <CarouselCardGeneratorSection onClose={() => setSectionKey(undefined)}/>
            </EditorSectionsCollapse>
            <CarouselCards />
        <EditorSectionsCollapse>
            <EditorSectionPublication />
        </EditorSectionsCollapse>
        </>
    )

}

const EditorCarouselPostWithStages = (props: any) => {

    const { creation } = useCreationEditor();

    return (
        <EditorWithStages
            stages={[
                {
                    name: "target",
                    content: <CreationTargetSelector forceProvider="facebook"/>,
                    fulfilled: (creation: Creation) => {
                        if (creation.content.provider && creation.content.provider.length > 0) return true;
                        return false;
                    }
                },
                {
                    name: "editor",
                    content: <EditorCarousel />,
                    fulfilled: (creation: Creation) => false
                }
            ]}
        />
    )

}

export default EditorCarouselPostWithStages;