import { Select } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import { useEffect, useMemo, useState } from "react";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { UnattachedSubscriptionsCard } from "./SubscriptionCard";
import ActorCardSelect from "components/inputs/actorCardSelect/ActorCardSelect";
import { unattachedSubscriptionsGroupedByProductSelector } from "state/subscriptions/subscriptionsSelectors";
import { projectsSelector } from "state/projects/projectsSelectors";

type Props = {
  product?: string | undefined;
  projectId?: string | number;
  setProjectId: (val: string) => void;
  setSubscriptionId: (val: string) => void;
};

export const SubscriptionAttachedManager = (props: Props) => {
  const [product, setProduct] = useState(
    props?.product !== undefined ? props?.product : undefined
  );

  const unattachedSubscriptionsByProduct = useSelectorWithParams(unattachedSubscriptionsGroupedByProductSelector);

  const projects = useSelectorWithParams(projectsSelector);

  const mappedProjects =
    projects &&
    Object.values(projects).map((p: any) => {
      return {
        label: p.name,
        value: p.id,
      };
    });

  const mappedSub = useMemo(() => {
    let subscriptions;
    if (product !== undefined) {
      subscriptions = unattachedSubscriptionsByProduct && unattachedSubscriptionsByProduct[product] && unattachedSubscriptionsByProduct[product].map && unattachedSubscriptionsByProduct[product].map(
        (s: any) => {
          return {
            value: s.id,
            renderLabel: <UnattachedSubscriptionsCard subscriptionId={s.id}/>,
          };
        }
      );
    }
    return (
      <>
        <FormattedMessage
          id="panel.containers.companySettings.attachSubscriptionModal.choose.product"
          defaultMessage="Choose subscription to attach"
        />
        <ActorCardSelect
          key={props.projectId}
          style={{ width: "100%" }}
          actors={subscriptions !== undefined ? subscriptions : null}
          onChange={(value) => props.setSubscriptionId(value)}
        />
      </>
    );
  }, [product]);

  const mappedProducts = unattachedSubscriptionsByProduct && Object.keys(unattachedSubscriptionsByProduct)?.map(
    (produckt: any) => {
      return {
        label: produckt,
        value: produckt,
      };
    }
  );

  return (
    <>
      <div>
        {props.product === undefined && (
          <>
            <FormattedMessage
              id="panel.containers.companySettings.attachSubscriptionModal.choose.product"
              defaultMessage="Choose product"
            />

            <Select
              options={mappedProducts}
              style={{ width: "100%" }}
              onChange={(value) => {
                setProduct(value);
              }}
            />
          </>
        )}

        {props?.projectId === undefined && (
          <>
            <FormattedMessage
              id="panel.containers.companySettings.attachSubscriptionModal.choose.project"
              defaultMessage="Choose project to attach product"
            />

            <Select
              options={mappedProjects}
              style={{ width: "100%" }}
              onChange={(value) => {
                props.setProjectId(value);
              }}
            />
          </>
        )}
        {mappedSub}
      </div>
    </>
  );
};
