import React, { PureComponent } from "react";
import { Form } from '@ant-design/compatible';
import { Button, Input } from "antd";
import FormattedMessage from "components/common/FormattedMessage";
import {
  RESET_PASSWORD,
  resetPassword,
} from "modules/panel/actions/user/password";
import CreateForm from "services/hoc/form-hoc";
import FormItem from "_components/forms/form-item-wrapper/form-item-wrapper";
import { EmailEnvelopeIcon } from "modules/panel/components/ft-icons";
import StandardButton from "components/common/StandardButton";

class ResetPasswordForm extends PureComponent {
  render() {
    let success = this.props.form.status.success;
    return (
      <>
        <h1 className="title">
          {!success ? (
            <FormattedMessage
              id="panel.containers.resetPassword.request.title"
              defaultMessage="Forgot your password?"
            />
          ) : (
            <FormattedMessage
              id="panel.containers.resetPassword.request.successTitle"
              defaultMessage="We miss you already"
            />
          )}
        </h1>
        <p className="subtitle">
          {!success ? (
            <FormattedMessage
              id="panel.containers.resetPassword.request.subtitle"
              defaultMessage="Enter your email address and <div>we’ll send you a password reset link!</div>"
              values={{
                div: (...chunks) => (
                  <>
                    <br /> {chunks}
                  </>
                ),
              }}
            />
          ) : (
            <FormattedMessage
              id="panel.containers.resetPassword.request.successSubtitile"
              defaultMessage="We just sent you an email with instructions to get back into {name}. It should be arriving any moment.
If you do not receive this email please check your spam folder"
              values={{
                name: import.meta.env.VITE_INSTANCE_NAME,
              }}
            />
          )}
        </p>
        {!success && (
          <Form onSubmit={this.props.form.handleSubmit}>
            <FormItem
              field="email"
              required={true}
              validator="email"
              form={this.props.form}
            >
              <Input
                placeholder="E-mail"
                type="email"
                suffix={<EmailEnvelopeIcon />}
              />
            </FormItem>

            <StandardButton
              type="primary"
              htmlType="submit"
              loading={this.props.form.status.pending}
              style={{ marginBottom: "30px", marginTop: "30px"}}
            >
              <FormattedMessage
                id="PaswordChangeRequest.requestBtn"
                defaultMessage="Reset password"
              />
            </StandardButton>

            <p className="form-addition-info align-left">
              <FormattedMessage
                id="panel.containers.resetPassword.request.doNotRememberEmail"
                defaultMessage="If you don’t remember your e-mail address, search your inbox messages by email"
              />
              : <strong>{import.meta.env.VITE_INSTANCE_NO_REPLY_EMAIL}</strong>
            </p>
          </Form>
        )}
      </>
    );
  }
}

export default CreateForm({
  form: {
    dispatch: resetPassword,
    action: RESET_PASSWORD,
  },
  withRouter: true,
})(ResetPasswordForm);
