import { useCallback } from "react";
import { useSpring, config, animated } from "react-spring";
import { FormatNumberOptions, useIntl } from "react-intl";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import { AdsSettingsCurrencySelector } from "modules/smart/smartSlice";

type Props = {
  value: number;
  isCurrency?: boolean;
  currency?: string;
  className?: string;
};

const PostNumber = ({
  value: val = 0,
  isCurrency = false,
  currency = undefined,
  className,
}: Props) => {
  const props = useSpring({
    val,
    from: { val: 0 },
    delay: 100,
    config: config.molasses,
  });

  const { formatNumber } = useIntl();
  const accCurrency = useSelectorWithParams(AdsSettingsCurrencySelector);

  const formatOptions = (): FormatNumberOptions | undefined => {
    if (isCurrency) {
      if (currency) {
        return {
          style: "currency",
          currency,
        };
      } else {
        return {
          style: "currency",
          currency: accCurrency,
        };
      }
    }
    return undefined;
  }

  const getFormattedNumber = useCallback(
    (value: string) =>
      formatNumber(
        parseFloat(value as any),
        formatOptions()),
    [formatNumber, currency, isCurrency]
  );

  return (
    <animated.div className={className}>
      {props.val.interpolate(getFormattedNumber as any)}
    </animated.div>
  );
};

export default PostNumber;
