import AwesomeIcon from "components/common/AwesomeIcon"
import { useCreationEditor } from "../CreationEditor"
import { CreationSectionState } from "./useCreationSection"
import { faCheckCircle, faCircle, faExclamationCircle, faSpinner } from "@fortawesome/pro-light-svg-icons"

export const getSectionStatusIcon = (status: CreationSectionState) => {
    switch (status) {
        case "initial":
            return <AwesomeIcon icon={faCircle} size="xl" />
        case "loading":
            return <AwesomeIcon icon={faSpinner} size="xl" spin={true} />
        case "error":
            return <AwesomeIcon icon={faExclamationCircle} size="xl" color="red" />
        case "ready":
            return <AwesomeIcon icon={faCheckCircle} size="xl" color="green" />
        default:
            return null;
    }
}

const SectionStatusIconDisplay = (props : {sectionName: string}) => {

    const { sectionStatuses } = useCreationEditor();
    const status = sectionStatuses[props.sectionName];
    return getSectionStatusIcon(status);

}

export default SectionStatusIconDisplay;