import { CreateNewProjectPopup } from "modules/panel/components/subscriptions/buy-product/CreateNewProjectPopup";
import { ProjectListItemFull } from "./ProjectListItem";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import FormattedMessage from "components/common/FormattedMessage";
import { Badge, Divider } from "antd";
import useAPI from "services/hooks/useAPI";
import { getSubscriptions } from "state/subscriptions/subscriptionsSlice";
import { projectsSelector } from "state/projects/projectsSelectors";
import { useMemo, useState } from "react";
import { unattachedSubscriptionsGroupedByProductSelector } from "state/subscriptions/subscriptionsSelectors";
import StandardAvatar from "components/common/Avatar/StandardAvatar";
import { getProductLogo } from "providers/routing/layouts/components/product-logo/smallProductLogo";
import Toolbar, { useToolbar } from "components/toolbar/Toolbar";

const UnattachedSubscriptions = () => {

  const unattachedSubscriptions = useSelectorWithParams(unattachedSubscriptionsGroupedByProductSelector);

  const mappedSubs = useMemo(() => {
    return Object.keys(unattachedSubscriptions).map((product) => {
      return (
        <Badge
          count={unattachedSubscriptions[product].length}
        >
          <StandardAvatar
            size="large"
            image={getProductLogo(product)}
          />
        </Badge>
      )
    })
  }, [unattachedSubscriptions])



  return (
    <>
      <Divider>
        <FormattedMessage
          id="panel.components.project.list.manage.unattachedSubscriptions"
          defaultMessage="Unattached Subscriptions"
        />
      </Divider>
      <div style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "10px"
      }}>
        {mappedSubs}
      </div>
    </>
  )
}

const ProjectList = () => {

  const { } = useAPI(getSubscriptions(), true)
  const projects = useSelectorWithParams(projectsSelector);

  const [manage, setManage] = useState(false);

  if (!projects) return null;

  return (
    <>
      <Toolbar
        title={(
          <FormattedMessage
            id="panel.components.project.list.projects"
            defaultMessage="My Projects"
          />
        )}
        smallToolbar={(
          <>
            <Toolbar.Spacer />
          </>
        )}
        largeToolbar={(<>
          <CreateNewProjectPopup />
          <Toolbar.Spacer />
        </>
        )}
        drawerToolbar={(<>
          <CreateNewProjectPopup />
        </>
        )}
      />
      {manage && <UnattachedSubscriptions />}
      <Divider />

      {Object.values(projects).map((project: any) => {
        return (
          <ProjectListItemFull
            key={project.id}
            projectId={project.id}
            manage={manage}
            setManage={setManage}
          />
        );
      })}
    </>
  );
};

export default ProjectList;

