import React from "react";

export default function InfinityListAdvancedSettingsCheckboxs(props: any) {
  return (
    <>
      <div className="post-list-advanced-settings-list">
        {props.settings.map((setting: any) => (
          <div key={setting.key} className="post-list-advanced-settings-group">
            <div className="post-list-advanced-settings-group-label">
              {setting.label}
            </div>
            <div className="post-list-advanced-settings-group-children">
              {setting.children.map((child: any) => (
                <div
                  key={child.key}
                  className="post-list-advanced-settings-item"
                >
                  {child.label}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
