import Tabs from "_components/tabs/tabs";
import FormattedMessage from "components/common/FormattedMessage";
import { StandardHalfFullColumn, StandardRow } from "components/layout/StandardGrid";
import HelpPanelDocument from "tools/helpPanel/HelpPanelDocument";
import UserIntegrations from "modules/panel/components/user/user-integrations";
import AccountAdvancedSettings from "./AccountAdvancedSettings";
import UserProfile from "./UserProfile";
import UserEmails from "./emails";

export default function UserAccountPage () {

    return (
        <Tabs
          panes={[
            {
              title: (
                <FormattedMessage
                  id="panel.profile.tabs.user"
                  defaultMessage="User"
                />
              ),
              key: "user",
              content: (
              <StandardRow>
                <StandardHalfFullColumn>
                  <UserProfile />
                </StandardHalfFullColumn>
                <StandardHalfFullColumn>
                    <HelpPanelDocument center pageId={"apps.panel.profile.userDetails.sider"}/>
                </StandardHalfFullColumn>
              </StandardRow>),
            },
            {
                title: (
                  <FormattedMessage
                    id="panel.profile.tabs.emails"
                    defaultMessage="Emails"
                  />
                ),
                key: "emails",
                content: (
                    <StandardRow>
                      <StandardHalfFullColumn>
                      <UserEmails />
                      </StandardHalfFullColumn>
                      <StandardHalfFullColumn>
                          <HelpPanelDocument center pageId={"apps.panel.profile.userEmails.sider"}/>
                      </StandardHalfFullColumn>
                    </StandardRow>),
            },
            {
                title: (
                  <FormattedMessage
                    id="panel.profile.tabs.integrations"
                    defaultMessage="Integrations"
                  />
                ),
                key: "integrations",
                content: (
                    <StandardRow>
                      <StandardHalfFullColumn>
                        <UserIntegrations />
                      </StandardHalfFullColumn>
                      <StandardHalfFullColumn>
                          <HelpPanelDocument center pageId={"apps.panel.profile.userIntegrations.sider"}/>
                      </StandardHalfFullColumn>
                    </StandardRow>),
            },
            {
              title: (
                <FormattedMessage
                  id="panel.profile.tabs.adcanced"
                  defaultMessage="Advanced"
                />
              ),
              key: "advanced",
              content: <AccountAdvancedSettings />
          },
          ]}
        />
      );
}